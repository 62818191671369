import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ReduxCrmKey } from '../types'

export interface SearchCrmRecordsParameter {
  crmId: ReduxCrmKey
  query: string
  objects?: Array<string>
}

export const searchCrmRecords = async (
  { crmId, query, objects }: SearchCrmRecordsParameter,
  options?: LuruAPIRequestOptions
) => {
  var url = `/api/sor/${crmId}/objects/search?q=${query}`

  if (objects) {
    url += `&objects=${objects.join(',')}`
  }

  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
