import { useEffect } from 'react'
import { scrollYToElement } from '../../../../../domutils/utils'
import styles from '../CRMCollectionChooser.module.css'

export const useHighlightSelectedItem = (
  controlRef: React.RefObject<HTMLElement>
) => {
  useEffect(() => {
    const selection = controlRef.current?.getElementsByClassName(
      styles.selected
    )

    if (selection?.[0]) {
      const container = controlRef.current?.getElementsByClassName(
        styles.results
      )[0]

      scrollYToElement(selection[0], container)
    }
  })
}
