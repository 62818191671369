import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DeleteTaskParameter } from '../middleware/deleteTask'

export const deleteTask = (
  { taskId }: Readonly<DeleteTaskParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    '/api/tasks/' + taskId,
    HTTPMethod.DELETE,
    options
  )

  return request.make()
}
