import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import { ReduxNoteTemplatesState } from '../types'

export interface UseNoteTemplateParameter {
  noteTemplateId: string
}

export interface UseNoteTemplateAPIResponse extends LuruAPIResponse {
  data: {}
}

export const useNoteTemplate = {
  action: createAsyncThunk<
    UseNoteTemplateAPIResponse['data'],
    UseNoteTemplateParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: UseNoteTemplateAPIResponse['metadata']
      rejectedMeta: UseNoteTemplateAPIResponse['metadata']
    }
  >(
    'noteTemplates/useNoteTemplate',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: UseNoteTemplateAPIResponse =
          await NoteTemplatesAPI.useNoteTemplate(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(useNoteTemplate.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(useNoteTemplate.action.fulfilled, (state, action) => {})
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(useNoteTemplate.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    useNoteTemplate.addPendingCase(builder)
    useNoteTemplate.addFulfilledCase(builder)
    useNoteTemplate.addRejectedCase(builder)
  },
}
