import json5 from 'json5'
import Omnibox, { FieldsetSelection, TabSections } from './Omnibox'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { CrmRecordFieldsData, CrmRecordMultiFieldUpdatePayload } from '../../../../domain/crmRecord/typings.d'
import { LuruReduxStore } from '../../../../app/store'
import { UserSliceActions } from '../../../../features/user/userSlice'
import { RecentEntityType } from '../../../../features/user/types'
import { trackEvent } from '../../../../analytics/Ga'
import LuruUser from '../../../../domain/users/LuruUser'
import update from 'immutability-helper'
import { CrmRecordType } from '../../../../features/crm/types'
import { ToastId } from '@/app_ui/types'

interface OmniboxEvents {
  onClickUpdateButton: () => void
  onClickCancelButton: () => void
  onChangeTabName: (tabName: string | number) => void
  onChooseFields: (fieldNames: Array<string>) => void
  onChooseCollection: (collectionId?: string) => void
  onFieldValueChange: (fieldName: string, value: any) => void
  onDefaultCollectionLoaded: (fields?: Array<string>) => void
  onChooseDefaultCollection: (fields?: Array<string>) => void
}

export default class OmniboxEventHandler {
  #component: Omnibox
  handlers: OmniboxEvents

  constructor(component: Omnibox) {
    this.#component = component
    this.handlers = {
      onClickCancelButton: this.#onClickCancelButton.bind(this),
      onClickUpdateButton: this.#onClickUpdateButton.bind(this),
      onChangeTabName: this.#onChangeTabName.bind(this),
      onChooseFields: this.#onChooseFields.bind(this),
      onChooseCollection: this.#onChooseCollection.bind(this),
      onFieldValueChange: this.#onFieldValueChange.bind(this),
      onDefaultCollectionLoaded: this.#onDefaultCollectionLoaded.bind(this),
      onChooseDefaultCollection: this.#onChooseDefaultCollection.bind(this),
    }
  }

  async loadOmnibox(
    crmRecordType: CrmRecordType,
    sorRecordId: string,
    sorRecordName: string,
    defaultTabName: TabSections = TabSections.DETAILS
  ) {
    if (crmRecordType === undefined || sorRecordId === undefined) {
      return
    }

    this.#component.setState({
      crmRecordType,
      sorRecordId,
      sorRecordName,
      crmRecordStatus: EntityStatus.Loading,
      currentTabName: defaultTabName,
    })

    try {
      let schema = await CrmRecord.getObjectSchema(crmRecordType)
      let getFieldsResponse = await CrmRecord.getRecordFields(crmRecordType, sorRecordId)
      let fields = getFieldsResponse.payload as CrmRecordFieldsData
      var recordNameField = CrmRecord.getNameFieldFromObjectSchema(crmRecordType)
      // sorRecordName passed is not present try to find the proper name field's value
      var recordName = sorRecordName ?? fields?.record?.[recordNameField || '']?.value ?? ''
      this.#component.setSchemaAndValues(json5.parse(json5.stringify(schema)), fields)

      LuruReduxStore.dispatch(
        UserSliceActions.addRecentItem({
          id: sorRecordId,
          type: crmRecordType as unknown as RecentEntityType,
          name: recordName,
        })
      )
    } catch (e) {
      this.#component.setState({
        crmRecordStatus: EntityStatus.ErrorLoading,
      })
      console.warn(e)
    }
  }

  #onChangeTabName(tabName: string | number) {
    if (typeof tabName === 'number') {
      this.#component.setState({ currentTabName: Object.values(TabSections)[tabName] })
    } else {
      this.#component.setState({ currentTabName: tabName })
    }
  }

  async #onClickUpdateButton() {
    if (this.#component.state.crmRecordType === undefined || this.#component.state.sorRecordId === undefined) {
      return
    }

    var nameField = this.#component.getObjectSchema()?.payload?.fields?.find((f) => f.nameField)?.name
    var recordName = nameField ? this.#component.state.fieldValues[nameField] : undefined
    var objectName = CrmRecord.getCrmRecordName(this.#component.state.crmRecordType)

    try {
      if (this.#component.state.fieldValues !== undefined) {
        let filteredValues: { [_: string]: any } = {}
        let missedMandatoryFields: string[] = []

        for (let fieldName in this.#component.state.fieldValues) {
          let isReadonly =
            this.#component.getObjectSchema()?.payload?.fields?.find((f) => f.name === fieldName)?.updateable === false

          if (
            this.#component.state.fieldValues[fieldName] !== null &&
            this.#component.state.fieldValues[fieldName] !== undefined &&
            this.#component.state.fieldValues[fieldName] !== '' &&
            !isReadonly
          ) {
            filteredValues[fieldName] = this.#component.state.fieldValues[fieldName]
          } else if (
            (this.#component.state.fieldValues[fieldName] === null ||
              this.#component.state.fieldValues[fieldName] === '') &&
            this.#component.state.mandatoryFields?.includes(fieldName)
          ) {
            missedMandatoryFields.push(fieldName)
          }
        }

        if (missedMandatoryFields.length > 0) {
          this.#component.setState({
            updateRecordStatus: EntityStatus.Idle,
            alertMessage: 'Please enter all mandatory fields (marked with *)',
            missedMandatoryFields,
          })
          return
        }

        var updatePayload: CrmRecordMultiFieldUpdatePayload = {
          crmRecordType: this.#component.state.crmRecordType,
          sorRecordId: this.#component.state.sorRecordId,
          fields: filteredValues,
        }

        this.#component.setState({
          updateRecordStatus: EntityStatus.Updating,
          alertMessage: undefined,
          missedMandatoryFields,
        })

        await CrmRecord.multiUpdate(updatePayload)
        this.#component.setState({ updateRecordStatus: EntityStatus.Updated })

        // Mark item as recently updated
        LuruReduxStore.dispatch(
          UserSliceActions.addRecentItem({
            id: this.#component.state.sorRecordId,
            name: recordName,
            type: this.#component.state.crmRecordType as unknown as RecentEntityType,
          })
        )

        this.#component.props.toast.showToast({
          id: ToastId.OMNIBOX_TOAST_ID,
          message: recordName
            ? `Updated ${objectName} '${recordName}' successfully`
            : `Updated ${objectName} successfully`,
          severity: 'success',
        })
      }

      this.#onClickCancelButton()

      trackEvent('update_ob_record', `${LuruUser.getCurrentUserCrmName()}-${objectName}-${recordName}`)
    } catch (e) {
      this.#component.setState(
        (prevState) =>
          update(prevState, {
            updateRecordStatus: { $set: EntityStatus.ErrorUpdating },
            alertMessage: { $set: (e as Error).message },
          }),
        () => this.#component.componentRefs.modal.current?.showModal()
      )
    }
  }

  #onClickCancelButton() {
    this.#component.setState({
      crmRecordType: undefined,
      sorRecordId: undefined,
      sorRecordName: undefined,
      crmRecordStatus: EntityStatus.NotLoaded,
      updateRecordStatus: EntityStatus.Idle,
      fieldNames: [],
      fieldValues: {},
      fieldsetSelectionMode: FieldsetSelection.DEFAULT,
      mandatoryFields: [],
      missedMandatoryFields: [],
      alertMessage: undefined,
    })
    this.#component.componentRefs.modal.current?.cancel()
  }

  #onChooseFields(fieldNames: Array<string>) {
    const { mandatoryFields } = this.#component.state
    this.#component.setState({
      fieldsetSelectionMode: FieldsetSelection.MANUAL,
      fieldNames: [...new Set([...(mandatoryFields ?? []), ...fieldNames])],
    })
    //Whenever new fields choosen remove the collection Selection
    this.#component.componentRefs.luruCollectionsRef.current?.setState({
      selectedCollectionId: undefined,
    })
  }

  async #onChooseCollection(collectionId?: string) {
    var collection = this.#component.props.collections.entities?.[collectionId || '']?.data
    if (collection) {
      var { mandatoryFields } = this.#component.state
      this.#component.setState({
        fieldsetSelectionMode: FieldsetSelection.COLLECTION,
        fieldNames: [...new Set([...(mandatoryFields ?? []), ...(collection?.fields || [])])],
      })
      trackEvent('use_collection', 'Omnibox')
    }
  }

  #onFieldValueChange(fieldName: string, fieldValue: string) {
    this.#component.setFieldValue(fieldName, fieldValue)
  }

  #onDefaultCollectionLoaded(fields?: Array<string>) {
    if (fields) {
      const { mandatoryFields } = this.#component.state
      this.#component.setState({
        // Union of mandatoryFields & selectedFields
        fieldNames: [...new Set([...(mandatoryFields ?? []), ...fields])],
      })
    }
  }

  #onChooseDefaultCollection(fields?: Array<string>) {
    if (fields && this.#component.state.fieldsetSelectionMode !== FieldsetSelection.DEFAULT) {
      const { mandatoryFields } = this.#component.state
      this.#component.setState({
        fieldsetSelectionMode: FieldsetSelection.DEFAULT,
        // Union of mandatoryFields & selectedFields
        fieldNames: [...new Set([...(mandatoryFields ?? []), ...fields])],
      })
    }
  }
}
