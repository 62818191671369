import { RootState } from '../../../app/store'
import { ReduxEmailsStateTTL } from '../types'

export const isEmailsCacheValid = (state: RootState) =>
  !Boolean(state.emails.refreshedAt) ||
  new Date().valueOf() -
    (state.emails.refreshedAt
      ? new Date(state.emails.refreshedAt).valueOf()
      : 0) <
    ReduxEmailsStateTTL
