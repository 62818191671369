// Libraries
import { useLocation } from 'react-router-dom'

// Luru components
import UninstallExtension from '../../routes/uninstallextension/UninstallExtension'
import { LoginScreen } from '../../routes/LoginScreen'

// Hooks
import useWebAppEffects from './hooks/useWebAppEffects'

// Other functions
import { isTenantizedUrl } from './hooks/useTrackAndNavigateEffect'

export default function WebAppSignedOut() {
  const location = useLocation()

  useWebAppEffects()

  // If we come here, user is signed-out (according to initial-state got from
  // server)
  // This could be because of 2 reasons:
  // 1. User has actually visited my.luru.com (instead of
  //    pepsi.stamp.my.luru.com)
  //    --> In this case, we redirect user to auth.luru.com to check if she's
  //        actually logged in
  //        A> If yes, auth.luru.com will redirect to pepsi.stamp.my.luru.com
  //           and we will get correct initial-state
  //        B> If no, auth.luru.com will redirect to my.luru.com/signin and
  //           we show Login screen
  // 2. User visited pepsi.stamp.my.luru.com but is actually signed out :)
  //    --> In this case, we need to show login screen
  //
  // NOTE: In above examples, luru.com is a placeholder. Base domain can be actually be
  //    luru.com  --> Local env
  //    looru.ai  --> Test env
  //    luru.app  --> Prod env

  /* Extension-Uninstall-Page */
  if (location.pathname === '/uninstall_chrome_extension') {
    return <UninstallExtension />
  } else if (location.pathname === '/signin' || isTenantizedUrl()) {
    // Cases 1.B / 2
    return <LoginScreen />
  } else {
    // Case 1.A
    // We are calling auth.luru.com as a part of useEffect() above
    return <></>
  }
}
