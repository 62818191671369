import luruFetch from '../../LuruFetch'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { handleApiException, handleApiResponse, responseToJson } from '../../common'
import LuruError, { LuruErrorDetails } from '../../LuruError'
import { abortControllers } from '../crmMiddleware'
import LuruAPIRequest from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
// import { convertToFieldType } from './crmBaseMiddleware'

/**
 * @classdesc: A base object for all CRM related API requests
 */
export const crmApi = {
  /**
   * Get schema of a CRM object
   * @param {string} crmId - CRM Identifier
   * @param {string} sorObjectName - object name for which schema is reqd.
   * @param {any} record - record for pipeline_id.
   * @param {boolean} forceRefresh - whether to force fetch schema or not.
   * @returns - API response as json
   * @throws - Error, if any, during API request
   */
  getObjectSchema: async (crmId, sorObjectName, record = null, forceRefresh = null) => {
    // TODO: We are putting pipedrive specific logic here to accommodate
    // getting the deal stages.  We need to make this crm agnostic and get this
    // logic into a pipedrive specific handler
    let urlSearchParams = {}

    if (record) {
      if (crmId === 'pipedrive' && sorObjectName === 'deal' && record.data.pipeline_id) {
        urlSearchParams['pipeline_id'] = record.data.pipeline_id
      }
      // if (crmId === 'hubspot' && sorObjectName === 'deals') {
      // urlSearchParams['pipeline_id'] = record.properties.pipeline
      // }
    }
    if (forceRefresh === true) {
      urlSearchParams['force_refresh'] = true
    }
    let params = ''
    if (Object.keys(urlSearchParams).length > 0) {
      params = `?${new URLSearchParams(urlSearchParams)}`
    }

    const apiURL = `/api/sor/${crmId}/objects/${sorObjectName}/schema${params}`

    return luruFetch(apiURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error getting ${crmId} schema for: ${sorObjectName}`)
        }
        return response.json()
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error
      })
  },

  getPipelineList: async ({ crmId }, { signal }) => {
    const opportunityNames = {
      sfdc: 'opportunity',
      pipedrive: 'deal',
      hubspot: 'deals',
    }
    const opportunityObjectName = opportunityNames[crmId] ?? null

    if (!opportunityObjectName) {
      throw new LuruError(LuruErrorDetails.InvalidArgumentError, `CRM ${crmId} not recognized`)
    }

    const apiURL = `/api/sor/${crmId}/objects/${opportunityObjectName}/pipeline`
    const apiRequest = new LuruAPIRequest(apiURL, HTTPMethod.GET, { signal })

    return apiRequest.make()
  },

  /**
   * Get details of a CRM record
   * @param {string} crmId - CRM Identifier
   * @param {string} sorObjectName - object name for which schema is reqd.
   * @param {string} sorRecordId - Id of the record for which details are reqd.
   * @param {string} requestId - Id of thunk request supplied by Redux
   * @returns - API response as json
   * @throws - Error, if any, during API request
   */
  getRecordFields: async (crmId, sorObjectName, sorRecordId, requestId) => {
    const apiURL = `/api/sor/${crmId}/objects/${sorObjectName}/${sorRecordId}`

    // Store the signal for current request
    let abortController = new AbortController()
    abortControllers[`${crmId}/getRecordFields`][requestId] = abortController

    return luruFetch(apiURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      signal: abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error getting fields: ${crmId} ${sorObjectName} id ${sorRecordId}`)
        }
        return response.json()
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error
      })
  },

  /**
   * Update field of a CRM record
   * @param {string} crmId - CRM Identifier
   * @param {string} sorObjectName - object name for which schema is reqd.
   * @param {string} sorRecordId - Id of the record for which details are reqd.
   * @param {string} fieldName - Field name
   * @param {string} fieldValue - Field value
   * @param {string} requestId - Id of thunk request supplied by Redux
   * @returns - API response as json
   * @throws - Error, if any, during API request
   */
  updateRecordField: async (
    crmId,
    { sorObjectName, sorRecordId, fieldName, fieldValue, luruFieldType },
    { requestId, rejectWithValue }
  ) => {
    const apiURL = `/api/sor/${crmId}/objects/${sorObjectName}/${sorRecordId}`

    // Store the signal for current request
    let abortController = new AbortController()
    abortControllers[`${crmId}/updateRecordField`][requestId] = abortController

    return luruFetch(apiURL, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        // [fieldName]: convertToFieldType(fieldValue, luruFieldType),
        [fieldName]: CrmRecord.convertToPrimitiveType(luruFieldType, fieldValue),
      }),
      signal: abortController.signal,
    })
      .then(responseToJson)
      .then(([response, json]) => {
        return handleApiResponse([response, json], rejectWithValue)
      })
      .catch((error) => {
        return handleApiException(error, rejectWithValue)
      })
  },

  /**
   * Update field of a CRM record
   * @param {string} crmId - CRM Identifier
   * @param {string} sorObjectName - object name for which schema is reqd.
   * @param {string} sorRecordId - Id of the record for which details are reqd.
   * @param {[fieldName: string]: { fieldValue: any, luruFieldType: LuruFieldType }} fields - Set of field values
   * @param {string} fieldValue - Field value
   * @param {string} requestId - Id of thunk request supplied by Redux
   * @returns - API response as json
   * @throws - Error, if any, during API request
   */
  multiUpdate: async (crmId, { sorObjectName, sorRecordId, fields }, { requestId, rejectWithValue }) => {
    const apiURL = `/api/sor/${crmId}/objects/${sorObjectName}/${sorRecordId}`
    var abortController = new AbortController()
    abortControllers[`${crmId}/multiUpdate`][requestId] = abortController

    return luruFetch(apiURL, {
      method: 'PUT',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(fields),
      signal: abortController.signal,
    })
      .then(responseToJson)
      .then(([response, json]) => handleApiResponse([response, json], rejectWithValue))
      .catch((error) => handleApiException(error, rejectWithValue))
  },

  /**
   * Update field of a CRM record
   * @param {string} crmId - CRM Identifier
   * @param {string} sorObjectName - object name for which schema is reqd.
   * @param {{[fieldName:string]: any}} fields - Field value map
   * @param {string} requestId - Id of thunk request supplied by Redux
   * @returns - API response as json
   * @throws - Error, if any, during API request
   */
  createRecord: async (crmId, { sorObjectName, fields }, { requestId, rejectWithValue }) => {
    const apiURL = `/api/sor/${crmId}/objects/${sorObjectName}`
    var abortController = new AbortController()
    abortControllers[`${crmId}/createRecord`][requestId] = abortController

    return luruFetch(apiURL, {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(fields),
      signal: abortController.signal,
    })
      .then(responseToJson)
      .then(([response, json]) => handleApiResponse([response, json], rejectWithValue))
      .catch((error) => handleApiException(error, rejectWithValue))
  },

  /**
   * Search CRM for records
   * @param {string} crmId - CRM identifier
   * @param {string} query - Search query as string
   * @param {Array} objects - List of objects to search for
   * @param {string} requestId - Id of thunk request supplied by Redux
   * @returns - API response as json
   * @throws - Error, if any, during API request
   */
  searchRecords: async (crmId, query, objects, requestId) => {
    const baseURL = `/api/sor/${crmId}/objects/search`,
      searchURL = `${baseURL}?objects=${objects.join(',')}&q=${query}`

    // Store the signal for current request
    let abortController = new AbortController()
    abortControllers[`${crmId}/searchRecords`][requestId] = abortController

    return luruFetch(searchURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      signal: abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            if (data.error_data.description === 'Aborted') {
              return {}
            } else {
              throw data.error_data
            }
          })
        } else {
          return response.json()
        }
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error
      })
  },
}
