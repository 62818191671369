import { useO365CalendarState } from '../../../hooks/useO365CalendarState'
import useNetworkErrorEffect from './useNetworkErrorEffect'
import useTrackAndNavigateEffect from './useTrackAndNavigateEffect'
import useWebAppDOMEffect from './useWebAppDOMEffect'

export default function useWebAppEffects() {
  useO365CalendarState()
  useNetworkErrorEffect()
  useWebAppDOMEffect()
  useTrackAndNavigateEffect()
}
