import { useState } from 'react'
import { ListItemChooserPopupProps } from './types'
import { ReduxNoteTemplateEntity } from '@/features/noteTemplates/types'
import styles from './ListItemChooserPopup.module.css'
import { EntityStatus } from '@/app/types'

export interface CallbackType {
  template: ReduxNoteTemplateEntity
  message: string
}

export interface ListItemChooserPopupState {
  templates: { template: ReduxNoteTemplateEntity; specificity: number }[]
  isSectionOpened: boolean
  callBacktoInsertTemplate: ({ template, message }: CallbackType) => void
  isManuallyToggled: boolean
  label?: string | JSX.Element
  containerClassName: Array<string>
  templateStatus: EntityStatus
}

export default function useComponentState(props: ListItemChooserPopupProps) {
  const [state, setState] = useState<ListItemChooserPopupState>({
    templates: [],
    isSectionOpened: false,
    callBacktoInsertTemplate: ({ template, message }: CallbackType) => {},
    isManuallyToggled: false,
    label: '',
    containerClassName: [styles.containerStyle, 'max-sm:!rounded-t-none max-sm:!border-t-0'],
    templateStatus: EntityStatus.Idle,
  })

  return {
    state,
    setState,
  }
}

export type UseComponentState = ReturnType<typeof useComponentState>
