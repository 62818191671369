import { ActionCreatorWithPayload, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from './store'
import { Params, useNavigate, useLocation } from 'react-router-dom'
import { ShowToastParams } from '@/app_ui/hooks/useAppUIStateHandlers'

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  dispatch: AppDispatch
  state: RootState
  rejectValue: string
}>()

export enum ThreeWayAnswer {
  YES = 'yes',
  NO = 'no',
  UNCERTAIN = 'uncertain',
}

// TODO: Should it move under domain folder? (domain/businessObjects)
export enum EntityStatus {
  Creating = 'creating',
  Loading = 'loading',
  LoadingMore = 'loading-more',
  LoadingNext = 'loading-next',
  LoadingPrev = 'loading-prev',
  Updating = 'updating',
  Syncing = 'syncing',
  Linking = 'linking',
  Deleting = 'deleting',
  Created = 'created',
  Loaded = 'loaded',
  Updated = 'updated',
  Synced = 'synced',
  Linked = 'Linked',
  Deleted = 'deleted',
  ErrorCreating = 'error-creating',
  ErrorLoading = 'error-loading',
  ErrorUpdating = 'error-updating',
  ErrorSyncing = 'error-syncing',
  ErrorLinking = 'error-linking',
  ErrorDeleting = 'error-deleting',
  Idle = 'Idle',
  NotLoaded = 'NotLoaded',
  LoadedPartially = 'loaded-partially',
}

// TODO: Should it move under domain folder? (domain/businessObjects)
export function isEntityAvailable(status: EntityStatus) {
  return [
    EntityStatus.Created,
    EntityStatus.Loaded,
    EntityStatus.Updated,
    EntityStatus.Synced,
    EntityStatus.Idle,
  ].includes(status)
}

export interface ReduxActions {
  touchEntity?: ActionCreatorWithPayload<any, string>
  unmarkEntityAsDraft?: ActionCreatorWithPayload<any, string>
}

export enum HTTPMethod {
  HEAD = 'HEAD',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  CONNECT = 'CONNECT',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE',
}

export interface LuruAPIResponse {
  http_code: number
  method: HTTPMethod
  request_id: string
  data: any
  metadata: LuruAPIResponseMetaData
  error_data: LuruAPIResponseErrorData
}

export type LuruAPIResponseMetaData = null | {
  count: number
  cursor: string | null
}

export type LuruAPIResponseErrorData = null | {
  error_code: number
  description: string
  message: string
  traceback: string
  additional_data?: Record<string, any>
}

export enum LuruEntryPoint {
  // These pages are served by webserver
  WEBAPP = 'webApp',
  EMBEDDED_ZOOM_MEETING = 'embeddedZoomMeeting',
  EMBEDDED_ZOOM_PANEL = 'embeddedZoomPanel',
  // These pages are served by extension
  NEWTAB = 'newTab',
  EMBEDDED_GMEET_NOTE = 'embeddedGmeetNote',
  BACKGROUND = 'background',
  GLOBALSEARCH = 'globalSearch',
  OPTIONS = 'options',
}

export interface ConnectLuruExtensionsProps {
  router: {
    params: Params
    navigate: ReturnType<typeof useNavigate>
    location: ReturnType<typeof useLocation>
    searchParams: URLSearchParams
    setSearchParams: (params?: URLSearchParams | Record<string, string>) => void
  }
  toast: {
    showToast: (params: ShowToastParams) => void
    hideToast: (id: string) => void
  }
}
