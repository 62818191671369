import { RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { NoteId } from '../types'
import { getNoteDetail } from './getNoteDetail'
import { getNoteDetailMap } from './getNoteDetailMap'
import { getSORConnection } from './getSORConnection'
import { getSuggestedRecordNamesForRecordLink } from './getSuggestedRecords'
import { doesSORConnectionExist } from './doesSorConnectionExist'
import { isNoteSyncRequired } from './isNoteSyncRequired'

export const NotesSliceSelectors = {
  getNoteDetail,
  getNoteDetailMap,
  getSORConnection,
  getSuggestedRecordNamesForRecordLink,
  doesSORConnectionExist,
  isNoteSyncRequired,
  // Select note data
  getNoteByNoteId: (noteId: NoteId) => (state: RootState) => {
    return state.notes?.entities[noteId]?.status === EntityStatus.Loaded
      ? state.notes?.entities[noteId]?.data
      : null
  },
  // Select note status
  getNoteEntityStatusByNoteId: (noteId: NoteId) => (state: RootState) => {
    return state.notes.entities[noteId]?.status
  },
  // Select note title
  getNoteTitle: (noteId: NoteId) => (state: RootState) => {
    return state.notes.entities[noteId]?.data?.title ?? null
  },
  // Select note created time
  getNoteCreatedTime: (noteId: NoteId) => (state: RootState) => {
    return state.notes.entities[noteId]?.data?.created_at
  },
  // Select note updated time
  getNoteUpdatedTime: (noteId: NoteId) => (state: RootState) => {
    return state.notes.entities[noteId]?.data?.updated_at
  },
  // Select note body
  getNoteBody: (noteId: NoteId) => (state: RootState) => {
    return state.notes.entities[noteId]?.data?.body
  },
  // Select note sync state
  getNoteSyncState: (noteId: NoteId) => (state: RootState) => {
    return state.notes.entities[noteId]?.data?.sync_state
  },
  // Select note editable status
  getNoteMarkedEditable: (noteId: NoteId) => (state: RootState) => {
    // If the markedEditable field is not there (which is default),
    // we return false
    return state.notes.entities[noteId]?.markedEditable ?? false
  },
}
