import { LuruFieldType, LuruHierarchialPicklistValue, SorRecord } from '../types'

export const compareFieldValues = (value1: any, value2: any, fieldType: LuruFieldType | undefined) => {
  // TODO: Implement this for each type based on CRMRecord
  switch (fieldType) {
    case LuruFieldType.MULTIENUM: {
      let v1 = Array.isArray(value1)
        ? value1.map(String).sort().join(';')
        : typeof value1 === 'string'
        ? value1.split(';').sort().join(';')
        : String(value1)
      let v2 = Array.isArray(value2)
        ? value2.map(String).sort().join(';')
        : typeof value2 === 'string'
        ? value2.split(';').sort().join(';')
        : String(value2)

      return v1 === v2
    }

    case LuruFieldType.REFERENCE: {
      let v1 = value1 as SorRecord | undefined
      let v2 = value2 as SorRecord | undefined

      return (
        v1?.sor_record_id === v2?.sor_record_id &&
        v1?.sor_object_name === v2?.sor_object_name &&
        v1?.sor_record_name === v2?.sor_record_name
      )
    }

    case LuruFieldType.HIERARCHICAL_ENUM:
      let hv1 = value1 as LuruHierarchialPicklistValue | undefined
      let hv2 = value2 as LuruHierarchialPicklistValue | undefined
      return hv1?.level1 === hv2?.level1 && hv1?.level2 === hv2?.level2
  }

  return value1 === value2
}
