import { AppDispatch, LuruReduxStore, RootState } from '@/app/store'
import { EntityStatus, LuruAPIResponse } from '@/app/types'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { UserAPI } from '../api'
import LuruError from '@/features/LuruError'
import { EmailSyncState, ReduxUserState } from '../types'

export interface SetEmailSyncInfoResponse extends LuruAPIResponse {}

export interface SetEmailSyncInfoParams {
  syncState: EmailSyncState
}

export const setEmailSyncInfo = {
  action: createAsyncThunk<
    SetEmailSyncInfoResponse['data'],
    SetEmailSyncInfoParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | SetEmailSyncInfoResponse['metadata']
      rejectedMeta: null | SetEmailSyncInfoResponse['metadata']
    }
  >(
    'user/setEmailSyncInfo',
    async (params: SetEmailSyncInfoParams, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await UserAPI.setEmailSyncInfo(params)) as SetEmailSyncInfoResponse

        return fulfillWithValue(response.data, null)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    },
    {
      condition: () => {
        return LuruReduxStore.getState().user?.data?.userSettings?.emailSyncData?.setStatus !== EntityStatus.Loading
      },
    }
  ),

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(setEmailSyncInfo.action.fulfilled, (state, action) => {
      const userSettingsData = state?.data?.userSettings

      if (userSettingsData) {
        userSettingsData.emailSyncData = userSettingsData.emailSyncData
          ? {
              ...userSettingsData.emailSyncData,
              data: {
                syncStatus: action.payload.status,
                mailBox: action.payload.mailbox,
                lastSyncTime: action.payload.last_sync_time,
              },
              setStatus: EntityStatus.Loaded,
            }
          : {
              data: {
                syncStatus: action.payload.status,
                mailBox: action.payload.mailbox,
                lastSyncTime: action.payload.last_sync_time,
              },
              setStatus: EntityStatus.Loaded,
            }
      }
    })
  },

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(setEmailSyncInfo.action.pending, (state, action) => {
      const userSettingsData = state?.data?.userSettings

      if (userSettingsData) {
        userSettingsData.emailSyncData = userSettingsData.emailSyncData
          ? {
              ...userSettingsData.emailSyncData,
              data: {
                ...userSettingsData.emailSyncData.data,
              },
              setStatus: EntityStatus.Loading,
            }
          : {
              data: {},
              setStatus: EntityStatus.Loading,
            }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(setEmailSyncInfo.action.rejected, (state, action) => {
      const userSettingsData = state?.data?.userSettings

      if (userSettingsData) {
        userSettingsData.emailSyncData = userSettingsData.emailSyncData
          ? {
              ...userSettingsData.emailSyncData,
              data: {},
              setStatus: EntityStatus.ErrorLoading,
            }
          : {
              data: {},
              setStatus: EntityStatus.ErrorLoading,
            }
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUserState>) {
    setEmailSyncInfo.addFulfilledCase(builder)
    setEmailSyncInfo.addRejectedCase(builder)
    setEmailSyncInfo.addPendingCase(builder)
  },
}
