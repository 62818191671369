// React
import React, { useCallback, useLayoutEffect, useRef } from 'react'

// Router
import { useNavigate } from 'react-router-dom'

// Redux
import { useSelector, useDispatch /*, shallowEqual*/ } from 'react-redux'
import { NotesMiddleware } from '../../features/notes/middleware'

// Own components
import NotesEditorInfoBlock from './NotesEditorInfoBlock'
import CRMFieldChooser from '../../primitives/CRMFieldChooser'
import FlatButton from '../../primitives/FlatButton'
import ListItemChooserDialog from '../../primitives/ListItemChooserDialog'
import LoadingSpinner from '../../primitives/LoadingSpinner'
import LinkDetailsPopup from '../../primitives/LinkDetailsPopup'
import FloatingFormattingMenu from '../../primitives/FloatingFormattingMenu'
import TemplateChooser from '../noteTemplates/components/TemplateChooser'
import { CRMCollectionChooser, SourceEntityType } from './components/CRMCollectionChooser'

// Own libraries & classes
import NotesEditorController from './NotesEditorController'
import NotesEditorModel from './NotesEditorModel'

// Styles
import styles from './css/NotesEditor.module.css'

// Assets
import meditatingIcon from '../../images/meditating.svg'
import ListItemChooserPopup from '@/primitives/ui/ListItemChooserPopup/ListItemChooserPopup'
import LuruUser from '@/domain/users/LuruUser'
import { LuruReduxStore } from '@/app/store'
import { LuruEntryPoint } from '@/app/types'
import { useLuruToast } from '@/hooks/useLuruToast'

// Core editor components
export default function NotesEditor({ noteId, embedded, globalRefs }) {
  // Every NotesEditor component should be instantiated with noteID
  // NotesEditor component should be initialized with a key attribute as well
  // in order for React to re-render component when noteId changes and also
  // to uniquely identify this component inspite of using state variable

  const { showToast, hideToast } = useLuruToast()

  if (!noteId) {
    throw new Error('NotesEditor created without a noteID')
  }

  // Dispatcher to write to notes slice
  const dispatch = useDispatch()

  // Navigate to navigate to a newly created Luru note from SOR note
  const navigate = useNavigate()

  const handleRedirectPlaybook = useCallback(() => {
    let currEntryPoint = LuruUser.getCurrentEntryPoint()

    if ([LuruEntryPoint.WEBAPP, LuruEntryPoint.EMBEDDED_ZOOM_PANEL].includes(currEntryPoint)) {
      navigate('/settings/meeting_playbooks')
    } else if ([LuruEntryPoint.EMBEDDED_GMEET_NOTE, LuruEntryPoint.GLOBALSEARCH].includes(currEntryPoint)) {
      const webappUrl = LuruReduxStore.getState()?.user?.extensionSettings?.webServer

      if (webappUrl) {
        /* global chrome */
        chrome.tabs.create({
          url: `${webappUrl}/settings/meeting_playbooks`,
        })
      }
    }
  }, [navigate])

  // Note content editable has both title and body.  We need to find out what
  // is happening to the title as well to handle save, edit, etc.  Note title
  // last updated timestamp is stored as an attribute in editor element
  const refs = {
    editor: useRef(),
    modal: useRef(),
    templateChooserDialog: useRef(),
    templateChooserPanel: useRef(),
    linkDetailsPopup: useRef(),
    floatingFormattingMenu: useRef(),
    templateChooserPopup: useRef(),
  }

  // CRM id is necessary to pass it down to Editor menu and later for any
  // purpose/feature dependent on the CRM
  const crmId = useSelector((state) => state.user?.data?.userSettings?.connectedCRM?.name)

  //// Main HTML of editor
  // Equivalent of render() of a class component
  function render() {
    let editorId = `editor-${noteId.slice(0, 7)}`
    let canvasId = `canvas-${noteId.slice(0, 7)}`
    let titleId = `title-${noteId.slice(0, 7)}`

    return (
      <div key={`container-${noteId}`} className={styles.editorContainer} id={editorId} ref={refs.editor}>
        <div key={`banner-${noteId}`} data-role='editor-banner' className={[styles.banner, styles.hidden].join(' ')}>
          <div data-role='editor-banner-message'></div>
          <div className={styles.bannerButtonContainer}>
            <FlatButton dataRole='editor-banner-button'>
              <span></span>
            </FlatButton>
          </div>
        </div>
        <div key={`canvas-${noteId}`} className={styles.canvas} id={canvasId}>
          <NotesEditorInfoBlock
            key={`info-block-${noteId}`}
            noteId={noteId}
            titleId={titleId}
            embedded={embedded}
            globalRefs={globalRefs}
            popupRefs={refs.templateChooserPopup}
          />
          <ListItemChooserPopup
            title='Choose Meeting Playbook'
            key={`list-item-chooser-popup-${noteId}`}
            ref={refs.templateChooserPopup}
            items={[]}
            noteId={noteId}
            crmId={crmId}
            handleRedirectPlaybook={handleRedirectPlaybook}
          />
          <CRMFieldChooser entityId={noteId} key={`crm-field-chooser-${noteId.slice(0, 7)}`} />
          <CRMCollectionChooser
            key={`crm-collection-chooser-${noteId.slice(0, 7)}`}
            entityId={noteId}
            sourceType={SourceEntityType.NOTE}
          />
          <ListItemChooserDialog
            key={`list-item-chooser-${noteId}`}
            title='Choose Meeting Playbook'
            ref={refs.templateChooserDialog}
            items={[]}
            handleRedirectPlaybook={handleRedirectPlaybook}
            noteId={noteId}
          />
          <TemplateChooser key={`template-chooser-${noteId}`} ref={refs.templateChooserPanel} />
          <LinkDetailsPopup ref={refs.linkDetailsPopup} />
          <FloatingFormattingMenu ref={refs.floatingFormattingMenu} />
        </div>
        <div className={styles.loadingMessage}>
          <img src={meditatingIcon} title='Loading' alt='Loading' />
          Loading...
          <LoadingSpinner></LoadingSpinner>
        </div>
      </div>
    )
  }

  // React component lifecycle method to take over control of our editor,
  //    which is designed as an uncontrolled component.  Responsibility of
  //    maintaining editor's state, therefore, lies with us.
  useLayoutEffect(() => {
    // console.log(`${noteId.slice(0, 5)}:useLayoutEffect`)
    // Editor element
    let editorElement = refs.editor.current

    // Model
    let modelConfig = {
      crmId,
      noteId,
      editorElement,
      dispatch,
      navigate,
      showToast,
      hideToast,
    }
    let model = new NotesEditorModel(modelConfig)

    // Controller
    let controllerConfig = {
      model,
      noteId,
      editorElement,
      navigate,
      dispatch,
      templateChooserDialog: refs.templateChooserDialog,
      templateChooserPanel: refs.templateChooserPanel,
      linkDetailsPopup: refs.linkDetailsPopup,
      floatingFormattingMenu: refs.floatingFormattingMenu,
      templateChooserPopup: refs.templateChooserPopup,
      showToast,
      hideToast,
    }
    let controller = new NotesEditorController(controllerConfig)
    controller.setup()
    console.log(`${noteId.slice(0, 5)}:mount`)
    return () => {
      console.log(`${noteId.slice(0, 5)}:unmount`)

      // Check if note is to be saved
      let latestBody = controller.getLatestNoteContent()

      if (model.isNoteDirty(latestBody)) {
        model.saveEntity({
          type: 'reconcile',
          data: latestBody,
          source: 'unmount',
          sync: true,
        })
        // console.log(`${noteId.slice(0, 5)}:unmount:Dirty note saved`)
      } else if (model.isEntityEditable()) {
        dispatch(NotesMiddleware.syncNote.action({ noteId, crmId })).catch(console.warn)
      }

      controller.teardown()
      controller = null
    }
  }, [
    crmId,
    dispatch,
    navigate,
    noteId,
    refs.editor,
    refs.floatingFormattingMenu,
    refs.linkDetailsPopup,
    refs.templateChooserDialog,
    refs.templateChooserPanel,
    refs.templateChooserPopup,
    showToast,
    hideToast,
  ])

  return render()
}
