import React, { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import ModalContent from './ModalContent'
import './calendly.css'

export interface CalendlyModalProps {
  open: boolean
  rootElement: HTMLElement
  onModalClose: () => void
  url: string
}

export default function CalendlyModal({ open, rootElement, onModalClose, url }: CalendlyModalProps) {
  const handlePanelKeyDown = useCallback(
    (e: KeyboardEvent) => {
      let key = e.key

      if (key === 'Escape') {
        onModalClose()
      }
    },
    [onModalClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', handlePanelKeyDown)

    return () => {
      document.removeEventListener('keydown', handlePanelKeyDown)
    }
  }, [handlePanelKeyDown])

  if (!open) return null

  if (!rootElement) {
    throw new Error('[react-calendly]: PopupModal rootElement property cannot be undefined')
  }

  return createPortal(
    <div className='calendly-overlay'>
      <div onClick={onModalClose} className='calendly-close-overlay'></div>
      <div className='calendly-popup'>
        <div className='calendly-popup-content'>
          <ModalContent open={open} onModalClose={onModalClose} rootElement={rootElement} url={url} />
        </div>
      </div>
    </div>,
    rootElement
  )
}
