import { useCallback } from 'react'
import { useEditorCaretOperations } from './useEditorCaretOperations'
import { InsertCollectionCommandContext } from './useEditorMenuCommandListener'
import { useShowHideCollectionsChooser } from './useShowHideCollectionsChooser'

export const useHandleChooseCollection = ({
  cmdContext,
  selectedIndex,
  displayedRecords,
  goBackToNotesEditor,
  hideCollectionsChooser,
}: {
  cmdContext: InsertCollectionCommandContext | null
  selectedIndex: number
  displayedRecords: Array<any>
  goBackToNotesEditor: ReturnType<typeof useEditorCaretOperations>
  hideCollectionsChooser: ReturnType<
    typeof useShowHideCollectionsChooser
  >['hideCollectionsChooser']
}) => {
  const handleChooseCollection = useCallback(
    (event: Event) => {
      const targetEl = event.target as HTMLElement

      const chosenIndex =
        event.type === 'click'
          ? parseInt(targetEl?.dataset.resultIndex ?? '', 10)
          : selectedIndex

      const chosenCollection =
        chosenIndex < displayedRecords.length && chosenIndex >= 0
          ? displayedRecords[chosenIndex]
          : undefined

      if (chosenCollection && cmdContext?.onResponseReady instanceof Function) {
        cmdContext?.onResponseReady({ chosenCollection })
      }

      hideCollectionsChooser()
      goBackToNotesEditor({ trace: 'Handle choose collection' })
    },
    [
      selectedIndex,
      displayedRecords,
      cmdContext,
      hideCollectionsChooser,
      goBackToNotesEditor,
    ]
  )

  return handleChooseCollection
}
