import CrmMultiObjectFieldChooserComponent, {
  CrmMultiObjectFieldChooserComponentProps,
  CrmMultiObjectFieldChooserComponentState,
} from './CrmMultiObjectFieldChooserComponent'
import update from 'immutability-helper'
import { CrmFieldSchema, CrmRecordSchema } from '../../../../domain/crmRecord/typings.d'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { EntityStatus } from '../../../../app/types'
import { CrmRecordType } from '../../../../features/crm/types'

interface CrmMultiObjectFieldChooserEvents {
  onChangeRecordType: (crmFieldSchema: Partial<CrmFieldSchema & { crmObjectName: string }>) => void
  toggleFieldSelection: (field: string) => void
  toggleMandatoryFieldSelection: (field: string) => void
  onReorderSelectedFields: (fields: Array<string>) => void
  onClickDone: () => void
  onClickCancel: () => void
  onFocusInput: () => void
  loadSchema: (crmRecordType: CrmRecordType | string, forceRefresh?: boolean) => void
}

export default class CrmMultiObjectFieldChooserEventHandlers {
  #component: CrmMultiObjectFieldChooserComponent
  handlers: CrmMultiObjectFieldChooserEvents
  #mounted: boolean = false
  constructor(component: CrmMultiObjectFieldChooserComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentDidUpdate = this.#onComponentUpdate.bind(this)
    this.#component.componentWillUnmount = this.#onComponentUnmount.bind(this)
    this.handlers = {
      onChangeRecordType: this.#onChangeRecordType.bind(this),
      toggleFieldSelection: this.#toggleFieldSelection.bind(this),
      toggleMandatoryFieldSelection: this.#toggleMandatoryFieldSelection.bind(this),
      onReorderSelectedFields: this.#onReorderSelectedFields.bind(this),
      onClickDone: this.#onClickDone.bind(this),
      onClickCancel: this.#onClickCancel.bind(this),
      onFocusInput: this.#onFocusInput.bind(this),
      loadSchema: this.#loadSchema.bind(this),
    }
  }

  async #onComponentMount() {
    const { crmRecordType, selectedItems = [] } = this.#component.props
    this.#mounted = true
    const loadSchemaRes = await this.#loadSchema(crmRecordType)
    // We don't show choosing of child fields of poly-ref fields at all i.e filed.referenceTo having morethan 1 referenceObject
    const recordsTypes = loadSchemaRes?.data?.filter?.((f) => f.referencedObject && f.referenceTo?.length === 1) || []
    const type2Fileds = selectedItems
      ?.filter((f) => f.split('.').length > 1)
      ?.map((i) => recordsTypes.find((r) => r.name === i.split('.')[0]))
    var allAsyncSchemaFetches = type2Fileds.map((f) =>
      this.#loadSchema(
        CrmRecord.getCrmRecordType(f?.referencedObject || '') ||
          f?.referencedObject ||
          CrmRecord.getAllPrimaryObjects().DEAL
      )
    )
    Promise.allSettled(allAsyncSchemaFetches).catch(console.warn)
    // this.#component.componentRefs.type2SelectModal?.current?.showModal?.()
  }

  #onComponentUnmount() {
    this.#mounted = false
  }

  async #onComponentUpdate(
    prevProps: Readonly<CrmMultiObjectFieldChooserComponentProps>,
    prevState: Readonly<CrmMultiObjectFieldChooserComponentState>,
    snapshot?: any
  ) {
    const crmPrimaryRecordType = CrmRecord.getAllPrimaryObjects()

    if (this.#component.props.crmRecordType !== prevProps.crmRecordType) {
      this.#component.setState({
        selectedCrmFieldSchema: {
          crmObjectName: CrmRecord.getCrmRecordName(this.#component.props.crmRecordType || crmPrimaryRecordType.DEAL),
        },
      })
      const loadSchemaRes = await this.#loadSchema(this.#component.props.crmRecordType as CrmRecordType)
      const recordsTypes = loadSchemaRes?.data?.filter?.((f) => f.referencedObject) || []
      const type2Fileds = this.#component.props.selectedItems
        ?.filter((f) => f?.split?.('.').length > 1)
        ?.map((i) => recordsTypes.find((r) => r.name === i.split('.')[0]))
      var allAsyncSchemaFetches = type2Fileds?.map?.((f) =>
        this.#loadSchema(
          CrmRecord.getCrmRecordType(f?.referencedObject || '') || f?.referencedObject || crmPrimaryRecordType.DEAL
        )
      )
      Promise.allSettled(allAsyncSchemaFetches || []).catch(console.warn)
    }
    if (prevProps.selectedItems !== this.#component.props.selectedItems) {
      this.#component.setState({
        selectedItems: this.#component.props.selectedItems || [],
      })
    }
  }

  #onChangeRecordType(crmFieldSchema: Partial<CrmFieldSchema & { crmObjectName: string }>) {
    this.#component.setState({
      selectedCrmFieldSchema: crmFieldSchema,
    })
    const crmRecordType =
      CrmRecord.getCrmRecordType(
        crmFieldSchema.crmObjectName ? crmFieldSchema.crmObjectName : crmFieldSchema.referencedObject || ''
      ) || crmFieldSchema.referencedObject
    this.#loadSchema(crmRecordType ?? CrmRecord.getAllPrimaryObjects().DEAL)
  }

  //Case1: If selected crm type is base crm type that comes from props
  //Don't added dot(.) in fieldName if not add dot(.) in fieldName
  #toggleFieldSelection(field: string) {
    var mandatoryFields = this.#component.props.mandatoryFields || []
    var itemIx = this.#component.state.selectedItems.findIndex((k) => k === field)
    //Remove
    if (itemIx !== -1) {
      this.#component.setState(
        (prevState: CrmMultiObjectFieldChooserComponentState) => ({
          selectedItems: update(prevState.selectedItems, {
            $splice: [[itemIx, 1]],
          }),
        }),
        () => {
          this.#component.props?.onSelectionChange?.(this.#component.state.selectedItems)

          //On remove also update the mandatory fields
          const updatedMandatoryFields = mandatoryFields.filter((f) => f !== field)
          this.#component.props?.onMandatorySelectionChange?.(updatedMandatoryFields)
        }
      )
    } else {
      //Select
      this.#component.setState(
        (prevState: CrmMultiObjectFieldChooserComponentState) => ({
          selectedItems: update(prevState.selectedItems, {
            $push: [field],
          }),
        }),
        () => {
          this.#component.props?.onSelectionChange?.(this.#component.state.selectedItems)
        }
      )
    }
  }
  #toggleMandatoryFieldSelection(field: string) {
    var mandatoryFields = this.#component.props.mandatoryFields || []
    var itemIx = mandatoryFields.findIndex((k) => k === field)
    //Remove
    if (itemIx !== -1) {
      mandatoryFields = update(mandatoryFields, {
        $splice: [[itemIx, 1]],
      })
      this.#component.props?.onMandatorySelectionChange?.(mandatoryFields)
    } else {
      //Select
      mandatoryFields = update(mandatoryFields, {
        $push: [field],
      })

      this.#component.props?.onMandatorySelectionChange?.(mandatoryFields)
    }
  }

  #onClickDone() {
    this.#component.props?.onFinishedSelection?.(this.#component.state.selectedItems)
  }

  #onClickCancel() {
    this.#component.setState({ selectedItems: this.#component.initialSelectedItems }, () => {
      this.#component.props?.onCancel?.(this.#component.initialSelectedItems)
      this.#component.props?.onSelectionChange?.(this.#component.initialSelectedItems)
      this.#component.props?.onFinishedSelection?.(this.#component.initialSelectedItems)
      this.#component.props?.onMandatorySelectionChange?.(this.#component.initialMandatorySelectedItems)
    })
  }

  #onFocusInput() {
    if (!this.#component.props.disabled) {
      this.#component.initialSelectedItems = this.#component.state.selectedItems
      this.#component.componentRefs.type2SelectModal.current?.showModal?.({
        ok: this.handlers.onClickDone,
        cancel: this.handlers.onClickCancel,
      })
      //Focus availableFiledFilterBox Input after opening a modal
      setTimeout(() => {
        this.#component.componentRefs.availableFiledFilterBox?.current?.focus?.()
      }, 2)
    }
  }

  #onReorderSelectedFields(fields: Array<string>) {
    this.#component.setState({ selectedItems: fields }, () => {
      this.#component.props?.onSelectionChange?.(this.#component.state.selectedItems)
    })
  }

  async #loadSchema(crmRecordType: CrmRecordType | string, forceRefresh?: boolean) {
    var schemaMap = this.#component.state.schema
    var schema = schemaMap[crmRecordType]

    try {
      if (!schema || schema?.status !== EntityStatus.Loaded || schema?.data?.length === 0 || forceRefresh) {
        this.#component.setState((prevState) =>
          update(prevState, {
            schema: {
              $set: {
                ...this.#component.state.schema,
                [crmRecordType]: {
                  status: EntityStatus.Loading,
                  data: [],
                },
              },
            },
          })
        )
        let schema = await CrmRecord.getObjectSchema(crmRecordType, forceRefresh)
        let filteredSchema = schema?.payload?.fields?.map?.(
          ({
            label,
            name,
            luruFieldType,
            picklistValues,
            referencedObject,
            isFilterable,
            updateable,
            referenceTo,
          }: CrmFieldSchema) => ({
            label,
            name,
            luruFieldType,
            picklistValues,
            referencedObject,
            isFilterable,
            updateable,
            referenceTo,
          })
        )

        if (!this.#mounted) {
          return
        }

        this.#component.setState((prevState) =>
          update(prevState, {
            schema: {
              $set: {
                ...this.#component.state.schema,
                [crmRecordType]: {
                  status: EntityStatus.Loaded,
                  data: filteredSchema,
                },
              },
            },
          })
        )
        // Update component once the schema is loaded. This should update the
        // individual rows (check)
        this.#component.forceUpdate()
        return {
          status: EntityStatus.Loaded,
          data: filteredSchema,
        } as { status: EntityStatus; data: CrmRecordSchema }
      }
    } catch (e) {
      this.#component.setState((prevState) =>
        update(prevState, {
          schema: {
            $set: {
              ...this.#component.state.schema,
              [crmRecordType]: {
                status: EntityStatus.ErrorLoading,
                data: [],
              },
            },
          },
        })
      )
      console.warn(`Cannot load schema`)
    }
  }
}
