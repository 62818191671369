import { EntityStatus } from '../../app/types'
import { ReduxNoteEntity } from '../notes/types'

export const ReduxMeetingsStateTTL = 60 * 60 * 1_000 // millisecs/hour

export const EmptyMeetingsSearchState: MeetingsSearchState = {
  query: {
    searchText: null,
    time_min: null,
    time_max: null,
  },
  status: EntityStatus.Idle,
  error: null,
  requestId: null,
  prevRequestId: null,
  prevQuery: null,
  results: null,
}

type MeetingsSearchCoreQuery = {
  searchText: string | null
  time_min: string | null
  time_max: string | null
}

export interface MeetingsSearchState {
  query: MeetingsSearchCoreQuery
  status: EntityStatus
  error: null | {}
  requestId: null | string
  prevRequestId: null | string
  prevQuery: null | MeetingsSearchCoreQuery
  results: null | Array<ReduxMeetingEntity>
}

export type ReduxMeetingsState = {
  refreshedAt: string | undefined
  entities: Record<
    string,
    {
      status: EntityStatus
      data: ReduxMeetingEntity
    }
  >
  search: Record<string, MeetingsSearchState>
  error: null | Record<string, any>
  status: EntityStatus
}

export interface ReduxMeetingEntity {
  meeting_id: string
  subject: string
  body: null | string
  start_time: string
  end_time: string
  organizer: MeetingParticipant
  participants: Array<MeetingParticipant>
  locations: Array<MeetingLocation>
  notes: Array<Partial<ReduxNoteEntity>>
  external_url?: string
}

export type MeetingParticipant = {
  name: string | null
  email: string
  optional: boolean
}

export type MeetingLocation = {
  type: string
  location: string
  data: string
}

export const EmptyMeetingsState: ReduxMeetingsState = {
  refreshedAt: undefined,
  search: {},
  entities: {},
  status: EntityStatus.Idle,
  error: null,
}
