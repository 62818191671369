// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { CRMProvider } from '../../../features/user/types'
import LuruError from '../../LuruError'

// Own libraries
import { NotesAPI } from '../api'
import { NoteSyncState, ReduxNoteEntity, ReduxNotesState } from '../types'

export type DeleteNoteConnectionParameter = {
  key: string
  noteId: string
  connectionId: string
  sorRecordId: string
}

export interface DeleteNoteConnectionAPIResponse extends LuruAPIResponse {
  data: Partial<ReduxNoteEntity>
}

export const deleteNoteConnection = {
  action: createAsyncThunk<
    DeleteNoteConnectionAPIResponse['data'],
    DeleteNoteConnectionParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | DeleteNoteConnectionAPIResponse['metadata']
      rejectedMeta: null | DeleteNoteConnectionAPIResponse['metadata']
    }
  >(
    'notes/deleteNoteConnection',
    // sorRecordId is purely sent so that meetingsSlice can easily handle
    // the 'fulfilled' case and update relevant meeting record as well
    async ({ key, noteId, connectionId, sorRecordId }, { getState, fulfillWithValue, rejectWithValue, signal }) => {
      if (!noteId || !connectionId) {
        return rejectWithValue(
          {
            message: 'Delete note connection called without noteId and/or connectionId',
          },
          null
        )
      }

      var currEntity = getState().notes?.entities?.[noteId]

      // If note is draft, we just create a link in frontend, not call any API
      if (currEntity && currEntity?.isDraft === true && currEntity?.data) {
        return fulfillWithValue(currEntity.data, null)
      }

      try {
        var response = (await NotesAPI.deleteNoteConnection(
          { key, noteId, connectionId, sorRecordId },
          { signal }
        )) as DeleteNoteConnectionAPIResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(deleteNoteConnection.action.pending, (state, action) => {
      let noteId = action.meta.arg.noteId
      let callerId = action.meta.arg.key
      let connectionId = action.meta.arg.connectionId
      // Ideally we should always have the entity in redux, as link note
      // is called from a note context (either from editor or later when)
      // we may provide a link button to each note displayed in a list
      if (!state.entities[noteId]) {
        state.entities[noteId] = {
          linkingStatus: {},
          status: EntityStatus.Loading,
          data: {
            note_id: noteId,
          },
        }
      }

      var currEntity = state.entities[noteId]
      var connection = currEntity
        ? currEntity.data?.connections?.find((item) => item.connection_id === connectionId)
        : undefined

      if (connection && currEntity) {
        currEntity.linkingStatus = {
          ...currEntity.linkingStatus,
          [callerId]: {
            status: EntityStatus.Deleting,
            connection,
          },
        }
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(deleteNoteConnection.action.fulfilled, (state, action) => {
      var noteId = action.meta.arg.noteId
      var connectionId = action.meta.arg.connectionId
      var currEntity = state.entities[noteId]
      var newConnections = currEntity?.data?.connections?.filter((item) => item.connection_id !== connectionId)
      var callerId = action.meta.arg.key

      if (!currEntity || !currEntity.data) {
        return
      }

      currEntity.data.connections = newConnections

      if (
        newConnections?.filter((conn) =>
          [CRMProvider.HUBSPOT, CRMProvider.PIPEDRIVE, CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX].includes(
            conn.sor as CRMProvider
          )
        ).length === 0
      ) {
        currEntity.data.sync_state = NoteSyncState.PRIVATE
      }

      var currConn = currEntity.linkingStatus?.[callerId]?.connection

      if (state.entities[noteId]?.linkingStatus && currConn !== undefined) {
        currEntity.linkingStatus = {
          ...currEntity.linkingStatus,
          [callerId]: {
            status: EntityStatus.Deleted,
            connection: currConn,
          },
        }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(deleteNoteConnection.action.rejected, (state, action) => {
      var noteId = action.meta.arg.noteId
      var callerId = action.meta.arg.key
      var currEntity = state.entities[noteId]
      var linkingStatus = currEntity ? currEntity.linkingStatus : undefined

      if (linkingStatus?.[callerId] !== undefined) {
        linkingStatus[callerId].status = EntityStatus.ErrorDeleting
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    deleteNoteConnection.addPendingCase(builder)
    deleteNoteConnection.addFulfilledCase(builder)
    deleteNoteConnection.addRejectedCase(builder)
  },
}
