// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse, LuruAPIResponseMetaData } from '../../../app/types'
import LuruError from '../../LuruError'
import { MeetingsAPI } from '../api'
import { ReduxMeetingsState, ReduxMeetingEntity } from '../types'
import { uniqBy } from '../../../utils/Utils'
import LuruUser from '@/domain/users/LuruUser'

const requestName = 'meetings/fetchMeeting'

export type FetchMeetingParameter = {
  meetingId: string
  include_notes?: boolean | null
}

interface FetchMeetingAPIResponse extends LuruAPIResponse {
  data: ReduxMeetingEntity
}

export const fetchMeeting = {
  action: createAsyncThunk<
    ReduxMeetingEntity,
    FetchMeetingParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: null | LuruAPIResponseMetaData
      rejectedMeta: null | LuruAPIResponseMetaData
    }
  >(requestName, async (params, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    var connectedCalendar = LuruUser.getCurrentUserCalendarName(getState())
    var connectedCalendarErrorCode =
      getState()?.user?.data?.userSettings?.connectedCalendar?.errorCode || getState()?.meetings?.error?.error_code

    if (!connectedCalendar) {
      let errorResponse = {
        error_code: 10100,
        message: 'No calendar connection exists',
        description: 'Please connect to your calendar and retry',
      }
      return rejectWithValue(errorResponse, null)
    }

    if (connectedCalendarErrorCode) {
      let errorResponse = {
        error_code: connectedCalendarErrorCode,
        message: 'Error accessing calendar API',
        description: 'Please connect to your calendar and retry',
      }
      return rejectWithValue(errorResponse, null)
    }

    if (params.include_notes === null || params.include_notes === undefined) {
      // making default as true
      params.include_notes = true
    }
    var { meetingId: meeting_id } = params
    var currMeeting = getState().meetings.entities?.[meeting_id]?.data

    // If meeting is already present in redux, return the entity
    if (currMeeting) {
      return fulfillWithValue(currMeeting, null)
    }

    try {
      var response = (await MeetingsAPI.fetchMeeting(params, {
        signal,
      })) as FetchMeetingAPIResponse
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }

    return fulfillWithValue(response.data, response.metadata)
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(fetchMeeting.action.pending, (state, action) => {
      const { meetingId: meeting_id } = action.meta.arg
      if (!state.entities) {
        state.entities = {}
      }
      if (!state.entities[meeting_id]) {
        state.entities[meeting_id] = {
          ...state.entities[meeting_id],
          status: EntityStatus.Loading,
        }
      } else {
        state.entities[meeting_id].status = EntityStatus.Loading
      }
      state.error = null
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(fetchMeeting.action.fulfilled, (state, action) => {
      const { meetingId: meeting_id } = action.meta.arg
      const meeting = action.payload
      if (state.entities) {
        state.entities[meeting_id] = {
          data: {
            ...state.entities[meeting_id]?.data,
            ...meeting,
            // Store only the note_ids as a part of store.meetings.entities[meeting_id]
            notes: meeting.notes
              ? uniqBy([...(state.entities[meeting_id]?.data?.notes || []), ...(meeting?.notes || [])], 'note_id')
              : [...(state.entities[meeting_id]?.data?.notes || [])],
          },
          status: EntityStatus.Loaded,
        }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(fetchMeeting.action.rejected, (state, action) => {
      const { meetingId: meeting_id } = action.meta.arg
      if (state.entities) {
        state.entities[meeting_id].status = EntityStatus.ErrorLoading
      }
      state.error = action.payload as any
    })
  },

  // TODO: We can do away with this after standardization
  addAllCases(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    fetchMeeting.addPendingCase(builder)
    fetchMeeting.addRejectedCase(builder)
    fetchMeeting.addFulfilledCase(builder)
  },
}
