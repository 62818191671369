import { LuruReduxStore } from '../../../app/store'
import { CalendarProvider, CRMProvider } from '../../user/types'
import { NotesMiddleware } from '../middleware'

export const getCrmConnections = async (noteId: string) => {
  var note = await LuruReduxStore.dispatch(NotesMiddleware.fetchNote.action({ noteId })).unwrap()
  var crmProviders = [CRMProvider.HUBSPOT, CRMProvider.PIPEDRIVE, CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX] as Array<
    CRMProvider | CalendarProvider
  >

  return note.connections?.filter((conn) => crmProviders.includes(conn.sor))
}
