import LuruCrmFieldInputSet from '.'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import update from 'immutability-helper'

interface LuruCrmFieldInputSetEvents {
  onChange: (fieldName: string, value: any) => void
}

export default class LuruCrmFieldInputSetEventHandler {
  #component: LuruCrmFieldInputSet
  handlers: LuruCrmFieldInputSetEvents
  #isMounted: boolean

  constructor(component: LuruCrmFieldInputSet) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount
    this.#component.componentWillUnmount = this.#onComponentUnmount
    this.handlers = {
      onChange: this.#onChange.bind(this),
    }
    this.#isMounted = false
  }

  #onComponentMount = async () => {
    this.#isMounted = true

    if (this.#component.state.status !== EntityStatus.Idle) {
      return
    }

    try {
      if (this.#isMounted) {
        this.#component.setState({
          status: EntityStatus.Loading,
        })
        this.#component.props?.onStatusChange?.(EntityStatus.Loading)
      }

      let schema = await CrmRecord.getObjectSchema(this.#component.props.crmRecordType)

      var fieldValues: Record<string, any> = {}

      if (this.#component.props.sorRecordId) {
        let response = await CrmRecord.getRecordFields(
          this.#component.props.crmRecordType,
          this.#component.props.sorRecordId
        )

        fieldValues = Object.entries(response.payload.record as Record<string, { value: any }>).reduce(
          (acc, [fieldName, { value }]) => ({ ...acc, [fieldName]: value }),
          {}
        )
      }

      if (this.#isMounted) {
        this.#component.setState({
          status: EntityStatus.Loaded,
          schema,
          fieldValues,
        })
        this.#component.props?.onStatusChange?.(EntityStatus.Loaded)
      }
    } catch (e) {
      let error = e as Error

      if (this.#isMounted) {
        this.#component.setState({
          status: EntityStatus.ErrorLoading,
          errorMessage: error.message,
        })
        this.#component.props?.onStatusChange?.(EntityStatus.ErrorLoading)
      }
    }
  }

  #onComponentUnmount = () => {
    this.#isMounted = false
  }

  #onChange = (fieldName: string, value: any) => {
    this.#component.setState((prevState) =>
      update(prevState, {
        fieldValues: {
          [fieldName]: { $set: value },
        },
      })
    )

    if (this.#component.props.onChange) {
      this.#component.props.onChange(fieldName, value)
    }
  }
}
