import { setHomeSearchQuery } from './setHomeSearchQuery'
import { clearHomeSearchQuery } from './clearHomeSearchQuery'
import { setMeetingNotesHomeSearchQuery } from './setMeetingNotesHomeSearchQuery'
import { clearMeetingNotesHomeSearchQuery } from './clearMeetingNotesHomeSearchQuery'
import { setNoteTemplateSearchQuery } from './setNoteTemplateSearchQuery'
import { clearNoteTemplateSearchQuery } from './clearNoteTemplateSearchQuery'
import { setTasksSearchQuery } from './setTasksSearchQuery'
import { clearTasksSearchQuery } from './clearTasksSearchQuery'
import { setMruView } from './setMruView'
import { resetMruView } from './resetMruView'
import { updateAppNotes } from './updateAppNotes'
import { setNoteDetails } from './setNoteDetails'
import { setNoteDetailsChanged } from './setNoteDetailsChanged'

export const AppSliceReducers = {
  setHomeSearchQuery,
  clearHomeSearchQuery,
  setMeetingNotesHomeSearchQuery,
  clearMeetingNotesHomeSearchQuery,
  setNoteTemplateSearchQuery,
  clearNoteTemplateSearchQuery,
  setTasksSearchQuery,
  clearTasksSearchQuery,
  setMruView,
  resetMruView,
  updateAppNotes,
  setNoteDetails,
  setNoteDetailsChanged,
}
