import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NoteLoading from './components/NoteLoading'
import { Messages } from './components/EmbeddedNoteMessages'
import fetchNoteDetails from './reduxHelpers/fetchNoteDetails'
import LoadedNote from './components/LoadedNote'
import Error from '../../primitives/Error'
import MeetingNoteChooser from './components/MeetingNoteChooser'
import EmbeddedNoteCreateDraftNote from './embeddedNote/EmbeddedNoteCreateDraftNote'
import { EntityStatus } from '../../app/types'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

/** Main component for a meeting note loaded as embedded note */
export default function EmbeddedMeetingNoteWrapper({ embeddedNoteId, meetingUrl }) {
  const { meetingUrl: meetingUrlAsParameter } = useParams()

  const { showToast } = useLuruToast()
  if (meetingUrl === undefined) {
    // meetingUrlAsParameter can be passed as undefined, when we are not able to findout a meetingUrl from zoom app
    meetingUrl = meetingUrlAsParameter
  }

  const [calendarApiErrorCode, setCalendarApiErrorCode] = useState(null)
  const dispatch = useDispatch()
  const calendarId = useSelector((state) => state.user?.data?.userSettings?.connectedCalendar?.name ?? null)
  const [componentStatus, setComponentStatus] = useState(
    !calendarId ? 'no-calendar' : embeddedNoteId ? EntityStatus.Loaded : 'awaiting-load'
  )
  const [errorReason, setErrorReason] = useState(null)
  const [noteId, setNoteId] = useState(embeddedNoteId ?? null)
  const [noteList, setNoteList] = useState([])
  const [meetingId, setMeetingId] = useState(null)
  const eventHandlers = {
    onSkip: () => {
      setComponentStatus(EntityStatus.Loading)
      setupNote()
    },
    onFetchNoteSuccess: ({ noteId, meetingId, linkedNotes, error, errorCode, meetingsApiErrorNotice }) => {
      if (error) {
        if (errorCode === 10100 || errorCode === 10104) {
          setComponentStatus('calendar-api-not-reachable')
          setCalendarApiErrorCode(errorCode)
        } else {
          eventHandlers.onFetchNoteError(error)
        }
        return
      }

      if (meetingsApiErrorNotice) {
        showToast({
          id: ToastId.EMBEDDED_NOTE_APP_TOAST_ID,
          message:
            'We could not link your note to this meeting\n' +
            'as we faced an issue accessing your calendar.  Please try\n' +
            'linking this note to this meeting manually.',
          severity: 'warning',
        })
      }

      // Set local state meetingId
      setMeetingId(meetingId)

      if (Array.isArray(linkedNotes) && linkedNotes.length > 1) {
        setNoteList(linkedNotes)
        setComponentStatus('loaded-multiple')
      } else if (Array.isArray(linkedNotes) && linkedNotes.length === 1) {
        setNoteId(linkedNotes[0].note_id)
        setComponentStatus(EntityStatus.Loaded)
      } else {
        setNoteId(noteId)
        setComponentStatus(EntityStatus.Loaded)
      }
    },
    onFetchNoteError: (error) => {
      console.warn(error)
      setErrorReason(error)
      setComponentStatus(EntityStatus.ErrorLoading)
    },
  }
  const setupNote = useCallback(() => {
    fetchNoteDetails({ dispatch, meetingUrl, calendarId })
      .then(eventHandlers.onFetchNoteSuccess)
      .catch(eventHandlers.onFetchNoteError)
  }, [dispatch, meetingUrl, calendarId, eventHandlers.onFetchNoteSuccess, eventHandlers.onFetchNoteError])

  function updateNoteId(noteId) {
    setNoteId(noteId)
  }

  useEffect(() => {
    if (embeddedNoteId) {
      setComponentStatus(EntityStatus.Loaded)
      setMeetingId(undefined)
      return
    }

    if (componentStatus === 'awaiting-load') {
      setComponentStatus(EntityStatus.Loading)
      setupNote()
    }
  }, [embeddedNoteId, componentStatus, setupNote])

  switch (componentStatus) {
    case 'no-calendar':
      return (
        <EmbeddedNoteCreateDraftNote
          renderConnectCalendar={true}
          // onSkipConnectCalendar={eventHandlers.onSkip}
          updateNoteId={updateNoteId}
        />
      )

    case 'calendar-api-not-reachable':
      return (
        <EmbeddedNoteCreateDraftNote
          renderConnectCalendar={true}
          calendarApiError={true}
          calendarApiErrorCode={calendarApiErrorCode}
          // onSkipConnectCalendar={eventHandlers.onSkip}
          updateNoteId={updateNoteId}
        />
      )

    case EntityStatus.ErrorLoading:
      return <Error error={{ message: errorReason }} />

    case 'awaiting-load':
    case EntityStatus.Loading:
      return <NoteLoading />

    case EntityStatus.Loaded:
      return (
        <LoadedNote
          key={`${meetingId}-${noteId}`}
          noteId={noteId}
          meetingId={meetingId}
          calendarId={calendarId}
          meetingUrl={meetingUrl}
          updateNoteId={updateNoteId}
        />
      )

    case 'loaded-multiple':
      return <MeetingNoteChooser noteList={noteList} calendarId={calendarId} />

    default:
      return <>Unknown state</>
  }
}

/** A simple redirect component used by login popup window after login */
export function RedirectToEmbeddedNote(props) {
  const isFromEmbedded = props?.embedded ?? false
  const { meetingUrl, tabId } = useParams()
  // TODO: From URL param meetingId, fetch the noteId to load in notes editor
  useEffect(() => {
    if (isFromEmbedded && tabId) {
      // Set time out is necessary as content script is creating an event
      // listener for this custom event first
      setTimeout(() => {
        document.body.dispatchEvent(
          new CustomEvent(Messages.RedirectEmbeddedNote, {
            detail: { tabId, meetingUrl },
          })
        )
        // Another setTimeout for processing this custom event
        setTimeout(() => window.close(), 500)
      }, 500)
    }
  }, [meetingUrl, isFromEmbedded, tabId])

  return <h1>Signed in, redirecting...</h1>
}

/**
 * A simple placeholder component that closes its own window.
 * Used after signing-in from embedded note
 */
export function CloseWindowAfterLogin() {
  useEffect(() => {
    setTimeout(() => {
      window.close()
    }, 500)
  })

  return <h1>Signed in, redirecting...</h1>
}
