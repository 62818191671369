import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError, { LuruErrorName } from '../../LuruError'
import { CRMProvider } from '../../user/types'
import { UsersAPI } from '../api'
import { ReduxSorUserEntity, ReduxUsersState, SorProvider } from '../types'

export interface GetSorUsersParameter {
  sor?: SorProvider
  include_groups?: boolean
  include_user_admin_tasks?: boolean
  force?: boolean
}

export interface GetSorUsersAPIResponse extends LuruAPIResponse {
  data: Array<ReduxSorUserEntity>
  metadata: {
    count: number
    cursor: string | null
    sor: SorProvider
  } | null
}

export const getSorUsers = {
  action: createAsyncThunk<
    GetSorUsersAPIResponse['data'],
    GetSorUsersParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: GetSorUsersAPIResponse['metadata']
      rejectedMeta: GetSorUsersAPIResponse['metadata']
    }
  >(
    'users/getSorUsers',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var sor = params.sor

        if (!sor) {
          throw new LuruError(LuruErrorName.ApplicationError, 'Trying to fetch users when user is logged out')
        }

        var response = (await UsersAPI.getSorUsers(params, { signal })) as GetSorUsersAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, {
          count: response.metadata ? response.metadata.count : 0,
          cursor: response.metadata ? response.metadata.cursor : null,
          sor,
        })
      } catch (e) {
        return rejectWithValue(
          { message: (e as Error)?.message ?? 'Error retrieving SOR users' },
          { count: 0, cursor: null, sor: sor as CRMProvider }
        )
      }
    },
    {
      condition: (params, { getState }) => {
        var sor = params.sor

        if (!sor) {
          return false
        }

        var state = getState()

        if (state.users[sor]?.status === EntityStatus.Loading) {
          return false
        }

        return true
      },
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getSorUsers.action.pending, (state, action) => {
      var sor = action.meta.arg.sor

      if (sor) {
        if (!state[sor]) {
          state[sor] = {
            status: EntityStatus.Loading,
            entities: {},
          }
        } else {
          state[sor]!.status = EntityStatus.Loading
        }
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getSorUsers.action.fulfilled, (state, action) => {
      var sor = action.meta.sor

      if (!sor) {
        return
      }

      if (!state[sor]) {
        state[sor] = {
          status: EntityStatus.Loaded,
          entities: {},
        }
      }

      state[sor]!.status = EntityStatus.Loaded
      action.payload.forEach(
        (user) =>
          (state[sor]!.entities[user.user_id] = {
            status: EntityStatus.Loaded,
            data: { ...user },
          })
      )
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getSorUsers.action.rejected, (state, action) => {
      var sor = action.meta.rejectedWithValue ? action.meta.sor : null

      if (!sor) {
        return
      }

      state[sor] = {
        status: EntityStatus.ErrorLoading,
        entities: {},
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    getSorUsers.addPendingCase(builder)
    getSorUsers.addFulfilledCase(builder)
    getSorUsers.addRejectedCase(builder)
  },
}
