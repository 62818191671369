// Redux
import { createAsyncThunk } from '@reduxjs/toolkit'

// CRM base APIs
import { crmApi } from '../crmBaseAPI'

// CRM middleware parent object
import { abortAllRequests } from '../../crmMiddleware'
import { EntityStatus } from '../../../../app/types'
import CRMBaseMiddlewareFactory from '../crmBaseMiddleware'

/**
 * @classdesc - Class to manage a CRM's updateRecordField API request
 */
export default class UpdateRecordField {
  #crmId = null
  #requestKey = null
  #middlewareFactory = null

  /**
   * Constructor for this class.  Sets up local variables.  Request key is
   * used as the thunk name for Redux purposes.  It is also used to namespace
   * abort controllers object in CRM middleware.
   * @param {CRMBaseMiddlewareFactory} middlewareFactory - A middleware factory
   * which is going to use this instance
   */
  constructor(middlewareFactory) {
    this.#middlewareFactory = middlewareFactory
    this.#crmId = this.#middlewareFactory.getCrmId()
    this.#requestKey = `${this.#crmId}/updateRecordField`
  }

  /**
   * Function to get the portion of the middleware for managing this particular
   * request.  This will be used by the top-level CRM middleware to compose a
   * large middleware object.
   * @returns {Object} - Middleware to manage this request
   */
  getRequestMiddleware() {
    return {
      key: this.#requestKey,
      action: this.#computeAction(),
      abortAllRequests: this.#computeAbortAllRequests(),
      pending: this.#computePendingState(),
      fulfilled: this.#computeFulfilledState(),
      rejected: this.#computeRejectedState(),
    }
  }

  /**
   * Returns a function to initiate a search in CRM.  The thunk itself expects
   * an object as a parameter with two keys: query and objects, which is an
   * array of object names across which to search
   * @return {Object} - Output of reduxjs/toolkit/createAsyncThunk() function
   */
  #computeAction() {
    let thunk = async (payload, { getState, requestId, rejectWithValue }) => {
      var { fieldName, fieldValue, luruFieldType, sorObjectName, sorRecordId } = payload
      var multiUpdatePayload = {
        [fieldName]: {
          fieldValue,
          luruFieldType,
          completeRecordFields: payload.completeRecordFields,
        },
      }
      var fieldSchemaList = getState().crm?.[this.#crmId]?.schema?.[sorObjectName]?.data?.fields
      var recordUpdatePayloadFieldValueMap = this.#middlewareFactory.prepareFieldSetForUpdate(
        multiUpdatePayload,
        fieldSchemaList
      )
      var currentFieldValues = getState().crm?.[this.#crmId]?.entities?.[sorObjectName]?.[sorRecordId]?.data

      recordUpdatePayloadFieldValueMap = CRMBaseMiddlewareFactory.reviewAndTweakControlledFields(
        fieldSchemaList,
        recordUpdatePayloadFieldValueMap,
        currentFieldValues
      )

      return await crmApi.multiUpdate(
        this.#crmId,
        {
          sorObjectName,
          sorRecordId,
          fields: recordUpdatePayloadFieldValueMap,
        },
        {
          requestId,
          rejectWithValue,
        }
      )
    }

    thunk = thunk.bind(this)

    return createAsyncThunk(this.#requestKey, thunk)
  }

  /**
   * Compute a function to abort requests for this particular request key
   * @returns {Function} - Function to abort all requests for this request
   */
  #computeAbortAllRequests() {
    let abortFunction = () => abortAllRequests(this.#requestKey)
    abortFunction.bind(this)
    return abortFunction
  }

  /**
   * Compute a pending state handler required by Redux for handling this request
   * @returns {Function} - Pending state handler
   */
  #computePendingState() {
    let pendingStateHandler = (state, action) => {
      const updatePayload = action.meta.arg
      const objectName = updatePayload.sorObjectName
      const recordId = updatePayload.sorRecordId
      const crmId = this.#crmId

      // Initialize objects if necessary
      if (state[crmId] === undefined) {
        state[crmId] = {}
      }
      if (state[crmId].schema === undefined) {
        state[crmId].schema = {}
      }
      if (state[crmId].schema[objectName] === undefined) {
        state[crmId].schema[objectName] = {}
      }

      // Check for availability of schema
      let storedSchema = state[crmId].schema[objectName]

      if (storedSchema.status !== EntityStatus.Loaded) {
        state[crmId].schema[objectName] = {
          status: EntityStatus.NotLoaded,
          data: 'null',
        }
      }

      // Check for availability of record (entity) data
      if (state[crmId].entities === undefined) {
        state[crmId].entities = {}
      }
      if (state[crmId].entities[objectName] === undefined) {
        state[crmId].entities[objectName] = {}
      }

      // pending state is called before action, don't update the record if data
      // is already available;
      // TODO: Invalidate this record after a defined TTL (somewhere centrally)
      var availableRecord = state[crmId].entities[objectName][recordId]
      var availableRecordData = availableRecord?.data ?? {}

      state[crmId].entities[objectName][recordId] = {
        requestId: action.meta.requestId,
        status: EntityStatus.Loading,
        data: {
          ...availableRecordData,
          [updatePayload.fieldName]: {
            ...availableRecordData?.[updatePayload.fieldName],
            value: updatePayload.fieldValue,
          },
        },
        error: null,
      }
    }

    pendingStateHandler = pendingStateHandler.bind(this)
    return pendingStateHandler
  }

  /**
   * Compute a fulfilled handler required by Redux for handling this request
   * @returns {Function} - Fulfilled state handler
   */
  #computeFulfilledState() {
    let fulfilledStateHandler = (state, action) => {
      // console.log(`updateRecordField:fulfilled:`, action)
      const updatePayload = action.meta.arg
      const objectName = updatePayload.sorObjectName
      const recordId = updatePayload.sorRecordId
      const crmId = this.#crmId
      const currentRecord = state[crmId]?.entities?.[objectName]?.[recordId] ?? {}
      const storedSchema = state[this.#crmId]?.schema?.[objectName]
      const isSchemaAvailable = storedSchema?.status === EntityStatus.Loaded && storedSchema.data !== null
      const schema = isSchemaAvailable ? storedSchema.data : undefined
      var latestRecordData = action?.payload?.data ?? {}
      var updatedRecordData = this.#middlewareFactory.mergeRecordWithSchema(latestRecordData, schema, objectName)

      state[crmId].entities[objectName][recordId] = {
        ...currentRecord,
        status: EntityStatus.Loaded,
        data: {
          ...updatedRecordData.record,
        },
        userAccess: updatedRecordData.userAccess,
      }
    }

    fulfilledStateHandler = fulfilledStateHandler.bind(this)
    return fulfilledStateHandler
  }

  /**
   * Compute a rejected handler required by Redux for handling this request
   * @returns {Function} - Rejected state handler
   */
  #computeRejectedState() {
    let rejectedStateHandler = (state, error) => {
      if (!error.meta.aborted) {
        const updatePayload = error.meta.arg
        const recordId = updatePayload.sorRecordId
        const objectName = updatePayload.sorObjectName

        state[this.#crmId].entities[objectName][recordId] = {
          ...state[this.#crmId].entities[objectName][recordId],
          requestId: error.meta.requestId,
          status: EntityStatus.ErrorUpdating,
          error: {
            ...error.payload,
            reason: 'field-update-failed',
            fieldName: updatePayload.fieldName,
            fieldValue: updatePayload.fieldValue,
          },
        }
      }
      throw new Error(error?.payload?.message ?? 'Unknown error')
    }

    rejectedStateHandler = rejectedStateHandler.bind(this)

    return rejectedStateHandler
  }
}
