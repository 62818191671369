import { LuruRole } from '@/features/users/types'
import { LuruReduxStore, RootState } from '../../app/store'
import { LuruEntryPoint } from '../../app/types'
import { ReduxCrmKey } from '../../features/crm/types'
import { getUserPolicyFor } from '../../features/user/selectors/getUserPolicy'
import {
  CRMProvider,
  CalendarProvider,
  EmailProvider,
  MessagingProvider,
  UserPolicyEffect,
  UserPolicyType,
} from '../../features/user/types'
import { UsersSliceHelpers } from '../../features/users/helpers'
import Utils from '../../utils/Utils'

export type UserAPIRecord = {
  id: string
  user_id: string
  email?: string
  first_name?: string
  last_name?: string
  joined_at?: string
  last_login?: string
  roles?: Array<string>
  sor_id?: string
}

export default class LuruUser {
  static getDefaultUser() {
    return LuruUser.getCurrentlyLoggedInUser()
  }

  static async getCurrentlyLoggedInUser() {
    let state = LuruReduxStore.getState()
    let userData = state.user.data
    let user = await UsersSliceHelpers.fetchLuruUser(userData.user_id)

    return user
  }

  static getCurrentUserCrmName(state?: RootState): CRMProvider | undefined {
    if (!state) {
      state = LuruReduxStore?.getState()
    }

    var crmName = state?.user?.data?.userSettings?.connectedCRM?.name

    return crmName
  }

  static getCurrentUserCrmCommonName(state?: RootState) {
    if (!state) {
      state = LuruReduxStore?.getState()
    }

    var crmName = state?.user?.data?.userSettings?.connectedCRM?.name

    if (crmName === CRMProvider.SFDC || crmName === CRMProvider.SFDC_SANDBOX) {
      return 'Salesforce'
    }

    if (crmName === CRMProvider.HUBSPOT) {
      return 'HubSpot'
    }

    if (crmName === CRMProvider.PIPEDRIVE) {
      return 'Pipedrive'
    }

    return 'CRM'
  }

  static getCurrentUserReduxCrmKey(state?: RootState) {
    const crmProvider = LuruUser.getCurrentUserCrmName(state)

    return crmProvider?.toLocaleLowerCase?.() as ReduxCrmKey
  }

  static getCurrentSorUserID(): string | undefined {
    let state = LuruReduxStore.getState()
    let userData = state.user.data

    return userData?.userSettings?.connectedCRM?.sor_user?.sor_record_id
  }

  static getCurrentUserCalendarName(state?: RootState): CalendarProvider | undefined {
    if (!state) {
      state = LuruReduxStore.getState()
    }

    return (state?.user?.data?.userSettings?.connectedCalendar?.name ??
      state?.user?.data?.userSettings?.connections?.find((conn: { name: string }) => conn.name === 'O365CAL')
        ?.name) as CalendarProvider
  }

  static getCurrentUserEmailName(state?: RootState): EmailProvider | undefined {
    if (!state) {
      state = LuruReduxStore.getState()
    }
    return (state?.user?.data?.userSettings?.connectedEmail?.name ??
      state?.user?.data?.userSettings?.connections?.find((conn: { name: string }) => conn.name === EmailProvider.O365MAIL || conn.name === EmailProvider.GMAIL)
        ?.name) as EmailProvider
  }

  static isSlackAdminConnected(state?: RootState): boolean {
    if (!state) {
      state = LuruReduxStore.getState()
    }

    // feat-admin-slack: We will return true if user has admin connected to Slack
    return Boolean(
      state?.user?.data?.userSettings?.connections?.find(
        (conn: { name: string; connected_by?: string }) => conn.connected_by === '__luru__slack_admin'
      )
    )
  }

  static getCurrentUserRole(): LuruRole {
    return LuruReduxStore.getState().user.data.userRoles?.find?.((role) => role.name === LuruRole.ADMIN)
      ? LuruRole.ADMIN
      : LuruRole.REGULAR_USER
  }

  static getCurrentUserMessagingProviderName(state?: RootState): MessagingProvider | undefined {
    if (!state) {
      state = LuruReduxStore.getState()
    }

    var connections = (state?.user?.data?.userSettings?.connections ?? []).map((conn) => conn.name)
    // feat-admin-slack: TODO: Find out if user has admin connected to Slack

    if (connections.includes('SLACK')) {
      return MessagingProvider.SLACK
    } else if (connections.includes('MSTEAMS')) {
      return MessagingProvider.MSTEAMS
    } else if (connections.includes('GCHAT')) {
      return MessagingProvider.GCHAT
    }

    return undefined
  }

  static isMicrosoftTeamsConnected() {
    return LuruUser.getCurrentUserMessagingProviderName() === MessagingProvider.MSTEAMS
  }

  static isGChatConnected() {
    return LuruUser.getCurrentUserMessagingProviderName() === MessagingProvider.GCHAT
  }

  static isZoomConnected(state?: RootState) {
    if (!state) {
      state = LuruReduxStore.getState()
    }
    let userData = state?.user?.data ?? {}

    return Boolean(userData.userSettings?.connections?.find((conn: { name: string }) => conn.name === 'ZOOM'))
  }

  static isO365CalConnected() {
    let state = LuruReduxStore.getState()
    let userData = state?.user?.data ?? {}
    return (
      Boolean(userData.userSettings?.connections?.find((conn: { name: string }) => conn.name === 'O365CAL')) ||
      Boolean(LuruUser.getCurrentUserCalendarName() === CalendarProvider.O365CAL)
    )
  }

  static isGCalConnected() {
    return Boolean(LuruUser.getCurrentUserCalendarName() === CalendarProvider.GCAL)
  }

  static isGmailConnected() {
    return Boolean(LuruUser.getCurrentUserEmailName() === EmailProvider.GMAIL)
  }

  static isO365MailConnected() {
    let state = LuruReduxStore.getState()
    let userData = state?.user?.data ?? {}
    return (
      Boolean(userData.userSettings?.connections?.find((conn: { name: string }) => conn.name === EmailProvider.O365MAIL)) ||
      Boolean(LuruUser.getCurrentUserEmailName() === EmailProvider.O365MAIL)
    )
  }


  static isExtensionInstalled() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        var isTabIdPresent = document.body.dataset.luruTabId

        isTabIdPresent ? resolve(true) : reject(false)
      }, 2000)
    })
  }

  static isSlackConnected(state?: RootState): boolean {
    if (!state) {
      state = LuruReduxStore.getState()
    }

    // feat-admin-slack: We will return true if user has admin connected to Slack or user has connected to Slack
    return Boolean(
      state?.user?.data?.userSettings?.connections?.find((conn: { name: string }) => conn.name === 'SLACK')
    )
  }

  static getTenantId() {
    let state = LuruReduxStore.getState()
    return state.user.data.tenant_id
  }

  static getLocale() {
    // TODO: Read this from user data later
    return 'en-US'
  }

  static getCurrentUserDomain() {
    var email = LuruUser.getCurrentUserEmail()

    // If @ is not found, we will return the full email
    return email.slice(email.indexOf('@') + 1)
  }

  static getCurrentUserEmail() {
    var state = LuruReduxStore.getState()
    return state.user.data.email
  }

  static getSearchShortcut(): Promise<string | null | undefined> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const bodyTag = document.querySelector('body[data-luru-extn-shortcut]')
        const searchShortcut = bodyTag?.getAttribute('data-luru-extn-shortcut')
        resolve(searchShortcut)
      }, 3000)
    })
  }

  static getCurrentEntryPoint() {
    if (typeof window === 'undefined') {
      return LuruEntryPoint.BACKGROUND
    }

    if (typeof chrome === 'undefined') {
      // @ts-ignore
      return window?.luru?.entryPoint
    }

    // @ts-ignore
    switch (window?.luru?.entryPoint) {
      // window object is available
      case LuruEntryPoint.WEBAPP:
      case LuruEntryPoint.EMBEDDED_ZOOM_PANEL:
      case LuruEntryPoint.EMBEDDED_ZOOM_MEETING:
        // @ts-ignore
        return window?.luru?.entryPoint

      default:
        // @ts-ignore
        switch (chrome?.luru?.entryPoint) {
          case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
          case LuruEntryPoint.GLOBALSEARCH:
          case LuruEntryPoint.NEWTAB:
          case LuruEntryPoint.OPTIONS:
            // @ts-ignore
            return chrome?.luru?.entryPoint
        }
    }

    return chrome.runtime === undefined ? LuruEntryPoint.WEBAPP : LuruEntryPoint.BACKGROUND
  }

  static isWebappContext() {
    return [LuruEntryPoint.WEBAPP, LuruEntryPoint.EMBEDDED_ZOOM_PANEL, LuruEntryPoint.EMBEDDED_ZOOM_MEETING].includes(
      LuruUser.getCurrentEntryPoint()
    )
  }

  static getTenantDomain() {
    var state = LuruReduxStore?.getState()

    if (state?.user?.extensionSettings?.tenantDomain) {
      return state?.user?.extensionSettings?.tenantDomain
    }

    var baseUrlCookieValue = Utils.getCookieValue('base_url')

    if (baseUrlCookieValue) {
      return `https://${baseUrlCookieValue}/`
    }

    return ''
  }

  //Policy related static functions
  static getUserPolicies() {
    let state = LuruReduxStore.getState()
    let userPolicies = state?.user?.data?.userPolicies || []

    return userPolicies
  }

  static getUserPolicyFor(policyFor: UserPolicyType) {
    let state = LuruReduxStore.getState()
    let userPolicy = getUserPolicyFor(state, policyFor)

    return userPolicy
  }

  static isUserHasRTWorkflowCreationPolicy() {
    let state = LuruReduxStore.getState()
    let userPolicy = getUserPolicyFor(state, UserPolicyType.WORKFLOW)
    return userPolicy?.effect === UserPolicyEffect.ALLOW
  }
}
