import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod, LuruAPIResponse } from '../../../app/types'
import { DateUtils } from '@/utils/dateUtils'

export interface SearchWorkflowReportsSummaryGroupByWorkflowParameter {
  time_min: Date
  time_max: Date
}

export interface WorkflowReportSummary {
  name: string
  num_runs: number
  num_alerts: number
  num_records: number
  num_interactions: number
}

export interface SearchWorkflowReportsSummaryGroupedResponse extends LuruAPIResponse {
  data: Record<string, WorkflowReportSummary>
}

export const searchWorkflowReportsSummaryGroupByWorkflow = async (
  params: SearchWorkflowReportsSummaryGroupByWorkflowParameter,
  options?: LuruAPIRequestOptions
) => {
  var urlParams: Record<string, any> = { ...params }

  if (urlParams.time_min) {
    urlParams.time_min = DateUtils.toISOStringCurrentTZ(params.time_min)
  }

  if (urlParams.time_max) {
    urlParams.time_max = DateUtils.toISOStringCurrentTZ(params.time_max)
  }

  var queryString = new URLSearchParams(urlParams as URLSearchParams)
  var request = new LuruAPIRequest(`/api/workflows/reports/summary?${queryString}`, HTTPMethod.GET, options)

  return request.make()
}
