import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { FetchMeetingParameter } from '../middleware/fetchMeeting'

export const fetchMeeting = (
  { meetingId: meeting_id, include_notes }: Readonly<FetchMeetingParameter>,
  options: LuruAPIRequestOptions
) => {
  var getMettingRequest = new LuruAPIRequest(`/api/meetings/${meeting_id}`, HTTPMethod.GET, options)

  var getMettingNotesRequest = new LuruAPIRequest(`/api/meetings/${meeting_id}/notes`, HTTPMethod.GET, options)

  // Since backend is not supporting include_notes query params for /api/meetings/<meeting_id> api, we are doing seperate api calls to get the notes for now
  // TODO: Backend should support it like it supports for ListMeetings endpoint
  return Promise.all([getMettingRequest.make(), ...(include_notes ? [getMettingNotesRequest.make()] : [])]).then(
    ([meetingRes, notesRes]) => {
      if (include_notes && notesRes) {
        meetingRes.data.notes = notesRes.data || []
      }
      return meetingRes
    }
  )
}
