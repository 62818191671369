import { Middleware } from '@reduxjs/toolkit'
import { RootState } from '../store'

// Middleware to dispatch actions as messages to background script in the
// context of extension
export const extensionMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  // We send the action to background store only if the current action has been
  // initiated from within this app.  For broadcast actions (whose __source is
  // set to 'extension'), we skip this step.
  if (action?.__source === undefined) {
    chrome.runtime.sendMessage({
      messageName: 'reduxTransmissionFromExtnAppToWorker',
      payload: action,
    })
  }

  return next(action)
}
