import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DeleteCollectionParameter } from '../middleware/deleteCollection'

export const deleteCollection = async (
  params: Readonly<DeleteCollectionParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/collections/${params.collectionId}`,
    HTTPMethod.DELETE,
    options
  )

  return request.make()
}
