import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { UsersAPI } from '../api'
import { LuruUserRole, ReduxUsersState } from '../types'

export interface UpdateLuruUserRolesParameter {
  userId: string
  roleNames: Array<string>
}

export interface UpdateLuruUserRolesAPIResponse extends LuruAPIResponse {
  data: Array<LuruUserRole>
}

export const updateLuruUserRoles = {
  action: createAsyncThunk<
    UpdateLuruUserRolesAPIResponse['data'],
    UpdateLuruUserRolesParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: UpdateLuruUserRolesAPIResponse['metadata']
      rejectedMeta: UpdateLuruUserRolesAPIResponse['metadata']
    }
  >('users/updateLuruUserRoles', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await UsersAPI.updateLuruUserRoles(params, {
        signal,
      })) as UpdateLuruUserRolesAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue({ message: (e as Error)?.message ?? 'Error updating Luru user roles' }, null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(updateLuruUserRoles.action.pending, (state, action) => {
      const userId = action.meta.arg.userId
      if (!state.LURU.entities[userId]) {
        return
      }
      state.LURU.entities[userId].status = EntityStatus.Updating
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(updateLuruUserRoles.action.fulfilled, (state, action) => {
      const userId = action.meta.arg.userId
      if (!state.LURU.entities[userId]) {
        return
      }
      state.LURU.entities[userId].status = EntityStatus.Loaded
      state.LURU.entities[userId].data.roles = [...action.payload]
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(updateLuruUserRoles.action.rejected, (state, action) => {
      const userId = action.meta.arg.userId
      if (!state.LURU.entities[userId]) {
        return
      }
      state.LURU.entities[userId].status = EntityStatus.ErrorUpdating
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    updateLuruUserRoles.addPendingCase(builder)
    updateLuruUserRoles.addFulfilledCase(builder)
    updateLuruUserRoles.addRejectedCase(builder)
  },
}
