import json5 from 'json5'
import { LuruReduxStore } from '../app/store'
import { LuruEntryPoint } from '../app/types'
import LuruUser from '../domain/users/LuruUser'

export function scrollYToElement(element, container) {
  // Scrolling if required
  let bottomOverflow =
      element.offsetTop + element.clientHeight - (container.offsetTop + container.clientHeight + container.scrollTop),
    topOverflow = container.offsetTop + container.scrollTop - element.offsetTop

  if (bottomOverflow > 0) {
    container.scrollBy({
      top: bottomOverflow,
      behavior: 'instant',
    })
  } else if (topOverflow > 0) {
    container.scrollBy({
      top: -topOverflow,
      behavior: 'instant',
    })
  }
}

/**
 * Measure text length from start of the note element (rootNode) until
 * the given container and offset (typically got from getSelection())
 * @param {HTMLElement} rootNode - Element to start measuring length from
 * @param {HTMLNode} container - Container of a range object
 * @param {number} offset Offset from a range object
 * @returns { text, length } - String and string length until caret position
 */
export function getTextDetailsUntil(rootNode, container, offset) {
  if (!container) {
    return 0
  }

  let length = 0
  let curNode = container
  let isPrevNodeChild = false
  let text = ''

  while (curNode !== rootNode && curNode) {
    if (curNode.nodeType === 3) {
      // Text node
      length += curNode === container ? offset : curNode.textContent.length
      text = (curNode === container ? curNode.textContent.slice(0, offset) : curNode.textContent) + text
    }
    // Assuming element node
    if (!isPrevNodeChild && curNode.lastChild) {
      curNode = curNode.lastChild
    } else if (curNode.previousSibling) {
      curNode = curNode.previousSibling
      isPrevNodeChild = false
    } else {
      curNode = curNode.parentElement
      isPrevNodeChild = true
    }
  }
  return { length, text }
}

/**
 * Calculate the fully qualified key of a keyboard event taking into account
 * the modifier keys pressed.  e.g.: 'CtrlAltShiftPageDown', 'CtrlEnter', etc.
 * @param {KeyboardEvent} e - A keyboard event fired by browser
 * @returns {string} - String identifying which key was pressed
 */
export function getFullyQualifiedKey(e) {
  const keyCombinations = {
    ctrlKey: 'Ctrl',
    altKey: 'Alt',
    shiftKey: 'Shift',
  }

  if (navigator.userAgent.match('Mac')) {
    keyCombinations.metaKey = 'Cmd'
  }

  return (
    Object.entries(keyCombinations).reduce(
      (prevResult, [modifierKey, modifierName]) => prevResult + (e[modifierKey] ? modifierName : ''),
      ''
    ) + (e.key.length === 1 ? e.key.toUpperCase() : e.key)
  )
}

/**
 * Check if a given keyboard event will trigger an edit in the event's target
 * @param {KeyboardEvent} e - Check if a keyboard event will lead to an edit
 * @returns {Boolean} - true if event will lead to edit
 */
export function isEditEvent(e) {
  const editKeys = ['Backspace', 'Enter', 'Delete']
  const nonEditKeys = ['CtrlS', 'CtrlShiftS', 'CtrlA', 'CtrlC', 'CtrlShiftA', 'CtrlShiftC']
  const fullKey = getFullyQualifiedKey(e)
  let result = (e.key.length === 1 || editKeys.includes(e.key)) && !nonEditKeys.includes(fullKey)
  return result
}

/**
 * Check if two JSON strings represent equivalent objects
 * @param {string} s1 - JSON string 1
 * @param {string} s2 - JSON string 2
 * @return {Boolean} - true if both JSON strings represent equivalent objects
 */
export function compareJSONStrings(s1, s2) {
  try {
    let obj1 = json5.parse(s1)
    let obj2 = json5.parse(s2)
    let normalizedJson1 = json5.stringify(obj1)
    let normalizedJson2 = json5.stringify(obj2)
    return normalizedJson1 === normalizedJson2
  } catch (e) {
    return false
  }
}

export function getAuthUrl() {
  var url = new URL(window.location.href)
  var hostname = url.protocol.startsWith('http') ? url.hostname : LuruUser.getTenantDomain()

  if (!hostname) {
    hostname =
      LuruReduxStore.getState()?.user?.extensionSettings?.webServer ??
      // eslint-disable-next-line
      (typeof chrome !== 'undefined' && chrome.luru?.config?.webServer)
  }

  if (
    [
      LuruEntryPoint.EMBEDDED_GMEET_NOTE,
      LuruEntryPoint.GLOBALSEARCH,
      LuruEntryPoint.NEWTAB,
      LuruEntryPoint.OPTIONS,
      LuruEntryPoint.BACKGROUND,
    ].includes(LuruUser.getCurrentEntryPoint()) &&
    !hostname
  ) {
    // eslint-disable-next-line
    hostname = typeof chrome !== 'undefined' && chrome.luru?.config?.webServer
  }

  // console.log(
  //   'getAuthUrl:webServer:',
  //   // eslint-disable-next-line
  //   typeof chrome !== 'undefined' && chrome?.luru?.config?.webServer
  // )

  // console.log(
  //   'getAuthUrl:user.extensionSettings:',
  //   JSON.stringify(LuruReduxStore.getState()?.user?.extensionSettings, null, 2)
  // )

  if (!hostname) {
    console.log('Could not get hostname at all')
    hostname = ''
  }

  var result =
    hostname.includes('looru.io') || hostname.endsWith('ngrok-free.app')
      ? 'https://auth.looru.io:10000'
      : hostname.includes('looru.ai')
      ? 'https://auth.looru.ai'
      : 'https://auth.luru.app'

  return result
}

export function getBaseUrl() {
  var url = new URL(window.location.href)
  var hostname = url.protocol.startsWith('http') ? url.hostname : LuruUser.getTenantDomain()

  if (!hostname) {
    hostname =
      LuruReduxStore.getState()?.user?.extensionSettings?.webServer ??
      // eslint-disable-next-line
      (typeof chrome !== 'undefined' && chrome.luru?.config?.webServer)
  }

  if (
    [
      LuruEntryPoint.EMBEDDED_GMEET_NOTE,
      LuruEntryPoint.GLOBALSEARCH,
      LuruEntryPoint.NEWTAB,
      LuruEntryPoint.OPTIONS,
      LuruEntryPoint.BACKGROUND,
    ].includes(LuruUser.getCurrentEntryPoint()) &&
    !hostname
  ) {
    // eslint-disable-next-line
    hostname = typeof chrome !== 'undefined' && chrome.luru?.config?.webServer
  }

  if (!hostname) {
    console.log('Could not get hostname at all')
    hostname = ''
  }

  var result =
    hostname.includes('looru.io') || hostname.endsWith('ngrok-free.app')
      ? 'https://my.looru.io:3000'
      : hostname.includes('looru.ai')
      ? 'https://my.looru.ai'
      : 'https://my.luru.app'

  return result
}
