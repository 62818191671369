import React from 'react'
import { EntityStatus, LuruEntryPoint } from '../../app/types'
import { LuruAppErrorCode } from '../../features/user/types'
import LuruUser from '../../domain/users/LuruUser'
import ModalScreen from '../ModalScreen'
import LuruButton from '../ui/LuruButton'

export interface LuruAppErrorProps {
  errorCode: LuruAppErrorCode | undefined
}

interface LuruAppErrorState {
  remainingTime: number
  refreshStatus: EntityStatus
}

export default class LuruAppError extends React.Component<LuruAppErrorProps, LuruAppErrorState> {
  props: LuruAppErrorProps
  state: LuruAppErrorState
  timer: NodeJS.Timer | null
  #isZoomEntryPoint: boolean

  constructor(props: LuruAppErrorProps) {
    super(props)
    this.props = props
    this.state = {
      remainingTime: 15,
      refreshStatus: EntityStatus.Idle,
    }
    this.timer = null
    this.#isZoomEntryPoint =
      LuruUser.getCurrentEntryPoint() === LuruEntryPoint.EMBEDDED_ZOOM_MEETING ||
      LuruUser.getCurrentEntryPoint() === LuruEntryPoint.EMBEDDED_ZOOM_PANEL
    this.reload = this.reload.bind(this)
  }

  async reload() {
    this.setState({ refreshStatus: EntityStatus.Loading })

    if (LuruUser.isWebappContext()) {
      window.location.reload()
    } else {
      var response =
        typeof chrome !== 'undefined' &&
        (await chrome?.runtime.sendMessage({
          messageName: 'refreshReduxState',
        }))
      // console.log('Received response from background script:', response)

      if (!response?.success) {
        alert('There was a problem fetching data from server.  Please check your connection again.')
        console.log('Error fetching data from server, details:', JSON.stringify(response?.details ?? {}, null, 2))
      }

      this.setState({ refreshStatus: EntityStatus.Idle })
    }
  }

  render() {
    if (!this.props.errorCode) {
      return null
    }

    if (this.timer) {
      clearInterval(this.timer)
    }

    if (this.props.errorCode === LuruAppErrorCode.AUTHORIZATION_ERROR && !this.#isZoomEntryPoint) {
      this.timer = setInterval(() => this.#countDown(), 1000)
    }

    return (
      <ModalScreen
        id='luru-app-error'
        // title='Application error'
        titleCloseButton={false}
        width='60%'
        height='300px'
        hideButtons={true}
        doShow={true}
        centered={true}
        dialogRole='luru-app-error'
        parentClassName='luru-app-error'
      >
        {this.#renderError()}
      </ModalScreen>
    )
  }

  #renderError() {
    switch (this.props.errorCode) {
      case LuruAppErrorCode.NETWORK_ERROR:
        return this.#renderNetworkError()

      case LuruAppErrorCode.AUTHORIZATION_ERROR:
        return this.#renderAuthorizationError()
    }
  }

  #renderNetworkError() {
    return (
      <>
        <p>It appears you are offline now. Please ensure your Internet connection is online.</p>
        <p>This message should disappear when your Internet connection is online.</p>
        <p>If not, click on Refresh when your network connection is active.</p>
        <LuruButton
          title='Refresh'
          onClick={() => this.reload()}
          loading={this.state.refreshStatus === EntityStatus.Loading}
          disabled={this.state.refreshStatus === EntityStatus.Loading}
        >
          Refresh
        </LuruButton>
      </>
    )
  }

  #renderAuthorizationError() {
    return (
      <>
        <p>There was an authorization error while accessing your records. Please sign in again to continue.</p>
        {this.#isZoomEntryPoint ? (
          <p>
            Please visit <strong>{LuruUser.getTenantDomain()}</strong> to sign in
          </p>
        ) : (
          <>
            <p>Redirecting to login page in {this.state.remainingTime} seconds...</p>
            <LuruButton
              title='Sign out'
              onClick={() => this.#handleSignOut()}
              loading={this.state.refreshStatus === EntityStatus.Loading}
              disabled={this.state.refreshStatus === EntityStatus.Loading}
            >
              Sign out now
            </LuruButton>
          </>
        )}
      </>
    )
  }

  #handleSignOut() {
    window.location.href = (LuruUser.isWebappContext() ? '' : LuruUser.getTenantDomain()) + '/user/logout'
  }

  #countDown() {
    if (this.#isZoomEntryPoint) {
      return
    }

    if (this.state.remainingTime === 0) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.#handleSignOut()
      return
    }

    this.setState({ remainingTime: this.state.remainingTime - 1 })
  }
}
