import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateNoteConnectionAPIResponse,
  CreateNoteConnectionParameter,
} from '../../notes/middleware/createNoteConnection'
import { CalendarProvider, CRMProvider } from '../../user/types'
import { ReduxMeetingsState } from '../types'

export const notesSliceCreateNoteConnection: CaseReducer<
  ReduxMeetingsState,
  PayloadAction<
    CreateNoteConnectionAPIResponse['data'],
    string,
    CreateNoteConnectionAPIResponse['metadata'] & {
      arg: CreateNoteConnectionParameter
      requestId: string
      requestStatus: 'fulfilled'
    }
  >
> = (state, action) => {
  var note = action.payload
  var calProviders = [CalendarProvider.GCAL, CalendarProvider.O365CAL] as Array<CRMProvider | CalendarProvider>

  if (!state.entities || !note.note_id || !calProviders.includes(action.meta.arg.sorObject.sor)) {
    return
  }

  var meetingId = action.meta.arg.sorObject.sor_record_id
  if (meetingId in state.entities && state.entities[meetingId]?.data) {
    state.entities[meetingId].data.notes = [...(state.entities[meetingId].data.notes ?? []), note]
  }
}
