import { createSlice } from '@reduxjs/toolkit'
import { EmailSyncsSliceReducer as reducers } from './reducers'
import { EmailSyncsMiddleware } from './middleware'
import { EmptyEmailSyncsState } from './types'

const emailsyncsSlice = createSlice({
  name: 'emailsyncs',
  initialState: EmptyEmailSyncsState,
  reducers,
  extraReducers: (builder) => {
    EmailSyncsMiddleware.connectGmailSync.addAllCases(builder)
    EmailSyncsMiddleware.disconnectGmailSync.addAllCases(builder)
    EmailSyncsMiddleware.connectO365MailSync.addAllCases(builder)
    EmailSyncsMiddleware.disconnectO365MailSync.addAllCases(builder)

  },
})

export default emailsyncsSlice.reducer

export const EmailSyncsSliceActions = emailsyncsSlice.actions
