import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'

export interface FetchWorkflowParameter {
  workflow_id: string
}

export const fetchWorkflow = async (
  params: FetchWorkflowParameter,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/workflows/${params.workflow_id}`,
    HTTPMethod.GET,
    options
  )

  return request.make()
}
