import React from 'react'
import { CrmRecordSchema, CrmRecordType } from '../../../../../../domain/crmRecord/typings.d'
import CrmMultiObjectFieldChooserEventHandler from '../../CrmMultiObjectFieldChooserEventHandler'
import styles from './styles.module.css'
import { CrmMultiObjectFieldChooserRefs } from '../../CrmMultiObjectFieldChooserComponent'
import CrmRecord from '../../../../../../domain/crmRecord/CrmRecord'
import { EntityStatus } from '../../../../../../app/types'
// Icons
import donotDisturbIcon from '../../../../../../images/fluent/donot_disturb.svg'
import refreshIcon from '../../../../../../images/fluent/refresh.svg'
import { CRMProvider } from '../../../../../../features/user/types'
import LuruUser from '../../../../../../domain/users/LuruUser'

export type SelectedFieldsListProps = {
  selectedItems: Array<string>
  mandatoryFields?: Array<string>
  crmRecordType: CrmRecordType
  eventHandler: CrmMultiObjectFieldChooserEventHandler
  globalComponentRefs: CrmMultiObjectFieldChooserRefs
  disabled?: boolean
  enableToggleMandatory?: boolean
  schema: {
    [key: string]: {
      data: CrmRecordSchema
      status: EntityStatus
    }
  }
}

export type SelectedFieldsListState = {}

export interface SelectedFieldsListEvents {}

export default class SelectedFieldsList extends React.Component<SelectedFieldsListProps, SelectedFieldsListState> {
  props: SelectedFieldsListProps
  state: SelectedFieldsListState
  handlers: SelectedFieldsListEvents
  dragStartPosition: number
  dragStartElement: HTMLLIElement | null
  dragEndPosition: number | null
  dragOversLIElements: Array<{ element: HTMLLIElement; position: number }>
  #crmProvider: CRMProvider | undefined
  constructor(props: SelectedFieldsListProps) {
    super(props)
    this.props = props
    this.state = {}
    this.handlers = {}
    this.dragStartPosition = 0
    this.dragStartElement = null
    this.dragEndPosition = null
    this.dragOversLIElements = []
    this.#crmProvider = LuruUser.getCurrentUserCrmName()
  }

  onDragStart = (e: React.DragEvent<HTMLLIElement>, position: number) => {
    this.dragStartPosition = position
    this.dragStartElement = e.currentTarget
    e.currentTarget?.classList.add(styles.beingGrabbed)
  }

  onDragEnter = (e: React.DragEvent<HTMLLIElement>, position: number) => {
    this.dragEndPosition = position
  }

  onDragOver = (e: React.DragEvent<HTMLLIElement>, position: number) => {
    e.preventDefault()
    this.dragEndPosition = position
    var fIndex = this.dragOversLIElements.findIndex((f) => f.position === position)
    if (fIndex === -1) {
      this.dragOversLIElements.push({
        element: e.currentTarget,
        position: position,
      })
    }
    if (position !== this.dragStartPosition) {
      e.currentTarget?.classList.add(styles.beingDraggedOver)
    }
  }

  onDragLeave = (e: React.DragEvent<HTMLLIElement>, position: number) => {
    this.dragEndPosition = null
    this.dragOversLIElements = this.dragOversLIElements.filter((f) => f.position !== position)
    e.currentTarget?.classList.remove(styles.beingDraggedOver)
  }
  onDrop = (e: React.DragEvent<HTMLLIElement>) => {
    //Not Ended within our drop zone
    if (this.dragEndPosition === null) {
      this.dragStartElement?.classList?.remove?.(styles.beingGrabbed)
      this.dragStartPosition = 0
      this.dragEndPosition = null
      this.dragOversLIElements = []
      this.dragStartElement = null
      return
    }
    const { selectedItems } = this.props
    const copyListItems = [...selectedItems]
    const dragItemContent = copyListItems[this.dragStartPosition]
    copyListItems.splice(this.dragStartPosition, 1)
    copyListItems.splice(this.dragEndPosition, 0, dragItemContent)
    this.props?.eventHandler?.handlers?.onReorderSelectedFields?.(copyListItems)
    this.dragOversLIElements?.forEach?.((i) => {
      i?.element.classList.remove(styles.beingDraggedOver)
    })
    this.dragStartElement?.classList?.remove?.(styles.beingGrabbed)
    this.dragStartPosition = 0
    this.dragEndPosition = null
    this.dragOversLIElements = []
    this.dragStartElement = null
  }

  onFieldToggle(targetFieldName: string) {
    const { eventHandler, globalComponentRefs } = this.props
    eventHandler.handlers?.toggleFieldSelection?.(targetFieldName)
    // Focus searchbox and select text inside it
    this.props.globalComponentRefs.availableFiledFilterBox?.current?.focus?.()
    globalComponentRefs.availableFiledFilterBox?.current?.select?.()
  }

  render() {
    const { schema, selectedItems, crmRecordType, eventHandler, globalComponentRefs, enableToggleMandatory } =
      this.props
    const sorObjectName = CrmRecord.getCrmRecordName(crmRecordType)
    const recordsTypes = schema?.[crmRecordType]?.data?.filter?.((f) => f.referencedObject) || []
    return (
      <ul className={[styles.selectedFieldsContainer].join(' ')} ref={globalComponentRefs.selectedFiledsList}>
        {selectedItems.map((f, index) => {
          const fields = f.split('.')
          var recordName = ''
          var fieldName = <span></span>
          var entitiyStatus: EntityStatus = EntityStatus.Loading
          if (fields.length === 1) {
            recordName = sorObjectName
            entitiyStatus = schema?.[crmRecordType]?.status
            fieldName =
              schema?.[crmRecordType]?.status === EntityStatus.ErrorLoading ? (
                <span
                  className={styles.errorType2SchemaLoading}
                  onClick={(e) => {
                    eventHandler.handlers.loadSchema(crmRecordType)
                    e.stopPropagation()
                  }}
                  title='Reload'
                >
                  Error
                  <img src={refreshIcon} alt='refresh' />
                </span>
              ) : schema?.[crmRecordType]?.status === EntityStatus.Loading ? (
                <span>Loading...</span>
              ) : (
                <span>{schema?.[crmRecordType]?.data?.find((f) => f.name === fields[0])?.label || ''}</span>
              )
          } else if (fields.length === 2) {
            recordName = recordsTypes.find((f) => f.name === fields[0])?.label || ''
            const referencedObject = recordsTypes.find((f) => f.name === fields[0])?.referencedObject
            const currentRecordType =
              CrmRecord.getCrmRecordType(referencedObject || '') ||
              referencedObject ||
              CrmRecord.getAllPrimaryObjects().DEAL
            entitiyStatus = schema?.[currentRecordType]?.status
            fieldName =
              schema?.[currentRecordType]?.status === EntityStatus.ErrorLoading ? (
                <span
                  className={styles.errorType2SchemaLoading}
                  onClick={(e) => {
                    eventHandler.handlers.loadSchema(currentRecordType)
                    e.stopPropagation()
                  }}
                  title='Reload'
                >
                  Error
                  <img src={refreshIcon} alt='refresh' />
                </span>
              ) : schema?.[currentRecordType]?.status === EntityStatus.Loading ? (
                <span>Loading...</span>
              ) : (
                <span>{schema?.[currentRecordType]?.data?.find((f) => f.name === fields[1])?.label || ''}</span>
              )
          }
          return (
            <li
              key={f}
              draggable='true'
              onDragStart={(e) => this.onDragStart(e, index)}
              onDragEnter={(e) => this.onDragEnter(e, index)}
              onDragOver={(e) => this.onDragOver(e, index)}
              onDragLeave={(e) => this.onDragLeave(e, index)}
              onDragEnd={(e) => this.onDrop(e)}
            >
              <button className={[styles.fieldItem].join(' ')}>
                <span className={styles.drag}></span>
                <label title={f}>
                  <b style={{ fontWeight: 'bold' }}>{recordName}:</b> {fieldName}{' '}
                </label>
                {enableToggleMandatory && (
                  <span
                    data-mandatory={this.props.mandatoryFields?.includes(f)}
                    className={[styles.mandatory, styles.mandatoryToggleIcon].join(' ')}
                    title={
                      this.props.mandatoryFields?.includes(f)
                        ? `Click here to make ${
                            this.#crmProvider === CRMProvider.HUBSPOT ? 'property' : 'field'
                          } optional`
                        : `Click here to make ${
                            this.#crmProvider === CRMProvider.HUBSPOT ? 'property' : 'field'
                          } mandatory`
                    }
                    onClick={() => {
                      if (enableToggleMandatory) {
                        entitiyStatus !== EntityStatus.Loading &&
                          entitiyStatus !== EntityStatus.ErrorLoading &&
                          eventHandler.handlers.toggleMandatoryFieldSelection(f)
                      }
                    }}
                  >
                    *
                  </span>
                )}
                <img
                  src={donotDisturbIcon}
                  alt='remove'
                  className={styles.removedIcon}
                  title='Remove'
                  onClick={() =>
                    entitiyStatus !== EntityStatus.Loading &&
                    entitiyStatus !== EntityStatus.ErrorLoading &&
                    this.onFieldToggle(f)
                  }
                />
              </button>
            </li>
          )
        })}
      </ul>
    )
  }
}
