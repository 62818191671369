import { createTask } from './createTask'
import { fetchTask } from './fetchTask'
import { updateTask } from './updateTask'
import { deleteTask } from './deleteTask'
import { listOrSearchTasks } from './listOrSearchTasks'
import { listOrSearchAssignees } from './listOrSearchAssignees'

export const TasksMiddleware = {
  createTask,
  fetchTask,
  updateTask,
  deleteTask,
  listOrSearchTasks,
  listOrSearchAssignees,
}
