import { addMeetingNotes } from './addMeetingNotes'
import { getNewNoteContent } from './getNewNoteContent'
import { getNoteTextContent } from './getNoteTextContent'
import { getMatchingNoteTemplates } from './getMatchingNoteTemplates'
import { getCrmConnections } from './getCrmConnections'
import { getMatchingCrmRecords } from './getMatchingCrmRecords'
import { isNoteDraft } from './isNoteDraft'

export const NotesSliceHelpers = {
  addMeetingNotes,
  getNewNoteContent,
  getNoteTextContent,
  getMatchingNoteTemplates,
  getCrmConnections,
  getMatchingCrmRecords,
  isNoteDraft,
}
