import { getSortedInfo } from './getSortedInfo'
import { getAllFieldsDisplayInfo } from './getAllFieldsDisplayedInfo'
import { getDisplayInfo } from './getDisplayInfo'
import { computeDisplaySettingsOnFieldOrderChange } from './computeDisplaySettingsOnFieldOrderChange'
import { computeDisplaySettingsWithNewFieldOrder } from './computeDisplaySettingsWithNewFieldOrder'
import { fetchAllViews } from './fetchAllViews'

export const ViewHelpers = {
  getSortedInfo,
  getAllFieldsDisplayInfo,
  getDisplayInfo,
  computeDisplaySettingsOnFieldOrderChange,
  computeDisplaySettingsWithNewFieldOrder,
  fetchAllViews,
}
