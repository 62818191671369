import sfdcAccountIcon from '../images/sfdc-account.png'
import sfdcLeadIcon from '../images/sfdc-lead.png'
import sfdcContactIcon from '../images/sfdc-contact.png'
import sfdcOpportunityIcon from '../images/sfdc-opportunity.png'
import hubspotCompanyIcon from '../images/fluent/building_shop.svg'
import hubspotContactIcon from '../images/fluent/contact_card.svg'
import hubspotDealIcon from '../images/money_square.svg'
import pipedriveOrganizationIcon from '../images/pipedrive-organization.png'
import pipedrivePersonIcon from '../images/pipedrive-person.png'
import pipedriveDealIcon from '../images/pipedrive-deal.png'
import pipedriveLeadIcon from '../images/pipedrive-lead.png'
import meetingIcon from '../images/fluent/calendar_ltr.svg'
import taskIcon from '../images/fluent/task_list_square_ltr.svg'
import useCrmName from '@/features/crm/hooks/useCrmName'
import { CRMProvider } from '@/features/user/types'

type LowerCaseCrmName = 'sfdc' | 'hubspot' | 'pipedrive'
export const crmIcons: Record<LowerCaseCrmName, Record<string, string>> = {
  sfdc: {
    account: sfdcAccountIcon,
    lead: sfdcLeadIcon,
    contact: sfdcContactIcon,
    opportunity: sfdcOpportunityIcon,
    meeting: meetingIcon,
    task: taskIcon,
  },
  hubspot: {
    deals: hubspotDealIcon,
    contacts: hubspotContactIcon,
    companies: hubspotCompanyIcon,
    meetings: meetingIcon,
  },
  pipedrive: {
    deal: pipedriveDealIcon,
    lead: pipedriveLeadIcon,
    person: pipedrivePersonIcon,
    organization: pipedriveOrganizationIcon,
    meeting: meetingIcon,
  },
}

export function CrmIcon({ objectName }: { objectName: string | undefined }) {
  const crmName = useCrmName()
  const crmKey =
    crmName === CRMProvider.SFDC_SANDBOX || crmName === CRMProvider.SFDC
      ? 'sfdc'
      : crmName === CRMProvider.HUBSPOT
      ? 'hubspot'
      : crmName === CRMProvider.PIPEDRIVE
      ? 'pipedrive'
      : undefined

  if (!crmKey) {
    return null
  }

  return objectName ? <img src={crmIcons[crmKey][objectName]} alt={`${crmName} ${objectName}`} /> : null
}
