import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { WorkflowsAPI } from '../api'
import { ReduxWorkflowsState, ReduxWorkflowEntity, CreateWorkflowParameter } from '../types'

export interface CreateWorkflowAPIResponse extends LuruAPIResponse {
  data: ReduxWorkflowEntity
}

export const createWorkflow = {
  action: createAsyncThunk<
    CreateWorkflowAPIResponse['data'],
    CreateWorkflowParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: CreateWorkflowAPIResponse['metadata']
      rejectedMeta: CreateWorkflowAPIResponse['metadata']
    }
  >('workflows/createWorkflow', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await WorkflowsAPI.createWorkflow(params, {
        signal,
      })) as CreateWorkflowAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue({ message: (e as Error)?.message ?? 'Error creating alert' }, null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(createWorkflow.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(createWorkflow.action.fulfilled, (state, action) => {
      const workflow = action.payload

      state.entities[workflow.workflow_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...workflow,
          sor_object_name: CrmRecord.getCrmRecordType(workflow.sor_object_name, workflow.sor),
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(createWorkflow.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    createWorkflow.addPendingCase(builder)
    createWorkflow.addFulfilledCase(builder)
    createWorkflow.addRejectedCase(builder)
  },
}
