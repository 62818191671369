import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { GetAllObjectsParameter } from '../middleware/getAllObjects'

export const getAllObjects = ({ crmKey, isVerbose }: GetAllObjectsParameter, options?: LuruAPIRequestOptions) => {
  var url = `/api/sor/${crmKey}/objects?verbose=${isVerbose}`
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
