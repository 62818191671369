import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { WorkflowsAPI } from '../api'
import { FetchWorkflowParameter } from '../api/fetchWorkflow'
import { ReduxWorkflowsState, ReduxV2WorkflowEntity } from '../types'
import LuruError from '@/features/LuruError'

export interface FetchWorkflowAPIResponse extends LuruAPIResponse {
  data: ReduxV2WorkflowEntity
}

export const fetchV2Workflow = {
  action: createAsyncThunk<
    FetchWorkflowAPIResponse['data'],
    FetchWorkflowParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: FetchWorkflowAPIResponse['metadata']
      rejectedMeta: FetchWorkflowAPIResponse['metadata']
    }
  >('workflows/fetchV2Workflow', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await WorkflowsAPI.fetchWorkflow(params, {
        signal,
      })) as FetchWorkflowAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null
      return rejectWithValue(
        {
          ...luruError?.toErrorValue(),
          message: luruError?.toErrorValue()?.message ?? 'Error fetching given workflow',
        },
        null
      )
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(fetchV2Workflow.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(fetchV2Workflow.action.fulfilled, (state, action) => {
      const workflow = action.payload

      state.entities[workflow.workflow_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...workflow,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(fetchV2Workflow.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    fetchV2Workflow.addPendingCase(builder)
    fetchV2Workflow.addFulfilledCase(builder)
    fetchV2Workflow.addRejectedCase(builder)
  },
}
