import React from 'react'
import EditableCellEventHandler from './EditableCellEventHandler'
import LuruTextBox, { LuruTextBoxProps } from '../ui/LuruTextBox'
import LuruSelectBox from '../ui/LuruSelectBox'
import { ViewDisplaySettings } from './PipelineViewDisplaySettings'
import CrmRecord from '../../domain/crmRecord/CrmRecord'
import parentStyles from './parentStyles.module.css'
import styles from './styles.module.css'
import errorIcon from '../../images/error.svg'
import undoIcon from '../../images/undo.svg'
import AppComponentsContext from '../AppComponents/AppComponentsContext'
import LuruMultiSelectBox from '../ui/LuruMultiSelectBox'
import CrmObjectSelectBox from '../domain/crm/CrmObjectSelectBox'
import moment from 'moment'
import LuruHierarchicalPicklist from '../ui/LuruHierarchicalPicklist'
import CrmMultiObjectSelectBox from '../domain/crm/CrmMultiObjectSelectBox'
import Utils from '../../utils/Utils'
import { CrmFieldSchema, CrmRecordType, LuruFieldType, LuruHierarchialPicklistValue } from '../../features/crm/types'
// Icons
import zoomOutIcon from '../../images/fluent/arrow_expand.svg'
import descriptionIcon from '../../images/fluent/description.svg'
import { EntityStatus } from '@/app/types'

export interface EditableCellProps {
  // Style to apply to parent element - comes from Grid
  // style: object
  // Row index of cell inside grid
  rowIndex: number
  // Grid row count
  rowCount: number
  // Col index of cell inside grid
  columnIndex: number
  // Grid column count
  columnCount: number
  // Field type
  luruFieldType: LuruFieldType
  // Field value
  value: any
  // Picklist values (if applicable)
  picklistValues?: Array<{
    label: string
    value: string
    valueForUpdate?: string
  }>
  // Width
  width: number
  // Disable explicit width setting
  disableExplicitWidth?: boolean
  // Parent element selector for menu element (for stacking correctly)
  menuParentSelector?: string
  // Field schema
  fieldSchema?: CrmFieldSchema
  // Readonly
  isReadOnly?: boolean

  // Controller field value
  // Lets the component know the value of a controller field
  controllerFieldValue?: string

  //// Callbacks
  // Call back on blur of editable field (for text box fields)
  onBlur?: (value: any, source: EditableCell) => void
  // Call back on value change (for non-text box fields)
  onChange?: (value: any, source: EditableCell) => void
  // Call back on user attempting to navigate up
  onNavigateUp: () => void
  // ...down
  onNavigateDown: () => void
  // ...left
  onNavigateLeft: () => void
  // ...right
  onNavigateRight: () => void

  onClickShowNotes?: () => void

  onClickShowDetails?: () => void

  omniboxActionsButtonsClassName?: string
  // Whether to update record on change of field value
  updateOnChange?: boolean
  // CRM record type to use when updating record (only relevant when updateOnChange is true)
  crmRecordType?: CrmRecordType
  // Record id to use when updating record (only relevant when updateOnChange is true)
  sorRecordId?: string
  // Callback when there is an update error
  onUpdateError?: (message: string | undefined) => void
  // Forced alignment
  align?: 'left' | 'right'

  showErroneousStatus?: boolean
  onFieldValueChange: (fieldName: string, value: string) => void
  status: EntityStatus
}

export enum EditableCellStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface EditableCellState {
  status: EditableCellStatus
  errorMessage?: string
  fieldValue: any
  displayedValue: string
}

interface EditateCellRefs {
  textBoxRef: React.RefObject<LuruTextBox>
  textAreaRef: React.RefObject<HTMLTextAreaElement>
  selectLayerRef: React.RefObject<HTMLDivElement>
  infoLayerRef: React.RefObject<HTMLDivElement>
}

export default class EditableCell extends React.Component<EditableCellProps, EditableCellState> {
  props: EditableCellProps
  state: EditableCellState
  eventHandler: EditableCellEventHandler
  componentRefs: EditateCellRefs

  static contextType?: React.Context<any> | undefined = AppComponentsContext

  textPropTypes = [
    LuruFieldType.ADDRESS,
    LuruFieldType.CURRENCY,
    LuruFieldType.DATE,
    LuruFieldType.DATETIME,
    LuruFieldType.DOUBLE,
    LuruFieldType.EMAIL,
    LuruFieldType.INTEGER,
    LuruFieldType.INTEGER_NOFORMAT,
    // LuruFieldType.LARGETEXT,
    LuruFieldType.PERCENTAGE,
    LuruFieldType.TELEPHONE,
    LuruFieldType.TEXT,
    LuruFieldType.URL,
  ]

  constructor(props: EditableCellProps) {
    super(props)
    this.props = props
    this.state = {
      status: EditableCellStatus.IDLE,
      fieldValue: this.props.value,
      displayedValue: this.formatFieldValue(this.props.value),
    }
    this.eventHandler = new EditableCellEventHandler(this)
    this.componentRefs = {
      textBoxRef: React.createRef(),
      textAreaRef: React.createRef(),
      selectLayerRef: React.createRef(),
      infoLayerRef: React.createRef(),
    }
  }

  isControlledField = () => Boolean(this.props.fieldSchema?.controllerName)

  //// Behaviors
  // Focus the value element
  focus = () => this.componentRefs.textBoxRef.current?.focus()

  // Set field updated success state
  setSuccessState = () => this.setState({ status: EditableCellStatus.SUCCESS })

  // Set idle state
  setIdleState = () => this.setState({ status: EditableCellStatus.IDLE })

  // Set loading state
  setLoadingState = () => this.setState({ status: EditableCellStatus.LOADING })

  // Set failure state
  setFailureState = (errorMessage: string) => this.setState({ status: EditableCellStatus.FAILURE, errorMessage })

  itemNameFormatter = (name: string | undefined) => {
    var parts = name?.split(':')

    return parts?.length === 2 ? (
      <div>
        <span className={styles.selectBoxLevel1}>{parts[0]?.trim()}</span>
        <span className={styles.selectBoxLevel2}>{parts[1]?.trim()}</span>
      </div>
    ) : (
      name
    )
  }

  labelFormatter = (name: string) => {
    var parts = name.split(':')

    return parts.length === 2 ? (
      <div className={styles.selectBoxLabel}>
        <span className={styles.selectBoxLabelLevel1}>{parts[0]?.trim()}</span>
        <span className={styles.selectBoxLabelLevel2}>{parts[1]?.trim()}</span>
      </div>
    ) : (
      name
    )
  }

  getElementId = () => `luru-editable-cell-${this.props.rowIndex}-${this.props.fieldSchema?.name}`

  render = () => {
    var classNames = [parentStyles.viewDataTableCell, styles.cell, styles[this.props.luruFieldType] ?? '']

    if (this.props.columnIndex + 1 === this.props.columnCount) {
      classNames.push(parentStyles.lastColumn)
    }

    if (this.props.rowIndex + 1 === this.props.rowCount) {
      classNames.push(parentStyles.lastRow)
    }

    var statusStyle =
      this.props.status === EntityStatus.Idle
        ? styles.idle
        : this.props.status === EntityStatus.Loading
        ? styles.loading
        : this.props.status === EntityStatus.Loaded
        ? styles.success
        : styles.error

    var infoStyle = [styles.info, statusStyle].join(' ')

    return (
      <div
        id={this.getElementId()}
        className={classNames.join(' ')}
        style={
          !this.props.disableExplicitWidth
            ? {
                width: this.props.width + 'px',
                minWidth: this.props.width + 'px',
              }
            : {}
        }
        title={this.state.status === EditableCellStatus.FAILURE ? this.state.errorMessage : undefined}
        data-luru-edit-status={this.state.status}
        data-luru-role='editable-cell'
        data-luru-field-type={this.props.luruFieldType}
      >
        {/* Info layer */}
        <div
          className={infoStyle}
          ref={this.componentRefs.infoLayerRef}
          data-luru-role='editable-cell-info-layer'
        ></div>
        {/* Value component layer */}
        <div
          className={[
            styles.edit,
            this.props.columnIndex === 0 ? styles.omniboxPositioning : null,
            this.props.showErroneousStatus ? styles.error : null,
          ].join(' ')}
        >
          {this.#renderValueComponent()}
        </div>
        {/* Alert message layer */}
        {this.state.status === EditableCellStatus.FAILURE ? (
          <div className={styles.alert} title={this.state.errorMessage} data-luru-role='editable-cell-alert'>
            <img
              data-role='revert-value'
              src={undoIcon}
              alt='Revert to original value'
              title='Revert to original value'
              onClick={this.eventHandler.handlers.onRevert}
            />
            <img src={errorIcon} alt={this.state.errorMessage} title={this.state.errorMessage} />
          </div>
        ) : null}
        {/* Select layer (not used for now) */}
        <div
          ref={this.componentRefs.selectLayerRef}
          id={`select-layer-${this.props.rowIndex}-${this.props.columnIndex}`}
          className={styles.select}
          tabIndex={-1}
          onDoubleClick={this.eventHandler.handlers.onEditCell}
          onKeyDown={this.eventHandler.handlers.onSelectLayerKeyDown}
        ></div>
        {/* Omnibox actions layer */}
        {this.props.columnIndex === 0 ? (
          <div className={styles.omniboxLayer}>
            <div className={[styles.omniboxActions, this.props.omniboxActionsButtonsClassName].join(' ')}>
              <img
                src={descriptionIcon}
                alt='description'
                title='Show notes'
                onClick={this.eventHandler.handlers.onClickShowNotes}
              />
              <img
                src={zoomOutIcon}
                alt='zoom_out'
                title='Show details'
                onClick={this.eventHandler.handlers.onClickShowDetails}
              />
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  textBoxTypes: {
    [key in LuruFieldType]?: 'date' | 'datetime-local' | 'email' | 'tel' | 'text'
  } = {
    [LuruFieldType.DATE]: 'date',
    [LuruFieldType.DATETIME]: 'datetime-local',
    [LuruFieldType.EMAIL]: 'email',
    [LuruFieldType.TELEPHONE]: 'tel',
    [LuruFieldType.URL]: 'text',
  }

  textBoxPatterns: {
    [key in LuruFieldType]?: string
  } = {
    [LuruFieldType.CURRENCY]: '([0-9,]+)?(.[0-9]{2})?',
    [LuruFieldType.DOUBLE]: '([0-9,]+)?(.[0-9]{2})?',
    [LuruFieldType.INTEGER]: '[0-9,]+',
    [LuruFieldType.INTEGER_NOFORMAT]: '[0-9,]+',
    [LuruFieldType.PERCENTAGE]: '[0-9]{1,3}',
    [LuruFieldType.URL]: '([^.]+.)?[^.]+.[^.]+',
  }

  picklistPropTypes = [LuruFieldType.BOOLEAN, LuruFieldType.ENUM, LuruFieldType.ENUM_RADIO, LuruFieldType.ENUM_SELECT]

  multipicklistPropTypes = [LuruFieldType.MULTIENUM]

  #renderValueComponent = () => {
    var component: any

    if (this.textPropTypes.includes(this.props.luruFieldType)) {
      let componentProps: LuruTextBoxProps = {
        additionalClassNames: [parentStyles.editCellTextBox],
        defaultValue: this.state.displayedValue,
        pattern: this.textBoxPatterns[this.props.luruFieldType],
        type: this.textBoxTypes[this.props.luruFieldType] ?? 'text',
        alignment: this.props.align ?? ViewDisplaySettings.getAlignment(this.props.luruFieldType),
        readOnly: this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly),
        onBlur: this.eventHandler.handlers.onBlur,
        onFocus: this.eventHandler.handlers.onFocus,
        onReturn: this.eventHandler.handlers.onEnter,
        onCancel: this.eventHandler.handlers.onCancel,
        onDatePicked: this.eventHandler.handleFieldValueChange,
        onKeyDown: this.eventHandler.handlers.onTextBoxKeyDown,
        onPaste: this.eventHandler.handlers.onTextBoxPaste,
      }

      component = (
        <LuruTextBox key={this.state.displayedValue} ref={this.componentRefs.textBoxRef} {...componentProps} />
      )
    }

    if (this.props.luruFieldType === LuruFieldType.LARGETEXT) {
      component = (
        <textarea
          className={[parentStyles.editCellTextBox, styles.textarea].join(' ')}
          disabled={this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly)}
          key={(this.props.sorRecordId ?? '') + '-' + JSON.stringify(this.state.fieldValue)}
          ref={this.componentRefs.textAreaRef}
          rows={5}
          value={this.state.displayedValue}
          onBlur={(e) => this.eventHandler.handlers.onBlur(e.target.value)}
          onChange={this.eventHandler.handlers.onTextAreaChange}
          onFocus={this.eventHandler.handlers.onFocus}
          onKeyDown={this.eventHandler.handlers.onTextAreaKeyDown}
          onPaste={this.eventHandler.handlers.onTextAreaPaste}
        ></textarea>
      )
    }

    if (this.picklistPropTypes.includes(this.props.luruFieldType)) {
      let isNillable = this.props.fieldSchema?.isNillable === true

      let picklistItems: Array<{ name: string; key: string | null }> = isNillable
        ? [{ name: '--None--', key: null }]
        : []

      switch (this.props.luruFieldType) {
        case LuruFieldType.BOOLEAN:
          picklistItems = [
            { name: 'True', key: 'true' },
            { name: 'False', key: 'false' },
          ]
          break

        // case LuruFieldType.MULTIENUM:
        //   picklistItems =
        //     this.props.fieldSchema?.picklistValues
        //       ?.filter(
        //         (x) =>
        //           x.label && (!this.isControlledField() || x.validFor?.includes(this.props.controllerFieldValue ?? ''))
        //       )
        //       ?.map((x) => ({
        //         name: x.label,
        //         key: x.valueForUpdate ?? x.value,
        //         isSelected:
        //           (this.props.value as string)?.split(';').find((s) => s === x.value || s === x.valueForUpdate) !==
        //           undefined,
        //       })) ?? []
        //   break

        default:
          picklistItems = picklistItems.concat(
            this.props.fieldSchema?.picklistValues
              ?.filter((x) => !this.isControlledField() || x.validFor?.includes(this.props.controllerFieldValue ?? ''))
              .map((x) => ({
                name: x.label,
                key: x.valueForUpdate ?? x.value,
              })) ?? []
          )
          break
      }

      let prechosenItem =
        this.props.fieldSchema?.picklistValues?.find(
          (v) =>
            v.valueForUpdate?.toString() === this.state.fieldValue?.toString() ||
            v.value?.toString() === this.state.fieldValue?.toString()
        )?.label ?? this.state.fieldValue?.toString()
      component = (
        <LuruSelectBox
          classes={[styles.pipelineViewSelectBox]}
          disabled={this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly)}
          isFilterable={true}
          itemNameFormatter={this.itemNameFormatter}
          items={[...picklistItems]}
          key={this.state.displayedValue}
          labelFormatter={this.labelFormatter}
          leftAlign={true}
          menuParentSelector={this.props.menuParentSelector}
          prechosenItem={prechosenItem}
          onChooseItem={this.eventHandler.handlers.onSelectItem}
        />
      )
    }

    if (this.multipicklistPropTypes.includes(this.props.luruFieldType)) {
      let selectedValues = Array.isArray(this.state?.fieldValue)
        ? this.state?.fieldValue
        : this.state?.fieldValue?.split?.(';') ?? []

      let picklistItems =
        this.props.fieldSchema?.picklistValues
          ?.filter(
            (x) => x.label && (!this.isControlledField() || x.validFor?.includes(this.props.controllerFieldValue ?? ''))
          )
          .map((x) => ({
            name: x.label,
            key: x.valueForUpdate ?? x.value,
            isSelected: selectedValues.includes(x.valueForUpdate ?? x.value),
          })) ?? []

      component = (
        <LuruMultiSelectBox
          key={picklistItems.map((x) => x.key).join(';')}
          items={picklistItems}
          menuParentSelector={this.props.menuParentSelector}
          classes={[styles.pipelineViewSelectBox]}
          onCancel={() => {}}
          onFinishSelection={this.eventHandler.handlers.onMultiSelectFinish}
          subdued={true}
          leftAlign={true}
          disabled={this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly)}
        />
      )
    }

    if (this.props.luruFieldType === LuruFieldType.REFERENCE) {
      let fieldValue = this.state.fieldValue as unknown as
        | {
            sor_object_name: string
            sor_record_name: string
            sor_record_id: string
          }
        | ''
        | null
      let refSorObjectName = this.props.fieldSchema?.referencedObject
      let isFieldMandatory = this.props.fieldSchema?.isMandatory
      let isFieldNillable = this.props.fieldSchema?.isNillable

      component = refSorObjectName ? (
        <CrmObjectSelectBox
          className={styles.referenceInput}
          disabled={this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly)}
          key={this.state.displayedValue}
          menuParentSelector={this.props.menuParentSelector}
          readOnly={this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly)}
          sorObjectName={refSorObjectName}
          value={fieldValue ? fieldValue.sor_record_name : ''}
          onSelectItem={(sorRecordId, sorRecordName) =>
            this.eventHandler.handlers.onReferenceValueChange(sorRecordId, sorRecordName, refSorObjectName)
          }
          onDeleteItem={
            !isFieldMandatory && isFieldNillable ? this.eventHandler.handlers.onReferenceValueDeleted : undefined
          }
        />
      ) : null
    }

    if (this.props.luruFieldType === LuruFieldType.REFERENCE_FIELD) {
      let componentProps: LuruTextBoxProps = {
        additionalClassNames: [parentStyles.editCellTextBox],
        alignment: ViewDisplaySettings.getAlignment(this.props.luruFieldType),
        defaultValue: this.state.displayedValue,
        pattern: '*',
        readOnly: this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly),
        type: this.textBoxTypes[this.props.luruFieldType] ?? 'text',
      }

      component = (
        <LuruTextBox key={this.state.displayedValue} ref={this.componentRefs.textBoxRef} {...componentProps} />
      )
    }

    if (this.props.luruFieldType === LuruFieldType.MULTIREFERENCE) {
      let fieldValue = this.state.fieldValue as unknown as
        | Array<{
            sor_object_name: string
            sor_record_id: string
            sor_record_name: string
          }>
        | ''
        | null
        | undefined

      if (!Array.isArray(fieldValue)) {
        fieldValue = []
      }

      if (!this.props.fieldSchema) {
        component = null
      }

      component = (
        <CrmMultiObjectSelectBox
          classes={[styles.multiObjectBox]}
          dialogTitle={`Add / Edit ${Utils.capitalizeString(this.props.fieldSchema?.referencedObject)}`}
          disabled={this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly)}
          fieldName={this.props.fieldSchema?.referencedObject ?? ''}
          key={this.state.displayedValue}
          sorObjectName={this.props.fieldSchema?.referencedObject ?? ''}
          values={fieldValue}
          onFinishSelection={(v: any) => {
            // this.props.onChange?.(v, this)
            this.eventHandler.handleFieldValueChange(v)
          }}
        />
      )
    }

    if (this.props.luruFieldType === LuruFieldType.HIERARCHICAL_ENUM) {
      if (!this.props.fieldSchema?.hierarchicalPicklistValues) {
        component = <span className='error'>Error</span>
      } else {
        component = (
          <LuruHierarchicalPicklist
            classes={[parentStyles.editCellTextBox]}
            disabled={this.props.fieldSchema?.updateable === false || Boolean(this.props.isReadOnly)}
            isFilterable={true}
            key={this.state.displayedValue}
            leftAlign={true}
            menuParentSelector={this.props.menuParentSelector}
            options={this.props.fieldSchema.hierarchicalPicklistValues}
            prechosenItem={this.state.fieldValue as unknown as LuruHierarchialPicklistValue}
            subdued={true}
            onChooseItem={(v: LuruHierarchialPicklistValue) => {
              // this.props.onChange?.(v, this)
              this.eventHandler.handleFieldValueChange(v)
            }}
          />
        )
      }
    }

    return component
  }

  formatFieldValue = (value: any) => {
    var luruFieldType = this.props.luruFieldType

    switch (luruFieldType) {
      case LuruFieldType.DATE:
        return value ? moment(value).format('YYYY-MM-DD') : ''

      case LuruFieldType.DATETIME:
        return value ? moment(value).format('YYYY-MM-DDTHH:mm') : ''

      default:
        return CrmRecord.getFormattedValue({
          luruFieldType,
          value,
        })
    }
  }
}
