import { userSliceSignInUser } from './userSliceSignInUser'
import { userSliceSignOutUser } from './userSliceSignOutUser'
import { notesSliceCreateNoteConnection } from './notesSliceCreateNoteConnection'
import { notesSliceDeleteNoteConnection } from './notesSliceDeleteNoteConnection'
import { notesSliceCreateNote } from './notesSliceCreateNote'
import { notesSliceDeleteNote } from './notesSliceDeleteNote'

export const MeetingsSliceExtraReducers = {
  userSliceSignInUser,
  userSliceSignOutUser,
  notesSliceCreateNoteConnection,
  notesSliceDeleteNoteConnection,
  notesSliceDeleteNote,
  notesSliceCreateNote,
}
