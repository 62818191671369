import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { DeleteNoteParameter, DeleteNoteAPIResponse } from '../../notes/middleware/deleteNote'
import { ReduxMeetingsState } from '../types'

export const notesSliceDeleteNote: CaseReducer<
  ReduxMeetingsState,
  PayloadAction<
    DeleteNoteAPIResponse['data'],
    string,
    DeleteNoteAPIResponse['metadata'] & {
      arg: DeleteNoteParameter
    }
  >
> = (state, action) => {
  var noteId = action.meta.arg.noteId

  if (!state.entities) {
    return
  }

  for (let meetingId in state.entities) {
    let meeting = state.entities[meetingId]

    if (meeting?.data?.notes?.map?.((n) => n.note_id)?.includes?.(noteId)) {
      state.entities[meetingId].data.notes = state.entities[meetingId].data.notes.filter(
        (note) => note.note_id !== noteId
      )

      break
    }
  }
}
