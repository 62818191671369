import update from 'immutability-helper'
import React from 'react'
import LuruMultiSelectPopup, { LuruMultiSelectPopupState, LuruMultiSelectPopupProps } from '.'
import styles from './styles.module.css'

export interface LuruMultiSelectPopupEvents {
  onReset: () => void
  onToggleItem: (key: string) => void
  onToggleSelectAll: () => void
  onShowSelectDropdown: () => void
  onFilterBoxChange: (value: string | null) => void
  onFilterBoxNavigateDown: () => void
  onFilterBoxNavigateUp: () => void
  onFilterBoxReturn: () => void
  onFilterBoxCancel: () => void
  onClickOkButton: () => void
  onClickCancelButton: () => void
}

export default class LuruMultiSelectPopupEventHandler {
  #component: LuruMultiSelectPopup
  handlers: LuruMultiSelectPopupEvents
  dragDropHandlers: Record<
    'dragstart' | 'dragend' | 'dragenter' | 'dragover' | 'dragleave' | 'drop',
    React.DragEventHandler<HTMLLIElement>
  >

  constructor(component: LuruMultiSelectPopup) {
    this.#component = component

    this.handlers = {
      onReset: this.#onReset,
      onToggleItem: this.#onToggleItem,
      onToggleSelectAll: this.#onToggleSelectAll,
      onShowSelectDropdown: this.#onShowSelectDropdown,
      onFilterBoxChange: this.#onFilterBoxChange,
      onFilterBoxNavigateUp: this.#onFilterBoxNavigateUp,
      onFilterBoxNavigateDown: this.#onFilterBoxNavigateDown,
      onFilterBoxReturn: this.#onFilterBoxReturn,
      onFilterBoxCancel: this.#onFilterBoxCancel,
      onClickOkButton: this.#onClickOkButton,
      onClickCancelButton: this.#onClickCancelButton,
    }

    this.dragDropHandlers = {
      dragstart: this.#onDragStart,
      dragend: this.#onDragEnd,
      dragenter: this.#onDragEnter,
      dragover: this.#onDragOver,
      dragleave: this.#onDragLeave,
      drop: this.#onDrop,
    }

    this.#component.componentDidUpdate = this.#onComponentUpdate
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
  }

  #onComponentMount() {
    this.#component.focusFilterBox()
  }

  #onComponentUpdate = (prevProps: LuruMultiSelectPopupProps) => {
    this.#component.focusFilterBox()
    if (prevProps.items !== this.#component.props.items) {
      const filterText = this.#component.componentRefs.filterBox?.current?.getValue()

      this.#component.setState({
        filteredItems: filterText
          ? this.#component.props.items.filter(
              (item, ix) =>
                (ix === 0 && this.#component.props.disableFirstItemReorder) ||
                item.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
            )
          : this.#component.props.items,
        orderedItems: this.#component.props.items,
        selectedItems: this.#component.props.items.filter((x) => x.isSelected).map((f) => f.key),
      })
    }

    this.#navigateToSelectedItem()
  }

  #onDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    if (!e.dataTransfer) {
      return
    }

    e.dataTransfer.setData('text/plain', e.currentTarget?.dataset.multiselectOrderedIndex ?? '0')

    e.dataTransfer.setData(e.currentTarget?.dataset?.multiselectOrderedIndex ?? '0', 'source')

    e.dataTransfer.dropEffect = 'move'
    e.currentTarget?.classList.add(styles.beingGrabbed)
  }

  #onDragEnd = (e: React.DragEvent<HTMLLIElement>) => {
    e.currentTarget?.classList.remove(styles.beingGrabbed)
  }

  #onDragEnter = (e: React.DragEvent<HTMLLIElement>) => {
    var sourceIndex = e.dataTransfer.types[1]

    var targetIndex = e.currentTarget?.dataset?.multiselectOrderedIndex ?? sourceIndex

    if (parseInt(sourceIndex) < parseInt(targetIndex)) {
      e.currentTarget?.classList.remove(styles.beingDraggedOverTop)
      e.currentTarget?.classList.add(styles.beingDraggedOverBottom)
    } else if (parseInt(sourceIndex) > parseInt(targetIndex)) {
      e.currentTarget?.classList.remove(styles.beingDraggedOverBottom)
      e.currentTarget?.classList.add(styles.beingDraggedOverTop)
    }
  }

  #onDragOver = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault()

    var sourceIndex = e.dataTransfer.types[1]

    var targetIndex = e.currentTarget?.dataset?.multiselectOrderedIndex ?? sourceIndex

    if (parseInt(sourceIndex) < parseInt(targetIndex)) {
      e.currentTarget?.classList.remove(styles.beingDraggedOverTop)
      e.currentTarget?.classList.add(styles.beingDraggedOverBottom)
    } else if (parseInt(sourceIndex) > parseInt(targetIndex)) {
      e.currentTarget?.classList.remove(styles.beingDraggedOverBottom)
      e.currentTarget?.classList.add(styles.beingDraggedOverTop)
    }
  }

  #onDragLeave = (e: React.DragEvent<HTMLLIElement>) => {
    e.currentTarget?.classList.remove(styles.beingDraggedOverTop)
    e.currentTarget?.classList.remove(styles.beingDraggedOverBottom)
  }

  #onDrop = (e: React.DragEvent<HTMLLIElement>) => {
    if (!e.dataTransfer) {
      return
    }

    e.currentTarget?.classList.remove(styles.beingDraggedOverBottom)
    e.currentTarget?.classList.remove(styles.beingDraggedOverTop)

    // Reorder fields
    var sourceIndex = parseInt(e.dataTransfer.getData('text/plain'))

    if (Number.isNaN(sourceIndex)) {
      return
    }

    var targetIndex = parseInt(e.currentTarget?.dataset?.multiselectOrderedIndex ?? `${sourceIndex}`)

    var fields = this.#component.state.orderedItems.map((f) => Object.assign({}, f))

    var sourceField = fields.splice(sourceIndex, 1)

    fields.splice(targetIndex, 0, sourceField[0])
    this.#component.setState({ orderedItems: fields })

    this.#component.componentRefs.list.current
      ?.querySelector(`li[data-multiselect-ordered-index='${sourceIndex}']`)
      ?.classList.remove(styles.beingGrabbed)
  }

  #onFilterBoxChange = (value: string | null) => {
    var filterText = value ?? ''

    this.#component.setState({
      filteredItems: this.#component.props.items.filter(
        (item, ix) =>
          (ix === 0 && this.#component.props.disableFirstItemReorder) ||
          item.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
      ),
    })
  }

  #onReset = () => {
    this.#component.componentRefs.filterBox?.current?.reset()
  }

  #onToggleItem = (key: string) => {
    const toggledItem = this.#component.props.items.find((item) => item.key === key && !item.isDisabled)

    if (toggledItem) {
      this.#toggleItem(toggledItem)
    }
    this.#component.componentRefs.filterBox?.current?.focus?.()
    this.#component.componentRefs.filterBox?.current?.select?.()
  }

  #onToggleSelectAll = () => {
    const itemsDisabledAndNotSelected = this.#component.props.items
      .filter((f) => f.isDisabled && !f.isSelected)
      .map((item) => item.key)

    const itemsDisabledAndSelected = this.#component.props.items
      .filter((f, ix) => (f.isDisabled && f.isSelected) || (ix === 0 && this.#component.props.disableFirstItemReorder))
      .map((item) => item.key)

    const allItems = this.#component.props.items.map((item) => item.key)

    if (this.#component.props.items.length === this.#component.state.selectedItems.length) {
      this.#component.setState({
        selectedItems: itemsDisabledAndSelected,
      })
      this.#component.props.onDeselectAllItems?.(itemsDisabledAndSelected)
    } else {
      const toBeSelected = allItems.filter((f) => !itemsDisabledAndNotSelected.includes(f))

      this.#component.setState({
        selectedItems: toBeSelected,
      })

      this.#component.props.onSelectAllItems?.(toBeSelected)

      this.#component.componentRefs.filterBox?.current?.focus?.()
      this.#component.componentRefs.filterBox?.current?.select?.()
    }
  }

  #onFilterBoxReturn = () => {
    var chosenIndex = this.#component.state.highlightedIndex

    if (chosenIndex === -1) {
      this.#onToggleSelectAll()
      return
    }

    if (chosenIndex >= 0 && chosenIndex < this.#component.state.filteredItems.length) {
      let chosenItem = this.#component.state.filteredItems[chosenIndex]

      this.#toggleItem(chosenItem)
    }
  }

  #toggleItem = (item: { name: string; key: string }) => {
    var itemIx = this.#component.state.selectedItems.findIndex((k) => k === item.key)

    if (itemIx !== -1) {
      this.#component.setState(
        (prevState: LuruMultiSelectPopupState) => ({
          selectedItems: update(prevState.selectedItems, {
            $splice: [[itemIx, 1]],
          }),
        }),
        () => {
          if (this.#component.props.onDeselectItem) {
            this.#component.props.onDeselectItem(item.key, this.#component.state.selectedItems)
          }
        }
      )
    } else {
      this.#component.setState(
        (prevState: LuruMultiSelectPopupState) => ({
          selectedItems: update(prevState.selectedItems, {
            $push: [item.key],
          }),
        }),
        () => {
          if (this.#component.props.onSelectItem) {
            this.#component.props.onSelectItem(item.key, this.#component.state.selectedItems)
          }
        }
      )
    }
  }

  #onShowSelectDropdown = () => {
    // We need a setTimeout because focus() method can be called only when
    // element is visible.  The 100ms timeout allows this component to wait for
    // show() method to finish (including CSS fade-in animation transition end)
    setTimeout(() => this.#component.componentRefs.filterBox?.current?.focus(), 100)
  }

  #onFilterBoxNavigateUp = () => {
    var nextIndex =
      this.#component.state.highlightedIndex <= -1
        ? this.#component.state.filteredItems.length - 1
        : this.#component.state.highlightedIndex - 1

    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #onFilterBoxNavigateDown = () => {
    var nextIndex =
      this.#component.state.highlightedIndex >= this.#component.state.filteredItems.length - 1
        ? -1
        : this.#component.state.highlightedIndex + 1

    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #navigateToSelectedItem = () => {
    var ulElement = this.#component.componentRefs.list.current as HTMLUListElement

    var liElement = ulElement?.querySelector('li[data-highlighted="yes"]') as HTMLLIElement

    if (liElement) {
      let topOverflow = Math.max(0, ulElement.scrollTop + liElement.offsetHeight - liElement.offsetTop)
      let bottomOverflow = Math.max(
        0,
        liElement.offsetTop + liElement.offsetHeight - ulElement.scrollTop - ulElement.offsetHeight
      )

      if (topOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: -topOverflow, behavior: 'instant' })
      } else if (bottomOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: bottomOverflow, behavior: 'instant' })
      }
    }
  }

  #onFilterBoxCancel = () => {
    if (this.#component.state.highlightedIndex === -1) {
      this.#component.props.onCancel()
    } else {
      this.#cancelPopup()
    }
  }

  #onClickOkButton = () => {
    this.#cancelPopup()

    this.#component.props.onFinishSelection(
      // filteredItems maintains order
      this.#component.props.items.filter((f) => this.#component.state.selectedItems.includes(f.key)).map((f) => f.key),

      // 2nd parameter will be all items
      this.#component.state.orderedItems.map((i, ix) => ({
        ...i,
        isSelected: this.#component.state.selectedItems.includes(i.key),
        order: ix,
      }))
    )
  }

  #onClickCancelButton = () => {
    this.#cancelPopup()
  }

  #cancelPopup = () => {
    this.#component.setState({
      highlightedIndex: -2,
      filteredItems: this.#component.props.items,
      orderedItems: this.#component.props.items,
    })

    this.#component.componentRefs.filterBox?.current?.reset()
    this.#component.props.onCancel()
  }

  getIsItemsChanged = () => {
    let stateItems = this.#component.state?.selectedItems
    let propsItems = this.#component.props?.items?.filter?.((item) => item?.isSelected)?.map?.((item) => item?.key)

    if (stateItems?.length !== propsItems?.length) {
      return true
    }

    for (let itemIndex = 0; itemIndex < stateItems?.length; itemIndex++) {
      if (stateItems?.[itemIndex] !== propsItems?.[itemIndex]) {
        return true
      }
    }

    return false
  }
}
