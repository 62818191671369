import { Route } from 'react-router-dom'
import { renderWithLazyLoading } from '../../WebAppLoading'
import { lazy } from 'react'

const RecordLandingHome = lazy(() => import('../../../routes/records/RecordLandingHome'))

const RecordLandingHomeLazyLoading = renderWithLazyLoading(<RecordLandingHome />, true)

const renderRecordLandingHomeRoutes = () => (
  <Route path='/record/:sorObjectName/:sorRecordId' element={<RecordLandingHomeLazyLoading />}></Route>
)

export default renderRecordLandingHomeRoutes
