import { useAppSelector } from '../../../app/hooks'

export function useConnectedRecord(noteId: string) {
  const note = useAppSelector((state) => state.notes.entities[noteId])?.data
  const crmId = useAppSelector((state) => state.user.data?.userSettings?.connectedCRM)?.name

  const connectedRecord = note?.connections?.find((f) => f.sor === crmId)

  return connectedRecord
}
