import { EntityStatus } from '../../app/types'
import { CRMProvider } from '../user/types'

export const ReduxCollectionsStateTTL = 60 * 60 * 1_000 // millisecs/hour

export interface ReduxCollectionsState {
  refreshedAt: string | undefined
  status: EntityStatus
  entities: Record<
    string,
    {
      status: EntityStatus
      data: ReduxCollectionEntity | null
      sort_number?: number
    }
  >
}

export interface ReduxCollectionEntity {
  access: number
  collection_id: string
  created_at: string
  created_by: string
  description: string
  fields: Array<string>
  name: string
  shares: Array<CollectionShareInfo>
  sor: CRMProvider
  sor_object_name: string
  updated_at: string
  used_at: null | string
  visibility: CollectionVisibilityEnum
}

export interface CollectionShareInfo {
  entity_type: CollectionShareSourceEnum
  entity_id: string
  created_by?: string
  availability: CollectionShareAccessRightEnum
}

export enum CollectionVisibilityEnum {
  NONE = 'NONE',
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}

export enum CollectionShareSourceEnum {
  TENANT = 'TENANT',
  USER = 'USER',
  GROUP = 'GROUP',
}

export enum CollectionShareAccessRightEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum CollectionAccessEnum {
  VIEWABLE = 1,
  EDITABLE = 2,
  HIDEABLE = 4,
  SHAREABLE = 8,
}

export const EmptyCollectionsState: ReduxCollectionsState = {
  refreshedAt: undefined,
  status: EntityStatus.NotLoaded,
  entities: {},
}
