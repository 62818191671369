import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { SearchTaskAssigneesParameter } from '../middleware/listOrSearchAssignees'

export const listOrSearchAssignees = (
  params: Readonly<SearchTaskAssigneesParameter>,
  options: LuruAPIRequestOptions
) => {
  var queryParams: Partial<SearchTaskAssigneesParameter> = { ...params }

  for (let key in params) {
    let typedKey = key as keyof SearchTaskAssigneesParameter

    if (params[typedKey] === undefined || params[typedKey] === null) {
      delete queryParams[typedKey]
    } else if (key === 'q') {
      queryParams['q'] = params.q?.trim()
    }
  }

  var request = new LuruAPIRequest(
    '/api/tasks/assignees?' +
      new URLSearchParams(queryParams as URLSearchParams),
    HTTPMethod.GET,
    options
  )

  return request.make()
}
