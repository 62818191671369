import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { CreateNoteActionParameter, CreateNoteAPIResponse } from '../../notes/middleware/createNote'
import { CalendarProvider, CRMProvider } from '../../user/types'
import { ReduxMeetingsState } from '../types'

export const notesSliceCreateNote: CaseReducer<
  ReduxMeetingsState,
  PayloadAction<
    CreateNoteAPIResponse['data'],
    string,
    CreateNoteAPIResponse['metadata'] & {
      arg: CreateNoteActionParameter
    }
  >
> = (state, action) => {
  if (!state.entities) {
    return
  }

  var note = action.payload
  var connections = action.meta.arg.connections

  if (connections == null) {
    return
  }

  var calProviders = [CalendarProvider.GCAL, CalendarProvider.O365CAL] as Array<CRMProvider | CalendarProvider>

  for (let connection of connections) {
    if (!calProviders.includes(connection.sor)) {
      // If linkNote API was not to a meeting (but say, to a CRM record),
      // we don't do anything here.
      continue
    }

    let meetingId = connection.sor_record_id

    if (meetingId in state.entities && state.entities[meetingId].data) {
      state.entities[meetingId].data.notes = [...(state.entities[meetingId].data.notes ?? []), note]
    }
  }
}
