import moment from 'moment'
import { CreateActivityLogModalProps, CreateActivityLogModalRefs, CreateActivityLogModalState } from '.'
import { useAppDispatch } from '../../../../app/hooks'
import { EntityStatus } from '../../../../app/types'
import LuruUser from '../../../../domain/users/LuruUser'
import { CrmMiddleware } from '../../../../features/crm/middleware'
import { CrmActivity } from '../../../../features/crm/types'
import { UseCreateActivityLogModalComponentState } from './useComponentState'
import { cleanUrl } from '../../../../utils/Utils'
import { getBaseUrl } from '../../../../domutils/utils'
import { useMemo } from 'react'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'
import { DateUtils } from '@/utils/dateUtils'
import { PERIOD_TYPE } from '@/primitives/LuruTimePicker/types'

export default function useCreateActivityLogModalEventHandlers(
  sharedState: UseCreateActivityLogModalComponentState,
  props: CreateActivityLogModalProps,
  refs: CreateActivityLogModalRefs
) {
  const { showToast } = useLuruToast()
  const { sorRecord } = props
  const { connectedNote } = sharedState
  const { getState: state, setState } = sharedState.state
  const { getDraftActivity: draftActivity, setDraftActivity } = sharedState.draftActivity
  const dispatch = useAppDispatch()
  const crmKey = LuruUser.getCurrentUserReduxCrmKey()

  const handleHideModal = () => {
    setState((prevState) => ({ ...prevState, show: false }))
  }

  const handleShowModal = (
    stateObj?: Partial<CreateActivityLogModalState>,
    draftActivity?: Partial<CrmActivity & { time_picked?: Date; duration?: string }>
  ) => {
    setState((prevState) => ({ ...prevState, ...stateObj, show: true }))
    setDraftActivity((prevState) => ({ ...prevState, ...draftActivity }))
    if (draftActivity?.title) {
      refs.titleTextBoxRef.current?.setValue(draftActivity?.title)
    }
  }

  const handleCreateActivity = async () => {
    const payload = { ...draftActivity, connections: sorRecord ? [sorRecord] : [] }
    try {
      const formValidation = handleFormValidation(payload)
      if (!formValidation.ok) {
        setState((prevState) => ({
          ...prevState,
          formValidation: {
            ...formValidation,
            message: formValidation.missingFields?.[0]?.message ?? 'Please fill all required field!',
          },
        }))
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            formValidation: undefined,
          }))
        }, 2000)
        return
      }

      //Add selected Time to the final date
      const startTime = new Date(payload.start_time)
      if (payload.time_picked) {
        startTime.setHours(payload.time_picked?.getHours?.())
        startTime.setMinutes(payload.time_picked?.getMinutes?.())
      } else {
        startTime.setHours(new Date().getHours?.())
        startTime.setMinutes(new Date().getMinutes?.())
      }
      delete payload.time_picked

      const endTime = new Date(startTime)
      endTime.setMinutes(payload.duration ? startTime.getMinutes() + Number(payload.duration) : 30)

      delete payload.duration

      setState((prevState) => ({ ...prevState, createLogStatus: EntityStatus.Creating }))
      const luruNoteUrl = cleanUrl(`${getBaseUrl()}/notes/${props.noteID}`)
      await dispatch(
        CrmMiddleware.createActivityLog.action({
          crmKey,
          type: state.activityLogType,
          payload: {
            ...payload,
            notes: connectedNote?.body ? `Luru note: ${luruNoteUrl}` : '', // When logging the activity when the Luru note body is NOT empty, the link of the Luru note should be added to the description of the activity
            start_time: moment(startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
            end_time: moment(endTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          },
        })
      ).unwrap()
      setState((prevState) => ({ ...prevState, createLogStatus: EntityStatus.Created }))
      handleHideModal()
      showToast({
        id: ToastId.CRM_LOG_AS_LINK_CONTROLL_TOAST_ID,
        message: 'Log created',
        severity: 'success',
      })
      props?.onLogSuccess?.(payload)
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        createLogStatus: EntityStatus.ErrorCreating,
        formValidation: { ok: false, message: 'Error creating log' },
      }))
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          formValidation: undefined,
        }))
      }, 2000)
    }
  }

  const handleFormValidation = (payload: CrmActivity) => {
    const requiredFields = state.requiredFields
    const missingFields: Array<{ field: string; message?: string }> = []

    requiredFields.forEach((field) => {
      if (!(payload as any)[field]) {
        missingFields.push({ field, message: 'Please fill all required field!' })
      }
      if (field === 'connections' && payload.connections.length <= 0) {
        missingFields.push({ field, message: 'Required field' })
      }
      if (field === 'attendees' && payload.attendees.length <= 0) {
        missingFields.push({ field, message: 'Required field' })
      }
      if (field === 'duration' && Number((payload as any)?.[field]) < 0) {
        missingFields.push({ field, message: "Duration can't be Negative" })
      }
    })
    return {
      ok: missingFields.length === 0,
      missingFields,
    }
  }

  const getHourAndMinute = useMemo(() => {
    var date = props.timePicked ? new Date(props.timePicked) : undefined
    var hours = date?.getHours?.()
    var minutes = date?.getMinutes?.()
    var period = hours ? DateUtils.get12HourFormat(String(hours))?.period : PERIOD_TYPE.AM
    hours = hours && hours % 12
    hours = hours === 0 ? 12 : hours // the hour '0' should be '12'
    return {
      hours: String(hours),
      minutes: String(minutes),
      period,
    }
  }, [props.timePicked])

  return {
    handleHideModal,
    handleShowModal,
    handleCreateActivity,
    handleFormValidation,
    getHourAndMinute,
  }
}
