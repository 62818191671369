import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { LuruAppErrorCode, ReduxUserState } from '../types'

export const setAuthorizationErrorState: CaseReducer<
  ReduxUserState,
  PayloadAction<{}>
> = (state, action) => {
  if (!state.applicationStatus) {
    state.applicationStatus = {
      errorCode: LuruAppErrorCode.AUTHORIZATION_ERROR,
    }
  } else {
    state.applicationStatus.errorCode = LuruAppErrorCode.AUTHORIZATION_ERROR
  }
}
