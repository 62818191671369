import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod, LuruAPIResponse } from '../../../app/types'
import { DateUtils } from '@/utils/dateUtils'

export interface SearchWorkflowEventsGroupByWorkflowParameter {
  time_min: Date
  time_max: Date
}

export interface WorkflowEvent {
  data: {}
  entity_ids: {
    type: 'workflow' | 'user'
    id: string
  }[]
  error: {}
  event_id: string
  name: string
  records: { sor_record_id: string; sor_object_name: string }[]
  request_id: string
  run_id: string
  status: 'success'
  tenant_id: string
  timestamp: number
}

export interface SearchWorkflowEventsGroupedResponse extends LuruAPIResponse {
  data: Record<string, WorkflowEvent[]>
}

export const searchWorkflowEventsGroupByWorkflow = async (
  params: SearchWorkflowEventsGroupByWorkflowParameter,
  options?: LuruAPIRequestOptions
) => {
  var urlParams: Record<string, any> = { ...params }

  if (urlParams.time_min) {
    urlParams.time_min = DateUtils.toISOStringCurrentTZ(params.time_min)
  }

  if (urlParams.time_max) {
    urlParams.time_max = DateUtils.toISOStringCurrentTZ(params.time_max)
  }

  var queryString = new URLSearchParams(urlParams as URLSearchParams)
  var request = new LuruAPIRequest(`/api/events/workflows?${queryString}`, HTTPMethod.GET, options)

  return request.make()
}
