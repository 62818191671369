import React from 'react'
import CrmRecord from '../../../../../../domain/crmRecord/CrmRecord'

import styles from '../../styles.module.css'
import LuruButton from '../../../../../ui/LuruButton'
// Icons
import meetingsIcon from '../../../../../../images/calendar.png'
import { Link, NavigateFunction } from 'react-router-dom'
import Utils from '../../../../../../utils/Utils'
import LuruUser from '../../../../../../domain/users/LuruUser'
import LuruSelectBox from '../../../../../ui/LuruSelectBox'
import { OmniboxRefs } from '../../Omnibox'
import AppComponentsContext from '../../../../../AppComponents/AppComponentsContext'
import { AppComponents } from '../../../../../AppComponents/typings'
import { SearchMeetingsAPIResponseEntity } from '../../../../../../features/meetings/middleware/searchMeetings'
import { MeetingsAPI } from '../../../../../../features/meetings/api'
import { DateUtils } from '../../../../../../utils/dateUtils'
import { MeetingsSearchResults } from '../../../../../../forms/GlobalSearchForm/GlobalSearchFormComponent'
import personIcon from '../../../../../../images/fluent/person.svg'
import { CalendarProvider } from '../../../../../../features/user/types'
import { CrmRecordType } from '../../../../../../features/crm/types'
import { ToastId } from '@/app_ui/types'
import { ConnectLuruExtensionsProps } from '@/app/types'

interface MeetingsTabPanelProps extends ConnectLuruExtensionsProps {
  crmRecordType: CrmRecordType
  sorRecordName: string
  sorRecordId: string
  globalRefs?: OmniboxRefs
  navigate?: NavigateFunction
}

interface MeetingsTabPanelState {
  loading: boolean
  loadingMore: boolean
  loadingPrev: boolean
  defaultLimitCount: number
  defaultDaysToFetch: number
  data: Array<SearchMeetingsAPIResponseEntity>
  metadata: { count?: number; cursor?: string }
  error: any
  calendarName?: CalendarProvider
}

class MeetingsTabPanel extends React.Component<MeetingsTabPanelProps, MeetingsTabPanelState> {
  props: MeetingsTabPanelProps
  state: MeetingsTabPanelState
  static contextType?: React.Context<any> | undefined = AppComponentsContext

  constructor(props: MeetingsTabPanelProps) {
    super(props)
    this.props = props
    this.state = {
      loading: true,
      loadingMore: false,
      loadingPrev: false,
      data: [],
      error: null,
      defaultLimitCount: 5,
      defaultDaysToFetch: 10,
      metadata: {
        count: 10,
        cursor: undefined,
      },
      calendarName: LuruUser.getCurrentUserCalendarName(),
    }
  }

  #hasNextPage() {
    const { data } = this.state
    if (!data || data.length <= 0) {
      return false
    } else {
      // return metadata?.cursor !== null && metadata?.cursor !== undefined
      return true
    }
  }

  #renderCalendarConnectButton = (extraMsg?: string, isFromError: boolean = false) => {
    return (
      <div className={styles.noCalendarContainer}>
        <img src={meetingsIcon} alt='Connect your calendar' style={{ width: '2em' }} />
        <div>{extraMsg || 'Please connect to your calendar'}</div>
        <LuruButton
          onClick={() => {
            var appComponents = this.context as AppComponents
            this.props.globalRefs?.modal?.current?.cancel?.()
            appComponents.connectCalendarModal?.current?.showModal()
          }}
          title='Connect your calendar'
        >
          Connect your calendar
        </LuruButton>
      </div>
    )
  }

  #renderError = () => {
    const { error } = this.state
    // 10100 = No Calendar Connection or 10104 = Calendar view permission not given
    if (error?.error_code === 10100 || error?.error_code === 10104) {
      let extraMsg = ''
      if (error?.error_code === 10104) {
        extraMsg = `Uh-oh! It looks like you have not given 'View Calendar' permissions to Luru`
      }
      return this.#renderCalendarConnectButton(extraMsg, true)
    } else {
      return (
        <div className={styles.errorBlockContainer}>
          <div>Error occured</div>
          <LuruButton
            onClick={() => {
              this.#fetchMeetings()
            }}
            title='Fetch meetings'
          >
            Try Again
          </LuruButton>
        </div>
      )
    }
  }

  #fetchMeetings = async () => {
    try {
      if (!this.state.calendarName) {
        this.setState({ loading: false })
        return
      }
      const { defaultDaysToFetch } = this.state
      const minTime = new Date(DateUtils.getStartOfDay().valueOf() - defaultDaysToFetch * DateUtils.MILLISECS_PER_DAY)
      const maxTime = DateUtils.getEndOfDay()
      const { crmRecordType, sorRecordId } = this.props
      const sorObjectName: string = CrmRecord.getCrmRecordName(crmRecordType)

      this.setState({ error: null, loading: true })

      const meetingAPIRecords = (await MeetingsAPI.searchMeetings({
        key: 'meetingsTabPanel',
        sor_object_name: sorObjectName,
        sor_record_id: sorRecordId,
        time_min: DateUtils.toAPIDateString(minTime),
        time_max: DateUtils.toAPIDateString(maxTime),
      })) as MeetingsSearchResults

      this.setState({
        loading: false,
        data: meetingAPIRecords?.data || [],
        metadata: meetingAPIRecords.metadata || {},
      })
    } catch (error) {
      console.log('Error', error)
      this.setState({ loading: false, error: error })
    }
  }

  #onClickLoadMorePrev = async () => {
    try {
      var { data, defaultLimitCount } = this.state
      var maxTime = new Date(data?.[0]?.start_time)
      var { crmRecordType, sorRecordId } = this.props
      var sorObjectName: string = CrmRecord.getCrmRecordName(crmRecordType)

      this.setState({ loadingPrev: true })

      var meetingAPIRecords = (await MeetingsAPI.searchMeetings({
        key: 'meetingsTabPanel/loadPrev',
        sor_object_name: sorObjectName,
        sor_record_id: sorRecordId,
        limit: defaultLimitCount,
        time_max: maxTime.toISOString(),
      })) as MeetingsSearchResults

      var newData = [...(meetingAPIRecords?.data || []), ...this.state.data]
      var uniqueData = Array.from(new Set(newData.map((a) => a.meeting_id)))
        .map((meeting_id) => newData.find((a) => a.meeting_id === meeting_id))
        .filter(Boolean) as Array<SearchMeetingsAPIResponseEntity>

      this.setState({
        loadingPrev: false,
        data: uniqueData,
        metadata: meetingAPIRecords.metadata || {},
      })
    } catch (error) {
      this.props.toast.showToast({
        id: ToastId.OMNIBOX_TOAST_ID,
        message: 'Error while loading more meetings!',
        severity: 'error',
      })
      console.log('Error', error)
      this.setState({ loadingPrev: false })
    }
  }

  #onClickLoadMore = async () => {
    try {
      var { metadata, data, defaultLimitCount } = this.state
      var minTime = new Date(data?.slice?.(-1)?.[0]?.start_time)
      var { crmRecordType, sorRecordId } = this.props
      var sorObjectName: string = CrmRecord.getCrmRecordName(crmRecordType)

      this.setState({ loadingMore: true })

      var meetingAPIRecords = (await MeetingsAPI.searchMeetings({
        key: 'meetingsTabPanel/loadMore',
        sor_object_name: sorObjectName,
        sor_record_id: sorRecordId,
        limit: defaultLimitCount,
        cursor: metadata.cursor,
        time_min: minTime.toISOString(),
      })) as MeetingsSearchResults
      var newData = [...this.state.data, ...(meetingAPIRecords?.data || [])]
      var uniqueData = Array.from(new Set(newData.map((a) => a.meeting_id)))
        .map((meeting_id) => newData.find((a) => a.meeting_id === meeting_id))
        .filter(Boolean) as Array<SearchMeetingsAPIResponseEntity>

      this.setState({
        loadingMore: false,
        data: uniqueData,
        metadata: meetingAPIRecords.metadata || {},
      })
    } catch (error) {
      this.props.toast.showToast({
        id: ToastId.OMNIBOX_TOAST_ID,
        message: 'Error while loading more meetings!',
        severity: 'error',
      })
      console.log('Error', error)
      this.setState({ loadingMore: false })
    }
  }

  componentDidMount() {
    this.#fetchMeetings()
  }

  #onCreateMeetingNote(meeting: SearchMeetingsAPIResponseEntity) {
    this.props?.globalRefs?.modal?.current?.cancel?.()
    this.props?.navigate?.('/meeting_notes', {
      state: {
        action: 'create_new',
        meetingId: meeting.meeting_id,
        meetingSubject: meeting.subject,
      },
    })
  }

  #onChooseMeetingNote(noteId: string | null) {
    if (noteId === null) {
      return
    }

    this.props?.globalRefs?.modal?.current?.cancel?.()
    this.props?.navigate?.(`/meeting_notes/${noteId}`)
  }

  #renderMeetingItem(item: SearchMeetingsAPIResponseEntity) {
    var connectedNotes = item.notes || []
    var currentUserEmail = LuruUser.getCurrentUserEmail()
    var currentUserDomain = LuruUser.getCurrentUserDomain()
    var externalParticipant =
      item.participants.length > 1
        ? item.participants.find((i) => i.email.slice(i.email.indexOf('@') + 1) !== currentUserDomain)
        : undefined
    var externalParticipantName = externalParticipant?.name ?? externalParticipant?.email
    var internalParticipant =
      externalParticipantName === undefined ? item.participants.find((i) => i.email !== currentUserEmail) : undefined
    var internalParticipantName = internalParticipant?.name ?? internalParticipant?.email
    var participantDesc =
      externalParticipantName !== undefined
        ? externalParticipantName +
          ' + ' +
          (item.participants.length - 1) +
          ' other' +
          (item.participants.length > 2 ? 's' : '')
        : internalParticipantName !== undefined && internalParticipantName !== null
        ? internalParticipantName + (item.participants.length > 1)
          ? ' + ' + (item.participants.length - 1) + ' other' + (item.participants.length > 2 ? 's' : '')
          : ''
        : ''

    return (
      <div className={styles.meetingResult}>
        <label className={styles.title}>{item.subject}</label>
        <span className={styles.timestamp}>{Utils.formatDateTime(item.start_time)}</span>
        {connectedNotes.length === 0 ? (
          <span
            className={[styles.meetingNoteTitle, styles.buttonish].join(' ')}
            onClick={() => this.#onCreateMeetingNote(item)}
          >
            + Add note
          </span>
        ) : connectedNotes.length === 1 ? (
          <span className={styles.meetingNoteTitle}>
            <Link to={`/meeting_notes/${connectedNotes[0].note_id}`}>{connectedNotes[0].title}</Link>
          </span>
        ) : (
          <span className={styles.meetingNoteTitle}>
            <LuruSelectBox
              items={connectedNotes.map((note) => ({
                name: note.title,
                key: note.note_id,
              }))}
              onChooseItem={this.#onChooseMeetingNote}
              leftAlign={true}
              subdued={true}
              prechosenItem={`${connectedNotes.length} meeting notes`}
              menuParentSelector={'[data-luru-role="create-or-search-record-dialog"]'}
            />
          </span>
        )}
        <span className={styles.trailingIcon}>
          <img src={personIcon} alt='person' title={participantDesc} className={styles.participant} />
        </span>
      </div>
    )
  }

  #renderMeetingsList() {
    const { data } = this.state

    if (data.length <= 0) {
      return <div className={styles.noResults}>No results found</div>
    }

    return (
      <div>
        <div className={styles.loadMoreContainer}>
          <LuruButton
            title='Older meetings'
            loading={this.state.loadingPrev}
            onClick={this.#onClickLoadMorePrev}
            disabled={!this.#hasNextPage()}
            variant='outline'
          >
            Older
          </LuruButton>
        </div>
        <ul className={styles.resultsList} data-luru-role={`search-results-Meetings`}>
          {data.map((meeting) => (
            <li key={meeting.meeting_id}>{this.#renderMeetingItem(meeting)}</li>
          ))}
        </ul>
        <div className={styles.loadMoreContainer}>
          <LuruButton
            title='Newer meetings'
            loading={this.state.loadingMore}
            onClick={this.#onClickLoadMore}
            disabled={!this.#hasNextPage()}
            variant='outline'
          >
            Newer
          </LuruButton>
        </div>
      </div>
    )
  }

  render() {
    const { loading, error, calendarName } = this.state

    if (!calendarName) {
      return <div className={styles.noCalendar}>{this.#renderCalendarConnectButton()}</div>
    }

    if (error) {
      return <div className={styles.errorBlock}>{this.#renderError()}</div>
    }

    if (loading) {
      return (
        <div className={[styles.loading, styles.loadingBlock, styles.loadingBlockContainer].join(' ')}>Loading...</div>
      )
    }

    return (
      <div>
        <div className={styles.searchResults}>{this.#renderMeetingsList()}</div>
      </div>
    )
  }
}

export default React.forwardRef(Utils.connectLuruExtensions(MeetingsTabPanel))
