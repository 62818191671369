import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DeleteViewParameter } from '../middleware/deleteView'

export const deleteView = (
  params: Readonly<DeleteViewParameter>,
  options: LuruAPIRequestOptions
) => {
  var url = `/api/views/${params.viewId}`
  var request = new LuruAPIRequest(url, HTTPMethod.DELETE, options)

  return request.make()
}
