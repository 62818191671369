import { useAppSelector } from '@/app/hooks'
import { getCurrentUserRole } from '@/features/users/selectors/getCurrentUserRole'
import { LuruRole } from '@/features/users/types'
import { NotFound } from '@/primitives/Error'

export enum WebAppTabs {
  NOTES = 'notes',
  MEETING_NOTES = 'meeting_notes',
  WORKFLOWS = 'workflows',
  TASKS = 'tasks',
  SETTINGS = 'settings',
  PIPELINE = 'pipeline',
}

interface PrivateRouteProps {
  children: JSX.Element
  tab: WebAppTabs
}

export default function PrivateRoute({ children, tab }: PrivateRouteProps): JSX.Element {
  const currentUserRole = useAppSelector(getCurrentUserRole) as LuruRole

  switch (currentUserRole) {
    case LuruRole.ADMIN:
      switch (tab) {
        case WebAppTabs.MEETING_NOTES:
          return <NotFound message={undefined} />
        case WebAppTabs.TASKS:
          return <NotFound message={undefined} />
        default:
          return children
      }

    case LuruRole.REGULAR_USER:
      switch (tab) {
        case WebAppTabs.WORKFLOWS:
          return <NotFound message={undefined} />
        case WebAppTabs.MEETING_NOTES:
          return <NotFound message={undefined} />
        default:
          return children
      }

    default:
      return children
  }
}
