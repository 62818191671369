import React from 'react'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { ConnectLuruExtensionsProps, EntityStatus } from '../../../../app/types'
import { CrmObjectSchema, CrmRecordFieldsData } from '../../../../domain/crmRecord/typings.d'
import ModalScreen from '../../../ModalScreen'
import LuruCrmFieldInputSet from '../LuruCrmFieldInputSet'
import OmniboxEventHandler from './OmniboxEventHandler'

import styles from './styles.module.css'
import NewNotesTabPanel from './components/NewNotesTabPanel'
import TasksTabPanel from './components/TasksTabPanel'
import MeetingsTabPanel from './components/NewNotesTabPanel/MeetingsTabPanel'
import LuruCollectionsComponent from '../../collections/LuruCollections/LuruCollectionsComponent'
import LuruCollectionsQuickAccessBar from '../../collections/LuruCollectionsQuickAccessBar'
import LuruUser from '../../../../domain/users/LuruUser'
import AppComponentsContext from '../../../AppComponents/AppComponentsContext'
import { AppComponents } from '../../../AppComponents/typings'
import { CrmRecordType } from '../../../../features/crm/types'
import { OmniboxConnectedProps } from '.'
import Tabs from '@/primitives/Tabs'
import LuruButton from '@/primitives/ui/LuruButton'

export interface OmniboxProps extends OmniboxConnectedProps, ConnectLuruExtensionsProps {
  id: string
}

export enum FieldsetSelection {
  DEFAULT = 'DEFAULT',
  COLLECTION = 'COLLECTION',
  MANUAL = 'MANUAL',
}

export enum TabSections {
  DETAILS = 'Details',
  NOTES = 'Notes',
  TASKS = 'Tasks',
  MEETINGS = 'Meetings',
}

interface OmniboxState {
  crmRecordType?: CrmRecordType
  sorRecordId?: string
  sorRecordName?: string
  crmRecordStatus: EntityStatus
  updateRecordStatus: EntityStatus
  fieldNames: Array<string>
  fieldValues: { [fieldName: string]: any }
  mandatoryFields?: Array<string>
  missedMandatoryFields: Array<string>
  alertMessage?: string
  fieldsetSelectionMode: FieldsetSelection
  currentTabName: string
}

export interface OmniboxRefs {
  modal: React.RefObject<ModalScreen>
  luruCollectionsRef: React.RefObject<LuruCollectionsComponent>
}

export default class Omnibox extends React.Component<OmniboxProps, OmniboxState> {
  static contextType = AppComponentsContext
  props: OmniboxProps
  state: OmniboxState
  componentRefs: OmniboxRefs
  eventHandler: OmniboxEventHandler
  #objectSchema?: CrmObjectSchema

  constructor(props: OmniboxProps) {
    super(props)
    this.props = props
    this.state = {
      crmRecordType: undefined,
      sorRecordId: undefined,
      sorRecordName: undefined,
      crmRecordStatus: EntityStatus.NotLoaded,
      updateRecordStatus: EntityStatus.Idle,
      fieldNames: [],
      fieldValues: {},
      fieldsetSelectionMode: FieldsetSelection.DEFAULT,
      mandatoryFields: [],
      missedMandatoryFields: [],
      alertMessage: undefined,
      currentTabName: TabSections.DETAILS,
    }
    this.componentRefs = {
      modal: React.createRef(),
      luruCollectionsRef: React.createRef(),
    }
    this.eventHandler = new OmniboxEventHandler(this)
  }

  showDialog(
    crmRecordType: CrmRecordType,
    sorRecordId: string,
    sorRecordName: string,
    defaultTabName: TabSections = TabSections.DETAILS
  ) {
    this.componentRefs.modal.current?.showModal()
    this.eventHandler.loadOmnibox(crmRecordType, sorRecordId, sorRecordName, defaultTabName)
  }

  render() {
    var objectName = CrmRecord.getCrmRecordNameSingular(this.state.crmRecordType || '') ?? this.state.crmRecordType
    const crmRecordIcon = CrmRecord.getIcon(this.state.crmRecordType ?? '')
    const sorObjectName: string = CrmRecord.getCrmRecordName(this.state.crmRecordType ?? '', this.state.crmRecordType)
    var recordName = this.state.sorRecordName ?? ''
    const crmId = LuruUser.getCurrentUserCrmName()
    var modalTitle = (
      <div className={styles.modalTitle}>
        <span>
          {crmRecordIcon && (
            <img
              src={crmRecordIcon}
              alt={sorObjectName}
              data-object-name={this.state.crmRecordType}
              data-crm-id={crmId}
            />
          )}
        </span>
        <span>
          {objectName !== ''
            ? `${objectName}: ${
                recordName ? recordName : this.state.crmRecordStatus === EntityStatus.Loading ? 'Loading...' : ''
              }`
            : 'Loading...'}
        </span>
      </div>
    )

    return (
      <ModalScreen
        ref={this.componentRefs.modal}
        id={this.props.id}
        width='600px'
        height='auto'
        hideButtons={true}
        title={modalTitle}
        titleCloseButton={true}
        dialogRole='omnibox'
      >
        <div className={styles.omniboxBody}>
          {this.state.crmRecordStatus === EntityStatus.Loaded
            ? this.#renderLoadedState()
            : this.state.crmRecordStatus === EntityStatus.ErrorLoading
            ? this.#renderErrorState()
            : this.#renderLoadingState()}
        </div>
      </ModalScreen>
    )
  }

  #renderNotLoadedState() {
    return <div className='hidden'>Omnibox</div>
  }

  #renderLoadingState() {
    return (
      <div className={[styles.loading, styles.loadingBlock, styles.loadingBlockContainer].join(' ')}>Loading...</div>
    )
  }

  #renderErrorState() {
    return <div className={styles.error}>Error loading record</div>
  }

  #renderLoadedState() {
    if (this.state.crmRecordType === undefined) {
      return
    }

    var collectionQuickAccessBar = (
      <LuruCollectionsQuickAccessBar
        crmRecordType={this.state.crmRecordType}
        onChooseCollection={this.eventHandler.handlers.onChooseCollection}
        ref={this.componentRefs.luruCollectionsRef}
        luruFieldSetChooserProps={{
          crmRecordType: this.state.crmRecordType,
          selectedFieldNames: this.state.fieldNames,
          disableMandatory: true,
          onChooseFields: this.eventHandler.handlers.onChooseFields,
          manualFields: Object.keys(this.state.fieldValues), //Restrict to recordFields only
        }}
      />
    )

    const detailsTab = (
      <div className={styles.detailsTab}>
        {this.state.alertMessage !== undefined ? <div className={styles.alert}>{this.state.alertMessage}</div> : null}
        <div className={styles.quickAccessContainer}>{collectionQuickAccessBar}</div>
        <div className={styles.omnibox}>{this.#renderFields()}</div>
        <div className={styles.actionButtons}>
          <LuruButton title='Cancel' variant='outline' onClick={this.eventHandler.handlers.onClickCancelButton}>
            Cancel
          </LuruButton>
          {this.state.crmRecordStatus === EntityStatus.Loaded && (
            <LuruButton
              title='Update'
              onClick={this.eventHandler.handlers.onClickUpdateButton}
              disabled={this.state.updateRecordStatus === EntityStatus.Updating}
              loading={this.state.updateRecordStatus === EntityStatus.Updating}
            >
              {this.state.updateRecordStatus === EntityStatus.Updating ? 'Updating' : 'Update'}
            </LuruButton>
          )}
        </div>
      </div>
    )

    const notesTab = (
      <NewNotesTabPanel
        key={`${this.state.crmRecordType}-${this.state.sorRecordId}`}
        crmRecordType={this.state.crmRecordType}
        sorRecordId={this.state.sorRecordId}
        sorRecordName={this.state.sorRecordName}
        onNavigateAway={() => {
          this.componentRefs.modal.current?.cancel()
          var appComponents = this.context as AppComponents
          appComponents?.createSearchRecordDialog?.current?.closeModal?.()
        }}
        explorerStyle={{ display: 'flex' }}
      />
    )

    const tasksTab = (
      <TasksTabPanel
        globalRefs={this.componentRefs}
        sorRecordName={this.state.sorRecordName || ''}
        sorRecordId={this.state.sorRecordId || ''}
        crmRecordType={this.state.crmRecordType}
        onClickNewTask={() => {
          var appComponents = this.context as AppComponents
          this.componentRefs.modal.current?.cancel()
          appComponents?.createSearchRecordDialog?.current?.closeModal?.()
          appComponents?.taskViewModal?.current?.showModal(null, undefined, {
            sor_object_name: CrmRecord.getCrmRecordName(this.state.crmRecordType ?? ''),
            sor_record_id: this.state.sorRecordId,
            sor_record_name: this.state.sorRecordName,
          })
        }}
      />
    )

    const meetingsTab = (
      <MeetingsTabPanel
        globalRefs={this.componentRefs}
        sorRecordName={this.state.sorRecordName || ''}
        sorRecordId={this.state.sorRecordId || ''}
        crmRecordType={this.state.crmRecordType}
        navigate={this.props.router?.navigate}
      />
    )

    const tabs = [
      { name: TabSections.DETAILS, content: detailsTab },
      { name: TabSections.NOTES, content: notesTab },
      { name: TabSections.TASKS, content: tasksTab },
      { name: TabSections.MEETINGS, content: meetingsTab },
    ]

    return <Tabs tabs={tabs} />
  }

  #renderFields() {
    if (this.state.crmRecordType === undefined) {
      return null
    }

    switch (this.state.crmRecordStatus) {
      case EntityStatus.Loaded:
        return (
          <LuruCrmFieldInputSet
            sorRecordId={this.state.sorRecordId}
            crmRecordType={this.state.crmRecordType}
            fieldNames={this.state.fieldNames}
            onChange={this.eventHandler.handlers.onFieldValueChange}
            menuParentSelector={`#${this.props.id}`}
            alertFieldNames={this.state.missedMandatoryFields}
            showErroneousFields={true}
          />
        )

      case EntityStatus.ErrorLoading:
        return <div className={styles.error}>Error fetching field names</div>
    }

    return <div className={styles.loading}>Loading...</div>
  }

  setSchemaAndValues(schema: CrmObjectSchema, recordFields: CrmRecordFieldsData) {
    var fieldValues = {} as { [fieldName: string]: any }
    for (let fieldName in recordFields.record) {
      fieldValues[fieldName] = recordFields.record[fieldName].value
    }

    var nameField = schema?.payload?.fields?.find((f) => f.nameField)?.name
    var sorRecordName = nameField ? fieldValues[nameField] : undefined

    this.#objectSchema = schema
    this.setState({
      // fieldNames: schema.payload?.fields?.map((f) => f.name) ?? [],
      fieldNames: Object.keys(fieldValues) ?? [], // Use fieldNames from recordFields, so we will show only the fields that is part of the recordFields
      crmRecordStatus: EntityStatus.Loaded,
      mandatoryFields: schema.payload?.fields?.filter((f) => f.isMandatory).map((f) => f.name),
      fieldValues,
      sorRecordName: this.state.sorRecordName || sorRecordName,
    })
  }

  setFieldValue(fieldName: string, value: any) {
    var newFieldValues: { [_: string]: any } = {}

    if (this.state.fieldValues !== undefined) {
      Object.assign(newFieldValues, this.state.fieldValues)
    }

    newFieldValues[fieldName] = value
    this.setState({ fieldValues: newFieldValues })
  }

  getObjectSchema() {
    return this.#objectSchema
  }
}
