import { useRef, useEffect } from 'react'
import { useGetSetState, useOutsideClick } from '../hooks/luru_hooks'
import { useSelector, useDispatch } from 'react-redux'
import { getSORConnection } from '../features/notes/selectors/getSORConnection'
import { crmMiddleware, getFormattedCrmFieldValue } from '../features/crm/crmMiddleware'
import { getSorObjectNameFilter } from '../features/noteTemplates/selectors/getSorObjectNameFilter'
import { scrollYToElement } from '../domutils/utils'
// import CrmRecord from '../domain/crmRecord/CrmRecord'
import styles from './css/CRMFieldChooser.module.css'
import editIcon from '../images/edit-icon.svg'
import readonlyIcon from '../images/readonly-icon.svg'
import json5 from 'json5'
import LuruButton from './ui/LuruButton'
// import LuruCollectionsQuickAccessBar from './domain/collections/LuruCollectionsQuickAccessBar'
// import { CollectionsMiddleware } from '../features/collections/middleware'
import { EntityStatus } from '../app/types'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'
// import { trackEvent } from '../analytics/Ga'

export const CRMFieldChooserType = {
  Schema: 'schema',
  Record: 'record',
}

/**
 * Component for choosing field(s) from a CRM record
 * @param {Object} props { entityId }
 * @returns {JSX} - CRMFieldChooser component
 */
export default function CRMFieldChooser(props) {
  //// Component props and derived props like values
  const key = props.entityId.slice(0, 7)
  const controlId = `crm-field-chooser-${key}`
  const linkRecordId = `crm-link-popup-${key}`
  const linkObjectId = `crm-object-link-popup-${key}`
  const parentCallerId = props.callerId ?? 'home'
  const callerId = `${parentCallerId}/CRMLinkControl`
  const chooserType = props.type ?? CRMFieldChooserType.Record
  const { showToast } = useLuruToast()

  //// Component state related
  const dispatch = useDispatch()
  let state = {
    linkRecordPopupShown: useGetSetState(false),
    editorMenuContext: useGetSetState(null),
    crmId: useSelector((s) => s.user.data?.userSettings?.connectedCRM?.name),
    selectedIndex: useGetSetState(-1),
    filterText: useGetSetState(''),
    selectedCollection: useGetSetState(undefined),
  }

  state.linkedCRMRecord = useSelector(getSORConnection(props.entityId, state.crmId, callerId))

  state.linkedObjectName = useSelector(getSorObjectNameFilter(props.entityId))

  state.recordDetails = useSelector((reduxStore) => {
    if (chooserType === CRMFieldChooserType.Record) {
      let crmId = state.crmId.toLowerCase()
      let objectName = state.linkedCRMRecord?.connection?.sor_object_name
      let recordId = state.linkedCRMRecord?.connection?.sor_record_id
      let record = reduxStore.crm[crmId]?.entities?.[objectName]?.[recordId]

      if (!record) {
        return null
      }
      return record
    } else {
      return null
    }
  })

  state.schema = useSelector((reduxStore) => {
    if (chooserType === CRMFieldChooserType.Schema) {
      let crmId = state.crmId.toLowerCase()
      let objectName = state.linkedObjectName
      return reduxStore.crm[crmId]?.schema?.[objectName]
    } else {
      return null
    }
  })

  state.displayedRecords =
    chooserType === CRMFieldChooserType.Record
      ? state.recordDetails?.status === EntityStatus.Loaded
        ? Object.entries(state.recordDetails.data ?? {})
            .filter(([fieldName, fieldDetails]) => {
              if (state.selectedCollection.get() && state.filterText.get()?.includes('collection:')) {
                return state.selectedCollection.get()?.fields?.includes(fieldName)
              } else {
                return (
                  state.filterText.get()?.trim() === '' ||
                  (fieldDetails?.schema?.label ?? fieldName)
                    .toLowerCase()
                    .indexOf(state.filterText.get()?.toLowerCase()) !== -1
                )
              }
            })
            .reduce(
              (prev, [fieldName, fieldDetails]) => ({
                ...prev,
                [fieldName]: fieldDetails,
              }),
              {}
            )
        : null
      : state.schema?.status === EntityStatus.Loaded
      ? state.schema.data.fields
          ?.filter?.((field) => {
            if (state.selectedCollection.get() && state.filterText.get()?.includes('collection:')) {
              return state.selectedCollection.get()?.fields?.includes(field.name)
            } else {
              return (
                state.filterText.get()?.trim() === '' ||
                field.label.toLowerCase().indexOf(state.filterText.get()?.toLowerCase()) !== -1
              )
            }
          })
          ?.reduce?.((prev, field) => ({ ...prev, [field.name]: field }), {})
      : null
  //// Component refs
  let refs = {
    control: useRef(),
    filterBox: useRef(),
    searchResults: useRef(),
    luruCollectionsRef: useRef(),
  }

  useEffect(() => {
    setSelectionRange()
    if (!state.filterText.get()?.includes('collection:')) {
      refs?.luruCollectionsRef?.current?.setState({
        selectedCollectionId: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filterText.get()])

  //// Component views
  function render() {
    let content = ''
    let filterBox = ''
    let footer = ''

    const isPendingState =
      (chooserType === CRMFieldChooserType.Record &&
        (!state.recordDetails || state.recordDetails?.status === EntityStatus.Loading)) ||
      (chooserType === CRMFieldChooserType.Schema && (!state.schema || state.schema?.status === EntityStatus.Loading))

    if (isPendingState) {
      content = <div className={styles.noresults}>Fetching...</div>
    }

    const isRejectedState =
      (chooserType === CRMFieldChooserType.Record && state.recordDetails?.status === EntityStatus.ErrorLoading) ||
      (chooserType === CRMFieldChooserType.Schema && state.schema?.status === EntityStatus.ErrorLoading)

    if (isRejectedState) {
      content = <div className={styles.error}>Error: {state.recordDetails.error.toString()}</div>
    }

    const isFulfilledState =
      (chooserType === CRMFieldChooserType.Record && state.recordDetails?.data && state.displayedRecords !== null) ||
      (chooserType === CRMFieldChooserType.Schema && state.schema?.data && state.displayedRecords !== null)

    if (isFulfilledState) {
      content = renderFieldsList()
      filterBox = (
        <input
          //id='filter-input-box'
          type='search'
          ref={refs.filterBox}
          className={styles.filterBox}
          placeholder='Filter or choose a field'
          value={state.filterText.get()}
          onChange={eventHandlers.onFilterBoxChange}
          onFocus={(e) => {
            setSelectionRange()
          }}
        />
      )
      footer = (
        <footer>
          <div>Esc: Cancel</div>
          <div>
            Missing any field?&nbsp;&nbsp;
            <a href='mailto:info@luru.app'>Let us know</a>
          </div>
          <div>
            {'\u2191'} or {'\u2193'}: Navigate
          </div>
        </footer>
      )
    }

    // var crmRecordType = CrmRecord.getCrmRecordType(
    //   state.linkedCRMRecord?.connection?.sor_object_name
    // )

    // var collectionsetChooser =
    //   state.linkedCRMRecord?.connection?.sor_object_name !== undefined ? (
    //     <div style={{ marginTop: '0.5em' }}>
    //       <LuruCollectionsQuickAccessBar
    //         crmRecordType={crmRecordType}
    //         onChooseCollection={handleChooseCollection}
    //         ref={refs.luruCollectionsRef}
    //         hidefieldSetChooser={true}
    //         showTitle={true}
    //       />
    //     </div>
    //   ) : null

    var insertCollectionButton =
      state.selectedCollection.get() && state.filterText.get()?.includes('collection:') ? (
        <LuruButton
          extraClassNames={[styles.insertCollection]}
          onClick={() => {
            handleChooseFieldSet(json5.parse(json5.stringify(state.selectedCollection.get()?.fields || [])))
          }}
          title='Insert collection'
          variant='link'
        >
          Insert Collection
        </LuruButton>
      ) : null

    return (
      <div className={[styles.parent, styles[chooserType]].join(' ')} ref={refs.control} id={controlId} key={controlId}>
        {/* {collectionsetChooser}
        {collectionsetChooser ? (
          <div className={styles.dividerContainer}>
            <hr className={styles.divider} />
            <span className={styles.text}>OR</span>
            <hr className={styles.divider} />
          </div>
        ) : null} */}
        <div className={styles.filterBoxContainer}>
          <div style={{ flexGrow: 1 }}>{filterBox}</div>
          <div style={{ width: 'auto', marginLeft: '0.2em' }}>{insertCollectionButton}</div>
        </div>
        {/* {filterBox} */}
        <div className={styles.results}>{content}</div>
        {footer}
      </div>
    )
  }

  function renderFieldsList() {
    return chooserType === CRMFieldChooserType.Record
      ? renderRecordsFieldsList(state.displayedRecords)
      : renderSchemaFieldsList(state.displayedRecords)
  }

  function renderSchemaFieldsList(fields) {
    return (
      <ul className={styles.loaded} ref={refs.searchResults}>
        {Object.keys(fields)?.map((fieldName, index) => (
          <li
            key={`result-${index}`}
            onClick={handleChooseSchemaField}
            className={state.selectedIndex.get() === index ? styles.selected : ''}
            data-field-result={true}
            data-field-name={fieldName}
            data-result-index={index}
          >
            {/*
            // TODO: Insert data type icon here
             <img
              src={
                crmIcons[state.crmId.toLowerCase()][
                  item.sor_object_name.toLowerCase()
                ]
              }
              alt={item.sor_object_name}
            /> */}
            <div className={styles.field}>
              <div className={styles.schemaFieldName} title={fields[fieldName].label}>
                {fields[fieldName].label}
              </div>
            </div>
          </li>
        ))}
      </ul>
    )
  }

  function renderRecordsFieldsList(fields) {
    return (
      <ul className={styles.loaded} ref={refs.searchResults}>
        {Object.keys(fields)?.map((fieldName, index) => {
          if (!fields[fieldName]?.schema) {
            return null
          }

          return (
            <li
              key={`result-${index}`}
              onClick={handleChooseField}
              className={state.selectedIndex.get() === index ? styles.selected : ''}
              data-field-result={true}
              data-field-name={fieldName}
              data-result-index={index}
            >
              {/*
            // TODO: Insert data type icon here
             <img
              src={
                crmIcons[state.crmId.toLowerCase()][
                  item.sor_object_name.toLowerCase()
                ]
              }
              alt={item.sor_object_name}
            /> */}
              <div className={styles.field}>
                <div className={styles.fieldName} title={fields[fieldName].schema.label}>
                  {fields[fieldName].schema.label}
                </div>
                {fields[fieldName].schema.updateable ? (
                  <img src={editIcon} alt='Edit' style={{ height: '1em', marginRight: '0.5em' }} />
                ) : (
                  <img src={readonlyIcon} alt='Readonly' style={{ height: '1em', marginRight: '0.5em' }} />
                )}
                {fields[fieldName].value === null ? (
                  <div className={styles.nullValue}>&lt;not set&gt;</div>
                ) : (
                  <div className={styles.fieldValue}>{getFormattedCrmFieldValue(fields[fieldName])}</div>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  //// Component commands
  function handleChooseField(event) {
    let choiceIndex =
      event.type === 'click' ? parseInt(event.currentTarget.dataset.resultIndex, 10) : state.selectedIndex.get()

    let chosenField = Object.keys(state.displayedRecords).find((_, index) => index === choiceIndex)

    if (chosenField) {
      // We can return the field chosen
      let context = state.editorMenuContext.get()
      if (context?.onResponseReady instanceof Function) {
        context.onResponseReady({
          crmId: state.crmId,
          linkedRecord: state.linkedCRMRecord,
          field: {
            name: chosenField,
            value: state.recordDetails.data[chosenField].value,
            schema: state.recordDetails.data[chosenField].schema,
          },
        })
      }
    }
    hideFieldChooser()
    goBackToNotesEditor({ trace: 'Handle choose field' })
  }

  function handleChooseFieldSet(fieldNames) {
    let context = state.editorMenuContext.get()
    if (fieldNames.length > 0 && context?.onResponseReady instanceof Function) {
      context.onResponseReady({
        crmId: state.crmId,
        linkedRecord: state.linkedCRMRecord,
        fieldSet: fieldNames.map((fieldName) => ({
          name: fieldName,
          value: state.recordDetails.data[fieldName].value,
          schema: state.recordDetails.data[fieldName].schema,
        })),
      })
    }
    hideFieldChooser()
    goBackToNotesEditor({ trace: 'Handle choose field' })
  }

  // async function handleChooseCollection(collectionId) {
  //   if (collectionId) {
  //     var collection = await dispatch(
  //       CollectionsMiddleware.fetchCollection.action({ collectionId })
  //     ).unwrap()

  //     state.selectedCollection.set(collection)
  //     state.filterText.set(`collection:${collection.name}`)
  //     trackEvent('use_collection', 'CRMFieldChooser')
  //   }
  // }

  function handleChooseSchemaField(e) {
    let choiceIndex = e.type === 'click' ? parseInt(e.currentTarget.dataset.resultIndex, 10) : state.selectedIndex.get()

    let chosenField = Object.keys(state.displayedRecords).find((_, index) => index === choiceIndex)
    if (chosenField) {
      // We can return the field chosen
      let context = state.editorMenuContext.get()
      if (context?.onResponseReady instanceof Function) {
        context.onResponseReady({
          crmId: state.crmId,
          linkedObjectName: state.linkedObjectName,
          field: {
            name: chosenField,
            schema: state.schema.data.fields.find((field) => field.name === chosenField),
          },
        })
      }
    }
    hideFieldChooser()
    goBackToNotesEditor({ trace: 'Handle chooseSchemaField' })
  }

  function showFieldChooser() {
    refs.control?.current?.classList.add(styles.visible)
    // Focus on filter box if available
    setTimeout(() => refs.filterBox.current?.focus(), 50)
  }

  function repositionCaretInEditor({ rangeContainer, rangeOffset }) {
    const container = state.editorMenuContext.get()
      ? state.editorMenuContext.get().range.endContainer
      : rangeContainer !== undefined
      ? rangeContainer
      : null
    const offset = state.editorMenuContext.get()
      ? state.editorMenuContext.get().range.endOffset
      : rangeOffset !== undefined
      ? rangeOffset
      : null

    if (container !== null && offset !== null) {
      let repositionRange = new Range()
      repositionRange.setEnd(container, offset)
      repositionRange.collapse(false)
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(repositionRange)
    }
  }

  function goBackToNotesEditor({ trace, rangeContainer, rangeOffset }) {
    try {
      // Check if editor caret is available and set range back there
      repositionCaretInEditor({ rangeContainer, rangeOffset })
      if (state.editorMenuContext.get() !== null) {
        state.editorMenuContext.set(null)
        state.selectedIndex.set(-1)
        state.filterText.set('')
        state.linkRecordPopupShown.set(false)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  function hideFieldChooser() {
    state.filterText.set('')
    refs.control?.current?.classList.remove(styles.visible)
    refs?.luruCollectionsRef?.current?.updateState?.({
      selectedCollectionId: undefined,
    })
  }

  function setSelectionRange() {
    if (state.filterText.get()?.includes('collection:')) {
      const filterBox = refs.filterBox.current
      filterBox?.focus?.()
      filterBox?.setSelectionRange?.(0, state.filterText.get()?.length)
    }
  }

  function handleLinkRecord(recordInfo) {
    if (recordInfo === null) {
      try {
        goBackToNotesEditor({
          trace: 'After cancel link',
          rangeContainer: recordInfo?.rangeContainer,
          rangeOffset: recordInfo?.rangeOffset,
        })
      } catch (e) {
        console.warn(e)
      }
      return
    }

    let sorObjectName = recordInfo.sorObjectName
    let sorRecordId = recordInfo.sorRecordId

    if (!sorObjectName || !sorRecordId) {
      hideFieldChooser()
      goBackToNotesEditor({
        trace: 'After link without SoR object/record',
        rangeContainer: recordInfo?.rangeContainer,
        rangeOffset: recordInfo?.rangeOffset,
      })
      return
    }

    if (state.recordDetails?.status !== EntityStatus.Loaded) {
      showToast({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: 'Fetching fields',
        isLoading: true,
      })
      state.linkRecordPopupShown.set(false)
      getRecordFields(sorObjectName, sorRecordId)
    } else {
      showFieldChooser()
    }
  }

  function handleLinkObject(objectInfo) {
    if (!objectInfo) {
      goBackToNotesEditor({ trace: 'Handle link object, object=null' })
      return
    }

    const { noteTemplateId, filter } = objectInfo

    if (!noteTemplateId || !filter) {
      hideFieldChooser()
      goBackToNotesEditor({
        trace: 'Handle link object, field/noteTemplate=null',
      })
      return
    }

    if (state.objectSchema?.status !== EntityStatus.Loaded) {
      showToast({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: 'Fetching schema',
        isLoading: true,
      })
      state.linkRecordPopupShown.set(false)
      getObjectSchema(noteTemplateId, filter.sorObjectName)
    } else {
      showFieldChooser()
    }
  }

  /**
   * Get fields of the connected record and call editor controller callback
   * @param {string} sorObjectName - Name of SOR object, e.g.: Opportunity
   * @param {string} sorRecordId - Id of SOR record object
   */
  function getRecordFields(sorObjectName, sorRecordId) {
    showToast({
      id: ToastId.NOTES_EDITOR_TOAST_ID,
      message: 'Fetching fields',
      isLoading: true,
    })
    dispatch(
      crmMiddleware[state.crmId.toLowerCase()].getRecordFields.action({
        sorObjectName,
        sorRecordId,
      })
    )
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Fetched fields',
          severity: 'success',
        })
      })
      .catch((error) => {
        console.warn(error)
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: error.message,
          severity: 'error',
        })
        hideFieldChooser()
        goBackToNotesEditor({ trace: 'Error in getRecordFields' })
      })
  }

  /**
   * Get fields of the connected object and call editor controller callback
   * @param {string} sorObjectName - Name of SOR object, e.g.: Opportunity
   */
  function getObjectSchema(noteTemplateId, sorObjectName) {
    showToast({
      id: ToastId.NOTES_EDITOR_TOAST_ID,
      message: `Fetching schema for ${sorObjectName}`,
      isLoading: true,
    })
    dispatch(
      crmMiddleware[state.crmId.toLowerCase()].getObjectSchema.action({
        sorObjectName,
        noteTemplateId,
      })
    )
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: `Fetched schema for ${sorObjectName}`,
          severity: 'success',
        })
      })
      .catch((error) => {
        console.warn(error)
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: error.message,
          severity: 'error',
        })
        hideFieldChooser()
        goBackToNotesEditor({ trace: 'Error in getObjectSchema' })
      })
  }

  //// Component event handlers (these are children-facing event handlers)
  const eventHandlers = {
    // Handler for receiving request for list of CRM fields
    onChooseFieldRequest: (e) => {
      let shouldHandleEvent = e.detail?.onResponseReady && e.detail.onResponseReady instanceof Function

      if (!shouldHandleEvent) {
        return
      }

      // Store the menu context so we can get back to editor correctly later
      // after CRM field has been chosen
      state.editorMenuContext.set(e.detail)

      // When we are choosing field for a record connection...
      // Check if there is connection; if no connection, show link to record
      // popup first.  Achieving this by dispatching a custom DOM event
      if (chooserType === CRMFieldChooserType.Record && !state.linkedCRMRecord?.connection) {
        // We should show UI to enable user to link to a CRM record first
        let eventConfig = {
          detail: {
            onLinkRecord: handleLinkRecord,
            rangeContainer: e.detail.range.endContainer,
            rangeOffset: e.detail.range.endOffset,
            userPromptMessage: 'Enter the name of a CRM record to connect',
            scrollY: e.detail.scrollY ?? 0,
          },
        }
        state.linkRecordPopupShown.set(true)
        document.getElementById(linkRecordId).dispatchEvent(new CustomEvent('linkrecordrequest', eventConfig))

        return
      }

      // When we are choosing field for a schema association (note template)...
      // Check if there is association; if no association, show link to object
      // popup first.  Achieving this by dispatching a custom DOM event
      if (chooserType === CRMFieldChooserType.Schema && (!state.linkedObjectName || state.linkedObjectName === 'All')) {
        // We should show UI to enable user to link to a CRM record first
        let eventConfig = {
          detail: {
            onLinkObject: handleLinkObject.bind(this),
            userPromptMessage: 'Choose object for playbook to insert fields',
            scrollY: e.detail.scrollY ?? 0,
          },
        }
        state.linkRecordPopupShown.set(true)
        document.getElementById(linkObjectId).dispatchEvent(new CustomEvent('linkobjectrequest', eventConfig))

        return
      }

      // TODO: If pipeline is also to be chosen, show that popup

      // A connection is available, so dispatch a request to get fields for
      // this record.  When the record's state changes as a result of this
      // request, this component would be re-rendered as we are using
      // useSelector() to read this record data
      if (
        chooserType === CRMFieldChooserType.Record &&
        state.recordDetails?.status !== EntityStatus.Loaded &&
        state.linkedCRMRecord.connection
      ) {
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Fetching fields',
          isLoading: true,
        })
        getRecordFields(
          state.linkedCRMRecord.connection.sor_object_name,
          state.linkedCRMRecord.connection.sor_record_id
        )
      } else if (
        chooserType === CRMFieldChooserType.Schema &&
        state.schema?.status !== EntityStatus.Loaded &&
        state.linkedObjectName &&
        state.linkedObjectName !== 'All'
      ) {
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Fetching schema',
          isLoading: true,
        })
        getObjectSchema(props.entityId, state.linkedObjectName)
      } else {
        showFieldChooser()
      }
    },

    // Handler for capturing keyboard events from search box.  Used for navigating
    // search results list items
    onFilterBoxKeyDown: (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        let currentIndex = state.selectedIndex.get()
        if (e.key === 'ArrowDown') {
          state.selectedIndex.set((currentIndex + 1) % Object.keys(state.displayedRecords).length)
        } else {
          state.selectedIndex.set(
            currentIndex === 0 ? Object.keys(state.displayedRecords).length - 1 : currentIndex - 1
          )
        }
        // Set the state for currently highlighted item
        e.preventDefault()
      } else if (e.key === 'Escape') {
        hideFieldChooser()
        goBackToNotesEditor({ trace: 'Escape key pressed in filter box' })
        // Set the state for currently highlighted item
        e.preventDefault()
        e.stopPropagation()
      } else if (e.key === 'Enter') {
        if (state.selectedIndex.get() >= 0) {
          if (chooserType === CRMFieldChooserType.Record) {
            handleChooseField(e)
          } else {
            handleChooseSchemaField(e)
          }
          e.preventDefault()
        }
      }
    },

    // Handler for change in filter box
    onFilterBoxChange: (e) => {
      // state.filterText.set(refs.filterBox.current?.value)
      state.filterText.set(e.target.value)
      if (state.filterText.get()?.includes('collection:')) {
        e.target.setSelectionRange(0, state.filterText.get()?.length)
      }
    },
  }

  //// Component setup - useEffect, etc. are set up here
  function useSetup() {
    // Set up click handlers for toggle/hide field chooser
    useOutsideClick(refs.control, () => {
      hideFieldChooser()
      goBackToNotesEditor({ trace: 'useOutsideClick' })
    })

    useEffect(() => {
      // Show field chooser if editor context is available and link record
      // popup is not shown
      if (state.editorMenuContext.get() && !state.linkRecordPopupShown.get()) {
        showFieldChooser()
      } else {
        hideFieldChooser()
      }

      // Add event listeners for filter box
      let filterBox = refs.filterBox.current
      filterBox?.addEventListener('keydown', eventHandlers.onFilterBoxKeyDown)
      // filterBox?.addEventListener('input', eventHandlers.onFilterBoxChange)

      // Focus on filter box if available
      // setTimeout(() => refs.filterBox.current?.focus());

      // Scroll to the result item
      let selection = refs.control.current?.getElementsByClassName(styles.selected)
      if (selection[0]) {
        let container = refs.control.current?.getElementsByClassName(styles.results)[0]
        scrollYToElement(selection[0], container)
      }

      // Listen for events to choose a field from editor
      let fieldChooserElement = refs.control.current
      fieldChooserElement?.addEventListener('choosefieldrequest', eventHandlers.onChooseFieldRequest)

      return () => {
        // Remove event listener to choose a field from editor
        fieldChooserElement?.removeEventListener('choosefieldrequest', eventHandlers.onChooseFieldRequest)

        // Remove event listeners for filter box key down
        // filterBox?.removeEventListener('input', eventHandlers.onFilterBoxChange)
        filterBox?.removeEventListener('keydown', eventHandlers.onFilterBoxKeyDown)
      }
    })
  }

  useSetup()

  return render()
}
