import { useCallback, useState } from 'react'
import styles from './css/Tabs.module.css'
import Utils from '@/utils/Utils'

interface TabsProps {
  tabs: Array<{ name: string; content: React.ReactNode }>
}

export default function Tabs({ tabs }: TabsProps) {
  const [selectedIx, setSelectedIx] = useState(0)

  const handleChooseTab = useCallback((e: React.MouseEvent<HTMLLIElement>) => {
    const ix = Number(e.currentTarget.dataset.ix)
    setSelectedIx(ix)
  }, [])

  return (
    <div className={styles.tabs}>
      <nav>
        <ul>
          {tabs.map(({ name }, ix) => (
            <li
              key={name}
              data-ix={ix}
              className={Utils.classes(ix === selectedIx && styles.selected)}
              onClick={handleChooseTab}
            >
              {name}
            </li>
          ))}
        </ul>
      </nav>
      <main>
        {tabs.map(({ content }, ix) => (
          <div key={ix} className={Utils.classes(ix === selectedIx && styles.selected)}>
            {content}
          </div>
        ))}
      </main>
    </div>
  )
}
