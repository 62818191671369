import { connect, ConnectedProps } from 'react-redux'
import NoteShareDialogComponent from './NoteShareDialogComponent'
import getReduxState from './getReduxState'
import getActions from './getActions'

// export default connect(getReduxState, getActions, null, { forwardRef: true })(
//   NoteShareDialogComponent
// )

const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type NoteShareDialogConnectedProps = ConnectedProps<typeof connector>

export default connector(NoteShareDialogComponent)
