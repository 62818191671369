import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { ViewsMiddleware } from '../../views/middleware'
import { ReduxAppState } from '../types'

export const viewsSliceUseView = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    viewsSliceUseView.addFulfilledCase(builder)
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      ViewsMiddleware.useView.action.fulfilled,
      (state, action) => {
        try {
          var { view_id: id, crmObjectNamePlural } = action.meta.arg

          if (!state.views) {
            state.views = { mruView: { id, crmObjectNamePlural } }
          } else if (state.views?.mruView?.id !== id) {
            state.views = {
              ...state.views,
              mruView: { id, crmObjectNamePlural },
            }
          }
        } catch (e) {
          console.error('Error updating app state marking MRU view:', e)
        }
      }
    )
  },
}
