import { PayloadAction } from '@reduxjs/toolkit'
import { EmptyViewsState, ReduxViewsState } from '../types'

export const invalidateCache = (
  state: ReduxViewsState,
  action: PayloadAction<undefined>
) => {
  state.entities = { ...EmptyViewsState.entities }
  state.status = { LURU: {}, SOR: {} }
  state.refreshedAt = undefined
}
