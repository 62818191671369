import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CollectionsAPI } from '../api'
import { ReduxCollectionEntity, ReduxCollectionsState } from '../types'

export interface HideCollectionParameter {
  collectionId: string
}

export interface HideCollectionAPIResponse extends LuruAPIResponse {
  data: ReduxCollectionEntity
}

export const hideCollection = {
  action: createAsyncThunk<
    HideCollectionAPIResponse['data'],
    HideCollectionParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: HideCollectionAPIResponse['metadata']
      rejectedMeta: HideCollectionAPIResponse['metadata']
    }
  >(
    'collections/hideCollection',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: HideCollectionAPIResponse =
          await CollectionsAPI.hideCollection(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(hideCollection.action.pending, (state, action) => {
      var { collectionId } = action.meta.arg

      if (!state.entities[collectionId]) {
        state.entities[collectionId] = {
          status: EntityStatus.Loading,
          data: null,
        }
      } else {
        state.entities[collectionId].status = EntityStatus.Loading
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(hideCollection.action.fulfilled, (state, action) => {
      var { collectionId } = action.meta.arg

      state.entities[collectionId] = {
        status: EntityStatus.Loaded,
        data: { ...action.payload },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(hideCollection.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    hideCollection.addPendingCase(builder)
    hideCollection.addFulfilledCase(builder)
    hideCollection.addRejectedCase(builder)
  },
}
