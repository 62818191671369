import {
  EditorMessage,
  EditorMessageEvent,
} from '../../../coreEditor/EditorController'

export default function saveNote(noteId, callback) {
  // Send message to editor to save note
  const editorMessagePayload = {
    message: EditorMessage.SaveNote,
    data: {
      noteId,
      callback,
    },
  }
  document.getElementById(`editor-${noteId.slice(0, 7)}`)?.dispatchEvent(
    new CustomEvent(EditorMessageEvent, {
      detail: editorMessagePayload,
    })
  )
}
