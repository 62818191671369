import { useReducer } from 'react'
import appUIStateReducer from './reducer'
import { initialAppUIState } from './reducer/types'
import { AppUIStateContextProvider } from './AppUIStateContext'
import { AppUIStateDispatchContextProvider } from './AppUIStateDispatchContext'
import CollectionDialog from './components/CollectionDialog'
import Toast from './components/Toast'
import Portal from './components/Portal'

export default function AppUI({ children }: { children: React.ReactNode }) {
  const [appUIState, appUIStateDispatch] = useReducer(appUIStateReducer, initialAppUIState)
  return (
    <AppUIStateContextProvider value={appUIState}>
      <AppUIStateDispatchContextProvider value={appUIStateDispatch}>
        {appUIState.collectionDialog.isOpen && <CollectionDialog />}
        {children}
        <Portal />
        <Toast />
      </AppUIStateDispatchContextProvider>
    </AppUIStateContextProvider>
  )
}
