import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import { ReduxNoteTemplatesState } from '../types'

export interface DeleteNoteTemplateParameter {
  noteTemplateId: string
}

export interface DeleteNoteTemplateAPIResponse extends LuruAPIResponse {
  data: {}
}

export const deleteNoteTemplate = {
  action: createAsyncThunk<
    DeleteNoteTemplateAPIResponse['data'],
    DeleteNoteTemplateParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: DeleteNoteTemplateAPIResponse['metadata']
      rejectedMeta: DeleteNoteTemplateAPIResponse['metadata']
    }
  >(
    'noteTemplates/deleteNoteTemplate',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: DeleteNoteTemplateAPIResponse =
          await NoteTemplatesAPI.deleteNoteTemplate(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(deleteNoteTemplate.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(deleteNoteTemplate.action.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg.noteTemplateId]
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(deleteNoteTemplate.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    deleteNoteTemplate.addPendingCase(builder)
    deleteNoteTemplate.addFulfilledCase(builder)
    deleteNoteTemplate.addRejectedCase(builder)
  },
}
