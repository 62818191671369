import { useAppSelector } from '@/app/hooks'
import { ListItemChooserPopupProps } from './types'
import { UseComponentState } from './useComponentState'
import { ReduxNoteTemplateEntity } from '@/features/noteTemplates/types'
import React, { useEffect, useImperativeHandle } from 'react'
import sparkleIcon from '../../../images/fluent/sparkle.svg'
import styles from './ListItemChooserPopup.module.css'
import { checkForValidActions } from './helpers/checkForValidActions'
import { useLuruToast } from '@/hooks/useLuruToast'
import { EntityStatus } from '@/app/types'

export default function useEventHandlers(sharedState: UseComponentState, props: ListItemChooserPopupProps, ref: any) {
  const { showToast } = useLuruToast()
  const { state, setState } = sharedState
  const selectedTemplateId = useAppSelector((state) => state.app.home.notes.noteDetails?.[props?.noteId]?.template_id)
  const isNoteEmpty = useAppSelector((state) => state.app.home.notes.noteDetails?.[props?.noteId]?.isEmpty)
  const isDiretiedAfterInsertion =
    useAppSelector((state) => state.app.home.notes.noteDetails?.[props.noteId]?.dirtiedAfterInsertion) ?? false
  const autoLinkedTemplateId = useAppSelector(
    (state) => state.app.home.notes.noteDetails?.[props.noteId]?.autoLinkedTemplateId
  )

  const ontoggleCollapsibleSection = () => {
    if (state.containerClassName?.includes?.(styles.disableEditing)) {
      return
    }

    setState((prevState) => ({ ...prevState, isSectionOpened: !prevState.isSectionOpened, isManuallyToggled: true }))
  }

  const onInsertTemplate = (e: React.MouseEvent<HTMLLIElement>, template: ReduxNoteTemplateEntity) => {
    if (state.containerClassName?.includes?.(styles.disableEditing)) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    let message = checkForValidActions(
      selectedTemplateId ?? '',
      selectedTemplateId != null,
      template.template_id === selectedTemplateId,
      isNoteEmpty,
      isDiretiedAfterInsertion,
      showToast
    )

    let editorMessagePayload = {
      message,
      template,
    }

    state.callBacktoInsertTemplate.apply(null, [editorMessagePayload])
  }

  // Effects
  useEffect(() => {
    if (autoLinkedTemplateId && selectedTemplateId && autoLinkedTemplateId === selectedTemplateId) {
      setState((prevState) => ({
        ...prevState,
        label: React.createElement('div', {
          dangerouslySetInnerHTML: {
            __html: `<div class=${styles.autoLinkMessage}>
         <span>
           <img src=${sparkleIcon} alt='sparkle' />
         </span>
         <span>&nbsp; Best playbook chosen for you! Before editing, change or remove here</span>
         </div>`,
          },
        }),
      }))
    }

    if (
      isDiretiedAfterInsertion === true ||
      autoLinkedTemplateId !== selectedTemplateId ||
      !autoLinkedTemplateId ||
      !selectedTemplateId
    ) {
      setState((prevState) => ({ ...prevState, label: '' }))
    }
  }, [isDiretiedAfterInsertion, autoLinkedTemplateId, selectedTemplateId, setState])

  useImperativeHandle(
    ref,
    () => {
      return {
        setTemplates: (templates: { template: ReduxNoteTemplateEntity; specificity: number }[]) => {
          setState((prevState) => ({ ...prevState, templates }))
        },
        setCallback: (callback: () => void) => {
          setState((prevState) => ({ ...prevState, callBacktoInsertTemplate: callback }))
        },
        showTemplates: () => {
          !state.isSectionOpened && setState((prevState) => ({ ...prevState, isSectionOpened: true }))
        },
        hideTemplates: () => {
          state.isSectionOpened && setState((prevState) => ({ ...prevState, isSectionOpened: false }))
        },
        getIsManuallyToggled: () => state.isManuallyToggled,
        makeEditable: () => {
          if (state.containerClassName?.includes?.(styles.disableEditing)) {
            setState((prevState) => ({
              ...prevState,
              containerClassName: state.containerClassName.filter((e) => e !== styles.disableEditing),
            }))
          }
        },
        makeNonEditable: () => {
          if (!state.containerClassName?.includes?.(styles.disableEditing)) {
            setState((prevState) => ({
              ...prevState,
              containerClassName: [...prevState.containerClassName, styles.disableEditing],
            }))
          }
        },
        getAutoLinedTemplateId: () => {
          return autoLinkedTemplateId
        },
        setTemplateStatus: (status: EntityStatus) => {
          setState((prevState) => ({
            ...prevState,
            templateStatus: status,
          }))
        },
      }
    },
    [state.isSectionOpened, state.isManuallyToggled, state.containerClassName, autoLinkedTemplateId, setState]
  )

  return {
    ontoggleCollapsibleSection,
    onInsertTemplate,
  }
}
