import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { NotesMiddleware } from '../../notes/middleware'
import { ReduxAppState } from '../types'

export const notesSliceCreateNote = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    notesSliceCreateNote.addFulfilledCase(builder)
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.createNote.action.fulfilled,
      (state, action) => {
        var noteId = action.payload.note_id
        var isDraft = action.meta?.arg?.draftNote === true

        if (isDraft) {
          if (!state.home.default.luruNotes.draftNotes) {
            state.home.default.luruNotes.draftNotes = []
          }
          state.home.default.luruNotes.draftNotes.unshift(noteId)
          return
        }

        var currentNotesNewImplementation = state.home.default.luruNotes.results

        if (currentNotesNewImplementation) {
          currentNotesNewImplementation.unshift(noteId)
        } else {
          state.home.default.luruNotes.results = [noteId]
        }
      }
    )
  },
}
