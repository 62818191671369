import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'

type SyncNoteParameter = {
  noteId: string
}

export default async function syncNote(
  { noteId }: Readonly<SyncNoteParameter>,
  options: LuruAPIRequestOptions
) {
  var request = new LuruAPIRequest(
    `/api/notes/${noteId}/sync?force=true`,
    HTTPMethod.POST,
    options
  )

  return request.make()
}
