import React from 'react'
import ModalScreen from '../../../../primitives/ModalScreen'
import UserLogo from '../../../../primitives/UserLogo'
import LuruSelectBox from '../../../../primitives/ui/LuruSelectBox'
import LuruUserMultiSelectBox from '../../../../primitives/domain/users/LuruUserMultiSelectBox'
import NoteTemplateShareDialogEventHandler from './NoteTemplateShareDialogEventHandler'
import type { NoteTemplateShareDialogConnectedProps } from '.'
import {
  NoteTemplateShareInfo,
  TemplateShareAccessRight,
  TemplateShareSource,
} from '../../../../features/noteTemplates/types'
import styles from './styles.module.css'
import { ReduxNoteTemplateEntity } from '../../../../features/noteTemplates/types'
import { LuruReduxStore } from '../../../../app/store'
import { NoteTemplatesMiddleware } from '../../../../features/noteTemplates/middleware'
import { UsersSliceHelpers } from '../../../../features/users/helpers'
import { ReduxLuruUserEntity } from '../../../../features/users/types'
import { NoteTemplatesSliceHelpers } from '../../../../features/noteTemplates/helpers'
import { LuruUserSelectBoxType } from '../../../../primitives/domain/users/LuruUserSelectBox'
import { EntityStatus } from '../../../../app/types'

// icons 

import linkIcon from '../../../../images/fluent/link.svg'
import lockIcon from '../../../../images/fluent/lock.svg'
import lockOpenIcon from '../../../../images/fluent/lock_open.svg'

export interface NoteTemplateShareDialogComponentProps
  extends NoteTemplateShareDialogConnectedProps {}

export interface NoteTemplateShareDialogComponentRefs {
  modal: React.RefObject<ModalScreen>
  copyLinkButton: React.RefObject<HTMLButtonElement>
  saveChangesButton: React.RefObject<HTMLButtonElement>
}

export interface NoteTemplateShareDialogComponentState {
  status: EntityStatus
  noteTemplateId: string
  title: string
  templateShares: Array<NoteTemplateShareInfo> | undefined
}

export default class NoteTemplateShareDialogComponent extends React.Component<
  NoteTemplateShareDialogComponentProps,
  NoteTemplateShareDialogComponentState
> {
  props: NoteTemplateShareDialogComponentProps
  state: NoteTemplateShareDialogComponentState
  eventHandler: NoteTemplateShareDialogEventHandler
  componentRefs: NoteTemplateShareDialogComponentRefs
  #template?: ReduxNoteTemplateEntity
  #templateOwner?: ReduxLuruUserEntity

  constructor(props: NoteTemplateShareDialogComponentProps) {
    super(props)
    this.props = props
    this.state = {
      status: EntityStatus.NotLoaded,
      noteTemplateId: '',
      title: '',
      templateShares: undefined,
    }
    this.componentRefs = {
      modal: React.createRef(),
      copyLinkButton: React.createRef(),
      saveChangesButton: React.createRef(),
    }
    this.eventHandler = new NoteTemplateShareDialogEventHandler(this)
  }

  async showModal(noteTemplateId: string) {
    this.setState({ status: EntityStatus.Loading })

    this.#template = await LuruReduxStore.dispatch(
      NoteTemplatesMiddleware.fetchNoteTemplate.action({ noteTemplateId })
    ).unwrap()

    this.#templateOwner = await UsersSliceHelpers.fetchLuruUser(
      this.#template.created_by
    )

    // TODO: Do this for groups as well
    var sharedUsers = this.state.templateShares?.filter(
      (share) => share.entity_type === TemplateShareSource.USER
    )

    if (sharedUsers) {
      // We are doing this sequentially, knowing very well that the first await
      // may trigger an API request for getLuruUsers() and subsequent awaits
      // will resolve immediately without an API request for sure
      for (let user of sharedUsers) {
        if (user.entity_id) {
          await UsersSliceHelpers.fetchLuruUser(user.entity_id)
        }
      }
    }

    this.setState({
      noteTemplateId,
      title: this.#template.title,
      status: EntityStatus.Loaded,
      templateShares: this.#template.shares?.reduce(
        (prev: NoteTemplateShareInfo[], curr) => [...prev, { ...curr }],
        []
      ),
    })

    this.componentRefs.modal.current?.showModal({
      cancel: () => {
        this.eventHandler.handlers.onClose()
      },
    })
  }

  #renderLoadingState() {
    return <>Loading...</>
  }

  #renderLoadedState() {
    // For select boxes
    var userAccessItems = [
      { name: 'Viewer', key: TemplateShareAccessRight.VIEW },
      // { name: 'Editor', key: TemplateShareAccessRight.EDIT },
    ]
    var generalAccessItems = [
      { name: 'Everyone', key: 'everyone' },
      { name: 'Restricted', key: 'restricted' },
    ]

    // List of emails to be selected in LuruSelectBox
    var selectedEmails = []

    // Template owner name to be displayed at the top of shared with users
    if (this.#templateOwner?.email) {
      selectedEmails.push({ email: this.#templateOwner?.email ?? '' })
    }

    var reduxState = LuruReduxStore.getState()
    var sharedWithUsers =
      this.state.templateShares
        ?.filter((share) => share.entity_type === TemplateShareSource.USER)
        .map((share) => {
          return {
            userId: share.entity_id,
            email:
              UsersSliceHelpers.getEmailFromLuruUserId(
                reduxState,
                share.entity_id
              ) ?? '',
            name:
              UsersSliceHelpers.getNameFromLuruUserId(
                reduxState,
                share.entity_id
              ) ?? '',
            access: share.availability, // 'VIEW' or 'EDIT'
          }
        }) ?? []

    if (sharedWithUsers) {
      selectedEmails = selectedEmails.concat(
        sharedWithUsers?.map((share) => ({ email: share.email }))
      )
    }

    // General access info
    var generalAccess = NoteTemplatesSliceHelpers.filterGeneralAccessInfo(
      this.state.templateShares
    )

    return (
      <>
        <div className={styles.filterUsers}>
          <LuruUserMultiSelectBox
            type={LuruUserSelectBoxType.LuruAndCrmUsers}
            selectedUsers={selectedEmails}
            onSelectUser={this.eventHandler.handlers.onSelectUser}
            onDeselectUser={this.eventHandler.handlers.onDeselectUser}
            excludedUsers={[{ email: this.#templateOwner?.email ?? '' }]}
            placeholder='Search for users to share meeting playbook with'
          />
        </div>
        <h2>People with access</h2>
        <div className={styles.sharingDetailsTable}>
          <div className={'table'}>
            {this.#templateOwner?.email ? (
              <div className={['table-row', styles.userRow].join(' ')}>
                <div
                  className={['table-cell', styles.userNameContainer].join(' ')}
                >
                  <UserLogo
                    firstname={this.#templateOwner?.first_name}
                    lastname={this.#templateOwner?.last_name}
                    position={['bottom', 'left']}
                  />
                  <div className={styles.nameEmailContainer}>
                    <div className={styles.userName}>
                      {UsersSliceHelpers.getFormattedName(this.#templateOwner)}
                    </div>
                    <div className={styles.userEmail}>
                      {this.#templateOwner?.email}
                    </div>
                  </div>
                </div>
                <div className={['table-cell', styles.access].join(' ')}>
                  Owner
                </div>
              </div>
            ) : null}

            {sharedWithUsers.map((user, index) => (
              <div
                key={user.email + '/' + index}
                className={['table-row', styles.userRow].join(' ')}
              >
                <div
                  className={['table-cell', styles.userNameContainer].join(' ')}
                >
                  <UserLogo
                    firstname={user.name.split(' ')[0]}
                    lastname={user.name.split(' ')[1] ?? ''}
                  />
                  <div className={styles.nameEmailContainer}>
                    <div className={styles.userName}>{user.name}</div>
                    <div className={styles.userEmail}>{user.email}</div>
                  </div>
                </div>
                <div className={['table-cell', styles.access].join(' ')}>
                  {
                    <LuruSelectBox
                      items={userAccessItems}
                      onChooseItem={(key: string | null) =>
                        this.eventHandler.handlers.onChangeUserAccess(
                          user.userId ?? '',
                          key
                        )
                      }
                      prechosenItem={
                        user.access === TemplateShareAccessRight.VIEW
                          ? 'Viewer'
                          : 'Editor'
                      }
                      classes={[styles.accessButton]}
                    />
                  }
                </div>
              </div>
            ))}
          </div>
        </div>

        <h2>General access</h2>
        <div className={styles.generalAccessTable}>
          <div className='table'>
            <div className={['table-row', styles.userRow].join(' ')}>
              <div
                className={['table-cell', styles.userNameContainer].join(' ')}
              >
                <img src={Boolean(generalAccess) ? lockOpenIcon : lockIcon} 
                  alt={Boolean(generalAccess) ? 'lockOpen' : 'lock'} 
                />
                <div className={styles.nameEmailContainer}>
                  <div className={styles.generalAccessTitle}>
                    <LuruSelectBox
                      items={generalAccessItems}
                      onChooseItem={
                        this.eventHandler.handlers.onChangeGeneralAccess
                      }
                      prechosenItem={
                        Boolean(generalAccess) ? 'Everyone' : 'Restricted'
                      }
                      classes={[styles.generalAccessButton]}
                      leftAlign={true}
                    />
                  </div>
                  <div className={styles.generalAccessInfo}>
                    {Boolean(generalAccess)
                      ? 'Everyone in your team can use this meeting playbook'
                      : 'Only people with access can use this meeting playbook'}
                  </div>
                </div>
              </div>
              <div className={['table-cell', styles.access].join(' ')}>
                {Boolean(generalAccess) ? (
                  <LuruSelectBox
                    items={userAccessItems}
                    onChooseItem={
                      this.eventHandler.handlers.onChangeGeneralAccessRight
                    }
                    prechosenItem={
                      generalAccess?.availability ===
                      TemplateShareAccessRight.EDIT
                        ? 'Editor'
                        : 'Viewer'
                    }
                    classes={[styles.accessButton]}
                    leftAlign={false}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <button
            className={styles.copyLinkButton}
            ref={this.componentRefs.copyLinkButton}
            onClick={this.eventHandler.handlers.onCopyLink}
          >
            <img src={linkIcon} alt='link' />
            Copy link
          </button>
          <button
            className={styles.saveChangesButton}
            ref={this.componentRefs.saveChangesButton}
            onClick={this.eventHandler.handlers.onSaveChanges}
          >
            Save changes
          </button>
        </div>
      </>
    )
  }

  #renderErrorState() {
    return <>Error loading playbook sharing details</>
  }

  render() {
    return (
      <ModalScreen
        ref={this.componentRefs.modal}
        width='700px'
        height='auto'
        hideButtons={true}
        title={`Share meeting playbook: ${this.state.title}`}
        titleCloseButton={true}
      >
        <div className={styles.parent}>
          {this.state.status === EntityStatus.Loading
            ? this.#renderLoadingState()
            : this.state.status === EntityStatus.Loaded
            ? this.#renderLoadedState()
            : this.state.status === EntityStatus.ErrorLoading
            ? this.#renderErrorState()
            : null}
        </div>
      </ModalScreen>
    )
  }

  getCurrentTemplate() {
    return this.#template
  }

  getCurrentTemplateOwner() {
    return this.#templateOwner
  }
}
