import styles from './css/LoadingSpinner.module.css'

export default function LoadingSpinner({
  size,
  color,
}: {
  size?: number
  color?: string
}) {
  const radius = size ?? 12
  const colorStyle = color ?? 'var(--brand-accent-color-blue)'
  return (
    <div
      className={styles.circularProgress}
      style={{
        width: `${radius * 2}px`,
        height: `${radius * 2}px`,
        color: colorStyle,
      }}
    >
      <svg
        className={styles.circle}
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      >
        <circle
          className={styles.circlePath}
          cx={radius}
          cy={radius}
          r={radius - 2}
        ></circle>
      </svg>
    </div>
  )
}
