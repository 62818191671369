import { PayloadAction } from '@reduxjs/toolkit'
import { listOrSearchEmptyState, ReduxAppState } from '../types'

export const clearNoteTemplateSearchQuery = (
  state: ReduxAppState,
  action: PayloadAction<undefined>
) => {
  state.noteTemplatesHome.search.query = null

  state.noteTemplatesHome.search['myTemplates'] = {
    ...listOrSearchEmptyState,
  }

  state.noteTemplatesHome.search['luruTemplates'] = {
    ...listOrSearchEmptyState,
  }
}
