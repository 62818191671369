import { createSlice } from '@reduxjs/toolkit'
import { UserSliceReducers as reducers } from './reducers'
import { EmptyUserState as initialState } from './types'
import { NotesSliceActions } from '../notes/notesSlice'
import { NoteTemplatesSliceActions } from '../noteTemplates/noteTemplatesSlice'
import { UserMiddleware } from './middleware'
import { UserSliceExtraReducers } from './extraReducers'
import { ViewsMiddleware } from '../views/middleware'
import { UsersMiddleware } from '../users/middleware'

// Slice definition
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers,

  // Extra reducers
  extraReducers: (builder) => {
    UserMiddleware.disconnectGCal.addAllCases(builder)
    UserMiddleware.disconnectO365Cal.addAllCases(builder)
    UserMiddleware.disconnectZoom.addAllCases(builder)
    UserMiddleware.disconnectSlack.addAllCases(builder)
    UserMiddleware.disconnectMSTeams.addAllCases(builder)
    UserMiddleware.disconnectGChat.addAllCases(builder)
    UserMiddleware.disconnectGmail.addAllCases(builder)
    UserMiddleware.disconnectO365Mail.addAllCases(builder)
    UserMiddleware.updateNotificationStats.addFulfilledCase(builder)
    UserMiddleware.setEmailSyncInfo.addAllCases(builder)
    UserMiddleware.getEmailSyncInfo.addAllCases(builder)

    builder
      .addCase(NotesSliceActions.updateTitle, UserSliceExtraReducers.notesSliceUpdateTitle)
      .addCase(NoteTemplatesSliceActions.updateTitle, UserSliceExtraReducers.noteTemplatesSliceUpdateTitle)
      .addCase(
        UsersMiddleware.updateLuruUserRoles.action.fulfilled,
        UserSliceExtraReducers.usersSliceUpdateLuruUserRoles
      )
      .addCase(ViewsMiddleware.updateView.action.fulfilled, UserSliceExtraReducers.viewsSliceUpdateView)
  },
})

// Action creators
export const UserSliceActions = userSlice.actions

// Root reducer for this slice
export default userSlice.reducer

if (typeof chrome === 'undefined') {
  // @ts-ignore
  window.chrome = {}
}

// @ts-ignore
if (!chrome?.luru) {
  // @ts-ignore
  chrome.luru = {}
}

// @ts-ignore
if (chrome.luru.backgroundStore) {
  // @ts-ignore
  chrome.luru.backgroundStore = Object.assign(chrome.luru.backgroundStore, {
    actions: {
      signInUser: UserSliceActions.signInUser,
      signOutUser: UserSliceActions.signOutUser,
      refreshUserSettings: UserSliceActions.refreshUserSettings,
    },
  })
}
