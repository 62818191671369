import './styles/layout.css'

export interface FlexRowProps {
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between'
  align?: 'top' | 'center' | 'bottom'
  gap?: string
  children?: React.ReactNode
  className?: string
  wrap?: boolean
}

const FlexRow = ({ justify, align, gap, children, wrap = false, className = '' }: FlexRowProps) => (
  <div
    className={[
      'l-layout-row',
      justify ? `l-layout-justify-${justify}` : null,
      align ? `l-layout-align-${align}` : null,
      wrap ? 'l-layout-flex-wrap' : null,
      className,
    ].join(' ')}
    style={{ gap: gap ?? '1rem' }}
  >
    {children ?? null}
  </div>
)

export default FlexRow
