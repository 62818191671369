import { PayloadAction } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { ReduxNotesState } from '../types'

export const invalidateCache = (
  state: ReduxNotesState,
  action: PayloadAction<undefined>
) => {
  // Invalidate cache, retain the draft entities
  state.status = EntityStatus.NotLoaded
  state.entities = Object.values(state.entities)
    .filter((entry) => entry?.isDraft)
    .reduce(
      (prev, curr) =>
        curr?.data?.note_id
          ? { ...prev, [curr.data.note_id]: { ...curr } }
          : prev,
      {}
    )
}
