import { NotificationActionButton, NotificationType } from '@/features/user/types'
import styles from './styles.module.css'
import dismissIcon from '@/images/fluent/close.svg'
import LuruButton from '@/primitives/ui/LuruButton'
import { useAppDispatch } from '@/app/hooks'
import { UserMiddleware } from '@/features/user/middleware'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import appStyles from '@/layout/css/App.module.css'

interface BannerNotificationProps {
  notificationId?: string
  message: string
  type?: NotificationType
  closable?: boolean
  onClose?: (notificationId?: string) => void
  className?: string
  messageContainerClassName?: string
  actionButtons?: Array<NotificationActionButton>
  dismissTime?: number
}

export default function BannerNotification({
  notificationId,
  message,
  type,
  closable = true,
  onClose,
  className,
  messageContainerClassName,
  actionButtons = [],
  dismissTime,
}: BannerNotificationProps) {
  const dispatch = useAppDispatch()
  const handlers = {
    handleCloseNotification: () => {
      if (notificationId) {
        dispatch(
          UserMiddleware.updateNotificationStats.action({
            notificationId,
            payload: {
              dismissed_at: moment(new Date()).format(), //Iso date format
            },
          })
        )
          .unwrap()
          .finally(() => {
            onClose?.(notificationId)
          })
      } else {
        onClose?.()
      }
    },
  }

  const bannerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const appContainer = document.querySelector(`.${appStyles.appContainer}`) as HTMLElement
    const bannerHeight = bannerRef.current?.offsetHeight

    if (bannerHeight && appContainer) {
      const newHeight = 100 - (bannerHeight / window.innerHeight) * 100

      // appContainer should occupie the remaining space of window
      appContainer.style.height = `${newHeight}vh`
    }

    let timer = setTimeout(() => {
      if (notificationId) {
        dispatch(
          UserMiddleware.updateNotificationStats.action({
            notificationId,
            payload: {
              dismissed_at: moment(new Date()).format(), //Iso date format
            },
          })
        )
          .unwrap()
          .finally(() => {
            onClose?.(notificationId)
          })
      } else {
        onClose?.()
      }
    }, dismissTime)

    return () => {
      clearTimeout(timer)
      appContainer.style.height = '100%'
    }
  }, [dismissTime, dispatch, notificationId, onClose])

  return (
    <div
      className={[styles.bannerContainer, className].join(' ')}
      data-luru-notification-type={type}
      data-luru-notification-dismissible={closable}
      ref={bannerRef}
    >
      <div className={styles.bannerBody}>
        <span className={styles.imagePlaceholder} />
        <div
          className={[styles.messageContainer, messageContainerClassName].join(' ')}
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>

        <div className={styles.actionButtonsContainer}>
          {actionButtons &&
            actionButtons.map((btn, indx) => {
              return (
                <LuruButton
                  key={indx}
                  title=''
                  variant='outline'
                  onClick={() => {
                    btn.href && window.open(btn.href, '_blank')
                  }}
                >
                  {btn.label}
                </LuruButton>
              )
            })}
        </div>
      </div>
      {closable && (
        <img className={styles.closeIcon} src={dismissIcon} alt='Close' onClick={handlers.handleCloseNotification} />
      )}
    </div>
  )
}
