import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CrmAPI } from '../api'
// import { getSchemaStatus } from '../selectors/getSchemaStatus'
import { CrmObjectName, CrmObjectSchema, ReduxCrmKey, ReduxCrmState } from '../types'

export interface GetObjectSchemaParameter {
  crmKey: ReduxCrmKey
  objectName: CrmObjectName
  force?: boolean
}

export interface GetObjectSchemaAPIResponse extends LuruAPIResponse {
  data: CrmObjectSchema
}

export const getObjectSchema = {
  action: createAsyncThunk<
    GetObjectSchemaAPIResponse['data'],
    GetObjectSchemaParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: GetObjectSchemaAPIResponse['metadata']
      rejectedMeta: GetObjectSchemaAPIResponse['metadata']
    }
  >(
    'crm/getObjectSchema',
    async (params, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      try {
        const { force, crmKey, objectName } = params
        const schemaFound = getState().crm?.[crmKey]?.schema?.[objectName]?.data
        if (schemaFound && !force) {
          return fulfillWithValue(schemaFound, null)
        }

        var schema = (await CrmAPI.getObjectSchema(params, {
          signal,
        })) as GetObjectSchemaAPIResponse

        // Deep-copy schema
        schema = JSON.parse(JSON.stringify(schema))

        return fulfillWithValue(schema.data, schema.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    }
    // {
    //   condition: ({ crmKey, objectName, force }, { getState }) =>
    //   force ||
    //   ![EntityStatus.Loading, EntityStatus.Loaded].includes(
    //     getSchemaStatus(crmKey, objectName)(getState()) ?? EntityStatus.NotLoaded
    //   ),
    // }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(getObjectSchema.action.pending, (state, action) => {
      var { crmKey, objectName, force } = action.meta.arg
      const schemaFound = state?.[crmKey]?.schema?.[objectName]?.data
      if (schemaFound && !force) {
        return
      }
      state[crmKey] = {
        ...state[crmKey],
        schema: {
          ...state[crmKey]?.schema,
          [objectName]: {
            ...state[crmKey]?.schema?.[objectName],
            status: EntityStatus.Loading,
          },
        },
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(getObjectSchema.action.fulfilled, (state, action) => {
      var { crmKey, objectName, force } = action.meta.arg
      const schemaFound = state?.[crmKey]?.schema?.[objectName]?.data
      if (schemaFound && !force) {
        return
      }

      state[crmKey] = {
        ...state[crmKey],
        schema: {
          ...state[crmKey]?.schema,
          [objectName]: {
            ...state[crmKey]?.schema?.[objectName],
            status: EntityStatus.Loaded,
            data: action.payload,
            lastRefreshedAt: new Date().toISOString(),
          },
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(getObjectSchema.action.rejected, (state, action) => {
      var { crmKey, objectName } = action.meta.arg

      state[crmKey] = {
        ...state[crmKey],
        schema: {
          ...state[crmKey]?.schema,
          [objectName]: {
            status: EntityStatus.ErrorLoading,
            error: action.payload as {},
          },
        },
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    getObjectSchema.addPendingCase(builder)
    getObjectSchema.addFulfilledCase(builder)
    getObjectSchema.addRejectedCase(builder)
  },
}
