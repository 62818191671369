import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import LuruUser from '@/domain/users/LuruUser'

export interface ListWorkflowsParameter {
  limit?: number
  created_by?: 'me' | 'notme' | 'all'
}

export const listWorkflows = async (params: ListWorkflowsParameter, options: LuruAPIRequestOptions) => {
  var urlParams: Partial<ListWorkflowsParameter> = { ...params }
  if (!urlParams.limit) {
    urlParams.limit = 200
  }
  // if (['luru.app', 'monday.com'].includes(LuruUser.getCurrentUserDomain())) {
  //   urlParams.created_by = 'all'
  // }
  const tenantDomain = LuruUser.getTenantDomain()
  if (
    tenantDomain.includes('mondaycom') ||
    tenantDomain.includes('luruapp') ||
    tenantDomain.includes('zluricom') ||
    tenantDomain.includes('hiverhqcom')
  ) {
    urlParams.created_by = 'all'
  }
  var queryString = new URLSearchParams(urlParams as URLSearchParams)
  var request = new LuruAPIRequest(`/api/workflows?${queryString}`, HTTPMethod.GET, options)
  return request.make()
}
