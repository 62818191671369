import type { RootState } from '../../../../app/store'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import LuruUser from '../../../../domain/users/LuruUser'
import { LuruFieldSetChooserOwnProps } from './LuruFieldSetChooserComponent'

export default function getReduxState(
  state: RootState,
  ownProps: LuruFieldSetChooserOwnProps
): {
  schemaStatus: EntityStatus
} {
  var crmKey = LuruUser.getCurrentUserCrmName()?.toLowerCase()
  var sorObjectName = CrmRecord.getCrmRecordNameForSchema(
    ownProps.crmRecordType
  )
  return {
    schemaStatus: crmKey
      ? // @ts-ignore
        (state.crm[crmKey]?.schema?.[sorObjectName]?.status as EntityStatus)
      : EntityStatus.NotLoaded,
  }
}
