// Styles
import { ReactNode } from 'react'
import styles from './css/FlatButton.module.css'
import loadingStyles from './css/LoadingAnimation.module.css'

export interface FlatButtonProps {
  // Custom className for button
  className?: string
  // Custom role attribute
  dataRole?: string
  // If loading indicator should be shown
  loading?: boolean
  // Children of this component
  children?: ReactNode
  // Custom event handlers
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void
}

/**
 * Opinionated, flat button to use it easily across app
 * - Has a lively effect on hover and on click
 * - Calls the onClick handler passed to it
 * - Renders the children as is inside it
 * - Customizations can be overridden by targeting the button element of the
 *   containing parent
 * @param {object} props { onClick }
 * @returns JSX of the FlatButton element
 */
export default function FlatButton(props: FlatButtonProps) {
  let classes = [styles.main]

  if (props.className) {
    classes.push(props.className)
  }

  let dataRole = props.dataRole ?? null

  return (
    <button
      className={classes.join(' ')}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      data-role={dataRole}
    >
      {props.children}
      {props.loading ? (
        <span className={[loadingStyles.loading, styles.spinner].join(' ')}>
          {' '}
        </span>
      ) : (
        ''
      )}
    </button>
  )
}
