import React from 'react'
import { LocalUserSettingKey, LocalUserSettings, UserNotification } from '../../features/user/types'

export default function useNotificationControllerComponentState() {
  const [toBeShownNotifications, setToBeShownNotifications] = React.useState<Array<UserNotification>>([])
  const [futureNotificationTimers, setFutureNotificationTimers] = React.useState<Record<string, NodeJS.Timeout>>({})
  const [localUserNotificationSettings, setLocalUserNotificationSettings] = React.useState<
    LocalUserSettings[LocalUserSettingKey.USER_NOTIFICATION_SETTINGS]
  >([])
  return {
    toBeShownNotifications: {
      getToBeShownNotifications: toBeShownNotifications,
      setToBeShownNotifications,
    },
    localUserNotificationSettings: {
      getLocalUserNotificationSettings: localUserNotificationSettings,
      setLocalUserNotificationSettings,
    },
    futureNotificationTimers: {
      getFutureNotificationTimers: futureNotificationTimers,
      setFutureNotificationTimers,
    },
  }
}

export type UseNotificationControllerComponentState = ReturnType<typeof useNotificationControllerComponentState>
