import { CRMProvider } from '../../features/user/types'

export type CrmRecordId = string

// export interface CrmObject extends DomainAPIRecord {}

// export enum CrmRecordType {
//   DEAL = 'deal',
//   LEAD = 'lead',
//   ACCOUNT = 'account',
//   CONTACT = 'contact',
//   MEETING = 'meeting',
// }

export type CrmRecordType = string

export interface CrmRecordUpdatePayload {
  crmRecordType: CrmRecordType
  sorRecordId: string
  fieldName: string
  fieldValue: string
}

export interface CrmRecordMultiFieldUpdatePayload {
  crmRecordType: CrmRecordType
  sorRecordId: string
  fields: { [fieldName: string]: any }
}

export const recordNameInCrm: Record<CRMProvider, Record<CrmRecordType, string>> = {
  // SFDC
  [CRMProvider.SFDC]: {
    account: 'account',
    lead: 'lead',
    contact: 'contact',
    deal: 'opportunity',
    meeting: 'meeting',
    task: 'task',
  },
  // SFDC_SANDBOX
  [CRMProvider.SFDC_SANDBOX]: {
    account: 'account',
    lead: 'lead',
    contact: 'contact',
    deal: 'opportunity',
    meeting: 'meeting',
    task: 'task',
  },
  // also Hubspot
  [CRMProvider.HUBSPOT]: {
    deal: 'deals',
    contact: 'contacts',
    account: 'companies',
    meeting: 'meetings',
  },
  // also Pipedrive
  [CRMProvider.PIPEDRIVE]: {
    deal: 'deal',
    contact: 'person',
    account: 'organization',
    lead: 'lead',
    meeting: 'meeting',
  },
}

export enum LuruFieldType {
  DATE = 'date',
  DATETIME = 'datetime',
  INTEGER = 'int',
  DOUBLE = 'double',
  CURRENCY = 'currency',
  PERCENTAGE = 'percent',
  TEXT = 'string',
  BOOLEAN = 'boolean',
  ENUM = 'picklist',
  ENUM_RADIO = 'picklist',
  ENUM_SELECT = 'picklist',
  MULTIENUM = 'multipicklist',
  REFERENCE = 'reference',
  MULTIREFERENCE = 'multi_reference',
  REFERENCE_FIELD = 'reference_field',
  LARGETEXT = 'textarea',
  ADDRESS = 'address',
  TELEPHONE = 'phone',
  EMAIL = 'email',
  URL = 'url',
  INTEGER_NOFORMAT = 'integer_noformat',
  HIERARCHICAL_ENUM = 'hierarchical_picklist',
  ASSOCIATED_EMAIL = 'associated_email',
  ASSOCIATED_CALL = 'associated_call',
  MEETING_TIMESTAMP = 'meeting_timestamp',
  LURU_MEETING_NOTE = 'luru_meeting_note',
}

export type LuruPicklistOption = { label: string; value: string; metadata: any }

export type LuruHierarchialPicklistOptions = Array<{
  label: string
  value: string
  options: Array<LuruPicklistOption>
}>

export interface LuruHierarchialPicklistValue {
  level1: string
  level2: string
}

export interface CrmObjectSchema {
  payload?: {
    fields?: Array<CrmFieldSchema>
  }
}

export interface CrmFieldSchema {
  luruFieldType: LuruFieldType
  // System facing name
  name: string
  // User facing name
  label: string
  // Original type
  type: string
  // Field type
  luruFieldType: string
  // Default value
  defaultValue?: string | null
  // Number of digits
  digits?: number | null
  // Length
  length?: number | null
  // Is name field?
  nameField?: boolean
  // Picklist values (for enum/multi-enum)
  picklistValues?: Array<{
    label: string
    value: string
    valueForUpdate?: any
    validFor?: Array<string>
  }>
  // Hierarchical picklist values
  hierarchicalPicklistValues?: LuruHierarchialPicklistOptions
  // Referenced field (for reference field type)
  referencedObject?: string
  // Is field updateable?
  updateable?: boolean
  // Is field mandatory?
  isMandatory?: boolean
  // Is field filterable?
  isFilterable: boolean
  // Is field nillable?
  isNillable: boolean
  // Controlled by field
  controllerName?: string
  // TODO: Remove later
  filterable: boolean
  // Is field non-createable?
  nonCreateable?: boolean
  // Reference fields
  referenceTo?: Array<string>
  // For PD
  optional_if_present?: string
}

export type CrmRecordSchema = Array<CrmFieldSchema>

export interface CrmRecordFieldsData {
  record: {
    [fieldName: string]: {
      value: any
      originalValue?: any
      schema: CrmFieldSchema
    }
  }
  userAccess: { can_delete?: boolean; can_edit?: boolean; can_read?: boolean }
}

export type SearchCrmRecordsResultItem = {
  sor_object_name: string
  sor_record_id: string
  sor_record_name: string
  data: {
    Name: string
  }
  user_access: {
    can_read: boolean
    can_edit: boolean
    can_delete: boolean
  }
}

export type SearchCrmRecordsResults = Array<SearchCrmRecordsResultItem>

export interface CompoundFieldValue {
  sor_record_id: string
  sor_record_name: string
  sor_object_name: string
}
