// React
import React from 'react'
import ReactDom from 'react-dom'

// Own components
import LuruButton from '../../ui/LuruButton'

// Styles
import styles from './styles.module.css'

export interface LuruLargeTextModalInputProps {}

interface LuruLargeTextModalInputState {
  visible: boolean
  title: string
  label: string
  value: string
  callback: (value: string) => void
}

export type LargeTextShowArgs = {
  title: string
  label: string
  value: string
  callback: (value: string) => void
}

interface LuruLargeTextModalInputRefs {
  textarea: React.RefObject<HTMLTextAreaElement>
}

export default class LuruLargeTextModalInput extends React.Component<LuruLargeTextModalInputProps> {
  props: LuruLargeTextModalInputProps
  state: LuruLargeTextModalInputState
  componentRefs: LuruLargeTextModalInputRefs

  constructor(props: LuruLargeTextModalInputProps) {
    super(props)

    // Props
    this.props = props

    // State
    this.state = {
      visible: false,
      title: '',
      label: '',
      value: '',
      callback: () => {},
    }

    // Bindings
    this.showDialog = this.showDialog.bind(this)
    this.hideDialog = this.hideDialog.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    // Refs
    this.componentRefs = {
      textarea: React.createRef(),
    }
  }

  showDialog({ title, label, value, callback }: LargeTextShowArgs) {
    this.setState({ title, label, value, callback, visible: true })
  }

  hideDialog() {
    this.setState({ visible: false, tip: '' })
  }

  onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ value: e.target.value })
  }

  onSubmit() {
    this.hideDialog()
    if (typeof this.state.callback === 'function') {
      this.state.callback(this.state.value)
    }
  }

  componentDidUpdate() {
    if (this.state.visible) {
      this.componentRefs.textarea.current?.focus()
    }
  }

  render() {
    var rootElement = document.getElementById('root')
    var component = (
      <div
        className={[styles.parent, this.state.visible ? null : styles.minimized].join(' ')}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className={styles.dialog}>
          <div className={styles.title}>{this.state.title}</div>
          <div className={styles.content}>
            <label>{this.state.label}</label>
            <textarea onChange={this.onChange} value={this.state.value} ref={this.componentRefs.textarea} />
          </div>
          <div className={styles.buttons}>
            <LuruButton title='Cancel' variant='outline' onClick={this.hideDialog}>
              Cancel
            </LuruButton>
            <LuruButton title='Submit text' onClick={this.onSubmit}>
              OK
            </LuruButton>
          </div>
        </div>
      </div>
    )

    return ReactDom.createPortal(component, rootElement ?? document.body)
  }
}
