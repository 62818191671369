import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { RecentEntityType, RecentItem, ReduxUserState } from '../types'

export const addRecentItem: CaseReducer<
  ReduxUserState,
  PayloadAction<RecentItem>
> = (state, action) => {
  var recentItem = { ...action.payload }
  var noteTypes = [RecentEntityType.MEETING_NOTE, RecentEntityType.NOTE]

  if (!Array.isArray(state.recentItems)) {
    state.recentItems = [recentItem]
  } else {
    let currIx = state.recentItems.findIndex(
      (item) =>
        item.id === recentItem.id &&
        (item.type === recentItem.type ||
          (noteTypes.includes(item.type) &&
            noteTypes.includes(recentItem.type)))
    )

    if (currIx !== -1) {
      state.recentItems.splice(currIx, 1)
    }

    state.recentItems.unshift(recentItem)
  }
}
