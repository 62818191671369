import * as React from 'react'
import { LuruReduxStore, RootState } from '../../../../../app/store'
import { connect, ConnectedProps } from 'react-redux'
import ModalScreen from '../../../../../primitives/ModalScreen'
import { WorkflowsMiddleware } from '../../../../../features/workflows/middleware'
import { ConnectLuruExtensionsProps, EntityStatus } from '../../../../../app/types'
import { WorkflowState } from '../../../../../features/workflows/types'
import msTeamsLogo from '../../../../../images/sor/msteams/logo.png'
import IntegrationCard from '../IntegrationCard'
import { getAuthUrl } from '../../../../../domutils/utils'
import CrmRecord from '../../../../../domain/crmRecord/CrmRecord'
import { UserMiddleware } from '../../../../../features/user/middleware'
import LuruUser from '../../../../../domain/users/LuruUser'
import { MessagingProvider } from '../../../../../features/user/types'
import Utils from '@/utils/Utils'
import { ToastId } from '@/app_ui/types'

function getMicrosoftTeamsStatusFromRedux(state: RootState) {
  return {
    workflowsWithActiveState: Object.entries(state?.workflows?.entities).filter(
      ([, wf]) => wf.data.state === WorkflowState.ACTIVE
    ),
    workflowStatus: state.workflows.status,
  }
}

const connector = connect(getMicrosoftTeamsStatusFromRedux, null, null, {
  forwardRef: true,
})

export interface MicrosoftTeamsCardRefs {
  disconnectModalRef: React.RefObject<ModalScreen>
}

export interface MicrosoftTeamsCardProps extends ConnectedProps<typeof connector>, ConnectLuruExtensionsProps {}

interface MicrosoftTeamsCardState {
  disconnecting: boolean
}

class MicrosoftTeamsCard extends React.Component<MicrosoftTeamsCardProps, MicrosoftTeamsCardState> {
  props: MicrosoftTeamsCardProps
  state: MicrosoftTeamsCardState
  componentRefs: MicrosoftTeamsCardRefs

  constructor(props: MicrosoftTeamsCardProps) {
    super(props)
    this.props = props
    this.state = {
      disconnecting: false,
    }
    this.componentRefs = {
      disconnectModalRef: React.createRef(),
    }
  }

  componentDidMount(): void {
    if (this.props.workflowStatus !== EntityStatus.Loaded) {
      LuruReduxStore.dispatch(WorkflowsMiddleware.listWorkflows.action({}))
    }
  }

  connectToMSTeams = () => {
    // Add redirect path so that we end up at the exact page we are in now
    var path = window === window.top ? window.location.pathname ?? '/' : '/'
    var msTeamsConnectUrl = `${getAuthUrl()}/login/azuread-v2-tenant-oauth2-msteams/?next=/next${path}`
    // var msTeamsBotAppStoreUrl = 'https://teams.microsoft.com/l/chat/0/0?users=28:a43fec97-6e2c-4c7b-8d2b-b4790aca3bf3'

    if (window === window.top) {
      window.location.href = msTeamsConnectUrl
    } else {
      window.open(msTeamsConnectUrl)
    }
  }

  onClickDisconnectMSTeams = () =>
    this.componentRefs.disconnectModalRef.current?.showModal({
      ok: () => {
        this.setState({ disconnecting: true })
        this.props.toast.showToast({
          id: ToastId.SETTING_INTEGRATION_TOAST_ID,
          message: 'Disconnecting',
          isLoading: true,
        })
        LuruReduxStore.dispatch(UserMiddleware.disconnectMSTeams.action({}))
          .unwrap()
          .then(() => {
            this.props.toast.showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Disconnected Microsoft Teams',
              severity: 'success',
            })
            this.setState({ disconnecting: false })
          })
          .catch((err: any) => {
            this.props.toast.showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Error disconnecting Microsoft Teams',
              severity: 'error',
            })
            this.setState({ disconnecting: false })
          })
      },
    })

  render = () => {
    const messagingProvider = LuruUser.getCurrentUserMessagingProviderName()
    return (
      <>
        <IntegrationCard
          sor='Microsoft'
          heading='Microsoft Teams'
          body={`Automate your workflows, configure bi-directional alerts and manage ${CrmRecord.getCrmName()} right from within Microsoft Teams.`}
          logoPath={msTeamsLogo}
          isConnected={messagingProvider === MessagingProvider.MSTEAMS}
          disableConnect={
            messagingProvider === MessagingProvider.GCHAT || messagingProvider === MessagingProvider.SLACK
          }
          handleConnect={this.connectToMSTeams}
          handleDisconnect={this.onClickDisconnectMSTeams}
          height='15em'
        />
        <ModalScreen title='Disconnect Microsoft Teams' ref={this.componentRefs.disconnectModalRef}>
          <span
            dangerouslySetInnerHTML={{
              __html:
                this.props.workflowsWithActiveState.length > 0
                  ? `You have ${this.props.workflowsWithActiveState.length} active workflow(s). Disconnecting your Teams account may cause the workflow to not work.<br/>Do you want to continue?`
                  : `Do you want to disconnect from Microsoft Teams?`,
            }}
          ></span>
        </ModalScreen>
      </>
    )
  }
}

export default React.forwardRef(Utils.connectLuruExtensions(connector(MicrosoftTeamsCard)))
