import * as React from 'react'
import styles from './styles.module.css'
//Assets
import zoomLogo from '../../../images/zoom-logo.png'
import luruLogo from '../../../images/luru/logoMain.svg'
import rocketImg from '../../../images/rocket.png'
import luruULogo from '../../../images/luru/logoULogo.png'
import { connect, ConnectedProps } from 'react-redux'
import LuruButton from '../../../primitives/ui/LuruButton'
import { RootState } from '../../../app/store'
import { getAuthUrl } from '../../../domutils/utils'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'

function getZoomStatusFromRedux(state: RootState) {
  return {
    isZoomConnected: state?.user?.data?.userSettings?.connections?.find(
      (conn: { name: string }) => conn.name === 'ZOOM'
    ),
  }
}

const connector = connect(getZoomStatusFromRedux, null, null, {
  forwardRef: true,
})

export interface ZoomConnectModalProps extends ConnectedProps<typeof connector> {}

interface ZoomConnectModalState {}

class ZoomConnectModal extends React.Component<ZoomConnectModalProps, ZoomConnectModalState> {
  props: ZoomConnectModalProps
  state: ZoomConnectModalState

  constructor(props: ZoomConnectModalProps) {
    super(props)
    this.props = props
    this.state = {}
  }

  #connectToZoom() {
    const nextPath = '/settings/integrations'
    chrome.tabs.create({ url: `${getAuthUrl()}/login/zoom-oauth2/?next=/next${nextPath}` }).then(window.close)
  }

  renderZoomConnectView() {
    const { isZoomConnected } = this.props

    return (
      <div>
        <div className={styles.appLogo}>
          <img src={luruLogo} alt='zoom logo' />
        </div>
        <div className={styles.modalTitle}>
          <h4>Supercharge your sales meetings with the Luru Zoom App</h4>
        </div>
        <div className={styles.modalBody}>
          <p>
            With the Luru Zoom App, you can create and access your sales notes and {CrmRecord?.getCrmName?.() ?? 'CRM'}{' '}
            data right from within Zoom
          </p>
          <div className={styles.images}>
            <img src={zoomLogo} alt='zoom logo' />
            <h2>+</h2>
            <img src={luruULogo} alt='luru logo' />
            <h2>=</h2>
            <img src={rocketImg} alt='rocket icon' />
          </div>
          {isZoomConnected && (
            <div className={styles.modalTitle}>
              <h4>You have already installed Luru Zoom App</h4>
            </div>
          )}
          {!isZoomConnected && (
            <LuruButton
              title='Install Luru Zoom Extension'
              onClick={this.#connectToZoom}
              extraClassNames={[styles.installButton]}
              id='connectToZoom'
              subdued
              outlined
            >
              INSTALL
            </LuruButton>
          )}
        </div>
      </div>
    )
  }

  render() {
    return <div className={styles.root}>{this.renderZoomConnectView()}</div>
  }
}

export default connector(ZoomConnectModal)
