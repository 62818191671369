import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UseCollectionParameter } from '../middleware/useCollection'

export const useCollection = async (
  params: Readonly<UseCollectionParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/collections/${params.collectionId}/use`,
    HTTPMethod.POST,
    options
  )

  return request.make()
}
