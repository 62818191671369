import LuruTaskInfoButton from './LuruTaskInfoButton'

export default class EditorTaskFieldView {
  static ReactRootSelector = '[data-luru-role="sor-task-info-root"]'

  /**
   * Create and return an HTML element representing a task
   * @param {Object} jotting - Task jotting
   * @param {Boolean} isReadOnly - Whether note is read only
   * @param {string} entityId - ID of the edited entity
   * @returns {HTMLElement}
   */
  computeTaskInfoElement(jotting, isReadOnly, entityId) {
    if (
      !jotting.taskId ||
      jotting.taskId === 'undefined' ||
      jotting.taskId === 'null'
    ) {
      return { element: null, component: null }
    }
    let reactRoot = document.createElement('span')
    reactRoot.setAttribute('placeholder', '')
    reactRoot.setAttribute('contenteditable', 'false')
    reactRoot.setAttribute('data-luru-role', 'sor-task-info-root')
    return {
      element: reactRoot,
      component: (
        <LuruTaskInfoButton
          noteId={entityId}
          taskId={jotting.taskId}
          readOnly={isReadOnly}
        />
      ),
    }
  }
}
