import React, { Component, useCallback } from 'react'
import ChipSelectBoxEventHandler from './ChipSelectBoxEventHandler'
// Styles
import styles from './styles.module.css'
import luruMultiSelectPopupStyles from '../LuruMultiSelectPopup/styles.module.css'
import PopupMenu from '../../PopupMenu'
import LuruTextBox from '../LuruTextBox'
import dismissIcon from '../../../images/fluent/dismiss_circle.svg'
// Icons
import checkmarkIcon from '../../../images/fluent/checkmark.svg'

export interface ChipSelectBoxProps {
  // Optional id
  id?: string
  menuParentSelector?: string
  loading?: boolean
  // List of items
  items: Array<{
    name: string
    key: string
    icon?: string
    isSelected: boolean
    isDisabled?: boolean
    isLabelDisabled?: boolean
  }>
  selectedItems?: Array<string>
  // Additional classes optionally
  classes?: Array<string>
  //// Event handlers
  // Handler for choosing item
  onSelect: (selectedKeys: Array<string>) => void
  onSelectItem?: (key: string, selectedKeys: Array<string>) => void
  onDeselectItem?: (key: string, selectedKeys: Array<string>) => void

  subdued?: boolean

  disabled?: boolean
  leftAlign?: boolean

  onReset?: () => void
}
export interface ChipSelectBoxState {
  focus: boolean
  highlightedIndex: number
  filteredItems: Array<{
    name: string
    key: string
    icon?: string
    isSelected: boolean
    isDisabled?: boolean
    isLabelDisabled?: boolean
  }>
  selectedItems: Array<string>
}
interface ChipSelectBoxRefs {
  popupMenu: React.RefObject<PopupMenu>
  list: React.RefObject<HTMLUListElement>
  filterBox?: React.RefObject<LuruTextBox>
}

export default class ChipSelectBox extends Component<ChipSelectBoxProps, ChipSelectBoxState> {
  props: ChipSelectBoxProps
  state: ChipSelectBoxState
  componentRefs: ChipSelectBoxRefs
  eventHandler: ChipSelectBoxEventHandler
  constructor(props: ChipSelectBoxProps) {
    super(props)
    this.props = props
    this.state = {
      highlightedIndex: -1,
      focus: false,
      filteredItems: props.items || [],
      selectedItems: props?.selectedItems || props?.items?.filter?.((x) => x.isSelected).map((f) => f.key) || [],
    }
    this.componentRefs = {
      popupMenu: React.createRef(),
      list: React.createRef(),
      filterBox: React.createRef(),
    }
    this.eventHandler = new ChipSelectBoxEventHandler(this)
  }

  render() {
    var labelElement = this.#renderLabelElement()
    var popupElement = this.#renderPopupElement()

    return (
      <PopupMenu
        ref={this.componentRefs.popupMenu}
        menuParentSelector={this.props.menuParentSelector}
        items={popupElement}
        popupMenuClassName={styles.popupMenu}
        className={styles.popupMenuContainer}
        hideOnMenuClick={false}
        disabled={this.props.disabled}
        leftalign={this.props.leftAlign}
        isGrowingParent={true}
      >
        {labelElement}
      </PopupMenu>
    )
  }

  #renderLabelElement() {
    const { focus, selectedItems = [] } = this.state
    const { items, classes = [], disabled, loading } = this.props

    return (
      <div className={[styles.parent, focus ? styles.parentFocused : '', ...classes].join(' ')}>
        <div className={styles.chipsContainer}>
          {selectedItems.map((f) => {
            const data = items.find((d) => d.key === f)
            return (
              <Chip
                key={f}
                title={data?.name || ''}
                toolTip={data?.name || ''}
                identityKey={f}
                onRemove={this.eventHandler.handlers.onToggleItem}
                disabled={disabled}
                loading={loading}
              />
            )
          })}
          <div className={styles.inputContainer}>
            <LuruTextBox
              additionalClassNames={[styles.textInput]}
              ref={this.componentRefs.filterBox}
              onChange={this.eventHandler.handlers.onFilterBoxChange}
              onNavigateDown={this.eventHandler.handlers.onFilterBoxNavigateDown}
              readOnly={this.props.disabled}
              onNavigateUp={this.eventHandler.handlers.onFilterBoxNavigateUp}
              onReturn={this.eventHandler.handlers.onFilterBoxReturn}
              onFocus={this.eventHandler.handlers.onFocus}
              onBlur={this.eventHandler.handlers.onBlur}
              placeholder='Select'
            />
          </div>
        </div>
      </div>
    )
  }

  #renderPopupElement() {
    return <div>{this.#renderMenuItems()}</div>
  }

  #renderMenuItems() {
    var height = `calc((2.5em + 2px) * ${Math.min(this.state.filteredItems.length, 5)} + 1px)`
    var style = { height }

    return (
      <ul style={style} className={[luruMultiSelectPopupStyles.menuList].join(' ')} ref={this.componentRefs.list}>
        {this.state.filteredItems.map((item, index) => (
          <li
            key={item?.key}
            className={[
              index === this.state.highlightedIndex ? luruMultiSelectPopupStyles.highlightedItem : null,
              item?.icon ? luruMultiSelectPopupStyles.withIcon : null,
            ].join(' ')}
            data-luru-role='multi-select-popup-list-item'
            data-highlighted={index === this.state.highlightedIndex ? 'yes' : 'no'}
            data-luru-multiselect-disabled={item?.isDisabled ? 'true' : 'false'}
            data-luru-multiselect-label-disabled={item?.isLabelDisabled ? 'true' : 'false'}
            data-luru-multiselect-index={index}
            onClick={() => this.eventHandler.handlers.onToggleItem(item?.key)}
          >
            {item?.icon ? <img data-role='list-item-icon' src={item?.icon} alt={item?.name} /> : null}
            <label>{item?.name}</label>
            {this.state.selectedItems.find((x) => x === item?.key) && (
              <img src={checkmarkIcon} alt='done' className={styles.checkedIcon} />
            )}
          </li>
        ))}
      </ul>
    )
  }
}

interface Props {
  title: string | JSX.Element
  toolTip?: string
  identityKey: string
  onRemove?: (key: string, e?: any) => void
  hideRemove?: boolean
  disabled?: boolean
  classes?: Array<string>
  titleClass?: string
  loading?: boolean
}

export function Chip({
  title,
  toolTip,
  identityKey,
  onRemove,
  hideRemove,
  disabled,
  loading,
  classes = [],
  titleClass = '',
}: Props) {
  const handleClickRemove = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (disabled || loading) {
        return
      }
      onRemove?.(identityKey, e)
    },
    [disabled, loading, onRemove, identityKey]
  )
  return (
    <div className={[styles.chip, ...classes].join(' ')} id='luru_chip'>
      <span title={toolTip} className={[styles.chipTitle, titleClass].join(' ')}>
        {loading ? 'Loading...' : title}
      </span>
      {!hideRemove && (
        <span title='Remove' className={styles.closeIcon} onClick={handleClickRemove}>
          <img src={dismissIcon} alt='Remove' />
        </span>
      )}
    </div>
  )
}
