import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { ListUsersParameter } from '../middleware/listUsers'

export const listUsers = async (
  param: ListUsersParameter,
  options: LuruAPIRequestOptions
) => {
  const { forceRefresh } = param
  var request = new LuruAPIRequest(
    forceRefresh ? '/api/chat/users?force_refresh=true' : '/api/chat/users',
    HTTPMethod.GET,
    options
  )
  return request.make()
}
