import { RootState } from '../../../app/store'
import { ThreeWayAnswer } from '../../../app/types'
import { CalendarProvider, CRMProvider } from '../../../features/user/types'
import { NoteId } from '../types'

export const doesSORConnectionExist = (
  state: RootState,
  noteId: NoteId,
  sorId: CRMProvider | CalendarProvider
) => {
  var record = state.notes.entities ? state.notes.entities[noteId] : null

  // If there is no note record or data in it, return null
  if (!record || typeof record !== 'object' || !record.data || !sorId) {
    return ThreeWayAnswer.UNCERTAIN
  }

  if (!Array.isArray(record.data.connections)) {
    return ThreeWayAnswer.NO
  }

  let connection = record.data.connections.find((item) => item.sor === sorId)

  if (!connection) {
    return ThreeWayAnswer.NO
  }

  return ThreeWayAnswer.YES
}
