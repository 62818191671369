import { useCallback, useEffect } from 'react'
import { UseNotificationControllerComponentState } from './useComponentState'
import { NotificationControllerProps } from '.'
import { useUserNotifications } from '../../features/user/hooks/useUserNotifications'
import {
  CRMProvider,
  LocalUserSettingKey,
  LocalUserSettings,
  NotificationCategory,
  NotificationState,
  UserNotification,
} from '../../features/user/types'
import { localUserSettings } from '../../features/user/localUserSettings'
import LuruUser from '../../domain/users/LuruUser'

export default function useNotificationControllerEventHandlers(
  sharedState: UseNotificationControllerComponentState,
  props: NotificationControllerProps
) {
  const userNotifications = useUserNotifications()
  const {
    toBeShownNotifications: { getToBeShownNotifications, setToBeShownNotifications },
    localUserNotificationSettings: { getLocalUserNotificationSettings, setLocalUserNotificationSettings },
    futureNotificationTimers: { getFutureNotificationTimers, setFutureNotificationTimers },
  } = sharedState

  const setUpTimerForFutureNotifications = useCallback(
    (notifications: Array<UserNotification>) => {
      var currentDate = new Date()
      notifications.forEach((f) => {
        try {
          if (f.notify_at) {
            //Remove any old timeOutIns
            if (getFutureNotificationTimers[f.id]) {
              clearTimeout(getFutureNotificationTimers[f.id])
              setFutureNotificationTimers((prevState) => {
                delete prevState?.[f.id]
                return prevState
              })
            }

            const timeOutIns = setTimeout(() => {
              //Check If future notification is of more priprity, then push it to begnining of the array else push it to end
              if (f.category === NotificationCategory.MODAL && !f.dismissible) {
                setToBeShownNotifications((prevState) => [f, ...prevState])
              } else {
                setToBeShownNotifications((prevState) => [...prevState, f])
              }

              //Remove old timeOutIns
              clearTimeout(getFutureNotificationTimers[f.id])
              setFutureNotificationTimers((prevState) => {
                delete prevState?.[f.id]
                return prevState
              })
            }, new Date(f.notify_at).getTime() - currentDate.getTime())
            setFutureNotificationTimers((prevState) => ({ ...prevState, [f.id]: timeOutIns }))
          }
        } catch (error) {
          console.warn(error)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setToBeShownNotifications, setFutureNotificationTimers]
  )

  // Component Mount
  useEffect(() => {
    const userNotificationSettings =
      (localUserSettings.get(
        LocalUserSettingKey.USER_NOTIFICATION_SETTINGS
      ) as LocalUserSettings[LocalUserSettingKey.USER_NOTIFICATION_SETTINGS]) || []

    const { notificationsWhichAreInPast, notificationsWhichAreInFuture } = getFilteredAndSortedUserNotifications(
      userNotifications,
      userNotificationSettings
    )

    setToBeShownNotifications(notificationsWhichAreInPast)

    setLocalUserNotificationSettings(userNotificationSettings)

    setUpTimerForFutureNotifications(notificationsWhichAreInFuture)

    return () => {
      //Component UnMount
      Object.values(getFutureNotificationTimers) ||
        [].forEach((timeOutIns) => {
          clearTimeout(timeOutIns)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseNotification = (notification?: UserNotification) => {
    const oldLocalUserNotificationSettings = [...getLocalUserNotificationSettings]

    if (notification) {
      //Store hidden notification state to localUserSettings
      const notificationSettingToStore = {
        notificationId: notification.id,
        reminder_frequency: notification?.reminder_frequency || 0,
        hidden: true,
        hiddenAt: new Date(),
        hiddenCount: 1,
        crm: LuruUser.getCurrentUserCrmName() as CRMProvider,
      }
      const alreadyPresentNotificationIndx = oldLocalUserNotificationSettings?.findIndex(
        (f) => f.notificationId === notification?.id
      )
      if (alreadyPresentNotificationIndx === -1) {
        oldLocalUserNotificationSettings.push(notificationSettingToStore)
      } else {
        var oldNotification = oldLocalUserNotificationSettings[alreadyPresentNotificationIndx]
        oldLocalUserNotificationSettings[alreadyPresentNotificationIndx] = {
          ...oldNotification,
          hiddenCount: oldNotification.hiddenCount ? oldNotification.hiddenCount + 1 : 0,
        }
      }
      localUserSettings.set(LocalUserSettingKey.USER_NOTIFICATION_SETTINGS, oldLocalUserNotificationSettings)

      //Remove this notification from toBeShownNotifications array
      const oldToBeShownNotifications = [...getToBeShownNotifications]
      const notificationIndx = oldToBeShownNotifications?.findIndex?.((f) => f.id === notification.id)
      if (notificationIndx !== -1) {
        oldToBeShownNotifications.splice(notificationIndx, 1)
        setToBeShownNotifications(oldToBeShownNotifications)
      }
    }
  }

  const getFilteredAndSortedUserNotifications = (
    notifications: Array<UserNotification>,
    userNotificationSettings: LocalUserSettings[LocalUserSettingKey.USER_NOTIFICATION_SETTINGS]
  ) => {
    var currentDate = new Date()
    //Filter non expired and  ACTIVE notifications
    var filteredAndSortedUserNotifications = notifications.filter(
      (f) => (!f.expiry_at ? true : currentDate <= new Date(f.expiry_at)) && f.state === NotificationState.ACTIVE
    )

    //Get past notifications and sort based on priority and notify_at
    var notificationsWhichAreInPast = filteredAndSortedUserNotifications
      .filter((f) => (!f.notify_at ? true : new Date(f.notify_at) <= currentDate))
      ?.sort?.((a, b) => new Date(a.notify_at || new Date()).getTime() - new Date(b.notify_at || new Date()).getTime()) //Sort by notify_at
      ?.sort?.((a, b) => {
        // Compare the category values
        if (a.category === NotificationCategory.MODAL && b.category !== NotificationCategory.MODAL) {
          return -1 // a comes before b
        }
        if (a.category !== NotificationCategory.MODAL && b.category === NotificationCategory.MODAL) {
          return 1 // b comes before a
        }

        // Both notifications have the same category, compare dismissible values
        if (a.dismissible === false && b.dismissible !== false) {
          return -1 // a comes before b
        }
        if (a.dismissible !== false && b.dismissible === false) {
          return 1 // b comes before a
        }

        // If category and dismissible values are the same or not applicable, maintain the original order
        return 0
      })

    //Get future notifications and sort based on priority and notify_at
    var notificationsWhichAreInFuture = filteredAndSortedUserNotifications
      .filter((f) => (!f.notify_at ? false : new Date(f.notify_at) > currentDate))
      ?.sort?.((a, b) => new Date(a.notify_at || new Date()).getTime() - new Date(b.notify_at || new Date()).getTime()) //Sort by notify_at
      ?.sort?.((a, b) => {
        // Compare the category values
        if (a.category === NotificationCategory.MODAL && b.category !== NotificationCategory.MODAL) {
          return -1 // a comes before b
        }
        if (a.category !== NotificationCategory.MODAL && b.category === NotificationCategory.MODAL) {
          return 1 // b comes before a
        }

        // Both notifications have the same category, compare dismissible values
        if (a.dismissible === false && b.dismissible !== false) {
          return -1 // a comes before b
        }
        if (a.dismissible !== false && b.dismissible === false) {
          return 1 // b comes before a
        }

        // If category and dismissible values are the same or not applicable, maintain the original order
        return 0
      })

    // Filter out hidden notifications older than 3 days and also check for reminder_frequency
    notificationsWhichAreInPast = notificationsWhichAreInPast.filter(function (notification) {
      var hiddenNotification = userNotificationSettings.find(function (setting) {
        return setting.notificationId === notification.id
      })
      if (!hiddenNotification) {
        return true
      }
      //Do not show it again, one time reminder only
      if (!notification.reminder_frequency || notification.reminder_frequency === 0) {
        return false
      }

      var isHiddenMoreThanReminderFrequencyBack =
        new Date().valueOf() - new Date(hiddenNotification.hiddenAt).valueOf() > notification.reminder_frequency
      return isHiddenMoreThanReminderFrequencyBack
    })

    // Filter out hidden notifications older than 3 days and also check for reminder_frequency
    notificationsWhichAreInFuture = notificationsWhichAreInFuture.filter(function (notification) {
      var hiddenNotification = userNotificationSettings.find(function (setting) {
        return setting.notificationId === notification.id
      })
      if (!hiddenNotification) {
        return true
      }

      //Do not show it again, one time reminder only
      if (!notification.reminder_frequency || notification.reminder_frequency === 0) {
        return false
      }

      var isHiddenMoreThanReminderFrequencyBack =
        new Date().valueOf() - new Date(hiddenNotification.hiddenAt).valueOf() > notification.reminder_frequency
      return isHiddenMoreThanReminderFrequencyBack
    })

    return {
      notificationsWhichAreInPast,
      notificationsWhichAreInFuture,
    }
  }

  return {
    handleCloseNotification,
  }
}

export type UseNotificationControllerEventHandlers = ReturnType<typeof useNotificationControllerEventHandlers>
