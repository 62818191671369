import { RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { DateUtils } from '../../../utils/dateUtils'
import { ReduxMeetingEntity } from '../types'

export const getDefaultMeetings = () => (state: RootState) => {
  return filterThisWeekMeetings(state)
}

export const filterThisWeekMeetings = (state: RootState) => {
  var currWeekStart = DateUtils.getStartOfWeek()
  var currWeekEnd = DateUtils.getEndOfWeek()
  var meetingEntities = state.meetings.entities

  if (!meetingEntities) {
    return []
  }

  return Object.keys(meetingEntities)
    .filter(
      (meetingId) =>
        meetingEntities?.[meetingId].data &&
        meetingEntities?.[meetingId].status === EntityStatus.Loaded
    )
    .reduce((result, meetingId) => {
      if (!state.meetings.entities?.[meetingId]) {
        return result
      }

      var meetingStartTime = new Date(
        state.meetings.entities[meetingId].data.start_time
      )

      if (
        meetingStartTime >= currWeekStart &&
        meetingStartTime <= currWeekEnd &&
        state.meetings.entities[meetingId].data
      ) {
        result.push(state.meetings.entities[meetingId].data)
      }

      return result
    }, [] as Array<ReduxMeetingEntity>)
}
