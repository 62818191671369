// React
import { useState, useEffect, forwardRef, useRef } from 'react'

// Redux
import { useSelector } from 'react-redux'

// Styles
import styles from './css/TypeAheadSearchBox.module.css'
import loadingStyles from './css/LoadingAnimation.module.css'

//Icons
import closeIcon from '../images/fluent/close.svg'

/**
 * TypeAheadSearchBox component that triggers a search once every while.
 * To be used by components that require a dynamic search interface.
 * This component can call onTriggerSearch and onClearSearch functions given
 * as props on events that trigger a search and clear a search (press of Esc
 * key). It can also accept a minSearchTextLength attribute to conditionally
 * trigger a 'typeAhead' search.  It can accept a 'clear' event to clear itself.
 */
export default forwardRef(function TypeAheadSearchBox(props, searchBoxRef) {
  ////// Component props
  const typeAheadDelay = props.typeAheadDelay ?? 400
  const minSearchTextLength = props.minSearchTextLength ?? 3
  const [disabled, setDisabled] = useState(props.disabled)
  const containerRef = useRef()

  useEffect(() => {
    setDisabled(props?.disabled)
  }, [props?.disabled])
  ////// Component state
  // Q: Why do we need a state that is derived from props?
  // A: In the intervening period between two triggers, as defined by the
  // 'TypeAhead' delay, searchText state of parent (given as props to this
  // component) will be different from the searchText displayed by the input
  // search box of this component.  The local state here is purely to control
  // the input element
  const reduxSearchQuery = useSelector(props.searchQuerySelector ?? ((state) => undefined))
  const [searchText, setSearchText] = useState(reduxSearchQuery ?? props.searchText ?? '')
  const [searchTimer, setSearchTimer] = useState(null)

  ////// Component views
  function render() {
    return (
      <div className={styles.main} data-luru-role='type-ahead-search-box' data-luru-status='' ref={containerRef}>
        <input
          type='search'
          placeholder={props.placeholder ?? (!disabled ? 'Start typing to search...' : '')}
          aria-label={props.ariaLabel ?? 'Search'}
          value={searchText}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          ref={searchBoxRef ?? null}
          className={props.withIcon ? styles.withIcon : null}
          data-role={props.luruRole ?? 'type-ahead-search-input'}
          data-luru-element-type='type-ahead-search-box'
          disabled={disabled ?? null}
        />
        {props.loading ? <span className={[loadingStyles.loading, styles.loading].join(' ')}></span> : ''}
        {searchText.length === 0 || disabled ? null : (
          <span className={styles.closeIcon} onClick={clearSearch}>
            <img
              src={closeIcon}
              alt='closeIcon'
              className={props.placeholder === 'Search all notes' ? styles.closeImg : null}
            />
          </span>
        )}
      </div>
    )
  }

  ////// Component event creators - surface events above
  // Clear search and exec event handler, if any
  function clearSearch(e) {
    e.stopPropagation()
    e.preventDefault()
    if (disabled) {
      return
    }
    setSearchText('')
    clearTimeout(searchTimer)
    if (props.onClearSearch) {
      props.onClearSearch(e)
    }
  }

  function triggerSearch(e) {
    const text = e.target.value
    e.data = {
      searchText: text,
    }
    props.onTriggerSearch && props.onTriggerSearch(e)
  }

  //// Component event handlers (facing user, handle events from below)
  function onFocus(e) {
    if (containerRef.current) {
      containerRef.current.dataset.luruStatus = 'focused'
    }

    props.onFocus && props.onFocus(e)
  }

  function onBlur(e) {
    if (containerRef.current) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.dataset.luruStatus = ''
        }
      }, 100)
    }

    props.onBlur && props.onBlur(e)
  }

  function onChange(e) {
    const text = e.target.value
    setSearchText(text)
    // Clear any pending trigger search callbacks
    clearTimeout(searchTimer)
    // Set a timer, if necessary, to trigger a search
    if (text.length >= minSearchTextLength && props.onTriggerSearch) {
      setSearchTimer(setTimeout(() => triggerSearch(e), typeAheadDelay))
    } else if (text.length === 0) {
      props.onClearSearch && props.onClearSearch(e)
    }
  }

  function onKeyDown(e) {
    // If escape is pressed, clear search and return
    if (e.key === 'Escape') {
      clearSearch(e)
      e.preventDefault()
      e.stopPropagation()
    } else if (e.key === 'Enter') {
      // Clear the timeout and trigger a search immediately.
      // The timeout action would have anyway triggered the same search - it
      // would have been set just before the enter key was pressed.
      // By clearing out this timer, we are explicitly having search execute
      // immediately and avoid any duplicate search getting triggered
      clearTimeout(searchTimer)
      if (e?.target?.value?.trim?.()) {
        // Trigger search iff there is a search value present after trim, avoiding searching with empty string OR space
        triggerSearch(e)
      }
      e.preventDefault()
      e.stopPropagation()
    }
    props.onKeyDown && props.onKeyDown(e)
  }

  // Users of this component may provide a ref (called as searchRef)
  // This ref's current DOMNode can listen for a CustomEvent named 'clear'
  // if anyone wants to clear this search box.  This event is necessary because
  // searchText is a local state (as explained above) and not a state managed
  // by hosting component, even if we derive the initial value of this local
  // state from props. For clearing, one can dispatch an event as follows:
  // searchRef.current.dispatchEvent(new CustomEvent('clear'));
  // This function is different from the clearSearch() function above.  This
  // is about handling an event that comes from above inside this component.
  // The function above (clearSearch()) is about handling an event that
  // originated here and is surfaced up to the parent container.
  useEffect(() => {
    let searchBox = searchBoxRef?.current
    function clearSearchBox() {
      setSearchText('')
    }
    if (searchBox) {
      searchBox.addEventListener('clear', clearSearchBox)
    }
    return () => {
      if (searchBox) {
        searchBox.removeEventListener('clear', clearSearchBox)
      }
    }
  }, [searchBoxRef])

  // Check for editor having made this component editable
  useEffect(() => {
    if (searchBoxRef?.current?.getAttribute('data-luru-state') === 'made-editable') {
      setDisabled(false)
    }
  }, [setDisabled, searchBoxRef])

  return render()
})
