import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { EmptyMeetingsSearchState, ReduxMeetingsState } from '../types'

export const clearSearch: CaseReducer<
  ReduxMeetingsState,
  PayloadAction<string>
> = (state, action) => {
  let callerId = action.payload

  if (!state.search) {
    state.search = {}
  }
  state.search[callerId] = EmptyMeetingsSearchState
}
