import { lazy } from 'react'
import { renderWithLazyLoading } from '../../../WebAppLoading'

const EmbeddedMeetingNoteWrapper = lazy(() => import('../../../../routes/meetingNotes/EmbeddedMeetingNote'))

const EmbeddedMeetingNoteWrapperLazyLoading = renderWithLazyLoading(
  <EmbeddedMeetingNoteWrapper embeddedNoteId={undefined} meetingUrl={undefined} />
)

export default EmbeddedMeetingNoteWrapperLazyLoading
