import * as React from 'react'
import { LuruReduxStore, RootState } from '../../../../../app/store'
import { connect, ConnectedProps } from 'react-redux'
import ModalScreen from '../../../../../primitives/ModalScreen'
import { WorkflowsMiddleware } from '../../../../../features/workflows/middleware'
import { ConnectLuruExtensionsProps, EntityStatus } from '../../../../../app/types'
import { WorkflowState } from '../../../../../features/workflows/types'
import gchatLogo from '../../../../../images/sor/google_chat/logo.png'
import IntegrationCard from '../IntegrationCard'
import { getAuthUrl } from '../../../../../domutils/utils'
import CrmRecord from '../../../../../domain/crmRecord/CrmRecord'
import { UserMiddleware } from '../../../../../features/user/middleware'
import LuruUser from '../../../../../domain/users/LuruUser'
import { MessagingProvider } from '../../../../../features/user/types'
import Utils from '@/utils/Utils'
import { ToastId } from '@/app_ui/types'
import { Link } from 'react-router-dom'
import styles from './styles.module.css'

function getGChatStatusFromRedux(state: RootState) {
  return {
    workflowsWithActiveState: Object.entries(state?.workflows?.entities).filter(
      ([, wf]) => wf.data.state === WorkflowState.ACTIVE
    ),
    workflowStatus: state.workflows.status,
  }
}

const connector = connect(getGChatStatusFromRedux, null, null, {
  forwardRef: true,
})

export interface GChatCardRefs {
  disconnectModalRef: React.RefObject<ModalScreen>
}

export interface GChatCardProps extends ConnectedProps<typeof connector>, ConnectLuruExtensionsProps {}

interface GChatCardState {
  disconnecting: boolean
}

class GChatCard extends React.Component<GChatCardProps, GChatCardState> {
  props: GChatCardProps
  state: GChatCardState
  componentRefs: GChatCardRefs

  constructor(props: GChatCardProps) {
    super(props)
    this.props = props
    this.state = {
      disconnecting: false,
    }
    this.componentRefs = {
      disconnectModalRef: React.createRef(),
    }
  }

  componentDidMount(): void {
    if (this.props.workflowStatus !== EntityStatus.Loaded) {
      LuruReduxStore.dispatch(WorkflowsMiddleware.listWorkflows.action({}))
    }
  }

  connectToGChat = () => {
    // Add redirect path so that we end up at the exact page we are in now
    var path = window === window.top ? window.location.pathname ?? '/' : '/'
    var gchatConnectUrl = `${getAuthUrl()}/login/google-oauth2-gchat/?next=/next${path}`

    if (window === window.top) {
      window.location.href = gchatConnectUrl
    } else {
      window.open(gchatConnectUrl)
    }
  }

  onClickDisconnectGChat = () =>
    this.componentRefs.disconnectModalRef.current?.showModal({
      ok: () => {
        this.setState({ disconnecting: true })
        this.props.toast.showToast({
          id: ToastId.SETTING_INTEGRATION_TOAST_ID,
          message: 'Disconnecting',
          isLoading: true,
        })
        LuruReduxStore.dispatch(UserMiddleware.disconnectGChat.action({}))
          .unwrap()
          .then(() => {
            this.props.toast.showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Disconnected Google Chat',
              severity: 'success',
            })
            this.setState({ disconnecting: false })
          })
          .catch((err: any) => {
            this.props.toast.showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Error disconnecting Google Chat',
              severity: 'error',
            })
            this.setState({ disconnecting: false })
          })
      },
    })

  render = () => {
    const messagingProvider = LuruUser.getCurrentUserMessagingProviderName()
    return (
      <>
        <IntegrationCard
          sor='Google'
          heading='Google Chat'
          body={`Automate your workflows, configure bi-directional alerts and manage ${CrmRecord.getCrmName()} right from within Google Chat`}
          logoPath={gchatLogo}
          isConnected={messagingProvider === MessagingProvider.GCHAT}
          disableConnect={
            messagingProvider === MessagingProvider.MSTEAMS || messagingProvider === MessagingProvider.SLACK
          }
          handleConnect={this.connectToGChat}
          handleDisconnect={this.onClickDisconnectGChat}
          height='15em'
        />
        <ModalScreen title='Disconnect Google Chat' ref={this.componentRefs.disconnectModalRef}>
          {this.props.workflowsWithActiveState.length > 0 ? (
            <span>
              You have {this.props.workflowsWithActiveState.length} active workflow(s).
              <br />
              NOTE: After disconnecting, active workflows will continue to be able to send messages to your GChat spaces
              where the Luru app is installed. If you want to stop the workflows, you can
              <br />
              <span>
                1. Disable / delete the{' '}
                <Link to='/workflows' className={styles.navigateLink}>
                  workflows
                </Link>
                <br />
                2. Remove Luru app from the respective GChat spaces
              </span>
            </span>
          ) : (
            <span>Do you want to disconnect from Google Chat?</span>
          )}
        </ModalScreen>
      </>
    )
  }
}

export default React.forwardRef(Utils.connectLuruExtensions(connector(GChatCard)))
