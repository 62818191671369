import { LocalUserSettingKey, LocalUserSettings } from './types'

export const LocalUserSettingsKey = 'localUserSettings'

const defaultLocalUserSettings = {
  [LocalUserSettingKey.DARK_THEME]: false,
  [LocalUserSettingKey.CONNECT_CALENDAR_DIALOG_HIDDEN]: false,
}

export function isLocalStorageAvailable() {
  try {
    return typeof localStorage !== 'undefined'
  } catch (e) {
    return false
  }
}

export const localUserSettings = {
  init() {
    if (isLocalStorageAvailable() && !localStorage.getItem(LocalUserSettingsKey)) {
      localStorage.setItem(LocalUserSettingsKey, JSON.stringify(defaultLocalUserSettings))
    }
  },

  get(name: LocalUserSettingKey) {
    if (!isLocalStorageAvailable()) {
      return undefined
    }

    var userSettingsJSON = localStorage.getItem(LocalUserSettingsKey) as string | null

    if (!userSettingsJSON) {
      return undefined
    }

    try {
      let obj = JSON.parse(userSettingsJSON) as LocalUserSettings
      return obj?.[name]
    } catch (e) {
      return undefined
    }
  },

  set(name: LocalUserSettingKey, value: unknown) {
    if (!isLocalStorageAvailable()) {
      return
    }

    var userSettingsJSON = localStorage.getItem(LocalUserSettingsKey)

    localStorage.setItem(
      LocalUserSettingsKey,
      JSON.stringify(
        Object.assign(userSettingsJSON ? JSON.parse(userSettingsJSON) : {}, {
          [name]: value,
        })
      )
    )
  },
}
