import { LuruReduxStore } from '../../../app/store'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { ViewsMiddleware } from '../middleware'
import { ViewType } from '../types'

export const fetchAllViews = async () => {
  var types = [ViewType.LURU, ViewType.SOR]
  var crmObjects = CrmRecord.getPrimaryCrmObjects().map((v) =>
    v.plural.toLocaleLowerCase()
  )

  for (let type of types) {
    for (let object_name of crmObjects) {
      await LuruReduxStore.dispatch(
        ViewsMiddleware.listViews.action({ type, object_name })
      )
    }
  }
}
