import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxNoteTemplatesState } from '../types'

export const updateTitle: CaseReducer<
  ReduxNoteTemplatesState,
  PayloadAction<{ noteTemplateId: string; title: string }>
> = (state, action) => {
  var { noteTemplateId, title } = action.payload

  if (state.entities[noteTemplateId]?.data) {
    state.entities[noteTemplateId].data!.title = title
  }
}
