import Utils from '@/utils/Utils'
import { NotesMiddleware } from '../../../features/notes/middleware'
import ConfirmDialog from '../../../primitives/ConfirmDialog'
import { ToastId } from '@/app_ui/types'
import React from 'react'

class NoteDeleteConfirmDialog extends ConfirmDialog {
  constructor(props) {
    super(props)
    this.state = { title: 'Confirm', message: 'Message', buttons: [] }
  }

  showForNote({ crmId, noteId, syncState, navigateCallback, dispatch }) {
    this.dispatch = dispatch
    this.navigateCallback = navigateCallback
    if (syncState === 'private') {
      this.showForPrivateNote({ crmId, noteId, syncState })
    } else if (syncState === 'synced') {
      this.showForSyncedNote({ crmId, noteId, syncState })
    }
  }

  showForPrivateNote({ crmId, noteId, syncState, navigateCallback }) {
    const deleteFunction = () => {
      this.deleteNoteFromBackend({ noteId, propagate: false })
    }
    deleteFunction.bind(this)
    const title = 'Delete note'
    const message = 'Do you want to delete this note?'
    const buttons = [
      // Cancel button
      {
        title: 'Cancel',
        isOkButton: false,
      },
      // OK button
      {
        title: 'OK',
        isOkButton: true,
        onClick: deleteFunction,
      },
    ]
    super.setDialog({ title, message, buttons })
    super.showDialog()
  }

  showForSyncedNote({ crmId, noteId, syncState, navigateCallback }) {
    const title = 'Delete note'
    const crmName = {
      SFDC: 'Salesforce',
      HUBSPOT: 'HubSpot',
      PIPEDRIVE: 'Pipedrive',
    }
    const message = [`Delete note from Luru alone?`, `Or from ${crmName[crmId] ?? 'CRM'} also?`].join(' ')

    const deleteFunction = (propagate) => {
      this.deleteNoteFromBackend({ noteId, propagate })
    }
    deleteFunction.bind(this)

    const buttons = [
      // cancelButton,
      {
        title: 'Cancel',
        isOkButton: false,
      },
      // deleteOnlyLuruButton,
      {
        title: 'Delete from Luru alone',
        isOkButton: true,
        onClick: () => deleteFunction(false),
      },
      // deleteCrmAlsoButton
      {
        title: `Delete from Luru and ${crmName[crmId] ?? 'CRM'}`,
        isOkButton: true,
        onClick: () => deleteFunction(true),
      },
    ]
    super.setDialog({ title, message, buttons })
    super.showDialog()
  }

  deleteNoteFromBackend({ noteId, propagate, redirectUrl }) {
    this.props.toast.showToast({
      id: ToastId.NOTES_EDITOR_TOAST_ID,
      message: 'Deleting note',
      isLoading: true,
    })
    this.dispatch(
      NotesMiddleware.deleteNote.action({
        key: 'popupMenu',
        propagate: propagate,
        noteId: noteId,
      })
    )
      .unwrap()
      .then(() => {
        this.props.toast.showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Deleted note',
          severity: 'success',
        })
        this.navigateCallback()
      })
      .catch((error) =>
        this.props.toast.showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: error?.message ?? 'Error deleting note',
          severity: 'error',
        })
      )
  }
}

export default React.forwardRef(Utils.connectLuruExtensions(NoteDeleteConfirmDialog))
