import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxUserState } from '../types'
import { LuruUserRole } from '@/features/users/types'

export const usersSliceUpdateLuruUserRoles: CaseReducer<ReduxUserState, PayloadAction<Array<LuruUserRole>>> = (
  state,
  action
) => {
  // @ts-ignore
  const userId = action.meta.arg.userId
  const newRoles = action.payload
  if (userId === state.data.user_id) {
    state.data.userRoles = newRoles
  }
  console.log('usersSliceUpdateLuruUserRoles:action:', action)
}
