import { HTTPMethod } from '@/app/types'
import { SetEmailSyncInfoParams } from '../middleware/setEmailSyncInfo'
import LuruAPIRequest from '@/features/LuruAPIRequest'

export const setEmailSyncInfo = async ({ syncState }: SetEmailSyncInfoParams) => {
  var setEmailInfoUrl = '/api/email_sync'
  var request = new LuruAPIRequest(setEmailInfoUrl, HTTPMethod.PUT)

  request.setBody({
    status: syncState,
  })

  return request.make()
}
