import { HTTPMethod } from '../../../app/types'
import { CrmObjectName } from '../../crm/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CRMProvider } from '../../user/types'
import { LuruFilterInput } from '../types'
import { ListViewRecordsAPIResponse } from './listRecords'

export interface GetTemporaryViewRecordsParameter {
  viewId: string
  cursor: string | null
  count?: number
  order_by?: string | undefined
  input: LuruFilterInput
  output: {
    sor: CRMProvider
    sor_object_name: CrmObjectName
  }
}

export const getTemporaryViewRecords = async (
  params: GetTemporaryViewRecordsParameter,
  options: LuruAPIRequestOptions
): Promise<ListViewRecordsAPIResponse> => {
  var { cursor, count, order_by }: Record<string, any> = params
  var queryStringParams = new URLSearchParams({})
  var requestPayload = {
    input: params.input,
    output: params.output,
  }

  if (cursor) {
    queryStringParams.append('cursor', cursor)
  }

  if (count) {
    queryStringParams.append('count', count + '')
  }

  if (order_by) {
    queryStringParams.append('order_by', order_by)
  }

  var url = `/api/views/temporary/records/list?` + new URLSearchParams(queryStringParams)
  var request = new LuruAPIRequest(url, HTTPMethod.POST, options)

  request.setBody(requestPayload)

  return request.make()
}
