import json5 from 'json5'
import { JottingType } from '../features/notes/types'
import { LuruReduxStore } from '../app/store'
import moment from 'moment'
/**
 * HtmlConvertor has utility method for converting a note to a HTML
 * This is useful when we want to share the note as HTML or copy-to-clipboard
 */
export class HtmlConvertor {
  static convertLuruNoteToHtmlWithFooter(body, userName, noteTitle, meetingId) {
    let html = ''
    html += this.getMeetingDetails(html, meetingId, noteTitle)
    html += HtmlConvertor.convertLuruNoteToHtml(body)

    html += `<br><footer><p><em>`
    if (userName !== null && userName !== undefined) {
      html += `${userName}'s note was powered by Luru </em></p></footer>`
    }

    return html
  }

  static getMeetingDetails(html, meetingId, noteTitle) {
    html += `<header>${noteTitle}</header><br>`

    const meetingDetails = LuruReduxStore.getState()?.meetings?.entities?.[meetingId]
    const participantNames =
      meetingDetails?.data?.participants?.map((p) => (p.name ? `${p.name} (${p.email})` : p.email)) || []
    const meetingInterval =
      meetingDetails?.data?.start_time && meetingDetails.data.end_time
        ? this.getMeetingStartAndEndDetails(meetingDetails.data.start_time, meetingDetails.data.end_time)
        : ''

    if (meetingInterval && participantNames) {
      html += `<strong>Meeting participants: </strong> ${participantNames.join(', ')}<br>`
      html += `<strong>Date & Time:</strong> ${meetingInterval}<br>`
    }

    return html
  }

  static getMeetingStartAndEndDetails(startTime, endTime) {
    const start = new Date(startTime)
    const end = new Date(endTime)

    if (start.toDateString() !== end.toDateString()) {
      return `${moment(startTime).format('LLLL')} - ${moment(endTime).format('LLLL')}`
    } else {
      return `${moment(startTime).format('LLLL')} - ${moment(endTime).format('h:mm A')}`
    }
  }

  static convertLuruNoteToHtml(body) {
    let i = 0
    let result = ''

    while (i < (body?.length ?? 0)) {
      let jot = body[i]
      let temp = ''

      switch (jot.type) {
        case JottingType.TASK_INCOMPLETE:
          ;[temp, i] = HtmlConvertor.#convertTaskIncompleteToHtml(body, i)
          break

        case JottingType.TASK_COMPLETE:
          ;[temp, i] = HtmlConvertor.#convertTaskCompleteToHtml(body, i)
          break

        case JottingType.H1:
        case JottingType.H2:
        case JottingType.H3:
          ;[temp, i] = HtmlConvertor.#convertHeadingToHtml(body, i)
          break

        case JottingType.P:
          ;[temp, i] = HtmlConvertor.#convertParaToHtml(body, i)
          break

        case JottingType.Q:
          ;[temp, i] = HtmlConvertor.#convertQuestionToHtml(body, i)
          break

        case JottingType.UL1:
        case JottingType.UL2:
        case JottingType.UL3:
          ;[temp, i] = HtmlConvertor.#convertUnorderedListToHtml(body, i)
          break

        case JottingType.OL1:
        case JottingType.OL2:
        case JottingType.OL3:
          ;[temp, i] = HtmlConvertor.#convertOrderedListToHtml(body, i)
          break

        case JottingType.CRM_FIELD_VALUE:
          ;[temp, i] = HtmlConvertor.#convertCrmFieldValueToHtml(body, i, false)
          break

        case JottingType.CRM_COLLECTION:
          ;[temp, i] = HtmlConvertor.#convertCrmFieldCollectionToHtml(body, i)
          break

        default:
          console.error(`Ignoring unrecognized JottingType at root level = ${jot.type}`)
          i++
      }

      result += temp + '\n'
    }

    return result
  }

  static #convertTaskIncompleteToHtml(body, i) {
    let jot = body[i]
    // assert(jot.type === JottingType.TASK_INCOMPLETE)
    let result = `<input type='checkbox'>
    <label>${jot.data}</label>
    <br>
    `
    return [result, i + 1]
  }

  static #convertTaskCompleteToHtml(body, i) {
    let jot = body[i]
    // assert(jot.type === JottingType.TASK_COMPLETE)
    let result = `<input type='checkbox' checked>
    <label>${jot.data}</label>
    <br>
    `
    return [result, i + 1]
  }

  static #convertHeadingToHtml(body, i) {
    let jot = body[i]
    let tag = ''
    switch (jot.type) {
      case JottingType.H1:
      case JottingType.A_H1:
        tag = 'h1'
        break
      case JottingType.H2:
      case JottingType.A_H2:
        tag = 'h2'
        break
      case JottingType.H3:
      case JottingType.A_H3:
      default:
        tag = 'h3'
        break
    }
    let result = `<${tag}>${jot.data}</${tag}>`
    return [result, i + 1]
  }

  static #convertParaToHtml(body, i) {
    let jot = body[i]
    let result = `<p>${jot.data}</p>`
    return [result, i + 1]
  }

  static #convertUnorderedListToHtml(body, i) {
    return this.#convertListToHtml(body, i, 'ul', HtmlConvertor.#isUnorderedListJot)
  }

  static #convertOrderedListToHtml(body, i) {
    return this.#convertListToHtml(body, i, 'ol', HtmlConvertor.#isOrderedListJot)
  }

  static #convertListToHtml(body, i, tag, checkFunc) {
    let ulJot = body[i]
    let currLevel = 0
    let result = ''
    while (ulJot && checkFunc(ulJot.type)) {
      let type = ulJot.type
      let prevLevel = currLevel
      // type is something like 'answerUnorderedListLevel3', pick the last char
      currLevel = Number(type.slice(type.length - 1))
      if (currLevel > prevLevel) {
        for (let k = prevLevel; k < currLevel; k++) {
          result += `<${tag}>\n`
        }
      } else if (currLevel < prevLevel) {
        for (let k = prevLevel; k > currLevel; k--) {
          result += `</${tag}>\n`
        }
      }
      result += `<li>${ulJot.data}</li>\n`
      ulJot = body?.[++i]
    }
    // Close all open uls now
    for (let k = currLevel; k > 0; k--) {
      result += `</${tag}>\n`
    }
    return [result, i]
  }

  static #convertCrmFieldCollectionToHtml(fields, e) {
    if (typeof fields[e].data.collection.fields[0] === 'string') {
      return ['', e + 1]
    }

    var result = '<h3>Crm Fields collection</h3> \n'
    var i = 0

    while (i < fields[e].data.collection.fields.length) {
      let temp = ''
      ;[temp, i] = HtmlConvertor.#convertCrmFieldValueToHtml(fields[e].data.collection.fields, i, true)
      result += temp + '\n'
    }

    return [result, e + 1]
  }

  static #convertCrmFieldValueToHtml(body, i, isCollectionField) {
    let jot = body?.[i]

    if (!jot) {
      return ['', i + 1]
    }

    if (!jot.data?.field?.luruFieldType && !isCollectionField) {
      return ['', i + 1]
    }
    let type = jot.data?.field?.luruFieldType || jot.luruFieldType
    let value = jot.data?.field?.value || jot.value

    if (type === 'reference') {
      if (typeof value === 'string') {
        try {
          let jsonValue = json5.parse(value)

          value = jsonValue.sor_record_name
        } catch (error) {
          console.error(error)
        }
      } else if (typeof value === 'object') {
        value = value.sor_record_name
      }
    } else if (type === 'multi_reference') {
      let jsonValue = json5.parse(value)

      value = ''

      for (let i = 0; i < jsonValue.length; i++) {
        if (value !== '') value += '; '
        value += jsonValue[i].sor_record_name
      }
    }

    let result = `<label>${jot.data?.field?.label || jot.name}</label>
    <input readonly='true' type='text' value=${value}>
    <br>
    `

    return [result, i + 1]
  }

  static #convertQuestionToHtml(body, i) {
    let qJot = body[i]
    let answerHtml = ''
    let result = `
      <details open style="border: 1px solid; border-radius: 4px; padding: .5em .5em 0;">
        <summary style="font-weight: var(--bold-font-weight); margin: -.5em -.5em 0; padding: .5em; border-bottom: 1px solid;">
          ${qJot.data}
        </summary>
        %%__ANSWER__%%
      </details>`
    ;[answerHtml, i] = HtmlConvertor.#convertAnswersToHtml(body, i + 1)
    result = result.replace('%%__ANSWER__%%', answerHtml)
    return [result, i]
  }

  static #convertAnswersToHtml(body, i) {
    let aJot = body?.[i]
    let result = ''
    while (aJot && HtmlConvertor.#isAnswerJot(aJot.type)) {
      let temp = ''
      switch (aJot.type) {
        case JottingType.A_UL1:
        case JottingType.A_UL2:
        case JottingType.A_UL3:
          ;[temp, i] = HtmlConvertor.#convertUnorderedListToHtml(body, i)
          break

        case JottingType.A_OL1:
        case JottingType.A_OL2:
        case JottingType.A_OL3:
          ;[temp, i] = HtmlConvertor.#convertOrderedListToHtml(body, i)
          break

        case JottingType.A_H1:
        case JottingType.A_H2:
        case JottingType.A_H3:
          ;[temp, i] = HtmlConvertor.#convertHeadingToHtml(body, i)
          break

        case JottingType.A_TASK_INCOMPLETE:
          ;[temp, i] = HtmlConvertor.#convertTaskIncompleteToHtml(body, i)
          break
        case JottingType.A_TASK_COMPLETE:
          ;[temp, i] = HtmlConvertor.#convertTaskCompleteToHtml(body, i)
          break

        case JottingType.A_CRM_FIELD_VALUE:
          ;[temp, i] = HtmlConvertor.#convertCrmFieldValueToHtml(body, i)
          break

        case JottingType.A_P:
        default:
          ;[temp, i] = HtmlConvertor.#convertParaToHtml(body, i)
          break
      }

      result += temp + '\n'
      aJot = body?.[i]
    }
    return [result, i]
  }

  static #isAnswerJot(jotType) {
    return jotType.startsWith('answer')
  }

  static #isUnorderedListJot(jotType) {
    return jotType.startsWith('unorderedList') || jotType.startsWith('answerUnorderedList')
  }

  static #isOrderedListJot(jotType) {
    return jotType.startsWith('orderedList') || jotType.startsWith('answerOrderedList')
  }
}
