import { connect, ConnectedProps } from 'react-redux'
import LuruUserSelectBoxComponent from './LuruUserSelectBoxComponent'
import getReduxState from './getReduxState'

export enum LuruUserSelectBoxType {
  LuruUsers = 'luru-users',
  CrmUsers = 'crm-users',
  LuruAndCrmUsers = 'luru-crm-users',
}

export interface LuruUserSelectBoxOwnProps {
  selectedUsers?: Array<{ email: string }>
  type: LuruUserSelectBoxType
  onChooseUser: (user: { userId: string; name: string; email: string }) => void
  isSelectableList: boolean
  excludedUsers?: Array<{ email: string }>
  placeholder: string
}

const connector = connect(getReduxState, null, null, { forwardRef: true })

export type LuruUserSelectBoxConnectedProps = ConnectedProps<typeof connector>

export default connector(LuruUserSelectBoxComponent)
