import { PayloadAction } from '@reduxjs/toolkit'
import { ReduxNotesState } from '../types'

export const markNoteEditable = (
  state: ReduxNotesState,
  action: PayloadAction<{ noteId: string }>
) => {
  var { noteId } = action.payload
  var currEntity = state.entities[noteId]

  if (!currEntity) {
    return
  }

  currEntity.markedEditable = true
}
