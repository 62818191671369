import { lazy } from 'react'
import { renderWithLazyLoading } from '../../../WebAppLoading'

const NotesEditorEmptyState = lazy(() => import('../../../../routes/notes/NotesEditorEmptyState'))

const NoteTemplatesEditorEmptyStateLazyLoading = renderWithLazyLoading(
  <NotesEditorEmptyState for='meeting_playbooks' />
)

export default NoteTemplatesEditorEmptyStateLazyLoading
