import { ReactNode } from 'react'
import styles from '@/primitives/css/ModalScreen.module.css'
import Utils from '@/utils/Utils'
import dismissIcon from '@/images/fluent/close.svg'
import LuruButton from '@/primitives/ui/LuruButton'

interface ModalProps {
  // ID for modal
  id?: string
  // Width of modal
  width?: string
  // Height of modal
  height?: string
  // If alignment is centered
  centered?: boolean
  // Title of modal
  title?: ReactNode | string
  // Whether to show close button in title
  titleCloseButton?: boolean
  // Action elements in title
  titleActionElements?: ReactNode
  // Custom attribute to add (for CSS targeting, JS handling, etc.)
  dialogRole?: string
  // Class name for dialog
  className?: string
  // Class name for parent component
  parentClassName?: string
  // Whether to hide the action buttons inside Modal (bottom right)
  hideButtons?: boolean
  // Hide cancel button
  hideCancelButton?: boolean
  // Whether to show modal by default
  doShow?: boolean
  // Content of Modal component
  children: ReactNode
  // Handler for OK
  onOk?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  // Handler for Cancel
  onCancel?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  // Type
  type?: 'normal' | 'slide-in'
  // Hide ok button
  hideOkButton?: boolean
}

export default function Modal(props: ModalProps) {
  var style: Record<string, any> = {}

  if (props.width) {
    style.width = props.width
  }

  if (props.height) {
    style.height = props.height
  }

  var dialogProps: Record<string, any> = {
    id: props.id,
    className: Utils.classes(
      styles.dialog,
      props.centered && styles.centered,
      props.className && props.className,
      props.type === 'slide-in' && styles.slideInDialog
    ),
    style,
  }

  if (props.dialogRole) {
    dialogProps['data-luru-role'] = props.dialogRole
  }

  return (
    <div
      className={Utils.classes(
        styles.parent,
        props.parentClassName ?? null,
        !props.doShow && styles.minimized,
        props.type === 'slide-in' && styles.slideInParent
      )}
    >
      <div {...dialogProps}>
        <div className={styles.title} data-role='modal-screen-title'>
          <div className={styles.titleText}>{props.title ?? ''}</div>
          <div className={styles.titleSpacer}>{props.titleActionElements ?? ''}</div>
          {props.titleCloseButton ? (
            <button onClick={props.onCancel} data-luru-role='close-dialog-button'>
              <img src={dismissIcon} alt='Close' className={styles.dismiss} />
            </button>
          ) : null}
        </div>
        <div className={styles.content} data-luru-role='modal-screen-content'>
          {props.doShow ? props.children : null}
        </div>
        {!props.hideButtons ? (
          <div className={styles.buttons}>
            {props.hideCancelButton ? null : (
              <LuruButton title='Cancel' variant='outline' role='luru-cancel-button' onClick={() => props.onCancel?.()}>
                Cancel
              </LuruButton>
            )}
            {props.hideOkButton ? null : (
              <LuruButton title='OK' role='luru-ok-button' onClick={props?.onOk}>
                OK
              </LuruButton>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}
