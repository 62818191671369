// React
import { useRef } from 'react'

// Own components
import LoadingSpinner from './LoadingSpinner'

// Styles
import styles from './css/LuruFloatingActionButton.module.css'

export default function LuruFloatingActionButton({
  title,
  loading,
  loadingSize,
  onClick,
  disabled,
  children,
}) {
  const root = useRef()
  if (!loadingSize) {
    loadingSize = 12
  }

  let classes = [styles.parent]

  if (disabled) {
    classes.push(styles.disabled)
  } else {
    classes.push('livelyButton')
  }

  const stopEvent = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <button
      ref={root}
      className={classes.join(' ')}
      title={title}
      onClick={Boolean(disabled) ? stopEvent : onClick}
    >
      {loading ? <LoadingSpinner size={loadingSize} color='white' /> : null}
      {children}
    </button>
  )
}
