import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { UsersAPI } from '../api'
import { ReduxLuruUserEntity, ReduxUsersState } from '../types'

export interface GetLuruUsersAPIResponse extends LuruAPIResponse {
  data: Array<ReduxLuruUserEntity>
}

export const getLuruUsers = {
  action: createAsyncThunk<
    GetLuruUsersAPIResponse['data'],
    {},
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: GetLuruUsersAPIResponse['metadata']
      rejectedMeta: GetLuruUsersAPIResponse['metadata']
    }
  >(
    'users/getLuruUsers',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await UsersAPI.getLuruUsers({
          signal,
        })) as GetLuruUsersAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        return rejectWithValue(
          { message: (e as Error)?.message ?? 'Error retrieving Luru users' },
          null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getLuruUsers.action.pending, (state, action) => {
      state.LURU.status = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getLuruUsers.action.fulfilled, (state, action) => {
      state.LURU.status = EntityStatus.Loaded
      action.payload.forEach(
        (user) =>
          (state.LURU.entities[user.user_id] = {
            status: EntityStatus.Loaded,
            data: { ...user },
          })
      )
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getLuruUsers.action.rejected, (state, action) => {
      state.LURU.status = EntityStatus.ErrorLoading
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    getLuruUsers.addPendingCase(builder)
    getLuruUsers.addFulfilledCase(builder)
    getLuruUsers.addRejectedCase(builder)
  },
}
