import { configureConnections } from './configureConnections'
import { inviteUsers } from './inviteUsers'
import { getSorUsers } from './getSorUsers'
import { getLuruUsers } from './getLuruUsers'
import { getLuruUserRoles } from './getLuruUserRoles'
import { updateLuruUserRoles } from './updateLuruUserRoles'

export const UsersMiddleware = {
  configureConnections,
  getSorUsers,
  getLuruUsers,
  getLuruUserRoles,
  updateLuruUserRoles,
  inviteUsers,
}
