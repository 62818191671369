import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { NotesMiddleware } from '../../notes/middleware'
import { ReduxAppState } from '../types'

export const notesSliceSearchLuruNotes = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    notesSliceSearchLuruNotes.addPendingCase(builder)
    notesSliceSearchLuruNotes.addFulfilledCase(builder)
    notesSliceSearchLuruNotes.addRejectedCase(builder)
  },

  addPendingCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.searchLuruNotes.action.pending,
      (state, action) => {
        if (action.meta.arg.key !== 'home/LuruNotes') {
          return
        }

        var searchOrList: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'

        state.home[searchOrList].luruNotes.status =
          action.meta.arg.loadMore === true
            ? EntityStatus.LoadingMore
            : EntityStatus.Loading
        state.home[searchOrList].luruNotes.error_data = null
      }
    )
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.searchLuruNotes.action.fulfilled,
      (state, action) => {
        if (action.meta.arg.key !== 'home/LuruNotes') {
          return
        }

        var searchOrList: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'
        var cursor = action.meta?.cursor ?? null
        var notes = action.payload
        var resultSet = new Set(notes.map((note) => note.note_id))
        var results = [...resultSet]

        // Set meta data
        state.home[searchOrList].luruNotes.status = EntityStatus.Loaded
        state.home[searchOrList].luruNotes.cursor = cursor
        state.home[searchOrList].luruNotes.error_data = null

        // Set data
        if (action.meta.arg.loadMore === true) {
          // If this was a next page request, just append to existing results
          // Remove duplicates first
          results = results.filter(
            (noteId) =>
              noteId &&
              !state.home[searchOrList].luruNotes?.results?.includes(noteId)
          )
          state.home[searchOrList].luruNotes?.results?.push(
            ...(results as Array<string>)
          )
        } else {
          state.home[searchOrList].luruNotes.results =
            results.slice() as Array<string>
        }
      }
    )
  },

  addRejectedCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.searchLuruNotes.action.rejected,
      (state, action) => {
        if (action.meta.arg.key !== 'home/LuruNotes') {
          return
        }

        var searchOrList: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'

        state.home[searchOrList].luruNotes = {
          status: EntityStatus.ErrorLoading,
          results: [],
          cursor: null,
          error_data: {
            message: action.error.message ?? 'Error searching Luru notes',
          },
        }
      }
    )
  },
}
