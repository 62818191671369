import Header from '../layout/Header'
import Toolbar from '../layout/Toolbar'
import styles from './WebAppLoading.module.css'
import appStyles from '../layout/css/App.module.css'
import { Suspense, memo } from 'react'

export default function WebAppLoading({ showHeaderToolbarSkeleton }: { showHeaderToolbarSkeleton?: boolean }) {
  return (
    <div className={appStyles.appContainer}>
      {showHeaderToolbarSkeleton && <Header />}
      <div className={appStyles.dashboardsPageNew}>
        {showHeaderToolbarSkeleton && <Toolbar />}
        <main>
          <div className={styles['loading-container']}>
            <div className={styles['lds-ripple']}>
              <div></div>
              <div></div>
            </div>{' '}
          </div>
        </main>
      </div>
    </div>
  )
}

export function renderWithLazyLoading(component: React.ReactNode, showHeaderToolbarSkeleton?: boolean) {
  return memo(() => (
    <Suspense fallback={<WebAppLoading showHeaderToolbarSkeleton={showHeaderToolbarSkeleton} />}>{component}</Suspense>
  ))
}
