import * as React from 'react'
import LuruUser from '../../../../../domain/users/LuruUser'
import AppComponentsContext from '../../../../../primitives/AppComponents/AppComponentsContext'
import { LuruReduxStore } from '../../../../../app/store'
import { UserMiddleware } from '../../../../../features/user/middleware'
import ModalScreen from '../../../../../primitives/ModalScreen'
import { trackEvent } from '../../../../../analytics/Ga'
import { CalendarProvider } from '../../../../../features/user/types'
import IntegrationCard from '../IntegrationCard'
import googleCalendarLogo from '../../../../../images/google_calendar_logo.png'
import CrmRecord from '../../../../../domain/crmRecord/CrmRecord'
import { useAppSelector } from '../../../../../app/hooks'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export interface GoogleCalendarProps {}

export interface GoogleCalendarState {
  disconnecting: boolean
}
export default function GoogleCalendar(props: GoogleCalendarProps) {
  const { showToast } = useLuruToast()
  const [, setState] = React.useState<GoogleCalendarState>({
    disconnecting: false,
  })
  const calendarConnectErrorCode = useAppSelector(
    (state) => state.user.data?.userSettings?.connectedCalendar?.errorCode || state.meetings?.error?.error_code
  )
  const appContext = React.useContext(AppComponentsContext)
  const refs = {
    disconnectModalRef: React.createRef<ModalScreen>(),
  }

  const onClickConnectGoogleCalendar = () => {
    trackEvent('connect_calendar_button_clicked')
    appContext.connectCalendarModal?.current?.showGCalModal()
  }

  // TODO (Prabin): Very similar to disconnect method in zoom; Move it to common
  const onClickDisconnectGoogle = () => {
    refs.disconnectModalRef.current?.showModal({
      ok: () => {
        setState({ disconnecting: true })
        showToast({
          id: ToastId.SETTING_INTEGRATION_TOAST_ID,
          message: 'Disconnecting',
          isLoading: true,
        })
        LuruReduxStore.dispatch(UserMiddleware.disconnectGCal.action({}))
          .unwrap()
          .then(() => {
            showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Disconnected Google Calendar',
              severity: 'success',
            })
            setState({ disconnecting: false })
          })
          .catch((err: any) => {
            showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Error disconnecting Google Calendar',
              severity: 'error',
            })
            setState({ disconnecting: false })
          })
      },
    })
  }

  const calendarName = LuruUser.getCurrentUserCalendarName()
  const connectedCrm = CrmRecord.getCrmName()
  const isSomeOtherCalendarConnected = calendarName && calendarName !== CalendarProvider.GCAL

  return (
    <>
      <IntegrationCard
        sor='Google'
        heading='Google Calendar'
        body={`Connect with your Google calendar for Luru to automatically link and log meeting notes to
          ${connectedCrm || 'CRM'}. You can also take prep notes for upcoming meetings.`}
        logoPath={googleCalendarLogo}
        isConnected={calendarName === CalendarProvider.GCAL}
        disableConnect={isSomeOtherCalendarConnected}
        handleConnect={onClickConnectGoogleCalendar}
        handleDisconnect={onClickDisconnectGoogle}
        isReconnectRequired={
          calendarConnectErrorCode === 10104 || calendarConnectErrorCode === 10100 || calendarConnectErrorCode === 10000
        }
        height='16em'
      />
      <ModalScreen title='Disconnect google calendar' ref={refs.disconnectModalRef}>
        Do you want to disconnect from Google Calendar?
      </ModalScreen>
    </>
  )
}
