import { LuruReduxStore } from '../../../app/store'
import { HTTPMethod, LuruEntryPoint } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import Utils from '../../../utils/Utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UpdateNotificationStatsRequestParams } from '../middleware/updateNotificationStats'

export const updateNotificationStats = async (
  params: UpdateNotificationStatsRequestParams,
  options: LuruAPIRequestOptions
) => {
  var csrfToken = Utils.getCookieValue('csrftoken') || ''
  var entryPoint = LuruUser.getCurrentEntryPoint()

  if (
    [
      LuruEntryPoint.NEWTAB,
      LuruEntryPoint.GLOBALSEARCH,
      LuruEntryPoint.EMBEDDED_GMEET_NOTE,
      LuruEntryPoint.OPTIONS,
    ].includes(entryPoint)
  ) {
    // Inside Extension, so get csrf token from store
    csrfToken = LuruReduxStore?.getState()?.user?.extensionSettings?.csrfTokenCookieValue || ''
  }
  var request = new LuruAPIRequest(`/api/notifications/${params.notificationId}/stats`, HTTPMethod.POST, {
    ...options,
    headers: {
      'X-CSRFToken': csrfToken,
    },
    credentials: 'include',
  })
  request.setBody(params.payload)

  return request.make()
}
