import { LuruReduxStore } from '../../../app/store'
import { TasksMiddleware } from '../middleware'
import { TaskStatus } from '../types'

export const fetchAllTasks = () => {
  var assignees = ['me', 'notme'] as Array<'me' | 'notme'>
  var statuses = [TaskStatus.OPEN, TaskStatus.COMPLETED]

  for (let assigned_to of assignees) {
    for (let status of statuses) {
      LuruReduxStore.dispatch(
        TasksMiddleware.listOrSearchTasks.action({
          key: 'tasksHome',
          limit: 10,
          cursor: null,
          loadMore: false,
          assigned_to,
          status,
        })
      )
    }
  }
}
