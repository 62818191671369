import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CRMProvider } from '../../user/types'

export const listCollections = async (
  sor: CRMProvider,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/collections?sor=${sor}`,
    HTTPMethod.GET,
    options
  )

  return request.make()
}
