import React from 'react'
import update from 'immutability-helper'
import LuruUserSelectBox, { LuruUserSelectBoxType } from './LuruUserSelectBox'

// Type defs
export interface LuruUserMultiSelectBoxProps {
  type: LuruUserSelectBoxType
  selectedUsers?: Array<{ email: string }>
  onSelectUser?: (user: { userId: string; name: string; email: string }) => void
  onDeselectUser?: (user: {
    userId: string
    name: string
    email: string
  }) => void
  excludedUsers?: Array<{ email: string }>
  placeholder: string
}

export interface LuruUserMultiSelectBoxState {
  selectedUsers: Array<{ email: string }>
}

interface LuruUserMultiSelectBoxEvents {
  onChooseUser: (user: { userId: string; name: string; email: string }) => void
}

// UI
export default class LuruUserMultiSelectBox extends React.Component<LuruUserMultiSelectBoxProps> {
  props: LuruUserMultiSelectBoxProps
  state: LuruUserMultiSelectBoxState
  #eventHandler: LuruUserMultiSelectBoxEventHandler

  constructor(props: LuruUserMultiSelectBoxProps) {
    super(props)
    this.props = props
    this.state = {
      selectedUsers: this.props.selectedUsers ?? [],
    }
    this.#eventHandler = new LuruUserMultiSelectBoxEventHandler(this)
  }

  render() {
    return (
      <LuruUserSelectBox
        type={this.props.type}
        selectedUsers={this.state.selectedUsers}
        onChooseUser={this.#eventHandler.handlers.onChooseUser}
        isSelectableList={true}
        excludedUsers={this.props.excludedUsers}
        placeholder={this.props.placeholder}
      />
    )
  }
}

class LuruUserMultiSelectBoxEventHandler {
  #component: LuruUserMultiSelectBox
  handlers: LuruUserMultiSelectBoxEvents

  constructor(component: LuruUserMultiSelectBox) {
    this.#component = component
    this.handlers = {
      onChooseUser: this.onChooseUser.bind(this),
    }
  }

  onChooseUser(user: { userId: string; name: string; email: string }) {
    let userIndexCurrently = this.#component.state.selectedUsers.findIndex(
      (u) => u.email === user.email
    )

    if (userIndexCurrently !== -1) {
      if (this.#component.props.onDeselectUser !== undefined) {
        this.#component.props.onDeselectUser(user)
      }
    } else {
      if (this.#component.props.onSelectUser !== undefined) {
        this.#component.props.onSelectUser(user)
      }
    }

    this.#component.setState((prevState: LuruUserMultiSelectBoxState) => {
      if (userIndexCurrently !== -1) {
        return update(prevState, {
          selectedUsers: {
            $splice: [[userIndexCurrently, 1]],
          },
        })
      } else {
        return update(prevState, {
          selectedUsers: {
            $push: [{ email: user.email }],
          },
        })
      }
    })
  }
}
