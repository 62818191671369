import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { ConfigureConnectionsParameter } from '../middleware/configureConnections'

export const configureConnections = async (params: ConfigureConnectionsParameter, options?: LuruAPIRequestOptions) => {
  var requestBody = {
    user_ids: params.userIds,
    message: params.message,
    connections: params.connections,
  }
  var request = new LuruAPIRequest(`/api/users/configure_connections`, HTTPMethod.POST, options)
  request.setBody(requestBody)
  return request.make()
}
