import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DeleteNoteConnectionParameter } from '../middleware/deleteNoteConnection'

export default async function deleteNoteConnection(
  { noteId, connectionId }: Readonly<DeleteNoteConnectionParameter>,
  options: LuruAPIRequestOptions
) {
  var request = new LuruAPIRequest(
    `/api/notes/${noteId}/connections/${connectionId}`,
    HTTPMethod.DELETE,
    options
  )

  return request.make()
}
