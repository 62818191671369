import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod, LuruAPIResponse } from '../../../app/types'
import { DateUtils } from '@/utils/dateUtils'

export interface SearchUserInteractionInfosForWorkflowParameter {
  workflowId: string
  time_min: Date
  time_max: Date
}

export interface UserInteractionInfoForWorkflow {
  action: string
  timestamp: number
  workflow_id: string
  records: Array<{
    sor_object_name: string
    sor_record_id: string
    sor_record_name: string
    owner: {
      sor_object_name: string
      sor_record_id: string
      sor_record_name: string
    }
  }>
  user: {
    name: string
    id: string
  }
}

export interface SearchUserInteractionInfosForWorkflowResponse extends LuruAPIResponse {
  data: Array<UserInteractionInfoForWorkflow>
}

export const searchUserInteractionInfosForWorkflow = async (
  params: SearchUserInteractionInfosForWorkflowParameter,
  options?: LuruAPIRequestOptions
) => {
  const { workflowId, ...otherParams } = params
  var urlParams: Record<string, any> = { ...otherParams }

  if (urlParams.time_min) {
    urlParams.time_min = DateUtils.toISOStringCurrentTZ(urlParams.time_min)
  }

  if (urlParams.time_max) {
    urlParams.time_max = DateUtils.toISOStringCurrentTZ(urlParams.time_max)
  }

  var queryString = new URLSearchParams(urlParams as URLSearchParams)
  var request = new LuruAPIRequest(`/api/workflows/${workflowId}/interactions?${queryString}`, HTTPMethod.GET, options)

  return request.make()
}
