import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UpdateNoteTemplateParameter } from '../middleware/updateNoteTemplate'

export const updateNoteTemplate = async (
  params: Readonly<UpdateNoteTemplateParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/note-templates/${params.noteTemplateId}`,
    HTTPMethod.PUT,
    options
  )
  var requestBody: Partial<UpdateNoteTemplateParameter> = {}

  if (params.title) {
    requestBody.title = params.title
  }

  if (params.body) {
    requestBody.body = params.body
  }

  if (params.filter) {
    requestBody.filter = params.filter
  }

  request.setBody(requestBody)

  return request.make()
}
