import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { EmailsAPI } from '../api'
import { ReduxEmailEntity, ReduxEmailsState } from '../types'


export interface ListEmailsAPIResponse extends LuruAPIResponse {
  data: Array<ReduxEmailEntity>
}

export const listEmails = {
  action: createAsyncThunk<
    ListEmailsAPIResponse['data'],
    {},
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: ListEmailsAPIResponse['metadata']
      rejectedMeta: ListEmailsAPIResponse['metadata']
    }
  >(
    'emails/listEmails',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: ListEmailsAPIResponse =

          await EmailsAPI.listEmails({ signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxEmailsState>) {
    builder.addCase(listEmails.action.pending, (state, action) => {
      state.status = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxEmailsState>) {
    builder.addCase(listEmails.action.fulfilled, (state, action) => {
      state.status = EntityStatus.Loaded
      state.refreshedAt = new Date().toISOString()

      action.payload.forEach(
        (c) =>
          (state.entities[c.email] = {
            status: EntityStatus.Loaded,
            data: { ...c },
          })
      )
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxEmailsState>) {
    builder.addCase(listEmails.action.rejected, (state, action) => {
      state.status = EntityStatus.ErrorLoading
    })
  },
  

  addAllCases(builder: ActionReducerMapBuilder<ReduxEmailsState>) {
    listEmails.addPendingCase(builder)
    listEmails.addFulfilledCase(builder)
    listEmails.addRejectedCase(builder)
  },
}
