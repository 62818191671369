import { createView } from './createView'
import { fetchView } from './fetchView'
import { updateView } from './updateView'
import { deleteView } from './deleteView'
import { useView } from './useView'
import { listViews } from './listViews'

export const ViewsMiddleware = {
  createView,
  fetchView,
  updateView,
  deleteView,
  useView,
  listViews,
}
