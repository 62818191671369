import { trackEvent } from '@/analytics/Ga'
import { LuruEntryPoint } from '@/app/types'
import LuruUser from '@/domain/users/LuruUser'
import { getAuthUrl } from '@/domutils/utils'
import { localUserSettings } from '@/features/user/localUserSettings'
import { EmailProvider, LocalUserSettingKey } from '@/features/user/types'
import { BrowserUtils } from '@/utils/browserUtils'
import { useCallback, useImperativeHandle } from 'react'
import { UseComponentState } from './useComponentState'
import { EmailConnectModalProps } from './EmailConnectModalComponent'
import { useLocation } from 'react-router-dom'

export default function useEventHandlers(sharedState: UseComponentState, props: EmailConnectModalProps, ref: any) {
  const { setState } = sharedState
  const location = useLocation()

  const onClose = useCallback(() => {
    trackEvent('email_connect_hide', '')
    setState((prevState) => ({ ...prevState, show: false }))
  }, [setState])

  const onSkip = useCallback(() => {
    trackEvent('email_connect_skip', '')
    setState((prevState) => ({ ...prevState, show: false }))
    localUserSettings.set(LocalUserSettingKey.CONNECT_EMAIL_DIALOG_HIDDEN, true)
  }, [setState])

  const onEmailConnectClick = useCallback(
    (emailProvider: EmailProvider) => {
      trackEvent('email_connect', emailProvider)

      if (location.pathname.startsWith('/settings/misc')) {
        localUserSettings.set(LocalUserSettingKey.CONNECTED_EMAIL_FROM_MISC_PAGE, true)
      } else {
        localUserSettings.set(LocalUserSettingKey.CONNECTED_EMAIL_FROM_MISC_PAGE, false)
      }

      const currentEntryPoint = LuruUser.getCurrentEntryPoint()

      switch (currentEntryPoint) {
        case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
        case LuruEntryPoint.GLOBALSEARCH:
          var path = `${window.location.protocol}//${window.location.host}/pages/newtab/index.html?force=true%26redirect=/noop_close`
          var emailConnectUrl =
            emailProvider === EmailProvider.GMAIL
              ? `${getAuthUrl()}/login/google-oauth2-gmail/?next=/next${path}`
              : `${getAuthUrl()}/login/azuread-v2-tenant-oauth2-mail/?next=/next${path}`
          break

        case LuruEntryPoint.NEWTAB:
        case LuruEntryPoint.OPTIONS:
          path = `${window.location.protocol}//${window.location.host}/pages/newtab/index.html?force=true%26redirect=${window.location.pathname}`
          emailConnectUrl =
            emailProvider === EmailProvider.GMAIL
              ? `${getAuthUrl()}/login/google-oauth2-gmail/?next=/next${path}`
              : `${getAuthUrl()}/login/azuread-v2-tenant-oauth2-mail/?next=/next${path}`
          break

        default:
          path = window.location.pathname ?? '/'
          emailConnectUrl =
            emailProvider === EmailProvider.GMAIL
              ? `${getAuthUrl()}/login/google-oauth2-gmail/?next=/next${path}`
              : `${getAuthUrl()}/login/azuread-v2-tenant-oauth2-mail/?next=/next${path}`
      }
      switch (currentEntryPoint) {
        case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
        case LuruEntryPoint.GLOBALSEARCH:
          BrowserUtils.openWindow(
            emailConnectUrl,
            `Connect to ${emailProvider === EmailProvider.GMAIL ? 'Gmail' : 'O365 Mail'}`
          )
          break

        default:
          window.location.href = emailConnectUrl
      }
    },
    [location.pathname]
  )

  useImperativeHandle(ref, () => {
    return {
      showModal: (isFromError: boolean = false, provider: EmailProvider) => {
        setState((prevState) => ({ ...prevState, show: true, isFromError: isFromError, emailProvider: provider }))
      },
    }
  })

  return {
    onClose,
    onSkip,
    onEmailConnectClick,
  }
}
