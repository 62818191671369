import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UpdateActivityLogParameter } from '../middleware/updateActivityLog'
import { CrmActivityLogType } from '../types'

export const updateActivityLog = (
  { payload, type, activityId }: UpdateActivityLogParameter,
  options?: LuruAPIRequestOptions
) => {
  var url =
    type === CrmActivityLogType.Call ? `/api/activities/calls/${activityId}` : `/api/activities/meetings/${activityId}`
  var request = new LuruAPIRequest(url, HTTPMethod.PUT, options)

  request.setBody(payload)
  return request.make()
}
