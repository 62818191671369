import useNotificationControllerComponentState from './useComponentState'
import useNotificationControllerEventHandlers from './useEventHandlers'
import BannerNotification from './components/BannerNotification'
import { NotificationCategory, NotificationType } from '../../features/user/types'
import ModalNotification from './components/ModalNotification'
import { useLuruToast } from '@/hooks/useLuruToast'

export interface NotificationControllerProps {
  className?: string
}

export default function NotificationController(props: NotificationControllerProps) {
  const componentState = useNotificationControllerComponentState()
  const { toBeShownNotifications } = componentState
  const { handleCloseNotification } = useNotificationControllerEventHandlers(componentState, props)
  const { showToast } = useLuruToast()

  if (toBeShownNotifications.getToBeShownNotifications.length <= 0) {
    return null
  }

  const notificationToDisplay = toBeShownNotifications.getToBeShownNotifications[0]
  const { category, data, type, dismissible, id, expiry_at, notify_at } = notificationToDisplay

  if (category === NotificationCategory.TOAST) {
    showToast({
      id,
      message: data,
      severity:
        type === NotificationType.ERROR
          ? 'error'
          : type === NotificationType.INFO
          ? 'info'
          : type === NotificationType.REMINDER
          ? 'warning'
          : 'info',
      withdrawDefaultHideBehaviour: true,
    })
    return null
  } else if (category === NotificationCategory.MODAL) {
    return (
      <ModalNotification
        key={id}
        notificationId={id}
        message={data}
        type={type}
        onClose={() => handleCloseNotification(notificationToDisplay)}
        closable={dismissible}
      />
    )
  } else if (category === NotificationCategory.BANNER) {
    return (
      <BannerNotification
        key={id}
        notificationId={id}
        message={data}
        type={type}
        onClose={() => handleCloseNotification(notificationToDisplay)}
        closable={dismissible}
        className={props.className}
        dismissTime={new Date(expiry_at).valueOf() - new Date(notify_at).valueOf()}
      />
    )
  } else {
    return null
  }
}
