import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { WorkflowsAPI } from '../api'
import {
  ReduxWorkflowsState,
  ReduxWorkflowEntity,
  PauseUnpauseWorkflowParams,
} from '../types'

export interface PauseUnpauseWorkflowAPIResponse {
  data: ReduxWorkflowEntity
  metadata: null
  error_data: null | { message: string }
}

export const pauseUnpauseWorkflow = {
  action: createAsyncThunk<
    PauseUnpauseWorkflowAPIResponse['data'],
    PauseUnpauseWorkflowParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: PauseUnpauseWorkflowAPIResponse['metadata']
      rejectedMeta: PauseUnpauseWorkflowAPIResponse['metadata']
    }
  >(
    'workflows/pauseUnpauseWorkflow',
    async (params, { fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await WorkflowsAPI.pauseUnpauseWorkflow(
          params
        )) as PauseUnpauseWorkflowAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        return rejectWithValue(
          { message: (e as Error)?.message ?? 'Error creating alert' },
          null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(pauseUnpauseWorkflow.action.pending, (state, action) => {
      if (state.entities[action.meta.arg.workflow_id]) {
        state.entities[action.meta.arg.workflow_id].status =
          EntityStatus.Updating
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(pauseUnpauseWorkflow.action.fulfilled, (state, action) => {
      const workflow = action.payload

      state.entities[workflow.workflow_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...workflow,
          sor_object_name: CrmRecord.getCrmRecordType(
            workflow.sor_object_name,
            workflow.sor
          ),
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(pauseUnpauseWorkflow.action.rejected, (state, action) => {
      if (state.entities[action.meta.arg.workflow_id]) {
        state.entities[action.meta.arg.workflow_id].status =
          EntityStatus.ErrorUpdating
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    pauseUnpauseWorkflow.addPendingCase(builder)
    pauseUnpauseWorkflow.addFulfilledCase(builder)
    pauseUnpauseWorkflow.addRejectedCase(builder)
  },
}
