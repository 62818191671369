import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { WorkflowsAPI } from '../api'
import { UpdateWorkflowParameter } from '../api/updateWorkflow'
import { ReduxWorkflowsState, ReduxWorkflowEntity } from '../types'

export interface UpdateWorkflowAPIResponse extends LuruAPIResponse {
  data: ReduxWorkflowEntity
}

export const updateWorkflow = {
  action: createAsyncThunk<
    UpdateWorkflowAPIResponse['data'],
    UpdateWorkflowParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: UpdateWorkflowAPIResponse['metadata']
      rejectedMeta: UpdateWorkflowAPIResponse['metadata']
    }
  >(
    'workflows/updateWorkflow',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await WorkflowsAPI.updateWorkflow(params, {
          signal,
        })) as UpdateWorkflowAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        return rejectWithValue(
          { message: (e as Error)?.message ?? 'Error creating alert' },
          null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(updateWorkflow.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(updateWorkflow.action.fulfilled, (state, action) => {
      const workflow = action.payload

      state.entities[workflow.workflow_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...workflow,
          sor_object_name: CrmRecord.getCrmRecordType(
            workflow.sor_object_name,
            workflow.sor
          ),
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(updateWorkflow.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    updateWorkflow.addPendingCase(builder)
    updateWorkflow.addFulfilledCase(builder)
    updateWorkflow.addRejectedCase(builder)
  },
}
