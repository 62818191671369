// 3rd party libraries
import update from 'immutability-helper'
import { LuruReduxStore } from '../../../../app/store'

// BL
import {
  NoteTemplateShareInfo,
  TemplateShareAccessRight,
  TemplateShareSource,
} from '../../../../features/noteTemplates/types'
import LuruUser from '../../../../domain/users/LuruUser'
import { NoteTemplatesMiddleware } from '../../../../features/noteTemplates/middleware'
import Utils from '../../../../utils/Utils'
import NoteTemplateShareDialogComponent, {
  NoteTemplateShareDialogComponentState,
} from './NoteTemplateShareDialogComponent'

import linkIcon from '../../../../images/fluent/link.svg'

interface NoteTemplateShareDialogEvents {
  onClose: () => void
  onSelectUser: (user: { userId: string; name: string; email: string }) => void
  onDeselectUser: (user: {
    userId: string
    name: string
    email: string
  }) => void
  onChangeUserAccess: (userId: string, key: string | null) => void
  onChangeGeneralAccess: (key: string | null) => void
  onChangeGeneralAccessRight: (key: string | null) => void
  onCopyLink: () => void
  onSaveChanges: () => void
}

export default class NoteTemplateShareDialogEventHandler {
  handlers: NoteTemplateShareDialogEvents
  #component: NoteTemplateShareDialogComponent

  constructor(component: NoteTemplateShareDialogComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentWillUnmount = this.#onComponentUnmount.bind(this)

    this.handlers = {
      onClose: this.#onClose.bind(this),
      onSelectUser: this.#onSelectUser.bind(this),
      onDeselectUser: this.#onDeselectUser.bind(this),
      onChangeUserAccess: this.#onChangeUserAccess.bind(this),
      onChangeGeneralAccess: this.#onChangeGeneralAccess.bind(this),
      onChangeGeneralAccessRight: this.#onChangeGeneralAccessRight.bind(this),
      onCopyLink: this.#onCopyLink.bind(this),
      onSaveChanges: this.#onSaveChanges.bind(this),
    }
  }

  #onComponentMount() {}

  #onComponentUnmount() {}

  // Remove the email-ids from state when the modal is closed
  #onClose() {
    this.#component.setState({
      noteTemplateId: '',
    })
  }

  // A user is selected
  async #onSelectUser(user: { userId: string; name: string; email: string }) {
    let templateOwner = this.#component.getCurrentTemplateOwner()

    // One cannot remove the owner
    if (user.userId === templateOwner?.user_id) {
      return
    }

    let currUser = await LuruUser.getCurrentlyLoggedInUser()

    let newUserShare: NoteTemplateShareInfo = {
      entity_id: user.userId,
      entity_type: TemplateShareSource.USER,
      created_by: currUser?.user_id ?? '',
      availability: TemplateShareAccessRight.VIEW,
    }

    this.#component.setState(
      (prevState: NoteTemplateShareDialogComponentState) => {
        return update(prevState, {
          templateShares: {
            $push: [newUserShare],
          },
        })
      }
    )
  }

  // A user is deselected
  async #onDeselectUser(user: { userId: string; name: string; email: string }) {
    let templateOwner = this.#component.getCurrentTemplateOwner()

    // One cannot remove the owner
    if (user.userId === templateOwner?.user_id) {
      return
    }

    let userIndex = this.#component.state.templateShares?.findIndex(
      (share) => share.entity_id === user.userId
    )

    if (userIndex !== undefined) {
      this.#component.setState(
        (prevState: NoteTemplateShareDialogComponentState) => {
          if (userIndex !== undefined) {
            return update(prevState, {
              templateShares: {
                $splice: [[userIndex, 1]],
              },
            })
          }
          return prevState
        }
      )
    }
  }

  // A user's access right is changed
  #onChangeUserAccess(userId: string, key: string | null) {
    if (key === null) {
      return
    }

    let userIndex = this.#component.state.templateShares?.findIndex(
      (share) => share.entity_id === userId
    )
    if (userIndex !== undefined) {
      this.#component.setState(
        (prevState: NoteTemplateShareDialogComponentState) => {
          if (
            userIndex !== undefined &&
            (key === TemplateShareAccessRight.EDIT ||
              key === TemplateShareAccessRight.VIEW)
          ) {
            return update(prevState, {
              templateShares: {
                [userIndex]: {
                  availability: {
                    $set: key,
                  },
                },
              },
            })
          }
          return prevState
        }
      )
    }
  }

  // Everyone's access is changed between general & restricted
  async #onChangeGeneralAccess(key: string | null) {
    if (key === null) {
      return
    }

    if (key === 'everyone') {
      // If general access changes from 'restricted' to 'everyone', then add
      // an entity_type TENANT into template shares
      let currentUser = await LuruUser.getCurrentlyLoggedInUser()
      let currentUserId = currentUser?.user_id

      let generalShare: NoteTemplateShareInfo = {
        entity_id: LuruUser.getTenantId(),
        entity_type: TemplateShareSource.TENANT,
        availability: TemplateShareAccessRight.VIEW,
        created_by: currentUserId ?? '',
      }

      this.#component.setState(
        (prevState: NoteTemplateShareDialogComponentState) => {
          return update(prevState, {
            templateShares: {
              $push: [generalShare],
            },
          })
        }
      )
    } else {
      // If general access changes from 'everyone' to 'restricted', then remove
      // the entity_type TENANT from template shares
      this.#component.setState(
        (prevState: NoteTemplateShareDialogComponentState) => {
          let generalIx = prevState.templateShares?.findIndex(
            (share) => share.entity_type === TemplateShareSource.TENANT
          )

          if (generalIx !== undefined) {
            var nextState = update(prevState, {
              templateShares: {
                $splice: [[generalIx, 1]],
              },
            })
          } else {
            nextState = prevState
          }

          return nextState
        }
      )
    }
  }

  // Change the access right for the general access section
  async #onChangeGeneralAccessRight(key: string | null) {
    if (key === null) {
      return
    }

    this.#component.setState(
      (prevState: NoteTemplateShareDialogComponentState) => {
        let generalIx = prevState.templateShares?.findIndex(
          (share) => share.entity_type === TemplateShareSource.TENANT
        )

        if (
          generalIx !== undefined &&
          (key === TemplateShareAccessRight.EDIT ||
            key === TemplateShareAccessRight.VIEW)
        ) {
          var nextState = update(prevState, {
            templateShares: {
              [generalIx]: {
                availability: {
                  $set: key,
                },
              },
            },
          })
        } else {
          nextState = prevState
        }

        return nextState
      }
    )
  }

  // Copy link button is clicked
  #onCopyLink() {
    let afterCopy = () => {
      let copyButton: HTMLButtonElement | null =
        this.#component.componentRefs.copyLinkButton.current

      if (copyButton) {
        let button = copyButton
        button.innerHTML = `
        <img src=${linkIcon} alt='link' />
        Copied
`
        setTimeout(() => {
          button.innerHTML = `
          <img src=${linkIcon} alt='link' />
          Copy link
`
        }, 2000)
      }
    }

    afterCopy = afterCopy.bind(this)

    Utils.copyTextToClipboard(
      LuruUser.isWebappContext()
        ? window.location.href
        : LuruUser.getTenantDomain() + window.location.pathname,
      afterCopy
    )
  }

  // When changes are saved
  async #onSaveChanges() {
    var saveButton: HTMLButtonElement | null = null
    try {
      saveButton = this.#component.componentRefs.saveChangesButton.current

      if (saveButton) {
        saveButton.innerHTML = 'Saving changes...'
      }

      let template = this.#component.getCurrentTemplate()

      if (template && Array.isArray(this.#component.state.templateShares)) {
        await LuruReduxStore.dispatch(
          NoteTemplatesMiddleware.shareNoteTemplate.action({
            noteTemplateId: template.template_id,
            shares: [...this.#component.state.templateShares],
          })
        )
        this.#component.componentRefs.modal.current?.cancel()
      }

      if (saveButton) {
        saveButton.innerHTML = 'Save changes'
      }
    } catch (e) {
      console.warn(e)

      if (saveButton) {
        saveButton.innerHTML = 'Save changes'
      }
    }
  }
}
