import { createSlice } from '@reduxjs/toolkit'
import { EmptyAppState } from './types'
import { AppSliceReducers as reducers } from './reducers'
import { AppSliceExtraReducers } from './extraReducers'

const appSlice = createSlice({
  name: 'app',
  initialState: EmptyAppState,
  reducers,
  extraReducers: (builder) => AppSliceExtraReducers.addAllCases(builder),
})

// Action creators
export const AppSliceActions = appSlice.actions

// Reducer
export default appSlice.reducer
