import { LuruReduxStore } from '@/app/store'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import LuruUser from '../../../domain/users/LuruUser'
import Utils from '../../../utils/Utils'
import { CrmObjectName, CrmObjectSchema, ReduxCrmKey } from '../../crm/types'
import { CRMProvider } from '../../user/types'
import {
  AlertDestination,
  CreateV2WorkflowParameter,
  OperatorUtils,
  ReduxV2WorkflowEntity,
  ReduxWorkflowEntity,
  V2WorkflowFilterOperator,
  WorkflowAction,
  WorkflowActionObj,
  WorkflowBlockType,
  WorkflowEditorBlock,
  WorkflowFilterBlockType,
  WorkflowFilterExpressionV2,
  WorkflowSorName,
  WorkflowSorSchemaTrigger,
  WorkflowTriggerObj,
  WorkflowTriggerV2,
  WorkflowV2EditorDraft,
} from '../types'
import { LuruFieldType } from '@/domain/crmRecord/typings.d'
import { OFFSET_PERIOD_TYPE } from '@/routes/workflowsv2/components/WorkflowFilter/WorkflowFilterMaker/components/WorkflowFilterConditionRow/components/FieldValue/OffsetPicker/types'
import { QUALIFIER } from '@/routes/workflowsv2/components/WorkflowFilter/WorkflowFilterMaker/components/WorkflowFilterConditionRow/components/FieldValue/QualifierChooser/types'
import { EntityStatus } from '@/app/types'

export function getNewWorkflowV2EditorDraft(): WorkflowV2EditorDraft {
  const triggerId = Utils.generateUUID() as string
  const crmProvider = LuruUser.getCurrentUserCrmName() as CRMProvider
  return {
    name: getDefaultName(),
    trigger_sor: crmProvider,
    blocks: [
      {
        id: triggerId,
        type: WorkflowBlockType.TRIGGER,
        details: {
          id: triggerId,
          name: '',
          description: '',
          sor: undefined,
          object_name: undefined,
          trigger_type: undefined,
          data: {
            args: {
              tz: undefined,
              cron_expr: undefined,
              filter: {
                version: 'v2',
                data: undefined,
              },
            },
          },
        },
      },
      getNewWorkflowV2ActionEditorBlockDraft(),
    ],
  }
}

export function getDefaultName() {
  let pattern = /^untitled workflow(?: - \d+)?$/
  let worklows = Object.values(LuruReduxStore.getState().workflows.entities) as Array<{
    data: ReduxV2WorkflowEntity | ReduxWorkflowEntity
    status: EntityStatus
  }>

  let numOfUntitledWf = worklows.filter((wf) =>
    // compare using expression
    pattern?.test(wf?.data?.name?.trim()?.toLocaleLowerCase())
  )?.length

  return `Untitled workflow ${numOfUntitledWf ? '- ' + numOfUntitledWf : ''}`
}

export function getNewWorkflowV2TriggerEditorBlockDraft(
  triggerSchema: {
    triggerSor: WorkflowSorName
    sorObjectName: CrmObjectName | 'global' | 'Global'
    trigger: WorkflowSorSchemaTrigger
  },
  triggerId?: string
): WorkflowEditorBlock {
  const newId = triggerId || (Utils.generateUUID() as string)
  const triggerType = triggerSchema.trigger.id
  var triggerEditorBlock: WorkflowEditorBlock
  switch (triggerType) {
    case WorkflowTriggerV2.RECORD_CU:
      triggerEditorBlock = {
        id: newId,
        type: WorkflowBlockType.TRIGGER,
        details: {
          id: newId,
          name: triggerSchema?.trigger?.name,
          description: triggerSchema?.trigger?.description,
          sor: triggerSchema?.triggerSor,
          object_name: triggerSchema?.sorObjectName as CrmObjectName,
          trigger_type: triggerType,
          data: {
            args: {},
          },
        },
      }
      break

    case WorkflowTriggerV2.CRON:
      triggerEditorBlock = {
        id: newId,
        type: WorkflowBlockType.TRIGGER,
        details: {
          id: newId,
          name: triggerSchema?.trigger?.name,
          description: triggerSchema?.trigger?.description,
          sor: triggerSchema?.triggerSor,
          object_name: triggerSchema?.sorObjectName as CrmObjectName,
          trigger_type: triggerType,
          data: {
            args: {
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
              cron_expr: undefined,
            },
          },
        },
      }
      break

    case WorkflowTriggerV2.MEETING_ENDED:
    case WorkflowTriggerV2.MEETING_STARTED:
      triggerEditorBlock = {
        id: newId,
        type: WorkflowBlockType.TRIGGER,
        details: {
          id: newId,
          name: triggerSchema?.trigger?.name,
          description: triggerSchema?.trigger?.description,
          sor: triggerSchema?.triggerSor,
          object_name: triggerSchema?.sorObjectName as CrmObjectName,
          trigger_type: triggerType,
          data: {
            args: {},
          },
        },
      }
      break

    case WorkflowTriggerV2.MEETING_ABOUT_TO_END:
    case WorkflowTriggerV2.MEETING_ABOUT_TO_START:
      triggerEditorBlock = {
        id: newId,
        type: WorkflowBlockType.TRIGGER,
        details: {
          id: newId,
          name: triggerSchema?.trigger?.name,
          description: triggerSchema?.trigger?.description,
          sor: triggerSchema?.triggerSor,
          object_name: triggerSchema?.sorObjectName as CrmObjectName,
          trigger_type: triggerType,
          data: {
            args: {
              in_minutes: 10,
            },
          },
        },
      }
      break

    default:
      triggerEditorBlock = {
        id: newId,
        type: WorkflowBlockType.TRIGGER,
        details: {
          id: newId,
          name: triggerSchema?.trigger?.name,
          description: triggerSchema?.trigger?.description,
          sor: triggerSchema?.triggerSor,
          object_name: triggerSchema?.sorObjectName as CrmObjectName,
          trigger_type: triggerType as WorkflowTriggerV2,
          data: {
            args: {
              tz: undefined,
              cron_expr: undefined,
              filter: {
                version: 'v2',
                data: undefined,
              },
            },
          },
        },
      }
      break
  }
  return triggerEditorBlock
}

export function getNewWorkflowV2ActionEditorBlockDraft(): WorkflowEditorBlock {
  const actionId = Utils.generateUUID() as string
  return {
    id: actionId,
    type: WorkflowBlockType.ACTION,
    details: {
      id: actionId,
      name: '',
      description: '',
    },
  }
}

export function getNewWorkflowV2SendMessageActionEditorBlockDraft(
  triggerType?: WorkflowTriggerV2
): WorkflowEditorBlock {
  const actionId = Utils.generateUUID() as string
  return {
    id: actionId,
    type: WorkflowBlockType.ACTION,
    details: {
      id: actionId,
      name: '',
      description: '',
      sor: undefined,
      object_name: undefined,
      action_type: undefined,
      data: {
        version: 'v2',
        args: {
          type: AlertDestination.CONVERSATION,
          conversations: [],
          users: null,
          msg_type: triggerType === WorkflowTriggerV2.CRON ? 'REPORT' : 'RECORD',

          // ...(triggerType === WorkflowTriggerV2.CRON
          //   ? {
          report_data: {
            report_desc: '',
            grouped: false,
            grouped_field: null,
            data_source: undefined,
            fields: [],
          },
          //   }
          // : {
          record_data: {
            msg_template: '',
            fields: {},
            action_buttons: [],
            media_category: null,
          },
          // }),
        },
      },
    },
  }
}

export function getNewWorkflowV2SendMessageStandardActionEditorBlockDraft(): WorkflowEditorBlock {
  const actionId = Utils.generateUUID() as string
  return {
    id: actionId,
    type: WorkflowBlockType.ACTION,
    details: {
      id: actionId,
      name: '',
      description: '',
      sor: undefined,
      object_name: undefined,
      action_type: undefined,
      data: {
        version: 'v3',
        args: {
          targets: [],
          body: {
            msg: {
              template: '',
              placeholders: {},
            },
            fields: [],
            action_buttons: [
              {
                type: undefined,
                data: {
                  fields: [],
                  mandatory_fields: [],
                },
              },
            ],
            media_category: null,
          },
          metadata: {},
        },
      },
    },
  }
}

export function getNewWorkflowV2SearchRecordActionEditorBlockDraft(): WorkflowEditorBlock {
  const actionId = Utils.generateUUID() as string
  return {
    id: actionId,
    type: WorkflowBlockType.ACTION,
    details: {
      id: actionId,
      name: '',
      description: '',
      sor: undefined,
      object_name: undefined,
      action_type: '',
      data: {
        args: {
          filter: {
            version: 'v2',
            data: undefined,
          },
        },
      },
    },
  }
}

export function getNewWorkflowV2FilterRecordActionEditorBlockDraft(): WorkflowEditorBlock {
  const actionId = Utils.generateUUID() as string

  const filterActionObj: WorkflowActionObj = {
    id: actionId,
    name: '',
    description: '',
    sor: undefined,
    object_name: undefined,
    action_type: undefined,
    data: {
      args: {
        source: {
          type: undefined,
          value: {
            object_name: undefined,
            applet_id: undefined,
          },
        },
        filter: {
          version: 'v2',
          data: undefined,
        },
      },
    },
  }

  return {
    id: actionId,
    type: WorkflowBlockType.ACTION,
    details: filterActionObj,
  }
}

export function getNewWorkflowV2DelayActionEditorBlockDraft(): WorkflowEditorBlock {
  const actionId = Utils.generateUUID() as string

  const delayActionObj: WorkflowActionObj = {
    id: actionId,
    name: '',
    description: '',
    sor: undefined,
    object_name: undefined,
    action_type: undefined,
    data: {
      args: {
        delay_in_minutes: undefined,
      },
    },
  }
  return {
    id: actionId,
    type: WorkflowBlockType.ACTION,
    details: delayActionObj,
  }
}

export function getWorkflowV2EditorDraftFromOriginalWorkflow(workflowV2Entity: ReduxV2WorkflowEntity) {
  if (!workflowV2Entity) {
    return getNewWorkflowV2EditorDraft()
  }
  if (!workflowV2Entity.triggers) {
    workflowV2Entity.triggers = {}
  }
  if (!workflowV2Entity.actions) {
    workflowV2Entity.actions = {}
  }
  // Currently only single trigger and send-message action will be present , so prepare edit copy based on this
  const crmProvider = LuruUser.getCurrentUserCrmName() as CRMProvider
  const workflowV2EditorDraft: WorkflowV2EditorDraft = {
    name: workflowV2Entity.name,
    trigger_sor: workflowV2Entity.trigger_sor || crmProvider,
    blocks: [],
  }

  // get trigger and append to start of the blocks array
  const trigger = workflowV2Entity.triggers[Object.keys(workflowV2Entity.triggers || {})[0]]

  // Fix filter expression
  const filterData = trigger.data?.args?.filter?.data
  if (filterData && trigger?.data?.args?.filter) {
    trigger.data.args.filter.data = fixWFFilterExpressionTree([filterData])[0]
  }
  const triggerBlock: WorkflowEditorBlock = {
    id: trigger.id,
    type: WorkflowBlockType.TRIGGER,
    details: trigger,
  }
  workflowV2EditorDraft.blocks.unshift(triggerBlock)

  // get actions and append to blocks array based on order of executation
  let currentKey = 'start' // Start with the "start" key

  while (currentKey && workflowV2Entity.actions[currentKey]) {
    const action = workflowV2Entity.actions[currentKey]
    if (currentKey === 'start') {
      const nextKey = workflowV2Entity.actions[currentKey]?.data?.next as string
      currentKey = nextKey?.split?.('/')?.[1]
      continue
    }
    const filterData = action.data?.args?.filter?.data
    if (filterData && action?.data?.args?.filter) {
      action.data.args.filter.data = fixWFFilterExpressionTree([filterData])[0]
    }
    const actionBlock: WorkflowEditorBlock = {
      id: action.id,
      type: WorkflowBlockType.ACTION,
      details: action,
    }
    workflowV2EditorDraft.blocks.push(actionBlock)
    const nextKey = workflowV2Entity.actions[currentKey]?.data?.next as {
      on_success: string
      on_failure?: string | undefined
    }
    currentKey = nextKey?.on_success?.split?.('/')?.[1]
  }

  return workflowV2EditorDraft
}

export function getWorkflowV2EntityFromEditorDraft(
  workflowV2EditorDraft: WorkflowV2EditorDraft,
  // objectName: string,
  // objectSchema?: CrmObjectSchema,
  oldWorkflowV2Entity?: ReduxV2WorkflowEntity
) {
  const crmProvider = LuruUser.getCurrentUserCrmName() as CRMProvider
  const reduxCrmKey = crmProvider?.toLowerCase?.() as ReduxCrmKey
  const workflowV2Entity: CreateV2WorkflowParameter = {
    name: workflowV2EditorDraft.name,
    description: oldWorkflowV2Entity?.description,
    version: oldWorkflowV2Entity?.version || 'v2',
    trigger_sor: workflowV2EditorDraft?.trigger_sor || crmProvider,
    triggers: {},
    actions: {
      start: {
        id: 'start',
        name: 'Start',
        sor: crmProvider === CRMProvider.HUBSPOT ? 'global' : 'Global',
        object_name: crmProvider === CRMProvider.HUBSPOT ? 'global' : 'Global',
        action_type: WorkflowAction.START,
        data: {
          next: '',
        },
      },
    },
  }

  // Compute & fix any data related to trigger & actions
  // workflowV2EditorDraft will only have blocks containing first trigger and remaing as actions
  const blocks = [...workflowV2EditorDraft.blocks]
  const triggerBlock = blocks.splice(0, 1)[0] // this will remove the first block and store in triggerBlock variable as trigger
  const actionBlocks = [...blocks] // this will hold remaing blocks as actions

  //Prepare for trigger payload
  const trigger = triggerBlock.details as WorkflowTriggerObj
  // Prepare filter expression
  const filterData = trigger.data?.args?.filter?.data
  if (filterData && trigger?.data?.args?.filter) {
    const objectName = trigger?.object_name as CrmObjectName
    const objectSchema = LuruReduxStore.getState().crm?.[reduxCrmKey]?.schema?.[objectName]?.data as
      | CrmObjectSchema
      | undefined
    trigger.data.args.filter.data = prepareWFFilterExpressionTree([filterData], objectSchema, objectName)[0]
  }
  // There will be single trigger for now
  workflowV2Entity.triggers = {
    [trigger.id]: trigger,
  }

  // Prepare for actions payload
  if (actionBlocks.length > 0) {
    // configure the start action by adding next step in data section
    if (workflowV2Entity.actions['start'].data) {
      workflowV2Entity.actions['start'].data.next = `start/${actionBlocks[0].id}`
    }
  }
  actionBlocks.forEach((actionBlock, index) => {
    const action = actionBlock.details as WorkflowActionObj
    // Remove any invalid value from action_buttons array
    const actionButtons = action.data?.args?.record_data?.action_buttons?.filter((f) => f.type) || []
    if (action?.data?.args?.record_data?.action_buttons) {
      action.data.args.record_data.action_buttons = actionButtons.length > 0 ? actionButtons : null
    }

    // Prepare filter expression
    const actionFilterData = action.data?.args?.filter?.data
    if (actionFilterData && action?.data?.args?.filter) {
      const objectName = (
        action?.action_type === WorkflowAction.FILTER
          ? action?.data?.args?.source?.value?.object_name
          : action?.object_name
      ) as CrmObjectName
      const objectSchema = LuruReduxStore.getState().crm?.[reduxCrmKey]?.schema?.[objectName]?.data as
        | CrmObjectSchema
        | undefined
      action.data.args.filter.data = prepareWFFilterExpressionTree([actionFilterData], objectSchema, objectName)[0]
    }

    //Configure next steps
    if (actionBlocks[index + 1]) {
      if (action.data) {
        action.data.next = {
          on_success: `${action.id}/${actionBlocks[index + 1].id}`,
          on_failure: '',
        }
      }
    } else {
      if (action.data) {
        action.data.next = {
          on_success: '',
          on_failure: '',
        }
      }
    }
    workflowV2Entity.actions[action.id] = action
  })

  return workflowV2Entity
}

/**
 * This function is responsible of fixing any WF Filter expression, by running same function as recursive ways to compute all tree filter data, before used by workflow v2 editor
 * @param data
 * @returns
 */
export function fixWFFilterExpressionTree(data: WorkflowFilterExpressionV2[]) {
  // var parsingRequiredOperators = {
  //   INTEGER: [
  //     WorkflowFilterOperator.PREV_N_DAYS,
  //     WorkflowFilterOperator.NOT_PREV_N_DAYS,
  //     WorkflowFilterOperator.NEXT_N_DAYS,
  //     WorkflowFilterOperator.NOT_NEXT_N_DAYS,
  //     WorkflowFilterOperator.BEFORE_N_DAYS,
  //     WorkflowFilterOperator.AFTER_N_DAYS,
  //     WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS,
  //     WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS,
  //     WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS,
  //     WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS,
  //     WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS,
  //     WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS,
  //   ],
  // }

  const filterTreeData = data.map((exp) => {
    if (!exp.originalOp) {
      exp.originalOp = OperatorUtils.getOperatorFromDataValues(exp.op || '', exp.value)
    }

    if (exp.block_type === WorkflowFilterBlockType.CONDITION) {
      exp.value = exp?.originalValue ?? exp?.value?.value ?? exp.value //exp.value can be of type {type,value} so we are converting it into just a exp.value=value
    }

    const updatedExp: WorkflowFilterExpressionV2 = { ...exp }

    if (exp.operands && exp.operands.length > 0) {
      // Recursively convert child nodes
      updatedExp.operands = fixWFFilterExpressionTree(exp.operands)
    }

    return updatedExp
  })

  return filterTreeData
}

/**
 * This function is responsible of preparing any WF Filter expression, by running same function as recursive ways to compute all tree filter data, before sending to server
 * @param data
 * @returns
 */
export function prepareWFFilterExpressionTree(
  data: WorkflowFilterExpressionV2[],
  objectSchema?: CrmObjectSchema,
  objectName?: string
) {
  const filterTreeData = [...data].map((exp) => {
    //storing originalValue as it is
    exp.originalValue = exp.value
    var typedValue = getTypedValue(exp?.value?.value || exp.value, exp.fieldType)
    if (exp.value === '') {
      typedValue = null
    }
    if (exp.originalOp) {
      var operator = exp.originalOp as V2WorkflowFilterOperator
      var argCount = OperatorUtils.v2operatorArgumentCountMap[operator]
      if (argCount === 1 && OperatorUtils.unaryV2OperatorArgument[operator] !== undefined) {
        typedValue = {
          type: 'macro',
          value: OperatorUtils.unaryV2OperatorArgument[operator],
        }
      } else if (argCount > 1) {
        typedValue = {
          type: exp.value?.type || 'abs',
          value: typedValue,
        }
      }
    }
    if (!exp.object_name) {
      exp.object_name = objectName
    }

    if (exp.fieldType === LuruFieldType.REFERENCE || exp.fieldType === LuruFieldType.MULTIREFERENCE) {
      exp.value = {
        type: 'abs',
        value: exp.value,
      }
    } else if ([LuruFieldType.DATE, LuruFieldType.DATETIME].includes(exp.fieldType as LuruFieldType)) {
      if (
        [V2WorkflowFilterOperator.WITHIN, V2WorkflowFilterOperator.NOT_WITHIN].includes(
          exp.originalOp as V2WorkflowFilterOperator
        )
      ) {
        //Range operator
        if (OperatorUtils.unaryV2OperatorArgument[exp?.value?.operator] !== undefined) {
          exp.value = {
            type: 'daterange',
            value: OperatorUtils.unaryV2OperatorArgument[exp?.value?.operator],
          }
        } else if (exp?.value?.operator === V2WorkflowFilterOperator.NEXT_N_DAYS) {
          exp.value = {
            type: 'daterange',
            value: `%%NEXT_N_DAYS:${exp?.value?.value || exp.value}%%`,
          }
        } else if (exp?.value?.operator === V2WorkflowFilterOperator.LAST_N_DAYS) {
          exp.value = {
            type: 'daterange',
            value: `%%LAST_N_DAYS:${exp?.value?.value || exp.value}%%`,
          }
        }
      } else {
        //Interval with offset operator
        const { offsetValue, ...expValue } = exp?.value || {}
        var timeDelta = {
          type: 'timedelta',
          value: {
            days: Number(offsetValue?.value?.period === OFFSET_PERIOD_TYPE.DAYS ? offsetValue?.value?.offset || 0 : 0),
            hours: Number(
              offsetValue?.value?.period === OFFSET_PERIOD_TYPE.HOURS ? offsetValue?.value?.offset || 0 : 0
            ),
            minutes: Number(
              offsetValue?.value?.period === OFFSET_PERIOD_TYPE.MINUTES ? offsetValue?.value?.offset || 0 : 0
            ),
          },
        }
        if (exp?.value?.type === 'ref') {
          exp.value = {
            type: 'formula',
            value: {
              operator: offsetValue?.value?.operator || '+',
              operands: [
                {
                  type: 'ref',
                  value: expValue?.value,
                },
                timeDelta,
              ],
            },
          }
        } else if (exp?.value?.type === 'macro') {
          exp.value = {
            type: 'formula',
            value: {
              operator: offsetValue?.value?.operator || '+',
              operands: [
                {
                  type: 'macro',
                  value: OperatorUtils.unaryV2OperatorArgument[expValue?.operator],
                },
                timeDelta,
              ],
            },
          }
        } else if (exp?.value?.type === 'abs') {
          //Interval with offset operator
          exp.value = {
            type: 'formula',
            value: {
              operator: offsetValue?.value?.operator || '+',
              operands: [
                {
                  type: 'abs',
                  value: expValue?.value,
                },
                timeDelta,
              ],
            },
          }
        }
      }
    } else if ([LuruFieldType.INTEGER, LuruFieldType.INTEGER_NOFORMAT].includes(exp.fieldType as LuruFieldType)) {
      var { qualifierValue, ...expValue } = exp?.value || {}
      const qualifier: QUALIFIER = qualifierValue?.value?.qualifier ?? QUALIFIER.ABSOLUTE
      var targetValue = {}
      if (expValue?.type === 'ref') {
        targetValue = {
          type: 'ref',
          value: expValue?.value,
        }
      } else if (expValue?.type === 'abs') {
        targetValue = {
          type: 'abs',
          value: Number(expValue?.value),
        }
      }
      if (qualifier === QUALIFIER.PERCENTAGE) {
        // If percentage we need to make a formula
        exp.value = {
          type: 'formula',
          value: {
            operator: '%',
            operands: [
              {
                type: 'ref',
                value: {
                  field: exp?.field,
                  object_name: exp?.object_name,
                },
              },
              targetValue,
            ],
          },
        }
      } else {
        // Else regular value
        exp.value = targetValue
      }
    } else {
      exp.value = typedValue
    }

    switch (exp.originalOp) {
      case V2WorkflowFilterOperator.NEXT_N_DAYS:
        exp.value = {
          type: 'macro',
          value: `%%NEXT_N_DAYS:${exp?.value?.value || exp.value}%%`,
        }
        break

      case V2WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS:
        exp.value = {
          type: 'macro',
          value: `%%EMAIL_SENT_LAST_N_DAYS:${exp?.value?.value || exp.value}%%`,
        }
        break

      case V2WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS:
        exp.value = {
          type: 'macro',
          value: `%%EMAIL_RECEIVED_LAST_N_DAYS:${exp?.value?.value || exp.value}%%`,
        }
        break

      case V2WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS:
        exp.value = {
          type: 'macro',
          value: `%%EMAIL_ACTIVITY_LAST_N_DAYS:${exp?.value?.value || exp.value}%%`,
        }
        break

      case V2WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS:
        exp.value = {
          type: 'macro',
          value: `%%NO_EMAIL_SENT_LAST_N_DAYS:${exp?.value?.value || exp.value}%%`,
        }
        break

      case V2WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS:
        exp.value = {
          type: 'macro',
          value: `%%NO_EMAIL_RECEIVED_LAST_N_DAYS:${exp?.value?.value || exp.value}%%`,
        }
        break

      case V2WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS:
        exp.value = {
          type: 'macro',
          value: `%%NO_EMAIL_ACTIVITY_LAST_N_DAYS:${exp?.value?.value || exp.value}%%`,
        }
        break

      case V2WorkflowFilterOperator.EMPTY:
        exp.value = {
          type: 'abs',
          value: '',
        }
        break

      case V2WorkflowFilterOperator.NOT_EMPTY:
        exp.value = {
          type: 'abs',
          value: '',
        }
        break

      case V2WorkflowFilterOperator.CHANGED:
        exp.value = {
          type: 'abs',
          value: '',
        }
        break
      case V2WorkflowFilterOperator.TRUE:
        exp.value = {
          type: 'abs',
          value: '',
        }
        break
      case V2WorkflowFilterOperator.FALSE:
        exp.value = {
          type: 'abs',
          value: '',
        }
        break
    }

    const updatedExp: WorkflowFilterExpressionV2 = { ...exp }

    if (exp.operands && exp.operands.length > 0) {
      // Recursively convert child nodes
      updatedExp.operands = prepareWFFilterExpressionTree(exp.operands, objectSchema, objectName)
    }

    return updatedExp
  })

  return filterTreeData
}

function getTypedValue(value: string, luruFieldType?: LuruFieldType) {
  return luruFieldType !== undefined ? CrmRecord.convertToPrimitiveType(luruFieldType, value) : value
}

export function validateFilterExpression(
  data: WorkflowFilterExpressionV2[],
  initialWarnings?: Record<string, string>
): Record<string, string> {
  var warnings: Record<string, string> = initialWarnings || {}
  for (let index = 0; index < data.length; index++) {
    const exp = data[index]
    var fieldName = exp.field || ''
    const operator = exp.op as V2WorkflowFilterOperator
    const numberArgs = OperatorUtils.getV2NumArguments(operator) ?? ''
    const fieldType = exp.fieldType
    if (exp.block_type === WorkflowFilterBlockType.CONDITION && !fieldName) {
      warnings[exp.id || ''] = 'Filter condition incomplete.  Please configure the filter condition(s) correctly'
    } else if (numberArgs && numberArgs > 1 && fieldName) {
      if (exp?.value instanceof Array && exp?.value?.length === 0) {
        warnings[exp.id || ''] = 'Filter condition incomplete.  Please configure the filter condition(s) correctly'
      } else if (fieldType === LuruFieldType.DATE || fieldType === LuruFieldType.DATETIME) {
        if (!exp?.value?.type) {
          warnings[exp.id || ''] = 'Filter condition incomplete.  Please configure the filter condition(s) correctly'
        }
      } else if (!exp?.value) {
        warnings[exp.id || ''] = 'Filter condition incomplete.  Please configure the filter condition(s) correctly'
      }
    }

    if (exp.operands && exp.operands.length > 0) {
      warnings = validateFilterExpression(exp.operands, warnings)
    }
  }
  return warnings
}
