import { useSchema } from '@/features/crm/hooks/useSchema'
import styles from './styles.module.css'
import { EntityStatus } from '@/app/types'
import Utils from '@/utils/Utils'

interface LuruCrmFieldInputLabelProps {
  objectName: string
  fieldName: string
  isInWarningState?: boolean
  showNonEditableLabel?: boolean
}

export default function LuruCrmFieldInputLabel(props: LuruCrmFieldInputLabelProps) {
  const { objectName, fieldName, isInWarningState, showNonEditableLabel } = props
  const { schemaMap, status } = useSchema(objectName)

  if (status === EntityStatus.Loading) {
    return <label>Loading...</label>
  }
  const fieldSchema = schemaMap?.fields?.[fieldName]

  return (
    <label className={Utils.classes(isInWarningState && styles.warning)}>
      {fieldSchema?.label}
      {fieldSchema?.isMandatory && <>*</>}
      {showNonEditableLabel && !fieldSchema?.updateable && ' (Non editable)'}
    </label>
  )
}
