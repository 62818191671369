import { lazy } from 'react'
import { renderWithLazyLoading } from '../../../WebAppLoading'
import { WorkflowEditorMode } from '@/routes/workflowsv2/components/WorkflowV2Editor/types'

const WorkflowV2EditorWrapper = lazy(() => import('@/routes/workflowsv2/components/WorkflowV2Editor'))

export const WorkflowV2EditorCreateMode = renderWithLazyLoading(
  <WorkflowV2EditorWrapper editorMode={WorkflowEditorMode.CREATE} />
)

export const WorkflowV2EditorEditMode = renderWithLazyLoading(
  <WorkflowV2EditorWrapper editorMode={WorkflowEditorMode.EDIT} />
)
