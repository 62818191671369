import { listMeetings } from './listMeetings'
import { searchMeetings } from './searchMeetings'
import { fetchMeeting } from './fetchMeeting'
import { findMatchingCrmRecords } from './findMatchingCrmRecords'

//Zoom meeting apis
import { listZoomMeetings } from './listZoomMeetings'

export const MeetingsAPI = {
  listMeetings,
  searchMeetings,
  fetchMeeting,
  listZoomMeetings,
  findMatchingCRMRecords: findMatchingCrmRecords,
}
