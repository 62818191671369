import { useAppSelector } from '../../../app/hooks'
import { CrmObjectName, ReduxCrmKey } from '../types'

export const useFieldValues = (
  // undefined can be passed to get all fields
  fields: string[] | undefined,
  crmObjectName: CrmObjectName | undefined,
  sorRecordId: string | undefined
): Record<string, any> => {
  const crmKey = useAppSelector((state) =>
    state?.user?.data?.userSettings?.connectedCRM?.name?.toLocaleLowerCase()
  ) as ReduxCrmKey

  const recordEntity = useAppSelector((state) =>
    crmKey && crmObjectName && sorRecordId
      ? state?.crm?.[crmKey]?.entities?.[crmObjectName]?.[sorRecordId]?.data
      : undefined
  )

  if (!recordEntity) {
    // console.trace({ recordEntity, fields, crmObjectName, sorRecordId })
    return {}
  }

  return Object.keys(recordEntity)
    .filter((f) => !fields || fields.includes(f))
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: recordEntity[curr].originalValue ?? recordEntity[curr].value,
      }),
      {}
    )
}
