import { Buffer } from 'buffer'
import { JottingType } from '../../../features/notes/types'
import { MeetingsMiddleware } from '../../../features/meetings/middleware'
import { getNewNoteContent } from '../../../features/notes/helpers/getNewNoteContent'
import { NotesMiddleware } from '../../../features/notes/middleware'
import Utils from '../../../utils/Utils'
import { MeetingsSliceHelpers } from '../../../features/meetings/helpers'
import { DateUtils } from '../../../utils/dateUtils'

const key = 'home/embeddedMeetingNote'

export default async function fetchNoteDetails({ dispatch, meetingUrl: encodedUrl, calendarId }) {
  var params = { dispatch, encodedUrl, calendarId }
  var meetingSearchResponse = calendarId && encodedUrl ? await searchMeetings(params) : { skip: true }
  // If search API is successful...
  if (meetingSearchResponse.success || meetingSearchResponse.skip) {
    // ...find the meeting record for this meeting URL
    let meetingRecordResponse = calendarId && encodedUrl ? filterSearchResults(params) : { skip: true }

    // If filtering results in an error (mostly due to malformed search
    // response), return error
    if (!meetingRecordResponse.success && !meetingRecordResponse.skip) {
      return meetingRecordResponse
    }

    // If we found the meeting, ...
    var meetingRecord = meetingRecordResponse.success ? meetingRecordResponse.payload : null
    var meetingId = meetingRecord?.meeting_id

    // ... check for the note Id linked to the meeting
    let linkedNotes = meetingRecord?.notes?.length > 0 ? meetingRecord.notes ?? null : null
    if (linkedNotes) {
      return { linkedNotes, meetingId }
    }

    // If we did not find a note linked to meeting, create note for the meeting
    var createNoteResponse = await createNoteForMeeting({
      dispatch,
      meetingRecord, // will be null when meeting not found or no calendar
      calendarId, // will be null when no calendar
    })
  } else {
    // If meeting search failed due to an auth_token/refresh_token issue,
    // surface this up to be handled/resolved using 'Sign in with Google'
    if (meetingSearchResponse.errorCode === 10104 || meetingSearchResponse.errorCode === 10100) {
      return meetingSearchResponse
    }

    // If meeting search failed due to any other reason, still create a note,
    // without a meeting record linked.
    createNoteResponse = await createNoteForMeeting({
      dispatch,
      meetingRecord: null,
      calendarId,
    })
  }

  if (!createNoteResponse.success) {
    return createNoteResponse
  }

  return {
    ...createNoteResponse.payload,
    meetingsApiErrorNotice: !meetingSearchResponse.success,
    meetingId,
  }
}

async function searchMeetings({ dispatch }) {
  var meetingSearchResponse = await dispatch(
    MeetingsMiddleware.searchMeetings.action({
      key,
      time_min: new Date(new Date().valueOf() - 7 * DateUtils.MILLISECS_PER_DAY).toISOString(),
      time_max: new Date(new Date().valueOf() + 7 * DateUtils.MILLISECS_PER_DAY).toISOString(),
      include_notes: true,
      limit: 150,
    })
  )

  if (meetingSearchResponse.error?.message === 'Rejected') {
    return {
      error: meetingSearchResponse.payload.description ?? meetingSearchResponse.payload.message,
      errorCode: meetingSearchResponse.payload.error_code ?? null,
    }
  }

  return {
    success: true,
  }
}

function filterSearchResults({ dispatch, encodedUrl }) {
  let meetingUrl =
    encodedUrl !== 'undefined'
      ? Buffer.from(encodedUrl.replace('_', '/') ?? '', 'base64').toString('utf-8')
      : encodedUrl
  let hashIndex = meetingUrl.indexOf('#')

  if (hashIndex !== -1) {
    meetingUrl = meetingUrl.slice(meetingUrl, hashIndex)
  }

  let meetingRecordResponse = MeetingsSliceHelpers.filterSearchResults({
    meetingUrl,
    key,
  })

  if (meetingRecordResponse?.error?.message === 'Rejected') {
    return {
      error: meetingRecordResponse?.description ?? meetingRecordResponse?.message,
    }
  }

  return {
    success: true,
    payload: meetingRecordResponse,
  }
}

async function createNoteForMeeting({ dispatch, meetingRecord, calendarId }) {
  let actionCreatorPayload = {
    key,
    title: meetingRecord?.subject ?? 'New meeting note',
    body: [
      {
        type: JottingType.P,
        data: '',
      },
    ],
    connections:
      meetingRecord && calendarId
        ? [
            {
              sor: calendarId,
              sor_record_id: meetingRecord.meeting_id,
              sor_record_name: meetingRecord?.subject ?? 'Meeting',
              sor_object_name: 'Event',
              connection_id: Utils.generateUUID(),
            },
          ]
        : [],
    draftNote: true,
  }

  let createNoteResponse = await dispatch(NotesMiddleware.createNote.action(actionCreatorPayload))

  if (createNoteResponse.error?.message === 'Rejected') {
    return {
      error: createNoteResponse.payload.description ?? createNoteResponse.payload.message,
    }
  }

  return {
    success: true,
    payload: { noteId: createNoteResponse.payload.note_id },
  }
}

export async function createDraftNote({ dispatch }) {
  let createNoteResponse = await dispatch(
    NotesMiddleware.createNote.action({
      key,
      ...getNewNoteContent(),
      draftNote: true,
    })
  )
  if (createNoteResponse.error?.message === 'Rejected') {
    return {
      error: createNoteResponse.payload.description ?? createNoteResponse.payload.message,
    }
  }

  // console.log('createNoteResponse.payload:', createNoteResponse.payload)

  return {
    success: true,
    payload: { noteId: createNoteResponse.payload.note_id },
  }
}
