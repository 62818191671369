// React
import React from 'react'

// // Components
import { EditLinkDialogMode } from './EditLinkDialog'
import AppComponentsContext from './AppComponents/AppComponentsContext'
// import ColorSwatch from './ColorSwatch'

// Helpers
import DomUtils from '../coreEditor/utils/DomUtils'

// Styles
import styles from './css/FloatingFormattingMenu.module.css'

// Icons
import boldIcon from '../images/fluent/bold.svg'
import italicIcon from '../images/fluent/italic.svg'
import underlineIcon from '../images/fluent/underline.svg'
import textclearIcon from '../images/fluent/text_format.svg'
import linkIcon from '../images/fluent/link.svg'
import linkdismissIcon from '../images/fluent/link_off.svg'
import listBulletIcon from '../images/fluent/list_bullet.svg'
import listNumberedIcon from '../images/fluent/list_numbered.svg'

// Formatter commands
export let FloatingFormatterCommand = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINED: 'underlined',
  HIGHLIGHT: 'highlight',
  UNORDERED_LIST: 'unordered-list',
  ORDERED_LIST: 'ordered-list',
  ADD_LINK: 'add-link',
  REMOVE_LINK: 'remove-link',
  HEADING1: 'h1',
  HEADING2: 'h2',
  HEADING3: 'h3',
  CLEAR_FORMAT: 'clear-format',
}

export default class FloatingFormattingMenu extends React.Component {
  #formatter = null
  static contextType = AppComponentsContext

  constructor(props) {
    super(props)

    // State
    this.state = { visible: false, colorSwatchVisible: false }

    // Element refs
    this.elements = { popup: React.createRef() }

    // Bindings
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.hideAndResetState = this.hideAndResetState.bind(this)
    this.chooseCommand = this.chooseCommand.bind(this)
    this.chooseHighlightColor = this.chooseHighlightColor.bind(this)
    this.insertLink = this.insertLink.bind(this)

    // Other internal variables
    this.intervalTimer = null
  }

  /**
   * Set a custom formatter for menu.  Menu will call back a function in
   * formatter with the command chosen in the menu.  If formatter is not set,
   * menu clicks will be no-ops
   * @param {Object} formatter - A formatter object
   */
  setFormatter(formatter = null) {
    this.#formatter = formatter
  }

  chooseCommand(e) {
    const role = e.target.closest('button[data-luru-role]').dataset.luruRole
    const command = role.replace('float-format-', '')

    switch (command) {
      case FloatingFormatterCommand.HIGHLIGHT:
        this.setState((state) => ({
          colorSwatchVisible: !state.colorSwatchVisible,
        }))
        break

      case FloatingFormatterCommand.ADD_LINK:
        this.context.editLinkDialog.current?.showDialog({
          url: '',
          mode: EditLinkDialogMode.CREATE_LINK,
          callback: this.insertLink,
        })
        break

      default:
        this.setState({ visible: false })
        const payload = { state: this.state.currentFormatInfo?.[command] }
        this.#formatter?.formatSelectedText?.({ command, payload })
    }
  }

  chooseHighlightColor(color) {
    this.setState({ visible: false, colorSwatchVisible: false })
    this.#formatter?.formatSelectedText?.({
      command: FloatingFormatterCommand.HIGHLIGHT,
      payload: { color },
    })
  }

  insertLink(url) {
    this.setState({ visible: false, colorSwatchVisible: false })
    this.#formatter?.formatSelectedText?.({
      command: FloatingFormatterCommand.ADD_LINK,
      payload: { url },
    })
  }

  show(range) {
    if (this.intervalTimer) {
      clearTimeout(this.intervalTimer)
      this.intervalTimer = null
    }
    // const rangeArea = range.getClientRects()
    const targetPosition = DomUtils.getElementScreenCoordinates(range.startContainer.parentElement)
    if (this.elements.popup.current) {
      this.elements.popup.current.style.left = targetPosition.getX() + 'px'
      this.elements.popup.current.style.top = targetPosition.getY() - this.elements.popup.current.offsetHeight + 'px'
    }
    let currentFormatInfo = this.#formatter?.getCurrentFormatInfo?.(range)
    // console.log(currentFormatInfo)
    this.setState({ visible: true, currentFormatInfo })
  }

  hideAndResetState() {
    if (this.elements?.popup?.current) {
      this.elements.popup.current.style.left = '-1000px'
      this.elements.popup.current.style.top = '-1000px'
      this.context?.editLinkDialog?.current?.hideDialog()
    }
    this.setState({ visible: false, colorSwatchVisible: false })
  }

  hide(e) {
    // If there is no event associated, we must be calling this from a teardown
    // chain.  Just hide the popup with relevant state changes, already!
    // Check if hiding is actually necessary before hiding
    if (!e) {
      if (this.state.visible) {
        this.hideAndResetState()
      }
      return
    }

    // If mouse moved into a link from popup or from popup to link, don't hide
    if (e.relatedTarget === this.elements.popup.current) {
      return
    }

    // Clear any existing timer to avoid multiple calls/race conditions
    if (this.intervalTimer) {
      clearTimeout(this.intervalTimer)
    }

    // Hide after a small delay, to avoid conditions when mouse event is
    // happening near the 'event horizon' (LOL!)
    this.intervalTimer = setTimeout(this.hideAndResetState, 100)
  }

  render() {
    let buttons = [
      {
        role: `float-format-${FloatingFormatterCommand.BOLD}`,
        icon: boldIcon,
        className: styles.floatFormatIcon,
        alt: 'bold',
      },
      {
        role: `float-format-${FloatingFormatterCommand.ITALIC}`,
        icon: italicIcon,
        className: styles.floatFormatIcon,
        alt: 'italic',
      },
      {
        role: `float-format-${FloatingFormatterCommand.UNDERLINED}`,
        icon: underlineIcon,
        className: styles.floatFormatIcon,
        alt: 'underline',
      },
      // {
      //   role: `float-format-${FloatingFormatterCommand.HIGHLIGHT}`,
      //   icon: 'format_color_fill',
      //   additionalComponent: (
      //     <ColorSwatch
      //       visible={this.state.colorSwatchVisible}
      //       onChooseColor={this.chooseHighlightColor}
      //     />
      //   ),
      // },
      {
        role: `float-format-${FloatingFormatterCommand.UNORDERED_LIST}`,
        icon: listBulletIcon,
        className: styles.floatFormatIcon,
        alt: 'bullet_list',
      },
      {
        role: `float-format-${FloatingFormatterCommand.ORDERED_LIST}`,
        icon: listNumberedIcon,
        className: styles.floatFormatIcon,
        alt: 'numbered_list',
      },
      {
        role: `float-format-${FloatingFormatterCommand.ADD_LINK}`,
        icon: linkIcon,
        className: styles.floatFormatIcon,
        alt: 'add_link',
      },
      {
        role: `float-format-${FloatingFormatterCommand.REMOVE_LINK}`,
        icon: linkdismissIcon,
        className: styles.floatFormatIcon,
        alt: 'remove_link',
      },
      {
        role: `float-format-${FloatingFormatterCommand.HEADING1}`,
        label: 'h1',
        className: styles.floatFormatHeading1Icon,
      },
      {
        role: `float-format-${FloatingFormatterCommand.HEADING2}`,
        label: 'h2',
        className: styles.floatFormatHeading2Icon,
      },
      {
        role: `float-format-${FloatingFormatterCommand.HEADING3}`,
        label: 'h3',
        className: styles.floatFormatHeading3Icon,
      },
      {
        role: `float-format-${FloatingFormatterCommand.CLEAR_FORMAT}`,
        icon: textclearIcon,
        className: styles.floatFormatIcon,
        alt: 'clear_text',
      },
    ]

    return (
      // linkHelper is the element that pops up when user hovers over the link
      // element (which is a SPAN)
      <div
        data-luru-role='floating-formatter'
        contentEditable={false}
        className={styles[`visible-${this.state.visible}`]}
        ref={this.elements.popup}
      >
        {buttons.map((button) => {
          const command = button.role.replace('float-format-', '')
          return (
            <div key={button.role}>
              <button
                data-luru-role={button.role}
                onClick={this.chooseCommand}
                data-luru-state={this.state.currentFormatInfo?.[command] ? 'on' : 'off'}
              >
                {button.label ? (
                  <span className={button.className}>{button.label}</span>
                ) : (
                  <img src={button.icon} alt={button.alt} className={button.className} />
                )}
              </button>
              {button.additionalComponent ?? null}
            </div>
          )
        })}
        {/* <!-- Formatters --> */}
      </div>
    )
  }
}
