import { HTTPMethod, LuruAPIResponse } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ListViewCrmRecord } from '../types'

export interface ListViewRecordsParameter {
  viewId: string
  cursor: string | null
  count?: number
  order_by?: string | undefined
}

export interface ListViewRecordsAPIResponse extends LuruAPIResponse {
  data: Array<ListViewCrmRecord>
}

export const listRecords = (
  params: Readonly<ListViewRecordsParameter>,
  options?: LuruAPIRequestOptions
) => {
  var { viewId, cursor, count, order_by }: Record<string, any> = params
  var queryStringParams = new URLSearchParams({})

  if (cursor) {
    queryStringParams.append('cursor', cursor)
  }

  if (count) {
    queryStringParams.append('count', count + '')
  }

  if (order_by) {
    queryStringParams.append('order_by', order_by)
  }

  var url =
    `/api/views/${viewId}/records?` + new URLSearchParams(queryStringParams)
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
