import type { RootState } from '../../../../app/store'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import LuruUser from '../../../../domain/users/LuruUser'
import { CrmObjectName, ReduxCrmKey } from '../../../../features/crm/types'
import { LuruCrmFieldInputOwnProps } from './LuruCrmFieldInputComponent'

export default function getReduxState(
  state: RootState,
  ownProps: LuruCrmFieldInputOwnProps
): {
  schemaStatus: EntityStatus
  refreshedAt: string | undefined
  error:
    | (Record<string, any> & {
        additional_data?: {
          error_fields: Array<string>
          sfdc_error_code?: string
          // TODO: Add hs_error_code and other hs_related fields when BE is ready
        }
      })
    | undefined
} {
  var crmKey = LuruUser.getCurrentUserCrmName()?.toLowerCase()
  var sorObjectName = CrmRecord.getCrmRecordName(ownProps.crmRecordType)

  return {
    schemaStatus: crmKey
      ? // @ts-ignore
        (state.crm[crmKey]?.schema?.[sorObjectName]?.status as EntityStatus)
      : EntityStatus.NotLoaded,
    refreshedAt:
      crmKey && sorObjectName && ownProps.sorRecordId
        ? state.crm[crmKey as ReduxCrmKey]?.entities?.[sorObjectName as CrmObjectName]?.[ownProps.sorRecordId]
            ?.lastRefreshedAt
        : undefined,
    error:
      crmKey && sorObjectName && ownProps.sorRecordId
        ? state.crm[crmKey as ReduxCrmKey]?.entities?.[sorObjectName as CrmObjectName]?.[ownProps.sorRecordId]?.error ??
          undefined
        : undefined,
  }
}
