import { useState } from 'react'
import { useConnectedMeeting } from '../../features/notes/hooks/useConnectedMeeting'
import { useConnectedRecord } from '../../features/notes/hooks/useConnectedRecord'
import { CRMLogAsLinkControlProps } from '.'
import { CrmActivity, CrmActivityLogType } from '../../features/crm/types'
import { EntityStatus } from '../../app/types'
import { useNote } from '../../features/notes/hooks/useNote'

export default function useCRMLogAsLinkControlComponentState({ noteID }: CRMLogAsLinkControlProps) {
  const [state, setState] = useState<{
    logAs: CrmActivityLogType
    isLogged: boolean
    createLogActivityStatus: EntityStatus
    updateLogActivityStatus: EntityStatus
    deleteLogActivityStatus: EntityStatus
    createdLog: CrmActivity | null
  }>({
    logAs: CrmActivityLogType.DoNotLog,
    isLogged: false,
    createLogActivityStatus: EntityStatus.Idle,
    updateLogActivityStatus: EntityStatus.Idle,
    deleteLogActivityStatus: EntityStatus.Idle,
    createdLog: null,
  })

  const connectedNote = useNote(noteID)
  const connectedMeetingObj = useConnectedMeeting(noteID)
  const connectedRecord = useConnectedRecord(noteID)
  return {
    state: {
      getState: state,
      setState,
    },
    connectedMeetingObj,
    connectedRecord,
    connectedNote,
  }
}

export type UseCRMLogAsLinkControlComponentState = ReturnType<typeof useCRMLogAsLinkControlComponentState>
