import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// Routes
import renderNotesPageRoutes from './routes/NotesPageRoutes'
import renderPipelinePageRoutes from './routes/PipelinePageRoutes'
import renderWorkflowPageRoutes from './routes/WorkflowPageRoutes'
import renderMeetingNotesPageRoutes from './routes/MeetingNotesPageRoutes'
import renderTaskPageRoutes from './routes/TaskPageRoutes'
import renderSettingsPageRoutes from './routes/SettingsPageRoutes'
import renderRecordLandingHomeRoutes from './routes/RecordLandingHomeRoutes'
// Post sign up onboarding flows page
import OnboardingFlowsLazyLoading from './routes/OnboardingFlows'
// Loading page
import WebAppLoading from '../WebAppLoading'
// Uninstall extension
import UninstallExtension from '../../routes/uninstallextension/UninstallExtension'
// Error pages
import Error, { NotFound } from '../../primitives/Error'
// Close window page
import { CloseWindowAfterLogin } from '../../routes/meetingNotes/EmbeddedMeetingNote'
import LoadingComponent from '@/components/LoadingComponent'
import renderExtensionPageRoutes from './routes/ExtensionPageRoutes'
import renderTestRoutes from './routes/TestRoutes'
import { useAppSelector } from '@/app/hooks'
import { getCurrentUserRole } from '@/features/users/selectors/getCurrentUserRole'
import { LuruRole } from '@/features/users/types'
import renderDealsDashboardRoutes from './routes/DealsDashboardRoutes'
import useCrmName from '@/features/crm/hooks/useCrmName'
import { CRMProvider } from '@/features/user/types'
// import EmbeddedNoteApp from '../EmbeddedNoteApp'

const WebAppRoutes = () => (
  <Suspense fallback={<WebAppLoading />}>
    <WebAppRoutesLoaded />
  </Suspense>
)

export default WebAppRoutes

const WebAppRoutesLoaded = () => {
  const currentUserRole = useAppSelector(getCurrentUserRole)
  const crmProvider = useCrmName() as CRMProvider

  return (
    <Routes>
      <Route path='/'>
        {/* Index element */}
        <Route
          index
          element={
            <Navigate
              to={
                currentUserRole === LuruRole.ADMIN && ![CRMProvider.PIPEDRIVE].includes(crmProvider)
                  ? '/workflows'
                  : '/notes'
              }
            />
          }
        />

        {/* Post extension install page */}
        <Route path='/install_crx' element={<Navigate to='/notes' />} />

        {/* Post sign up page */}
        <Route path='/thank-you' element={<OnboardingFlowsLazyLoading />} />

        {/* Home page */}
        {renderNotesPageRoutes()}

        {/* Pipeline page */}
        {renderPipelinePageRoutes()}

        {/* Workflow pages */}
        {renderWorkflowPageRoutes()}

        {/* Meeting notes pages */}
        {renderMeetingNotesPageRoutes()}

        {/* Task page routes */}
        {renderTaskPageRoutes()}

        {/* Settings page routes */}
        {renderSettingsPageRoutes()}

        {/* Deals dashboard routes */}
        {currentUserRole === LuruRole.ADMIN && renderDealsDashboardRoutes()}

        {/* Record landing home ('View in Luru' in alerts) */}
        {renderRecordLandingHomeRoutes()}

        {/* Extension page routes */}
        {renderExtensionPageRoutes()}

        {/* Test routes */}
        {renderTestRoutes()}
      </Route>

      {/* Extension-Uninstall-Page */}
      <Route path='/uninstall_chrome_extension' element={<UninstallExtension />} />

      {/* Test */}
      <Route
        path='/test-comp'
        element={
          <div>
            <LoadingComponent error={false} loading={true}>
              <div>Comp</div>
            </LoadingComponent>
          </div>
        }
      />
      {/* <Route path='/test/enp' element={<EmbeddedNoteApp />} /> */}

      {/* Error, placeholder, and not-found pages */}
      <Route path='/error' element={<Error />} />
      <Route path='/noop_close' element={<CloseWindowAfterLogin />} />
      <Route path='*' element={<NotFound message={undefined} />} />
    </Routes>
  )
}
