import styles from './styles.module.css'
import clockIcon from '../../images/fluent/clock.svg'
import arrowDropUpIcon from '../../images/fluent/arrow_drop_up.svg'
import arrowDropDownIcon from '../../images/fluent/arrow_drop_down.svg'
import PopupMenu from '../PopupMenu'
import { useEffect, useState } from 'react'
import { DIAL_TYPE, PERIOD_TYPE } from './types'
import { DateUtils } from '../../utils/dateUtils'

interface LuruTimePickerProps {
  value?: Date
  prevChoosenValue?: { hours: string | undefined; minutes: string | undefined; period: PERIOD_TYPE | undefined }
  onChange?: (params: {
    value: Date | null
    raw: { hour: string; minute: string; period: PERIOD_TYPE | null }
    originalValue?: Date
    isValid?: boolean
  }) => void
  prevChosenHour?: string
  prevChosenMinute?: string
  onChooseHour?: (hour: string | null) => void
  onChooseMinute?: (minute: string | null) => void
  leftAlign?: boolean
  hideClockPicker?: boolean
  width?: string
}

export default function LuruTimePicker({
  value,
  onChange,
  prevChosenHour,
  prevChosenMinute,
  onChooseHour,
  onChooseMinute,
  leftAlign,
  hideClockPicker,
  width,
  prevChoosenValue,
}: LuruTimePickerProps) {
  const [state, setState] = useState<{
    hour: string
    minute: string
    period: PERIOD_TYPE | null
  }>({
    hour: prevChosenHour ? DateUtils.get12HourFormat(prevChosenHour).hour : '',
    minute: prevChosenMinute ? prevChosenMinute : '',
    period: prevChosenHour ? DateUtils.get12HourFormat(prevChosenHour).period : PERIOD_TYPE.AM,
  })

  const [isValidationError, setIsValidationError] = useState(false)
  const [dialType, setDialType] = useState(DIAL_TYPE.HOUR)

  const handlers = {
    handleHoursChange: (hour: string, source: 'input' | 'clock' = 'input') => {
      if (Number(hour) > 12 || hour.length > 2) {
        return
      }
      setState((prevState) => ({ ...prevState, hour: hour }))
      if (source === 'clock') {
        setTimeout(() => {
          setDialType(DIAL_TYPE.MINUTE)
        }, 1)
      }

      const formatedDate = getFormattedDate({ ...state, hour })
      setIsValidationError(!formatedDate.isValid)
      onChange?.(formatedDate)
      onChooseHour?.(formatedDate.raw.hourIn24Format)
    },
    handleMinutesChange: (minute: string, source: 'input' | 'clock' = 'input') => {
      if (Number(minute) > 60 || minute.length > 2) {
        return
      }
      setState((prevState) => ({ ...prevState, minute: minute }))
      const formatedDate = getFormattedDate({ ...state, minute })
      setIsValidationError(!formatedDate.isValid)
      onChange?.(formatedDate)
      onChooseMinute?.(formatedDate.raw.minute)
    },
    handlePeriodChange: (period: PERIOD_TYPE) => {
      setState((prevState) => ({ ...prevState, period: period }))
      const formatedDate = getFormattedDate({ ...state, period })
      setIsValidationError(!formatedDate.isValid)
      onChange?.(formatedDate)
      onChooseHour?.(formatedDate.raw.hourIn24Format)
    },
    handleDialTypeChange: (dialType: DIAL_TYPE) => {
      setDialType(dialType)
    },
  }

  useEffect(() => {
    if (prevChosenHour || prevChosenMinute) {
      return
    }

    if (prevChoosenValue?.hours === 'undefined' && prevChoosenValue?.minutes === 'undefined') {
      setState((prevState) => ({
        ...prevState,
        hour: '',
        minute: '',
        period: PERIOD_TYPE.AM,
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        hour: prevChoosenValue?.hours ? DateUtils.get12HourFormat(prevChoosenValue.hours).hour : '',
        minute: prevChoosenValue?.minutes
          ? prevChoosenValue.minutes?.length === 1
            ? `0${prevChoosenValue.minutes}`
            : prevChoosenValue.minutes
          : '',
        period: prevChoosenValue?.period || PERIOD_TYPE.AM,
      }))
    }
    // Adding this because I don't want this Effect should trigger when the prevChoosenHour and prevChoosenMinute changes
    // eslint-disable-next-line
  }, [prevChoosenValue])

  const getFormattedDate = (params: { hour: string; minute: string; period: PERIOD_TYPE | null }) => {
    var { hour, minute, period } = params
    var dateTarget: Date | null = null
    try {
      if (hour && minute && period) {
        dateTarget = new Date()
        // To convert 12 hour clock into 24 hour clock we have to add 12 hours, if the given time is in PM.
        var hourToBe = period === PERIOD_TYPE.PM ? Number(hour) + 12 : Number(hour)
        dateTarget.setHours(hourToBe)
        var minuteToBe = Number(minute) === 60 ? 0 : Number(minute)
        dateTarget.setMinutes(minuteToBe)
        dateTarget.setSeconds(0)
        dateTarget.setMilliseconds(0)
        return {
          value: dateTarget,
          raw: {
            hour,
            minute: minute === '60' ? '00' : minute,
            period,
            hourIn24Format: DateUtils.get24HourFormat(hour, period).hour,
          },
          originalValue: value,
          isValid: true,
        }
      } else {
        return {
          value: dateTarget,
          raw: { hour, minute, period, hourIn24Format: DateUtils.get24HourFormat(hour, period).hour },
          originalValue: value,
          isValid: !((hour || minute || period) && !dateTarget),
        }
      }
    } catch (error) {
      return {
        value: dateTarget,
        raw: { hour, minute, period, hourIn24Format: DateUtils.get24HourFormat(hour, period).hour },
        originalValue: value,
        isValid: !((hour || minute || period) && !dateTarget),
      }
    }
  }

  const renderClockTimePicker = () => {
    const hourDegrees = (Number(state.hour) / 12) * 360 + 90
    const minDegrees = (Number(state.minute) / 60) * 360 + 90
    return (
      <div className={styles.clockContainer}>
        <div className={styles.dialType}>
          <img
            src={arrowDropUpIcon}
            className={styles.dialTypeItem}
            alt='arrow-left'
            data-luru-timer={DIAL_TYPE.HOUR}
            data-luru-selected={dialType === DIAL_TYPE.HOUR}
            onClick={() => handlers.handleDialTypeChange(DIAL_TYPE.HOUR)}
          />
          <img
            src={arrowDropDownIcon}
            className={styles.dialTypeItem}
            alt='arrow-right'
            data-luru-timer={DIAL_TYPE.MINUTE}
            data-luru-selected={dialType === DIAL_TYPE.MINUTE}
            onClick={() => handlers.handleDialTypeChange(DIAL_TYPE.MINUTE)}
          />
        </div>
        <div className={styles.clock}>
          {dialType === DIAL_TYPE.HOUR && (
            <ul className={styles.hours}>
              {Array.from(Array(12).keys()).map((hr) => (
                <li
                  className={styles.hour}
                  key={hr}
                  data-luru-hour={hr + 1}
                  data-luru-selected={Number(state.hour) === hr + 1}
                  onClick={(e) => {
                    const newHr = String(hr + 1).length === 1 ? `0${hr + 1}` : String(hr + 1)
                    handlers.handleHoursChange(newHr, 'clock')
                  }}
                >
                  {hr + 1}
                </li>
              ))}
            </ul>
          )}
          {dialType === DIAL_TYPE.MINUTE && (
            <ul className={styles.minutes}>
              {Array.from(Array(60).keys()).map((min) => (
                <li
                  className={styles.minute}
                  key={min}
                  data-luru-minute={min + 1 === 60 ? '00' : min + 1}
                  data-luru-selected={Number(state.minute) === (min + 1) % 60}
                  onClick={(e) => {
                    const newMin = String((min + 1) % 60).length === 1 ? `0${(min + 1) % 60}` : String((min + 1) % 60)
                    handlers.handleMinutesChange(newMin)
                  }}
                >
                  {min + 1 === 60 ? '00' : (min + 1) % 5 === 0 ? min + 1 : '.'}
                </li>
              ))}
            </ul>
          )}
          <div className={styles.center}></div>
          {state.hour && dialType === DIAL_TYPE.HOUR && (
            <div
              className={[styles.hand, styles.hourHand].join(' ')}
              style={{ transform: `rotate(${hourDegrees}deg)`, transition: 'transform 0.2s ease-in-out' }}
            >
              <div className={styles.clockHandThumb}></div>
            </div>
          )}
          {state.minute && dialType === DIAL_TYPE.MINUTE && (
            <div
              className={[styles.hand, styles.minHand].join(' ')}
              style={{ transform: `rotate(${minDegrees}deg)`, transition: 'transform 0.2s ease-in-out' }}
            >
              <div className={styles.clockHandThumb}></div>
            </div>
          )}
        </div>
        <div className={styles.period}>
          <div
            className={styles.periodItem}
            data-luru-selected={state.period === 'PM'}
            onClick={() => {
              handlers.handlePeriodChange(PERIOD_TYPE.PM)
            }}
          >
            PM
          </div>
          <div
            className={styles.periodItem}
            data-luru-selected={state.period === 'AM'}
            onClick={() => {
              handlers.handlePeriodChange(PERIOD_TYPE.AM)
            }}
          >
            AM
          </div>
        </div>
      </div>
    )
  }

  var classNames = [styles.parent]

  var timeInputComponent = (
    <>
      <div className={[styles.parentInput].join(' ')}>
        <input
          type='number'
          min='1'
          max='12'
          value={state.hour}
          onChange={(e) => {
            handlers.handleHoursChange(e.target.value)
          }}
          placeholder='HH'
          onFocus={(e) => {
            e.target.select()
          }}
          onBlur={(e) => {
            e.target.value &&
              handlers.handleHoursChange(e.target.value.length === 1 ? `0${e.target.value}` : e.target.value)
          }}
        />
        <span>:</span>
        <input
          style={{ marginLeft: '0.3em' }}
          type='number'
          min='0'
          max='60'
          value={state.minute === '60' ? '00' : state.minute}
          onChange={(e) => {
            handlers.handleMinutesChange(e.target.value)
          }}
          placeholder='MM'
          onFocus={(e) => {
            e.target.select()
          }}
          onBlur={(e) => {
            e.target.value &&
              handlers.handleMinutesChange(e.target.value.length === 1 ? `0${e.target.value}` : e.target.value)
          }}
        />
        <select
          value={state.period ?? ''}
          onChange={(e) => {
            handlers.handlePeriodChange(e.target.value as PERIOD_TYPE)
          }}
          disabled={!state.hour || !state.minute}
        >
          <option value={PERIOD_TYPE.AM}>{PERIOD_TYPE.AM}</option>
          <option value={PERIOD_TYPE.PM}>{PERIOD_TYPE.PM}</option>
        </select>
      </div>
      {!hideClockPicker && (
        <span className={styles.icon} data-luru-role='time-picker-icon'>
          <PopupMenu
            popupMenuClassName={styles.clockPopupMenu}
            hideOnMenuClick={false}
            items={renderClockTimePicker()}
            leftalign={leftAlign}
          >
            <img src={clockIcon} alt='Pick time' />
          </PopupMenu>
        </span>
      )}
    </>
  )

  return (
    <div style={width ? { width: width } : undefined}>
      <div className={classNames.join(' ')}>{timeInputComponent}</div>
      {isValidationError && (
        <div className={styles.errorContainer}>
          <span>Invalid time format</span>
        </div>
      )}
      {/* <div style={{ marginTop: 10, width: 350 }}>{renderClockTimePicker()}</div> */}
    </div>
  )
}
