import { notesSliceUpdateTitle } from './notes.updateTitle'
import { noteTemplatesSliceUpdateTitle } from './noteTemplates.updateTitle'
import { usersSliceUpdateLuruUserRoles } from './users.updateLuruUserRoles'
import { viewsSliceUpdateView } from './views.updateView.fulfilled'

export const UserSliceExtraReducers = {
  notesSliceUpdateTitle,
  noteTemplatesSliceUpdateTitle,
  usersSliceUpdateLuruUserRoles,
  viewsSliceUpdateView,
}
