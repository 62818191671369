import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import LuruError, { LuruErrorName } from '../../LuruError'
import { UpdateViewParameter } from '../middleware/updateView'

export const updateView = async (
  params: Readonly<UpdateViewParameter>,
  options: LuruAPIRequestOptions
) => {
  var url = `/api/views/${params.view_id}`
  var request = new LuruAPIRequest(url, HTTPMethod.PUT, options)
  var requestParams: Partial<UpdateViewParameter> = { ...params }

  if (!params.display_settings) {
    delete requestParams.display_settings
  }

  if (!params.input) {
    delete requestParams.input
  }

  if (!params.name) {
    delete requestParams.name
  }

  if (!params.name && !params.display_settings && !params.input) {
    throw new LuruError(
      LuruErrorName.InvalidArgumentError,
      'Update view params are all empty',
      undefined,
      { params }
    )
  }

  delete requestParams.view_id

  request.setBody(requestParams)

  return request.make()
}
