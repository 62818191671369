import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ListViewsParameter } from '../middleware/listViews'

export const listViews = (
  params: Readonly<ListViewsParameter>,
  options: LuruAPIRequestOptions
) => {
  var listParams: Record<string, any> = params

  Object.keys(params).forEach((key) => {
    var k = key as keyof ListViewsParameter
    if (params[k] !== undefined && params[k] !== null) {
      listParams[k] = params[k] as string
    }
  })

  delete listParams.force

  var url = '/api/views?' + new URLSearchParams(listParams)
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
