import json5 from 'json5'
import { HTTPMethod, LuruEntryPoint } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { SaveNoteParameter } from '../middleware/saveNote'
import { NoteSyncState } from '../types'
import LuruUser from '../../../domain/users/LuruUser'

export default async function saveNote(
  {
    note_id,
    title = 'New note',
    body = '[]',
    connections = undefined,
    isDraft = false,
    sync = false,
    syncState = NoteSyncState.PRIVATE,
    source,
    template_id
  }: Readonly<SaveNoteParameter>,
  options: LuruAPIRequestOptions
) {
  var requestUrl = '/api/notes' + (isDraft ? '' : `/${note_id}`) + (!isDraft && sync ? `?sync=${sync}` : '')
  var request = new LuruAPIRequest(requestUrl, isDraft ? HTTPMethod.POST : HTTPMethod.PUT, options)
  var requestBody: Partial<SaveNoteParameter & { note_id: string; sync_state: string }> = {
    // JSON stringify ensures  quotes around keys
    // JSON5 is a superset of JSON that allows ES5 syntax that supports
    // unquoted keys. We run into issues
    body: JSON.stringify(json5.parse(body)),
    title,
    sync_state: syncState,
    template_id: template_id,
  }

  if (isDraft) {
    requestBody.note_id = note_id
  }
  if (isDraft) {
    requestBody.source = source ?? (LuruUser.getCurrentEntryPoint() as LuruEntryPoint)
  }

  if (connections) {
    requestBody.connections = connections
  }

  request.setBody(requestBody)

  return request.make()
}
