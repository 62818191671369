import CRMBaseMiddlewareFactory from './api/crmBaseMiddleware'
import { LuruFieldType } from '../../domain/crmRecord/typings.d'
import { LuruHierarchialPicklistOptions } from '../../domain/crmRecord/typings.d'
import { EntityStatus } from '../../app/types'
import { CrmFieldSchema } from './types'
import CrmRecord from '../../domain/crmRecord/CrmRecord'

interface PicklistOption {
  label: string
  value: string
  valueForUpdate?: string
}

interface HubspotObjectSchema {
  results: Array<HubspotRawFieldSchema>
  fields: Array<HubspotFieldSchema>
}

interface HubspotRawFieldSchema {
  name: string
  calculated: boolean
  field_type: HubspotRawFieldType
  type: HubspotRawFieldType
  modification_metadata: {
    read_only_value: boolean
  }
  referenced_object_type: null | any
  options: Array<PicklistOption> | null
  display_order?: number
  name_field: boolean
  mandatory: boolean
}

interface HubspotFieldSchema extends HubspotRawFieldSchema {
  label: string
  luruFieldType: LuruFieldType
  updateable: boolean
  nameField: boolean
  referencedObject: null | any
  picklistValues: Array<PicklistOption> | undefined
  hierarchicalPicklistValues: LuruHierarchialPicklistOptions | undefined
  isMandatory: boolean
  isFilterable: boolean
  isHubspotTypeSelect: boolean
}

interface HubspotPipelineSchemaRawData {
  data: {
    results: Array<HubspotPipelineSchema>
  }
}

interface HubspotPipelineSchema {
  id: string
  label: string
  archived: boolean
  stages: Array<HubspotStageSchema>
  display_order: number
}

interface HubspotStageSchema {
  id: string
  label: string
  archived: boolean
  display_order: number
  metadata: { isClosed: 'false' | 'true'; probability: string }
}

type HubspotRawFieldType =
  | 'number'
  | 'calculation_score'
  | 'select'
  | 'textarea'
  | 'checkbox'
  | 'booleancheckbox'
  | 'text'
  | 'date'
  | 'phonenumber'
  | 'radio'
  | 'reference'
  | 'multi_reference'
  | 'calculation_rollup'

/**
 * @classdesc Class for HUBSPOT middleware
 */
export default class HubspotMiddlewareFactory extends CRMBaseMiddlewareFactory {
  constructor() {
    super('hubspot')
  }

  //// Actions
  /**
   * Normalize a given HUBSPOT schema
   * @param {HubspotObjectSchema} schema - Schema as returned by the CRM
   * @param {string} objectName - Object name in CRM
   * @param {HubspotPipelineSchemaRawData} pipelines - List of pipelines, applicable only for deals
   * @returns {HubspotObjectSchema} - A normalized schema which has one 'luruFieldType' key
   * added to the base schema (implementation for now)
   */
  normalizeSchema(schema: HubspotObjectSchema, objectName: string): HubspotObjectSchema {
    // @ts-ignore
    schema.fields = schema.results
    schema.fields = schema.fields
      .map((f) => ({
        ...f,
        display_order: f.display_order === -1 ? Number.MAX_SAFE_INTEGER : f.display_order,
      }))
      .sort((f1, f2) => (f1.display_order ?? 0) - (f2.display_order ?? 0))

    schema.fields.forEach((field) => {
      // Add a key for luru field type
      field.luruFieldType =
        this.#computeLuruFieldType(field.field_type ?? field.type, field.name, objectName) ?? LuruFieldType.TEXT

      // Mark field as mandatory or not
      field.isMandatory = field.mandatory ?? false

      // Mark field as nameField or not
      field.nameField = field.name_field

      // Mark if field is updateable or not
      field.updateable = !field.modification_metadata.read_only_value

      // Add a standard key to point to the referenced object
      field.referencedObject = field.referenced_object_type

      // Add a hint if the field was a REFERENCE field for Luru, but came as a SELECT from HS
      field.isHubspotTypeSelect = field.field_type === 'select' && field.luruFieldType === LuruFieldType.REFERENCE

      // Add picklistValues
      if (field.luruFieldType !== LuruFieldType.REFERENCE) {
        this.#addPicklistValues(field)
      }

      // For Deal > dealstage field, by default, options are absent.  Add them.
      if (field.name === 'dealstage') {
        this.#normalizeDealstageField(field)
      }

      // Indicate if field is filterable in views - by default true
      field.isFilterable = true

      // Special field types for Hubspot
      const specificFieldType = this.getSpecificFieldTypes(field.name)
      if (specificFieldType) {
        field.luruFieldType = specificFieldType
      }
    })

    const objFieldNames = this.getObjectFieldNames(objectName)

    if (objFieldNames) {
      let selectedFields = [...schema.fields.filter((f) => objFieldNames.includes(f.name))]
      return {
        fields: selectedFields,
        results: selectedFields,
      }
    }

    return schema
  }

  getObjectFieldNames(objectName: string): string[] | undefined {
    return undefined
    // objectName = CrmRecord.getCrmRecordType(objectName as CrmRecordType)
    // const fieldNames: Partial<Record<string, string[]>> = {
    //   ['CrmRecordType.MEETING']: [
    //     'hs_meeting_title',
    //     'hs_meeting_start_time',
    //     'hs_meeting_end_time',
    //     'hs_meeting_outcome',
    //   ],
    // }
    // return fieldNames[objectName] ?? undefined
  }

  getSpecificFieldTypes(fieldName: string): LuruFieldType | undefined {
    const fieldTypes: Partial<Record<string, LuruFieldType>> = {
      hs_meeting_start_time: LuruFieldType.MEETING_TIMESTAMP,
      hs_meeting_end_time: LuruFieldType.MEETING_TIMESTAMP,
    }
    return fieldTypes[fieldName]
  }

  // Format the picklist value options in a standard way
  // Key is 'picklistvalues'; options are built as a Record<label, value>
  #addPicklistValues(field: HubspotFieldSchema) {
    if (field.luruFieldType === LuruFieldType.MULTIENUM || field.luruFieldType === LuruFieldType.ENUM) {
      if (field.options && Array.isArray(field.options)) {
        field.picklistValues = []
        field.options.forEach((option) => {
          field.picklistValues?.push({
            label: option.label,
            // We will use .label instead of .value for HS picklist values
            value: option.label,
            valueForUpdate: option.valueForUpdate ?? option.value,
          })
        })
      }
    }
  }

  #normalizeDealstageField(field: HubspotFieldSchema) {
    field.picklistValues = (field.options as LuruHierarchialPicklistOptions)
      ?.map((pipelineLevel) =>
        pipelineLevel.options.map((stageLevel) => ({
          label: pipelineLevel.label + ': ' + stageLevel.label,
          // Note: We are backing up the original value in valueForUpdate prop
          value: pipelineLevel.label + ': ' + stageLevel.label,
          // value: stageLevel.value,
          valueForUpdate: stageLevel.value,
        }))
      )
      .flat()
    field.luruFieldType = LuruFieldType.ENUM
    field.field_type = 'select'
  }

  // TODO: There will be errors in following 2 functions - fix them
  normalizePipelineList(payload: HubspotPipelineSchemaRawData) {
    return payload.data.results.map((pipeline) => ({
      ...pipeline,
      name: pipeline.label,
      stages: {
        status: EntityStatus.Loaded,
        data: pipeline.stages.map((stage) => ({
          ...stage,
          name: stage.label,
          value: stage.id,
        })),
      },
    }))
  }

  getStageListFromSchema(schema: HubspotObjectSchema) {
    const field = schema?.results?.find((field) => field.name === 'dealstage')
    return field?.options?.map((option) => ({ ...option, name: option.label }))
  }

  /**
   * Get primary object names for UI display for HUBSPOT
   * @returns {Array} - Array of objects with name and plural version
   */
  getPrimaryObjectNames(): Array<any> {
    var crmPrimaryCrmRecordType = CrmRecord.getAllPrimaryObjects()

    return [
      {
        name: 'deals',
        plural: 'Deals',
        singular: 'Deal',
        isOpportunity: true,
        crmRecordType: crmPrimaryCrmRecordType.DEAL,
      },
      {
        name: 'companies',
        plural: 'Companies',
        singular: 'Company',
        crmRecordType: crmPrimaryCrmRecordType.ACCOUNT,
      },
      {
        name: 'contacts',
        plural: 'Contacts',
        singular: 'Contact',
        crmRecordType: crmPrimaryCrmRecordType.CONTACT,
      },
    ]
  }

  /**
   * Get primary object names for UI display for HUBSPOT
   * @returns {Array} - Array of objects with name and plural version
   */
  getAllPrimaryObjectNames(): Array<any> {
    var crmPrimaryCrmRecordType = CrmRecord.getAllPrimaryObjects()

    return [
      {
        name: 'deals',
        plural: 'Deals',
        singular: 'Deal',
        isOpportunity: true,
        crmRecordType: crmPrimaryCrmRecordType.DEAL,
      },
      {
        name: 'companies',
        plural: 'Companies',
        singular: 'Company',
        crmRecordType: crmPrimaryCrmRecordType.ACCOUNT,
      },
      {
        name: 'contacts',
        plural: 'Contacts',
        singular: 'Contact',
        crmRecordType: crmPrimaryCrmRecordType.CONTACT,
      },
      {
        name: 'meetings',
        plural: 'Meetings',
        singular: 'Meeting',
        crmRecordType: crmPrimaryCrmRecordType.MEETING,
      },
    ]
  }

  /**
   * Fill a record with schema details and return a composed object
   * @param {Object} record - An HUBSPOT record, this object will be unchanged
   * @param {HubspotObjectSchema} schema - Schema of the record
   * @returns - Object of type {record, schema}, with record itself of type
   * { value: <HUBSPOT value returned>, schema: <normalized schema> }
   */
  mergeRecordWithSchema(
    record: { properties: Record<string, any> },
    schema: HubspotObjectSchema,
    sorObjectName: string
  ): {
    record: Record<string, { value: any; schema: HubspotFieldSchema; originalValue?: any }>
    schema: HubspotObjectSchema
  } {
    var recordResult: Record<string, { value: any; schema: HubspotFieldSchema; originalValue?: any }> = {}

    Object.keys(record.properties).forEach((fieldName) => {
      if (record.properties[fieldName] === null || record.properties[fieldName] === undefined) {
        record.properties[fieldName] = ''
      }

      let fieldSchema = schema.fields.find((item) => item.name === fieldName)

      // We populate the result Record<> only if we find the field name in
      // fieldSchema
      if (fieldSchema) {
        if (fieldSchema.luruFieldType === LuruFieldType.ENUM) {
          // For picklists, we replace the system-value with user-friendly
          // label and backup value to the originalValue field
          recordResult[fieldName] = {
            value:
              fieldSchema.picklistValues?.find(({ value }) => value === record.properties[fieldName])?.label ??
              fieldSchema.picklistValues?.find(({ valueForUpdate }) => valueForUpdate === record.properties[fieldName])
                ?.label,
            originalValue: record.properties[fieldName],
            schema: {
              ...fieldSchema,
              picklistValues: fieldSchema.picklistValues?.map((option) => ({
                ...option,
                valueForUpdate: option.valueForUpdate ?? option.value,
                value: option.label,
              })),
            },
          }
        } else {
          recordResult[fieldName] = {
            value: record.properties[fieldName],
            schema: fieldSchema,
          }
        }
      }
    })

    return { record: recordResult, schema }
  }

  /**
   * Compute a standardized field type.  The function may use the object name
   * and field name parameters, if required.  For e.g., we may need to identify
   * a note as a LuruFieldType.NOTE and not as 'textarea', while allowing for
   * the option of identifying 'textarea' as LuruFieldType.LARGETEXT.
   * @param {string} hubspotFieldType - Field type as returned by hubspot
   * @param {string} fieldName - Field name as returned by hubspot
   * @param {string} objectName - Object name in hubspot
   * @returns {string} - Luru normalized field type
   */
  #computeLuruFieldType(
    hubspotFieldType: HubspotRawFieldType,
    fieldName: string,
    objectName: string
  ): LuruFieldType | undefined {
    const map: Record<HubspotRawFieldType, LuruFieldType> = {
      number: LuruFieldType.DOUBLE,
      calculation_score: LuruFieldType.INTEGER,
      calculation_rollup: LuruFieldType.INTEGER,
      select: LuruFieldType.ENUM_SELECT,
      textarea: LuruFieldType.LARGETEXT,
      checkbox: LuruFieldType.MULTIENUM,
      booleancheckbox: LuruFieldType.BOOLEAN,
      text: LuruFieldType.TEXT,
      date: LuruFieldType.DATE,
      phonenumber: LuruFieldType.TELEPHONE,
      radio: LuruFieldType.ENUM_RADIO,
      reference: LuruFieldType.REFERENCE,
      multi_reference: LuruFieldType.MULTIREFERENCE,
    }

    return map[hubspotFieldType]
  }

  /*
   * Since the API responses of the SearchRecord API are in different formats
   * we use this function to standardize them for consumption by the FE.
   * This is typically used in the 'link record to CRM' search box
   */
  massageSearchRecordResponse(payload: any) {
    Object.keys(payload).forEach(function (item) {
      // Hubspot is returning a string instead of JSON. So we're doing this
      var _payload_item = payload[item]

      _payload_item.data = JSON.parse(_payload_item.data)

      switch (_payload_item.sor_object_name) {
        case 'deals':
          _payload_item.data.Name = _payload_item.data.dealname
          break

        case 'contacts':
          //Some contacts dont have a last name
          _payload_item.data.Name =
            _payload_item.data.firstname + ' ' + (_payload_item.data.lastname ? _payload_item.data.lastname : '')
          break

        case 'companies':
        default:
          _payload_item.data.Name = _payload_item.data.name
          break
      }

      payload[item] = _payload_item
    })

    return payload
  }

  // We are doing this so that we can show the 'label'
  // But send the 'value' to the CRM for updation
  prepareEnumForUpdate(payload: any) {
    var picklistOptions = payload.completeRecordFields?.record[payload.fieldName].schema.picklistValues ?? undefined

    var chosenOption =
      picklistOptions?.find((p: PicklistOption) => p.valueForUpdate === payload.fieldValue) ??
      picklistOptions?.find((p: PicklistOption) => p.value === payload.fieldValue) ??
      picklistOptions?.find((p: PicklistOption) => p.label === payload.fieldValue)

    return chosenOption?.valueForUpdate ?? payload.fieldValue
  }

  prepareFieldSetForUpdate(
    fields: Record<string, { fieldValue?: any; luruFieldType: LuruFieldType } | any>,
    fieldSchemaList: Array<CrmFieldSchema> | undefined
  ) {
    return super.prepareFieldSetForUpdate(fields, fieldSchemaList)
  }
}
