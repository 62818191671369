import moment from 'moment'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { LuruFieldType } from '../../../domain/crmRecord/typings.d'
import LuruUser from '../../../domain/users/LuruUser'
import {
  ViewAPIFilterExpression,
  ViewFilterGroupingOperator,
  ViewFilterOperator,
} from '../../../features/views/filterTypes.d'
import { NoteSorConnection } from '../../notes/types'
import { NoteTemplateFilter, ReduxNoteTemplateEntity } from '../types'
import { hasEmptyFilter } from './hasEmptyFilter'

/**
 * Test whether the current template is a match for a given CRM record.
 * If filter is empty, then consider it non-applicable.  Return a specificity
 * score based on how specific the match was
 * @param crmConnection Object name
 * @returns -1, if match not found, else a non-zero specificity score
 */
export const getSpecificityForCrmObject = async (
  template: ReduxNoteTemplateEntity,
  conn: NoteSorConnection
) => {
  // If filter is empty, consider this to be a non-match.  (note record will
  // decide how to handle templates with empty filters)
  var specificity = 1

  if (hasEmptyFilter(template)) {
    specificity = -1
  }

  // At this step, filter is not empty => there is an SOR
  // Check if SOR is present in filter and if it equals current user's SOR
  var crmId = LuruUser.getCurrentUserCrmName()?.toLowerCase()

  if (template.filter?.sorId !== crmId) {
    specificity = -1
  }

  // At this step, SOR is present and matches with current user's SOR
  // If this template is applicable to all records ('All'), return 0, which
  // is the equivalent for a 'general' template (one with no filters)
  if (template.filter?.sorObjectName === 'All') {
    specificity = 0
  }

  // At this step, there is an SOR in filter, there is an object name in
  // filter, which is set to something other than 'All'.  Now, an exact
  // match between object names has to be made, else we say there's no match
  if (template.filter?.sorObjectName !== conn.sor_object_name) {
    specificity = -1
  }

  // If the template's "sor_object" matches the sor_object of the note's record
  if (template.filter?.sorObjectName === conn.sor_object_name) {
    specificity = 1
  }

  // If the specificity is 1 (calculated above) and a filter is present, we use the following logic:
  // If a record matches a template filter, the specificity of the template is
  // Specificity score = 1 + Number of 'And' expressions + 0/1 (if no OR exp. are there then add 0, if > 1 or exp. are there add 1)
  if (specificity === 1 && template.filter?.data) {
    let filterSpecificity = await getSpecificityForTemplateFilter(
      conn,
      template.filter
    )

    return filterSpecificity === -1 ? -1 : 1 + filterSpecificity
  }

  return specificity
}

async function getSpecificityForTemplateFilter(
  conn: NoteSorConnection,
  filter: NoteTemplateFilter
) {
  var andExpressionList =
    filter.data?.[ViewFilterGroupingOperator.AND]?.[0]?.[
      ViewFilterGroupingOperator.AND
    ] || []
  var orExpressionList =
    filter.data?.[ViewFilterGroupingOperator.AND]?.[1]?.[
      ViewFilterGroupingOperator.OR
    ] || []
  var recordDetailsResponse = await CrmRecord.getRecordFields(
    CrmRecord.getCrmRecordType(conn.sor_object_name),
    conn.sor_record_id
  )
  var recordDetails = recordDetailsResponse?.payload
  var matchingAndExpressions = getMatchingExpressions(
    recordDetails,
    andExpressionList
  )
  var matchingOrExpressions = getMatchingExpressions(
    recordDetails,
    orExpressionList
  )

  if (
    matchingAndExpressions.length !== andExpressionList.length ||
    (orExpressionList.length > 0 && matchingOrExpressions.length === 0)
  ) {
    return -1
  }

  return matchingAndExpressions.length + matchingOrExpressions.length
}

function getMatchingExpressions(
  recordDetails: any,
  filterExpression: ViewAPIFilterExpression[]
) {
  var matchingExpressions: ViewAPIFilterExpression[] = []

  filterExpression.forEach((expression) => {
    const recordField = recordDetails?.record?.[expression.field]
    const value = recordField?.value

    switch (expression.original_op) {
      case ViewFilterOperator.EQ:
        if (recordField?.schema.luruFieldType === LuruFieldType.REFERENCE) {
          if (
            expression.value?.sor_record_id &&
            expression.value?.sor_object_name &&
            expression.value?.sor_record_id === value?.sor_record_id &&
            expression.value?.sor_object_name === value?.sor_object_name
          ) {
            matchingExpressions.push(expression)
          }
        } else if (expression.value === value) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.NEQ:
        if (recordField?.schema.luruFieldType === LuruFieldType.REFERENCE) {
          if (
            expression.value?.sor_record_id &&
            expression.value?.sor_object_name &&
            expression.value?.sor_record_id !== value?.sor_record_id &&
            expression.value?.sor_object_name !== value?.sor_object_name
          ) {
            matchingExpressions.push(expression)
          }
        } else if (expression.value !== value) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.BLANK:
        if (recordField?.schema.luruFieldType === LuruFieldType.REFERENCE) {
          if (!value?.sor_record_id) {
            matchingExpressions.push(expression)
          }
        } else if (value === null || value === '') {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.NOT_BLANK:
        if (recordField?.schema.luruFieldType === LuruFieldType.REFERENCE) {
          if (value?.sor_record_id) {
            matchingExpressions.push(expression)
          }
        } else if (value) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.GT:
        if (value > expression.value) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.GTE:
        if (value >= expression.value) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.LT:
        if (value < expression.value) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.LTE:
        if (value <= expression.value) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.IN:
        if (value?.includes?.(expression.value)) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.NOT_IN:
        if (!value?.includes?.(expression.value)) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.STARTS_WITH:
        const re = new RegExp(`^${expression.value}[a-zA-Z]*`)
        if (re.test(value)) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.CONTAINS:
        if (value?.includes(expression.value)) {
          matchingExpressions.push(expression)
        }
        break

      case ViewFilterOperator.CURR_MTH:
      case ViewFilterOperator.PREV_MTH:
      case ViewFilterOperator.NEXT_MTH:
      case ViewFilterOperator.CURR_QTR:
      case ViewFilterOperator.PREV_QTR:
      case ViewFilterOperator.NEXT_QTR:
        let givenValue = moment(value)
        let dateStart, dateEnd

        switch (expression.original_op) {
          case ViewFilterOperator.CURR_MTH:
            dateStart = moment().startOf('month')
            dateEnd = moment().endOf('month')
            break

          case ViewFilterOperator.PREV_MTH:
            dateStart = moment().subtract(1, 'month').startOf('month')
            dateEnd = moment().subtract(1, 'month').endOf('month')
            break

          case ViewFilterOperator.NEXT_MTH:
            dateStart = moment().add(1, 'month').startOf('month')
            dateEnd = moment().add(1, 'month').endOf('month')
            break

          case ViewFilterOperator.CURR_QTR:
            let currQuarterStart = moment()
              .startOf('month')
              .subtract(moment().get('month') % 3, 'month')
            dateStart = currQuarterStart
            dateEnd = moment(currQuarterStart)
              .add(3, 'month')
              .subtract(1, 'day')
            break

          case ViewFilterOperator.PREV_QTR:
            let prevQuarterStart = moment()
              .startOf('month')
              .subtract(moment().get('month') % 3, 'month')
              .subtract(3, 'month')
            dateStart = prevQuarterStart
            dateEnd = moment(prevQuarterStart)
              .add(3, 'month')
              .subtract(1, 'day')
            break

          case ViewFilterOperator.NEXT_QTR:
            let nextQuarterStart = moment()
              .startOf('month')
              .subtract(moment().get('month') % 3, 'month')
              .add(3, 'month')
            dateStart = nextQuarterStart
            dateEnd = moment(nextQuarterStart)
              .add(3, 'month')
              .subtract(1, 'day')
            break
        }

        if (givenValue >= dateStart && givenValue <= dateEnd) {
          matchingExpressions.push(expression)
        }

        break

      default:
        break
    }
  })

  return matchingExpressions
}
