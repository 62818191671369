import { useState } from 'react'
import { CreateActivityLogModalProps, CreateActivityLogModalState } from '.'
import { CrmActivity } from '../../../../features/crm/types'
import { useNote } from '../../../../features/notes/hooks/useNote'

export default function useCreateActivityLogModalComponentState({
  activityLogType,
  sorRecord,
  noteID,
}: CreateActivityLogModalProps) {
  const [state, setState] = useState<CreateActivityLogModalState>({
    show: false,
    activityLogType: activityLogType,
    requiredFields: ['title', 'duration', 'start_time'],
    formValidation: undefined,
  })
  const connectedNote = useNote(noteID)
  const [draftActivity, setDraftActivity] = useState<CrmActivity & { time_picked?: Date; duration?: string }>({
    title: '',
    notes: '',
    start_time: '',
    connections: sorRecord ? [sorRecord] : [],
    attendees: [],
    duration: '30',
  })

  return {
    state: {
      getState: state,
      setState,
    },
    draftActivity: {
      getDraftActivity: draftActivity,
      setDraftActivity,
    },
    connectedNote,
  }
}

export type UseCreateActivityLogModalComponentState = ReturnType<typeof useCreateActivityLogModalComponentState>
