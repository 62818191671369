// Redux
import { createAsyncThunk } from '@reduxjs/toolkit'

// CRM base APIs
import { crmApi } from '../crmBaseAPI'

// CRM middleware parent object
import { abortControllers, abortAllRequests } from '../../crmMiddleware'
import { EntityStatus } from '../../../../app/types'

/**
 * @classdesc - Class to manage a CRM's searchRecords API request
 */
export default class SearchRecords {
  #crmId = null
  #requestKey = null
  #middlewareFactory = null

  /**
   * Constructor for this class.  Sets up local variables.  Request key is
   * used as the thunk name for Redux purposes.  It is also used to namespace
   * abort controllers object in CRM middleware.
   * @param {CRMBaseMiddlewareFactory} middlewareFactory - A middleware factory
   * which is going to use this instance
   */
  constructor(middlewareFactory) {
    this.#crmId = middlewareFactory.getCrmId()
    this.#requestKey = `${this.#crmId}/searchRecords`
    this.#middlewareFactory = middlewareFactory
  }

  /**
   * Function to get the portion of the middleware for managing this particular
   * request.  This will be used by the top-level CRM middleware to compose a
   * large middleware object.
   * @returns {Object} - Middleware to manage this request
   */
  getRequestMiddleware() {
    return {
      key: this.#requestKey,
      action: this.#computeAction(),
      abortAllRequests: this.#computeAbortAllRequests(),
      pending: this.#computePendingState(),
      fulfilled: this.#computeFulfilledState(),
      rejected: this.#computeRejectedState(),
    }
  }

  /**
   * Returns a function to initiate a search in CRM.  The thunk itself expects
   * an object as a parameter with two keys: query and objects, which is an
   * array of object names across which to search
   * @return {Object} - Output of reduxjs/toolkit/createAsyncThunk() function
   */
  #computeAction() {
    let thunk = async (payload, { requestId, getState }) => {
      // Abort a previous signal, if present already
      var prevRequestId = getState().crm.search.prevRequestId

      if (abortControllers[this.#requestKey][prevRequestId]) {
        abortControllers[this.#requestKey][prevRequestId].abort('SELF')
        delete abortControllers[this.#requestKey][prevRequestId]
      }

      var rawResponse = await crmApi.searchRecords(
        this.#crmId,
        payload.query,
        payload.objects,
        requestId
      )

      var normalizedResponse =
        this.#middlewareFactory.massageSearchRecordResponse(rawResponse)

      return normalizedResponse
    }

    thunk = thunk.bind(this)

    return createAsyncThunk(this.#requestKey, thunk)
  }

  /**
   * Compute a function to abort requests for this particular request key
   * @returns {Function} - Function to abort all requests for this request
   */
  #computeAbortAllRequests() {
    let abortFunction = () => abortAllRequests(this.#requestKey)
    abortFunction = abortFunction.bind(this)
    return abortFunction
  }

  /**
   * Compute a pending state handler required by Redux for handling this request
   * @returns {Function} - Pending state handler
   */
  #computePendingState() {
    let pendingStateHandler = (state, action) => {
      const queryPayload = action.meta.arg
      state.search = {
        ...state.search,
        query: queryPayload.query,
        objects: queryPayload.objects,
        status: EntityStatus.Loading,
        error: null,
        requestId: action.meta?.requestId,
        prevRequestId: state.search?.requestId ?? null,
        prevQuery: state.search?.query,
        prevObjects: state.search?.objects,
      }
    }
    pendingStateHandler = pendingStateHandler.bind(this)
    return pendingStateHandler
  }

  /**
   * Compute a fulfilled handler required by Redux for handling this request
   * @returns {Function} - Fulfilled state handler
   */
  #computeFulfilledState() {
    let fulfilledStateHandler = (state, action) => {
      // If searches are aborted because of typeahead search control, action.
      // payload will be undefined; we won't fill search results; we also won't
      // consider this as an error state (and conduct the rejected flow)
      if (action.payload) {
        state.search = {
          ...state.search,
          status: EntityStatus.Loaded,
          results: action.payload, // TODO: Need to add massage payload based on
        }
      }
    }

    fulfilledStateHandler = fulfilledStateHandler.bind(this)

    return fulfilledStateHandler
  }

  /**
   * Compute a rejected handler required by Redux for handling this request
   * @returns {Function} - Rejected state handler
   */
  #computeRejectedState() {
    let rejectedStateHandler = (state, action) => {
      if (!action.meta.aborted) {
        state.search = {
          ...state.search,
          status: EntityStatus.ErrorLoading,
          error: action.error.message,
          results: null,
        }
      }
    }

    rejectedStateHandler = rejectedStateHandler.bind(this)
    return rejectedStateHandler
  }
}
