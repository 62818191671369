import { EntityStatus } from '../../app/types'
import { SorRecord } from '../crm/types'

export const ReduxTasksStateTTL = 60 * 60 * 1_000 // millisecs/hour

export type ReduxTasksState = {
  refreshedAt: string | undefined
  entities: Record<
    string,
    {
      data: null | ReduxTaskEntity
      status: EntityStatus
    }
  >
  assignees: {
    entities: Record<
      string,
      {
        data: TaskAssignee
        status: EntityStatus
      }
    >
  }
}

export interface TaskAssignee extends SorRecord {
  sor_object_name: 'User' | 'user'
}

export type ReduxTaskEntity = {
  assigned_to: TaskAssignee | null
  body: string | null
  connections: Array<TaskConnectionSpec>
  due_date: string | null
  priority: TaskPriority
  sor: string
  sor_task_id: string
  status: TaskStatus
  task_id: string
  title: string
}

export enum TaskPriority {
  Normal = 'NORMAL',
  Low = 'LOW',
  High = 'HIGH',
}

export interface TaskConnectionSpec extends SorRecord {}

export enum TaskStatus {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
}

export const EmptyTasksState: ReduxTasksState = {
  refreshedAt: undefined,
  entities: {},
  assignees: {
    entities: {},
  },
}
