import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { ViewsAPI } from '../api'
import {
  LuruFilterInput,
  ReduxLuruViewEntity,
  ReduxViewsState,
  ViewDisplaySettings,
} from '../types'

export interface UpdateViewParameter {
  view_id: string
  name?: string
  input?: LuruFilterInput
  display_settings?: ViewDisplaySettings | null
}

export interface UpdateViewAPIResponse extends LuruAPIResponse {
  data: ReduxLuruViewEntity
}

export const updateView = {
  action: createAsyncThunk<
    UpdateViewAPIResponse['data'],
    UpdateViewParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: UpdateViewAPIResponse['metadata']
      rejectedMeta: UpdateViewAPIResponse['metadata'] | null
    }
  >(
    'views/updateView',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var view = (await ViewsAPI.updateView(params, {
          signal,
        })) as UpdateViewAPIResponse

        return fulfillWithValue(view.data, view.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(updateView.action.pending, (state, action) => {
      let currEntity = state.entities?.[action.meta.arg.view_id]?.data
      if (
        action.meta.arg.display_settings !== undefined &&
        currEntity !== undefined
      ) {
        currEntity.display_settings = action.meta.arg.display_settings
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(updateView.action.fulfilled, (state, action) => {
      var updatedView = action.payload

      state.entities[updatedView.view_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...updatedView,
          output: {
            ...updatedView.output,
            schema: {
              ...(updatedView.output.schema ??
                state.entities[updatedView.view_id]?.data?.output.schema),
            },
          },
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(updateView.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    updateView.addPendingCase(builder)
    updateView.addFulfilledCase(builder)
    updateView.addRejectedCase(builder)
  },
}
