import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import LuruError from '../../LuruError'
import { ReduxNoteEntity } from '../../notes/types'
import { MeetingsAPI } from '../api'
import { MeetingLocation, MeetingParticipant, ReduxMeetingsState } from '../types'

export interface SearchMeetingsParameter {
  key: string
  q?: string | null
  time_min?: string | null
  time_max?: string | null
  limit?: number | null
  cursor?: string | null
  include_notes?: boolean | null
  sor_object_name?: string
  sor_record_id?: string
  loadMore?: boolean | null
  loadPrev?: boolean | null
}

export interface SearchMeetingsAPIResponseEntity {
  meeting_id: string
  subject: string
  body: null | string
  start_time: string
  end_time: string
  organizer: MeetingParticipant
  participants: Array<MeetingParticipant>
  locations: Array<MeetingLocation>
  notes: Array<ReduxNoteEntity>
}

export interface SearchMeetingsAPIResponse extends LuruAPIResponse {
  data: Array<SearchMeetingsAPIResponseEntity>
}

export const searchMeetings = {
  action: createAsyncThunk<
    SearchMeetingsAPIResponse['data'],
    SearchMeetingsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: SearchMeetingsAPIResponse['metadata'] | null
      rejectedMeta: SearchMeetingsAPIResponse['metadata'] | null
    }
  >('meetings/searchMeetings', async (params, { signal, fulfillWithValue, rejectWithValue, getState }) => {
    var connectedCalendar = LuruUser.getCurrentUserCalendarName(getState())
    var connectedCalendarErrorCode =
      getState()?.user?.data?.userSettings?.connectedCalendar?.errorCode || getState()?.meetings?.error?.error_code

    if (!connectedCalendar) {
      let errorResponse = {
        error_code: 10100,
        message: 'No calendar connection exists',
        description: 'Please connect to your calendar and retry',
      }
      return rejectWithValue(errorResponse, null)
    }

    if (connectedCalendarErrorCode) {
      let errorResponse = {
        error_code: connectedCalendarErrorCode,
        message: 'Error accessing calendar API',
        description: 'Please connect to your calendar and retry',
      }
      return rejectWithValue(errorResponse, null)
    }

    try {
      var meetings = (await MeetingsAPI.searchMeetings(params, {
        signal,
      })) as SearchMeetingsAPIResponse

      return fulfillWithValue(meetings.data, meetings.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? (e as Error), luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(searchMeetings.action.pending, (state, action) => {
      const queryPayload = action.meta.arg,
        callerId = queryPayload.key,
        searchQuery = {
          searchText: queryPayload.q ?? null,
          // We need to format minTime & maxTime as serializable values for Redux
          time_min: queryPayload.time_min ?? null,
          time_max: queryPayload.time_max ?? null,
        }

      // For the very first time after @@INIT, redux may not have state.meetings.
      // search (unless Django initializes it so later).  So create an empty
      // stub now to handle this case anyway
      if (!state.search) {
        state.search = {}
      }

      // Populate state with required details about current request
      state.search = {
        // Retain status of all other callers
        ...state.search,
        [callerId]: {
          // Retain any previous search info for this caller
          ...state?.search?.[callerId],
          query: searchQuery,
          status: EntityStatus.Loading,
          error: null,
          requestId: action.meta.requestId,
          prevRequestId: state.search[callerId]?.requestId ?? null,
          prevQuery: state.search[callerId]?.query ?? null,
        },
      }
      state.error = null
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(searchMeetings.action.fulfilled, (state, action) => {
      const callerId = action.meta.arg.key

      if (!action?.payload) {
        return
      }

      if (!state.entities) {
        state.entities = {}
      }

      // 1.
      // Update the meetings in "store.meetings.entities" since thats the SoT
      // Here `state` points to "store.meetings"
      for (let meeting of action.payload) {
        state.entities[meeting.meeting_id] = {
          data: {
            ...meeting,
            // Store only the note_ids as a part of store.meetings.entities[meeting_id]
            notes: meeting.notes ?? [],
          },
          status: EntityStatus.Loaded,
        }
      }

      // 2.
      // Update "store.meetings.search.<callerID>" with search results
      // TODO: Remove this after moving this to MeetingLinkControl
      state.search = {
        // Retain status of all other callers
        // Since we come here after the pending state function is called (above),
        // we are sure to have these as valid objects and not null/undefined
        ...state.search,
        [callerId]: {
          ...state?.search?.[callerId],
          status: EntityStatus.Loaded,
          results: action.payload.map((m) => ({ ...m, notes: m.notes ?? [] })).slice(),
          requestId: null,
          prevRequestId: null,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(searchMeetings.action.rejected, (state, action) => {
      if (action.meta.aborted) {
        return
      }

      var callerId = action.meta.arg.key
      state.search = {
        ...state.search,
        [callerId]: {
          ...state?.search?.[callerId],
          status: EntityStatus.ErrorLoading,
          error: action.payload as {},
          results: null,
          requestId: null,
          prevRequestId: null,
        },
      }
      state.error = action.payload as any
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    searchMeetings.addPendingCase(builder)
    searchMeetings.addFulfilledCase(builder)
    searchMeetings.addRejectedCase(builder)
  },
}
