import CRMBaseMiddlewareFactory from './api/crmBaseMiddleware'
import { LuruFieldType } from '../../domain/crmRecord/typings.d'
import { EntityStatus } from '../../app/types'
import { Buffer } from 'buffer'
import CrmRecord from '../../domain/crmRecord/CrmRecord'

/**
 * @classdesc Class for SFDC middleware
 */
export default class SFDCMiddlewareFactory extends CRMBaseMiddlewareFactory {
  constructor() {
    super('sfdc')
  }

  //// Actions
  /**
   * Normalize a given SFDC schema
   * @param {Object} schema - Schema as returned by the CRM
   * @param {string} objectName - Object name in CRM
   * @returns {Object} - A normalized schema which has one 'luruFieldType' key
   * added to the base schema (implementation for now)
   */
  normalizeSchema(schema, objectName) {
    schema.fields.forEach((field) => {
      field.isMandatory = field.nillable === false && field.updateable === true && field.defaultedOnCreate === false

      field.luruFieldType = this.#computeLuruFieldType(field.type, field.name, objectName) ?? LuruFieldType.TEXT

      field.isFilterable = field.filterable
      field.isNillable = field.nillable
      field.controllerName = field.controllerName ?? null
      field.nonCreateable = field.createable === false

      field.referencedObject =
        Array.isArray(field.referenceTo) && field.referenceTo.length >= 1 ? field.referenceTo.join(',') : undefined

      if (field.picklistValues.length > 0 && field.controllerName) {
        let controllerFieldSchema = schema.fields.find((f) => f.name === field.controllerName)

        field.picklistValues = field.picklistValues.map((option) => ({
          ...option,
          validFor: this.#filterControllerValues(controllerFieldSchema, option.validFor),
        }))
      }
    })

    return schema
  }

  #filterControllerValues(controllerFieldSchema, mask) {
    const buffer = Buffer.from(mask, 'base64')
    var validChoiceIxList = []

    for (let i = 0; i < buffer.length; i++) {
      validChoiceIxList = validChoiceIxList.concat(
        Array(8)
          .fill(0)
          .map((_, j) => ((buffer[i] >> j) & 1 ? i * 8 + 7 - j : -1))
          .filter((x) => x !== -1)
      )
    }

    var result =
      controllerFieldSchema.picklistValues
        ?.filter((_, ix) => validChoiceIxList.includes(ix))
        .map((option) => option?.value) ?? []

    return result
  }

  // TODO: There will/may be errors in following 2 functions - fix them
  normalizePipelineList(payload) {
    return { ...payload, stages: { status: EntityStatus.Idle } }
  }

  getStageListFromSchema(schema) {
    const field = schema?.fields?.find((field) => field.name === 'StageName')
    return field.picklistValues.map((option) => ({
      ...option,
      name: option.label,
      id: option.value,
    }))
  }

  //// Calculations
  /**
   * Get primary object names for UI display for SFDC
   * @returns {Array} - Array of objects with name and plural version
   */
  getPrimaryObjectNames() {
    var crmPrimaryCrmRecordType = CrmRecord.getAllPrimaryObjects()

    return [
      {
        name: 'Opportunity',
        plural: 'Opportunities',
        singular: 'Opportunity',
        isOpportunity: true,
        crmRecordType: crmPrimaryCrmRecordType.DEAL,
      },
      {
        name: 'Account',
        plural: 'Accounts',
        singular: 'Account',
        crmRecordType: crmPrimaryCrmRecordType.ACCOUNT,
      },
      {
        name: 'Contact',
        plural: 'Contacts',
        singular: 'Contact',
        crmRecordType: crmPrimaryCrmRecordType.CONTACT,
      },
      {
        name: 'Lead',
        plural: 'Leads',
        singular: 'Lead',
        crmRecordType: crmPrimaryCrmRecordType.LEAD,
      },
    ]
  }

  /**
   * Get all object names for UI display for SFDC
   * @returns {Array} - Array of objects with name and plural version
   */
  getAllPrimaryObjectNames() {
    var crmPrimaryCrmRecordType = CrmRecord.getAllPrimaryObjects()

    return [
      {
        name: 'Opportunity',
        plural: 'Opportunities',
        singular: 'Opportunity',
        isOpportunity: true,
        crmRecordType: crmPrimaryCrmRecordType.DEAL,
      },
      {
        name: 'Account',
        plural: 'Accounts',
        singular: 'Account',
        crmRecordType: crmPrimaryCrmRecordType.ACCOUNT,
      },
      {
        name: 'Contact',
        plural: 'Contacts',
        singular: 'Contact',
        crmRecordType: crmPrimaryCrmRecordType.CONTACT,
      },
      {
        name: 'Lead',
        plural: 'Leads',
        singular: 'Lead',
        crmRecordType: crmPrimaryCrmRecordType.LEAD,
      },
      // TODO: Uncomment when SFDC meeting workflows are ready
      // {
      //   name: 'meetings',
      //   plural: 'Meetings',
      //   singular: 'Meeting',
      //   crmRecordType: CrmRecordType.MEETING,
      // },
    ]
  }

  /**
   * Fill a record with schema details and return a composed object
   * @param {Object} record - An SFDC record, this object will be unchanged
   * @param {Object} schema - Schema of the record
   * @returns - Object of type {record, schema}, with record itself of type
   * { value: <SFDC value returned>, schema: <normalized schema> }
   */
  mergeRecordWithSchema(record, schema) {
    let recordResult = {}
    Object.keys(record).forEach((fieldName) => {
      let fieldSchema = schema.fields.find((item) => item.name === fieldName)
      if (fieldSchema) {
        recordResult[fieldName] = {
          value: record[fieldName],
          schema: fieldSchema,
        }
      }
    })
    let ua = record['__user_access'] ?? null
    return { record: recordResult, schema, userAccess: ua }
  }

  /**
   * Compute a standardized field type.  The function may use the object name
   * and field name parameters, if required.  For e.g., we may need to identify
   * a note as a LuruFieldType.NOTE and not as 'textarea', while allowing for
   * the option of identifying 'textarea' as LuruFieldType.LARGETEXT.
   * @param {string} sfdcFieldType - Field type as returned by SFDC
   * @param {string} sfdcFieldName - Field name as returned by SFDC
   * @param {string} sfdcObjectName - Object name in SFDC
   * @returns {string} - Luru normalized field type
   */
  #computeLuruFieldType(sfdcFieldType, sfdcFieldName, sfdcObjectName) {
    const map = {
      date: LuruFieldType.DATE,
      datetime: LuruFieldType.DATETIME,
      int: LuruFieldType.INTEGER,
      double: LuruFieldType.DOUBLE,
      currency: LuruFieldType.CURRENCY,
      percent: LuruFieldType.PERCENTAGE,
      string: LuruFieldType.TEXT,
      boolean: LuruFieldType.BOOLEAN,
      picklist: LuruFieldType.ENUM,
      combobox: LuruFieldType.ENUM,
      multipicklist: LuruFieldType.MULTIENUM,
      reference: LuruFieldType.REFERENCE,
      textarea: LuruFieldType.LARGETEXT,
      address: LuruFieldType.ADDRESS,
      phone: LuruFieldType.TELEPHONE,
      email: LuruFieldType.EMAIL,
      url: LuruFieldType.URL,
    }

    // Some overrides
    if (sfdcFieldName === 'FiscalYear') {
      return LuruFieldType.INTEGER_NOFORMAT
    }
    if (sfdcFieldName === 'Subject') {
      return LuruFieldType.TEXT
    }

    return sfdcFieldType in map ? map[sfdcFieldType] : null
  }

  massageSearchRecordResponse(payload) {
    return payload
  }
}
