import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse, LuruEntryPoint } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import Utils from '../../../utils/Utils'
import LuruError from '../../LuruError'
import { UserAPI } from '../api'
import { EmailProvider, ReduxUserState } from '../types'

export interface DisconnectO365MailRequestParams {
  csrfToken?: string
}

export interface DisconnectO365MailResponse extends LuruAPIResponse {}

export const disconnectO365Mail = {
  action: createAsyncThunk<
    DisconnectO365MailResponse['data'],
    DisconnectO365MailRequestParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | DisconnectO365MailResponse['metadata']
      rejectedMeta: null | DisconnectO365MailResponse['metadata']
    }
  >(
    'user/disconnectO365Mail',
    async (params: DisconnectO365MailRequestParams, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      var csrfToken = params.csrfToken

      if (!csrfToken) {
        csrfToken = Utils.getCookieValue('csrftoken') || ''
        var entryPoint = LuruUser.getCurrentEntryPoint()

        if (
          [
            LuruEntryPoint.NEWTAB,
            LuruEntryPoint.GLOBALSEARCH,
            LuruEntryPoint.EMBEDDED_GMEET_NOTE,
            LuruEntryPoint.OPTIONS,
          ].includes(entryPoint)
        ) {
          // Inside Extension, so get csrf token from store
          csrfToken = getState()?.user?.extensionSettings?.csrfTokenCookieValue || ''
        }
      }

      try {
        var response = (await UserAPI.disconnectO365Mail({ csrfToken }, { signal })) as DisconnectO365MailResponse
        return fulfillWithValue(response.data, null)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    }
  ),

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(disconnectO365Mail.action.fulfilled, (state, action) => {
      if (state?.data?.userSettings?.connections && Array.isArray(state?.data?.userSettings?.connections)) {
        state.data.userSettings.connections = state.data.userSettings.connections?.filter?.(
          (conn) => conn.name !== EmailProvider.O365MAIL
        )
      }

      if (state?.data?.userSettings?.connectedEmail) {
        state.data.userSettings.connectedEmail = null
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUserState>) {
    disconnectO365Mail.addFulfilledCase(builder)
  },
}
