// React
import { useContext, useEffect, useRef } from 'react'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { NotesSliceSelectors } from '../features/notes/selectors'
import { NotesMiddleware } from '../features/notes/middleware'
import { clearSearch } from '../features/crm/crmSlice'
import { crmMiddleware } from '../features/crm/crmMiddleware'

// Own components
import ModalScreen from './ModalScreen'
import LuruFloatingActionButton from '../primitives/LuruFloatingActionButton'

// Own libraries
import { scrollYToElement } from '../domutils/utils'

// Custom hooks
import { useGetSetState, useThreeWayClickHandler } from '../hooks/luru_hooks'

// Own components
import RecordLinkStatusButton from './RecordLinkStatusButton'
import TypeAheadSearchBox from './TypeAheadSearchBox'

// Styles
import styles from './css/RecordLinkControl.module.css'
import crmStyles from './css/CRMRecordLinkControl.module.css'
import crmIconStyles from '../primitives/css/CRMIcons.module.css'

// Assets
import { crmIcons } from './CRMIcons'
import { EditorMessage, EditorMessageEvent } from '../coreEditor/EditorController'
import { ThreeWayAnswer } from '../utils/Utils'
import LuruButton from './ui/LuruButton'
import { trackEvent } from '../analytics/Ga'
import { NoteCrmConnectionChangeType } from '../coreEditor/types'
import { NotesSliceHelpers } from '../features/notes/helpers'
import LuruUser from '../domain/users/LuruUser'
import { EntityStatus } from '../app/types'

//Icons
import syncIcon from '../images/fluent/sync.svg'
import unsyncIcon from '../images/fluent/sync_disable.svg'
import sparkleIcon from '../images/fluent/sparkle.svg'
import LuruSelectBox from './ui/LuruSelectBox'
import CrmRecord from '../domain/crmRecord/CrmRecord'
import plusIcon from '../images/fluent/add.svg'
import AppComponentsContext from './AppComponents/AppComponentsContext'
import { StringUtils } from '@/utils/stringUtils'
import { useCrmCommonName } from '@/features/crm/hooks/useCrmName'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export default function CRMRecordLinkControl(props) {
  //// Component props and derived props like values
  const callerId = 'home/CRMLinkControl'
  const crmProvider = useCrmCommonName()
  const { showToast } = useLuruToast()

  const MenuMode = {
    SEARCH: 'search',
    SELECT: 'select',
  }
  Object.freeze(MenuMode)

  //// AppContext
  var appContext = useContext(AppComponentsContext)

  //// Component state
  let state = {
    linkContext: useGetSetState(null),
    search: useSelector((state) => state.crm.search),
    suggestedResults: useSelector(NotesSliceSelectors.getSuggestedRecordNamesForRecordLink),
    syncInProgress: useGetSetState(false),
    crmId: useSelector((state) => state.user?.data?.userSettings?.connectedCRM?.name),
    searchText: useGetSetState(''),
    mode: useGetSetState(MenuMode.SEARCH),
    selectedIndex: useGetSetState(-1),
    defaultRecords: [],
    noteUpdatedTS: useSelector(NotesSliceSelectors.getNoteUpdatedTime(props.noteId)),
    autoLinkMessage: useGetSetState(0),
    autoLinkedRecord: useGetSetState(false),
    lastSearchQuery: useGetSetState(''),
    connectionChangeAlertType: useGetSetState(NoteCrmConnectionChangeType.DELETE_CONNECTION),
  }

  state.isNoteSyncRequired = useSelector(NotesSliceSelectors.isNoteSyncRequired(props.noteId, state.crmId))

  const noteConnectionChangeMessages = {
    [NoteCrmConnectionChangeType.DELETE_CONNECTION]: {
      title: `Remove connected record`,
      message: (
        <>
          <p>You are about to remove this note's connection.</p>
          <p>
            This will delete {CrmRecord.getCrmName()} fields from this note and disconnect tasks in this note from the
            record.
          </p>
          <p>
            This will also delete this note from your {CrmRecord.getCrmName()}. To sync this note back to your{' '}
            {CrmRecord.getCrmName()}, re-connect to a {CrmRecord.getCrmName()} record.
          </p>
          <p>Do you want to continue?</p>
        </>
      ),
    },
    [NoteCrmConnectionChangeType.REPLACE_SAME_TYPE]: {
      title: `Replace connected record`,
      message: (
        <>
          <p>You are about to replace this note's connection.</p>
          <p>
            This will replace this note's {CrmRecord.getCrmName()} field values with new record values and connect tasks
            in this note to the new record.
          </p>
          <p>Do you want to continue?</p>
        </>
      ),
    },
    [NoteCrmConnectionChangeType.REPLACE_OTHER_TYPE]: {
      title: `Replace connected record`,
      message: (
        <>
          <p>You are about to replace this note's connection.</p>
          <p>
            This will remove this note's {CrmRecord.getCrmName()} field values and connect tasks in this note to the new
            record.
          </p>
          <p>Do you want to continue?</p>
        </>
      ),
    },
  }

  const initialFilterState = {}
  const primaryObjects = crmMiddleware[state.crmId.toLowerCase()].getPrimaryObjectNames()
  primaryObjects.forEach((object) => (initialFilterState[object.name] = false))

  state.recordFilters = useGetSetState(initialFilterState)
  state.linkedCRMRecord = useSelector(NotesSliceSelectors.getSORConnection(props.noteId, state.crmId, callerId))
  state.displayedRecords = useGetSetState(state.search ? state.search.results : [])

  const dispatch = useDispatch()

  const refs = {
    changeNoteCrmConnectionModal: useRef(null),
    popup: useRef(null),
    control: useRef(null),
    filters: useRef(null),
    searchBox: useRef(null),
    actionIcon: useRef(null),
    syncButton: useRef(null),
    searchResults: useRef(null),
    createRecordButton: useRef(null),
    orRef: useRef(null),
    searchBoxDiv: useRef(null),
  }

  //// Component views
  function render() {
    const syncEnabled = state.isNoteSyncRequired === ThreeWayAnswer.YES

    let floatingSyncButton = state.linkedCRMRecord?.connection ? (
      // Not removing element, but hiding it, because this control has event
      // listeners for messaging among editor classes
      <div
        ref={refs.syncButton}
        id={`noteSyncButton-${props.noteId.slice(0, 7)}`}
        className='hidden'
        data-luru-role='floating-sync-button'
        style={{ display: 'none' }}
      >
        <LuruFloatingActionButton
          title={`Sync this note with ${CrmRecord.getCrmName()}`}
          loading={state.syncInProgress.get() === true}
          loadingSize={10}
          onClick={(e) => handleSyncNote(e)}
          disabled={!syncEnabled}
        >
          <img src={syncIcon} alt='sync' />
          <label>Sync</label>
        </LuruFloatingActionButton>
      </div>
    ) : null

    return (
      <div className={styles.parent} ref={refs.control}>
        <div className={crmStyles.recordLinkStatusContainer}>
          {state.autoLinkMessage.get() > 1 ? (
            <div id='crm-auto-link-message' className={crmStyles.autoLinkMessage}>
              <span>
                <img src={sparkleIcon} alt='sparkle' />
              </span>
              &nbsp; Auto-linked to {StringUtils.toTitleCase(LuruUser.getCurrentUserCrmName()) ?? 'CRM'}, click to
              change
            </div>
          ) : state.autoLinkMessage.get() === 1 ? (
            <div id='crm-auto-link-message' className={crmStyles.autoLinkMessage}>
              <span>
                <img src={sparkleIcon} alt='sparkle' />
              </span>
              &nbsp; Auto-linked to {StringUtils.toTitleCase(LuruUser.getCurrentUserCrmName()) ?? 'CRM'}
            </div>
          ) : (
            <div id='crm-auto-link-message' className={crmStyles.autoLinkMessage} />
          )}

          <RecordLinkStatusButton
            embedded={props.embedded}
            key='crm'
            link={state.linkedCRMRecord}
            linkType='crm'
            ref={refs.actionIcon}
          />
        </div>
        <ModalScreen
          title={noteConnectionChangeMessages[state.connectionChangeAlertType.get()].title}
          ref={refs.changeNoteCrmConnectionModal}
        >
          <div className={crmStyles.modalMessage}>
            {noteConnectionChangeMessages[state.connectionChangeAlertType.get()].message}
          </div>
        </ModalScreen>
        {/* {syncButton} */}
        {floatingSyncButton}
        <div className={styles.popup} ref={refs.popup} id={`crm-link-popup-${props.noteId?.slice(0, 7)}`}>
          <header>
            <div className={styles.searchBoxWidth} ref={refs.searchBoxDiv}>
              <TypeAheadSearchBox
                placeholder={
                  state.linkContext.get()?.userPromptMessage ?? `Search for ${CrmRecord.getCrmName()} record to link`
                }
                ariaLabel={`Search for ${CrmRecord.getCrmName()} record to link with note`}
                searchText={state.searchText.get()}
                onKeyDown={eventHandlers.onSearchBoxKeyDown}
                onTriggerSearch={eventHandlers.onTriggerSearch}
                onClearSearch={eventHandlers.onClearSearch}
                ref={refs.searchBox}
                loading={state.search?.status === EntityStatus.Loading}
                onFocus={onFocus}
              />
            </div>
            <p ref={refs.orRef}>or</p>
            <div ref={refs.createRecordButton} className={styles.createButtonRender}>
              {renderCreateRecordButton()}
            </div>
          </header>
          {/*<ProgressBar inProgress={state.search?.status === "loading"} />*/}
          <ul
            className={styles.hideFilters}
            ref={refs.filters}
            onClick={eventHandlers.onRecordFilterChange}
            style={props.embedded ? { marginTop: '0.5em' } : {}}
          >
            {primaryObjects.map((object) => (
              <li
                key={object.name}
                data-filter={object.name}
                className={state.recordFilters.get()?.[object.name] ? crmStyles.selected : ''}
              >
                {object.plural}
              </li>
            ))}
          </ul>
          <label className={styles.label}>{state.searchText.get().length === 0 ? 'Recent' : null}</label>
          <div className={styles.results}>{renderRecordsListArea()}</div>
          <footer>
            <div>Esc: Cancel</div>
            <div>
              {'\u2191'} or {'\u2193'} : Navigate
            </div>
          </footer>
        </div>
      </div>
    )
  }

  function onFocus(e) {
    e?.preventDefault()
    e?.stopPropagation()
    refs.createRecordButton.current?.classList?.add?.(styles.hideButtonRecord)
    refs.orRef.current?.classList?.add?.(styles.hideOrRecord)
    refs.popup.current?.classList?.add?.(styles.focusedInput)
    refs.searchBoxDiv.current?.classList?.add?.(styles.searchBoxWidthIncrease)
    refs.filters.current?.classList?.add?.(crmStyles.filterList)
  }

  function renderRecordsListArea() {
    // Decide what to display based on search status
    switch (state.search?.status) {
      case EntityStatus.Loading:
        return <div className={styles.noresults}>Searching for records...</div>

      case EntityStatus.ErrorLoading:
        return (
          <div className={styles.error}>
            <div>Error: {state.search.error}</div>
            <LuruButton title='Retry' onClick={retryLastSearch} extraClassNames={[styles.retryButton]}>
              Retry
            </LuruButton>
          </div>
        )

      default:
        return renderRecordsList()
    }
  }

  function renderCreateRecordButton() {
    var crmId = LuruUser.getCurrentUserCrmName()

    function handlChooseItem(s) {
      appContext.createRecordDialog[s?.toLowerCase()]?.current?.showDialog?.(linkRecordAfterCreation, props?.noteId)
    }

    return (
      <LuruSelectBox
        items={CrmRecord.getPrimaryCrmObjects().map((obj) => ({
          name: `Create ${obj.singular}`,
          key: obj.crmRecordType,
          icon: (
            <img
              className={crmIconStyles.icon}
              data-luru-icon-name={`${crmId?.toLowerCase?.()}-${obj?.name?.toLowerCase?.()}`}
              src={CrmRecord.getIcon(obj.crmRecordType)}
              alt={obj.plural}
            />
          ),
        }))}
        onChooseItem={handlChooseItem}
        leftAlign={true}
        classes={[styles.createRecordSelect]}
        selectLabel='Create a Record'
        iconPopup={plusIcon}
        dontShowChoosenItemOnChoose={true}
        menuParentSelector='[id^="crm-link-popup"]'
        menuWidth={200}
      />
    )
  }

  async function linkRecordAfterCreation(response) {
    let sorRecordName =
      response?.payload?.data?.__record_name ??
      response?.meta?.arg?.fields?.Name?.fieldValue ??
      response?.meta?.arg?.fields?.title?.fieldValue ??
      response?.meta?.arg?.fields?.LastName?.fieldValue ?? // this is for lead in SFDC
      response?.meta?.arg?.fields?.name?.fieldValue // this is for orgonization in PipeDrive
    let sorRecordId = response?.payload?.data?.id ?? response?.payload?.data?.data?.id
    let sorObjectName = response?.payload?.data?.__object_name ?? response?.meta?.arg?.sorObjectName
    var connectionChangeType = state.linkedCRMRecord?.connection
      ? sorObjectName === state.linkedCRMRecord?.connection?.sor_object_name
        ? NoteCrmConnectionChangeType.REPLACE_SAME_TYPE
        : NoteCrmConnectionChangeType.REPLACE_OTHER_TYPE
      : NoteCrmConnectionChangeType.NEW_CONNECTION

    if (state.linkedCRMRecord?.connection) {
      state.connectionChangeAlertType.set(connectionChangeType)
      refs.changeNoteCrmConnectionModal.current?.showModal({
        ok: executeCreateConnectionRequest,
      })
    } else {
      await executeCreateConnectionRequest()
    }

    async function executeCreateConnectionRequest() {
      // Show loading message
      showToast({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: `Linking ${sorRecordName} to note`,
        isLoading: true,
      })
      // Google Analytics
      trackEvent('link_note', LuruUser.getCurrentUserCrmName() + '/' + sorRecordName)

      try {
        // Send a link record request
        var sorObject = {
          sor: state.crmId,
          sor_record_id: sorRecordId,
          sor_record_name: sorRecordName,
          sor_object_name: sorObjectName,
        }
        var createConnectionPayload = {
          key: callerId,
          noteId: props.noteId,
          sorObject,
        }
        var response = await dispatch(NotesMiddleware.createNoteConnection.action(createConnectionPayload))

        if (response.error) {
          throw response.error
        }

        // Show success notification
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Linked successfully',
          severity: 'success',
        })
        // Hide link record menu
        hideMenu()

        if (state.linkContext.get() === null) {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Successfully linked note',
            severity: 'success',
          })
          // Inform editor to show applicable templates (we do this only when
          // CRM record linking is from a non-editor menu context; in editor
          // menu context, record linking logic would have been called to
          // insert a CRM field inside note)
          await informEditor.updateCrmConnectionAndShowTemplates(props.embedded, false, connectionChangeType)
          return
        } else {
          // Inform editor about CRM record being linked (editor will fetch
          // latest CRM record details)
          await informEditor.crmRecordLinked(connectionChangeType)
        }

        // Send a signal to the calling component by executing the callback
        // configured
        const linkContext = state.linkContext.get()

        linkContext?.onLinkRecord({
          rangeContainer: linkContext?.rangeContainer,
          rangeOffset: linkContext?.rangeOffset,
          sorObjectName: sorObjectName,
          sorRecordId: sorRecordId,
        })

        state.linkContext.set(null)
      } catch (error) {
        if (error?.message !== 'Rejected') {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: error.message,
            severity: 'error',
          })
        } else {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Not able to create connection',
            severity: 'error',
          })
        }
      }
    }
  }

  function renderRecordsList() {
    let records = state.displayedRecords.get()

    if (state.searchText.get().length === 0 && (records == null || records?.length === 0)) {
      records = state.suggestedResults
    }

    if ((!records || records?.length === 0) && state.searchText?.get()?.length === 0) {
      return <div className={styles.noresults}>Search {crmProvider} for a record, or create one</div>
    }

    if (!records || records?.length === 0) {
      return (
        <div className={styles.noresults}>
          <p>No records, please try with another search term</p>
          <b>or</b>
          <div className={styles.createButtonRender}>{renderCreateRecordButton()}</div>
        </div>
      )
    }
    // `records` contains CRM records that the user has edit access to + records
    // that the user does not have edit access to. We sort the list to make
    // sure that the records that the user has edit access to, appear first
    records = records.slice().sort(function (a, b) {
      if (a?.user_access?.can_edit === true) {
        return -1
      } else if (b?.user_access?.can_edit === true) {
        return 1
      }
      return 0
    })

    return (
      <ul className={styles.loaded} ref={refs.searchResults}>
        {records?.map((item, index) => (
          <li
            key={`result-${index}`}
            onClick={onCreateNoteCrmConnection}
            className={
              item?.sor_record_id === state.linkedCRMRecord?.connection?.sor_record_id ||
              index === state.selectedIndex.get()
                ? styles.selected
                : ''
            }
            data-sor-result={true}
            data-sor-record-id={item.sor_record_id}
            data-sor-record-name={item.sor_record_name}
            data-sor-object-name={item.sor_object_name}
            data-result-index={index}
            data-user-can-edit={item.user_access?.can_edit}
          >
            <img
              src={crmIcons[state.crmId.toLowerCase()][item.sor_object_name.toLowerCase()]}
              className={crmStyles.icon}
              data-luru-icon-name={[state.crmId, item.sor_object_name].join('-').toLowerCase()}
              alt={item.sor_object_name}
            />
            <div>{item.sor_record_name?.trim?.() || item.data?.email?.trim?.() || 'No name'}</div>
            {item.user_access?.can_edit === false ? (
              <div class={crmStyles.unselectableIcon}>
                <img src={unsyncIcon} alt='unsycn' />
              </div>
            ) : (
              ''
            )}
          </li>
        ))}
      </ul>
    )
  }

  //// Component commands: These are the key functions of the controller
  // These functions control the model and/or view
  // Hide menu and execute all side-effects
  function hideMenu() {
    if (refs.popup.current?.classList.contains(styles.visible)) {
      // Redux reset
      crmMiddleware[state.crmId.toLowerCase()].searchRecords.abortAllRequests()
      dispatch(clearSearch())

      // Own component state reset
      clearRecordFilters()
      state.searchText.set('')
      if (state.autoLinkedRecord.get() === false) {
        state.displayedRecords.set(state.defaultRecords)
      }
      state.selectedIndex.set(-1)
      refs.searchBox.current.dispatchEvent(new CustomEvent('clear'))

      // Own component style reset
      refs.popup.current.classList.remove(styles.visible)

      // Show the default record create button
      refs.createRecordButton.current?.classList?.remove?.(styles.hideButtonRecord)
      refs.orRef.current?.classList?.remove?.(styles.hideOrRecord)
      refs.popup.current?.classList?.remove?.(styles.focusedInput)
      refs.searchBoxDiv.current?.classList?.remove?.(styles.searchBoxWidthIncrease)
      refs.filters.current?.classList?.remove?.(crmStyles.filterList)

      if (state.linkContext.get() !== null) {
        const linkContext = state.linkContext.get()
        linkContext.onLinkRecord({
          rangeContainer: linkContext.rangeContainer,
          rangeOffset: linkContext.rangeOffset,
        })
        state.linkContext.set(null)
      }
    }
  }

  // Toggle menu
  /**
   * Show or hide menu
   * @param {Object} linkContext - Optional link context, if toggle menu is
   * called on an event raised from CRMFieldChooser
   */
  function toggleMenu(linkContext = null) {
    if (refs.popup.current.classList.contains(styles.visible)) {
      // Hide menu and execute all side effects
      hideMenu()
    } else {
      // If there is link context, position link record control as fixed
      // rather than absolute (which will look like a popup)
      if (linkContext) {
        refs.popup.current.classList.remove(styles.buttonPopup)
        refs.popup.current.classList.add(styles.modalPopup)
      } else {
        refs.popup.current.classList.remove(styles.modalPopup)
        refs.popup.current.classList.add(styles.buttonPopup)
      }
      // Add style to make popup visible
      refs.popup.current.classList.add(styles.visible)
      // Set default records as records list
      if (state.autoLinkedRecord.get() === false) {
        state.displayedRecords.set(state.defaultRecords)
      }
      // Hide auto-link message
      state.autoLinkMessage.set(0)
      // Remove selected class from each item
      state.selectedIndex.set(-1)
      // Set focus on search box
      // setTimeout(() => refs.searchBox?.current?.focus?.(), 100)
    }
  }

  // Handler for sync note
  function handleSyncNote(e) {
    // Step 1: Some prep-work
    e.preventDefault()
    e.stopPropagation()
    state.syncInProgress.set(true)

    // Step 2: (Execution step#3): Prepare what to do after saving note
    // Sync note to be done after saving note.  How to sync note is enclosed in
    // a callback, that will be passed to save note, which will execute this
    // callback upon successful save
    const syncNoteCallback = () => {
      dispatch(
        NotesMiddleware.syncNote.action({
          noteId: props.noteId,
          crmId: state.crmId,
        })
      )
        .then(() =>
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: `Synced note with ${CrmRecord.getCrmName()}`,
            severity: 'success',
          })
        )
        .catch((error) => {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message:
              error.message ?? `Cannot sync note with ${CrmRecord.getCrmName()}.  Please check your access rights.`,
            severity: 'error',
          })
          console.error(error)
        })
        .finally(() => state.syncInProgress.set(false))
    }

    // Step 3: (Exection step#2): Prepare payload to save note
    // Prepare payload to send inside the message event to editor.  Payload to
    // contain the callback defined above
    const editorMessagePayload = {
      message: EditorMessage.SaveNote,
      data: {
        noteId: props.noteId,
        callback: syncNoteCallback,
      },
    }

    // Step 4: (Execution step#1): Save note (before syncing)
    // Pass on instructions on what to do after saving (through payload prepped)
    // Send message to editor to save note, and then call sync callback
    document.getElementById(`editor-${props.noteId.slice(0, 7)}`)?.dispatchEvent(
      new CustomEvent(EditorMessageEvent, {
        detail: editorMessagePayload,
      })
    )
  }

  // Handle choosing of an SOR from search results
  async function onCreateNoteCrmConnection(e) {
    e.preventDefault()

    var resultElem = e.target.closest('li')
    var resultRecord = resultElem.dataset

    if (!resultRecord?.sorRecordId) {
      return
    }

    // Show alert if attempt to link note with same connection
    if (resultRecord?.sorRecordId === state?.linkedCRMRecord?.connection?.sor_record_id) {
      showToast({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: `Note already linked to ${resultRecord?.sorObjectName ?? 'record'}`,
        severity: 'error',
      })
      return
    }

    // Show alert if attempt to link note with non-editable record
    if (resultRecord.userCanEdit === 'false') {
      showToast({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: `Can't link to this record, since you don't seem to have edit access to '${resultRecord.sorRecordName}' in ${state.crmId}`,
        severity: 'error',
      })
      return
    }

    var connectionChangeType = state.linkedCRMRecord?.connection
      ? resultRecord.sorObjectName === state.linkedCRMRecord?.connection?.sor_object_name
        ? NoteCrmConnectionChangeType.REPLACE_SAME_TYPE
        : NoteCrmConnectionChangeType.REPLACE_OTHER_TYPE
      : NoteCrmConnectionChangeType.NEW_CONNECTION

    if (state.linkedCRMRecord?.connection) {
      state.connectionChangeAlertType.set(connectionChangeType)
      refs.changeNoteCrmConnectionModal.current?.showModal({
        ok: executeCreateConnectionRequest,
      })
    } else {
      await executeCreateConnectionRequest()
    }

    async function executeCreateConnectionRequest() {
      // Show loading message
      showToast({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: `Linking ${resultRecord.sorRecordName} to note`,
        isLoading: true,
      })
      // Google Analytics
      trackEvent('link_note', LuruUser.getCurrentUserCrmName() + '/' + resultRecord.sorObjectName)

      try {
        // Send a link record request
        var sorObject = {
          sor: state.crmId,
          sor_record_id: resultRecord.sorRecordId,
          sor_record_name: resultRecord.sorRecordName,
          sor_object_name: resultRecord.sorObjectName,
        }
        var createConnectionPayload = {
          key: callerId,
          noteId: props.noteId,
          sorObject,
        }
        var response = await dispatch(NotesMiddleware.createNoteConnection.action(createConnectionPayload))

        if (response.error) {
          throw response.error
        }

        // Show success notification
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Linked successfully',
          severity: 'success',
        })
        // Hide link record menu
        hideMenu()

        if (state.linkContext.get() === null) {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Successfully linked note',
            severity: 'success',
          })
          // Inform editor to show applicable templates (we do this only when
          // CRM record linking is from a non-editor menu context; in editor
          // menu context, record linking logic would have been called to
          // insert a CRM field inside note)
          await informEditor.updateCrmConnectionAndShowTemplates(props.embedded, false, connectionChangeType)
          return
        } else {
          // Inform editor about CRM record being linked (editor will fetch
          // latest CRM record details)
          await informEditor.crmRecordLinked(connectionChangeType)
        }

        // Send a signal to the calling component by executing the callback
        // configured
        const linkContext = state.linkContext.get()

        linkContext?.onLinkRecord({
          rangeContainer: linkContext?.rangeContainer,
          rangeOffset: linkContext?.rangeOffset,
          sorObjectName: resultRecord.sorObjectName,
          sorRecordId: resultRecord.sorRecordId,
        })

        state.linkContext.set(null)
      } catch (error) {
        if (error?.message !== 'Rejected') {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: error.message,
            severity: 'error',
          })
        } else {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Not able to create connection',
            severity: 'error',
          })
        }
        // Hide link record menu
        hideMenu()
      }
    }
  }

  function retryLastSearch(e) {
    e.preventDefault()
    e.stopPropagation()
    triggerSearch(state.lastSearchQuery.get())
  }

  // Trigger an asynchronous search operation
  function triggerSearch(query) {
    state.autoLinkMessage.set(0)
    state.autoLinkedRecord.set(false)

    var queryText = query?.searchText ?? state.searchText.get()
    var filters = query.recordFilters ?? state.recordFilters.get()
    var filtersArray = Object.keys(filters).filter((name) => filters[name])

    if (!filtersArray.length) {
      filtersArray = Object.keys(filters)
    }

    var middleware = crmMiddleware[state.crmId.toLowerCase()]
    var searchAction = middleware.searchRecords.action
    var searchPayload = {
      query: queryText,
      objects: filtersArray,
    }

    state.lastSearchQuery.set(queryText)
    dispatch(searchAction(searchPayload))
      .then((response) => {
        state.displayedRecords.set(response.payload)
        state.selectedIndex.set(-1)
        // Set focus on search box
        setTimeout(() => refs.searchBox?.current?.focus?.())
      })
      .catch((error) => {
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Record search error:' + error.message,
          severity: 'error',
        })
      })
  }

  function clearRecordFilters() {
    state.recordFilters.set(initialFilterState)
  }

  const informEditor = {
    updateCrmConnectionAndShowTemplates: async (
      isEmbedded = false,
      isAutoLinked = false,
      connectionChangeType = NoteCrmConnectionChangeType.NEW_CONNECTION
    ) => {
      var crmConnections = (await NotesSliceHelpers.getCrmConnections(props.noteId)) ?? []

      // setting up status while getting matching templates
      props.popupRefs?.current?.setTemplateStatus?.(EntityStatus.Loading)

      var applicableTemplates = await NotesSliceHelpers.getMatchingNoteTemplates(props.noteId)

      props.popupRefs?.current?.setTemplateStatus?.(EntityStatus.Loaded)

      if (crmConnections.length > 0) {
        let crmConnection = crmConnections[0]
        let editorMessagePayload = {
          message: EditorMessage.UpdateCRMConnectionAndShowTemplates,
          data: {
            connection: crmConnection,
            isNoteNonDraft: NotesSliceHelpers.isNoteDraft(props.noteId) === false,
            templates: applicableTemplates,
            isEmbedded,
            isAutoLinked,
            connectionChangeType,
          },
        }
        let intervalId = setInterval(() => {
          var editorId = `editor-${props.noteId.slice(0, 7)}`
          var editorElement = document.getElementById(editorId)

          if (editorElement?.getAttribute('data-message-listener-setup') === 'true') {
            editorElement?.dispatchEvent(
              new CustomEvent(EditorMessageEvent, {
                detail: editorMessagePayload,
              })
            )
            clearInterval(intervalId)
          }
        }, 200)
      }
    },

    crmRecordLinked: async (connectionChangeType) => {
      var editorId = `editor-${props.noteId.slice(0, 7)}`
      var editorElement = document.getElementById(editorId)
      var crmConnections = (await NotesSliceHelpers.getCrmConnections(props.noteId)) ?? []

      if (crmConnections.length > 0) {
        let crmConnection = crmConnections[0]
        let editorMessagePayload = {
          message: EditorMessage.CRMRecordLinked,
          data: {
            connection: crmConnection,
            isNoteNonDraft: NotesSliceHelpers.isNoteDraft(props.noteId) === false,
            connectionChangeType,
          },
        }

        editorElement?.dispatchEvent(
          new CustomEvent(EditorMessageEvent, {
            detail: editorMessagePayload,
          })
        )
      }
    },
  }

  //// Component event handlers (these are children-facing event handlers)
  const eventHandlers = {
    // Handler for removing link
    onDeleteNoteCrmConnection: (e) => {
      state.connectionChangeAlertType.set(NoteCrmConnectionChangeType.DELETE_CONNECTION)
      refs.changeNoteCrmConnectionModal.current.showModal({
        ok: () => {
          const connectionId = state.linkedCRMRecord?.connection?.connection_id
          const sorRecordId = state.linkedCRMRecord?.connection?.sor_record_id
          const noteId = props.noteId
          trackEvent(
            'delete_note_connection',
            LuruUser.getCurrentUserCrmName(),
            state.linkedCRMRecord?.connection?.sor_object_name
          )
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Deleting note connection',
            isLoading: true,
          })
          dispatch(
            NotesMiddleware.deleteNoteConnection.action({
              key: callerId,
              noteId,
              connectionId,
              sorRecordId,
            })
          )
            .then((response) => {
              // Inform editor that note has been unlinked
              let editorId = `editor-${props.noteId.slice(0, 7)}`
              document.getElementById(editorId).dispatchEvent(
                new CustomEvent(EditorMessageEvent, {
                  detail: {
                    message: EditorMessage.CRMRecordUnlinked,
                    data: { noteId, connectionId },
                  },
                })
              )

              // Show message to user
              showToast({
                id: ToastId.NOTES_EDITOR_TOAST_ID,
                message: 'Deleted note connection',
                severity: 'success',
              })
            })
            .catch((error) => {
              console.warn(error)
              showToast({
                id: ToastId.NOTES_EDITOR_TOAST_ID,
                message: 'Error deleting note connection',
                severity: 'error',
              })
            })
        },
      })
    },
    // Handler for trigger search event from type ahead search box component
    onTriggerSearch: (e) => {
      if (e.key === 'Enter' && state.mode.get() === MenuMode.SELECT) {
        // Choose the meeting here based on current selection
        onCreateNoteCrmConnection({
          target: refs.popup.current.querySelector(`li[data-result-index="${state.selectedIndex.get()}"]`),
          preventDefault: e.preventDefault,
        })
      } else {
        const queryText = e.data.searchText
        if (queryText?.trim?.() === '') {
          state.searchText.set(queryText)
          state.mode.set(MenuMode.SEARCH)
          // TODO: Implement callerId-wise API call in CRM Middleware
          dispatch(clearSearch(callerId))
          state.displayedRecords.set(state.defaultRecords)
        } else if (queryText?.trim?.()?.length > 1) {
          state.searchText.set(queryText)
          state.mode.set(MenuMode.SEARCH)
          triggerSearch({ searchText: queryText })
        }
      }
    },

    // Handler for keydown event from type ahead search box component
    onClearSearch: (e) => {
      if (e.key && e.key === 'Escape') {
        hideMenu()
      } else {
        // TODO: Implement callerId-wise API call in CRM Middleware
        dispatch(clearSearch(callerId))
        state.displayedRecords.set(state.defaultRecords)
        state.selectedIndex.set(-1)
        state.searchText.set('')
      }
    },

    // Handler for change filter event from week browser component
    onRecordFilterChange: (e) => {
      // let records = state.search ? state.search.results : state.defaultRecords;
      if (e.target?.dataset?.filter) {
        let chosenFilter = e.target.dataset.filter
        let currentFilters = state.recordFilters.get()
        let newFilters = {
          ...currentFilters,
          [chosenFilter]: !currentFilters[chosenFilter],
        }
        state.recordFilters.set(newFilters)
        if (state.searchText.get()?.trim?.() !== '') {
          triggerSearch({ recordFilters: newFilters })
        }
      }
    },

    // Handler for capturing keyboard events from search box.  Used for
    // navigating search results list items
    onSearchBoxKeyDown: (e) => {
      let records = state.displayedRecords.get() || []

      if (state.searchText.get().length === 0 && (records == null || records?.length === 0)) {
        records = state.suggestedResults || []
      }
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        state.mode.set(MenuMode.SELECT)
        let currentIndex = state.selectedIndex.get()
        if (e.key === 'ArrowDown') {
          state.selectedIndex.set(currentIndex === records.length - 1 ? 0 : currentIndex + 1)
        } else {
          state.selectedIndex.set(currentIndex === 0 ? records.length - 1 : currentIndex - 1)
        }
        // Set the state for currently highlighted item
        e.preventDefault()
      }
    },

    // Handler for receiving request for list of CRM fields
    onLinkRecordRequest: (e) => {
      let shouldHandleEvent = e.detail?.onLinkRecord && e.detail.onLinkRecord instanceof Function
      if (!shouldHandleEvent) {
        return
      }

      // If link does not exist, show UI to link record
      if (!state.linkedCRMRecord?.connection) {
        // Store the fact that we are attempting to link record in response
        // to a request from another component (as opposed to user clicking on
        // this control's button)
        state.linkContext.set(e.detail)
        toggleMenu(e.detail)
        return
      }

      // If link exists already, respond with linked record details to handler
      // Local state linkContext need not change now, as we are immediately
      // returning
      e.detail.onLinkRecord({
        rangeContainer: e.detail.rangeContainer,
        rangeOffset: e.detail.rangeOffset,
        sorObjectName: state.linkedCRMRecord?.connection?.sor_object_name,
        sorRecordId: state.linkedCRMRecord?.connection?.sor_record_id,
      })
    },

    // Handler for event on making note editable
    onMakeEditable: (e) => {
      refs.syncButton.current?.classList.remove('hidden')
    },

    // Handler for receiving and displaying matching CRM records when a meeting
    // is auto-linked
    onCrmRecordAutoLinked: async (e) => {
      var matchingCrmRecords = e.detail?.matchingCrmRecords?.data
      var postSetupCallback = e.detail?.postSetupCallback
      var isEmbedded = e.detail?.embedded ?? false

      // Show message regarding auto-linking of CRM record
      state.autoLinkMessage.set(matchingCrmRecords?.length ?? 0)

      // Set default records in popup to the other CRM records
      state.autoLinkedRecord.set(true)
      state.displayedRecords.set(matchingCrmRecords)

      // Hide auto-link message after 1 minute
      setTimeout(() => {
        state.autoLinkMessage.set(0)
      }, 60000)

      // Google Analytics
      trackEvent('link_record', 'Auto')

      // Inform editor about CRM record being linked (editor will fetch latest
      // CRM record details), update templates and show templates
      await informEditor.updateCrmConnectionAndShowTemplates(isEmbedded, true)

      if (typeof postSetupCallback === 'function') {
        postSetupCallback()
      }
    },
  }

  //// Component setup - useEffect, etc. are set up here
  function useSetup() {
    // Set up click handlers for toggle/hide menu
    useThreeWayClickHandler(
      { outsideRef: refs.control, insideRef: refs.popup },
      {
        outsideClick: () => hideMenu(),
        betweenClick: (e) => {
          let actionIcon = refs.actionIcon.current
          let isAction =
            e.clientX >= actionIcon.getBoundingClientRect().left &&
            e.clientX <= actionIcon.getBoundingClientRect().right &&
            e.clientY >= actionIcon.getBoundingClientRect().top &&
            e.clientY <= actionIcon.getBoundingClientRect().bottom
          if (isAction && state?.linkedCRMRecord?.connection?.connection_id) {
            eventHandlers.onDeleteNoteCrmConnection(e)
          } else if (!props.readonly && refs.changeNoteCrmConnectionModal.current?.getIsModalShown?.() === false) {
            // Execute this only when the user clicks on cancel or ok button in the model
            setTimeout(() => {
              toggleMenu()
            })
          }
        },
      }
    )

    // Scroll to the result item
    useEffect(() => {
      let selection = refs.popup.current.querySelector(`.${styles.selected?.replace('+', '\\+')}`)
      if (selection) {
        let container = refs.popup.current.querySelector(`.${styles.results?.replace('+', '\\+')}`)
        scrollYToElement(selection, container)
      }

      // Add signaling to popup element to enable dispatching custom event
      // to link record
      let popupElement = refs.popup.current
      popupElement?.addEventListener('linkrecordrequest', eventHandlers.onLinkRecordRequest)
      popupElement?.addEventListener('crmrecordautolinked', eventHandlers.onCrmRecordAutoLinked)

      // Add signalling to the floating sync button to show itself when note
      // becomes editable
      let syncButton = refs.syncButton.current
      syncButton?.addEventListener('makeeditable', eventHandlers.onMakeEditable)

      return () => {
        // Remove signaling to popup element to enable dispatching custom event
        // to link record
        popupElement?.removeEventListener('linkrecordrequest', eventHandlers.onLinkRecordRequest)
        popupElement?.removeEventListener('crmrecordautolinked', eventHandlers.onCrmRecordAutoLinked)

        // Add signalling to the floating sync button to show itself when note
        // becomes editable
        syncButton?.removeEventListener('makeeditable', eventHandlers.onMakeEditable)
      }
    })
  }

  useSetup()

  return render()
}
