import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import {
  NoteTemplateFilter,
  ReduxNoteTemplateEntity,
  ReduxNoteTemplatesState,
} from '../types'

export interface UpdateNoteTemplateParameter {
  noteTemplateId: string
  title?: string
  body?: string
  filter?: NoteTemplateFilter
}

export interface UpdateNoteTemplateAPIResponse extends LuruAPIResponse {
  data: ReduxNoteTemplateEntity
}

export const updateNoteTemplate = {
  action: createAsyncThunk<
    UpdateNoteTemplateAPIResponse['data'],
    UpdateNoteTemplateParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: UpdateNoteTemplateAPIResponse['metadata']
      rejectedMeta: UpdateNoteTemplateAPIResponse['metadata']
    }
  >(
    'noteTemplates/updateNoteTemplate',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var augParams: UpdateNoteTemplateParameter = params
        var currEntity =
          getState().noteTemplates.entities[params.noteTemplateId]?.data

        if (currEntity) {
          let { title, body, filter } = currEntity

          if (!params.title) {
            augParams.title = title
          }

          if (!params.body) {
            augParams.body = body
          }

          if (!params.filter) {
            augParams.filter = filter
          }
        }

        var response: UpdateNoteTemplateAPIResponse =
          await NoteTemplatesAPI.updateNoteTemplate(augParams, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(updateNoteTemplate.action.pending, (state, action) => {
      if (state.entities[action.meta.arg.noteTemplateId]) {
        state.entities[action.meta.arg.noteTemplateId].status =
          EntityStatus.Updating
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(updateNoteTemplate.action.fulfilled, (state, action) => {
      state.entities[action.meta.arg.noteTemplateId] = {
        status: EntityStatus.Loaded,
        data: {
          ...action.payload,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(updateNoteTemplate.action.rejected, (state, action) => {
      if (state.entities[action.meta.arg.noteTemplateId]) {
        state.entities[action.meta.arg.noteTemplateId].status =
          EntityStatus.ErrorUpdating
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    updateNoteTemplate.addPendingCase(builder)
    updateNoteTemplate.addFulfilledCase(builder)
    updateNoteTemplate.addRejectedCase(builder)
  },
}
