import { Route } from 'react-router-dom'
import LuruUser from '../../../domain/users/LuruUser'
import { CRMProvider } from '../../../features/user/types'
import PipelineHomeLazyLoading from './pipeline/PipelineHome'

const renderPipelinePageRoutes = () => {
  const crm = LuruUser.getCurrentUserCrmName()
  return crm === CRMProvider.SFDC || crm === CRMProvider.HUBSPOT || crm === CRMProvider.SFDC_SANDBOX ? (
    <Route path='/pipeline' element={<PipelineHomeLazyLoading />}>
      <Route path=':crmObjectNamePlural' element={<PipelineHomeLazyLoading />}>
        <Route path=':viewId' element={<PipelineHomeLazyLoading />} />
      </Route>
    </Route>
  ) : null
}

export default renderPipelinePageRoutes
