// Redux
import { createSlice } from '@reduxjs/toolkit'
import { UserSliceActions } from '../user/userSlice'
import { TasksSliceReducers as reducers } from './reducers'
import { TasksSliceExtraReducers } from './extraReducers'
import { TasksMiddleware } from './middleware'
import { EmptyTasksState } from './types'

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: EmptyTasksState,
  reducers,
  extraReducers: (builder) => {
    TasksMiddleware.createTask.addAllCases(builder)
    TasksMiddleware.fetchTask.addAllCases(builder)
    TasksMiddleware.updateTask.addAllCases(builder)
    TasksMiddleware.deleteTask.addAllCases(builder)
    TasksMiddleware.listOrSearchTasks.addAllCases(builder)
    TasksMiddleware.listOrSearchAssignees.addAllCases(builder)

    // Sign-in and sign-out user flows
    builder
      .addCase(
        UserSliceActions.signInUser,
        TasksSliceExtraReducers.userSliceSignInUser
      )
      .addCase(
        UserSliceActions.signOutUser,
        TasksSliceExtraReducers.userSliceSignOutUser
      )
  },
})

export default tasksSlice.reducer

export const TasksSliceActions = tasksSlice.actions
