import { Route, useNavigate } from 'react-router-dom'
import NotesLazyLoading from './notes/Notes'
import NotesEditorEmptyStateLazyLoading from './notes/NotesEditorEmptyState'
import NotesEditorErrorLazyLoading from './notes/NotesEditorError'
import NotesEditorWrapperLazyLoading from './notes/NotesEditorWrapper'
import { useEffect } from 'react'

const RedirectHomeToNotes = () => {
  const navigate = useNavigate()

  // Perform redirection to /notes/noteId
  useEffect(() => {
    const currentPath = window.location.pathname
    if (currentPath) {
      if (currentPath?.startsWith('/home/')) {
        const noteId = currentPath?.split('/')?.pop?.()
        navigate(`/notes/${noteId}`, { replace: true })
      } else if (currentPath?.startsWith('/home')) {
        navigate(`/notes`, { replace: true })
      }
    }
  }, [navigate])

  return null
}

const renderNotesPageRoutes = () => (
  <>
    <Route path='/home/:noteId' element={<RedirectHomeToNotes />} />
    <Route path='/home' element={<RedirectHomeToNotes />} />
    <Route path='/notes' element={<NotesLazyLoading />}>
      <Route index element={<NotesEditorEmptyStateLazyLoading />} />
      <Route path=':noteId' element={<NotesEditorWrapperLazyLoading />} />
      <Route path='error' element={<NotesEditorErrorLazyLoading />} />
    </Route>
  </>
)

export default renderNotesPageRoutes
