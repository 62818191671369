import { RootState } from '../../../app/store'

export const getSorObjectNameFilter = (noteTemplateId: string) => (state: RootState) => {
  var filterValue = state.noteTemplates.entities[noteTemplateId]?.data?.filter

  if (filterValue) {
    return filterValue.sorObjectName ?? 'All'
  }

  return null
}
