// React
import React, { DragEventHandler, ReactNode } from 'react'

// Bundle
import LuruMultiSelectPopupEventHandler from './LuruMultiSelectPopupEventHandler'

// Styles
import styles from './styles.module.css'
import LuruTextBox from '../LuruTextBox'

// Assets
// import closeIcon from '../../../images/close.svg'
import selectedIcon from '../../../images/done.svg'
import notSelectedIcon from '../../../images/not_done.svg'
import tickIcon from '../../../images/fluent/checkmark.svg'
import OvalButton from '../../OvalButton'

export interface LuruMultiSelectPopupProps {
  items: Array<{
    name: string
    key: string
    icon?: string
    customLabel?: ReactNode
    isSelected: boolean
    isDisabled?: boolean
    isLabelDisabled?: boolean
  }>
  areItemsReorderable?: boolean
  disableFirstItemReorder?: boolean
  hideToggleSelectAllItems?: boolean
  hideFilterBox?: boolean
  hideFooterButtons?: boolean
  footerInfoElement?: JSX.Element
  maxItemsToShow?: number
  onSelectItem?: (key: string, arg?: Array<string>) => void
  onDeselectItem?: (key: string, arg?: Array<string>) => void
  onFinishSelection: (
    selectedItems: Array<string>,
    allItems?: Array<{ key: string; isSelected: boolean; order: number }>
  ) => void
  onSelectAllItems?: (arg: Array<string>) => void

  //This will returns either emptry array or array of fields which can are disabled but selected
  onDeselectAllItems?: (arg: Array<string>) => void
  onCancel: () => void
}

export interface LuruMultiSelectPopupState {
  highlightedIndex: number
  orderedItems: Array<{
    name: string
    key: string
    icon?: string
    customLabel?: ReactNode
    isSelected: boolean
    isDisabled?: boolean
    isLabelDisabled?: boolean
  }>
  filteredItems: Array<{
    name: string
    key: string
    icon?: string
    customLabel?: ReactNode
    isSelected: boolean
    isDisabled?: boolean
    isLabelDisabled?: boolean
  }>
  selectedItems: Array<string>
}

interface LuruMultiSelectPopupRefs {
  filterBox?: React.RefObject<LuruTextBox>
  list: React.RefObject<HTMLUListElement>
}

export default class LuruMultiSelectPopup extends React.Component<
  LuruMultiSelectPopupProps,
  LuruMultiSelectPopupState
> {
  props: LuruMultiSelectPopupProps
  state: LuruMultiSelectPopupState
  eventHandler: LuruMultiSelectPopupEventHandler
  componentRefs: LuruMultiSelectPopupRefs

  constructor(props: LuruMultiSelectPopupProps) {
    super(props)
    this.props = props

    this.state = {
      highlightedIndex: -2,
      orderedItems: props.items,
      filteredItems: props.items,
      selectedItems: props.items.filter((x) => x.isSelected).map((f) => f.key),
    }

    this.eventHandler = new LuruMultiSelectPopupEventHandler(this)

    this.componentRefs = {
      filterBox: React.createRef(),
      list: React.createRef(),
    }
  }

  focusFilterBox = () => setTimeout(() => this.componentRefs.filterBox?.current?.focus(), 100)

  resetFilterBox = () => this.componentRefs.filterBox?.current?.setValue('')

  render = () => (
    <>
      {this.props.hideFilterBox ? null : this.#renderFilterBox()}
      {this.#renderMenuItems()}
      {this.props.footerInfoElement ?? null}
      {this.props.hideFooterButtons ? null : this.#renderButtons()}
    </>
  )

  #renderFilterBox = () => (
    <div className={styles.filterBoxContainer}>
      <LuruTextBox
        additionalClassNames={[styles.filterBox]}
        ref={this.componentRefs.filterBox}
        onChange={this.eventHandler.handlers.onFilterBoxChange}
        onNavigateDown={this.eventHandler.handlers.onFilterBoxNavigateDown}
        onNavigateUp={this.eventHandler.handlers.onFilterBoxNavigateUp}
        onReturn={this.eventHandler.handlers.onFilterBoxReturn}
        onCancel={this.eventHandler.handlers.onFilterBoxCancel}
        placeholder='Filter fields...'
      />
    </div>
  )

  #renderToggleSelectAll = () => {
    if (this.props.hideToggleSelectAllItems) {
      return null
    }

    return (
      <li
        onClick={() => this.eventHandler.handlers.onToggleSelectAll()}
        data-luru-role='multi-select-popup-list-item'
        data-highlighted={-1 === this.state.highlightedIndex ? 'yes' : 'no'}
        className={[-1 === this.state.highlightedIndex ? styles.highlightedItem : null, styles.toggleSelectAll].join(
          ' '
        )}
      >
        {this.state.selectedItems.length === this.props.items.length ? (
          <img data-role='checked-icon' src={selectedIcon} alt='Selected' />
        ) : (
          <img data-role='checked-icon' src={notSelectedIcon} alt='Selected' />
        )}
        <label>All</label>
      </li>
    )
  }

  #renderMenuItems = () => {
    var height = `calc((2.2em + 2px) * ${Math.min(
      this.state.filteredItems.length + (this.props.hideToggleSelectAllItems ? 0 : 1),
      this.props.maxItemsToShow ?? 5
    )} + 1px)`

    var style = { height }

    var dragDropHandlers = this.props.areItemsReorderable ? this.eventHandler.dragDropHandlers : undefined

    var isDragCandidate = (ix: number) =>
      this.props.areItemsReorderable && (ix > 0 || !this.props.disableFirstItemReorder)

    var assignDragEvent = (ix: number, f: DragEventHandler<HTMLElement> | undefined) =>
      isDragCandidate(ix) ? f : undefined

    if (this.state.filteredItems.length <= 0) {
      return <div className={styles.emptyValue}>No values</div>
    }
    return (
      <ul
        style={style}
        className={[styles.menuList, this.props.hideToggleSelectAllItems ? styles.toggleSelectAllHidden : null].join(
          ' '
        )}
        ref={this.componentRefs.list}
      >
        {this.#renderToggleSelectAll()}
        {this.state.filteredItems
          // Order filtered items by ordered items index
          .sort((a, b) =>
            this.state.orderedItems.findIndex((f) => f.key === a.key) <
            this.state.orderedItems.findIndex((f) => f.key === b.key)
              ? -1
              : 1
          )
          .map((item, index) => (
            <li
              key={item.key}
              className={[
                index === this.state.highlightedIndex ? styles.highlightedItem : null,
                item.icon ? styles.withIcon : null,
                this.props.areItemsReorderable ? styles.draggable : null,
              ].join(' ')}
              data-luru-role='multi-select-popup-list-item'
              data-highlighted={index === this.state.highlightedIndex ? 'yes' : 'no'}
              data-luru-multiselect-disabled={
                item.isDisabled || (index === 0 && this.props.disableFirstItemReorder) ? 'true' : 'false'
              }
              data-luru-multiselect-label-disabled={item.isLabelDisabled ? 'true' : 'false'}
              data-luru-multiselect-index={index}
              // Maintain info on position in ordered index, this position would
              // be monotonic increasing in this loop
              data-multiselect-ordered-index={this.state.orderedItems.findIndex((f) => f.key === item.key)}
              draggable={isDragCandidate(index)}
              onClick={
                this.props.disableFirstItemReorder && index === 0
                  ? undefined
                  : () => this.eventHandler.handlers.onToggleItem(item.key)
              }
              onDragStart={assignDragEvent(index, dragDropHandlers?.dragstart)}
              onDragEnd={assignDragEvent(index, dragDropHandlers?.dragend)}
              onDragEnter={assignDragEvent(index, dragDropHandlers?.dragenter)}
              onDragOver={assignDragEvent(index, dragDropHandlers?.dragover)}
              onDragLeave={assignDragEvent(index, dragDropHandlers?.dragleave)}
              onDrop={assignDragEvent(index, dragDropHandlers?.drop)}
              title={`${item.name}`}
            >
              {this.state.selectedItems.find((x) => x === item.key) ? (
                <img data-role='checked-icon' src={selectedIcon} alt='Selected' />
              ) : (
                <img data-role='checked-icon' src={notSelectedIcon} alt='Selected' />
              )}
              {item.icon ? <img data-role='list-item-icon' src={item.icon} alt={item.name} /> : null}
              <label>{item.customLabel ?? item.name}</label>
            </li>
          ))}
      </ul>
    )
  }

  #renderButtons = () => (
    <div
      className={[styles.buttons, this.eventHandler.getIsItemsChanged() ? styles.doneButtonChange : undefined].join(
        ' '
      )}
    >
      <OvalButton onClick={this.eventHandler.handlers.onClickOkButton}>
        <img src={tickIcon} alt='Done' /> Done
      </OvalButton>
    </div>
  )
}
