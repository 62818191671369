import { forwardRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import LuruCollectionsQuickAccessBarComponent from './LuruCollectionsQuickAccessBarComponent'
import getReduxState from './getReduxState'
import Utils from '../../../../utils/Utils'
import { ConnectLuruExtensionsProps } from '@/app/types'

const connector = connect(getReduxState, null, null, { forwardRef: true })

export interface LuruCollectionsQuickAccessBarConnectedProps
  extends ConnectedProps<typeof connector>,
    ConnectLuruExtensionsProps {}

export default connector(forwardRef(Utils.connectLuruExtensions(LuruCollectionsQuickAccessBarComponent)))
