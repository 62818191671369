import { EntityStatus } from '@/app/types'
import { CalendarProvider, CRMProvider } from '../user/types'

export interface ReduxUsersState extends Partial<Record<SorProvider, ReduxSorUsersState>> {
  LURU: ReduxLuruUsersState
}

export type SorProvider = CRMProvider | CalendarProvider

interface ReduxSorUsersState {
  status: EntityStatus
  entities: Record<
    SorUserId,
    {
      status: EntityStatus
      data: ReduxSorUserEntity
    }
  >
}

interface ReduxLuruUsersState {
  status: EntityStatus
  entities: Record<
    LuruUserId,
    {
      status: EntityStatus
      data: ReduxLuruUserEntity
    }
  >
  roles: {
    status: EntityStatus
    entities: Record<
      string,
      {
        status: EntityStatus
        data: LuruUserRole
      }
    >
  }
}

export type LuruUserId = string

export type SorUserId = string

export interface ReduxLuruUserEntity {
  email: string
  first_name: string
  joined_at: string
  last_login: string
  last_name: string
  roles: Array<LuruUserRole>
  user_id: string
  connects: Array<LuruUserSorConnectInfo>
}

export interface LuruUserSorConnectInfo {
  provider: string
  created_at: string
}

export interface ReduxSorUserEntity {
  email: string
  first_name: string | null
  last_name: string | null
  sor: CRMProvider
  luru_user_id: string | null
  sor_user_id: string
  user_id: string
  groups?: Array<ReduxSorGroupEntity>
  user_admin_tasks: Array<ReduxSorUserAdminTask>
  photo_url?: string
}

export interface ReduxSorUserAdminTask {
  name: string
  status: string
  data: {}
}

export const EmptyUsersState: ReduxUsersState = {
  LURU: {
    status: EntityStatus.NotLoaded,
    entities: {},
    roles: {
      status: EntityStatus.NotLoaded,
      entities: {},
    },
  },
}

export interface ReduxSorGroupEntity {
  group_id: string
  name: string
  sor: string
  sor_group_id: string
}

export interface LuruUserRole {
  id: string
  name: string
}

export enum LuruRole {
  ADMIN = 'Admin',
  REGULAR_USER = 'Regular User',
}

export enum UserRole {
  LEADER = 'Leader',
  REVOPS = 'RevOps',
  AE = 'AE',
  SDR = 'SDR',
  CS = 'CS',
}

export const RoleMapping = {
  [LuruRole.ADMIN]: [UserRole.LEADER, UserRole.REVOPS],
  [LuruRole.REGULAR_USER]: [UserRole.AE, UserRole.SDR, UserRole.CS],
}
