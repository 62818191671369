import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CrmAPI } from '../api'
import CRMBaseMiddlewareFactory from '../api/crmBaseMiddleware'
import { CrmObjectName, LuruFieldType, ReduxCrmKey, ReduxCrmState } from '../types'

export interface UpdateSorRecordParameter {
  crmKey: ReduxCrmKey
  objectName: CrmObjectName
  recordId: string
  fields: Record<string, { fieldValue: any; luruFieldType: LuruFieldType }>
  recordFieldValues: Record<string, { value: any }>
}

export interface UpdateSorRecordPreparedParameter {
  crmKey: ReduxCrmKey
  objectName: CrmObjectName
  recordId: string
  fields: Record<string, any>
}

export interface UpdateSorRecordAPIResponse extends LuruAPIResponse {
  data: Record<string, any>
}

export const updateSorRecord = {
  action: createAsyncThunk<
    UpdateSorRecordAPIResponse['data'],
    UpdateSorRecordParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: UpdateSorRecordAPIResponse['metadata']
      rejectedMeta: UpdateSorRecordAPIResponse['metadata']
    }
  >('crm/updateSorRecord', async (params, { getState, signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var crmFactory = new CRMBaseMiddlewareFactory(params.crmKey as string)
      var { crmKey, objectName, fields, recordFieldValues } = params
      var fieldSchemaList = getState().crm?.[crmKey]?.schema?.[objectName]?.data?.fields
      var fieldNameValueMap = crmFactory.prepareFieldSetForUpdate(fields, fieldSchemaList) as Record<string, any>
      var preparedParams = {
        ...params,
        fields: CRMBaseMiddlewareFactory.reviewAndTweakControlledFields(
          fieldSchemaList,
          fieldNameValueMap,
          recordFieldValues
        ),
      } as UpdateSorRecordPreparedParameter
      var response = (await CrmAPI.updateSorRecord(preparedParams, {
        signal,
      })) as UpdateSorRecordAPIResponse
      var recordFieldsMap = params.crmKey === 'hubspot' ? response.data.properties : response.data

      return fulfillWithValue(recordFieldsMap, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(updateSorRecord.action.pending, (state, action) => {
      var { crmKey, objectName, recordId } = action.meta.arg

      state[crmKey] = {
        ...state[crmKey],
        records: {
          ...state[crmKey]?.records,
          [objectName]: {
            ...state[crmKey]?.records?.[objectName],
            [recordId]: {
              status: EntityStatus.Loading,
            },
          },
        },
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(updateSorRecord.action.fulfilled, (state, action) => {
      var { crmKey, objectName, recordId } = action.meta.arg

      state[crmKey] = {
        ...state[crmKey],
        records: {
          ...state[crmKey]?.records,
          [objectName]: {
            ...state[crmKey]?.records?.[objectName],
            [recordId]: {
              status: EntityStatus.Loaded,
              data: { ...action.payload } as any,
            },
          },
        },
      }

      if (state[crmKey]?.entities?.[objectName]?.[recordId]) {
        delete state[crmKey]?.entities?.[objectName]?.[recordId]
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(updateSorRecord.action.rejected, (state, action) => {
      var { crmKey, objectName, recordId } = action.meta.arg

      state[crmKey] = {
        ...state[crmKey],
        records: {
          ...state[crmKey]?.records,
          [objectName]: {
            ...state[crmKey]?.records?.[objectName],
            [recordId]: {
              ...state[crmKey]?.records?.[objectName]?.[recordId],
              status: EntityStatus.ErrorUpdating,
              error: action.payload as {},
            },
          },
        },
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    updateSorRecord.addPendingCase(builder)
    updateSorRecord.addFulfilledCase(builder)
    updateSorRecord.addRejectedCase(builder)
  },
}
