import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LuruUser from '../domain/users/LuruUser'
import { useLuruToast } from '@/hooks/useLuruToast'

export default class Utils {
  static generateUUID() {
    let result = null

    if (crypto?.randomUUID) {
      result = crypto.randomUUID()
    } else if (crypto.getRandomValues) {
      result = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      )
    }

    return result
  }

  static generateRandomId(uidLength = 7) {
    let letters = 'abcdefghijklmnopqrstuvwxyz1234567890'
    return Array.from(Array(uidLength).keys()).reduce(
      (result, i) => result + ((i + 1) % 5 ? letters[Math.floor(Math.random() * (letters.length - 1))] : '-'),
      ''
    )
  }

  static fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand('copy')
    } catch (err) {
      console.error('Unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  static copyTextToClipboard(text, callback) {
    if (!navigator.clipboard) {
      Utils.fallbackCopyTextToClipboard(text)
      callback()
      return
    }
    navigator.clipboard.writeText(text).then(callback, (err) => console.error('Could not copy text: ', err))
  }

  static copyToClipboard(typeContentMap, callback) {
    if (!navigator.clipboard) {
      console.error('Could not copy')
      return
    }
    const clipboardItemData = {}
    for (let type in typeContentMap) {
      let content = typeContentMap[type]
      const blob = new Blob([content], { type: type })
      clipboardItemData[blob.type] = blob
    }
    const data = [new window.ClipboardItem(clipboardItemData)]

    navigator.clipboard.write(data).then(
      () => {
        callback()
      },
      (err) => console.error('Async: Could not copy content to clipboard: ', err)
    )
  }

  static copyArrayOfObjects(arr) {
    if (Array.isArray(arr)) {
      return arr.reduce((prev, curr) => [...prev, { ...curr }], [])
    }
    return arr
  }

  static log(...args) {
    var DEBUG = process.env.REACT_APP_DEBUG === 'true'
    if (DEBUG) {
      args.length > 0 ? console.log(...args) : console.log('')
    } else {
      return
    }
  }

  /**
   *
   * @param {Array} input
   * @param {number} from
   * @param {number} to
   */
  static moveArrayIndex(input, from, to) {
    let numberOfDeletedElm = 1

    const elm = input.splice(from, numberOfDeletedElm)[0]

    numberOfDeletedElm = 0

    input.splice(to, numberOfDeletedElm, elm)
    return input
  }

  /**
   *
   * @param {string} str
   * @param {number} length
   * @param {string} ending
   * @returns {string}
   */
  static textTruncate(str, length, ending) {
    if (length == null) {
      length = 100
    }
    if (ending == null) {
      ending = '...'
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending
    } else {
      return str
    }
  }

  static connectLuruExtensions(Component) {
    const WrapperOfComponent = (props, componentRef) => {
      const params = useParams()
      const navigate = useNavigate()
      const location = useLocation()
      const [searchParams, setSearchParams] = useSearchParams()
      const router = { params, navigate, location, searchParams, setSearchParams }
      const toast = useLuruToast()

      props = { ...props, router, toast }

      if (componentRef && Object.keys(componentRef).length >= 1) {
        return <Component ref={componentRef} {...props} />
      } else {
        return <Component {...props} />
      }
    }

    return WrapperOfComponent
  }

  static connectCharkaUI(Component) {
    const WrapperOfComponent = (props, componentRef) => {
      if (componentRef && Object.keys(componentRef).length >= 1) {
        return <Component ref={componentRef} {...props} />
      } else {
        return <Component {...props} />
      }
    }

    return WrapperOfComponent
  }

  static capitalizeString(s) {
    if (typeof s !== 'string') {
      return s
    }

    var words = s.split(' ')
    var capitalizedWords = []

    for (let word of words) {
      capitalizedWords.push(word.substring(0, 1).toLocaleUpperCase() + word.substring(1).toLocaleLowerCase())
    }

    return capitalizedWords.join(' ')
  }

  static formatDate(dateStr) {
    var locale = LuruUser.getLocale()
    return Intl.DateTimeFormat(locale, {
      year: '2-digit',
      month: 'short',
      day: '2-digit',
    }).format(new Date(dateStr))
  }

  static formatDateTime(dateStr) {
    var locale = LuruUser.getLocale()
    return Intl.DateTimeFormat(locale, {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    }).format(new Date(dateStr))
  }

  static encodeHTML(text) {
    var escapedText = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;',
    }

    return text.replace(/[&<>"']/g, (matchedChar) => escapedText[matchedChar])
  }

  static getCookieValue(cookieName) {
    return document.cookie && document.cookie !== ''
      ? document.cookie
          .split(';')
          .find((c) => c.trim().startsWith(`${cookieName}=`))
          ?.trim()
          .substring(cookieName.length + 1)
      : null
  }

  static removeCookie(cookieName) {
    if (document.cookie && document.cookie !== '') {
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
  }

  /**
   * Compute the number of occurrences of the name in current names and returns the maximum occurrence of the name
   * @param {string} name - Name to be computed
   * @param {Array<string>} currentNames - Names to compare the occurrences
   * @returns {string} - Returns new name, Which is the maximum occurrence of the name. ex:- `Copy (number of occurrences + 1) of <name>`
   */
  static computeDuplicateName(name, currentNames) {
    var compareText = new RegExp('^Copy( \\((\\d+)\\))? of')

    if (name.match(compareText)) {
      let index = name.indexOf('of')
      name = name.slice(index + 2).trim()
    }

    var regexCompare = new RegExp('^Copy( \\((\\d+)\\))? of ' + name)
    var max = 0

    for (let x of currentNames) {
      let value = x.match(regexCompare)
      if (value) {
        // If there is a name already exists something like "Copy of <name>" then value[2] will be undefined;
        if (value[2] === undefined) {
          max = Math.max(1, max)
        } else {
          max = Math.max(+value[2], max)
        }
      }
    }
    return max === 0 ? `Copy (1) of ${name}` : `Copy (${max + 1}) of ${name}`
  }

  static classes(...classes) {
    return classes.filter(Boolean).join(' ')
  }
}

export const appendScript = (scriptToAppend, appendToHead = false, id = null) => {
  const script = document.createElement('script')
  script.src = scriptToAppend
  if (id) {
    script.id = id
  }
  if (appendToHead) {
    document.head.appendChild(script)
  } else {
    document.body.appendChild(script)
  }
}

export function connectRouter(Component) {
  const WrapperOfComponent = (props) => {
    const params = useParams()
    const navigate = useNavigate()
    const router = { params, navigate }
    props = { ...props, router }
    return <Component {...props} />
  }
  return WrapperOfComponent
}

/**
 *
 * @returns {'Mac'|'Win'|'Linux'|'Unknown'}
 */
export const getPlatform = () => {
  let platform = 'Unknown'
  if (navigator.platform.indexOf('Mac') === 0) {
    platform = 'Mac'
  } else if (navigator.platform.indexOf('Win') === 0) {
    platform = 'Win'
  } else if (navigator.platform.indexOf('Linux') === 0) {
    platform = 'Linux'
  }
  return platform
}

/**
 *
 * @returns {'Opera'|'Microsoft Edge'|'Google Chrome'|'Mozilla Firefox'|'Apple Safari'|'Microsoft Internet Explore'|'UC Browser'|'Samsung Browser'|'Unknown'}
 */
export const getBrowserName = () => {
  const test = (regexp) => {
    return regexp.test(navigator.userAgent)
  }
  if (test(/opr\//i) || !!window.opr) {
    return 'Opera'
  } else if (test(/edg/i)) {
    return 'Microsoft Edge'
  } else if (test(/chrome|chromium|crios/i)) {
    return 'Google Chrome'
  } else if (test(/firefox|fxios/i)) {
    return 'Mozilla Firefox'
  } else if (test(/safari/i)) {
    return 'Apple Safari'
  } else if (test(/trident/i)) {
    return 'Microsoft Internet Explorer'
  } else if (test(/ucbrowser/i)) {
    return 'UC Browser'
  } else if (test(/samsungbrowser/i)) {
    return 'Samsung Browser'
  } else {
    return 'Unknown'
  }
}

/**
 * Remove any unwanted slash
 * @param {string} url
 * @returns
 */
export const cleanUrl = (url) => {
  return url.replace(/([^:]\/)\/+/g, '$1')
}

/**
 *
 * @param {object} obj1
 * @param {object} obj2
 * @returns
 */
export function areObjectsDeepEqual(obj1, obj2) {
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  } catch (error) {
    return false
  }
}

/**
 *
 * @param {object} object
 * @returns string
 */
export function convertObjIntoQueryParams(object) {
  const objString = Object.keys(object)
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`
    })
    .join('&')

  return objString
}

export const ThreeWayAnswer = {
  YES: 'yes',
  NO: 'no',
  UNCERTAIN: 'uncertain',
}

Object.freeze(ThreeWayAnswer)

export const LoggingUtils = {
  count: 0,

  getNewLoggingStyle: () => {
    var hue = `${(LoggingUtils.count++ % 12) * 30 + 15}deg`
    return `background: hsla(${hue}, 50%, 95%, 1); color: hsla(${hue}, 50%, 50%, 1)`
  },
}

/**
 *
 * @param {any[]} arr
 * @param {string} field
 * @returns
 */
export function uniqBy(arr, field) {
  return [...new Map(arr.map((item) => [item[field], item])).values()]
}

/**
 *
 * @param {string} text
 * @returns {boolean}
 */
export function areHtmlTagsPresent(text) {
  var regExpToCheckHtmlTags = /<([a-zA-Z]+)[^>]*>(.*?)<\/\1>|<([a-zA-Z]+)[^>]*\/>/g
  return regExpToCheckHtmlTags.test(text)
}

/**
 *
 * @param {string} text
 * @returns {boolean}
 */
export function areHyperLinksPresent(text) {
  var urlRegex = /(?:https?:\/\/)?(?:www\.)?([^\s]+\.[^\s]+)/g
  const links = text.match(urlRegex)
  return Boolean(links)
}
