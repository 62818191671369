import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxUserState } from '../types'

export const setConnectedCalendarErrorCode: CaseReducer<ReduxUserState, PayloadAction<{ errorCode: number }>> = (
  state,
  action
) => {
  if (state?.data?.userSettings?.connectedCalendar) {
    state.data.userSettings.connectedCalendar = {
      ...state.data.userSettings.connectedCalendar,
      errorCode: action.payload.errorCode,
    }
  }
}
