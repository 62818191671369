import { connect, ConnectedProps } from 'react-redux'
import NewNotesTabPanelComponent from './NewNotesTabPanelComponent'
import getReduxState from './getReduxState'
import getActions from './getActions'
import Utils from '../../../../../../utils/Utils'

const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type NewNotesTabPanelConnectedProps = ConnectedProps<typeof connector>

export default Utils.connectLuruExtensions(NewNotesTabPanelComponent)
