import { getFullyQualifiedKey, getTextDetailsUntil } from '../../domutils/utils'
import { JottingType } from '../../features/notes/types'
import { CaretPosition } from '../EditorDOM'
import DomUtils from '../utils/DomUtils'
import EditorCrmFieldView from '../views/EditorCrmFieldView'

/**
 * @classdesc Class to handle navigation events inside editor
 */
export default class NavigationHandler {
  // Computations

  /**
   * Compute if and how navigation event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]
    let navEvent = lastEvent?.sourceEvent?.type === 'keydown' ? lastEvent : null

    if (!navEvent) {
      return
    }

    let eventKey = getFullyQualifiedKey(navEvent?.sourceEvent)
    let handlingActions = {
      do: null,
      undo: null,
    }

    switch (eventKey) {
      case 'ArrowDown':
      case 'CtrlArrowDown':
        handlingActions = this.#computeArrowDownHandler(view, navEvent)
        break

      case 'ArrowUp':
      case 'CtrlArrowUp':
        handlingActions = this.#computeArrowUpHandler(view, navEvent)
        break

      case 'ArrowLeft':
        handlingActions = this.#computeArrowLeftHandler(view, navEvent)
        break

      case 'ArrowRight':
        handlingActions = this.#computeArrowRightHandler(view, navEvent)
        break

      default:
    }

    return handlingActions
  }

  /**
   * Compute arrow left handler
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeArrowLeftHandler(view, navEvent) {
    if (
      view.isFirstNote(navEvent.data.noteElement) &&
      getTextDetailsUntil(
        navEvent.data.noteElement,
        navEvent.data.range.startContainer,
        navEvent.data.range.startOffset
      )?.length === 0
    ) {
      return {
        do: (view) => {
          navEvent.sourceEvent.preventDefault()
        },
        preventDefault: true,
      }
    }
  }

  /**
   * Compute arrow right handler
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeArrowRightHandler(view, navEvent) {
    // TODO: Add test to check if caret is at end of node
    if (view.isLastNote(navEvent.data.noteElement)) {
      // If there is no lastChild, it means noteElement is empty in content.
      // Content can only be at the last position
      let preventDefault = !navEvent.data.noteElement?.lastChild

      // If there is some content, then we need to check whether caret is at
      // the end of note element
      if (navEvent.data.noteElement?.lastChild) {
        let lastCaretPosition = new Range()
        lastCaretPosition.setEnd(
          navEvent.data.noteElement.lastChild,
          navEvent.data.noteElement.lastChild.nodeType === 3
            ? navEvent.data.noteElement.lastChild.textContent.length
            : navEvent.data.noteElement.lastChild.childNodes.length
        )
        lastCaretPosition.collapse(false)
        preventDefault = lastCaretPosition.compareBoundaryPoints(Range.END_TO_END, navEvent.data.range) <= 0
      }

      if (preventDefault) {
        return {
          do: (view) => {
            navEvent.sourceEvent.preventDefault()
          },
          preventDefault: true,
        }
      } else {
        return null
      }
    }
  }

  /**
   * Compute arrow down handler
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeArrowDownHandler(view, navEvent) {
    if (navEvent.data?.range?.collapsed === true) {
      return this.#computeCaretArrowDownHandler(view, navEvent)
    } else {
      return this.#computeSelectionArrowDownHandler(view, navEvent)
    }
  }

  /**
   * Compute arrow down handler when there is selection
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeSelectionArrowDownHandler(view, navEvent) {
    return {
      do: (view) => {
        navEvent.sourceEvent.preventDefault()
        navEvent.data.range.collapse(false)
      },
      preventDefault: true,
    }
  }

  /**
   * Compute arrow down handler when there is no selection
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeCaretArrowDownHandler(view, navEvent) {
    const { noteElement: note, jottingElement: jotting, range } = navEvent.data

    // Check 1.
    // Note elements can be multi-line.  If caret is not at the last line
    // we go with the default behavior of browser.
    const bottomPadding = view.getJottingType(jotting) === JottingType.CRM_FIELD_VALUE ? 30 : 0
    if (!DomUtils.isCaretAtLastLine(range, note, view.getEditorContainer(), bottomPadding)) {
      return null
    }

    // Note:
    // In all of the following checks, the caret is at the last line of the note
    // element.  Note elements can be single line too (in which case, the caret
    // is for sure at the same line - both first and last are the same)

    // Check 2.
    // The following block should prevent caret from moving into meeting
    // navigation drawer in embedded notes.  Check why this is not working.
    // TODO: Keep a simple, non-scrollable note to figure out if this is
    // happening, or if this is a special case because the note itself is
    // scrollable.
    if (view.isLastNote(note)) {
      return {
        do: (view) => {
          navEvent.sourceEvent.preventDefault()
        },
        preventDefault: true,
      }
    }

    // Step 3. Find the next element where caret can go potentially
    let nextNote = view.getNextNoteElement(note)
    let nextJotting = view.getJottingElement(nextNote)
    let nextJottingType = view.getJottingType(nextJotting)
    let nextInput = view.getInputElement(nextJotting)

    // Go until we find a jotting where we can place the caret
    while (nextInput && !EditorCrmFieldView.canSelectAndEdit(nextInput)) {
      nextJotting = view.getNextJottingElement(nextJotting)
      nextNote = view.getNoteElement(nextJotting)
      if (nextJotting) {
        nextJottingType = view.getJottingType(nextJotting)
        nextInput = view.getInputElement(nextJotting)
      }
    }

    // If there are no more jottings, the current note is the last note where
    // a caret can be placed, so prevent default (we want to keep caret within
    // editor).  This is like step 2 above, just that the last jotting in step
    // 2 is really the last jotting, whereas here, it is the last jotting where
    // a caret can be placed
    if (!nextJotting) {
      return {
        do: (view) => {
          navEvent.sourceEvent.preventDefault()
        },
        preventDefault: true,
      }
    }

    // Step 4.  We have a jotting which can take a caret.  Now, figure out how
    // to place the caret.

    // Handle case when next element is CRM field value
    if (nextJottingType === JottingType.CRM_FIELD_VALUE) {
      // As per WHATWG specs, only following types can accept
      // setSelectionRange() method
      // Note: If we have an input element, that fails the following repoAllowed
      // check, the effect of our handler will be to just select the element.
      // This is ok for now.  We have already figured out that this is an
      // editable input element.  So this behavior of selecting it is ideal,
      // even though we are not able to precisely place a caret (due to specs)
      const caretRepoAllowedElements = ['text', 'search', 'url', 'tel']
      const repoAllowed =
        caretRepoAllowedElements.includes(nextInput?.type) || nextInput?.tagName.toLowerCase() === 'textarea'

      if (repoAllowed) {
        return {
          do: (view) => {
            if (nextInput) {
              nextInput.select()
              if (repoAllowed) {
                nextInput.setSelectionRange(nextInput.value.length, nextInput.value.length, 'forward')
              }
              navEvent.sourceEvent.preventDefault()
              DomUtils.scrollIntoViewIfNeeded(nextNote, view.getEditorContainer())
            }
          },
          preventDefault: true,
        }
      }
    }

    const placeCaretInNextNote = {
      do: (view) => {
        navEvent.sourceEvent.preventDefault()
        view.setCaretAt(nextNote, CaretPosition.START_OF_NOTE)
        DomUtils.scrollIntoViewIfNeeded(nextNote, view.getEditorContainer())
      },
      preventDefault: true,
    }
    const placeCaretInLastNote = {
      do: (view) => {
        navEvent.sourceEvent.preventDefault()
        let notesList = view.getNoteElementsList()
        view.setCaretAt(notesList[notesList.length - 1], CaretPosition.END_OF_NOTE)
        DomUtils.scrollIntoViewIfNeeded(notesList[notesList.length - 1], view.getEditorContainer())
      },
      preventDefault: true,
    }

    // Step 5.
    // Handle special cases when next element is not crm field value.
    // By default we don't handle the ArrowDown event.  It is handled by
    // browser.  We handle it only in the special conditions tested.
    return view.getJottingType(jotting) === JottingType.CRM_FIELD_VALUE
      ? placeCaretInNextNote
      : nextNote?.textContent.trim() === ''
      ? placeCaretInNextNote
      : !nextNote
      ? placeCaretInLastNote
      : null
  }

  /**
   * Compute arrow up handler
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeArrowUpHandler(view, navEvent) {
    if (navEvent?.data?.range?.collapsed) {
      return this.#computeCaretArrowUpHandler(view, navEvent)
    } else {
      return this.#computeSelectionArrowUpHandler(view, navEvent)
    }
  }

  /**
   * Compute arrow up handler when there is selection
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeSelectionArrowUpHandler(view, navEvent) {
    return {
      do: (view) => {
        navEvent.sourceEvent.preventDefault()
        navEvent.data.range.collapse(true)
      },
      preventDefault: true,
    }
  }

  /**
   * Compute arrow up handler when there is no selection
   * @param {EditorDOM} view - EditorDOM instance hosting the edited note
   * @param {Object} navEvent - A luruEvent object
   * @returns {Object} - An object with do() and undo() callbacks - optional
   */
  #computeCaretArrowUpHandler(view, navEvent) {
    const { noteElement: note, jottingElement: jotting, range } = navEvent.data
    // Check 1.
    // Note elements can be multi-line.  If caret is not at the first line
    // we go with the default behavior of browser.
    if (!DomUtils.isCaretAtFirstLine(range, note, view.getEditorContainer())) {
      return null
    }

    // Note:
    // In all of the following checks, the caret is at the first line of note
    // element.  Note elements can be single line too (in which case, the caret
    // is for sure at the same line - both first and last are the same)

    // Check 2.
    if (view.isFirstNote(note)) {
      return {
        do: (view, controller) => {
          controller.setFocusInTitle()
        },
      }
    }

    // Step 3. Find the next element where caret can go potentially
    let prevNote = view.getPreviousNoteElement(note)
    let prevJotting = view.getJottingElement(prevNote)
    let prevInput = view.getInputElement(prevJotting)
    let prevJottingType = view.getJottingType(prevJotting)

    // Go until we find a jotting where we can place the caret
    while (prevInput && !EditorCrmFieldView.canSelectAndEdit(prevInput)) {
      prevJotting = view.getPreviousJottingElement(prevJotting)
      prevNote = view.getNoteElement(prevJotting)
      if (prevJotting) {
        prevJottingType = view.getJottingType(prevJotting)
        prevInput = view.getInputElement(prevJotting)
      }
    }

    // If there are no more jottings, the current note is the first note where
    // a caret can be placed, so prevent default (we want to keep caret within
    // editor).  This is like step 2 above, just that the first jotting in step
    // 2 is really the first jotting, whereas here, it's the first jotting where
    // a caret can be placed
    if (!prevJotting) {
      return {
        do: (view) => {
          navEvent.sourceEvent.preventDefault()
        },
        preventDefault: true,
      }
    }

    // Step 4.  We have a jotting which can take a caret.  Now, figure out how
    // to place the caret.

    // Handle case when next element is CRM field value
    if (prevJottingType === JottingType.CRM_FIELD_VALUE) {
      let prevInput = view.getInputElement(prevJotting)
      // As per WHATWG specs, only following types can accept
      // setSelectionRange() method
      // Note: If we have an input element, that fails the following repoAllowed
      // check, the effect of our handler will be to just select the element.
      // This is ok for now.  We have already figured out that this is an
      // editable input element.  So this behavior of selecting it is ideal,
      // even though we are not able to precisely place a caret (due to specs)
      let caretRepoAllowedElements = ['text', 'search', 'url', 'tel']
      const repoAllowed =
        caretRepoAllowedElements.includes(prevInput?.type) || prevInput?.tagName?.toLowerCase() === 'textarea'

      if (repoAllowed) {
        return {
          do: (view) => {
            if (prevInput) {
              prevInput.select()
              if (repoAllowed) {
                prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length, 'forward')
              }
              navEvent.sourceEvent.preventDefault()
              DomUtils.scrollIntoViewIfNeeded(prevNote, view.getEditorContainer())
            }
          },
          preventDefault: true,
        }
      }
    }

    const placeCaretInPrevNote = {
      do: (view) => {
        const prevNote = view.getPreviousNoteElement(navEvent.data.noteElement)
        navEvent.sourceEvent.preventDefault()
        view.setCaretAt(prevNote, CaretPosition.START_OF_NOTE)
        DomUtils.scrollIntoViewIfNeeded(prevNote, view.getEditorContainer())
      },
      preventDefault: true,
    }
    const placeCaretInFirstNote = {
      do: (view) => {
        const firstNote = view.getNoteElementsList()[0]
        navEvent.sourceEvent.preventDefault()
        view.setCaretAt(firstNote, CaretPosition.START_OF_NOTE)
        DomUtils.scrollIntoViewIfNeeded(firstNote, view.getEditorContainer())
      },
      preventDefault: true,
    }

    // Step 5.
    // Handle special cases when prev element is not crm field value.
    // By default we don't handle the ArrowDown event.  It is handled by
    // browser.  We handle it only in the special conditions tested.
    return prevNote?.textContent.trim() === ''
      ? placeCaretInPrevNote
      : view.getJottingType(jotting) === JottingType.CRM_FIELD_VALUE
      ? placeCaretInPrevNote
      : !prevNote
      ? placeCaretInFirstNote
      : null
  }
}
