import { PayloadAction } from '@reduxjs/toolkit'
import { CrmObjectName, ReduxCrmKey, ReduxCrmState, ReduxCrmStateTTL } from '../types'

export const invalidateCache = (state: ReduxCrmState, action: PayloadAction<undefined>) => {
  var crmKeys = ['sfdc', 'hubspot', 'pipedrive'] as Array<ReduxCrmKey>

  // Invalidate expired object schemas
  for (let crmKey of crmKeys) {
    if (!state[crmKey]) {
      continue
    }

    let schema = state[crmKey]?.schema

    if (!schema) {
      continue
    }

    for (let objectName in schema) {
      let objectSchema = schema[objectName as CrmObjectName]

      if (!objectSchema) {
        continue
      }

      let isCacheValid = objectSchema.lastRefreshedAt
        ? new Date().valueOf() - new Date(objectSchema.lastRefreshedAt).valueOf() <= ReduxCrmStateTTL
        : false

      if (!isCacheValid) {
        // Invalid schemas should lead to window refresh because schema is tied
        // to many other states and entities
        // TODO: Relook
        // window.location.reload()
        // delete schema[objectName as CrmObjectName]
      }
    }
  }

  // Invalidate expired records
  for (let crmKey of crmKeys) {
    if (!state[crmKey]) {
      return
    }

    let entities = state[crmKey]?.entities

    if (!entities) {
      continue
    }

    for (let objectName in entities) {
      let objectEntities = entities[objectName as CrmObjectName]

      if (!objectEntities) {
        return
      }

      for (let recordId in objectEntities) {
        let record = objectEntities[recordId]

        if (!record) {
          return
        }

        let isCacheValid = record.lastRefreshedAt
          ? new Date().valueOf() - new Date(record.lastRefreshedAt).valueOf() <= ReduxCrmStateTTL
          : false

        if (!isCacheValid) {
          delete objectEntities[recordId]
        }
      }
    }
  }
}
