import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { FetchCollectionParameter } from '../middleware/fetchCollection'

export const fetchCollection = async (
  params: Readonly<FetchCollectionParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/collections/${params.collectionId}`,
    HTTPMethod.GET,
    options
  )

  return request.make()
}
