import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { FetchNoteTemplateParameter } from '../middleware/fetchNoteTemplate'

export const fetchNoteTemplate = async (
  params: Readonly<FetchNoteTemplateParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/note-templates/${params.noteTemplateId}`,
    HTTPMethod.GET,
    options
  )

  return request.make()
}
