import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxUserState } from '../types'

export const refreshUserSettings: CaseReducer<
  ReduxUserState,
  PayloadAction<ReduxUserState>
> = (state, action) => {
  try {
    if (!action.payload) {
      return
    }

    Object.assign(state, { ...action.payload })
  } catch (e) {
    console.warn('userSlice:reducer:refreshUserSettings:', e)
  }
}
