import { createSlice } from '@reduxjs/toolkit'
import { ViewsSliceReducers as reducers } from './reducers.ts'
import { ViewsMiddleware } from './middleware'
import { EmptyViewsState } from './types'

export const viewsSlice = createSlice({
  // name: 'viewsNew',
  name: 'views',
  initialState: EmptyViewsState,
  reducers,
  extraReducers: (builder) => {
    ViewsMiddleware.createView.addAllCases(builder)
    ViewsMiddleware.fetchView.addAllCases(builder)
    ViewsMiddleware.updateView.addAllCases(builder)
    ViewsMiddleware.deleteView.addAllCases(builder)
    ViewsMiddleware.useView.addAllCases(builder)
    ViewsMiddleware.listViews.addAllCases(builder)
  },
})

export default viewsSlice.reducer

export const ViewsSliceActions = viewsSlice.actions
