// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse, LuruAPIResponseMetaData } from '../../../app/types'
import LuruError from '../../LuruError'
import { TasksAPI } from '../api'
import { ReduxTaskEntity, ReduxTasksState } from '../types'

const requestName = 'tasks/fetchTask'

export type FetchTaskParameter = {
  taskId: string
}

interface FetchTaskAPIResponse extends LuruAPIResponse {
  data: ReduxTaskEntity
}

export const fetchTask = {
  action: createAsyncThunk<
    ReduxTaskEntity,
    FetchTaskParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: null | LuruAPIResponseMetaData
      rejectedMeta: null | LuruAPIResponseMetaData
    }
  >(requestName, async (params, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    var { taskId: task_id } = params
    var currTask = getState().tasks.entities?.[task_id]?.data

    // If task is already present in redux, return the entity
    if (getState().tasks.entities?.[task_id]?.status === EntityStatus.Loaded && currTask) {
      return fulfillWithValue(currTask, null)
    }

    try {
      var response = (await TasksAPI.fetchTask(params, {
        signal,
      })) as FetchTaskAPIResponse
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }

    return fulfillWithValue(response.data, response.metadata)
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(fetchTask.action.pending, (state, action) => {
      const { taskId: task_id } = action.meta.arg

      if (state.entities[task_id]?.status !== EntityStatus.Loaded) {
        state.entities[task_id] = {
          data: null,
          status: EntityStatus.Loading,
        }
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(fetchTask.action.fulfilled, (state, action) => {
      const { taskId: task_id } = action.meta.arg
      const task = action.payload
      state.entities[task_id] = {
        data: task,
        status: EntityStatus.Loaded,
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(fetchTask.action.rejected, (state, action) => {
      state.entities[action.meta.arg.taskId].status = EntityStatus.ErrorLoading
    })
  },

  // TODO: We can do away with this after standardization
  addAllCases(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    fetchTask.addPendingCase(builder)
    fetchTask.addRejectedCase(builder)
    fetchTask.addFulfilledCase(builder)
  },
}
