import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { FetchNoteParameter } from '../middleware/fetchNote'

export default async function fetchNote(
  { noteId }: Readonly<FetchNoteParameter>,
  options: LuruAPIRequestOptions
) {
  var request = new LuruAPIRequest(
    `/api/notes/${noteId}`,
    HTTPMethod.GET,
    options
  )
  return request.make()
}
