import { Navigate, Route } from 'react-router-dom'
import SettingsHomeLazyLoading from './settings/SettingsHome'
import CollectionSettingsLazyLoading from './settings/CollectionSettings'
import EmailSettingsLazyLoading from './settings/EmailSettings'
import IntegrationSettingsLazyLoading from './settings/IntegrationSettings'
import NoteTemplatesHomeLazyLoading from './settings/NoteTemplatesHome'
import NoteTemplatesEditorEmptyStateLazyLoading from './settings/NoteTemplatesEditorEmptyState'
import NoteTemplatesEditorWrapperLazyLoading from './settings/NoteTemplatesEditorWrapper'
import UserSettingsLazyLoading from './settings/UserSettings'

const renderSettingsPageRoutes = () => (
  <Route path='/settings' element={<SettingsHomeLazyLoading />}>
    <Route index element={<Navigate to='/settings/meeting_playbooks' />} />
    <Route path='collections' element={<CollectionSettingsLazyLoading />} />
    <Route path='misc' element={<EmailSettingsLazyLoading />} />
    <Route path='integrations' element={<IntegrationSettingsLazyLoading />} />
    <Route path='meeting_playbooks' element={<NoteTemplatesHomeLazyLoading />}>
      <Route index element={<NoteTemplatesEditorEmptyStateLazyLoading />} />
      <Route path=':noteTemplateId' element={<NoteTemplatesEditorWrapperLazyLoading />} />
    </Route>
    <Route path='users' element={<UserSettingsLazyLoading />} />
  </Route>
)

export default renderSettingsPageRoutes
