import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { AlertType } from '../types'

export interface CreateWorkflowFromTemplateParameter {
  type: AlertType
  template_id: string
  tz: string
  users: string[]
  conversations: string[]
  field_names: Record<string, string>
}

export const createWorkflowFromTemplate = async (
  params: CreateWorkflowFromTemplateParameter,
  options?: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(`/api/workflows/create_from_template`, HTTPMethod.POST, options)
  request.setBody(params)

  return request.make()
}
