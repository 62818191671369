import LuruAPIRequest from '../../LuruAPIRequest'
import { PauseUnpauseWorkflowParams } from '../types'
import { HTTPMethod } from '../../../app/types'

export const pauseUnpauseWorkflow = async (params: PauseUnpauseWorkflowParams) => {
  var pauseOrUnpause = params.doPause ? 'pause' : 'unpause'
  var request = new LuruAPIRequest(`/api/workflows/${params.workflow_id}/${pauseOrUnpause}`, HTTPMethod.POST)

  request.setBody(params)

  return request.make()
}
