import React from 'react'

import styles from './LuruUserSelectBox.module.css'
import LuruUserListDisplay from '../LuruUserListDisplay'
import LuruUserSelectBoxEventHandler from './LuruUserSelectBoxEventHandler'
import { EntityStatus } from '../../../../app/types'
import { LuruUserSelectBoxConnectedProps, LuruUserSelectBoxOwnProps } from '.'
import { UsersSliceHelpers } from '../../../../features/users/helpers'

export type LuruUserSelectBoxProps = LuruUserSelectBoxConnectedProps &
  LuruUserSelectBoxOwnProps

interface LuruUserSelectBoxState {
  currentInput: string
  usersLoadingStatus: EntityStatus
  popupVisible: boolean
}

export default class LuruUserSelectBox extends React.Component<
  LuruUserSelectBoxProps,
  LuruUserSelectBoxState
> {
  props: LuruUserSelectBoxProps
  state: LuruUserSelectBoxState
  eventHandler: LuruUserSelectBoxEventHandler
  userInputRef: React.RefObject<HTMLInputElement>

  constructor(props: LuruUserSelectBoxProps) {
    super(props)
    this.props = props

    this.state = {
      currentInput: '',
      usersLoadingStatus: EntityStatus.NotLoaded,
      popupVisible: false,
    }

    this.eventHandler = new LuruUserSelectBoxEventHandler(this)
    this.userInputRef = React.createRef()
  }

  render() {
    return this.state.usersLoadingStatus === EntityStatus.Loaded
      ? this.#renderLoadedState()
      : this.state.usersLoadingStatus === EntityStatus.ErrorLoading
      ? this.#renderErrorState()
      : this.#renderLoadingState()
  }

  #renderLoadingState() {
    return this.#renderElementWithinPopup(<>Loading...</>)
  }

  #renderErrorState() {
    return this.#renderElementWithinPopup(<>Error fetching users</>)
  }

  #renderLoadedState() {
    return this.#renderElementWithinPopup(this.#getUserListElement())
  }

  #getUserListElement() {
    var list = <></>
    var selectedUsers = Array.isArray(this.props.selectedUsers)
      ? this.props.selectedUsers
      : []

    if (Array.isArray(this.props.users)) {
      let searchKey = this.state.currentInput.toLowerCase()
      let displayedUsers = this.props.users
        .filter(
          (user) =>
            user.email.toLowerCase().indexOf(searchKey) !== -1 ||
            user.first_name?.toLowerCase().indexOf(searchKey) !== -1 ||
            user.last_name?.toLowerCase().indexOf(searchKey) !== -1
        )
        .map((user) => ({
          userId: user.user_id,
          email: user.email,
          name: UsersSliceHelpers.getFormattedName(user),
          isSelected: Boolean(
            selectedUsers.find((s) => s.email === user.email)
          ),
        }))

      if (displayedUsers.length > 0) {
        list = (
          <LuruUserListDisplay
            users={displayedUsers}
            onChooseUser={this.eventHandler.handlers.onChooseUser}
            isSelectableList={this.props.isSelectableList}
          />
        )
      } else {
        list = <div className={styles.noMatches}>No users</div>
      }
    } else {
      list = <>Error fetching users</>
    }

    return list
  }

  #renderElementWithinPopup(element: JSX.Element) {
    return (
      <div className={styles.parent}>
        <input
          ref={this.userInputRef}
          type='text'
          placeholder={this.props.placeholder}
          onChange={this.eventHandler.handlers.onUserInputChange}
          onFocus={this.eventHandler.handlers.onUserInputFocus}
          onBlur={this.eventHandler.handlers.onUserInputBlur}
          value={this.state.currentInput}
        />
        <div
          className={[
            styles.popupMenu,
            this.state.popupVisible ? styles.visible : styles.hidden,
          ].join(' ')}
          data-role='luru-user-select-box-popup-menu'
        >
          {element}
        </div>
      </div>
    )
  }
}
