import { createRef, forwardRef, useImperativeHandle } from 'react'
import { CrmActivity, CrmActivityLogType, SorRecord } from '../../../../features/crm/types'
import Modal from '../../../Modal'
import styles from './styles.module.css'
import LuruTextBox from '../../../ui/LuruTextBox'
import LuruDatePicker from '../../../ui/LuruDatePicker'
import CrmMultiObjectSelectBox from '../../../domain/crm/CrmMultiObjectSelectBox'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import OvalButton from '../../../OvalButton'
import { EntityStatus } from '../../../../app/types'
import useCreateActivityLogModalComponentState from './useComponentState'
import useCreateActivityLogModalEventHandlers from './useEventHandlers'
import LuruTimePicker from '../../../LuruTimePicker'
import { StringUtils } from '@/utils/stringUtils'
import LuruButton from '@/primitives/ui/LuruButton'
import { ValuesType } from '@/primitives/domain/crm/CrmMultiObjectSelectBox/CrmMultiObjectSelectBoxComponent'

export interface CreateActivityLogModalProps {
  activityLogType: CrmActivityLogType
  noteID: string
  sorRecord?: Omit<SorRecord, 'sor_record_name'>
  onLogSuccess?: (data: CrmActivity) => void
  onClose?: () => void
  timePicked?: Date
}

export interface CreateActivityLogModalRefsHandler {
  show: (
    stateObj?: Partial<CreateActivityLogModalState>,
    draftActivity?: Partial<CrmActivity & { time_picked?: Date; duration: string }>
  ) => void
  hide: () => void
}

export interface CreateActivityLogModalState {
  show: boolean
  activityLogType: CrmActivityLogType
  createLogStatus?: EntityStatus
  requiredFields: Array<string>
  formValidation?: {
    ok: boolean
    message: string
    missingFields?: {
      field: string
      message?: string | undefined
    }[]
  }
}

export interface CreateActivityLogModalRefs {
  titleTextBoxRef: React.RefObject<LuruTextBox>
}

const CreateActivityLogModal = forwardRef<CreateActivityLogModalRefsHandler, CreateActivityLogModalProps>(
  (props: CreateActivityLogModalProps, ref) => {
    const { sorRecord, onClose } = props
    const componentState = useCreateActivityLogModalComponentState(props)
    const { getState: state } = componentState.state
    const { getDraftActivity: draftActivity, setDraftActivity } = componentState.draftActivity
    const refs: CreateActivityLogModalRefs = {
      titleTextBoxRef: createRef<LuruTextBox>(),
    }

    const { handleHideModal, handleShowModal, handleCreateActivity, getHourAndMinute } =
      useCreateActivityLogModalEventHandlers(componentState, props, refs)
    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleHideModal,
    }))

    const renderCreateActivityForm = () => {
      const crmPrimaryRecordType = CrmRecord.getAllPrimaryObjects()

      if (!sorRecord) {
        return <div>Sor record is empty</div>
      }
      return (
        <div className={styles.parentStyle}>
          {state.formValidation && !state.formValidation.ok && (
            <div className={styles.error}>{state.formValidation?.message}</div>
          )}
          <div className={[styles.formItem, styles.nameDiv].join(' ')}>
            <label>Meeting name {state.requiredFields.includes('title') ? '*' : ''}</label>
            <LuruTextBox
              onChange={(value) => setDraftActivity((prevState) => ({ ...prevState, title: value as string }))}
              ref={refs.titleTextBoxRef}
            />
          </div>

          {/* <div className={[styles.formItemGroupHorizontal, styles.wrap].join(' ')}> */}
          <div className={[styles.formItem, styles.dateDiv].join(' ')}>
            <label>Meeting date {state.requiredFields.includes('start_time') ? '*' : ''}</label>
            <LuruDatePicker
              onValuePicked={(value) =>
                setDraftActivity((prevState) => ({ ...prevState, start_time: value as string }))
              }
              value={draftActivity.start_time}
              alignment='left'
            />
          </div>
          {/* </div> */}

          {/* <div className={[styles.formItemGroupHorizontal, styles.wrap].join(' ')}> */}
          <div className={[styles.formItem, styles.timeDiv].join(' ')}>
            <label>Start time {state.requiredFields.includes('time_picked') ? '*' : ''}</label>

            <LuruTimePicker
              onChange={({ value }) => value && setDraftActivity((prevState) => ({ ...prevState, time_picked: value }))}
              //  value={draftActivity.time_picked ? new Date(draftActivity.time_picked) : undefined}
              hideClockPicker={true}
              width='100px'
              prevChoosenValue={getHourAndMinute}
            />
          </div>
          <div className={[styles.formItem, styles.durationDiv].join(' ')}>
            <label>Duration (min) {state.requiredFields.includes('duration') ? '*' : ''}</label>
            <LuruTextBox
              onChange={(value) => setDraftActivity((prevState) => ({ ...prevState, duration: value || '' }))}
              type='number'
              defaultValue={draftActivity.duration?.toString?.()}
              width='80px'
            />
          </div>
          {/* </div> */}

          {/* <div className={[styles.formItemGroupHorizontal, styles.wrap].join(' ')}> */}
          <div className={[styles.formItem, styles.contactDiv].join(' ')}>
            <label>Associated contacts {state.requiredFields.includes('attendees') ? '*' : ''}</label>
            <CrmMultiObjectSelectBox
              emptyNode={<OvalButton subdued>Choose records</OvalButton>}
              doNotResetOnCancel={true}
              sorObjectName={CrmRecord.getCrmRecordName(crmPrimaryRecordType.CONTACT)}
              values={[]}
              onFinishSelection={(values: ValuesType[]) =>
                setDraftActivity((prevState) => ({ ...prevState, attendees: values }))
              }
            />
          </div>
          {/* </div> */}
        </div>
      )
    }

    return (
      <Modal
        titleText={`Log ${state.activityLogType}`}
        onClose={() => {
          handleHideModal()
          onClose?.()
        }}
        show={state.show}
        modalContentClass={styles.modalContent}
      >
        <div className={styles.modalBody}>{renderCreateActivityForm()}</div>
        <div className={styles.actionButtons}>
          <LuruButton
            role='create-crm-log-cancel-button'
            onClick={() => {
              handleHideModal()
              onClose?.()
            }}
            title='Cancel'
            variant='outline'
          >
            Cancel
          </LuruButton>
          <LuruButton
            role='create-crm-log-button'
            onClick={handleCreateActivity}
            disabled={state.createLogStatus === EntityStatus.Creating}
            title='Log Meeting'
            loading={state.createLogStatus === EntityStatus.Creating}
          >
            {state.createLogStatus === EntityStatus.Creating
              ? 'Logging...'
              : `Log ${StringUtils.toTitleCase(state.activityLogType)}`}
          </LuruButton>
        </div>
      </Modal>
    )
  }
)

export default CreateActivityLogModal
