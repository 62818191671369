import { Route } from 'react-router-dom'
import NotesEditorWrapperLazyLoading from './notes/NotesEditorWrapper'
import MeetingNotesHomeLazyLoading from './meeting_notes/MeetingNotesHome'
import MeetingNotesEditorEmptyStateLazyLoading from './meeting_notes/MeetingNotesEditorEmptyState'
import PrivateRoute, { WebAppTabs } from './PrivateRoute'

const renderMeetingNotesPageRoutes = () => (
  <Route
    path='/meeting_notes'
    element={
      <PrivateRoute tab={WebAppTabs.MEETING_NOTES}>
        <MeetingNotesHomeLazyLoading />
      </PrivateRoute>
    }
  >
    <Route index element={<MeetingNotesEditorEmptyStateLazyLoading />} />
    <Route path=':noteId' element={<NotesEditorWrapperLazyLoading />} />
  </Route>
)

export default renderMeetingNotesPageRoutes
