import React from 'react'
import { Link } from 'react-router-dom'

import CreateSearchRecordDialogEventHandler from './CreateSearchRecordDialogEventHandler'

import ModalScreen from '../../primitives/ModalScreen'
import TypeAheadSearchBox from '../../primitives/TypeAheadSearchBox'

import { ConnectLuruExtensionsProps, EntityStatus } from '../../app/types'
import { SearchCrmRecordsResultItem } from '../../domain/crmRecord/typings.d'
import CrmRecord from '../../domain/crmRecord/CrmRecord'

import styles from './styles.module.css'

// Icons
import notesIcon from '../../images/fluent/note.svg'
import meetingsIcon from '../../images/fluent/calendar_ltr.svg'
import templatesIcon from '../../images/fluent/form.svg'
import tasksIcon from '../../images/fluent/task_list_square_ltr.svg'
import { AppComponents } from '../../primitives/AppComponents/typings'
import Utils from '../../utils/Utils'
import LuruUser from '../../domain/users/LuruUser'
import LuruSelectBox from '../../primitives/ui/LuruSelectBox'
import LoadingSpinner from '../../primitives/LoadingSpinner'
import LuruButton from '../../primitives/ui/LuruButton'
import AppComponentsContext from '../../primitives/AppComponents/AppComponentsContext'
import { ReduxTaskEntity } from '../../features/tasks/types'
import { trackEvent } from '../../analytics/Ga'
import { ReduxNoteEntity } from '../../features/notes/types'
import { SearchMeetingsAPIResponseEntity } from '../../features/meetings/middleware/searchMeetings'
import personIcon from '../../images/fluent/person.svg'
import checkBoxBlankIcon from '../../images/fluent/check_box_blank.svg'
import checkBoxFilledIcon from '../../images/fluent/check_box_filled.svg'
import eventIcon from '../../images/fluent/calendar_ltr.svg'
import { CrmRecordType } from '../../features/crm/types'
import { CRMProvider } from '@/features/user/types'

export interface CreateSearchRecordDialogProps extends ConnectLuruExtensionsProps {
  appComponents: AppComponents
}

export enum CreateSearchRecordDialogMode {
  CREATE = 'CREATE',
  SEARCH = 'SEARCH',
}

export enum SearchCategory {
  NOTE = 'NOTE',
  TASK = 'TASK',
  CRM_OBJECT = 'CRM_OBJECT',
  MEETING = 'MEETING',
}

export interface CreateSearchRecordDialogState {
  mode: CreateSearchRecordDialogMode
  searchText: string
  search: Record<
    SearchCategory,
    {
      status: EntityStatus
      results: Array<any>
      error?: any
    }
  >
  noteSearchCategory: 'luru' | 'sor'
}

interface CreateSearchRecordDialogRefs {
  modal: React.RefObject<ModalScreen>
  searchBox: React.RefObject<typeof TypeAheadSearchBox>
}

export type AllNotesSearchResults = {
  data: Array<ReduxNoteEntity>
  metadata: {
    count: number
    cursor: string | null
  }
}

export type MeetingParticipant = {
  name: string | null
  email: string
  optional: boolean
}

export type MeetingsSearchResults = {
  data: Array<SearchMeetingsAPIResponseEntity>
  metadata: { count: number; cursor: string }
}

export default class CreateSearchRecordDialogComponent extends React.Component<
  CreateSearchRecordDialogProps,
  CreateSearchRecordDialogState
> {
  props: CreateSearchRecordDialogProps
  state: CreateSearchRecordDialogState
  componentRefs: CreateSearchRecordDialogRefs
  eventHandler: CreateSearchRecordDialogEventHandler
  static contextType = AppComponentsContext

  constructor(props: CreateSearchRecordDialogProps) {
    super(props)
    this.props = props
    this.state = this.getResetState()
    this.componentRefs = {
      modal: React.createRef(),
      searchBox: React.createRef(),
    }
    this.eventHandler = new CreateSearchRecordDialogEventHandler(this)
  }

  getResetState(searchStatus: EntityStatus = EntityStatus.Idle): CreateSearchRecordDialogState {
    var searchState = {} as Record<SearchCategory, { status: EntityStatus; results: Array<any>; error?: any }>
    var calendarProvider = LuruUser.getCurrentUserCalendarName()
    var crmProvider = LuruUser.getCurrentUserCrmName()

    for (let key in SearchCategory) {
      searchState[key as SearchCategory] = {
        status: crmProvider === CRMProvider.PIPEDRIVE && key === SearchCategory.TASK ? EntityStatus.Idle : searchStatus,
        results: [],
      }
    }

    if (!calendarProvider) {
      searchState[SearchCategory.MEETING].status = EntityStatus.Idle
    }

    return {
      mode: CreateSearchRecordDialogMode.CREATE,
      searchText: '',
      search: searchState,
      noteSearchCategory: 'luru',
    }
  }

  isSearchLoading() {
    for (let key in this.state.search) {
      if (this.state.search[key as SearchCategory].status === EntityStatus.Loading) {
        return true
      }
    }
    return false
  }

  showDialog() {
    this.componentRefs?.modal.current?.showModal({
      cancel: this.resetState.bind(this),
    })
    setTimeout(() => this.focusSearchBox())
  }

  focusSearchBox() {
    var modalEl = document.body.querySelector('[data-luru-role="create-or-search-record-dialog"]')
    var searchContainerEl = modalEl?.querySelector('[data-luru-role="search-box-container"]')
    var searchBox = searchContainerEl?.querySelector('input[type="search"]') as HTMLInputElement
    searchBox?.focus()
  }

  render() {
    return (
      <ModalScreen
        ref={this.componentRefs.modal}
        width='600px'
        height='auto'
        hideButtons={true}
        title={`Create or Search for Records`}
        titleCloseButton={true}
        dialogRole='create-or-search-record-dialog'
      >
        <div className={styles.parent}>
          <div className={styles.searchBox} data-luru-role='search-box-container'>
            <TypeAheadSearchBox
              // @ts-ignore
              placeholder='Search for records, tasks, notes'
              ariaLabel='Search for records, tasks, notes'
              searchText={this.state.searchText}
              onKeyDown={this.eventHandler.handlers.onSearchBoxKeyDown}
              onTriggerSearch={this.eventHandler.handlers.onTriggerSearch}
              onClearSearch={this.eventHandler.handlers.onClearSearch}
              ref={this.componentRefs.searchBox}
              loading={this.isSearchLoading()}
            />
          </div>
          {this.state.mode === CreateSearchRecordDialogMode.CREATE
            ? this.#renderCreateElements()
            : this.#renderSearchElements()}
        </div>
      </ModalScreen>
    )
  }

  #renderCreateElements() {
    var crmId = LuruUser.getCurrentUserCrmName()
    var crmName = CrmRecord.getCrmName()

    return (
      <>
        <h1>Luru</h1>
        <div className={styles.records}>
          <ul>
            <li onClick={this.eventHandler.handlers.onCreateNote}>
              <img src={notesIcon} alt='Note' className={styles.notesIcon} />
              <span>Create Note</span>
            </li>
            <li onClick={this.eventHandler.handlers.onCreateNoteTemplate}>
              <img src={templatesIcon} alt='Playbook' />
              <span>Create Meeting Playbook</span>
            </li>
          </ul>
        </div>
        <h1>{crmName}</h1>
        <div className={styles.records}>
          <ul>
            {CrmRecord.getPrimaryCrmObjects().map(
              (obj: { name: string; singular: string; crmRecordType: CrmRecordType }) => (
                <li
                  key={obj.name}
                  className={styles.noFilter}
                  onClick={() => this.eventHandler.handlers.onCreateCrmRecord(obj.crmRecordType)}
                >
                  <img
                    src={CrmRecord.getIcon(obj.crmRecordType)}
                    alt={obj.name}
                    data-crm-id={crmId}
                    data-record-type={obj.crmRecordType}
                  />
                  <span>Create {obj.singular}</span>
                </li>
              )
            )}
            <li onClick={this.eventHandler.handlers.onCreateTask}>
              <img src={tasksIcon} alt='Task' />
              <span>Create Task</span>
            </li>
          </ul>
        </div>
      </>
    )
  }

  #renderSearchElements() {
    return (
      <div className={styles.allSearchResults}>
        {Object.keys(this.state.search).map((category) => this.#renderSearchCategory(category as SearchCategory))}
      </div>
    )
  }

  categoryName = {
    [SearchCategory.NOTE]: 'Notes',
    [SearchCategory.MEETING]: 'Meetings',
    [SearchCategory.TASK]: 'Tasks',
    [SearchCategory.CRM_OBJECT]: CrmRecord.getCrmName(),
  }

  categoryIcon = {
    [SearchCategory.NOTE]: notesIcon,
    [SearchCategory.MEETING]: meetingsIcon,
    [SearchCategory.TASK]: tasksIcon,
    [SearchCategory.CRM_OBJECT]: CrmRecord.getCrmIcon(),
  }

  #renderSearchCategory(category: SearchCategory) {
    var categoryName = this.categoryName[category]
    var isCrmIcon = ![SearchCategory.NOTE, SearchCategory.MEETING, SearchCategory.TASK].includes(category)
    var calendarProvider = LuruUser.getCurrentUserCalendarName()
    var crmProvider = LuruUser.getCurrentUserCrmName()

    if (!categoryName) {
      return
    }

    if (!calendarProvider && category === SearchCategory.MEETING) {
      return null
    }

    if (crmProvider === CRMProvider.PIPEDRIVE && category === SearchCategory.TASK) {
      return null
    }

    return (
      <React.Fragment key={category}>
        <h2 className={styles.searchCategoryTitle}>
          {this.categoryIcon[category] !== undefined ? (
            <img
              src={this.categoryIcon[category]}
              alt={this.categoryName[category]}
              className={isCrmIcon ? styles.noFilter : ''}
              data-luru-role={`result-icon-${category}`}
            />
          ) : null}
          {category === SearchCategory.NOTE
            ? this.state.noteSearchCategory === 'luru'
              ? 'Luru'
              : CrmRecord.getCrmName()
            : null}{' '}
          {this.categoryName[category]}
        </h2>
        <div className={styles.searchResults}>
          {this.state.search[category].status === EntityStatus.ErrorLoading ? (
            this.#renderSearchErrorResults(category)
          ) : this.state.search[category].status === EntityStatus.Loading ? (
            <div className={styles.loading}>Loading...</div>
          ) : (
            this.#renderSearchResults(category)
          )}
        </div>
      </React.Fragment>
    )
  }

  #renderSearchErrorResults(category: SearchCategory) {
    if (category === SearchCategory.MEETING) {
      const error = this.state.search?.[category]?.error
      // 10100 = No Calendar Connection or 10104 = Calendar view permission not given
      if (error?.error_code === 10100 || error?.error_code === 10104) {
        return (
          <div className={styles.error}>
            <LuruButton
              onClick={() => {
                var appComponents = this.context as AppComponents
                this.componentRefs.modal.current?.cancel?.()
                appComponents.connectCalendarModal?.current?.showModal()
              }}
              title='Connect your calendar'
            >
              Connect your calendar
            </LuruButton>
          </div>
        )
      } else {
        return <div className={styles.error}>Error occurred</div>
      }
    } else {
      return <div className={styles.error}>Error occurred</div>
    }
  }

  #renderSearchResults(category: SearchCategory) {
    var results = this.state.search[category].results

    return results?.length > 0 ? (
      <>
        <ul className={styles.resultsList} data-luru-role={`search-results-${category}`}>
          {results.map((result) => (
            <li key={this.#getUniqueResultItemKey(category, result)}>{this.#formatResultItem(category, result)}</li>
          ))}
        </ul>
        {category === SearchCategory.NOTE && this.state.noteSearchCategory === 'luru' ? (
          <button
            type='button'
            className={styles.loadMore}
            onClick={this.eventHandler.handlers.onTriggerSORNotesSearch}
            data-luru-role={`show-more-button-${category}`}
          >
            Search {CrmRecord.getCrmName()} notes
          </button>
        ) : null}
        {/* {results.length > 5 ? (
          <button
            type='button'
            className={styles.loadMore}
            onClick={() =>
              this.eventHandler.handlers.onResultDisplayCountAdjust(category)
            }
            data-luru-role={`show-more-button-${category}`}
          >
            More
          </button>
        ) : null} */}
      </>
    ) : (
      <div className={styles.noResults}>No results found</div>
    )
  }

  #getUniqueResultItemKey(category: SearchCategory, item: any) {
    switch (category) {
      case SearchCategory.NOTE:
        return (item as ReduxNoteEntity).note_id

      case SearchCategory.MEETING:
        return (item as SearchMeetingsAPIResponseEntity).meeting_id

      case SearchCategory.TASK:
        return (item as ReduxTaskEntity).task_id

      case SearchCategory.CRM_OBJECT:
        return (item as SearchCrmRecordsResultItem).sor_record_id
    }
  }

  #formatResultItem(category: SearchCategory, item: any) {
    switch (category) {
      case SearchCategory.NOTE:
        return this.#renderNoteResultItem(item as ReduxNoteEntity)

      case SearchCategory.MEETING:
        return this.#renderMeetingResultItem(item as SearchMeetingsAPIResponseEntity)

      case SearchCategory.TASK:
        return this.#renderTaskResultItem(item as ReduxTaskEntity)

      case SearchCategory.CRM_OBJECT:
        return this.#renderCrmRecordItem(item as SearchCrmRecordsResultItem)
    }
  }

  #renderNoteResultItem(noteItem: ReduxNoteEntity) {
    let crmConnection = noteItem.connections?.find((c) => c.sor === LuruUser.getCurrentUserCrmName())
    let sorObjectName = crmConnection?.sor_object_name ?? ''
    let crmRecordType = CrmRecord.getCrmRecordType(sorObjectName)
    let sorRecordName = crmConnection?.sor_record_name

    return (
      <Link
        to={`/notes/${noteItem.note_id}`}
        onClick={() => {
          this.closeModal()
          trackEvent('accessed_search_note_result', crmConnection ? 'SoROnly' : 'Luru')
        }}
      >
        <div className={styles.noteResult}>
          <label className={styles.title}>{noteItem.title}</label>
          <span className={styles.timestamp}>{Utils.formatDate(noteItem.updated_at ?? noteItem.created_at)}</span>
          {crmConnection ? (
            <>
              <span
                className={styles.icon}
                onClick={(e) =>
                  crmConnection &&
                  this.eventHandler.handlers.onClickCrmRecord(
                    e,
                    crmConnection.sor_object_name,
                    crmConnection.sor_record_id,
                    crmConnection.sor_record_name
                  )
                }
              >
                <img src={CrmRecord.getIcon(crmRecordType)} alt={sorObjectName} />
              </span>
              <span
                className={styles.recordName}
                onClick={(e) =>
                  crmConnection &&
                  this.eventHandler.handlers.onClickCrmRecord(
                    e,
                    crmConnection.sor_object_name,
                    crmConnection.sor_record_id,
                    crmConnection.sor_record_name
                  )
                }
              >
                {sorRecordName || 'No Name'}
              </span>
            </>
          ) : (
            <>
              <span className={styles.icon}>
                <img src={notesIcon} alt='Private note' />
              </span>
              <span className={styles.recordName}>Private note</span>
            </>
          )}
        </div>
      </Link>
    )
  }

  #renderMeetingResultItem(item: SearchMeetingsAPIResponseEntity) {
    var connectedNotes = item.notes
    var currentUserEmail = LuruUser.getCurrentUserEmail()
    var currentUserDomain = LuruUser.getCurrentUserDomain()
    var externalParticipant =
      item.participants.length > 1
        ? item.participants.find((i) => i.email.slice(i.email.indexOf('@') + 1) !== currentUserDomain)
        : undefined
    var externalParticipantName = externalParticipant?.name ?? externalParticipant?.email
    var internalParticipant =
      externalParticipantName === undefined ? item.participants.find((i) => i.email !== currentUserEmail) : undefined
    var internalParticipantName = internalParticipant?.name ?? internalParticipant?.email
    var participantDesc =
      externalParticipantName !== undefined
        ? externalParticipantName +
          ' + ' +
          (item.participants.length - 1) +
          ' other' +
          (item.participants.length > 2 ? 's' : '')
        : internalParticipantName !== undefined && internalParticipantName !== null
        ? internalParticipantName + (item.participants.length > 1)
          ? ' + ' + (item.participants.length - 1) + ' other' + (item.participants.length > 2 ? 's' : '')
          : ''
        : ''

    return (
      <div className={styles.meetingResult}>
        <label className={styles.title}>{item.subject}</label>
        <span className={styles.timestamp}>{Utils.formatDateTime(item.start_time)}</span>
        {connectedNotes.length === 0 ? (
          <span
            className={[styles.meetingNoteTitle, styles.buttonish].join(' ')}
            onClick={() => this.eventHandler.handlers.onCreateMeetingNote(item)}
          >
            + Add note
          </span>
        ) : connectedNotes.length === 1 ? (
          <span className={styles.meetingNoteTitle}>
            <Link
              to={`/meeting_notes/${connectedNotes[0].note_id}`}
              onClick={() => {
                trackEvent('accessed_search_meeting_note_result', LuruUser.getCurrentUserCrmName())
              }}
            >
              {connectedNotes[0].title}
            </Link>
          </span>
        ) : (
          <span className={styles.meetingNoteTitle}>
            <LuruSelectBox
              items={connectedNotes.map((note) => ({
                name: note.title,
                key: note.note_id,
              }))}
              onChooseItem={this.eventHandler.handlers.onChooseMeetingNote}
              leftAlign={true}
              subdued={true}
              prechosenItem={`${connectedNotes.length} meeting notes`}
              menuParentSelector={'[data-luru-role="create-or-search-record-dialog"]'}
            />
          </span>
        )}
        <span className={styles.trailingIcon}>
          <span title={participantDesc}>
            <img src={personIcon} alt='person' width='15px' />
          </span>
        </span>
      </div>
    )
  }

  #renderTaskResultItem(item: ReduxTaskEntity) {
    var connection = item.connections.length > 0 ? item.connections[0] : undefined
    var crmRecordType = connection ? CrmRecord.getCrmRecordType(connection.sor_object_name) : undefined
    var crmRecordIcon = crmRecordType ? CrmRecord.getIcon(crmRecordType) : undefined
    var crmRecordName = connection ? connection.sor_record_name : ''

    return (
      <div className={styles.taskResult} onClick={() => this.eventHandler.handlers.onChooseTask(item)}>
        <span className={styles.checkbox} onClick={(e) => this.eventHandler.handlers.onToggleTask(e, item)}>
          <span className={styles.spinnerContainer}>
            <LoadingSpinner size={6} color='#333' />
          </span>
          <span className={styles.box}>
            <img
              src={item.status === 'OPEN' ? checkBoxBlankIcon : checkBoxFilledIcon}
              alt={item.status === 'OPEN' ? 'checkbox_blank' : 'checkbox_checked'}
            />
          </span>
        </span>
        <label className={styles.title}>{item.title}</label>
        <span
          className={[
            styles.timestamp,
            ((item?.due_date ? new Date(item.due_date).valueOf() : null) ?? Number.MAX_SAFE_INTEGER) <
            new Date().valueOf()
              ? styles.overdue
              : '',
            !item.due_date ? styles.noduedate : '',
          ]
            .join(' ')
            .trim()}
        >
          <span className={styles.icon}>
            <img src={eventIcon} alt='event' />
          </span>
          {item.due_date ? Utils.formatDate(item.due_date) : 'No due date'}
        </span>
        <span className={styles.assignee}>
          <span className={styles.icon}>
            <img src={personIcon} alt='person' />
          </span>
          <span className={styles.assigneeNames}>
            {item.assigned_to ? item.assigned_to.sor_record_name : 'Not assigned'}
          </span>
        </span>
        <span
          className={styles.trailingIcon}
          title={`${connection?.sor_object_name}: ${crmRecordName}`}
          onClick={(e) =>
            connection &&
            this.eventHandler.handlers.onClickCrmRecord(
              e,
              connection.sor_object_name,
              connection.sor_record_id,
              connection.sor_record_name
            )
          }
        >
          {crmRecordIcon ? <img src={crmRecordIcon} alt={crmRecordName} /> : null}
        </span>
      </div>
    )
  }

  #renderCrmRecordItem(item: SearchCrmRecordsResultItem) {
    var objectName = item.sor_object_name
    var crmRecordType = CrmRecord.getCrmRecordType(objectName)

    return (
      <div
        className={styles.crmResult}
        onClick={(e) =>
          this.eventHandler.handlers.onClickCrmRecord(
            e,
            item.sor_object_name,
            item.sor_record_id,
            item.sor_record_name?.trim?.() || 'No Name'
          )
        }
      >
        <span className={styles.recordIcon}>
          <img src={CrmRecord.getIcon(crmRecordType)} alt={objectName} />
        </span>
        <label className={styles.title}>{item.sor_record_name || 'No Name'}</label>
      </div>
    )
  }

  closeModal() {
    this.componentRefs.modal.current?.cancel()
  }

  resetState() {
    this.setState(this.getResetState())
  }
}
