import { ReduxViewEntity } from '../types'

export const computeDisplaySettingsOnFieldOrderChange = (
  view: ReduxViewEntity | undefined,
  sourceIndex: number,
  targetIndex: number
) => {
  var displayInfo = view?.output.schema?.fields
    .map((f, ix) => ({
      field: f.field,
      display:
        view?.display_settings?.field_settings?.[f.field]?.display ??
        view?.display_settings?.field_settings?.defaults?.display ??
        true,
      width: view?.display_settings?.field_settings?.[f.field]?.width,
      display_order: view?.display_settings?.field_settings?.[f.field]?.display_order ?? ix,
    }))
    .sort((a, b) => (a.display_order < b.display_order ? -1 : 0))

  if (!displayInfo) {
    return
  }

  var visibleFields = displayInfo.filter((f) => f.display)

  if (
    !(0 <= sourceIndex && sourceIndex <= visibleFields.length) ||
    !(0 <= targetIndex && targetIndex <= visibleFields.length)
  ) {
    return
  }

  var srcField = visibleFields[sourceIndex]
  var tgtField = visibleFields[targetIndex]

  sourceIndex = displayInfo.findIndex((f) => f.field === srcField.field)
  targetIndex = displayInfo.findIndex((f) => f.field === tgtField.field)
  // Swap the source and target display_order
  displayInfo[sourceIndex].display_order = tgtField.display_order
  displayInfo[targetIndex].display_order = srcField.display_order

  var newFieldSettings = displayInfo
    .map((fieldSettings, index) => {
      var newDisplayOrder = fieldSettings.display_order
      if (fieldSettings.display_order === -1 || fieldSettings.display_order === undefined) {
        newDisplayOrder = fieldSettings.display_order
      } else if (fieldSettings.display && index !== sourceIndex) {
        if (sourceIndex < targetIndex && index > sourceIndex && index <= targetIndex) {
          // Shift down if the source is before the target
          newDisplayOrder = fieldSettings.display_order - 1
        } else if (sourceIndex > targetIndex && index >= targetIndex && index < sourceIndex) {
          // Shift up if the source is after the target
          newDisplayOrder = fieldSettings.display_order + 1
        }
      }

      return {
        ...fieldSettings,
        // display_order:
        //   fieldSettings.display_order === -1 ||
        //   fieldSettings.display_order === undefined ||
        //   (fieldSettings.display_order < sourceIndex && fieldSettings.display_order < targetIndex) ||
        //   (fieldSettings.display_order > sourceIndex && fieldSettings.display_order > targetIndex) ||
        //   sourceIndex === targetIndex
        //     ? fieldSettings.display_order
        //     : fieldSettings.display_order === sourceIndex
        //     ? targetIndex
        //     : sourceIndex < targetIndex
        //     ? fieldSettings.display_order - 1
        //     : fieldSettings.display_order + 1,
        display_order: newDisplayOrder,
      }
    })
    .reduce(
      (prev, curr) =>
        curr.width
          ? {
              ...prev,
              [curr.field]: {
                display: curr.display,
                display_order: curr.display_order,
                width: curr.width,
              },
            }
          : {
              ...prev,
              [curr.field]: {
                display: curr.display,
                display_order: curr.display_order,
              },
            },
      {}
    )

  return {
    ...view?.display_settings,
    field_settings: {
      defaults: view?.display_settings?.field_settings?.defaults ?? {},
      ...newFieldSettings,
    },
  }
}
