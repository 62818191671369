import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { SignOutUserPayload } from '../../user/reducers/signOutUser'
import { ReduxTasksState } from '../types'

export const userSliceSignOutUser: CaseReducer<ReduxTasksState, PayloadAction<SignOutUserPayload>> = (
  state,
  action
) => {
  Object.keys(state).forEach((key) => delete state[key as keyof ReduxTasksState])
  Object.assign(state, action.payload?.tasks)
}
