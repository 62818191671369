import React from 'react'
import { LuruCrmFieldInputConnectedProps } from '.'
import { EntityStatus } from '../../../../app/types'
import {
  CrmFieldSchema,
  CrmObjectSchema,
  CrmRecordFieldsData,
  LuruFieldType,
  LuruHierarchialPicklistValue,
} from '../../../../domain/crmRecord/typings.d'
import LuruCrmFieldInputEventHandler from './LuruCrmFieldInputEventHandler'

// Styles
import styles from './styles.module.css'

// Assets
import errorIcon from '../../../../images/error.svg'
import undoIcon from '../../../../images/undo.svg'
import LuruTextBox, { LuruTextBoxProps } from '../../../ui/LuruTextBox'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import LuruSelectBox from '../../../ui/LuruSelectBox'
import LuruMultiSelectBox from '../../../ui/LuruMultiSelectBox'
import CrmObjectSelectBox from '../CrmObjectSelectBox'
import CrmMultiObjectSelectBox from '../CrmMultiObjectSelectBox'
import Utils from '../../../../utils/Utils'
import moment from 'moment'
import LuruHierarchicalPicklist from '../../../ui/LuruHierarchicalPicklist'
import { CrmRecordType } from '../../../../features/crm/types'
import LuruCrmFieldInputLabel from './LuruCrmFieldInputLabel'
import LuruTextArea, { LuruTextAreaProps } from '@/primitives/ui/LuruTextArea'

export interface LuruCrmFieldInputOwnProps {
  // Object type
  crmRecordType: CrmRecordType
  // Field name
  fieldName: string
  className?: string
  // SOR record id, if given, value is loaded
  sorRecordId?: string
  // Size of input field
  size?: 'narrow' | 'normal' | 'large' | 'wide'
  // Display label above input
  displayLabel?: boolean
  // Display warning alert
  warningState?: boolean
  // Disabled state?
  disabled?: boolean
  // Stacking anchor for popups
  menuParentSelector?: string
  // Show edited status on field value change?
  showEditedStatus?: boolean
  // Show erroneous status?
  showErroneousStatus?: boolean
  // Controller field value
  // Lets the component know the value of a controller field
  controllerFieldValue?: string
  //// Callbacks
  // Call back on blur of editable field (for text box fields)
  onBlur?: (value: any, source: LuruCrmFieldInputComponent) => void
  // Call back on value change (for non-text box fields)
  onChange?: (value: any, source: LuruCrmFieldInputComponent) => void
  // Call back on user attempting to navigate up
  onNavigateUp?: () => void
  // ...down
  onNavigateDown?: () => void
  // ...left
  onNavigateLeft?: () => void
  // ...right
  onNavigateRight?: () => void
  // defaultValue
  defaultValue?: string
  // Mandatory
  isMandatory?: boolean
}

export type LuruCrmFieldInputProps = LuruCrmFieldInputOwnProps & LuruCrmFieldInputConnectedProps

export interface LuruCrmFieldInputState {
  isSchemaLoaded: boolean
  isFieldValueLoaded: boolean
  componentStatus: LuruCrmFieldInputStatus
  errorMessage?: string
  fieldValue?: any
  displayedValue?: string
}

export enum LuruCrmFieldInputStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

interface LuruCrmFieldInputComponentRefs {
  textBoxRef: React.RefObject<LuruTextBox>
  textAreaBoxRef: React.RefObject<LuruTextArea>
  selectLayerRef: React.RefObject<HTMLDivElement>
}

export default class LuruCrmFieldInputComponent extends React.Component<
  LuruCrmFieldInputProps,
  LuruCrmFieldInputState
> {
  #fieldSchema?: CrmFieldSchema
  props: LuruCrmFieldInputProps
  state: LuruCrmFieldInputState
  eventHandler: LuruCrmFieldInputEventHandler
  componentRefs: LuruCrmFieldInputComponentRefs

  constructor(props: LuruCrmFieldInputProps) {
    super(props)
    this.props = props
    this.state = {
      isSchemaLoaded: false,
      isFieldValueLoaded: false,
      componentStatus: LuruCrmFieldInputStatus.IDLE,
      fieldValue: props.defaultValue,
    }
    this.eventHandler = new LuruCrmFieldInputEventHandler(this)
    this.componentRefs = {
      textBoxRef: React.createRef(),
      textAreaBoxRef: React.createRef(),
      selectLayerRef: React.createRef(),
    }
    this.itemNameFormatter = this.itemNameFormatter.bind(this)
    this.labelFormatter = this.labelFormatter.bind(this)
  }

  getValidFieldValue = () => {
    if (
      !this.isControlledField() ||
      (this.#fieldSchema?.luruFieldType !== LuruFieldType.ENUM &&
        this.#fieldSchema?.luruFieldType !== LuruFieldType.MULTIENUM)
    ) {
      return this.state.fieldValue
    }

    return this.#fieldSchema?.luruFieldType === LuruFieldType.ENUM
      ? this.#fieldSchema?.picklistValues
          ?.find((opt) => opt.value === this.state.fieldValue)
          ?.validFor?.includes(this.props.controllerFieldValue ?? '')
      : this.state.fieldValue
  }

  isControlledField = () => Boolean(this.#fieldSchema?.controllerName)

  render = () => {
    var output: React.ReactNode

    switch (this.props.schemaStatus) {
      case EntityStatus.ErrorLoading:
        output = this.#renderErrorState()
        break

      case EntityStatus.Idle:
      case EntityStatus.Loaded:
        output = this.state.isSchemaLoaded ? this.#renderLoadedState() : this.#renderLoadingState()
        break

      default:
        output = this.#renderLoadingState()
    }

    return this.#wrapLabel(output)
  }

  #wrapLabel = (element: React.ReactNode) => (
    <div
      className={Utils.classes(
        styles.parent,
        this.props.showEditedStatus && styles.edited,
        (this.props.error?.additional_data?.error_fields?.includes(this.props.fieldName) &&
          this.props.showErroneousStatus) ||
          (this.props.warningState && styles.fieldCreateUpdateError)
      )}
    >
      {this.props.displayLabel && (
        <LuruCrmFieldInputLabel
          objectName={CrmRecord.getCrmRecordName(this.props.crmRecordType)}
          fieldName={this.props.fieldName}
          isInWarningState={this.props.warningState}
          showNonEditableLabel={true}
        />
      )}
      {element}
    </div>
  )

  #renderErrorState = () => (
    <div className={styles.valueContainer}>
      <div className={styles.error}>Error loading field</div>
    </div>
  )

  #renderLoadingState = () => <div className={styles.valueContainer}>{this.#renderLoadingElement()}</div>

  #renderLoadedState = () => {
    var classNames = [
      styles.valueContainer,
      this.props.size === 'wide'
        ? styles.wide
        : this.props.size === 'large'
        ? styles.large
        : this.props.size === 'narrow'
        ? ''
        : styles.normal,
      this.#fieldSchema?.luruFieldType ? styles[this.#fieldSchema?.luruFieldType] ?? '' : '',
      this.props.className || '',
    ]
    var statusStyle =
      this.state.componentStatus === LuruCrmFieldInputStatus.IDLE
        ? styles.idle
        : this.state.componentStatus === LuruCrmFieldInputStatus.LOADING
        ? styles.loading
        : this.state.componentStatus === LuruCrmFieldInputStatus.SUCCESS
        ? styles.success
        : styles.error

    var infoStyle = [styles.info, statusStyle].join(' ')

    return (
      <div
        className={classNames.join(' ')}
        data-luru-field-type={this.#fieldSchema?.luruFieldType ?? LuruFieldType.TEXT}
      >
        <div className={infoStyle}></div>
        {!this.state.isFieldValueLoaded && this.props.sorRecordId ? (
          this.#renderLoadingElement()
        ) : (
          <div className={styles.edit}>{this.#renderValueComponent()}</div>
        )}

        {this.state.componentStatus === LuruCrmFieldInputStatus.FAILURE ? (
          <div className={styles.alert}>
            <img
              data-role='revert-value'
              src={undoIcon}
              alt='Revert to original value'
              title='Revert to original value'
              onClick={this.eventHandler.handlers.onRevert}
            />
            <img src={errorIcon} alt={this.state.errorMessage} title={this.state.errorMessage} />
          </div>
        ) : null}
        <div
          ref={this.componentRefs.selectLayerRef}
          className={styles.select}
          tabIndex={-1}
          onDoubleClick={this.eventHandler.handlers.onEditCell}
          onKeyDown={this.eventHandler.handlers.onSelectLayerKeyDown}
        ></div>
      </div>
    )
  }

  textPropTypes = [
    LuruFieldType.ADDRESS,
    LuruFieldType.CURRENCY,
    LuruFieldType.DATE,
    LuruFieldType.DATETIME,
    LuruFieldType.DOUBLE,
    LuruFieldType.EMAIL,
    LuruFieldType.INTEGER,
    LuruFieldType.INTEGER_NOFORMAT,
    // LuruFieldType.LARGETEXT,
    LuruFieldType.PERCENTAGE,
    LuruFieldType.TELEPHONE,
    LuruFieldType.TEXT,
    LuruFieldType.URL,
  ]

  textBoxTypes: { [key in LuruFieldType]?: 'date' | 'datetime-local' | 'email' | 'tel' | 'text' } = {
    [LuruFieldType.DATE]: 'date',
    [LuruFieldType.DATETIME]: 'datetime-local',
    [LuruFieldType.EMAIL]: 'email',
    [LuruFieldType.TELEPHONE]: 'tel',
    [LuruFieldType.URL]: 'text',
  }

  textBoxPatterns: { [key in LuruFieldType]?: string } = {
    [LuruFieldType.CURRENCY]: '([0-9,]+)?(.[0-9]{2})?',
    [LuruFieldType.DOUBLE]: '([0-9,]+)?(.[0-9]{2})?',
    [LuruFieldType.INTEGER]: '[0-9,]+',
    [LuruFieldType.INTEGER_NOFORMAT]: '[0-9,]+',
    [LuruFieldType.PERCENTAGE]: '[0-9]{1,3}',
    [LuruFieldType.URL]: '([^.]+.)?[^.]+.[^.]+',
  }

  picklistPropTypes = [LuruFieldType.BOOLEAN, LuruFieldType.ENUM, LuruFieldType.ENUM_RADIO, LuruFieldType.ENUM_SELECT]

  multipicklistPropTypes = [LuruFieldType.MULTIENUM]

  textBoxAlignment: Record<string, 'left' | 'right' | 'center'> = {
    [LuruFieldType.CURRENCY]: 'left',
    [LuruFieldType.DOUBLE]: 'left',
    [LuruFieldType.INTEGER]: 'left',
    [LuruFieldType.INTEGER_NOFORMAT]: 'left',
    [LuruFieldType.PERCENTAGE]: 'left',
    [LuruFieldType.DATE]: 'center',
    [LuruFieldType.DATETIME]: 'center',
  }

  #renderLoadingElement = () => (
    <div className={styles.loading}>
      <span>Loading...</span>
    </div>
  )

  #renderValueComponent = () => {
    var component: any
    var fieldType = this.#fieldSchema?.luruFieldType ?? LuruFieldType.TEXT
    var isReadonly = this.#fieldSchema?.updateable === false

    if (this.textPropTypes.includes(fieldType) || isReadonly || this.props.disabled) {
      let componentProps: LuruTextBoxProps = {
        menuParentSelector: this.props.menuParentSelector,
        additionalClassNames: [styles.datePickerContainer],
        additionalTextBoxClassNames: [styles.datePickerTextBox],
        defaultValue: this.state.displayedValue
          ? this.state.displayedValue
          : this.props.defaultValue
          ? this.props.defaultValue
          : '',
        onBlur: isReadonly ? undefined : this.eventHandler.handlers.onBlur,
        onFocus: isReadonly ? undefined : this.eventHandler.handlers.onFocus,
        onReturn: isReadonly ? undefined : this.eventHandler.handlers.onEnter,
        onCancel: isReadonly ? undefined : this.eventHandler.handlers.onCancel,
        onDatePicked: isReadonly
          ? undefined
          : fieldType === LuruFieldType.DATE || fieldType === LuruFieldType.DATETIME
          ? this.eventHandler.handlers.onBlur
          : undefined,
        pattern: this.textBoxPatterns[fieldType],
        type: this.textBoxTypes[fieldType] ?? 'text',
        alignment: this.getAlignment(fieldType),
        readOnly: this.#fieldSchema?.updateable === false || this.props.disabled,
      }

      component = (
        <LuruTextBox
          ref={this.componentRefs.textBoxRef}
          key={this.state.displayedValue ?? '<no_value>'}
          {...componentProps}
        />
      )
    }

    if (fieldType === LuruFieldType.LARGETEXT) {
      let componentProps: LuruTextAreaProps = {
        menuParentSelector: this.props.menuParentSelector,
        additionalClassNames: [styles.datePickerContainer],
        additionalTextBoxClassNames: [styles.datePickerTextBox],
        defaultValue: this.state.displayedValue
          ? this.state.displayedValue
          : this.props.defaultValue
          ? this.props.defaultValue
          : '',
        onBlur: isReadonly ? undefined : this.eventHandler.handlers.onBlurTextArea,
        onFocus: isReadonly ? undefined : this.eventHandler.handlers.onFocusTextArea,
        onReturn: isReadonly ? undefined : this.eventHandler.handlers.onEnterTextArea,
        onCancel: isReadonly ? undefined : this.eventHandler.handlers.onCancelTextArea,
        alignment: this.getAlignment(fieldType),
        readOnly: this.#fieldSchema?.updateable === false || this.props.disabled,
        rows: 3,
      }
      component = (
        <LuruTextArea
          ref={this.componentRefs.textAreaBoxRef}
          key={this.state.displayedValue ?? '<no_value>'}
          {...componentProps}
        />
      )
    }

    if (this.picklistPropTypes.includes(fieldType) && !isReadonly) {
      let isNillable = this.#fieldSchema?.isNillable === true

      let picklistItems: Array<{ name: string; key: string | null }> = isNillable
        ? [{ name: '--None--', key: null }]
        : []

      switch (fieldType) {
        case LuruFieldType.BOOLEAN:
          picklistItems = picklistItems.concat({ name: 'True', key: 'true' }, { name: 'False', key: 'false' })
          break

        default:
          picklistItems = picklistItems.concat(
            this.#fieldSchema?.picklistValues
              ?.filter((x) => !this.isControlledField() || x.validFor?.includes(this.props.controllerFieldValue ?? ''))
              .map((x) => ({
                name: x.label,
                key: x.valueForUpdate ?? x.value,
              })) ?? []
          )
          break
      }

      let prechosenItem =
        this.#fieldSchema?.picklistValues?.find(
          (v) =>
            this.state.fieldValue &&
            (v.valueForUpdate?.toString() === this.state.fieldValue?.toString() ||
              v.value?.toString() === this.state.fieldValue?.toString())
        )?.label ?? this.state.fieldValue?.toString()

      component = (
        <LuruSelectBox
          key={picklistItems.map((x) => x.key).join(';')}
          prechosenItem={fieldType === LuruFieldType.BOOLEAN ? Utils.capitalizeString(prechosenItem) : prechosenItem}
          items={[...picklistItems]}
          isFilterable={true}
          disabled={this.props.disabled || this.#fieldSchema?.updateable === false}
          classes={[styles.selectBox]}
          leftAlign={true}
          subdued={true}
          menuParentSelector={this.props.menuParentSelector}
          itemNameFormatter={this.itemNameFormatter}
          labelFormatter={this.labelFormatter}
          onChooseItem={this.eventHandler.handlers.onChooseItem}
          popupMenuProps={{
            className: styles.enumSelectBoxPopupMenu,
            popupMenuClassName: styles.enumSelectBoxPopupMenu,
          }}
        />
      )
    }

    if (this.multipicklistPropTypes.includes(fieldType) && !isReadonly) {
      let selectedValues = Array.isArray(this.state?.fieldValue)
        ? this.state?.fieldValue
        : this.state?.fieldValue?.split?.(';') ?? []

      let picklistItems =
        this.#fieldSchema?.picklistValues
          ?.filter(
            (x) => x.label && (!this.isControlledField() || x.validFor?.includes(this.props.controllerFieldValue ?? ''))
          )
          .map((x) => ({
            name: x.label,
            key: x.valueForUpdate ?? x.value,
            isSelected: selectedValues.includes(x.valueForUpdate ?? x.value),
          })) ?? []

      component = (
        <LuruMultiSelectBox
          key={picklistItems.map((x) => x.key).join(';')}
          items={picklistItems}
          menuParentSelector={this.props.menuParentSelector}
          classes={[styles.selectBox]}
          onCancel={() => {}}
          onFinishSelection={this.eventHandler.handlers.onMultiSelectFinish}
          subdued={true}
          disabled={this.props.disabled || this.#fieldSchema?.updateable === false}
          popupMenuProps={{
            className: styles.enumSelectBoxPopupMenu,
            popupMenuClassName: styles.enumSelectBoxPopupMenu,
          }}
        />
      )
    }

    if (fieldType === LuruFieldType.REFERENCE) {
      let fieldValue = this.state.fieldValue as unknown as
        | {
            sor_object_name: string
            sor_record_name: string
          }
        | ''
        | null
        | undefined
      let refSorObjectName = this.#fieldSchema?.referencedObject

      component = (
        <CrmObjectSelectBox
          className={styles.referenceInput}
          value={fieldValue ? fieldValue.sor_record_name : ''}
          sorObjectName={refSorObjectName ?? ''}
          onSelectItem={(sorRecordId, sorRecordName) =>
            this.eventHandler.handlers.onReferenceValueChange(sorRecordId, sorRecordName, refSorObjectName)
          }
          readOnly={this.props.disabled || this.#fieldSchema?.updateable === false}
          menuParentSelector={this.props.menuParentSelector}
        />
      )
    }

    if (fieldType === LuruFieldType.MULTIREFERENCE) {
      let fieldValue = this.state.fieldValue as unknown as
        | Array<{
            sor_object_name: string
            sor_record_id: string
            sor_record_name: string
          }>
        | ''
        | null
        | undefined

      if (!Array.isArray(fieldValue)) {
        fieldValue = []
      }

      if (!this.#fieldSchema) {
        component = null
      }

      component = (
        <CrmMultiObjectSelectBox
          sorObjectName={this.#fieldSchema?.referencedObject ?? ''}
          values={fieldValue}
          fieldName={this.#fieldSchema?.referencedObject ?? ''}
          dialogTitle={`Add / Edit ${Utils.capitalizeString(this.#fieldSchema?.referencedObject)}`}
          classes={[styles.multiObjectBox]}
          onFinishSelection={this.eventHandler.handlers.onMultiReferenceSelectFinish}
          menuParentSelector={this.props.menuParentSelector}
        />
      )
    }

    if (fieldType === LuruFieldType.HIERARCHICAL_ENUM) {
      if (!this.#fieldSchema?.hierarchicalPicklistValues) {
        component = <span className='error'>Error</span>
      } else {
        component = (
          <LuruHierarchicalPicklist
            prechosenItem={this.state.fieldValue}
            options={this.#fieldSchema.hierarchicalPicklistValues}
            onChooseItem={(value: LuruHierarchialPicklistValue) =>
              this.eventHandler.handlers.onChooseItem(JSON.stringify(value))
            }
            menuParentSelector={this.props.menuParentSelector}
            classes={[styles.selectBox]}
            subdued={true}
            disabled={this.props.disabled || this.#fieldSchema?.updateable === false}
            isFilterable={true}
          />
        )
      }
    }

    return component
  }

  setSchema = (schema: CrmObjectSchema) => {
    this.#fieldSchema = schema.payload?.fields?.find((x) => x.name === this.props.fieldName)
    this.setState({ isSchemaLoaded: true })
  }

  setFields = (fieldValues: CrmRecordFieldsData) => {
    var fieldValue = fieldValues?.record?.[this.props.fieldName]?.value

    // For date fields, use only the yyyy-MM-dd part (first 10 chars)
    // if (this.#fieldSchema?.luruFieldType === LuruFieldType.DATE) {
    //   fieldValue = fieldValue?.slice?.(0, 10)
    // }

    // For date time fields, use correct string
    if (this.#fieldSchema?.luruFieldType === LuruFieldType.DATETIME) {
      fieldValue = moment(fieldValue).format('yyyy-MM-DDTHH:mm')
    }

    // this.#fieldValues = fieldValues
    this.setState({
      fieldValue,
      displayedValue: this.formatFieldValue(fieldValue),
      isFieldValueLoaded: true,
    })
  }

  formatFieldValue = (value: string | null) => {
    var luruFieldType = this.#fieldSchema?.luruFieldType ?? LuruFieldType.TEXT

    if (luruFieldType === LuruFieldType.ENUM) {
      return (
        this.#fieldSchema?.picklistValues?.find((x) => x.value?.toString() === value?.toString())?.label ??
        value?.toString() ??
        value
      )
    }

    if (luruFieldType !== LuruFieldType.DATE && luruFieldType !== LuruFieldType.DATETIME) {
      return CrmRecord.getFormattedValue({
        luruFieldType,
        value,
      })
    }

    // if (luruFieldType === LuruFieldType.DATE) {
    //   // Use only the yyyy-MM-dd part
    //   return value?.slice?.(0, 10)
    // }

    if (luruFieldType === LuruFieldType.DATETIME) {
      // Use format accepted by input[type='datetime-local']
      return moment(value).format('yyyy-MM-DDTHH:mm')
    }

    return value
  }

  getAlignment = (luruFieldType: string) => this.textBoxAlignment[luruFieldType] ?? 'left'

  getFieldType = () => this.#fieldSchema?.luruFieldType

  itemNameFormatter = (name: string) => {
    var parts = name.split(':')

    return parts.length === 2 ? (
      <div>
        <span className={styles.selectBoxLevel1}>{parts[0]?.trim()}</span>
        <span className={styles.selectBoxLevel2}>{parts[1]?.trim()}</span>
      </div>
    ) : (
      name
    )
  }

  labelFormatter = (name: string) => {
    var parts = name?.split?.(':') ?? undefined

    return parts?.length === 2 ? (
      <div className={styles.selectBoxLabel}>
        <span className={styles.selectBoxLabelLevel1}>{parts[0]?.trim()}</span>
        <span className={styles.selectBoxLabelLevel2}>{parts[1]?.trim()}</span>
      </div>
    ) : (
      name.toString()
    )
  }
}
