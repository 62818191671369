import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { WorkflowsAPI } from '../api'
import { UpdateV2WorkflowParameter } from '../api/updateV2Workflow'
import { ReduxWorkflowsState, ReduxV2WorkflowEntity } from '../types'

export interface UpdateV2WorkflowAPIResponse extends LuruAPIResponse {
  data: ReduxV2WorkflowEntity
}

export const updateV2Workflow = {
  action: createAsyncThunk<
    UpdateV2WorkflowAPIResponse['data'],
    UpdateV2WorkflowParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: UpdateV2WorkflowAPIResponse['metadata']
      rejectedMeta: UpdateV2WorkflowAPIResponse['metadata']
    }
  >('workflows/updateV2Workflow', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await WorkflowsAPI.updateV2Workflow(params, {
        signal,
      })) as UpdateV2WorkflowAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue({ message: (e as Error)?.message ?? 'Error creating alert' }, null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(updateV2Workflow.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(updateV2Workflow.action.fulfilled, (state, action) => {
      const workflow = action.payload

      state.entities[workflow.workflow_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...workflow,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(updateV2Workflow.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    updateV2Workflow.addPendingCase(builder)
    updateV2Workflow.addFulfilledCase(builder)
    updateV2Workflow.addRejectedCase(builder)
  },
}
