import { EntityStatus } from '../../app/types'
import { CrmObjectName, LuruFieldType } from '../crm/types'
import { CRMProvider } from '../user/types'

export enum ViewType {
  LURU = 'LURU',
  SOR = 'SOR',
}

export const ReduxViewsStateTTL = 60 * 60 * 1_000 // millisecs/hour

export interface ReduxViewsState {
  refreshedAt: string | undefined
  status?: {
    LURU: Record<string, EntityStatus>
    SOR: Record<string, EntityStatus>
  }
  entities: Record<
    string,
    {
      data?: ReduxViewEntity
      status: EntityStatus
    }
  >
}

export type ReduxViewEntity = ReduxLuruViewEntity | ReduxSORViewEntity

export interface ReduxSORViewEntity {
  access: number
  created_at: string
  created_by: string
  display_settings?: null | ViewDisplaySettings
  input: {
    version: string
    data: {
      sor_view_id: string
    }
  }
  name: string
  output: {
    schema: null | {
      fields: Array<ViewOutputSchemaField>
      sor_object_name: CrmObjectName
      sorted_fields: Array<SortInfo> | null
    }
    sor: CRMProvider
    sor_object_name: CrmObjectName
  }
  type: ViewType.SOR
  updated_at: string
  used_at: string | null
  view_id: string
  visibility: 'VISIBLE' | 'HIDDEN'
}

export enum ViewFilterGroupingOperator {
  AND = 'and',
  OR = 'or',
}

export interface ViewDisplaySettings {
  field_settings?: {
    [fieldName: string]: {
      display?: boolean
      width?: number
      display_order?: number
    }
  }
  sorted_by?: Array<SortInfo> | null
}

export interface SortInfo {
  field: string
  sort_order: 'ASC' | 'DESC'
}

export interface ReduxLuruViewEntity {
  created_at: string
  created_by: string
  display_settings?: ViewDisplaySettings
  input: LuruFilterInput
  name: string
  output: {
    schema: {
      fields: Array<ViewOutputSchemaField>
      sor_object_name: CrmObjectName
      sorted_fields: Array<SortInfo>
    }
    sor: CRMProvider
    sor_object_name: CrmObjectName
  }
  type: ViewType.LURU
  updated_at: string
  used_at: string | null
  view_id: string
}

export interface LuruFilterInput {
  data: {
    [ViewFilterGroupingOperator.AND]: [
      {
        [ViewFilterGroupingOperator.AND]: Array<ViewFilterExpression>
      },
      {
        [ViewFilterGroupingOperator.OR]: Array<ViewFilterExpression>
      }
    ]
  }
  version: string
}

export interface LuruViewFilterRootTree {
  [ViewFilterGroupingOperator.AND]: [
    {
      [ViewFilterGroupingOperator.AND]: Array<ViewFilterExpression>
    },
    {
      [ViewFilterGroupingOperator.OR]: Array<ViewFilterExpression>
    }
  ]
}

export interface ViewFilterExpression {
  field: string
  object_name: string
  op: string
  original_op: string
  value: any
}

export interface ViewOutputSchemaField {
  aggregations: Array<any>
  field: string
  label: string
  sor_object_name: CrmObjectName
  sortable: boolean
  updateable: boolean
}

export type ListViewCrmRecord = {
  sor_record_id: string
  last_modified_at: string
  fields: Array<{ name: string; value: any }>
}

export type ListViewCrmRecordMap = {
  sor_record_id: string
  last_modified_at: string
  fields: Record<string, any>
}

export interface ListViewFormattedCrmRecordMap {
  sor_record_id: string
  last_modified_at: string
  fields: Record<
    string,
    {
      value: any
      formattedValue: string
      nonFormattedValue: string
      type: LuruFieldType
      prevValue?: any
      error?: { message: string }
      status?: EntityStatus
      committedValue?: any
    }
  >
}

export const EmptyViewsState: ReduxViewsState = {
  refreshedAt: undefined,
  status: {
    LURU: {},
    SOR: {},
  },
  entities: {},
}
