import { PayloadAction } from '@reduxjs/toolkit'
import { listOrSearchEmptyState, ReduxAppState } from '../types'

export const clearTasksSearchQuery = (
  state: ReduxAppState,
  action: PayloadAction<undefined>
) => {
  state.tasks.search.query = null

  state.tasks.search['mytasks_open'] = {
    ...listOrSearchEmptyState,
  }

  state.tasks.search['mytasks_completed'] = {
    ...listOrSearchEmptyState,
  }

  state.tasks.search['notmytasks_open'] = {
    ...listOrSearchEmptyState,
  }

  state.tasks.search['notmytasks_completed'] = {
    ...listOrSearchEmptyState,
  }
}
