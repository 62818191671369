import * as React from 'react'
import o365CalendarLogo from '../../../../../images/sor/o365/outlook_logo.svg'
import LuruUser from '../../../../../domain/users/LuruUser'
import AppComponentsContext from '../../../../../primitives/AppComponents/AppComponentsContext'
import { AppComponents } from '../../../../../primitives/AppComponents/typings'
import { LuruReduxStore } from '../../../../../app/store'
import { UserMiddleware } from '../../../../../features/user/middleware'
import ModalScreen from '../../../../../primitives/ModalScreen'
import { trackEvent } from '../../../../../analytics/Ga'
import { CalendarProvider } from '../../../../../features/user/types'
import IntegrationCard from '../IntegrationCard'
import CrmRecord from '../../../../../domain/crmRecord/CrmRecord'
import { ConnectLuruExtensionsProps, LuruEntryPoint } from '../../../../../app/types'
import Utils from '@/utils/Utils'
import { ToastId } from '@/app_ui/types'

export interface O365CalendarProps extends ConnectLuruExtensionsProps {}

export interface O365CalendarState {
  disconnecting: boolean
}

class O365Calendar extends React.Component<O365CalendarProps, O365CalendarState> {
  state: O365CalendarState
  isZoom = [LuruEntryPoint.EMBEDDED_ZOOM_MEETING, LuruEntryPoint.EMBEDDED_ZOOM_PANEL].includes(
    LuruUser.getCurrentEntryPoint()
  )
  disconnectModalRef: React.RefObject<ModalScreen>

  constructor(props: O365CalendarProps) {
    super(props)

    this.state = {
      disconnecting: false,
    }
    this.disconnectModalRef = React.createRef()
  }

  #onClickConnectO365Calendar = () => {
    trackEvent('connect_o365_calendar_button_clicked')
    var appComponents = this.context as AppComponents
    appComponents.connectCalendarModal?.current?.showO365CalModal()
  }

  // TODO (Prabin): Very similar to disconnect method in zoom; Move it to common
  #onClickDisconnectO365Calendar = () => {
    this.disconnectModalRef.current?.showModal({
      ok: () => {
        this.setState({ disconnecting: true })
        this.props.toast.showToast({
          id: ToastId.SETTING_INTEGRATION_TOAST_ID,
          message: 'Disconnecting',
          isLoading: true,
        })

        LuruReduxStore.dispatch(UserMiddleware.disconnectO365Cal.action({}))
          .unwrap()
          .then(() => {
            this.props.toast.showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Disconnected Office356 Calendar',
              severity: 'success',
            })
            this.setState({ disconnecting: false })
          })
          .catch((err: any) => {
            this.props.toast.showToast({
              id: ToastId.SETTING_INTEGRATION_TOAST_ID,
              message: 'Error disconnecting Office365 Calendar',
              severity: 'error',
            })
            this.setState({ disconnecting: false })
          })
      },
    })
  }

  render() {
    const calendarName = LuruUser.getCurrentUserCalendarName()
    const connectedCrm = CrmRecord.getCrmName()
    const isSomeOtherCalendarConnected = calendarName && calendarName !== CalendarProvider.O365CAL
    return (
      <>
        <IntegrationCard
          heading='Office 365 Calendar'
          body={`Connect with your Microsoft Outlook calendar for Luru to automatically link and log meeting notes to
          ${connectedCrm || 'CRM'}. You can also take prep notes for upcoming meetings.`}
          logoPath={o365CalendarLogo}
          isConnected={calendarName === CalendarProvider.O365CAL}
          disableConnect={isSomeOtherCalendarConnected}
          handleConnect={this.#onClickConnectO365Calendar}
          handleDisconnect={this.#onClickDisconnectO365Calendar}
          height='16em'
        />
        <ModalScreen title='Disconnect Office 365 calendar' ref={this.disconnectModalRef}>
          Do you want to disconnect from Microsoft Office 365 Calendar?
        </ModalScreen>
      </>
    )
  }
}

O365Calendar.contextType = AppComponentsContext

export default React.forwardRef(Utils.connectLuruExtensions(O365Calendar))
