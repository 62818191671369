import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { CreateWorkflowParameter } from '../types'

export const createWorkflow = async (
  params: CreateWorkflowParameter,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest('/api/workflows', HTTPMethod.POST, options)

  request.setBody({
    ...params,
    sor_object_name: CrmRecord.getCrmRecordName(params.sor_object_name),
  })

  return request.make()
}
