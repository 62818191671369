import { ReduxChatUserEntity } from '../../chat/types'
import { ReduxLuruUserEntity, ReduxSorUserEntity } from '../types'

// TODO: Depending on locale, change formatting of names
export const getFormattedName = (
  user?: ReduxLuruUserEntity | ReduxSorUserEntity | ReduxChatUserEntity
) => {
  if (!user) {
    return 'Unnamed User'
  }

  return ((user.first_name ?? '') + ' ' + (user.last_name ?? '')).trim()
}
