// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'

// Own libraries
import { NotesAPI } from '../api'
import { ReduxNotesState } from '../types'

export type DeleteNoteParameter = {
  noteId: string
  propagate?: boolean
}

export interface DeleteNoteAPIResponse extends LuruAPIResponse {
  data: {
    success: boolean
    noteId: string
  }
}

export const deleteNote = {
  /**
   * Thunk to delete a note
   * Query is an object: { key, noteId }.
     - key: Key to identify the caller.
     - noteId: The note to link the SOR object with
     - connectionId: Connection id set by Luru
   */
  action: createAsyncThunk<
    DeleteNoteAPIResponse['data'],
    DeleteNoteParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: DeleteNoteAPIResponse['metadata']
      rejectedMeta: DeleteNoteAPIResponse['metadata']
    }
  >(
    'notes/deleteNote',
    async (
      { noteId, propagate },
      { getState, rejectWithValue, fulfillWithValue, signal }
    ) => {
      if (!noteId) {
        let errorMessage = 'notesAPI:deleteNote called without required params'
        console.warn(errorMessage)
        throw new Error(errorMessage)
      }

      // If this note is a draft note, then delete only from Redux
      if (getState().notes?.entities?.[noteId]?.isDraft === true) {
        return fulfillWithValue({ success: true, noteId }, null)
      }

      try {
        await NotesAPI.deleteNote({ noteId, propagate }, { signal })
        return fulfillWithValue({ success: true, noteId }, null)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(deleteNote.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(deleteNote.action.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg.noteId]

      if (!Array.isArray(state.deletedNotes)) {
        state.deletedNotes = []
      }

      state.deletedNotes.push({
        note_id: action.meta.arg.noteId,
        deleted_at: new Date().toISOString(),
      })
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(deleteNote.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    deleteNote.addPendingCase(builder)
    deleteNote.addFulfilledCase(builder)
    deleteNote.addRejectedCase(builder)
  },
}
