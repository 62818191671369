import LuruUser from '../domain/users/LuruUser'

export enum PERIOD_TYPE {
  AM = 'AM',
  PM = 'PM',
}

export const DateUtils = {
  MILLISECS_PER_DAY: 24 * 60 * 60 * 1000,

  MILLISECS_PER_SEC: 1000,

  getStartOfDay(d: Date | undefined = undefined) {
    var currDate = d === undefined ? new Date() : new Date(d)

    currDate.setUTCHours(0)
    currDate.setUTCMinutes(0)
    currDate.setUTCSeconds(0)
    currDate.setUTCMilliseconds(0)

    return currDate
  },

  getEndOfDay(d: Date | undefined = undefined) {
    var currDate = d === undefined ? new Date() : new Date(d)

    currDate.setUTCHours(23)
    currDate.setUTCMinutes(59)
    currDate.setUTCSeconds(59)
    currDate.setUTCMilliseconds(0)

    return currDate
  },

  getStartOfWeek(d: Date | undefined = undefined) {
    var dCopy = d === undefined ? new Date() : new Date(d)
    var first = dCopy.getDate() - dCopy.getDay()

    dCopy.setUTCHours(0)
    dCopy.setUTCMinutes(0)
    dCopy.setUTCSeconds(0)
    dCopy.setUTCMilliseconds(0)

    return new Date(dCopy.setDate(first))
  },

  getEndOfWeek(d: Date | undefined = undefined) {
    var dCopy = d === undefined ? new Date() : new Date(d)
    var first = dCopy.getDate() - dCopy.getDay()
    var last = first + 6

    dCopy.setUTCHours(0)
    dCopy.setUTCMinutes(0)
    dCopy.setUTCSeconds(0)
    dCopy.setUTCMilliseconds(0)

    return new Date(dCopy.setDate(last))
  },

  toAPIDateString: (d: Date) => d.toISOString().slice(0, 19) + '+00:00',

  dateFromShortString(s: string) {
    var d = new Date()

    d.setUTCDate(parseInt(s.slice(6, 8)))
    d.setUTCMonth(parseInt(s.slice(4, 6)))
    d.setUTCFullYear(parseInt(s.slice(0, 4)))

    return d
  },

  formatToShortString: (d: Date) => d.toISOString().slice(0, 10).replaceAll('-', ''),

  formatShort: (d: Date | string | undefined) =>
    d
      ? new Intl.DateTimeFormat(LuruUser.getLocale(), {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }).format(typeof d === 'string' ? new Date(d) : d)
      : '',

  formatMini: (d: Date | string | undefined) =>
    d
      ? new Intl.DateTimeFormat(LuruUser.getLocale(), {
          month: 'short',
          day: 'numeric',
        }).format(typeof d === 'string' ? new Date(d) : d)
      : '',

  get12HourFormat: (h: string) => {
    var hourInNumber = parseInt(h)
    var period = (hourInNumber >= 0 && hourInNumber <= 11) || hourInNumber === 24 ? PERIOD_TYPE.AM : PERIOD_TYPE.PM
    hourInNumber = hourInNumber % 12
    hourInNumber = hourInNumber ? hourInNumber : 12 // the hour '0' should be '12'
    return {
      hour: String(hourInNumber).length === 1 ? `0${String(hourInNumber)}` : String(hourInNumber),
      period,
    }
  },

  get24HourFormat: (h: string, period: PERIOD_TYPE | null) => {
    var hourInNumber = parseInt(h)
    if (period === PERIOD_TYPE.AM) {
      if (hourInNumber === 12) {
        h = '00'
      }
    }
    if (period === PERIOD_TYPE.PM) {
      if (hourInNumber !== 12) {
        h = String(hourInNumber + 12)
      }
    }
    return {
      hour: h.length === 1 ? `0${h}` : h,
    }
  },
  // Ex: 20230704 will be converted to 2023-07-04 which is the valid date string
  getValidDateFormate: (s: string) => {
    if (!s) {
      return new Date().toISOString().slice(0, 10)
    }

    var date = ''

    for (let num = 0; num < s.length; num++) {
      if (num === 4) {
        date += '-'
      }
      if (num === 6) {
        date += '-'
      }
      date += s[num]
    }

    return date
  },

  /**
   * This function will convert any date object into YYYY-MM-DD eg: 2023-08-27, will add more format option later as nedded
   * @param date
   * @param format
   * @returns
   */
  formatDate: (date: string | Date | number, format: string = 'YYYY-MM-DD') => {
    if (!DateUtils.isValidDate(date)) {
      return ''
    }
    var dateObj = new Date(date)
    const year = dateObj.getFullYear()
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const day = dateObj.getDate().toString().padStart(2, '0')
    format = format.toUpperCase()
    if (format === 'YYYY-MM-DD') {
      return `${year}-${month}-${day}`
    } else if (format === 'DD-MM-YYYY') {
      return `${day}-${month}-${year}`
    } else if (format === 'DD-MMM-YYYY') {
      let monthName = dateObj.toLocaleString('default', { month: 'short' })
      return `${day}-${monthName}-${year}`
    } else if (format === 'MMMM YYYY') {
      let monthName = dateObj.toLocaleString('default', { month: 'long' })
      return `${monthName} ${year}`
    } else if (format === 'YYYY/MM/DD') {
      return `${year}/${month}/${day}`
    } else if (format === 'HH:MM:SS') {
      let parts = [dateObj.getHours(), dateObj.getMinutes(), dateObj.getSeconds()]
      return parts.map((part) => part.toString().padStart(2, '0')).join(':')
    } else {
      return ''
    }
  },

  isValidDate: (date: string | Date | number) => {
    var dateObj = date instanceof Date ? date : new Date(date)
    if (Object.prototype.toString.call(dateObj) === '[object Date]') {
      if (!isNaN(dateObj.getTime())) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },

  toISOStringCurrentTZ: (date: Date) => {
    var tzo = -date.getTimezoneOffset()
    var dif = tzo >= 0 ? '+' : '-'
    const pad = (num: number) => (num < 10 ? '0' : '') + num

    return [
      date.getFullYear() + '-',
      pad(date.getMonth() + 1) + '-',
      pad(date.getDate()),
      'T',
      pad(date.getHours()) + ':',
      pad(date.getMinutes()) + ':',
      pad(date.getSeconds()),
      dif,
      pad(Math.floor(Math.abs(tzo) / 60)) + ':',
      pad(Math.abs(tzo) % 60),
    ].join('')
  },

  toDateOnly: (date: Date) => {
    return new Date(date.toISOString().slice(0, 10))
  },
}
