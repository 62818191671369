import { ThreeWayAnswer } from '../../../app/types'
import { ReduxNoteTemplateEntity, TemplateAccessEnum } from '../types'

export const isNoteTemplateShareable = (
  noteTemplate: ReduxNoteTemplateEntity | undefined
): ThreeWayAnswer => {
  if (noteTemplate?.access) {
    return (noteTemplate.access & TemplateAccessEnum.SHAREABLE) !== 0
      ? ThreeWayAnswer.YES
      : ThreeWayAnswer.NO
  }

  return ThreeWayAnswer.UNCERTAIN
}
