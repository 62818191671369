import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DeleteNoteParameter } from '../middleware/deleteNote'

export default async function deleteNote(
  { noteId, propagate = true }: Readonly<DeleteNoteParameter>,
  options: LuruAPIRequestOptions
) {
  var request = new LuruAPIRequest(
    `/api/notes/${noteId}?` +
      new URLSearchParams({ propagate: propagate.toString() }),
    HTTPMethod.DELETE,
    options
  )

  return request.make()
}
