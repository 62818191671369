import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { SignInUserPayload } from '../../user/reducers/signInUser'
import { EmptyMeetingsState } from '../types'
import { ReduxMeetingsState } from '../types'

export const userSliceSignInUser: CaseReducer<ReduxMeetingsState, PayloadAction<SignInUserPayload>> = (
  state,
  action
) => {
  Object.keys(state).forEach((key) => delete state[key as keyof ReduxMeetingsState])
  Object.assign(state, EmptyMeetingsState)
  Object.assign(state, action.payload.meetings)
}
