import { PayloadAction } from '@reduxjs/toolkit'
import { ReduxNotesState } from '../types'

export const removeNote = (
  state: ReduxNotesState,
  action: PayloadAction<{ noteId: string }>
) => {
  var { noteId } = action.payload
  // TODO: Verify if new logic works
  // New logic:
  delete state.entities[noteId]
  // Old logic:
  // var { [noteId]: _, ...otherNotes } = state.entities
  // state.entities = otherNotes
}
