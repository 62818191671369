import { AppComponents } from '../../../AppComponents/typings'
import LuruCollectionsSelectBoxComponent from './LuruCollectionsSelectBoxComponent'
import { LuruReduxStore } from '../../../../app/store'
import { CollectionsMiddleware } from '../../../../features/collections/middleware'

interface LuruCollectionsSelectBoxEvents {
  // Add required event handler methods here
  onChooseCollection: (collectionId: string | null) => void
}

export default class LuruComponentEventHandler {
  handlers: LuruCollectionsSelectBoxEvents
  #component: LuruCollectionsSelectBoxComponent
  static createCollectionKey = '__add_new_collection'

  constructor(component: LuruCollectionsSelectBoxComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentWillUnmount = this.#onComponentUnmount.bind(this)

    this.handlers = {
      // Add event handler methods to this class and assemble them into this
      // object and bind them to this object as well
      onChooseCollection: this.#onChooseCollection.bind(this),
    }
  }

  async #onComponentMount() {
    // Add required onMount behavior here
    if (this.#component.props.collectionsNotLoaded) {
      await LuruReduxStore.dispatch(
        CollectionsMiddleware.listCollections.action({})
      )
    }
  }

  #onComponentUnmount() {
    // Add required onUnmount behavior here
  }

  #onChooseCollection(collectionId: string | null) {
    if (collectionId === null) {
      return
    }

    if (collectionId === LuruComponentEventHandler.createCollectionKey) {
      var appComponents = this.#component.context as AppComponents

      appComponents.createCollectionDialog?.current?.showModal(
        this.#component.props.crmRecordType
      )

      return
    }

    this.#component.props.onChooseCollection?.(collectionId)
  }
}
