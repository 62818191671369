import { LuruUserSelectBoxOwnProps, LuruUserSelectBoxType } from '.'
import { RootState } from '../../../../app/store'
import { EntityStatus } from '../../../../app/types'
import LuruUser from '../../../../domain/users/LuruUser'
import {
  ReduxLuruUserEntity,
  ReduxSorUserEntity,
} from '../../../../features/users/types'

export default function getReduxState(
  state: RootState,
  ownProps: LuruUserSelectBoxOwnProps
) {
  var crmProvider = LuruUser.getCurrentUserCrmName(state)
  var crmUsersStatus = crmProvider
    ? state.users[crmProvider]?.status
    : EntityStatus.ErrorLoading
  var luruUsersStatus = state.users.LURU.status
  var users: Array<ReduxSorUserEntity | ReduxLuruUserEntity> = []

  if (
    ownProps.type === LuruUserSelectBoxType.CrmUsers ||
    ownProps.type === LuruUserSelectBoxType.LuruAndCrmUsers
  ) {
    users = users.concat(
      crmProvider && state.users[crmProvider]?.entities
        ? Object.values(state.users[crmProvider]!.entities)
            .map((e) => e.data)
            .filter(Boolean)
        : []
    )
  }

  if (
    ownProps.type === LuruUserSelectBoxType.LuruUsers ||
    ownProps.type === LuruUserSelectBoxType.LuruAndCrmUsers
  ) {
    users = users.concat(
      state.users.LURU.entities
        ? Object.values(state.users.LURU.entities)
            .map((e) => e.data)
            .filter(Boolean)
        : []
    )
  }

  var excludedEmails = ownProps.excludedUsers?.map((u) => u.email) ?? []

  users = users.filter((u) => !excludedEmails.includes(u.email))

  return {
    crmProvider,
    crmUsersStatus,
    luruUsersStatus,
    users,
  }
}
