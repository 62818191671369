import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { UpdateLuruUserRolesParameter } from '../middleware/updateLuruUserRoles'

export const updateLuruUserRoles = async (params: UpdateLuruUserRolesParameter, options: LuruAPIRequestOptions) => {
  var requestBody = {
    role_names: params.roleNames,
  }
  var request = new LuruAPIRequest(`/api/users/${params.userId}/roles`, HTTPMethod.POST, options)
  request.setBody(requestBody)
  return request.make()
}
