import { getFullyQualifiedKey } from '../../domutils/utils'

/**
 * @classdesc Class to handle input events inside editor
 */
export default class SelectionHandler {
  // Computations

  /**
   * Compute if and how input (onInput) event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    try {
      // console.log(`SelectionChange:computeHandling:entry`)

      const keydownEvent =
        eventStream[eventStream.length - 1].sourceEvent?.type === 'keydown'
          ? eventStream[eventStream.length - 1]
          : null

      if (keydownEvent) {
        const key = getFullyQualifiedKey(keydownEvent.sourceEvent)

        if (
          (key === 'CmdA' || key === 'CtrlA') &&
          !keydownEvent.sourceEvent?.target?.closest(
            '[data-luru-role="sor-field-value-root"]'
          )
        ) {
          return {
            do: (view) => {
              let notes = view?.getNoteElementsList()
              let selectAllRange = new Range()
              selectAllRange.setStartBefore(notes[0].firstChild ?? notes[0])
              selectAllRange.setEndAfter(
                notes[notes.length - 1].lastChild ?? notes[notes.length - 1]
              )
              document.getSelection().removeAllRanges()
              document.getSelection().addRange(selectAllRange)
              keydownEvent.sourceEvent.preventDefault()
            },
            preventDefault: true,
          }
        }
      }

      // console.log(`SelectionChange:computeHandling:keydown handled`)

      // Find out if there is a blur caused on a CRM field value
      // If there is a CRM value change, update controller
      let crmFieldBlurEvent =
        eventStream[eventStream.length - 1].sourceName === 'crm-field'
          ? eventStream[eventStream.length - 1]
          : null

      if (crmFieldBlurEvent?.sourceEvent?.currentTarget) {
        let inputElement = crmFieldBlurEvent.sourceEvent.currentTarget
        let noteElement = view?.getContainingNoteElement(inputElement)
        let jottingElement = view?.getJottingElement(noteElement)
        let jottingCrmData = view?.getCrmJottingData(jottingElement)

        if (
          inputElement.type !== 'search' &&
          jottingCrmData &&
          jottingCrmData.data.field.value !== inputElement.value
        ) {
          let crmFieldUpdatedInfo = {
            oldData: jottingCrmData.data,
            newFieldValue: inputElement.value,
          }
          return {
            do: (view, controller) => {
              controller.onCrmFieldUpdated(
                crmFieldUpdatedInfo.oldData,
                crmFieldUpdatedInfo.newFieldValue,
                jottingElement
              )
            },
            dirtyFlag: true,
          }
        }
      }

      let taskBlurEvent =
        eventStream[eventStream.length - 1].data.taskBlurred &&
        eventStream.length === 2 &&
        eventStream[0].sourceEvent?.type === 'keydown' &&
        eventStream[0].sourceEvent?.code === 'Enter'

      if (taskBlurEvent) {
        let { blurredTaskJotting } = eventStream[eventStream.length - 1].data

        if (blurredTaskJotting) {
          return {
            do: async (view, controller) => {
              await controller.onTaskJottingBlurred(blurredTaskJotting)
            },
          }
        }
      }
      // console.log(`SelectionChange:computeHandling:CRM field blur handled`)

      // Editor is not blurred, decide about placeholders now
      let lastEvent = eventStream[eventStream.length - 1]

      if (lastEvent.sourceEvent.type === 'blur') {
        return {
          do: (view) => {
            view?.clearPlaceholders()
          },
        }
      }

      // console.log(`SelectionChange:computeHandling:Blur handled`)

      if (
        !['selectionchange', 'keydown', 'click'].includes(
          lastEvent?.sourceEvent?.type
        )
      ) {
        return
      }

      // console.log(`SelectionChange:computeHandling:Filtering done`)

      // Show a floating formatter panel on selecting some text
      // Log event stream
      const eventStreamType = eventStream
        .map((e) => e.sourceEvent.type)
        .join('>')

      const selectionChangeEventStreams = [
        'mousedown>selectionchange>click',
        'keydown>selectionchange',
      ]

      if (selectionChangeEventStreams.includes(eventStreamType)) {
        // console.log(`Selection change:Event stream identified`)
        // console.log(`Range:`, lastEvent.data.range)
        let range = document.getSelection().getRangeAt(0)
        if (range.collapsed === false) {
          // console.log(`Selection change:Selection identified`)
          // let rangeArea = lastEvent.data.range.getClientRects()
          // console.log(`SelectionHandler:Something was selected`)
          // console.table(rangeArea)
          return {
            do: (view, controller) => {
              try {
                controller.getFloatingFormattingMenu()?.show(range)
              } catch (e) {
                console.warn(e)
              }
            },
          }
        }
      }

      // Process instruction to reset selection boundaries
      if (
        lastEvent.data.resetSelection.start ||
        lastEvent.data.resetSelection.end
      ) {
        let lastKeyEvent = eventStream.find(
          (event) => event.sourceEvent.type === 'keydown'
        )

        if (lastKeyEvent && !lastKeyEvent.data.preventDefault) {
          return {
            do: (view) => {
              lastEvent.sourceEvent.preventDefault()
              let notes = view?.getNoteElementsList()
              let range = lastEvent.data.range.cloneRange()
              if (lastEvent.data.resetSelection.start) {
                // console.log(`SelectionHandler:Resetting start`);
                range.setStartBefore(notes[0].firstChild ?? notes[0])
              }
              if (lastEvent.data.resetSelection.end) {
                // console.log(`SelectionHandler:Resetting end`);
                range.setEndAfter(
                  notes[notes.length - 1].lastChild ?? notes[notes.length - 1]
                )
              }
              document.getSelection().removeAllRanges()
              document.getSelection().addRange(range)
            },
            preventDefault: true,
          }
        }
      }

      return {
        do: (view, controller) => {
          if (lastEvent.data.noteElement) {
            if (lastEvent.data.noteElement.textContent === '') {
              lastEvent.data.noteElement.innerHTML = ''
            }
            controller.getFloatingFormattingMenu()?.hide()
            view?.clearPlaceholders()
            view?.setPlaceholder(lastEvent.data.noteElement)
          }
        },
      }
    } catch (e) {
      return null
    }
  }
}
