import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import NoteLoading from '../components/NoteLoading'
import Error from '../../../primitives/Error'
import { createDraftNote } from '../reduxHelpers/fetchNoteDetails'
import LoadedNote from '../components/LoadedNote'
import EditorController from '../../../coreEditor/EditorController'
import { EntityStatus, LuruEntryPoint } from '../../../app/types'
import { useAppSelector } from '@/app/hooks'
import ConnectCalendarModal from '@/forms/ConnectCalendarModal'

interface EmbeddedNoteCreateDraftNoteProps {
  renderConnectCalendar?: boolean
  calendarApiError?: boolean
  calendarApiErrorCode?: number
  onSkipConnectCalendar?: () => void
  updateNoteIdHook?: (noteId: string) => void
}

/** Component loaded when clicking pop-out from an embedded draft note */
export default function EmbeddedNoteCreateDraftNote(props: EmbeddedNoteCreateDraftNoteProps) {
  const dispatch = useDispatch()
  const [componentState, setComponentState] = useState('awaiting-load')

  const [errorReason, setErrorReason] = useState(null)

  const [noteId, setNoteId] = useState<string | null>(null)
  const noteEntities = useAppSelector((state) => state.notes.entities)

  const createDraftNoteIfRequired = useCallback(async () => {
    if (componentState === 'awaiting-load') {
      let createNoteResponse: {
        success?: boolean
        payload?: {
          noteId: string
        }
        error?: any
      } = {}
      // Check for any draftnote already created, before going to create new draft note
      const alreadyHaveDraftNote = Object.values(noteEntities).find(
        (f) =>
          f?.isDraft === true &&
          [
            LuruEntryPoint.EMBEDDED_GMEET_NOTE,
            LuruEntryPoint.GLOBALSEARCH,
            LuruEntryPoint.EMBEDDED_ZOOM_MEETING,
          ].includes(f?.data?.source as LuruEntryPoint)
      )
      if (Boolean(alreadyHaveDraftNote)) {
        createNoteResponse = {
          success: true,
          payload: {
            noteId: alreadyHaveDraftNote?.data?.note_id!,
          },
        }
      } else {
        createNoteResponse = await createDraftNote({ dispatch })
      }
      if (createNoteResponse?.error) {
        setErrorReason(createNoteResponse?.error)
        setComponentState(EntityStatus.ErrorLoading)
      } else if (createNoteResponse?.payload) {
        let noteId = createNoteResponse.payload.noteId
        setNoteId(noteId)
        setComponentState(EntityStatus.Loaded)
      }
    }
  }, [dispatch, setErrorReason, componentState, setComponentState, noteEntities])

  const eventHandlers = {
    onSkip: () => {
      props.onSkipConnectCalendar?.()

      if (noteId) {
        EditorController.focusEditor(noteId)
      }
    },
  }

  useEffect(() => {
    createDraftNoteIfRequired()
  }, [createDraftNoteIfRequired])

  if (componentState === EntityStatus.Loaded && noteId) {
    return (
      <>
        <LoadedNote
          key={noteId}
          noteId={noteId}
          meetingId={undefined}
          meetingUrl={undefined}
          calendarId={undefined}
          updateNoteId={props.updateNoteIdHook}
        />
        {props.renderConnectCalendar && (
          <ConnectCalendarModal errorState={props.calendarApiError} onClose={eventHandlers.onSkip} />
        )}
      </>
    )
  } else if (componentState === EntityStatus.ErrorLoading) {
    return <Error error={{ message: errorReason }} />
  }

  return <NoteLoading />
}
