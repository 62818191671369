import { RootState } from '../../../app/store'
import { ReduxTasksStateTTL } from '../types'

export const isTasksCacheValid = (state: RootState) =>
  !Boolean(state.tasks.refreshedAt) ||
  new Date().valueOf() -
    (state.tasks.refreshedAt
      ? new Date(state.tasks.refreshedAt).valueOf()
      : 0) <
    ReduxTasksStateTTL
