import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ListZoomMeetingsParameter } from '../middleware/listZoomMeetings'

export const listZoomMeetings = async (params: ListZoomMeetingsParameter, options: LuruAPIRequestOptions) => {
  var urlParams: Partial<ListZoomMeetingsParameter> = { ...params }

  var request = new LuruAPIRequest(
    '/api/zoom/meetings?' + new URLSearchParams(urlParams as URLSearchParams),
    HTTPMethod.GET,
    options
  )

  return request.make()
}
