import { useCallback } from 'react'
import { InsertCollectionCommandContext } from './useEditorMenuCommandListener'

export const useEditorCaretOperations = (
  editorMenuContext: InsertCollectionCommandContext | null,
  resetColletionsChooser: () => void
) => {
  const repositionCaretInEditor = useCallback(
    ({
      rangeContainer,
      rangeOffset,
    }: {
      rangeContainer?: Node
      rangeOffset?: number
    }) => {
      const container = editorMenuContext
        ? editorMenuContext.range.endContainer
        : rangeContainer !== undefined
        ? rangeContainer
        : null

      const offset = editorMenuContext
        ? editorMenuContext.range.endOffset
        : rangeOffset !== undefined
        ? rangeOffset
        : null

      if (container !== null && offset !== null) {
        let repositionRange = new Range()

        repositionRange.setEnd(container, offset)
        repositionRange.collapse(false)
        document.getSelection()?.removeAllRanges()
        document.getSelection()?.addRange(repositionRange)
      }
    },
    [editorMenuContext]
  )

  const goBackToNotesEditor = useCallback(
    ({
      rangeContainer,
      rangeOffset,
    }: {
      trace: string
      rangeContainer?: Node
      rangeOffset?: number
    }) => {
      try {
        // Check if editor caret is available and set range back there
        repositionCaretInEditor({ rangeContainer, rangeOffset })
        if (editorMenuContext !== null) {
          resetColletionsChooser()
        }
      } catch (e) {}
    },
    [editorMenuContext, resetColletionsChooser, repositionCaretInEditor]
  )

  return goBackToNotesEditor
}
