import { HTTPMethod } from '../../../app/types'
import { convertObjIntoQueryParams } from '../../../utils/Utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { SearchActivityLogAPIResponse, SearchActivityLogParameter } from '../middleware/searchActivityLog'
import { CrmActivityLogType } from '../types'

export const searchActivityLog = (
  { params, type }: SearchActivityLogParameter,
  options?: LuruAPIRequestOptions
): Promise<SearchActivityLogAPIResponse> => {
  var url = type === CrmActivityLogType.Call ? `/api/activities/calls` : `/api/activities/meetings`
  var query = convertObjIntoQueryParams(params)
  var request = new LuruAPIRequest(`${url}?${query}`, HTTPMethod.GET, options)

  return request.make()
}
