import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { NotesMiddleware } from '../../notes/middleware'
import { ReduxAppState } from '../types'

export const notesSliceSearchSORNotes = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    notesSliceSearchSORNotes.addPendingCase(builder)
    notesSliceSearchSORNotes.addFulfilledCase(builder)
    notesSliceSearchSORNotes.addRejectedCase(builder)
  },

  addPendingCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.searchSORNotes.action.pending,
      (state, action) => {
        if (action.meta.arg.key !== 'home/SORNotes') {
          return
        }

        var searchOrList: keyof ReduxAppState['home'] = action.meta.arg.q
          ? 'search'
          : 'default'

        state.home[searchOrList].sorNotes.status =
          action.meta.arg.loadMore === true
            ? EntityStatus.LoadingMore
            : EntityStatus.Loading
        state.home[searchOrList].sorNotes.error_data = null
      }
    )
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.searchSORNotes.action.fulfilled,
      (state, action) => {
        if (action.meta.arg.key !== 'home/SORNotes') {
          return
        }

        var searchOrList: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'
        var cursor = action.meta?.cursor ?? null
        var notes = action.payload
        var resultSet = new Set(notes.map((note) => note.note_id))
        var results = [...resultSet]

        // Set meta data
        state.home[searchOrList].sorNotes.status = EntityStatus.Loaded
        state.home[searchOrList].sorNotes.cursor = cursor
        state.home[searchOrList].sorNotes.error_data = null

        // Set data
        // - store only note_ids with us
        // - de-dup so that we don't store duplicates
        if (action.meta.arg.loadMore === true) {
          // Append since this was a next page request; Remove duplicates first
          results = results.filter(
            (noteId) =>
              noteId &&
              !state.home[searchOrList].sorNotes?.results?.includes(noteId)
          )
          state.home[searchOrList].sorNotes?.results?.push(
            ...(results as Array<string>)
          )
        } else {
          // Replace, since this was a fresh request
          state.home[searchOrList].sorNotes.results = (
            results as Array<string>
          ).slice()
        }
      }
    )
  },

  addRejectedCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.searchSORNotes.action.rejected,
      (state, action) => {
        if (action.meta.arg.key !== 'home/SORNotes') {
          return
        }

        var searchOrList: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'

        state.home[searchOrList].sorNotes = {
          status: EntityStatus.ErrorLoading,
          results: [],
          cursor: null,
          error_data: {
            message: action.error.message ?? 'Error searching SOR notes',
          },
        }
      }
    )
  },
}
