import { Route } from 'react-router-dom'
import { renderWithLazyLoading } from '../../WebAppLoading'
import { lazy } from 'react'
import PrivateRoute, { WebAppTabs } from './PrivateRoute'

const Tasks = lazy(() => import('../../../routes/tasks/Tasks'))

const TasksLazyLoading = renderWithLazyLoading(<Tasks />, true)

const renderTaskPageRoutes = () => (
  <Route
    path='/tasks'
    element={
      <PrivateRoute tab={WebAppTabs.TASKS}>
        <TasksLazyLoading />
      </PrivateRoute>
    }
  />
)

export default renderTaskPageRoutes
