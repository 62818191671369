import moment from 'moment'
import React from 'react'
import PopupMenu from '../../PopupMenu'
import LuruDatePickerEventHandler from './LuruDatePickerEventHandler'
import calendarIcon from '../../../images/fluent/calendar_ltr.svg'
import styles from './styles.module.css'

//icons
import chevronLeftIcon from '../../../images/fluent/chevron_circle_left.svg'
import chevronRightIcon from '../../../images/fluent/chevron_circle_right.svg'
import { DateUtils } from '@/utils/dateUtils'

export interface LuruDatePickerProps {
  // If value is given as string, it should be of a valid format accepted by
  // Date constructor
  mode?: 'WITH_INPUT' | 'WITHOUT_INPUT'
  value?: Date | string
  menuParentSelector?: string
  // Is readonly?
  readOnly?: boolean
  // Optional placeholder
  placeholder?: string
  // Extra class names
  additionalClassNames?: Array<string>

  popupMenuClassName?: string
  // Text box class names
  additionalTextBoxClassNames?: Array<string>
  // Optional alignment to play well with LuruTextBox
  alignment?: 'left' | 'center' | 'right'
  // Callback on value picked or cleared
  onValuePicked?: (value: string | null) => void

  //Auto focus
  autoFocus?: boolean
}

interface LuruDatePickerState {
  date?: number
  month?: number
  year?: number
  stringValue: string
  displayState: 'calendar' | 'month_list' | 'year_list'
  yearRangeBegin?: number
}

interface LuruDatePickerRefs {
  popupMenu: React.RefObject<PopupMenu>
  textBox: React.RefObject<HTMLInputElement>
}

export default class LuruDatePicker extends React.Component<LuruDatePickerProps, LuruDatePickerState> {
  props: LuruDatePickerProps
  state: LuruDatePickerState
  eventHandler: LuruDatePickerEventHandler
  componentRefs: LuruDatePickerRefs
  #startOfWeek = moment().startOf('week').subtract(1, 'day')
  #weekDayNames = Array(7)
    .fill(1)
    .map((_) => this.#startOfWeek.add(1, 'day').format('ddd'))
  #monthNames = Array(12)
    .fill(1)
    .map((_, ix) => new Date(2000, ix, 1).toLocaleString('default', { month: 'short' }))

  constructor(props: LuruDatePickerProps) {
    super(props)
    this.props = props
    this.state = this.getOriginalState()
    this.componentRefs = {
      popupMenu: React.createRef(),
      textBox: React.createRef(),
    }
    this.eventHandler = new LuruDatePickerEventHandler(this)
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.componentRefs?.textBox?.current?.focus?.()
      this.componentRefs.popupMenu?.current?.showMenu?.()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<LuruDatePickerProps>,
    prevState: Readonly<LuruDatePickerState>,
    snapshot?: any
  ): void {
    if (prevProps.value !== this.props.value) {
      this.setValue(this.props.value)
    }
  }

  setValue(value?: Date | string | null) {
    if (value === null || value === '') {
      value = undefined
    }

    this.setState(this.getStateForValue(value))
  }

  focus() {
    this.componentRefs.textBox.current?.focus()
  }

  blur() {
    this.componentRefs.textBox.current?.blur()
  }

  select() {
    this.componentRefs.textBox.current?.select?.()
  }

  getStateForValue(value?: Date | string | null): LuruDatePickerState {
    var dateValue = value instanceof Date ? new Date(value) : typeof value === 'string' ? new Date(value) : undefined

    return {
      date: dateValue?.getDate(),
      month: dateValue?.getMonth(),
      year: dateValue?.getFullYear(),
      stringValue: dateValue ? DateUtils.formatDate(dateValue, 'dd-mmm-yyyy') : '',
      displayState: 'calendar',
      yearRangeBegin: undefined,
    }
  }

  getOriginalState(): LuruDatePickerState {
    // null and empty string are coalesced into undefined
    var value = !(this.props.value === null || this.props.value === '') ? this.props.value : undefined
    var stdFormatMatch: RegExpMatchArray | null = null

    // Convert standard formats like 2023-05-01 to Date with local timezone
    if (typeof value === 'string' && (stdFormatMatch = value.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/))) {
      value = new Date(Number(stdFormatMatch[1]), Number(stdFormatMatch[2]) - 1, Number(stdFormatMatch[3]))
    }

    var dateValue =
      this.props.value instanceof Date
        ? new Date(this.props.value)
        : value instanceof Date
        ? value
        : typeof value === 'string' && value !== '' && moment(value).isValid()
        ? new Date(value)
        : undefined

    return {
      date: dateValue?.getDate(),
      month: dateValue?.getMonth(),
      year: dateValue?.getFullYear(),
      stringValue: dateValue ? DateUtils.formatDate(dateValue, 'dd-mmm-yyyy') : '',
      displayState: 'calendar',
      yearRangeBegin: undefined,
    }
  }

  getFormattedValue(year?: number, month?: number, date?: number) {
    var y = year ?? this.state.year
    var m = month ?? this.state.month
    var d = date ?? this.state.date

    if (y === undefined || m === undefined || d === undefined) {
      return ''
    }

    return DateUtils.formatDate(new Date(y, m, d), 'dd-mmm-yyyy')
  }

  resetState() {
    this.setState(this.getOriginalState())
  }

  getValue() {
    if (this.state.year === undefined || this.state.month === undefined || this.state.date === undefined) {
      return undefined
    }

    return new Date(this.state.year, this.state.month, this.state.date)
  }

  render() {
    return this.props.mode === 'WITHOUT_INPUT' ? this.renderWithoutInput() : this.renderWithInput()
  }

  renderWithoutInput() {
    return this.#renderPopupDisplay()
  }

  renderWithInput() {
    var classNames = [styles.parent]
    var textBoxClassNames = ['luruTextBox', styles.textBox, this.props.readOnly ? styles.disabled : '']

    if (this.props.additionalClassNames) {
      classNames = [styles.parent, ...this.props.additionalClassNames]
    }

    if (this.props.additionalTextBoxClassNames) {
      textBoxClassNames = textBoxClassNames.concat([...this.props.additionalTextBoxClassNames])
    }

    var component = (
      <>
        <input
          ref={this.componentRefs.textBox}
          data-role='luru-date-picker'
          data-luru-role='luru-text-box'
          className={textBoxClassNames.join(' ')}
          type='text'
          value={this.state.stringValue}
          spellCheck={false}
          placeholder={this.props.placeholder ?? 'dd-mmm-yyyy'}
          readOnly={this.props.readOnly}
          onKeyDown={this.eventHandler.handlers.onTextBoxKeyDown}
          onChange={this.eventHandler.handlers.onTextBoxChange}
          onFocus={this.eventHandler.handlers.onTextBoxFocus}
          onBlur={this.eventHandler.handlers.onTextBoxBlur}
          style={{ textAlign: this.props.alignment ?? 'center' }}
        />
        <span className={styles.icon} data-luru-role='date-picker-icon'>
          <img src={calendarIcon} alt='Pick date' />
        </span>
      </>
    )

    return (
      <div className={classNames.join(' ')}>
        <PopupMenu
          menuParentSelector={this.props.menuParentSelector}
          ref={this.componentRefs.popupMenu}
          items={this.#renderPopupDisplay()}
          disabled={this.props.readOnly}
          onHideMenu={this.eventHandler.handlers.onHideCalendarMenu}
          hideOnMenuClick={false}
          className={this.props.popupMenuClassName}
        >
          {component}
        </PopupMenu>
      </div>
    )
  }

  #renderPopupDisplay() {
    return this.state.displayState === 'calendar'
      ? this.#renderCalendar()
      : this.state.displayState === 'month_list'
      ? this.#renderMonthList()
      : this.#renderYearList()
  }

  #renderYearList() {
    var year = this.state.yearRangeBegin ?? this.state.year ?? new Date().getFullYear()
    var [yearRangeBegin, yearRangeEnd] = [year - (year % 10) + 1, year - (year % 10) + 10]
    var yearRangeTitle = yearRangeBegin + '-' + `${yearRangeEnd % 100}`.padStart(2, '0')

    return (
      <div className={styles.calendar}>
        <header>
          <button onClick={(e) => this.eventHandler.handlers.onGoToYearRange(e, yearRangeBegin - 10)}>
            <img src={chevronLeftIcon} alt='prev' />
          </button>
          <span className={styles.yearRangeTitle} onClick={this.eventHandler.handlers.onBrowseMonths}>
            {yearRangeTitle}
          </span>
          <button onClick={(e) => this.eventHandler.handlers.onGoToYearRange(e, yearRangeEnd + 1)}>
            <img src={chevronRightIcon} alt='next' />
          </button>
        </header>
        <div className={styles.yearContainer}>
          {Array(4)
            .fill(1)
            .map((x, i) => (
              <div key={i} className={styles.yearRow}>
                {Array(i === 3 ? 1 : 3)
                  .fill(1)
                  .map((y, j) => (
                    <div
                      key={`${i}_${j}`}
                      onClick={(e) => this.eventHandler.handlers.onClickYear(e, yearRangeBegin + i * 3 + j)}
                      className={[
                        styles.yearCell,
                        yearRangeBegin + i * 3 + j === this.state.year ? styles.currentYear : null,
                      ].join(' ')}
                    >
                      {yearRangeBegin + i * 3 + j}
                    </div>
                  ))}
              </div>
            ))}
        </div>
        {this.#renderMoreActions()}
      </div>
    )
  }

  #renderMonthList() {
    return (
      <div className={styles.calendar}>
        <header>
          <button onClick={this.eventHandler.handlers.onGoToPrevYear}>
            <img src={chevronLeftIcon} alt='prev' />
          </button>
          <span className={styles.yearRangeTitle} onClick={this.eventHandler.handlers.onBrowseYears}>
            {this.state.year}
          </span>
          <button onClick={this.eventHandler.handlers.onGoToNextYear}>
            <img src={chevronRightIcon} alt='next' />
          </button>
        </header>
        <div className={styles.monthContainer}>
          {Array(4)
            .fill(1)
            .map((x, i) => (
              <div key={i} className={styles.monthRow}>
                {Array(3)
                  .fill(1)
                  .map((y, j) => (
                    <button
                      key={`${i}_${j}`}
                      onClick={(e) => this.eventHandler.handlers.onClickMonth(e, i * 3 + j)}
                      className={[styles.monthCell, i * 3 + j === this.state.month ? styles.currentMonth : null].join(
                        ' '
                      )}
                    >
                      {this.#monthNames[i * 3 + j]}
                    </button>
                  ))}
              </div>
            ))}
        </div>
        {this.#renderMoreActions()}
      </div>
    )
  }

  #renderCalendar() {
    var dateValue = this.getValue()

    if (dateValue === undefined && this.state.year !== undefined && this.state.month !== undefined) {
      dateValue = new Date(this.state.year, this.state.month, 1)
    }

    if (dateValue === undefined) {
      dateValue = new Date()
    }

    var monthName = dateValue.toLocaleString('default', { month: 'long' })
    var startOfMonth = new Date(dateValue.getFullYear(), dateValue.getMonth(), 1)
    var endOfMonth = new Date(dateValue.getFullYear(), dateValue.getMonth() + 1, 0)
    var calendarStartDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), -startOfMonth.getDay() + 1)
    var currDateValue = calendarStartDate.getDate()
    var weeks: Array<Array<{ date: number; currMonth: boolean }>> = []

    for (let w = 0; w < 6; w++) {
      let week = []

      for (let d = 0; d < 7; d++) {
        week.push({
          date: currDateValue,
          currMonth: !(
            w * 7 + d < startOfMonth.getDay() || w * 7 + d > startOfMonth.getDay() + endOfMonth.getDate() - 1
          ),
        })
        currDateValue++

        if (w * 7 + d + 1 === startOfMonth.getDay() || w * 7 + d + 1 === startOfMonth.getDay() + endOfMonth.getDate()) {
          currDateValue = 1
        }
      }

      weeks.push(week)
    }

    return (
      <div className={styles.calendar}>
        <header>
          <button onClick={this.eventHandler.handlers.onGoToPrevMonth}>
            <img src={chevronLeftIcon} alt='prev' />
          </button>
          <span className={styles.monthName}>
            <button onClick={this.eventHandler.handlers.onBrowseMonths}>{monthName}</button>{' '}
            <button onClick={this.eventHandler.handlers.onBrowseMonths}>
              {Number.isNaN(dateValue.getFullYear()) ? '' : dateValue.getFullYear()}
            </button>
          </span>
          <button onClick={this.eventHandler.handlers.onGoToNextMonth}>
            <img src={chevronRightIcon} alt='next' />
          </button>
        </header>
        <div className={styles.weekDays}>
          {this.#weekDayNames.map((d) => (
            <div key={d}>{d}</div>
          ))}
        </div>
        <div className={styles.monthContainer}>
          {weeks.map((w, ix) => (
            <div key={ix} className={styles.weekContainer}>
              {w.map((d, jx: number) => (
                <button
                  key={`${ix}_${jx}`}
                  className={[
                    styles.dayContainer,
                    d.currMonth ? styles.activeMonth : null,
                    d.currMonth && d.date === this.state.date ? styles.currentDate : null,
                  ].join(' ')}
                  onClick={(event) => {
                    this.eventHandler.chooseDate(
                      event,
                      d.date,
                      (dateValue ?? new Date()).getMonth() + (d.currMonth ? 0 : ix === 0 ? -1 : 1),
                      (dateValue ?? new Date()).getFullYear()
                    )
                  }}
                >
                  {d.date}
                </button>
              ))}
            </div>
          ))}
        </div>
        {this.#renderMoreActions()}{' '}
      </div>
    )
  }

  #renderMoreActions() {
    return (
      <div className={styles.moreActions}>
        <button
          className={[styles.calendarMoreActionButton, styles.clearButton].join(' ')}
          onClick={this.eventHandler.handlers.onClearDate}
        >
          Clear
        </button>
        <button className={styles.calendarMoreActionButton} onClick={this.eventHandler.handlers.onChooseToday}>
          Today
        </button>
      </div>
    )
  }
}
