import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CollectionsAPI } from '../api'
import { ReduxCollectionEntity, ReduxCollectionsState } from '../types'

export interface FetchCollectionParameter {
  collectionId: string
}

export interface FetchCollectionAPIResponse extends LuruAPIResponse {
  data: ReduxCollectionEntity
}

export const fetchCollection = {
  action: createAsyncThunk<
    FetchCollectionAPIResponse['data'],
    FetchCollectionParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: FetchCollectionAPIResponse['metadata']
      rejectedMeta: FetchCollectionAPIResponse['metadata']
    }
  >(
    'collections/fetchCollection',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: FetchCollectionAPIResponse = await CollectionsAPI.fetchCollection(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    },
    {
      condition: (params, { getState }) =>
        getState().collections.entities[params.collectionId]?.status !== EntityStatus.Loading,
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(fetchCollection.action.pending, (state, action) => {
      var { collectionId } = action.meta.arg

      if (!state.entities[collectionId]) {
        state.entities[collectionId] = {
          status: EntityStatus.Loading,
          data: null,
        }
      } else {
        state.entities[collectionId].status = EntityStatus.Loading
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(fetchCollection.action.fulfilled, (state, action) => {
      var { collectionId } = action.meta.arg

      state.entities[collectionId] = {
        status: EntityStatus.Loaded,
        data: {
          ...action.payload,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(fetchCollection.action.rejected, (state, action) => {
      var { collectionId } = action.meta.arg

      if (state.entities[collectionId]) {
        state.entities[collectionId].status = EntityStatus.ErrorLoading
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    fetchCollection.addPendingCase(builder)
    fetchCollection.addFulfilledCase(builder)
    fetchCollection.addRejectedCase(builder)
  },
}
