import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { MeetingsMiddleware } from '../../meetings/middleware'
import { ReduxAppState } from '../types'

export const meetingsSliceSearchMeetings = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    meetingsSliceSearchMeetings.addPendingCase(builder)
    meetingsSliceSearchMeetings.addFulfilledCase(builder)
    meetingsSliceSearchMeetings.addRejectedCase(builder)
  },

  addPendingCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      MeetingsMiddleware.searchMeetings.action.pending,
      (state, action) => {
        if (action.meta.arg.key === 'home/UpcomingMeetings') {
          if (action.meta.arg.loadMore === true) {
            state.home.search.upcomingMeetings.status = EntityStatus.LoadingNext
          } else if (action.meta.arg.loadPrev === true) {
            state.home.search.upcomingMeetings.status = EntityStatus.LoadingPrev
          } else {
            state.home.search.upcomingMeetings.status = EntityStatus.Loading
          }
          state.home.search.upcomingMeetings.error_data = null
        }
      }
    )
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      MeetingsMiddleware.searchMeetings.action.fulfilled,
      (state, action) => {
        if (action.meta.arg.key === 'home/UpcomingMeetings') {
          state.home.search.upcomingMeetings.status = EntityStatus.Loaded

          const meetings = action.payload
          // From above API response, store only the meeting_ids of the meetings
          // that are not already there with us.
          let results = meetings.map((meeting) => meeting.meeting_id)

          if (action.meta.arg.loadMore) {
            // If this was a next page request, just append to existing results
            // Remove duplicates first
            results = results.filter(
              (meeting_id) =>
                !state.home.search.upcomingMeetings.results?.includes(
                  meeting_id
                )
            )
            state.home.search.upcomingMeetings.results.push(...results)
          } else if (action.meta.arg.loadPrev) {
            // if this was a prev page request, prepend to existing results
            // Remove duplicates first
            results = results.filter(
              (meeting_id) =>
                !state.home.search.upcomingMeetings.results?.includes(
                  meeting_id
                )
            )
            state.home.search.upcomingMeetings.results = [
              ...results,
              ...state.home.search.upcomingMeetings.results,
            ]
          } else {
            // If this was a new search, replace the results
            state.home.search.upcomingMeetings.results = results
          }
          state.home.search.upcomingMeetings.error_data = null
        }
      }
    )
  },

  addRejectedCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      MeetingsMiddleware.searchMeetings.action.rejected,
      (state, action) => {
        if (action.meta.arg.key === 'home/UpcomingMeetings') {
          state.home.search.upcomingMeetings.status = EntityStatus.ErrorLoading
          state.home.search.upcomingMeetings.results = []
          // We use action.payload (instead of action.error) for the
          // error_data cos rejectWithValue(..) sets it there
          state.home.search.upcomingMeetings.error_data = {
            message: action.error.message ?? 'Error searching meetings',
          }
        }
      }
    )
  },
}
