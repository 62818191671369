import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ListNoteTemplatesParameter } from '../middleware/listNoteTemplates'

export const listNoteTemplates = async (
  params: Readonly<ListNoteTemplatesParameter>,
  options: LuruAPIRequestOptions
) => {
  var url = params.visibility ? `/api/note-templates?visibility=${params.visibility}` : `/api/note-templates`
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
