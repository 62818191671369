import { AppUIState, AppUIStateAction, AppUIStateActionType } from './types'
import update from 'immutability-helper'

export default function appUIStateReducer(state: AppUIState, action: AppUIStateAction) {
  const { type, payload } = action

  switch (type) {
    case AppUIStateActionType.SHOW_COLLECTION_DIALOG:
      return update(state, {
        collectionDialog: {
          $set: {
            isOpen: true,
            objectName: payload?.objectName,
            editMode: payload?.editMode ?? 'create',
            collectionId: payload?.collectionId,
          },
        },
      })

    case AppUIStateActionType.HIDE_COLLECTION_DIALOG:
      return update(state, {
        collectionDialog: {
          $set: {
            isOpen: false,
            objectName: undefined,
            editMode: 'create',
            collectionId: undefined,
          },
        },
      })

    case AppUIStateActionType.SHOW_TOAST:
      if (!payload?.id) {
        throw new Error('payload.id is required')
      }
      return update(state, {
        toast: {
          [payload?.id]: {
            $set: {
              isOpen: true,
              message: payload?.message,
              severity: payload?.severity ?? 'info',
              isLoading: payload?.isLoading ?? false,
            },
          },
        },
      })

    case AppUIStateActionType.HIDE_TOAST:
      if (!payload?.id) {
        throw new Error('payload.id is required')
      }

      if (!state.toast?.[payload.id]?.isOpen) {
        return update(state, {
          toast: {
            $unset: [payload?.id],
          },
        })
      }

      return update(state, {
        toast: {
          [payload?.id]: { isOpen: { $set: false } },
        },
      })

    case AppUIStateActionType.CLOSE_TOAST:
      if (!payload?.id) {
        throw new Error('payload.id is required')
      }
      return update(state, {
        toast: {
          $unset: [payload?.id],
        },
      })

    default:
      return state
  }
}
