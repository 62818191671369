import { useCallback, useEffect, useState } from 'react'
import { getFilterId } from '../helpers/getFilterId'
import { InsertCollectionCommandContext } from './useEditorMenuCommandListener'

export const useShowHideCollectionsChooser = ({
  entityId,
  resetFilter,
  editorMenuContext,
  linkRecordPopupShown,
}: {
  entityId: string
  resetFilter: () => void
  editorMenuContext: InsertCollectionCommandContext | null
  linkRecordPopupShown: boolean
}) => {
  const [isChooserVisible, setIsChooserVisible] = useState(
    editorMenuContext && !linkRecordPopupShown
  )

  const hideCollectionsChooser = useCallback(() => {
    setIsChooserVisible(false)
    resetFilter()
  }, [resetFilter])

  const showCollectionsChooser = useCallback(() => {
    setIsChooserVisible(true)
  }, [])

  useEffect(() => {
    if (isChooserVisible) {
      setTimeout(
        () => document.getElementById(getFilterId(entityId))?.focus(),
        50
      )
    }
  })

  return {
    isChooserVisible,
    showCollectionsChooser,
    hideCollectionsChooser,
  }
}
