import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UpdateCollectionParameter } from '../middleware/updateCollection'

export const updateCollection = async (
  params: Readonly<UpdateCollectionParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/collections/${params.collectionId}`,
    HTTPMethod.PUT,
    options
  )
  var requestBody: Partial<UpdateCollectionParameter> = { ...params }

  for (let key in params) {
    let typedKey = key as keyof UpdateCollectionParameter

    if (params[typedKey] === undefined || params[typedKey] === null) {
      delete requestBody[typedKey]
    }
  }

  delete requestBody.collectionId

  request.setBody(requestBody)

  return request.make()
}
