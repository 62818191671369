import { PayloadAction } from '@reduxjs/toolkit'
import { EmptyMeetingsState, ReduxMeetingsState } from '../types'

export const invalidateCache = (
  state: ReduxMeetingsState,
  action: PayloadAction<undefined>
) => {
  state.entities = { ...EmptyMeetingsState.entities }
  state.refreshedAt = undefined
  state.search = { ...EmptyMeetingsState.search }
}
