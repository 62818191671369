import { Route } from 'react-router-dom'
import LuruUser from '@/domain/users/LuruUser'
import { CRMProvider } from '@/features/user/types'
import NewWorkflowsHomeWrapperLazyLoading from './workflows/WorkflowsHomeWrapper'
import WorkflowsHomeLazyLoading from './workflows/WorkflowsHome'
import WorkflowEditorWrapperLazyLoading from './workflows/WorkflowEditorWrapper'
import { WorkflowV2EditorCreateMode, WorkflowV2EditorEditMode } from './workflows/WorkflowV2EditorWrapper'
import PrivateRoute, { WebAppTabs } from './PrivateRoute'

const renderWorkflowPageRoutes = () => {
  const crm = LuruUser.getCurrentUserCrmName()
  const displayV1Editor = crm === CRMProvider.SFDC || crm === CRMProvider.HUBSPOT || crm === CRMProvider.SFDC_SANDBOX
  const displayV2Editor = crm === CRMProvider.SFDC || crm === CRMProvider.HUBSPOT || crm === CRMProvider.SFDC_SANDBOX
  return displayV1Editor ? (
    <Route
      path='/workflows'
      element={
        <PrivateRoute tab={WebAppTabs.WORKFLOWS}>
          <NewWorkflowsHomeWrapperLazyLoading />
        </PrivateRoute>
      }
    >
      {/* New UI: */}
      <Route index element={<WorkflowsHomeLazyLoading />} />
      <Route path='old' element={<WorkflowsHomeLazyLoading />} />
      <Route path='analytics' element={<WorkflowsHomeLazyLoading />}>
        <Route index element={<WorkflowsHomeLazyLoading />} />
        <Route path='events' element={<WorkflowsHomeLazyLoading />} />
      </Route>
      <Route path='old/:workflowId' element={<WorkflowEditorWrapperLazyLoading />} />
      <Route path='create' element={<WorkflowEditorWrapperLazyLoading />} />
      <Route path='process_dashboard' element={<WorkflowsHomeLazyLoading />} />
      {/* Old UI: */}
      {displayV2Editor && <Route path='v2/create' element={<WorkflowV2EditorCreateMode />} />}
      {displayV2Editor && <Route path='v2/:workflowId' element={<WorkflowV2EditorEditMode />} />}
    </Route>
  ) : null
}

export default renderWorkflowPageRoutes
