import json5 from 'json5'
import update from 'immutability-helper'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import LuruFieldSetChooserComponent, {
  LuruFieldSetChooserProps,
  LuruFieldSetChooserState,
} from './LuruFieldSetChooserComponent'
import { LuruReduxStore } from '../../../../app/store'
import { CollectionsMiddleware } from '../../../../features/collections/middleware'
import { trackEvent } from '../../../../analytics/Ga'

interface LuruFieldSetChooserEvents {
  onChangeSelectionMode: (mode: 'Manual' | 'Preset') => void
  onManualSelectItem: (key: string, keys?: Array<string>) => void
  onManualDeselectItem: (key: string, keys?: Array<string>) => void
  onFinishSelection: (
    keys: Array<string>,
    allItems?: Array<{ key: string; isSelected: boolean; order: number }>
  ) => void
  onManualSelectAllItems: (keys: Array<string>) => void
  onManualDeselectAllItems: (keys: Array<string>) => void
  onCancelMultiSelectPopup: () => void
  onChooseCollection: (collectionId?: string) => void
  onHideMultiSelectPopup: () => void
}

export default class LuruCrmFieldInputEventHandler {
  #component: LuruFieldSetChooserComponent
  #isMounted: boolean = false

  handlers: LuruFieldSetChooserEvents

  constructor(component: LuruFieldSetChooserComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount
    this.#component.componentDidUpdate = this.#onComponentUpdate
    this.#component.componentWillUnmount = this.#onComponentUnmount

    this.handlers = {
      onChangeSelectionMode: this.#onChangeSelectionMode,
      onManualSelectItem: this.#onManualSelectItem,
      onManualDeselectItem: this.#onManualDeselectItem,
      onFinishSelection: this.#onFinishSelection,
      onManualSelectAllItems: this.#onManualSelectAllItems,
      onManualDeselectAllItems: this.#onManualDeselectAllItems,
      onCancelMultiSelectPopup: this.#onCancelMultiSelectPopup,
      onChooseCollection: this.#onChooseCollection,
      onHideMultiSelectPopup: this.#onHideMultiSelectPopup,
    }
  }

  #onComponentMount = async () => {
    this.#isMounted = true
    await this.#loadSchema()
  }

  #onComponentUpdate = (prevProps: LuruFieldSetChooserProps) => {
    if (
      prevProps.selectedFieldNames !==
        this.#component.props.selectedFieldNames ||
      prevProps.crmRecordType !== this.#component.props.crmRecordType
    ) {
      this.#component.setState({
        selectedFieldNames: this.#component.props.checkedAllByDefault
          ? this.#component.getAllFieldNames()
          : this.#component.props.selectedFieldNames ?? [],
      })
    }
  }

  #onComponentUnmount = () => {
    this.#isMounted = false
  }

  #loadSchema = async () => {
    try {
      let schema = await CrmRecord.getObjectSchema(
        this.#component.props.crmRecordType
      )

      if (this.#isMounted) {
        this.#component.setSchema(json5.parse(json5.stringify(schema)))
      }
    } catch (e) {
      console.warn(e)
    }
  }

  #onChangeSelectionMode = (selectionMode: 'Manual' | 'Preset') => {
    this.#component.setState({ selectionMode })
  }

  #onManualSelectItem = (key: string, keys?: Array<string>) => {
    if (!this.#component.state.selectedFieldNames.includes(key)) {
      this.#component.setState((prevState: LuruFieldSetChooserState) =>
        update(prevState, {
          selectedFieldNames: {
            $push: [key],
          },
        })
      )
    }

    this.#component.props.onChangeFields?.(keys || [])
  }

  #onManualSelectAllItems = (keys: Array<string>) => {
    this.#component.setState({
      selectedFieldNames: keys,
    })
    this.#component.props?.onChangeFields?.(keys || [])
  }

  #onManualDeselectAllItems = (keys: Array<string>) => {
    this.#component.setState({
      selectedFieldNames: keys || [],
    })

    this.#component.props.onChangeFields?.(keys || [])
  }

  #onManualDeselectItem = (key: string, keys?: Array<string>) => {
    if (this.#component.state.selectedFieldNames.includes(key)) {
      this.#component.setState((prevState: LuruFieldSetChooserState) =>
        update(prevState, {
          selectedFieldNames: {
            $splice: [
              [prevState.selectedFieldNames.findIndex((f) => f === key), 1],
            ],
          },
        })
      )
    }

    this.#component.props.onChangeFields?.(keys || [])
  }

  #onFinishSelection = (
    selectedKeys: Array<string>,
    allItems?: Array<{ key: string; isSelected: boolean; order: number }>
  ) => {
    if (this.#component.props.onChooseFields) {
      this.#component.props.onChooseFields(selectedKeys, allItems)
    }

    this.#onCancelMultiSelectPopup()
  }

  #onCancelMultiSelectPopup = () =>
    this.#component.componentRefs.popupButton.current?.hideMenu()

  #onHideMultiSelectPopup = () => {
    this.#component.setState({
      selectedFieldNames: this.#component.props.selectedFieldNames,
    })

    this.#component.componentRefs.multiselectPopup.current?.resetFilterBox()
  }

  #onChooseCollection = async (collectionId?: string) => {
    if (collectionId) {
      this.#component.setState({
        selectedCollectionId: collectionId,
        selectedCollectionStatus: EntityStatus.Loading,
      })

      try {
        let collectionFieldNames = await this.#getCollectionFields(collectionId)

        let nameField = this.#component
          .getSchema()
          ?.payload?.fields?.find((f) => f.nameField)?.name

        if (nameField) {
          collectionFieldNames = [
            nameField,
            ...collectionFieldNames.filter((n) => n !== nameField),
          ]
        }

        this.#component.setState({
          selectedCollectionStatus: EntityStatus.Loaded,
          selectedCollectionId: collectionId,
          collectionFieldNames,
          selectedFieldNames: collectionFieldNames
            ? [...collectionFieldNames]
            : this.#component.state.selectedFieldNames,
        })

        trackEvent('use_collection', 'LuruFieldSetChooser')
      } catch (e) {
        this.#component.setState({
          selectedCollectionStatus: EntityStatus.ErrorLoading,
        })
      }
    }
  }

  #getCollectionFields = async (collectionId: string) => {
    var collection = await LuruReduxStore.dispatch(
      CollectionsMiddleware.fetchCollection.action({ collectionId })
    ).unwrap()

    return collection.fields
  }
}
