import { LuruFieldType } from '../../../domain/crmRecord/typings.d'
import GetObjectSchema from './requestInterfaces/getObjectSchema'
import GetRecordFields from './requestInterfaces/getRecordFields'
import SearchRecords from './requestInterfaces/searchRecords'
import UpdateRecordField from './requestInterfaces/updateRecordField'
import CreateRecord from './requestInterfaces/createRecord'
import GetPipelineList from './requestInterfaces/getPipelineList'
import GetPipelineStages from './requestInterfaces/getPipelineStages'
import MultiUpdate from './requestInterfaces/multiUpdate'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'

/**
 * @classdesc Base class for a CRM's middleware.  Implements common functions
 */
export default class CRMBaseMiddlewareFactory {
  #crmId = null

  /**
   * @constructor - Must be called by derived classes with a CRM id
   * @param {string} crmId - CRM id
   * @throws {Error} - If no CRM id is provided
   */
  constructor(crmId) {
    if (!crmId) {
      throw new Error(`Cannot instantiate CRMBaseMiddleware without ${CrmRecord.getCrmName()} Id`)
    }
    this.#crmId = crmId
  }

  /**
   * Compute and return the CRM middleware for a CRM id
   * @returns {Object} - A middleware composed of various CRM requests.  A
   * particular derived class may override this method to implement only
   * specific request types, later, if needed
   */
  getMiddleware() {
    this.getRecordFields = new GetRecordFields(this)
    this.searchRecords = new SearchRecords(this)
    this.updateRecordField = new UpdateRecordField(this)
    this.createRecord = new CreateRecord(this)
    this.multiUpdate = new MultiUpdate(this)
    this.getObjectSchema = new GetObjectSchema(this)
    this.getPipelineList = new GetPipelineList(this)
    this.getPipelineStages = new GetPipelineStages(this)

    return {
      getRecordFields: this.getRecordFields.getRequestMiddleware(),
      searchRecords: this.searchRecords.getRequestMiddleware(),
      updateRecordField: this.updateRecordField.getRequestMiddleware(),
      createRecord: this.createRecord.getRequestMiddleware(),
      multiUpdate: this.multiUpdate.getRequestMiddleware(),
      getObjectSchema: this.getObjectSchema.getRequestMiddleware(),
      getPipelineList: this.getPipelineList.getRequestMiddleware(),
      getPipelineStages: this.getPipelineStages.getRequestMiddleware(),
      getPrimaryObjectNames: () => this.getPrimaryObjectNames(),
      getAllPrimaryObjectNames: () => this.getAllPrimaryObjectNames(),
    }
  }

  /**
   * Get the CRM id of this factory
   * @returns {string} - CRM id
   */
  getCrmId() {
    return this.#crmId
  }

  /**
   *
   * @param {{fieldName: string, fieldValue: string, luruFieldType: LuruFieldType}} payload
   * @returns
   */
  prepareFieldForUpdate(payload) {
    switch (payload.luruFieldType) {
      case LuruFieldType.INTEGER:
      case LuruFieldType.INTEGER_NOFORMAT:
      case LuruFieldType.PERCENTAGE:
        payload.fieldValue = parseInt(payload.fieldValue)
        break

      case LuruFieldType.DOUBLE:
      case LuruFieldType.CURRENCY:
        payload.fieldValue = parseFloat(payload.fieldValue)
        break

      case LuruFieldType.ENUM:
      case LuruFieldType.ENUM_RADIO:
      case LuruFieldType.ENUM_SELECT:
        payload.fieldValue = this.prepareEnumForUpdate(payload)
        break

      case LuruFieldType.MULTIENUM:
        if (Array.isArray(payload.fieldValue)) {
          payload.fieldValue = payload.fieldValue.join(';')
        }

        payload.fieldValue = this.prepareMultiEnumForUpdate(payload.fieldValue)
        break

      case LuruFieldType.REFERENCE:
        payload.fieldValue = this.prepareReferenceForUpdate(payload)
        break

      case LuruFieldType.DATE:
        if (payload.fieldValue) {
          let d = new Date(payload.fieldValue)
          payload.fieldValue =
            d.getFullYear() +
            '-' +
            (d.getMonth() < 9 ? '0' : '') +
            (d.getMonth() + 1) +
            '-' +
            (d.getDate() < 10 ? '0' : '') +
            d.getDate()
        }
        break

      case LuruFieldType.DATETIME:
        // Regular expression for the short format "YYYY-MM-DDTHH:mm"
        const shortFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/
        if (shortFormatRegex.test(payload.fieldValue)) {
          // Convert datetime value to ISO format
          payload.fieldValue = new Date(payload.fieldValue).toISOString()
        }
        break

      default:
      // Do nothing
    }

    return payload
  }

  prepareReferenceForUpdate(payload) {
    return payload.fieldValue
  }

  prepareEnumForUpdate(payload) {
    return payload.fieldValue
  }

  prepareMultiEnumForUpdate(fieldValue) {
    return fieldValue ? fieldValue : ''
  }

  prepareFieldSetForUpdate(fields, fieldSchemaList) {
    var output = {}

    for (let fieldName in fields) {
      output[fieldName] = this.prepareFieldForUpdate({
        ...fields[fieldName],
        fieldName,
        fieldSchemaList,
      })?.fieldValue
    }

    return output
  }

  static reviewAndTweakControlledFields(fieldSchemaList, recordUpdatePayloadFieldValueMap, currentFieldValues) {
    var tweakedControlledFields = {}

    for (let controllingFieldName in recordUpdatePayloadFieldValueMap) {
      // Find fields that are controlled by this fieldName
      let controlledFields = fieldSchemaList.filter((field) => field.controllerName === controllingFieldName)

      // Find the current value of the controlled field
      for (let controlledField of controlledFields) {
        let controlledFieldName = controlledField.name
        let controlledFieldType = controlledField.luruFieldType
        let controlledFieldValueList =
          controlledFieldType === LuruFieldType.MULTIENUM
            ? recordUpdatePayloadFieldValueMap?.[controlledFieldName]?.split(';') ??
              currentFieldValues?.[controlledFieldName]?.value?.split(';')
            : [
                recordUpdatePayloadFieldValueMap?.[controlledFieldName] ??
                  currentFieldValues?.[controlledFieldName]?.value,
              ]
        let controllingFieldValue = recordUpdatePayloadFieldValueMap[controllingFieldName]
        let controlledFieldValidValuesList = controlledFieldValueList
          ?.map((value) => controlledField.picklistValues?.find((option) => option.value === value))
          ?.filter(Boolean)
          ?.filter((option) => option.validFor?.includes(controllingFieldValue))
          ?.map((option) => option.value)

        if (!controlledFieldValidValuesList?.length) {
          tweakedControlledFields[controlledFieldName] = null
        } else {
          tweakedControlledFields[controlledFieldName] = controlledFieldValidValuesList.join(';')
        }
      }
    }

    recordUpdatePayloadFieldValueMap = {
      ...recordUpdatePayloadFieldValueMap,
      ...tweakedControlledFields,
    }

    return recordUpdatePayloadFieldValueMap
  }
}
