import { createSlice } from '@reduxjs/toolkit'
import { ChatSliceReducers as reducers } from './reducers'
import { ChatMiddleware } from './middleware'
import { EmptyReduxChatState } from './types'

const chatSlice = createSlice({
  name: 'chat',
  initialState: EmptyReduxChatState,
  reducers,
  extraReducers: (builder) => {
    ChatMiddleware.listConversations.addAllCases(builder)
    ChatMiddleware.listUsers.addAllCases(builder)
  },
})

export const ChatSliceReducer = chatSlice.reducer

export const ChatSliceActions = chatSlice.actions
