import { useLayoutEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { trackEvent } from '../../../analytics/Ga'
import { getAuthUrl } from '../../../domutils/utils'
import useSignInState from './useSignInState'

export function isTenantizedUrl() {
  let hostname = window.location.hostname
  let parts = hostname.split('.')
  if (parts.length >= 5) {
    // URL is of the form
    //    tenant.stamp.my.luru.com - Local
    //    tenant.stamp.my.looru.ai - Test
    //    tenant.stamp.my.luru.app - Prod
    return true
  }
  return false
}

export default function useTrackAndNavigateEffect() {
  const signedInState = useSignInState()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useLayoutEffect(() => {
    // If we are in signed in state, do nothing more
    if (
      signedInState === 'signedIn' ||
      location.pathname === '/signin' ||
      location.pathname === '/uninstall_chrome_extension' ||
      isTenantizedUrl()
    ) {
      return
    }

    // If we come here,
    // - signedInState = "signedOut"
    // - URL is NOT https://my.luru.com/signin
    // - URL is https://my.luru.com or https://stamp1.my.luru.com
    // So we check with auth.luru.com if we are indeed logged in, and if so,
    // redirect us to the correct tenant-ized URL

    // Track event in GA based on extension-event query params sent from background script
    if (searchParams.get('extension-event') === 'installed') {
      trackEvent('installed_ext', 'from_chromeext')
    } else if (searchParams.get('extension-event') === 'updated') {
      trackEvent('updated_ext', 'from_chromeext')
    } else if (searchParams.get('extension-event') === 'uninstalled') {
      trackEvent('uninstalled_ext', 'from_chromeext')
    }

    window.location.href = `${getAuthUrl()}/proxy?next=${location.pathname ?? '/'}`
  }, [location, signedInState, searchParams])
}
