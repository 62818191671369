import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HideCollectionParameter } from '../middleware/hideCollection'

export const hideCollection = async (
  params: Readonly<HideCollectionParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/collections/${params.collectionId}/hide`,
    HTTPMethod.POST,
    options
  )

  return request.make()
}
