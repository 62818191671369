import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CollectionsAPI } from '../api'
import { ReduxCollectionsState } from '../types'

export interface DeleteCollectionParameter {
  collectionId: string
}

export interface DeleteCollectionAPIResponse extends LuruAPIResponse {
  data: {}
}

export const deleteCollection = {
  action: createAsyncThunk<
    DeleteCollectionAPIResponse['data'],
    DeleteCollectionParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: DeleteCollectionAPIResponse['metadata']
      rejectedMeta: DeleteCollectionAPIResponse['metadata']
    }
  >(
    'collections/deleteCollection',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: DeleteCollectionAPIResponse =
          await CollectionsAPI.deleteCollection(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(deleteCollection.action.pending, (state, action) => {
      var { collectionId } = action.meta.arg

      if (!state.entities[collectionId]) {
        state.entities[collectionId] = {
          status: EntityStatus.Loading,
          data: null,
        }
      } else {
        state.entities[collectionId].status = EntityStatus.Deleting
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(deleteCollection.action.fulfilled, (state, action) => {
      var { collectionId } = action.meta.arg
      delete state.entities[collectionId]
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(deleteCollection.action.rejected, (state, action) => {
      var { collectionId } = action.meta.arg

      if (state.entities[collectionId]) {
        state.entities[collectionId].status = EntityStatus.ErrorDeleting
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    deleteCollection.addPendingCase(builder)
    deleteCollection.addFulfilledCase(builder)
    deleteCollection.addRejectedCase(builder)
  },
}
