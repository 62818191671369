import React, { useCallback, useEffect, useRef } from 'react'
import { SourceEntityType } from '..'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { getControlId } from '../helpers/getControlId'
import { getSORConnection } from '../../../../../features/notes/selectors/getSORConnection'
import { getSorObjectNameFilter } from '../../../../../features/noteTemplates/selectors/getSorObjectNameFilter'
import { EntityStatus } from '../../../../../app/types'
import { CollectionsMiddleware } from '../../../../../features/collections/middleware'
import { useShowHideCollectionsChooser } from './useShowHideCollectionsChooser'
import { useHandleLinkRecord } from './useHandleLinkRecord'
import { useHandleLinkObject } from './useHandleLinkObject'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export interface InsertCollectionCommandContext {
  // TODO: Fix the type of response
  onResponseReady: (response: any) => void
  range: Range
  scrollY?: number
}

export const useEditorMenuCommandListener = ({
  entityId,
  sourceType,
  setLinkRecordPopupShown,
  setEditorMenuContext,
  showCollectionsChooser,
  handleLinkRecord,
  handleLinkObject,
}: {
  entityId: string
  sourceType: SourceEntityType
  setLinkRecordPopupShown: React.Dispatch<React.SetStateAction<boolean>>
  setEditorMenuContext: React.Dispatch<React.SetStateAction<InsertCollectionCommandContext | null>>
  showCollectionsChooser: ReturnType<typeof useShowHideCollectionsChooser>['showCollectionsChooser']
  handleLinkRecord: ReturnType<typeof useHandleLinkRecord>
  handleLinkObject: ReturnType<typeof useHandleLinkObject>
}) => {
  // Toast
  const { showToast } = useLuruToast()
  // Redux dispatch
  const reduxDispatch = useAppDispatch()

  // Connected CRM
  const crmId = useAppSelector((s) => s.user?.data?.userSettings?.connectedCRM?.name)

  // Collections status
  const collectionsStatus = useAppSelector((s) => s.collections.status)

  const showChooserAfterLoading = useRef(false)

  // Element Id of CRM record link control
  const linkRecordId = `crm-link-popup-${entityId.slice(0, 7)}`

  // Element Id of CRM object link control (for templates)
  const linkObjectId = `crm-object-link-popup-${entityId.slice(0, 7)}`

  // Linked CRM Record
  const linkedCRMRecord = useAppSelector(getSORConnection(entityId, crmId, null))

  // Linked object
  const linkedObjectName = useAppSelector(getSorObjectNameFilter(entityId))

  // Linked sorObjectName
  const linkedSorObjectName =
    (sourceType === SourceEntityType.NOTE ? linkedCRMRecord?.connection?.sor_object_name : linkedObjectName) ??
    undefined

  // Function to ask CRM record link control to link a record
  const requestLinkCRMRecord = useCallback(
    (cmdContext: InsertCollectionCommandContext) => {
      const evtConfig = {
        detail: {
          onLinkRecord: handleLinkRecord,
          rangeContainer: cmdContext.range.endContainer,
          rangeOffset: cmdContext.range.endOffset,
          userPromptMessage: 'Enter the name of a CRM record to connect',
          scrollY: cmdContext.scrollY ?? 0,
        },
      }

      setLinkRecordPopupShown(true)

      document.getElementById(linkRecordId)?.dispatchEvent(new CustomEvent('linkrecordrequest', evtConfig))
    },
    [linkRecordId, handleLinkRecord, setLinkRecordPopupShown]
  )

  // Function to ask object chooser to link object to template
  const requestLinkObjectToTemplate = useCallback(
    (cmdContext: InsertCollectionCommandContext) => {
      let eventConfig = {
        detail: {
          onLinkObject: handleLinkObject,
          userPromptMessage: 'Choose object for playbook to insert collection',
          scrollY: cmdContext.scrollY ?? 0,
        },
      }

      setLinkRecordPopupShown(true)

      document.getElementById(linkObjectId)?.dispatchEvent(new CustomEvent('linkobjectrequest', eventConfig))
    },
    [linkObjectId, handleLinkObject, setLinkRecordPopupShown]
  )

  // Handler when menu command is received to insert collection
  const handleChooseCollectionRequest = useCallback(
    (e: Event) => {
      const evt = e as CustomEvent<InsertCollectionCommandContext>

      const cmdContext = evt.detail

      const shouldHandleEvent = evt.detail?.onResponseReady && evt.detail.onResponseReady instanceof Function

      if (!shouldHandleEvent) {
        return
      }

      setEditorMenuContext(cmdContext)

      if (sourceType === SourceEntityType.NOTE && !linkedCRMRecord?.connection) {
        requestLinkCRMRecord(cmdContext)
        return
      }

      if (sourceType === SourceEntityType.TEMPLATE && (!linkedObjectName || linkedObjectName === 'All')) {
        requestLinkObjectToTemplate(cmdContext)
        return
      }

      if (collectionsStatus !== EntityStatus.Loaded) {
        showToast({
          id: ToastId.CRM_COLLECTION_CHOOSER_TOAST_ID,
          message: 'Fetching collections',
          isLoading: true,
        })
        showChooserAfterLoading.current = true
        reduxDispatch(CollectionsMiddleware.listCollections.action({}))
          .unwrap()
          .then((res) => {
            showToast({
              id: ToastId.CRM_COLLECTION_CHOOSER_TOAST_ID,
              message: 'Fetched collections',
              severity: 'success',
            })
            showCollectionsChooser()
          })
          .catch((err) => {
            showToast({
              id: ToastId.CRM_COLLECTION_CHOOSER_TOAST_ID,
              message: `Error while fetching collections: ${err}`,
              severity: 'error',
            })
          })
      } else {
        showCollectionsChooser()
      }
    },
    [
      sourceType,
      linkedCRMRecord,
      requestLinkCRMRecord,
      linkedObjectName,
      requestLinkObjectToTemplate,
      collectionsStatus,
      reduxDispatch,
      showCollectionsChooser,
      setEditorMenuContext,
      showToast
    ]
  )

  useEffect(() => {
    const controlEl = document.getElementById(getControlId(entityId))

    controlEl?.addEventListener('chooseCollectionRequest', handleChooseCollectionRequest)

    return () => {
      controlEl?.removeEventListener('chooseCollectionRequest', handleChooseCollectionRequest)
    }
  }, [entityId, handleChooseCollectionRequest])

  useEffect(() => {
    if (showChooserAfterLoading.current) {
      showCollectionsChooser()
      showChooserAfterLoading.current = false
    }
  }, [showCollectionsChooser])

  return { linkedSorObjectName }
}
