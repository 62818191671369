import { HTTPMethod } from '../../../app/types'
import { convertObjIntoQueryParams } from '../../../utils/Utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CreateActivityLogFromCalEvntParameter } from '../middleware/createActivityLogFromCalEvnt'
import { CrmActivityLogType } from '../types'

export const createActivityLogFromCalEvnt = (
  { payload, type, params }: CreateActivityLogFromCalEvntParameter,
  options?: LuruAPIRequestOptions
) => {
  var url = type === CrmActivityLogType.Call ? `/api/activities/calls` : `/api/activities/meetings`
  var query = convertObjIntoQueryParams(params)
  var request = new LuruAPIRequest(`${url}?${query}`, HTTPMethod.POST, options)

  request.setBody(payload)
  return request.make()
}
