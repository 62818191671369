import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { UsersAPI } from '../api'
import { ReduxSorUserAdminTask, ReduxUsersState, SorProvider } from '../types'

export interface InviteUsersParameter {
  sor: string
  sorUserIds: string[]
  message: string
  connections: string[]
}

export interface InviteLuruUserRolesAPIResponse extends LuruAPIResponse {
  data: Record<string, Array<ReduxSorUserAdminTask>>
}

export const inviteUsers = {
  action: createAsyncThunk<
    InviteLuruUserRolesAPIResponse['data'],
    InviteUsersParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: InviteLuruUserRolesAPIResponse['metadata']
      rejectedMeta: InviteLuruUserRolesAPIResponse['metadata']
    }
  >('users/inviteUsers', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await UsersAPI.inviteUsers(params, {
        signal,
      })) as InviteLuruUserRolesAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue({ message: (e as Error)?.message ?? 'Error inviting users' }, null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(inviteUsers.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(inviteUsers.action.fulfilled, (state, action) => {
      const sorUserIds = Object.keys(action.payload)
      const sorName = action.meta.arg.sor as SorProvider
      sorUserIds.forEach((userId) => {
        const userData = state[sorName]?.entities[userId]
        if (!userData) {
          return
        }
        userData.status = EntityStatus.Loaded
        userData.data.user_admin_tasks = action.payload[userId]
      })
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(inviteUsers.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    inviteUsers.addPendingCase(builder)
    inviteUsers.addFulfilledCase(builder)
    inviteUsers.addRejectedCase(builder)
  },
}
