import { PayloadAction } from '@reduxjs/toolkit'
import { SaveNoteParameter } from '../../notes/middleware/saveNote'
import { ReduxNoteEntity } from '../../notes/types'
import { ReduxAppState } from '../types'

// Note: The type of action parameter here are copied over from the action
// NotesMiddleware.saveNote.fulfilled
export const updateAppNotes = (
  state: ReduxAppState,
  action: PayloadAction<{
    payload: ReduxNoteEntity
    meta: {
      arg: SaveNoteParameter
      requestId: string
      requestStatus: 'fulfilled'
    } & {
      count: number
      cursor: string | null
    }
    type: string
  }>
) => {
  var originalAction = action.payload

  if (!state.home.default.luruNotes.results) {
    return
  }

  if (
    !state.home.default.luruNotes.results.includes(
      originalAction.payload.note_id
    )
  ) {
    state.home.default.luruNotes.results.push(originalAction.payload.note_id)
  }
}
