import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import LuruError, { LuruErrorName } from '../../LuruError'
import { CollectionsAPI } from '../api'
import { ReduxCollectionEntity, ReduxCollectionsState } from '../types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'

export interface ListCollectionsParameter {}

export interface ListCollectionsAPIResponse extends LuruAPIResponse {
  data: Array<ReduxCollectionEntity>
}

export const listCollections = {
  action: createAsyncThunk<
    ListCollectionsAPIResponse['data'],
    ListCollectionsParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: ListCollectionsAPIResponse['metadata']
      rejectedMeta: ListCollectionsAPIResponse['metadata']
    }
  >(
    'collections/listCollections',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var sor = LuruUser.getCurrentUserCrmName(getState())

        if (!sor) {
          return rejectWithValue(
            new LuruError(
              LuruErrorName.ApplicationError,
              `No ${CrmRecord.getCrmName()} provider found to list collections`
            ).toErrorValue(),
            null
          )
        }

        var response: ListCollectionsAPIResponse =
          await CollectionsAPI.listCollections(sor, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(listCollections.action.pending, (state, action) => {
      state.status = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(listCollections.action.fulfilled, (state, action) => {
      state.status = EntityStatus.Loaded
      state.refreshedAt = new Date().toISOString()

      action.payload.forEach(
        (c) =>
          (state.entities[c.collection_id] = {
            status: EntityStatus.Loaded,
            data: { ...c },
          })
      )
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(listCollections.action.rejected, (state, action) => {
      state.status = EntityStatus.ErrorLoading
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    listCollections.addPendingCase(builder)
    listCollections.addFulfilledCase(builder)
    listCollections.addRejectedCase(builder)
  },
}
