import { connect, ConnectedProps } from 'react-redux'
import NotesExplorerBarComponent from './NotesExplorerBarComponent'
import getReduxState from './getReduxState'
import getActions from './getActions'
import Utils from '../../../utils/Utils'
import React from 'react'

const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type NotesExplorerBarConnectedProps = ConnectedProps<typeof connector>

export default React.forwardRef(Utils.connectLuruExtensions(connector(NotesExplorerBarComponent)))
