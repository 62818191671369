// React
import React, { useEffect } from 'react'
import reactDom from 'react-dom'

// Styles
import styles from './css/Modal.module.css'

// Icons
import closeIcon from '../images/fluent/close.svg'

// /**
//  * Generic modal component; renders children inside the modal
//  * @param {object} props { onClose, show }
//  * @returns Modal component
//  */
export default function Modal(props) {
  useEffect(() => {
    if (props.disallowClosing) {
      return
    }

    function handleKeydown(e) {
      if (e.key === 'Escape') {
        e.preventDefault()
        props.onClose()
      }
    }

    document.body.addEventListener('keydown', handleKeydown)

    return () => document.body.removeEventListener('keydown', handleKeydown)
  })

  let classes = [styles.modal]

  if (!props.show) {
    classes.push(styles.hidden)
  }

  if (props.className) {
    classes.push(props.className)
  }

  return reactDom.createPortal(
    <div className={classes.join(' ')}>
      <div className={[styles.modalContent, props.modalContentClass].join(' ')}>
        <div className={styles.modalHeader}>
          {props.titleText ? <div className={styles.titleText}>{props.titleText}</div> : null}
          {props.disallowClosing ? null : (
            <div onClick={props.onClose} className={styles.modalClose}>
              <img src={closeIcon} alt='close' />
            </div>
          )}
        </div>
        {props.children}
      </div>
    </div>,
    document.getElementById('root')
  )
}
