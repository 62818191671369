import { useEffect, useRef } from 'react'
import { plot } from '@observablehq/plot'
import Skeleton from './Skeleton'

interface PlotContainerProps {
  isLoading?: boolean
  width?: string
  height?: string
  plot?: () => ReturnType<typeof plot>
}

export default function PlotContainer({ isLoading, width: givenWidth, height: givenHeight, plot }: PlotContainerProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const width = givenWidth || '480px'
  const height = givenHeight || '270px'
  const containerEl = <div ref={containerRef} style={{ width, height }} />
  const errorEl = <div style={{ width, height }}>Error: No plotting function given</div>

  useEffect(() => {
    if (isLoading || !plot) {
      return
    }

    var plotObj: ReturnType<typeof plot> | undefined = undefined

    if (containerRef.current) {
      plotObj = plot()
      containerRef.current?.append(plotObj)
    }

    return () => {
      plotObj?.remove()
    }
  }, [plot, isLoading])

  return isLoading ? <Skeleton>{containerEl}</Skeleton> : Boolean(plot) ? containerEl : errorEl
}
