import LuruAPIRequest from '@/features/LuruAPIRequest'
import { EmailSyncInfoParams } from '../middleware/getEmailSyncInfo'
import { HTTPMethod } from '@/app/types'

export const getEmailSyncInfo = async (params: EmailSyncInfoParams) => {
  var emailInfoUrl = '/api/email_sync'
  var request = new LuruAPIRequest(emailInfoUrl, HTTPMethod.GET)

  return request.make()
}
