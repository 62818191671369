export interface WindowDimensions {
  height: number
  width: number
}

export const openWindow = (
  url: string,
  name: string,
  dimensions: WindowDimensions = {
    height: window.screen.availHeight * 0.8,
    width: window.screen.availWidth * 0.8,
  }
) =>
  window.open(
    url,
    name,
    Object.entries({
      height: dimensions.height,
      width: dimensions.width,
      top: (window.screen.availHeight - dimensions?.height) / 2,
      left: (window.screen.availWidth - dimensions?.width) / 2,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join(',')
  )
