import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import { ReduxNoteTemplateEntity, ReduxNoteTemplatesState } from '../types'

export interface HideNoteTemplateParameter {
  noteTemplateId: string
}

export interface HideNoteTemplateAPIResponse extends LuruAPIResponse {
  data: ReduxNoteTemplateEntity
}

export const hideNoteTemplate = {
  action: createAsyncThunk<
    HideNoteTemplateAPIResponse['data'],
    HideNoteTemplateParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: HideNoteTemplateAPIResponse['metadata']
      rejectedMeta: HideNoteTemplateAPIResponse['metadata']
    }
  >(
    'noteTemplates/hideNoteTemplate',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: HideNoteTemplateAPIResponse =
          await NoteTemplatesAPI.hideNoteTemplate(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(hideNoteTemplate.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(hideNoteTemplate.action.fulfilled, (state, action) => {
      state.entities[action.payload.template_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...action.payload,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(hideNoteTemplate.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    hideNoteTemplate.addPendingCase(builder)
    hideNoteTemplate.addFulfilledCase(builder)
    hideNoteTemplate.addRejectedCase(builder)
  },
}
