// Redux
import { createSlice } from '@reduxjs/toolkit'

// Third party components
import { NotesMiddleware } from '../notes/middleware'
import { UserSliceActions } from '../user/userSlice'
import { MeetingsSliceReducers as reducers } from './reducers'
import { MeetingsSliceExtraReducers } from './extraReducers'
import { MeetingsMiddleware } from './middleware'
import { EmptyMeetingsState } from './types'

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: EmptyMeetingsState,
  reducers,

  // Extra reducers
  extraReducers: (builder) => {
    MeetingsMiddleware.listMeetings.addAllCases(builder)
    MeetingsMiddleware.searchMeetings.addAllCases(builder)
    MeetingsMiddleware.fetchMeeting.addAllCases(builder)

    builder
      .addCase(UserSliceActions.signInUser, MeetingsSliceExtraReducers.userSliceSignInUser)
      .addCase(UserSliceActions.signOutUser, MeetingsSliceExtraReducers.userSliceSignOutUser)
      .addCase(
        NotesMiddleware.createNoteConnection.action.fulfilled,
        MeetingsSliceExtraReducers.notesSliceCreateNoteConnection
      )
      .addCase(
        NotesMiddleware.deleteNoteConnection.action.fulfilled,
        MeetingsSliceExtraReducers.notesSliceDeleteNoteConnection
      )
      .addCase(NotesMiddleware.createNote.action.fulfilled, MeetingsSliceExtraReducers.notesSliceCreateNote)
      .addCase(NotesMiddleware.deleteNote.action.fulfilled, MeetingsSliceExtraReducers.notesSliceDeleteNote)
  },
})

// Action creators
export const { clearSearch } = meetingsSlice.actions

export const MeetingsSliceActions = meetingsSlice.actions

// Reducers for this slice; currently empty
export default meetingsSlice.reducer
