import { configureStore } from '@reduxjs/toolkit'
import { initThirdPartyScripts } from '../../analytics'
import { isLocalStorageAvailable } from './isLocalStorageAvailable'
import { RootState } from '../store'
import { localUserSettings } from '../../features/user/localUserSettings'
import { luruRootReducer } from './luruRootReducer'
import { LuruReduxStore, setLuruReduxStore } from './LuruReduxStore'
import { getEmptyReduxState } from '../store/getEmptyReduxStore'
import { webappMiddleware } from './webappMiddleware'
import { WebappReduxGateway } from './webappReduxGateway'

export const initWebappStore = () => {
  // Initialize a random id identifying this webapp tab
  WebappReduxGateway.initWebappTabId()

  localUserSettings.init()
  var localUserSettingsStored = isLocalStorageAvailable() ? localStorage.getItem('userSettings') : undefined

  if (!localUserSettingsStored) {
    localUserSettingsStored = '{}'
  }

  var preloadedState: RootState =
    // @ts-ignore: __PRELOADED_STATE__ is set by initialState.js
    window.__PRELOADED_STATE__ ?? getEmptyReduxState()

  setLuruReduxStore(
    configureStore({
      reducer: luruRootReducer,
      preloadedState,
      devTools: process.env.REACT_APP_DEBUG === 'true',
      middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(webappMiddleware),
    })
  )

  initThirdPartyScripts()

  // Add store to window
  // Temporarily adding a hook, adding || true for that
  if (process.env.REACT_APP_DEBUG === 'true' || true) {
    // @ts-ignore
    window.__luru_store = LuruReduxStore
  }

  WebappReduxGateway.listen()
}
