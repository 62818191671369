import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { RecentEntityType, ReduxUserState } from '../types'

export const notesSliceUpdateTitle: CaseReducer<
  ReduxUserState,
  PayloadAction<{ noteId: string; title: string }>
> = (state, action) => {
  var { noteId, title } = action.payload
  var recentItem = state.recentItems?.find(
    (item) =>
      item.id === noteId &&
      (item.type === RecentEntityType.NOTE ||
        item.type === RecentEntityType.MEETING_NOTE)
  )

  if (recentItem) {
    recentItem.name = title
  }
}
