import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { CRMProvider } from '../../../features/user/types'
import LuruUser from '../../../domain/users/LuruUser'
import { WorkflowsAPI } from '../api'
import { ReduxWorkflowsState, ReduxWorkflowEntity } from '../types'
import { ListWorkflowsParameter } from '../api/listWorkflows'

export interface ListWorkflowsAPIResponse extends LuruAPIResponse {
  data: Array<ReduxWorkflowEntity>
  metadata: {
    count: number
    cursor: string | null
    crmProvider: CRMProvider
  } | null
}

export const listWorkflows = {
  action: createAsyncThunk<
    ListWorkflowsAPIResponse['data'],
    ListWorkflowsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ListWorkflowsAPIResponse['metadata']
      rejectedMeta: ListWorkflowsAPIResponse['metadata']
    }
  >(
    'workflows/listWorkflows',
    async (params, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await WorkflowsAPI.listWorkflows(params, {
          signal,
        })) as ListWorkflowsAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, {
          count: response.metadata ? response.metadata.count : 0,
          cursor: response.metadata ? response.metadata.cursor : null,
          crmProvider: LuruUser.getCurrentUserCrmName(getState()) as CRMProvider,
        })
      } catch (e) {
        return rejectWithValue({ message: (e as Error)?.message ?? 'Error retrieving chat users' }, null)
      }
    },
    {
      condition: (params, { getState }) => getState().workflows.status !== EntityStatus.Loading,
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(listWorkflows.action.pending, (state, action) => {
      state.status = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(listWorkflows.action.fulfilled, (state, action) => {
      state.status = EntityStatus.Loaded

      action.payload.forEach(
        (workflow) =>
          (state.entities[workflow.workflow_id] = {
            status: EntityStatus.Loaded,
            data: {
              ...workflow,
              sor_object_name: CrmRecord.getCrmRecordType(workflow.sor_object_name, action.meta.crmProvider),
            },
          })
      )

      state.refreshedAt = new Date().toISOString()
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(listWorkflows.action.rejected, (state, action) => {
      state.status = EntityStatus.ErrorLoading
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    listWorkflows.addPendingCase(builder)
    listWorkflows.addFulfilledCase(builder)
    listWorkflows.addRejectedCase(builder)
  },
}
