import { lazy } from 'react'
import { renderWithLazyLoading } from '../../../WebAppLoading'

const EmbeddedNoteCreateDraftNote = lazy(
  () => import('../../../../routes/meetingNotes/embeddedNote/EmbeddedNoteCreateDraftNote')
)

const EmbeddedNoteCreateDraftNoteLazyLoading = renderWithLazyLoading(<EmbeddedNoteCreateDraftNote />)

export default EmbeddedNoteCreateDraftNoteLazyLoading
