import { RootState } from '../../../app/store'
import { ReduxCollectionsStateTTL } from '../types'

export const isCollectionsCacheValid = (state: RootState) =>
  !Boolean(state.collections.refreshedAt) ||
  new Date().valueOf() -
    (state.collections.refreshedAt
      ? new Date(state.collections.refreshedAt).valueOf()
      : 0) <
    ReduxCollectionsStateTTL
