// Redux
import { createAsyncThunk } from '@reduxjs/toolkit'

// CRM base APIs
import { crmApi } from '../crmBaseAPI'

// CRM middleware parent object
import { abortAllRequests } from '../../crmMiddleware'
import { EntityStatus } from '../../../../app/types'

/**
 * @classdesc - Class to manage a CRM's getRecordFields API request
 */
export default class GetRecordFields {
  #crmId = null
  #requestKey = null
  #middlewareFactory = null

  /**
   * Constructor for this class.  Sets up local variables.  Request key is
   * used as the thunk name for Redux purposes.  It is also used to namespace
   * abort controllers object in CRM middleware.
   * @param {CRMBaseMiddlewareFactory} middlewareFactory - A middleware factory
   * which is going to use this instance
   */
  constructor(middlewareFactory) {
    this.#middlewareFactory = middlewareFactory
    this.#crmId = this.#middlewareFactory.getCrmId()
    this.#requestKey = `${this.#crmId}/getRecordFields`
  }

  /**
   * Function to get the portion of the middleware for managing this particular
   * request.  This will be used by the top-level CRM middleware to compose a
   * large middleware object.
   * @returns {Object} - Middleware to manage this request
   */
  getRequestMiddleware() {
    return {
      key: this.#requestKey,
      action: this.#computeAction(),
      abortAllRequests: this.#computeAbortAllRequests(),
      pending: this.#computePendingState(),
      fulfilled: this.#computeFulfilledState(),
      rejected: this.#computeRejectedState(),
    }
  }

  /**
   * Returns a function to initiate a search in CRM.  The thunk itself expects
   * an object as a parameter with two keys: query and objects, which is an
   * array of object names across which to search
   * @return {Object} - Output of reduxjs/toolkit/createAsyncThunk() function
   */
  #computeAction() {
    let thunk = async (payload, { requestId, getState, signal }) => {
      var storedSchema = getState().crm?.[this.#crmId]?.schema[payload.sorObjectName]
      var storedRecord = getState().crm?.[this.#crmId]?.entities[payload.sorObjectName]?.[payload.sorRecordId]
      var isSchemaAvailable = storedSchema?.status === EntityStatus.Loaded && storedSchema.data !== null
      var isRecordAvailable =
        (storedRecord?.status === EntityStatus.Loading ||
          storedRecord?.status === EntityStatus.Loaded ||
          storedRecord?.status === EntityStatus.Updated) &&
        storedRecord.data !== null

      var record = isRecordAvailable
        ? storedRecord.data
        : await crmApi.getRecordFields(this.#crmId, payload.sorObjectName, payload.sorRecordId, requestId)

      if (isSchemaAvailable) {
        var schema = storedSchema.data
      } else {
        // Get schema & pipelines for opportunity object
        // let pipelines = ['deal', 'deals'].includes(payload.sorObjectName)
        //   ? await crmApi.getPipelineList({ crmId: this.#crmId }, { signal })
        //   : undefined

        schema = await crmApi.getObjectSchema(this.#crmId, payload.sorObjectName, record)

        // Deep-copy schema object
        schema = JSON.parse(JSON.stringify(schema ?? {}))

        schema = this.#middlewareFactory.normalizeSchema(schema, payload.sorObjectName)
      }

      // Merge schema into record data for easy lookups
      try {
        let response = isRecordAvailable
          ? { record, schema, userAccess: storedRecord.userAccess ?? null }
          : this.#middlewareFactory.mergeRecordWithSchema(record, schema, payload.sorObjectName)

        return response
      } catch (e) {
        console.warn(`crmMiddleware/getRecordFields: Error while consolidating schema & data`, e)
      }
    }

    thunk = thunk.bind(this)

    return createAsyncThunk(`${this.#crmId}/getRecordFields`, thunk)
  }

  /**
   * Compute a function to abort requests for this particular request key
   * @returns {Function} - Function to abort all requests for this request
   */
  #computeAbortAllRequests() {
    let abortFunction = () => abortAllRequests(this.#requestKey)
    abortFunction.bind(this)
    return abortFunction
  }

  /**
   * Compute a pending state handler required by Redux for handling this request
   * @returns {Function} - Pending state handler
   */
  #computePendingState() {
    let pendingStateHandler = (state, action) => {
      const queryPayload = action.meta.arg
      const objectName = queryPayload.sorObjectName
      const recordId = queryPayload.sorRecordId
      const crmId = this.#crmId

      // Initialize objects if necessary
      if (state[crmId] === undefined) {
        state[crmId] = {}
      }
      if (state[crmId].schema === undefined) {
        state[crmId].schema = {}
      }
      if (state[crmId].schema[objectName] === undefined) {
        state[crmId].schema[objectName] = {}
      }

      // Check for availability of schema
      let storedSchema = state[crmId].schema[objectName]

      if (storedSchema.status !== EntityStatus.Loaded) {
        state[crmId].schema[objectName] = {
          status: EntityStatus.Loading,
          data: 'null',
        }
      }

      // Check for availability of record (entity) data
      if (state[crmId].entities === undefined) {
        state[crmId].entities = {}
      }
      if (state[crmId].entities[objectName] === undefined) {
        state[crmId].entities[objectName] = {}
      }

      // pending state is called before action, don't update the record if data
      // is already available;
      // TODO: Invalidate this record after a defined TTL (somewhere centrally)
      let availableRecord = state[crmId].entities[objectName][recordId]

      if (availableRecord?.status === EntityStatus.Loaded) {
        return
      }

      state[crmId].entities[objectName][recordId] = {
        requestId: action.meta.requestId,
        status: EntityStatus.Loading,
        data: state[crmId].entities[objectName][recordId]?.data
          ? { ...state[crmId].entities[objectName][recordId]?.data }
          : null,
        error: null,
      }
    }

    pendingStateHandler = pendingStateHandler.bind(this)
    return pendingStateHandler
  }

  /**
   * Compute a fulfilled handler required by Redux for handling this request
   * @returns {Function} - Fulfilled state handler
   */
  #computeFulfilledState() {
    let fulfilledStateHandler = (state, action) => {
      const queryPayload = action.meta.arg
      const objectName = queryPayload.sorObjectName
      const recordId = queryPayload.sorRecordId
      const crmId = this.#crmId

      if (state[crmId].schema[objectName]?.status !== EntityStatus.Loaded) {
        state[crmId].schema[objectName] = {
          lastRefreshedAt: new Date().toISOString(),
          status: EntityStatus.Loaded,
          data: action.payload.schema,
          error: null,
        }
      }

      if (state[crmId].entities[objectName][recordId]?.status !== EntityStatus.Loaded) {
        state[crmId].entities[objectName][recordId] = {
          requestId: action.meta.requestId,
          status: EntityStatus.Loaded,
          data: action.payload.record,
          userAccess: action.payload.userAccess ?? null,
          error: null,
          lastRefreshedAt: new Date().toISOString(),
        }
      }
    }

    fulfilledStateHandler = fulfilledStateHandler.bind(this)
    return fulfilledStateHandler
  }

  /**
   * Compute a rejected handler required by Redux for handling this request
   * @returns {Function} - Rejected state handler
   */
  #computeRejectedState() {
    let rejectedStateHandler = (state, error) => {
      if (!error.meta.aborted) {
        const queryPayload = error.meta.arg
        const objectName = queryPayload.sorObjectName
        const recordId = queryPayload.sorRecordId
        const crmId = this.#crmId

        if (state[crmId].schema[objectName]?.status !== EntityStatus.Loaded) {
          state[crmId].schema[objectName] = {
            status: EntityStatus.ErrorLoading,
            data: null,
            error: error,
          }
        }

        if (state[crmId].entities[objectName][recordId]?.status !== EntityStatus.Loaded) {
          state[crmId].entities[objectName][recordId] = {
            requestId: error.meta.requestId,
            status: EntityStatus.ErrorLoading,
            data: null,
            error: error,
          }
        }
      }
    }

    rejectedStateHandler = rejectedStateHandler.bind(this)

    return rejectedStateHandler
  }
}
