import meditatingIcon from '../../../images/meditating.svg'
import styles from '../css/EmbeddedMeetingNote.module.css'
import LoadingSpinner from '../../../primitives/LoadingSpinner'

export default function NoteLoading() {
  return (
    <div className={styles.loadingMessage}>
      <img src={meditatingIcon} title='Loading' alt='Loading' />
      Loading...
      <LoadingSpinner></LoadingSpinner>
    </div>
  )
}
