import { LuruAPIResponseMetaData } from '../app/types'

export enum LuruErrorName {
  ApplicationError = 'ApplicationError',
  MissingArgumentError = 'MissingArgumentError',
  InvalidArgumentError = 'InvalidArgumentError',
  JSONConversionError = 'JSONConversionError',
  JSONParseError = 'JSONParseError',
  ReduxSetupError = 'ReduxSetupError',
  MeetingsApiError = 'MeetingsApiError',
  NetworkError = 'NetworkError',
  HTTPResponseError = 'HTTPResponseError',
  HTTPReadError = 'HTTPReadError',
  HTTPNullResponseError = 'HTTPNullResponseError',
  LuruAPIError = 'LuruAPIError',
}

export const LuruErrorDetails = {
  [LuruErrorName.ApplicationError]: {
    Code: 10000,
    Desc: 'Application error',
    DefaultMessage: 'An application error has happened',
  },
  [LuruErrorName.LuruAPIError]: {
    Code: 10001,
    Desc: 'API error',
    DefaultMessage: 'Error in API response',
  },
  [LuruErrorName.MissingArgumentError]: {
    Code: 11000,
    Desc: 'Missing argument',
    DefaultMessage: 'One or more arguments are missing',
  },
  [LuruErrorName.InvalidArgumentError]: {
    Code: 11001,
    Desc: 'Invalid argument',
    DefaultMessage: 'One or more arguments are not correct',
  },
  [LuruErrorName.JSONConversionError]: {
    Code: 11002,
    Desc: 'JSON conversion error',
    DefaultMessage: 'Cannot serialize/convert given object to JSON string',
  },
  [LuruErrorName.JSONParseError]: {
    Code: 11003,
    Desc: 'JSON parse error',
    DefaultMessage: 'Cannot parse given string to JSON',
  },
  [LuruErrorName.ReduxSetupError]: {
    Code: 11004,
    Desc: 'Redux setup error',
    DefaultMessage: 'There was an error setting up Redux',
  },
  [LuruErrorName.MeetingsApiError]: {
    Code: 10100,
    Desc: 'Meetings API error',
    DefaultMessage: 'There was an error accessing your calendar',
  },
  [LuruErrorName.NetworkError]: {
    Code: 12000,
    Desc: 'Network error',
    DefaultMessage: 'There was an error accessing Internet',
  },
  [LuruErrorName.HTTPResponseError]: {
    Code: 12001,
    Desc: 'Response error',
    DefaultMessage: 'Received an erroneous response for HTTP request',
  },
  [LuruErrorName.HTTPReadError]: {
    Code: 12002,
    Desc: 'HTTP read error',
    DefaultMessage: 'There was an error reading HTTP response',
  },
  [LuruErrorName.HTTPNullResponseError]: {
    Code: 12003,
    Desc: 'HTTP null response read error',
    DefaultMessage: 'Attempt to read from empty response',
  },
}

Object.freeze(LuruErrorDetails)

export default class LuruError extends Error {
  #errorData?: {
    error_code?: number
    http_code?: number
    message?: string
    description?: string
    traceback?: string
    meta?: null | LuruAPIResponseMetaData
    additional_data?: Record<string, any>
  } = undefined

  constructor(
    errorName: LuruErrorName,
    customMessage: string | undefined = undefined,
    cause: Error | undefined = undefined,
    errorData: {} | undefined = undefined
  ) {
    super(customMessage ?? LuruErrorDetails[errorName].DefaultMessage, {
      cause,
    })
    super.name = errorName

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LuruError)
    }

    this.#errorData = errorData
    Object.setPrototypeOf(this, LuruError.prototype)
  }

  toString() {
    return `${this.name}: ${this.message}`
  }

  serialize() {
    return {
      causeCode: this.name,
      source: this.message,
      errorData: this.#errorData,
    }
  }

  // For usage with rejectWithValue of Redux
  toErrorValue() {
    return {
      error_code: this.#errorData?.error_code ?? LuruErrorDetails[this.name as LuruErrorName]?.Code,
      http_code: this.#errorData?.http_code,
      message: this.#errorData?.message ?? this.message,
      description: this.#errorData?.description ?? LuruErrorDetails[this.name as LuruErrorName]?.Desc,
      traceback: this.#errorData?.traceback ?? this.stack,
      meta: this.#errorData?.meta,
      additional_data: this.#errorData?.additional_data,
    }
  }
}
