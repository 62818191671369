import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import { WorkflowsAPI } from '../api'
import { DeleteWorkflowParameter } from '../api/deleteWorkflow'
import { ReduxWorkflowsState } from '../types'

export interface DeleteWorkflowAPIResponse extends LuruAPIResponse {
  data: {}
}

export const deleteWorkflow = {
  action: createAsyncThunk<
    DeleteWorkflowAPIResponse['data'],
    DeleteWorkflowParameter,
    {
      dispatch: AppDispatch
      state: RootState
    }
  >(
    'workflows/deleteWorkflow',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await WorkflowsAPI.deleteWorkflow(params, {
          signal,
        })) as DeleteWorkflowAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data)
      } catch (e) {
        return rejectWithValue({
          message: (e as Error)?.message ?? 'Error deleting workflow',
        })
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(deleteWorkflow.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(deleteWorkflow.action.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg.workflow_id]
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(deleteWorkflow.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    deleteWorkflow.addPendingCase(builder)
    deleteWorkflow.addFulfilledCase(builder)
    deleteWorkflow.addRejectedCase(builder)
  },
}
