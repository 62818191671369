// React / core
import React from 'react'
import { Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import { Buffer } from 'buffer'

// Redux
import { NotesMiddleware } from '../../../features/notes/middleware'
import { doesSORConnectionExist } from '../../../features/notes/selectors/doesSorConnectionExist'
import saveNote from '../reduxHelpers/saveNote'

// Own components/functions
import handleMessage, { Messages } from './EmbeddedNoteMessages'
import NotesEditor from '../../notes/NotesEditor'
import NoteLoading from './NoteLoading'
import NoteDeleteConfirmDialog from '../../notes/components/NoteDeleteConfirmDialog'
import MeetingNavigationDrawer from './MeetingNavigationDrawer'
import DataStoreRefreshComponent from './DataStoreRefreshComponent'

// Own functions/types/libraries
import { ThreeWayAnswer } from '../../../utils/Utils'

// Styles
import styles from '../css/EmbeddedMeetingNote.module.css'
// import headerStyles from '../../../layout/Header.module.css'
import EditorController from '../../../coreEditor/EditorController'

// Icons
import exitToAppIcon from '../../../images/fluent/exit_to_app.svg'
import ConnectCalendarModal from '@/forms/ConnectCalendarModal'

// Extract data/state required by component
const getLoadedNoteState = (state, ownProps) => ({
  // Component data/state from props
  noteId: ownProps.noteId,
  meetingId: ownProps.meetingId,
  meetingUrl: ownProps.meetingUrl,
  calendarId: ownProps.calendarId,

  // Component data/state from Redux
  refreshedAt: state.notes.refreshedAt,
  doesCrmConnectionExist: doesSORConnectionExist(
    state,
    ownProps.noteId,
    state.user?.data?.userSettings?.connectedCRM?.name
  ),
})

const getLoadedNoteActions = (dispatch, ownProps) => ({
  actions: {
    // This is not a Redux action, but an action on a parent component
    updateNoteId: ownProps.updateNoteId,

    // This is Redux's dispatch (needed by DataRefreshComponent)
    dispatch: dispatch,

    // This is to read note record from Redux
    readNoteEntityFromRedux: () => dispatch(NotesMiddleware.readNoteEntityFromRedux.action(ownProps.noteId)),

    // Link note to object
    linkNoteToRecord: async (crmRecord) => {
      await dispatch(
        NotesMiddleware.createNoteConnection.action({
          noteId: ownProps.noteId,
          sorObject: {
            sor: crmRecord.sor,
            sor_record_id: crmRecord.sor_record_id,
            sor_record_name: crmRecord.sor_record_name,
            sor_object_name: crmRecord.sor_object_name,
          },
        })
      )
    },
  },
})

class LoadedNote extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      noteId: props.noteId,
      meetingId: props.meetingId,
      meetingUrl: props.meetingUrl,
      calendarId: props.calendarId,
      refreshedAt: props.refreshedAt,
      doesCrmConnectionExist: props.doesCrmConnectionExist,
      crmConnectionCheckFinished: false,
    }

    this.actions = props.actions

    // this.refs cannot be used; refs is used by React.Component
    this.componentRefs = {
      popinButton: React.createRef(),
      connectCalendarModal: React.createRef(),
      dataRefreshComponent: React.createRef(),
      noteDeleteConfirmDialog: React.createRef(),
    }

    this.eventHandler = new LoadedNoteEventHandler(this)

    this.onCloseCalendarConnectModal = this.onCloseCalendarConnectModal.bind(this)
  }

  getState() {
    return this.state
  }
  getRefs() {
    return this.componentRefs
  }

  getActions() {
    return this.actions
  }

  onCloseCalendarConnectModal() {
    EditorController.focusEditor(this.state.noteId)
  }

  render() {
    return (
      <div
        className={styles.wrapperParent}
        key={`embedded-note-wrapper-${this.state.noteId}`}
        data-last-refreshed-at={this.state.refreshedAt}
        data-luru-role='embedded-meeting-note-parent'
      >
        {/* Commenting below code becuase now we are handling notifications using toast */}
        {/* <div id='luruNotification' className={[headerStyles.notification, styles.notification].join(' ')}></div> */}
        <button
          onClick={this.eventHandler.handlers.popoutOrPopin}
          ref={this.componentRefs.popinButton}
          className={styles.popInButton}
        >
          <img src={exitToAppIcon} alt='exit_to_app' />
        </button>
        {this.state.crmConnectionCheckFinished ? (
          <NotesEditor
            noteId={this.state.noteId}
            key={`notes-editor-${this.state.noteId}`}
            embedded={true}
            globalRefs={this.componentRefs}
            dataLastRefreshedAt={this.state.refreshedAt}
            embeddedMeetingId={this.state.meetingId}
          />
        ) : (
          <NoteLoading />
        )}
        <MeetingNavigationDrawer
          globalRefs={this.componentRefs}
          noteDetails={{
            noteId: this.state.noteId,
            calendarId: this.state.calendarId,
          }}
          dataLastRefreshedAt={this.state.refreshedAt}
        />
        <DataStoreRefreshComponent ref={this.componentRefs.dataRefreshComponent} dispatch={this.actions.dispatch} />
        <NoteDeleteConfirmDialog ref={this.componentRefs.noteDeleteConfirmDialog} />
        <ConnectCalendarModal
          ref={this.componentRefs.connectCalendarModal}
          showRefreshButton={true}
          onClose={this.onCloseCalendarConnectModal}
        />
        <Outlet context={{ globalRefs: this.componentRefs }} />
      </div>
    )
  }
}

class LoadedNoteEventHandler {
  constructor(component) {
    // Setup component load and unload
    this.component = component
    this.component.componentDidMount = this.handleComponentLoad.bind(this)
    this.component.componentWillUnmount = this.handleComponentUnload.bind(this)

    // Setup links to required behaviors
    this.actions = component.getActions()

    // Setup state
    this.state = component.getState()

    // Bind this instance's handlers
    this.handlers = {
      popoutOrPopin: this.popoutOrPopin.bind(this),
      processMessage: this.processMessage.bind(this),
    }
  }

  handleComponentLoad() {
    // An event handler sets its internal refs just after the component is
    // mounted.  Following line should be common behavior across all components
    this.refs = this.component.getRefs()

    // Do component-specific setup after component mount
    this.hidePopinButtonIfRequired()

    // Check if CRM connection exists and setup if it doesn't
    this.setupCrmConnection()

    window.addEventListener('message', this.handlers.processMessage)
  }

  // Setup CRM connection if required
  setupCrmConnection() {
    if (this.state?.doesCrmConnectionExist === ThreeWayAnswer.NO && this.state.meetingId) {
      let noteId = this.component.state.noteId
      let evtConfig = {
        detail: {
          noteId,
          meetingId: this.state.meetingId,
          postSetupCallback: () => {},
        },
      }
      let linkEvent = new CustomEvent('embeddednotemeetinglinked', evtConfig)
      let intervalId = setInterval(() => {
        let infoBlockElementId = `note-info-${noteId.slice(0, 7)}`
        let infoBlockElement = document.getElementById(infoBlockElementId)
        if (infoBlockElement && infoBlockElement.getAttribute('data-message-listener-setup') === 'true') {
          infoBlockElement.dispatchEvent(linkEvent)
          clearInterval(intervalId)
        }
      }, 200)
    }

    this.component.setState({ crmConnectionCheckFinished: true })
  }

  handleComponentUnload() {
    window.removeEventListener('message', this.handlers.processMessage)
  }

  hidePopinButtonIfRequired() {
    // Hide pop in button if this component is inside an iframe or we have a
    // popout window for zoom meeting
    let cleanUrl = this.state.meetingUrl?.replace('_', '/') ?? ''

    let decodedUrl = Buffer.from(cleanUrl, 'base64')?.toString('utf-8')

    let isPopinButtonHidden = decodedUrl?.match(/https?:\/\/(.*)?zoom.us\/*/) || window !== window.top || !window.opener

    let popinButton = this.refs.popinButton.current

    if (isPopinButtonHidden && popinButton) {
      popinButton.style.display = 'none'
      window.parent?.postMessage({ messageName: Messages.FrameLoaded }, '*')
    }
  }

  popoutOrPopin() {
    if (window === window.top) {
      // This is an embedded note, but in a popup outside
      try {
        let callback = async ({ noteDirtyAndSaved }) => {
          let noteEntity = await this.actions.readNoteEntityFromRedux(this.state.noteId)

          if (noteEntity.payload.isDraft === true && !noteDirtyAndSaved) {
            window.opener.postMessage(
              {
                messageName: Messages.PopInWithDraftNote,
                url: window.location.href,
              },
              '*'
            )
          } else {
            window.opener.postMessage(
              {
                messageName: Messages.PopInLuruWindow,
                noteId: this.state.noteId,
              },
              '*'
            )
          }
          // TODO: Comment following line in
          window.close()
        }
        saveNote(this.state.noteId, callback)
      } catch (e) {
        console.warn(e)
      }
    }
  }

  processMessage(e) {
    handleMessage({
      e,
      noteId: this.state.noteId,
      dispatch: this.actions.dispatch,
      meetingUrl: this.state.meetingUrl,
      dataRefresher: this.refs.dataRefreshComponent.current,
      updateNoteIdCallback: this.actions.updateNoteId,
    })
  }
}

export default connect(getLoadedNoteState, getLoadedNoteActions)(LoadedNote)
