import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UseViewParameter } from '../middleware/useView'

export const useView = async (
  params: UseViewParameter,
  options: LuruAPIRequestOptions
) => {
  var url = `/api/views/${params.view_id}/use`
  var request = new LuruAPIRequest(url, HTTPMethod.POST, options)

  request.setBody(params)

  return request.make()
}
