import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CrmAPI } from '../api'
import { CrmActivity, CrmActivityLogType, ReduxCrmKey, ReduxCrmState } from '../types'

export interface UpdateActivityLogParameter {
  crmKey: ReduxCrmKey
  activityId: string
  payload: Partial<CrmActivity>
  type: CrmActivityLogType
}

export interface UpdateActivityLogAPIResponse extends LuruAPIResponse {
  data: CrmActivity
}

export const updateActivityLog = {
  action: createAsyncThunk<
    UpdateActivityLogAPIResponse['data'],
    UpdateActivityLogParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: UpdateActivityLogAPIResponse['metadata']
      rejectedMeta: UpdateActivityLogAPIResponse['metadata']
    }
  >('crm/updateActivityLog', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var createdActivity = (await CrmAPI.updateActivityLog(params, {
        signal,
      })) as UpdateActivityLogAPIResponse

      return fulfillWithValue(createdActivity.data, createdActivity.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(updateActivityLog.action.pending, () => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(updateActivityLog.action.fulfilled, (state, action) => {
      var { crmKey } = action.meta.arg
      const activity = action.payload
      if (activity.activity_id) {
        state[crmKey] = {
          ...state[crmKey],
          activities: {
            ...state[crmKey]?.activities,
            entities: {
              ...state[crmKey]?.activities?.entities,
              [activity.activity_id]: {
                data: activity,
                status: EntityStatus.Loaded,
              },
            },
          },
        }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(updateActivityLog.action.rejected, () => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    updateActivityLog.addPendingCase(builder)
    updateActivityLog.addFulfilledCase(builder)
    updateActivityLog.addRejectedCase(builder)
  },
}
