import { connect, ConnectedProps } from 'react-redux'
import AddCollectionDialogComponent from './AddCollectionDialogComponent'
import getReduxState from './getReduxState'
import getActions from './getActions'
import Utils from '@/utils/Utils'
import React from 'react'

const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type AddCollectionDialogConnectedProps = ConnectedProps<typeof connector>

export default React.forwardRef(Utils.connectLuruExtensions(connector(AddCollectionDialogComponent)))
