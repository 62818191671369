import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { SignOutUserPayload } from '../../user/reducers/signOutUser'
import { ReduxNoteTemplatesState } from '../types'

export const userSliceSignOutUser: CaseReducer<ReduxNoteTemplatesState, PayloadAction<SignOutUserPayload>> = (
  state,
  action
) => {
  Object.keys(state).forEach((key) => delete state[key as keyof ReduxNoteTemplatesState])
  Object.assign(state, action.payload?.noteTemplates)
}
