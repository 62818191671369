import styles from './styles.module.css'
import Card from '@/components/luru/data_display/Card'
import FlexColumn from '@/components/luru/layout/FlexColumn'
import FlexRow from '@/components/luru/layout/FlexRow'
import GoogleSignInButton from '@/components/luru/integrations/GoogleSignInButton'
import SlackSignInButton from '@/components/luru/integrations/SlackSignInButton'
import MicrosoftSignInButton from '@/components/luru/integrations/MicrosoftSignInButton'
import { MouseEventHandler, useCallback, useState } from 'react'
import LuruButton from '@/primitives/ui/LuruButton'

interface IntegrationCardProps {
  sor?: string
  heading: string
  body: string
  logoPath: string
  isConnected?: boolean
  isReconnectRequired?: boolean
  disableConnect?: boolean
  handleConnect: () => void
  handleDisconnect: () => void
  height?: string
}

const IntegrationCard = ({
  sor,
  heading,
  body,
  logoPath,
  isConnected,
  handleConnect,
  handleDisconnect,
  isReconnectRequired,
  disableConnect,
  height,
}: IntegrationCardProps) => {
  const combinationButton = (
    <CombinationButton
      disableConnect={disableConnect}
      isConnected={isConnected}
      handleConnect={handleConnect}
      sor={sor}
    />
  )
  const isKnownSor = ['Google', 'Slack', 'Microsoft'].includes(sor ?? '')

  return (
    <Card heading={heading} height={height}>
      <FlexColumn gap='1em' justify='space-between'>
        <FlexRow gap='1em' wrap={false}>
          <img src={logoPath} alt={heading} className={styles.sorLogo} />
          <div style={{ color: disableConnect ? 'var(--brand-accent-color-gray-darker-1)' : 'initial' }}>{body}</div>
        </FlexRow>
        {!isConnected && !disableConnect && isKnownSor ? (
          combinationButton
        ) : (
          <LuruButton
            size='md'
            variant={!isConnected ? (disableConnect ? 'outline' : 'solid') : 'outline'}
            color={isConnected ? 'red' : 'blue'}
            title={isReconnectRequired ? 'Reconnect' : isConnected ? 'Disconnect' : 'Connect'}
            disabled={disableConnect}
            onClick={isReconnectRequired ? handleConnect : isConnected ? handleDisconnect : handleConnect}
          >
            {isReconnectRequired ? 'Reconnect' : isConnected ? 'Disconnect' : 'Connect'}
          </LuruButton>
        )}
      </FlexColumn>
    </Card>
  )
}

export default IntegrationCard

const CombinationButton = ({
  isConnected,
  disableConnect,
  sor,
  handleConnect,
}: {
  isConnected?: boolean
  disableConnect?: boolean
  sor?: string
  handleConnect: MouseEventHandler<HTMLButtonElement>
}) => {
  const [scaffoldButton, setScaffoldButton] = useState(true)
  const handleClick = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (scaffoldButton) {
        setScaffoldButton(false)
      } else if (e) {
        handleConnect(e)
      }
    },
    [handleConnect, scaffoldButton, setScaffoldButton]
  )
  return scaffoldButton ? (
    <LuruButton size='md' variant='solid' onClick={handleClick} title='Connect'>
      Connect
    </LuruButton>
  ) : sor === 'Google' ? (
    <GoogleSignInButton handleSignIn={handleConnect} />
  ) : sor === 'Slack' ? (
    <SlackSignInButton handleSignIn={handleConnect} />
  ) : sor === 'Microsoft' ? (
    <MicrosoftSignInButton handleSignIn={handleConnect} />
  ) : null // We don't expect CombinationButton for other SORs
}
