import React, { ReactNode, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EntityStatus } from '../../../app/types'
import { getCrmKey } from '../../user/selectors/getCrmKey'
import { CrmMiddleware } from '../middleware'
import CrmRecord from '@/domain/crmRecord/CrmRecord'
import Utils from '@/utils/Utils'
import { CrmIcon } from '@/primitives/CRMIcons'

export function useCrmObjects(
  isVerbose: boolean = false,
  includeIcons: boolean = false
): {
  status?: EntityStatus
  crmObjects?: { label: string; name: string; icon: ReactNode | undefined }[]
} {
  const dispatch = useAppDispatch()
  const crmKey = useAppSelector(getCrmKey)
  const status = useAppSelector((state) => (crmKey ? state.crm[crmKey]?.objects?.status : undefined))
  const crmObjects = useAppSelector((state) => (crmKey ? state.crm[crmKey]?.objects?.data : undefined))

  useEffect(() => {
    if (
      crmKey &&
      crmKey.startsWith('sfdc') &&
      status !== EntityStatus.Loading &&
      status !== EntityStatus.Loaded &&
      status !== EntityStatus.ErrorLoading
    ) {
      dispatch(CrmMiddleware.getAllObjects.action({ crmKey, isVerbose }))
    }
  }, [crmKey, status, dispatch, isVerbose])

  // Return primary objects for HubSpot
  if (crmKey && crmKey.startsWith('hubspot')) {
    const crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()
    const hsObjectTypes = {
      deals: crmPrimaryRecordTypes.DEAL,
      contacts: crmPrimaryRecordTypes.CONTACT,
      companies: crmPrimaryRecordTypes.ACCOUNT,
      meetings: crmPrimaryRecordTypes.MEETING,
    }
    const hsCrmObjects = Object.entries(hsObjectTypes).map(([key, value]) => ({
      label: Utils.capitalizeString(key),
      name: key,
      icon: includeIcons ? <CrmIcon objectName={key} /> : undefined,
    }))

    return {
      status: EntityStatus.Loaded,
      crmObjects: hsCrmObjects,
    }
  }

  // Return primary objects for Pipedrive
  if (crmKey && crmKey.startsWith('pipedrive')) {
    const crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()
    const pdObjectTypes = {
      deal: crmPrimaryRecordTypes.DEAL,
      organization: crmPrimaryRecordTypes.ACCOUNT,
      person: crmPrimaryRecordTypes.CONTACT,
      lead: crmPrimaryRecordTypes.LEAD,
    }
    const pdCrmObjects = Object.entries(pdObjectTypes).map(([key, _]) => ({
      label: Utils.capitalizeString(key),
      name: key,
      icon: includeIcons ? <CrmIcon objectName={key} /> : undefined,
    }))

    return {
      status: EntityStatus.Loaded,
      crmObjects: pdCrmObjects,
    }
  }

  return {
    status,
    crmObjects,
  }
}
