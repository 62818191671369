// Redux
import { useSelector } from 'react-redux'

// Own components
import UserLogo from './UserLogo'
import PopupMenu from './PopupMenu'
import LuruUser from '../domain/users/LuruUser'
import { getAuthUrl } from '../domutils/utils'
import { useNavigate } from 'react-router-dom'
import { CRMProvider } from '../features/user/types'
import Utils from '../utils/Utils'
import { LuruEntryPoint } from '../app/types'
import luruFetch from '../features/LuruFetch'

export default function LoginControl() {
  const crmId = LuruUser.getCurrentUserCrmName()
  const firstName = useSelector((state) => state.user.data.firstName)
  const lastName = useSelector((state) => state.user.data.lastName)
  const email = useSelector((state) => state.user.data.email)
  const navigate = useNavigate()

  function handleDisconnect() {
    const crmOAuthKeyMap = {
      [CRMProvider.SFDC]: 'salesforce-oauth2',
      [CRMProvider.SFDC_SANDBOX]: 'salesforce-oauth2-sandbox',
      [CRMProvider.HUBSPOT]: 'hubspot',
      [CRMProvider.PIPEDRIVE]: 'pipedrive',
    }
    const oauthKey = crmOAuthKeyMap[crmId]
    const csrftoken = Utils.getCookieValue('csrftoken')
    const disconnectUrl = getAuthUrl() + `/disconnect/${oauthKey}/`

    fetch(disconnectUrl, {
      method: 'POST',
      headers: {
        Accept: 'aspplication/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    }).then((response) => {
      if (response.status === 200) {
        if (LuruUser.isWebappContext()) {
          window.location.href = '/notes'
        } else {
          navigate('/notes')
        }
      }
    })
  }

  function handleSignOut() {
    if (LuruUser.isWebappContext()) {
      window.location.href = '/user/logout'
    } else {
      // luruFetch('/user/logout').then(() => console.log('Signed out'))
      luruFetch('/user/logout')
    }
  }

  function getMenuItems() {
    return crmId === CRMProvider.PIPEDRIVE ? (
      <ul>
        <li onClick={handleSignOut}>Sign Out</li>
        <li onClick={handleDisconnect}>Remove Account</li>
      </ul>
    ) : (
      <ul>
        <li onClick={handleSignOut}>Sign Out</li>
      </ul>
    )
  }

  if (
    [LuruEntryPoint.EMBEDDED_ZOOM_MEETING, LuruEntryPoint.EMBEDDED_ZOOM_PANEL].includes(LuruUser.getCurrentEntryPoint())
  ) {
    return <UserLogo firstname={firstName} lastname={lastName} />
  }

  return (
    <PopupMenu id='loginControlMenu' items={getMenuItems()} leftalign={true}>
      <UserLogo firstname={firstName} lastname={lastName} email={email} />
    </PopupMenu>
  )
}
