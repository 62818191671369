import React from 'react'

import PopupMenu, { PopupMenuProps } from '../../PopupMenu'
import OvalButton from '../../OvalButton'

export interface LuruPopupButtonProps {
  // An optional id (only used for debug)
  id?: string
  // Disable showModal
  disabled?: boolean
  // Stacking anchor
  menuParentSelector?: string
  // Items to render within the menu
  items: any
  // Any additional classes to apply on the button
  classes?: Array<string>
  // Items to render within the menu button
  children: any
  // Should the button have a subdued UI (not bright color background)
  subdued?: boolean
  // Is the menu left-aligned with the button
  leftAlign?: boolean
  // Should the menu be hidden when user clicks inside menu
  hideOnMenuClick?: boolean
  // Force display popup below button/element
  forceBottomPosition?: boolean
  // Custom button title
  title?: string
  // Optional menu width
  menuWidth?: number
  // Function to execute on show menu
  onShowMenu?: () => void
  // Function to execute on hide menu
  onHideMenu?: () => void
  // Varient
  blueVarient?: boolean
  lightBlueVarient?: boolean

  // additional Menu Props
  popupMenuProps?: Partial<PopupMenuProps>
}

export interface LuruPopupButtonRefs {
  menuRef: React.RefObject<PopupMenu>
}

export default class LuruPopupButton extends React.Component<LuruPopupButtonProps> {
  props: LuruPopupButtonProps
  componentRefs: LuruPopupButtonRefs
  eventHandler: LuruPopupButtonEventHandler

  constructor(props: LuruPopupButtonProps) {
    super(props)
    this.props = props
    this.componentRefs = {
      menuRef: React.createRef(),
    }
    this.eventHandler = new LuruPopupButtonEventHandler(this)
  }

  hideMenu() {
    this.componentRefs.menuRef.current?.hideMenu()
  }

  showMenu() {
    this.componentRefs.menuRef.current?.showMenu()
  }

  render() {
    var classList: string[] = []

    if (this.props.classes) {
      classList = classList.concat(this.props.classes)
    }

    var menuProps: PopupMenuProps = {
      menuParentSelector: this.props.menuParentSelector,
      items: this.props.items,
      disabled: this.props.disabled,
      leftalign: Boolean(this.props.leftAlign),
      hideOnMenuClick: this.props.hideOnMenuClick ?? true,
      forceBottomPosition: Boolean(this.props.forceBottomPosition),
      onShowMenu: this.props.onShowMenu,
      onHideMenu: this.props.onHideMenu,
      width: this.props.menuWidth,
      children: (
        <OvalButton
          id={this.props.id}
          classes={classList}
          subdued={this.props.subdued}
          blueVarient={this.props.blueVarient}
          lightBlueVarient={this.props.lightBlueVarient}
          onFocus={this.eventHandler.handlers.onFocus}
          onMouseDown={this.eventHandler.handlers.onMouseDown}
          onClick={(e) => e.preventDefault()}
          title={this.props.title}
        >
          {this.props.children}
        </OvalButton>
      ),
      ...this.props.popupMenuProps,
    }

    if (this.props.id) {
      menuProps.id = this.props.id + '-menu'
    }

    return <PopupMenu ref={this.componentRefs.menuRef} {...menuProps} />
  }
}

class LuruPopupButtonEventHandler {
  #component: LuruPopupButton
  #focusWithMouse: boolean = false

  handlers: {
    onMouseDown: React.MouseEventHandler<HTMLButtonElement>
    onFocus: React.FocusEventHandler<HTMLButtonElement>
  }

  constructor(component: LuruPopupButton) {
    this.#component = component
    this.handlers = {
      onMouseDown: this.#onMouseDown.bind(this),
      onFocus: this.#onFocus.bind(this),
    }
  }

  #onMouseDown(e: React.MouseEvent<HTMLButtonElement>) {
    this.#focusWithMouse = true
  }

  #onFocus(e: React.FocusEvent<HTMLButtonElement>) {
    if (!this.#focusWithMouse) {
      this.#component.componentRefs.menuRef.current?.showMenu()
    }
    this.#focusWithMouse = false
  }
}
