import CRMBaseMiddlewareFactory from './api/crmBaseMiddleware'
import { LuruFieldType } from '../../domain/crmRecord/typings.d'
import CrmRecord from '../../domain/crmRecord/CrmRecord'

/**
 * @classdesc Class for Pipedrive middleware
 */
export default class PipedriveMiddlewareFactory extends CRMBaseMiddlewareFactory {
  constructor() {
    super('pipedrive')
  }

  //// Actions
  /**
   * Normalize a given PIPEDRIVE schema
   * @param {Object} schema - Schema as returned by the CRM
   * @param {string} objectName - Object name in CRM
   * @returns {Object} - A normalized schema which has one 'luruFieldType' key
   * added to the base schema (implementation for now)
   */
  normalizeSchema(schema, objectName) {
    schema.data.forEach((field) => {
      // Add a standard key to point to the referenced object
      field.referencedObject = field.referenced_object_type
      field.label = field.name
      field.name = field.key
      field.luruFieldType = this.#computeLuruFieldType(field.field_type, field.key, objectName) ?? LuruFieldType.TEXT
      field.updateable = Boolean(field.bulk_edit_allowed)
      // Looks like we need to check for mandatory_flag field because for some records create will fail, eg: Create contact without name field
      field.isMandatory = field.mandatory_flag === true && Boolean(field.bulk_edit_allowed)
      field.isFilterable = field.filtering_allowed

      if (field.name === 'stage_id') {
        field.luruFieldType = LuruFieldType.ENUM
        field.field_type = 'enum'
        field.picklistValues = field.options
          ?.map((pipelineLevel) =>
            pipelineLevel.options.map((stageLevel) => ({
              label: pipelineLevel.label + ': ' + stageLevel.label,
              value: stageLevel.id,
            }))
          )
          .flat()
      } else if (
        field.luruFieldType === LuruFieldType.MULTIENUM ||
        field.luruFieldType === LuruFieldType.ENUM ||
        field.luruFieldType === LuruFieldType.ENUM_RADIO ||
        field.luruFieldType === LuruFieldType.ENUM_SELECT
      ) {
        if (field.options && Array.isArray(field.options)) {
          field.picklistValues = []
          field.options.forEach((option) => {
            field.picklistValues.push({
              label: option.label ?? option.name,
              value: option.id.toString(),
            })
          })
        }
      }
    })

    schema.fields = schema.data

    // UPDATE: Invalidate the following comment to make API consistent with
    // SFDC and Hubspot
    // Since PD gives the schema within the 'data' element, we are bothered
    // only about that. Moreover, the other components expect the data in that
    // format for eg: picklistValues should be schema[0].picklistValues

    return schema
  }

  normalizePipelineList(payload) {
    return payload.data?.data?.reduce((p, i) => [...p, { ...i, label: i.name }], []) ?? null
  }

  getStageListFromSchema(schema) {
    const field = schema?.find((field) => field.key === 'stage_id')
    return field.options.map((option) => ({ ...option, name: option.label }))
  }

  //// Calculations
  /**
   * Get primary object names for UI display for PIPEDRIVE
   * @returns {Array} - Array of objects with name and plural version
   */
  getPrimaryObjectNames() {
    var crmPrimaryCrmRecordType = CrmRecord.getAllPrimaryObjects()

    return [
      {
        name: 'deal',
        plural: 'Deals',
        singular: 'Deal',
        isOpportunity: true,
        crmRecordType: crmPrimaryCrmRecordType.DEAL,
      },
      {
        name: 'organization',
        plural: 'Organizations',
        singular: 'Organization',
        crmRecordType: crmPrimaryCrmRecordType.ACCOUNT,
      },
      {
        name: 'person',
        plural: 'People',
        singular: 'Person',
        crmRecordType: crmPrimaryCrmRecordType.CONTACT,
      },
      {
        name: 'lead',
        plural: 'Leads',
        singular: 'Lead',
        crmRecordType: crmPrimaryCrmRecordType.LEAD,
      },
    ]
  }

  /**
   * Fill a record with schema details and return a composed object
   * @param {Object} record - An PIPEDRIVE record, this object will be unchanged
   * @param {Object} schema - Schema of the record
   * @param {string} objectName - SOR object name
   * @returns - Object of type {record, schema}, with record itself of type
   * { value: <PIPEDRIVE value returned>, schema: <normalized schema> }
   */
  mergeRecordWithSchema(record, schema, objectName) {
    let recordResult = {}
    Object.keys(record.data).forEach((fieldName) => {
      let fieldSchema = schema.data.find((item) => item.key === fieldName)
      if (fieldSchema) {
        recordResult[fieldName] = {
          value: this.computeFieldValueAsPrimitive(objectName, fieldName, record.data[fieldName]),
          schema: fieldSchema,
        }
        // We are getting the values from PD as a comma separated string
        // Our expectation is that it will be ';' separated?
        if (recordResult[fieldName]['schema'].luruFieldType === LuruFieldType.MULTIENUM) {
          let selectedvalues = recordResult[fieldName]?.['value']?.split(',') ?? []
          recordResult[fieldName] = {
            ...recordResult[fieldName],
            value: selectedvalues.join(';'),
          }
          if (fieldSchema) {
            fieldSchema.picklistValues.forEach((option) => {
              option = { ...option, value: option?.value?.toString() ?? '' }
            })
          }
        }
      }
    })
    return { record: recordResult, schema }
  }

  /**
   *
   * @param {*} recordName
   * @param {*} fieldName
   * @param {*} apiFieldValue
   * @returns
   */
  computeFieldValueAsPrimitive(recordName, fieldName, apiFieldValue) {
    switch (recordName) {
      case 'person':
        switch (fieldName) {
          case 'email':
            return apiFieldValue.find((item) => item.primary)?.value ?? ''
          case 'phone':
            return apiFieldValue.find((item) => item.primary)?.value ?? ''
          default:
            return apiFieldValue
        }

      default:
        return apiFieldValue
    }
  }

  /**
   * Compute a standardized field type.  The function may use the object name
   * and field name parameters, if required.  For e.g., we may need to identify
   * a note as a LuruFieldType.NOTE and not as 'textarea', while allowing for
   * the option of identifying 'textarea' as LuruFieldType.LARGETEXT.
   * @param {string} pdFieldType - Field type as returned by PIPEDRIVE
   * @param {string} pdFieldName - Field name as returned by PIPEDRIVE
   * @param {string} pdObjectName - Object name in PIPEDRIVE
   * @returns {string} - Luru normalized field type
   */
  #computeLuruFieldType(pdFieldType, pdFieldName, pdObjectName) {
    const map = {
      varchar: pdFieldName === 'email' ? LuruFieldType.EMAIL : LuruFieldType.TEXT,
      monetary: LuruFieldType.CURRENCY,
      int: LuruFieldType.INTEGER,
      double: pdFieldName === 'pipeline_id' ? LuruFieldType.ENUM : LuruFieldType.DOUBLE,
      stage: LuruFieldType.ENUM_RADIO,
      enum: LuruFieldType.ENUM_SELECT,
      status: LuruFieldType.ENUM_RADIO,
      varchar_options: LuruFieldType.ENUM_SELECT,
      date: LuruFieldType.DATE,
      set: LuruFieldType.MULTIENUM,
      phone: LuruFieldType.TELEPHONE,
      address: LuruFieldType.ADDRESS,
      org: LuruFieldType.REFERENCE,
      people: LuruFieldType.REFERENCE,
      user: LuruFieldType.REFERENCE,
      // TODO: Also "Deal Stage", "Pipeline", "Deal Owner" doesn't seem to
      // provide the options.  We need to find out how to get them.
      // "referenced_object_type": "OWNER" -> We need to fetch all users for
      // this
    }
    return pdFieldType in map ? map[pdFieldType] : null
  }

  /*
   * Since the API responses of the SearchRecord API are in different formats
   * we use this function to standardize them for consumption by the FE.
   * This is typically used in the 'link record to CRM' search box
   */
  massageSearchRecordResponse(payload) {
    return payload.map((item) => ({
      ...item,
      data: {
        ...item.data,
        Name: ['lead', 'deal'].includes(item.sor_object_name) ? item.data.title : item.data.name,
      },
    }))
  }

  // PD expects the values as a array of strings
  prepareMultiEnumForUpdate(fieldValue) {
    fieldValue = fieldValue.split(';')
    return fieldValue
  }
}
