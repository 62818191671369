import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DeleteNoteTemplateParameter } from '../middleware/deleteNoteTemplate'

export const deleteNoteTemplate = async (
  params: Readonly<DeleteNoteTemplateParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/note-templates/${params.noteTemplateId}`,
    HTTPMethod.DELETE,
    options
  )

  return request.make()
}
