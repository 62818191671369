import CrmObjectSelectBox from '.'

interface CrmObjectSelectBoxEvents {
  onSelectItem: (sorRecordId: string, sorRecordName: string, data?: any) => void
}

export default class CrmObjectSelectBoxEventHandler {
  #component: CrmObjectSelectBox
  handlers: CrmObjectSelectBoxEvents

  constructor(component: CrmObjectSelectBox) {
    this.#component = component
    this.#component.componentDidMount = this.#componentDidMount.bind(this)
    this.handlers = {
      onSelectItem: this.#onSelectItem.bind(this),
    }
  }

  #componentDidMount() {
    if (this.#component.props.autoFocus) {
      this.#component.componentRefs?.typeAheadSearchSelectBoxRef?.current?.focus?.()
    }
  }

  #onSelectItem(sorRecordId: string, sorRecordName: string, data?: any) {
    if (this.#component.componentRefs.container.current) {
      this.#component.props.onSelectItem(
        sorRecordId,
        sorRecordName,
        this.#component.componentRefs.container.current,
        data
      )
    }
  }
}
