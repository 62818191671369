import { CrmFieldSchema, CrmObjectName, LuruFieldType } from '../../crm/types'
import {
  WorkflowBlockType,
  WorkflowSorName,
  WorkflowSorSchema,
  WorkflowSorSchemaAction,
  WorkflowSorSchemaTrigger,
} from '../types'

export function normalizeWorkflowSchema(workflowSchema: Record<WorkflowSorName, WorkflowSorSchema>) {
  const normalizedSchemas: Array<{
    id: string
    type: WorkflowBlockType
    workflowSor: WorkflowSorName
    sorObjectName: CrmObjectName | 'global' | 'Global'
    schema?: WorkflowSorSchemaTrigger | WorkflowSorSchemaAction
  }> = []
  for (const workflowSor in workflowSchema) {
    if (Object.prototype.hasOwnProperty.call(workflowSchema, workflowSor)) {
      const wfSorSchemaEntity = workflowSchema[workflowSor as WorkflowSorName]
      for (const crmObjectName in wfSorSchemaEntity) {
        if (Object.prototype.hasOwnProperty.call(wfSorSchemaEntity, crmObjectName)) {
          const wfSorSchemaObj = wfSorSchemaEntity?.[crmObjectName as CrmObjectName | 'global' | 'Global']
          if (Object.keys(wfSorSchemaObj?.triggers || {}).length > 0) {
            const triggers = Object.entries(wfSorSchemaObj?.triggers || {}).map(([triggerId, trigger]) => ({
              id: triggerId?.toUpperCase?.(),
              type: WorkflowBlockType.TRIGGER,
              schema: { ...trigger, id: triggerId?.toUpperCase?.() },
              workflowSor: workflowSor,
              sorObjectName: crmObjectName,
            })) as Array<{
              id: string
              type: WorkflowBlockType
              workflowSor: WorkflowSorName
              sorObjectName: CrmObjectName | 'global' | 'Global'
              schema: WorkflowSorSchemaTrigger
            }>

            normalizedSchemas.push(...triggers)
          }
          if (Object.keys(wfSorSchemaObj?.actions || {}).length > 0) {
            const actions = Object.entries(wfSorSchemaObj?.actions || {}).map(([actionId, action]) => ({
              id: actionId,
              type: WorkflowBlockType.ACTION,
              schema: { ...action, id: actionId },
              workflowSor: workflowSor,
              sorObjectName: crmObjectName,
            })) as Array<{
              id: string
              type: WorkflowBlockType
              workflowSor: WorkflowSorName
              sorObjectName: CrmObjectName | 'global' | 'Global'
              schema: WorkflowSorSchemaAction
            }>

            normalizedSchemas.push(...actions)
          }
        }
      }
    }
  }
  return normalizedSchemas
}

export function computeLuruFieldType(filedType: string): LuruFieldType | undefined {
  const map: Record<string, LuruFieldType> = {
    number: LuruFieldType.INTEGER,
    calculation_score: LuruFieldType.INTEGER,
    select: LuruFieldType.ENUM_SELECT,
    textarea: LuruFieldType.LARGETEXT,
    checkbox: LuruFieldType.MULTIENUM,
    booleancheckbox: LuruFieldType.BOOLEAN,
    text: LuruFieldType.TEXT,
    date: LuruFieldType.DATE,
    datetime: LuruFieldType.DATETIME,
    phonenumber: LuruFieldType.TELEPHONE,
    radio: LuruFieldType.ENUM_RADIO,
    reference: LuruFieldType.REFERENCE,
    multi_reference: LuruFieldType.MULTIREFERENCE,
  }

  return map[filedType]
}

export function appendLuruFieldTypeInFieldSchema(fieldSchema?: CrmFieldSchema): CrmFieldSchema | undefined {
  if (!fieldSchema) {
    return undefined
  }
  if (fieldSchema?.luruFieldType) {
    return fieldSchema
  }
  var tempSchema = JSON.parse(JSON.stringify(fieldSchema)) as CrmFieldSchema
  tempSchema.luruFieldType = computeLuruFieldType(tempSchema?.type ?? tempSchema?.native_type) as LuruFieldType

  return tempSchema
}
