// 3rd party libraries
import json5 from 'json5'

// Own libraries
import { JottingType } from '../../features/notes/types'
import { CaretPosition } from '../EditorDOM.js'
import DomUtils from '../utils/DomUtils.tsx'
import EditorTaskFieldView from '../views/EditorTaskFieldView.js'

/**
 * @classdesc Class to handle clipboard events inside editor
 */
export default class ClipboardHandler {
  // Computations

  /**
   * Compute if and how clipboard event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]
    let clipboardEvent = ['cut', 'copy', 'paste'].includes(
      lastEvent?.sourceEvent?.type
    )
      ? lastEvent
      : null

    if (!clipboardEvent) {
      return
    }

    let copyNoteToClipboard = (view) => {
      let range = clipboardEvent.data.range
      let text = this.#computeLuruFormat(view, range)
      let html = this.#computeLuruFormat(view, range, 'html')
      let luru = this.#computeLuruFormat(view, range, 'luru')

      // Set data for different mime types
      clipboardEvent.sourceEvent.clipboardData.setData('text/plain', text)
      clipboardEvent.sourceEvent.clipboardData.setData('text/markdown', text)
      clipboardEvent.sourceEvent.clipboardData.setData('text/html', html)
      clipboardEvent.sourceEvent.clipboardData.setData(
        'application/x-luru',
        luru
      )
    }
    copyNoteToClipboard = copyNoteToClipboard.bind(this)

    return {
      do: (view, controller) => {
        // Prevent default behavior
        clipboardEvent.sourceEvent.preventDefault()

        // Prevent any cut or paste events inside a readonly editor
        if (
          clipboardEvent.sourceEvent.type !== 'copy' &&
          controller.isEditorReadOnly()
        ) {
          // console.log(
          //   `ClipboardHandler: Prevented ${clipboardEvent.sourceEvent.type}`
          // )
          return
        }

        // Copy content to clipboard if event is cut or copy
        if (
          clipboardEvent.sourceEvent.type === 'cut' ||
          clipboardEvent.sourceEvent.type === 'copy'
        ) {
          if (!clipboardEvent.data.range.collapsed) {
            copyNoteToClipboard(view)
          } else {
            // No cut/copy action done
            return
          }
        }

        // Remove range contents if event is cut or 'paste over selection'
        if (
          clipboardEvent.sourceEvent.type === 'cut' ||
          (clipboardEvent.sourceEvent.type === 'paste' &&
            !clipboardEvent.data.range.collapsed)
        ) {
          this.#cutContents(view, clipboardEvent.data.range)
        }
        // Insert contents from clipboard at range
        if (clipboardEvent.sourceEvent.type === 'paste') {
          this.#insertContents(view, clipboardEvent)
        }
      },
    }
  }

  /**
   * Insert contents into note editor at current caret
   * @param {EditorDOM} view - View instance hosting note
   * @param {Object} clipboardEvent - Luru event object
   * @returns
   */
  #insertContents(view, clipboardEvent) {
    let pasteData =
      clipboardEvent.sourceEvent.clipboardData || window.clipboardData

    let luruData = pasteData.getData('application/x-luru')
    if (luruData) {
      this.#insertLuruContent(view, luruData)
    } else {
      let textData = pasteData.getData('text/plain')
      if (textData !== '') {
        this.#insertTextContent(view, textData)
      }
    }

    clipboardEvent.sourceEvent.preventDefault()
  }

  /**
   * Insert (during paste) text inside editor
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {string} text - Text to be pasted inside note
   */
  #insertTextContent(view, text) {
    let range = document.getSelection().getRangeAt(0)
    let noteElement = view.getContainingNoteElement(range.startContainer)
    let lines = text.replace(/\n+$/, '').split(/\r|\n|\r\n/)

    if (lines.length === 1) {
      let template = document.createElement('template')
      template.innerHTML = lines[0].trim()
      range.insertNode(template.content)
      range.collapse(false)
      noteElement.normalize()
      return
    }

    let startNoteElement = noteElement
    let startJottingElement = view.getJottingElement(noteElement)
    let rootJottingType = view.getJottingType(startJottingElement)
    let defaultJottingType = rootJottingType

    if (/heading/.test(defaultJottingType)) {
      defaultJottingType = JottingType.P
    }

    // Get text after caret
    let remainingRange = range.cloneRange()
    remainingRange.setEndAfter(noteElement.lastChild ?? noteElement)
    let remainingLine = remainingRange.textContent

    let firstLinePaste = null
    let excludedJottingsOnPaste = this.#getExcludedJottingsOnPaste()

    lines.forEach((line, index) => {
      let jotting = this.#computeJottingFromText(line)

      if (excludedJottingsOnPaste.includes(jotting.type)) {
        jotting.type =
          jotting.type.indexOf('answer') !== -1
            ? JottingType.A_P
            : JottingType.P
      }

      if (index === 0) {
        firstLinePaste = jotting
      } else {
        let newJottingType =
          jotting.type !== JottingType.P ? jotting.type : defaultJottingType
        view.insertJotting(
          noteElement,
          'after',
          newJottingType,
          null,
          jotting.data
        )
        noteElement = view.getNextNoteElement(noteElement)
      }
    })

    // Set the caret position
    let caretRepoNote = noteElement
    let noteElementToScrollTo = noteElement
    view.setCaretAt(caretRepoNote, CaretPosition.END_OF_NOTE)

    if (remainingLine instanceof String && remainingLine.trim() !== '') {
      // Create a new jotting for the portion of text after caret
      view.insertJotting(
        noteElement,
        'after',
        defaultJottingType,
        null,
        remainingLine
      )
      noteElementToScrollTo = view.getNextNoteElement(noteElement)
    }

    // Scroll to the last note element after insert
    DomUtils.scrollIntoViewIfNeeded(
      noteElementToScrollTo,
      view.getEditorContainer()
    )

    // After rangeAfter would have extracted its contents, paste the first line
    // of clipboard at start note element
    if (startNoteElement) {
      if (startNoteElement.textContent === '') {
        view.changeJottingType(
          view.getJottingElement(startNoteElement),
          firstLinePaste.type
        )
        startNoteElement.innerHTML = firstLinePaste.data
      } else {
        startNoteElement.innerHTML =
          startNoteElement.innerHTML + firstLinePaste.data
      }
    }
    if (startNoteElement.innerHTML !== '') {
      startNoteElement.setAttribute('placeholder', '')
    }

    return
  }

  /**
   * Insert (during paste) luru content inside editor
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {string} luruJson - Text to be pasted inside note
   */
  #insertLuruContent(view, luruJson) {
    let range = document.getSelection().getRangeAt(0)
    let noteElement = view.getContainingNoteElement(range.startContainer)
    let jottings = json5.parse(luruJson)
    // Exclude CRM related jottings on paste
    let excludedJottingsOnPaste = this.#getExcludedJottingsOnPaste()
    jottings = jottings.map((jotting) => {
      let newType = excludedJottingsOnPaste.includes(jotting.type)
        ? jotting.type.indexOf('answer') !== -1
          ? JottingType.A_P
          : JottingType.P
        : jotting.type
      if (
        [JottingType.CRM_FIELD_VALUE, JottingType.A_CRM_FIELD_VALUE].includes(
          jotting.type
        )
      ) {
        // jotting.data = jotting.data?.field?.value ?? ''
        jotting.data = jotting.data?.fieldName ?? ''
      }

      return {
        ...jotting,
        type: newType,
      }
    })

    if (jottings.length === 1) {
      let template = document.createElement('template')
      template.innerHTML = jottings[0].data.trim()
      range.insertNode(template.content)
      range.collapse(false)
      noteElement.normalize()
      return
    }

    let startJottingElement = view.getJottingElement(noteElement)
    let rootJottingType = view.getJottingType(startJottingElement)

    // Get text after caret
    let remainingRange = range.cloneRange()
    remainingRange.setEndAfter(noteElement.lastChild ?? noteElement)
    let remainingLine = this.#computeRangeInnerHTML(
      view,
      remainingRange,
      startJottingElement
    )

    jottings.forEach((jotting, index) => {
      if (index === 0 && noteElement.textContent === '') {
        view.changeJottingType(startJottingElement, jotting.type)
        noteElement.innerHTML = jotting.data
      } else if (index !== jotting.length - 1 || jotting.data !== '') {
        view.insertJotting(
          noteElement,
          'after',
          jotting.type,
          null,
          jotting.data
        )
        noteElement = view.getNextNoteElement(noteElement)
      }
    })

    // Set the caret position
    let caretRepoNote = noteElement
    let noteElementToScrollTo = noteElement
    view.setCaretAt(caretRepoNote, CaretPosition.END_OF_NOTE)

    if (remainingLine !== '') {
      // Create a new jotting for the portion of text after caret
      view.insertJotting(
        noteElement,
        'after',
        rootJottingType,
        null,
        remainingLine
      )
      noteElementToScrollTo = view.getNextNoteElement(noteElement)
    }

    // Scroll to the last note element after insert
    DomUtils.scrollIntoViewIfNeeded(
      noteElementToScrollTo,
      view.getEditorContainer()
    )

    return
  }

  /**
   * Given a plain text, calculate what the most relevant jotting type is
   * @param {string} text - Input text
   * @return {Object} - Luru jotting { type, data }
   */
  #computeJottingFromText(text) {
    let regexp = {
      [JottingType.H1]: /^(\s*#\s*)([^#].*)$/,
      [JottingType.H2]: /^(\s*##\s*)([^#].*)$/,
      [JottingType.H3]: /^(\s*###\s*)([^#].*)$/,
      [JottingType.A_H1]: /^(\s*>\s*#\s*)([^#].*)$/,
      [JottingType.A_H2]: /^(\s*>\s*##\s*)([^#].*)$/,
      [JottingType.A_H3]: /^(\s*>\s*###\s*)([^#].*)$/,
      [JottingType.OL1]: /^(\s{0,3}\d+\.)\s*(.*)$/,
      [JottingType.OL2]: /^(\s{4,7}\d+\.)\s*(.*)$/,
      [JottingType.OL3]: /^(\s{8}\s*\d+\.)\s*(.*)$/,
      [JottingType.A_OL1]: /^(\s*>\s{1,4}\d+\.)\s*(.*)$/,
      [JottingType.A_OL2]: /^(\s*>\s\s{5,8}\d+\.)\s*(.*)$/,
      [JottingType.A_OL3]: /^(\s*>\s{9}\s*\d+\.)\s*(.*)$/,
      [JottingType.UL1]: /^(\s{0,3}[*\-+])\s*(.*)$/,
      [JottingType.UL2]: /^(\s{4,7}[*\-+])\s*(.*)$/,
      [JottingType.UL3]: /^(\s{8}\s*[*\-+])\s*(.*)$/,
      [JottingType.A_UL1]: /^(\s*>\s{1,4}[*\-+])\s*(.*)$/,
      [JottingType.A_UL2]: /^(\s*>\s\s{5,8}[*\-+])\s*(.*)$/,
      [JottingType.A_UL3]: /^(\s*>\s{9}\s*[*\-+])\s*(.*)$/,
      [JottingType.TASK_COMPLETE]: /^(\s*\[\s*x\s*\])\s*(.*)$/,
      [JottingType.TASK_INCOMPLETE]: /^(\s*\[\s*\])\s*(.*)$/,
      [JottingType.A_TASK_COMPLETE]: /^(\s*>\s*\[\s*x\s*\])\s*(.*)$/,
      [JottingType.A_TASK_INCOMPLETE]: /^(\s*>\s*\[\s*\])\s*(.*)$/,
      [JottingType.Q]: /^(\s*\?)\s*(.*)$/,
      [JottingType.Q]: /^(\s*Q\s*:\s*)(.*)$/,
      [JottingType.A_P]: /^(\s*>\s*)(.*)$/,
    }

    let regexpTest = Object.keys(regexp).filter((key) => regexp[key].test(text))
    let jottingType = JottingType.P
    let match = text

    if (regexpTest.length > 0) {
      jottingType = regexpTest[0]
      match = text.match(regexp[jottingType])[2]
    }

    return {
      type: jottingType,
      data: match,
    }
  }

  /**
   * Calculate the Luru content in a given format
   * @param {EditorDOM} view - View instance hosting note
   * @param {Range} range - A range object
   * @param {string} format - text|html|luru
   * @returns {string} - Luru text content of range
   */
  #cutContents(view, range) {
    // Compute starting and ending note elements
    let startNoteElement = view.getContainingNoteElement(range.startContainer)
    if (!startNoteElement && view.isJottingElement(range.startContainer)) {
      startNoteElement = view.getNoteElement(range.startContainer)
    }

    let endNoteElement = view.getContainingNoteElement(range.endContainer)
    if (!endNoteElement && view.isJottingElement(range.endContainer)) {
      endNoteElement = view.getNoteElement(range.endContainer)
    }

    // Remove content in first jotting of selection
    if (startNoteElement === endNoteElement) {
      let cutRange = new Range()
      cutRange.setStart(range.startContainer, range.startOffset)
      cutRange.setEnd(range.endContainer, range.endOffset)
      cutRange.deleteContents()
      return
    } else {
      let cutRange = new Range()
      cutRange.setStart(range.startContainer, range.startOffset)
      cutRange.setEndAfter(startNoteElement?.lastChild ?? startNoteElement)
      cutRange.deleteContents()
    }

    let currentNoteElement = view.getNextNoteElement(startNoteElement)

    // Remove in-between jottings
    while (currentNoteElement !== endNoteElement) {
      let jottingElement = view.getJottingElement(currentNoteElement)
      currentNoteElement = view.getNextNoteElement(currentNoteElement)
      jottingElement.remove()
    }

    // Cut the content in last jotting
    let cutRange = new Range()
    let removeLastJotting = !endNoteElement?.firstChild
    if (!removeLastJotting) {
      cutRange.setStartBefore(endNoteElement?.firstChild)
      cutRange.setEnd(range.endContainer, range.endOffset)
      cutRange.deleteContents()
      if (endNoteElement.textContent === '') {
        removeLastJotting = true
      }
    }

    let repositionRange = new Range()

    if (removeLastJotting) {
      let lastJotting = view.getJottingElement(endNoteElement)
      lastJotting.remove()
      // Set caret at the start of cut selection
      repositionRange.setStart(range.startContainer, range.startOffset)
      repositionRange.collapse(true)
    } else if (startNoteElement.textContent === '') {
      let firstJotting = view.getJottingElement(startNoteElement)
      firstJotting.remove()
      // Set caret at the start of cut selection
      repositionRange.setStart(range.endContainer, range.endOffset)
      repositionRange.collapse(false)
    }

    document.getSelection().removeAllRanges()
    document.getSelection().addRange(repositionRange)

    return
  }

  /**
   * Calculate the Luru content in a given format
   * @param {EditorDOM} view - View instance hosting note
   * @param {Range} range - A range object
   * @param {string} format - text|html|luru
   * @returns {string} - Luru text content of range
   */
  #computeLuruFormat(view, range, format = 'text') {
    let startNoteElement = view.getContainingNoteElement(range.startContainer)
    if (!startNoteElement && view.isJottingElement(range.startContainer)) {
      startNoteElement = view.getNoteElement(range.startContainer)
    }

    let endNoteElement = view.getContainingNoteElement(range.endContainer)
    if (!endNoteElement && view.isJottingElement(range.endContainer)) {
      endNoteElement = view.getNoteElement(range.endContainer)
    }

    let currentOffset = null
    let currentContainer = null
    let currentNoteElement = null

    let result = ''
    let luruFormatData = []

    let counter = {
      [JottingType.OL1]: 0,
      [JottingType.OL2]: 0,
      [JottingType.OL3]: 0,
      [JottingType.A_OL1]: 0,
      [JottingType.A_OL2]: 0,
      [JottingType.A_OL3]: 0,
    }

    while (currentNoteElement !== endNoteElement) {
      if (currentNoteElement === null) {
        currentNoteElement = startNoteElement
        currentContainer = range.startContainer
        currentOffset = range.startOffset
      } else {
        currentNoteElement = view.getNextNoteElement(currentNoteElement)
        currentContainer = currentNoteElement?.firstChild ?? currentNoteElement
        currentOffset = 0
      }

      let currentRange = new Range()

      if (currentNoteElement === endNoteElement) {
        currentRange.setStart(currentContainer, currentOffset)
        currentRange.setEnd(range.endContainer, range.endOffset)
      } else {
        currentRange.setStart(currentContainer, currentOffset)
        currentRange.setEndAfter(
          currentNoteElement?.lastChild ?? currentNoteElement
        )
      }

      let jottingElement = view.getJottingElement(currentNoteElement)
      let jottingType = view.getJottingType(jottingElement)

      counter = this.#computeNextCounterState(counter, jottingType)

      switch (format) {
        case 'html':
          result +=
            this.#computeRangeOuterHTML(view, currentRange, jottingElement) +
            '\n'
          break

        case 'luru':
          if (
            jottingType === JottingType.CRM_FIELD_VALUE ||
            jottingType === JottingType.A_CRM_FIELD_VALUE
          ) {
            luruFormatData.push({
              ...view.getCrmJottingData(jottingElement),
            })
          } else if (view.getTaskJottingTypes().includes(jottingType)) {
            let taskData = view.getTaskJottingData(jottingElement)
            luruFormatData.push({
              type: jottingType,
              data: this.#computeRangeInnerHTML(
                view,
                currentRange,
                jottingElement
              ),
              taskId: taskData?.data?.taskId,
            })
          } else {
            luruFormatData.push({
              type: jottingType,
              data: this.#computeRangeInnerHTML(
                view,
                currentRange,
                jottingElement
              ),
            })
          }
          break

        default:
          result += this.#computePrefixText(view, jottingElement, counter)
          result += this.#computeRangeText(currentRange) + '\n'
      }
    }

    return format === 'luru' ? json5.stringify(luruFormatData) : result
  }

  /**
   * Calculate the text content of a range
   * @param {Range} range - A range object
   * @returns {string} - Text of the range
   */
  #computeRangeText(range) {
    let result = Array.prototype.map
      .call(range.cloneContents().childNodes, (node) => node.textContent)
      .join('')
    return result
  }

  /**
   * Calculate the HTML content of a range inside a given jotting
   * @param {EditorDOM} view - View instance hosting note
   * @param {Range} range - A range object
   * @param {HTMLElement} jottingElement - A jotting element hosting the range
   * @returns {string} - HTML of the jotting element
   */
  #computeRangeInnerHTML(view, range, jottingElement) {
    let noteContents = view.isJottingElement(range.commonAncestorContainer)
      ? view.getNoteElement(range.commonAncestorContainer)
      : range.cloneContents()

    let result = Array.prototype.map
      .call(noteContents.childNodes, (node) =>
        node.nodeType === 3 ? node.textContent : node.outerHTML
      )
      .join('')

    let noteElement = view.getNoteElement(jottingElement)
    let taskInfoElement = noteElement.querySelector(
      EditorTaskFieldView.ReactRootSelector
    )
    let taskInfoHTML = taskInfoElement?.outerHTML ?? ''
    result = result.replace(taskInfoHTML, '')

    return result
  }

  /**
   * Calculate the outer HTML content of a jotting
   * @param {EditorDOM} view - View instance hosting note
   * @param {Range} range - A range object
   * @param {HTMLElement} jottingElement - A jotting element hosting the range
   * @returns {string} - HTML of the jotting element
   */
  #computeRangeOuterHTML(view, range, jottingElement) {
    let result = this.#computeRangeInnerHTML(view, range, jottingElement)

    switch (view.getJottingType(jottingElement)) {
      case JottingType.H1:
      case JottingType.A_H1:
        return `<H1>${result}</H1>`

      case JottingType.H2:
      case JottingType.A_H2:
        return `<H2>${result}</H2>`

      case JottingType.H3:
      case JottingType.A_H3:
        return `<H3>${result}</H3>`

      case JottingType.P:
      default:
        return `<P>${result}</P>`
    }
  }

  /**
   * Calculate the prefix content in a given format
   * @param {EditorDOM} view - View instance hosting note
   * @param {Range} range - A range object
   * @param {Object} counterState - A counter state to set correct numbers for
   * ordered list items; keys will be same as ordered list jotting types
   * @returns {string} - Prefix text content
   */
  #computePrefixText(view, jottingElement, counterState) {
    let jottingType = view.getJottingType(jottingElement)
    let bulletPrefixes = {
      [JottingType.P]: '',
      [JottingType.UL1]: '* ',
      [JottingType.UL2]: '    - ',
      [JottingType.UL3]: '        - ',
      [JottingType.H1]: '# ',
      [JottingType.H2]: '## ',
      [JottingType.H3]: '### ',
      [JottingType.TASK_COMPLETE]: '[x] ',
      [JottingType.TASK_INCOMPLETE]: '[ ] ',
      [JottingType.Q]: 'Q: ',
      [JottingType.A_P]: '> ',
      [JottingType.A_UL1]: '> * ',
      [JottingType.A_UL2]: '>    + ',
      [JottingType.A_UL3]: '>        - ',
      [JottingType.A_H1]: '> # ',
      [JottingType.A_H2]: '> ## ',
      [JottingType.A_H3]: '> ### ',
      [JottingType.A_TASK_COMPLETE]: '> [x] ',
      [JottingType.A_TASK_INCOMPLETE]: '> [ ] ',
    }

    if (jottingType in bulletPrefixes) {
      return bulletPrefixes[jottingType]
    }

    if (jottingType in counterState) {
      let index = counterState[jottingType]
      let quote = view.isAnswerJottingType(jottingType) ? '> ' : ''
      let level = parseInt(jottingType[jottingType.length - 1]) - 1
      return `${quote}${' '.repeat(level * 4)}${index}.`
    }

    return ''
  }

  #computeNextCounterState(counterState, jottingType) {
    let nextState = { ...counterState }
    let resetKeys = []
    switch (jottingType) {
      case JottingType.OL1:
        nextState[JottingType.OL1] += 1
        resetKeys = [
          JottingType.OL2,
          JottingType.OL3,
          JottingType.A_OL1,
          JottingType.A_OL2,
          JottingType.A_OL3,
        ]
        break
      case JottingType.OL2:
        nextState[JottingType.OL2] += 1
        resetKeys = [
          JottingType.OL3,
          JottingType.A_OL1,
          JottingType.A_OL2,
          JottingType.A_OL3,
        ]
        break
      case JottingType.OL3:
        nextState[JottingType.OL3] += 1
        resetKeys = [JottingType.A_OL1, JottingType.A_OL2, JottingType.A_OL3]
        break
      case JottingType.A_OL1:
        nextState[JottingType.A_OL1] += 1
        resetKeys = [
          JottingType.A_OL2,
          JottingType.A_OL3,
          JottingType.OL1,
          JottingType.OL2,
          JottingType.OL3,
        ]
        break
      case JottingType.A_OL2:
        nextState[JottingType.A_OL2] += 1
        resetKeys = [
          JottingType.A_OL3,
          JottingType.OL1,
          JottingType.OL2,
          JottingType.OL3,
        ]
        break
      case JottingType.A_OL3:
        nextState[JottingType.A_OL3] += 1
        resetKeys = [JottingType.OL1, JottingType.OL2, JottingType.OL3]
        break
      default:
        resetKeys = [
          JottingType.A_OL1,
          JottingType.A_OL2,
          JottingType.A_OL3,
          JottingType.OL1,
          JottingType.OL2,
          JottingType.OL3,
        ]
    }

    Object.keys(nextState).forEach((key) => {
      if (resetKeys.includes(key)) {
        nextState[key] = 0
      }
    })

    return nextState
  }

  #getExcludedJottingsOnPaste() {
    let excludedJottingsOnPaste = [
      JottingType.CRM_FIELD_LABEL,
      JottingType.CRM_FIELD_VALUE,
      JottingType.A_CRM_FIELD_LABEL,
      JottingType.A_CRM_FIELD_VALUE,
      JottingType.TASK_COMPLETE,
      JottingType.TASK_INCOMPLETE,
      JottingType.A_TASK_COMPLETE,
      JottingType.A_TASK_INCOMPLETE,
    ]

    return excludedJottingsOnPaste
  }
}
