// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

// Own libraries
import { NotesAPI } from '../api'
import { EntityStatus } from '../../../app/types'
import {
  SearchNotesAPIResponse,
  SearchNotesParameter,
} from '../api/searchAllNotes'
import { AppDispatch, RootState } from '../../../app/store'
import LuruError from '../../LuruError'
import { ReduxNotesState } from '../types'

export const searchSORNotes = {
  action: createAsyncThunk<
    SearchNotesAPIResponse['data'],
    SearchNotesParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: SearchNotesAPIResponse['metadata'] | null
      rejectedMeta: SearchNotesAPIResponse['metadata'] | null
    }
  >(
    'notes/searchSORNotes',
    async (params, { fulfillWithValue, rejectWithValue, signal }) => {
      if (!params?.key) {
        return rejectWithValue(
          { message: 'SearchLuruNotes request should have a key (callerID)' },
          null
        )
      }

      try {
        var notes = (await NotesAPI.searchSORNotes(
          {
            q: params.q?.trim(),
            cursor: params.cursor,
            limit: params.limit,
            sor_object_name: params.sor_object_name,
            sor_record_id: params.sor_record_id,
          },
          { signal }
        )) as SearchNotesAPIResponse

        return fulfillWithValue(notes.data, notes.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(searchSORNotes.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(searchSORNotes.action.fulfilled, (state, action) => {
      // Add notes to store.notes.entities
      var notes = action.payload

      state.refreshedAt = new Date().toISOString()

      for (let note of notes) {
        if (note.note_id) {
          state.entities[note.note_id] = {
            data: note,
            status: !note.body
              ? EntityStatus.LoadedPartially
              : EntityStatus.Loaded,
          }
        }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(searchSORNotes.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    searchSORNotes.addPendingCase(builder)
    searchSORNotes.addFulfilledCase(builder)
    searchSORNotes.addRejectedCase(builder)
  },
}
