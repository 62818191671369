import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod, LuruAPIResponse } from '../../../app/types'
import { DateUtils } from '@/utils/dateUtils'

export interface SearchRecordInfosForWorkflowParameter {
  workflowId: string
  time_min: Date
  time_max: Date
}

export interface RecordInfoForWorkflow {
  sor_object_name: string
  sor_record_id: string
  sor_record_name: string
  owner?: {
    sor_object_name: string
    sor_record_id: string
    sor_record_name: string
  }
  analytics: {
    last_alert_sent: number
    num_alerts: number
    num_updates: number
  }
}

export interface SearchRecordInfosForWorkflowResponse extends LuruAPIResponse {
  data: Array<RecordInfoForWorkflow>
}

export const searchRecordInfosForWorkflow = async (
  params: SearchRecordInfosForWorkflowParameter,
  options?: LuruAPIRequestOptions
) => {
  const { workflowId, ...otherParams } = params
  var urlParams: Record<string, any> = { ...otherParams }

  if (urlParams.time_min) {
    urlParams.time_min = DateUtils.toISOStringCurrentTZ(urlParams.time_min)
  }

  if (urlParams.time_max) {
    urlParams.time_max = DateUtils.toISOStringCurrentTZ(urlParams.time_max)
  }

  var queryString = new URLSearchParams(urlParams as URLSearchParams)
  var request = new LuruAPIRequest(`/api/workflows/${workflowId}/records?${queryString}`, HTTPMethod.GET, options)

  return request.make()
}
