import { useCallback } from 'react'
import { useCrmObjects } from '@/features/crm/hooks/useCrmObjects'
import { EntityStatus } from '@/app/types'
import LuruSelectBox from '@/primitives/ui/LuruSelectBox'
import styles from './styles.module.css'

interface ObjectChooserProps {
  label?: string
  objectName?: string
  onObjectNameChange: (objectName: string) => void
}

export default function ObjectChooser(props: ObjectChooserProps) {
  const { status: objectListFetchingStatus, crmObjects } = useCrmObjects(false, true)
  const handleChooseObject = useCallback(
    (selectedItem: string | null) => {
      if (selectedItem) {
        props.onObjectNameChange(selectedItem)
      }
    },
    [props]
  )

  if (objectListFetchingStatus === EntityStatus.Loaded && crmObjects) {
    const items = crmObjects?.map?.((obj) => ({ name: obj.label, key: obj.name, icon: obj.icon })) ?? []
    return (
      <>
        {props.label && <label>{props.label}</label>}
        <LuruSelectBox
          prechosenItem={crmObjects?.find?.((obj) => obj.name === props.objectName)?.label ?? null ?? undefined}
          items={items}
          onChooseItem={handleChooseObject}
          subdued={true}
          isFilterable={items.length > 5}
          leftAlign={true}
        />
        <div className={styles.spacer}></div>
      </>
    )
  }

  if (objectListFetchingStatus === EntityStatus.ErrorLoading) {
    return <>Error loading objects</>
  }

  return <>Loading objects...</>
}
