import { createCollection } from './createCollection'
import { fetchCollection } from './fetchCollection'
import { updateCollection } from './updateCollection'
import { deleteCollection } from './deleteCollection'
import { hideCollection } from './hideCollection'
import { listCollections } from './listCollections'
import { shareCollection } from './shareCollection'
import { useCollection } from './useCollection'

export const CollectionsMiddleware = {
  createCollection,
  fetchCollection,
  updateCollection,
  deleteCollection,
  hideCollection,
  listCollections,
  shareCollection,
  useCollection,
}
