import React from 'react'
import ModalScreen from '../../../../../primitives/ModalScreen'
import LuruSelectBox from '../../../../../primitives/ui/LuruSelectBox'
import AddCollectionDialogEventHandler from './AddCollectionDialogEventHandler'
import type { AddCollectionDialogConnectedProps } from '.'

import styles from './styles.module.css'
import LuruTextBox from '../../../../../primitives/ui/LuruTextBox'
import LuruUser from '../../../../../domain/users/LuruUser'
import CrmRecord from '../../../../../domain/crmRecord/CrmRecord'
import Utils from '../../../../../utils/Utils'
import CrmMultiObjectFieldChooser from '../../../../../primitives/domain/crm/CrmMultiObjectFieldChooser'
import { CRMProvider } from '../../../../../features/user/types'
import { CrmRecordType } from '../../../../../features/crm/types'
import { ConnectLuruExtensionsProps } from '@/app/types'

export interface AddCollectionDialogComponentProps
  extends AddCollectionDialogConnectedProps,
    ConnectLuruExtensionsProps {}

export interface AddCollectionDialogComponentRefs {
  modal: React.RefObject<ModalScreen>
  container: React.RefObject<HTMLDivElement>
  nameInputRef: React.RefObject<LuruTextBox>
  descriptionInputRef: React.RefObject<LuruTextBox>
}

export interface AddCollectionDialogComponentState {
  newCollection: {
    name: string
    description: string
    sor: string
    sorObjectName: string
    fields: string[]
  }
  alertMessage?: string
  missedMandatoryFields?: string[]
  creating: boolean
  selectedPrimaryObject: PrimaryObject
  crmPrimaryObjects: PrimaryObject[]
}

export interface PrimaryObject {
  name: string
  singular: string
  plural: string
  isOpportunity?: boolean
  crmRecordType: CrmRecordType
}

export default class AddCollectionDialogComponent extends React.Component<
  AddCollectionDialogComponentProps,
  AddCollectionDialogComponentState
> {
  #crmProvider: CRMProvider | undefined

  props: AddCollectionDialogComponentProps
  state: AddCollectionDialogComponentState
  eventHandler: AddCollectionDialogEventHandler
  componentRefs: AddCollectionDialogComponentRefs

  constructor(props: AddCollectionDialogComponentProps) {
    super(props)
    this.#crmProvider = LuruUser.getCurrentUserCrmName()
    this.props = props
    this.state = this.getResetState()

    this.componentRefs = {
      modal: React.createRef(),
      container: React.createRef(),
      nameInputRef: React.createRef(),
      descriptionInputRef: React.createRef(),
    }

    this.eventHandler = new AddCollectionDialogEventHandler(this)
  }

  getResetState() {
    const crmPrimaryObjects = CrmRecord.getPrimaryCrmObjects() as PrimaryObject[]
    const defaultPrimaryObject = crmPrimaryObjects.find(
      (i) => i.crmRecordType === CrmRecord.getAllPrimaryObjects().DEAL
    ) as PrimaryObject
    return {
      newCollection: {
        name: '',
        description: '',
        sor: LuruUser.getCurrentUserCrmName() ?? '',
        sorObjectName: defaultPrimaryObject.name,
        fields: [],
      },
      alertMessage: undefined,
      missedMandatoryFields: [],
      creating: false,
      crmPrimaryObjects: crmPrimaryObjects,
      selectedPrimaryObject: defaultPrimaryObject,
    }
  }

  showModal(crmRecordType?: CrmRecordType) {
    if (crmRecordType) {
      let selectedPrimaryObject = CrmRecord.getPrimaryCrmObjects().find((p) => p.crmRecordType === crmRecordType)

      if (selectedPrimaryObject) {
        this.setState(
          {
            selectedPrimaryObject,
            newCollection: {
              ...this.state.newCollection,
              sorObjectName: selectedPrimaryObject?.name,
              fields: [],
            },
            alertMessage: undefined,
            missedMandatoryFields: [],
          },
          () => this.componentRefs.modal.current?.showModal()
        )
      }
    } else {
      this.setState({
        alertMessage: undefined,
        missedMandatoryFields: [],
        newCollection: {
          ...this.state.newCollection,
          // sorObjectName: selectedPrimaryObject?.name,
          fields: [],
        },
      })
      this.componentRefs.modal.current?.showModal()
    }
  }

  render = () => {
    const { crmPrimaryObjects, newCollection, selectedPrimaryObject, creating, alertMessage, missedMandatoryFields } =
      this.state

    const itemsForSelectBox = crmPrimaryObjects.map((primaryObj) => ({
      name: Utils.capitalizeString(primaryObj.plural),
      key: primaryObj.name,
    }))

    return (
      <ModalScreen
        ref={this.componentRefs.modal}
        width='80%'
        height='auto'
        hideButtons={true}
        title='Create Collection'
        titleCloseButton={true}
        dialogRole='create-collection-dialog'
        className={styles.createCollectionModal}
      >
        <div className={styles.createCollectionDialog} ref={this.componentRefs.container}>
          <div>
            <label className={styles.inputLabel}>Collection applicable for</label>
            <LuruSelectBox
              prechosenItem={Utils.capitalizeString(selectedPrimaryObject.plural)}
              items={itemsForSelectBox}
              onChooseItem={this.eventHandler.handlers.onChooseObjectNameItem}
              leftAlign={true}
            />
            <div className={styles.inputContainer}>
              {alertMessage && <div className={styles.alert}>{alertMessage}</div>}
              <label
                className={[styles.inputLabel, missedMandatoryFields?.includes('name') ? styles.error : ''].join(' ')}
              >
                Collection name*
              </label>
              <LuruTextBox
                additionalClassNames={[styles.inputBox]}
                ref={this.componentRefs.nameInputRef}
                onChange={this.eventHandler.handlers.onChangeName}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>Description</label>
              <LuruTextBox
                additionalClassNames={[styles.inputBox]}
                ref={this.componentRefs.descriptionInputRef}
                onChange={this.eventHandler.handlers.onChangeDescription}
              />
            </div>
            <div className={styles.fieldLabelContainer}>
              <div className={styles.fieldsetChooserContainer}>
                <label className={styles.inputLabel}>
                  {this.#crmProvider === CRMProvider.HUBSPOT ? 'Properties' : 'Fields'}
                </label>
                <CrmMultiObjectFieldChooser
                  key={selectedPrimaryObject.crmRecordType}
                  dialogTitle={
                    'Choose ' +
                    (this.#crmProvider === CRMProvider.HUBSPOT ? 'properties' : 'fields') +
                    ' for collection'
                  }
                  crmRecordType={selectedPrimaryObject.crmRecordType}
                  showOnlyBaseObjectFields={true}
                  selectedItems={newCollection.fields}
                  onSelectionChange={this.eventHandler.handlers.onChooseFields}
                  onFinishedSelection={this.eventHandler.handlers.onChooseFields}
                />
              </div>
            </div>
          </div>
          {this.state.newCollection.fields.length === 0 ? <h3>Choose fields to start with</h3> : null}
          <div className={styles.inviteButtons}>
            <button data-role='cancel-button' onClick={this.eventHandler.handlers.onClickCancelButton}>
              Cancel
            </button>
            <button data-role='invite-button' onClick={this.eventHandler.handlers.onClickCreateButton}>
              {creating ? 'Creating...' : 'Create'}
            </button>
          </div>
        </div>
      </ModalScreen>
    )
  }
}
