import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { CRMProvider } from '../../user/types'
import { WorkflowSorName } from '../types'
import slackIcon from '../../../images/sor/slack/slack_logo.png'
import gChatIcon from '../../../images/sor/google_chat/logo.png'
import msTeamIcon from '../../../images/sor/msteams/logo.png'
import luruIcon from '../../../images/luru/logoU.png'

export function getWorkflowSorIcon(wfSor: WorkflowSorName | CRMProvider) {
  if (
    [CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX, CRMProvider.HUBSPOT, CRMProvider.PIPEDRIVE].includes(
      wfSor as CRMProvider
    )
  ) {
    return CrmRecord.getCrmIcon(wfSor as CRMProvider)
  } else {
    var icons: Record<string, string> = {
      [WorkflowSorName.LURU]: luruIcon,
      [WorkflowSorName.SLACK]: slackIcon,
      [WorkflowSorName.GCHAT]: gChatIcon,
      [WorkflowSorName.MSTEAMS]: msTeamIcon,
    }
    return icons[wfSor]
  }
}
