import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { MeetingsSliceActions } from '../../meetings/meetingsSlice'
import { EmptyAppState, ReduxAppState } from '../types'

export const meetingsSliceInvalidateCache = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(MeetingsSliceActions.invalidateCache, (state, action) => {
      state.home.default.upcomingMeetings = {
        ...EmptyAppState.home.default.upcomingMeetings,
      }
      state.home.search.upcomingMeetings = {
        ...EmptyAppState.home.search.upcomingMeetings,
      }
      state.meetingNotesHome = { ...EmptyAppState.meetingNotesHome }
    })
  },
}
