import styles from './styles.module.css'
import CrmLogSelectBox, { CrmLogSelectBoxProps } from '../domain/crm/CrmLogSelectBox'
import logAsIcon from '../../images/fluent/task_list_square_ltr.svg'
import removeIcon from '../../images/fluent/subtract_circle.svg'
import useCRMLogAsLinkControlComponentState from './useComponentState'
import useCRMLogAsLinkControlEventHandlers from './useEventHandlers'
import { CrmActivityLogType } from '../../features/crm/types'
import CreateActivityLogModal, { CreateActivityLogModalRefsHandler } from './components/CreateActivityLogModal'
import { createRef, useMemo } from 'react'
import { EntityStatus } from '../../app/types'
import LuruSelectBox from '../ui/LuruSelectBox'
import OvalButton from '../OvalButton'
import ModalScreen from '../ModalScreen'
import { CRMProvider } from '../../features/user/types'
import LuruUser from '../../domain/users/LuruUser'
import CrmRecord from '../../domain/crmRecord/CrmRecord'

export interface CRMLogAsLinkControlProps extends CrmLogSelectBoxProps {
  noteID: string
  embedded?: boolean
  onLogSuccess?: () => void
}

export interface CRMLogAsLinkControlRefs {
  createActivityLogModalRef: React.RefObject<CreateActivityLogModalRefsHandler>
  crmLogSelectBoxRef: React.RefObject<LuruSelectBox>
  deleteActivityLogModalRef: React.RefObject<ModalScreen>
}

/**
 * typically log a meeting as a call/event. Both of these are of the object type ‘task’, so we will be creating a task here.
 * Ref: https://docs.google.com/document/d/1pLQX3LS8HGnAEdnvIcelpGqn_c1d9I4oi1ZvQhsLSrM/edit
 *
 */

export default function CRMLogAsLinkControl(props: CRMLogAsLinkControlProps) {
  const { noteID, onChooseItem, ...restProps } = props
  const componentState = useCRMLogAsLinkControlComponentState(props)
  const { connectedRecord, state } = componentState
  const { meetingRecordState, activityRecord, meetingRecord } = componentState.connectedMeetingObj
  const { getState } = state

  const refs: CRMLogAsLinkControlRefs = {
    createActivityLogModalRef: createRef<CreateActivityLogModalRefsHandler>(),
    crmLogSelectBoxRef: createRef<LuruSelectBox>(),
    deleteActivityLogModalRef: createRef<ModalScreen>(),
  }

  const eventHandlers = useCRMLogAsLinkControlEventHandlers(componentState, props, refs)

  const crmActivityLogTypeNamesMap = useMemo(() => {
    const userCrmName = LuruUser.getCurrentUserCrmName()
    return userCrmName === CRMProvider.SFDC || userCrmName === CRMProvider.SFDC_SANDBOX
      ? {
          [CrmActivityLogType.Call]: 'Call',
          [CrmActivityLogType.Meeting]: 'Event',
          meetings: 'Event',
          [CrmActivityLogType.DoNotLog]: 'Do not log',
        }
      : {
          [CrmActivityLogType.Call]: 'Call',
          [CrmActivityLogType.Meeting]: 'Meeting',
          meetings: 'Meeting',
          [CrmActivityLogType.DoNotLog]: 'Do not log',
        }
  }, [])

  var selectLabel =
    meetingRecordState.status === EntityStatus.Loading
      ? 'Loading...'
      : getState.createLogActivityStatus === EntityStatus.Creating
      ? 'Logging...'
      : undefined

  if (getState.logAs && getState.logAs !== CrmActivityLogType.DoNotLog) {
    selectLabel = undefined
  }

  var isLogged = activityRecord.length > 0

  return (
    <div className={[styles.main].join(' ')}>
      <img src={logAsIcon} alt={'Log as '} className={[styles.logAsIcon].join(' ')} data-luru-icon-name='Log-as' />
      {!isLogged ? (
        <CrmLogSelectBox
          {...restProps}
          onChooseItem={(key) => eventHandlers.handleOnChooseLogActivity(key, styles.highLightRecordLinkController)}
          prechosenItem={selectLabel || (isLogged ? getState.logAs : CrmActivityLogType.DoNotLog)}
          disabled={
            meetingRecordState.status !== EntityStatus.Loaded ||
            getState.createLogActivityStatus === EntityStatus.Creating ||
            isLogged
          }
          popupMenuProps={{
            className: styles.popupMenuParent,
            popupMenuClassName: styles.popupMenuClassName,
          }}
          classes={[
            isLogged ? styles.logged : '',
            styles.ovalButton,
            props?.embedded ? styles.ovalButtonEmbedded : styles.ovalButtonRegular,
          ]}
          selectLabel={selectLabel}
          selectLabelClassName={styles.selectLabelClassName}
          selectBoxRef={refs.crmLogSelectBoxRef}
        />
      ) : (
        <OvalButton
          classes={[
            isLogged ? styles.logged : '',
            styles.ovalButton,
            props?.embedded ? styles.ovalButtonEmbedded : styles.ovalButtonRegular,
          ]}
          disabled={getState.deleteLogActivityStatus === EntityStatus.Deleting}
        >
          <span className={styles.text}>
            {selectLabel ||
              crmActivityLogTypeNamesMap[(activityRecord?.[0]?.type || getState.logAs) as CrmActivityLogType]}
          </span>
          <img
            src={removeIcon}
            alt='remove'
            className={[styles.removeActionImg, isLogged ? styles.whiteFilter : ''].join(' ')}
            onClick={() => {
              eventHandlers.handleDeleteActivity()
            }}
          />
        </OvalButton>
      )}

      <CreateActivityLogModal
        sorRecord={
          connectedRecord
            ? { sor_object_name: connectedRecord?.sor_object_name, sor_record_id: connectedRecord?.sor_record_id }
            : undefined
        }
        noteID={noteID}
        activityLogType={CrmActivityLogType.Meeting}
        ref={refs.createActivityLogModalRef}
        onLogSuccess={eventHandlers.onLogSuccess}
        onClose={eventHandlers.onCloseCreateActivityModal}
        timePicked={meetingRecord?.data?.start_time ? new Date(meetingRecord.data.start_time) : undefined}
      />

      <ModalScreen title='Remove activity' ref={refs.deleteActivityLogModalRef}>
        Do you want to remove this activity from {CrmRecord.getCrmName()}?
      </ModalScreen>
    </div>
  )
}
