import { Suspense, useCallback, useState } from 'react'
import Modal from '@/components/Modal'
import styles from '@/routes/settings/sections/Collections/AddCollectionDialog/styles.module.css'
import useAppUIState from '../hooks/useAppUIState'
import useAppUIStateHandlers from '../hooks/useAppUIStateHandlers'
import ObjectChooser from '@/routes/workflowsv2/components/WorkflowAction/actions/SendMessageAction/components/WorkflowActionButtonRow/ObjectChooser'
import LuruTextBox from '@/primitives/ui/LuruTextBox'
import useCrmName from '@/features/crm/hooks/useCrmName'
import { CRMProvider } from '@/features/user/types'
import CrmMultiObjectFieldChooser from '@/primitives/domain/crm/CrmMultiObjectFieldChooser'
import useCollection from '@/features/collections/hooks/useCollection'
import { trackEvent } from '@/analytics/Ga'
import { useAppDispatch } from '@/app/hooks'
import { CollectionsMiddleware } from '@/features/collections/middleware'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '../types'

interface CollectionDialogState {
  name?: string
  desc?: string
  fields: string[]
  objectName?: string
}

export default function CollectionDialog() {
  const appUIState = useAppUIState()

  const dispatch = useAppDispatch()

  const collection = useCollection(appUIState.collectionDialog.collectionId)

  const { showToast } = useLuruToast()

  const [draftCollection, updateDraftCollection] = useState<CollectionDialogState>({
    name: appUIState.collectionDialog.editMode === 'update' ? collection?.name : undefined,
    desc: appUIState.collectionDialog.editMode === 'update' ? collection?.description : undefined,
    objectName:
      appUIState.collectionDialog.editMode === 'update'
        ? collection?.sor_object_name
        : appUIState.collectionDialog.objectName,
    fields: appUIState.collectionDialog.editMode === 'update' ? collection?.fields ?? [] : [],
  })

  const { hideCollectionDialog } = useAppUIStateHandlers()

  const [alertMessage, setAlertMessage] = useState<string | null>(null)

  const [missedMandatoryFields] = useState<string[]>([])

  const crmName = useCrmName()

  const dialogTitle = 'Choose ' + (crmName === CRMProvider.HUBSPOT ? 'properties' : 'fields') + ' for collection'

  const [isLoading, setIsLoading] = useState(false)

  const createCollection = useCallback(() => {
    try {
      trackEvent('create_collection')
      if (!draftCollection.name || !draftCollection.objectName || !draftCollection.fields.length || !crmName) {
        return
      }
      dispatch(
        CollectionsMiddleware.createCollection.action({
          name: draftCollection.name,
          sor: crmName,
          sor_object_name: draftCollection.objectName,
          description: draftCollection.desc,
          fields: draftCollection.fields,
        })
      ).unwrap()
      showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: `Created collection "${draftCollection.name}"`,
        severity: 'success',
      })
    } catch (err) {
      showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: (err as Error).message || 'Error creating collection',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
      hideCollectionDialog()
    }
  }, [crmName, dispatch, draftCollection, hideCollectionDialog, showToast])

  const updateCollection = useCallback(() => {
    try {
      trackEvent('update_collection')
      if (
        !draftCollection.name ||
        !draftCollection.objectName ||
        !draftCollection.fields.length ||
        !crmName ||
        !appUIState.collectionDialog.collectionId
      ) {
        return
      }
      dispatch(
        CollectionsMiddleware.updateCollection.action({
          collectionId: appUIState.collectionDialog.collectionId,
          name: draftCollection.name,
          sor: crmName,
          sor_object_name: draftCollection.objectName,
          description: draftCollection.desc,
          fields: draftCollection.fields,
        })
      ).unwrap()
      showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: `Updated collection "${draftCollection.name}"`,
        severity: 'success',
      })
    } catch (err) {
      showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: (err as Error).message || 'Error updating collection',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
      hideCollectionDialog()
    }
  }, [crmName, dispatch, draftCollection, hideCollectionDialog, appUIState.collectionDialog.collectionId, showToast])

  const handleOk = useCallback(() => {
    setIsLoading(true)

    // Error checks
    if (!draftCollection.name || !draftCollection.objectName || !draftCollection.fields.length) {
      setAlertMessage('Please fill all mandatory fields marked with *')
      return
    }
    // TODO: Create/update collection, etc.
    if (appUIState.collectionDialog.editMode === 'create') {
      createCollection()
    } else if (appUIState.collectionDialog.editMode === 'update') {
      updateCollection()
    }

    setIsLoading(false)
    hideCollectionDialog()
  }, [
    hideCollectionDialog,
    setAlertMessage,
    draftCollection,
    createCollection,
    updateCollection,
    appUIState.collectionDialog.editMode,
  ])

  const handleCollectionNameChange = useCallback(
    (chosenCollectionName: string | null) => {
      updateDraftCollection({
        ...draftCollection,
        name: chosenCollectionName ?? undefined,
      })
    },
    [draftCollection]
  )

  const handleCollectionDescChange = useCallback(
    (description: string | null) => {
      updateDraftCollection({
        ...draftCollection,
        desc: description ?? undefined,
      })
    },
    [draftCollection]
  )

  const handleObjectNameChange = useCallback(
    (chosenObjectName: string) => {
      updateDraftCollection({
        ...draftCollection,
        objectName: chosenObjectName,
      })
    },
    [draftCollection]
  )

  const handleChooseFields = useCallback(
    (chosenFields: string[]) => {
      updateDraftCollection({
        ...draftCollection,
        fields: chosenFields,
      })
    },
    [draftCollection]
  )

  return (
    <Modal
      width='80%'
      height='auto'
      hideButtons={true}
      title={appUIState.collectionDialog.editMode === 'create' ? 'Create Collection' : 'Edit Collection'}
      titleCloseButton={true}
      dialogRole='create-collection-dialog'
      className={styles.createCollectionModal}
      onOk={handleOk}
      onCancel={hideCollectionDialog}
      doShow={appUIState.collectionDialog.isOpen}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <div className={styles.createCollectionDialog}>
          <div>
            <label className={styles.inputLabel}>Collection applicable for</label>
            <ObjectChooser objectName={draftCollection.objectName} onObjectNameChange={handleObjectNameChange} />
            <div className={styles.inputContainer}>
              {alertMessage && <div className={styles.alert}>{alertMessage}</div>}
              <label
                className={[styles.inputLabel, missedMandatoryFields?.includes('name') ? styles.error : ''].join(' ')}
              >
                Collection name*
              </label>
              <LuruTextBox
                additionalClassNames={[styles.inputBox]}
                onChange={handleCollectionNameChange}
                defaultValue={draftCollection.name}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>Description</label>
              <LuruTextBox
                additionalClassNames={[styles.inputBox]}
                onChange={handleCollectionDescChange}
                defaultValue={draftCollection.desc}
              />
            </div>
            <div className={styles.fieldLabelContainer}>
              <div className={styles.fieldsetChooserContainer}>
                <label className={styles.inputLabel}>
                  {crmName === CRMProvider.HUBSPOT ? 'Properties' : 'Fields'}*
                </label>
                {draftCollection.objectName && (
                  <CrmMultiObjectFieldChooser
                    dialogTitle={dialogTitle}
                    crmRecordType={draftCollection.objectName}
                    showOnlyBaseObjectFields={true}
                    selectedItems={draftCollection.fields}
                    onSelectionChange={handleChooseFields}
                    onFinishedSelection={handleChooseFields}
                  />
                )}
              </div>
            </div>
          </div>
          {draftCollection.fields.length === 0 ? <h3>Choose fields to start with</h3> : null}
          <div className={styles.inviteButtons}>
            <button data-role='cancel-button' onClick={hideCollectionDialog}>
              Cancel
            </button>
            <button data-role='invite-button' onClick={handleOk}>
              {isLoading
                ? appUIState.collectionDialog.editMode === 'create'
                  ? 'Creating...'
                  : 'Updating'
                : appUIState.collectionDialog.editMode === 'create'
                ? 'Create'
                : 'Update'}
            </button>
          </div>
        </div>
      </Suspense>
    </Modal>
  )
}
