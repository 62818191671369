import { LuruReduxStore } from '../../../../../../app/store'
import { EntityStatus, LuruEntryPoint } from '../../../../../../app/types'
import { NotesMiddleware } from '../../../../../../features/notes/middleware'
import NewNotesTabPanelComponent from './NewNotesTabPanelComponent'
import update from 'immutability-helper'
import CrmRecord from '../../../../../../domain/crmRecord/CrmRecord'
import { ReduxNoteEntity } from '../../../../../../features/notes/types'
import Utils from '../../../../../../utils/Utils'
import LuruUser from '../../../../../../domain/users/LuruUser'
import { getNewNoteContent } from '../../../../../../features/notes/helpers/getNewNoteContent'
import { CRMProvider } from '../../../../../../features/user/types'

interface NewNotesTabPanelEvents {
  // Add required event handler methods here
  onCollapseExpandSorNotes: () => void
  onClickLoadMoreNotes: (section: 'luru' | 'sor') => void
  onClickNewNote: () => void
}

export default class NewNotesTabPanelEventHandler {
  handlers: NewNotesTabPanelEvents
  #component: NewNotesTabPanelComponent

  constructor(component: NewNotesTabPanelComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentDidUpdate = this.#onComponentUpdate.bind(this)

    this.handlers = {
      // Add event handler methods to this class and assemble them into this
      // object and bind them to this object as well
      onCollapseExpandSorNotes: this.#onCollapseExpandSorNotes.bind(this),
      onClickLoadMoreNotes: this.#onClickLoadMoreNotes.bind(this),
      onClickNewNote: this.#onClickNewNote.bind(this),
    }
  }

  async #onComponentMount() {
    if (this.#component.state.notes.luru.status !== EntityStatus.NotLoaded) {
      return
    }

    try {
      var response = await LuruReduxStore.dispatch(
        // @ts-ignore
        NotesMiddleware.searchLuruNotes.action({
          key: 'omnibox/LuruNotes',
          q: undefined,
          cursor: undefined,
          limit: this.#component.pageSize,
          sor_object_name: CrmRecord.getCrmRecordName(
            this.#component.props.crmRecordType
          ),
          sor_record_id: this.#component.props.sorRecordId,
        })
      )

      if (NotesMiddleware.searchLuruNotes.action.fulfilled.match(response)) {
        this.#updateStateWithNoteSearchResults(
          'luru',
          {
            payload: {
              data: response.payload as Array<ReduxNoteEntity>,
              metadata: {
                cursor: response.meta.cursor,
              },
            },
          },
          'replace'
        )
      }
    } catch (e) {}
  }

  async #onClickNewNote() {
    // Clear search state from component
    this.#component.setState({
      // displayedItemType: 'default',
      // searchQuery: undefined,
    })

    //Link Note Created From Note Tabs to specific copnnection
    let newConnection = {
      connection_id: Utils.generateUUID(),
      sor: LuruUser.getCurrentUserCrmName() as CRMProvider,
      sor_record_id: this.#component.props.sorRecordId,
      sor_record_name: this.#component.props.sorRecordName,
      sor_object_name: CrmRecord.getCrmRecordName(
        this.#component.props.crmRecordType
      ),
    }

    // Dispatch request to Redux store to create new note
    var createNoteResponse = await LuruReduxStore.dispatch(
      // @ts-ignore
      NotesMiddleware.createNote.action({
        ...getNewNoteContent(),
        draftNote: true,
        connections: [newConnection],
      })
    )

    if (NotesMiddleware.createNote.action.fulfilled.match(createNoteResponse)) {
      let newNoteId = createNoteResponse.payload.note_id

      // Go to new note
      if (newNoteId) {
        switch (LuruUser.getCurrentEntryPoint()) {
          case LuruEntryPoint.GLOBALSEARCH:
            this.#component.props?.onClickNewNote?.(createNoteResponse.payload)
            this.#component.props?.onNavigateAway?.()
            break

          default:
            this.#component.props.router.navigate(`/notes/${newNoteId}`)
            this.#component.props?.onClickNewNote?.(createNoteResponse.payload)
            this.#component.props?.onNavigateAway?.()
        }
      }
    }
  }

  // Add or replace Luru or SOR note results from various event points -
  // - List Luru notes on mounting
  // - Expand SOR notes section first time
  // - Click on load more from Luru or SOR notes section
  // 'append' used when Load More button is clicked, 'replace' otherwise
  #updateStateWithNoteSearchResults(
    section: 'luru' | 'sor',
    response: {
      payload: {
        data: Array<ReduxNoteEntity>
        metadata: { cursor: string | null }
      }
    },
    resultAddition: 'append' | 'replace'
  ) {
    var currentNoteIdList = this.#component.state.notes[section].results.map(
      (note) => note.note_id
    )
    var ifNoteNotAlreadyPresent = (note: ReduxNoteEntity) =>
      !currentNoteIdList.includes(note.note_id)
    var results =
      resultAddition === 'replace'
        ? response.payload.data
        : [
            ...this.#component.state.notes[section].results,
            ...response.payload.data?.filter?.(ifNoteNotAlreadyPresent) ?? [],
          ]
    var cursor = response.payload.metadata?.cursor
    var endIndex = Math.min(
      this.#component.state.notes[section].endIndex,
      results?.length ?? -1
    )

    this.#component.setState((prevState) =>
      update(prevState, {
        notes: {
          [section]: {
            results: { $set: results },
            status: { $set: EntityStatus.Loaded },
            cursor: { $set: cursor },
            endIndex: { $set: endIndex },
          },
        },
      })
    )
  }

  #onComponentUpdate() {
    // console.log('NoteExplorer:componentUpdate:state:', this.#component.state)
    // console.log('NoteExplorer:componentUpdate:props:', this.#component.props)
  }

  async #onCollapseExpandSorNotes() {
    var isCollapsed = this.#component.state.sorNotesDisplay === 'collapsed'

    this.#component.setState({
      sorNotesDisplay: isCollapsed ? 'expanded' : 'collapsed',
    })

    if (!isCollapsed) {
      return
    }

    if (
      this.#component.state.notes.sor.status !== EntityStatus.Loaded &&
      this.#component.state.notes.sor.status !== EntityStatus.Loading
    ) {
      this.#component.setState((prevState) =>
        update(prevState, {
          notes: {
            sor: {
              status: {
                $set: EntityStatus.Loading,
              },
            },
          },
        })
      )

      var sorNotesResponse = await LuruReduxStore.dispatch(
        // @ts-ignore
        NotesMiddleware.searchSORNotes.action({
          key: 'home/SORNotes',
          q: undefined,
          cursor: undefined,
          limit: 10,
        })
      )

      if (
        NotesMiddleware.searchSORNotes.action.fulfilled.match(sorNotesResponse)
      ) {
        this.#updateStateWithNoteSearchResults(
          'sor',
          {
            payload: {
              data: sorNotesResponse.payload as Array<ReduxNoteEntity>,
              metadata: { cursor: sorNotesResponse.meta.cursor },
            },
          },
          'replace'
        )
      }
    }
  }

  async #onClickLoadMoreNotes(section: 'sor' | 'luru') {
    var displayedData = this.#component.state.notes[section]
    var cursor = displayedData.cursor
    var lastIndex = (displayedData.results?.length ?? -1) - 1

    // If more results are available in store, increment the endIndex state
    // of component; do not search for more in backend
    if (
      this.#component.state.notes[section].endIndex +
        this.#component.pageSize <=
        lastIndex ||
      cursor === null
    ) {
      this.#component.setState((prevState) =>
        update(prevState, {
          notes: {
            [section]: {
              endIndex: {
                $set: Math.min(
                  this.#component.state.notes[section].endIndex +
                    this.#component.pageSize,
                  lastIndex
                ),
              },
            },
          },
        })
      )

      return
    }

    // If we are already past the available results, (cursor is non-null here)
    var key = section === 'luru' ? 'omnibox/LuruNotes' : 'omnibox/SORNotes'
    var limit = this.#component.pageSize
    var actionKey = section === 'luru' ? 'searchLuruNotes' : 'searchSORNotes'

    this.#component.setState((prevState) =>
      update(prevState, {
        notes: {
          [section]: {
            endIndex: {
              $set:
                this.#component.state.notes[section].endIndex +
                this.#component.pageSize,
            },
            status: {
              $set: EntityStatus.LoadingMore,
            },
          },
        },
      })
    )

    var response = await LuruReduxStore.dispatch(
      // @ts-ignore
      NotesMiddleware[actionKey].action({
        key,
        q: null,
        cursor,
        limit,
        loadMore: true,
      })
    )

    this.#updateStateWithNoteSearchResults(section, response, 'append')
  }
}
