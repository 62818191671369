import { useRef, useState } from 'react'
import EditLinkDialog from '../EditLinkDialog'
import NoteShareDialog from '../../routes/notes/components/NoteShareDialog'
import NoteTemplateShareDialog from '../../routes/noteTemplates/components/NoteTemplateShareDialog'
import CreateCrmRecordDialog from '../domain/crm/CreateCrmRecordDialog'
import TaskViewModal from '../../routes/tasks/TaskViewModal'
import LuruUser from '../../domain/users/LuruUser'
import { CRMProvider } from '../../features/user/types'
import CreateSearchRecordDialog from '../../forms/CreateSearchRecordDialog'
import Omnibox from '../domain/crm/Omnibox'
import LuruLargeTextModalInput from '../ui/LuruLargeTextModalInput'
import AddCollectionDialog from '../../routes/settings/sections/Collections/AddCollectionDialog'
import AppComponentsContext from './AppComponentsContext'
import CrmRecord from '../../domain/crmRecord/CrmRecord'
import ConnectCalendarModal from '@/forms/ConnectCalendarModal'
import EmailConnectModal from '@/email/components/EmailConnectModal/EmailConnectModalComponent'

export function AppComponentsProvider(props) {
  const crmPrimaryRecordType = CrmRecord.getAllPrimaryObjects()
  const [layoutSettings, setLayoutSettings] = useState({
    hamburgerOpen: true,
  })
  const appComponents = {
    editLinkDialog: useRef(),
    noteShareDialog: useRef(),
    connectCalendarModal: useRef(),
    emailConnectModal: useRef(),
    noteTemplateShareDialog: useRef(),
    createRecordDialog: {
      [crmPrimaryRecordType.DEAL]: useRef(),
      [crmPrimaryRecordType.ACCOUNT]: useRef(),
      [crmPrimaryRecordType.CONTACT]: useRef(),
      [crmPrimaryRecordType.LEAD]: useRef(),
    },
    createSearchRecordDialog: useRef(),
    taskViewModal: useRef(),
    omnibox: useRef(),
    largeTextInput: useRef(),
    createCollectionDialog: useRef(),
    layoutSettings: {
      ...layoutSettings,
    },
    updateLayoutSettings: (data) => {
      setLayoutSettings((prevState) => ({ ...prevState, ...data }))
    },
  }

  return (
    <AppComponentsContext.Provider value={appComponents}>
      <CreateSearchRecordDialog ref={appComponents.createSearchRecordDialog} appComponents={appComponents} />
      <EditLinkDialog ref={appComponents.editLinkDialog} tip='Edit URL' />
      <NoteShareDialog ref={appComponents.noteShareDialog} />
      <NoteTemplateShareDialog ref={appComponents.noteTemplateShareDialog} />
      <CreateCrmRecordDialog
        id='__global_createDealDialog'
        ref={appComponents.createRecordDialog[crmPrimaryRecordType.DEAL]}
        crmRecordType={crmPrimaryRecordType.DEAL}
      />
      <CreateCrmRecordDialog
        id='__global_createAccountDialog'
        ref={appComponents.createRecordDialog[crmPrimaryRecordType.ACCOUNT]}
        crmRecordType={crmPrimaryRecordType.ACCOUNT}
      />
      <CreateCrmRecordDialog
        id='__global_createContactDialog'
        ref={appComponents.createRecordDialog[crmPrimaryRecordType.CONTACT]}
        crmRecordType={crmPrimaryRecordType.CONTACT}
      />
      {LuruUser.getCurrentUserCrmName() !== CRMProvider.HUBSPOT ? (
        <CreateCrmRecordDialog
          id='__global_createLeadDialog'
          ref={appComponents.createRecordDialog[crmPrimaryRecordType.LEAD]}
          crmRecordType={crmPrimaryRecordType.LEAD}
        />
      ) : null}
      {props.children}
      <ConnectCalendarModal ref={appComponents.connectCalendarModal} />
      <EmailConnectModal ref={appComponents.emailConnectModal} />
      <TaskViewModal ref={appComponents.taskViewModal} />
      <Omnibox id='__global_omnibox' ref={appComponents.omnibox} />
      <LuruLargeTextModalInput ref={appComponents.largeTextInput} />
      <AddCollectionDialog ref={appComponents.createCollectionDialog} />
    </AppComponentsContext.Provider>
  )
}
