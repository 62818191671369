// Redux
import { NotesMiddleware } from '../features/notes/middleware'
import { NotesSliceActions } from '../features/notes/notesSlice'
// import { JottingType } from '../features/notes/types'
import { getCrmMiddleware } from '../features/crm/crmMiddleware'

// Third party libraries
import json5 from 'json5'

// Own libraries
// import { LuruFieldType } from '../features/crm/api/crmBaseMiddleware'
import { compareJSONStrings } from '../domutils/utils'
import { LuruErrorDetails } from '../features/LuruError'
import { EMBEDDED_NOTE_HOME } from '../entryPoints/EmbeddedNoteApp/EmbeddedNoteAppComponent'
import { getMatchingNoteTemplates } from '../features/notes/helpers/getMatchingNoteTemplates'
import { TasksMiddleware } from '../features/tasks/middleware'
import { trackEvent } from '../analytics/Ga'
import { ToastId } from '@/app_ui/types'

export class NoteEditStatus {
  static Readonly = new NoteEditStatus('Readonly')
  static Editable = new NoteEditStatus('Editable')
  static ReadonlySorNote = new NoteEditStatus('ReadonlySorNote')
  static ReadonlyCRMVersionDifferent = new NoteEditStatus('ReadonlyCRMVersionDifferent')
  static ReadonlyNoEditAccessInCRM = new NoteEditStatus('ReadonlyNoEditAccessInCRM')

  constructor(name) {
    this.name = name
  }
  toString() {
    return `NoteEditStatus.${this.name}`
  }
}

export default class EditorModel {
  #crmId = null
  #entityId = null
  #dispatch = null
  #navigate = null
  #templates = null
  #controller = null
  #entityBody = null
  #taskDetails = null
  #entityCreator = null
  #lastSavedTime = null
  #editorElement = null
  #noteSyncState = null
  #crmConnection = null
  #crmRecordDetails = null
  #supplementaryData = null
  #entityMarkedEditable = false
  #noteEditStatus = NoteEditStatus.Readonly
  #showToast = null
  #hideToast = null

  constructor(config) {
    if (!config) {
      throw new Error('Model cannot be instantiated without config')
    }
    this.#crmId = config.crmId
    this.#entityId = config.noteId ?? null
    this.#dispatch = config.dispatch
    this.#navigate = config.navigate
    this.#editorElement = config.editorElement
    this.#showToast = config.showToast
    this.#hideToast = config.hideToast
  }

  getEntityId() {
    return this.#entityId
  }

  setEntityId(entityId) {
    this.#entityId = entityId
  }

  getEntityBody() {
    return this.#entityBody
  }

  setEntityBody(body) {
    this.#entityBody = body
  }

  setController(controller) {
    this.#controller = controller
  }

  setEntityCreator(creator) {
    this.#entityCreator = creator
  }

  getEntityCreator() {
    return this.#entityCreator
  }

  setSupplementaryData(data) {
    this.#supplementaryData = data
  }

  getDispatcher() {
    return this.#dispatch
  }

  getNavigator() {
    return this.#navigate
  }

  getNoteEditStatus() {
    return this.#noteEditStatus.name
  }

  // Default function for noteId; TODO: extract this to NoteEditorModel
  computeSaveAction(params) {
    return (noteId) =>
      NotesMiddleware.saveNote.action(
        params
          ? {
              note_id: noteId,
              body: params.data,
              sync: params?.sync,
              template_id: params.template_id ? params.template_id : null,
            }
          : { note_id: noteId }
      )
  }

  saveEmtpyNote(params) {
    return (noteId) =>
      NotesMiddleware.saveNote.action(
        params
          ? {
              note_id: noteId,
              body: params.data,
              sync: params?.sync,
              template_id: params.template_id ? params.template_id : null,
            }
          : { note_id: noteId }
      )
  }

  // Default function for noteId; TODO: extract this to NoteEditorModel
  computeFetchAction() {
    return (noteId) => NotesMiddleware.fetchNote.action({ noteId })
  }

  computeReadNoteEntityFromReduxAction() {
    return (noteID) => NotesMiddleware.readNoteEntityFromRedux.action(noteID)
  }

  /**
   * Function to save a note in server
   * @param {Object} params - { data, source, onSuccess }
   */
  saveEntity(params) {
    if (!this.isNoteDirty(params.data)) {
      return
    }
    if (!this.isEntityEditable()) {
      console.warn(`EditorModel:saveEntity:Ignoring save of readonly entity`)
      return
    }

    if (!params || !params.data) {
      return
    } else {
      let bodyObj = json5.parse(params.data)
      if (!(bodyObj instanceof Array)) {
        return
      }
    }

    // Compute actions and handlers
    params.type === 'full' &&
      this.#showToast?.({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: <span>Saving</span>,
        isLoading: true,
      })
    let saveEntityAction = this.computeSaveAction(params)(this.#entityId)

    let errorHandler = (error) => {
      let errorNotificationMsg =
        params.type === 'full'
          ? 'Could not save note.  Please retry.'
          : 'Could not save note.  We will retry.  Please refresh page if error persists.'
      this.#showToast?.({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: <span>{errorNotificationMsg}</span>,
        severity: 'error',
      })
      console.warn(error)
    }

    let successHandler = (response) => {
      try {
        if (response?.error?.name === 'ConditionError') {
          // Ignore - this was a duplicate save
          return
        }

        if (!response || !response.payload) {
          errorHandler(response)
          return
        }

        this.#entityBody = params.data
        this.#lastSavedTime = new Date()

        // Hide any persistent notification (including save error)
        // this.#hideToast?.(ToastId.NOTES_EDITOR_TOAST_ID)

        if (params.type === 'full') {
          // 'full' save is only used for notification purpose.  It does not
          // tamper the body of note object in Redux to avoid any rerender
          const title = response?.payload?.title ?? response?.payload?.data?.title

          this.#showToast?.({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: <span>Saved {title.slice(0, Math.min(title.indexOf(' ', 10), 20))}...</span>,
            severity: 'success',
          })
        }
        params.onSuccess instanceof Function && params.onSuccess.apply()
      } catch (e) {
        errorHandler(e)
      }
    }

    this.#dispatch(saveEntityAction)
      .then(successHandler)
      .catch((e) => {
        console.log(e)
        errorHandler(e)
      })
  }

  /**
   * Replace connections of all tasks in note
   * @param {{sor: string, sor_object_name: string, sor_record_id: string, sor_record_name: string}} conn - CRM connection object
   */
  async updateTaskConnection(conn) {
    try {
      var taskIdList = this.getTasksInNote()
    } catch (e) {
      console.warn(`EditorModel:updateTaskConnection:Cannot get task ID list`)
      throw e
    }

    var taskUpdaters = taskIdList.map(
      (taskId) =>
        new Promise(async (resolve, reject) => {
          try {
            await this.#dispatch(
              TasksMiddleware.updateTask({
                task_id: taskId,
                connections: [{ ...conn }],
              })
            ).unwrap()
            resolve(true)
          } catch (e) {
            reject(e)
          }
        })
    )

    await Promise.allSettled(taskUpdaters).catch((e) => console.log('EditorModel:UpdateTaskConnection:', e))
  }

  /**
   * Remove connections of all tasks in note
   */
  async removeTaskConnections() {
    try {
      var taskIdList = this.getTasksInNote()
    } catch (e) {
      console.warn(`EditorModel:updateTaskConnection:Cannot get task ID list`)
      throw e
    }

    var taskUpdaters = taskIdList.map(
      (taskId) =>
        new Promise(async (resolve, reject) => {
          try {
            await this.#dispatch(
              TasksMiddleware.updateTask.action({
                task_id: taskId,
                connections: [],
              })
            ).unwrap()
            resolve(true)
          } catch (e) {
            reject(e)
          }
        })
    )

    await Promise.allSettled(taskUpdaters)
  }

  /**
   * Get the details required for populating note editor.
   * @returns {Object} - { noteBody, crmRecordDetails, taskDetails }
   */
  async sequentialFetchAndSetupEntityDetails() {
    try {
      // Get note record
      const fetchEntityPayload = await this.fetchEntityDetails()

      // Get and return CRM and task data
      return {
        noteBody: fetchEntityPayload?.body,
        ...(await this.fetchAndSetupSupplementaryDetails(fetchEntityPayload)),
        taskDetails: await this.fetchTaskDetails(this.#entityBody),
      }
    } catch (e) {
      this.handleError({ message: e.message, statusCode: 500, cause: e })
    }
  }

  async fetchEntityDetails() {
    // Get note record
    const fetchAction = this.computeFetchAction()
    const fetchResponse = await this.#dispatch(fetchAction(this.#entityId))
    const fetchEntityPayload = fetchResponse?.payload
    // When /notes/<note_id> page is reloaded, and the <note_id> is a draft note and is not available from the BE, then we should display a toast message "Requested note not found" and we should redirect to the home page.
    if (!fetchResponse.type.endsWith('fulfilled') && fetchEntityPayload?.http_code === 404) {
      this.#showToast?.({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: <span>Request note not found</span>,
        severity: 'warning',
      })
      this.#navigate('/notes')
      return
    } else if (!fetchResponse.type.endsWith('fulfilled')) {
      this.handleError({
        ...fetchEntityPayload,
        statusCode: 500,
        cause: new Error('Redux action was not fulfilled'),
      })
      return
    }

    this.#entityBody = fetchEntityPayload?.body
    this.#noteSyncState = fetchEntityPayload?.sync_state

    return fetchEntityPayload
  }

  async fetchAndSetupEntityDetails() {
    const reduxEntity = await this.#dispatch(this.computeReadNoteEntityFromReduxAction()(this.#entityId))
    const canParallelizeRequests = Boolean(reduxEntity?.payload?.data?.connections)

    if (!canParallelizeRequests) {
      return await this.sequentialFetchAndSetupEntityDetails()
    }

    var noteLoader = async (resolve, reject) => {
      var fetchEntityPayload = await this.fetchEntityDetails()
      resolve(fetchEntityPayload?.body)
    }

    var crmRecordLoader = async (resolve, reject) => {
      var crmRecordDetails = await this.fetchAndSetupSupplementaryDetails(reduxEntity?.payload?.data)
      resolve(crmRecordDetails)
    }

    var promiseErrorHandler = (error) =>
      this.handleError({
        message: error.message,
        statusCode: 500,
        cause: error,
      })

    var promises = {
      note: new Promise(noteLoader).catch(promiseErrorHandler),
      crmRecord: new Promise(crmRecordLoader).catch(promiseErrorHandler),
    }

    var loadRemoteDataResult = await Promise.all([promises.note, promises.crmRecord])

    var taskDetails = await this.fetchTaskDetails(this.#entityBody)

    return {
      noteBody: loadRemoteDataResult[0],
      crmRecordDetails: loadRemoteDataResult[1],
      taskDetails,
    }
  }

  async fetchAndSetupSupplementaryDetails(record) {
    return { ...(await this.fetchAndSetupCrmConnectionDetails(record)) }
  }

  async fetchAndSetupCrmConnectionDetails(record) {
    var crmRecordDetails = null

    // Fetch note entity from redux and read markedEditable field
    const noteEntity = await this.#dispatch(this.computeReadNoteEntityFromReduxAction()(this.#entityId))

    this.#entityMarkedEditable = noteEntity?.payload?.markedEditable ?? false
    this.#crmConnection = Array.isArray(record?.connections)
      ? record.connections.find((item) => item.sor === this.#crmId) ?? null
      : null

    try {
      var getRecordFieldsResponse =
        this.#noteSyncState !== 'private' && this.#crmConnection
          ? await this.#dispatch(
              getCrmMiddleware(this.#crmId).getRecordFields.action({
                sorObjectName: this.#crmConnection.sor_object_name,
                sorRecordId: this.#crmConnection.sor_record_id,
              })
            )
          : null
    } catch (e) {
      console.warn('EditorModel:getRecordFields:Exception:', e)
    }

    crmRecordDetails = getRecordFieldsResponse?.payload
    this.#crmRecordDetails = crmRecordDetails
    this.#templates = await this.#getMatchingTemplates()

    // Calculate note's initial edit status
    this.#noteEditStatus = this.#computeNoteEditStatus()

    return this.#noteSyncState === 'private' ? {} : { crmRecordDetails }
  }

  async fetchTaskDetails(noteBody) {
    try {
      var taskIdList = this.getTasksInNote(noteBody)
    } catch (e) {
      // console.warn(`Cannot extract task ID list from note body`)
      return
    }

    var taskLoaders = taskIdList.map(
      (taskId) =>
        new Promise(async (resolve, reject) => {
          try {
            let taskRecord = await this.#dispatch(TasksMiddleware.fetchTask.action({ taskId })).unwrap()
            resolve(taskRecord)
          } catch (e) {
            reject(e)
          }
        })
    )
    var taskLoadResult = await Promise.allSettled(taskLoaders)

    this.#taskDetails = taskLoadResult.filter((t) => t !== undefined)

    return this.#taskDetails
  }

  getApplicableTemplates() {
    return this.#templates
  }

  async updateCrmConnection(connection) {
    this.#crmConnection = connection ?? null

    let recordDetailsResponse = await this.#dispatch(
      getCrmMiddleware(this.#crmId).getRecordFields.action({
        sorObjectName: this.#crmConnection?.sor_object_name,
        sorRecordId: this.#crmConnection?.sor_record_id,
      })
    )
    this.#crmRecordDetails = recordDetailsResponse?.payload

    await this.setCrmConnectionToTasks(connection)
    await this.refreshApplicableTemplates()
  }

  async removeCrmConnection() {
    await this.removeCrmConnectionFromTasks(this.#crmConnection)
    await this.refreshApplicableTemplates()
    this.#crmConnection = null
    this.#crmRecordDetails = null
  }

  async setCrmConnectionToTasks(connection) {
    var taskIdList = this.getTasksInNote()
    var taskUpdaters = taskIdList.map(
      (taskId) =>
        new Promise((resolve, reject) => {
          this.#dispatch(
            TasksMiddleware.updateTask.action({
              task_id: taskId,
              connections: [
                {
                  sor_object_name: connection.sor_object_name,
                  sor_record_id: connection.sor_record_id,
                  sor_record_name: connection.sor_record_name,
                },
              ],
            })
          )
            .unwrap()
            .then(resolve)
            .catch(reject)
        })
    )
    var taskUpdateResult = await Promise.allSettled(taskUpdaters)

    return taskUpdateResult
  }

  async removeCrmConnectionFromTasks(connection) {
    var taskIdList = this.getTasksInNote()
    var taskUpdaters = taskIdList.map(
      (taskId) =>
        new Promise(async (resolve, reject) => {
          try {
            var task = await this.#dispatch(
              TasksMiddleware.updateTask.action({
                task_id: taskId,
                connections: [],
              })
            ).unwrap()
            resolve(task)
          } catch (e) {
            reject(e)
          }
        })
    )
    var taskUpdateResult = await Promise.allSettled(taskUpdaters)

    return taskUpdateResult
  }

  async refreshApplicableTemplates() {
    this.#templates = await this.#getMatchingTemplates()
  }

  getCrmRecordDetails() {
    return this.#crmRecordDetails
  }

  getCrmConnection() {
    return this.#crmConnection
  }

  /**
   * Handle the event when a CRM field value has been updated
   * @param {Object} crmFieldData - Luru CRM data
   * @param {string} newFieldValue - New field value
   */
  updateRecordField(crmFieldData, newFieldValue) {
    const updatePayload = {
      sorObjectName: crmFieldData.record?.sorObjectName,
      sorRecordId: crmFieldData.record?.sorRecordId,
      fieldName: crmFieldData.field.name,
      fieldValue: newFieldValue,
      fieldType: crmFieldData.field.type,
      luruFieldType: crmFieldData.field.luruFieldType,
      completeRecordFields: this.#crmRecordDetails,
    }
    const crmId = crmFieldData.sorId.toLowerCase()

    trackEvent('update_crm_from_note')

    return this.#dispatch(getCrmMiddleware(crmId).updateRecordField.action(updatePayload)).then((response) => {
      try {
        this.#crmRecordDetails = {
          ...this.#crmRecordDetails,
          record: {
            ...this.#crmRecordDetails.record,
            [crmFieldData.field.name]: {
              ...this.#crmRecordDetails.record[crmFieldData.field.name],
              value: newFieldValue,
            },
          },
        }
      } catch (e) {
        console.warn(e)
      }
    })
  }

  /**
   * Make the current note editable
   */
  makeNoteEditable() {
    if (this.isEntityEditable()) {
      return { next: null }
    }

    if (this.#noteEditStatus === NoteEditStatus.ReadonlyCRMVersionDifferent) {
      return this.#markNoteAsEditable()
    } else if (this.#noteEditStatus === NoteEditStatus.ReadonlySorNote) {
      return this.#convertSorNoteToLuruNote()
    } else {
      throw new Error(LuruErrorDetails.ApplicationError)
    }
  }

  /**
   * Mark current note as editable in Redux
   */
  #markNoteAsEditable() {
    this.#dispatch(NotesSliceActions.markNoteEditable({ noteId: this.#entityId }))
    this.#entityMarkedEditable = true
    this.#noteEditStatus = this.#computeNoteEditStatus()
    return { next: 'makeCurrentNoteEditable' }
  }

  /**
   * Convert current note, which is an SOR only note, to a Luru note
   */
  async #convertSorNoteToLuruNote() {
    // TODO: Use the new API which can create with a connection
    // 1. Create a new Luru note (which is private by default)
    let createDuplicateNoteResponse = await this.#dispatch(
      NotesMiddleware.createNote.action({ duplicateFromNoteId: this.#entityId })
    )
    if (createDuplicateNoteResponse.error) {
      throw createDuplicateNoteResponse.error
    }

    const newNoteId = createDuplicateNoteResponse?.payload?.note_id

    // 2. Add a connection to above note to the same record
    //    (also passing this sor_note_id)
    let linkNoteResponse = await this.#dispatch(
      NotesMiddleware.createNoteConnection.action({
        key: 'home/noteEditor',
        noteId: newNoteId,
        sorObject: {
          sor: this.#crmId,
          sor_record_id: this.#crmConnection?.sor_record_id,
          sor_record_name: this.#crmConnection?.sor_record_name,
          sor_object_name: this.#crmConnection?.sor_object_name,
          // Include the 'sor_note_id' param so that a new note is not
          // created in the SoR; Instead we sync to the same SoR note object
          sor_note_id: this.#crmConnection?.sor_note_id,
        },
      })
    )

    if (linkNoteResponse.error) {
      throw linkNoteResponse.error
    }

    // 3. Update Redux to remove this SoR note and add the new Luru note
    let removeNoteResponse = await this.#dispatch(NotesSliceActions.removeNote({ noteId: this.#entityId }))

    if (removeNoteResponse.error) {
      throw removeNoteResponse.error
    }

    const editorPath = window.location.href.indexOf('/meeting_notes/') !== -1 ? 'meeting_notes' : 'home'
    const path = window.location.href.includes(EMBEDDED_NOTE_HOME)
      ? `${EMBEDDED_NOTE_HOME}?target=specificNote&noteId=${newNoteId}`
      : `/${editorPath}/${newNoteId}`

    return { next: 'navigate', path }
  }

  async createTask(taskData) {
    try {
      var createTaskPayload = {
        title: taskData.data.title,
        status: taskData.data.status,
        connections: this.#crmConnection
          ? [
              {
                sor_object_name: this.#crmConnection.sor_object_name,
                sor_record_id: this.#crmConnection.sor_record_id,
                sor_record_name: this.#crmConnection.sor_record_name,
              },
            ]
          : [],
      }

      var task = await this.#dispatch(TasksMiddleware.createTask.action(createTaskPayload)).unwrap()
      return task.task_id
    } catch (e) {
      console.warn(e)
      this.#showToast?.({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: <span>{'Error creating task:' + e.message}</span>,
        severity: 'error',
      })
    }
  }

  async updateTask(taskData, successMessage = undefined) {
    if (taskData.data.taskId) {
      try {
        await this.#dispatch(
          TasksMiddleware.updateTask.action({
            task_id: taskData.data.taskId,
            title: taskData.data.titleText,
            status: taskData.data.status,
          })
        ).unwrap()

        if (typeof successMessage === 'string' && successMessage.trim() !== '') {
          this.#showToast?.({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: <span>{successMessage}</span>,
            severity: 'success',
          })
        }
      } catch (e) {
        this.#showToast?.({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: <span>{e.message ? 'Error updating task: ' + e.message : 'Cannot update task'}</span>,
          severity: 'error',
        })
      }
    }
  }

  // Computations
  /**
   * Check last saved time of note
   * @return {int} - Last saved time (result of Date.getTime())
   */
  getLastSavedTime() {
    return this.#lastSavedTime
  }

  /**
   * Get the CRM id set for the current user
   * @returns {string} CRM id
   */
  getCrmId() {
    return this.#crmId
  }

  /**
   * Sanitize HTML characters for given string
   */
  sanitizeHtmlCharacters(str) {
    var doc = new DOMParser().parseFromString(str, 'text/html')
    return doc.documentElement.textContent
  }

  /**
   * Checks if note is dirty (and therefore needs saving)
   * @returns {Boolean} - true, if note is dirty
   */
  isNoteDirty(noteBody) {
    if (!this.isEntityEditable()) {
      return false
    }

    let noteTitleChangedTime = parseInt(this.#editorElement.getAttribute('data-title-last-changed-time'), 10)

    // Sanitize noteBody and entityBody for HTML characters
    let cmpEntityBody = this.sanitizeHtmlCharacters(this.#entityBody)
    let cmpNoteBody = this.sanitizeHtmlCharacters(noteBody)
    let areJSONsDifferent = !compareJSONStrings(cmpEntityBody, cmpNoteBody)
    let hasNoteTitleChanged =
      !isNaN(noteTitleChangedTime) && this.getLastSavedTime() === null
        ? true
        : !isNaN(noteTitleChangedTime) &&
          this.getLastSavedTime() !== null &&
          this.getLastSavedTime() < noteTitleChangedTime
    let result = areJSONsDifferent || hasNoteTitleChanged
    result = result || this.#controller?.getNoteDirtyFlag()

    // console.log('-'.repeat(100))
    // console.log('isNoteDirty:noteBody:', cmpNoteBody)
    // console.log('isNoteDirty:entityBody:', cmpEntityBody)
    // console.log('isNoteDirty:result:', result)
    // console.log('isNoteDirty: calculations:')
    // console.log('isNoteDirty: JSON comparisons:', !compareJSONStrings(cmpEntityBody, cmpNoteBody))
    // console.log('isNoteDiry:isNaN(noteTitleChangedTime):', isNaN(noteTitleChangedTime))
    // console.log('isNoteDirty:this.getLastSavedTime():', this.getLastSavedTime())
    // console.log('isNoteDirty:noteTitleChangedTime:', noteTitleChangedTime)
    // console.log(
    //   'isNoteDirty:this.getLastSavedTime() < noteTitleChangedTime:',
    //   this.getLastSavedTime() < noteTitleChangedTime
    // )

    return result
  }

  async #getMatchingTemplates() {
    try {
      var applicableTemplates = await getMatchingNoteTemplates(this.#entityId)

      applicableTemplates = applicableTemplates.sort(
        (item1, item2) => (item2.specificity ?? 0) - (item1.specificity ?? 0)
      )

      return applicableTemplates
    } catch (e) {
      console.warn('EditorModel:#getNoteTemplates:error:', e)
    }
  }

  /**
   * Calculates the note's edit status
   * @returns {NoteEditStatus} - enum
   */
  #computeNoteEditStatus() {
    // 1. If private note, then note is editable
    if (this.#noteSyncState === 'private') {
      return NoteEditStatus.Editable
    }

    // If control comes here, it is either a 'synced' or 'sor' note

    // 2. Check if the user has edit access to the underlying CRM record
    // BUGBUG:
    // Currently, Hubspot / Pipedrive APIs do not send userAccess.
    // So for now, if userAccess is present AND 'can_edit' is false, mark readonly
    // Else if userAccess is not present, assume editable in CRM
    let userAccess = this.#crmRecordDetails?.userAccess ?? null
    if (userAccess && userAccess['can_edit'] === false) {
      return NoteEditStatus.ReadonlyNoEditAccessInCRM
    }

    // 3. Check if this has been explicitly marked editable
    if (this.#entityMarkedEditable) {
      return NoteEditStatus.Editable
    }

    // 4. If its a 'synced' note, check if this has changed in the CRM directly
    if (
      this.#noteSyncState === 'synced' &&
      this.#crmConnection?.sor_version !== null &&
      this.#crmConnection?.sor_version !== this.#crmConnection?.synced_version
    ) {
      return NoteEditStatus.ReadonlyCRMVersionDifferent
    }

    // 5. Check if its an 'sor' note
    if (this.#noteSyncState === 'sor') {
      return NoteEditStatus.ReadonlySorNote
    }

    return NoteEditStatus.Editable
  }

  /**
   * Check whether note is editable
   * @returns {Boolean} - true if note is editable
   */
  isEntityEditable() {
    return this.#noteEditStatus === NoteEditStatus.Editable
  }

  getSavedTask(taskId) {
    if (!this.#taskDetails) {
      return null
    }
    return this.#taskDetails.find((t) => t?.task_id === taskId)
  }

  getSavedTaskDetails() {
    return this.#taskDetails
  }

  /**
   * Get a relevant read only message when note is read only
   * @returns {string} - Message, empty if note is not readonly
   */
  getReadonlyMessage() {
    if (this.isEntityEditable()) {
      return ''
    }

    if (this.#noteEditStatus === NoteEditStatus.ReadonlySorNote) {
      return `This note was created directly in ${this.#crmId}. Do you want to edit it using Luru?`
    } else if (this.#noteEditStatus === NoteEditStatus.ReadonlyCRMVersionDifferent) {
      return [
        `This note's contents has changed`,
        `in ${this.#crmId} since it was last edited in Luru.`,
        `<br/>`,
        `Do you want to continue editing in Luru? This will override those changes.`,
        `<br/>`,
        `Tip: Make a copy of the contents from ${this.#crmId} before editing here`,
      ].join(' ')
    } else if (this.#noteEditStatus === NoteEditStatus.ReadonlyNoEditAccessInCRM) {
      let object_name = this.#crmConnection?.sor_object_name
      let record_name = this.#crmConnection?.sor_record_name
      return [
        `This note is read-only since you don't have access to edit`,
        `the ${object_name} '${record_name}'`,
        `in ${this.#crmId}.`,
        `Please contact your ${this.#crmId} admin for edit access`,
      ].join(' ')
    }
  }

  getTasksInNote(noteBodyJson = null) {
    // console.log('EditorModel:getTasksInNote:entry:noteBodyJson:', noteBodyJson)

    if (!noteBodyJson) {
      noteBodyJson = this.#controller?.getLatestNoteContent?.()
    }

    try {
      var noteBodyObject = json5.parse(noteBodyJson)
    } catch (e) {
      console.warn('EditorModel:getTasksInNote:Error parsing noteBodyJson:', noteBodyJson)
      throw e
    }

    var taskIdList = noteBodyObject
      .map((jotting) => jotting.taskId)
      .filter((taskId) => Boolean(taskId) && taskId !== 'undefined' && taskId !== 'null' && taskId !== '')

    // console.log('EditorModel:getTasksInNote:return:', [...new Set(taskIdList)])

    return [...new Set(taskIdList)]
  }

  /**
   * Handle any application error in this instance
   * @param {{message: string, statusCode: number, cause: Error}} details - Details of error
   */
  handleError(details) {
    this.#navigate('/notes/error', { state: details })
  }
}
