// Script to test applying actions on an invalidated state
import { LuruReduxStore } from '../app/store'
import { ChatSliceActions } from '../features/chat/chatSlice'
import { CollectionsSliceActions } from '../features/collections/collectionsSlice'
import { CrmSliceActions } from '../features/crm/crmSlice'
import { MeetingsSliceActions } from '../features/meetings/meetingsSlice'
import { NotesSliceActions } from '../features/notes/notesSlice'
import { NoteTemplatesSliceActions } from '../features/noteTemplates/noteTemplatesSlice'
import { TasksSliceActions } from '../features/tasks/tasksSlice'
import { ViewsSliceActions } from '../features/views/viewsSlice'
import { WorkflowsSliceActions } from '../features/workflows/workflowsSlice'

// @ts-ignore
export const runLuruReduxTest = () => {
  // @ts-ignore:
  var numTests = window.__luruReduxActionHistory.length
  var numTestsPassed = 0
  var i = 0
  // @ts-ignore: Test all actions
  var testActions = [...window.__luruReduxActionHistory]

  console.log('runLuruReduxTest:entry')
  console.log('runLuruReduxTest: No. of actions available to test:', numTests)

  for (let action of testActions) {
    i++

    // Invalidate all caches
    invalidateAllCaches()

    try {
      LuruReduxStore.dispatch(action)
      numTestsPassed += 1

      console.log(
        'runLuruReduxTest:(',
        i,
        '/',
        numTests,
        '):test for[',
        action.type,
        '] passed'
      )
    } catch (e) {
      console.warn(
        'runLuruReduxTest:(',
        i,
        '/',
        numTests,
        '):test for[',
        action.type,
        '] failed for payload:',
        action.payload,
        ';error:',
        e
      )
    }
  }

  console.log('runLuruReduxTest:', numTestsPassed, '/', numTests, ' passed')
  console.log('runLuruReduxTest:exit')
}

function invalidateAllCaches() {
  LuruReduxStore.dispatch(NotesSliceActions.invalidateCache())
  LuruReduxStore.dispatch(ViewsSliceActions.invalidateCache())
  LuruReduxStore.dispatch(WorkflowsSliceActions.invalidateCache())
  LuruReduxStore.dispatch(ChatSliceActions.invalidateUsersCache())
  LuruReduxStore.dispatch(ChatSliceActions.invalidateConversationsCache())
  LuruReduxStore.dispatch(MeetingsSliceActions.invalidateCache())
  LuruReduxStore.dispatch(NoteTemplatesSliceActions.invalidateCache())
  LuruReduxStore.dispatch(TasksSliceActions.invalidateCache())
  LuruReduxStore.dispatch(CollectionsSliceActions.invalidateCache())
  LuruReduxStore.dispatch(CrmSliceActions.invalidateCache())
}
