import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import {
  NoteTemplateShareInfo,
  ReduxNoteTemplateEntity,
  ReduxNoteTemplatesState,
} from '../types'

export interface ShareNoteTemplateParameter {
  noteTemplateId: string
  shares: Array<NoteTemplateShareInfo>
}

export interface ShareNoteTemplateAPIResponse extends LuruAPIResponse {
  data: ReduxNoteTemplateEntity
}

export const shareNoteTemplate = {
  action: createAsyncThunk<
    ShareNoteTemplateAPIResponse['data'],
    ShareNoteTemplateParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: ShareNoteTemplateAPIResponse['metadata']
      rejectedMeta: ShareNoteTemplateAPIResponse['metadata']
    }
  >(
    'noteTemplates/shareNoteTemplate',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: ShareNoteTemplateAPIResponse =
          await NoteTemplatesAPI.shareNoteTemplate(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(shareNoteTemplate.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(shareNoteTemplate.action.fulfilled, (state, action) => {
      if (state.entities[action.meta.arg.noteTemplateId]?.data) {
        state.entities[action.meta.arg.noteTemplateId].data!.shares =
          action.payload.shares
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(shareNoteTemplate.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    shareNoteTemplate.addPendingCase(builder)
    shareNoteTemplate.addFulfilledCase(builder)
    shareNoteTemplate.addRejectedCase(builder)
  },
}
