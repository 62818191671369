import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import AppComponentsContext from '@/primitives/AppComponents/AppComponentsContext'
import styles from './Header.module.css'
import luruLogo from '@/images/luru/luru_u_logo_white_bg.svg'
import searchIcon from '@/images/search.png'
import LuruUser from '@/domain/users/LuruUser'
import Utils, { getPlatform } from '@/utils/Utils'
import { LuruEntryPoint } from '@/app/types'
import { UserSliceActions } from '@/features/user/userSlice'
import Tooltip from '@/components/Tooltip'
// import { LuruRole } from '@/features/users/types'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
// import useCurrentUserRole from '@/features/user/hooks/useCurrentUserRole'
import { trackEvent } from '@/analytics/Ga'
import meetingIcon from '@/images/fluent/calendar_ltr.svg'
import CalendlyModal from '@/primitives/ui/CalendlyModal'

export default function Header() {
  const reduxSearchShortcut = useAppSelector((state) => state.user?.searchShortcut)
  const shortcut = reduxSearchShortcut ?? (getPlatform() === 'Mac' ? '⌘J' : 'Ctrl+J')
  // const currentUserRole = useCurrentUserRole()
  const dispatch = useAppDispatch()
  const { createSearchRecordDialog } = useContext(AppComponentsContext)
  const handleCreateSearchClick = useCallback(() => {
    createSearchRecordDialog?.current?.showDialog()
    trackEvent('search_record', LuruUser.getCurrentUserCrmName())
  }, [createSearchRecordDialog])
  const [modalState, setModalState] = useState<boolean>(false)

  useEffect(() => {
    if (!reduxSearchShortcut && LuruUser.getCurrentEntryPoint() === LuruEntryPoint.WEBAPP) {
      LuruUser.getSearchShortcut()
        .then((shortcut) =>
          dispatch(UserSliceActions.setSearchShortcut(shortcut ? shortcut : getPlatform() === 'Mac' ? '⌘J' : 'Ctrl+J'))
        )
        .catch((err) => dispatch(UserSliceActions.setSearchShortcut(getPlatform() === 'Mac' ? '⌘J' : 'Ctrl+J')))
    }
  }, [reduxSearchShortcut, dispatch])

  const showModal = useCallback(() => {
    setModalState(true)
  }, [])

  const onClose = useCallback(() => {
    setModalState(false)
  }, [])

  return (
    <>
      <header className={styles.parent}>
        <nav>
          <div>
            <NavLink to={'/'}>
              <img src={luruLogo} title='Luru' alt='Luru' />
            </NavLink>
          </div>
          <div className={styles.search}>
            <Tooltip label={`Talk to a sales process expert`} placement='left'>
              <button
                type='button'
                className={Utils.classes(styles.newRecordAlt, styles.pushRight)}
                onClick={showModal}
              >
                <img src={meetingIcon} alt='Talk to a sales process expert' />
              </button>
            </Tooltip>
            <Tooltip label={`Create or Search Records (${shortcut})`} placement='left'>
              <button
                type='button'
                className={Utils.classes(styles.newRecordAlt, styles.pushRight)}
                onClick={handleCreateSearchClick}
              >
                <img src={searchIcon} alt='Create or Search Records' />
              </button>
            </Tooltip>
          </div>
        </nav>
      </header>
      <CalendlyModal
        // We can just change the url of calendly
        url='https://calendly.com/karthik-luru/luru-intro-30mins'
        onModalClose={onClose}
        open={modalState}
        rootElement={document.getElementById('root') ?? document.body}
      />
    </>
  )
}
