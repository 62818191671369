import { useAppSelector } from '../../../../app/hooks'
import { EntityStatus } from '../../../../app/types'
import { getControlId } from './helpers/getControlId'
import { useCollectionChooserSetup } from './hooks/useCollectionChooserSetup'
import styles from './CRMCollectionChooser.module.css'
import { useCallback, useContext } from 'react'
import { getFilterId } from './helpers/getFilterId'
import { CollectionsList } from './CollectionsList'
import LuruButton from '../../../../primitives/ui/LuruButton'
import AppComponentsContext from '../../../../primitives/AppComponents/AppComponentsContext'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
// import { LuruReduxStore } from '../../../../app/store'
// import { CalendarProvider, CRMProvider } from '../../../../features/user/types'
// import { NotesMiddleware } from '../../../../features/notes/middleware'
// import { NoteSorConnection } from '../../../../features/notes/types'
// import { CreateNoteConnectionParameter } from '../../../../features/notes/middleware/createNoteConnection'

export enum SourceEntityType {
  NOTE = 'note',
  TEMPLATE = 'noteTemplate',
}

export interface CRMCollectionChooserProps {
  entityId: string
  sourceType: SourceEntityType
}

export const CRMCollectionChooser = ({
  entityId,
  sourceType,
}: CRMCollectionChooserProps) => {
  const {
    control: { isVisible, controlRef },
    filter: { filterRef, filterText, selectedIndex, handleFilterBoxChange },
    handleChooseCollection,
    linkedSorObjectName,
  } = useCollectionChooserSetup({ entityId, sourceType })

  const controlId = getControlId(entityId)
  const collectionsStatus = useAppSelector((s) => s.collections.status)
  const handleCreateCollection = useHandleCreateCollection(linkedSorObjectName)

  const numCollections = useAppSelector(
    (state) =>
      Object.keys(state.collections.entities)
        .filter(
          (collectionId) =>
            state.collections.entities[collectionId].status ===
              EntityStatus.Loaded &&
            state.collections.entities[collectionId].data?.sor_object_name ===
              linkedSorObjectName &&
            state.collections.entities[collectionId].data?.name
              ?.toLowerCase()
              .includes(filterText.toLowerCase())
        )
        .map((collId) => state.collections.entities[collId]?.data)
        .filter((coll) => coll !== null).length
  )

  var filterBox = <></>
  var content = <></>
  var footer = <></>

  switch (collectionsStatus) {
    case EntityStatus.Loaded:
      filterBox =
        numCollections > 0 ? (
          <input
            id={getFilterId(entityId)}
            type='search'
            ref={filterRef}
            className={styles.filterBox}
            placeholder='Filter or choose a collection'
            value={filterText}
            onChange={handleFilterBoxChange}
          />
        ) : (
          <></>
        )

      content = (
        <CollectionsList
          {...{
            filterText,
            selectedIndex,
            linkedSorObjectName,
            handleChooseCollection,
          }}
        />
      )

      footer = (
        <footer>
          <div>Esc: Cancel</div>
          <div>
            {'\u2191'} or {'\u2193'}: Navigate
          </div>
        </footer>
      )

      break

    case EntityStatus.ErrorLoading:
      content = <div className={styles.error}>Error fetching collections</div>
      break

    case EntityStatus.Loading:
    default:
      content = <div className={styles.noresults}>Fetching...</div>
      break
  }

  return (
    <div
      id={controlId}
      className={[styles.parent, isVisible ? styles.visible : null].join(' ')}
      ref={controlRef}
    >
      {/* Filter box */}
      <div className={styles.filterBoxContainer}>
        <div className={styles.filterBoxWrapper}>{filterBox}</div>
      </div>
      {/* Content */}
      <div
        className={[
          styles.results,
          numCollections === 0 ? styles.noresults : null,
        ].join(' ')}
      >
        {content}
      </div>
      <div
        className={[
          styles.action,
          numCollections === 0 ? styles.noCollection : null,
        ].join(' ')}
      >
        <LuruButton
          title='Create a new collection'
          onClick={handleCreateCollection}
        >
          + Create new collection
        </LuruButton>
      </div>
      {/* Footer */}
      {footer}
    </div>
  )
}

export const useHandleCreateCollection = (
  linkedSorObjectName: string | undefined
) => {
  const appComponents = useContext(AppComponentsContext)

  const crmRecordType = linkedSorObjectName
    ? CrmRecord.getCrmRecordType(linkedSorObjectName)
    : undefined

  const handleCreateCollection = useCallback(() => {
    if (!linkedSorObjectName || !crmRecordType) {
      console.log({ linkedSorObjectName, crmRecordType })
      return
    }

    appComponents.createCollectionDialog?.current.showModal(crmRecordType)
  }, [linkedSorObjectName, crmRecordType, appComponents.createCollectionDialog])

  return handleCreateCollection
}

// // @ts-ignore
// window.findCurrentNoteConnection = () => {
//   const noteId = window.location.href.split('/').at(-1)

//   const crmList = [
//     CRMProvider.HUBSPOT,
//     CRMProvider.PIPEDRIVE,
//     CRMProvider.SFDC,
//   ] as Array<CalendarProvider | CRMProvider>

//   const crmConnection = noteId
//     ? LuruReduxStore.getState().notes.entities[noteId]?.data?.connections?.find(
//         (conn) => crmList.includes(conn.sor)
//       )
//     : undefined

//   return crmConnection
// }

// // @ts-ignore
// window.deleteCurrentNoteConnection = () => {
//   // @ts-ignore
//   const conn = window.findCurrentNoteConnection() as NoteSorConnection
//   const noteId = window.location.href.split('/').at(-1) as string

//   LuruReduxStore.dispatch(
//     NotesMiddleware.deleteNoteConnection.action({
//       connectionId: conn.connection_id as string,
//       key: 'debug',
//       noteId,
//       sorRecordId: conn.sor_record_id,
//     })
//   )
// }

// // @ts-ignore
// window.deleteCurrentNoteConnection = () => {
//   // @ts-ignore
//   const conn = window.findCurrentNoteConnection() as NoteSorConnection
//   const noteId = window.location.href.split('/').at(-1) as string

//   LuruReduxStore.dispatch(
//     NotesMiddleware.deleteNoteConnection.action({
//       connectionId: conn.connection_id as string,
//       key: 'debug',
//       noteId,
//       sorRecordId: conn.sor_record_id,
//     })
//   )
// }

// // @ts-ignore
// window.addDebugConnection = () => {
//   const noteId = window.location.href.split('/').at(-1) as string

//   const debugConn = {
//     connection_id: '7837b678-2ef4-42a4-8a69-d932bf408cad',
//     sor: 'SFDC',
//     sor_note_id: '0022w000007hD07AAE',
//     sor_record_id: '0032w00000yIbvFAAS',
//     sor_object_name: 'Contact',
//     sor_record_name: 'Ashley James',
//     synced_version: '454f77185b5c162db7ad3634746d5d3e',
//     sor_version: '454f77185b5c162db7ad3634746d5d3e',
//     synced_at: '2023-02-23T05:26:56.422162+00:00',
//     dirty: false,
//     data: null,
//   }

//   const newConnArg: CreateNoteConnectionParameter = {
//     key: 'debug',
//     noteId,
//     sorObject: {
//       sor: debugConn.sor as CRMProvider,
//       sor_object_name: debugConn.sor_object_name,
//       sor_record_id: debugConn.sor_record_id,
//       sor_record_name: debugConn.sor_record_name,
//     },
//   }

//   LuruReduxStore.dispatch(
//     NotesMiddleware.createNoteConnection.action(newConnArg)
//   )
// }
