import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CollectionsAPI } from '../api'
import { ReduxCollectionEntity, ReduxCollectionsState } from '../types'

export interface UseCollectionParameter {
  collectionId: string
}

export interface UseCollectionAPIResponse extends LuruAPIResponse {
  data: ReduxCollectionEntity
}

export const useCollection = {
  action: createAsyncThunk<
    UseCollectionAPIResponse['data'],
    UseCollectionParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: UseCollectionAPIResponse['metadata']
      rejectedMeta: UseCollectionAPIResponse['metadata']
    }
  >('collections/useCollection', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response: UseCollectionAPIResponse = await CollectionsAPI.useCollection(params, { signal })

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null
      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(useCollection.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(useCollection.action.fulfilled, (state, action) => {
      var { collectionId } = action.meta.arg
      if (state.entities[collectionId]?.data) {
        state.entities[collectionId].data = {
          ...state.entities[collectionId].data,
          updated_at: new Date().toISOString(),
          used_at: new Date().toISOString(),
        } as ReduxCollectionEntity
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(useCollection.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    useCollection.addPendingCase(builder)
    useCollection.addFulfilledCase(builder)
    useCollection.addRejectedCase(builder)
  },
}
