// Get note details as a map

import { RootState } from '../../../app/store'
import { ReduxNoteEntity } from '../types'

// Output: Map: note_id -> {note-detail-object}
export const getNoteDetailMap =
  (request: string | Array<string>) => (state: RootState) => {
    if (typeof request === 'string') {
      request = [request]
    }

    if (!Array.isArray(request)) {
      return null
    }

    var result: Record<string, Partial<ReduxNoteEntity>> = {}

    for (let noteId of Object.keys(state.notes?.entities)) {
      if (state.notes.entities[noteId]) {
        let note = state.notes.entities[noteId]?.data
        if (note && note.note_id) {
          result[note.note_id] = {
            note_id: note.note_id,
            sync_state: note.sync_state,
            title: note.title,
            created_at: note.created_at,
            updated_at: note.updated_at,
            connections: note.connections,
          }
        }
      }
    }

    return result
  }
