import { PayloadAction } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { ReduxWorkflowsState } from '../types'

export const invalidateCache = (
  state: ReduxWorkflowsState,
  action: PayloadAction<undefined>
) => {
  state.refreshedAt = undefined
  state.status = EntityStatus.NotLoaded
  state.entities = {}
}
