import { LuruReduxStore } from '@/app/store'
import { HTTPMethod } from '../../../app/types'
import { DateUtils } from '../../../utils/dateUtils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ListMeetingsParameter } from '../middleware/listMeetings'
import { UserSliceActions } from '@/features/user/userSlice'

export const listMeetings = async (params: ListMeetingsParameter, options: LuruAPIRequestOptions) => {
  var urlParams: Partial<ListMeetingsParameter> = { ...params }

  for (let key in params) {
    let typedKey = key as keyof ListMeetingsParameter

    if (params[typedKey] === undefined || params[typedKey] === null) {
      delete urlParams[typedKey]
    } else if (typedKey === 'q') {
      urlParams.q = urlParams.q?.trim()
    }
  }

  if (urlParams.q === '') {
    delete urlParams.q
  }

  delete urlParams.key
  delete urlParams.loadMore
  delete urlParams.loadPrev

  if (urlParams.time_max) {
    urlParams.time_max = DateUtils.toAPIDateString(new Date(urlParams.time_max))
  }

  if (urlParams.time_min) {
    urlParams.time_min = DateUtils.toAPIDateString(new Date(urlParams.time_min))
  }

  var request = new LuruAPIRequest(
    '/api/meetings?' + new URLSearchParams(urlParams as URLSearchParams),
    HTTPMethod.GET,
    options
  )

  const response = await request.make().catch((err) => {
    if (err?.toErrorValue?.()?.message !== 'Request aborted' && err?.toErrorValue?.()?.error_code) {
      // Dispatch calendar error message to Redux
      LuruReduxStore.dispatch(
        UserSliceActions.setConnectedCalendarErrorCode({ errorCode: err?.toErrorValue?.()?.error_code })
      )
    }
    return Promise.reject(err)
  })
  return response
}
