import { useCallback, useMemo } from 'react'
import useAppStateDispatch from './useAppUIStateDispatch'
import { AppUIStateAction, AppUIStateActionType } from '../reducer/types'
import hideToastHelper from '../helpers/hideToastHelper'

export interface ShowToastParams {
  id: string
  message: React.ReactNode
  severity?: 'success' | 'error' | 'warning' | 'info'
  isLoading?: boolean
  duration?: null | number
  withdrawDefaultHideBehaviour?: boolean
}

export default function useAppUIStateHandlers() {
  const dispatch = useAppStateDispatch() as React.Dispatch<AppUIStateAction>

  const showCollectionDialog = useCallback(
    (payload?: { objectName?: string; collectionId?: string; editMode?: 'create' | 'update' }) => {
      dispatch({ type: AppUIStateActionType.SHOW_COLLECTION_DIALOG, payload: payload ?? {} })
    },
    [dispatch]
  )

  const hideCollectionDialog = useCallback(() => {
    dispatch({ type: AppUIStateActionType.HIDE_COLLECTION_DIALOG, payload: {} })
  }, [dispatch])

  const hideToast = useCallback(
    (id: string) => {
      hideToastHelper(id)
      dispatch({ type: AppUIStateActionType.HIDE_TOAST, payload: { id } })
      setTimeout(() => {
        dispatch({ type: AppUIStateActionType.CLOSE_TOAST, payload: { id } })
      }, 200) // Same as transition duration in Toast.module.css; change both together if required
    },
    [dispatch]
  )

  const showToast = useCallback(
    (params: ShowToastParams) => {
      try {
        dispatch({ type: AppUIStateActionType.SHOW_TOAST, payload: params })
        if (params.duration) {
          setTimeout(() => {
            hideToast(params.id)
          }, params.duration)
        } else {
          // Default time for hide toast is 7s for now.
          if (!params.withdrawDefaultHideBehaviour) {
            if (params.severity === 'error') {
              setTimeout(() => {
                //Check whether the toast is in loading state or not.
                const elementIsLoading = document.getElementById(`toast-${params.id}`)?.dataset?.isloading
                elementIsLoading === 'false' && hideToast(params.id)
              }, 7000)
            } else if (params.severity) {
              setTimeout(() => {
                //Check whether the toast is in loading state or not.
                const elementIsLoading = document.getElementById(`toast-${params.id}`)?.dataset?.isloading
                elementIsLoading === 'false' && hideToast(params.id)
              }, 5000)
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    [dispatch, hideToast]
  )

  return useMemo(
    () => ({
      showCollectionDialog,
      hideCollectionDialog,
      showToast,
      hideToast,
    }),
    [showCollectionDialog, hideCollectionDialog, showToast, hideToast]
  )
}
