import { LuruFieldType } from '../../features/crm/types'

type Alignment = 'left' | 'right' | 'center'

type Settings = {
  textBoxAlignment: Record<string, Alignment>
  getAlignment: (_: string) => Alignment | undefined
}

export const ViewDisplaySettings: Settings = {
  textBoxAlignment: {
    [LuruFieldType.CURRENCY]: 'right',
    [LuruFieldType.DOUBLE]: 'right',
    [LuruFieldType.INTEGER]: 'right',
    [LuruFieldType.INTEGER_NOFORMAT]: 'right',
    [LuruFieldType.PERCENTAGE]: 'right',
    [LuruFieldType.DATE]: 'center',
    [LuruFieldType.DATETIME]: 'center',
  },

  getAlignment: (luruFieldType: string) =>
    ViewDisplaySettings.textBoxAlignment[luruFieldType] ?? 'left',
}
