import { useAppSelector } from '@/app/hooks'
import { MessagingProvider } from '../types'

export const useConnectedMessagingProvider = () => {
  const messagingProviders = [MessagingProvider.SLACK, MessagingProvider.GCHAT, MessagingProvider.MSTEAMS]
  // feat-admin-slack: We will return true if user has admin connected to Slack or user has connected to Slack
  return useAppSelector((state) =>
    state.user.data.userSettings.connections.find((conn) => messagingProviders.includes(conn.name as MessagingProvider))
  )?.name as MessagingProvider | undefined
}
