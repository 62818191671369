import { forwardRef } from 'react'
import styles from './css/RecordLinkStatusButton.module.css'
import crmIconStyles from './css/CRMIcons.module.css'
import { crmIcons } from './CRMIcons'
import plusIcon from '../images/fluent/add_circle.svg'
import removeIcon from '../images/fluent/subtract_circle.svg'
import cloudIcon from '../images/fluent/cloud.svg'
import meetingIcon from '../images/fluent/calendar_ltr.svg'
import OvalButton from './OvalButton'
import { EntityStatus } from '../app/types'
import CrmRecord from '../domain/crmRecord/CrmRecord'

let RecordLinkStatusButton = (props, actionIconRef) => {
  let link = props.link
  let linkType = props.linkType
  let linkingTooltip
  let leadingIcon
  let leadingIconTitle
  let leadingIconStyle
  let buttonText
  let actionIcon
  let recordName
  let luruIconName = null

  if (link?.connection) {
    // Meeting
    if (linkType === 'meeting') {
      linkingTooltip = 'Linked to Meeting'
      leadingIcon = meetingIcon
      leadingIconStyle = styles.meetingIcon
      leadingIconTitle = 'Meeting'
      buttonText = link.connection.sor_record_name?.trim?.() ? link.connection.sor_record_name : '(No Name)'
      recordName = buttonText
    }
    // CRM
    else if (linkType === 'crm') {
      linkingTooltip = `Linked to ${CrmRecord.getCrmName()}`
      let crmId = link.connection.sor?.toLowerCase(),
        crmObjectName = link.connection.sor_object_name?.toLowerCase()

      leadingIcon = cloudIcon
      if (crmId && crmObjectName) {
        leadingIcon = crmIcons[crmId][crmObjectName]
        luruIconName = `${crmId}-${crmObjectName}`
      }
      leadingIconTitle = link.connection.sor_object_name
      leadingIconStyle = styles.crmIcon
      buttonText = link.connection.sor_record_name?.trim?.() ? link.connection.sor_record_name : '(No Name)'
      recordName = buttonText
    }
    // User
    else if (linkType === 'user') {
      linkingTooltip = 'Linked to User'
      leadingIcon = null
      leadingIconStyle = styles.meetingIcon
      leadingIconTitle = 'User'
      buttonText = link.connection.name
      recordName = buttonText
    }

    buttonText = <span className={styles.text}>{buttonText}</span>
    actionIcon = removeIcon
  } else {
    if (linkType === 'crm') {
      linkingTooltip = `Link to ${CrmRecord.getCrmName()} record`
      leadingIcon = cloudIcon
      leadingIconTitle = 'CRM Record'
      leadingIconStyle = styles.cloudIcon
      buttonText = <span className={styles.text}>Link to {CrmRecord.getCrmName()}</span>
      actionIcon = plusIcon
    } else if (linkType === 'meeting') {
      linkingTooltip = 'Link to meeting'
      leadingIcon = meetingIcon
      leadingIconTitle = 'Meeting'
      leadingIconStyle = styles.meetingIcon
      buttonText = <span className={styles.text}>Link to meeting</span>
      actionIcon = plusIcon
    } else if (linkType === 'user') {
      linkingTooltip = props.linkingTooltip ?? 'Link to user'
      leadingIcon = null
      leadingIconTitle = 'User'
      leadingIconStyle = styles.meetingIcon
      buttonText = <span className={styles.text}>{props.linkingTooltip ?? 'Link to user'}</span>
      actionIcon = plusIcon
    }
  }

  let actionButton =
    link?.status !== EntityStatus.Loading ? (
      <img src={actionIcon} alt={linkingTooltip} className={styles.action} ref={actionIconRef ?? null} />
    ) : (
      ''
    )

  let leadingIconClassList = [leadingIconStyle, styles.iconStyle]
  if (linkType === 'crm') {
    leadingIconClassList.push(crmIconStyles.icon)
  }

  let leadingIconImg =
    leadingIcon !== null ? (
      <img
        src={leadingIcon}
        alt={leadingIconTitle}
        className={leadingIconClassList.join(' ')}
        data-luru-icon-name={luruIconName}
      />
    ) : null

  return (
    <div className={styles.main}>
      {leadingIconImg}
      <OvalButton
        subdued={Boolean(link?.connection)}
        loading={link?.status === EntityStatus.Loading}
        title={recordName}
        classes={
          Boolean(link?.connection)
            ? [
                styles.buttonBorder,
                styles.ovalButton,
                props.embedded ? styles.ovalButtonEmbedded : styles.ovalButtonRegular,
              ]
            : [styles.ovalButton, props.embedded ? styles.ovalButtonEmbedded : styles.ovalButtonRegular]
        }
      >
        {buttonText}
        {actionButton}
      </OvalButton>
    </div>
  )
}

export default forwardRef(RecordLinkStatusButton)
