import LuruError, { LuruErrorName, LuruErrorDetails } from './LuruError'

export function responseToJson(response) {
  return Promise.all([response, response.json()])
}

export function handleApiResponse(
  [response, json],
  rejectWithValue,
  rejectAsPromise = false
) {
  if (!response.ok) {
    let errorData = json.error_data
    if (!errorData) {
      let error = new LuruError(LuruErrorDetails.ApplicationError)
      errorData = error.toErrorValue()
    }
    if (rejectWithValue) {
      return rejectWithValue(errorData)
    } else if (rejectAsPromise) {
      return Promise.reject(errorData)
    } else {
      throw new Error(
        errorData.message ?? LuruErrorDetails.ApplicationError.DefaultMessage
      )
    }
  }
  return { data: json.data, metadata: json.metadata }
}

export function handleApiException(
  error,
  rejectWithValue,
  rejectAsPromise = false
) {
  if (rejectAsPromise) {
    return Promise.reject(error)
  }

  var luruError = new LuruError(LuruErrorName.ApplicationError, error.message)

  if (rejectWithValue) {
    return rejectWithValue(luruError.toErrorValue())
  }

  throw new Error(
    luruError.message ?? LuruErrorDetails.ApplicationError.DefaultMessage
  )
}
