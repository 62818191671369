import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CrmAPI } from '../api'
import { ReduxCrmKey, ReduxCrmState } from '../types'

export interface ListRecordsInfoParameter {
  crmKey: ReduxCrmKey
  records: { sor_object_name: string; sor_record_id: string }[]
}

export interface ListRecordsInfoAPIResponse extends LuruAPIResponse {
  data: Array<{
    sor_object_name: string
    sor_record_id: string
    sor_record_name: string
    sor_owner_name: string
    sor_owner_id: string
  }>
}

export const listRecordsInfo = {
  action: createAsyncThunk<
    ListRecordsInfoAPIResponse['data'],
    ListRecordsInfoParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ListRecordsInfoAPIResponse['metadata']
      rejectedMeta: ListRecordsInfoAPIResponse['metadata']
    }
  >('crm/listRecordsInfo', async (params, { getState, signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await CrmAPI.listRecordsInfo(params, {
        signal,
      })) as ListRecordsInfoAPIResponse
      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null
      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(listRecordsInfo.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(listRecordsInfo.action.fulfilled, (state, action) => {
      var { crmKey } = action.meta.arg
      var records = action.payload
      records.forEach((record) => {
        var { sor_object_name, sor_record_id, sor_record_name, sor_owner_name, sor_owner_id } = record
        state[crmKey] = {
          ...state[crmKey],
          record_info: {
            ...state[crmKey]?.record_info,
            [sor_object_name]: {
              ...state[crmKey]?.record_info?.[sor_object_name],
              [sor_record_id]: {
                sor_record_name,
                sor_owner_name,
                sor_owner_id,
              },
            },
          },
        }
      })
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(listRecordsInfo.action.rejected, () => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    listRecordsInfo.addPendingCase(builder)
    listRecordsInfo.addFulfilledCase(builder)
    listRecordsInfo.addRejectedCase(builder)
  },
}
