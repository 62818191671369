import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { RootState } from '../../app/store'
import { UserSignInStatus, LuruAppErrorCode } from '../../features/user/types'
import { EmbeddedNoteAppComponentsProvider } from '../../primitives/AppComponents/EmbeddedNoteApp'
import { LoginScreen } from '../../routes/LoginScreen'
import EmbeddedNoteWrapper from '../../routes/meetingNotes/EmbeddedNoteWrapper'
import EmbeddedMeetingNoteWrapper, { RedirectToEmbeddedNote } from '../../routes/meetingNotes/EmbeddedMeetingNote'
import EmbeddedNoteCreateDraftNote from '../../routes/meetingNotes/embeddedNote/EmbeddedNoteCreateDraftNote'
import ZoomConnectModal from '../../routes/meetingNotes/ZoomConnectModal'
import { EmbeddedNoteAppConnectedProps } from './index'
import LuruUser from '../../domain/users/LuruUser'
import { ConnectLuruExtensionsProps, LuruEntryPoint } from '../../app/types'
import { ChromeUpdateBannerBasedOnNewExtensionApi } from '../../primitives/ChromeUpdateBannerBasedOnNewExtensionApi'
import { ToastId } from '@/app_ui/types'

export interface EmbeddedNoteAppProps extends EmbeddedNoteAppConnectedProps, ConnectLuruExtensionsProps {}

export default class EmbeddedNoteApp extends React.Component<EmbeddedNoteAppProps> {
  props: EmbeddedNoteAppProps

  constructor(props: EmbeddedNoteAppProps) {
    super(props)
    this.props = props
  }

  #onChromeMessageListen(request: any, sender: any, sendResponse: any) {
    var { messageName } = request
    switch (messageName) {
      case 'reloadSidePanel':
        if ([LuruEntryPoint.EMBEDDED_GMEET_NOTE].includes(LuruUser.getCurrentEntryPoint())) {
          document?.location?.reload?.()
        }
        sendResponse({
          messageName: 'response:reloadSidePanel',
          success: true,
        })
        break

      default:
        break
    }
    // This listener will respond asynchronously
    return true
  }

  componentDidMount(): void {
    //Add Chrome.runtime event listeners for Embedded Note App
    chrome?.runtime?.onMessage?.addListener?.(this.#onChromeMessageListen)

    // Add signedIn status for Usetiful
    document.body.setAttribute('data-luru-signin-status', this.props.userSignInStatus)

    window.addEventListener('load', () => {
      chrome.runtime?.sendMessage({ messageName: 'getTabId' }, (response) => {
        document.body.setAttribute('data-luru-tab-id', response.payload.tabId)
      })
    })

    window.addEventListener('load', () => {
      const zoomConnectButton = window.document.getElementById('connectToZoom')
      const zoomConnectUrl = zoomConnectButton?.getAttribute?.('luru-data')

      if (zoomConnectUrl) {
        zoomConnectButton?.addEventListener('click', () => {
          chrome.runtime.sendMessage({
            messageName: 'closeConnectToZoomPopupAndCreateTabForZoomOAuth',
            payload: {
              zoomConnectUrl: zoomConnectUrl,
            },
          })
        })
      }
    })

    window.addEventListener('load', () =>
      document.body.addEventListener('redirectEmbeddedNote', (e) => {
        chrome.runtime.sendMessage(
          {
            messageName: 'redirectEmbeddedNote',
            // @ts-ignore
            payload: e.detail,
          },
          () => {}
        )
      })
    )

    // Check for o365cal_state and show info to user
    if (this.props.userSignInStatus === 'signedIn') {
      const o365cal_state = this.props.router.searchParams.get('o365cal_state')
      const isCalenderConnected = LuruUser.getCurrentUserCalendarName()
      if (!isCalenderConnected && o365cal_state) {
        this.props.toast.showToast({
          id: ToastId.EMBEDDED_NOTE_APP_TOAST_ID,
          message: 'There was an issue connecting to Microsoft Office 365 Calendar. Please retry later',
          severity: 'warning',
        })
        this.props.router.searchParams.delete('o365cal_state')
        this.props.router.navigate(this.props.router.searchParams.toString())
      }
    }
  }

  componentWillUnmount(): void {
    //Remove Chrome.runtime event listeners for Embedded Note App
    chrome?.runtime?.onMessage?.removeListener?.(this.#onChromeMessageListen)
  }

  render(): React.ReactNode {
    return this.props.userSignInStatus === UserSignInStatus.SIGNED_IN &&
      this.props.appErrorCode !== LuruAppErrorCode.AUTHORIZATION_ERROR
      ? this.#renderLoggedInState()
      : this.#renderLoggedOutState()
  }

  #renderLoggedInState() {
    return (
      <EmbeddedNoteAppComponentsProvider>
        <div className='main'>
          <EmbeddedNoteDecisionTree />
          <ChromeUpdateBannerBasedOnNewExtensionApi />
          {/* {this.#renderRoutes()}
          <Outlet /> */}
        </div>
      </EmbeddedNoteAppComponentsProvider>
    )
  }

  #renderLoggedOutState() {
    return <LoginScreen />
  }

  #renderRoutes() {
    return (
      <Routes>
        <Route path='/pages/embeddednote/index.html' element={<Navigate to='/' />} />
        <Route path='/'>element={<EmbeddedNoteCreateDraftNote />}</Route>
        <Route path='/embedded_new_draft_note/' element={<EmbeddedNoteCreateDraftNote />} />
        <Route
          path='/embedded_note/:urlNoteId'
          // @ts-ignore
          element={<EmbeddedNoteWrapper />}
        />
        <Route
          path='/embedded_meeting_note/:meetingUrl'
          // @ts-ignore
          element={<EmbeddedMeetingNoteWrapper />}
        />
        <Route
          path='/signin_and_embed_meeting_note/:tabId/:meetingUrl'
          element={<RedirectToEmbeddedNote embedded={true} />}
        />
      </Routes>
    )
  }
}

function EmbeddedNoteDecisionTree() {
  const [searchParams] = useSearchParams()
  const target = searchParams.get('target')
  const noteId = searchParams.get('noteId')
  const encodedMeetingUrl = searchParams.get('encodedMeetingUrl')

  const calendarId =
    useSelector((state: RootState) => state.user?.data?.userSettings?.connectedCalendar?.name) ?? 'no-calendar'

  const componentKey = `${searchParams.toString()}:${calendarId}`

  switch (target) {
    case 'draftNote':
      return <EmbeddedNoteCreateDraftNote key={componentKey} />

    case 'noteForMeeting':
      return <EmbeddedMeetingNoteWrapper key={componentKey} embeddedNoteId={undefined} meetingUrl={encodedMeetingUrl} />

    case 'specificNote':
      return <EmbeddedMeetingNoteWrapper key={componentKey} embeddedNoteId={noteId} meetingUrl={undefined} />

    case 'connectToZoom':
      return <ZoomConnectModal key={componentKey} />

    default:
      return <EmbeddedNoteCreateDraftNote key={componentKey} />
  }
}

export const EMBEDDED_NOTE_HOME = '/pages/embeddednote/index.html'
// chrome?.runtime?.getURL('/pages/embeddednote/index.html') ?? ''
