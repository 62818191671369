// 3rd party components
import json5 from 'json5'

// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

// Own libraries
import { NotesAPI } from '../api'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { ReduxNoteEntity, ReduxNotesState } from '../types'
import { AppDispatch, RootState } from '../../../app/store'
import LuruError from '../../LuruError'

export type FetchNoteParameter = {
  noteId: string
}

export interface FetchNoteAPIResponse extends LuruAPIResponse {
  data: ReduxNoteEntity
}

// Async thunks for integrating notes API with notes slice
export const fetchNote = {
  action: createAsyncThunk<
    FetchNoteAPIResponse['data'],
    FetchNoteParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: FetchNoteAPIResponse['metadata']
      rejectedMeta: FetchNoteAPIResponse['metadata']
    }
  >(
    'notes/fetchNote',
    async (
      { noteId },
      { getState, rejectWithValue, fulfillWithValue, signal }
    ) => {
      var currEntity = getState().notes.entities[noteId]

      if (
        currEntity &&
        (currEntity?.status === EntityStatus.Loaded ||
          currEntity?.isDraft === true)
      ) {
        return fulfillWithValue(
          // When entity status is loaded, we must have the full note entity
          // So, we are type-casting what was originally defined as as Partial
          // to a full ReduxNoteEntity
          currEntity.data as ReduxNoteEntity,
          null
        )
      }

      try {
        var response = (await NotesAPI.fetchNote(
          { noteId },
          { signal }
        )) as FetchNoteAPIResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(fetchNote.action.pending, (state, action) => {
      var { noteId } = action.meta.arg

      if (!state.entities[noteId]) {
        state.entities[noteId] = {
          status: EntityStatus.Loading,
          data: {
            note_id: noteId,
          },
        }
      }

      var currEntity = state.entities[noteId]

      if (
        currEntity &&
        currEntity?.status !== EntityStatus.Loaded &&
        currEntity?.isDraft !== true
      ) {
        currEntity.status = EntityStatus.Loading
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(fetchNote.action.fulfilled, (state, action) => {
      // Request may have been fulfilled by the thunk from redux itself
      // In this case, we need not update redux.  If the request had been
      // satisfied using an API, then we need to add an entry in redux
      var { noteId } = action.meta.arg
      var currEntity = state.entities[noteId]

      if (!currEntity) {
        return
      }

      if (
        currEntity.status === EntityStatus.Loading ||
        currEntity.status === EntityStatus.LoadedPartially
      ) {
        currEntity.status = EntityStatus.Loaded
      }

      currEntity.data = action.payload

      if (currEntity.data && typeof currEntity.data?.body !== 'string') {
        currEntity.data.body = json5.stringify(currEntity.data.body)
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(fetchNote.action.rejected, (state, action) => {
      var { noteId } = action.meta.arg
      var currEntity = state.entities[noteId]

      if (currEntity) {
        currEntity.status = EntityStatus.ErrorLoading
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    fetchNote.addPendingCase(builder)
    fetchNote.addFulfilledCase(builder)
    fetchNote.addRejectedCase(builder)
  },
}
