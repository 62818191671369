import json5 from 'json5'
import { LuruReduxStore } from '../app/store/LuruReduxStore'
import { HTTPMethod, LuruEntryPoint } from '../app/types'
import LuruUser from '../domain/users/LuruUser'
import LuruError, { LuruErrorName } from './LuruError'
import { UserSliceActions } from './user/userSlice'
import { LuruAPIResponse } from '../app/types'

interface NormalizedFetchResponse {
  ok: boolean
  networkError: boolean
  status: number
  statusText: string
  headers: Headers
  text: string
}

// Response for malformed responses
const UnknownErrorResponse: LuruAPIResponse = {
  http_code: 400,
  method: HTTPMethod.GET, // Not important to get this correct, fix it later
  request_id: '',
  data: null,
  metadata: null,
  error_data: {
    error_code: 99999,
    description: 'Malformed response',
    message: 'Cannot parse response as JSON',
    traceback: '',
  },
}

const AbortResponse: LuruAPIResponse = {
  http_code: 400,
  method: HTTPMethod.GET, // Not important to get this correct, fix it later
  request_id: '',
  data: null,
  metadata: null,
  error_data: {
    error_code: 99998,
    description: 'Aborted',
    message: 'Request aborted',
    traceback: '',
  },
}

export default function luruFetch(...args: any): Promise<Response> {
  if (!args?.length) {
    throw new LuruError(LuruErrorName.InvalidArgumentError, 'No URL given')
  }

  var url = args[0]

  async function fetchHandler(
    resolve: (value: Response | PromiseLike<Response>) => void,
    reject: (reason?: any) => void
  ) {
    var networkError = true
    var signalSet: AbortSignal | undefined = undefined

    // If there is any issue during fetch, try..catch it and reject
    try {
      var fetchResponse: NormalizedFetchResponse | null = null

      if (
        [
          LuruEntryPoint.EMBEDDED_GMEET_NOTE,
          LuruEntryPoint.GLOBALSEARCH,
          LuruEntryPoint.NEWTAB,
          LuruEntryPoint.OPTIONS,
        ].includes(LuruUser.getCurrentEntryPoint())
      ) {
        let tenantDomain = LuruUser.getTenantDomain()

        if (!tenantDomain) {
          fetchResponse = null
        } else {
          // Prefix tenant domain
          url = tenantDomain + url

          // Delete non-serializable signal
          if (typeof args[1]?.signal !== 'undefined') {
            delete args[1]?.signal
          }

          // Prepare message payload for background service worker
          let fetchMessage = {
            messageName: 'fetch',
            payload: { args: [url, ...args.slice(1)] },
          }

          // Send message to background service worker
          fetchResponse = (await chrome.runtime.sendMessage(fetchMessage)) as NormalizedFetchResponse
          networkError = fetchResponse?.networkError
        }
      } else {
        // Construct a standard fetch response object
        try {
          signalSet = args[1]?.signal

          if (!signalSet) {
            const controller = new AbortController()
            signalSet = controller.signal

            if (args.length === 1) {
              args.push({ signal: signalSet })
            } else {
              // args.length >= 2
              args[1].signal = signalSet
            }
          }

          fetchResponse = await fetch(url, ...args.slice(1)).then(async (r) => {
            networkError = false
            return {
              ok: r.ok,
              networkError: false,
              status: r.status,
              statusText: r.statusText,
              headers: r.headers,
              text: await r.text(),
            }
          })
        } catch (e) {
          networkError = !signalSet?.aborted
        }
      }

      // At this point, we have a normalized fetch response object
      // We will examine it and handle success & error cases
      if (networkError) {
        // Dispatch network error message to Redux
        LuruReduxStore.dispatch(UserSliceActions.setNetworkErrorState({}))
        reject({ message: 'Network error', description: 'Network error' })
        return
      }

      try {
        var responseJson = json5.parse(fetchResponse?.text ?? 'null') as LuruAPIResponse | null
      } catch (e) {
        responseJson = null
      }
      responseJson = signalSet?.aborted ? AbortResponse : responseJson?.error_data ? responseJson : UnknownErrorResponse
      if (responseJson.error_data?.error_code === 10002) {
        // Dispatch authentication error message to Redux
        LuruReduxStore.dispatch(UserSliceActions.setAuthorizationErrorState({}))
        reject(responseJson)
        return
      }

      if (!fetchResponse) {
        fetchResponse = {
          ok: false,
          networkError: false,
          headers: new Headers(),
          status: 400,
          statusText: 'Empty response',
          text: JSON.stringify(responseJson),
        }
      }

      resolve(
        new Response(fetchResponse.text, {
          status: fetchResponse.status,
          statusText: fetchResponse.statusText,
          headers: fetchResponse.headers,
        })
      )
    } catch (e) {
      reject(e)
    }
  }

  return new Promise(fetchHandler)
}
