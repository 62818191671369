import { EntityStatus } from '../../app/types'
import { ReduxWorkflowsState } from './types'

export const EmptyReduxWorkflowsState: ReduxWorkflowsState = {
  refreshedAt: undefined,
  status: EntityStatus.NotLoaded,
  entities: {},
  schema: {
    refreshedAt: undefined,
    status: EntityStatus.NotLoaded,
    entities: {},
    normalizedEntities: [],
  },
}
