import { EntityStatus } from '../../app/types'
import { ViewAPIFilterStructure } from '../../features/views/filterTypes.d'

export const EmptyNoteTemplatesState: ReduxNoteTemplatesState = {
  refreshedAt: undefined,
  status: EntityStatus.NotLoaded,
  entities: {},
}

export const ReduxNoteTemplatesStateTTL = 60 * 60 * 1_000 // millisecs/hour

export type ReduxNoteTemplatesState = {
  refreshedAt: string | undefined
  status: EntityStatus
  entities: Record<
    string,
    {
      data: ReduxNoteTemplateEntity | null
      status: EntityStatus
    }
  >
  error?: { message: string }
}

export type ReduxNoteTemplateEntity = {
  template_id: string
  title: string
  body: string
  visibility: 'VISIBLE' | 'HIDDEN'
  access: number
  created_by: string
  created_at: string
  updated_at: string
  filter?: NoteTemplateFilter
  usage_count: number
  used_at: null | string
  shares: Array<NoteTemplateShareInfo>
}

export interface NoteTemplateShareInfo {
  entity_type: TemplateShareSource
  entity_id: string
  created_by: string
  availability: TemplateShareAccessRight
}

export enum TemplateShareSource {
  TENANT = 'TENANT',
  USER = 'USER',
  GROUP = 'GROUP',
}

export enum TemplateShareAccessRight {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export interface NoteTemplateFilter {
  sorId?: string
  sorObjectName?: string
  data?: ViewAPIFilterStructure
  sorPipelineId?: string
  sorStageId?: string
}

export enum TemplateAccessEnum {
  VIEWABLE = 1,
  EDITABLE = 2,
  HIDEABLE = 4,
  SHAREABLE = 8,
}

export enum TemplateVisibilityEnum {
  NONE = 'NONE',
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}
