import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { LuruAppErrorCode, ReduxUserState } from '../types'

export const unsetNetworkErrorState: CaseReducer<
  ReduxUserState,
  PayloadAction<{}>
> = (state, action) => {
  if (!state.applicationStatus) {
    state.applicationStatus = { errorCode: undefined }
  } else if (
    state.applicationStatus.errorCode === LuruAppErrorCode.NETWORK_ERROR
  ) {
    state.applicationStatus.errorCode = undefined
  }
}
