import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { NotesMiddleware } from '../../../features/notes/middleware'
import { getNewNoteContent } from '../../../features/notes/helpers/getNewNoteContent'
import NotesExplorerBar from '../../home/NotesExplorerBar'
import { EMBEDDED_NOTE_HOME } from '../../../entryPoints/EmbeddedNoteApp/EmbeddedNoteAppComponent'
import { trackEvent } from '../../../analytics/Ga'
import DataStoreRefreshComponent from './DataStoreRefreshComponent'
import { useAppDispatch } from '../../../app/hooks'
import { CalendarProvider } from '../../../features/user/types'
import styles from '../css/MeetingNavigationDrawer.module.css'
import expandLessIcon from '../../../images/fluent/expand_less.svg'
import expandMoreIcon from '../../../images/fluent/expand_more.svg'
import noteAddIcon from '../../../images/fluent/note_add.svg'
import { LuruEntryPoint } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import { ConnectCalendarModalRefValue } from '@/forms/ConnectCalendarModal'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'
import { NoteDeleteConfirmDialogMethods } from '@/routes/notes/components'

export interface MeetingNavigationDrawerProps {
  dataLastRefreshedAt: string | undefined
  noteDetails: {
    noteId: string | undefined
    calendarId: string | undefined
  }
  globalRefs: {
    popinButton: React.RefObject<HTMLButtonElement | null>
    connectCalendarModal: React.RefObject<ConnectCalendarModalRefValue>
    dataRefreshComponent: React.RefObject<DataStoreRefreshComponent | null>
    noteDeleteConfirmDialog: React.RefObject<NoteDeleteConfirmDialogMethods | null>
  }
}

export default function MeetingNavigationDrawer(props: MeetingNavigationDrawerProps) {
  const labels = {
    moreNotes: 'More notes',
    lessNotes: 'Hide',
    newNoteTitle: 'New note',
  }
  const { handleCreateNote, handleToggleDrawer, parentRef, toggleRef } = useMeetingDrawerEvents(props, labels)

  return (
    <div className={styles.parent} ref={parentRef}>
      <div className={styles.header}>
        <button className={styles.leftPanel} onClick={handleToggleDrawer} title={labels.moreNotes} ref={toggleRef}>
          <img src={expandLessIcon} alt='Show more notes' />
          <span>{labels.moreNotes}</span>
        </button>
        <button className={styles.rightPanel} title={labels.newNoteTitle} onClick={handleCreateNote}>
          <img src={noteAddIcon} alt='Create a new note' />
          <span>New note</span>
        </button>
      </div>
      <NotesExplorerBar noteDeleteDialogRef={props.globalRefs.noteDeleteConfirmDialog} isEmbedded={true} />
    </div>
  )
}

function useMeetingDrawerEvents(props: MeetingNavigationDrawerProps, labels: Record<string, string>) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { showToast } = useLuruToast()
  const refs = {
    parent: useRef<HTMLDivElement>(null),
    drawerTogglerButton: useRef<HTMLButtonElement>(null),
  }

  var handleExpandDrawer = useCallback(() => {
    const button = refs.drawerTogglerButton.current
    const icon = button?.firstElementChild as HTMLImageElement
    const label = icon?.nextElementSibling

    refs.parent.current?.classList.add(styles.expanded)
    button?.setAttribute('title', labels.lessNotes)

    if (icon) {
      icon.src = expandMoreIcon
      icon.alt = 'Hide notes'
    }

    if (label) {
      label.textContent = labels.lessNotes
    }
  }, [labels.lessNotes, refs.drawerTogglerButton, refs.parent])

  var handleCollapseDrawer = useCallback(() => {
    const button = refs.drawerTogglerButton.current
    const icon = button?.firstElementChild as HTMLImageElement
    const label = icon?.nextElementSibling

    refs.parent.current?.classList.remove(styles.expanded)
    button?.setAttribute('title', labels.moreNotes)

    if (icon) {
      icon.src = expandLessIcon
      icon.alt = 'Show more notes'
    }

    if (label) {
      label.textContent = labels.moreNotes
    }
  }, [refs.drawerTogglerButton, refs.parent, labels.moreNotes])

  var handleCreateNote = useCallback(async () => {
    trackEvent('create_note', 'embeddedNotes/meetingDrawer')
    var isExtensionEmbeddedNoteHome = [
      LuruEntryPoint.NEWTAB,
      LuruEntryPoint.GLOBALSEARCH,
      LuruEntryPoint.EMBEDDED_GMEET_NOTE,
      LuruEntryPoint.OPTIONS,
    ].includes(LuruUser.getCurrentEntryPoint())

    var defaultNewNote = getNewNoteContent()
    var newNoteObject = {
      draftNote: true,
      title: defaultNewNote.title,
      body: JSON.stringify(defaultNewNote.body),
      baseNoteId: props.noteDetails.noteId,
      useSorConnection: props.noteDetails.calendarId?.toUpperCase() as CalendarProvider,
    }

    try {
      var response = await dispatch(NotesMiddleware.createNote.action(newNoteObject)).unwrap()
      var newNoteId = response.note_id

      showToast({
        id: ToastId.MEETING_NOTES_TOAST_ID,
        message: 'Created new note',
        severity: 'success',
      })

      // If is in Embedded Note Home for chrome extension use EMBEDDED_NOTE_HOME url if not use regular /embedded_note/:nodeId webapp url which will work for zoom app
      var newNoteUrl = isExtensionEmbeddedNoteHome
        ? `${EMBEDDED_NOTE_HOME}?target=specificNote&noteId=${newNoteId}`
        : `/embedded_note/${newNoteId}`

      navigate(newNoteUrl)
    } catch (error) {
      console.error('MeetingNavigationDrawer:handleCreateNote:error:' + error)
    }
  }, [navigate, dispatch, props.noteDetails.calendarId, props.noteDetails.noteId, showToast])

  var handleToggleDrawer = useCallback(() => {
    if (refs.parent.current?.classList.contains(styles.expanded)) {
      handleCollapseDrawer()
    } else {
      handleExpandDrawer()
    }
  }, [refs.parent, handleCollapseDrawer, handleExpandDrawer])

  return {
    handleCreateNote,
    handleToggleDrawer,
    parentRef: refs.parent,
    toggleRef: refs.drawerTogglerButton,
  }
}
