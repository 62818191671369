import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CreateViewParameter } from '../middleware/createView'

export const createView = async (
  params: CreateViewParameter,
  options: LuruAPIRequestOptions
) => {
  var url = '/api/views'
  var request = new LuruAPIRequest(url, HTTPMethod.POST, options)

  request.setBody(params)

  return request.make()
}
