// Redux
import { createSlice } from '@reduxjs/toolkit'
import { EntityStatus } from '../../app/types'
import { UserSliceActions } from '../user/userSlice'
import { crmMiddleware } from './crmMiddleware'
import { CrmMiddleware } from './middleware'
import { EmptyCrmState } from './types'
import { invalidateCache } from './reducers/invalidateCache'
import { setEntityStatus } from './reducers/setEntityStatus'

const crmSlice = createSlice({
  name: 'crm',
  initialState: EmptyCrmState,

  // Reducer functions
  reducers: {
    invalidateCache,
    setEntityStatus,
    clearSearch: (state, action) => {
      state.search = { status: EntityStatus.Idle, error: null, results: null }
    },
  },

  // Extra reducers
  extraReducers: (builder) => {
    // New middleware
    CrmMiddleware.getObjectSchema.addAllCases(builder)
    CrmMiddleware.updateSorRecord.addAllCases(builder)

    // Activity log middlewares
    CrmMiddleware.createActivityLog.addAllCases(builder)
    CrmMiddleware.createActivityLogFromCalEvnt.addAllCases(builder)
    CrmMiddleware.searchActivityLog.addAllCases(builder)
    CrmMiddleware.updateActivityLog.addAllCases(builder)
    CrmMiddleware.deleteActivityLog.addAllCases(builder)
    CrmMiddleware.getAllObjects.addAllCases(builder)
    CrmMiddleware.listRecordsInfo.addAllCases(builder)

    // Sign-in and sign-out user flows
    builder
      .addCase(UserSliceActions.signInUser, (state, action) => {
        Object.keys(state).forEach((key) => delete state[key])
        Object.assign(state, action.payload?.crm ?? {})
      })
      .addCase(UserSliceActions.signOutUser, (state, action) => {
        Object.keys(state).forEach((key) => delete state[key])
        Object.assign(state, action.payload?.crm ?? {})
      })

    crmMiddleware
      .addCases(builder, 'sfdc/searchRecords')
      .addCases(builder, 'sfdc/getRecordFields')
      .addCases(builder, 'sfdc/updateRecordField')
      .addCases(builder, 'sfdc/getObjectSchema')
      .addCases(builder, 'sfdc/getPipelineList')
      .addCases(builder, 'sfdc/getPipelineStages')
      .addCases(builder, 'sfdc/multiUpdate')

      .addCases(builder, 'hubspot/searchRecords')
      .addCases(builder, 'hubspot/getRecordFields')
      .addCases(builder, 'hubspot/updateRecordField')
      .addCases(builder, 'hubspot/getObjectSchema')
      .addCases(builder, 'hubspot/getPipelineList')
      .addCases(builder, 'hubspot/getPipelineStages')
      .addCases(builder, 'hubspot/multiUpdate')

      .addCases(builder, 'pipedrive/searchRecords')
      .addCases(builder, 'pipedrive/getRecordFields')
      .addCases(builder, 'pipedrive/updateRecordField')
      .addCases(builder, 'pipedrive/getObjectSchema')
      .addCases(builder, 'pipedrive/getPipelineList')
      .addCases(builder, 'pipedrive/getPipelineStages')
      .addCases(builder, 'pipedrive/multiUpdate')
  },
})

// Action creators
export const { clearSearch } = crmSlice.actions

export const CrmSliceActions = crmSlice.actions

// Reducer
export default crmSlice.reducer
