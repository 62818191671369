// Own APIs
import { LuruFieldType } from '../../domain/crmRecord/typings.d'

// SFDC
import SFDCMiddlewareFactory from './sfdcMiddleware'
import HubspotMiddlewareFactory from './hubspotMiddleware'
import PipedriveMiddlewareFactory from './pipedriveMiddleware'
import moment from 'moment'

// Global object within this module to store signals
export let abortControllers = {
  'sfdc/searchRecords': {},
  'sfdc/getRecordFields': {},
  'sfdc/getObjectSchema': {},
  'sfdc/getPipelineList': {},
  'sfdc/getPipelineStages': {},
  'sfdc/updateRecordField': {},
  'sfdc/createRecord': {},
  'sfdc/multiUpdate': {},

  'hubspot/searchRecords': {},
  'hubspot/getRecordFields': {},
  'hubspot/getObjectSchema': {},
  'hubspot/getPipelineList': {},
  'hubspot/getPipelineStages': {},
  'hubspot/updateRecordField': {},
  'hubspot/createRecord': {},
  'hubspot/multiUpdate': {},

  'pipedrive/searchRecords': {},
  'pipedrive/getRecordFields': {},
  'pipedrive/getObjectSchema': {},
  'pipedrive/getPipelineList': {},
  'pipedrive/getPipelineStages': {},
  'pipedrive/updateRecordField': {},
  'pipedrive/createRecord': {},
  'pipedrive/multiUpdate': {},
}

// Container for all CRM related API calls
const sfdcMiddlewareFactory = new SFDCMiddlewareFactory()
const hubspotMiddlewareFactory = new HubspotMiddlewareFactory()
const pipedriveMiddlewareFactory = new PipedriveMiddlewareFactory()

// TODO: Instantiate only one of the three middlewares
export const crmMiddleware = {
  sfdc: sfdcMiddlewareFactory.getMiddleware(),
  hubspot: hubspotMiddlewareFactory.getMiddleware(),
  pipedrive: pipedriveMiddlewareFactory.getMiddleware(),
}

export function abortAllRequests(requestType) {
  for (let key of Object.keys(abortControllers[requestType])) {
    abortControllers[requestType][key].abort()
  }
}

// Helper function to add asyncThunk reducers for all action types
crmMiddleware.addCases = (builder, key) => {
  // Bind this function to builder object to enable chaining
  // Bind this function to builder object to enable chaining
  builder.addCases = crmMiddleware.addCases
  try {
    const parts = key.split('/'),
      crm = parts[0],
      api = parts[1]
    return builder
      .addCase(crmMiddleware[crm][api].action.pending, crmMiddleware[crm][api].pending ?? null)
      .addCase(crmMiddleware[crm][api].action.fulfilled, crmMiddleware[crm][api].fulfilled ?? null)
      .addCase(crmMiddleware[crm][api].action.rejected, crmMiddleware[crm][api].rejected ?? null)
  } catch (e) {
    console.warn(e)
    throw new Error(`crmMiddleware: Cannot add extra reducers for ${key}`)
  }
}

export function getFormattedCrmFieldValue(field, schema = undefined) {
  if (schema) {
    field.schema = schema
  }

  if (
    (!field?.schema?.luruFieldType && !field?.luruFieldType) ||
    field.value === undefined ||
    (field.value === null && field?.luruFieldType !== LuruFieldType.ENUM) ||
    field.value === ''
  ) {
    return ''
  }

  // TODO: Find out currency & locale for user
  const locale = 'en-US'
  // TODO: removing currency for now.
  // const currency = ''
  // const timeZone = 'UTC'
  const fieldType = field?.schema?.luruFieldType ?? field?.luruFieldType

  try {
    switch (fieldType) {
      // case LuruFieldType.CURRENCY:
      // return Intl.NumberFormat(locale, {
      //   style: 'currency',
      //   currency: currency,
      //   minimumFractionDigits: 2,
      // }).format(field.value)

      case LuruFieldType.CURRENCY:
      case LuruFieldType.DOUBLE:
        return Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(field.value)

      case LuruFieldType.INTEGER:
        return Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(field.value)

      case LuruFieldType.DATE:
        let dateObj = moment(field.value)
        return dateObj.isValid() ? moment(field.value).format('DD MMM YYYY') : ''

      case LuruFieldType.DATETIME:
        dateObj = moment(field.value)
        return dateObj.isValid() ? moment(field.value).format('DD MMM YYYY hh:mm') : ''

      case LuruFieldType.ENUM:
        return field?.value === null
          ? field?.schema?.picklistValues?.find((option) => option.key === null)?.label
          : field?.schema?.picklistValues?.find((option) =>
              `${option.valueForUpdate}` === `${field.value}` ? option.label : null
            )?.label ??
              field?.schema?.picklistValues?.find((option) =>
                `${option.value}` === `${field.value}` ? option.label : null
              )?.label ??
              field.value

      case LuruFieldType.MULTIENUM:
        const values = field.value.split(';')
        return (
          field?.schema?.picklistValues
            ?.filter((option) => values.includes(`${option.value}`))
            ?.map((option) => option.label)
            ?.join(', ') ?? field.value
        )

      case LuruFieldType.REFERENCE:
        return field?.value ? field.value.sor_record_name : '<Not set>'

      case LuruFieldType.REFERENCE_FIELD:
        return field?.value?.value ?? ''

      case LuruFieldType.MULTIREFERENCE:
        if (!Array.isArray(field.value)) {
          return ''
        }

        let associatedValues = field.value
        /*
        as Array<{
          sor_object_name: string,
          sor_record_id: string,
          sor_record_name: string
        }>
        */

        switch (associatedValues.length) {
          case 0:
            return ''

          case 1:
            return associatedValues[0].sor_record_name

          case 2:
            return associatedValues[0].sor_record_name + ' & ' + associatedValues[1].sor_record_name

          default:
            return (
              associatedValues[0].sor_record_name +
              ', ' +
              associatedValues[1].sor_record_name +
              ' & ' +
              (associatedValues.length - 2) +
              ' more'
            )
        }

      default:
        return field.value.toString()
    }
  } catch (e) {
    return field.value.toString()
  }
}

/**
 * Get middleware for a given SOR
 * @param {string} sorId - SOR ID
 * @returns - CRM middlware for given SOR
 */
export function getCrmMiddleware(sorId) {
  return crmMiddleware[sorId.toLowerCase()]
}
