import { PayloadAction } from '@reduxjs/toolkit'
import { ReduxNotesState } from '../types'

export const updateTitle = (
  state: ReduxNotesState,
  action: PayloadAction<{ noteId: string; title: string }>
) => {
  var { noteId, title } = action.payload
  var currEntity = state.entities[noteId]

  if (currEntity && currEntity?.data) {
    currEntity.data.title = title
  }
}
