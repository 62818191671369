import LuruAPIRequest from '../../LuruAPIRequest'
import { NoteShareRecipient } from '../types'
import { HTTPMethod } from '../../../app/types'
import { LuruAPIRequestOptions } from '../../LuruAPIRequest'

type ShareNoteParameter = {
  noteId: string
  recipients: Array<NoteShareRecipient>
}

export default async function shareNote(
  { noteId, recipients }: Readonly<ShareNoteParameter>,
  options?: LuruAPIRequestOptions
) {
  var apiRequest = new LuruAPIRequest(
    `/api/notes/${noteId}/share`,
    HTTPMethod.POST,
    options
  )

  apiRequest.setBody({ recipients })

  return apiRequest.make()
}
