import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import { ReduxNoteTemplateEntity, ReduxNoteTemplatesState, TemplateVisibilityEnum } from '../types'

export interface ListNoteTemplatesParameter {
  visibility?: TemplateVisibilityEnum
}

export interface ListNoteTemplatesAPIResponse extends LuruAPIResponse {
  data: Array<ReduxNoteTemplateEntity>
}

export const listNoteTemplates = {
  action: createAsyncThunk<
    ListNoteTemplatesAPIResponse['data'],
    ListNoteTemplatesParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: ListNoteTemplatesAPIResponse['metadata']
      rejectedMeta: ListNoteTemplatesAPIResponse['metadata']
    }
  >('noteTemplates/listNoteTemplates', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response: ListNoteTemplatesAPIResponse = await NoteTemplatesAPI.listNoteTemplates(params, { signal })

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null
      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(listNoteTemplates.action.pending, (state, action) => {
      state.status = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(listNoteTemplates.action.fulfilled, (state, action) => {
      state.entities = typeof state.entities === 'object' ? { ...state.entities } : {}

      for (let template of action.payload) {
        if (typeof template.filter === 'string') {
          try {
            template.filter = JSON.parse(template.filter)
          } catch (e) {
            console.warn('listNoteTemplates:filter is not parseable as JSON:', template.filter)
          }
        }

        state.entities[template.template_id] = {
          status: EntityStatus.Loaded,
          data: { ...template },
        }
      }

      state.status = EntityStatus.Loaded
      state.refreshedAt = new Date().toISOString()
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(listNoteTemplates.action.rejected, (state, action) => {
      state.status = EntityStatus.ErrorLoading
      state.error = {
        message: action.error.message ?? 'Error listing playbooks',
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    listNoteTemplates.addPendingCase(builder)
    listNoteTemplates.addFulfilledCase(builder)
    listNoteTemplates.addRejectedCase(builder)
  },
}
