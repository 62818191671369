import React from 'react'
import { EntityStatus } from '../../../../app/types'
import { CrmObjectSchema } from '../../../../domain/crmRecord/typings.d'
import LuruFieldSetChooserEventHandler from './LuruFieldSetChooserEventHandler'
import { LuruFieldSetChooserConnectedProps } from '.'
import LuruMultiSelectPopup from '../../../ui/LuruMultiSelectPopup'
import LuruPopupButton from '../../../ui/LuruPopupButton'
import fieldsIcon from '../../../../images/fluent/column_triple.svg'
import LuruCollectionsSelectBox from '../LuruCollectionsSelectBox'
import styles from './styles.module.css'
import { CrmRecordType } from '../../../../features/crm/types'
import readonlyIcon from '@/images/readonly-icon.svg'

export interface LuruFieldSetChooserOwnProps {
  crmRecordType: CrmRecordType
  manualFields?: Array<string>
  menuParentSelector?: string
  displayLabel?: boolean
  label?: string
  useCollections?: boolean
  hideActionButtons?: boolean
  checkedAllByDefault?: boolean
  selectedFieldNames?: Array<string>
  classes?: Array<string>
  multiSelectPopupContainerClasses?: Array<string>
  disableMandatory?: boolean
  disableNonUpdateable?: boolean
  disableNonCreateable?: boolean
  hideNonUpdateable?: boolean
  hideNonCreateable?: boolean
  showReadOnlyIcon?: boolean
  // Align left or right
  align?: 'LEFT' | 'RIGHT'
  // Force display popup below button/element
  forceBottomPosition?: boolean
  disabledFields?: Array<string>
  //// Callbacks
  // Call back on value change (for non-text box fields)
  onChooseFields?: (
    selectedFields: Array<string>,
    allFields?: Array<{ key: string; isSelected: boolean; order: number }>
  ) => void
  onChangeFields?: (fields: Array<string>) => void
  luruMultiSelectPopupProps?: Partial<React.ComponentProps<typeof LuruMultiSelectPopup>>
}

export type LuruFieldSetChooserProps = LuruFieldSetChooserOwnProps & LuruFieldSetChooserConnectedProps

export interface LuruFieldSetChooserState {
  isSchemaLoaded: boolean
  selectionMode: 'Manual' | 'Preset'
  selectedFieldNames: Array<string>
  selectedCollectionId?: string
  selectedCollectionStatus: EntityStatus
  collectionFieldNames: Array<string>
}

interface LuruFieldSetChooserRefs {
  popupButton: React.RefObject<LuruPopupButton>
  multiselectPopup: React.RefObject<LuruMultiSelectPopup>
}

export default class LuruFieldSetChooser extends React.Component<LuruFieldSetChooserProps> {
  props: LuruFieldSetChooserProps
  state: LuruFieldSetChooserState
  #schema?: CrmObjectSchema
  eventHandler: LuruFieldSetChooserEventHandler
  componentRefs: LuruFieldSetChooserRefs

  static defaultProps = {
    hideNonUpdateable: true,
  }

  constructor(props: LuruFieldSetChooserProps) {
    super(props)
    this.props = props

    this.state = {
      isSchemaLoaded: false,
      selectionMode: 'Manual',
      selectedFieldNames: [],
      selectedCollectionId: undefined,
      selectedCollectionStatus: EntityStatus.Idle,
      collectionFieldNames: [],
    }

    this.eventHandler = new LuruFieldSetChooserEventHandler(this)

    this.componentRefs = {
      popupButton: React.createRef(),
      multiselectPopup: React.createRef(),
    }
  }

  render = () => {
    var output: React.ReactNode

    switch (this.props.schemaStatus) {
      case EntityStatus.ErrorLoading:
        output = this.#renderErrorState()
        break

      case EntityStatus.Idle:
      case EntityStatus.Loaded:
        output = this.state.isSchemaLoaded ? this.#renderLoadedState() : this.#renderLoadingState()
        break

      default:
        output = this.#renderLoadingState()
    }

    return (
      <div className={styles.parent} data-luru-role='fieldset-chooser-component'>
        {output}
      </div>
    )
  }

  #renderErrorState = () => (
    <div className={styles.parent}>
      <div className={styles.error}>Error loading field</div>
    </div>
  )

  #renderLoadingState = () => <div className={styles.parent}>{this.#renderLoadingElement()}</div>

  #renderLoadedState = () => {
    var manualFieldsButton = (
      <button
        type='button'
        className={this.state.selectionMode === 'Manual' ? styles.selected : ''}
        onClick={
          this.state.selectionMode === 'Preset'
            ? () => this.eventHandler.handlers.onChangeSelectionMode('Manual')
            : () => {}
        }
      >
        Fields
      </button>
    )

    var presetFieldsButton = (
      <button
        type='button'
        className={this.state.selectionMode === 'Preset' ? styles.selected : ''}
        onClick={
          this.state.selectionMode === 'Manual'
            ? () => this.eventHandler.handlers.onChangeSelectionMode('Preset')
            : () => {}
        }
      >
        Collections
      </button>
    )

    var selectionModeComponent = this.props.useCollections ? (
      <div className={styles.selectionMode}>
        {manualFieldsButton}
        {presetFieldsButton}
      </div>
    ) : null

    var collectionsChooser =
      this.props.useCollections && this.state.selectionMode === 'Preset' ? (
        <div className={styles.collectionsSelector}>
          <LuruCollectionsSelectBox
            key={this.props.crmRecordType}
            selectedCollectionId={this.state.selectedCollectionId}
            crmRecordType={this.props.crmRecordType}
            classes={[styles.collectionSelectBox]}
            onChooseCollection={this.eventHandler.handlers.onChooseCollection}
          />
        </div>
      ) : null

    var items =
      this.#schema?.payload?.fields
        ?.map((f) => ({
          name: f.label + (f.isMandatory ? '*' : ''),
          customLabel: (
            <span>
              {f.label + (f.isMandatory ? '*' : '')}{' '}
              {this.props.showReadOnlyIcon && !f.updateable && (
                <img title='Non editable field' className={styles.readonlyIcon} src={readonlyIcon} alt='Readonly' />
              )}
            </span>
          ),
          key: f.name,
          isSelected:
            (this.props.disableNonUpdateable && !f.updateable) || (this.props.disableNonCreateable && f.nonCreateable)
              ? false
              : this.state.selectionMode === 'Manual'
              ? this.state.selectedFieldNames.includes(f.name)
              : this.state.collectionFieldNames.includes(f.name),
          isDisabled:
            (this.props.disableMandatory && f.isMandatory) ||
            (this.props.disableNonUpdateable && !f.updateable) ||
            (this.props.disableNonCreateable && f.nonCreateable) ||
            this.props.disabledFields?.includes(f.name),
          isLabelDisabled: this.props.disabledFields?.includes(f.name),
          isNameField: f.nameField,
          isUpdatable: f.updateable,
          // nonCreateable === true only if rawField.createable = false in SFDC schema data
          // For other cases like HS or where PD does not contain this attribute, nonCreateable will be false
          isCreateable: f.nonCreateable !== true,
        }))
        ?.filter((f, key) =>
          this.state.selectionMode === 'Manual' &&
          (this.props.hideNonUpdateable ? f.isUpdatable : true) &&
          (this.props.hideNonCreateable ? f.isCreateable : true)
            ? // If a list of manual fields is given, filter only those fields
              this.props.manualFields
              ? this.props.manualFields.includes(f.key)
              : true
            : // If we are in Preset mode, show only collection fields
              f.isSelected &&
              (this.props.hideNonUpdateable ? f.isUpdatable : true) &&
              (this.props.hideNonCreateable ? f.isCreateable : true)
        ) ?? []

    var manualFieldsIndex: Record<string, number> | undefined = this.props.manualFields?.reduce(
      (prev, curr, ix) => Object.assign(prev, { [curr]: ix }),
      {}
    )

    if (this.state.selectionMode === 'Manual') {
      items.sort((a, b) => (manualFieldsIndex?.[a.key] ?? 0) - (manualFieldsIndex?.[b.key] ?? 0))
    }

    var multiSelectPopup = (
      <div
        className={[styles.multiSelectPopupContainer, ...(this.props.multiSelectPopupContainerClasses || [])].join(' ')}
      >
        <LuruMultiSelectPopup
          ref={this.componentRefs.multiselectPopup}
          key={this.state.selectionMode + (this.state.selectedCollectionId ?? `manual`)}
          areItemsReorderable={true}
          disableFirstItemReorder={items[0]?.isNameField}
          hideToggleSelectAllItems={this.state.selectionMode === 'Preset'}
          items={items}
          hideFilterBox={this.state.selectionMode === 'Preset'}
          maxItemsToShow={10}
          onSelectItem={
            this.state.selectionMode === 'Manual' ? this.eventHandler.handlers.onManualSelectItem : () => {}
          }
          onDeselectItem={
            this.state.selectionMode === 'Manual' ? this.eventHandler.handlers.onManualDeselectItem : () => {}
          }
          onSelectAllItems={
            this.state.selectionMode === 'Manual' ? this.eventHandler.handlers.onManualSelectAllItems : () => {}
          }
          onDeselectAllItems={
            this.state.selectionMode === 'Manual' ? this.eventHandler.handlers.onManualDeselectAllItems : () => {}
          }
          onFinishSelection={this.eventHandler.handlers.onFinishSelection}
          onCancel={this.eventHandler.handlers.onCancelMultiSelectPopup}
          {...this.props.luruMultiSelectPopupProps}
        />
      </div>
    )

    var popupContent = (
      <div className={styles.popupContent}>
        {selectionModeComponent}
        {collectionsChooser}
        {this.state.selectionMode === 'Preset' && !this.state.selectedCollectionId
          ? null
          : this.state.selectionMode === 'Preset' && this.state.selectedCollectionStatus !== EntityStatus.Loaded
          ? this.#renderCollectionStatus()
          : multiSelectPopup}
      </div>
    )

    var popupButtonClasses = [styles.chooseFields]

    if (this.props.classes) {
      popupButtonClasses = popupButtonClasses.concat(this.props.classes)
    }

    if (this.props.hideActionButtons) {
      return popupContent
    }

    return (
      <LuruPopupButton
        id='choose-fields'
        ref={this.componentRefs.popupButton}
        items={popupContent}
        classes={popupButtonClasses}
        subdued={true}
        leftAlign={this.props.align === 'RIGHT' ? false : true}
        hideOnMenuClick={false}
        onShowMenu={/**TBD: focus filter box */ () => {}}
        onHideMenu={this.eventHandler.handlers.onHideMultiSelectPopup}
        forceBottomPosition={this.props.forceBottomPosition}
      >
        <img src={fieldsIcon} alt='Fields' />
        <span data-luru-role='luru-select-box-label'>
          {this.props.displayLabel ? this.props.label ?? 'Fields' : ''}
        </span>
      </LuruPopupButton>
    )
  }

  #renderCollectionStatus = () => (
    <div>{this.state.selectedCollectionStatus === EntityStatus.ErrorLoading ? 'Error' : 'Loading...'}</div>
  )

  #renderLoadingElement = () => (
    <div className={styles.loading}>
      <span>Loading...</span>
    </div>
  )

  setSchema = (schema: CrmObjectSchema) => {
    var { checkedAllByDefault = true, selectedFieldNames } = this.props
    var selectedAllFieldNames = schema.payload?.fields?.map((f) => f.name) ?? []

    this.#schema = schema

    this.setState({
      isSchemaLoaded: true,
      selectedFieldNames: checkedAllByDefault ? selectedAllFieldNames : selectedFieldNames ?? [],
    })
  }

  getAllFieldNames = () => this.#schema?.payload?.fields?.map((f) => f.name) ?? []

  getSchema = () => this.#schema
}
