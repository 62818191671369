import { ShowToastParams } from '@/app_ui/hooks/useAppUIStateHandlers'
import { ToastId } from '@/app_ui/types'
import { MeetingPlaybookMessage } from '@/coreEditor/EditorController'

export function checkForValidActions(
  selectedTemplateId: string,
  isTemplateInserted: boolean,
  isSameSelectedTemplateId: boolean,
  isNoteEmpty: boolean,
  isDirtiedAfterInsertion: boolean,
  showToast: (params: ShowToastParams) => void
) {
  if (isNoteEmpty) {
    // user may manually emptied the note after inserting a playbook
    if (isDirtiedAfterInsertion) {
      showToast({
        id: ToastId.PLAYBOOK_TOAST_ID,
        message:
          "Can't change or remove the playbook as you have made some changes to the note. You can delete parts of the note instead",
        severity: 'warning',
      })
      return 'message'
    }

    if (!isDirtiedAfterInsertion) {
      return MeetingPlaybookMessage.InsertPlaybook
    }
  } else {
    // User tries to insert a playbook if not is not empty
    if (!selectedTemplateId && !isTemplateInserted) {
      showToast({
        id: ToastId.PLAYBOOK_TOAST_ID,
        message: 'Playbook can’t be changed for a non-empty note. Try inserting another one using the ‘/‘ command?',
        severity: 'warning',
      })
      return 'message'
    }

    // Unselecting the playbook if note is edited after insertion
    if (isDirtiedAfterInsertion && isSameSelectedTemplateId) {
      showToast({
        id: ToastId.PLAYBOOK_TOAST_ID,
        message:
          "Can't change or remove the playbook as you have made some changes to the note. You can delete parts of the note instead",
        severity: 'warning',
      })
      return 'message'
    }

    // Unselecting the playbook if note is not edited after insertion
    if (!isDirtiedAfterInsertion && isSameSelectedTemplateId) {
      return MeetingPlaybookMessage.UnselectPlaybook
    }

    // Choose Different Template if note is edited after insertion
    if (isDirtiedAfterInsertion && !isSameSelectedTemplateId) {
      showToast({
        id: ToastId.PLAYBOOK_TOAST_ID,
        message:
          "Can't change or remove the playbook as you have made some changes to the note. You can delete parts of the note instead",
        severity: 'warning',
      })
      return 'message'
    }

    //Choose Different Template if note is not edited after insertion
    if (!isDirtiedAfterInsertion && !isSameSelectedTemplateId) {
      return MeetingPlaybookMessage.ChangePlaybook
    }
  }

  return 'message'
}
