import { RootState } from '../../../app/store'
import { getFormattedName } from './getFormattedName'

export const getNameFromLuruUserId = (state: RootState, userId: string) => {
  var user = state.users.LURU.entities[userId]

  if (user && user.data) {
    return getFormattedName(user.data)
  }

  return undefined
}
