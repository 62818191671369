import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { EmailSyncsAPI } from '../api'
import { ReduxEmailSyncEntity, ReduxEmailSyncsState } from '../types'


export interface DisconnectO365MailSyncAPIResponse extends LuruAPIResponse {
  data: ReduxEmailSyncEntity
}

export const disconnectO365MailSync = {
  action: createAsyncThunk<
    DisconnectO365MailSyncAPIResponse['data'],
    {},
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: DisconnectO365MailSyncAPIResponse['metadata']
      rejectedMeta: DisconnectO365MailSyncAPIResponse['metadata']
    }
  >(
    'emailsyncs/disconnectO365MailSync',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: DisconnectO365MailSyncAPIResponse =

          await EmailSyncsAPI.disconnectO365MailSync({ signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxEmailSyncsState>) {
    builder.addCase(disconnectO365MailSync.action.pending, (state, action) => {
      state.status = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxEmailSyncsState>) {
    builder.addCase(disconnectO365MailSync.action.fulfilled, (state, action) => {
      state.status = EntityStatus.Loaded
      state.refreshedAt = new Date().toISOString()
      const c = action.payload;
      state.entities[c.email] = {
        status: EntityStatus.Loaded,
        data: { ...c },
      };
    });
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxEmailSyncsState>) {
    builder.addCase(disconnectO365MailSync.action.rejected, (state, action) => {
      state.status = EntityStatus.ErrorLoading
    })
  },
  

  addAllCases(builder: ActionReducerMapBuilder<ReduxEmailSyncsState>) {
    disconnectO365MailSync.addPendingCase(builder)
    disconnectO365MailSync.addFulfilledCase(builder)
    disconnectO365MailSync.addRejectedCase(builder)
  },
}
