import { RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { NoteSorConnection } from '../types'

// Get suggested record names for the record links
export const getSuggestedRecordNamesForRecordLink = (state: RootState) => {
  const sorId = state?.user?.data?.userSettings?.connectedCRM?.name

  if (!sorId) {
    return []
  }

  return Object.entries(state.notes.entities || [])
    .filter(
      ([, record]) =>
        record &&
        (record.status === EntityStatus.Loaded ||
          record.status === EntityStatus.LoadedPartially ||
          record.isDraft === true) &&
        (record.data?.sync_state === 'synced' || record.data?.sync_state === 'sor')
    )
    .reduce((accResult: Array<NoteSorConnection>, curr) => {
      const conn =
        curr?.[1]?.data?.connections?.filter((f) => {
          const alreadyPresent = accResult?.find((i) => i.sor_record_id === f.sor_record_id)
          return !alreadyPresent && f.sor === sorId
        }) || []
      const newData = [...accResult, ...conn]
      return newData
    }, [])
    ?.map((connection) => {
      const recordLink = {
        sor_object_name: connection?.sor_object_name,
        sor_record_id: connection?.sor_record_id,
        sor_record_name: connection?.sor_record_name,
        data: {
          Id: connection?.sor_record_id,
          Name: connection?.sor_record_name,
          LastModifiedDate: null,
        },
        user_access: {
          can_read: true,
          can_edit: true,
          can_delete: true,
        },
      }
      return recordLink
    })
}
