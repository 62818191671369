import { unhideNoteTemplate } from './unhideNoteTemplate'
import { createNoteTemplate } from './createNoteTemplate'
import { fetchNoteTemplate } from './fetchNoteTemplate'
import { updateNoteTemplate } from './updateNoteTemplate'
import { shareNoteTemplate } from './shareNoteTemplate'
import { useNoteTemplate } from './useNoteTemplate'
import { hideNoteTemplate } from './hideNoteTemplate'
import { deleteNoteTemplate } from './deleteNoteTemplate'
import { listNoteTemplates } from './listNoteTemplates'

export const NoteTemplatesAPI = {
  createNoteTemplate,
  fetchNoteTemplate,
  updateNoteTemplate,
  shareNoteTemplate,
  useNoteTemplate,
  hideNoteTemplate,
  deleteNoteTemplate,
  listNoteTemplates,
  unhideNoteTemplate,
}
