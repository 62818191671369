import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { UserAPI } from '../api'
import { ReduxUserState } from '../types'

export interface UpdateNotificationStatsRequestParams {
  notificationId?: string
  payload: {
    dismissed_at?: string
    shown_at?: string
  }
}

export interface UpdateNotificationStatsResponse extends LuruAPIResponse {}

export const updateNotificationStats = {
  action: createAsyncThunk<
    UpdateNotificationStatsResponse['data'],
    UpdateNotificationStatsRequestParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | UpdateNotificationStatsResponse['metadata']
      rejectedMeta: null | UpdateNotificationStatsResponse['metadata']
    }
  >(
    'user/updateNotificationStats',
    async (params: UpdateNotificationStatsRequestParams, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await UserAPI.updateNotificationStats(params, { signal })) as UpdateNotificationStatsResponse
        return fulfillWithValue(response.data, null)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    }
  ),

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(updateNotificationStats.action.fulfilled, (state, action) => {
      const { notificationId, payload } = action.meta.arg
      const nIndx = state.data.notifications?.findIndex((f) => f.id === notificationId)
      if (nIndx !== -1) {
        state.data.notifications[nIndx] = {
          ...state.data.notifications[nIndx],
          last_dismissed_at: payload?.dismissed_at || null,
        }
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUserState>) {
    updateNotificationStats.addFulfilledCase(builder)
  },
}
