import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EntityStatus } from '../../../app/types'
import { getCrmKey } from '../../user/selectors/getCrmKey'
// import { CrmMiddleware } from '../middleware'
import { getSchema } from '../selectors/getSchema'
import { getSchemaStatus } from '../selectors/getSchemaStatus'
import { CrmObjectName, CrmObjectSchemaMap } from '../types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'

export function useSchema(objectName: CrmObjectName | undefined) {
  var dispatch = useAppDispatch()
  var crmKey = useAppSelector(getCrmKey)
  var status = useAppSelector(getSchemaStatus(crmKey, objectName))
  var schema = useAppSelector(getSchema(crmKey, objectName))
  var schemaMap: CrmObjectSchemaMap | undefined = useMemo(
    () =>
      schema
        ? {
            ...schema,
            fields: schema?.fields?.reduce(
              (prev: any, curr: any) => ({
                ...prev,
                [curr.name]: { ...curr },
              }),
              {}
            ),
          }
        : undefined,
    [schema]
  )

  useEffect(() => {
    if (
      crmKey &&
      objectName &&
      objectName.toLocaleLowerCase() !== 'global' && // ObjectName can be global from WF V2 and we should skip fetching schema for global objectName
      // We are using toLowerCase() because SFDC applet uses 'Global' instead of 'global'
      status !== EntityStatus.Loading &&
      status !== EntityStatus.Loaded &&
      status !== EntityStatus.ErrorLoading
    ) {
      CrmRecord.getObjectSchema(CrmRecord.getCrmRecordType(objectName))
      // dispatch(CrmMiddleware.getObjectSchema.action({ crmKey, objectName }))
    }
  }, [crmKey, objectName, status, dispatch])

  return {
    status,
    schema,
    schemaMap,
  }
}
