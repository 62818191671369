// React
import React from 'react'

// Bundle
import LuruSelectBoxEventHandler from './LuruSelectBoxEventHandler'

// Own components
import LuruPopupButton from '../LuruPopupButton'
import LuruTextBox from '../LuruTextBox'

// Styles
import styles from './styles.module.css'
import popupStyles from '../../css/PopupMenu.module.css'
import arrowDropDownIcon from '../../../images/fluent/arrow_drop_down.svg'
import { PopupMenuProps } from '@/primitives/PopupMenu'

export interface LuruSelectBoxProps {
  // Optional id
  id?: string
  // Parent element selector for menu element (for stacking correctly)
  menuParentSelector?: string
  // List of items
  items: Array<{ name: string; key: string | null; icon?: React.ReactNode }>
  // Disable showModal
  disabled?: boolean
  // An optional label to show instead of 'Select'
  selectLabel?: string
  // Which item is to be shown by default instead of 'Select'
  prechosenItem?: string
  // Show a 'select' item label before list of items
  showSelectItem?: boolean
  // Should filter box be shown?
  isFilterable?: boolean
  // Formatting function for item name
  itemNameFormatter?: (name: string) => React.ReactNode
  // Formatting function for label
  labelFormatter?: (name: string) => React.ReactNode
  // Additional classes optionally
  classes?: Array<string>

  // additional Menu Props
  popupMenuProps?: Partial<PopupMenuProps>

  selectLabelClassName?: string

  // Should popup be left-aligned with select box?
  leftAlign?: boolean
  // Should the styling be subdued?
  subdued?: boolean
  // Optional dropdown menu width
  menuWidth?: number

  //Disable scroll bar on menu items
  disableScroll?: boolean
  //// Event handlers
  // Handler for choosing item
  onChooseItem: (itemKeySelected: string | null) => void
  // Handler for reset
  onReset?: () => void
  // Varient
  blueVarient?: boolean
  // Icons
  iconPopup?: string
  // Don't want to show choosen item on choose
  dontShowChoosenItemOnChoose?: boolean
  // Whether to auto focus and open menu
  autoFocus?: boolean
}

interface LuruSelectBoxState {
  chosenItemName?: string
  highlightedIndex: number
  filteredItems: Array<{ name: string; key: string | null; icon?: React.ReactNode }>
}

interface LuruSelectBoxRefs {
  popupControl: React.RefObject<LuruPopupButton>
  filterBox?: React.RefObject<LuruTextBox>
  list: React.RefObject<HTMLUListElement>
}

export default class LuruSelectBox extends React.Component<LuruSelectBoxProps> {
  props: LuruSelectBoxProps
  state: LuruSelectBoxState
  eventHandler: LuruSelectBoxEventHandler
  componentRefs: LuruSelectBoxRefs

  constructor(props: LuruSelectBoxProps) {
    super(props)
    this.props = props
    this.state = {
      chosenItemName: props.prechosenItem ?? undefined,
      highlightedIndex: -1,
      filteredItems: this.props.items,
    }
    this.eventHandler = new LuruSelectBoxEventHandler(this)
    this.componentRefs = {
      popupControl: React.createRef(),
      filterBox: Boolean(this.props.isFilterable) ? React.createRef() : undefined,
      list: React.createRef(),
    }
  }

  render() {
    var menuItems = (
      <>
        {this.#renderFilterBox()}
        {this.#renderMenuItems()}
      </>
    )

    if (this.props.items.length <= 0) {
      menuItems = (
        <div className={styles.emptyData}>
          <span>No values</span>
        </div>
      )
    }

    var classes = [styles.main, this.props.disabled ? styles.disabled : '']

    if (Array.isArray(this.props.classes)) {
      classes = classes.concat(this.props.classes)
    }

    var selectedLabel = <>{this.props.selectLabel ?? 'Select'}</>

    if (this.state.chosenItemName) {
      selectedLabel = (
        <>
          {this.props.labelFormatter ? this.props.labelFormatter(this.state.chosenItemName) : this.state.chosenItemName}
        </>
      )

      let selectedIcon = this.props.items.find((i) => i.name === this.state.chosenItemName)?.icon

      if (selectedIcon) {
        selectedLabel = (
          <label className={styles.selectedItemDisplay}>
            {selectedIcon}
            <span>{selectedLabel}</span>
          </label>
        )
      }
    }

    return (
      <LuruPopupButton
        id={this.props.id ? this.props.id + '-popup-button' : undefined}
        ref={this.componentRefs.popupControl}
        menuParentSelector={this.props.menuParentSelector}
        subdued={this.props.subdued === true}
        blueVarient={this.props.blueVarient === true}
        items={menuItems}
        classes={classes}
        disabled={this.props.disabled}
        leftAlign={Boolean(this.props.leftAlign)}
        hideOnMenuClick={!Boolean(this.props.isFilterable)}
        onShowMenu={this.eventHandler.handlers.onShowSelectDropdown}
        title={this.state.chosenItemName}
        menuWidth={this.props.menuWidth}
        popupMenuProps={this.props.popupMenuProps}
      >
        {this.props.iconPopup && (
          <span className={styles.propsIcon}>
            <img src={this.props.iconPopup} alt='Drop down' />
          </span>
        )}
        <span
          className={[styles.label, this.props.selectLabelClassName || ''].join(' ')}
          data-luru-role='luru-select-box-label'
        >
          {selectedLabel}
        </span>
        {!this.props.iconPopup && (
          <span className={styles.actionIcon}>
            <img src={arrowDropDownIcon} alt='Drop down' />
          </span>
        )}
      </LuruPopupButton>
    )
  }

  #renderFilterBox() {
    var isFilterable = Boolean(this.props.isFilterable)

    if (!isFilterable) {
      return null
    }

    var filterBox = (
      <div className={styles.filterBoxContainer}>
        <LuruTextBox
          additionalClassNames={[styles.filterBox]}
          ref={this.componentRefs.filterBox}
          onChange={this.eventHandler.handlers.onFilterBoxChange}
          onNavigateDown={this.eventHandler.handlers.onFilterBoxNavigateDown}
          onNavigateUp={this.eventHandler.handlers.onFilterBoxNavigateUp}
          onReturn={this.eventHandler.handlers.onFilterBoxReturn}
          onCancel={this.eventHandler.handlers.onFilterBoxCancel}
        />
      </div>
    )

    return filterBox
  }

  #renderMenuItems() {
    var height = `calc((2.75em + 1px) * ${Math.min(
      this.state.filteredItems.length + (this.props.showSelectItem ? 1 : 0),
      5
    )})`

    var style = { height: this.props.disableScroll ? 'auto' : height }

    return (
      <ul style={style} className={styles.menuList} ref={this.componentRefs.list}>
        {this.props.showSelectItem ? <li onClick={this.eventHandler.handlers.onReset}>Select</li> : null}

        {this.state.filteredItems
          .filter((_, index) => !this.props.isFilterable || index < 100)
          .map((item, index) => (
            <li
              key={`${index}-${item.key ?? '--None--'}`}
              data-item-chooser-key={item.key ?? '--None--'}
              onClick={this.eventHandler.handlers.onChooseItem}
              data-highlighted={index === this.state.highlightedIndex ? 'yes' : 'no'}
              className={[
                index === 0 && this.props.showSelectItem ? popupStyles.separated : '',
                index === this.state.highlightedIndex ? styles.highlightedItem : null,
                item.icon ? styles.withIcon : null,
              ].join(' ')}
              title={item.name}
            >
              {item.icon ? item.icon : null}
              <span>{this.props.itemNameFormatter ? this.props.itemNameFormatter(item.name) : item.name}</span>
            </li>
          ))}
        {this.props.isFilterable && this.state.filteredItems.length > 100 && (
          <li className={styles.selectBoxFooter}>Showing first 100 items...</li>
        )}
      </ul>
    )
  }
}
