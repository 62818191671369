// React
import { useEffect, useRef } from 'react'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { clearSearch } from '../features/crm/crmSlice'
import { crmMiddleware } from '../features/crm/crmMiddleware'

// Own libraries
import { scrollYToElement } from '../domutils/utils'

// Custom hooks
import { useGetSetState, useThreeWayClickHandler } from '../hooks/luru_hooks'

// Own components
import RecordLinkStatusButton from './RecordLinkStatusButton'
import TypeAheadSearchBox from './TypeAheadSearchBox'
// import OvalButton from './OvalButton'
// import LuruButton from './LuruButton'

// Styles
import styles from './css/RecordLinkControl.module.css'
import crmStyles from './css/CRMRecordLinkControl.module.css'

// Assets
import { crmIcons } from './CRMIcons'
import { EntityStatus } from '../app/types'

// Icons
import syncDisableIcon from '../images/fluent/sync_disable.svg'
import { NotesSliceSelectors } from '../features/notes/selectors'
import CrmRecord from '../domain/crmRecord/CrmRecord'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export default function CRMRecordChooser(props) {
  //// Component props and derived props like values
  const callerId = 'home/CRMLinkControl'
  const { showToast } = useLuruToast()
  const MenuMode = {
    SEARCH: 'search',
    SELECT: 'select',
  }
  Object.freeze(MenuMode)

  //// Component state
  let state = {
    linkContext: useGetSetState(null),
    search: useSelector((state) => state.crm.search),
    suggestedResults: useSelector(NotesSliceSelectors.getSuggestedRecordNamesForRecordLink),
    syncInProgress: useGetSetState(false),
    crmId: useSelector((state) => state.user?.data?.userSettings?.connectedCRM?.name),
    searchText: useGetSetState(''),
    mode: useGetSetState(MenuMode.SEARCH),
    selectedIndex: useGetSetState(-1),
    defaultRecords: [],
  }

  const initialFilterState = {}
  const primaryObjects = crmMiddleware[state.crmId.toLowerCase()].getPrimaryObjectNames()
  primaryObjects.forEach((object) => (initialFilterState[object.name] = false))

  state.recordFilters = useGetSetState(initialFilterState)
  state.linkedCRMRecord = props.linkedCRMRecord
  state.displayedRecords = useGetSetState(state.search ? state.search.results : [])

  const dispatch = useDispatch()

  const refs = {
    popup: useRef(null),
    control: useRef(null),
    filters: useRef(null),
    searchBox: useRef(null),
    actionIcon: useRef(null),
    syncButton: useRef(null),
    searchResults: useRef(null),
  }

  //// Component views
  function render() {
    // RecordLinkStatusButton expects the record in this format (ie, under a
    // 'connection' key). Also add 'sor'
    // TODO: Change it since that format is unnecessary.
    let linkedRecord = null
    if (state.linkedCRMRecord !== null) {
      linkedRecord = {
        connection: {
          ...state.linkedCRMRecord,
          sor: state.crmId,
        },
      }
    }
    return (
      <div className={styles.parent} ref={refs.control}>
        <RecordLinkStatusButton key='crm' link={linkedRecord} linkType='crm' ref={refs.actionIcon} />
        <div className={styles.popup} ref={refs.popup} id={`generic-crm-link-popup`}>
          <TypeAheadSearchBox
            placeholder={state.linkContext.get()?.userPromptMessage ?? `Search for ${CrmRecord.getCrmName()} record`}
            ariaLabel={`Search for ${CrmRecord.getCrmName()} record`}
            searchText={state.searchText.get()}
            onKeyDown={eventHandlers.onSearchBoxKeyDown}
            onTriggerSearch={eventHandlers.onTriggerSearch}
            onClearSearch={eventHandlers.onClearSearch}
            ref={refs.searchBox}
            loading={state.search?.status === EntityStatus.Loading}
          />
          <ul className={crmStyles.filterList} ref={refs.filters} onClick={eventHandlers.onRecordFilterChange}>
            {primaryObjects.map((object) => (
              <li key={object.name} data-filter={object.name}>
                {object.plural}
              </li>
            ))}
          </ul>
          <div className={styles.results}>{renderRecordsListArea()}</div>
          <footer>
            <div>esc to cancel</div>
            <div>
              {'\u2191'} or {'\u2193'} to navigate
            </div>
          </footer>
        </div>
      </div>
    )
  }

  function renderRecordsListArea() {
    // Decide what to display based on search status
    switch (state.search?.status) {
      case EntityStatus.Loading:
        return (
          <div className={styles.noresults}>
            {state.search.status === EntityStatus.Loading ? 'Fetching...' : 'No results'}
          </div>
        )

      case EntityStatus.ErrorLoading:
        return <div className={styles.error}>Error: {state.search.error}</div>

      default:
        return renderRecordsList()
    }
  }

  function renderRecordsList() {
    let records = state.displayedRecords.get()
    if (records == null || records?.length === 0) {
      records = state.suggestedResults
    }

    if (!records || records?.length === 0) {
      return <div className={styles.noresults}>No records</div>
    }
    if (props.transformDisplayRecords) {
      records = props.transformDisplayRecords(records)
    }

    // `records` contains CRM records that the user has edit access to + records
    // that the user does not have edit access to. We sort the list to make
    // sure that the records that the user has edit access to, appear first
    records = records.slice().sort(function (a, b) {
      if (a?.user_access?.can_edit === true) {
        return -1
      } else if (b?.user_access?.can_edit === true) {
        return 1
      }
      return 0
    })

    return (
      <ul className={styles.loaded} ref={refs.searchResults}>
        {records?.map((item, index) => (
          <li
            key={`result-${index}`}
            onClick={handleChooseRecord}
            className={item?.sor_record_id === state.linkedCRMRecord?.sor_record_id ? styles.selected : ''}
            title={item.sor_record_name?.trim?.()}
            data-sor-result={true}
            data-sor-record-id={item.sor_record_id}
            data-sor-record-name={item.sor_record_name?.trim?.()}
            data-sor-object-name={item.sor_object_name}
            data-result-index={index}
            data-user-can-edit={item.user_access?.can_edit}
          >
            <img
              src={crmIcons[state.crmId.toLowerCase()][item.sor_object_name.toLowerCase()]}
              className={crmStyles.icon}
              data-luru-icon-name={[state.crmId, item.sor_object_name].join('-').toLowerCase()}
              alt={item.sor_object_name}
            />
            <div>{item.sor_record_name?.trim?.() || 'No Name'}</div>
            {props.isRecordChoosable && !props.isRecordChoosable(item) ? (
              <div class={crmStyles.unselectableIcon}>
                <span title="You don't have edit access to this record in the CRM">
                  <img src={syncDisableIcon} alt='sync_disabled' />
                </span>
              </div>
            ) : (
              ''
            )}
          </li>
        ))}
      </ul>
    )
  }

  //// Component commands: These are the key functions of the controller
  // These functions control the model and/or view
  // Hide menu and execute all side-effects
  function hideMenu(config) {
    if (refs.popup.current?.classList.contains(styles.visible)) {
      // Redux reset
      crmMiddleware[state.crmId.toLowerCase()].searchRecords.abortAllRequests()
      dispatch(clearSearch())

      // Own component state reset
      clearRecordFilters()
      state.searchText.set('')
      state.displayedRecords.set(state.defaultRecords)
      state.selectedIndex.set(-1)
      refs.searchBox.current.dispatchEvent(new CustomEvent('clear'))

      // Own component style reset
      refs.popup.current.classList.remove(styles.visible)

      if (state.linkContext.get() !== null) {
        const linkContext = state.linkContext.get()
        linkContext.onLinkRecord({
          rangeContainer: linkContext.rangeContainer,
          rangeOffset: linkContext.rangeOffset,
        })
        state.linkContext.set(null)
      }
    }
  }

  // Toggle menu
  /**
   * Show or hide menu
   * @param {Object} linkContext - Optional link context, if toggle menu is
   * called on an event raised from CRMFieldChooser
   */
  function toggleMenu(linkContext = null) {
    if (refs.popup.current.classList.contains(styles.visible)) {
      // Hide menu and execute all side effects
      hideMenu({ cancelled: true })
    } else {
      // If there is link context, position link record control as fixed
      // rather than absolute (which will look like a popup)
      if (linkContext) {
        refs.popup.current.classList.remove(styles.buttonPopup)
        refs.popup.current.classList.add(styles.modalPopup)
      } else {
        refs.popup.current.classList.remove(styles.modalPopup)
        refs.popup.current.classList.add(styles.buttonPopup)
      }
      // Add style to make popup visible
      refs.popup.current.classList.add(styles.visible)
      // Set default records as records list
      state.displayedRecords.set(state.defaultRecords)
      state.selectedIndex.set(-1)
      // Set focus on search box
      setTimeout(() => refs.searchBox.current.focus(), 100)
    }
  }

  // Handle choosing of an SOR from search results
  function handleChooseRecord(e) {
    let resultItem = e.target.closest('li'),
      objData = resultItem.dataset
    e.preventDefault()
    props.onChooseRecord(objData)
    toggleMenu()
  }

  // Trigger an asynchronous search operation
  function triggerSearch(query) {
    let queryText = query?.searchText ?? state.searchText.get()

    let filters = query.recordFilters ?? state.recordFilters.get()
    let filtersArray = Object.keys(filters).filter((name) => filters[name])
    if (!filtersArray.length) {
      filtersArray = Object.keys(filters)
    }

    dispatch(
      crmMiddleware[state.crmId.toLowerCase()].searchRecords.action({
        query: queryText,
        objects: filtersArray,
      })
    )
      .then((response) => {
        state.displayedRecords.set(response.payload)
        state.selectedIndex.set(-1)
        // Set focus on search box
        setTimeout(() => refs.searchBox.current.focus())
      })
      .catch((error) => {
        console.log(error)
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Error searching for records',
          severity: 'error',
        })
      })
  }

  function clearRecordFilters() {
    state.recordFilters.set(initialFilterState)
  }

  //// Component event handlers (these are children-facing event handlers)
  const eventHandlers = {
    // Handler for removing link
    onRemoveLink: (e) => {
      props.onRemoveLink(state.linkedCRMRecord)
    },
    // Handler for trigger search event from type ahead search box component
    onTriggerSearch: (e) => {
      if (e.key === 'Enter' && state.mode.get() === MenuMode.SELECT) {
        // Choose the meeting here based on current selection
        handleChooseRecord({
          target: refs.popup.current.querySelector(`li[data-result-index="${state.selectedIndex.get()}"]`),
          preventDefault: e.preventDefault,
        })
      } else {
        const queryText = e.data.searchText
        if (queryText.trim() === '') {
          state.searchText.set(queryText)
          state.mode.set(MenuMode.SEARCH)
          // TODO: Implement callerId-wise API call in CRM Middleware
          dispatch(clearSearch(callerId))
          state.displayedRecords.set(state.defaultRecords)
        } else if (queryText?.trim?.()?.length > 1) {
          state.searchText.set(queryText)
          state.mode.set(MenuMode.SEARCH)
          triggerSearch({ searchText: queryText })
        }
      }
    },

    // Handler for keydown event from type ahead search box component
    onClearSearch: (e) => {
      if (e.key && e.key === 'Escape') {
        hideMenu({ cancelled: true })
      } else {
        // TODO: Implement callerId-wise API call in CRM Middleware
        dispatch(clearSearch(callerId))
        state.displayedRecords.set(state.defaultRecords)
        state.selectedIndex.set(-1)
      }
    },

    // Handler for change filter event from week browser component
    onRecordFilterChange: (e) => {
      // let records = state.search ? state.search.results : state.defaultRecords;
      if (e.target?.dataset?.filter) {
        let chosenFilter = e.target.dataset.filter
        let currentFilters = state.recordFilters.get()
        let newFilters = {
          ...currentFilters,
          [chosenFilter]: !currentFilters[chosenFilter],
        }
        e.target.classList.toggle(crmStyles.selected)
        state.recordFilters.set(newFilters)
        if (state.searchText.get().trim() !== '') {
          triggerSearch({ recordFilters: newFilters })
        }
      }
    },

    // Handler for capturing keyboard events from search box.  Used for
    // navigating search results list items
    onSearchBoxKeyDown: (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        state.mode.set(MenuMode.SELECT)
        let currentIndex = state.selectedIndex.get()
        if (e.key === 'ArrowDown') {
          state.selectedIndex.set((currentIndex + 1) % state.displayedRecords.get().length)
        } else {
          state.selectedIndex.set(currentIndex === 0 ? state.displayedRecords.get().length - 1 : currentIndex - 1)
        }
        // Set the state for currently highlighted item
        e.preventDefault()
      }
    },

    // Handler for receiving request for list of CRM fields
    onLinkRecordRequest: (e) => {
      let shouldHandleEvent = e.detail?.onLinkRecord && e.detail.onLinkRecord instanceof Function
      if (!shouldHandleEvent) {
        return
      }

      // If link does not exist, show UI to link record
      if (!state.linkedCRMRecord) {
        // Store the fact that we are attempting to link record in response
        // to a request from another component (as opposed to user clicking on
        // this control's button)
        state.linkContext.set(e.detail)
        toggleMenu(e.detail)
        return
      }

      // If link exists already, respond with linked record details to handler
      // Local state linkContext need not change now, as we are immediately
      // returning
      e.detail.onLinkRecord({
        rangeContainer: e.detail.rangeContainer,
        rangeOffset: e.detail.rangeOffset,
        sorObjectName: state.linkedCRMRecord.sor_object_name,
        sorRecordId: state.linkedCRMRecord.sor_record_id,
      })
    },

    // Handler for event on making note editable
    onMakeEditable: (e) => {
      refs.syncButton.current?.classList.remove('hidden')
    },
  }

  //// Component setup - useEffect, etc. are set up here
  function useSetup() {
    // Set up click handlers for toggle/hide menu
    useThreeWayClickHandler(
      { outsideRef: refs.control, insideRef: refs.popup },
      {
        outsideClick: () => hideMenu({ cancelled: true }),
        betweenClick: (e) => {
          let actionIcon = refs.actionIcon.current
          let isAction =
            e.clientX >= actionIcon.getBoundingClientRect().left &&
            e.clientX <= actionIcon.getBoundingClientRect().right &&
            e.clientY >= actionIcon.getBoundingClientRect().top &&
            e.clientY <= actionIcon.getBoundingClientRect().bottom
          if (isAction && state.linkedCRMRecord) {
            eventHandlers.onRemoveLink(e)
          } else {
            if (!props.readonly) toggleMenu()
          }
        },
      }
    )

    // Scroll to the result item
    useEffect(() => {
      let selection = refs.popup.current.querySelector(`.${styles.selected?.replace('+', '\\+')}`)
      if (selection) {
        let container = refs.popup.current.querySelector(`.${styles.results?.replace('+', '\\+')}`)
        scrollYToElement(selection, container)
      }

      // Add signaling to popup element to enable dispatching custom event
      // to link record
      let popupElement = refs.popup.current
      popupElement?.addEventListener('linkrecordrequest', eventHandlers.onLinkRecordRequest)

      // Add signalling to the floating sync button to show itself when note
      // becomes editable
      let syncButton = refs.syncButton.current
      syncButton?.addEventListener('makeeditable', eventHandlers.onMakeEditable)

      return () => {
        // Remove signaling to popup element to enable dispatching custom event
        // to link record
        popupElement?.removeEventListener('linkrecordrequest', eventHandlers.onLinkRecordRequest)

        // Add signalling to the floating sync button to show itself when note
        // becomes editable
        syncButton?.removeEventListener('makeeditable', eventHandlers.onMakeEditable)
      }
    })
  }

  useSetup()

  return render()
}
