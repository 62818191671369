import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UseNoteTemplateParameter } from '../middleware/useNoteTemplate'

export const useNoteTemplate = async (
  params: Readonly<UseNoteTemplateParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/note-templates/${params.noteTemplateId}/use`,
    HTTPMethod.POST,
    options
  )

  request.setBody(params)

  return request.make()
}
