import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod, LuruAPIResponse } from '../../../app/types'
import { DateUtils } from '@/utils/dateUtils'

export interface SearchWorkflowEventsParameter {
  workflow_id?: string | null
  q?: string
  name?: string | null
  status?: string | null
  limit?: number
  time_min: Date
  time_max: Date
  cursor: string | null
}

export interface WorkflowEvent {
  data: {}
  entity_ids: {
    type: 'workflow' | 'user'
    id: string
  }[]
  error: {}
  event_id: string
  name: string
  records: { sor_record_id: string; sor_object_name: string }[]
  request_id: string
  run_id: string
  status: 'success'
  tenant_id: string
  timestamp: number
}

export interface SearchWorkflowEventsResponse extends LuruAPIResponse {
  data: WorkflowEvent[]
}

export const searchWorkflowEvents = async (params: SearchWorkflowEventsParameter, options?: LuruAPIRequestOptions) => {
  var urlParams: Record<string, any> = { ...params }

  if (!urlParams.limit) {
    urlParams.limit = 50
  }

  if (urlParams.time_min) {
    urlParams.time_min = DateUtils.toISOStringCurrentTZ(params.time_min)
  }

  if (urlParams.time_max) {
    urlParams.time_max = DateUtils.toISOStringCurrentTZ(params.time_max)
  }

  if (urlParams.status === null) {
    delete urlParams.status
  }

  if (urlParams.name === null) {
    delete urlParams.name
  }

  if (urlParams.cursor === null) {
    delete urlParams.cursor
  }

  var queryString = new URLSearchParams(urlParams as URLSearchParams)
  var request = new LuruAPIRequest(`/api/events?${queryString}`, HTTPMethod.GET, options)

  return request.make()
}
