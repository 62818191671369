import { hasEmptyFilter } from './hasEmptyFilter'
import { getSpecificityForCrmObject } from './getSpecificityForCrmObject'
import { getDefaultNoteTemplate } from './getDefaultNoteTemplate'
import { isNoteTemplateHideable } from './isNoteTemplateHideable'
import { isNoteTemplateShareable } from './isNoteTemplateShareable'
import { filterGeneralAccessInfo } from './filterGeneralAccessInfo'
import { isNoteTemplatesCacheValid } from './isNoteTemplatesCacheValid'

export const NoteTemplatesSliceHelpers = {
  hasEmptyFilter,
  getSpecificityForCrmObject,
  getDefaultNoteTemplate,
  isNoteTemplateHideable,
  isNoteTemplateShareable,
  filterGeneralAccessInfo,
  isNoteTemplatesCacheValid,
}
