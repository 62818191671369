// React
import React from 'react'

// Own components
import LuruButton from './ui/LuruButton'

// Styles
import styles from './css/EditLinkDialog.module.css'

export let EditLinkDialogMode = {
  CREATE_LINK: 'createLink',
  UPDATE_LINK: 'updateLink',
}

Object.freeze(EditLinkDialogMode)

export default class EditLinkDialog extends React.Component {
  #callback = null

  constructor(props) {
    super(props)

    // Props
    this.props = props

    // State
    this.state = {
      visible: false,
      url: props.url ?? '',
      mode: EditLinkDialogMode.CREATE_LINK,
      tip: props.tip ?? '',
      isValidURL: false,
    }

    // Bindings
    this.showDialog = this.showDialog.bind(this)
    this.hideDialog = this.hideDialog.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    // Refs
    this.elements = {
      inputBox: React.createRef(),
    }
  }

  showDialog({ url, mode, callback, tip }) {
    this.#callback = callback
    this.setState({ visible: true, mode, url, tip })
  }

  hideDialog() {
    this.setState({ visible: false, tip: '' })
  }

  onChange(e) {
    if (this.isValidURL(e.target.value)) {
      this.setState({ url: e.target.value, isValidURL: true })
    } else {
      this.setState({ url: e.target.value, isValidURL: false })
    }
  }

  onSubmit() {
    if (!this.state.isValidURL || this.state.url.length === 0) {
      return
    }

    this.hideDialog()
    if (typeof this.#callback === 'function') {
      this.#callback(this.state.url)
    }
  }

  componentDidUpdate() {
    if (this.state.visible) {
      this.elements?.inputBox?.current?.focus()
    }
  }

  isValidURL(url) {
    var isValid = new RegExp(
      '(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
    ).test(url)

    if (isValid) {
      return true
    }

    try {
      return Boolean(new URL(url))
    } catch (e) {
      return false
    }
  }

  render() {
    return (
      <div
        className={[styles.parent, this.state.visible ? null : styles.minimized].join(' ')}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className={styles.dialog}>
          <div className={styles.title}>
            {this.state.mode === EditLinkDialogMode.CREATE_LINK ? 'Add Link' : 'Update Link'}
          </div>
          <div className={styles.content}>
            <input
              type='text'
              onChange={this.onChange}
              value={this.state.url}
              ref={this.elements.inputBox}
              onKeyUp={(e) => (e.key === 'Enter' ? this.onSubmit() : null)}
            />
            {this.state.tip ? <div className={styles.tip}>Tip: {this.state.tip}</div> : null}
          </div>
          <div className={!this.state.isValidURL ? styles.notValidUrlText : null}>
            {!this.state.isValidURL && this.state.url.length > 0 ? 'Url is not valid please try another' : ''}
          </div>
          <div className={styles.buttons}>
            <LuruButton variant='outlined' onClick={this.hideDialog}>
              Cancel
            </LuruButton>
            <LuruButton onClick={this.onSubmit}>OK</LuruButton>
          </div>
        </div>
      </div>
    )
  }
}
