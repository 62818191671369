import { Middleware } from '@reduxjs/toolkit'
import { luruRootReducer } from './luruRootReducer'
import { WebappReduxGateway } from './webappReduxGateway'
import { runLuruReduxTest } from '../../test/reduxTest'

export type RootState = ReturnType<typeof luruRootReducer>

// Middleware to dispatch actions as messages to background script in the
// context of extension
export const webappMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (action && !action.skipBroadcast) {
    // console.log(
    //   'Webapp[',
    //   // @ts-ignore
    //   window.__luru_tab_id.slice(0, 7),
    //   ']:broadcastAction'
    // )
    WebappReduxGateway.transmit({ action })
  }

  // @ts-ignore
  window.__luruReduxActionHistory.push(action)

  return next(action)
}

// @ts-ignore: Store all actions for testing by replaying them on an
// invalidated state
window.__luruReduxActionHistory = []

// @ts-ignore
window.__runLuruReduxTest = runLuruReduxTest
