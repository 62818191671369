import React from 'react'
import { CrmMultiObjectFieldChooserConnectedProps } from '.'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { CrmFieldSchema, CrmRecordSchema } from '../../../../domain/crmRecord/typings.d'
import LuruUser from '../../../../domain/users/LuruUser'
import { CRMProvider } from '../../../../features/user/types'
import ModalScreen from '../../../ModalScreen'
import LuruButton from '../../../ui/LuruButton'
import LuruTextBox from '../../../ui/LuruTextBox'
import AvailableFieldsList from './components/AvailableFieldsList'
import SelectedFieldsChipsList from './components/SelectedFieldsChipsList'
import SelectedFieldsList from './components/SelectedFieldsList'
import CrmMultiObjectFieldChooserEventHandler from './CrmMultiObjectFieldChooserEventHandler'
import styles from './styles.module.css'
import chevronRightIcon from '../../../../images/fluent/chevron_circle_right.svg'
import { CrmRecordType, LuruFieldType } from '../../../../features/crm/types'

export interface CrmMultiObjectFieldChooserComponentProps extends CrmMultiObjectFieldChooserConnectedProps {
  classes?: Array<string>
  chipsInputContainerClasses?: Array<string>
  dialogContainerClasses?: Array<string>
  disabled?: boolean
  selectedItems?: Array<string>
  crmRecordType: CrmRecordType
  onSelectionChange?: (updatedFields: Array<string>) => void
  onFinishedSelection?: (selectedFields: Array<string>) => void
  onCancel?: (initialSelectedFields: Array<string>) => void
  inputPlaceholder?: string
  dialogTitle?: string
  /** Show only Base Object */
  showOnlyBaseObjectFields?: boolean
  /** Hide all reference fields including multi_reference fields */
  hideReferenceField?: boolean
  /** Only hide multi_reference fields but show a single reference fields */
  hideMultiReferenceField?: boolean
  // When we are choosing fields for create-record, we don't want to show non-createable fields
  lockedNonCreatableFields?: boolean
  lockedNonUpdatableFields?: boolean

  // Mandatory settings and  props
  enableMandatorySelection?: boolean
  mandatoryFields?: Array<string>
  onMandatorySelectionChange?: (updatedFields: Array<string>) => void
}

export interface CrmMultiObjectFieldChooserComponentState {
  schema: {
    [key: string]: {
      data: CrmRecordSchema
      status: EntityStatus
    }
  }
  selectedCrmFieldSchema: Partial<CrmFieldSchema & { crmObjectName: string }>
  selectedItems: Array<string>
}

export interface CrmMultiObjectFieldChooserRefs {
  availableFiledFilterBox?: React.RefObject<LuruTextBox>
  filterBox?: React.RefObject<LuruTextBox>
  type2SelectModal: React.RefObject<ModalScreen>
  availableFiledsList: React.RefObject<HTMLUListElement>
  selectedFiledsList: React.RefObject<HTMLUListElement>
}

export default class CrmMultiObjectFieldChooserComponent extends React.Component<
  CrmMultiObjectFieldChooserComponentProps,
  CrmMultiObjectFieldChooserComponentState
> {
  props: CrmMultiObjectFieldChooserComponentProps
  state: CrmMultiObjectFieldChooserComponentState
  componentRefs: CrmMultiObjectFieldChooserRefs
  eventHandler: CrmMultiObjectFieldChooserEventHandler
  initialSelectedItems: Array<string>
  initialMandatorySelectedItems: Array<string>
  #crmProvider: CRMProvider | undefined

  constructor(props: CrmMultiObjectFieldChooserComponentProps) {
    super(props)
    this.props = props

    this.state = {
      schema: {},
      selectedCrmFieldSchema: {
        crmObjectName: CrmRecord.getCrmRecordName(this.props.crmRecordType),
      },
      selectedItems: props.selectedItems || [],
    }

    this.initialSelectedItems = props.selectedItems || []
    this.initialMandatorySelectedItems = props.mandatoryFields || []
    this.eventHandler = new CrmMultiObjectFieldChooserEventHandler(this)
    this.#crmProvider = LuruUser.getCurrentUserCrmName()

    this.componentRefs = {
      filterBox: React.createRef(),
      availableFiledFilterBox: React.createRef(),
      type2SelectModal: React.createRef(),
      availableFiledsList: React.createRef(),
      selectedFiledsList: React.createRef(),
    }
  }

  render = () => {
    const { classes = [], dialogTitle } = this.props

    return (
      <div className={[styles.mainContainer, ...classes].join(' ')}>
        {this.#renderLabelElement()}

        <ModalScreen width='100%' title={dialogTitle || 'Select Fields'} ref={this.componentRefs.type2SelectModal}>
          {this.#renderType2SelectControl()}
        </ModalScreen>
      </div>
    )
  }

  #renderLabelElement = () => {
    const { schema, selectedItems = [] } = this.state

    const {
      crmRecordType = CrmRecord.getAllPrimaryObjects().DEAL,
      chipsInputContainerClasses = [],
      disabled,
    } = this.props

    return (
      <div className={[styles.parent, disabled ? styles.disabledParent : '', ...chipsInputContainerClasses].join(' ')}>
        <div className={styles.chipsContainer}>
          <SelectedFieldsChipsList
            selectedItems={selectedItems}
            crmRecordType={crmRecordType}
            disabled={disabled}
            eventHandler={this.eventHandler}
            globalComponentRefs={this.componentRefs}
            schema={schema}
          />
          <div className={styles.inputContainer}>
            <LuruTextBox
              additionalClassNames={[styles.textInput, disabled ? styles.disabledTextInput : '']}
              ref={this.componentRefs.filterBox}
              placeholder={
                this.props.inputPlaceholder ||
                ('Select ' + this.#crmProvider === CRMProvider.HUBSPOT ? 'Properties' : 'Fields')
              }
              onFocus={this.eventHandler.handlers.onFocusInput}
            />
          </div>
        </div>
      </div>
    )
  }

  #renderType2SelectControl = () => (
    <div className={styles.parentDialog}>
      <div className={styles.container}>
        <section data-section='object'>
          <header>
            <span>{this.props.showOnlyBaseObjectFields ? 'Object' : 'Choose object'}</span>
          </header>
          <div>{this.#renderRecordType()}</div>
        </section>
        <section data-section='available_fields'>
          <header>
            <span>Available {this.#crmProvider === CRMProvider.HUBSPOT ? 'properties' : 'fields'}</span>
          </header>
          <div>{this.#renderAvailableFields()}</div>
        </section>
        <section data-section='selected_fields'>
          <header className={styles.selectedColumnHeader}>
            <span>Selected {this.#crmProvider === CRMProvider.HUBSPOT ? 'properties' : 'fields'}</span>
            {this.props.enableMandatorySelection && (
              <span className={styles.hint}>
                (Toggle mandatory {this.#crmProvider === CRMProvider.HUBSPOT ? 'properties' : 'fields'} by clicking *)
              </span>
            )}
          </header>
          <div>{this.#renderSelectedFields()}</div>
        </section>
      </div>
    </div>
  )

  #renderRecordType = () => {
    const { crmRecordType } = this.props
    const sorObjectName = CrmRecord.getCrmRecordName(crmRecordType)
    const { schema, selectedCrmFieldSchema } = this.state

    // We don't show choosing of child fields of poly-ref fields at all i.e filed.referenceTo having morethan 1 referenceObject
    const recordsTypes =
      schema?.[crmRecordType]?.data?.filter?.((f) => f.referencedObject && f.referenceTo?.length === 1) || []

    const entityStatus = schema?.[crmRecordType]?.status

    if (entityStatus === EntityStatus.Loading) {
      return <div className={styles.loadingContainer}>Loading...</div>
    } else if (entityStatus === EntityStatus.ErrorLoading) {
      return (
        <div className={styles.errorContainer}>
          <div>Error occured</div>
          <LuruButton
            title='Try again'
            onClick={() => {
              this.eventHandler.handlers?.loadSchema?.(crmRecordType)
            }}
          >
            Try Again
          </LuruButton>
        </div>
      )
    } else if (entityStatus === EntityStatus.Loaded) {
      return (
        <div className={styles.recordTypeContainer}>
          <div
            data-field-selected={selectedCrmFieldSchema.crmObjectName === sorObjectName}
            className={[styles.recordItem, styles.baseRecordItem].join(' ')}
            onClick={() =>
              this.eventHandler.handlers.onChangeRecordType({
                crmObjectName: sorObjectName,
              })
            }
          >
            <label>{sorObjectName}</label>
            {selectedCrmFieldSchema.crmObjectName === sorObjectName && <img src={chevronRightIcon} alt='next' />}
          </div>
          {!this.props.showOnlyBaseObjectFields &&
            recordsTypes.map((f) => {
              return (
                <div
                  data-field-selected={selectedCrmFieldSchema.name === f.name}
                  className={styles.recordItem}
                  key={f.name}
                  onClick={() => this.eventHandler.handlers.onChangeRecordType(f)}
                >
                  <label>{f.label}</label>
                  {selectedCrmFieldSchema.name === f.name && <img src={chevronRightIcon} alt='next' />}
                </div>
              )
            })}
        </div>
      )
    }
  }

  #renderAvailableFields = () => {
    const { schema, selectedCrmFieldSchema, selectedItems } = this.state
    const {
      crmRecordType,
      disabled,
      hideReferenceField,
      hideMultiReferenceField,
      lockedNonUpdatableFields,
      lockedNonCreatableFields,
    } = this.props
    var selectedCrmRecordType =
      CrmRecord.getCrmRecordType(
        selectedCrmFieldSchema.crmObjectName
          ? selectedCrmFieldSchema.crmObjectName
          : selectedCrmFieldSchema.referencedObject || ''
      ) ||
      selectedCrmFieldSchema.crmObjectName ||
      'deal'

    if (!schema[selectedCrmRecordType]) {
      selectedCrmRecordType = crmRecordType
    }

    var availableFields = schema?.[selectedCrmRecordType]?.data ?? []

    if (hideReferenceField) {
      availableFields = availableFields?.filter((f) => !f.referencedObject)
    }

    if (hideMultiReferenceField) {
      availableFields = availableFields?.filter((f) => f.luruFieldType !== LuruFieldType.MULTIREFERENCE)
    }

    const entityStatus = schema?.[selectedCrmRecordType]?.status

    return (
      <AvailableFieldsList
        crmRecordType={crmRecordType}
        selectedItems={selectedItems}
        selectedCrmFieldSchema={selectedCrmFieldSchema}
        selectedCrmRecordType={selectedCrmRecordType}
        availableFields={availableFields}
        eventHandler={this.eventHandler}
        globalComponentRefs={this.componentRefs}
        disabled={disabled}
        entityStatus={entityStatus}
        lockedNonUpdatableFields={lockedNonUpdatableFields}
        lockedNonCreatableFields={lockedNonCreatableFields}
      />
    )
  }

  #renderSelectedFields = () => {
    const { schema, selectedItems } = this.state
    const { crmRecordType, disabled, enableMandatorySelection, mandatoryFields } = this.props

    return (
      <SelectedFieldsList
        selectedItems={selectedItems}
        crmRecordType={crmRecordType}
        eventHandler={this.eventHandler}
        globalComponentRefs={this.componentRefs}
        disabled={disabled}
        schema={schema}
        enableToggleMandatory={enableMandatorySelection}
        mandatoryFields={mandatoryFields}
      />
    )
  }
}
