import { PayloadAction } from '@reduxjs/toolkit'
import { ReduxNotesState } from '../types'

export const updateBody = (
  state: ReduxNotesState,
  action: PayloadAction<{
    noteId: string
    body: string
    isDraft: boolean
  }>
) => {
  // updateBody is called before saveNote API call, so save previous body
  // in a special key - prevBody; if saveNote API fails for some reason,
  // error handler can restore the body from prevBody value, if required.
  // We update note body before receiving an OK from API call because,
  // during the save, notes editor should read the updated body from store
  var { noteId, body, isDraft } = action.payload
  var currEntity = state.entities[noteId]

  if (!currEntity || !currEntity?.data) {
    return
  }

  // Backup previous body
  currEntity.data.prevBody = currEntity.data.body

  if (!isDraft) {
    currEntity.data.body = body
    delete currEntity.data.draft
  } else {
    currEntity.data.draft = body
  }
}
