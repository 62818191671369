import { PayloadAction } from '@reduxjs/toolkit'
import Utils from '../../utils/Utils'
import { LuruEntryPoint } from '../types'
import { LuruReduxStore } from './LuruReduxStore'
import { processUIEffects } from './processUIEffects'

interface BridgeMessage {
  messageName: string
  payload: PayloadAction<{}>
  origin: {
    type: LuruEntryPoint
    id: string
  }
}

interface Gateway {
  initWebappTabId: () => void
  getWebappTabId: () => string
  listen: () => void
  handleExtensionBridgeMessage: (_: BridgeMessage) => void
  handleWebappBridgeMessage: (e: MessageEvent<BridgeMessage>) => void
  isValidActionType: (_: string) => boolean
  processAction: (_: PayloadAction<{}>) => void
  transmit: (_: { action: PayloadAction<{}> }) => void
  postToExtensionBridge: (message: BridgeMessage) => void
  webappBridge: BroadcastChannel | null
}

export const WebappReduxGateway: Gateway = {
  listen: () => {
    if (!WebappReduxGateway.webappBridge) {
      return
    }

    WebappReduxGateway.webappBridge.removeEventListener('message', WebappReduxGateway.handleWebappBridgeMessage)

    WebappReduxGateway.webappBridge.addEventListener('message', WebappReduxGateway.handleWebappBridgeMessage)

    window.addEventListener('reduxTransmissionFromExtnToWebapp', (e) =>
      WebappReduxGateway.handleExtensionBridgeMessage((e as CustomEvent).detail)
    )
  },

  handleWebappBridgeMessage: (e: MessageEvent<BridgeMessage>) => {
    // console.log(
    //   'Webapp[',
    //   WebappReduxGateway.getWebappTabId().slice(0, 7),
    //   ']:receiveFromWebapp:',
    //   e.data.origin.type + '/' + e.data.origin.id.slice(0, 7)
    // )

    if (
      e &&
      e.data.messageName === 'reduxTransmissionFromWebappToWebapp' &&
      e.data.origin.type === LuruEntryPoint.WEBAPP &&
      e.data.origin.id !== WebappReduxGateway.getWebappTabId()
    ) {
      WebappReduxGateway.processAction(e.data.payload)
    }
  },

  handleExtensionBridgeMessage: (data: BridgeMessage) => {
    // console.log(
    //   'Webapp[',
    //   WebappReduxGateway.getWebappTabId().slice(0, 7),
    //   ']:receiveFromExtension:',
    //   data.origin.type + '/' + data.origin.id + ':' + data.payload.type
    // )

    // // @ts-ignore: TODO: remove after testing
    // if (!window.receivedActions) {
    //   // @ts-ignore: TODO: remove after testing
    //   window.receivedActions = []
    // }

    // // @ts-ignore: TODO: remove after testing
    // window.receivedActions.push(data.payload)

    if (data.origin.type !== LuruEntryPoint.WEBAPP) {
      WebappReduxGateway.processAction(data.payload)
    }
  },

  initWebappTabId: () => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.__luru_tab_id = Utils.generateUUID()
    }
  },

  isValidActionType: (s: string) => {
    const getType = (s: string) => s.split('/')?.[0]
    const stage = s.split('/')?.[1]
    if (getType(s) === 'crm' && ['createActivityLog', 'createActivityLogFromCalEvnt'].includes(stage)) {
      return true
    }
    if (getType(s) === 'notes' && stage === 'createNote') {
      return false
    }

    const allowedTypes = [
      'chat',
      'collections',
      'emails',
      'emailsyncs',
      'meetings',
      'notes',
      'noteTemplates',
      'tasks',
      'user',
      'users',
      'views',
      'workflows',
    ]

    return allowedTypes.includes(getType(s)) && s.indexOf('invalidateCache') === -1
  },

  processAction: (action: PayloadAction<{}>) => {
    if (!WebappReduxGateway.isValidActionType(action.type)) {
      // console.log(
      //   'WebappReduxGateway:processAction:invalid Action:',
      //   action.type
      // )
      return
    }

    // console.log('WebappReduxGateway:processAction:', action.type)
    LuruReduxStore.dispatch({ ...action, skipBroadcast: true })
    processUIEffects(action)
  },

  transmit: ({ action }: { action: PayloadAction<{}> }) => {
    // TODO: Filter messages and don't transmit invalid messages anyway

    // console.log(
    //   'Webapp[',
    //   // @ts-ignore
    //   window.__luru_tab_id.slice(0, 7),
    //   ']:transmitAction:',
    //   action.type
    // )

    if (!WebappReduxGateway.webappBridge) {
      return
    }

    WebappReduxGateway.webappBridge.postMessage({
      messageName: 'reduxTransmissionFromWebappToWebapp',
      payload: action,
      origin: {
        type: LuruEntryPoint.WEBAPP,
        id: WebappReduxGateway.getWebappTabId(),
      },
    })

    WebappReduxGateway.postToExtensionBridge({
      messageName: 'reduxTransmissionFromWebappToExtension',
      payload: action,
      origin: {
        type: LuruEntryPoint.WEBAPP,
        id: WebappReduxGateway.getWebappTabId(),
      },
    })
  },

  postToExtensionBridge: (message: BridgeMessage) => {
    var reduxEvent = new CustomEvent(message.messageName, { detail: message })
    window.dispatchEvent(reduxEvent)
  },

  // @ts-ignore
  getWebappTabId: () => window.__luru_tab_id,

  webappBridge: typeof BroadcastChannel !== 'undefined' ? new BroadcastChannel('webappWebappBridge') : null,
}

WebappReduxGateway.initWebappTabId()
