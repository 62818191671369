import { PayloadAction } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { ReduxAppState } from '../types'

export const clearHomeSearchQuery = (
  state: ReduxAppState,
  action: PayloadAction<undefined>
) => {
  state.home.search.query = null

  state.home.search['sorNotes'] = {
    ...state.home.search['sorNotes'],
    status: EntityStatus.Idle,
    results: [],
  }

  state.home.search['luruNotes'] = {
    ...state.home.search['luruNotes'],
    status: EntityStatus.Idle,
    results: [],
  }
}
