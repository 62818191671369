import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { CrmObjectName } from '../../crm/types'
import LuruError from '../../LuruError'
import { CRMProvider } from '../../user/types'
import { ViewsAPI } from '../api'
import { LuruFilterInput, ReduxLuruViewEntity, ReduxViewsState } from '../types'

export interface CreateViewParameter {
  name: string
  input: LuruFilterInput
  output: {
    sor: CRMProvider
    sor_object_name: CrmObjectName
  }
}

export interface CreateViewAPIResponse extends LuruAPIResponse {
  data: ReduxLuruViewEntity
}

export const createView = {
  action: createAsyncThunk<
    CreateViewAPIResponse['data'],
    CreateViewParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: CreateViewAPIResponse['metadata']
      rejectedMeta: CreateViewAPIResponse['metadata'] | null
    }
  >('views/createView', async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
    try {
      var view = (await ViewsAPI.createView(params, {
        signal,
      })) as CreateViewAPIResponse

      return fulfillWithValue(view.data, view.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(createView.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(createView.action.fulfilled, (state, action) => {
      var newView = action.payload
      state.entities[newView.view_id] = {
        status: EntityStatus.Loaded,
        data: newView,
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(createView.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    createView.addPendingCase(builder)
    createView.addFulfilledCase(builder)
    createView.addRejectedCase(builder)
  },
}
