import { RootState } from '../../../app/store'
import { ReduxChatStateTTL } from '../types'

export const isChatUsersCacheValid = (state: RootState) =>
  !Boolean(state.chat.refreshedAt.users) ||
  new Date().valueOf() -
    (state.chat.refreshedAt.users
      ? new Date(state.chat.refreshedAt.users).valueOf()
      : 0) <
    ReduxChatStateTTL
