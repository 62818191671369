import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CrmAPI } from '../api'
import { CrmActivity, CrmActivityLogType, ReduxCrmKey, ReduxCrmState } from '../types'

export interface DeleteActivityLogParameter {
  crmKey: ReduxCrmKey
  activityId: string
  type: CrmActivityLogType
}

export interface DeleteActivityLogAPIResponse extends LuruAPIResponse {
  data: CrmActivity
}

export const deleteActivityLog = {
  action: createAsyncThunk<
    DeleteActivityLogAPIResponse['data'],
    DeleteActivityLogParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: DeleteActivityLogAPIResponse['metadata']
      rejectedMeta: DeleteActivityLogAPIResponse['metadata']
    }
  >('crm/deleteActivityLog', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var createdActivity = (await CrmAPI.deleteActivityLog(params, {
        signal,
      })) as DeleteActivityLogAPIResponse

      return fulfillWithValue(createdActivity.data, createdActivity.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(deleteActivityLog.action.pending, () => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(deleteActivityLog.action.fulfilled, (state, action) => {
      var { crmKey, activityId } = action.meta.arg
      if (state[crmKey]?.activities?.entities?.[activityId]) {
        delete state[crmKey]?.activities?.entities?.[activityId]
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(deleteActivityLog.action.rejected, () => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    deleteActivityLog.addPendingCase(builder)
    deleteActivityLog.addFulfilledCase(builder)
    deleteActivityLog.addRejectedCase(builder)
  },
}
