import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse, LuruEntryPoint } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import Utils from '../../../utils/Utils'
import LuruError from '../../LuruError'
import { UserAPI } from '../api'
import { ReduxUserState } from '../types'

export interface DisconnectGCalRequestParams {
  csrfToken?: string
}

export interface DisconnectGCalResponse extends LuruAPIResponse {}

export const disconnectGCal = {
  action: createAsyncThunk<
    DisconnectGCalResponse['data'],
    DisconnectGCalRequestParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | DisconnectGCalResponse['metadata']
      rejectedMeta: null | DisconnectGCalResponse['metadata']
    }
  >(
    'user/disconnectGCal',
    async (
      params: DisconnectGCalRequestParams,
      { getState, signal, fulfillWithValue, rejectWithValue }
    ) => {
      var csrfToken = params.csrfToken

      if (!csrfToken) {
        csrfToken = Utils.getCookieValue('csrftoken') || ''
        var entryPoint = LuruUser.getCurrentEntryPoint()

        if (
          [
            LuruEntryPoint.NEWTAB,
            LuruEntryPoint.GLOBALSEARCH,
            LuruEntryPoint.EMBEDDED_GMEET_NOTE,
            LuruEntryPoint.OPTIONS,
          ].includes(entryPoint)
        ) {
          // Inside Extension, so get csrf token from store
          csrfToken =
            getState()?.user?.extensionSettings?.csrfTokenCookieValue || ''
        }
      }

      try {
        var response = (await UserAPI.disconnectGCal(
          { csrfToken },
          { signal }
        )) as DisconnectGCalResponse
        return fulfillWithValue(response.data, null)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(disconnectGCal.action.fulfilled, (state, action) => {
      if (state.data.userSettings.connectedCalendar) {
        state.data.userSettings.connectedCalendar = null
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUserState>) {
    disconnectGCal.addFulfilledCase(builder)
  },
}
