import json5 from 'json5'
import AddCollectionDialogComponent from './AddCollectionDialogComponent'
import { LuruReduxStore } from '../../../../../app/store'
import { CollectionsMiddleware } from '../../../../../features/collections/middleware'
import { trackEvent } from '../../../../../analytics/Ga'
import { ToastId } from '@/app_ui/types'

interface AddCollectionDialogEvents {
  onClickCancelButton: () => void
  onClickCreateButton: () => void
  validateForm: () => string[]
  onChooseFields: (fieldNames: Array<string>) => void
  onChooseObjectNameItem: (key: string | null) => void
  onChangeName: (value: string | null) => void
  onChangeDescription: (value: string | null) => void
}

export default class AddCollectionDialogEventHandler {
  handlers: AddCollectionDialogEvents
  #component: AddCollectionDialogComponent

  constructor(component: AddCollectionDialogComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentWillUnmount = this.#onComponentUnmount.bind(this)
    this.#component.componentDidUpdate = this.#onComponentDidUpdate.bind(this)
    this.handlers = {
      onClickCancelButton: this.onClickCancelButton.bind(this),
      onClickCreateButton: this.onClickCreateButton.bind(this),
      onChooseFields: this.onChooseFields.bind(this),
      onChooseObjectNameItem: this.onChooseObjectNameItem.bind(this),
      onChangeName: this.onChangeName.bind(this),
      onChangeDescription: this.onChangeDescription.bind(this),
      validateForm: this.validateForm.bind(this),
    }
  }

  #onComponentMount() {}

  #onComponentUnmount() {}

  #onComponentDidUpdate() {}

  onClickCancelButton() {
    this.#component.setState(this.#component.getResetState(), () =>
      this.#component.componentRefs.modal.current?.cancel()
    )
  }

  validateForm() {
    const { newCollection } = this.#component.state
    let missedMandatoryFields = []
    if (!newCollection.name) {
      missedMandatoryFields.push('name')
    }
    if (newCollection.fields.length <= 0) {
      missedMandatoryFields.push('fields')
    }
    return missedMandatoryFields
  }

  async onClickCreateButton() {
    const { newCollection } = this.#component.state
    const missedMandatoryFields = this.handlers.validateForm()

    if (missedMandatoryFields.length > 0) {
      let alertMessage = ''

      if (missedMandatoryFields.length === 1 && missedMandatoryFields.includes('fields')) {
        alertMessage = 'A collection should have atleast one field'
      } else if (missedMandatoryFields.length > 1 && missedMandatoryFields.includes('fields')) {
        alertMessage = 'Please enter all mandatory fields (marked with*), \n A collection should have atleast one field'
      } else {
        alertMessage = 'Please enter all mandatory fields (marked with*)'
      }

      this.#component.setState({
        alertMessage: alertMessage,
        missedMandatoryFields: missedMandatoryFields,
      })

      return
    }

    this.#component.setState({
      creating: true,
      alertMessage: undefined,
      missedMandatoryFields: [],
    })

    try {
      trackEvent('create_collection')
      await LuruReduxStore.dispatch(
        CollectionsMiddleware.createCollection.action({
          name: newCollection.name,
          sor: newCollection.sor,
          sor_object_name: newCollection.sorObjectName,
          description: newCollection.description,
          fields: newCollection.fields,
        })
      ).unwrap()
      this.#component.props.toast.showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: 'Created collection',
        severity: 'success',
      })
    } catch (err) {
      this.#component.props.toast.showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: (err as Error)?.message || 'Error creating collection',
        severity: 'error',
      })
    } finally {
      this.#component.setState({ creating: false })
      this.onClickCancelButton()
    }
  }

  onChooseObjectNameItem(key: string | null) {
    if (key === null) {
      return
    }

    const selectedPrimaryObject = this.#component.state.crmPrimaryObjects.find((i) => i.name === key)
    if (selectedPrimaryObject?.name === this.#component.state.newCollection.sorObjectName) {
      return
    }

    if (selectedPrimaryObject) {
      this.#component.setState({
        selectedPrimaryObject,
        newCollection: {
          ...this.#component.state.newCollection,
          sorObjectName: selectedPrimaryObject?.name,
          fields: [],
        },
      })
    }
  }

  onChangeName(value: string | null) {
    if (!value) {
      return
    }

    this.#component.setState({
      newCollection: { ...this.#component.state.newCollection, name: value },
    })
  }

  onChangeDescription(value: string | null) {
    if (!value) {
      return
    }

    this.#component.setState({
      newCollection: {
        ...this.#component.state.newCollection,
        description: value,
      },
    })
  }

  onChooseFields(fieldNames: Array<string>) {
    this.#component.setState({
      newCollection: {
        ...this.#component.state.newCollection,
        fields: json5.parse(json5.stringify(fieldNames)),
      },
    })
  }
}
