import { useCallback, useContext, useRef, useState } from 'react'
import { CRMCollectionChooserProps } from '..'
import { useOutsideClick } from '../../../../../hooks/luru_hooks'
import AppComponentsContext from '../../../../../primitives/AppComponents/AppComponentsContext'
import { useEditorCaretOperations } from './useEditorCaretOperations'
import {
  InsertCollectionCommandContext,
  useEditorMenuCommandListener,
} from './useEditorMenuCommandListener'
import { useFilterBoxEvents } from './useFilterBoxEvents'
import { useHandleLinkObject } from './useHandleLinkObject'
import { useHandleLinkRecord } from './useHandleLinkRecord'
import { useHighlightSelectedItem } from './useHighlightSelectedItem'
import { useShowHideCollectionsChooser } from './useShowHideCollectionsChooser'

export const useCollectionChooserSetup = (props: CRMCollectionChooserProps) => {
  const { entityId, sourceType } = props

  const controlRef = useRef<HTMLDivElement>(null)

  const appComponents = useContext(AppComponentsContext)

  const filterRef = useRef<HTMLInputElement>(null)

  // State to store if CRM record link control is shown or hidden
  const [linkRecordPopupShown, setLinkRecordPopupShown] = useState(false)

  // State to store editor menu command context (with details as per type above)
  const [editorMenuContext, setEditorMenuContext] =
    useState<InsertCollectionCommandContext | null>(null)

  const resetFilter = useCallback(() => {
    // state.selectedIndex.set(-1)
    // state.filterText.set('')
  }, [])

  // Function to go back to notes editor
  const goBackToNotesEditor = useEditorCaretOperations(
    editorMenuContext,
    () => {
      setEditorMenuContext(null)
      resetFilter()
      setLinkRecordPopupShown(false)
    }
  )

  const { isChooserVisible, showCollectionsChooser, hideCollectionsChooser } =
    useShowHideCollectionsChooser({
      entityId,
      resetFilter,
      editorMenuContext,
      linkRecordPopupShown,
    })

  const handleLinkRecord = useHandleLinkRecord({
    goBackToNotesEditor,
    showCollectionsChooser,
    hideCollectionsChooser,
    setLinkRecordPopupShown,
  })

  const handleLinkObject = useHandleLinkObject({
    goBackToNotesEditor,
    hideCollectionsChooser,
    setLinkRecordPopupShown,
    showCollectionsChooser,
  })

  const { linkedSorObjectName } = useEditorMenuCommandListener({
    entityId,
    sourceType,
    setLinkRecordPopupShown,
    setEditorMenuContext,
    showCollectionsChooser,
    handleLinkRecord,
    handleLinkObject,
  })

  useOutsideClick(controlRef, (e: MouseEvent) => {
    const addCollElem =
      appComponents.createCollectionDialog?.current?.componentRefs.container
        .current

    if (addCollElem?.contains(e.target)) {
      return
    }

    hideCollectionsChooser()
    goBackToNotesEditor({ trace: 'useOutsideClick' })
  })

  useHighlightSelectedItem(controlRef)

  const {
    filterText,
    selectedIndex,
    handleFilterBoxChange,
    handleChooseCollection,
  } = useFilterBoxEvents({
    filterRef,
    goBackToNotesEditor,
    hideCollectionsChooser,
    editorMenuContext,
    sorObjectName: linkedSorObjectName,
  })

  return {
    control: {
      isVisible: isChooserVisible,
      controlRef,
    },
    filter: {
      filterRef,
      filterText,
      handleFilterBoxChange,
      selectedIndex,
    },
    handleChooseCollection,
    linkedSorObjectName,
  }
}
