import React from 'react'
import LuruCollectionsSelectBoxEventHandler from './LuruCollectionsSelectBoxEventHandler'
import type { LuruCollectionsSelectBoxConnectedProps } from '.'

// Import styles
import styles from './styles.module.css'
import LuruSelectBox from '../../../ui/LuruSelectBox'
import AppComponentsContext from '../../../AppComponents/AppComponentsContext'
import { CrmRecordType } from '../../../../features/crm/types'

export interface LuruCollectionsSelectBoxOwnProps {
  selectedCollectionId?: string
  crmRecordType: CrmRecordType
  subdued?: boolean
  classes?: Array<string>
  onChooseCollection?: (collectionId?: string) => void
}

export type LuruCollectionsSelectBoxProps = LuruCollectionsSelectBoxOwnProps &
  LuruCollectionsSelectBoxConnectedProps

export interface LuruCollectionsSelectBoxRefs {
  parent: React.RefObject<HTMLDivElement>
  // Add other ref elements as required
}

export default class LuruCollectionsSelectBoxComponent extends React.Component<LuruCollectionsSelectBoxProps> {
  props: LuruCollectionsSelectBoxProps
  eventHandler: LuruCollectionsSelectBoxEventHandler
  componentRefs: LuruCollectionsSelectBoxRefs

  static contextType?: React.Context<any> | undefined = AppComponentsContext

  constructor(props: LuruCollectionsSelectBoxProps) {
    super(props)
    this.props = props
    this.componentRefs = {
      parent: React.createRef(),
    }
    this.eventHandler = new LuruCollectionsSelectBoxEventHandler(this)
  }

  // Add any external world facing methods here (so consumers can call methods
  // on this components with ref handles)
  render() {
    var collections = Object.keys(this.props.collections)?.map(
      (collectionId) => ({
        key: collectionId,
        name:
          this.props.collections[collectionId]?.data?.name ??
          `Collection#${collectionId.slice(0, 4)}`,
      })
    )

    var selectedCollectionName = collections?.find(
      (c) => c.key === this.props.selectedCollectionId
    )?.name

    collections.push({
      name: '+ Add collection',
      key: LuruCollectionsSelectBoxEventHandler.createCollectionKey,
    })

    return this.props.collections ? (
      <LuruSelectBox
        key={collections.map((c) => c.key).join(';')}
        selectLabel='Choose collection'
        leftAlign={true}
        items={collections}
        subdued={this.props.subdued}
        classes={this.props.classes}
        prechosenItem={selectedCollectionName}
        onChooseItem={this.eventHandler.handlers.onChooseCollection}
      />
    ) : (
      this.#renderLoadingState()
    )
  }

  #renderLoadingState() {
    return <div className={styles.parent}>Loading...</div>
  }
}
