import { JottingType } from '../../features/notes/types'

/**
 * @classdesc Class to handle deleting a CRM field
 */
export default class CRMFieldDeleteHandler {
  // Computations

  /**
   * Compute if and how CRM field delete event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]

    if (
      lastEvent?.sourceEvent?.type !== 'mousedown' ||
      view.getJottingType(lastEvent.data.jottingElement) !==
        JottingType.CRM_FIELD_VALUE ||
      lastEvent.sourceEvent.target.dataset.role !== 'crm-field-delete'
    ) {
      return null
    }

    return this.#computeCrmFieldDeleteHandler(view, lastEvent)
  }

  /**
   * Compute handler for handling task toggling
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} toggleEvent - Luru event
   * @returns {Object} - Of type { do, preventDefault }
   */
  #computeCrmFieldDeleteHandler(view, toggleEvent) {
    let jottingElement = toggleEvent.data.jottingElement

    return {
      do: (view) => {
        view.removeJottingElement(jottingElement)
      },
      dirtyFlag: true,
    }
  }
}
