import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import { UsersAPI } from '../api'
import { ReduxSorUserAdminTask, ReduxUsersState } from '../types'

export interface ConfigureConnectionsParameter {
  userIds: string[]
  message: string
  connections: string[]
}

export interface ConfigureConnectionsAPIResponse extends LuruAPIResponse {
  data: Record<string, ReduxSorUserAdminTask[]>
}

export const configureConnections = {
  action: createAsyncThunk<
    ConfigureConnectionsAPIResponse['data'],
    ConfigureConnectionsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ConfigureConnectionsAPIResponse['metadata']
      rejectedMeta: ConfigureConnectionsAPIResponse['metadata']
    }
  >('users/configureConnections', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await UsersAPI.configureConnections(params, {
        signal,
      })) as ConfigureConnectionsAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue(
        { message: (e as Error)?.message ?? 'Error inviting users for configuring connections' },
        null
      )
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(configureConnections.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(configureConnections.action.fulfilled, (state, action) => {})
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(configureConnections.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    configureConnections.addPendingCase(builder)
    configureConnections.addFulfilledCase(builder)
    configureConnections.addRejectedCase(builder)
  },
}
