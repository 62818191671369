import { Provider } from 'react-redux'

// Own libaries and components
import { LuruFieldType } from '../../domain/crmRecord/typings.d'
import LuruPicklist from './LuruPicklist'
import LuruMultiPicklist from './LuruMultiPicklist'
import { LuruReduxStore } from '../../app/store'
import CrmMultiObjectSelectBox from '../../primitives/domain/crm/CrmMultiObjectSelectBox'
import DomUtils from '../utils/DomUtils'
import CrmObjectSelectBox from '../../primitives/domain/crm/CrmObjectSelectBox'

// Styles
import styles from '../../routes/notes/css/NotesEditor.module.css'
import Utils from '../../utils/Utils'

export default class EditorCrmFieldView {
  static ReactRootSelector = '[data-luru-role="sor-field-value-root"]'
  static ValueElementSelector = '[data-role="luru-crm-field"]'

  /**
   * Create and return an HTML element representing a CRM field value
   * @param {Object} crmJottingData - CRM jotting data
   * @param {Object} fieldValue - Latest value of CRM field
   * @param {Boolean} isReadonly - Whether note is read only
   * @param {(_: string) => void} onValueChange - Callback for value changed
   * @param {() => boolean} isReadonlyCallback - Compute if editor is readonly
   * @returns {HTMLElement}
   */
  computeViewElement(
    crmJottingData,
    fieldValue,
    isReadonly = false,
    onValueChange = null,
    isReadonlyCallback = () => false
  ) {
    if (!crmJottingData) {
      return { element: null }
    }

    var uidLength = 7
    var letters = 'abcdefghijklmnopqrstuvwxyz1234567890'
    var uid = Array.from(Array(uidLength).keys()).reduce(
      (result, i) =>
        result +
        ((i + 1) % 5
          ? letters[Math.floor(Math.random() * (letters.length - 1))]
          : '-'),
      ''
    )

    if (crmJottingData.field.luruFieldType === LuruFieldType.ENUM) {
      let reactFieldRoot = document.createElement('DIV')

      reactFieldRoot.setAttribute('data-luru-role', 'sor-field-value-root')

      return {
        element: reactFieldRoot,
        component: (
          <LuruPicklist
            key={uid}
            fieldData={crmJottingData}
            onSelectItem={onValueChange}
            computeIfReadonly={isReadonlyCallback}
          />
        ),
      }
    }

    if (crmJottingData.field.luruFieldType === LuruFieldType.MULTIENUM) {
      let reactFieldRoot = document.createElement('DIV')

      reactFieldRoot.setAttribute('data-luru-role', 'sor-field-value-root')

      return {
        element: reactFieldRoot,
        component: (
          <LuruMultiPicklist
            key={uid}
            fieldData={crmJottingData}
            onSelectItem={onValueChange}
            computeIfReadonly={isReadonlyCallback}
          />
        ),
      }
    }

    if (crmJottingData.field.luruFieldType === LuruFieldType.REFERENCE) {
      let reactFieldRoot = document.createElement('DIV')
      let fieldValue = crmJottingData.field.value
      let selectHandler = (sorRecordId, sorRecordName, srcElement) => {
        onValueChange({ sorRecordId, sorRecordName }, srcElement)
      }

      reactFieldRoot.setAttribute('data-luru-role', 'sor-field-value-root')

      return {
        element: reactFieldRoot,
        component: (
          <Provider store={LuruReduxStore} key={uid}>
            <CrmObjectSelectBox
              className={styles.noBorder}
              sorObjectName={crmJottingData.field.sorObjectName}
              value={fieldValue?.sor_record_name ?? undefined}
              onSelectItem={selectHandler}
              luruRole='luru-crm-field'
              readOnly={isReadonlyCallback() ? true : undefined}
            />
          </Provider>
        ),
      }
    }

    if (crmJottingData.field.luruFieldType === LuruFieldType.MULTIREFERENCE) {
      let reactFieldRoot = document.createElement('DIV')
      let fieldValue = crmJottingData.field.value
      let selectHandler = (values) => {
        onValueChange(values, reactFieldRoot)
      }

      reactFieldRoot.setAttribute('data-luru-role', 'sor-field-value-root')

      return {
        element: reactFieldRoot,
        component: (
          <Provider store={LuruReduxStore} key={uid}>
            <CrmMultiObjectSelectBox
              sorObjectName={crmJottingData.field.sorObjectName}
              values={fieldValue}
              fieldName={crmJottingData.field.sorObjectName}
              dialogTitle={`Add / Edit ${Utils.capitalizeString(
                crmJottingData.field.sorObjectName
              )}`}
              readOnly={isReadonlyCallback() ? true : undefined}
              luruRole='luru-crm-field'
              onFinishSelection={selectHandler}
            />
          </Provider>
        ),
      }
    }

    let domElementDetails = this.#computeDomElement(
      crmJottingData.field.luruFieldType
    )
    let valueElement = document.createElement(domElementDetails.htmlTag)

    // Attributes
    valueElement.setAttribute('name', crmJottingData.field.name)
    valueElement.setAttribute('data-role', 'luru-crm-field')
    valueElement.setAttribute(
      'data-luru-field-type',
      crmJottingData.field.luruFieldType
    )

    if (
      crmJottingData.field.readonly === 'true' ||
      crmJottingData.field.readonly === true
    ) {
      valueElement.setAttribute('readonly', 'true')
    }

    if (isReadonly) {
      valueElement.setAttribute('disabled', 'true')
    }

    Object.keys(domElementDetails.attributes).forEach((name) =>
      valueElement.setAttribute(name, domElementDetails.attributes[name])
    )
    valueElement.setAttribute('placeholder', '')

    if (
      crmJottingData.field.luruFieldType === LuruFieldType.DATE ||
      crmJottingData.field.luruFieldType === LuruFieldType.DATETIME
    ) {
      DomUtils.addDatePickedEvent(valueElement, (value) =>
        onValueChange(value, valueElement)
      )
    }

    try {
      this.#fillCrmFieldValue(valueElement, crmJottingData, fieldValue)
    } catch (e) {
      valueElement.value = '(warning) Field value'
    }

    // Value - styles
    valueElement.classList.add(styles[crmJottingData.field.luruFieldType])

    return { element: valueElement }
  }

  /**
   * Make a CRM field element editable
   * @param {HTMLElement} crmNoteElement - Given CRM note element
   * @param {Object} crmJottingData - CRM jotting data
   */
  makeCrmFieldEditable(crmNoteElement, crmJottingData) {
    let valueElements = crmNoteElement?.querySelectorAll(
      '[data-role="luru-crm-field"]'
    )

    if (!valueElements) {
      return
    }

    if (
      crmJottingData?.field?.readonly !== 'true' &&
      crmJottingData?.field?.readonly !== true
    ) {
      Array.from(valueElements).forEach((element) => {
        element.setAttribute('data-luru-state', 'made-editable')
        element.removeAttribute('disabled')
      })
    }
  }

  /**
   * Calculate which DOM element should be created and which attributes should
   * be set for a given field type
   */
  #computeDomElement(fieldType) {
    switch (fieldType) {
      case LuruFieldType.ADDRESS:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
          },
        }

      case LuruFieldType.BOOLEAN:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'checkbox',
          },
        }

      case LuruFieldType.DATE:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'date',
          },
        }

      case LuruFieldType.DATETIME:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'datetime-local',
          },
        }

      case LuruFieldType.EMAIL:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'email',
          },
        }

      case LuruFieldType.ENUM:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
          },
        }

      case LuruFieldType.CURRENCY:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
            pattern: `[0-9]+`,
          },
        }

      case LuruFieldType.DOUBLE:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
            pattern: `[0-9]+`,
          },
        }

      case LuruFieldType.INTEGER:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
            pattern: `[0-9]+`,
          },
        }

      case LuruFieldType.INTEGER_NOFORMAT:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
            pattern: `[0-9]+`,
          },
        }

      case LuruFieldType.PERCENTAGE:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
            pattern: `[0-9]{1,3}`,
          },
        }

      case LuruFieldType.LARGETEXT:
        return {
          htmlTag: 'textarea',
          attributes: {
            rows: 3,
          },
        }

      case LuruFieldType.MULTIENUM:
        // We are doing checkboxes for MULTIENUM
        return {
          htmlTag: 'div',
          attributes: {},
        }

      case LuruFieldType.TELEPHONE:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'tel',
          },
        }

      case LuruFieldType.URL:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'url',
          },
        }

      case LuruFieldType.TEXT:
      default:
        return {
          htmlTag: 'input',
          attributes: {
            type: 'text',
          },
        }
    }
  }

  /**
   * Fill a CRM value element with the corresponding field data
   * @param {HTMLElement} element - A CRM field value element
   * @param {Object} crmJottingData - Given CRM jotting data
   * @param {any} fieldValue - CRM field value to be used
   */
  #fillCrmFieldValue(element, crmJottingData, fieldValue) {
    if (fieldValue === null || fieldValue === undefined) {
      fieldValue = ''
    }

    switch (crmJottingData.field.luruFieldType) {
      case LuruFieldType.ADDRESS:
        element.value = fieldValue
        break

      case LuruFieldType.BOOLEAN:
        element.setAttribute('checked', fieldValue ? 'true' : 'false')
        break

      case LuruFieldType.CURRENCY:
        element.value = fieldValue
        break

      case LuruFieldType.DATE:
        try {
          let d = new Date(fieldValue)
          element.value = d.toISOString().slice(0, 10)
        } catch (e) {}
        break

      case LuruFieldType.DATETIME:
        try {
          let d = new Date(fieldValue)
          element.value = d.toISOString().slice(0, -8)
        } catch (e) {}
        break

      case LuruFieldType.DOUBLE:
        element.value = fieldValue
        break

      case LuruFieldType.EMAIL:
        element.value = fieldValue
        break

      case LuruFieldType.ENUM:
        const listNameParts = [
          crmJottingData.sorId,
          crmJottingData.record.sorObjectName,
          crmJottingData.field.name,
          'values',
        ]
        const listName = listNameParts.join('-')
        let dataListElement = document.getElementById(listName)

        // Insert data list element if not present already
        // Advantage of appending this to body: Even navigating across different
        // notes, we can reuse the data list
        if (!dataListElement) {
          dataListElement = document.createElement('datalist')
          dataListElement.setAttribute('id', listName)

          if (crmJottingData.field.picklistValues instanceof Array) {
            crmJottingData.field.picklistValues.forEach((item) => {
              let option = document.createElement('option')
              option.setAttribute('value', item.value)
              dataListElement.appendChild(option)
            })
          } else {
            console.warn(
              `fillCrmFieldValue:Field value is not array for ENUM`,
              crmJottingData.field.picklistValues
            )
          }
          document.body.appendChild(dataListElement)
        }

        element.value = fieldValue
        element.setAttribute('list', listName)

        break

      case LuruFieldType.MULTIENUM:
        // TODO: Setup names for each input element
        let chosenValues = fieldValue.split(';')
        if (crmJottingData.field.picklistValues instanceof Array) {
          let html = crmJottingData.field.picklistValues.map((item) =>
            [
              `<div><input type="checkbox" id="${item.label}"`,
              chosenValues.includes(item.value) ? `checked` : ``,
              `data-role="luru-crm-field"`,
              `name="${crmJottingData.field.name}" value="${item.value}">`,
              `<label for="${item.label}">${item.label}</label></div>`,
            ].join(' ')
          )
          element.innerHTML = html.join('')
        } else {
          console.log(crmJottingData.field.picklistValues)
        }
        element.value = fieldValue
        break

      case LuruFieldType.INTEGER:
        element.value = fieldValue
        break

      case LuruFieldType.INTEGER_NOFORMAT:
        element.value = fieldValue
        break

      case LuruFieldType.LARGETEXT:
        element.value = fieldValue
        break

      case LuruFieldType.PERCENTAGE:
        element.value = fieldValue
        break

      case LuruFieldType.TELEPHONE:
        element.value = fieldValue
        break

      case LuruFieldType.URL:
        element.value = fieldValue
        break

      case LuruFieldType.TEXT:
      default:
        element.value = fieldValue
        break
    }
  }

  // Computations
  static canSelectAndEdit(inputElement) {
    try {
      return (
        !inputElement.hasAttribute('readonly') &&
        !inputElement.hasAttribute('disabled') &&
        inputElement.type !== 'radio' &&
        inputElement.type !== 'checkbox' &&
        inputElement.getAttribute('data-luru-element-type') !==
          'type-ahead-search-box'
      )
    } catch (e) {
      return false
    }
  }
}
