import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { EntityStatus } from '../../../../../app/types'
import { CollectionsMiddleware } from '../../../../../features/collections/middleware'
import { useEditorCaretOperations } from './useEditorCaretOperations'
import { useShowHideCollectionsChooser } from './useShowHideCollectionsChooser'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export const useHandleLinkRecord = ({
  goBackToNotesEditor,
  showCollectionsChooser,
  hideCollectionsChooser,
  setLinkRecordPopupShown,
}: {
  setLinkRecordPopupShown: React.Dispatch<React.SetStateAction<boolean>>
  goBackToNotesEditor: ReturnType<typeof useEditorCaretOperations>
  showCollectionsChooser: ReturnType<typeof useShowHideCollectionsChooser>['showCollectionsChooser']
  hideCollectionsChooser: ReturnType<typeof useShowHideCollectionsChooser>['hideCollectionsChooser']
}) => {
  // Redux dispatch
  const reduxDispatch = useAppDispatch()

  // Toast
  const { showToast } = useLuruToast()

  // Collections status
  const collectionsStatus = useAppSelector((s) => s.collections.status)

  // Handler when a CRM record is linked (when we started with a note that was
  // already not linked)
  // TODO: Implement this
  const handleLinkRecord = useCallback(
    (
      recordInfo: {
        sorObjectName?: string
        sorRecordId?: string
        rangeContainer?: Node
        rangeOffset?: number
      } | null
    ) => {
      if (!recordInfo) {
        try {
          goBackToNotesEditor({ trace: 'After cancel link' })
        } catch (e) {
          console.warn('useEditorMenuCommandListener:handleLinkRecord:', e)
        }
      }

      if (!recordInfo?.sorObjectName || !recordInfo?.sorRecordId) {
        hideCollectionsChooser()

        goBackToNotesEditor({
          trace: 'After link without SOR object or record',
          rangeContainer: recordInfo?.rangeContainer,
          rangeOffset: recordInfo?.rangeOffset,
        })

        return
      }

      if (collectionsStatus !== EntityStatus.Loaded) {
        showToast({
          id: ToastId.CRM_COLLECTION_CHOOSER_TOAST_ID,
          message: 'Fetching collections',
          isLoading: true,
        })
        setLinkRecordPopupShown(false)
        reduxDispatch(CollectionsMiddleware.listCollections.action({}))
      } else {
        showCollectionsChooser()
      }
    },
    [
      goBackToNotesEditor,
      hideCollectionsChooser,
      collectionsStatus,
      reduxDispatch,
      showCollectionsChooser,
      setLinkRecordPopupShown,
      showToast,
    ]
  )

  return handleLinkRecord
}
