import { RootState } from '../../../app/store'
import { ReduxEmailSyncsStateTTL } from '../types'

export const isEmailSyncsCacheValid = (state: RootState) =>
  !Boolean(state.emailsyncs.refreshedAt) ||
  new Date().valueOf() -
    (state.emailsyncs.refreshedAt
      ? new Date(state.emailsyncs.refreshedAt).valueOf()
      : 0) <
    ReduxEmailSyncsStateTTL
