import { Route } from 'react-router-dom'
import * as d3 from 'd3'
import styles from './TestRoutes.module.css'
import { useEffect, useState } from 'react'
import PlotContainer from '@/components/PlotContainer'

const renderTestRoutes = () => <Route path='/test' element={<MyTestWrapper />} />

export default renderTestRoutes

function MyTestWrapper() {
  return (
    <div style={{ margin: '2rem' }}>
      <MyTest />
    </div>
  )
}

function MyTest() {
  const [data, setData] = useState<d3.DSVRowArray<string>>()

  useEffect(() => {
    d3.csv(
      'https://media.githubusercontent.com/media/datablist/sample-csv-files/main/files/customers/customers-100.csv'
    ).then((d) => {
      setData(d)
    })
  }, [])

  // Log whenever data changes
  useEffect(() => console.log({ data }), [data])

  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        width: '35rem',
        margin: '0 auto',
        overflow: 'auto',
        height: '90vh',
      }}
    >
      <h1 className={styles.gradientText}>Different LuruButton styles</h1>
      <div className={styles.translucentBox}>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae voluptates magnam modi laboriosam temporibus
        repellat accusamus, magni doloremque blanditiis corporis, voluptate unde, in quo quaerat iure tenetur voluptatem
        quis! Et.
      </div>
      <PlotContainer isLoading={true} />
    </div>
  )
}

// // Exposing a component's (say, InnerComponent) inner functions with refs

// // 1. Declare types for InnerComponent props and ref value
// //    Ref value can be an object that holds all the inner functions
// type InnerComponentRefValue = {
//   innerFunction: () => void
// }

// type InnerComponentProps = {
//   foo: string
// }

// // 2. Write the component
// function InnerComponent(props: InnerComponentProps, ref: React.Ref<InnerComponentRefValue>) {
//   const innerFunction = useCallback(() => {
//     alert('innerFunction')
//   }, [])

//   // 3. Use useImperativeHandle hook to expose the inner functions
//   //    the 2nd argument is a function that returns an object that conforms to ref value type declared in (1)
//   useImperativeHandle(ref, () => ({
//     innerFunction,
//   }))

//   return <>InnerComponent</>
// }

// // 4. Option 1. Write a new component and se React.forwardRef to forward the ref to the component
// const OuterComponent = React.forwardRef<InnerComponentRefValue, InnerComponentProps>(InnerComponent)

// // 4. Option 2. Write the component inline
// //    Advantage: The types of props and ref are inferred automatically
// const InnerComponentAlt = React.forwardRef<InnerComponentRefValue, InnerComponentProps>((props, ref) => {
//   return <></>
// })

// const UsingComponent = () => {
//   const ref = React.useRef<InnerComponentRefValue>(null)

//   const handleClick = () => {
//     ref.current?.innerFunction()
//   }

//   return (
//     <>
//       <OuterComponent ref={ref} foo='' />
//       <button onClick={handleClick}>Click</button>
//     </>
//   )
// }
