import json5 from 'json5'
import { JottingType, NoteBody } from '../types'

export const getNoteTextContent = (body: string | NoteBody) => {
  if (!body) {
    return null
  }
  if (typeof body === 'string') {
    try {
      body = json5.parse(body)
    } catch (e) {}
  }
  if (!(body instanceof Array)) {
    return null
  }
  return body
    .map((jotting) =>
      typeof jotting.data !== 'string' &&
      jotting.type === JottingType.CRM_FIELD_VALUE
        ? jotting.data.fieldName ?? ''
        : // [
        //   jotting.data.record.sorObjectName,
        //   jotting.data.field.label,
        //   jotting.data.field.value,
        // ].join(' ')
        typeof jotting.data === 'string'
        ? jotting.data.replace(/(<([^>]+)>)/gi, '') ?? ''
        : ''
    )
    .join(' ')
}
