import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CreateNoteTemplateParameter } from '../middleware/createNoteTemplate'

export const createNoteTemplate = async (
  params: Readonly<CreateNoteTemplateParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    '/api/note-templates',
    HTTPMethod.POST,
    options
  )

  request.setBody(params)

  return request.make()
}
