import { PayloadAction } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { CrmObjectName, ReduxCrmKey, ReduxCrmState } from '../types'

export const setEntityStatus = (
  state: ReduxCrmState,
  action: PayloadAction<{
    crmKey: ReduxCrmKey
    objectName: CrmObjectName
    recordId: string
    status: EntityStatus
  }>
) => {
  const { crmKey, objectName, recordId, status } = action.payload
  const entityObj = state[crmKey]?.entities?.[objectName]?.[recordId]

  if (entityObj) {
    entityObj.status = status
  }
}
