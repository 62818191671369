// Redux
import { createAsyncThunk } from '@reduxjs/toolkit'

// CRM base APIs
import { crmApi } from '../crmBaseAPI'

// CRM middleware parent object
import { abortAllRequests } from '../../crmMiddleware'
import LuruUser from '../../../../domain/users/LuruUser'
import { CRMProvider } from '../../../../features/user/types'
import { EntityStatus } from '../../../../app/types'
import { areObjectsDeepEqual } from '../../../../utils/Utils'

/**
 * @classdesc - Class to manage a CRM's getRecordFields API request
 */
export default class GetObjectSchema {
  #crmId = null
  #requestKey = null
  #middlewareFactory = null

  /**
   * Constructor for this class.  Sets up local variables.  Request key is
   * used as the thunk name for Redux purposes.  It is also used to namespace
   * abort controllers object in CRM middleware.
   * @param {CRMBaseMiddlewareFactory} middlewareFactory - A middleware factory
   * which is going to use this instance
   */
  constructor(middlewareFactory) {
    this.#middlewareFactory = middlewareFactory
    this.#crmId = this.#middlewareFactory.getCrmId()
    this.#requestKey = `${this.#crmId}/getObjectSchema`
  }

  /**
   * Function to get the portion of the middleware for managing this particular
   * request.  This will be used by the top-level CRM middleware to compose a
   * large middleware object.
   * @returns {Object} - Middleware to manage this request
   */
  getRequestMiddleware() {
    return {
      key: this.#requestKey,
      action: this.#computeAction(),
      abortAllRequests: this.#computeAbortAllRequests(),
      pending: this.#computePendingState(),
      fulfilled: this.#computeFulfilledState(),
      rejected: this.#computeRejectedState(),
    }
  }

  /**
   * Returns a function to initiate a search in CRM.  The thunk itself expects
   * an object as a parameter with two keys: query and objects, which is an
   * array of object names across which to search
   * @return {Object} - Output of reduxjs/toolkit/createAsyncThunk() function
   */
  #computeAction() {
    let thunk = async (payload, { getState, signal }) => {
      if (!payload?.sorObjectName) {
        return
      }

      let storedSchema = getState().crm?.[this.#crmId]?.schema[payload.sorObjectName]
      let isSchemaAvailable = storedSchema?.status === EntityStatus.Loaded && storedSchema.data !== null

      // Insert a pipeline ID for getting schema of opportunity objects
      const sorOpportunityName = this.#middlewareFactory
        .getPrimaryObjectNames()
        .find((item) => item.isOpportunity === true)?.name

      // Try to get the pipelineId directly or indirectly from whoever calls
      // getSchema()
      let { pipelineId, noteTemplateId } = payload

      if (noteTemplateId && !pipelineId) {
        pipelineId = getState().noteTemplates?.entities?.[noteTemplateId]?.data?.filter?.sorPipelineId
      }

      let recordWithPipeline = null

      if (sorOpportunityName === payload.sorObjectName) {
        if (!pipelineId && this.#crmId !== 'sfdc') {
          // console.trace(`WARNING:getObjectSchema:pipelineId not available`)
        }
        // if (this.#crmId === 'hubspot' && pipelineId) {
        //   recordWithPipeline = { properties: { pipeline: pipelineId } }
        // }
        if (this.#crmId === 'pipedrive') {
          recordWithPipeline = { data: { pipeline_id: pipelineId } }
        }
      }

      try {
        if (isSchemaAvailable && !payload?.forceRefresh) {
          return { schema: storedSchema.data }
        }

        // Get schema for given object
        let schema = await crmApi.getObjectSchema(
          this.#crmId,
          payload.sorObjectName,
          recordWithPipeline,
          payload?.forceRefresh
        )

        if (
          [CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX, CRMProvider.HUBSPOT, CRMProvider.PIPEDRIVE].includes(
            LuruUser.getCurrentUserCrmName()
          )
        ) {
          schema = this.#middlewareFactory.normalizeSchema(schema, payload.sorObjectName)
          return { schema, source: 'action' }
        }

        // Get pipelines as well for opportunity object
        let pipelines = await crmApi.getPipelineList({ crmId: this.#crmId }, { signal })
        pipelines = this.#middlewareFactory.normalizePipelineList(pipelines)
        return { schema, pipelines }
      } catch (e) {
        console.warn(`${this.#requestKey}: Error while fetching schema`, e)
        return { error: true }
      }
    }

    thunk = thunk.bind(this)
    return createAsyncThunk(this.#requestKey, thunk)
  }

  /**
   * Compute a function to abort requests for this particular request key
   * @returns {Function} - Function to abort all requests for this request
   */
  #computeAbortAllRequests() {
    let abortFunction = () => abortAllRequests(this.#requestKey)
    abortFunction.bind(this)
    return abortFunction
  }

  /**
   * Compute a pending state handler required by Redux for handling this request
   * @returns {Function} - Pending state handler
   */
  #computePendingState() {
    let pendingStateHandler = (state, action) => {
      const queryPayload = action.meta.arg
      const objectName = queryPayload.sorObjectName
      const crmId = this.#crmId

      // Initialize objects if necessary
      if (state[crmId] === undefined) {
        state[crmId] = {}
      }
      if (state[crmId].schema === undefined) {
        state[crmId].schema = {}
      }
      if (state[crmId].schema[objectName] === undefined) {
        state[crmId].schema[objectName] = {}
      }

      // Check for availability of schema
      let storedSchema = state[crmId].schema[objectName]

      if (storedSchema.status !== EntityStatus.Loading && storedSchema.status !== EntityStatus.Loaded) {
        state[crmId].schema[objectName] = {
          status: EntityStatus.Loading,
          data: null,
        }
      }
    }
    pendingStateHandler = pendingStateHandler.bind(this)
    return pendingStateHandler
  }

  /**
   * Compute a fulfilled handler required by Redux for handling this request
   * @returns {Function} - Fulfilled state handler
   */
  #computeFulfilledState() {
    var fulfilledStateHandler = (state, action) => {
      const queryPayload = action.meta.arg
      const objectName = queryPayload.sorObjectName
      const crmId = this.#crmId
      var { schema, pipelines } = action.payload || {}

      // Normalize schema only if necessary
      // if (!Array.isArray(schema.fields) || schema.fields.length === 0 || schema.fields[0].luruFieldType === undefined) {
      //   schema = this.#middlewareFactory.normalizeSchema(schema, objectName)
      // }

      // Check whether fetched schema is same or not
      const isSchemaFieldsSameAsOld = areObjectsDeepEqual(
        state?.[crmId]?.schema?.[objectName]?.data?.fields || {},
        schema?.fields
      )

      if (
        state[crmId] &&
        (state[crmId].schema[objectName]?.status !== EntityStatus.Loaded || !isSchemaFieldsSameAsOld)
      ) {
        state[crmId].schema[objectName] = {
          status: EntityStatus.Loaded,
          data: schema,
          error: null,
          lastRefreshedAt: new Date().toISOString(),
        }
      }

      if (state[crmId] && state[crmId].pipelines?.status !== EntityStatus.Loaded && pipelines) {
        state[crmId].pipelines = {
          status: EntityStatus.Loaded,
          data: this.#middlewareFactory.normalizePipelineList(pipelines),
        }
      }
      if (action?.payload?.error && state[crmId]) {
        state[crmId].schema[objectName] = {
          ...state[crmId]?.schema?.[objectName],
          status: EntityStatus.ErrorLoading,
        }
      }
    }

    fulfilledStateHandler = fulfilledStateHandler.bind(this)

    return fulfilledStateHandler
  }

  /**
   * Compute a rejected handler required by Redux for handling this request
   * @returns {Function} - Rejected state handler
   */
  #computeRejectedState() {
    let rejectedStateHandler = (state, error) => {
      if (!error.meta.aborted) {
        const queryPayload = error.meta.arg
        const objectName = queryPayload.sorObjectName
        const crmId = this.#crmId

        if (state[crmId].schema[objectName]?.status !== EntityStatus.Loaded) {
          state[crmId].schema[objectName] = {
            status: EntityStatus.ErrorLoading,
            data: null,
            error: error,
          }
        }

        console.log(`${this.#requestKey}:rejectedStateHandler:`, error)
        throw new Error(error)
      }
    }
    rejectedStateHandler = rejectedStateHandler.bind(this)
    return rejectedStateHandler
  }
}
