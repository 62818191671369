import { HTTPMethod } from '../../../app/types'
import { getAuthUrl } from '../../../domutils/utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DisconnectGChatRequestParams } from '../middleware/disconnectGChat'

export const disconnectGChat = async (params: DisconnectGChatRequestParams, options: LuruAPIRequestOptions) => {
  // var oAuthKey = 'google-oauth2-gchat'
  var disconnectUrl = getAuthUrl() + '/disconnect_gchat'
  var request = new LuruAPIRequest(disconnectUrl, HTTPMethod.POST, {
    ...options,
    headers: {
      'X-CSRFToken': params.csrfToken ?? '',
    },
    credentials: 'include',
  })

  return request.make()
}
