// Own hooks
import { useRef } from 'react'
import { useGetSetState } from '../../hooks/luru_hooks'
import { useOutsideClick } from '../../hooks/luru_hooks'

// Own styles
import styles from './css/LuruMultiPicklist.module.css'

// Icons
import doneIcon from '../../images/fluent/done.svg'
import closeIcon from '../../images/fluent/close.svg'
import circleDownIcon from '../../images/fluent/chevron_circle_down.svg'

export default function LuruMultiPicklist({
  fieldData,
  onSelectItem,
  computeIfReadonly,
  maximumItems,
}) {
  const refs = {
    filterBox: useRef(),
    inputElement: useRef(),
    dropdownList: useRef(),
  }

  const DEFAULT_MAX_ITEMS = 5
  const compareValues = (a, b) => `${a}` === `${b}`

  const sortValueIndex = fieldData?.field?.picklistValues
    ?.map((option, index) => ({ value: option?.value, index }))
    .reduce((prev, item) => ({ ...prev, [item?.value]: item?.index }), {})

  var selectedValues = fieldData?.field?.value?.split(';') ?? []

  selectedValues = selectedValues.filter((v) =>
    Boolean(fieldData?.field?.picklistValues?.find((p) => p.value === v))
  )

  const state = {
    selectedValues: useGetSetState(selectedValues),
    allItems: fieldData?.field?.picklistValues ?? null,
    typedLabel: useGetSetState(''),
    filtered: useGetSetState(false),
    filteredItems: useGetSetState(fieldData?.field?.picklistValues ?? null),
    selectedIndex: useGetSetState(-1),
  }

  const actions = {
    showDropdown: () => {
      refs.dropdownList.current?.classList?.remove(styles.collapsed)
      refs.dropdownList.current?.classList?.add(styles.expandedDown)
      refs.filterBox.current?.focus()
    },
    hideDropDown: () => {
      if (refs.dropdownList.current?.classList?.contains(styles.collapsed)) {
        return
      }
      refs.dropdownList.current?.classList?.remove(styles.expandedDown)
      refs.dropdownList.current?.classList?.add(styles.collapsed)
      state.typedLabel.set('')
      state.filtered.set(false)
      state.filteredItems.set(state.allItems)

      var selectedValues = state.selectedValues.get()
      var valueToUpdate =
        selectedValues.length > 0
          ? selectedValues
              .sort((a, b) => (sortValueIndex[a] > sortValueIndex[b] ? 1 : -1))
              .join(';')
          : null

      onSelectItem?.(valueToUpdate, refs.inputElement.current)
    },
    removeItem: (e, value) => {
      if (computeIfReadonly()) {
        return
      }

      // Prevent click from propagating up and triggering hide/show
      e.preventDefault()
      e.stopPropagation()

      // Remove chip-linked-value
      state.selectedValues.set(
        state.selectedValues
          .get()
          ?.filter((selectedValue) => !compareValues(selectedValue, value))
      )

      // If menu was hidden, show it
      if (refs.dropdownList.current?.classList?.contains(styles.collapsed)) {
        eventHandlers.onDropdownActivate()
      }
    },
    toggleSelectionForItem: ({ label, value }, isItemSelected) => {
      if (computeIfReadonly()) {
        return
      }

      if (isItemSelected) {
        state.selectedValues.set(
          state.selectedValues
            .get()
            ?.filter((selectedValue) => !compareValues(selectedValue, value))
        )
      } else {
        state.selectedValues.set(state.selectedValues.get()?.concat(value))
      }
    },
  }

  const eventHandlers = {
    onDropdownActivate: () => {
      !computeIfReadonly() &&
      refs.dropdownList.current?.classList?.contains(styles.collapsed)
        ? actions.showDropdown()
        : actions.hideDropDown()
    },
    onClickItem: (option, index, isItemSelected) => {
      actions.toggleSelectionForItem(option, isItemSelected)
    },
    onValueChange: (e) => {
      state.typedLabel.set(e?.target?.value)
      let filterText = e?.target?.value?.toLowerCase()
      state.filtered.set(true)
      state.filteredItems.set(
        state.allItems?.filter(
          (option) => option?.label?.toLowerCase()?.indexOf(filterText) !== -1
        )
      )
    },
    onKeyDown: (e) => {
      switch (e?.key) {
        case 'Enter':
          const currOption = state.filteredItems.get()?.[
            state.selectedIndex.get()
          ] ?? { label: null, value: null }
          actions.toggleSelectionForItem(
            currOption,
            Boolean(
              state.selectedValues
                .get()
                ?.find((value) => compareValues(value, currOption.value))
            )
          )
          e.preventDefault()
          e.stopPropagation()
          break

        case 'Escape':
          actions.hideDropDown()
          e.preventDefault()
          e.stopPropagation()
          break

        case 'ArrowUp':
          state.selectedIndex.set(
            state.selectedIndex.get() === 0
              ? state.filteredItems.get()?.length - 1
              : (state.selectedIndex.get() ?? 1) - 1
          )
          e.preventDefault()
          e.stopPropagation()
          break

        case 'ArrowDown':
          state.selectedIndex.set(
            state.selectedIndex.get() >= state.filteredItems.get()?.length - 1
              ? 0
              : (state.selectedIndex.get() ?? -1) + 1
          )
          e.preventDefault()
          e.stopPropagation()
          break
        default:
      }
    },
  }

  const renderer = {
    formatResult: (option, index) => {
      try {
        if (!option) {
          return ''
        }
        const searchIndex = option.label
          ?.toLowerCase()
          ?.indexOf(state.typedLabel.get()?.toLowerCase())
        let filterLength = state.typedLabel.get()?.length ?? 0
        let preSearch = ''
        let search = option.label
        let postSearch = ''

        if (filterLength > 0 && searchIndex >= 0 && state.filtered.get()) {
          preSearch = option.label?.slice(0, searchIndex)
          search = (
            <b>
              {option.label?.slice(searchIndex, searchIndex + filterLength)}
            </b>
          )
          postSearch =
            searchIndex < (option.label?.length ?? 1) - 1
              ? option.label?.slice(searchIndex + filterLength)
              : ''
        }

        const isItemSelected = state.selectedValues
          .get()
          ?.find((value) => compareValues(value, option.value))

        return (
          <li
            key={option.value}
            className={
              index === state.selectedIndex.get() ? styles.selected : null
            }
            data-luru-value={option.label}
            onClick={() =>
              eventHandlers.onClickItem(option, index, isItemSelected)
            }
          >
            {isItemSelected ? (
              <img src={doneIcon} alt='done' />
            ) : (
              <span>&nbsp;</span>
            )}
            <span className={styles.searchResult}>
              {preSearch}
              {search}
              {postSearch}
            </span>
          </li>
        )
      } catch (e) {
        console.warn(e)
        return option?.label ?? 'Error formatting option'
      }
    },
  }

  useOutsideClick(refs.inputElement, actions.hideDropDown)

  return (
    <div className={styles.parent} ref={refs.inputElement}>
      <div
        className={styles.inputRow}
        onClick={eventHandlers.onDropdownActivate}
      >
        <div className={styles.inputBox}>
          {state.selectedValues
            ?.get()
            ?.filter((x, index) => index < (maximumItems ?? DEFAULT_MAX_ITEMS))
            ?.map((value) => {
              const option = state.allItems?.find((option) =>
                compareValues(option.value, value) ? option : null
              )
              if (!option) {
                return ''
              }
              return (
                <span className={styles.chip} key={value}>
                  <button onClick={(e) => actions.removeItem(e, value)}>
                    <img src={closeIcon} alt='close' />
                  </button>
                  <label>{option.label}</label>
                </span>
              )
            }) ?? ''}
          {state.selectedValues?.get()?.length >
          (maximumItems ?? DEFAULT_MAX_ITEMS) ? (
            <span className={styles.moreMessage}>
              &amp;{' '}
              {state.selectedValues?.get()?.length -
                (maximumItems ?? DEFAULT_MAX_ITEMS)}{' '}
              more
            </span>
          ) : null}
        </div>
        <button className={styles.dropdownButton}>
          <img src={circleDownIcon} alt='expand_down' />
        </button>
      </div>
      <div
        className={[styles.dropdownList, styles.collapsed].join(' ')}
        ref={refs.dropdownList}
      >
        <input
          ref={refs.filterBox}
          type='search'
          value={state.typedLabel.get() ?? ''}
          onChange={eventHandlers.onValueChange}
          onKeyDown={eventHandlers.onKeyDown}
        />
        <ul>
          {state.filteredItems
            .get()
            ?.map((option, index) => renderer.formatResult(option, index))}
        </ul>
      </div>
    </div>
  )
}
