// Redux
import { createAsyncThunk } from '@reduxjs/toolkit'

// CRM base APIs
import { crmApi } from '../crmBaseAPI'

// CRM middleware parent object
import { abortAllRequests } from '../../crmMiddleware'
import { EntityStatus } from '../../../../app/types'

/**
 * @classdesc - Class to manage a CRM's getRecordFields API request
 */
export default class GetPipelineStages {
  #crmId = null
  #requestKey = null
  #middlewareFactory = null

  /**
   * Constructor for this class.  Sets up local variables.  Request key is
   * used as the thunk name for Redux purposes.  It is also used to namespace
   * abort controllers object in CRM middleware.
   * @param {CRMBaseMiddlewareFactory} middlewareFactory - A middleware factory
   * which is going to use this instance
   */
  constructor(middlewareFactory) {
    this.#middlewareFactory = middlewareFactory
    this.#crmId = this.#middlewareFactory.getCrmId()
    this.#requestKey = `${this.#crmId}/getPipelineStages`
  }

  /**
   * Function to get the portion of the middleware for managing this particular
   * request.  This will be used by the top-level CRM middleware to compose a
   * large middleware object.
   * @returns {Object} - Middleware to manage this request
   */
  getRequestMiddleware() {
    return {
      key: this.#requestKey,
      action: this.#computeAction(),
      abortAllRequests: this.#computeAbortAllRequests(),
      pending: this.#computePendingState(),
      fulfilled: this.#computeFulfilledState(),
      rejected: this.#computeRejectedState(),
    }
  }

  /**
   * Returns a function to initiate a search in CRM.  The thunk itself expects
   * an object as a parameter with two keys: query and objects, which is an
   * array of object names across which to search
   * @return {Object} - Output of reduxjs/toolkit/createAsyncThunk() function
   */
  #computeAction() {
    let thunk = async (
      { pipelineId, callerId },
      { requestId, getState, rejectWithValue }
    ) => {
      let crmState = getState().crm?.[this.#crmId]

      let storedPipeline = crmState?.pipelines?.data?.find(
        (pipeline) => pipeline.id === pipelineId
      )

      let isStageListAvailable =
        storedPipeline?.stages?.status === EntityStatus.Loaded &&
        storedPipeline?.stages?.data instanceof Array

      let opportunityName = this.#middlewareFactory
        .getPrimaryObjectNames()
        ?.find((object) => object.isOpportunity).name

      let record = null

      if (this.#crmId === 'pipedrive') {
        record = { data: { pipeline_id: pipelineId } }
      } else if (this.#crmId === 'hubspot') {
        record = { properties: { pipeline: pipelineId } }
      }

      try {
        const pipeline = isStageListAvailable
          ? storedPipeline.stages.data
          : await crmApi.getObjectSchema(this.#crmId, opportunityName, record)
        return pipeline
      } catch (e) {
        console.warn(`${this.#requestKey}: Error while fetching schema`, e)
      }
    }

    // Create payload only when another request already does not exist
    let condition = {
      condition: ({ pipelineId }, { getState }) => {
        const pipeline = getState().crm?.[this.#crmId]?.pipelines?.data?.find(
          (x) => x.id === pipelineId
        )
        if (
          pipeline &&
          (pipeline.stages?.status === EntityStatus.Loading ||
            pipeline.stages?.status === EntityStatus.Loaded)
        ) {
          return false
        }
      },
    }

    // Resetting condition
    condition = undefined

    thunk = thunk.bind(this)
    return createAsyncThunk(this.#requestKey, thunk, condition)
  }

  /**
   * Compute a function to abort requests for this particular request key
   * @returns {Function} - Function to abort all requests for this request
   */
  #computeAbortAllRequests() {
    let abortFunction = () => abortAllRequests(this.#requestKey)
    abortFunction.bind(this)
    return abortFunction
  }

  /**
   * Compute a pending state handler required by Redux for handling this request
   * @returns {Function} - Pending state handler
   */
  #computePendingState() {
    let pendingStateHandler = (state, action) => {
      const crmId = this.#crmId
      const pipelineId = action.meta.arg.pipelineId
      // Initialize objects if necessary
      if (state[crmId] === undefined) {
        state[crmId] = { pipelines: { status: EntityStatus.Idle, data: [] } }
      }
      if (state[crmId].pipelines === undefined) {
        state[crmId].pipelines = { status: EntityStatus.Idle, data: [] }
      }
      // Check for availability of pipeline
      let storedPipeline =
        state[crmId].pipelines.data instanceof Array
          ? state[crmId].pipelines.data?.find(
              (pipeline) => pipeline.id === pipelineId
            ) ?? null
          : null

      if (!storedPipeline) {
        state[crmId].pipelines = {
          ...state[crmId].pipelines,
        }
        if (state[crmId].pipelines.data instanceof Array) {
          state[crmId].pipelines.data = [
            ...state[crmId].pipelines.data,
            {
              id: pipelineId,
              stages: { status: EntityStatus.Loading, data: [] },
            },
          ]
        } else {
          state[crmId].pipelines.data = [
            {
              id: pipelineId,
              stages: { status: EntityStatus.Loading, data: [] },
            },
          ]
        }
      }
      if (
        storedPipeline &&
        storedPipeline?.stages?.status !== EntityStatus.Loaded
      ) {
        storedPipeline.stages = {
          status: EntityStatus.Loading,
        }
      }
    }
    pendingStateHandler = pendingStateHandler.bind(this)
    return pendingStateHandler
  }

  /**
   * Compute a fulfilled handler required by Redux for handling this request
   * @returns {Function} - Fulfilled state handler
   */
  #computeFulfilledState() {
    let fulfilledStateHandler = (state, action) => {
      const crmId = this.#crmId
      const pipelineId = action.meta.arg.pipelineId
      let storedPipeline = state[crmId].pipelines.data.find(
        (pipeline) => pipeline.id === pipelineId
      )
      if (storedPipeline?.stages?.status !== EntityStatus.Loaded) {
        storedPipeline.stages = {
          status: EntityStatus.Loaded,
          data: this.#middlewareFactory.getStageListFromSchema(
            crmId === 'pipedrive' ? action.payload.data : action.payload
          ),
        }
      }
    }
    fulfilledStateHandler = fulfilledStateHandler.bind(this)
    return fulfilledStateHandler
  }

  /**
   * Compute a rejected handler required by Redux for handling this request
   * @returns {Function} - Rejected state handler
   */
  #computeRejectedState() {
    let rejectedStateHandler = (state, error) => {
      console.log(error)
      if (!error.meta.aborted) {
        const crmId = this.#crmId
        const pipelineId = error.meta.arg.pipelineId
        let storedPipeline = state[crmId].pipelines.data.find(
          (pipeline) => pipeline.id === pipelineId
        )

        if (storedPipeline?.stages?.status !== EntityStatus.Loaded) {
          storedPipeline.stages = {
            status: EntityStatus.ErrorLoading,
            error: error,
          }
        }
        console.log(`${this.#requestKey}:rejectedStateHandler:`, error)
        throw new Error(error)
      }
    }
    rejectedStateHandler = rejectedStateHandler.bind(this)
    return rejectedStateHandler
  }
}
