import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CrmAPI } from '../api'
import { CrmActivity, CrmActivityLogType, ReduxCrmKey, ReduxCrmState } from '../types'

export interface SearchActivityLogParameter {
  crmKey: ReduxCrmKey
  params: {
    limit?: number
    assigned_to?: string
    sor_record_id?: string
    sor_object_name?: string
    external_url?: string
  }
  type: CrmActivityLogType
}

export interface SearchActivityLogAPIResponse extends LuruAPIResponse {
  data: Array<CrmActivity>
}

export const searchActivityLog = {
  action: createAsyncThunk<
    SearchActivityLogAPIResponse['data'],
    SearchActivityLogParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: SearchActivityLogAPIResponse['metadata']
      rejectedMeta: SearchActivityLogAPIResponse['metadata']
    }
  >('crm/searchActivityLog', async (params, { getState, signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var currEntities = Object.values(getState().crm[params.crmKey]?.activities?.entities || {}).filter(
        (f) => f.data?.external_url === params?.params?.external_url
      )

      if (currEntities.length > 0) {
        return fulfillWithValue(currEntities.map((f) => f.data) as CrmActivity[], null)
      }
      var createdActivity = (await CrmAPI.searchActivityLog(params, {
        signal,
      })) as SearchActivityLogAPIResponse

      return fulfillWithValue(createdActivity.data, createdActivity.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(searchActivityLog.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(searchActivityLog.action.fulfilled, (state, action) => {
      var { crmKey } = action.meta.arg
      const activities = action.payload
      for (let activity of activities) {
        if (activity.activity_id) {
          state[crmKey] = {
            ...state[crmKey],
            activities: {
              ...state[crmKey]?.activities,
              status: EntityStatus.Loaded,
              entities: {
                ...state[crmKey]?.activities?.entities,
                [activity.activity_id]: {
                  data: activity,
                  status: EntityStatus.Loaded,
                },
              },
            },
          }
        }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(searchActivityLog.action.rejected, () => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    searchActivityLog.addPendingCase(builder)
    searchActivityLog.addFulfilledCase(builder)
    searchActivityLog.addRejectedCase(builder)
  },
}
