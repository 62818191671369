import { useEffect } from 'react'
import errorImage from '../images/404_error.svg'

function goBack() {
  if (window.location.href.includes('embedded')) {
    window.location.href = '/pages/embeddednote/index.html'
  } else {
    window.location.href = '/'
  }
}

export default function Error(props) {
  useEffect(() => {
    document.title = 'Luru: Error'
  })

  console.trace(`Error:render:`, props.error)

  return (
    <div className='error-page'>
      <h1>Uh-oh. This is awkward. But even the strongest things break sometimes, right?</h1>
      <p>Please try refreshing the page, sometimes it works. If it does not, do let us know</p>
      {typeof props?.error?.message === 'string' ? <p>{props?.error?.message}</p> : null}
      <img src={errorImage} alt='Error' />
      <button onClick={goBack}>Back</button>
      <pre>
        {props.error
          ? props.error.stack ??
            props.error.cause?.message ??
            props.error.message ??
            props.error.toString?.() ??
            props.error
          : 'Unknown error'}
      </pre>
    </div>
  )
}

export function NotFound({ message }) {
  useEffect(() => {
    document.title = 'Luru: Page not found'
  })

  return (
    <div className='error-page'>
      <h1>Something's wrong here</h1>
      <p>{message ?? 'You might have clicked a broken link or an invalid URL'}</p>
      <img src={errorImage} alt='Error' />
      <button onClick={goBack}>Back</button>
    </div>
  )
}
