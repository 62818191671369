import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { TasksMiddleware } from '../../tasks/middleware'
import { TaskStatus } from '../../tasks/types'
import { EmptyAppState, listOrSearchEmptyState, ReduxAppState } from '../types'

export const tasksSliceListOrSearchTasks = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    tasksSliceListOrSearchTasks.addPendingCase(builder)
    tasksSliceListOrSearchTasks.addFulfilledCase(builder)
    tasksSliceListOrSearchTasks.addRejectedCase(builder)
  },

  addPendingCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      TasksMiddleware.listOrSearchTasks.action.pending,
      (state, action) => {
        if (action.meta.arg.key !== 'tasksHome') {
          // If tasksHome did not initiate this API request, ignore it here
          return
        }

        var keys = []

        if (action.meta.arg?.assigned_to === 'me') {
          if (action.meta.arg?.status === TaskStatus.OPEN) {
            keys.push('mytasks_open')
          } else if (action.meta.arg?.status === TaskStatus.COMPLETED) {
            keys.push('mytasks_completed')
          } else {
            keys.push('mytasks_open')
            keys.push('mytasks_completed')
          }
        } else if (action.meta.arg?.assigned_to === 'notme') {
          if (action.meta.arg?.status === TaskStatus.OPEN) {
            keys.push('notmytasks_open')
          } else if (action.meta.arg?.status === TaskStatus.COMPLETED) {
            keys.push('notmytasks_completed')
          } else {
            keys.push('notmytasks_open')
            keys.push('notmytasks_completed')
          }
        } else {
          keys.push('mytasks_open')
          keys.push('mytasks_completed')
          keys.push('notmytasks_open')
          keys.push('notmytasks_completed')
        }

        var searchOrDefault: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'

        for (let key of keys) {
          let k = key as keyof ReduxAppState['tasks']['default']

          if (action.meta.arg.loadMore === true) {
            state.tasks[searchOrDefault][k].status = EntityStatus.LoadingMore
          } else {
            state.tasks[searchOrDefault][k].status = EntityStatus.Loading
          }
          state.tasks[searchOrDefault][k].error_data = null
        }
      }
    )
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      TasksMiddleware.listOrSearchTasks.action.fulfilled,
      (state, action) => {
        if (action.meta.arg.key !== 'tasksHome') {
          // If tasksHome did not initiate this API request, ignore it here
          return
        }

        var keys = []

        if (action.meta.arg?.assigned_to === 'me') {
          if (action.meta.arg?.status === TaskStatus.OPEN) {
            keys.push('mytasks_open')
          } else if (action.meta.arg?.status === TaskStatus.COMPLETED) {
            keys.push('mytasks_completed')
          } else {
            keys.push('mytasks_open')
            keys.push('mytasks_completed')
          }
        } else if (action.meta.arg?.assigned_to === 'notme') {
          if (action.meta.arg?.status === TaskStatus.OPEN) {
            keys.push('notmytasks_open')
          } else if (action.meta.arg?.status === TaskStatus.COMPLETED) {
            keys.push('notmytasks_completed')
          } else {
            keys.push('notmytasks_open')
            keys.push('notmytasks_completed')
          }
        } else {
          keys.push('mytasks_open')
          keys.push('mytasks_completed')
          keys.push('notmytasks_open')
          keys.push('notmytasks_completed')
        }

        var searchOrDefault: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'
        var resultTaskIds = action.payload.map((task) => task.task_id)

        if (!state.tasks[searchOrDefault]) {
          if (searchOrDefault === 'search') {
            state.tasks.search = { ...EmptyAppState.tasks.search }
          } else {
            state.tasks.default = { ...EmptyAppState.tasks.default }
          }
        }

        for (let key of keys) {
          let k = key as keyof ReduxAppState['tasks']['default']

          if (!state.tasks[searchOrDefault][k]) {
            state.tasks[searchOrDefault][k] = { ...listOrSearchEmptyState }
          }

          state.tasks[searchOrDefault][k].status = EntityStatus.Loaded
          state.tasks[searchOrDefault][k].error_data = null
          state.tasks[searchOrDefault][k].cursor = action.meta?.cursor ?? null

          if (action.meta.arg.loadMore) {
            // Remove duplicates and append
            resultTaskIds = resultTaskIds.filter(
              (taskId) =>
                !state.tasks[searchOrDefault][k]?.results?.includes(taskId)
            )
            state.tasks[searchOrDefault][k].results?.push(...resultTaskIds)
          } else {
            // Replace results
            state.tasks[searchOrDefault][k].results = resultTaskIds
          }
        }
      }
    )
  },

  addRejectedCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      TasksMiddleware.listOrSearchTasks.action.rejected,
      (state, action) => {
        if (action.meta.arg.key !== 'tasksHome') {
          // If tasksHome did not initiate this API request, ignore it here
          return
        }

        var keys = []

        if (action.meta.arg?.assigned_to === 'me') {
          if (action.meta.arg?.status === TaskStatus.OPEN) {
            keys.push('mytasks_open')
          } else if (action.meta.arg?.status === TaskStatus.COMPLETED) {
            keys.push('mytasks_completed')
          } else {
            keys.push('mytasks_open')
            keys.push('mytasks_completed')
          }
        } else if (action.meta.arg?.assigned_to === 'notme') {
          if (action.meta.arg?.status === TaskStatus.OPEN) {
            keys.push('notmytasks_open')
          } else if (action.meta.arg?.status === TaskStatus.COMPLETED) {
            keys.push('notmytasks_completed')
          } else {
            keys.push('notmytasks_open')
            keys.push('notmytasks_completed')
          }
        } else {
          keys.push('mytasks_open')
          keys.push('mytasks_completed')
          keys.push('notmytasks_open')
          keys.push('notmytasks_completed')
        }

        let searchOrDefault: 'search' | 'default' = action.meta.arg.q
          ? 'search'
          : 'default'

        if (!state.tasks[searchOrDefault]) {
          if (searchOrDefault === 'search') {
            state.tasks.search = { ...EmptyAppState.tasks.search }
          } else {
            state.tasks.default = { ...EmptyAppState.tasks.default }
          }
        }

        for (let key in keys) {
          let k = key as keyof ReduxAppState['tasks']['default']

          state.tasks[searchOrDefault][k] = {
            ...state.tasks[searchOrDefault][k],
            status: EntityStatus.ErrorLoading,
            error_data: {
              message: action.error.message ?? 'Error listing tasks',
            },
          }
        }
      }
    )
  },
}
