// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { TasksAPI } from '../api'
import {
  ReduxTaskEntity,
  ReduxTasksState,
  TaskAssignee,
  TaskConnectionSpec,
  TaskPriority,
  TaskStatus,
} from '../types'

const requestName = 'tasks/createTask'

export interface CreateTaskParameter {
  title: string
  status?: TaskStatus
  body?: string
  due_date?: string
  assigned_to?: TaskAssignee | null
  priority?: TaskPriority
  connections?: Array<TaskConnectionSpec>
}

interface CreateTaskAPIResponse extends LuruAPIResponse {
  data: ReduxTaskEntity
}

export const createTask = {
  action: createAsyncThunk<
    CreateTaskAPIResponse['data'],
    CreateTaskParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: null | CreateTaskAPIResponse['metadata']
      rejectedMeta: null | CreateTaskAPIResponse['metadata']
    }
  >(
    requestName,
    async (
      params: CreateTaskParameter,
      { fulfillWithValue, rejectWithValue, signal }
    ) => {
      try {
        var response: CreateTaskAPIResponse = await TasksAPI.createTask(
          params,
          { signal }
        )
        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(createTask.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(createTask.action.fulfilled, (state, action) => {
      var task = action.payload

      state.entities[task.task_id] = {
        data: task,
        status: EntityStatus.Loaded,
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(createTask.action.rejected, (state, action) => {})
  },

  // TODO: We can do away with this after standardization
  addAllCases(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    createTask.addPendingCase(builder)
    createTask.addRejectedCase(builder)
    createTask.addFulfilledCase(builder)
  },
}
