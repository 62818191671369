import json5 from 'json5'

export default function getNoteIsEmtpy(noteB: string) {
  let isEmpty = false

  if (!noteB) {
    return false
  }

  try {
    let noteBJson = json5.parse(noteB)

    if (noteBJson.length === 1 && noteBJson?.[0]?.data?.length === 0) {
      return (isEmpty = true)
    }
  } catch (error) {
    isEmpty = false
    console.warn(error)
  }

  return isEmpty
}
