import { RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'

export const getNoteDetail =
  (request: string | Array<string>) => (state: RootState) => {
    if (typeof request === 'string') {
      request = [request]
    }

    if (!Array.isArray(request)) {
      return null
    }

    var result = []

    for (let noteId of Object.keys(state.notes?.entities)) {
      if (
        state.notes.entities[noteId] &&
        state.notes.entities[noteId]?.status === EntityStatus.Loaded &&
        request.includes(noteId)
      ) {
        let item = state.notes.entities[noteId]?.data

        if (item) {
          result.push({
            note_id: item.note_id,
            title: item.title,
            created_at: item.created_at,
            updated_at: item.updated_at,
          })
        }
      }
    }
    return result
  }
