import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { RecentEntityType, ReduxUserState } from '../types'

export const noteTemplatesSliceUpdateTitle: CaseReducer<
  ReduxUserState,
  PayloadAction<{ noteTemplateId: string; title: string }>
> = (state, action) => {
  var { noteTemplateId, title } = action.payload
  var recentItem = state.recentItems?.find(
    (item) =>
      item.id === noteTemplateId && item.type === RecentEntityType.TEMPLATE
  )

  if (recentItem) {
    recentItem.name = title
  }
}
