import { listMeetings } from './listMeetings'
import { searchMeetings } from './searchMeetings'
import { fetchMeeting } from './fetchMeeting'

//Zoom meetings middlewares
import { listZoomMeetings } from './listZoomMeetings'

export const MeetingsMiddleware = {
  listMeetings,
  searchMeetings,
  fetchMeeting,
  listZoomMeetings,
}
