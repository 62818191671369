import React from 'react'
import ReactDom from 'react-dom'
import update from 'immutability-helper'
import CrmObjectSelectBox from '../CrmObjectSelectBox'
import LuruButton from '../../../ui/LuruButton'
import CrmMultiObjectSelectBoxEventHandler from './CrmMultiObjectSelectBoxEventHandler'
import Utils from '../../../../utils/Utils'
import styles from './styles.module.css'
// Icons
import addIcon from '../../../../images/fluent/add.svg'
import removeIcon from '../../../../images/fluent/remove.svg'
import { CrmMultiObjectSelectBoxConnectedProps } from '.'

export interface ValuesType {
  sor_object_name: string
  sor_record_id: string
  sor_record_name: string
}

export interface CrmMultiObjectSelectBoxComponentProps extends CrmMultiObjectSelectBoxConnectedProps {
  sorObjectName: string
  values: Array<ValuesType>
  description?: string
  dialogTitle?: string
  dialogDescription?: string
  disabled?: boolean
  fieldName?: string
  classes?: Array<string>
  chipClassName?: string
  modalClass?: string
  luruRole?: string
  readOnly?: boolean
  menuParentSelector?: string
  emptyNode?: React.ReactNode
  doNotResetOnCancel?: boolean
  onFinishSelection: (values: ValuesType[]) => void
}

export interface CrmMultiObjectSelectBoxComponentState {
  values: Array<ValuesType>
  visible: boolean
  warningMessage?: string
}

export default class CrmMultiObjectSelectBoxComponent extends React.Component<CrmMultiObjectSelectBoxComponentProps> {
  props: CrmMultiObjectSelectBoxComponentProps
  state: CrmMultiObjectSelectBoxComponentState
  eventHandler: CrmMultiObjectSelectBoxEventHandler

  constructor(props: CrmMultiObjectSelectBoxComponentProps) {
    super(props)
    this.props = props
    this.state = {
      visible: false,
      values:
        this.props.values.length > 0
          ? this.props.values.filter(
              (v) =>
                Object.hasOwn(v, 'sor_record_id') &&
                Object.hasOwn(v, 'sor_record_name') &&
                Object.hasOwn(v, 'sor_object_name')
            )
          : this.#getInitValues(),
      warningMessage: '',
    }
    this.eventHandler = new CrmMultiObjectSelectBoxEventHandler(this)
  }

  componentDidUpdate() {
    if (this.state.values.length === 0) {
      this.setState((prevState) => update(prevState, { values: { $set: this.#getInitValues() } }))
    }
  }

  #getInitValues() {
    return [
      {
        sor_object_name: this.props.sorObjectName,
        sor_record_id: '',
        sor_record_name: '',
      },
    ]
  }

  showDialog = () => {
    this.setState({ visible: true })
  }

  hideDialog = () => {
    this.setState({ visible: false })
  }

  render() {
    const { values, visible } = this.state
    const { fieldName, dialogTitle, dialogDescription } = this.props
    const component = (
      <div
        className={[styles.parent, this.state.visible ? null : styles.minimized, this.props.modalClass || ''].join(' ')}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        data-luru-role={this.props.luruRole}
      >
        <div className={styles.dialog}>
          <div className={styles.title}>{dialogTitle}</div>
          <div className={styles.description}>{dialogDescription}</div>
          {this.state.warningMessage && <div className={styles.warningMessage}>{this.state.warningMessage}</div>}
          <div className={styles.content}>
            {values.map((val, index) => {
              return (
                <CrmObjectSelectRow
                  index={index}
                  key={val.sor_record_id || index.toString()}
                  sorObjectName={this.props.sorObjectName}
                  onSelectItem={(sorRecordId, sorRecordName, srcElement, data) =>
                    this.eventHandler.handlers.onSelectItem(sorRecordId, sorRecordName, srcElement, data, index)
                  }
                  value={val.sor_record_name}
                  fieldName={fieldName}
                  onClickRemove={this.eventHandler.handlers.onClickRemove}
                  onClickAdd={this.eventHandler.handlers.onClickAdd}
                />
              )
            })}
          </div>
          {this.#renderActionButtons()}
        </div>
      </div>
    )

    return (
      <>
        {this.#renderSummary()}
        {visible && <CrmMultiObjectSelectBoxComponentPortal>{component}</CrmMultiObjectSelectBoxComponentPortal>}
      </>
    )
  }

  /**
   * Render summary view
   */
  #renderSummary() {
    var { values } = this.state

    /** Remove the artificial empty item while rendering summary */
    values = values.filter((item) => item.sor_record_id)

    const numChips = 10
    var chips = values
      .slice(0, numChips)
      .map((item, ix) => <Chip key={ix} title={item.sor_record_name} chipClassName={this.props.chipClassName} />)
    var classNames = [styles.summaryTextContainer]

    if (Array.isArray(this.props.classes)) {
      classNames = classNames.concat(this.props.classes)
    }

    if (values.length > numChips) {
      chips.push(<Chip key={numChips + 1} title={`${values.length - numChips} more`} />)
    }

    return (
      <div
        className={classNames.join(' ')}
        onClick={this.props.readOnly || this.props.disabled ? () => {} : this.eventHandler.handlers.onClickSummaryText}
      >
        {chips.length > 0 ? chips : this.props.emptyNode ?? 'No values set'}
      </div>
    )
  }

  /**
   * Render action buttons within the modal (expanded view)
   */
  #renderActionButtons() {
    return (
      <div className={styles.buttons}>
        <LuruButton title='Cancel' variant='outline' onClick={this.eventHandler.handlers.onClickCancelButton}>
          Cancel
        </LuruButton>
        <LuruButton title='Select items' onClick={this.eventHandler.handlers.onClickOkButton}>
          OK
        </LuruButton>
      </div>
    )
  }
}

export interface CrmObjectSelectRowProps {
  value?: string
  fieldName?: string
  sorObjectName: string
  index?: number
  onSelectItem?: (sorRecordId: string, sorRecordName: string, srcElement: HTMLElement, data?: any) => void
  onClickRemove?: (index: number) => void
  onClickAdd?: (index: number) => void
}

class CrmObjectSelectRow extends React.Component<CrmObjectSelectRowProps> {
  props: CrmObjectSelectRowProps

  constructor(props: CrmObjectSelectRowProps) {
    super(props)
    this.props = props
  }

  #renderAddButton() {
    return (
      <button onClick={() => this.props.onClickAdd?.(this.props.index || 0)}>
        <img src={addIcon} alt='add' />
      </button>
    )
  }

  #renderRemoveButton() {
    return (
      <button onClick={() => this.props.onClickRemove?.(this.props.index || 0)}>
        <img src={removeIcon} alt='remove' />
      </button>
    )
  }
  render() {
    const { fieldName, value } = this.props
    return (
      <div>
        <label>{Utils.capitalizeString(fieldName)}</label>
        <div className={styles.crmObjectSelectRow}>
          <div style={{ marginRight: 10 }}>
            <CrmObjectSelectBox
              sorObjectName={this.props.sorObjectName}
              onSelectItem={(...arg) => this.props.onSelectItem?.(...arg)}
              value={value}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', flex: 'auto' }}>
            <div style={{ marginRight: 10 }}>{this.#renderRemoveButton()}</div>
            <div>{this.#renderAddButton()}</div>
          </div>
        </div>
      </div>
    )
  }
}

class CrmMultiObjectSelectBoxComponentPortal extends React.Component<{
  children: JSX.Element
}> {
  props: {
    children: JSX.Element
  }

  constructor(props: { children: JSX.Element }) {
    super(props)
    this.props = props
  }

  render() {
    var rootElement = document.getElementById('root') || document.body
    return ReactDom.createPortal(this.props.children, rootElement)
  }
}

const Chip = ({ title, chipClassName }: { title: string; chipClassName?: string }) => (
  <div className={[styles.chipsContainer, chipClassName].join(' ')} title={title}>
    {title}
  </div>
)
