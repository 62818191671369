// React
import React from 'react'

// Redux
import { TasksMiddleware } from '../../features/tasks/middleware'
import { connect } from 'react-redux'

// Own components
// import Modal from '../../primitives/Modal'
// import LuruButton from '../../primitives/LuruButton'
import ModalScreen from '../../primitives/ModalScreen'
import CRMRecordChooser from '../../primitives/CRMRecordChooser'
import RecordChooser from '../../primitives/RecordChooser'
import CrmRecord from '../../domain/crmRecord/CrmRecord'

// Own functions
import { trackEvent } from '../../analytics/Ga'

// Styles
import styles from '../../layout/css/TaskView.module.css'
import LuruUser from '../../domain/users/LuruUser'
import LuruButton from '../../primitives/ui/LuruButton'

//Icons
import uncheckedIcon from '../../../src/images/fluent/unchecked_circle.svg'
import checkedIcon from '../../../src/images/fluent/checkmark_circle.svg'
// import Utils from '@/utils/Utils'
// import { ToastId } from '@/app_ui/types'

const CRMObjectMap = {
  SFDC: ['Opportunity', 'Account', 'Contact', 'Lead'],
  HUBSPOT: ['deals', 'companies', 'contacts'],
  PIPEDRIVE: ['deal', 'organization', 'person', 'lead'],
}

class TaskViewModal extends React.Component {
  onSuccessCallback = undefined
  #modalRef = React.createRef()

  constructor(props) {
    super(props)

    // Bind methods
    this.initForTask = this.initForTask.bind(this)
    this.parseConnections = this.parseConnections.bind(this)
    // this.buildConnections = this.buildConnections.bind(this)
    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePriorityChange = this.handlePriorityChange.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleAssignedToChange = this.handleAssignedToChange.bind(this)
    this.handleDueDateChange = this.handleDueDateChange.bind(this)
    this.handleBodyChange = this.handleBodyChange.bind(this)

    // Refs (to focus on specific field when modal is opened)
    this.titleRef = React.createRef()
    this.assignedToRef = React.createRef()
    this.dueDateRef = React.createRef()
    this.linkedCRMRecordRef = React.createRef()

    this.task = props.task ?? null
    this.linkedCRMRecord = this.parseConnections(this.task?.sor, this.task?.connections)

    this.state = {
      show: props.show ?? false, // Modal hidden or visible?
      creatingOrUpdating: false, // Set to true when user clicks on Create/Update
      title: this.task?.title ?? null,
      body: this.task?.body ?? null,
      status: this.task?.status ?? null,
      priority: this.task?.priority ?? 'NORMAL',
      due_date: this.task?.due_date ?? null,
      assigned_to: this.task?.assigned_to ?? null,
      linkedCRMRecord: this.linkedCRMRecord,
      successMessage: '',
      warningMessage: '',
    }
  }

  initForTask(task = null, connection = null) {
    this.task = task
    this.linkedCRMRecord = this.parseConnections(this.task?.sor, this.task?.connections)
    this.setState({
      title: this.task?.title ?? null,
      body: this.task?.body ?? null,
      status: this.task?.status ?? null,
      priority: this.task?.priority ?? 'NORMAL',
      due_date: this.task?.due_date ?? null,
      assigned_to: this.task?.assigned_to ?? null,
      linkedCRMRecord: this.linkedCRMRecord || connection,
    })
  }

  // Parse the connections array of a task and extract linked CRM record
  // Returns the first valid connection
  parseConnections(sor, connections) {
    if (!connections || connections.length === 0) {
      return null
    }
    // Return the first connection that links to an allowed object type
    for (let conn of connections) {
      if (CRMObjectMap[sor].includes(conn.sor_object_name)) {
        return conn
      }
    }
    return null
  }

  // Build the Task object which can be passed on the the task middleware based on
  // what form parameters have been added / changed by the user
  buildSubmitTask() {
    var t = {}
    var taskProps = ['title', 'priority', 'assigned_to', 'due_date', 'body']

    taskProps.forEach((p) => this.state[p] !== this.task?.[p] && (t[p] = this.state[p]))

    if (this.hasConnectionChanged()) {
      t.connections = this.state.linkedCRMRecord ? [{ ...this.state.linkedCRMRecord }] : []
    }

    return t
  }

  hasConnectionChanged() {
    return (
      this.state.linkedCRMRecord?.sor_record_id !== this.linkedCRMRecord?.sor_record_id ||
      this.state.linkedCRMRecord?.sor_object_name !== this.linkedCRMRecord?.sor_object_name
    )
  }

  // Close the modal
  closeModal() {
    this.#modalRef.current?.cancel()
  }

  /**
   * This displays the modal.
   * @param {*} task
   * @param {*} onSuccessCallback
   * @param {{sor_object_name:string,sor_record_id:string,sor_record_name:string}} connection
   */
  showModal(task = null, onSuccessCallback = undefined, connection = null) {
    this.onSuccessCallback = onSuccessCallback
    trackEvent('task_modal_show', '')
    // this.setState({ show: true })
    this.#modalRef.current?.showModal({
      cancel: () => trackEvent('task_modal_hide', ''),
    })
    this.initForTask(task, connection)
    this.titleRef.current?.focus()
  }

  componentDidMount() {
    this.titleRef.current?.focus()
    // If its the first time this component is loading, defaultAssignees list
    // be empty; So start an API call to fetch it
    if (
      this.props.defaultAssignees === null ||
      this.props.defaultAssignees === undefined ||
      Object.values(this.props.defaultAssignees).length === 0
    ) {
      this.props.dispatch(TasksMiddleware.listOrSearchAssignees.action({}))
    }
  }

  handlePriorityChange(ev) {
    this.setState({ priority: ev.target.value })
  }

  handleTitleChange(ev) {
    this.setState({ title: ev.target.value })
  }

  handleBodyChange(ev) {
    this.setState({ body: ev.target.value })
  }

  handleDueDateChange(ev) {
    this.setState({ due_date: ev.target.value })
  }

  handleAssignedToChange(ev) {
    this.setState({ assigned_to: ev.target.value })
  }

  // When the submit button is clicked
  handleSubmit(event) {
    event.preventDefault() // So that the browser form submit doesn't happen and reload
    let t = this.buildSubmitTask()
    if (!this.task) {
      this.createTask(t)
    } else {
      this.updateTask(t)
    }
  }

  createTask(t) {
    // Create new task
    this.setState({ creatingOrUpdating: true })

    const createTaskPromise = this.props.dispatch(
      TasksMiddleware.createTask.action({
        title: t.title,
        status: 'OPEN',
        body: t.body,
        due_date: t.due_date,
        assigned_to: t.assigned_to,
        priority: t.priority,
        connections: t.connections,
      })
    )

    createTaskPromise
      .unwrap()
      .then((response) => {
        this.setState({
          creatingOrUpdating: false,
          successMessage: 'Tasks created succesfully',
        })
        // this.props?.toast.showToast?.({
        //   id: ToastId.TASKS_TOAST_ID,
        //   message: 'Task created successfully',
        //   severity: 'success',
        // })
        setTimeout(() => {
          this.closeModal()
          this.setState({
            successMessage: '',
          })
        }, 3000)
      })
      .catch((error) => {
        this.setState({
          creatingOrUpdating: false,
          warningMessage: error.message ?? 'Failed to create task',
        })
        // this.props?.toast.showToast?.({
        //   id: ToastId.TASKS_TOAST_ID,
        //   message: error.message ?? 'Failed to create task',
        //   severity: 'error',
        // })
        setTimeout(() => {
          this.setState({
            warningMessage: '',
          })
        }, 3000)
      })
  }

  // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-results
  updateTask(t) {
    trackEvent('update_task')
    this.setState({ creatingOrUpdating: true })
    this.props
      .dispatch(
        TasksMiddleware.updateTask.action({
          ...t,
          task_id: this.task.task_id ?? this.task.taskId,
        })
      )
      .unwrap()
      .then((response) => {
        this.setState({ creatingOrUpdating: false, successMessage: 'Task updated successfully' })
        // this.props?.toast.showToast?.({
        //   id: ToastId.TASKS_TOAST_ID,
        //   message: 'Task updated successfully',
        //   severity: 'success',
        // })
        setTimeout(() => {
          this.closeModal()
          this.setState({ successMessage: '' })
        }, 3000)

        if (typeof this.onSuccessCallback === 'function') {
          this.onSuccessCallback(response)
        }
      })
      .catch((error) => {
        this.setState({ creatingOrUpdating: false, warningMessage: error?.message ?? 'Task updation failed' })
        // console.warn(error)
        // this.props?.toast.showToast?.({
        //   id: ToastId.TASKS_TOAST_ID,
        //   message: error.message,
        //   severity: 'error',
        // })
        setTimeout(() => {
          this.setState({ warningMessage: '' })
        }, 3000)
      })
  }

  renderPriorityRadio(value, labelValue) {
    let button = ''
    if (this.state.priority === value) {
      // Display checked radio
      let callback = (ev) => {
        this.setState({ priority: value })
      }
      button = <img src={checkedIcon} onClick={callback} alt='checked' />
    } else {
      // Display un-checked radio
      let callback = (ev) => {
        this.setState({ priority: value })
      }
      button = <img src={uncheckedIcon} onClick={callback} alt='unchecked' />
    }
    return (
      <label className={styles.taskViewRadioValue}>
        {button}
        &nbsp;
        {labelValue}
      </label>
    )
  }

  renderPriority() {
    return (
      <div className={styles.taskViewProperty}>
        <label className={styles.taskViewLabel}>Priority</label>
        <div className={styles.taskViewValuePriority}>
          {this.renderPriorityRadio('LOW', 'Low')}
          {this.renderPriorityRadio('NORMAL', 'Normal')}
          {this.renderPriorityRadio('HIGH', 'High')}
        </div>
      </div>
    )
  }

  renderTitle() {
    return (
      <div className={styles.taskViewProperty}>
        <label className={styles.taskViewLabel}>Title</label>
        <input
          ref={this.titleRef}
          className={styles.taskViewValue}
          type='text'
          value={this.state.title ?? ''}
          onChange={this.handleTitleChange}
        />
      </div>
    )
  }

  toDisplayRecordId(sor_object_name, sor_record_id) {
    return sor_object_name + '__' + sor_record_id
  }

  fromDisplayRecordId(itemId) {
    let [sor_object_name, sor_record_id] = itemId.split('__')
    return [sor_object_name, sor_record_id]
  }

  assigneeToDisplayRecord(assignee) {
    if (assignee === null || assignee === undefined) {
      return null
    }

    return {
      name: assignee.sor_record_name,
      id: this.toDisplayRecordId(assignee.sor_object_name, assignee.sor_record_id),
      choosable: true,
    }
  }

  assigneesToDisplayRecords(assignees) {
    if (assignees === null || assignees === undefined) {
      return null
    }

    return assignees.map((a) => this.assigneeToDisplayRecord(a.data ?? a))
  }

  renderAssignedTo() {
    let onChooseUser = (itemData) => {
      const [sor_object_name, sor_record_id] = this.fromDisplayRecordId(itemData.id)

      let record = {
        sor_object_name: sor_object_name,
        sor_record_id: sor_record_id,
        sor_record_name: itemData.name,
      }
      this.setState({ assigned_to: record })
    }

    let onRemoveUser = (removedRecord) => {
      console.log(`Setting assigned_to to null`)
      this.setState({ assigned_to: null })
    }

    let search = async (params) => {
      let args = {
        q: params.query,
      }
      return this.props.dispatch(TasksMiddleware.listOrSearchAssignees.action(args)).then((response) => {
        return this.assigneesToDisplayRecords(response.payload)
      })
    }

    return (
      <div className={styles.taskViewProperty} data-luru-role='task-assignee'>
        <label className={styles.taskViewLabel}>Assigned to</label>
        <RecordChooser
          linkedRecord={this.assigneeToDisplayRecord(this.state.assigned_to)}
          key={`generic-record-chooser`}
          onChooseRecord={onChooseUser}
          onRemoveLink={onRemoveUser}
          readonly={false}
          searchBoxMessage='Search users'
          recordType='user'
          search={search}
          clearSearch={undefined}
          defaultRecords={this.assigneesToDisplayRecords(Object.values(this.props.defaultAssignees))}
          linkingTooltip='Default: Myself'
        />
      </div>
    )
  }

  renderDueDate() {
    return (
      <div className={styles.taskViewProperty}>
        <label className={styles.taskViewLabel}>Due on</label>
        <input
          ref={this.dueDateRef}
          className={styles.taskViewValue}
          type='date'
          value={this.state.due_date ?? ''}
          onChange={this.handleDueDateChange}
        />
      </div>
    )
  }

  renderLinkedCRMRecord() {
    var onChooseCRMRecord = (itemData) => {
      var record = {
        sor_object_name: itemData.sorObjectName,
        sor_record_id: itemData.sorRecordId,
        sor_record_name: itemData.sorRecordName,
      }
      this.setState({ linkedCRMRecord: record })
    }
    var onRemoveCRMRecord = (removedRecord) => {
      this.setState({ linkedCRMRecord: null })
    }

    return (
      <div className={styles.taskViewProperty} data-luru-role='task-crm-link'>
        <label className={styles.taskViewLabel}>Record</label>
        <CRMRecordChooser
          linkedCRMRecord={this.state.linkedCRMRecord}
          key={`generic-crm-record-link-control`}
          onChooseRecord={onChooseCRMRecord}
          onRemoveLink={onRemoveCRMRecord}
          readonly={false}
        />
      </div>
    )
  }

  renderBody() {
    return (
      <div className={styles.taskViewProperty}>
        <label className={styles.taskViewLabel}>Description</label>
        <textarea
          className={styles.taskViewBodyValue}
          value={this.state.body ?? ''}
          onChange={this.handleBodyChange}
          rows='4'
          columns='400'
        />
      </div>
    )
  }

  renderSuccessMessage() {
    return <div className={styles.taskSuccessMessage}>{this.state.successMessage}</div>
  }

  renderWarningMessage() {
    return <div className={styles.taskWarningMessage}>{this.state.warningMessage}</div>
  }

  render() {
    const { linkedCRMRecord } = this.state
    var img = null
    const crmId = LuruUser.getCurrentUserCrmName()

    if (linkedCRMRecord && linkedCRMRecord.sor_object_name) {
      let crmRecordType = CrmRecord.getCrmRecordType(linkedCRMRecord.sor_object_name)
      const crmRecordIcon = CrmRecord.getIcon(crmRecordType)
      img = (
        <img
          src={crmRecordIcon}
          alt={linkedCRMRecord.sor_object_name}
          data-object-name={crmRecordType}
          data-crm-id={crmId}
        />
      )
    }

    var modalTitle = (
      <div className={styles.modalTitle}>
        {img ? <span className={styles.imgContainer}>{img}</span> : null}
        <span>{this.task ? 'Update task' : 'Create task'}</span>
      </div>
    )

    return (
      <ModalScreen
        ref={this.#modalRef}
        title={modalTitle}
        titleCloseButton={true}
        height='fit-content'
        hideButtons={true}
        dialogRole='taskViewModal'
        className={styles.taskViewDialog}
        width='45%'
      >
        <div className={styles.taskView}>
          {/* <div className={styles.taskViewHeader}>
            {this.task ? 'Update task' : 'Create task'}
          </div> */}
          <div className={styles.taskViewBody}>
            <div id='taskview'>
              {/* Render meessage */}
              {this.renderSuccessMessage()}
              {this.renderWarningMessage()}

              {/* Priority */}
              {this.renderPriority()}

              {/* Title */}
              {this.renderTitle()}

              {/* AssignedTo */}
              {this.renderAssignedTo()}

              {/* Due Date */}
              {this.renderDueDate()}

              {/* CRM Record */}
              {this.renderLinkedCRMRecord()}

              {/* Body */}
              {this.renderBody()}

              <div className={styles.taskViewFooter}>
                <LuruButton variant='outline' onClick={this.closeModal} extraClassNames={[styles.cancelButton]}>
                  Cancel
                </LuruButton>
                <LuruButton
                  title={this.task ? 'Update task' : 'Create task'}
                  loading={this.state.creatingOrUpdating}
                  onClick={this.handleSubmit}
                  role='create-task'
                >
                  {this.state.creatingOrUpdating
                    ? this.task
                      ? 'Updating'
                      : 'Creating'
                    : this.task
                    ? 'Update'
                    : 'Create'}
                </LuruButton>
                {/* <button
                        data-luru-role='cancel-button'
                        onClick={this.closeModal}
                      >
                        Cancel
                      </button> */}
                {/* <button
                  type='button'
                  data-luru-role='ok-button'
                  disabled={this.state.creatingOrUpdating}
                  onClick={this.handleSubmit}
                >
                  {this.state.creatingOrUpdating
                    ? this.task
                      ? 'Updating'
                      : 'Creating'
                    : this.task
                    ? 'Update'
                    : 'Create'}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </ModalScreen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    defaultAssignees: state.tasks.assignees.entities,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(TaskViewModal)
