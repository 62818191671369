import { createPortal } from 'react-dom'
import useAppUIState from '../hooks/useAppUIState'
import useAppUIStateHandlers from '../hooks/useAppUIStateHandlers'
import styles from './Toast.module.css'
import Utils from '@/utils/Utils'
import infoIcon from '@/images/fluent/info.svg'
import successIcon from '@/images/fluent/checkmark.svg'
import errorIcon from '@/images/fluent/error.svg'
import warningIcon from '@/images/fluent/warning.svg'
import closeIcon from '@/images/fluent/close.svg'
import LoadingSpinner from '@/primitives/LoadingSpinner'

export default function Toast() {
  const { toast } = useAppUIState()

  const { hideToast } = useAppUIStateHandlers()

  return createPortal(
    <div className={styles.toastContainer}>
      {Object.entries(toast).map(([toastId, toast]) => (
        <div
          id={`toast-${toastId}`}
          key={toastId}
          className={Utils.classes(styles.toastMessage, !toast.isOpen && styles.closed, styles[toast.severity])}
          data-isloading={toast.isLoading}
        >
          {toast.isLoading ? (
            <LoadingSpinner size={8} color='white' />
          ) : (
            <button>
              <img
                src={
                  toast.severity === 'success'
                    ? successIcon
                    : toast.severity === 'error'
                    ? errorIcon
                    : toast.severity === 'warning'
                    ? warningIcon
                    : infoIcon
                }
                alt={toast.severity}
              />
            </button>
          )}
          {typeof toast.message === 'string' ? (
            <div className={styles.toastNotificationStyles} dangerouslySetInnerHTML={{ __html: toast.message }}></div>
          ) : (
            toast.message
          )}
          {!toast.isLoading ? (
            <button onClick={() => hideToast(toastId)} data-role='toast-close-button'>
              <img src={closeIcon} alt='Close' />
            </button>
          ) : (
            <button></button>
          )}
        </div>
      ))}
    </div>,
    document.getElementById('luruPortal') ?? document.body
  )
}
