import React, { Component } from 'react'
import { trackEvent } from '../../analytics/Ga'
import LuruButton from '../../primitives/ui/LuruButton'
import styles from './styles.module.css'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../app/store'

const connector = connect(
  (state: RootState) => {
    return {
      userEmail: state?.user?.data?.email,
      userId: state?.user?.data?.user_id,
    }
  },
  null,
  null,
  {
    forwardRef: true,
  }
)

export interface UninstallExtensionProps
  extends ConnectedProps<typeof connector> {}
interface UninstallExtensionState {}

class UninstallExtension extends Component<
  UninstallExtensionProps,
  UninstallExtensionState
> {
  props: UninstallExtensionProps
  state: UninstallExtensionState

  constructor(props: UninstallExtensionProps) {
    super(props)
    this.props = props
    this.state = {}
  }
  componentDidMount(): void {
    const gAlabel = this.props.userEmail
      ? `${this.props.userEmail}-${this.props.userId}`
      : ''
    trackEvent('uninstalled_ext', gAlabel)
  }
  #onClickReinstallExtension = () => {
    const gAlabel = this.props.userEmail
      ? `${this.props.userEmail}-${this.props.userId}`
      : ''
    trackEvent('reInstall_extension_button_clicked', gAlabel)
    window.location.replace(
      'https://chrome.google.com/webstore/detail/luru-meeting-note-taker-f/dkkoajmblennnfhdpoolnnnbcemchabl'
    )
  }

  #onClickTryWebApp = () => {
    const gAlabel = this.props.userEmail
      ? `${this.props.userEmail}-${this.props.userId}`
      : ''
    trackEvent('try_web_app_clicked', gAlabel)
    window.location.replace('/notes')
  }

  render() {
    return (
      <div>
        <div>
          <iframe
            src='https://docs.google.com/forms/d/e/1FAIpQLSfe2Rwrc_rgVWeWIF9hhK385tYKmrl6dzWi8pRG3OFzAyjBhg/viewform?embedded=true'
            width='100%'
            height={550}
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
            title='Uninstall Luru Extension'
          >
            Loading…
          </iframe>
        </div>
        <div className={styles.infoBody}>
          <div>
            <div className={styles.info}>
              <div className={styles.question}>
                Do not want Luru to open every time you open a new tab?
              </div>
              <LuruButton
                onClick={this.#onClickTryWebApp}
                title='Try the web app'
              >
                Try the web app
              </LuruButton>
            </div>
            <div className={styles.info}>
              <div className={styles.question}>Uninstalled by mistake?</div>
              <LuruButton
                onClick={this.#onClickReinstallExtension}
                title='Try the web app'
              >
                Reinstall
              </LuruButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connector(UninstallExtension)
