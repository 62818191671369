import { SearchNotesParameter } from './searchAllNotes'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'

export default async function searchLuruNotes(
  params: Readonly<Partial<SearchNotesParameter>>,
  options: LuruAPIRequestOptions
) {
  var searchParams: Record<string, string> = {
    type: 'luru',
  }

  Object.keys(params).forEach((key) => {
    var k = key as keyof SearchNotesParameter
    if (params[k] !== undefined && params[k] !== null) {
      searchParams[k] = params[k] as string
    }
  })

  delete searchParams.key
  delete searchParams.refreshRequest

  var url = '/api/notes?' + new URLSearchParams(searchParams)
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
