import { CrmObjectName } from '@/features/crm/types'
import { WorkflowBlockType, WorkflowEditorBlock, WorkflowNormalizedSchema, WorkflowSorName } from '../types'

export function getBlockSchemaFromBlockId(
  blockId: string,
  blocks: WorkflowEditorBlock[],
  normalizedWorkflowSchemas: WorkflowNormalizedSchema[]
) {
  const block = blocks?.find((b) => b.id === blockId)
  const wfSor = block?.details?.sor as WorkflowSorName
  const wfObjectName = block?.details?.object_name as CrmObjectName | 'global' | 'Global' | undefined
  const actionOrTriggerType =
    block?.type === WorkflowBlockType.TRIGGER ? block?.details?.trigger_type : block?.details?.action_type
  const blockSchema = normalizedWorkflowSchemas?.find(
    (f) =>
      f.id.toLowerCase?.() === actionOrTriggerType?.toLowerCase?.() &&
      f.workflowSor === wfSor &&
      f.sorObjectName === wfObjectName
  )
  return blockSchema
}

export function isBlockSchemaOutputArrayFromBlockId(
  blockId: string,
  blocks: WorkflowEditorBlock[],
  normalizedWorkflowSchemas: WorkflowNormalizedSchema[]
) {
  const blockSchema = getBlockSchemaFromBlockId(blockId, blocks, normalizedWorkflowSchemas)
  return Boolean(blockSchema?.schema?.output?.records?.type === 'array')
}

export function isBlockSchemaOutputArrayFromBlockSchema(blockSchema?: WorkflowNormalizedSchema) {
  return Boolean(blockSchema?.schema?.output?.records?.type === 'array')
}
