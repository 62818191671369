import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { WorkflowsAPI } from '../api'
import { ReduxWorkflowsState, CreateV2WorkflowParameter, ReduxV2WorkflowEntity } from '../types'

export interface CreateV2WorkflowAPIResponse extends LuruAPIResponse {
  data: ReduxV2WorkflowEntity
}

export const createV2Workflow = {
  action: createAsyncThunk<
    CreateV2WorkflowAPIResponse['data'],
    CreateV2WorkflowParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: CreateV2WorkflowAPIResponse['metadata']
      rejectedMeta: CreateV2WorkflowAPIResponse['metadata']
    }
  >('workflows/createV2Workflow', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await WorkflowsAPI.createV2Workflow(params, {
        signal,
      })) as CreateV2WorkflowAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue({ message: (e as Error)?.message ?? 'Error creating alert' }, null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(createV2Workflow.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(createV2Workflow.action.fulfilled, (state, action) => {
      const workflow = action.payload

      state.entities[workflow.workflow_id] = {
        status: EntityStatus.Loaded,
        data: workflow,
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(createV2Workflow.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    createV2Workflow.addPendingCase(builder)
    createV2Workflow.addFulfilledCase(builder)
    createV2Workflow.addRejectedCase(builder)
  },
}
