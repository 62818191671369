import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { NotesSliceActions } from '../../notes/notesSlice'
import { ReduxAppState } from '../types'

export const notesSliceRemoveNote = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    notesSliceRemoveNote.addFulfilledCase(builder)
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(NotesSliceActions.removeNote, (state, action) => {
      try {
        const noteId = action.payload.noteId
        type tier1Key = 'search' | 'default'
        type tier2Key = 'sorNotes' | 'luruNotes'

        if (state.home.notes?.lastLoadedNoteId === noteId) {
          state.home.notes.lastLoadedNoteId = null
        }

        for (let i in ['search', 'default']) {
          for (let j in ['sorNotes', 'luruNotes']) {
            let t1: tier1Key = i as tier1Key
            let t2: tier2Key = j as tier2Key

            if (state.home[t1]?.[t2]?.results instanceof Array) {
              state.home[t1][t2].results = state.home[t1][t2].results.filter(
                (item) => item !== noteId
              )
            }
          }
        }
      } catch (e) {
        console.warn(e)
      }
    })
  },
}
