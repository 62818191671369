import { CRMProvider, MessagingProvider } from '../../../features/user/types'
import LuruUser from '../../../domain/users/LuruUser'
import {
  AlertDestination,
  AlertType,
  CreateWorkflowParameter,
  WorkflowFilterGroupingOperator,
  WorkflowTrigger,
} from '../types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'

export function getNewWorkflowDraft(): CreateWorkflowParameter {
  var messagingProvider = LuruUser.getCurrentUserMessagingProviderName()
  var crmPrimaryRecordType = CrmRecord.getAllPrimaryObjects()
  var alertType =
    messagingProvider === MessagingProvider.SLACK
      ? AlertType.SLACK_MESSAGE
      : messagingProvider === MessagingProvider.GCHAT
      ? AlertType.GCHAT_MESSAGE
      : AlertType.MSTEAMS_MESSAGE
  return {
    name: 'New workflow',
    description: '',
    sor: LuruUser.getCurrentUserCrmName() as CRMProvider,
    sor_object_name: crmPrimaryRecordType.DEAL,
    actions: [
      {
        type: alertType,
        data: {
          version: 'v1',
          type: AlertDestination.CONVERSATION,
          msg_template: 'Your alert message',
          msg_type: 'REPORT',
          report_desc: '',
          report_config: {
            grouped: false,
            grouped_field: null,
          },
          conversations: [],
          users: null,
          fields: [],
          media_category: null,
          action_buttons: [],
        },
      },
    ],
    evaluation: {
      type: WorkflowTrigger.CREATE_UPDATE,
      // LuruUser.getCurrentUserCrmName() === CRMProvider.HUBSPOT
      //   ? WorkflowTrigger.SCHEDULE
      //   : WorkflowTrigger.CREATE_UPDATE,
      data: {},
      // LuruUser.getCurrentUserCrmName() === CRMProvider.HUBSPOT
      //   ? {
      //       cron_expression: '0 9 * * 1',
      //       tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      //     }
      //   : {},
    },
    filter: {
      version: 'v1',
      data: {
        [WorkflowFilterGroupingOperator.AND]: [
          // @ts-ignore
          {
            [WorkflowFilterGroupingOperator.AND]: [
              {
                object_name: crmPrimaryRecordType.DEAL,
                field: '',
                op: '=',
                value: '',
              },
            ],
          },
          // @ts-ignore
          {
            [WorkflowFilterGroupingOperator.OR]: [
              {
                object_name: crmPrimaryRecordType.DEAL,
                field: '',
                op: '=',
                value: '',
              },
            ],
          },
        ],
      },
    },
  }
}
