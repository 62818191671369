import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { ViewsSliceActions } from '../../views/viewsSlice'
import { EmptyAppState, ReduxAppState } from '../types'

export const viewsSliceInvalidateCache = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(ViewsSliceActions.invalidateCache, (state, action) => {
      state.views = { ...EmptyAppState.views }
    })
  },
}
