// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { ReduxNoteEntity, ReduxNotesState } from '../types'

export type ReadNoteEntityFromReduxParameter = string

export interface ReadNoteEntityFromReduxResponse {
  data: Partial<ReduxNoteEntity> | null
  metadata: null
}

export const readNoteEntityFromRedux = {
  action: createAsyncThunk<
    ReadNoteEntityFromReduxResponse['data'],
    ReadNoteEntityFromReduxParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | ReadNoteEntityFromReduxResponse['metadata']
      rejectedMeta: null | ReadNoteEntityFromReduxResponse['metadata']
    }
  >(
    'notes/readNoteEntityFromRedux',
    async (noteId, { getState, fulfillWithValue }) => {
      return fulfillWithValue(
        getState().notes.entities?.[noteId]?.data ?? null,
        null
      )
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(
      readNoteEntityFromRedux.action.pending,
      (state, action) => {}
    )
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(
      readNoteEntityFromRedux.action.fulfilled,
      (state, action) => {}
    )
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(
      readNoteEntityFromRedux.action.rejected,
      (state, action) => {}
    )
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    readNoteEntityFromRedux.addPendingCase(builder)
    readNoteEntityFromRedux.addFulfilledCase(builder)
    readNoteEntityFromRedux.addRejectedCase(builder)
  },
}
