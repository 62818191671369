import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CollectionsAPI } from '../api'
import {
  CollectionShareInfo,
  ReduxCollectionEntity,
  ReduxCollectionsState,
} from '../types'

export interface ShareCollectionParameter {
  collectionId: string
  shares: Array<CollectionShareInfo>
}

export interface ShareCollectionAPIResponse extends LuruAPIResponse {
  data: ReduxCollectionEntity
}

export const shareCollection = {
  action: createAsyncThunk<
    ShareCollectionAPIResponse['data'],
    ShareCollectionParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: ShareCollectionAPIResponse['metadata']
      rejectedMeta: ShareCollectionAPIResponse['metadata']
    }
  >(
    'collections/shareCollection',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: ShareCollectionAPIResponse =
          await CollectionsAPI.shareCollection(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(shareCollection.action.pending, (state, action) => {
      var { collectionId } = action.meta.arg

      if (!state.entities[collectionId]) {
        state.entities[collectionId] = {
          status: EntityStatus.Loading,
          data: null,
        }
      } else {
        state.entities[collectionId].status = EntityStatus.Loading
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(shareCollection.action.fulfilled, (state, action) => {
      var { collectionId } = action.meta.arg

      state.entities[collectionId] = {
        status: EntityStatus.Loaded,
        data: { ...action.payload },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(shareCollection.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    shareCollection.addPendingCase(builder)
    shareCollection.addFulfilledCase(builder)
    shareCollection.addRejectedCase(builder)
  },
}
