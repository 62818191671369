import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'

export interface FindMatchingCrmRecordsParameter {
  meetingId: string
}

export const findMatchingCrmRecords = (
  { meetingId }: FindMatchingCrmRecordsParameter,
  options?: LuruAPIRequestOptions
) => {
  var url = `/api/meetings/${meetingId}/sor_records`
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
