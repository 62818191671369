import { useAppSelector } from '../../../../app/hooks'
import { EntityStatus } from '../../../../app/types'
import { useCallback } from 'react'
import styles from './CRMCollectionChooser.module.css'

export const CollectionsList = ({
  linkedSorObjectName,
  filterText,
  selectedIndex,
  handleChooseCollection,
}: {
  linkedSorObjectName: string | undefined
  filterText: string
  selectedIndex: number
  handleChooseCollection: (e: Event) => void
}) => {
  const collections = useAppSelector((state) =>
    Object.keys(state.collections.entities)
      .filter(
        (collectionId) =>
          state.collections.entities[collectionId].status ===
            EntityStatus.Loaded &&
          state.collections.entities[collectionId].data?.sor_object_name ===
            linkedSorObjectName &&
          state.collections.entities[collectionId].data?.name
            ?.toLowerCase()
            .includes(filterText.toLowerCase())
      )
      .map((collId) => state.collections.entities[collId]?.data)
      .filter((coll) => coll !== null)
  )

  const handleItemClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      handleChooseCollection(e.nativeEvent)
    },
    [handleChooseCollection]
  )

  return collections.length === 0 ? (
    <>No collections available for {linkedSorObjectName}</>
  ) : (
    <ul
      className={[
        styles.loaded,
        collections.length === 0 ? styles.emptyList : null,
      ].join(' ')}
    >
      {collections.map((coll, ix) => (
        <li
          key={coll?.collection_id}
          className={selectedIndex === ix ? styles.selected : ''}
          onClick={handleItemClick}
          data-collection-result={true}
          data-collection-name={coll?.name}
          data-result-index={ix}
        >
          {coll?.name}
        </li>
      ))}
    </ul>
  )
}
