import { HTTPMethod } from '../../../app/types'
import { getAuthUrl } from '../../../domutils/utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DisconnectO365CalRequestParams } from '../middleware/disconnectO365Cal'

export const disconnectO365Cal = async (params: DisconnectO365CalRequestParams, options: LuruAPIRequestOptions) => {
  var oAuthKey = 'azuread-v2-tenant-oauth2-cal'
  var disconnectUrl = getAuthUrl() + '/disconnect/' + oAuthKey + '/'
  var request = new LuruAPIRequest(disconnectUrl, HTTPMethod.POST, {
    ...options,
    headers: {
      'X-CSRFToken': params.csrfToken ?? '',
    },
    credentials: 'include',
  })

  return request.make()
}
