// React
import React, { ReactNode } from 'react'

// Own components
// import FlatButton from './FlatButton'
import LuruButton, { LuruButtonProps } from './ui/LuruButton'

// Styles
import styles from './css/ModalScreen.module.css'

// icons
import dismissIcon from '../../src/images/fluent/close.svg'

export interface ModalScreenProps {
  // ID for modal
  id?: string
  // Width of modal
  width?: string
  // Height of modal
  height?: string
  // If alignment is centered
  centered?: boolean
  // Title of modal
  title?: ReactNode | string
  // Whether to show close button in title
  titleCloseButton?: boolean
  // Action elements in title
  titleActionElements?: ReactNode
  // Custom attribute to add (for CSS targeting, JS handling, etc.)
  dialogRole?: string
  // Class name for dialog
  className?: string
  // Class name for parent component
  parentClassName?: string
  // Whether to hide the action buttons inside Modal (bottom right)
  hideButtons?: boolean
  // Whether to show modal by default
  doShow?: boolean
  // Content of Modal component
  children: ReactNode
  // Hide cancel button
  hideCancelButton?: boolean
  // Ok button props
  okButtonProps?: Partial<LuruButtonProps>
  okButtonLabel?: string

  // Cancel button props
  cancelButtonProps?: Partial<LuruButtonProps>
  cancelButtonLabel?: string
}

interface ModalScreenState {
  show: boolean
}

interface ModalScreenRefs {
  parent: React.RefObject<HTMLDivElement>
}

interface ModalConfig {
  ok?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  cancel?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export default class ModalScreen extends React.Component<ModalScreenProps, ModalScreenState> {
  #modalConfig: ModalConfig | null = null

  props: ModalScreenProps
  componentRefs: ModalScreenRefs

  constructor(props: ModalScreenProps) {
    super(props)
    this.props = props
    this.componentRefs = { parent: React.createRef() }

    this.state = {
      show: this.props.doShow ?? false,
    }
  }

  getIsModalShown = () => this.state.show

  showModal = (modalConfig: ModalConfig | null = null) => {
    this.componentRefs.parent.current?.classList.remove(styles.minimized)
    this.#getCancelButton()?.focus()
    this.#modalConfig = modalConfig
    this.setState({ show: true })
  }

  hideModal = () => this.componentRefs.parent.current?.classList.add(styles.minimized)

  ok = (e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLElement>) => {
    this.componentRefs.parent.current?.classList.add(styles.minimized)
    this.setState({ show: false })
    this.#modalConfig?.ok?.(e)
  }

  cancel = (e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLElement>) => {
    this.componentRefs.parent.current?.classList.add(styles.minimized)
    this.#modalConfig?.cancel?.(e)
    this.setState({ show: false })
  }

  onBlur = (e?: React.FocusEvent<HTMLButtonElement, Element> | React.FocusEvent<HTMLElement>) => {
    if (!e) return
    e.preventDefault()

    var focusedElement = e.relatedTarget

    if (this.componentRefs.parent.current?.contains(focusedElement)) {
      return
    }

    if (this.#isCancelButton(e.target)) {
      this.#getOkButton()?.focus()
    } else {
      this.#getCancelButton()?.focus()
    }
  }

  #getCancelButton: () => HTMLButtonElement | null | undefined = () =>
    this.componentRefs.parent.current?.querySelector('[data-role="luru-cancel-button"]')

  #getOkButton: () => HTMLButtonElement | null | undefined = () =>
    this.componentRefs.parent.current?.querySelector('[data-role="luru-ok-button"]')

  #isOkButton = (el: HTMLElement) => el.dataset.role === 'luru-ok-button'

  #isCancelButton = (el: HTMLElement) => el.dataset.role === 'luru-cancel-button'

  render = () => {
    var style: Record<string, any> = {}

    if (this.props.width) {
      style.width = this.props.width
    }

    if (this.props.height) {
      style.height = this.props.height
    }

    var dialogProps: Record<string, any> = {
      id: this.props.id,
      className: [
        styles.dialog,
        this.props.centered ? styles.centered : '',
        this.props.className ? this.props.className : '',
      ].join(' '),
      style,
    }

    if (this.props.dialogRole) {
      dialogProps['data-luru-role'] = this.props.dialogRole
    }

    return (
      <div
        ref={this.componentRefs.parent}
        className={[
          styles.parent,
          this.props.parentClassName ?? null,
          !this.props.doShow ? styles.minimized : null,
        ].join(' ')}
      >
        <div {...dialogProps}>
          <div className={styles.title}>
            <div className={styles.titleText}>{this.props.title ?? ''}</div>
            <div className={styles.titleSpacer}>{this.props.titleActionElements ?? ''}</div>
            {this.props.titleCloseButton ? (
              <button onClick={this.cancel} data-luru-role='close-dialog-button'>
                <img src={dismissIcon} alt='Close' className={styles.dismiss} />
              </button>
            ) : null}
          </div>
          <div className={styles.content} data-luru-role='modal-screen-content'>
            {this.state.show ? this.props.children : null}
          </div>
          {!this.props.hideButtons ? (
            <div className={styles.buttons}>
              {!this.props.hideCancelButton && (
                // <FlatButton dataRole='luru-cancel-button' onClick={this.cancel} onBlur={this.onBlur}>
                //   Cancel
                // </FlatButton>
                <LuruButton
                  title='Cancel'
                  variant='outline'
                  role='luru-cancel-button'
                  onClick={this.cancel}
                  onBlur={this.onBlur}
                  {...this.props.cancelButtonProps}
                >
                  {this.props.cancelButtonLabel || 'Cancel'}
                </LuruButton>
              )}
              {/* <FlatButton dataRole='luru-ok-button' onClick={this.ok} className={styles.okButton} onBlur={this.onBlur}>
                OK
              </FlatButton> */}
              <LuruButton
                title='OK'
                role='luru-ok-button'
                onClick={this.ok}
                onBlur={this.onBlur}
                {...this.props.okButtonProps}
              >
                {this.props.okButtonLabel || 'OK'}
              </LuruButton>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
