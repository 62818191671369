import React from 'react'
import Modal from '../../../primitives/Modal'
import LuruButton from '../../../primitives/ui/LuruButton'
import { EmailProvider } from '../../../features/user/types'
import styles from './styles.module.css'
import useComponentState from './useComponentState'
import useEventHandlers from './useEventHandlers'

export const emailConnectToastId = 'EmailConnectToastId'

export interface EmailConnectModalProps {}

export interface EmailConnectModalState {
  show: boolean
  isFromError: boolean
  emailProvider: EmailProvider
}

const EmailConnectModal = React.forwardRef((props: EmailConnectModalProps, ref) => {
  const sharedState = useComponentState(props)
  const { state } = sharedState
  const { onClose, onSkip, onEmailConnectClick } = useEventHandlers(sharedState, props, ref)
  // const emailProvider = useAppSelector(
  //   (state) =>
  //     state.user?.data?.userSettings?.connectedEmail?.name ||
  //     state.user?.data?.userSettings?.connections?.find((f) => f?.name === 'O365MAIL')?.name
  // ) as EmailProvider

  return (
    <Modal onClose={onClose} show={state.show}>
      <div className={styles.container}>
        <>
          <h4 className={styles.title}>
            Connect your {state.emailProvider === EmailProvider.GMAIL ? 'GMail' : 'Office 365 email'}
          </h4>
          <div className={styles.body}>
            <div className={styles.bodyInfo}>
              <p>
                Unlock magic by connecting {state.emailProvider === EmailProvider.GMAIL ? 'Gmail' : 'Office 365'} to
                Luru
              </p>
              <p>
                <span role='img' aria-label='arrows_counterclockwise'>
                  🔄
                </span>{' '}
                Luru can auto-sync all your sales email to Salesforce - no more manual (s)logging
              </p>
              <p>
                <span role='img' aria-label='robot_face'>
                  🤖
                </span>{' '}
                You can setup cool automations based on email triggers
              </p>
              <p>
                <span role='img' aria-label='lock'>
                  🔒
                </span>{' '}
                Completely secure. Luru doesn’t store any of your emails. Zero. Zilch. Nada.
              </p>

              <div className={styles.connectButtonsContainer}>
                {state.emailProvider === EmailProvider.GMAIL ? (
                  <button className={styles.gcalButton} onClick={() => onEmailConnectClick(EmailProvider.GMAIL)}>
                    <div className={styles.icon} data-luru-cal={EmailProvider.GMAIL}></div>
                    <div className={styles.buttonText}>Sign in with Google</div>
                  </button>
                ) : null}
                {state.emailProvider === EmailProvider.O365MAIL ? (
                  <button className={styles.o365Button} onClick={() => onEmailConnectClick(EmailProvider.O365MAIL)}>
                    <div className={styles.icon} data-luru-cal={EmailProvider.O365MAIL}></div>
                    <div className={styles.buttonText}>Sign in with Microsoft</div>
                  </button>
                ) : null}
              </div>

              <p className={styles.smallerFont}>
                When you click{' '}
                <span role='img' aria-label='point_up'>
                  ☝️
                </span>
                {state.emailProvider === EmailProvider.GMAIL
                  ? `, you will see a permissions page where you would authorise Google to give Luru following the permissions:`
                  : ', you will see a permissions page where you would authorise Microsoft to give Luru access to read your email'}
              </p>
              {state.emailProvider === EmailProvider.GMAIL ? (
                <div className={[styles.smallerFont, styles.insteadUl].join(' ')}>
                  <span>
                    <b>View your email messages and settings</b> - Well, kinda critical if we are to sync your emails
                  </span>

                  <span>
                    <b>View and manage Pub/Sub topics and subscriptions</b> - Nerd-speak for Luru to be notified
                    whenever there is a new email
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.footer}>
            <LuruButton title='Skip' onClick={onSkip} extraClassNames={[styles.skipButtonStyle]}>
              Skip for now
            </LuruButton>
          </div>
        </>
      </div>
    </Modal>
  )
})

export default EmailConnectModal
