import CrmRecord from '../../domain/crmRecord/CrmRecord'
import { getFullyQualifiedKey } from '../../domutils/utils'
import { JottingType } from '../../features/notes/types'

/**
 * @classdesc Class to handle task toggling (with mouse) inside editor
 */
export default class TaskToggleHandler {
  // Computations

  /**
   * Compute if and how navigation event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]
    if (lastEvent?.sourceEvent?.type !== 'mousedown' && lastEvent?.sourceEvent?.type !== 'keydown') {
      return null
    }

    if (lastEvent.sourceEvent.type === 'mousedown' && lastEvent.data.prefixElement === null) {
      return null
    }

    if (lastEvent.sourceEvent.type === 'keydown' && getFullyQualifiedKey(lastEvent.sourceEvent) !== 'Ctrl ') {
      return null
    }

    return this.#computeTaskToggleHandler(view, lastEvent)
  }

  /**
   * Compute handler for handling task toggling
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} toggleEvent - Luru event
   * @returns {Object} - Of type { do, preventDefault }
   */
  #computeTaskToggleHandler(view, toggleEvent) {
    let jottingElement = toggleEvent.data.jottingElement
    let jottingType = view.getJottingType(jottingElement)
    let nextType = {
      [JottingType.TASK_COMPLETE]: JottingType.TASK_INCOMPLETE,
      [JottingType.TASK_INCOMPLETE]: JottingType.TASK_COMPLETE,
      [JottingType.A_TASK_COMPLETE]: JottingType.A_TASK_INCOMPLETE,
      [JottingType.A_TASK_INCOMPLETE]: JottingType.A_TASK_COMPLETE,
    }

    return {
      do: async (view, controller) => {
        if (toggleEvent.sourceEvent.type === 'keydown') {
          toggleEvent.sourceEvent.preventDefault()
        }
        if (!(jottingType in nextType)) {
          return
        }
        view.changeJottingType(jottingElement, nextType[jottingType])
        let taskData = view.getTaskJottingData(jottingElement)
        let successMessage =
          jottingType === JottingType.TASK_COMPLETE || jottingType === JottingType.A_TASK_COMPLETE
            ? `Task marked as yet-to-be completed in ${CrmRecord.getCrmName()}`
            : `Task marked as completed in ${CrmRecord.getCrmName()}`
        await controller?.getModel()?.updateTask(taskData, successMessage)
      },
      preventDefault: toggleEvent.sourceEvent.type === 'keydown',
      dirtyFlag: true,
    }
  }
}
