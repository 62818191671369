import { useCallback, useState } from 'react'
import { CalendlyModalProps } from '.'
import CalendlySpinner from './CalendlySpinner'
import './calendly.css'
import { useAppSelector } from '@/app/hooks'

interface ModalContentProps extends CalendlyModalProps {}

export default function ModalContent(props: ModalContentProps) {
  const [loading, setLoading] = useState<boolean>(true)

  const onLoad = useCallback(() => {
    // The modal content may take some minute time to load.
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const userName = useAppSelector((state) => {
    let firstName = state.user.data.firstName
    let lastName = state.user.data.lastName

    return `${firstName} ${lastName}`
  })

  const email = useAppSelector((state) => state.user.data.email)

  return (
    <>
      {loading && <CalendlySpinner />}
      <iframe
        width='100%'
        height='100%'
        frameBorder='0'
        title={'Calendly Scheduling Page'}
        onLoad={onLoad}
        src={`${props.url}?embed_type=PopupWidget&embed_domain=1&email=${email}&name=${userName}`}
      ></iframe>
      {!loading && (
        <button
          className='calendly-popup-close'
          onClick={props.onModalClose}
          aria-label='Close modal'
          style={{
            display: 'block',
            border: 'none',
            padding: 0,
          }}
        ></button>
      )}
    </>
  )
}
