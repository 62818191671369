import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import { ReduxNoteTemplateEntity, ReduxNoteTemplatesState } from '../types'

export interface UnhideNoteTemplateParameter {
  noteTemplateId: string
}

export interface UnhideNoteTemplateAPIResponse extends LuruAPIResponse {
  data: ReduxNoteTemplateEntity
}

export const unhideNoteTemplate = {
  action: createAsyncThunk<
    UnhideNoteTemplateAPIResponse['data'],
    UnhideNoteTemplateParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: UnhideNoteTemplateAPIResponse['metadata']
      rejectedMeta: UnhideNoteTemplateAPIResponse['metadata']
    }
  >('noteTemplates/unhideNoteTemplate', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response: UnhideNoteTemplateAPIResponse = await NoteTemplatesAPI.unhideNoteTemplate(params, { signal })

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null
      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(unhideNoteTemplate.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(unhideNoteTemplate.action.fulfilled, (state, action) => {
      state.entities[action.payload.template_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...action.payload,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(unhideNoteTemplate.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    unhideNoteTemplate.addPendingCase(builder)
    unhideNoteTemplate.addFulfilledCase(builder)
    unhideNoteTemplate.addRejectedCase(builder)
  },
}
