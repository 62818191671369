import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../app/hooks'
import { useEffect } from 'react'
import LuruUser from '../domain/users/LuruUser'
import { useLuruToast } from './useLuruToast'
import { ToastId } from '@/app_ui/types'

export interface UseO365CalendarStateI {
  checkAndShowErrorMessage?: boolean
}

export function useO365CalendarState(config?: UseO365CalendarStateI) {
  const { showToast } = useLuruToast()
  const { checkAndShowErrorMessage = true } = config || {}
  let signedInState = useAppSelector((state) => state.user.status)
  let [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (checkAndShowErrorMessage) {
      if (signedInState === 'signedIn') {
        const o365cal_state = searchParams.get('o365cal_state')
        const isCalenderConnected = LuruUser.getCurrentUserCalendarName()
        if (!isCalenderConnected && o365cal_state) {
          showToast({
            id: ToastId.O365_CALENDAR_TOAST_ID,
            message: '',
            severity: 'warning',
          })
          searchParams.delete('o365cal_state')
          setSearchParams(searchParams)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  return searchParams.get('o365cal_state')
}
