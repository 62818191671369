// Utils
// import { positionMenuAtTarget } from '../domutils/utils'

// Own libraries
import { JottingType } from '../features/notes/types'
import { EditorEntityType, EditorMenuAction } from './EditorController'

// Styles
import styles from '../routes/notes/css/EditorMenus.module.css'
import DomUtils from './utils/DomUtils'
import { trackEvent } from '../analytics/Ga'

export class EditorMenu {
  static currentMenuShown = null

  constructor(menuType, editorContainer, controller, crmId) {
    this.controller = controller
    this.menuType = menuType
    this.menuElement = null
    this.editorContainer = editorContainer
    this.isMenuElementCreated = false
    this.isMenuVisible = false
    this.currentContext = null
    this.menuItemDefault = null
    this.crmId = crmId
  }

  initialize() {
    if (this.isMenuElementCreated === false) {
      return this.render()
    } else {
      return false
    }
  }

  teardown() {
    // Remove menu element from DOM
    this.menuElement.remove()
    // Set an event listener to hide the menu on an outside click
    document.body.removeEventListener('click', fnClickHideMenu)
  }

  render() {
    this.menuElement = document.createElement('DIV')
    this.menuElement.setAttribute('data-menu-name', this.menuType)
    this.menuElement.classList.add(styles.editormenu)
    this.editorContainer?.append(this.menuElement)

    let menuItemsList = document.createElement('UL'),
      currentItemIndex = 1,
      prevMenuItemElement = null

    for (let sectionIndex in Object.keys(this.menuItems)) {
      // Create section title as LI element
      let section = this.menuItems[sectionIndex],
        sectionTitle = document.createElement('LI')
      sectionTitle.textContent = section.label

      // Set attribute; attributes are always strings
      if (section.defaultVisible === false) {
        sectionTitle.dataset.menuItemVisible = 'false'
      } else {
        sectionTitle.dataset.menuItemVisible = 'true'
      }
      menuItemsList.append(sectionTitle)

      // Bind section title to internal menu data structure
      this.menuItems[sectionIndex].element = sectionTitle

      // Create all items inside section
      let sectionItemsList = document.createElement('UL')
      for (let itemIndex in Object.keys(section.items)) {
        let item = section.items[itemIndex],
          menuItemElement = document.createElement('LI')
        if (item.type === 'menuItemDefault') {
          this.menuItemDefault = menuItemElement
        }
        if (item.defaultVisible === false) {
          menuItemElement.dataset.menuItemVisible = 'false'
        } else {
          menuItemElement.dataset.menuItemVisible = 'true'
        }
        menuItemElement.dataset.menuIndex = currentItemIndex
        menuItemElement.command = item.command ?? section.command
        menuItemElement.commandPayload = item.data
        menuItemElement.addEventListener('click', (e) => {
          this.prepareCommand(true, e.currentTarget)
          e.preventDefault()
          e.stopPropagation()
          EditorMenu.currentMenuShown.hide()
        })
        // menuItemElement.addEventListener("mouseenter", () =>
        //   ((element) => this.selectMenuItem(element))(menuItemElement)
        // );
        // menuItemElement.addEventListener("mouseleave", () =>
        //   ((element) => this.deselectMenuItem(element))(menuItemElement)
        // );

        menuItemElement.append(this.formatAsElement(item.data))

        // Have menu items behave a doubly-linked list for easy navigation
        menuItemElement.prevMenuItemElement = prevMenuItemElement
        if (prevMenuItemElement) {
          prevMenuItemElement.nextMenuItemElement = menuItemElement
        }

        sectionItemsList.append(menuItemElement)

        // Bind the menu item element to menu items object
        this.menuItems[sectionIndex].items[itemIndex].element = menuItemElement

        // Track most recent menu item appended
        prevMenuItemElement = menuItemElement

        // Update index
        currentItemIndex++
      }
      menuItemsList.append(sectionItemsList)
    }

    // Link the first and last elements (make the linked list circular)
    let firstMenuItemElement = menuItemsList.querySelector('ul > ul > li'),
      lastMenuItemElement = prevMenuItemElement
    firstMenuItemElement.prevMenuItemElement = lastMenuItemElement
    lastMenuItemElement.nextMenuItemElement = firstMenuItemElement

    // Remove any content if it already exists
    // This seems to happen when there are 're-renders'
    this.menuElement.innerHTML = ''
    // Append menu to menu container given by Editor component
    this.menuElement.append(menuItemsList)

    // Set flag to avoid any repeated creation of menu element
    this.isMenuElementCreated = true

    // Select first visible item if mouse leaves menu without any selection
    menuItemsList.addEventListener('mouseleave', () => {
      if (!this.currentContext?.selectedMenuItem) {
        this.selectFirstVisibleMenuItem()
      }
    })

    // Return creation status
    return true
  }

  /**
   * Function to show a generic editor menu
   * To be used by shortcut menu, contacts menu & hashtag menu
   * This function attaches menu hide triggers and handles cleanup
   * This function also handles the 'currentMenuShown' object
   * @param {Object} luruEvent - Luru event object
   */
  show(luruEvent) {
    let noteElement = luruEvent.data.noteElement
    let range = luruEvent.data.range

    try {
      const isCaretAtEnd = range.endContainer.nodeType === 1 && range.endOffset === range.endContainer.childNodes.length
      if (isCaretAtEnd) {
        let youngestElement = range.endContainer.lastChild
        while (youngestElement && youngestElement.nodeType === 1) {
          youngestElement = youngestElement.lastChild
        }
        // Note: youngestElement will be null if there is no content inside
        // noteElement
        if (youngestElement) {
          range.setEnd(youngestElement, youngestElement.textContent?.length ?? 0)
        }
      }
    } catch (e) {
      console.warn(e)
    }

    // If a menu (even a different menu object) is already shown, hide it
    if (EditorMenu.currentMenuShown) {
      EditorMenu.currentMenuShown.hide()
    }

    EditorMenu.currentMenuShown = this

    // Scroll menu to top
    this.menuElement.scrollTo({ top: 0, behavior: 'instant' })

    // Set no results flag to false
    this.menuElement.dataset.noResults = 'false'

    // Build a context for later purposes
    this.currentContext = {
      source: noteElement,
      commandStartOffset: range.endOffset,
      range: range,
      selectedMenuItem: null, // Will be set by 'selectFirstVisible...()'
      scrollY: this.editorContainer.scrollTop,
    }

    this.selectFirstVisibleMenuItem()

    // Toggle visibility of the menu
    if (!this.menuElement.classList.contains(styles[`${this.menuType}Visible`])) {
      this.menuElement.classList.add(styles[`${this.menuType}Visible`])
    }
    // Set a flag to indicate visibility
    this.isMenuVisible = true

    // Position menu correctly at caret
    DomUtils.positionMenuAtTarget(this.menuElement, noteElement, this.editorContainer)

    // Set an event listener to hide the menu on an outside click
    document.body.addEventListener('click', fnClickHideMenu)
  }

  /**
   * Generic function to hide editor menu
   * This will be used by other functions (for e.g. when user presses /
   * and then immediately deletes the character)
   */
  hide() {
    if (!this.isMenuVisible) {
      return
    }

    // Remove the 'visible' style for the menu itself
    if (this.menuElement.classList.contains(styles[`${this.menuType}Visible`])) {
      this.menuElement.classList.remove(styles[`${this.menuType}Visible`])
    }

    // Remove 'anywhere-click-hide-menu' event listener
    // TODO: Check if this is removed properly
    document.body.removeEventListener('click', fnClickHideMenu)

    // Set menu-shown state variables to hidden/empty state
    EditorMenu.currentMenuShown = null
    this.isMenuVisible = false
    this.currentContext = null

    // Remove 'selectedMenuItem' style from all menuItems
    // The first available menu item will be selected on next menu show
    let selectedItems = this.menuElement.querySelectorAll(`.${styles.selectedMenuItem?.replace('+', '\\+')}`)
    for (let elem of selectedItems) {
      elem.classList.remove(styles.selectedMenuItem)
    }

    // Set all menu items as visible
    let visibleTokenList = this.menuElement.querySelectorAll('[data-menu-item-visible="false"]')
    for (let token of visibleTokenList) {
      token.dataset.menuItemVisible = 'true'
    }

    // For default menu item, revert it back to its original state
    if (this.menuItemDefault) {
      this.menuItemDefault.dataset.menuItemVisible = 'false'
      this.menuItemDefault.innerHTML = ''
      let defaultSection = this.menuItemDefault?.parentElement.previousElementSibling
      defaultSection.dataset.menuItemVisible = 'false'
    }
  }

  /** Select next item of an editor menu */
  selectNext() {
    let currSelElement = this.currentContext?.selectedMenuItem,
      nextSelElement = currSelElement?.nextMenuItemElement,
      nextMenuIndex

    if (!nextSelElement || !currSelElement) {
      return
    }

    // Keep traversing the array until we find a next selected element
    while (nextSelElement && nextSelElement.dataset?.menuItemVisible !== 'true' && nextSelElement !== currSelElement) {
      nextSelElement = nextSelElement?.nextMenuItemElement
    }

    // If there is only one search result, we need not do anything now
    if (nextSelElement === currSelElement) {
      return
    }

    // Else, we set the selected menu item to the newly found item
    nextMenuIndex = parseInt(nextSelElement.dataset.menuIndex, 10)
    currSelElement.classList.remove(styles.selectedMenuItem)
    nextSelElement.classList.add(styles.selectedMenuItem)
    this.currentContext.selectedMenuItem = nextSelElement

    // Scrolling if required
    let bottomOverflow =
        nextSelElement.offsetTop +
        nextSelElement.clientHeight -
        (this.menuElement.clientHeight + this.menuElement.scrollTop),
      topOverflow = this.menuElement.scrollTop - nextSelElement.offsetTop

    /** TODO: Instead of checking menu index, check if nextSelElement is
     *        the first item visible.  This is a corner case now.
     */
    if (nextMenuIndex === 1) {
      this.menuElement.scrollTo({ top: 0, behavior: 'instant' })
    } else if (bottomOverflow > 0) {
      this.menuElement.scrollBy({
        top: bottomOverflow,
        behavior: 'instant',
      })
    } else if (topOverflow > 0) {
      this.menuElement.scrollBy({
        top: -topOverflow,
        behavior: 'instant',
      })
    }
  }

  /** Select previous item of an editor menu */
  selectPrevious() {
    let currSelElement = this.currentContext?.selectedMenuItem,
      prevSelElement = currSelElement?.prevMenuItemElement,
      prevMenuIndex

    if (!currSelElement || !prevSelElement) {
      return
    }

    // Keep traversing the array until we find a next selected element
    while (prevSelElement && prevSelElement.dataset?.menuItemVisible !== 'true' && prevSelElement !== currSelElement) {
      prevSelElement = prevSelElement?.prevMenuItemElement
    }

    // If there is only one search result, we need not do anything now
    if (prevSelElement === currSelElement) {
      return
    }

    // Else, we set the selected menu item to the newly found item
    prevMenuIndex = parseInt(prevSelElement.dataset.menuIndex, 10)
    currSelElement.classList.remove(styles.selectedMenuItem)
    prevSelElement.classList.add(styles.selectedMenuItem)
    this.currentContext.selectedMenuItem = prevSelElement

    // Scrolling if required
    let bottomOverflow =
        prevSelElement.offsetTop +
        prevSelElement.clientHeight -
        (this.menuElement.clientHeight + this.menuElement.scrollTop),
      topOverflow = this.menuElement.scrollTop - prevSelElement.offsetTop

    /** TODO: Instead of checking menu index, check if prevSelElement is
     *        the first item visible.  This is a corner case now.
     */
    if (prevMenuIndex === 1) {
      this.menuElement.scrollTo({ top: 0, behavior: 'instant' })
    } else if (bottomOverflow > 0) {
      this.menuElement.scrollBy({
        top: bottomOverflow,
        behavior: 'instant',
      })
    } else if (topOverflow > 0) {
      this.menuElement.scrollBy({
        top: -topOverflow,
        behavior: 'instant',
      })
    }
  }

  /**
   * Compute if and how keyboard event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]
    let keyEvent = lastEvent?.sourceEvent?.type === 'keydown' ? lastEvent : null

    if (!keyEvent) {
      return null
    }

    if (!EditorMenu.currentMenuShown) {
      if (
        (keyEvent.sourceEvent.key === '/' && this.menuType === 'shortcutMenu') ||
        (keyEvent.sourceEvent.key === '#' && this.menuType === 'hashtagMenu')
      ) {
        return {
          do: () => {
            this.show(keyEvent)
            EditorMenu.currentMenuShown = this
          },
          stopHandling: false,
          preventDefault: false,
        }
      } else {
        return null
      }
    } else if (EditorMenu.currentMenuShown !== this) {
      return null
    }

    // Keys to be handled and whether each key should be further handled
    let menuKeys = {
      ArrowUp: true,
      ArrowDown: true,
      Escape: true,
      Enter: true,
      Tab: true,
      Backspace: false,
      Delete: false,
      ' ': false,
    }

    let processEvent = (view) => {
      this.#processKeyboardEvent(view, keyEvent)
    }
    processEvent = processEvent.bind(this)

    return {
      do: (view) => {
        if (menuKeys[keyEvent.sourceEvent.key]) {
          keyEvent.sourceEvent.preventDefault()
        }
        processEvent(view)
      },
      stopHandling: menuKeys[keyEvent.sourceEvent.key],
      preventDefault: menuKeys[keyEvent.sourceEvent.key],
    }
  }

  /**
   * Function to handle commands received by an editor menu
   * @param {EditorDOM} view - Instance of EditorDOM hosting note
   * @param {Object} luruEvent - Luru event object
   */
  #processKeyboardEvent(view, luruEvent) {
    let command = this.#computeMenuCommand(luruEvent)

    if (command?.preventDefault) {
      luruEvent.sourceEvent.preventDefault()
    }

    let currentlyEditedNote = luruEvent.data.noteElement

    this.processCommand(view, command, currentlyEditedNote)
  }

  processCommand(view, command, noteElement) {
    let jottingElement = view?.getJottingElement(noteElement)
    if (!command) {
      return
    }

    // 1. Pre-command execution actions
    let context = command.context,
      endContainer = context.range.endContainer,
      endOffset = context.range.endOffset,
      // Flag to set if caret requires repositioning -
      //   required if there is modification of content due to command
      requireCaretPositioning =
        command?.trigger === 'mouse' &&
        command?.action !== EditorMenuAction.INSERT_CRM_FIELD &&
        // command?.action !== EditorMenuAction.INSERT_CRM_FIELD_NEW &&
        command?.action !== EditorMenuAction.INSERT_CRM_COLLECTION &&
        command?.action !== EditorMenuAction.INSERT_CRM_TEMPLATE

    // 2. Command execution step
    // Routing command to actions
    switch (command?.action) {
      case EditorMenuAction.SELECT_NEXT:
        EditorMenu.currentMenuShown.selectNext()
        break

      case EditorMenuAction.SELECT_PREVIOUS:
        EditorMenu.currentMenuShown.selectPrevious()
        break

      case EditorMenuAction.HIDE_MENU:
        EditorMenu.currentMenuShown.hide()
        break

      case EditorMenuAction.INSERT_TEXT:
        insertContentAtCaret('/' + command?.payload?.label + ' ')
        break

      case EditorMenuAction.INSERT_CONTACT:
        insertContentAtCaret(command?.payload?.email + ' ')
        break

      case EditorMenuAction.INSERT_HASHTAG:
        insertContentAtCaret('#' + command?.payload?.hashtag + ' ')
        break

      case EditorMenuAction.INSERT_NEW_HASHTAG:
        insertContentAtCaret('#' + command?.payload?.hashtag + ' ')
        break

      case EditorMenuAction.FORMAT.H1:
        changeJottingAndFormat(JottingType.H1)
        break

      case EditorMenuAction.FORMAT.H2:
        changeJottingAndFormat(JottingType.H2)
        break

      case EditorMenuAction.FORMAT.H3:
        changeJottingAndFormat(JottingType.H3)
        break

      case EditorMenuAction.FORMAT.P:
        changeJottingAndFormat(JottingType.P)
        break

      case EditorMenuAction.FORMAT.OL:
        changeJottingAndFormat(JottingType.OL1)
        break

      case EditorMenuAction.FORMAT.UL:
        changeJottingAndFormat(JottingType.UL1)
        break

      case EditorMenuAction.COMMAND_TASK:
        changeJottingAndFormat(JottingType.TASK_INCOMPLETE)
        break

      case EditorMenuAction.COMMAND_QUESTION:
        changeJottingAndFormat(JottingType.Q)
        break

      case EditorMenuAction.INSERT_CRM_FIELD:
        // 27-02-23: Make INSERT_CRM_FIELD behave like INSERT_CRM_FIELD_NEW,
        // the new implementation; commenting it out for now (in case we want
        // to go back to old behavior)
        // // Delete command content that was inserted
        // if (noteElement.textContent.trim() === '/') {
        //   noteElement.textContent = ''
        // } else if (noteElement.textContent !== '') {
        //   deleteCommandText()
        // }
        // this.#insertCrmField(command.context)
        // break

        // case EditorMenuAction.INSERT_CRM_FIELD_NEW:
        // Delete command content that was inserted
        if (noteElement.textContent.trim() === '/') {
          noteElement.textContent = ''
        } else if (noteElement.textContent !== '') {
          deleteCommandText()
        }
        this.#insertCrmFieldNew(command.context)
        break

      case EditorMenuAction.INSERT_CRM_COLLECTION:
        // Delete command content that was inserted
        if (noteElement.textContent.trim() === '/') {
          noteElement.textContent = ''
        } else if (noteElement.textContent !== '') {
          deleteCommandText()
        }
        this.#insertCrmCollection(command.context)
        break

      case EditorMenuAction.INSERT_CRM_TEMPLATE:
        // Delete command content that was inserted
        if (noteElement.textContent.trim() === '/') {
          noteElement.textContent = ''
        } else if (noteElement.textContent !== '') {
          deleteCommandText()
        }
        this.#insertCrmTemplate(command.context)
        break

      default:
        console.log(`processEditorCommand::Can't process ${JSON.stringify(command?.action)}`)
    }

    // 3. Post-command execution steps:
    //    - Check if caret requires repositioning
    if (requireCaretPositioning) {
      // Setting cursor after a timeout to let mouse events bubble up
      //   and pass through other handlers (like blur)
      setTimeout(() => {
        // Set selection to the collapsed range created
        document.getSelection().removeAllRanges()

        let range = new Range()
        let repositionContainer = endContainer.parentElement ? endContainer : command.context.source
        let repositionOffset = endContainer.parentElement ? Math.min(endOffset, endContainer.textContent.length) : 0

        if (repositionContainer && repositionContainer.textContent.length >= repositionOffset) {
          range.setStart(
            repositionContainer,
            repositionContainer.nodeType === 3
              ? Math.min(repositionOffset, repositionContainer.textContent.length - 1)
              : Math.min(repositionOffset, repositionContainer.children.length)
          )
        } else {
          console.warn('Invalid offset or node is empty.')
        }
        range.collapse(true)
        document.getSelection().addRange(range)
      }, 100 * (command.trigger === 'mouse'))
    }

    // Command helpers
    function insertContentAtCaret(content) {
      try {
        let text = context.range.endContainer.textContent,
          filterTextLength = endOffset - context.commandStartOffset - 1
        text = text.slice(0, context.commandStartOffset) + content + text.slice(endOffset)
        context.range.endContainer.textContent = text
        // Move end offset forward by length of inserted content
        endOffset += content.length - 1
        //   (minus) partial content already entered
        endOffset -= filterTextLength
      } catch (err) {
        console.warn(err)
      }
      // Since there is modification of content, we need content
      //   repositioning after inserted content
      requireCaretPositioning = true
    }

    // Deletes the text that was typed to invoke the menu command
    function deleteCommandText() {
      let range = new Range()
      range.setStart(context.range.endContainer, context.commandStartOffset)
      if (
        context.source === context.range.endContainer &&
        context.range.endOffset === 0 &&
        context.commandStartOffset === 0
      ) {
        let caretRange = document.getSelection().getRangeAt(0)
        range.setEnd(caretRange.endContainer, caretRange.endOffset)
      } else {
        range.setEnd(context.range.endContainer, context.range.endOffset)
      }
      range.deleteContents()
    }

    function changeJottingAndFormat(jottingType) {
      if (view?.isAnswerJotting(jottingElement)) {
        jottingType = view?.getAnswerType(jottingType)
      }
      // Change current jotting type
      view?.changeJottingType(jottingElement, jottingType)

      // Delete command content that was inserted
      if (noteElement.textContent.trim() === '/') {
        noteElement.textContent = ''
      } else if (noteElement.textContent !== '') {
        deleteCommandText()
      }

      // If there is no content, set a placeholder
      if (noteElement.textContent === '') {
        setTimeout(view?.setPlaceholder(noteElement))
      }
    }
  }

  /**
   * Trigger inserting a CRM field.  This function dispatches a custom event
   * to the CRM link popup element.
   * @param {Object} - Command context maintained by editor menu object
   */
  #insertCrmField(commandContext) {
    const eventConfig = {
      detail: {
        ...commandContext,
        onResponseReady: (response) =>
          this.controller.onReceiveChosenCrmField(response, commandContext.source /* noteElement */),
      },
    }
    this.controller.getCrmFieldChooser().dispatchEvent(new CustomEvent('choosefieldrequest', eventConfig))

    trackEvent('read_crm_from_note')
  }

  /**
   * Trigger inserting a CRM field.  This function dispatches a custom event
   * to the CRM link popup element.
   * @param {Object} - Command context maintained by editor menu object
   */
  #insertCrmFieldNew(commandContext) {
    const eventConfig = {
      detail: {
        ...commandContext,
        onResponseReady: (response) =>
          this.controller.onReceiveChosenCrmFieldNew(response, commandContext.source /* noteElement */),
      },
    }

    this.controller.getCrmFieldChooser().dispatchEvent(new CustomEvent('choosefieldrequest', eventConfig))

    trackEvent('read_crm_from_note')
  }

  /**
   * Trigger inserting a CRM collection.  This function dispatches a custom event
   * to the CRM link popup element if required
   * @param {Object} - Command context maintained by editor menu object
   */
  #insertCrmCollection(commandContext) {
    const eventConfig = {
      detail: {
        ...commandContext,
        onResponseReady: (response) =>
          this.controller.onReceiveChosenCrmCollection(response, commandContext.source /* noteElement */),
      },
    }

    this.controller.getCrmCollectionChooser().dispatchEvent(new CustomEvent('chooseCollectionRequest', eventConfig))

    trackEvent('insert_crm_collection_from_note')
  }

  /**
   * Trigger inserting a CRM field.  This function dispatches a custom event
   * to the CRM link popup element.
   * @param {Object} - Command context maintained by editor menu object
   */
  #insertCrmTemplate(commandContext) {
    const templates = this.controller.getApplicableTemplates()
    if (templates) {
      this.controller
        .getTemplateChooserDialog()
        .setItems(
          this.controller.getApplicableTemplates().map((item) => ({
            ...item,
            id: item.data?.template_id,
            label: item.data?.title,
          }))
        )
        .showDialog({
          title: '',
          ok: (chosenTemplate) => {
            this.controller.onInsertTemplate(chosenTemplate, commandContext.source)
          },
          cancel: () => {
            let repositionRange = new Range()
            repositionRange.setEnd(commandContext?.range.endContainer, commandContext?.range.endOffset)
            repositionRange.collapse(false)
            document.getSelection().removeAllRanges()
            document.getSelection().addRange(repositionRange)
          },
        })
    } else {
      // showLuruNotification({
      //   type: 'success',
      //   message: 'There are no playbooks available.  Create one to use.',
      // })
    }
  }

  /**
   * Compute a command received by an editor menu
   * @param {Object} luruEvent - Luru event object
   */
  #computeMenuCommand(luruEvent) {
    try {
      var range = luruEvent.data.range
      // TODO: In some cases range is null - fix this
      // This could be null when document.getSelection().getRangeCount() is 0
      // in EditorEventsManager.computeEventDetails() - where there is a caret
      // available, we should never have this value as zero.
      var commandText = range.endContainer.textContent.slice(this.currentContext.commandStartOffset, range.endOffset)
      var selectedText = range.toString()
      var commandSecondStage = false
      var returnCommand = null

      // Update the current range to reflect the last position of caret
      this.currentContext.range.setEnd(
        this.currentContext.range.endContainer,
        this.currentContext.range.endContainer?.nodeType === 3
          ? Math.min(range.endOffset, range.endContainer?.textContent?.length)
          : Math.min(range.endOffset, range.endContainer?.children?.length - 1)
      )
    } catch (e) {
      console.warn(`EditorMenu:#computeMenuCommand:Exception while setting end range:`, e)
    }

    // First stage of command processing - Process the key event
    //   Predict what would the command text look like if key is processed
    switch (luruEvent.sourceEvent.key) {
      case 'Backspace':
        commandSecondStage = true
        // If there is no selection (before pressing backspace)
        if (selectedText === '') {
          commandText = commandText.slice(0, commandText.length - 1)
        }
        // If there is no selection (before pressing backspace)
        else {
          // TODO: Remove selected text out of command text
          commandText = commandText.slice(0, commandText.lastIndexOf(selectedText))
        }
        break

      // On pressing of space/esc, we assume user wants to enter a word, and
      //   not select an action from menu.  We reset the command text here.
      case ' ':
      case 'Escape':
        commandSecondStage = true
        commandText = ''
        break

      case 'Enter':
      case 'Tab':
        commandSecondStage = true
        break

      case 'ArrowUp':
        commandSecondStage = true
        commandText = EditorMenuAction.SELECT_PREVIOUS
        break

      case 'ArrowDown':
        commandSecondStage = true
        commandText = EditorMenuAction.SELECT_NEXT
        break

      default:
        if (luruEvent.sourceEvent.key.length === 1) {
          commandSecondStage = true
          commandText = commandText + luruEvent.sourceEvent.key
        }
    }

    // If the key processed indicates further menu actions, then process further
    if (commandSecondStage) {
      if ([EditorMenuAction.SELECT_NEXT, EditorMenuAction.SELECT_PREVIOUS].includes(commandText)) {
        returnCommand = {
          action: commandText,
          preventDefault: true,
          context: this.currentContext,
        }
      } else if (commandText === '') {
        // If there is no command, we hide the menu.  We can reach this state
        // either by user deletion of command or a space, or more such handlers
        returnCommand = {
          action: EditorMenuAction.HIDE_MENU,
          preventDefault: false,
          context: this.currentContext,
        }
      } else if (['Enter', 'Tab'].includes(luruEvent.sourceEvent.key)) {
        // Command has been selected
        returnCommand = this.prepareCommand()
        if (returnCommand) {
          returnCommand = { ...returnCommand, trigger: 'keyboard' }
        }
        this.hide()
      } else {
        this.applyMenuFilter(commandText)
      }
    }

    // Command is an object with two keys:
    //   action: What to do (one of EditorMenuAction.* commands)
    //   preventDefault: Whether to prevent default action or not
    return returnCommand
  }

  /**
   * EditorMenu.prepareCommand
   */
  prepareCommand(dispatchCommand = false, targetMenuItem = null) {
    // TODO: All menu items may be filtered out, but a tab or enter may be
    //   pressed.  Menu is visible in state, but hidden for user.  Show a
    //   no result
    if (!this.currentContext.selectedMenuItem && !targetMenuItem) {
      return null
    }

    let trigger = dispatchCommand ? 'mouse' : 'keyboard'

    // Update the end offset is trigger was mouse
    if (trigger === 'mouse') {
      this.currentContext.range.setEnd(
        this.currentContext.range.endContainer,
        Math.min(this.currentContext.range.endOffset + 1, this.currentContext.range.endContainer.textContent.length)
      )
    }

    let selectedMenuItem = targetMenuItem ?? this.currentContext.selectedMenuItem

    let command = {
      action: selectedMenuItem.command,
      payload: selectedMenuItem.commandPayload,
      preventDefault: true,
      context: this.currentContext,
      trigger,
    }

    if (dispatchCommand) {
      this.processCommand(this.controller.getViewInstance(), command, this.currentContext.source)
      return false
    }

    return command
  }

  /**
   * Apply filter to a menu
   */
  applyMenuFilter(text) {
    text = text.substring(1).toLowerCase()
    let isMenuAvailable = false

    // Set visibility
    for (let section of this.menuItems) {
      let isSectionVisible = false

      for (let item of section.items) {
        if (this.filterMenuItem(item, text, item.element)) {
          item.element.dataset.menuItemVisible = 'true'
          isMenuAvailable = true
          isSectionVisible = true
        } else {
          item.element.dataset.menuItemVisible = 'false'
        }
      }

      section.element.dataset.menuItemVisible = isSectionVisible ? 'true' : 'false'
    }

    if (!isMenuAvailable) {
      console.log('Menu is not available')
      let noResultsElem = this.menuElement.getElementsByClassName(styles.noResults)[0]
      if (!noResultsElem) {
        console.log('No results elements not found, hiding menu')
        this.hide()
        return
      }
      noResultsElem.innerHTML = this.getNoResultsMessage(text)
      this.menuElement.dataset.noResults = 'true'
    } else {
      this.menuElement.dataset.noResults = 'false'
      // Set new selection if current selection was filtered away
      if (!this.currentContext.selectedMenuItem) {
        // If there was no result visible previously, try setting it now
        this.selectFirstVisibleMenuItem()
      } else if (this.currentContext?.selectedMenuItem.dataset.menuItemVisible === 'false') {
        // Remove selection styling from current menu item
        this.currentContext.selectedMenuItem.classList.remove(styles.selectedMenuItem)
        this.selectFirstVisibleMenuItem()
      }
    }
  }

  /**
   * EditorMenu.selectFirstVisibleMenuItem()
   *   Function to select the first visible menu item
   */
  selectFirstVisibleMenuItem() {
    if (this.currentContext) {
      // Select the first visible menu item
      this.currentContext.selectedMenuItem = this.menuElement.querySelector(
        'ul > ul > li[data-menu-item-visible="true"]'
      )
      // Add style to the first visible menu item
      // eslint-disable-next-line no-unused-expressions
      this.currentContext.selectedMenuItem?.classList.add(styles.selectedMenuItem)
    }
  }

  /**
   * EditorMenu.selectMenuItem()
   */
  selectMenuItem(menuItem) {
    if (this.currentContext.selectedMenuItem) {
      this.deselectMenuItem(this.currentContext.selectedMenuItem)
    }
    this.currentContext.selectedMenuItem = menuItem
    menuItem.classList.add(styles.selectedMenuItem)
  }

  /**
   * EditorMenu.deselectMenuItem()
   */
  deselectMenuItem(menuItem) {
    if (this.currentContext) {
      this.currentContext.selectedMenuItem = null
    }
    menuItem.classList.remove(styles.selectedMenuItem)
  }

  /** Default function to filter menu based on given text.
   *    This can be overridden by individual menus.
   *    For e.g., contacts menu many choose to filter by email as well
   */
  filterMenuItem(menuItem, filterText) {
    return menuItem.element.innerText.toLowerCase().startsWith(filterText.toLowerCase())
  }
}

export class ShortcutMenu extends EditorMenu {
  constructor({ editorContainer, controller, crmId }) {
    super('shortcutMenu', editorContainer, controller, crmId)
    const crmName = {
      SFDC: 'Salesforce',
      HUBSPOT: 'HubSpot',
      PIPEDRIVE: 'Pipedrive',
    }
    let crmActions = [
      {
        type: 'menuItem',
        data: {
          label: `${crmName[crmId]} field` ?? 'CRM',
        },
        command: EditorMenuAction.INSERT_CRM_FIELD,
      },
      // {
      //   type: 'menuItem',
      //   data: {
      //     label: `${crmName[crmId]} field (New)` ?? 'CRM',
      //   },
      //   command: EditorMenuAction.INSERT_CRM_FIELD_NEW,
      // },
      {
        type: 'menuItem',
        data: {
          label: `${crmName[crmId]} field collection`,
        },
        command: EditorMenuAction.INSERT_CRM_COLLECTION,
      },
      // Disabling contacts for first release; uncomment later
      // {
      //   type: 'menuItem',
      //   data: {
      //     label: 'Contact',
      //   },
      //   command: EditorMenuAction.INSERT_CRM_CONTACT,
      // },
    ]
    if (controller.getEntityType() === EditorEntityType.Note) {
      crmActions.push({
        type: 'menuItem',
        data: { label: 'Task' },
        command: EditorMenuAction.COMMAND_TASK,
      })
      crmActions.push({
        type: 'menuItem',
        data: { label: `Playbook` },
        command: EditorMenuAction.INSERT_CRM_TEMPLATE,
      })
    }
    this.menuItems = [
      // {
      //   type: 'section',
      //   label: 'Luru',
      //   items: [
      //     // Disabling tasks for first release; uncomment later
      //     // {
      //     //   type: 'menuItem',
      //     //   data: { label: 'Task' },
      //     //   command: EditorMenuAction.COMMAND_TASK,
      //     // },
      //     {
      //       type: 'menuItem',
      //       data: { label: 'Question' },
      //       command: EditorMenuAction.COMMAND_QUESTION,
      //     },
      //   ],
      // },
      {
        type: 'section',
        label: crmName[crmId] ?? 'CRM',
        items: crmActions,
      },
      {
        type: 'section',
        label: 'Formatting',
        items: [
          {
            type: 'menuItem',
            data: { label: 'Question' },
            command: EditorMenuAction.COMMAND_QUESTION,
          },
          {
            type: 'menuItem',
            data: { label: 'Heading1' },
            command: EditorMenuAction.FORMAT.H1,
          },
          {
            type: 'menuItem',
            data: { label: 'Heading2' },
            command: EditorMenuAction.FORMAT.H2,
          },
          {
            type: 'menuItem',
            data: { label: 'Heading3' },
            command: EditorMenuAction.FORMAT.H3,
          },
          {
            type: 'menuItem',
            data: { label: 'Paragraph' },
            command: EditorMenuAction.FORMAT.P,
          },
          {
            type: 'menuItem',
            data: { label: 'Bulleted List' },
            command: EditorMenuAction.FORMAT.UL,
          },
          {
            type: 'menuItem',
            data: { label: 'Numbered List' },
            command: EditorMenuAction.FORMAT.OL,
          },
        ],
      },
      {
        type: 'section',
        label: 'No command results',
        defaultVisible: false,
        command: EditorMenuAction.INSERT_TEXT,
        items: [
          {
            type: 'menuItemDefault',
            defaultVisible: false,
            data: { label: '' },
          },
        ],
      },
    ]
  }

  formatAsElement(menuData) {
    return menuData.label
  }

  getNoResultsMessage(text) {
    return `+ Insert text <em>${text}</em>`
  }

  filterMenuItem(menuItem, filterText, menuItemElement = null) {
    if (menuItem.type === 'menuItemDefault') {
      menuItemElement.innerHTML = `Insert <em>/${filterText}</em> here`
      menuItemElement.commandPayload = {
        ...menuItem.data,
        label: filterText,
      }
    }
    let result =
      (menuItem.type !== 'menuItemDefault' && menuItem.data.label.toLowerCase().startsWith(filterText.toLowerCase())) ||
      (menuItem.type === 'menuItemDefault' && filterText !== '')
    return result
  }
}

export class ContactsMenu extends EditorMenu {
  constructor({ editorContainer, controller, crmId }) {
    super('contactsMenu', editorContainer, controller, crmId)
    this.menuItems = [
      {
        type: 'section',
        label: 'Contacts',
        command: EditorMenuAction.INSERT_CONTACT,
        items: [
          {
            type: 'menuItem',
            data: {
              firstName: 'Sid',
              lastName: 'Ramesh',
              email: 'sid@luru.app',
            },
          },
          {
            type: 'menuItem',
            data: {
              firstName: 'Sanjeeth',
              lastName: 'Kumar',
              email: 'sanjeeth@luru.app',
            },
          },
          {
            type: 'menuItem',
            data: {
              firstName: 'Ananda',
              lastName: 'Kumar',
              email: 'anand@luru.app',
            },
          },
          {
            type: 'menuItem',
            data: {
              firstName: 'Karthikeyan',
              lastName: 'Krishnamurthy',
              email: 'karthik@luru.app',
            },
          },
        ],
      },
    ]
  }

  formatAsElement(menuData) {
    let contactInfoBox = document.createElement('DIV'),
      contactNameBox = document.createElement('SPAN'),
      contactEmailBox = document.createElement('SPAN')

    contactNameBox.classList.add(styles.contactName)
    contactNameBox.textContent = `${menuData.firstName} ${menuData.lastName}`
    contactInfoBox.append(contactNameBox)

    contactEmailBox.classList.add(styles.contactEmail)
    contactEmailBox.textContent = `${menuData.email}`
    contactInfoBox.append(contactEmailBox)

    return contactInfoBox
  }

  filterMenuItem(menuItem, filterText) {
    return (
      // Match for first name
      menuItem.data.firstName.toLowerCase().startsWith(filterText.toLowerCase()) ||
      // Match for last name
      menuItem.data.lastName.toLowerCase().startsWith(filterText.toLowerCase()) ||
      // Match for email user name
      menuItem.data.email.toLowerCase().startsWith(filterText.toLowerCase()) ||
      // Match for email domain
      menuItem.data.email
        .substring(menuItem.data.email.indexOf('@') + 1)
        .toLowerCase()
        .startsWith(filterText.toLowerCase())
    )
  }

  getNoResultsMessage(text) {
    return `+ Insert contact <em>${text}</em>`
  }
}

export class HashtagMenu extends EditorMenu {
  constructor({ editorContainer, controller, crmId }) {
    super('hashtagMenu', editorContainer, controller, crmId)
    this.menuItems = [
      {
        type: 'section',
        label: 'Hashtags',
        command: EditorMenuAction.INSERT_HASHTAG,
        items: [
          { type: 'menuItem', data: { hashtag: 'Competition' } },
          { type: 'menuItem', data: { hashtag: 'Pricing' } },
          { type: 'menuItem', data: { hashtag: 'Product' } },
          { type: 'menuItem', data: { hashtag: 'Discount' } },
          { type: 'menuItem', data: { hashtag: 'Billing' } },
        ],
      },
      {
        type: 'section',
        label: '+ Add new hashtag',
        defaultVisible: false,
        command: EditorMenuAction.INSERT_NEW_HASHTAG,
        items: [
          {
            type: 'menuItemDefault',
            defaultVisible: false,
            data: {},
          },
        ],
      },
    ]
  }

  formatAsElement(menuData) {
    return '#' + menuData.hashtag
  }

  filterMenuItem(menuItem, filterText, menuItemElement = null) {
    if (menuItem.type === 'menuItemDefault') {
      menuItemElement.innerHTML = '#' + filterText
      menuItemElement.commandPayload = {
        ...menuItem.data,
        hashtag: filterText,
      }
    }
    let result =
      (menuItem.type !== 'menuItemDefault' &&
        menuItem.data.hashtag.toLowerCase().startsWith(filterText.toLowerCase())) ||
      (menuItem.type === 'menuItemDefault' && filterText !== '')
    return result
  }

  getNoResultsMessage(text) {
    return `<em>#${text}</em>`
  }
}

/** Event handler to hide editor menu on clicking anywhere in document */
function fnClickHideMenu() {
  if (EditorMenu.currentMenuShown) {
    EditorMenu.currentMenuShown.hide()
  }
}
