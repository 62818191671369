import { RootState } from '../../../app/store'
import { EntityStatus, ThreeWayAnswer } from '../../../app/types'
import { CalendarProvider, CRMProvider } from '../../../features/user/types'
import { NoteId } from '../types'

export const isNoteSyncRequired =
  (noteId: NoteId, sorId: CRMProvider | CalendarProvider) =>
  (state: RootState) => {
    var noteRecord = state.notes.entities[noteId]

    if (
      !noteRecord ||
      (noteRecord.status !== EntityStatus.Loaded && noteRecord.isDraft !== true)
    ) {
      return ThreeWayAnswer.UNCERTAIN
    }

    if (noteRecord.data?.sync_state === 'sor') {
      return ThreeWayAnswer.NO
    }

    if (!Array.isArray(noteRecord.data?.connections)) {
      return ThreeWayAnswer.NO
    }

    let connection = noteRecord.data?.connections?.find(
      (conn) => conn.sor === sorId
    )

    if (!connection) {
      return ThreeWayAnswer.NO
    }

    if (connection.synced_version !== connection.sor_version) {
      return ThreeWayAnswer.YES
    }

    let updatedTime = noteRecord.data?.updated_at
    let syncedTime = connection.synced_at

    if (
      new Date(updatedTime ?? '').valueOf() >
      new Date(syncedTime ?? '').valueOf()
    ) {
      return ThreeWayAnswer.YES
    }

    return ThreeWayAnswer.NO
  }
