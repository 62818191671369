import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, RouterProvider, createBrowserRouter } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import { LuruReduxStore } from './app/store'
import { LuruEntryPoint } from './app/types'

// Base CSS
import '@/index.css'
import './layout/css/base.css'

// Components
import Error from './primitives/Error'
import Utils from './utils/Utils'
import { initWebappStore } from './app/store/initWebappStore'
import { ReduxCacheObserver } from './app/store/cacheObserver'
import NewWebApp from './entryPoints/WebAppNew/NewWebApp'

// Create a luru namespace
if (typeof window !== 'undefined') {
  // @ts-ignore
  window.luru = {
    entryPoint:
      Utils.getCookieValue('luru_access_from') === 'zoom_panel'
        ? LuruEntryPoint.EMBEDDED_ZOOM_PANEL
        : Utils.getCookieValue('luru_access_from') === 'zoom_meeting'
        ? LuruEntryPoint.EMBEDDED_ZOOM_MEETING
        : LuruEntryPoint.WEBAPP,
  }
}

initWebappStore()

// Remove loading, etc. before rendering
try {
  let rootEl = document.getElementById('root')

  if (rootEl) {
    rootEl.classList.remove('rootLoading')
    rootEl.innerHTML = ''
  }
} catch (e) {
  console.warn(e)
}

let container = document.getElementById('root')
let root = createRoot(container!)

// Migrating to RouterProvider (https://reactrouter.com/en/main/upgrading/v6-data)
const router = createBrowserRouter([{ path: '*', Component: NewWebApp }])

try {
  root.render(
    <React.StrictMode>
      {/* Old Router with BrowserRouter */}
      {/* <BrowserRouter>
      <Provider store={LuruReduxStore}>
        <RouterProvider router={router} />
        <NewWebApp />
      </Provider>
      </BrowserRouter> */}

      {/* With RouterProvider Data APIs */}
      <Provider store={LuruReduxStore}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  )
} catch (e) {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        {/* @ts-ignore */}
        <Provider store={LuruReduxStore}>
          <Error error={e} />
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  )
  // TODO: Check if we can send this info sentry
}

ReduxCacheObserver.init()
