import './styles/layout.css'

export interface FlexColumnProps {
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between'
  align?: 'top' | 'center' | 'bottom'
  gap?: string
  wrap?: boolean
  children?: React.ReactNode
  className?: string
}

const FlexColumn = ({ justify, align, gap, children, wrap, className = '' }: FlexColumnProps) => (
  <div
    className={[
      'l-layout-column',
      justify ? `l-layout-justify-${justify}` : null,
      align ? `l-layout-align-${align}` : null,
      wrap ? 'l-layout-flex-wrap' : null,
      className,
    ].join(' ')}
    style={{ gap: gap ?? '1rem', height: '100%' }}
  >
    {children ?? null}
  </div>
)

export default FlexColumn
