import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ShareCollectionParameter } from '../middleware/shareCollection'

export const shareCollection = async (
  params: Readonly<ShareCollectionParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/collections/${params.collectionId}/share`,
    HTTPMethod.POST,
    options
  )

  request.setBody(params.shares)

  return request.make()
}
