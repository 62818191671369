import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { ChatAPI } from '../api'
import { EmptyReduxChatState, ReduxChatState, ReduxChatUserEntity } from '../types'

export interface ListUsersAPIResponse extends LuruAPIResponse {
  data: Array<ReduxChatUserEntity>
}

export type ListUsersParameter = {
  forceRefresh?: boolean
}

export const listUsers = {
  action: createAsyncThunk<
    ListUsersAPIResponse['data'],
    ListUsersParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ListUsersAPIResponse['metadata']
      rejectedMeta: ListUsersAPIResponse['metadata']
    }
  >(
    'chat/listUsers',
    async ({ forceRefresh }, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await ChatAPI.listUsers(
          { forceRefresh },
          {
            signal,
          }
        )) as ListUsersAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        return rejectWithValue({ message: (e as Error)?.message ?? 'Error retrieving chat users' }, null)
      }
    },
    {
      condition: (arg, { getState }) => {
        var chatState = getState().chat
        var { status } = chatState

        if (status.users === EntityStatus.Loading) {
          return false
        }

        if (status.users === EntityStatus.Loaded && !arg.forceRefresh) {
          return false
        }

        return true
      },
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxChatState>) {
    builder.addCase(listUsers.action.pending, (state, action) => {
      state.status.users = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxChatState>) {
    builder.addCase(listUsers.action.fulfilled, (state, action) => {
      state.status.users = EntityStatus.Loaded
      state.userEntities = { ...EmptyReduxChatState.userEntities }
      action.payload.forEach(
        (user) =>
          (state.userEntities[user.id] = {
            ...user,
            handle: user.handle.replace(/^<at>/g, '').replace(/ UPN<\/at>$/g, ''),
          })
      )

      if (!state.refreshedAt) {
        state.refreshedAt = { ...EmptyReduxChatState.refreshedAt }
      }

      state.refreshedAt.users = new Date().toISOString()
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxChatState>) {
    builder.addCase(listUsers.action.rejected, (state, action) => {
      state.status.users = EntityStatus.ErrorLoading
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxChatState>) {
    listUsers.addPendingCase(builder)
    listUsers.addFulfilledCase(builder)
    listUsers.addRejectedCase(builder)
  },
}
