import { LuruReduxStore } from '../../../app/store'
import { getSpecificityForCrmObject } from '../../noteTemplates/helpers/getSpecificityForCrmObject'
import { hasEmptyFilter } from '../../noteTemplates/helpers/hasEmptyFilter'
import { ReduxNoteTemplateEntity } from '../../noteTemplates/types'
import { NotesMiddleware } from '../middleware'
import { ReduxNoteEntity } from '../types'

export interface NoteTemplateMatchResult {
  template: ReduxNoteTemplateEntity
  specificity: number
}

export const getMatchingNoteTemplates = async (noteId: string) => {
  var state = LuruReduxStore.getState()
  var noteTemplates = Object.values(state.noteTemplates.entities)
    .map((entity) => entity.data)
    .filter((e) => Boolean(e)) as Array<ReduxNoteTemplateEntity>
  var crmTemplateMatches: Array<NoteTemplateMatchResult> = []
  var generalTemplateMatches = noteTemplates
    .filter((entity) => hasEmptyFilter(entity))
    .map((entity) => ({
      template: entity,
      specificity: 0,
    }))
  var note = (await LuruReduxStore.dispatch(NotesMiddleware.fetchNote.action({ noteId }))
    .unwrap()
    .then((res) => res)
    .catch((error) => console.warn(error))) as ReduxNoteEntity | undefined

  if (Array.isArray(note?.connections) && note?.connections && (note?.connections?.length ?? 0) > 0) {
    for (let connection of note?.connections) {
      for (let template of noteTemplates) {
        let specificity = await getSpecificityForCrmObject(template, connection)

        if (specificity !== -1) {
          crmTemplateMatches.push({
            template,
            specificity,
          })
        }
      }
    }
  }

  var unsortedResults = generalTemplateMatches.concat(crmTemplateMatches)
  var sortedResults = unsortedResults.sort((r1, r2) => r2.specificity - r1.specificity)

  return sortedResults
}
