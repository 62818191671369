import { RootState } from '../../../app/store'
import { ReduxWorkflowsStateTTL } from '../types'

export const isWorkflowsCacheValid = (state: RootState) =>
  !Boolean(state.workflows.refreshedAt) ||
  new Date().valueOf() -
    (state.workflows.refreshedAt
      ? new Date(state.workflows.refreshedAt).valueOf()
      : 0) <
    ReduxWorkflowsStateTTL
