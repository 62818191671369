// 3rd party components
import json5 from 'json5'

// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

// Own libraries
import { createNote as createNoteAPI } from '../api/createNote'
import Utils from '../../../utils/Utils'
import { CreateNoteParameter, NoteSyncState, ReduxNoteEntity, ReduxNotesState } from '../types'
import { EntityStatus, LuruAPIResponse, LuruEntryPoint } from '../../../app/types'
import { AppDispatch, RootState } from '../../../app/store'
import LuruError from '../../LuruError'
import { SorProvider } from '../../users/types'
import { CalendarProvider } from '../../user/types'
import LuruUser from '@/domain/users/LuruUser'

export interface CreateNoteActionParameter extends CreateNoteParameter {
  draftNote?: boolean
  duplicateFromNoteId?: string
  baseNoteId?: string
  useSorConnection?: SorProvider
}

export interface CreateNoteAPIResponse extends LuruAPIResponse {
  data: ReduxNoteEntity
}

export const createNote = {
  action: createAsyncThunk<
    CreateNoteAPIResponse['data'],
    CreateNoteActionParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | CreateNoteAPIResponse['metadata']
      rejectedMeta: null | CreateNoteAPIResponse['metadata']
    }
  >('notes/createNote', async (query, { getState, fulfillWithValue, signal, rejectWithValue }) => {
    // Callers can indicate if they want to create a draft note, one which is
    // not saved on the server on create.  For these draft notes, a UUID will
    // be generated by frontend.  There won't be an API call (POST) to create
    // the note
    var draftNote = query.draftNote ?? false

    // If title or body not given, and a duplicateFromNoteId is given, we will
    // use the title and body, if required, of note whose id=duplicateFromNoteId
    var sourceNoteId: string | undefined = query.duplicateFromNoteId
    var sourceNote = sourceNoteId ? getState().notes?.entities[sourceNoteId]?.data : undefined
    var title = query.title ?? (sourceNote ? sourceNote.title : undefined)
    var body = query.body ?? (sourceNote ? sourceNote.body : undefined)
    var source = query?.source ?? (LuruUser.getCurrentEntryPoint() as LuruEntryPoint)

    if (!title || !body) {
      return rejectWithValue(
        {
          error_code: 0,
          message: 'Mandatory parameters missing',
          description: 'Mandatory parameters title and body not available to create note',
          traceback: '',
        },
        null
      )
    }
    // Callers can give the connections required to be added to note upon
    // creation
    var connections = query.connections ?? undefined
    if (connections) {
      for (let i = 0; i < connections.length; i++) {
        if (connections[i].sor === CalendarProvider.GCAL) {
          let meetingId = connections[i].sor_record_id
          let meetingURI = getState().meetings?.entities?.[meetingId]?.data?.external_url
          if (meetingURI) {
            let meetingURIData = { external_url: meetingURI }
            if (connections[i].data) {
              meetingURIData = { ...json5.parse(connections[i].data ?? '{}'), ...meetingURIData }
            }
            connections[i].data = json5.stringify(meetingURIData)
          }
        }
      }
    }

    // Callers can specify an existing connection (to an SOR, from an existing
    // note, whose id=baseNoteId) to be added to the note to be created.
    // useSorConnection is the SOR ID to be used from existing connections
    // of baseNoteId
    var baseNoteId = query.baseNoteId
    var useSorConnection = query.useSorConnection ?? undefined

    if (useSorConnection && baseNoteId) {
      let baseNote = getState().notes?.entities[baseNoteId] ?? null
      let baseConnections = baseNote?.data?.connections ?? null
      let existingConnection = baseConnections?.find((connection) => connection.sor === useSorConnection) ?? null

      if (existingConnection) {
        connections = [existingConnection]
      }
    }

    if (draftNote) {
      let createdTS = new Date().toISOString()
      // If draftNote flag is true, we create note in frontend and return
      return fulfillWithValue(
        {
          note_id: Utils.generateUUID(),
          sync_state: NoteSyncState.PRIVATE,
          title,
          body,
          source: source,
          template_id: null,
          created_at: createdTS,
          updated_at: createdTS,
          created_by: null,
          updated_by: null,
          connections,
        },
        null
      )
    }

    try {
      var response = (await createNoteAPI({ title, body, connections, source }, { signal })) as CreateNoteAPIResponse

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(createNote.action.pending, (state, action) => {
      // TODO: If there are multiple parallel createNote requests,
      // we need to keep track of each of them.  Following status update
      // will need to change to an object with 'requestId' being the key
      state.status = EntityStatus.Creating
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(createNote.action.fulfilled, (state, action) => {
      var createdNote = { ...action.payload }
      var noteId = createdNote.note_id

      state.status = EntityStatus.Loaded
      if (!state.entities) {
        state.entities = {}
      }

      state.entities[noteId] = {
        status: EntityStatus.Loaded,
        data: createdNote,
        isDraft: Boolean(action.meta.arg.draftNote),
      }

      var currEntity = state.entities[noteId]

      if (currEntity && typeof createdNote.body !== 'string') {
        currEntity.data.body = json5.stringify(currEntity.data.body)
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(createNote.action.rejected, (state, action) => {
      // TODO: If there are multiple parallel createNote requests,
      // we need to keep track of each of them.  Following status update
      // will need to change to an object with 'requestID' being the key
      state.status = EntityStatus.ErrorCreating
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    createNote.addPendingCase(builder)
    createNote.addFulfilledCase(builder)
    createNote.addRejectedCase(builder)
  },
}
