import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { CreateV2WorkflowParameter } from '../types'

export const createV2Workflow = async (params: CreateV2WorkflowParameter, options: LuruAPIRequestOptions) => {
  var request = new LuruAPIRequest('/api/workflows', HTTPMethod.POST, options)

  request.setBody({
    ...params,
  })

  return request.make()
}
