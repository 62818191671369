import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { WorkflowSorName } from '../types'

export interface FetchWorkflowSchemaParameter {
  sor?: WorkflowSorName
}

export const fetchWorkflowSchema = async (params: FetchWorkflowSchemaParameter, options?: LuruAPIRequestOptions) => {
  var request = new LuruAPIRequest(
    params?.sor ? `/api/workflows/schemas/${params.sor}` : `/api/workflows/schemas`,
    HTTPMethod.GET,
    options
  )

  return request.make()
}
