import React from 'react'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import TypeAheadSearchSelectBox from '../../../TypeAheadSearchSelectBox'
import CrmObjectSelectBoxEventHandler from './CrmObjectSelectBoxEventHandler'
import styles from './styles.module.css'
import LuruUser from '../../../../domain/users/LuruUser'
import { CRMProvider } from '../../../../features/user/types'
// import { CrmRecordType } from '../../../../features/crm/types'

export interface CrmObjectSelectBoxProps {
  containerClassName?: string
  // Optional class name
  className?: string
  // Disabled?
  disabled?: boolean
  // An optional value for pre-filling
  value?: string
  // Optional Luru role
  luruRole?: string
  // Readonly?
  readOnly?: boolean
  // Object name in CRM/SOR
  sorObjectName: string
  // Parent element selector for menu element (for stacking correctly)
  menuParentSelector?: string
  //// Event handlers
  // Event handler for selecting item, Pass data also, We may need it to identify sor_object_name in case of Poly Ref Objects such as Lead-> User,Group
  onSelectItem: (sorRecordId: string, sorRecordName: string, srcElement: HTMLElement, data?: any) => void
  onDeleteItem?: () => void

  // Auto focus
  autoFocus?: boolean
}

interface CrmObjectSelectBoxRefs {
  container: React.RefObject<HTMLDivElement>
  typeAheadSearchSelectBoxRef: React.RefObject<TypeAheadSearchSelectBox>
}

export default class CrmObjectSelectBox extends React.Component<CrmObjectSelectBoxProps> {
  props: CrmObjectSelectBoxProps
  eventHandler: CrmObjectSelectBoxEventHandler
  componentRefs: CrmObjectSelectBoxRefs
  #isSearchOn: boolean
  #crmId?: CRMProvider

  constructor(props: CrmObjectSelectBoxProps) {
    super(props)
    this.#isSearchOn = false
    this.props = props
    this.eventHandler = new CrmObjectSelectBoxEventHandler(this)
    this.componentRefs = { container: React.createRef(), typeAheadSearchSelectBoxRef: React.createRef() }
    this.#crmId = LuruUser.getCurrentUserCrmName()
  }

  render = () => (
    <div
      ref={this.componentRefs.container}
      className={[this.props.containerClassName || ''].join(' ')}
      data-luru-role='crm-object-select-box-container'
    >
      <TypeAheadSearchSelectBox
        menuParentSelector={this.props.menuParentSelector}
        className={[this.props.className].join(' ')}
        value={this.props.value}
        onSelectItem={this.eventHandler.handlers.onSelectItem}
        searchProvider={this.searchRecords.bind(this)}
        luruRole={this.props.luruRole}
        disabled={this.props.readOnly || this.props.disabled}
        onDeleteItem={this.props.onDeleteItem}
        searchItemClassName={this.#crmId === CRMProvider.HUBSPOT ? styles.searchItemHS : ''}
        ref={this.componentRefs.typeAheadSearchSelectBoxRef}
      />
    </div>
  )

  async searchRecords(query: string) {
    var crmRecordType = CrmRecord.getCrmRecordType(this.props.sorObjectName)
    var icon = crmRecordType ? CrmRecord.getIcon(crmRecordType) : undefined
    var isPolyRefObject = this.props.sorObjectName?.split(',').length > 1
    return CrmRecord.searchRecords(this.props.sorObjectName, query).then((response) =>
      response?.map((i: { sor_record_id: string; sor_record_name: string; sor_object_name: string }) => ({
        key: i?.sor_record_id,
        name: i?.sor_record_name
          ? isPolyRefObject
            ? `${i?.sor_record_name} (${i.sor_object_name})`
            : i?.sor_record_name
          : 'No Name',
        icon,
        data: i, // Pass data also, We may need it to identify sor_object_name in case of Poly Ref Objects such as Lead-> User,Group
      }))
    )
  }
}
