import { useContext, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clearSearch } from '../features/meetings/meetingsSlice'
import { getDefaultMeetings } from '../features/meetings/selectors/getDefaultMeetings'
import { NotesSliceSelectors } from '../features/notes/selectors'
import { NotesMiddleware } from '../features/notes/middleware'
import { scrollYToElement } from '../domutils/utils'
import { useGetSetState, useThreeWayClickHandler } from '../hooks/luru_hooks'
import RecordLinkStatusButton from './RecordLinkStatusButton'
import TypeAheadSearchBox from './TypeAheadSearchBox'
import WeekBrowser, { ShortcutName } from './WeekBrowser'
import OvalButton from './OvalButton'
import styles from './css/RecordLinkControl.module.css'
import meetingStyles from './css/MeetingLinkControl.module.css'
import linkStyles from './css/RecordLinkStatusButton.module.css'
import meetingIcon from '../images/fluent/calendar_ltr.svg'
import { trackEvent } from '../analytics/Ga'
import AppComponentsContext from './AppComponents/AppComponentsContext'
import { MeetingsMiddleware } from '../features/meetings/middleware'
import { DateUtils } from '../utils/dateUtils'
import { EntityStatus } from '../app/types'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'
import { useAppSelector } from '@/app/hooks'
import Tooltip from '@/components/Tooltip'
import warningIcon from '@/images/fluent/warning.svg'
import { CalendarProvider } from '@/features/user/types'

export default function MeetingLinkControl(props) {
  //// Component props and derived props like values
  var searchAction = null
  const callerId = 'home/MeetingLinkControl' + (props.noteID ? '/' + props.noteID : '')
  const MenuMode = {
    SEARCH: 'search',
    SELECT: 'select',
  }
  const calendarErrorCode = useAppSelector(
    (state) => state.user?.data?.userSettings?.connectedCalendar?.errorCode || state.meetings?.error?.error_code
  )
  const context = useContext(AppComponentsContext)
  Object.freeze(MenuMode)
  const { showToast } = useLuruToast()

  //// Component state
  // var isComponentUnmounted = false
  let state = {
    search: useSelector((state) =>
      typeof state.meetings?.search === 'object' ? state.meetings.search[callerId] ?? null : null
    ),
    defaultMeetings: useSelector(getDefaultMeetings()),
    calendarId: useSelector((state) => state.user?.data?.userSettings?.connectedCalendar?.name),
    // displayedMeetings: useGetSetState(useSelector(getDefaultMeetings())),
    week: useGetSetState(DateUtils.getEndOfWeek()),
    dateFilters: useGetSetState([new Date().toISOString().slice(0, 10).replaceAll('-', '')]),
    searchText: useGetSetState(''),
    mode: useGetSetState(MenuMode.SEARCH),
    selectedIndex: useGetSetState(-1),
  }
  state.linkedMeeting = useSelector(NotesSliceSelectors.getSORConnection(props.noteID, state.calendarId, callerId))
  state.displayedMeetings = useGetSetState(
    state.search && state.search.status === EntityStatus.Loaded ? state.search.results : state.defaultMeetings
  )

  const dispatch = useDispatch()

  const refs = {
    popup: useRef(null),
    control: useRef(null),
    searchBox: useRef(null),
    searchResults: useRef(null),
    actionIcon: useRef(null),
  }

  const onConnectCalendar = () => {
    context?.connectCalendarModal?.current?.showModal()
  }

  const onReConnectCalendar = () => {
    context?.connectCalendarModal?.current?.showModal(state.calendarId)
  }

  //// Component views
  function render() {
    if (!state.calendarId) {
      return renderCalendarConnectButton()
    } else if (calendarErrorCode === 10104 || calendarErrorCode === 10100 || calendarErrorCode === 10000) {
      return renderCalendarPermissionErrorConnectButton()
    } else {
      return renderMeetingControl()
    }
  }

  function renderCalendarConnectButton() {
    return (
      <div className={linkStyles.main}>
        <img src={meetingIcon} alt='Connect your calendar' className={linkStyles.meetingIcon} />
        <OvalButton
          onClick={onConnectCalendar}
          classes={[
            meetingStyles.connectButton,
            props.embedded ? meetingStyles.connectButtonEmbedded : meetingStyles.connectButtonRegular,
          ]}
        >
          Connect your calendar
        </OvalButton>
      </div>
    )
  }

  function renderCalendarPermissionErrorConnectButton() {
    return (
      <div className={linkStyles.main}>
        <img src={meetingIcon} alt='Reconnect your calendar' className={linkStyles.meetingIcon} />
        <OvalButton
          onClick={onReConnectCalendar}
          classes={[
            meetingStyles.connectButton,
            styles.warningStatusButton,
            props.embedded ? meetingStyles.connectButtonEmbedded : meetingStyles.connectButtonRegular,
          ]}
        >
          <span>Reconnect your calendar</span>
          <Tooltip
            label={`There was a problem connecting to your ${
              state.calendarId === CalendarProvider.O365CAL ? 'Microsoft' : 'Google'
            } Calendar.`}
            placement='bottom'
          >
            <img src={warningIcon} alt='warning' className={styles.warningIcon} />
          </Tooltip>
        </OvalButton>
      </div>
    )
  }

  function renderMeetingControl() {
    return (
      <div className={styles.parent} ref={refs.control}>
        <RecordLinkStatusButton
          embedded={props.embedded}
          key='meeting'
          link={state.linkedMeeting}
          linkType='meeting'
          ref={refs.actionIcon}
        />
        <div className={styles.popup} ref={refs.popup}>
          <TypeAheadSearchBox
            placeholder='Search for meeting to link'
            ariaLabel='Search for meeting to link with note'
            searchText={state.searchText.get()}
            onKeyDown={eventHandlers.onSearchBoxKeyDown}
            onTriggerSearch={eventHandlers.onTriggerSearch}
            onClearSearch={eventHandlers.onClearSearch}
            ref={refs.searchBox}
            loading={state.search?.status === EntityStatus.Loading}
          />
          {/* <ProgressBar inProgress={state.search?.status === "loading"} /> */}
          <WeekBrowser
            week={state.week.get()}
            onWeekChange={eventHandlers.onWeekChange}
            dateFilters={state.dateFilters.get()}
            multiFilter={false}
            onDateFilterChange={eventHandlers.onDateFilterChange}
          />
          <div className={styles.results}>{renderMeetingsListArea()}</div>
          <footer>
            <div>Esc: Cancel</div>
            <div>
              {'\u2191'} or {'\u2193'} : Navigate
            </div>
          </footer>
        </div>
      </div>
    )
  }

  function renderMeetingsListArea() {
    // Decide what to display based on search status
    switch (state.search?.status) {
      case EntityStatus.Loading:
        return (
          <div className={styles.noresults}>
            {state.search.status === EntityStatus.Loading ? 'Fetching...' : 'No results'}
          </div>
        )

      case EntityStatus.ErrorLoading:
        return <div className={styles.error}>{state.search?.error?.description ?? 'Error: Please retry later'}</div>

      default:
        return renderMeetingsList()
    }
  }

  function renderMeetingsList() {
    let meetings = state.displayedMeetings.get()
    if (!Array.isArray(meetings) || meetings?.length === 0) {
      return <div className={styles.noresults}>No meetings</div>
    }

    meetings = filterMeetings(meetings, state.dateFilters.get())

    return (
      <ul className={[styles.loaded, meetingStyles.results].join(' ')} ref={refs.searchResults}>
        {meetings?.map((item, index) => (
          <li
            key={`result-${index}`}
            onClick={onCreateNoteMeetingConnection}
            className={item?.meeting_id === state.linkedMeeting?.connection?.sor_record_id ? styles.selected : ''}
            data-sor-result={true}
            data-sor-record-id={item.meeting_id}
            data-sor-record-name={item.subject}
            data-day-index={new Date(item.start_time).toISOString().slice(0, 10).replaceAll('-', '')}
            data-result-index={index}
          >
            <span className={meetingStyles.subject}>{item.subject}</span>
            <span className={meetingStyles.time}>
              {new Intl.DateTimeFormat(undefined, {
                day: 'numeric',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h23',
              }).format(new Date(item.start_time))}
            </span>
          </li>
        ))}
      </ul>
    )
  }

  //// Component commands
  // Hide menu and execute all side-effects
  function hideMenu() {
    if (refs.popup.current?.classList.contains(styles.visible)) {
      // Redux reset
      searchAction?.abort()
      dispatch(clearSearch(callerId))

      // Own component state reset
      clearDateFilters()
      state.week.set(DateUtils.getEndOfWeek())
      state.searchText.set('')
      state.displayedMeetings.set(state.defaultMeetings)
      state.selectedIndex.set(-1)
      refs.searchBox.current.dispatchEvent(new CustomEvent('clear'))

      // Own component style reset
      refs.popup.current.classList.remove(styles.visible)
    }
  }

  // Toggle menu
  function toggleMenu() {
    if (refs.popup.current.classList.contains(styles.visible)) {
      // Hide menu and execute all side effects
      hideMenu()
    } else {
      // Add style to make popup visible
      refs.popup.current.classList.add(styles.visible)
      // Set default meetings as meetings list
      state.displayedMeetings.set(state.defaultMeetings)
      state.selectedIndex.set(-1)
      // Set focus on search box
      setTimeout(() => refs.searchBox.current.focus())
    }
  }

  // Handle choosing of an SOR from search results
  function onCreateNoteMeetingConnection(e) {
    let resultItem = e.target.closest('li'),
      objData = resultItem.dataset

    trackEvent('link_meeting', 'Manual')
    dispatch(
      NotesMiddleware.createNoteConnection.action({
        key: callerId,
        noteId: props.noteID,
        sorObject: {
          sor: state.calendarId,
          sor_record_id: objData.sorRecordId,
          sor_record_name: objData.sorRecordName,
          sor_object_name: 'Event',
        },
      })
    )
      .then(async (response) => {
        if (response.error) {
          throw response.error
        }
        hideMenu()
        props.onMeetingLinked(objData.sorRecordId)
      })
      .catch((error) => {
        if (error?.message === 'Rejected') {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Error creating connection',
            severity: 'error',
          })
        } else {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: error.message,
            severity: 'error',
          })
        }
      })
    e.preventDefault()
  }

  // Trigger an asynchronous search operation
  function triggerSearch(query) {
    var weekStart = query?.weekStart ?? state.week.get()
    var queryText = query?.searchText ?? state.searchText.get()

    searchAction = dispatch(
      MeetingsMiddleware.searchMeetings.action({
        key: callerId,
        q: queryText,
        time_min: DateUtils.toAPIDateString(DateUtils.getStartOfWeek(weekStart)),
        time_max: DateUtils.toAPIDateString(DateUtils.getEndOfWeek(weekStart)),
        include_notes: true,
      })
    )

    searchAction
      .unwrap()
      .then((response) => {
        state.displayedMeetings.set(filterMeetings(response, query?.dateFilters ?? state.dateFilters.get(), weekStart))
        state.selectedIndex.set(-1)
        // Set focus on search box
        setTimeout(() => refs.searchBox.current?.focus())
      })
      .catch(() => {})
  }

  function clearDateFilters() {
    state.dateFilters.set([new Date().toISOString().slice(0, 10).replaceAll('-', '')])
  }

  function filterMeetings(meetings, filters, weekStart = state.week.get()) {
    const thisWeekFilters =
      filters?.filter(
        (item) =>
          new Date(DateUtils.getValidDateFormate(item)) <= DateUtils.getEndOfWeek(weekStart) &&
          new Date(DateUtils.getValidDateFormate(item)) >= DateUtils.getStartOfWeek(weekStart)
      ) ?? []

    if (thisWeekFilters.length > 0 && meetings) {
      meetings = meetings.filter((item) =>
        thisWeekFilters.includes(new Date(item.start_time).toISOString().slice(0, 10).replaceAll('-', ''))
      )
    }
    if (!meetings) {
      // console.warn(`filterMeetings:meetings is null; this is not expected`)
    }
    return meetings
  }

  //// Component event handlers (these are children-facing event handlers)
  const eventHandlers = {
    // Handler for trigger search event from type ahead search box component
    onTriggerSearch: (e) => {
      if (e.key === 'Enter' && state.mode.get() === MenuMode.SELECT) {
        // Choose the meeting here based on current selection
        onCreateNoteMeetingConnection({
          target: refs.popup.current.querySelector(`li[data-result-index="${state.selectedIndex.get()}"]`),
          preventDefault: e.preventDefault,
        })
      } else {
        const queryText = e.data.searchText
        state.searchText.set(queryText)
        state.mode.set(MenuMode.SEARCH)
        if (queryText.trim() === '') {
          dispatch(clearSearch(callerId))
          state.displayedMeetings.set(state.defaultMeetings)
        } else {
          triggerSearch({ searchText: queryText })
        }
      }
    },

    // Handler for keydown event from type ahead search box component
    onClearSearch: (e) => {
      if (e.key && e.key === 'Escape') {
        hideMenu()
      } else {
        dispatch(clearSearch(callerId))
        state.displayedMeetings.set(state.defaultMeetings)
        state.selectedIndex.set(-1)
      }
    },

    // Handler for change filter event from week browser component
    onDateFilterChange: (newFilters) => {
      let meetings =
        state.search && state.search.status === EntityStatus.Loaded ? state.search.results : state.defaultMeetings
      state.dateFilters.set(newFilters)
      state.displayedMeetings.set(filterMeetings(meetings, newFilters))
    },

    // Handler for change week event from week browser component
    onWeekChange: (e) => {
      let newFilters = []
      if (e.data?.source === ShortcutName.TODAY) {
        let meetings =
          state.search && state.search.status === EntityStatus.Loaded ? state.search.results : state.defaultMeetings
        newFilters = [new Date().toISOString().slice(0, 10).replaceAll('-', '')]
        state.dateFilters.set(newFilters)
        state.displayedMeetings.set(filterMeetings(meetings, newFilters))
      } else if (e.data?.source === ShortcutName.NEXT_WEEK) {
        // User has clicked next week; add 7 to filtered dates
        for (let date of state.dateFilters.get()) {
          newFilters.push(
            DateUtils.formatToShortString(
              new Date(DateUtils.dateFromShortString(date).valueOf() + 7 * DateUtils.MILLISECS_PER_DAY)
            )
          )
        }
        state.dateFilters.set(newFilters)
      } else if (e.data?.source === ShortcutName.PREVIOUS_WEEK) {
        // User has cliecked prev week; subtract 7 days from filtered days
        for (let date of state.dateFilters.get()) {
          newFilters.push(
            DateUtils.formatToShortString(
              new Date(DateUtils.dateFromShortString(date).valueOf() - 7 * DateUtils.MILLISECS_PER_DAY)
            )
          )
        }
        state.dateFilters.set(newFilters)
      }

      if (!e.data?.week?.isSame(state.week.get())) {
        state.week.set(e.data.week)
        setTimeout(triggerSearch({ weekStart: e.data.week, dateFilters: newFilters }))
      }
      e.preventDefault && e.preventDefault()
    },

    // Handler for capturing keyboard events from search box.  Used for navigating
    // search results list items
    onSearchBoxKeyDown: (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        state.mode.set(MenuMode.SELECT)
        let currentIndex = state.selectedIndex.get()
        if (e.key === 'ArrowDown') {
          state.selectedIndex.set((currentIndex + 1) % state.displayedMeetings.get().length)
        } else {
          state.selectedIndex.set(currentIndex === 0 ? state.displayedMeetings.get().length - 1 : currentIndex - 1)
        }
        // Set the state for currently highlighted item
        e.preventDefault()
      }
    },

    // Handler for removing a linked meeting
    onDeleteNoteMeetingConnection: (e) => {
      const connectionId = state.linkedMeeting?.connection?.connection_id
      const sorRecordId = state.linkedMeeting?.connection?.sor_record_id
      const noteId = props.noteID

      showToast({
        id: ToastId.NOTES_EDITOR_TOAST_ID,
        message: 'Deleting linked meeting',
        isLoading: true,
      })
      trackEvent('unlink_meeting', '')

      dispatch(
        NotesMiddleware.deleteNoteConnection.action({
          key: callerId,
          noteId,
          connectionId,
          sorRecordId,
        })
      )
        .then((response) => {
          // NOTE: Unlike removing CRM link, there is no need to inform editor
          // that meeting has been removed from note

          // Show message to user
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Deleted linked meeting',
            severity: 'success',
          })
        })
        .catch((error) => {
          console.warn(error)
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Error deleting linked meeting',
            severity: 'error',
          })
        })
    },
  }

  //// Component setup - useEffect, etc. are set up here
  function useSetup() {
    // Set up click handlers for toggle/hide menu
    useThreeWayClickHandler(
      { outsideRef: refs.control, insideRef: refs.popup },
      {
        outsideClick: hideMenu,
        betweenClick: (e) => {
          let actionIcon = refs.actionIcon.current
          let isAction =
            e.clientX >= actionIcon.getBoundingClientRect().left &&
            e.clientX <= actionIcon.getBoundingClientRect().right &&
            e.clientY >= actionIcon.getBoundingClientRect().top &&
            e.clientY <= actionIcon.getBoundingClientRect().bottom
          if (isAction && state.linkedMeeting.connection?.connection_id) {
            eventHandlers.onDeleteNoteMeetingConnection(e)
          } else {
            if (!props.readonly) toggleMenu()
          }
        },
      }
    )

    // Scroll to the result item
    useEffect(() => {
      let selection = refs.popup.current?.querySelector(`.${styles.selected?.replace('+', '\\+')}`)
      if (selection) {
        let container = refs.popup.current.querySelector(`.${styles.results?.replace('+', '\\+')}`)
        scrollYToElement(selection, container)
      }
    })

    // Now the meeting data is not loaded during initial load of the webapp
    // So we fetch the meeting data for the current week
    useEffect(() => {
      if (state.defaultMeetings?.length > 0) {
        return
      }

      // isComponentUnmounted = false
      triggerSearch()

      // Cancel pending requests on unmount
      return () => {
        searchAction?.abort()
      }
    }, [])
  }

  useSetup()

  return render()
}
