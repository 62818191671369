// Redux
import { createSlice } from '@reduxjs/toolkit'
import { NoteTemplatesSliceReducers as reducers } from './reducers'
import { UserSliceActions } from '../user/userSlice'
import { NoteTemplatesMiddleware } from './middleware'
import { EmptyNoteTemplatesState } from './types'
import { NoteTemplatesSliceExtraReducers as extraReducers } from './extraReducers'

export const SystemTemplateUserId = '384d3b6a-811f-11ec-a8a3-0242ac120002'

const noteTemplatesSlice = createSlice({
  name: 'templates',
  initialState: EmptyNoteTemplatesState,
  reducers,
  extraReducers: (builder) => {
    NoteTemplatesMiddleware.createNoteTemplate.addAllCases(builder)
    NoteTemplatesMiddleware.fetchNoteTemplate.addAllCases(builder)
    NoteTemplatesMiddleware.updateNoteTemplate.addAllCases(builder)
    NoteTemplatesMiddleware.shareNoteTemplate.addAllCases(builder)
    NoteTemplatesMiddleware.hideNoteTemplate.addAllCases(builder)
    NoteTemplatesMiddleware.useNoteTemplate.addAllCases(builder)
    NoteTemplatesMiddleware.deleteNoteTemplate.addAllCases(builder)
    NoteTemplatesMiddleware.listNoteTemplates.addAllCases(builder)
    NoteTemplatesMiddleware.unhideNoteTemplate.addAllCases(builder)

    // Sign-in and sign-out user flows
    builder
      .addCase(UserSliceActions.signInUser, extraReducers.userSliceSignInUser)
      .addCase(UserSliceActions.signOutUser, extraReducers.userSliceSignOutUser)
  },
})

export const NoteTemplatesSliceActions = noteTemplatesSlice.actions

// Reducers for this slice
export default noteTemplatesSlice.reducer
