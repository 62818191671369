import * as React from 'react'
import { LuruCollectionsQuickAccessBarConnectedProps } from '.'
import LuruCollectionsQuickAccessBarEventHandler from './LuruCollectionsQuickAccessBarEventHandler'
import LuruButton from '../../../ui/LuruButton'
import { EntityStatus } from '../../../../app/types'
import styles from './styles.module.css'
import LuruCollectionsSelectBox from '../LuruCollectionsSelectBox'
import LuruFieldSetChooser from '../LuruFieldSetChooser'
import { LuruFieldSetChooserOwnProps } from '../LuruFieldSetChooser/LuruFieldSetChooserComponent'
import { ReduxCollectionEntity } from '../../../../features/collections/types'
import { CrmRecordType } from '../../../../features/crm/types'

export interface LuruCollectionsQuickAccessBarOwnProps {
  selectedCollectionId?: string
  crmRecordType: CrmRecordType
  subdued?: boolean
  useRecentUsedCollectionAsDefaultSelected?: boolean
  onChooseCollection?: (collectionId?: string, collection?: ReduxCollectionEntity) => void
  containerClassName?: string
  title?: string
  showTitle?: boolean
  onCollectionLoaded?: (recentCollectionId?: string) => void
  luruFieldSetChooserProps?: LuruFieldSetChooserOwnProps
  hidefieldSetChooser?: boolean
}

export type LuruCollectionsQuickAccessBarProps = LuruCollectionsQuickAccessBarOwnProps &
  LuruCollectionsQuickAccessBarConnectedProps

export interface LuruCollectionsQuickAccessBarState {
  selectedCollectionId?: string
  recentCollections?: Array<ReduxCollectionEntity>
}

export default class LuruCollectionsQuickAccessBarComponent extends React.Component<
  LuruCollectionsQuickAccessBarProps,
  LuruCollectionsQuickAccessBarState
> {
  props: LuruCollectionsQuickAccessBarProps
  state: LuruCollectionsQuickAccessBarState
  eventHandler: LuruCollectionsQuickAccessBarEventHandler

  constructor(props: LuruCollectionsQuickAccessBarProps) {
    super(props)
    this.props = props
    this.state = {
      selectedCollectionId: this.props.selectedCollectionId,
      recentCollections: [],
    }
    this.eventHandler = new LuruCollectionsQuickAccessBarEventHandler(this)
  }

  render() {
    const { collectionsStatus } = this.props
    const { recentCollections } = this.state

    if (collectionsStatus === EntityStatus.ErrorLoading) {
      return <div className={styles.collectionsContainer}>{this.#renderErrorState()}</div>
    }

    if (collectionsStatus === EntityStatus.Loading) {
      return <div className={styles.collectionsContainer}>{this.#renderLoadingState()}</div>
    }

    return (
      <div className={this.props.containerClassName}>
        {this.props.showTitle && <p className={styles.title}>{this.props.title || 'Quick Select'}</p>}

        <div className={styles.collectionsChooser}>
          {recentCollections?.filter(Boolean).map((c, ix) => (
            <button
              key={c.collection_id ?? ix}
              className={[
                styles.collectionChooserButton,
                this.state.selectedCollectionId === c.collection_id ? styles.selected : null,
              ].join(' ')}
              type='button'
              onClick={() => (c.collection_id ? this.eventHandler.handlers.onChooseCollection(c.collection_id) : null)}
              title={c.name}
            >
              {c.name}
            </button>
          ))}
          <LuruCollectionsSelectBox
            crmRecordType={this.props.crmRecordType}
            onChooseCollection={(id?: string) => id && this.eventHandler.handlers.onChooseCollection(id)}
            subdued={true}
            classes={[styles.collectionChooserComponent]}
          />
          {!this.props.hidefieldSetChooser && (
            <div className={styles.fieldChooserContainer}>
              <LuruFieldSetChooser
                crmRecordType={this.props.crmRecordType}
                useCollections={false}
                classes={[styles.fieldChooserComponent]}
                displayLabel={true}
                checkedAllByDefault={false}
                label='Choose fields'
                align='RIGHT'
                {...this.props.luruFieldSetChooserProps}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  #renderLoadingState() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>Loading collections...</h2>
      </div>
    )
  }

  #renderErrorState() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>Error Loading</h2>
        <LuruButton onClick={this.eventHandler.handlers.onFetchInitialCollections} title='Retry'>
          Try Again
        </LuruButton>
      </div>
    )
  }
}
