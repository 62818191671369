import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import {
  DeleteNoteConnectionAPIResponse,
  DeleteNoteConnectionParameter,
} from '../../notes/middleware/deleteNoteConnection'
import { ReduxMeetingsState } from '../types'

export const notesSliceDeleteNoteConnection: CaseReducer<
  ReduxMeetingsState,
  PayloadAction<
    DeleteNoteConnectionAPIResponse['data'],
    string,
    DeleteNoteConnectionAPIResponse['metadata'] & {
      arg: DeleteNoteConnectionParameter
      requestId: string
      requestStatus: 'fulfilled'
    }
  >
> = (state, action) => {
  var noteId = action.meta.arg.noteId
  var sorRecordId = action.meta.arg.sorRecordId

  if (!state.entities || !(sorRecordId in state.entities)) {
    return
  }

  var meetingId = sorRecordId // This connection was a meeting connection

  if (state.entities[meetingId]?.data?.notes) {
    state.entities[meetingId].data.notes = state.entities[meetingId].data.notes.filter(
      (note) => note.note_id !== noteId
    )
  }
}
