import FlexColumn from '../layout/FlexColumn'
import styles from './Card.module.css'

interface CardProps {
  heading: string
  width?: string
  children: React.ReactNode
  height?: string
}

const Card = ({ heading, width = '24em', height = '15em', children }: CardProps) => (
  <div style={{ width, height }} className={styles.card}>
    <FlexColumn gap='1em'>
      <h4 className='text-lg font-bold'>{heading}</h4>
      <div style={{ height: '100%' }}>{children}</div>
    </FlexColumn>
  </div>
)

export default Card
