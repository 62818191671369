import { HTTPMethod } from '../../../app/types'
import { getAuthUrl } from '../../../domutils/utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DisconnectGCalRequestParams } from '../middleware/disconnectGCal'

export const disconnectGCal = async (
  params: DisconnectGCalRequestParams,
  options: LuruAPIRequestOptions
) => {
  var oAuthKey = 'google-oauth2'
  var disconnectUrl = getAuthUrl() + '/disconnect/' + oAuthKey + '/'
  var request = new LuruAPIRequest(disconnectUrl, HTTPMethod.POST, {
    ...options,
    headers: {
      'X-CSRFToken': params.csrfToken ?? '',
    },
    credentials: 'include',
  })

  return request.make()
}
