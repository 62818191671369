// Redux
import { createAsyncThunk } from '@reduxjs/toolkit'

// CRM base APIs
import { crmApi } from '../crmBaseAPI'

// CRM middleware parent object
import { abortAllRequests } from '../../crmMiddleware'
import { EntityStatus } from '../../../../app/types'

/**
 * @classdesc - Class to manage a CRM's getRecordFields API request
 */
export default class GetPipelineList {
  #crmId = null
  #requestKey = null
  #middlewareFactory = null

  /**
   * Constructor for this class.  Sets up local variables.  Request key is
   * used as the thunk name for Redux purposes.  It is also used to namespace
   * abort controllers object in CRM middleware.
   * @param {CRMBaseMiddlewareFactory} middlewareFactory - A middleware factory
   * which is going to use this instance
   */
  constructor(middlewareFactory) {
    this.#middlewareFactory = middlewareFactory
    this.#crmId = this.#middlewareFactory.getCrmId()
    this.#requestKey = `${this.#crmId}/getPipelineList`
  }

  /**
   * Function to get the portion of the middleware for managing this particular
   * request.  This will be used by the top-level CRM middleware to compose a
   * large middleware object.
   * @returns {Object} - Middleware to manage this request
   */
  getRequestMiddleware() {
    return {
      key: this.#requestKey,
      action: this.#computeAction(),
      abortAllRequests: this.#computeAbortAllRequests(),
      pending: this.#computePendingState(),
      fulfilled: this.#computeFulfilledState(),
      rejected: this.#computeRejectedState(),
    }
  }

  /**
   * Returns a function to initiate a search in CRM.  The thunk itself expects
   * an object as a parameter with two keys: query and objects, which is an
   * array of object names across which to search
   * @return {Object} - Output of reduxjs/toolkit/createAsyncThunk() function
   */
  #computeAction() {
    var thunk = async (_, { getState, signal }) => {
      var storedPipeline = getState().crm?.[this.#crmId]?.pipelines
      var isPipelineAvailable =
        storedPipeline?.status === EntityStatus.Loaded &&
        storedPipeline.data !== null

      try {
        let pipeline = isPipelineAvailable
          ? storedPipeline.data
          : this.#crmId === 'sfdc'
          ? [{ name: 'Default', label: 'Default', id: 'Default' }]
          : await crmApi.getPipelineList({ crmId: this.#crmId }, { signal })

        return pipeline
      } catch (e) {
        console.warn(`${this.#requestKey}: Error while fetching schema`, e)
      }
    }

    thunk = thunk.bind(this)
    return createAsyncThunk(this.#requestKey, thunk)
  }

  /**
   * Compute a function to abort requests for this particular request key
   * @returns {Function} - Function to abort all requests for this request
   */
  #computeAbortAllRequests() {
    let abortFunction = () => abortAllRequests(this.#requestKey)
    abortFunction.bind(this)
    return abortFunction
  }

  /**
   * Compute a pending state handler required by Redux for handling this request
   * @returns {Function} - Pending state handler
   */
  #computePendingState() {
    let pendingStateHandler = (state, action) => {
      const crmId = this.#crmId
      // Initialize objects if necessary
      if (state[crmId] === undefined) {
        state[crmId] = {}
      }
      if (state[crmId].pipelines === undefined) {
        state[crmId].pipelines = {}
      }

      // Check for availability of pipeline list
      let storedPipelines = state[crmId].pipelines

      if (storedPipelines.status !== EntityStatus.Loaded) {
        state[crmId].pipelines = {
          status: EntityStatus.Loading,
        }
      }
    }
    pendingStateHandler = pendingStateHandler.bind(this)
    return pendingStateHandler
  }

  /**
   * Compute a fulfilled handler required by Redux for handling this request
   * @returns {Function} - Fulfilled state handler
   */
  #computeFulfilledState() {
    let fulfilledStateHandler = (state, action) => {
      const crmId = this.#crmId
      if (state[crmId].pipelines?.status !== EntityStatus.Loaded) {
        // Retain attributes of pipelines already present
        // (This is because getPipelineStages API may have 'touched' a pipeline)
        // object to 'fill' it with pipeline stages - however, it might not have
        // had the attributes that getPipelineList could fetch - like name,
        // label, etc.
        let loadedPipelineList = this.#middlewareFactory.normalizePipelineList(
          action.payload
        )

        if (!(state[crmId]?.pipelines?.data instanceof Array)) {
          state[crmId].pipelines.data = []
        }

        const currentPipelineList = [...state[crmId].pipelines.data]

        loadedPipelineList = loadedPipelineList.map((newItem) => {
          let oldItem = currentPipelineList.find((x) => x.id === newItem.id)
          return oldItem ? { ...oldItem, ...newItem } : newItem
        })

        state[crmId].pipelines = {
          status: EntityStatus.Loaded,
          data: loadedPipelineList,
        }
      }
    }

    fulfilledStateHandler = fulfilledStateHandler.bind(this)

    return fulfilledStateHandler
  }

  /**
   * Compute a rejected handler required by Redux for handling this request
   * @returns {Function} - Rejected state handler
   */
  #computeRejectedState() {
    let rejectedStateHandler = (state, error) => {
      if (!error.meta.aborted) {
        const crmId = this.#crmId

        if (state[crmId].pipelines?.status !== EntityStatus.Loaded) {
          state[crmId].pipelines = {
            status: EntityStatus.ErrorLoading,
            error: error,
          }
        }

        console.log(`${this.#requestKey}:rejectedStateHandler:`, error)
        throw new Error(error)
      }
    }
    rejectedStateHandler = rejectedStateHandler.bind(this)
    return rejectedStateHandler
  }
}
