import { CSSProperties } from 'react'
import styles from './styles.module.css'
import LoadingSpinner from '@/primitives/LoadingSpinner'
import LuruButton from '@/primitives/ui/LuruButton'
interface LoadingComponentProps {
  children: JSX.Element
  loading: boolean
  loadingLabel?: string
  loadingContainerStyle?: CSSProperties
  error?: boolean
  tryAgainButtonText?: string
  errorMessage?: string
  errorContainerStyle?: CSSProperties
  onClickTryAgain?: () => void
  removeShadow?: boolean
}

export default function LoadingComponent({
  children,
  loading,
  loadingLabel,
  loadingContainerStyle,
  error,
  errorMessage,
  errorContainerStyle,
  tryAgainButtonText,
  removeShadow,
  onClickTryAgain,
}: LoadingComponentProps) {
  if (error) {
    return (
      <div
        className={[styles.containerStyle, removeShadow ? '' : 'shadow-sm'].join(' ')}
        style={{ ...errorContainerStyle }}
      >
        <span className='error'>{errorMessage}</span>
        <LuruButton color='red' size='sm' variant='outline' onClick={onClickTryAgain} title='Retry'>
          {tryAgainButtonText}
        </LuruButton>
      </div>
    )
  }

  if (loading) {
    return (
      <div
        className={[styles.containerStyle, removeShadow ? '' : 'shadow-sm'].join(' ')}
        style={{ ...loadingContainerStyle }}
      >
        <LoadingSpinner />
        {loadingLabel && <span>{loadingLabel}</span>}
      </div>
    )
  }
  return children
}

LoadingComponent.defaultProps = {
  errorMessage: 'Ops error occured!',
  loadingLabel: '',
  tryAgainButtonText: 'Try Again',
}
