import { createSlice } from '@reduxjs/toolkit'
import { EmailsSliceReducer as reducers } from './reducers'
import { EmailsMiddleware } from './middleware'
import { EmptyEmailsState } from './types'

const emailsSlice = createSlice({
  name: 'emails',
  initialState: EmptyEmailsState,
  reducers,
  extraReducers: (builder) => {
    EmailsMiddleware.listEmails.addAllCases(builder)
  },
})

export default emailsSlice.reducer

export const EmailsSliceActions = emailsSlice.actions
