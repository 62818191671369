import React, { ClipboardEvent } from 'react'
import LuruDatePicker from '../LuruDatePicker'
import styles from './styles.module.css'
export interface LuruTextBoxProps {
  additionalClassNames?: Array<string>
  datePickerPopupMenuClassName?: string
  additionalTextBoxClassNames?: Array<string>
  alignment?: 'left' | 'center' | 'right'
  defaultValue?: string
  disabled?: boolean
  menuParentSelector?: string
  pattern?: string
  placeholder?: string
  readOnly?: boolean
  size?: 'small' | 'normal'
  type?: 'date' | 'text' | 'datetime-local' | 'email' | 'search' | 'tel' | 'url' | 'number'
  onBlur?: (value: string, e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onCancel?: () => void
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void
  onChange?: (value: string | null) => void
  onDatePicked?: (value: string | null) => void
  onFocus?: () => void
  onNavigateUp?: () => void
  onNavigateDown?: () => void
  onReturn?: (value: string) => void
  autoFocus?: boolean
  width?: string
  min?: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface LuruTextBoxState {
  value: string
}

interface LuruTextBoxRefs {
  textBox: React.RefObject<HTMLInputElement>
  datePicker?: React.RefObject<LuruDatePicker>
}

interface LuruTextBoxEvents {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onPaste: (e: ClipboardEvent<HTMLInputElement>) => void
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void
  onDatePicked: (value: string | null) => void
}

export default class LuruTextBox extends React.Component {
  props: LuruTextBoxProps
  state: LuruTextBoxState
  value: string = ''
  #componentRefs: LuruTextBoxRefs
  #eventHandler: LuruTextBoxEventHandler

  constructor(props: LuruTextBoxProps) {
    super(props)
    this.props = props
    this.state = {
      value: this.props.defaultValue ?? '',
    }
    this.#componentRefs = {
      textBox: React.createRef(),
      datePicker: this.props.type === 'date' ? React.createRef() : undefined,
    }
    this.#eventHandler = new LuruTextBoxEventHandler(this)
  }

  getValue() {
    return this.state.value
  }

  setValue(value: string, callback?: () => void) {
    this.value = value
    this.setState({ value }, callback)

    if (this.props.type === 'date') {
      this.#componentRefs.datePicker?.current?.setValue(value)
    }
  }

  focus() {
    if (this.props.type === 'date') {
      this.#componentRefs.datePicker?.current?.focus()
    } else {
      this.#componentRefs.textBox.current?.focus()
    }
  }

  select() {
    if (this.props.type === 'date') {
      this.#componentRefs.datePicker?.current?.select?.()
    } else {
      this.#componentRefs.textBox.current?.select?.()
    }
  }

  blur() {
    if (this.props.type === 'date') {
      this.#componentRefs.datePicker?.current?.blur()
    } else {
      this.#componentRefs.textBox.current?.blur()
    }
  }

  reset() {
    this.value = ''
    this.setState({ value: '' })

    if (this.props.type === 'date') {
      this.#componentRefs.datePicker?.current?.setValue(null)
    }
  }

  isReadOnly() {
    return this.props.readOnly === true
  }

  render() {
    var classNames = ['luruTextBox', this.props.readOnly ? styles.disabled : '']

    if (this.props.additionalClassNames) {
      classNames = classNames.concat(this.props.additionalClassNames)
    }

    if (this.props.type === 'date') {
      return (
        <LuruDatePicker
          menuParentSelector={this.props.menuParentSelector}
          popupMenuClassName={this.props.datePickerPopupMenuClassName}
          ref={this.#componentRefs.datePicker}
          value={this.state.value}
          readOnly={this.props.readOnly}
          placeholder={this.props.placeholder}
          additionalClassNames={this.props.additionalClassNames}
          additionalTextBoxClassNames={this.props.additionalTextBoxClassNames}
          alignment={this.props.alignment}
          onValuePicked={this.#eventHandler.handlers.onDatePicked}
          autoFocus={this.props.autoFocus}
        />
      )
    }

    var inputElemValue = this.props.type === 'datetime-local' ? this.state.value.slice(0, 16) : this.state.value
    if (inputElemValue === null) {
      // Fix for React warning about controlled/uncontrolled input:
      // Warning: `value` prop on `input` should not be null.
      // Consider using an empty string to clear the component or `undefined` for uncontrolled components.
      inputElemValue = ''
    }

    return (
      <input
        className={classNames.join(' ')}
        data-luru-role='luru-text-box'
        data-luru-size={this.props.size}
        disabled={this.props.readOnly === true || this.props.disabled}
        placeholder={this.props.placeholder ?? ''}
        pattern={this.props.pattern ?? '.*'}
        readOnly={this.props.readOnly === true}
        ref={this.#componentRefs.textBox}
        style={{ textAlign: this.props.alignment ?? 'left', width: this.props.width }}
        type={this.props.type ?? 'text'}
        value={inputElemValue}
        onBlur={this.#eventHandler.handlers.onBlur}
        onPaste={this.#eventHandler.handlers.onPaste}
        onChange={this.#eventHandler.handlers.onChange}
        onFocus={this.#eventHandler.handlers.onFocus}
        onKeyDown={this.#eventHandler.handlers.onKeyDown}
      />
    )
  }
}

class LuruTextBoxEventHandler {
  #component: LuruTextBox
  #eventTimeStamps: {
    onKeyDown?: number
    onChange?: number
  } = {
    onKeyDown: undefined,
    onChange: undefined,
  }
  handlers: LuruTextBoxEvents

  constructor(component: LuruTextBox) {
    this.#component = component
    this.#component.componentDidUpdate = this.#componentDidUpdate.bind(this)
    this.#component.componentDidMount = this.#componentDidMount.bind(this)

    this.handlers = {
      onChange: this.#onChange,
      onKeyDown: this.#onKeyDown,
      onBlur: this.#onBlur,
      onFocus: this.#onFocus,
      onPaste: this.#onPaste,
      onDatePicked: this.#onDatePicked,
    }
  }

  #componentDidMount() {
    if (this.#component.props.autoFocus) {
      this.#component?.focus?.()
    }
  }

  #componentDidUpdate(
    prevProps: Readonly<LuruTextBoxProps>,
    prevState: Readonly<LuruTextBoxState>,
    snapshot?: any
  ): void {
    if (prevProps.defaultValue !== this.#component.props.defaultValue) {
      this.#component.setValue(this.#component.props.defaultValue ?? '')
    }
  }

  #onDatePicked = (value: string | null) => {
    var externalHandler = this.#component.props.onDatePicked ?? this.#component.props.onChange
    this.#component.setValue(value ?? '', () => externalHandler?.(value))
  }

  #onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var externalHandler = this.#component.props.onChange
    var inputElement = e.target as HTMLInputElement
    var value = inputElement.value

    // Validation for min numeric value
    if (this.#component.props.min) {
      let min = Number(this.#component.props.min)
      let enteredValue = Number(value)

      if ((min || min === 0) && (enteredValue || enteredValue === 0) && enteredValue < min) {
        this.#component.setValue('')
        return
      }
    }

    if (inputElement.type === 'date' || inputElement.type === 'datetime-local') {
      let delayAfterKeyDown = 0

      if (this.#eventTimeStamps.onKeyDown !== undefined) {
        delayAfterKeyDown = new Date().valueOf() - this.#eventTimeStamps.onKeyDown
      }

      if (
        (this.#eventTimeStamps.onKeyDown === undefined || delayAfterKeyDown > 50) &&
        this.#component.props.onDatePicked
      ) {
        this.#eventTimeStamps.onChange = new Date().valueOf()
        this.#component.props.onDatePicked(value)
      }

      // User of timestamp resets it after using it
      this.#eventTimeStamps.onKeyDown = undefined
    }

    e.preventDefault()
    this.#component.setValue(value, () => {
      externalHandler && externalHandler(value)
    })
  }

  #onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    // Stop sending key down event to its parent
    e.stopPropagation()
  }

  #onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    var inputEl = e.target as HTMLInputElement

    if (inputEl.type === 'date' || inputEl.type === 'datetime-local') {
      this.#eventTimeStamps.onKeyDown = new Date().valueOf()
    }

    var key = e.key
    var keyMappings: { [key: string]: ((_: string) => void) | undefined } = {
      ArrowUp: this.#component.props.onNavigateUp,
      ArrowDown: this.#component.props.onNavigateDown,
      Enter: this.#component.props.onReturn,
      Escape: this.#component.props.onCancel,
    }

    if (Object.keys(keyMappings).includes(key)) {
      let handler = keyMappings[key]

      if (handler !== undefined && typeof handler === 'function') {
        handler(this.#component.state.value)
      }
    }

    this.#component.props?.onKeyDown?.(e)
  }

  #onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (this.#component.props.onBlur) {
      this.#component.props.onBlur(this.#component.getValue(), e)
    }
  }

  #onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    var delayAfterChange = 0

    if (this.#eventTimeStamps.onChange !== undefined) {
      delayAfterChange = new Date().valueOf() - this.#eventTimeStamps.onChange
    }

    if ((this.#eventTimeStamps.onChange === undefined || delayAfterChange > 50) && this.#component.props.onFocus) {
      this.#component.props.onFocus()
    }

    // User of timestamp resets it after use
    this.#eventTimeStamps.onChange = undefined
  }
}
