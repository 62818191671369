import { useCallback, useEffect, useState } from 'react'
import { EntityStatus } from '../app/types'

export type FunctionReturningPromise = (...args: any[]) => Promise<any>

export type AsyncState<T> = {
  loading: boolean
  error?: any
  status: EntityStatus
  data?: T
}

export function useAsyncFn<T = any>(
  fn: (...args: any[]) => Promise<T>,
  deps: React.DependencyList = [],
  immediatelyFetch: boolean = true,
  initialState?: AsyncState<T>
) {
  const [state, setState] = useState(
    initialState || {
      loading: immediatelyFetch ? true : false,
      error: undefined,
      data: undefined,
      status: immediatelyFetch ? EntityStatus.Loading : EntityStatus.Idle,
    }
  )
  const doFetch = useCallback(
    async (...args: any[]) => {
      try {
        setState((oldState) => ({ ...oldState, loading: true, status: EntityStatus.Loading, error: undefined }))
        const res = await fn(...args)
        setState((oldState) => ({ ...oldState, loading: false, status: EntityStatus.Loaded, data: res }))
      } catch (error) {
        setState((oldState) => ({ ...oldState, loading: false, status: EntityStatus.ErrorLoading, error: error }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, fn]
  )

  useEffect(() => {
    if (immediatelyFetch) {
      doFetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return {
    ...state,
    doFetch,
  }
}
