import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { NoteTemplatesAPI } from '../api'
import { ReduxNoteTemplateEntity, ReduxNoteTemplatesState } from '../types'

export interface FetchNoteTemplateParameter {
  noteTemplateId: string
}

export interface FetchNoteTemplateAPIResponse extends LuruAPIResponse {
  data: ReduxNoteTemplateEntity
}

export const fetchNoteTemplate = {
  action: createAsyncThunk<
    FetchNoteTemplateAPIResponse['data'],
    FetchNoteTemplateParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: FetchNoteTemplateAPIResponse['metadata']
      rejectedMeta: FetchNoteTemplateAPIResponse['metadata']
    }
  >('noteTemplates/fetchNoteTemplate', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response: FetchNoteTemplateAPIResponse = await NoteTemplatesAPI.fetchNoteTemplate(params, { signal })

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null
      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(fetchNoteTemplate.action.pending, (state, action) => {
      state.entities[action.meta.arg.noteTemplateId] = {
        status: EntityStatus.Loading,
        data: {
          ...state.entities[action.meta.arg.noteTemplateId].data,
        } as ReduxNoteTemplateEntity,
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(fetchNoteTemplate.action.fulfilled, (state, action) => {
      var entity = action.payload

      if (typeof entity.filter === 'string') {
        try {
          entity.filter = JSON.parse(entity.filter)
        } catch (e) {
          console.warn('fetchNoteTemplate:entity.filter is not parseable as JSON:', entity.filter)
        }
      }

      state.entities[action.meta.arg.noteTemplateId] = {
        status: EntityStatus.Loaded,
        data: {
          ...entity,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    builder.addCase(fetchNoteTemplate.action.rejected, (state, action) => {
      state.entities[action.meta.arg.noteTemplateId] = {
        status: EntityStatus.ErrorLoading,
        data: null,
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNoteTemplatesState>) {
    fetchNoteTemplate.addPendingCase(builder)
    fetchNoteTemplate.addFulfilledCase(builder)
    fetchNoteTemplate.addRejectedCase(builder)
  },
}
