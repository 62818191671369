import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UnhideNoteTemplateParameter } from '../middleware/unhideNoteTemplate'

export const unhideNoteTemplate = async (
  params: Readonly<UnhideNoteTemplateParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(`/api/note-templates/${params.noteTemplateId}/unhide`, HTTPMethod.POST, options)

  request.setBody(params)

  return request.make()
}
