import { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import LuruUser from '../../../domain/users/LuruUser'
import useSignInState from './useSignInState'

export default function useWebAppDOMEffect() {
  const location = useLocation()
  const signInState = useSignInState()

  useLayoutEffect(() => {
    // Add a flag to indicate if this app is rendered in the context of an
    // embedded meeting note
    if (location.pathname.indexOf('/embedded_meeting_note/') !== -1) {
      document.getElementById('root')?.setAttribute('data-luru-role', 'embedded-meeting-root')
    }
  }, [location])

  useEffect(() => {
    document.body.setAttribute('data-luru-entry-point', LuruUser.getCurrentEntryPoint())
    document.body.setAttribute('data-luru-signin-status', signInState)
  })
}
