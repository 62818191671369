// import update from 'immutability-helper'
import RecordView from '.'
import { EntityStatus } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { CrmObjectSchema } from '../../../domain/crmRecord/typings'
import EditableCell from '../../../primitives/EditableCell'
import update from 'immutability-helper'
import { ReduxNoteEntity } from '../../../features/notes/types'
import { ReduxTaskEntity } from '../../../features/tasks/types'

interface RecordViewEvents {
  onFieldChange: (value: any, source: EditableCell) => void
  onFieldBlur: (value: any, source: EditableCell) => void
  onChangeTabName: (name: string) => void
  onClickNote: (note: ReduxNoteEntity) => void
  onClickNewNote: (note: ReduxNoteEntity) => void
  onClickTask: (task: ReduxTaskEntity) => void
  onClickNewTask: () => void
  onFieldValueChange: (fieldName: string, value: string) => void
}

export default class RecordViewEventHandler {
  #component: RecordView
  handlers: RecordViewEvents

  constructor(component: RecordView) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMounted
    this.handlers = {
      onFieldBlur: this.#onFieldBlur,
      onFieldChange: this.#onFieldChange,
      onChangeTabName: this.#onChangeTabName,
      onClickNote: this.#onClickNote,
      onClickNewNote: this.#onClickNewNote,
      onClickTask: this.#onClickTask,
      onClickNewTask: this.#onClickNewTask,
      onFieldValueChange: this.#onFieldValueChange,
    }
  }

  #onChangeTabName = (tabName: string) => this.#component.setState({ currentTabName: tabName })

  #onClickNote = (note: ReduxNoteEntity) =>
    this.#component.props?.onChooseCommand?.('EDIT', 'NOTE', note.note_id, note.title)

  #onClickNewTask = () => this.#component.props?.onChooseCommand?.('CREATE', 'TASK', undefined, undefined)

  #onClickNewNote = (note: ReduxNoteEntity) =>
    this.#component.props?.onChooseCommand?.('EDIT', 'NOTE', note.note_id, note.title)

  #onClickTask = (task: ReduxTaskEntity) =>
    this.#component.props?.onChooseCommand?.('EDIT', 'TASK', task.task_id, task.title)

  #onComponentMounted = async () => {
    try {
      var schema = (await CrmRecord.getObjectSchema(this.#component.props.crmRecordType)) as CrmObjectSchema
      this.#component.setState({ schemaFields: schema.payload?.fields, schemaStatus: EntityStatus.Loaded })
    } catch (e) {
      this.#component.setState({ schemaStatus: EntityStatus.ErrorLoading })
    }
  }

  #onFieldBlur = (value: any, source: EditableCell) => this.#handleFieldChange(value, source)

  #onFieldChange = (value: any, source: EditableCell) => this.#handleFieldChange(value, source)

  #handleFieldChange(value: any, source: EditableCell) {
    var fieldName = source.props.fieldSchema?.name as string

    if (fieldName) {
      this.#component.setState(
        (prevState) => update(prevState, { fieldValuesMap: { [fieldName]: { $set: { value } } } }),
        () => this.#component.props.onFieldValueChange(value, source)
      )
    }
  }

  async #onFieldValueChange(fieldName: string, value: string) {
    const { crmRecordType, sorRecordId } = this.#component.props
    this.#component.setState((prevState) => ({
      ...prevState,
      fieldStatus: EntityStatus.Loading,
    }))

    try {
      if (crmRecordType && sorRecordId) {
        await CrmRecord.multiUpdate({
          crmRecordType,
          fields: { [fieldName]: value },
          sorRecordId,
        })

        this.#component.setState((prevState) => ({
          ...prevState,
          fieldStatus: EntityStatus.Loaded,
        }))

        setTimeout(() => {
          this.#component.setState((prevState) => ({
            ...prevState,
            fieldStatus: EntityStatus.Idle,
          }))
        }, 2000)
      }
    } catch (e) {
      // handleUpdateError('Error updating field. ' + (e as Error).message)
      this.#component.setState((prevState) => ({
        ...prevState,
        fieldStatus: EntityStatus.ErrorLoading,
      }))

      setTimeout(() => {
        this.#component.setState((prevState) => ({
          ...prevState,
          fieldStatus: EntityStatus.Idle,
        }))
      }, 2000)
    }
  }
}
