import { RootState } from '../../../app/store'
import { UserPolicyType } from '../types'

export const getUserPolicyFor = (state: RootState, policyFor: UserPolicyType) => {
  const policyAction =
    policyFor === UserPolicyType.WORKFLOW ? 'sor:workflow:*' : policyFor === UserPolicyType.API ? 'sor:api:*' : ''
  const policy = state.user.data.userPolicies?.find?.((up) =>
    Array.isArray(up.action) ? up.action.includes(policyAction) : up.action === policyAction
  )
  return policy
}
