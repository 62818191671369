import { createWorkflow } from './createWorkflow'
import { fetchWorkflow } from './fetchWorkflow'
import { updateWorkflow } from './updateWorkflow'
import { updateV2Workflow } from './updateV2Workflow'
import { deleteWorkflow } from './deleteWorkflow'
import { listWorkflows } from './listWorkflows'
import { testWorkflow } from './testWorkflow'
import { pauseUnpauseWorkflow } from './pauseUnpauseWorkflow'
import { listWorkflowTemplates } from './listWorkflowTemplates'
import { createWorkflowFromTemplate } from './createWorkflowFromTemplate'
import { listWorkflowTemplateFields } from './listWorkflowTemplateFields'
import { fetchWorkflowSchema } from './fetchWorkflowSchema'
import { createV2Workflow } from './createV2Workflow'
import { searchWorkflowEvents } from './searchWorkflowEvents'
import { searchWorkflowEventsGroupByWorkflow } from './searchEventsGroupByWorkflow'
import { searchWorkflowReportsSummaryGroupByWorkflow } from './searchReportsSummaryGroupByWorkflow'
import { searchRecordInfosForWorkflow } from './searchRecordInfosForWorkflow'
import { searchRunInfosForWorkflow } from './searchRunInfosForWorkflow'
import { searchUserInteractionInfosForWorkflow } from './searchUserInteractionInfosForWorkflow'

export const WorkflowsAPI = {
  createWorkflow,
  createV2Workflow,
  fetchWorkflow,
  updateWorkflow,
  updateV2Workflow,
  deleteWorkflow,
  listWorkflows,
  testWorkflow,
  fetchWorkflowSchema,
  pauseUnpauseWorkflow,
  listWorkflowTemplates,
  createWorkflowFromTemplate,
  listWorkflowTemplateFields,
  searchWorkflowEvents,
  searchWorkflowEventsGroupByWorkflow,
  searchWorkflowReportsSummaryGroupByWorkflow,
  searchRecordInfosForWorkflow,
  searchRunInfosForWorkflow,
  searchUserInteractionInfosForWorkflow,
}
