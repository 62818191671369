import { HTTPMethod } from '../../../app/types'
import { DateUtils } from '../../../utils/dateUtils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UpdateTaskParameter } from '../middleware/updateTask'

export const updateTask = (params: Readonly<UpdateTaskParameter>, options: LuruAPIRequestOptions) => {
  var { task_id: taskId } = params
  var request = new LuruAPIRequest(`/api/tasks/${taskId}`, HTTPMethod.PUT, options)
  var requestBody: Partial<UpdateTaskParameter> = { ...params }

  for (let key in params) {
    let typedKey = key as keyof UpdateTaskParameter

    if ((params[typedKey] === undefined || params[typedKey] === null) && typedKey !== 'assigned_to') {
      delete requestBody[typedKey]
    } else if (typedKey === 'due_date' && params.due_date) {
      requestBody.due_date = DateUtils.toAPIDateString(new Date(params.due_date))
    } else if (typedKey === 'due_date' && !params.due_date) {
      requestBody.due_date = null
    } else if (typedKey === 'assigned_to' && params.assigned_to) {
      requestBody.assigned_to = { ...params.assigned_to }
    } else if (typedKey === 'connections' && params.connections) {
      requestBody.connections = [...params.connections]
    }
  }

  request.setBody(requestBody)

  return request.make()
}
