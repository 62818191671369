import { EntityStatus } from '../../app/types'
import { CrmRecordSchema, CrmRecordType, LuruFieldType } from '../../domain/crmRecord/typings.d'
import { CRMProvider } from '../../features/user/types'
import LuruUser from '../../domain/users/LuruUser'
import { CrmFieldSchema, CrmObjectName } from '../crm/types'
import { WorkflowEditorMode } from '@/routes/workflowsv2/components/WorkflowV2Editor/types'

export const ReduxWorkflowsStateTTL = 24 * 60 * 60 * 1_000 // millisecs/hour

export enum EventFilterSettingKey {
  DATE_TIME_RANGE_VALUE = 'dateTimeRangeValue',
  STATUS = 'status',
  WORKFLOW_ID = 'workflowId',
}

export interface ReduxWorkflowsState {
  refreshedAt: string | undefined
  status: EntityStatus
  entities: Record<
    string,
    {
      status: EntityStatus
      data: ReduxWorkflowEntity | ReduxV2WorkflowEntity
    }
  >
  schema: {
    refreshedAt: string | undefined
    status: EntityStatus
    error?: Record<string, any> | null
    entities: Partial<
      Record<
        WorkflowSorName,
        {
          status: EntityStatus
          data: WorkflowSorSchema
        }
      >
    >
    normalizedEntities: Array<WorkflowNormalizedSchema>
  }
}

export type WeekdayNumber = number

export function isWeekdayNumber(n: number): n is WeekdayNumber {
  return n >= 0 && n <= 6
}

export interface CreateWorkflowParameter {
  name: string
  version?: string
  description: string | null
  sor: CRMProvider
  sor_object_name: CrmRecordType
  evaluation: {
    type: WorkflowTrigger
    data: {
      tz?: string
      cron_expression?: string
    }
  }
  filter: {
    version: string
    data: WorkflowFilterExpressionTree
  }
  actions: Array<WorkflowAlert>
}

export interface TestWorkflowAPIRequestBody {
  sor: CRMProvider
  sor_object_name: string
  sor_record_id: string | null
  sor_record_ids: Array<string> | null
  actions: Array<WorkflowAlert>
  filter?: {
    version: string
    data: WorkflowFilterExpressionTree
  }
}

export interface PauseUnpauseWorkflowParams {
  workflow_id: string
  doPause: boolean
  body?: {
    sor: CRMProvider
    sor_object_name: string
    actions: Array<WorkflowAlert>
  }
}

export interface ReduxWorkflowEntity extends CreateWorkflowParameter {
  workflow_id: string
  version?: string
  created_at: string
  created_by: {
    first_name: string
    last_name: string
    user_id: string
    email: string
  }
  updated_at: string
  updated_by: {
    first_name: string
    last_name: string
    user_id: string
    email: string
  }
  state: WorkflowState
}

export interface ReduxV2WorkflowEntity extends CreateV2WorkflowParameter {
  workflow_id: string
  created_at: string
  created_by: {
    first_name: string
    last_name: string
    user_id: string
    email: string
  }
  updated_at: string
  updated_by: {
    first_name: string
    last_name: string
    user_id: string
    email: string
  }
  state: WorkflowState
  editorMode?: WorkflowEditorMode
}

export enum WorkflowState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum WorkflowTrigger {
  CREATE_UPDATE = 'RECORD_CU',
  SCHEDULE = 'SCHEDULE',
}
// V2
export enum WorkflowTriggerV2 {
  RECORD_CU = 'record_cu',
  CRON = 'cron',
  MEETING_ABOUT_TO_END = 'meeting-about-to-end',
  MEETING_ABOUT_TO_START = 'meeting-about-to-start',
  MEETING_ENDED = 'meeting-ended',
  MEETING_STARTED = 'meeting-started',
}

export enum MediaCategory {
  CELEBRATORY = 'celebrate',
  REMINDER = 'reminder',
  SAD = 'sad',
  HAPPY = 'happy',
  CONCERNED = 'concerned',
}

export enum ActionButtonType {
  UPDATE_RECORD = 'update-record',
  DISPLAY_RECORD = 'display-record',
  VIEW_IN_LURU = 'view-in-luru',
  VIEW_MEETING_NOTE = 'view-meeting-note',
  UPDATE_MEETING_NOTE = 'update-meeting-note',
  MARK_AS_DONE = 'mark-task-done',
  CREATE_RECORD = 'create-record',
  OPEN_URL = 'open-url',
}

export const DefaultActionButtonLabel = {
  [ActionButtonType.UPDATE_RECORD]: 'Update Record',
  [ActionButtonType.DISPLAY_RECORD]: 'View Record',
  [ActionButtonType.VIEW_IN_LURU]: 'View in Luru',
  [ActionButtonType.VIEW_MEETING_NOTE]: 'View Meeting Note',
  [ActionButtonType.UPDATE_MEETING_NOTE]: 'Update Meeting Note',
  [ActionButtonType.MARK_AS_DONE]: 'Mark as Done',
  [ActionButtonType.CREATE_RECORD]: 'Create Record',
  [ActionButtonType.OPEN_URL]: 'Open link in browser',
}

export interface ActionButton {
  type?: ActionButtonType
  fields: Array<string>
  mandatory_fields?: Array<string>
}

export interface WorkflowAlert {
  type: AlertType
  data: {
    version: string
    type: AlertDestination
    conversations: Array<string> | null
    users: Array<string> | null
    msg_type: 'RECORD' | 'REPORT'
    report_desc: string
    report_config?: {
      grouped: boolean
      grouped_field: string | null
    }
    msg_template: string
    fields: Array<string>
    media_category: MediaCategory | null
    action_buttons: Array<ActionButton> | null
  }
}

export enum AlertType {
  SLACK_MESSAGE = 'slack-message',
  GROUP_DM = 'group-dm',
  MSTEAMS_MESSAGE = 'msteams-message',
  GCHAT_MESSAGE = 'gchat-message',
}

export enum AlertDestination {
  CONVERSATION = 'conversation',
  GROUP_DM = 'group-dm',
}

export enum WorkflowFilterGroupingOperator {
  OR = 'or',
  AND = 'and',
}

export type WorkflowFilterExpressionTree = {
  [key in WorkflowFilterGroupingOperator]:
    | Array<WorkflowFilterExpression | WorkflowFilterExpressionTree>
    | WorkflowFilterExpressionTree
}

export interface IndexedExpression extends WorkflowFilterExpression {
  expressionIndex: number
}

export interface WorkflowFilterExpression {
  object_name: string
  field: string
  op: string
  originalOp?: string
  value: any
  fieldType?: LuruFieldType
}

export interface WorkflowFilterExpressionRoot {
  [WorkflowFilterGroupingOperator.AND]: Array<{
    [key in WorkflowFilterGroupingOperator]: Array<WorkflowFilterExpression>
  }>
}

export enum WorkflowFilterOperator {
  // General
  EQ = 'equals',
  NEQ = 'not_equals',
  BLANK = 'blank',
  NOT_BLANK = 'not_blank',
  CHANGED = 'changed',
  CHANGED_TO = 'changed_to',
  CHANGED_FROM = 'changed_from',
  NOT_CHANGED = 'not_changed',
  CHANGED_N_DAYS = 'changed_n_days',
  UNCHANGED_N_DAYS = 'unchanged_n_days',
  // Number specific
  GT = 'greater_than',
  GTE = 'greater_than_eq',
  LT = 'less_than',
  LTE = 'less_than_eq',
  INCREASED = 'increased',
  DECREASED = 'decreased',
  INCREASED_BY_VALUE = 'increased_by_value',
  DECREASED_BY_VALUE = 'decreased_by_value',
  INCREASED_BY_PERCENT = 'increased_by_percent',
  DECREASED_BY_PERCENT = 'decreased_by_percent',
  INCREASED_BY_ATMOST_VALUE = 'increased_by_atmost_value',
  DECREASED_BY_ATMOST_VALUE = 'decreased_by_atmost_value',
  INCREASED_BY_ATMOST_PERCENT = 'increased_by_atmost_percent',
  DECREASED_BY_ATMOST_PERCENT = 'decreased_by_atmost_percent',
  INCREASED_BY_ATLEAST_VALUE = 'increased_by_atleast_value',
  DECREASED_BY_ATLEAST_VALUE = 'decreased_by_atleast_value',
  INCREASED_BY_ATLEAST_PERCENT = 'increased_by_atleast_percent',
  DECREASED_BY_ATLEAST_PERCENT = 'decreased_by_atleast_percent',
  // List specific
  IN = 'in',
  NOT_IN = 'not in',
  // String specific
  STARTS_WITH = 'starts_with',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does_not_contain',
  // Date specific
  PREV_WEEK = 'prev_week',
  CURR_WEEK = 'curr_week',
  NEXT_WEEK = 'next_week',
  PREV_MTH = 'prev_month',
  CURR_MTH = 'curr_month',
  NEXT_MTH = 'next_month',
  PREV_QTR = 'prev_quarter',
  CURR_QTR = 'curr_quarter',
  NEXT_QTR = 'next_quarter',
  PREV_FY = 'prev_fy',
  CURR_FY = 'curr_fy',
  NEXT_FY = 'next_fy',
  PREV_YEAR = 'prev_year',
  CURR_YEAR = 'curr_year',
  NEXT_YEAR = 'next_year',
  PREV_N_DAYS = 'prev_n_days',
  NEXT_N_DAYS = 'next_n_days',
  NOT_PREV_N_DAYS = 'not_prev_n_days',
  NOT_NEXT_N_DAYS = 'not_next_n_days',
  BEFORE_N_DAYS = 'before_n_days',
  AFTER_N_DAYS = 'after_n_days',
  // New operators - Date
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  PUSHED_OUT = 'pushed_out',
  PUSHED_BACK = 'pushed_back',
  MOVED_FROM_PREV_WEEK = 'moved_from_prev_week',
  MOVED_FROM_CURR_WEEK = 'moved_from_curr_week',
  MOVED_FROM_NEXT_WEEK = 'moved_from_next_week',
  MOVED_FROM_PREV_MONTH = 'moved_from_prev_month',
  MOVED_FROM_CURR_MONTH = 'moved_from_curr_month',
  MOVED_FROM_NEXT_MONTH = 'moved_from_next_month',
  MOVED_FROM_PREV_QUARTER = 'moved_from_prev_quarter',
  MOVED_FROM_CURR_QUARTER = 'moved_from_curr_quarter',
  MOVED_FROM_NEXT_QUARTER = 'moved_from_next_quarter',
  MOVED_FROM_PREV_YEAR = 'moved_from_prev_year',
  MOVED_FROM_CURR_YEAR = 'moved_from_curr_year',
  MOVED_FROM_NEXT_YEAR = 'moved_from_next_year',
  MOVED_TO_PREV_WEEK = 'moved_to_prev_week',
  MOVED_TO_CURR_WEEK = 'moved_to_curr_week',
  MOVED_TO_NEXT_WEEK = 'moved_to_next_week',
  MOVED_TO_PREV_MONTH = 'moved_to_prev_month',
  MOVED_TO_CURR_MONTH = 'moved_to_curr_month',
  MOVED_TO_NEXT_MONTH = 'moved_to_next_month',
  MOVED_TO_PREV_QUARTER = 'moved_to_prev_quarter',
  MOVED_TO_CURR_QUARTER = 'moved_to_curr_quarter',
  MOVED_TO_NEXT_QUARTER = 'moved_to_next_quarter',
  MOVED_TO_PREV_YEAR = 'moved_to_prev_year',
  MOVED_TO_CURR_YEAR = 'moved_to_curr_year',
  MOVED_TO_NEXT_YEAR = 'moved_to_next_year',
  // Associated Email specific
  EMAIL_SENT = 'email_sent',
  EMAIL_RECEIVED = 'email_received',
  EMAIL_FORWARDED = 'email_sent_received',
  EMAIL_SENT_LAST_N_DAYS = 'email_sent_last_n_days',
  EMAIL_RECEIVED_LAST_N_DAYS = 'email_received_last_n_days',
  EMAIL_FORWARDED_LAST_N_DAYS = 'email_sent_received_last_n_days',
  NO_EMAIL_SENT_LAST_N_DAYS = 'no_email_sent_last_n_days',
  NO_EMAIL_RECEIVED_LAST_N_DAYS = 'no_email_received_last_n_days',
  NO_EMAIL_FORWARDED_LAST_N_DAYS = 'no_email_sent_received_last_n_days',
  // Multi-picklist specific
  VALUE_ADDED = 'value_added',
  VALUE_REMOVED = 'value_removed',
  NUM_VALUES_EQUALS = 'num_values_equals',
  NUM_VALUES_NOT_EQUALS = 'num_values_not_equals',
  NUM_VALUES_AT_LEAST = 'num_values_at_least',
  NUM_VALUES_AT_MOST = 'num_values_at_most',
  // Meeting timestamp specific
  BEGINS_N_MINS_LATER = 'begins_n_mins_later',
  ENDED_N_MINS_AGO = 'ended_n_mins_ago',
  // Luru meeting note specific (can be used for other fields later)
  PRESENT = 'present',
  NOT_PRESENT = 'not_present',
  // Associated call specific
  HAPPENED_IN_LAST_N_DAYS = 'happened_in_last_n_days',
  NOT_HAPPENED_IN_LAST_N_DAYS = 'not_happened_in_last_n_days',
  // Multi-reference specific
  CONTAINS_ALL = 'contains_all',
  CONTAINS_ANY = 'contains_any',
  CONTAINS_NONE = 'contains_none',
}

export enum V2WorkflowFilterOperator {
  // General
  EQ = 'eq',
  NEQ = 'neq',
  EMPTY = 'is_empty',
  NOT_EMPTY = 'is_not_empty',
  CHANGED = 'changed',
  CHANGED_TO = 'changed_to',
  // Number specific
  GT = 'gt',
  GTE = 'ge',
  LT = 'lt',
  LTE = 'le',
  INCREASED_BY_AT_LEAST = 'increased_by_at_least',
  DECREASED_BY_AT_LEAST = 'decreased_by_at_least',
  INCREASED_BY_AT_MOST = 'increased_by_at_most',
  DECREASED_BY_AT_MOST = 'decreased_by_at_most',

  // List specific
  IN = 'in',
  NOT_IN = 'not_in',
  // String specific
  STARTS_WITH = 'starts_with',
  CONTAINS = 'contains',
  // Boolean specific
  TRUE = 'is_true',
  FALSE = 'is_false',
  // Date specific
  TODAY = 'today',
  NOW = 'now',
  TOMORROW = 'tomorrow',
  BEFORE = 'is_before',
  ON_OR_BEFORE = 'is_on_or_before',
  AFTER = 'is_after',
  ON_OR_AFTER = 'is_on_or_after',
  WITHIN = 'is_within',
  NOT_WITHIN = 'is_not_within',
  NEXT_N_DAYS = 'next_n_days',
  LAST_N_DAYS = 'last_n_days',
  THIS_QUARTER = 'is_this_quarter',
  NEXT_QUARTER = 'is_next_quarter',
  LAST_QUARTER = 'is_last_quarter',
  THIS_YEAR = 'is_this_year',
  NEXT_YEAR = 'is_next_year',
  LAST_YEAR = 'is_last_year',
  THIS_MONTH = 'is_this_month',
  NEXT_MONTH = 'is_next_month',
  LAST_MONTH = 'is_last_month',
  THIS_WEEK = 'is_this_week',
  NEXT_WEEK = 'is_next_week',
  LAST_WEEK = 'is_last_week',
  // Associated Email specific
  EMAIL_SENT = 'email_sent',
  EMAIL_RECEIVED = 'email_received',
  EMAIL_FORWARDED = 'email_sent_received',
  EMAIL_SENT_LAST_N_DAYS = 'email_sent_last_n_days',
  EMAIL_RECEIVED_LAST_N_DAYS = 'email_received_last_n_days',
  EMAIL_FORWARDED_LAST_N_DAYS = 'email_sent_received_last_n_days',
  NO_EMAIL_SENT_LAST_N_DAYS = 'no_email_sent_last_n_days',
  NO_EMAIL_RECEIVED_LAST_N_DAYS = 'no_email_received_last_n_days',
  NO_EMAIL_FORWARDED_LAST_N_DAYS = 'no_email_sent_received_last_n_days',

  // Meeting timestamp specific
  BEGINS_N_MINS_LATER = 'begins_n_mins_later',
  ENDED_N_MINS_AGO = 'ended_n_mins_ago',
  // Luru meeting note specific (can be used for other fields later)
  PRESENT = 'present',
  NOT_PRESENT = 'not_present',
  // Associated call specific
  HAPPENED_IN_LAST_N_DAYS = 'happened_in_last_n_days',
  NOT_HAPPENED_IN_LAST_N_DAYS = 'not_happened_in_last_n_days',

  // Multi-reference specific
  CONTAINS_ALL = 'contains_all',
  CONTAINS_ANY = 'contains_any',
  CONTAINS_NONE = 'contains_none',
}

enum OperatorGroup {
  DATE = 'date',
  STRING = 'string',
  NUMBER = 'number',
  LIST = 'list',
  MULTILIST = 'multilist',
  ASSOCIATED_EMAIL = 'associated_email',
  ASSOCIATED_CALL = 'associated_call',
  BOOLEAN = 'boolean',
  REFERENCE = 'reference',
  MULTIREFERENCE = 'multi_reference',
  MEETING_TIMESTAMP = 'meeting_timestamp',
  LURU_NOTE = 'luru_note',
}

export class OperatorUtils {
  // operator(fieldName, value) => count = 2, e.g. is_equals(amount, 0)
  // operator(fieldName) => count = 1, e.g. is_blank(name)
  static operatorArgumentCountMap = {
    [WorkflowFilterOperator.EQ]: 2,
    [WorkflowFilterOperator.NEQ]: 2,
    [WorkflowFilterOperator.BLANK]: 1,
    [WorkflowFilterOperator.NOT_BLANK]: 1,
    [WorkflowFilterOperator.GT]: 2,
    [WorkflowFilterOperator.GTE]: 2,
    [WorkflowFilterOperator.INCREASED]: 1,
    [WorkflowFilterOperator.DECREASED]: 1,
    [WorkflowFilterOperator.INCREASED_BY_VALUE]: 2,
    [WorkflowFilterOperator.DECREASED_BY_VALUE]: 2,
    [WorkflowFilterOperator.INCREASED_BY_ATMOST_VALUE]: 2,
    [WorkflowFilterOperator.DECREASED_BY_ATMOST_VALUE]: 2,
    [WorkflowFilterOperator.INCREASED_BY_ATLEAST_VALUE]: 2,
    [WorkflowFilterOperator.DECREASED_BY_ATLEAST_VALUE]: 2,
    [WorkflowFilterOperator.INCREASED_BY_PERCENT]: 2,
    [WorkflowFilterOperator.DECREASED_BY_PERCENT]: 2,
    [WorkflowFilterOperator.INCREASED_BY_ATMOST_PERCENT]: 2,
    [WorkflowFilterOperator.DECREASED_BY_ATMOST_PERCENT]: 2,
    [WorkflowFilterOperator.INCREASED_BY_ATLEAST_PERCENT]: 2,
    [WorkflowFilterOperator.DECREASED_BY_ATLEAST_PERCENT]: 2,
    [WorkflowFilterOperator.LT]: 2,
    [WorkflowFilterOperator.LTE]: 2,
    // [WorkflowFilterOperator.BETWEEN]: 3,
    [WorkflowFilterOperator.IN]: 2, // the value argument is a list, (one argument)
    [WorkflowFilterOperator.NOT_IN]: 2,
    [WorkflowFilterOperator.VALUE_ADDED]: 2,
    [WorkflowFilterOperator.VALUE_REMOVED]: 2,
    [WorkflowFilterOperator.NUM_VALUES_EQUALS]: 2,
    [WorkflowFilterOperator.NUM_VALUES_NOT_EQUALS]: 2,
    [WorkflowFilterOperator.NUM_VALUES_AT_LEAST]: 2,
    [WorkflowFilterOperator.NUM_VALUES_AT_MOST]: 2,
    [WorkflowFilterOperator.STARTS_WITH]: 2,
    [WorkflowFilterOperator.CONTAINS]: 2,
    [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 2,
    // Date specific
    [WorkflowFilterOperator.PREV_N_DAYS]: 2,
    [WorkflowFilterOperator.NEXT_N_DAYS]: 2,
    [WorkflowFilterOperator.NOT_PREV_N_DAYS]: 2,
    [WorkflowFilterOperator.NOT_NEXT_N_DAYS]: 2,
    [WorkflowFilterOperator.BEFORE_N_DAYS]: 2,
    [WorkflowFilterOperator.AFTER_N_DAYS]: 2,
    [WorkflowFilterOperator.PREV_WEEK]: 1,
    [WorkflowFilterOperator.CURR_WEEK]: 1,
    [WorkflowFilterOperator.NEXT_WEEK]: 1,
    [WorkflowFilterOperator.PREV_MTH]: 1,
    [WorkflowFilterOperator.CURR_MTH]: 1,
    [WorkflowFilterOperator.NEXT_MTH]: 1,
    [WorkflowFilterOperator.PREV_QTR]: 1,
    [WorkflowFilterOperator.CURR_QTR]: 1,
    [WorkflowFilterOperator.NEXT_QTR]: 1,
    [WorkflowFilterOperator.PREV_FY]: 1,
    [WorkflowFilterOperator.CURR_FY]: 1,
    [WorkflowFilterOperator.NEXT_FY]: 1,
    [WorkflowFilterOperator.PREV_YEAR]: 1,
    [WorkflowFilterOperator.CURR_YEAR]: 1,
    [WorkflowFilterOperator.NEXT_YEAR]: 1,
    [WorkflowFilterOperator.CHANGED]: 1,
    [WorkflowFilterOperator.CHANGED_TO]: 2,
    [WorkflowFilterOperator.CHANGED_FROM]: 2,
    [WorkflowFilterOperator.NOT_CHANGED]: 1,
    [WorkflowFilterOperator.CHANGED_N_DAYS]: 2,
    [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 2,
    [WorkflowFilterOperator.YESTERDAY]: 1,
    [WorkflowFilterOperator.TODAY]: 1,
    [WorkflowFilterOperator.TOMORROW]: 1,
    [WorkflowFilterOperator.PUSHED_BACK]: 1,
    [WorkflowFilterOperator.PUSHED_OUT]: 1,
    [WorkflowFilterOperator.MOVED_FROM_PREV_WEEK]: 1,
    [WorkflowFilterOperator.MOVED_FROM_CURR_WEEK]: 1,
    [WorkflowFilterOperator.MOVED_FROM_NEXT_WEEK]: 1,
    [WorkflowFilterOperator.MOVED_FROM_CURR_MONTH]: 1,
    [WorkflowFilterOperator.MOVED_FROM_PREV_MONTH]: 1,
    [WorkflowFilterOperator.MOVED_FROM_NEXT_MONTH]: 1,
    [WorkflowFilterOperator.MOVED_FROM_CURR_QUARTER]: 1,
    [WorkflowFilterOperator.MOVED_FROM_PREV_QUARTER]: 1,
    [WorkflowFilterOperator.MOVED_FROM_NEXT_QUARTER]: 1,
    [WorkflowFilterOperator.MOVED_FROM_CURR_YEAR]: 1,
    [WorkflowFilterOperator.MOVED_FROM_PREV_YEAR]: 1,
    [WorkflowFilterOperator.MOVED_FROM_NEXT_YEAR]: 1,
    [WorkflowFilterOperator.MOVED_TO_PREV_WEEK]: 1,
    [WorkflowFilterOperator.MOVED_TO_CURR_WEEK]: 1,
    [WorkflowFilterOperator.MOVED_TO_NEXT_WEEK]: 1,
    [WorkflowFilterOperator.MOVED_TO_CURR_MONTH]: 1,
    [WorkflowFilterOperator.MOVED_TO_PREV_MONTH]: 1,
    [WorkflowFilterOperator.MOVED_TO_NEXT_MONTH]: 1,
    [WorkflowFilterOperator.MOVED_TO_CURR_QUARTER]: 1,
    [WorkflowFilterOperator.MOVED_TO_PREV_QUARTER]: 1,
    [WorkflowFilterOperator.MOVED_TO_NEXT_QUARTER]: 1,
    [WorkflowFilterOperator.MOVED_TO_CURR_YEAR]: 1,
    [WorkflowFilterOperator.MOVED_TO_PREV_YEAR]: 1,
    [WorkflowFilterOperator.MOVED_TO_NEXT_YEAR]: 1,
    // Email specific
    [WorkflowFilterOperator.EMAIL_SENT]: 1,
    [WorkflowFilterOperator.EMAIL_RECEIVED]: 1,
    [WorkflowFilterOperator.EMAIL_FORWARDED]: 1,
    [WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS]: 2,
    [WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS]: 2,
    [WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS]: 2,
    [WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS]: 2,
    [WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS]: 2,
    [WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS]: 2,
    // Meeting timestamp specific
    [WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 2,
    [WorkflowFilterOperator.ENDED_N_MINS_AGO]: 2,
    // Luru meeting note specific
    [WorkflowFilterOperator.PRESENT]: 1,
    [WorkflowFilterOperator.NOT_PRESENT]: 1,
    // Associate call specific
    [WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS]: 2,
    [WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS]: 2,
    // Multi reference specific
    [WorkflowFilterOperator.CONTAINS_ALL]: 2,
    [WorkflowFilterOperator.CONTAINS_ANY]: 2,
    [WorkflowFilterOperator.CONTAINS_NONE]: 2,
  }
  static v2operatorArgumentCountMap = {
    [V2WorkflowFilterOperator.EQ]: 2,
    [V2WorkflowFilterOperator.NEQ]: 2,
    [V2WorkflowFilterOperator.EMPTY]: 1,
    [V2WorkflowFilterOperator.NOT_EMPTY]: 1,
    [V2WorkflowFilterOperator.GT]: 2,
    [V2WorkflowFilterOperator.GTE]: 2,
    [V2WorkflowFilterOperator.INCREASED_BY_AT_LEAST]: 2,
    [V2WorkflowFilterOperator.DECREASED_BY_AT_LEAST]: 2,
    [V2WorkflowFilterOperator.INCREASED_BY_AT_MOST]: 2,
    [V2WorkflowFilterOperator.DECREASED_BY_AT_MOST]: 2,
    [V2WorkflowFilterOperator.LT]: 2,
    [V2WorkflowFilterOperator.LTE]: 2,
    // [V2WorkflowFilterOperator.BETWEEN]: 3,
    [V2WorkflowFilterOperator.IN]: 2, // the value argument is a list, (one argument)
    [V2WorkflowFilterOperator.NOT_IN]: 2,
    [V2WorkflowFilterOperator.STARTS_WITH]: 2,
    [V2WorkflowFilterOperator.CONTAINS]: 2,
    // Date specific
    [V2WorkflowFilterOperator.TODAY]: 2,
    [V2WorkflowFilterOperator.NOW]: 2,
    [V2WorkflowFilterOperator.NEXT_N_DAYS]: 2,
    [V2WorkflowFilterOperator.LAST_N_DAYS]: 2,
    [V2WorkflowFilterOperator.TOMORROW]: 2,
    [V2WorkflowFilterOperator.BEFORE]: 2,
    [V2WorkflowFilterOperator.ON_OR_BEFORE]: 2,
    [V2WorkflowFilterOperator.AFTER]: 2,
    [V2WorkflowFilterOperator.ON_OR_AFTER]: 2,
    [V2WorkflowFilterOperator.WITHIN]: 2,
    [V2WorkflowFilterOperator.NOT_WITHIN]: 2,
    [V2WorkflowFilterOperator.THIS_QUARTER]: 1,
    [V2WorkflowFilterOperator.NEXT_QUARTER]: 1,
    [V2WorkflowFilterOperator.LAST_QUARTER]: 1,
    [V2WorkflowFilterOperator.THIS_YEAR]: 1,
    [V2WorkflowFilterOperator.NEXT_YEAR]: 1,
    [V2WorkflowFilterOperator.LAST_YEAR]: 1,
    [V2WorkflowFilterOperator.THIS_MONTH]: 1,
    [V2WorkflowFilterOperator.NEXT_MONTH]: 1,
    [V2WorkflowFilterOperator.LAST_MONTH]: 1,
    [V2WorkflowFilterOperator.THIS_WEEK]: 1,
    [V2WorkflowFilterOperator.NEXT_WEEK]: 1,
    [V2WorkflowFilterOperator.LAST_WEEK]: 1,
    [V2WorkflowFilterOperator.CHANGED]: 1,
    [V2WorkflowFilterOperator.CHANGED_TO]: 2,
    // Boolean specific
    [V2WorkflowFilterOperator.TRUE]: 1,
    [V2WorkflowFilterOperator.FALSE]: 1,
    // Email specific
    [V2WorkflowFilterOperator.EMAIL_SENT]: 1,
    [V2WorkflowFilterOperator.EMAIL_RECEIVED]: 1,
    [V2WorkflowFilterOperator.EMAIL_FORWARDED]: 1,
    [V2WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS]: 2,
    [V2WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS]: 2,
    [V2WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS]: 2,
    [V2WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS]: 2,
    [V2WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS]: 2,
    [V2WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS]: 2,
    // Meeting timestamp specific
    [V2WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 2,
    [V2WorkflowFilterOperator.ENDED_N_MINS_AGO]: 2,
    // Luru meeting note specific
    [V2WorkflowFilterOperator.PRESENT]: 1,
    [V2WorkflowFilterOperator.NOT_PRESENT]: 1,
    // Associate call specific
    [V2WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS]: 2,
    [V2WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS]: 2,
    // Multi reference specific
    [V2WorkflowFilterOperator.CONTAINS_ALL]: 2,
    [V2WorkflowFilterOperator.CONTAINS_ANY]: 2,
    [V2WorkflowFilterOperator.CONTAINS_NONE]: 2,
  }

  static operatorSyntaxMap = {
    // Intentionally keeping EQ and NEQ at the first positions; don't change
    [WorkflowFilterOperator.EQ]: '=',
    [WorkflowFilterOperator.NEQ]: '!=',
    [WorkflowFilterOperator.BLANK]: '=',
    [WorkflowFilterOperator.CONTAINS]: '~',
    [WorkflowFilterOperator.DOES_NOT_CONTAIN]: '!~',
    [WorkflowFilterOperator.CURR_FY]: '=',
    [WorkflowFilterOperator.CURR_YEAR]: '=',
    [WorkflowFilterOperator.CURR_MTH]: '=',
    [WorkflowFilterOperator.CURR_QTR]: '=',
    [WorkflowFilterOperator.GT]: '>',
    [WorkflowFilterOperator.GTE]: '>=',
    [WorkflowFilterOperator.INCREASED]: 'DELTA>0',
    [WorkflowFilterOperator.DECREASED]: 'DELTA<0',
    [WorkflowFilterOperator.INCREASED_BY_VALUE]: '+DELTA=',
    [WorkflowFilterOperator.DECREASED_BY_VALUE]: '-DELTA=',
    [WorkflowFilterOperator.INCREASED_BY_ATMOST_VALUE]: '+DELTA<=',
    [WorkflowFilterOperator.DECREASED_BY_ATMOST_VALUE]: '-DELTA<=',
    [WorkflowFilterOperator.INCREASED_BY_ATLEAST_VALUE]: '+DELTA>=',
    [WorkflowFilterOperator.DECREASED_BY_ATLEAST_VALUE]: '-DELTA>=',
    [WorkflowFilterOperator.INCREASED_BY_PERCENT]: '+DELTA%=',
    [WorkflowFilterOperator.DECREASED_BY_PERCENT]: '-DELTA%=',
    [WorkflowFilterOperator.INCREASED_BY_ATMOST_PERCENT]: '+DELTA%<=',
    [WorkflowFilterOperator.DECREASED_BY_ATMOST_PERCENT]: '-DELTA%<=',
    [WorkflowFilterOperator.INCREASED_BY_ATLEAST_PERCENT]: '+DELTA%>=',
    [WorkflowFilterOperator.DECREASED_BY_ATLEAST_PERCENT]: '-DELTA%>=',
    [WorkflowFilterOperator.IN]: 'IN',
    [WorkflowFilterOperator.LT]: '<',
    [WorkflowFilterOperator.LTE]: '<=',
    [WorkflowFilterOperator.PREV_N_DAYS]: '=',
    [WorkflowFilterOperator.NEXT_N_DAYS]: '=',
    [WorkflowFilterOperator.NOT_PREV_N_DAYS]: '!=',
    [WorkflowFilterOperator.NOT_NEXT_N_DAYS]: '!=',
    [WorkflowFilterOperator.BEFORE_N_DAYS]: '<',
    [WorkflowFilterOperator.AFTER_N_DAYS]: '>',
    [WorkflowFilterOperator.NEXT_FY]: '=',
    [WorkflowFilterOperator.NEXT_YEAR]: '=',
    [WorkflowFilterOperator.NEXT_MTH]: '=',
    [WorkflowFilterOperator.NEXT_QTR]: '=',
    [WorkflowFilterOperator.NOT_BLANK]: '!=',
    [WorkflowFilterOperator.NOT_IN]: 'NOT IN',
    [WorkflowFilterOperator.PREV_FY]: '=',
    [WorkflowFilterOperator.PREV_YEAR]: '=',
    [WorkflowFilterOperator.PREV_MTH]: '=',
    [WorkflowFilterOperator.PREV_QTR]: '=',
    [WorkflowFilterOperator.STARTS_WITH]: '^~',
    [WorkflowFilterOperator.CHANGED]: '=',
    [WorkflowFilterOperator.CHANGED_TO]: '=',
    [WorkflowFilterOperator.CHANGED_FROM]: '=',
    [WorkflowFilterOperator.NOT_CHANGED]: '!=',
    [WorkflowFilterOperator.CHANGED_N_DAYS]: '=',
    [WorkflowFilterOperator.UNCHANGED_N_DAYS]: '!=',
    [WorkflowFilterOperator.EMAIL_SENT]: '=',
    [WorkflowFilterOperator.EMAIL_RECEIVED]: '=',
    [WorkflowFilterOperator.EMAIL_FORWARDED]: '=',
    [WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS]: '=',
    [WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS]: '=',
    [WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS]: '=',
    [WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS]: '!=',
    [WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS]: '!=',
    [WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS]: '!=',
    [WorkflowFilterOperator.YESTERDAY]: '=',
    [WorkflowFilterOperator.TODAY]: '=',
    [WorkflowFilterOperator.TOMORROW]: '=',
    [WorkflowFilterOperator.PREV_WEEK]: '=',
    [WorkflowFilterOperator.CURR_WEEK]: '=',
    [WorkflowFilterOperator.NEXT_WEEK]: '=',
    [WorkflowFilterOperator.PUSHED_OUT]: '=',
    [WorkflowFilterOperator.PUSHED_BACK]: '=',
    [WorkflowFilterOperator.MOVED_FROM_PREV_WEEK]: '=',
    [WorkflowFilterOperator.MOVED_FROM_CURR_WEEK]: '=',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_WEEK]: '=',
    [WorkflowFilterOperator.MOVED_FROM_PREV_MONTH]: '=',
    [WorkflowFilterOperator.MOVED_FROM_CURR_MONTH]: '=',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_MONTH]: '=',
    [WorkflowFilterOperator.MOVED_FROM_PREV_QUARTER]: '=',
    [WorkflowFilterOperator.MOVED_FROM_CURR_QUARTER]: '=',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_QUARTER]: '=',
    [WorkflowFilterOperator.MOVED_FROM_PREV_YEAR]: '=',
    [WorkflowFilterOperator.MOVED_FROM_CURR_YEAR]: '=',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_YEAR]: '=',
    [WorkflowFilterOperator.MOVED_TO_PREV_WEEK]: '=',
    [WorkflowFilterOperator.MOVED_TO_CURR_WEEK]: '=',
    [WorkflowFilterOperator.MOVED_TO_NEXT_WEEK]: '=',
    [WorkflowFilterOperator.MOVED_TO_PREV_MONTH]: '=',
    [WorkflowFilterOperator.MOVED_TO_CURR_MONTH]: '=',
    [WorkflowFilterOperator.MOVED_TO_NEXT_MONTH]: '=',
    [WorkflowFilterOperator.MOVED_TO_PREV_QUARTER]: '=',
    [WorkflowFilterOperator.MOVED_TO_CURR_QUARTER]: '=',
    [WorkflowFilterOperator.MOVED_TO_NEXT_QUARTER]: '=',
    [WorkflowFilterOperator.MOVED_TO_PREV_YEAR]: '=',
    [WorkflowFilterOperator.MOVED_TO_CURR_YEAR]: '=',
    [WorkflowFilterOperator.MOVED_TO_NEXT_YEAR]: '=',
    [WorkflowFilterOperator.VALUE_ADDED]: '+=',
    [WorkflowFilterOperator.VALUE_REMOVED]: '-=',
    [WorkflowFilterOperator.NUM_VALUES_EQUALS]: '=',
    [WorkflowFilterOperator.NUM_VALUES_NOT_EQUALS]: '!=',
    [WorkflowFilterOperator.NUM_VALUES_AT_LEAST]: '>=',
    [WorkflowFilterOperator.NUM_VALUES_AT_MOST]: '<=',
    // Meeting timestamp specific
    [WorkflowFilterOperator.BEGINS_N_MINS_LATER]: '=',
    [WorkflowFilterOperator.ENDED_N_MINS_AGO]: '=',
    // Luru meeting note specific
    [WorkflowFilterOperator.PRESENT]: '=',
    [WorkflowFilterOperator.NOT_PRESENT]: '!=',
    // Associated call specific
    [WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS]: '=',
    [WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS]: '!=',
    // Multi reference specific
    [WorkflowFilterOperator.CONTAINS_ALL]: '~all_of',
    [WorkflowFilterOperator.CONTAINS_ANY]: '~any_of',
    [WorkflowFilterOperator.CONTAINS_NONE]: '~none_of',
  }

  static v2operatorSyntaxMap = {
    // Intentionally keeping EQ and NEQ at the first positions; don't change
    [V2WorkflowFilterOperator.EQ]: V2WorkflowFilterOperator.EQ,
    [V2WorkflowFilterOperator.NEQ]: V2WorkflowFilterOperator.NEQ,
    [V2WorkflowFilterOperator.EMPTY]: V2WorkflowFilterOperator.EMPTY,
    [V2WorkflowFilterOperator.CONTAINS]: V2WorkflowFilterOperator.CONTAINS,
    [V2WorkflowFilterOperator.GT]: V2WorkflowFilterOperator.GT,
    [V2WorkflowFilterOperator.GTE]: V2WorkflowFilterOperator.GTE,
    [V2WorkflowFilterOperator.INCREASED_BY_AT_LEAST]: V2WorkflowFilterOperator.INCREASED_BY_AT_LEAST,
    [V2WorkflowFilterOperator.DECREASED_BY_AT_LEAST]: V2WorkflowFilterOperator.DECREASED_BY_AT_LEAST,
    [V2WorkflowFilterOperator.INCREASED_BY_AT_MOST]: V2WorkflowFilterOperator.INCREASED_BY_AT_MOST,
    [V2WorkflowFilterOperator.DECREASED_BY_AT_MOST]: V2WorkflowFilterOperator.DECREASED_BY_AT_MOST,
    [V2WorkflowFilterOperator.IN]: V2WorkflowFilterOperator.IN,
    [V2WorkflowFilterOperator.LT]: V2WorkflowFilterOperator.LT,
    [V2WorkflowFilterOperator.LTE]: V2WorkflowFilterOperator.LTE,
    [V2WorkflowFilterOperator.BEFORE]: V2WorkflowFilterOperator.BEFORE,
    [V2WorkflowFilterOperator.ON_OR_BEFORE]: V2WorkflowFilterOperator.ON_OR_BEFORE,
    [V2WorkflowFilterOperator.AFTER]: V2WorkflowFilterOperator.AFTER,
    [V2WorkflowFilterOperator.ON_OR_AFTER]: V2WorkflowFilterOperator.ON_OR_AFTER,
    [V2WorkflowFilterOperator.WITHIN]: V2WorkflowFilterOperator.WITHIN,
    [V2WorkflowFilterOperator.THIS_QUARTER]: V2WorkflowFilterOperator.THIS_QUARTER,
    [V2WorkflowFilterOperator.NEXT_QUARTER]: V2WorkflowFilterOperator.NEXT_QUARTER,
    [V2WorkflowFilterOperator.LAST_QUARTER]: V2WorkflowFilterOperator.LAST_QUARTER,
    [V2WorkflowFilterOperator.THIS_YEAR]: V2WorkflowFilterOperator.THIS_YEAR,
    [V2WorkflowFilterOperator.NEXT_YEAR]: V2WorkflowFilterOperator.NEXT_YEAR,
    [V2WorkflowFilterOperator.LAST_YEAR]: V2WorkflowFilterOperator.LAST_YEAR,
    [V2WorkflowFilterOperator.THIS_WEEK]: V2WorkflowFilterOperator.THIS_WEEK,
    [V2WorkflowFilterOperator.NEXT_WEEK]: V2WorkflowFilterOperator.NEXT_WEEK,
    [V2WorkflowFilterOperator.LAST_WEEK]: V2WorkflowFilterOperator.LAST_WEEK,
    [V2WorkflowFilterOperator.NOT_EMPTY]: V2WorkflowFilterOperator.NOT_EMPTY,
    [V2WorkflowFilterOperator.NOT_IN]: V2WorkflowFilterOperator.NOT_IN,
    [V2WorkflowFilterOperator.STARTS_WITH]: V2WorkflowFilterOperator.STARTS_WITH,
    [V2WorkflowFilterOperator.CHANGED]: V2WorkflowFilterOperator.CHANGED,
    [V2WorkflowFilterOperator.CHANGED_TO]: V2WorkflowFilterOperator.CHANGED_TO,
    [V2WorkflowFilterOperator.EMAIL_SENT]: V2WorkflowFilterOperator.EMAIL_SENT,
    [V2WorkflowFilterOperator.EMAIL_RECEIVED]: V2WorkflowFilterOperator.EMAIL_RECEIVED,
    [V2WorkflowFilterOperator.EMAIL_FORWARDED]: V2WorkflowFilterOperator.EMAIL_FORWARDED,
    [V2WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS]: V2WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS,
    [V2WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS]: V2WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS,
    [V2WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS]: V2WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS,
    [V2WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS]: V2WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS,
    [V2WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS]: V2WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS,
    [V2WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS]: V2WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS,
    // Boolean Specific
    [V2WorkflowFilterOperator.TRUE]: V2WorkflowFilterOperator.TRUE,
    [V2WorkflowFilterOperator.FALSE]: V2WorkflowFilterOperator.FALSE,
    // Meeting timestamp specific
    [V2WorkflowFilterOperator.BEGINS_N_MINS_LATER]: V2WorkflowFilterOperator.BEGINS_N_MINS_LATER,
    [V2WorkflowFilterOperator.ENDED_N_MINS_AGO]: V2WorkflowFilterOperator.ENDED_N_MINS_AGO,
    // Luru meeting note specific
    [V2WorkflowFilterOperator.PRESENT]: V2WorkflowFilterOperator.PRESENT,
    [V2WorkflowFilterOperator.NOT_PRESENT]: V2WorkflowFilterOperator.NOT_PRESENT,
    // Associated call specific
    [V2WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS]: V2WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS,
    [V2WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS]: V2WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS,
    // Multi reference specific
    [V2WorkflowFilterOperator.CONTAINS_ALL]: V2WorkflowFilterOperator.CONTAINS_ALL,
    [V2WorkflowFilterOperator.CONTAINS_ANY]: V2WorkflowFilterOperator.CONTAINS_ALL,
    [V2WorkflowFilterOperator.CONTAINS_NONE]: V2WorkflowFilterOperator.CONTAINS_NONE,
  }

  static unaryOperatorArgument: Record<string, string> = {
    [WorkflowFilterOperator.BLANK]: '%%EMPTY%%',
    [WorkflowFilterOperator.NOT_BLANK]: '%%EMPTY%%',
    [WorkflowFilterOperator.CURR_FY]: '%%THIS_FISCAL_YEAR%%',
    [WorkflowFilterOperator.CURR_YEAR]: '%%THIS_YEAR%%',
    [WorkflowFilterOperator.CURR_MTH]: '%%THIS_MONTH%%',
    [WorkflowFilterOperator.CURR_QTR]: '%%THIS_QUARTER%%',
    [WorkflowFilterOperator.PREV_FY]: '%%LAST_FISCAL_YEAR%%',
    [WorkflowFilterOperator.PREV_YEAR]: '%%LAST_YEAR%%',
    [WorkflowFilterOperator.PREV_MTH]: '%%LAST_MONTH%%',
    [WorkflowFilterOperator.PREV_QTR]: '%%LAST_QUARTER%%',
    [WorkflowFilterOperator.NEXT_FY]: '%%NEXT_FISCAL_YEAR%%',
    [WorkflowFilterOperator.NEXT_YEAR]: '%%NEXT_YEAR%%',
    [WorkflowFilterOperator.NEXT_MTH]: '%%NEXT_MONTH%%',
    [WorkflowFilterOperator.NEXT_QTR]: '%%NEXT_QUARTER%%',
    [WorkflowFilterOperator.CHANGED]: '%%CHANGED%%',
    [WorkflowFilterOperator.NOT_CHANGED]: '%%CHANGED%%',
    [WorkflowFilterOperator.EMAIL_SENT]: '%%EMAIL_SENT%%',
    [WorkflowFilterOperator.EMAIL_RECEIVED]: '%%EMAIL_RECEIVED%%',
    [WorkflowFilterOperator.EMAIL_FORWARDED]: '%%EMAIL_ACTIVITY%%',
    [WorkflowFilterOperator.YESTERDAY]: '%%YESTERDAY%%',
    [WorkflowFilterOperator.TODAY]: '%%TODAY%%',
    [WorkflowFilterOperator.TOMORROW]: '%%TOMORROW%%',
    [WorkflowFilterOperator.PREV_WEEK]: '%%LAST_WEEK%%',
    [WorkflowFilterOperator.CURR_WEEK]: '%%THIS_WEEK%%',
    [WorkflowFilterOperator.NEXT_WEEK]: '%%NEXT_WEEK%%',
    [WorkflowFilterOperator.PUSHED_OUT]: '%%PUSHED_OUT%%',
    [WorkflowFilterOperator.PUSHED_BACK]: '%%PUSHED_BACK%%',
    [WorkflowFilterOperator.MOVED_FROM_PREV_WEEK]: '%%MOVED_FROM_LAST_WEEK%%',
    [WorkflowFilterOperator.MOVED_FROM_CURR_WEEK]: '%%MOVED_FROM_THIS_WEEK%%',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_WEEK]: '%%MOVED_FROM_NEXT_WEEK%%',
    [WorkflowFilterOperator.MOVED_FROM_PREV_MONTH]: '%%MOVED_FROM_LAST_MONTH%%',
    [WorkflowFilterOperator.MOVED_FROM_CURR_MONTH]: '%%MOVED_FROM_THIS_MONTH%%',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_MONTH]: '%%MOVED_FROM_NEXT_MONTH%%',
    [WorkflowFilterOperator.MOVED_FROM_PREV_QUARTER]: '%%MOVED_FROM_LAST_QUARTER%%',
    [WorkflowFilterOperator.MOVED_FROM_CURR_QUARTER]: '%%MOVED_FROM_THIS_QUARTER%%',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_QUARTER]: '%%MOVED_FROM_NEXT_QUARTER%%',
    [WorkflowFilterOperator.MOVED_FROM_PREV_YEAR]: '%%MOVED_FROM_LAST_YEAR%%',
    [WorkflowFilterOperator.MOVED_FROM_CURR_YEAR]: '%%MOVED_FROM_THIS_YEAR%%',
    [WorkflowFilterOperator.MOVED_FROM_NEXT_YEAR]: '%%MOVED_FROM_NEXT_YEAR%%',
    [WorkflowFilterOperator.MOVED_TO_PREV_WEEK]: '%%MOVED_TO_LAST_WEEK%%',
    [WorkflowFilterOperator.MOVED_TO_CURR_WEEK]: '%%MOVED_TO_THIS_WEEK%%',
    [WorkflowFilterOperator.MOVED_TO_NEXT_WEEK]: '%%MOVED_TO_NEXT_WEEK%%',
    [WorkflowFilterOperator.MOVED_TO_PREV_MONTH]: '%%MOVED_TO_LAST_MONTH%%',
    [WorkflowFilterOperator.MOVED_TO_CURR_MONTH]: '%%MOVED_TO_THIS_MONTH%%',
    [WorkflowFilterOperator.MOVED_TO_NEXT_MONTH]: '%%MOVED_TO_NEXT_MONTH%%',
    [WorkflowFilterOperator.MOVED_TO_PREV_QUARTER]: '%%MOVED_TO_LAST_QUARTER%%',
    [WorkflowFilterOperator.MOVED_TO_CURR_QUARTER]: '%%MOVED_TO_THIS_QUARTER%%',
    [WorkflowFilterOperator.MOVED_TO_NEXT_QUARTER]: '%%MOVED_TO_NEXT_QUARTER%%',
    [WorkflowFilterOperator.MOVED_TO_PREV_YEAR]: '%%MOVED_TO_LAST_YEAR%%',
    [WorkflowFilterOperator.MOVED_TO_CURR_YEAR]: '%%MOVED_TO_THIS_YEAR%%',
    [WorkflowFilterOperator.MOVED_TO_NEXT_YEAR]: '%%MOVED_TO_NEXT_YEAR%%',
  }

  static unaryV2OperatorArgument: Record<string, string> = {
    [V2WorkflowFilterOperator.EMPTY]: '%%EMPTY%%',
    [V2WorkflowFilterOperator.NOT_EMPTY]: '%%NOT_EMPTY%%',
    [V2WorkflowFilterOperator.TODAY]: '%%TODAY%%',
    [V2WorkflowFilterOperator.NOW]: '%%NOW%%',
    [V2WorkflowFilterOperator.TRUE]: '%%TRUE%%',
    [V2WorkflowFilterOperator.FALSE]: '%%FASLE%%',
    [V2WorkflowFilterOperator.THIS_QUARTER]: '%%THIS_QUARTER%%',
    [V2WorkflowFilterOperator.NEXT_QUARTER]: '%%NEXT_QUARTER%%',
    [V2WorkflowFilterOperator.LAST_QUARTER]: '%%LAST_QUARTER%%',
    [V2WorkflowFilterOperator.THIS_YEAR]: '%%THIS_YEAR%%',
    [V2WorkflowFilterOperator.NEXT_YEAR]: '%%NEXT_YEAR%%',
    [V2WorkflowFilterOperator.LAST_YEAR]: '%%LAST_YEAR%%',
    [V2WorkflowFilterOperator.THIS_MONTH]: '%%THIS_MONTH%%',
    [V2WorkflowFilterOperator.NEXT_MONTH]: '%%NEXT_MONTH%%',
    [V2WorkflowFilterOperator.LAST_MONTH]: '%%LAST_MONTH%%',
    [V2WorkflowFilterOperator.THIS_WEEK]: '%%THIS_WEEK%%',
    [V2WorkflowFilterOperator.NEXT_WEEK]: '%%NEXT_WEEK%%',
    [V2WorkflowFilterOperator.LAST_WEEK]: '%%LAST_WEEK%%',
    [V2WorkflowFilterOperator.CHANGED]: '%%CHANGED%%',
    [V2WorkflowFilterOperator.EMAIL_SENT]: '%%EMAIL_SENT%%',
    [V2WorkflowFilterOperator.EMAIL_RECEIVED]: '%%EMAIL_RECEIVED%%',
    [V2WorkflowFilterOperator.EMAIL_FORWARDED]: '%%EMAIL_ACTIVITY%%',
  }

  static getNumArguments = (operator: WorkflowFilterOperator) => OperatorUtils.operatorArgumentCountMap[operator]

  static getV2NumArguments = (operator: V2WorkflowFilterOperator) => OperatorUtils.v2operatorArgumentCountMap[operator]

  static getOperatorSyntax = (operator: WorkflowFilterOperator) => OperatorUtils.operatorSyntaxMap[operator]

  static getV2OperatorSyntax = (operator: V2WorkflowFilterOperator) =>
    OperatorUtils.v2operatorSyntaxMap[operator as keyof typeof OperatorUtils.v2operatorSyntaxMap]

  static getOperatorFromDataValues = (op: string, value: any) => {
    var matchingOperators = Object.keys(OperatorUtils.operatorSyntaxMap).filter(
      (operator) => OperatorUtils.operatorSyntaxMap[operator as keyof typeof OperatorUtils.operatorSyntaxMap] === op
    )

    if (matchingOperators.length === 0) {
      return op
    }

    if (matchingOperators.length === 1) {
      return matchingOperators[0]
    }

    var matchingUnaryOperators = matchingOperators.filter((op) => OperatorUtils.unaryOperatorArgument[op] === value)

    if (matchingUnaryOperators.length === 1) {
      return matchingUnaryOperators[0]
    }

    // matchingUnaryOperators.length can't be >= 2
    return matchingOperators[0]
  }

  static getOperators = (luruFieldType: string, alertType: WorkflowTrigger) => {
    const crm = LuruUser.getCurrentUserCrmName()
    const operatorGroup = LuruFieldTypeOperatorGroupMap[luruFieldType as LuruFieldType]
    return crm === CRMProvider.SFDC || crm === CRMProvider.HUBSPOT || crm === CRMProvider.SFDC_SANDBOX
      ? WorkflowFilterOperators[crm][alertType][operatorGroup]
      : {}
  }

  static getV2Operators = (luruFieldType: string, triggerType: WorkflowTriggerV2) => {
    const crm = LuruUser.getCurrentUserCrmName()
    const operatorGroup = LuruFieldTypeOperatorGroupMap[luruFieldType as LuruFieldType]
    var operators =
      crm === CRMProvider.SFDC || crm === CRMProvider.HUBSPOT || crm === CRMProvider.SFDC_SANDBOX
        ? V2WorkflowFilterOperators[crm][triggerType][operatorGroup]
        : {}

    return operators
  }

  static constructFilterExpression = (expression: WorkflowFilterExpressionTree, schema: CrmRecordSchema): any => {
    if (Array.isArray(expression)) {
      return expression.map((e) => this.constructFilterExpression(e, schema)).filter((x) => x !== undefined)
    } else if (Object.keys(expression).length === 1) {
      // this is a tree
      let e = expression as WorkflowFilterExpressionTree
      return {
        [Object.keys(e)[0]]: this.constructFilterExpression(
          // @ts-ignore
          Object.values(e)[0],
          schema
        ),
      }
    } else if (expression) {
      // this is a filter expression
      var e = expression as unknown as WorkflowFilterExpression

      if (e.field.trim() === '') {
        return undefined
      }

      var sysFieldName = schema.find((f) => f.label === e.field)?.name

      if (!sysFieldName) {
        sysFieldName = schema.find((f) => f.name === e.field)?.name
      }

      var originalOp = OperatorUtils.getOperatorFromDataValues(e.op, e.value) as WorkflowFilterOperator
      var fieldValue =
        this.getNumArguments(originalOp) === 1
          ? this.unaryOperatorArgument[e.op]
          : /^-?\d*\.?\d+$/.test(e.value)
          ? Number(e.value)
          : e.value

      return {
        object_name: e.op,
        field: sysFieldName,
        op: this.getOperatorSyntax(originalOp),
        // original_op: e.op,
        value: fieldValue,
      }
    } else {
      return {}
    }
  }

  static checkOperatorValidity = (
    alert: WorkflowTrigger,
    operator: WorkflowFilterOperator,
    fieldType: LuruFieldType | undefined
  ) => {
    if (fieldType === undefined) {
      return { allowed: true }
    }

    const operatorGroup = LuruFieldTypeOperatorGroupMap[fieldType]
    var crm = LuruUser.getCurrentUserCrmName()
    if (crm === CRMProvider.SFDC_SANDBOX) {
      crm = CRMProvider.SFDC
    }

    const allowedOperators =
      crm === CRMProvider.HUBSPOT || crm === CRMProvider.SFDC ? WorkflowFilterOperators[crm][alert][operatorGroup] : []

    if (Object.keys(allowedOperators || {}).includes(operator)) {
      return { allowed: true }
    }

    console.log(
      'checkOperatorValidity' +
        `Operator is not supported for ${
          alert === WorkflowTrigger.CREATE_UPDATE ? 'realtime record update' : 'scheduled'
        } triggers.  Please change workflow type to ${
          alert === WorkflowTrigger.CREATE_UPDATE ? 'Scheduled' : 'Real Time'
        } or choose a supported operator`
    )
    return {
      allowed: false,
      message: `Operator is not supported for ${
        alert === WorkflowTrigger.CREATE_UPDATE ? 'realtime record update' : 'scheduled'
      } triggers.  Please change workflow type to ${
        alert === WorkflowTrigger.CREATE_UPDATE ? 'Scheduled' : 'Real Time'
      } or choose a supported operator`,
    }
  }

  static isChangeOperator = (operator: V2WorkflowFilterOperator) => {
    return [
      V2WorkflowFilterOperator.CHANGED,
      V2WorkflowFilterOperator.CHANGED_TO,
      V2WorkflowFilterOperator.DECREASED_BY_AT_LEAST,
      V2WorkflowFilterOperator.DECREASED_BY_AT_MOST,
      V2WorkflowFilterOperator.INCREASED_BY_AT_LEAST,
      V2WorkflowFilterOperator.INCREASED_BY_AT_MOST,
    ].includes(operator)
  }

  static isDateRangeOperator = (operator: V2WorkflowFilterOperator) => {
    return [V2WorkflowFilterOperator.WITHIN, V2WorkflowFilterOperator.NOT_WITHIN].includes(operator)
  }
}

const WorkflowFilterOperators: Record<string, Record<string, Record<string, Record<string, string>>>> = {
  // Salesforce realtime workflows
  [CRMProvider.SFDC]: {
    [WorkflowTrigger.CREATE_UPDATE]: {
      [OperatorGroup.STRING]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.NUMBER]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is greater than',
        [WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [WorkflowFilterOperator.LT]: 'is less than',
        [WorkflowFilterOperator.LTE]: 'is less than or equals',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.INCREASED]: 'increased',
        [WorkflowFilterOperator.DECREASED]: 'decreased',
        [WorkflowFilterOperator.INCREASED_BY_VALUE]: 'increased by',
        [WorkflowFilterOperator.DECREASED_BY_VALUE]: 'decreased by',
        [WorkflowFilterOperator.INCREASED_BY_ATMOST_VALUE]: 'increased by at most',
        [WorkflowFilterOperator.DECREASED_BY_ATMOST_VALUE]: 'decreased by at most',
        [WorkflowFilterOperator.INCREASED_BY_ATLEAST_VALUE]: 'increased by at least',
        [WorkflowFilterOperator.DECREASED_BY_ATLEAST_VALUE]: 'decreased by at least',
        [WorkflowFilterOperator.INCREASED_BY_PERCENT]: 'increased by %',
        [WorkflowFilterOperator.DECREASED_BY_PERCENT]: 'decreased by %',
        [WorkflowFilterOperator.INCREASED_BY_ATMOST_PERCENT]: 'increased by at most %',
        [WorkflowFilterOperator.DECREASED_BY_ATMOST_PERCENT]: 'decreased by at most %',
        [WorkflowFilterOperator.INCREASED_BY_ATLEAST_PERCENT]: 'increased by at least %',
        [WorkflowFilterOperator.DECREASED_BY_ATLEAST_PERCENT]: 'decreased by at least %',
      },
      [OperatorGroup.LIST]: {
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.DATE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.GT]: 'is after',
        [WorkflowFilterOperator.LT]: 'is before',
        [WorkflowFilterOperator.PREV_N_DAYS]: 'in last N days',
        [WorkflowFilterOperator.NEXT_N_DAYS]: 'in next N days',
        [WorkflowFilterOperator.NOT_PREV_N_DAYS]: 'not in last N days',
        [WorkflowFilterOperator.NOT_NEXT_N_DAYS]: 'not in next N days',
        [WorkflowFilterOperator.BEFORE_N_DAYS]: 'before N days',
        [WorkflowFilterOperator.AFTER_N_DAYS]: 'after N days',
        [WorkflowFilterOperator.YESTERDAY]: 'yesterday',
        [WorkflowFilterOperator.TODAY]: 'today',
        [WorkflowFilterOperator.TOMORROW]: 'tomorrow',
        [WorkflowFilterOperator.PREV_WEEK]: 'in last week',
        [WorkflowFilterOperator.CURR_WEEK]: 'in this week',
        [WorkflowFilterOperator.NEXT_WEEK]: 'in next week',
        [WorkflowFilterOperator.PREV_MTH]: 'in last month',
        [WorkflowFilterOperator.CURR_MTH]: 'in this month',
        [WorkflowFilterOperator.NEXT_MTH]: 'in next month',
        [WorkflowFilterOperator.PREV_QTR]: 'in last quarter',
        [WorkflowFilterOperator.CURR_QTR]: 'in current quarter',
        [WorkflowFilterOperator.NEXT_QTR]: 'in next quarter',
        [WorkflowFilterOperator.PREV_FY]: 'in last FY',
        [WorkflowFilterOperator.CURR_FY]: 'in current FY',
        [WorkflowFilterOperator.NEXT_FY]: 'in next FY',
        [WorkflowFilterOperator.PUSHED_OUT]: 'pushed out',
        [WorkflowFilterOperator.PUSHED_BACK]: 'pushed back',
        [WorkflowFilterOperator.MOVED_FROM_PREV_WEEK]: 'moved from last week',
        [WorkflowFilterOperator.MOVED_FROM_CURR_WEEK]: 'moved from this week',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_WEEK]: 'moved from next week',
        [WorkflowFilterOperator.MOVED_FROM_PREV_MONTH]: 'moved from last month',
        [WorkflowFilterOperator.MOVED_FROM_CURR_MONTH]: 'moved from this month',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_MONTH]: 'moved from next month',
        [WorkflowFilterOperator.MOVED_FROM_PREV_QUARTER]: 'moved from last quarter',
        [WorkflowFilterOperator.MOVED_FROM_CURR_QUARTER]: 'moved from this quarter',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_QUARTER]: 'moved from next quarter',
        [WorkflowFilterOperator.MOVED_FROM_PREV_YEAR]: 'moved from last year',
        [WorkflowFilterOperator.MOVED_FROM_CURR_YEAR]: 'moved from this year',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_YEAR]: 'moved from next year',
        [WorkflowFilterOperator.MOVED_TO_PREV_WEEK]: 'moved to last week',
        [WorkflowFilterOperator.MOVED_TO_CURR_WEEK]: 'moved to this week',
        [WorkflowFilterOperator.MOVED_TO_NEXT_WEEK]: 'moved to next week',
        [WorkflowFilterOperator.MOVED_TO_PREV_MONTH]: 'moved to last month',
        [WorkflowFilterOperator.MOVED_TO_CURR_MONTH]: 'moved to this month',
        [WorkflowFilterOperator.MOVED_TO_NEXT_MONTH]: 'moved to next month',
        [WorkflowFilterOperator.MOVED_TO_PREV_QUARTER]: 'moved to last quarter',
        [WorkflowFilterOperator.MOVED_TO_CURR_QUARTER]: 'moved to this quarter',
        [WorkflowFilterOperator.MOVED_TO_NEXT_QUARTER]: 'moved to next quarter',
        [WorkflowFilterOperator.MOVED_TO_PREV_YEAR]: 'moved to last year',
        [WorkflowFilterOperator.MOVED_TO_CURR_YEAR]: 'moved to this year',
        [WorkflowFilterOperator.MOVED_TO_NEXT_YEAR]: 'moved to next year',
      },
      [OperatorGroup.BOOLEAN]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.MULTILIST]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.VALUE_ADDED]: 'has a value added',
        [WorkflowFilterOperator.VALUE_REMOVED]: 'has a value removed',
        [WorkflowFilterOperator.NUM_VALUES_EQUALS]: 'has N values',
        [WorkflowFilterOperator.NUM_VALUES_NOT_EQUALS]: 'does not have N values',
        [WorkflowFilterOperator.NUM_VALUES_AT_LEAST]: 'has at least N values',
        [WorkflowFilterOperator.NUM_VALUES_AT_MOST]: 'has at most N values',
      },
      [OperatorGroup.REFERENCE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      // TODO: Uncomment when SFDC RT supports this
      // [OperatorGroup.MEETING_TIMESTAMP]: {
      //   [WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 'begins in n minutes',
      //   [WorkflowFilterOperator.ENDED_N_MINS_AGO]: 'ended n minutes ago',
      // },
    },
    // Salesforce scheduled workflows
    [WorkflowTrigger.SCHEDULE]: {
      [OperatorGroup.STRING]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
      },
      [OperatorGroup.NUMBER]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is greater than',
        [WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [WorkflowFilterOperator.LT]: 'is less than',
        [WorkflowFilterOperator.LTE]: 'is less than or equals',
      },
      [OperatorGroup.LIST]: {
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
      },
      [OperatorGroup.DATE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is after',
        [WorkflowFilterOperator.LT]: 'is before',
        [WorkflowFilterOperator.PREV_N_DAYS]: 'in last N days',
        [WorkflowFilterOperator.NEXT_N_DAYS]: 'in next N days',
        [WorkflowFilterOperator.NOT_PREV_N_DAYS]: 'not in last N days',
        [WorkflowFilterOperator.NOT_NEXT_N_DAYS]: 'not in next N days',
        [WorkflowFilterOperator.BEFORE_N_DAYS]: 'before N days',
        [WorkflowFilterOperator.AFTER_N_DAYS]: 'after N days',
        [WorkflowFilterOperator.YESTERDAY]: 'yesterday',
        [WorkflowFilterOperator.TODAY]: 'today',
        [WorkflowFilterOperator.TOMORROW]: 'tomorrow',
        [WorkflowFilterOperator.PREV_WEEK]: 'in last week',
        [WorkflowFilterOperator.CURR_WEEK]: 'in this week',
        [WorkflowFilterOperator.NEXT_WEEK]: 'in next week',
        [WorkflowFilterOperator.PREV_MTH]: 'in last month',
        [WorkflowFilterOperator.CURR_MTH]: 'in this month',
        [WorkflowFilterOperator.NEXT_MTH]: 'in next month',
        [WorkflowFilterOperator.PREV_QTR]: 'in last quarter',
        [WorkflowFilterOperator.CURR_QTR]: 'in current quarter',
        [WorkflowFilterOperator.NEXT_QTR]: 'in next quarter',
        [WorkflowFilterOperator.PREV_FY]: 'in last FY',
        [WorkflowFilterOperator.CURR_FY]: 'in current FY',
        [WorkflowFilterOperator.NEXT_FY]: 'in next FY',
      },
      [OperatorGroup.BOOLEAN]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
      },
      [OperatorGroup.MULTILIST]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
      },
      [OperatorGroup.REFERENCE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
      },
    },
  },
  // Salesforce realtime workflows
  [CRMProvider.SFDC_SANDBOX]: {
    [WorkflowTrigger.CREATE_UPDATE]: {
      [OperatorGroup.STRING]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.NUMBER]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is greater than',
        [WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [WorkflowFilterOperator.LT]: 'is less than',
        [WorkflowFilterOperator.LTE]: 'is less than or equals',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.INCREASED]: 'increased',
        [WorkflowFilterOperator.DECREASED]: 'decreased',
        [WorkflowFilterOperator.INCREASED_BY_VALUE]: 'increased by',
        [WorkflowFilterOperator.DECREASED_BY_VALUE]: 'decreased by',
        [WorkflowFilterOperator.INCREASED_BY_ATMOST_VALUE]: 'increased by at most',
        [WorkflowFilterOperator.DECREASED_BY_ATMOST_VALUE]: 'decreased by at most',
        [WorkflowFilterOperator.INCREASED_BY_ATLEAST_VALUE]: 'increased by at least',
        [WorkflowFilterOperator.DECREASED_BY_ATLEAST_VALUE]: 'decreased by at least',
        [WorkflowFilterOperator.INCREASED_BY_PERCENT]: 'increased by %',
        [WorkflowFilterOperator.DECREASED_BY_PERCENT]: 'decreased by %',
        [WorkflowFilterOperator.INCREASED_BY_ATMOST_PERCENT]: 'increased by at most %',
        [WorkflowFilterOperator.DECREASED_BY_ATMOST_PERCENT]: 'decreased by at most %',
        [WorkflowFilterOperator.INCREASED_BY_ATLEAST_PERCENT]: 'increased by at least %',
        [WorkflowFilterOperator.DECREASED_BY_ATLEAST_PERCENT]: 'decreased by at least %',
      },
      [OperatorGroup.LIST]: {
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.DATE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.GT]: 'is after',
        [WorkflowFilterOperator.LT]: 'is before',
        [WorkflowFilterOperator.PREV_N_DAYS]: 'in last N days',
        [WorkflowFilterOperator.NEXT_N_DAYS]: 'in next N days',
        [WorkflowFilterOperator.NOT_PREV_N_DAYS]: 'not in last N days',
        [WorkflowFilterOperator.NOT_NEXT_N_DAYS]: 'not in next N days',
        [WorkflowFilterOperator.BEFORE_N_DAYS]: 'before N days',
        [WorkflowFilterOperator.AFTER_N_DAYS]: 'after N days',
        [WorkflowFilterOperator.YESTERDAY]: 'yesterday',
        [WorkflowFilterOperator.TODAY]: 'today',
        [WorkflowFilterOperator.TOMORROW]: 'tomorrow',
        [WorkflowFilterOperator.PREV_WEEK]: 'in last week',
        [WorkflowFilterOperator.CURR_WEEK]: 'in this week',
        [WorkflowFilterOperator.NEXT_WEEK]: 'in next week',
        [WorkflowFilterOperator.PREV_MTH]: 'in last month',
        [WorkflowFilterOperator.CURR_MTH]: 'in this month',
        [WorkflowFilterOperator.NEXT_MTH]: 'in next month',
        [WorkflowFilterOperator.PREV_QTR]: 'in last quarter',
        [WorkflowFilterOperator.CURR_QTR]: 'in current quarter',
        [WorkflowFilterOperator.NEXT_QTR]: 'in next quarter',
        [WorkflowFilterOperator.PREV_FY]: 'in last FY',
        [WorkflowFilterOperator.CURR_FY]: 'in current FY',
        [WorkflowFilterOperator.NEXT_FY]: 'in next FY',
        [WorkflowFilterOperator.PUSHED_OUT]: 'pushed out',
        [WorkflowFilterOperator.PUSHED_BACK]: 'pushed back',
        [WorkflowFilterOperator.MOVED_FROM_PREV_WEEK]: 'moved from last week',
        [WorkflowFilterOperator.MOVED_FROM_CURR_WEEK]: 'moved from this week',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_WEEK]: 'moved from next week',
        [WorkflowFilterOperator.MOVED_FROM_PREV_MONTH]: 'moved from last month',
        [WorkflowFilterOperator.MOVED_FROM_CURR_MONTH]: 'moved from this month',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_MONTH]: 'moved from next month',
        [WorkflowFilterOperator.MOVED_FROM_PREV_QUARTER]: 'moved from last quarter',
        [WorkflowFilterOperator.MOVED_FROM_CURR_QUARTER]: 'moved from this quarter',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_QUARTER]: 'moved from next quarter',
        [WorkflowFilterOperator.MOVED_FROM_PREV_YEAR]: 'moved from last year',
        [WorkflowFilterOperator.MOVED_FROM_CURR_YEAR]: 'moved from this year',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_YEAR]: 'moved from next year',
        [WorkflowFilterOperator.MOVED_TO_PREV_WEEK]: 'moved to last week',
        [WorkflowFilterOperator.MOVED_TO_CURR_WEEK]: 'moved to this week',
        [WorkflowFilterOperator.MOVED_TO_NEXT_WEEK]: 'moved to next week',
        [WorkflowFilterOperator.MOVED_TO_PREV_MONTH]: 'moved to last month',
        [WorkflowFilterOperator.MOVED_TO_CURR_MONTH]: 'moved to this month',
        [WorkflowFilterOperator.MOVED_TO_NEXT_MONTH]: 'moved to next month',
        [WorkflowFilterOperator.MOVED_TO_PREV_QUARTER]: 'moved to last quarter',
        [WorkflowFilterOperator.MOVED_TO_CURR_QUARTER]: 'moved to this quarter',
        [WorkflowFilterOperator.MOVED_TO_NEXT_QUARTER]: 'moved to next quarter',
        [WorkflowFilterOperator.MOVED_TO_PREV_YEAR]: 'moved to last year',
        [WorkflowFilterOperator.MOVED_TO_CURR_YEAR]: 'moved to this year',
        [WorkflowFilterOperator.MOVED_TO_NEXT_YEAR]: 'moved to next year',
      },
      [OperatorGroup.BOOLEAN]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.MULTILIST]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.VALUE_ADDED]: 'has a value added',
        [WorkflowFilterOperator.VALUE_REMOVED]: 'has a value removed',
        [WorkflowFilterOperator.NUM_VALUES_EQUALS]: 'has N values',
        [WorkflowFilterOperator.NUM_VALUES_NOT_EQUALS]: 'does not have N values',
        [WorkflowFilterOperator.NUM_VALUES_AT_LEAST]: 'has at least N values',
        [WorkflowFilterOperator.NUM_VALUES_AT_MOST]: 'has at most N values',
      },
      [OperatorGroup.REFERENCE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      // TODO: Uncomment when SFDC RT supports this
      // [OperatorGroup.MEETING_TIMESTAMP]: {
      //   [WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 'begins in n minutes',
      //   [WorkflowFilterOperator.ENDED_N_MINS_AGO]: 'ended n minutes ago',
      // },
    },
    // Salesforce scheduled workflows
    [WorkflowTrigger.SCHEDULE]: {
      [OperatorGroup.STRING]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
      },
      [OperatorGroup.NUMBER]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is greater than',
        [WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [WorkflowFilterOperator.LT]: 'is less than',
        [WorkflowFilterOperator.LTE]: 'is less than or equals',
      },
      [OperatorGroup.LIST]: {
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
      },
      [OperatorGroup.DATE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is after',
        [WorkflowFilterOperator.LT]: 'is before',
        [WorkflowFilterOperator.PREV_N_DAYS]: 'in last N days',
        [WorkflowFilterOperator.NEXT_N_DAYS]: 'in next N days',
        [WorkflowFilterOperator.NOT_PREV_N_DAYS]: 'not in last N days',
        [WorkflowFilterOperator.NOT_NEXT_N_DAYS]: 'not in next N days',
        [WorkflowFilterOperator.BEFORE_N_DAYS]: 'before N days',
        [WorkflowFilterOperator.AFTER_N_DAYS]: 'after N days',
        [WorkflowFilterOperator.YESTERDAY]: 'yesterday',
        [WorkflowFilterOperator.TODAY]: 'today',
        [WorkflowFilterOperator.TOMORROW]: 'tomorrow',
        [WorkflowFilterOperator.PREV_WEEK]: 'in last week',
        [WorkflowFilterOperator.CURR_WEEK]: 'in this week',
        [WorkflowFilterOperator.NEXT_WEEK]: 'in next week',
        [WorkflowFilterOperator.PREV_MTH]: 'in last month',
        [WorkflowFilterOperator.CURR_MTH]: 'in this month',
        [WorkflowFilterOperator.NEXT_MTH]: 'in next month',
        [WorkflowFilterOperator.PREV_QTR]: 'in last quarter',
        [WorkflowFilterOperator.CURR_QTR]: 'in current quarter',
        [WorkflowFilterOperator.NEXT_QTR]: 'in next quarter',
        [WorkflowFilterOperator.PREV_FY]: 'in last FY',
        [WorkflowFilterOperator.CURR_FY]: 'in current FY',
        [WorkflowFilterOperator.NEXT_FY]: 'in next FY',
      },
      [OperatorGroup.BOOLEAN]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
      },
      [OperatorGroup.MULTILIST]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
      },
      [OperatorGroup.REFERENCE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
      },
    },
  },
  // HubSpot realtime workflows
  [CRMProvider.HUBSPOT]: {
    [WorkflowTrigger.CREATE_UPDATE]: {
      [OperatorGroup.STRING]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.NUMBER]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is greater than',
        [WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [WorkflowFilterOperator.LT]: 'is less than',
        [WorkflowFilterOperator.LTE]: 'is less than or equals',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.INCREASED]: 'increased',
        [WorkflowFilterOperator.DECREASED]: 'decreased',
        [WorkflowFilterOperator.INCREASED_BY_VALUE]: 'increased by',
        [WorkflowFilterOperator.DECREASED_BY_VALUE]: 'decreased by',
        [WorkflowFilterOperator.INCREASED_BY_ATMOST_VALUE]: 'increased by at most',
        [WorkflowFilterOperator.DECREASED_BY_ATMOST_VALUE]: 'decreased by at most',
        [WorkflowFilterOperator.INCREASED_BY_ATLEAST_VALUE]: 'increased by at least',
        [WorkflowFilterOperator.DECREASED_BY_ATLEAST_VALUE]: 'decreased by at least',
        [WorkflowFilterOperator.INCREASED_BY_PERCENT]: 'increased by %',
        [WorkflowFilterOperator.DECREASED_BY_PERCENT]: 'decreased by %',
        [WorkflowFilterOperator.INCREASED_BY_ATMOST_PERCENT]: 'increased by at most %',
        [WorkflowFilterOperator.DECREASED_BY_ATMOST_PERCENT]: 'decreased by at most %',
        [WorkflowFilterOperator.INCREASED_BY_ATLEAST_PERCENT]: 'increased by at least %',
        [WorkflowFilterOperator.DECREASED_BY_ATLEAST_PERCENT]: 'decreased by at least %',
      },
      [OperatorGroup.LIST]: {
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.DATE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.GT]: 'is after',
        [WorkflowFilterOperator.LT]: 'is before',
        [WorkflowFilterOperator.PREV_N_DAYS]: 'in last N days',
        [WorkflowFilterOperator.NEXT_N_DAYS]: 'in next N days',
        [WorkflowFilterOperator.NOT_PREV_N_DAYS]: 'not in last N days',
        [WorkflowFilterOperator.NOT_NEXT_N_DAYS]: 'not in next N days',
        [WorkflowFilterOperator.BEFORE_N_DAYS]: 'before N days',
        [WorkflowFilterOperator.AFTER_N_DAYS]: 'after N days',
        [WorkflowFilterOperator.YESTERDAY]: 'yesterday',
        [WorkflowFilterOperator.TODAY]: 'today',
        [WorkflowFilterOperator.TOMORROW]: 'tomorrow',
        [WorkflowFilterOperator.PREV_WEEK]: 'in last week',
        [WorkflowFilterOperator.CURR_WEEK]: 'in this week',
        [WorkflowFilterOperator.NEXT_WEEK]: 'in next week',
        [WorkflowFilterOperator.PREV_MTH]: 'in last month',
        [WorkflowFilterOperator.CURR_MTH]: 'in this month',
        [WorkflowFilterOperator.NEXT_MTH]: 'in next month',
        [WorkflowFilterOperator.PREV_QTR]: 'in last quarter',
        [WorkflowFilterOperator.CURR_QTR]: 'in current quarter',
        [WorkflowFilterOperator.NEXT_QTR]: 'in next quarter',
        [WorkflowFilterOperator.PREV_YEAR]: 'in last year',
        [WorkflowFilterOperator.CURR_YEAR]: 'in current year',
        [WorkflowFilterOperator.NEXT_YEAR]: 'in next year',
        [WorkflowFilterOperator.PUSHED_OUT]: 'pushed out',
        [WorkflowFilterOperator.PUSHED_BACK]: 'pushed back',
        [WorkflowFilterOperator.MOVED_FROM_PREV_WEEK]: 'moved from last week',
        [WorkflowFilterOperator.MOVED_FROM_CURR_WEEK]: 'moved from this week',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_WEEK]: 'moved from next week',
        [WorkflowFilterOperator.MOVED_FROM_PREV_MONTH]: 'moved from last month',
        [WorkflowFilterOperator.MOVED_FROM_CURR_MONTH]: 'moved from this month',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_MONTH]: 'moved from next month',
        [WorkflowFilterOperator.MOVED_FROM_PREV_QUARTER]: 'moved from last quarter',
        [WorkflowFilterOperator.MOVED_FROM_CURR_QUARTER]: 'moved from this quarter',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_QUARTER]: 'moved from next quarter',
        [WorkflowFilterOperator.MOVED_FROM_PREV_YEAR]: 'moved from last year',
        [WorkflowFilterOperator.MOVED_FROM_CURR_YEAR]: 'moved from this year',
        [WorkflowFilterOperator.MOVED_FROM_NEXT_YEAR]: 'moved from next year',
        [WorkflowFilterOperator.MOVED_TO_PREV_WEEK]: 'moved to last week',
        [WorkflowFilterOperator.MOVED_TO_CURR_WEEK]: 'moved to this week',
        [WorkflowFilterOperator.MOVED_TO_NEXT_WEEK]: 'moved to next week',
        [WorkflowFilterOperator.MOVED_TO_PREV_MONTH]: 'moved to last month',
        [WorkflowFilterOperator.MOVED_TO_CURR_MONTH]: 'moved to this month',
        [WorkflowFilterOperator.MOVED_TO_NEXT_MONTH]: 'moved to next month',
        [WorkflowFilterOperator.MOVED_TO_PREV_QUARTER]: 'moved to last quarter',
        [WorkflowFilterOperator.MOVED_TO_CURR_QUARTER]: 'moved to this quarter',
        [WorkflowFilterOperator.MOVED_TO_NEXT_QUARTER]: 'moved to next quarter',
        [WorkflowFilterOperator.MOVED_TO_PREV_YEAR]: 'moved to last year',
        [WorkflowFilterOperator.MOVED_TO_CURR_YEAR]: 'moved to this year',
        [WorkflowFilterOperator.MOVED_TO_NEXT_YEAR]: 'moved to next year',
      },
      [OperatorGroup.BOOLEAN]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.MULTILIST]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.DOES_NOT_CONTAIN]: 'does not contain',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
        [WorkflowFilterOperator.VALUE_ADDED]: 'has a value added',
        [WorkflowFilterOperator.VALUE_REMOVED]: 'has a value removed',
        [WorkflowFilterOperator.NUM_VALUES_EQUALS]: 'has N values',
        [WorkflowFilterOperator.NUM_VALUES_NOT_EQUALS]: 'does not have N values',
        [WorkflowFilterOperator.NUM_VALUES_AT_LEAST]: 'has at least N values',
        [WorkflowFilterOperator.NUM_VALUES_AT_MOST]: 'has at most N values',
      },
      [OperatorGroup.REFERENCE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED]: 'changed',
        [WorkflowFilterOperator.CHANGED_TO]: 'changed to',
        [WorkflowFilterOperator.CHANGED_FROM]: 'changed from',
        [WorkflowFilterOperator.NOT_CHANGED]: 'not changed',
      },
      [OperatorGroup.MULTIREFERENCE]: {
        [WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
      },
      [OperatorGroup.ASSOCIATED_EMAIL]: {
        [WorkflowFilterOperator.EMAIL_SENT]: 'sent',
        [WorkflowFilterOperator.EMAIL_RECEIVED]: 'received',
        [WorkflowFilterOperator.EMAIL_FORWARDED]: 'forwarded',
      },
      [OperatorGroup.MEETING_TIMESTAMP]: {
        [WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 'is in next n minutes',
        [WorkflowFilterOperator.ENDED_N_MINS_AGO]: 'is in last n minutes',
      },
      [OperatorGroup.LURU_NOTE]: {
        [WorkflowFilterOperator.PRESENT]: 'present',
        [WorkflowFilterOperator.NOT_PRESENT]: 'not present',
      },
    },
    // HubSpot scheduleded workflows
    [WorkflowTrigger.SCHEDULE]: {
      [OperatorGroup.STRING]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.STARTS_WITH]: 'has word starting with',
        [WorkflowFilterOperator.CONTAINS]: 'contains',
        [WorkflowFilterOperator.CHANGED_N_DAYS]: 'changed in last N days',
        [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 'unchanged for N days',
      },
      [OperatorGroup.NUMBER]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is greater than',
        [WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [WorkflowFilterOperator.LT]: 'is less than',
        [WorkflowFilterOperator.LTE]: 'is less than or equals',
        [WorkflowFilterOperator.CHANGED_N_DAYS]: 'changed in last N days',
        [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 'unchanged for N days',
      },
      [OperatorGroup.LIST]: {
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED_N_DAYS]: 'changed in last N days',
        [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 'unchanged for N days',
      },
      [OperatorGroup.DATE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.GT]: 'is after',
        [WorkflowFilterOperator.LT]: 'is before',
        [WorkflowFilterOperator.PREV_N_DAYS]: 'in last N days',
        [WorkflowFilterOperator.NEXT_N_DAYS]: 'in next N days',
        [WorkflowFilterOperator.BEFORE_N_DAYS]: 'before N days',
        [WorkflowFilterOperator.AFTER_N_DAYS]: 'after N days',
        [WorkflowFilterOperator.YESTERDAY]: 'yesterday',
        [WorkflowFilterOperator.TODAY]: 'today',
        [WorkflowFilterOperator.TOMORROW]: 'tomorrow',
        [WorkflowFilterOperator.PREV_WEEK]: 'in last week',
        [WorkflowFilterOperator.CURR_WEEK]: 'in this week',
        [WorkflowFilterOperator.NEXT_WEEK]: 'in next week',
        [WorkflowFilterOperator.PREV_MTH]: 'in last month',
        [WorkflowFilterOperator.CURR_MTH]: 'in this month',
        [WorkflowFilterOperator.NEXT_MTH]: 'in next month',
        [WorkflowFilterOperator.PREV_QTR]: 'in last quarter',
        [WorkflowFilterOperator.CURR_QTR]: 'in current quarter',
        [WorkflowFilterOperator.NEXT_QTR]: 'in next quarter',
        [WorkflowFilterOperator.PREV_YEAR]: 'in last year',
        [WorkflowFilterOperator.CURR_YEAR]: 'in current year',
        [WorkflowFilterOperator.NEXT_YEAR]: 'in next year',
        [WorkflowFilterOperator.CHANGED_N_DAYS]: 'changed in last N days',
        [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 'unchanged for N days',
      },
      [OperatorGroup.BOOLEAN]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED_N_DAYS]: 'changed in last N days',
        [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 'unchanged for N days',
      },
      [OperatorGroup.MULTILIST]: {
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.CHANGED_N_DAYS]: 'changed in last N days',
        [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 'unchanged for N days',
      },
      [OperatorGroup.REFERENCE]: {
        [WorkflowFilterOperator.EQ]: 'is',
        [WorkflowFilterOperator.NEQ]: 'is not',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
        [WorkflowFilterOperator.IN]: 'is in',
        [WorkflowFilterOperator.NOT_IN]: 'is not in',
        [WorkflowFilterOperator.CHANGED_N_DAYS]: 'changed in last N days',
        [WorkflowFilterOperator.UNCHANGED_N_DAYS]: 'unchanged for N days',
      },
      [OperatorGroup.MULTIREFERENCE]: {
        [WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [WorkflowFilterOperator.BLANK]: 'is empty',
        [WorkflowFilterOperator.NOT_BLANK]: 'is not empty',
      },
      [OperatorGroup.ASSOCIATED_EMAIL]: {
        [WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS]: 'sent in last N days',
        [WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS]: 'received in last N days',
        [WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS]: 'forwarded in last N days',
        [WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS]: 'not sent in last N days',
        [WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS]: 'not received in last N days',
        [WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS]: 'not forwarded in last N days',
      },
      [OperatorGroup.ASSOCIATED_CALL]: {
        [WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS]: 'happened in last N days',
        [WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS]: 'not happened in last N days',
      },
    },
  },
}

const V2WorkflowFilterOperators: Record<string, Record<string, Record<string, Record<string, string>>>> = {
  // HubSpot v2 workflows
  [CRMProvider.HUBSPOT]: {
    // HubSpot realtime workflows
    [WorkflowTriggerV2.RECORD_CU]: {
      [OperatorGroup.STRING]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [V2WorkflowFilterOperator.CONTAINS]: 'contains',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
      },
      [OperatorGroup.NUMBER]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.GT]: 'is greater than',
        [V2WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [V2WorkflowFilterOperator.LT]: 'is less than',
        [V2WorkflowFilterOperator.LTE]: 'is less than or equals',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
        [V2WorkflowFilterOperator.INCREASED_BY_AT_LEAST]: 'has increased by at least',
        [V2WorkflowFilterOperator.DECREASED_BY_AT_LEAST]: 'has decreased by at least',
        [V2WorkflowFilterOperator.INCREASED_BY_AT_MOST]: 'has increased by at most',
        [V2WorkflowFilterOperator.DECREASED_BY_AT_MOST]: 'has decreased by at most',
      },
      [OperatorGroup.LIST]: {
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.IN]: 'is one of',
        [V2WorkflowFilterOperator.NOT_IN]: 'is not one of',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
      },
      [OperatorGroup.DATE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        // [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        // [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
        [V2WorkflowFilterOperator.BEFORE]: 'is before',
        // [V2WorkflowFilterOperator.ON_OR_BEFORE]: 'is on or before',
        [V2WorkflowFilterOperator.AFTER]: 'is after',
        // [V2WorkflowFilterOperator.ON_OR_AFTER]: 'is on or after',
        [V2WorkflowFilterOperator.WITHIN]: 'is within',
        [V2WorkflowFilterOperator.NOT_WITHIN]: 'is not within',
        // [V2WorkflowFilterOperator.THIS_QUARTER]: 'is this quarter',
        // [V2WorkflowFilterOperator.NEXT_QUARTER]: 'is next quarter',
        // [V2WorkflowFilterOperator.LAST_QUARTER]: 'is last quarter',
        // [V2WorkflowFilterOperator.THIS_YEAR]: 'is this year',
        // [V2WorkflowFilterOperator.NEXT_YEAR]: 'is next year',
        // [V2WorkflowFilterOperator.LAST_YEAR]: 'is last year',
        // [V2WorkflowFilterOperator.THIS_WEEK]: 'is this week',
        // [V2WorkflowFilterOperator.NEXT_WEEK]: 'is next week',
        // [V2WorkflowFilterOperator.LAST_WEEK]: 'is last week',
      },
      [OperatorGroup.BOOLEAN]: {
        [V2WorkflowFilterOperator.TRUE]: 'is true',
        [V2WorkflowFilterOperator.FALSE]: 'is false',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
      },
      [OperatorGroup.MULTILIST]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty ',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty ',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed ',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to ',
      },
      [OperatorGroup.REFERENCE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.IN]: 'is one of',
        [V2WorkflowFilterOperator.NOT_IN]: 'is not one of',
      },
      [OperatorGroup.MULTIREFERENCE]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
      },
      [OperatorGroup.ASSOCIATED_EMAIL]: {
        [V2WorkflowFilterOperator.EMAIL_SENT]: 'sent',
        [V2WorkflowFilterOperator.EMAIL_RECEIVED]: 'received',
        [V2WorkflowFilterOperator.EMAIL_FORWARDED]: 'forwarded',
      },
      [OperatorGroup.MEETING_TIMESTAMP]: {
        [V2WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 'is in next n minutes',
        [V2WorkflowFilterOperator.ENDED_N_MINS_AGO]: 'is in last n minutes',
      },
      [OperatorGroup.LURU_NOTE]: {
        [V2WorkflowFilterOperator.PRESENT]: 'present',
        [V2WorkflowFilterOperator.NOT_PRESENT]: 'not present',
      },
    },
    // HubSpot scheduleded workflows
    [WorkflowTriggerV2.CRON]: {
      [OperatorGroup.STRING]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [V2WorkflowFilterOperator.CONTAINS]: 'contains',
      },
      [OperatorGroup.NUMBER]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.GT]: 'is greater than',
        [V2WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [V2WorkflowFilterOperator.LT]: 'is less than',
        [V2WorkflowFilterOperator.LTE]: 'is less than or equals',
      },
      [OperatorGroup.LIST]: {
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.IN]: 'is one of',
        [V2WorkflowFilterOperator.NOT_IN]: 'is not one of',
      },
      [OperatorGroup.DATE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        // [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        // [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        // [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        // [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
        [V2WorkflowFilterOperator.BEFORE]: 'is before',
        // [V2WorkflowFilterOperator.ON_OR_BEFORE]: 'is on or before',
        [V2WorkflowFilterOperator.AFTER]: 'is after',
        // [V2WorkflowFilterOperator.ON_OR_AFTER]: 'is on or after',
        [V2WorkflowFilterOperator.WITHIN]: 'is within',
        [V2WorkflowFilterOperator.NOT_WITHIN]: 'is not within',
        // [V2WorkflowFilterOperator.THIS_QUARTER]: 'is this quarter',
        // [V2WorkflowFilterOperator.NEXT_QUARTER]: 'is next quarter',
        // [V2WorkflowFilterOperator.LAST_QUARTER]: 'is last quarter',
        // [V2WorkflowFilterOperator.THIS_YEAR]: 'is this year',
        // [V2WorkflowFilterOperator.NEXT_YEAR]: 'is next year',
        // [V2WorkflowFilterOperator.LAST_YEAR]: 'is last year',
        // [V2WorkflowFilterOperator.THIS_WEEK]: 'is this week',
        // [V2WorkflowFilterOperator.NEXT_WEEK]: 'is next week',
        // [V2WorkflowFilterOperator.LAST_WEEK]: 'is last week',
      },
      [OperatorGroup.BOOLEAN]: {
        [V2WorkflowFilterOperator.TRUE]: 'is true',
        [V2WorkflowFilterOperator.FALSE]: 'is false',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
      },
      [OperatorGroup.MULTILIST]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty ',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty ',
      },
      [OperatorGroup.REFERENCE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
      },
      [OperatorGroup.MULTIREFERENCE]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
      },
      [OperatorGroup.ASSOCIATED_EMAIL]: {
        [V2WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS]: 'sent in last N days',
        [V2WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS]: 'received in last N days',
        [V2WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS]: 'forwarded in last N days',
        [V2WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS]: 'not sent in last N days',
        [V2WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS]: 'not received in last N days',
        [V2WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS]: 'not forwarded in last N days',
      },
      [OperatorGroup.ASSOCIATED_CALL]: {
        [V2WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS]: 'happened in last N days',
        [V2WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS]: 'not happened in last N days',
      },
      [OperatorGroup.MEETING_TIMESTAMP]: {
        [V2WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 'is in next n minutes',
        [V2WorkflowFilterOperator.ENDED_N_MINS_AGO]: 'is in last n minutes',
      },
      [OperatorGroup.LURU_NOTE]: {
        [V2WorkflowFilterOperator.PRESENT]: 'present',
        [V2WorkflowFilterOperator.NOT_PRESENT]: 'not present',
      },
    },
  },
  // SFDC workflows
  [CRMProvider.SFDC]: {
    // SFDC realtime workflows
    [WorkflowTriggerV2.RECORD_CU]: {
      [OperatorGroup.STRING]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [V2WorkflowFilterOperator.CONTAINS]: 'contains',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
      },
      [OperatorGroup.NUMBER]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.GT]: 'is greater than',
        [V2WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [V2WorkflowFilterOperator.LT]: 'is less than',
        [V2WorkflowFilterOperator.LTE]: 'is less than or equals',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
        [V2WorkflowFilterOperator.INCREASED_BY_AT_LEAST]: 'has increased by at least',
        [V2WorkflowFilterOperator.DECREASED_BY_AT_LEAST]: 'has decreased by at least',
        [V2WorkflowFilterOperator.INCREASED_BY_AT_MOST]: 'has increased by at most',
        [V2WorkflowFilterOperator.DECREASED_BY_AT_MOST]: 'has decreased by at most',
      },
      [OperatorGroup.LIST]: {
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.IN]: 'is one of',
        [V2WorkflowFilterOperator.NOT_IN]: 'is not one of',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
      },
      [OperatorGroup.DATE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
        [V2WorkflowFilterOperator.BEFORE]: 'is before',
        // [V2WorkflowFilterOperator.ON_OR_BEFORE]: 'is on or before',
        [V2WorkflowFilterOperator.AFTER]: 'is after',
        // [V2WorkflowFilterOperator.ON_OR_AFTER]: 'is on or after',
        [V2WorkflowFilterOperator.WITHIN]: 'is within',
        [V2WorkflowFilterOperator.NOT_WITHIN]: 'is not within',
        // [V2WorkflowFilterOperator.THIS_QUARTER]: 'is this quarter',
        // [V2WorkflowFilterOperator.NEXT_QUARTER]: 'is next quarter',
        // [V2WorkflowFilterOperator.LAST_QUARTER]: 'is last quarter',
        // [V2WorkflowFilterOperator.THIS_YEAR]: 'is this year',
        // [V2WorkflowFilterOperator.NEXT_YEAR]: 'is next year',
        // [V2WorkflowFilterOperator.LAST_YEAR]: 'is last year',
        // [V2WorkflowFilterOperator.THIS_WEEK]: 'is this week',
        // [V2WorkflowFilterOperator.NEXT_WEEK]: 'is next week',
        // [V2WorkflowFilterOperator.LAST_WEEK]: 'is last week',
      },
      [OperatorGroup.BOOLEAN]: {
        [V2WorkflowFilterOperator.TRUE]: 'is true',
        [V2WorkflowFilterOperator.FALSE]: 'is false',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
      },
      [OperatorGroup.MULTILIST]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty ',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty ',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed ',
        [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to ',
      },
      [OperatorGroup.REFERENCE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.IN]: 'is one of',
        [V2WorkflowFilterOperator.NOT_IN]: 'is not one of',
        [V2WorkflowFilterOperator.CHANGED]: 'has changed ',
      },
      [OperatorGroup.MULTIREFERENCE]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
      },
      [OperatorGroup.ASSOCIATED_EMAIL]: {
        [V2WorkflowFilterOperator.EMAIL_SENT]: 'sent',
        [V2WorkflowFilterOperator.EMAIL_RECEIVED]: 'received',
        [V2WorkflowFilterOperator.EMAIL_FORWARDED]: 'forwarded',
      },
      [OperatorGroup.MEETING_TIMESTAMP]: {
        [V2WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 'is in next n minutes',
        [V2WorkflowFilterOperator.ENDED_N_MINS_AGO]: 'is in last n minutes',
      },
      [OperatorGroup.LURU_NOTE]: {
        [V2WorkflowFilterOperator.PRESENT]: 'present',
        [V2WorkflowFilterOperator.NOT_PRESENT]: 'not present',
      },
    },
    // SFDC scheduled workflows
    [WorkflowTriggerV2.CRON]: {
      [OperatorGroup.STRING]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.STARTS_WITH]: 'starts with',
        [V2WorkflowFilterOperator.CONTAINS]: 'contains',
      },
      [OperatorGroup.NUMBER]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.GT]: 'is greater than',
        [V2WorkflowFilterOperator.GTE]: 'is greater than or equals',
        [V2WorkflowFilterOperator.LT]: 'is less than',
        [V2WorkflowFilterOperator.LTE]: 'is less than or equals',
      },
      [OperatorGroup.LIST]: {
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.IN]: 'is one of',
        [V2WorkflowFilterOperator.NOT_IN]: 'is not one of',
      },
      [OperatorGroup.DATE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.NEQ]: 'is not',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        // [V2WorkflowFilterOperator.CHANGED]: 'has changed',
        // [V2WorkflowFilterOperator.CHANGED_TO]: 'has changed to',
        [V2WorkflowFilterOperator.BEFORE]: 'is before',
        // [V2WorkflowFilterOperator.ON_OR_BEFORE]: 'is on or before',
        [V2WorkflowFilterOperator.AFTER]: 'is after',
        // [V2WorkflowFilterOperator.ON_OR_AFTER]: 'is on or after',
        [V2WorkflowFilterOperator.WITHIN]: 'is within',
        [V2WorkflowFilterOperator.NOT_WITHIN]: 'is not within',
        // [V2WorkflowFilterOperator.THIS_QUARTER]: 'is this quarter',
        // [V2WorkflowFilterOperator.NEXT_QUARTER]: 'is next quarter',
        // [V2WorkflowFilterOperator.LAST_QUARTER]: 'is last quarter',
        // [V2WorkflowFilterOperator.THIS_YEAR]: 'is this year',
        // [V2WorkflowFilterOperator.NEXT_YEAR]: 'is next year',
        // [V2WorkflowFilterOperator.LAST_YEAR]: 'is last year',
        // [V2WorkflowFilterOperator.THIS_WEEK]: 'is this week',
        // [V2WorkflowFilterOperator.NEXT_WEEK]: 'is next week',
        // [V2WorkflowFilterOperator.LAST_WEEK]: 'is last week',
      },
      [OperatorGroup.BOOLEAN]: {
        [V2WorkflowFilterOperator.TRUE]: 'is true',
        [V2WorkflowFilterOperator.FALSE]: 'is false',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
      },
      [OperatorGroup.MULTILIST]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty ',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty ',
      },
      [OperatorGroup.REFERENCE]: {
        [V2WorkflowFilterOperator.EQ]: 'is',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
        [V2WorkflowFilterOperator.IN]: 'is one of',
        [V2WorkflowFilterOperator.NOT_IN]: 'is not one of',
      },
      [OperatorGroup.MULTIREFERENCE]: {
        [V2WorkflowFilterOperator.CONTAINS_ALL]: 'contains all of',
        [V2WorkflowFilterOperator.CONTAINS_ANY]: 'contains any of',
        [V2WorkflowFilterOperator.CONTAINS_NONE]: 'contains none of',
        [V2WorkflowFilterOperator.EMPTY]: 'is empty',
        [V2WorkflowFilterOperator.NOT_EMPTY]: 'is not empty',
      },
      [OperatorGroup.ASSOCIATED_EMAIL]: {
        [V2WorkflowFilterOperator.EMAIL_SENT_LAST_N_DAYS]: 'sent in last N days',
        [V2WorkflowFilterOperator.EMAIL_RECEIVED_LAST_N_DAYS]: 'received in last N days',
        [V2WorkflowFilterOperator.EMAIL_FORWARDED_LAST_N_DAYS]: 'forwarded in last N days',
        [V2WorkflowFilterOperator.NO_EMAIL_SENT_LAST_N_DAYS]: 'not sent in last N days',
        [V2WorkflowFilterOperator.NO_EMAIL_RECEIVED_LAST_N_DAYS]: 'not received in last N days',
        [V2WorkflowFilterOperator.NO_EMAIL_FORWARDED_LAST_N_DAYS]: 'not forwarded in last N days',
      },
      [OperatorGroup.ASSOCIATED_CALL]: {
        [V2WorkflowFilterOperator.HAPPENED_IN_LAST_N_DAYS]: 'happened in last N days',
        [V2WorkflowFilterOperator.NOT_HAPPENED_IN_LAST_N_DAYS]: 'not happened in last N days',
      },
      [OperatorGroup.MEETING_TIMESTAMP]: {
        [V2WorkflowFilterOperator.BEGINS_N_MINS_LATER]: 'is in next n minutes',
        [V2WorkflowFilterOperator.ENDED_N_MINS_AGO]: 'is in last n minutes',
      },
      [OperatorGroup.LURU_NOTE]: {
        [V2WorkflowFilterOperator.PRESENT]: 'present',
        [V2WorkflowFilterOperator.NOT_PRESENT]: 'not present',
      },
    },
  },
}

const LuruFieldTypeOperatorGroupMap: Record<LuruFieldType, OperatorGroup> = {
  [LuruFieldType.ADDRESS]: OperatorGroup.STRING,
  [LuruFieldType.BOOLEAN]: OperatorGroup.BOOLEAN,
  [LuruFieldType.CURRENCY]: OperatorGroup.NUMBER,
  [LuruFieldType.DATE]: OperatorGroup.DATE,
  [LuruFieldType.DATETIME]: OperatorGroup.DATE,
  [LuruFieldType.DOUBLE]: OperatorGroup.NUMBER,
  [LuruFieldType.EMAIL]: OperatorGroup.STRING,
  [LuruFieldType.ENUM]: OperatorGroup.LIST,
  // @ts-ignore
  [LuruFieldType.ENUM_RADIO]: OperatorGroup.LIST,
  // @ts-ignore
  [LuruFieldType.ENUM_SELECT]: OperatorGroup.LIST,
  [LuruFieldType.INTEGER]: OperatorGroup.NUMBER,
  [LuruFieldType.INTEGER_NOFORMAT]: OperatorGroup.NUMBER,
  [LuruFieldType.LARGETEXT]: OperatorGroup.STRING,
  [LuruFieldType.MULTIENUM]: OperatorGroup.MULTILIST,
  [LuruFieldType.PERCENTAGE]: OperatorGroup.NUMBER,
  [LuruFieldType.REFERENCE]: OperatorGroup.REFERENCE,
  [LuruFieldType.TELEPHONE]: OperatorGroup.STRING,
  [LuruFieldType.TEXT]: OperatorGroup.STRING,
  [LuruFieldType.URL]: OperatorGroup.STRING,
  [LuruFieldType.ASSOCIATED_EMAIL]: OperatorGroup.ASSOCIATED_EMAIL,
  [LuruFieldType.ASSOCIATED_CALL]: OperatorGroup.ASSOCIATED_CALL,
  [LuruFieldType.MULTIREFERENCE]: OperatorGroup.MULTIREFERENCE,
  [LuruFieldType.REFERENCE_FIELD]: OperatorGroup.REFERENCE,
  [LuruFieldType.HIERARCHICAL_ENUM]: OperatorGroup.LIST,
  [LuruFieldType.MEETING_TIMESTAMP]: OperatorGroup.MEETING_TIMESTAMP,
  [LuruFieldType.LURU_MEETING_NOTE]: OperatorGroup.LURU_NOTE,
}

export enum WorkflowFilterBlockType {
  AND = 'and',
  OR = 'or',
  NOT = 'not',
  ANY = 'any',
  ALL = 'all',
  NONE = 'none',
  FIRST = 'first',
  LAST = 'last',
  CONDITION = 'condition',
}

export interface WorkflowFilterExpressionV2 {
  id?: string
  block_type?: WorkflowFilterBlockType
  parent_field?: string
  object_name?: string
  field?: string
  op?: string
  originalOp?: string
  nextOriginalOp?: string
  value?: any
  originalValue?: any
  fieldType?: LuruFieldType
  operands?: Array<WorkflowFilterExpressionV2>
}

export interface WorkflowTriggerObj {
  id: string
  name?: string
  description?: string
  sor?: WorkflowSorName | CRMProvider
  object_name?: CrmObjectName
  trigger_type?: WorkflowTriggerV2
  action_type?: string
  data: {
    args?: {
      in_minutes?: number
      tz?: string
      cron_expr?: string
      sor_object_name?: string
      filter?: {
        version: string
        data?: WorkflowFilterExpressionV2
      }
    }
  }
}

export interface ActionButtonV2 {
  sor_record?: {
    type: 'ref'
    value: {
      object_name: CrmObjectName
      applet_id: string
      field: string
    }
  }
  type?: ActionButtonType
  fields: Array<string>
  mandatory_fields?: Array<string>
  config?: Record<string, any>
  label?: string
}

export interface V2FilterDataSource {
  type?: 'ref'
  value?: {
    object_name?: CrmObjectName | 'global' | 'Global'
    applet_id?: string
  }
}

export enum FetchFromLastRun {
  CREATED_SINCE_LAST_RUN = 'created_since_last_run',
  CHANGED_SINCE_LAST_RUN = 'changed_since_last_run',
}

export interface WorkflowActionObj {
  id: string
  name?: string
  description?: string
  sor?: WorkflowSorName | CRMProvider | 'global' | 'Global'
  object_name?: CrmObjectName | 'global' | 'Global'
  action_type?: WorkflowAction
  trigger_type?: WorkflowTriggerV2
  data?: {
    version?: string
    args?: {
      type?: AlertDestination
      users?: Array<string> | null
      conversations?: Array<string>
      msg_type?: 'RECORD' | 'REPORT'
      record_data?: {
        msg_template: string
        fields: Record<string, Record<CrmObjectName | 'global' | 'Global', Array<string>>>
        action_buttons: Array<ActionButtonV2> | null
        media_category: MediaCategory | null
      }
      report_data?: {
        report_desc: string
        grouped: boolean
        grouped_field: string | null
        data_source?: {
          object_name: CrmObjectName | 'global' | 'Global'
          applet_id: string
        }
        fields: Array<string>
      }
      sor_object_name?: string

      // Search record/filter specific
      filter?: {
        version: string
        data?: WorkflowFilterExpressionV2
      }

      //Delay specific
      delay_in_minutes?: number

      //Filter specific
      source?: V2FilterDataSource

      [FetchFromLastRun.CREATED_SINCE_LAST_RUN]?: boolean
      [FetchFromLastRun.CHANGED_SINCE_LAST_RUN]?: boolean
    }
    next?:
      | string
      | {
          on_success: string
          on_failure?: string
        }
  }
}

export enum AlertTargetType {
  CONVERSATION = 'conversation',
  DM = 'group-dm',
  EMAIL = 'email',
}

export enum WorkflowResultListFieldSpecifierOperatorEnum {
  EACH = 'each',
  FIRST = 'first',
  LAST = 'last',
}

export interface AlertTarget {
  type: AlertTargetType
  conversation_id?: string | AlertField
  user_id?: string
  email_id?: AlertField
}

export interface AlertMessageTemplatePlaceholder extends AlertField {}

export interface AlertMessageTemplate {
  template: string
  placeholders?: Record<string, AlertMessageTemplatePlaceholder>
}

export interface AlertField {
  type: string
  value: {
    object_name: string
    applet_id: string
    field: string
    parent_field?: string | null
    specifier?: string | null
  }
  default_value?: {
    type?: string
    value?: any
  }
}

export interface ActionButtonStandardV2 {
  type: ActionButtonType | undefined
  data: {
    fields?: Array<AlertField>
    mandatory_fields?: Array<AlertField>
    task_record?: {
      type: string
      value: {
        object_name: string
        applet_id: string
      }
    } | null
    // config?: Record<string, any>

    //Create record specific
    object_name?: string
    record_type?: string
    label?: string
    url?: {
      type: string
      value: string | AlertField['value']
    }
  }
}

export interface WorkflowSendMessageActionObj {
  id: string
  name?: string
  description?: string
  sor?: WorkflowSorName | CRMProvider | 'global'
  object_name?: CrmObjectName | 'global'
  action_type?: WorkflowAction
  trigger_type?: WorkflowTriggerV2
  data?: {
    version?: string
    args?: {
      targets: Array<AlertTarget>
      body: {
        msg: AlertMessageTemplate
        fields: Array<AlertField>
        action_buttons: Array<ActionButtonStandardV2>
        media_category: MediaCategory | null
      }
      metadata: {
        batching?: {
          group_field?: AlertField | null
          attach_pdf?: boolean
        } | null
      }
    }
    next?:
      | string
      | {
          on_success: string
          on_failure?: string
        }
  }
}

export interface CreateV2WorkflowParameter {
  workflow_id?: string
  name: string
  description?: string
  version?: string
  trigger_sor?: WorkflowSorName | CRMProvider
  triggers: Record<string, WorkflowTriggerObj>
  actions: Record<string, WorkflowActionObj>
}

export enum WorkflowBlockType {
  TRIGGER = 'Trigger',
  FILTER = 'Filter',
  ACTION = 'Action',
}

export interface WorkflowEditorBlock {
  id: string
  type: WorkflowBlockType
  // validation?: {
  //   blockErrorMessage?: string
  //   valid?: boolean
  //   errors?: Record<string, any>
  // }
  details: WorkflowTriggerObj | WorkflowActionObj | WorkflowSendMessageActionObj
}

export interface WorkflowV2EditorDraft {
  workflow_id?: string
  name: string
  trigger_sor: WorkflowSorName | CRMProvider
  blocks: Array<WorkflowEditorBlock>
  editorMode?: WorkflowEditorMode
}

export enum WorkflowSorName {
  HUBSPOT = 'HUBSPOT',
  PIPEDRIVE = 'PIPEDRIVE',
  SFDC = 'SFDC',
  LURU = 'LURU',
  SLACK = 'SLACK',
  MSTEAMS = 'MSTEAMS',
  GCHAT = 'GCHAT',
}

export interface WorkflowSorSchemaTrigger {
  id: string
  name: string
  description: string
  input: {
    filter: {}
    sor_object_name: {}
  } & Record<string, CrmFieldSchema>
  args: Record<string, CrmFieldSchema>
  output: Record<string, CrmFieldSchema>
}

export interface WorkflowSorSchemaAction {
  id: string
  name: string
  description: string
  input: {
    filter: {}
    sor_object_name: {}
  } & Record<string, CrmFieldSchema>
  args: Record<string, CrmFieldSchema>
  output: Record<string, CrmFieldSchema>
}

export interface WorkflowSorSchemaObj {
  actions: Record<string, WorkflowSorSchemaAction>
  triggers: Record<string, WorkflowSorSchemaTrigger>
}
export interface WorkflowNormalizedSchema {
  id: string
  type: WorkflowBlockType
  workflowSor: WorkflowSorName
  sorObjectName: CrmObjectName | 'global' | 'Global'
  schema?: WorkflowSorSchemaTrigger | WorkflowSorSchemaAction
}

export interface WorkflowSorSchema extends Record<CrmObjectName | 'global' | 'Global', WorkflowSorSchemaObj> {}

export enum WorkflowAction {
  START = 'start',
  // Company record specific
  CREATE_COMPANY = 'create-company',
  UPDATE_COMPANY = 'update-company',
  SEARCH_COMPANY = 'search-company',
  FILTER_COMPANY = 'filter-company',

  // Contact record specific
  CREATE_CONTACT = 'create-contact',
  UPDATE_CONTACT = 'update-contact',
  SEARCH_CONTACT = 'search-contact',
  FILTER_CONTACT = 'filter-contact',

  // Deal record specific
  // CREATE_DEAL = 'create-deal',
  // UPDATE_DEAL = 'update-deal'
  SEARCH_DEAL = 'search-deal',
  FILTER_DEAL = 'filter-deal',

  SEND_MESSAGE = 'send-message',

  FILTER = 'filter',

  DELAY = 'delay',
}
