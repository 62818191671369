import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  isLocalStorageAvailable,
  LocalUserSettingsKey,
} from '../localUserSettings'
import { LocalUserSettings, ReduxUserState } from '../types'

export const setUserSettings: CaseReducer<
  ReduxUserState,
  PayloadAction<{ userSettings: LocalUserSettings }>
> = (state, action) => {
  try {
    Object.assign(state, {
      ...state,
      data: {
        ...state.data,
        userSettings: action.payload.userSettings,
      },
    })

    if (isLocalStorageAvailable()) {
      localStorage.setItem(
        LocalUserSettingsKey,
        JSON.stringify(state.data.userSettings)
      )
    }
  } catch (e) {
    console.warn('userSlice:reducer:setUserSettings:', e)
  }
}
