import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ShareNoteTemplateParameter } from '../middleware/shareNoteTemplate'

export const shareNoteTemplate = async (
  params: Readonly<ShareNoteTemplateParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(`/api/note-templates/${params.noteTemplateId}/share`, HTTPMethod.POST, options)

  request.setBody(params.shares)

  return request.make()
}
