import { NavLink } from 'react-router-dom'
import { CRMProvider } from '../features/user/types'
import styles from './css/Toolbar.module.css'
import { useAppSelector } from '@/app/hooks'
import { getCurrentUserRole } from '@/features/users/selectors/getCurrentUserRole'
import { LuruRole } from '@/features/users/types'
import Tooltip from '@/components/Tooltip'
import LoginControl from '@/primitives/LoginControl'
import Utils from '@/utils/Utils'
import useCrmName from '@/features/crm/hooks/useCrmName'

const calcClassName = ({ isActive }: { isActive: boolean }, withBadge = false) =>
  Utils.classes(styles.normal, isActive && styles.selected, withBadge && styles.withBadge)

const showWorkflowsIcon = (c: CRMProvider | undefined) =>
  [CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX, CRMProvider.HUBSPOT].includes(c as CRMProvider)

const showPipelineIcon = showWorkflowsIcon

export const showDealsDashboardIcon = () =>
  window.location.href.includes('.my.looru.io:3000') ||
  window.location.href.includes('https://luruapp-tjjgsxk.s1.my.luru.app')

export default function Toolbar() {
  const crm = useCrmName()
  return useAppSelector(getCurrentUserRole) === LuruRole.ADMIN ? (
    <AdminToolbar crm={crm} />
  ) : (
    <RegularUserToolbar crm={crm} />
  )
}

const WorkflowsIcon = () => (
  <NavLink to='/workflows' className={({ isActive }) => calcClassName({ isActive }, true)}>
    <Tooltip label='Workflows' placement='right'>
      <div className={Utils.classes(styles.workflowImage, styles.image)}></div>
    </Tooltip>
  </NavLink>
)

const PipelineIcon = () => (
  <NavLink to='/pipeline' className={({ isActive }) => calcClassName({ isActive }, true)}>
    <Tooltip label='Pipeline' placement='right'>
      <div className={Utils.classes(styles.pipeLineImage, styles.image)}></div>
    </Tooltip>
  </NavLink>
)

const DealDashboardIcon = () => (
  <NavLink to='/deals_dashboard' className={calcClassName}>
    <Tooltip label='Deals Dashboard' placement='right'>
      <div className={Utils.classes(styles.dealsDashboardImage, styles.image)}></div>
    </Tooltip>
  </NavLink>
)

const NotesIcon = () => (
  <NavLink to='/notes' className={calcClassName}>
    <Tooltip label='Notes' placement='right'>
      <div className={Utils.classes(styles.noteImage, styles.image)}></div>
    </Tooltip>
  </NavLink>
)

const SettingsIcon = () => (
  <NavLink to='/settings' className={calcClassName}>
    <Tooltip label='Settings' placement='right'>
      <div className={Utils.classes(styles.settingsImage, styles.image)}></div>
    </Tooltip>
  </NavLink>
)

const TasksIcon = () => (
  <NavLink to='/tasks' className={calcClassName}>
    <Tooltip label='Tasks' placement='right'>
      <div className={Utils.classes(styles.taskImage, styles.image)}></div>
    </Tooltip>
  </NavLink>
)

const AdminToolbar = ({ crm }: { crm: CRMProvider | undefined }) => (
  <aside className={styles.toolbar}>
    <div>
      {showWorkflowsIcon(crm) && <WorkflowsIcon />}
      {showDealsDashboardIcon() && <DealDashboardIcon />}
      {showPipelineIcon(crm) && <PipelineIcon />}
      <NotesIcon />
      <SettingsIcon />
    </div>
    <div>
      <LoginControl />
    </div>
  </aside>
)

const RegularUserToolbar = ({ crm }: { crm: CRMProvider | undefined }) => (
  <aside className={styles.toolbar}>
    <div>
      <NotesIcon />
      <TasksIcon />
      {showPipelineIcon(crm) && <PipelineIcon />}
      <SettingsIcon />
    </div>
    <div>
      <LoginControl />
    </div>
  </aside>
)
