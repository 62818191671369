// API end points
import { createNote } from './createNote'
import fetchNote from './fetchNote'
import saveNote from './saveNote'
import deleteNote from './deleteNote'
import createNoteConnection from './createNoteConnection'
import deleteNoteConnection from './deleteNoteConnection'
import syncNote from './syncNote'
import shareNote from './shareNote'
import searchSORNotes from './searchSORNotes'
import searchLuruNotes from './searchLuruNotes'
import searchAllNotes from './searchAllNotes'

export const NotesAPI = {
  createNote,
  fetchNote,
  saveNote,
  deleteNote,
  createNoteConnection,
  deleteNoteConnection,
  syncNote,
  shareNote,
  searchSORNotes,
  searchLuruNotes,
  searchAllNotes,
}
