// React
import { useEffect, useRef } from 'react'

// Redux
import { useDispatch } from 'react-redux'

// Own libraries
import { scrollYToElement } from '../domutils/utils'

// Custom hooks
import { useGetSetState, useThreeWayClickHandler } from '../hooks/luru_hooks'

// Own components
import RecordLinkStatusButton from './RecordLinkStatusButton'
import TypeAheadSearchBox from './TypeAheadSearchBox'

// Styles
import styles from './css/RecordLinkControl.module.css'
import crmStyles from './css/CRMRecordLinkControl.module.css'
import UserLogo from './UserLogo'

// Icons
import syncDisabledIcon from '../images/fluent/sync_disable.svg'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

/***
 * This component is for displaying a list of (any) records where the user can
 * scroll and select any record.
 * Further, there is a search box which updates the list displayed
 * The records displayed are generic and have to have only two properties
 * {
 *    'id': <An Opaque string>
 *    'name': A string that is dislpayed to the user
 * }
 */
export default function RecordChooser(props) {
  const MenuMode = {
    SEARCH: 'search',
    SELECT: 'select',
  }
  Object.freeze(MenuMode)

  const { showToast } = useLuruToast()

  //// Component state
  let state = {
    searchInProgress: useGetSetState(false),
    searchText: useGetSetState(''),
    mode: useGetSetState(MenuMode.SEARCH),
    selectedIndex: useGetSetState(-1),
    defaultRecords: props.defaultRecords,
  }

  // Currently filters are hard-coded to empty list
  // Later, take this also as a part of props
  const initialFilterState = {}
  // const primaryObjects = []
  // primaryObjects.forEach((object) => (initialFilterState[object.name] = false))

  state.recordFilters = useGetSetState(initialFilterState)

  // props.linkedRecord is of the format when linkType = 'user'
  // {
  //   'name': 'Pepsi',
  //   'id': 'abc123',
  // }
  state.linkedRecord = props.linkedRecord

  // Array of records that need to be displayed; Each item of array is of this format
  // {
  //    'name': 'Pepsi',
  //    'id': 'abc123',
  // }
  state.displayedRecords = useGetSetState([])

  const dispatch = useDispatch()

  const refs = {
    popup: useRef(null),
    control: useRef(null),
    filters: useRef(null),
    searchBox: useRef(null),
    actionIcon: useRef(null),
    syncButton: useRef(null),
    searchResults: useRef(null),
  }

  //// Component views
  function render() {
    // RecordLinkStatusButton expects the record in this format (ie, under a
    // 'connection' key).
    // TODO: Change it since that format is unnecessary.
    let linkedRecord = null
    if (state.linkedRecord !== null) {
      linkedRecord = {
        connection: {
          ...state.linkedRecord,
        },
      }
    }
    return (
      <div className={styles.parent} ref={refs.control}>
        <RecordLinkStatusButton
          key={props.recordType}
          link={linkedRecord}
          linkType={props.recordType}
          ref={refs.actionIcon}
          linkingTooltip={props.linkingTooltip}
        />
        <div className={styles.popup} ref={refs.popup} id={`generic-crm-link-popup`}>
          <TypeAheadSearchBox
            placeholder={props.searchBoxMessage ?? 'Search for record'}
            ariaLabel='Search for record'
            searchText={state.searchText.get()}
            onKeyDown={eventHandlers.onSearchBoxKeyDown}
            onTriggerSearch={eventHandlers.onTriggerSearch}
            onClearSearch={eventHandlers.onClearSearch}
            ref={refs.searchBox}
            loading={state.searchInProgress.get()}
          />
          {/* <ul
            className={crmStyles.filterList}
            ref={refs.filters}
            onClick={eventHandlers.onRecordFilterChange}
          >
            {primaryObjects.map((object) => (
              <li key={object.name} data-filter={object.name}>
                {object.plural}
              </li>
            ))}
          </ul> */}
          <div className={styles.results}>{renderRecordsListArea()}</div>
          <footer>
            <div>esc to cancel</div>
            <div>
              {'\u2191'} or {'\u2193'} to navigate
            </div>
          </footer>
        </div>
      </div>
    )
  }

  function renderRecordsListArea() {
    if (state.searchInProgress.get() === true) {
      return <div className={styles.noresults}>Fetching...</div>
    } else {
      return renderRecordsList()
    }
  }

  function renderUserLogo(name) {
    let firstName = null
    let lastName = null
    let title = null
    let parts = name.split(' ')
    if (parts.length >= 2) {
      firstName = parts[0]
      lastName = parts.slice(1).join(' ')
    } else if (parts.length === 1) {
      firstName = parts[0]
    }
    return (
      <div style={{ marginRight: '0.5em' }}>
        <UserLogo title={title} firstname={firstName} lastname={lastName} />
      </div>
    )
  }

  function renderRecordsList() {
    let records = state.displayedRecords.get()
    // console.log(`renderRecordsList::records = ${JSON.stringify(records, null, 4)}`)
    if (records == null || records?.length === 0) {
      return <div className={styles.noresults}>No records</div>
    }

    return (
      <ul className={styles.loaded} ref={refs.searchResults}>
        {records?.map((item, index) => (
          <li
            key={`result-${index}`}
            onClick={handleChooseRecord}
            title={item.name}
            className={state.selectedIndex.get() === index ? styles.selected : ''}
            data-name={item.name}
            data-id={item.id}
            data-choosable={item.choosable}
            data-sor-result={true}
            data-result-index={index}
          >
            {renderUserLogo(item.name)}
            <div>{item.name}</div>
            {!item.choosable ? (
              <div className={crmStyles.unselectableIcon}>
                <img src={syncDisabledIcon} alt='syncDisabled' title={item.cantChooseMessage} />
              </div>
            ) : (
              ''
            )}
          </li>
        ))}
      </ul>
    )
  }

  //// Component commands: These are the key functions of the controller
  // These functions control the model and/or view
  // Hide menu and execute all side-effects
  function hideMenu(config) {
    if (refs.popup.current?.classList.contains(styles.visible)) {
      // Redux reset
      // TODO: Cancel any ongoing API requests
      // crmMiddleware[state.crmId.toLowerCase()].searchRecords.abortAllRequests()
      props.clearSearch?.()

      // Own component state reset
      clearRecordFilters()
      state.searchText.set('')
      state.displayedRecords.set(state.defaultRecords)
      state.selectedIndex.set(-1)
      refs.searchBox.current.dispatchEvent(new CustomEvent('clear'))

      // Own component style reset
      refs.popup.current.classList.remove(styles.visible)
    }
  }

  // Toggle menu
  /**
   * Show or hide menu
   * called on an event raised from CRMFieldChooser
   */
  function toggleMenu() {
    if (refs.popup.current.classList.contains(styles.visible)) {
      // Hide menu and execute all side effects
      hideMenu({ cancelled: true })
    } else {
      refs.popup.current.classList.remove(styles.modalPopup)
      refs.popup.current.classList.add(styles.buttonPopup)
      // Add style to make popup visible
      refs.popup.current.classList.add(styles.visible)
      // Set default records as records list
      state.displayedRecords.set(state.defaultRecords)
      state.selectedIndex.set(-1)
      // Set focus on search box
      setTimeout(() => refs.searchBox.current.focus(), 100)
    }
  }

  // Handle choosing of an SOR from search results
  function handleChooseRecord(e) {
    let resultItem = e.target.closest('li'),
      objData = resultItem.dataset
    e.preventDefault()
    props.onChooseRecord(objData)
    hideMenu({ cancelled: true })
  }

  // Trigger an asynchronous search operation
  function triggerSearch(query) {
    let queryText = query?.searchText ?? state.searchText.get()

    let filters = query.recordFilters ?? state.recordFilters.get()
    let filtersArray = Object.keys(filters).filter((name) => filters[name])
    if (!filtersArray.length) {
      filtersArray = Object.keys(filters)
    }

    state.searchInProgress.set(true)
    props
      .search({
        query: queryText,
        objects: filtersArray,
      })
      .then((items) => {
        state.searchInProgress.set(false)
        state.displayedRecords.set(items)
      })
      .catch((error) => {
        state.searchInProgress.set(false)
        console.log(error)
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: error.message ?? 'Error searching for records',
          severity: 'error',
        })
      })
  }

  function clearRecordFilters() {
    state.recordFilters.set(initialFilterState)
  }

  //// Component event handlers (these are children-facing event handlers)
  const eventHandlers = {
    // Handler for removing link
    onRemoveLink: (e) => {
      props.onRemoveLink(state.linkedRecord)
    },
    // Handler for trigger search event from type ahead search box component
    onTriggerSearch: (e) => {
      if (e.key === 'Enter' && state.mode.get() === MenuMode.SELECT) {
        // Choose the meeting here based on current selection
        handleChooseRecord({
          target: refs.popup.current.querySelector(`li[data-result-index="${state.selectedIndex.get()}"]`),
          preventDefault: e.preventDefault,
        })
      } else {
        const queryText = e.data.searchText
        state.searchText.set(queryText)
        state.mode.set(MenuMode.SEARCH)
        if (queryText.trim() === '') {
          // TODO: Implement callerId-wise API call in CRM Middleware
          if (props.clearSearch) {
            dispatch(props.clearSearch())
          }
          state.displayedRecords.set(state.defaultRecords)
        } else {
          triggerSearch({ searchText: queryText })
        }
      }
    },

    // Handler for keydown event from type ahead search box component
    onClearSearch: (e) => {
      if (e.key && e.key === 'Escape') {
        hideMenu({ cancelled: true })
      } else {
        if (props.clearSearch) {
          dispatch(props.clearSearch())
        }
        state.displayedRecords.set(state.defaultRecords)
        state.selectedIndex.set(-1)
      }
    },

    // Handler for change filter event from week browser component
    onRecordFilterChange: (e) => {
      if (e.target?.dataset?.filter) {
        let chosenFilter = e.target.dataset.filter
        let currentFilters = state.recordFilters.get()
        let newFilters = {
          ...currentFilters,
          [chosenFilter]: !currentFilters[chosenFilter],
        }
        e.target.classList.toggle(crmStyles.selected)
        state.recordFilters.set(newFilters)
        if (state.searchText.get().trim() !== '') {
          triggerSearch({ recordFilters: newFilters })
        }
      }
    },

    // Handler for capturing keyboard events from search box.  Used for
    // navigating search results list items
    onSearchBoxKeyDown: (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        state.mode.set(MenuMode.SELECT)
        let currentIndex = state.selectedIndex.get()
        if (e.key === 'ArrowDown') {
          state.selectedIndex.set((currentIndex + 1) % state.displayedRecords.get().length)
        } else {
          state.selectedIndex.set(currentIndex === 0 ? state.displayedRecords.get().length - 1 : currentIndex - 1)
        }
        // Set the state for currently highlighted item
        e.preventDefault()
      }
    },

    // Handler for event on making note editable
    onMakeEditable: (e) => {
      refs.syncButton.current?.classList.remove('hidden')
    },
  }

  //// Component setup - useEffect, etc. are set up here
  function useSetup() {
    // Set up click handlers for toggle/hide menu
    useThreeWayClickHandler(
      { outsideRef: refs.control, insideRef: refs.popup },
      {
        outsideClick: () => hideMenu({ cancelled: true }),
        betweenClick: (e) => {
          let actionIcon = refs.actionIcon.current
          let isAction =
            e.clientX >= actionIcon.getBoundingClientRect().left &&
            e.clientX <= actionIcon.getBoundingClientRect().right &&
            e.clientY >= actionIcon.getBoundingClientRect().top &&
            e.clientY <= actionIcon.getBoundingClientRect().bottom
          if (isAction && state.linkedRecord) {
            eventHandlers.onRemoveLink(e)
          } else {
            if (!props.readonly) toggleMenu()
          }
        },
      }
    )

    // Scroll to the result item
    useEffect(() => {
      let selection = refs.popup.current.querySelector(`.${styles.selected?.replace('+', '\\+')}`)
      if (selection) {
        let container = refs.popup.current.querySelector(`.${styles.results?.replace('+', '\\+')}`)
        scrollYToElement(selection, container)
      }
    })
  }

  useSetup()

  return render()
}
