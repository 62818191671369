// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { TasksAPI } from '../api'
import { ReduxTaskEntity, ReduxTasksState, TaskAssignee, TaskConnectionSpec, TaskPriority, TaskStatus } from '../types'

export interface UpdateTaskParameter {
  task_id: string
  title?: string
  status?: TaskStatus
  body?: string
  due_date?: string | null
  assigned_to?: TaskAssignee | null
  priority?: TaskPriority
  connections?: Array<TaskConnectionSpec>
}

interface UpdateTaskAPIResponse extends LuruAPIResponse {
  data: ReduxTaskEntity
}

export const updateTask = {
  action: createAsyncThunk<
    UpdateTaskAPIResponse['data'],
    UpdateTaskParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | UpdateTaskAPIResponse['metadata']
      rejectedMeta: null | UpdateTaskAPIResponse['metadata']
    }
  >('tasks/updateTask', async (params, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      var response: UpdateTaskAPIResponse = await TasksAPI.updateTask(params, { signal })
      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null
      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(updateTask.action.pending, (state, action) => {
      var { task_id: taskId } = action.meta.arg

      if (state.entities[taskId]) {
        state.entities[taskId] = {
          ...state.entities[taskId],
          status: EntityStatus.Loading,
        }
      } else {
        state.entities[taskId] = {
          status: EntityStatus.Loading,
          data: null,
        }
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(updateTask.action.fulfilled, (state, action) => {
      var task = action.payload
      state.entities[task.task_id] = {
        data: task,
        status: EntityStatus.Loaded,
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(updateTask.action.rejected, (state, action) => {
      var { task_id: taskId } = action.meta.arg
      state.entities[taskId] = {
        ...state.entities[taskId],
        status: EntityStatus.Loaded,
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    updateTask.addPendingCase(builder)
    updateTask.addRejectedCase(builder)
    updateTask.addFulfilledCase(builder)
  },
}
