import React from 'react'
import {
  LuruHierarchialPicklistOptions,
  LuruHierarchialPicklistValue,
} from '../../../domain/crmRecord/typings.d'
import LuruPopupButton from '../LuruPopupButton'
import LuruTextBox from '../LuruTextBox'
import LuruHierarchicalPicklistEventHandler from './LuruHierarchicalPicklistEventHandler'

import styles from './styles.module.css'
import popupStyles from '../../css/PopupMenu.module.css'

// Icons
import arrowDropDownIcon from '../../../images/fluent/arrow_drop_down.svg'

export interface LuruHierarchicalPicklistProps {
  // Optional id
  id?: string
  // Menu parent selector for stacking
  menuParentSelector?: string
  // Pre-chosen item - also, value
  prechosenItem?: LuruHierarchialPicklistValue
  // Options
  options: LuruHierarchialPicklistOptions
  // Disabled or not
  disabled?: boolean
  // Is filterable?
  isFilterable?: boolean
  // Show select item?
  showSelectItem?: boolean
  // Custom select label
  selectLabel?: string
  // Extra classes
  classes?: Array<string>
  // Alignment
  leftAlign?: boolean
  // Subdued styling (passed on to PopupButton)
  subdued?: boolean
  // Handlers
  onChooseItem: (value: LuruHierarchialPicklistValue) => void
}

interface LuruHierarchicalPicklistState {
  chosenItemName?: string
  filteredOptions: LuruHierarchialPicklistOptions
  highlightedIndex: {
    level1: number
    level2: number
  }
}

interface LuruHierarchicalPicklistRefs {
  popupControl: React.RefObject<LuruPopupButton>
  filterBox: React.RefObject<LuruTextBox>
  list: React.RefObject<HTMLUListElement>
}

export default class LuruHierarchicalPicklist extends React.Component<
  LuruHierarchicalPicklistProps,
  LuruHierarchicalPicklistState
> {
  props: LuruHierarchicalPicklistProps
  state: LuruHierarchicalPicklistState
  eventHandler: LuruHierarchicalPicklistEventHandler
  componentRefs: LuruHierarchicalPicklistRefs

  constructor(props: LuruHierarchicalPicklistProps) {
    super(props)
    this.props = props

    var chosenItemLevel1 = this.props.options.find(
      (item) => item.value === this.props.prechosenItem?.level1
    )
    var chosenItemLevel2 = chosenItemLevel1?.options.find(
      (item) => item.value === this.props.prechosenItem?.level2
    )
    var chosenItemName = chosenItemLevel1
      ? chosenItemLevel1?.label + ': ' + chosenItemLevel2?.label
      : undefined

    this.state = {
      chosenItemName,
      filteredOptions: this.props.options,
      highlightedIndex: {
        level1: -1,
        level2: -1,
      },
    }
    this.eventHandler = new LuruHierarchicalPicklistEventHandler(this)
    this.componentRefs = {
      popupControl: React.createRef(),
      filterBox: React.createRef(),
      list: React.createRef(),
    }
  }

  render() {
    var menuItems = (
      <>
        {this.#renderFilterBox()}
        {this.#renderMenuItems()}
      </>
    )
    var classes = [styles.parent]

    if (Array.isArray(this.props.classes)) {
      classes = classes.concat(this.props.classes)
    }

    var selectedLabel = <>{this.props.selectLabel ?? 'Select'}</>

    if (this.state.chosenItemName) {
      selectedLabel = <>{this.state.chosenItemName}</>
    }

    return (
      <LuruPopupButton
        id={this.props.id ? this.props.id + '-popup-button' : undefined}
        ref={this.componentRefs.popupControl}
        menuParentSelector={this.props.menuParentSelector}
        subdued={this.props.subdued === true}
        items={menuItems}
        classes={classes}
        disabled={this.props.disabled}
        leftAlign={Boolean(this.props.leftAlign)}
        hideOnMenuClick={!Boolean(this.props.isFilterable)}
        onShowMenu={this.eventHandler.handlers.onShowSelectDropdown}
        title={this.state.chosenItemName}
      >
        <span className={styles.label} data-luru-role='luru-select-box-label'>
          {selectedLabel}
        </span>
        <span className={styles.actionIcon}>
          <img src={arrowDropDownIcon} alt='drop_down' />
        </span>
      </LuruPopupButton>
    )
  }

  #renderFilterBox() {
    var isFilterable = Boolean(this.props.isFilterable)

    if (!isFilterable) {
      return null
    }

    var filterBox = (
      <div className={styles.filterBoxContainer}>
        <LuruTextBox
          additionalClassNames={[styles.filterBox]}
          ref={this.componentRefs.filterBox}
          onChange={this.eventHandler.handlers.onFilterBoxChange}
          onNavigateDown={this.eventHandler.handlers.onFilterBoxNavigateDown}
          onNavigateUp={this.eventHandler.handlers.onFilterBoxNavigateUp}
          onReturn={this.eventHandler.handlers.onFilterBoxReturn}
          onCancel={this.eventHandler.handlers.onFilterBoxCancel}
        />
      </div>
    )

    return filterBox
  }

  #renderMenuItems() {
    return (
      <ul className={styles.menuList} ref={this.componentRefs.list}>
        {this.props.showSelectItem ? (
          <li onClick={this.eventHandler.handlers.onReset}>Select</li>
        ) : null}

        {this.state.filteredOptions.map((level1Item, level1Ix) => (
          <li
            key={`level1-${level1Item.value}`}
            className={[
              level1Ix === 0 && this.props.showSelectItem
                ? popupStyles.separated
                : undefined,
              styles.level1ListItem,
            ].join(' ')}
          >
            <label>{level1Item.label}</label>
            <ul className={styles.level2}>
              {this.state.filteredOptions[level1Ix].options.map(
                (level2Item, level2Ix) => (
                  <li
                    key={`level2-${level1Item.value}-${level2Item.value}`}
                    className={
                      level1Ix === this.state.highlightedIndex.level1 &&
                      level2Ix === this.state.highlightedIndex.level2
                        ? styles.highlightedItem
                        : undefined
                    }
                    onClick={this.eventHandler.handlers.onChooseItem}
                    data-item-chooser-key={`${level1Ix};${level2Ix}`}
                    data-highlighted={
                      level1Ix === this.state.highlightedIndex.level1 &&
                      level2Ix === this.state.highlightedIndex.level2
                        ? 'yes'
                        : 'no'
                    }
                  >
                    {level2Item.label}
                  </li>
                )
              )}
            </ul>
          </li>
        ))}
      </ul>
    )
  }
}
