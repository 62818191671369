import { PayloadAction } from '@reduxjs/toolkit'
import { ReduxAppState } from '../types'

export interface PayloadTypeForNoteChanged {
  template_id?: string | null
  isEmpty?: boolean
  noteBody?: string
  dirtiedAfterInsertion?: boolean
  autoLinkedTemplateId?: string | null
  note_id: string
}

export const setNoteDetailsChanged = (state: ReduxAppState, action: PayloadAction<PayloadTypeForNoteChanged>) => {
  const noteId = action.payload?.note_id
  const existingNoteDetails = state.home.notes.noteDetails?.[noteId]

  state.home.notes.noteDetails = {
    ...state.home.notes.noteDetails,
    [noteId]: {
      ...existingNoteDetails,
      ...action.payload,
    },
  }
}
