export const NodeType = {
  ELEMENT_NODE: 1,
  TEXT_NODE: 3,
  DOCUMENT_NODE: 9,
}

export interface IDOMPosition {
  container?: Node | ChildNode
  offset: number
}

export interface IDOMBoundary {
  startPosition: IDOMPosition
  endPosition: IDOMPosition
  startNode?: Node | ChildNode
  endNode?: Node | ChildNode
}

export default class DomUtils {
  static addDatePickedEvent(element: HTMLInputElement, callback: (value: string) => void) {
    var tsKeydown = 0

    function onKeyDown() {
      tsKeydown = new Date().valueOf()
    }

    function onChange() {
      let tsChange = new Date().valueOf()
      let tsDelta = tsChange - tsKeydown

      if (tsDelta > 50) {
        callback(element.value)
      }
    }

    element.addEventListener('keydown', onKeyDown)
    element.addEventListener('change', onChange)
  }

  static isCaretAtLastLine(
    range: Range,
    currBox?: HTMLElement,
    scrollContainer?: HTMLElement,
    bottomPadding = 0
  ): Boolean {
    if (currBox?.textContent?.trim() === '') {
      return true
    }

    let boxTop = currBox?.offsetTop ?? 0 + (currBox?.parentElement?.parentElement?.offsetTop ?? 0)
    let boxBottom = boxTop + (currBox?.clientHeight ?? 0)
    let caretHeight = range.getBoundingClientRect().height
    let caretTop =
      range.getBoundingClientRect().top - (scrollContainer?.offsetTop ?? 0) + (scrollContainer?.scrollTop ?? 0)
    let caretBottom = caretTop + caretHeight

    if (range.getBoundingClientRect().top === 0) {
      caretTop = boxTop
      if (currBox) {
        caretHeight = parseFloat(getComputedStyle(currBox).lineHeight)
      }
      caretBottom = caretTop + caretHeight
    }

    return caretBottom + caretHeight + bottomPadding > boxBottom
  }

  static isCaretAtFirstLine(range: Range, currBox?: HTMLElement, scrollContainer?: HTMLElement): Boolean {
    if (currBox?.textContent?.trim() === '') {
      return true
    }

    let boxTop = (currBox?.offsetTop ?? 0) + (currBox?.parentElement?.parentElement?.offsetTop ?? 0)
    let caretHeight = range.getBoundingClientRect().height
    let caretTop =
      range.getBoundingClientRect().top - (scrollContainer?.offsetTop ?? 0) + (scrollContainer?.scrollTop ?? 0)

    if (range.getBoundingClientRect().top === 0) {
      caretTop = boxTop
      caretHeight = currBox ? parseFloat(getComputedStyle(currBox).lineHeight) : 0
    }

    return caretTop - caretHeight < boxTop
  }

  static scrollIntoViewIfNeeded(currBox?: HTMLElement, scrollContainer?: HTMLElement): void {
    let boxTop = (currBox?.offsetTop ?? 0) + (currBox?.parentElement?.parentElement?.offsetTop ?? 0)
    let boxBottom = boxTop + (currBox?.clientHeight ?? 0)

    if (boxBottom >= (scrollContainer?.scrollTop ?? 0) + (scrollContainer?.clientHeight ?? 0)) {
      currBox?.scrollIntoView(false)
      return
    }

    if (boxTop < (scrollContainer?.scrollTop ?? 0)) {
      currBox?.scrollIntoView(true)
    }
  }

  static getCaretCoordinates(): Point {
    var range, rect
    var x = 0,
      y = 0
    if (window.getSelection) {
      var sel = window.getSelection()
      if (sel?.rangeCount) {
        range = sel.getRangeAt(0).cloneRange()
        if (range.getClientRects) {
          range.collapse(true)
          if (range.getClientRects().length > 0) {
            rect = range.getClientRects()[0]
            x = rect.left
            y = rect.top
          }
        }
        // Fall back to inserting a temporary element
        if (x === 0 && y === 0) {
          var span = document.createElement('span')
          if (span.getClientRects) {
            // Ensure span has dimensions and position by
            // adding a zero-width space character
            span.appendChild(document.createTextNode('\u200b'))
            range.insertNode(span)
            rect = span?.getClientRects?.()[0]
            x = rect?.left
            y = rect?.top
            var spanParent = span?.parentNode
            if (spanParent && spanParent.contains(span)) {
              spanParent?.removeChild?.(span)
            }

            // Glue any broken text nodes back together
            spanParent?.normalize()
          }
        }
      }
    }
    return new Point(x ?? 0, y ?? 0)
  }

  /**
   * Position a given menu element at the cursor in a target within a container
   * @param {HTMLElement} menu - Menu object
   * @param {HTMLElement} target - Typically the note element
   * @param {HTMLElement} container - Typically the editor container
   */
  static positionMenuAtTarget(menu?: HTMLElement, target?: HTMLElement, container?: HTMLElement) {
    // Preface: We assume that container is relatively positioned (which is
    // true in our case for editor container)

    // Find positions and (a) dimension of elements/caret in different XY planes
    const screenXY = {
      caret: DomUtils.getCaretCoordinates(),
      target: DomUtils.getElementScreenCoordinates(target),
    }
    const targetXY = { caret: screenXY.caret.subtract(screenXY.target) }
    const elementCoords = DomUtils.getElementCoordinates(target, container)
    let containerXY = {
      target: elementCoords,
      frame: {
        top: container?.scrollTop ?? 0,
        left: container?.scrollLeft ?? 0,
        bottom: (container?.scrollTop ?? 0) + (container?.clientHeight ?? 0),
        right: (container?.scrollLeft ?? 0) + (container?.clientWidth ?? 0),
      },
      caret: elementCoords?.add(targetXY.caret) ?? null,
    }

    // Find dimensions of other spaces/elements (except frame - see above)
    const caretHeight = target ? parseFloat(window.getComputedStyle(target).lineHeight) : 0
    const menuSize = DomUtils.getElementDimensions(menu)
    const spaceAvailable = {
      above: (containerXY.caret?.getY() ?? 0) - containerXY.frame.top,
      below: containerXY.frame.bottom - (containerXY.caret?.getY() ?? 0) - caretHeight,
      left: containerXY.caret?.getX() ?? 0 - containerXY.frame.left,
      right: containerXY.frame.right - (containerXY.caret?.getX() ?? 0) - 10,
      // right is reduced to account for scrollbar
    }

    // Store the original size of menu, if not already set
    // Restore the menu to its original size - as we may resize it for smaller
    // container frames later
    if (!menu?.hasAttribute('data-luru-original-width')) {
      if (menu) {
        menu.setAttribute('data-luru-original-width', menuSize?.width + '' ?? '0')
      }
    } else {
      if (menuSize) {
        menuSize.width = parseFloat(menu?.getAttribute('data-luru-original-width') ?? '0')
        menu.style.width = menuSize.width + 'px'
      }
    }

    if (!menu?.hasAttribute('data-luru-original-height')) {
      if (menu) {
        menu.setAttribute('data-luru-original-height', menuSize?.height + '' ?? '0')
      }
    } else {
      const originalHeight = parseFloat(menu?.getAttribute('data-luru-original-height') ?? '0')
      if (Math.floor(menuSize?.height ?? 0) < Math.floor(originalHeight)) {
        if (menuSize) {
          menuSize.height = originalHeight
          menu.style.height = menuSize.height + 'px'
        }
      } else {
        if (menuSize) {
          menuSize.height = originalHeight
          menu.style.height = 'auto'
        }
      }
    }

    // Finding out where to position menu by computing spaces in all directions
    const isSpaceAvailable = {
      above: spaceAvailable.above > (menuSize?.height ?? 0),
      below: spaceAvailable.below > (menuSize?.height ?? 0),
      left: spaceAvailable.left > (menuSize?.width ?? 0),
      right: spaceAvailable.right > (menuSize?.width ?? 0),
    }

    let sideBuffer = 0

    // Place menu
    if (isSpaceAvailable.below) {
      if (menu) {
        menu.style.top = (containerXY.caret?.getY() ?? 0 + caretHeight) + 'px'
        menu.style.bottom = 'auto'
      }
    } else if (isSpaceAvailable.above) {
      if (menu) {
        menu.style.top = 'auto'
        menu.style.bottom = containerXY.frame.bottom - containerXY.frame.top - (containerXY.caret?.getY() ?? 0) + 'px'
      }
    } else if (spaceAvailable.above + spaceAvailable.below > (menuSize?.height ?? 0)) {
      if (menu) {
        menu.style.top = (containerXY.caret?.getY() ?? 0) - ((menuSize?.height ?? 0) - spaceAvailable.below) + 'px'
        menu.style.bottom = 'auto'
      }
      sideBuffer = 5
    } else {
      if (menu) {
        menu.style.height = spaceAvailable.above + spaceAvailable.below + 'px'
        menu.style.top = containerXY.frame.top + 'px'
        menu.style.bottom = 'auto'
      }
      sideBuffer = 5
    }

    if (isSpaceAvailable.right && menu) {
      menu.style.left = (containerXY.caret?.getX() ?? 0) + sideBuffer + 'px'
    } else if (isSpaceAvailable.left && menu) {
      menu.style.left = (containerXY.caret?.getX() ?? 0) - (menuSize?.width ?? 0) - sideBuffer + 'px'
    } else if (spaceAvailable.left + spaceAvailable.right > (menuSize?.width ?? 0) && menu) {
      menu.style.left = (containerXY.caret?.getX() ?? 0) - ((menuSize?.width ?? 0) - spaceAvailable.right) + 'px'
    } else {
      if (menu) {
        menu.style.width = spaceAvailable.left + spaceAvailable.right + 'px'
        menu.style.left = containerXY.frame.left + 'px'
      }
    }
  }

  static getElementCoordinates(element?: HTMLElement, relativeTo?: HTMLElement): Point | null {
    try {
      let point = new Point(element?.offsetLeft ?? 0, element?.offsetTop ?? 0)
      let coordinatePlaneParent = element?.offsetParent as HTMLElement

      while (coordinatePlaneParent && coordinatePlaneParent !== relativeTo) {
        point = point.add({
          x: coordinatePlaneParent.offsetLeft,
          y: coordinatePlaneParent.offsetTop,
        })
        coordinatePlaneParent = coordinatePlaneParent?.offsetParent as HTMLElement
      }

      return point
    } catch (e) {
      console.warn(`DomUtils:getElementCoordinates:`, e)
      return null
    }
  }

  static getScrollAdjustedElementCoordinates(element?: HTMLElement, relativeTo?: HTMLElement): Point | null {
    try {
      if (element) {
        let point = new Point(element.offsetLeft, element.offsetTop)
        let parent = element.parentElement

        while (parent && parent !== relativeTo) {
          point = point.subtract({
            x: parent.scrollLeft,
            y: parent.scrollTop,
          })
          if (parent === element.offsetParent) {
            point = point.add({
              x: parent.offsetLeft,
              y: parent.offsetTop,
            })
            element = element.offsetParent as HTMLElement
          }
          parent = parent.parentElement
        }

        return point
      } else {
        return null
      }
    } catch (e) {
      console.warn(`DomUtils:getScrollAdjustedElementCoordinates:`, e)
      return null
    }
  }

  static getElementScreenCoordinates(element?: HTMLElement): Point | null {
    try {
      if (element) {
        let point = new Point(element.offsetLeft, element.offsetTop)
        let parent = element.parentElement

        while (parent) {
          point = point.subtract({
            x: parent.scrollLeft,
            y:
              parent.scrollTop +
              parseFloat(getComputedStyle(parent).borderTopWidth) * 0 +
              parseFloat(getComputedStyle(parent).marginTop) * 0,
          })
          if (parent === element.offsetParent) {
            point = point.add({
              x: parent.offsetLeft,
              y: parent.offsetTop,
            })
            element = element.offsetParent as HTMLElement
          }
          parent = parent.parentElement
        }

        return point
      } else {
        return null
      }
    } catch (e) {
      console.warn(`DomUtils:getElementCoordinates:`, e)
      return null
    }
  }

  static getElementDimensions(element?: HTMLElement): { width: number; height: number } | null {
    try {
      return element
        ? {
            width: element.offsetWidth,
            height: element.offsetHeight,
          }
        : null
    } catch (e) {
      return null
    }
  }

  static traverseDOMWithCallback({
    startNode,
    endNode,
    callback,
    context,
    checkTerminateCallback,
  }: {
    startNode?: Node | ChildNode
    endNode?: Node | ChildNode
    callback: (node?: Node | ChildNode | null) => void
    context: Object
    checkTerminateCallback: (arg0?: Node | ChildNode | null) => Boolean
  }) {
    let VisitType = {
      FROM_ORIGIN: 0,
      FROM_PREV_SIBLING: 1,
      FROM_LAST_CHILD: 2,
      FROM_PARENT: 3,
      FROM_CHILD: 4,
    }
    Object.freeze(VisitType)

    let currNode = startNode
    let visitType = VisitType.FROM_ORIGIN

    // Visit the first node
    if (callback) {
      callback.call(context, currNode)
    }

    while (currNode && currNode !== endNode && checkTerminateCallback(currNode) === false) {
      // Go to next node
      if (currNode.nodeType === NodeType.TEXT_NODE) {
        visitType = currNode.nextSibling ? VisitType.FROM_PREV_SIBLING : VisitType.FROM_LAST_CHILD
      } else if (currNode.nodeType === NodeType.ELEMENT_NODE) {
        visitType =
          visitType !== VisitType.FROM_CHILD && visitType !== VisitType.FROM_LAST_CHILD && currNode.firstChild
            ? VisitType.FROM_PARENT
            : currNode.nextSibling
            ? VisitType.FROM_PREV_SIBLING
            : VisitType.FROM_LAST_CHILD
      } else {
        visitType = VisitType.FROM_CHILD
      }

      currNode = (
        visitType === VisitType.FROM_PREV_SIBLING
          ? currNode.nextSibling
          : visitType === VisitType.FROM_PARENT
          ? currNode.firstChild
          : currNode.parentNode
      ) as Node

      // Visit the node
      if (callback) {
        callback.call(context, currNode)
      }
    }
  }

  static splitNodeUntil(
    currentElement: Node | null,
    breakpoint: number,
    checkTerminateCallback: (arg0: Node) => Boolean,
    trackingPosition: IDOMPosition | null = null,
    debugElement: HTMLElement
  ): IDOMPosition {
    // We go from the end container all the way up until the common
    // ancestor container
    while (currentElement && checkTerminateCallback(currentElement)) {
      // We check at each level if a break/split of the currently visited
      // element is required
      let isBreakRequired =
        breakpoint !== 0 &&
        (currentElement.nodeType === NodeType.TEXT_NODE
          ? breakpoint < (currentElement?.textContent?.length ?? 0)
          : breakpoint < currentElement?.childNodes.length)

      // We break open the node if a break is required
      if (isBreakRequired) {
        // Breaking a text node is different... (a)
        if (currentElement.nodeType === NodeType.TEXT_NODE) {
          // For a text node, we create a new text node with content from
          // the breaking point
          let newNode = document.createTextNode(currentElement.textContent?.slice(breakpoint) ?? '')
          // We also curtail the text of the left part of the broken node
          currentElement.textContent = currentElement.textContent?.slice(0, breakpoint) ?? ''
          // We insert the new node after the broken node (with curtailed text)
          if (currentElement.nextSibling) {
            currentElement.parentElement?.insertBefore(newNode, currentElement.nextSibling)
          } else {
            currentElement.parentElement?.appendChild(newNode)
          }

          // We are going to split the text node.  If the tracking position is
          // tracking this text node, then this split is going to change the
          // tracking position.  So tracking position needs to be updated.
          if (trackingPosition) {
            const containerType = trackingPosition.container?.nodeType
            if (containerType === NodeType.TEXT_NODE) {
              if (trackingPosition.container === currentElement && trackingPosition.offset >= breakpoint) {
                trackingPosition.container = newNode
                trackingPosition.offset -= breakpoint
              }
            } else if (containerType === NodeType.ELEMENT_NODE) {
              if (
                trackingPosition.container === currentElement.parentElement &&
                trackingPosition.offset > this.findChildIndex(currentElement.parentElement, currentElement)
              ) {
                trackingPosition.offset += 1
              }
            }
          }
          // Update the breakpoint
          breakpoint = currentElement.parentElement ? this.findChildIndex(currentElement.parentElement, newNode) : 0

          // (a contd.) ...from breaking open an element node
        } else if (currentElement.nodeType === NodeType.ELEMENT_NODE) {
          // Since node is an element node, we can get an element reference
          let currentHtmlElement = currentElement as HTMLElement
          // Create a new node of the same type...
          let newNode = document.createElement(currentHtmlElement.tagName)
          // ...and with the same attributes
          Array.from(currentHtmlElement.attributes).forEach((attribute) => {
            if (attribute.nodeValue) {
              newNode.setAttribute(attribute.name, attribute.nodeValue)
            }
          })
          // We insert the new node after the broken node (with curtailed text)
          if (currentElement.nextSibling) {
            currentElement.parentElement?.insertBefore(newNode, currentElement.nextSibling)
          } else {
            currentElement.parentElement?.appendChild(newNode)
          }

          // Partition the childNodes at breakpoint
          if (breakpoint < currentElement.childNodes.length) {
            for (let n: Node | null = currentElement.childNodes[breakpoint]; n; n = n.nextSibling) {
              let clonedNode = n.cloneNode(true)
              newNode.appendChild(clonedNode)
            }
            let numNodesToRemove = currentElement.childNodes.length - breakpoint
            while (numNodesToRemove--) {
              let toRemoveNode = currentElement.lastChild as Node
              if (currentElement && toRemoveNode && currentElement.contains(toRemoveNode)) {
                currentElement?.removeChild?.(toRemoveNode)
              }
            }

            // We are going to split the element node.  If tracking position is
            // tracking this node, then this split is going to change the
            // tracking position.  So tracking position needs to be updated.
            if (trackingPosition) {
              const containerType = trackingPosition.container?.nodeType
              if (containerType === NodeType.TEXT_NODE) {
                if (trackingPosition.container === currentElement && trackingPosition.offset >= breakpoint) {
                  trackingPosition.container = newNode
                  trackingPosition.offset -= breakpoint
                }
              } else if (containerType === NodeType.ELEMENT_NODE) {
                if (
                  trackingPosition.container === currentElement.parentElement &&
                  trackingPosition.offset >= breakpoint
                ) {
                  trackingPosition.offset += 1
                }
              }
            }
          }

          // Update the breakpoint
          breakpoint = currentElement.parentElement ? this.findChildIndex(currentElement.parentElement, newNode) : 0
        }
      } else {
        // ...if break is not required
        let newBreakpoint = currentElement.parentElement
          ? this.findChildIndex(currentElement.parentElement, currentElement)
          : 0
        breakpoint = breakpoint === 0 ? newBreakpoint : newBreakpoint + 1
      }

      currentElement = currentElement.parentElement
    }

    return {
      container: currentElement ? currentElement : undefined,
      offset: Math.min(
        breakpoint,
        currentElement?.nodeType === NodeType.ELEMENT_NODE
          ? currentElement.childNodes.length
          : (currentElement?.textContent ?? '').length
      ),
    }
  }

  static findChildIndex(parent?: HTMLElement, child?: Node): number {
    if (!parent || !child) {
      return -1
    }
    let newNodePosition = 0
    for (let e = parent?.firstChild; e && e !== child; e = e.nextSibling) {
      newNodePosition++
    }
    return newNodePosition
  }

  static debugPrintNode(debugNode?: Node | ChildNode): void {
    if (!debugNode) {
      console.log(`  Given node is undefined`)
      return
    }
    if (debugNode.nodeType === NodeType.TEXT_NODE) {
      console.log(`#(${debugNode.textContent})`)
    } else {
      console.log(`${(debugNode as HTMLElement).tagName}:`)
      debugNode.childNodes.forEach((node, ix) => {
        if (node.nodeType === NodeType.TEXT_NODE) {
          console.log(`  ${ix}:#(${node.textContent})`)
        } else if (node.nodeType === NodeType.ELEMENT_NODE) {
          let e = node as HTMLElement
          console.log(`  ${ix}:${e.tagName}(${e.innerHTML})`)
        }
      })
    }
  }

  static nonPartialSelectRange(
    range: Range,
    checkTerminateCallback: (arg0: Node) => Boolean | null,
    debugElement: HTMLElement
  ): IDOMBoundary {
    let traversalStopCheckFn = (checkTerminateCallback ??
      ((n: Node): Boolean => n !== range.commonAncestorContainer)) as (arg0: Node) => Boolean
    let staticRange: StaticRange = new StaticRange({
      endContainer: range.endContainer,
      endOffset: range.endOffset,
      startContainer: range.startContainer,
      startOffset: range.startOffset,
    })
    // Splitting end
    let endPosition: IDOMPosition = DomUtils.splitNodeUntil(
      staticRange.endContainer,
      staticRange.endOffset,
      traversalStopCheckFn,
      null,
      debugElement
    )

    // Track changes to end position after split
    let trackingPosition: IDOMPosition = {
      container: endPosition.container,
      offset: endPosition.offset,
    }
    let trackingPositionUnchanged: IDOMPosition = { ...trackingPosition }

    // Splitting start
    let startPosition: IDOMPosition = DomUtils.splitNodeUntil(
      staticRange.startContainer,
      staticRange.startOffset,
      traversalStopCheckFn,
      trackingPosition,
      debugElement
    )
    // Update endPosition which may have been affected by start's split
    if (
      trackingPositionUnchanged.container !== trackingPosition.container ||
      trackingPositionUnchanged.offset !== trackingPosition.offset
    ) {
      endPosition.container = trackingPosition.container
      endPosition.offset = trackingPosition.offset
    }

    // Setting start node
    let startNode =
      startPosition.container?.nodeType === NodeType.ELEMENT_NODE
        ? startPosition.container.childNodes[startPosition.offset - 1]
        : startPosition.container

    // Setting end node
    let endNode =
      endPosition.container?.nodeType === NodeType.ELEMENT_NODE
        ? endPosition.container.childNodes[endPosition.offset - 1]
        : endPosition.container

    return {
      startPosition,
      endPosition,
      startNode,
      endNode,
    }
  }

  static clearTagsBetween(boundary: IDOMBoundary, tagNames: string[]): void {
    let clearTagsFn = (node?: Node | ChildNode | null) => {
      if (!node) {
        return
      } else if (node.nodeType === NodeType.TEXT_NODE) {
        return
      } else if (
        node.nodeType === NodeType.ELEMENT_NODE &&
        tagNames.includes((node as HTMLElement).tagName.toLowerCase())
      ) {
        let e = node as HTMLElement
        e.replaceWith(...(node as HTMLElement).childNodes)
      }
    }

    DomUtils.traverseDOMWithCallback({
      startNode: boundary.startNode?.nextSibling
        ? boundary.startNode.nextSibling
        : boundary.startPosition.container?.childNodes[boundary.startPosition.offset],
      endNode: boundary.endNode?.nextSibling
        ? boundary.endNode.nextSibling
        : boundary.endPosition.container?.childNodes[boundary.endPosition.offset],
      callback: clearTagsFn,
      context: {},
      checkTerminateCallback: (n) =>
        n?.nodeType === NodeType.ELEMENT_NODE && (n as HTMLElement).getAttribute('data-luru-role') === 'note-element',
    })
    // for (
    //   let node = boundary.startNode?.nextSibling as Node;
    //   node && node !== boundary.endNode;
    //   node = node.nextSibling as Node
    // ) {
    // }
  }
}

export class Point {
  #x: number = 0
  #y: number = 0

  constructor(x: number, y: number) {
    this.#x = x
    this.#y = y
  }

  getCoordinates(): { x: number; y: number } {
    return { x: this.#x, y: this.#y }
  }

  getX(): number {
    return this.#x
  }

  getY(): number {
    return this.#y
  }

  set({ x, y }: { x: number; y: number }): Point {
    this.#x = x
    this.#y = y
    return this
  }

  add(obj: Point | { x: number; y: number } | null): Point {
    let x = 0,
      y = 0
    if (obj instanceof Point) {
      x = obj.getX()
      y = obj.getY()
    } else if (obj) {
      x = obj.x
      y = obj.y
    }
    return new Point(this.#x + x, this.#y + y)
  }

  subtract(obj: Point | { x: number; y: number } | null): Point {
    let x = 0,
      y = 0
    if (obj instanceof Point) {
      x = obj.getX()
      y = obj.getY()
    } else if (obj) {
      x = obj.x
      y = obj.y
    }
    return new Point(this.#x - x, this.#y - y)
  }
}
