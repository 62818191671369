import { RootState } from '../../../app/store'
import { ReduxNoteTemplatesStateTTL } from '../types'

export const isNoteTemplatesCacheValid = (state: RootState) =>
  !Boolean(state.noteTemplates.refreshedAt) ||
  new Date().valueOf() -
    (state.noteTemplates.refreshedAt
      ? new Date(state.noteTemplates.refreshedAt).valueOf()
      : 0) <
    ReduxNoteTemplatesStateTTL
