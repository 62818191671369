import React from 'react'
import CollapsibleSection from '../CollapsibleSection'
import styles from './ListItemChooserPopup.module.css'
import { ListItemChooserPopupProps } from './types'
import useComponentState from './useComponentState'
import useEventHandlers from './useEventHandler'
import { useAppSelector } from '@/app/hooks'
import LuruUser from '@/domain/users/LuruUser'
import { EntityStatus, LuruEntryPoint } from '@/app/types'
import LuruCopyText from '../LuruCopyText'
import Utils from '@/utils/Utils'
import LoadingSpinner from '@/primitives/LoadingSpinner'

const ListItemChooserPopup = React.forwardRef((props: ListItemChooserPopupProps, ref) => {
  const sharedState = useComponentState(props)
  const { state } = sharedState
  const selectedTemplateId = useAppSelector((state) => state.app.home.notes.noteDetails?.[props?.noteId]?.template_id)
  const selectedTemplateTitle = useAppSelector(
    (state) => state.noteTemplates.entities[selectedTemplateId ?? '']?.data?.title
  )
  const { ontoggleCollapsibleSection, onInsertTemplate } = useEventHandlers(sharedState, props, ref)

  return (
    <div className={Utils.classes(styles.parent, 'max-sm:!mt-0 max-sm:text-sm')}>
      {state.templateStatus === EntityStatus.Loading ? (
        <div className={styles.templateRefreshing}>
          {' '}
          <span>
            <LoadingSpinner size={8}></LoadingSpinner>
          </span>
          <span>Loading...</span>
        </div>
      ) : (
        <></>
      )}
      <CollapsibleSection
        title={selectedTemplateTitle || 'Playbooks'}
        open={state.isSectionOpened}
        onCollapse={ontoggleCollapsibleSection}
        onExpand={ontoggleCollapsibleSection}
        containerClassName={state.containerClassName}
        contentClassName={[styles.contentStyles]}
        label={state.label}
      >
        {state.templateStatus === EntityStatus.Loading ? (
          <></>
        ) : state.templates?.length <= 0 ? (
          [LuruEntryPoint.EMBEDDED_ZOOM_MEETING].includes(LuruUser.getCurrentEntryPoint()) ? (
            <div className={styles.parentMessage}>
              <div>
                <span>No playbooks yet? Create one at</span>
              </div>
              <div>
                <span>
                  <b>https://my.luru.app/settings/meeting_playbooks </b>
                </span>
                <span>
                  <LuruCopyText
                    positionForToolTip={['top', 'right']}
                    text='https://my.luru.app/settings/meeting_playbooks'
                  />
                </span>
              </div>
            </div>
          ) : (
            <div>
              No playbooks yet?{' '}
              <span className={styles.empty} onClick={props.handleRedirectPlaybook}>
                {' '}
                Click here
              </span>{' '}
              to create one
            </div>
          )
        ) : (
          <div className={styles.tiles}>
            {state.templates?.map?.((template) => (
              <li
                key={template?.template.template_id}
                className={[
                  styles.tile,
                  selectedTemplateId === template?.template.template_id
                    ? styles.selectedTile
                    : template.specificity === 0
                    ? styles.specificity0
                    : template.specificity === 1
                    ? styles.specificity1
                    : template.specificity === 2
                    ? styles.specificity2
                    : styles.specificityN,
                ].join(' ')}
                onClick={(e) => onInsertTemplate(e, template.template)}
                title={template?.template?.title}
              >
                {template?.template?.title}
              </li>
            ))}
          </div>
        )}
      </CollapsibleSection>
    </div>
  )
})

export default ListItemChooserPopup
