import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { EntityStatus } from '@/app/types'
import { useEffect } from 'react'
import { fetchCollection } from '../middleware/fetchCollection'

export default function useCollection(collectionId: string | undefined) {
  const dispatch = useAppDispatch()

  const collectionEntity = useAppSelector((state) =>
    collectionId ? state.collections.entities[collectionId] : undefined
  )

  useEffect(() => {
    if (collectionEntity?.status === EntityStatus.Loaded || !collectionId) {
      return
    }
    dispatch(fetchCollection.action({ collectionId }))
  }, [collectionId, dispatch, collectionEntity])

  return collectionEntity?.data
}
