import { EntityStatus } from '../../app/types'
import { LuruViewFilterRootTree } from '../views/types'

export type ReduxAppState = {
  home: AppHome
  noteTemplatesHome: AppNoteTemplatesHome
  meetingNotesHome: AppMeetingNotesHome
  tasks: AppTasks
  views?: {
    mruView?: {
      crmObjectNamePlural: string
      id: string
      filter?: LuruViewFilterRootTree
    }
  }
}

type AppHome = {
  notes: {
    lastLoadedNoteId: null | string
    createNoteStatus: EntityStatus
    noteDetails: {
      [key: string]: {
        template_id: string | null
        isEmpty: boolean
        noteBody: string
        dirtiedAfterInsertion?: boolean
        autoLinkedTemplateId?: string | null
      }
    }
  }

  search: {
    query: null | string
    luruNotes: ListOrSearchEntityResults
    sorNotes: ListOrSearchEntityResults
    upcomingMeetings: ListOrSearchEntityResults
  }
  default: {
    luruNotes: { draftNotes: Array<string> } & ListOrSearchEntityResults
    sorNotes: ListOrSearchEntityResults
    upcomingMeetings: ListOrSearchEntityResults
  }
}

type AppNoteTemplatesHome = {
  search: {
    query: null | string
    myTemplates: ListOrSearchEntityResults
    luruTemplates: ListOrSearchEntityResults
  }
}

type AppMeetingNotesHome = {
  search: {
    query: null | string
  }
}

type AppTasks = {
  default: {
    mytasks_open: ListOrSearchEntityResults
    mytasks_completed: ListOrSearchEntityResults
    notmytasks_open: ListOrSearchEntityResults
    notmytasks_completed: ListOrSearchEntityResults
  }
  search: {
    query: string | null
    mytasks_open: ListOrSearchEntityResults
    mytasks_completed: ListOrSearchEntityResults
    notmytasks_open: ListOrSearchEntityResults
    notmytasks_completed: ListOrSearchEntityResults
  }
}

interface ListOrSearchEntityResults {
  status: EntityStatus
  results: Array<string>
  error_data: null | { message: string }
  cursor: null | string
}

export const listOrSearchEmptyState: ListOrSearchEntityResults = {
  status: EntityStatus.Idle,
  results: [],
  error_data: null,
  cursor: null,
}

const tasksEmptyState = {
  mytasks_open: { ...listOrSearchEmptyState },
  mytasks_completed: { ...listOrSearchEmptyState },
  notmytasks_open: { ...listOrSearchEmptyState },
  notmytasks_completed: { ...listOrSearchEmptyState },
}

const appHomeEmptyState = {
  sorNotes: { ...listOrSearchEmptyState },
  luruNotes: { ...listOrSearchEmptyState },
  upcomingMeetings: { ...listOrSearchEmptyState },
}

export const EmptyAppState: ReduxAppState = {
  home: {
    notes: {
      lastLoadedNoteId: null,
      createNoteStatus: EntityStatus.Idle,
      noteDetails: {},
    },
    default: {
      ...appHomeEmptyState,
      luruNotes: {
        ...listOrSearchEmptyState,
        draftNotes: [],
      },
    },
    search: {
      query: null,
      ...appHomeEmptyState,
    },
  },
  meetingNotesHome: {
    search: {
      query: null,
    },
  },
  noteTemplatesHome: {
    search: {
      query: null,
      myTemplates: { ...listOrSearchEmptyState },
      luruTemplates: { ...listOrSearchEmptyState },
    },
  },
  tasks: {
    search: {
      query: null,
      ...tasksEmptyState,
    },
    default: { ...tasksEmptyState },
  },
}
