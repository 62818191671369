import { PayloadAction } from '@reduxjs/toolkit'
import { EmptyReduxChatState, ReduxChatState } from '../types'

export const invalidateChatUsersCache = (
  state: ReduxChatState,
  action: PayloadAction<undefined>
) => {
  state.userEntities = { ...EmptyReduxChatState.userEntities }
  state.status.users = EmptyReduxChatState.status.users

  if (!state.refreshedAt) {
    state.refreshedAt = { ...EmptyReduxChatState.refreshedAt }
  } else {
    state.refreshedAt = {
      ...state.refreshedAt,
      users: EmptyReduxChatState.refreshedAt.users,
    }
  }
}
