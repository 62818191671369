import React from 'react'
import { EntityStatus } from '../../app/types'
import PopupMenu from '../PopupMenu'
import TypeAheadSearchBox from '../TypeAheadSearchBox'
import TypeAheadSearchSelectBoxEventHandler from './TypeAheadSearchSelectBoxEventHandler'

import styles from './styles.module.css'
// Icons
import deleteIcon from '../../images/fluent/delete.svg'

export interface TypeAheadSearchSelectBoxProps {
  // An optional id
  id?: string
  // An optional preset value
  value?: string
  // Disable flag
  disabled?: boolean
  // Stacking anchor
  menuParentSelector?: string
  // Should menu be aligned left with the button
  leftAlign?: boolean
  // Additional class name if required
  className?: string
  // Additional class name if required
  searchItemsContainerClassName?: string
  // Additional class name if required
  searchItemClassName?: string
  // Optional placeholder
  placeholder?: string
  // Optional Luru role
  luruRole?: string
  // Search results provider
  searchProvider: (_: string) => Promise<Array<{ key: string; name: string; icon?: string }>>
  // Optional footer
  footer?: React.ReactNode
  //// Events
  // On item picked
  onSelectItem: (key: string, label: string, data?: any) => void
  // Optionally, on item deleted
  onDeleteItem?: () => void
}

export interface TypeAheadSearchSelectBoxState {
  currentResults: Array<{
    key: string
    name: string
    icon?: string
    data?: any
  }>
  searchText: string
  searchStatus: EntityStatus
  committedValue: string
  highlightedIndex: number
}

interface TypeAheadSearchSelectBoxRefs {
  list: React.RefObject<HTMLUListElement>
  menu: React.RefObject<PopupMenu>
  searchBox: React.RefObject<HTMLSpanElement>
  inputSearchBox: React.RefObject<HTMLInputElement>
}

export default class TypeAheadSearchSelectBox extends React.Component<
  TypeAheadSearchSelectBoxProps,
  TypeAheadSearchSelectBoxState
> {
  props: TypeAheadSearchSelectBoxProps
  state: TypeAheadSearchSelectBoxState
  eventHandler: TypeAheadSearchSelectBoxEventHandler
  componentRefs: TypeAheadSearchSelectBoxRefs
  committedValue: string

  constructor(props: TypeAheadSearchSelectBoxProps) {
    super(props)
    this.props = props
    this.state = this.getEmptyState()
    this.eventHandler = new TypeAheadSearchSelectBoxEventHandler(this)
    this.componentRefs = {
      list: React.createRef(),
      menu: React.createRef(),
      searchBox: React.createRef(),
      inputSearchBox: React.createRef(),
    }
    this.committedValue = this.props.value ?? ''
  }

  getEmptyState(): TypeAheadSearchSelectBoxState {
    return {
      currentResults: [],
      searchText: this.props.value ?? '',
      searchStatus: EntityStatus.Idle,
      committedValue: this.props.value ?? '',
      highlightedIndex: -1,
    }
  }

  focus() {
    this.componentRefs?.inputSearchBox?.current?.focus?.()
  }

  render() {
    return (
      <PopupMenu
        ref={this.componentRefs.menu}
        id={this.props.id}
        // disabled={this.props.disabled}
        menuParentSelector={this.props.menuParentSelector}
        leftalign={this.props.leftAlign}
        hideOnMenuClick={true}
        className={this.props.className}
        items={this.#renderItems()}
      >
        {this.#renderTypeAheadSearchBox()}
      </PopupMenu>
    )
  }

  #renderTypeAheadSearchBox() {
    return (
      <span ref={this.componentRefs.searchBox} className={styles.container}>
        <TypeAheadSearchBox
          key={this.state.committedValue}
          ref={this.componentRefs.inputSearchBox}
          // @ts-ignore
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          luruRole={this.props.luruRole}
          ariaLabel={this.props.placeholder}
          searchText={this.state.searchText}
          onFocus={this.eventHandler.handlers.onFocus}
          onBlur={this.eventHandler.handlers.onBlur}
          onKeyDown={this.eventHandler.handlers.onSearchBoxKeyDown}
          onTriggerSearch={this.eventHandler.handlers.onTriggerSearch}
          onClearSearch={this.eventHandler.handlers.onClearSearch}
          loading={this.state.searchStatus === EntityStatus.Loading}
        />
        {this.props.onDeleteItem && this.state.searchStatus !== EntityStatus.Loading && !this.props.disabled ? (
          <img
            src={deleteIcon}
            alt='delete'
            className={styles.clearIcon}
            onClick={this.eventHandler.handlers.onDeleteItem}
          />
        ) : null}
      </span>
    )
  }

  #renderItems() {
    if (this.state.searchStatus === EntityStatus.Loading) {
      return <div className={styles.loading}>Loading...</div>
    }

    if (!this.state.currentResults || this.state.currentResults?.length === 0) {
      return this.state.searchText !== '' && this.state.searchText !== this.state.committedValue ? (
        <div className={styles.noResults}>No results</div>
      ) : null
    }

    var style = {
      height: `${Math.min(this.state.currentResults.length, 5) * (0.6 * 2 + 1.5)}em`,
    }
    var clickHandler = this.eventHandler.handlers.onChooseItem
    var highlightedIx = this.state.highlightedIndex

    return (
      <>
        <ul
          className={[styles.list, this.props.searchItemsContainerClassName].join(' ')}
          style={style}
          ref={this.componentRefs.list}
        >
          {this.state.currentResults.map((r, ix) => (
            <li
              key={r.key}
              data-highlighted={ix === highlightedIx ? 'yes' : 'no'}
              onClick={() => clickHandler(this.state.currentResults[ix].key)}
              className={[this.props.searchItemClassName].join(' ')}
            >
              {r.icon ? <img src={r.icon} alt={r.name} /> : null}
              <span>{r.name}</span>
            </li>
          ))}
        </ul>
        {this.props.footer ?? null}
      </>
    )
  }
}
