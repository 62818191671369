// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { listOrSearchAssignees as listOrSearchAssigneesAPI } from '../api/listOrSearchAssignees'
import { ReduxTasksState, TaskAssignee } from '../types'

const requestName = 'tasks/listOrSearchAssignees'

export type SearchTaskAssigneesParameter = {
  q?: string
  cursor?: string
  limit?: number
}

export interface SearchTaskAssigneesResponse extends LuruAPIResponse {
  data: Array<TaskAssignee>
}

export const listOrSearchAssignees = {
  action: createAsyncThunk<
    SearchTaskAssigneesResponse['data'],
    SearchTaskAssigneesParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | SearchTaskAssigneesResponse['metadata']
      rejectedMeta: null | SearchTaskAssigneesResponse['metadata']
    }
  >(
    requestName,
    async (params, { fulfillWithValue, rejectWithValue, signal }) => {
      try {
        var response = (await listOrSearchAssigneesAPI(params, {
          signal,
        })) as SearchTaskAssigneesResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(listOrSearchAssignees.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(listOrSearchAssignees.action.fulfilled, (state, action) => {
      for (let assignee of action.payload) {
        let id = assignee.sor_object_name + '__' + assignee.sor_record_id
        state.assignees.entities[id] = {
          data: assignee,
          status: EntityStatus.Loaded,
        }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(
      listOrSearchAssignees.action.rejected,
      (state, action) => {}
    )
  },

  // TODO: We can do away with this after standardization
  addAllCases(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    listOrSearchAssignees.addPendingCase(builder)
    listOrSearchAssignees.addRejectedCase(builder)
    listOrSearchAssignees.addFulfilledCase(builder)
  },
}
