import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CreateActivityLogParameter } from '../middleware/createActivityLog'
import { CrmActivityLogType } from '../types'

export const createActivityLog = ({ payload, type }: CreateActivityLogParameter, options?: LuruAPIRequestOptions) => {
  var url = type === CrmActivityLogType.Call ? `/api/activities/calls` : `/api/activities/meetings`
  var request = new LuruAPIRequest(url, HTTPMethod.POST, options)

  request.setBody(payload)
  return request.make()
}
