// React
import React, { ReactNode } from 'react'
import ReactDom from 'react-dom'
import DomUtils from '../coreEditor/utils/DomUtils'
// import React, { useRef } from 'react'

// Custom hooks
// import { useOutsideClick } from '../hooks/luru_hooks'
import { Point } from '../coreEditor/utils/DomUtils'

// Styles
import styles from './css/PopupMenu.module.css'

// Users of this component can mix areas where click hides menu with areas
// where click won't hide menu (provided hideOnMenuClick='false').  The negative
// areas can be demarcated with the following selector at the root of the area
const forceHideSelector = '[data-popup-menu-force-hide="true"]'

export interface PopupMenuProps {
  // An optional id
  id?: string
  // Disable showModal
  disabled?: boolean
  // Stacking anchor
  menuParentSelector?: string
  // Should menu be aligned left with the button
  leftalign?: boolean
  // Should menu hide on click inside the menu
  hideOnMenuClick?: boolean
  // Additional class name on button if required
  className?: string
  // Optional class name for popped up menu
  popupMenuClassName?: string
  // Optional Luru role
  luruRole?: string
  // What is displayed to be clicked (typically a button)
  children: any
  // What is displayed inside the menu
  items: any
  // Force display popup below button/element
  forceBottomPosition?: boolean
  // Optional width
  width?: number

  // Is parent height is variable, if so we need to adjust the position top value of the menu element
  isGrowingParent?: boolean
  // Function to callback on show menu
  onShowMenu?: () => void
  // Function to callback on hide menu
  onHideMenu?: () => void
}

export interface PopupMenuRefs {
  controlRef: React.RefObject<HTMLDivElement>
  menuRef: React.RefObject<HTMLDivElement>
}

export default class PopupMenu extends React.Component<PopupMenuProps> {
  props: PopupMenuProps
  componentRefs: PopupMenuRefs
  menuContainerEl: HTMLDivElement

  constructor(props: PopupMenuProps) {
    super(props)
    this.props = props
    this.componentRefs = {
      controlRef: React.createRef(),
      menuRef: React.createRef(),
    }
    this.menuContainerEl = document.createElement('div')
  }

  toggleMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    var clickedElem = e.target as HTMLElement
    var forceHide = Boolean(clickedElem?.closest?.(forceHideSelector))
    var menuElem = this.componentRefs.menuRef?.current

    if (menuElem) {
      let menuVisible = menuElem.classList.contains(styles.menuVisible)
      let hideOnMenuClick = this.props.hideOnMenuClick ?? true

      if (!menuVisible) {
        this.showMenu()
      } else if (hideOnMenuClick || forceHide) {
        menuElem.classList.remove(styles.menuVisible)
        this.props.onHideMenu?.()
      }
    }
  }

  isMenuVisible = () => this.componentRefs.menuRef?.current?.classList.contains(styles.menuVisible)

  showMenu = () => {
    if (this.props.disabled) {
      return
    }

    var menuElement = this.componentRefs.menuRef?.current
    var menuCoords = new Point(0, 0)

    if (menuElement) {
      // Screen coordinates
      let controlElement = this.componentRefs.controlRef.current ?? undefined

      if (this.props.menuParentSelector) {
        let p = DomUtils.getScrollAdjustedElementCoordinates(
          controlElement,
          document.querySelector(this.props.menuParentSelector) as HTMLElement
        )

        if (menuElement && p) {
          var triggerElement = controlElement?.firstElementChild as HTMLElement
          var yOffset = triggerElement ? triggerElement.offsetHeight : 0

          menuCoords = menuCoords.add({
            x: p.getX(),
            y: p.getY() + yOffset,
          })
        }
      } else {
        menuCoords = menuCoords.add({
          x: 0,
          y: controlElement?.clientHeight ?? 0,
        })
      }

      // Decide whether to position above or below
      let controlCoords = DomUtils.getElementScreenCoordinates(controlElement) as Point

      let verticalOverflowDistance =
        controlCoords.getY() +
        (controlElement?.clientHeight ?? 0) +
        menuElement.clientHeight -
        document.documentElement.clientHeight

      if (verticalOverflowDistance > 0) {
        if (this.props.forceBottomPosition) {
          menuElement.dataset.originalHeight = `${menuElement.clientHeight}`
          menuElement.dataset.originalHeightBottom = `${menuElement.clientHeight - verticalOverflowDistance - 10}`
          menuElement.style.height = menuElement.clientHeight - verticalOverflowDistance - 10 + 'px'
          menuElement.style.overflow = 'auto'
        } else {
          // There is an overflow below the screen
          menuCoords = menuCoords.subtract({
            x: 0,
            y: menuElement.clientHeight + (controlElement?.clientHeight ?? 0),
          })
          menuElement.style.overflow = 'visible'
        }
      } else {
        // Else clause is required because user may show menu after changing
        // zoom levels
        if (this.props.forceBottomPosition) {
          menuElement.style.overflow = 'scroll'

          if (menuElement.dataset.originalHeight) {
            menuElement.style.height = parseInt(menuElement.dataset.originalHeightBottom ?? '250') + 'px'
          }
        } else {
          menuElement.style.overflow = 'visible'

          if (menuElement.dataset.originalHeight) {
            menuElement.style.height = parseInt(menuElement.dataset.originalHeight) + 'px'
          }
        }
      }

      if (!this.props.isGrowingParent) {
        // Only add style top value if the parent is not growing
        menuElement.style.top = menuCoords.getY() + 'px'
      }

      if (this.props.menuParentSelector) {
        menuElement.style.left = menuCoords.getX() + 'px'
        menuElement.style.minWidth = (this.props.width ?? controlElement?.clientWidth) + 'px'
        menuElement.style.width = 'fit-content'
        menuElement.style.maxWidth = '400px'
      }
      menuElement.classList.add(styles.menuVisible)
      this.props.onShowMenu?.()
    }
  }

  hideMenu = () =>
    setTimeout(() => {
      this.componentRefs.menuRef?.current?.classList.remove(styles.menuVisible)
      this.props.onHideMenu?.()
    })

  processClick = (e: any) => {
    var event = e as MouseEvent
    var clickedElement = event.target
    var controlElement = this.componentRefs.controlRef.current
    var menuElement = this.componentRefs.menuRef.current

    if (
      controlElement &&
      !controlElement.contains(clickedElement as Node) &&
      menuElement &&
      !menuElement.contains(clickedElement as Node)
    ) {
      this.hideMenu()
    }
  }

  componentDidMount = () => {
    document.body.addEventListener('mousedown', this.processClick)

    if (this.props.menuParentSelector) {
      let parentEl = document.querySelector(this.props.menuParentSelector)

      parentEl?.appendChild(this.menuContainerEl)
    }
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('mousedown', this.processClick)

    if (this.props.menuParentSelector) {
      let parentEl = document.querySelector(this.props.menuParentSelector)
      if (parentEl && this.menuContainerEl && parentEl.contains(this.menuContainerEl)) {
        parentEl?.removeChild?.(this.menuContainerEl)
      }
    }
  }

  #renderWithStackingAnchor = () => (
    <Portal container={this.menuContainerEl}>{this.#renderWithoutStackingAnchor()}</Portal>
  )

  #renderWithoutStackingAnchor = () => (
    // <div style={{ overflow: 'hidden' }}>
    <div
      className={[
        styles.menu,
        this.props.leftalign ?? true ? styles.leftAligned : styles.rightAligned,
        this.props.popupMenuClassName ?? null,
      ].join(' ')}
      ref={this.componentRefs.menuRef}
      id={this.props.id}
      data-role='popup-menu'
    >
      {this.props.items}
    </div>
    // </div>
  )

  render = () => (
    <div
      className={[styles.parent, this.props.className ?? ''].join(' ')}
      onClick={this.toggleMenu}
      ref={this.componentRefs.controlRef}
      data-luru-role='luru-popup-menu'
      // style={{ overflow: 'visible' }}
    >
      {this.props.children}
      {this.props.menuParentSelector ? this.#renderWithStackingAnchor() : this.#renderWithoutStackingAnchor()}
    </div>
  )
}

const Portal = ({ container, children }: { container: HTMLElement; children: ReactNode }) =>
  ReactDom.createPortal(children, container)
