import { JottingType } from '../../notes/types'

export const getDefaultNoteTemplate = () => ({
  title: 'New Meeting Playbook',
  body: [
    {
      type: JottingType.P,
      data: '',
    },
  ],
})
