import copyIcon from '@/images/fluent/copy_content.svg'
import { useCallback, useState } from 'react'
import doneIcon from '@/images/fluent/done.svg'
import styles from '@/primitives/ui/LuruCopyText/LuruCopyText.module.css'
import Utils from '@/utils/Utils'
import Tooltip from '@/components/Tooltip'

interface LuruCopyTextProps {
  text: string
  positionForToolTip?: Array<'top' | 'right' | 'bottom' | 'left' | 'middle'>
}

export default function LuruCopyText({ text, positionForToolTip }: LuruCopyTextProps) {
  const [copied, setCopied] = useState<boolean>(false)

  const handleCopy = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()

      Utils.copyTextToClipboard(text, () => {
        setCopied(true)

        setTimeout(() => {
          setCopied(false)
        }, 2000)
      })
    },
    [text]
  )

  return (
    <div>
      {!copied ? (
        <div onClick={handleCopy}>
          <img src={copyIcon} alt='copy' />
        </div>
      ) : (
        <Tooltip label='Copied!'>
          <div className={styles.copiedIcon}>
            <img src={doneIcon} alt='copied!' />
          </div>
        </Tooltip>
      )}
    </div>
  )
}
