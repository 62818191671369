import TypeAheadSearchSelectBox from '.'
import { EntityStatus } from '../../app/types'

interface TypeAheadSearchSelectBoxEvents {
  onSearchBoxKeyDown: (e: KeyboardEvent) => void
  onTriggerSearch: (e: KeyboardEvent) => void
  onClearSearch: () => void
  onNavigateUp: () => void
  onNavigateDown: () => void
  onFocus: (e: FocusEvent) => void
  onBlur: (e: FocusEvent) => void
  onChooseItem: (key: string) => void
  onDeleteItem: () => void
}

export default class TypeAheadSearchSelectBoxEventHandler {
  #component: TypeAheadSearchSelectBox
  handlers: TypeAheadSearchSelectBoxEvents
  #restoreValueTimerId: NodeJS.Timeout | null = null

  constructor(component: TypeAheadSearchSelectBox) {
    this.#component = component
    this.#component.componentDidUpdate = this.#onComponentUpdated.bind(this)
    this.handlers = {
      onSearchBoxKeyDown: this.#onSearchBoxKeyDown.bind(this),
      onTriggerSearch: this.#onTriggerSearch.bind(this),
      onClearSearch: this.#onClearSearch.bind(this),
      onNavigateUp: this.#onNavigateUp.bind(this),
      onNavigateDown: this.#onNavigateDown.bind(this),
      onFocus: this.#onFocus.bind(this),
      onBlur: this.#onBlur.bind(this),
      onChooseItem: this.#onChooseItem.bind(this),
      onDeleteItem: this.#onDeleteItem.bind(this),
    }
  }

  #onComponentUpdated() {
    this.#navigateToSelectedItem()
  }

  async #onTriggerSearch(e: KeyboardEvent) {
    e.preventDefault()

    var searchElem = e.target as HTMLInputElement
    var searchText = searchElem.value

    if (searchText === this.#component.state.searchText) {
      return
    }

    this.#component.setState({
      searchStatus: EntityStatus.Loading,
      searchText,
    })

    try {
      let results = await this.#component.props.searchProvider(searchElem.value)

      this.#component.setState({
        searchStatus: EntityStatus.Loaded,
        currentResults: results,
        highlightedIndex: 0,
      })

      if (!this.#component.componentRefs.menu.current?.isMenuVisible()) {
        this.#component.componentRefs.menu.current?.showMenu()
      }
    } catch (e) {
      this.#component.setState({ searchStatus: EntityStatus.ErrorLoading })
    }
  }

  #onSearchBoxKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault()
        this.#onNavigateDown()
        break

      case 'ArrowUp':
        e.preventDefault()
        this.#onNavigateUp()
        break

      case 'Enter':
        let chosenItem = this.#component?.state?.currentResults?.[this.#component?.state?.highlightedIndex]

        if (!chosenItem) {
          break
        }

        e.preventDefault()
        this.#component.componentRefs.menu.current?.hideMenu?.()

        let container = this.#component.componentRefs.searchBox.current
        let searchBox = container?.querySelector?.('input[type="search"]') as HTMLInputElement

        this.#onChooseItem(chosenItem.key)

        if (searchBox) {
          setTimeout(() => {
            searchBox.value = chosenItem.name
            searchBox.blur()
          }, 50)
        }
    }
  }

  #onNavigateUp() {
    var nextIndex =
      this.#component.state.highlightedIndex <= 0
        ? this.#component.state.currentResults.length - 1
        : this.#component.state.highlightedIndex - 1

    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #onNavigateDown() {
    var nextIndex =
      this.#component.state?.highlightedIndex >= this.#component.state?.currentResults?.length - 1
        ? 0
        : this.#component.state.highlightedIndex + 1
    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #navigateToSelectedItem() {
    var ulElement = this.#component.componentRefs.list.current as HTMLUListElement
    var liElement = ulElement?.querySelector('li[data-highlighted="yes"]') as HTMLLIElement

    if (liElement) {
      let topOverflow = Math.max(0, ulElement.scrollTop + liElement.offsetHeight - liElement.offsetTop)
      let bottomOverflow = Math.max(
        0,
        liElement.offsetTop + liElement.offsetHeight - ulElement.scrollTop - ulElement.offsetHeight
      )
      if (topOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: -topOverflow, behavior: 'instant' })
      } else if (bottomOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: bottomOverflow, behavior: 'instant' })
      }
    }
  }

  #onClearSearch() {
    this.#component.setState({
      searchStatus: EntityStatus.Idle,
      searchText: '',
      currentResults: [],
      highlightedIndex: -1,
    })
    this.#component.props?.onSelectItem?.('', '', {})
  }

  #onFocus(e: FocusEvent) {
    var inputElement = e.target as HTMLInputElement
    inputElement.value = ''
  }

  #onBlur(e: FocusEvent) {
    var inputElement = e.target as HTMLInputElement

    // Simple restore value
    if (!this.#component.props.onDeleteItem) {
      inputElement.value = this.#component.state.committedValue
      return
    }

    // We have a delete handler, so we need to check if blur is due to delete
    if (this.#restoreValueTimerId) {
      clearTimeout(this.#restoreValueTimerId)
    }

    this.#restoreValueTimerId = setTimeout(() => this.#restoreValue(inputElement), 200)
  }

  #onChooseItem(key: string) {
    if (this.#restoreValueTimerId) {
      clearTimeout(this.#restoreValueTimerId)
    }

    var label = this.#component.state.currentResults?.find((f) => f.key === key)?.name ?? ''
    var data = this.#component.state.currentResults?.find?.((f) => f.key === key)?.data
    var restoreValue = this.#component.state.committedValue !== label

    this.#component.committedValue = label
    this.#component.setState(
      {
        searchText: label,
        committedValue: label,
        currentResults: [],
      },
      () => {
        if (restoreValue) {
          let inputElement = this.#component.componentRefs.inputSearchBox.current
          if (inputElement) {
            inputElement.value = label
          }
        }
        this.#component.props.onSelectItem(key, label, data)
      }
    )
  }

  #onDeleteItem() {
    if (!this.#component.props.onDeleteItem || this.#component.props.disabled) {
      return
    }

    if (this.#restoreValueTimerId) {
      clearTimeout(this.#restoreValueTimerId)
    }

    this.#component.setState(
      {
        searchText: '',
        committedValue: '',
        currentResults: [],
      },
      () => this.#component.props.onDeleteItem?.()
    )
  }

  #restoreValue(inputElement: HTMLInputElement) {
    // console.log(
    //   `restoreValue:committedValue:`,
    //   this.#component.state.committedValue
    // )
    inputElement.value = this.#component.state.committedValue
    this.#restoreValueTimerId = null
  }
}
