import { CaretPosition } from '../EditorDOM'

/**
 * @classdesc Class to handle updating a CRM field - Currently only handling
 * the checkboxes as other fields are handled by selection handler
 */
export default class CanvasEventsHandler {
  // Computations

  /**
   * Compute if and how canvas click event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]

    if (lastEvent?.sourceEvent?.type !== 'click') {
      return null
    }

    return {
      do: (view, controller) => {
        const editorRoot = view?.getEditorRootElement()
        const canvas = editorRoot?.parentElement
        const editorRootRect = {
          top: editorRoot?.offsetTop,
          left: editorRoot?.offsetLeft,
          bottom: editorRoot?.offsetTop + editorRoot?.offsetHeight,
          right: editorRoot?.offsetLeft + editorRoot?.offsetWidth,
        }
        const clickPoint = {
          x: lastEvent.sourceEvent.clientX - canvas?.offsetLeft,
          y: lastEvent.sourceEvent.clientY - canvas?.offsetTop - 18, //margin-top
        }
        const areaOfInterest = {
          top: editorRootRect.bottom,
          left: editorRootRect.left,
          bottom: canvas?.offsetTop + canvas?.offsetHeight + 18, //margin-bottom
          right: editorRootRect.right,
        }
        // console.log(`CanvasEventHandler:`, lastEvent.sourceEvent.target)
        if (
          lastEvent.sourceEvent.target.id.startsWith('canvas-') &&
          clickPoint.x >= areaOfInterest.left &&
          clickPoint.x <= areaOfInterest.right &&
          clickPoint.y >= areaOfInterest.top &&
          clickPoint.y <= areaOfInterest.bottom
        ) {
          try {
            const notes = Array.from(view.getNoteElementsList())
            const lastNote = notes[notes.length - 1]
            view.setCaretAt(lastNote, CaretPosition.END_OF_NOTE)
          } catch (e) {
            console.warn(e)
          }
        }
      },
    }
  }
}
