import type { RootState } from '../../../../app/store'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { LuruCollectionsQuickAccessBarOwnProps } from './LuruCollectionsQuickAccessBarComponent'
import {
  CollectionVisibilityEnum,
  ReduxCollectionEntity,
} from '../../../../features/collections/types'
import { EntityStatus } from '../../../../app/types'

export default function getReduxState(
  state: RootState,
  ownProps: LuruCollectionsQuickAccessBarOwnProps
) {
  var sorObjectName = CrmRecord.getCrmRecordName(ownProps.crmRecordType)
  var entities = state.collections.entities
  var collectionsNotLoaded = state.collections.status !== EntityStatus.Loaded
  var collections = Object.values(entities ?? {})
    .filter(
      (entity) =>
        entity.data?.sor_object_name === sorObjectName &&
        entity.data?.visibility === CollectionVisibilityEnum.VISIBLE
    )
    .sort((entity1, entity2) =>
      new Date(
        entity1.data?.used_at ?? entity1.data?.updated_at ?? new Date()
      ) >
      new Date(entity2.data?.used_at ?? entity2.data?.updated_at ?? new Date())
        ? -1
        : 1
    )
    .map((entity) => entity.data) as Array<ReduxCollectionEntity>

  let recentCollections = collections?.slice?.(0, 4)

  return {
    collections: collections,
    recentCollections: recentCollections,
    collectionsStatus: state.collections.status,
    collectionsNotLoaded,
  }
}
