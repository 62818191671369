import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { GetSorUsersParameter } from '../middleware/getSorUsers'

export const getSorUsers = async (params: GetSorUsersParameter, options: LuruAPIRequestOptions) => {
  var urlParams = new URLSearchParams()
  if (params.include_groups) {
    urlParams.append('include_groups', 'true')
  }
  if (params.include_user_admin_tasks) {
    urlParams.append('include_user_admin_tasks', 'true')
  }
  if (params.force) {
    urlParams.append('force', 'true')
  }

  var request = new LuruAPIRequest(
    '/api/sor/users' + (Array.from(urlParams.entries()).length ? '?' + urlParams.toString() : ''),
    HTTPMethod.GET,
    options
  )

  return request.make()
}
