import { PayloadAction } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { EmptyNoteTemplatesState, ReduxNoteTemplatesState } from '../types'

export const invalidateCache = (
  state: ReduxNoteTemplatesState,
  action: PayloadAction<undefined>
) => {
  state.entities = { ...EmptyNoteTemplatesState.entities }
  state.refreshedAt = undefined
  state.status = EntityStatus.NotLoaded
  state.error = undefined
}
