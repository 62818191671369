import React from 'react'
import { AppComponents } from './typings'
import CrmRecord from '../../domain/crmRecord/CrmRecord'

/**
 * A container for components used across the webapp, like tooltip, alert box,
 * input dialog, etc.
 */
var crmPrimaryRecordType = CrmRecord.getAllPrimaryObjects()

const AppComponentsContext = React.createContext<AppComponents>({
  key: undefined,
  editLinkDialog: undefined,
  noteShareDialog: undefined,
  noteTemplateShareDialog: undefined,
  createRecordDialog: {
    [crmPrimaryRecordType.DEAL]: undefined,
    [crmPrimaryRecordType.ACCOUNT]: undefined,
    [crmPrimaryRecordType.CONTACT]: undefined,
    [crmPrimaryRecordType.LEAD]: undefined,
  },
  createSearchRecordDialog: undefined,
  taskViewModal: undefined,
  omnibox: undefined,
  largeTextInput: undefined,
  createCollectionDialog: undefined,
  emailConnectModal: undefined,
  connectCalendarModal: undefined,
  layoutSettings: { hamburgerOpen: true },
})

export default AppComponentsContext
