import json5 from 'json5'
import NoteShareDialogComponent from './NoteShareDialogComponent'
import Utils from '../../../../utils/Utils'
import { HtmlConvertor } from '../../../../utils/htmlConvertor'
import { MarkdownConvertor } from '../../../../utils/markdownConvertor'
import LuruUser from '../../../../domain/users/LuruUser'
import { trackEvent } from '../../../../analytics/Ga'
import { EntityStatus } from '../../../../app/types'

interface NoteShareDialogEvents {
  onClickRemoveEmail: (email: string) => void
  onChooseUser: (user: { userId: string; name: string; email: string }) => void
  onClickCopyShareableLink: () => void
  onClickCopyNoteContent: () => void
  onClickShareEmailButton: () => void
  onNoteEmailShareSuccess: () => void
  onNoteEmailShareError: () => void
  onClose: () => void
}

export default class NoteShareDialogEventHandler {
  handlers: NoteShareDialogEvents
  #component: NoteShareDialogComponent

  constructor(component: NoteShareDialogComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount
    this.#component.componentWillUnmount = this.#onComponentUnmount

    this.handlers = {
      onClickRemoveEmail: this.#onClickRemoveEmail,
      onChooseUser: this.onChooseUser,
      onClickCopyShareableLink: this.#onClickCopyShareableLink,
      onClickCopyNoteContent: this.#onClickCopyNoteContent,
      onClickShareEmailButton: this.#onClickShareEmailButton,
      onNoteEmailShareSuccess: this.#onNoteEmailShareSuccess,
      onNoteEmailShareError: this.#onNoteEmailShareError,
      onClose: this.#onClose,
    }
  }

  #onComponentMount = () => {}

  #onComponentUnmount = () => {}

  // Remove the email-ids from state when the modal is closed
  #onClose = () =>
    this.#component.setState({
      emails: [],
    })

  #onClickShareEmailButton = () => {
    trackEvent('share_note', this.#component.state.emails.length ? 'team' : 'individual')

    this.#component.setState({
      shareEmailProgressState: EntityStatus.Loading,
    })

    this.#component.props
      .shareNote(this.#component.state.noteId, this.#component.state.emails)
      .then(this.handlers.onNoteEmailShareSuccess)
      .catch(this.handlers.onNoteEmailShareError)
  }

  #onNoteEmailShareSuccess = () => {
    this.#component.setState({
      shareEmailProgressState: EntityStatus.Loaded,
    })

    let closeDialog = () => {
      this.#component.setState({
        shareEmailProgressState: EntityStatus.Idle,
      })
      this.#component.componentRefs.modal.current?.cancel()
    }
    closeDialog = closeDialog.bind(this)

    setTimeout(closeDialog, 2000)
  }

  #onNoteEmailShareError = () => {
    this.#component.setState({
      shareEmailProgressState: EntityStatus.ErrorLoading,
      emails: [],
      currentEmailText: '',
    })

    setTimeout(
      () =>
        this.#component.setState({
          shareEmailProgressState: EntityStatus.Idle,
        }),
      2000
    )
  }

  onChooseUser = (user: { userId: string; name: string; email: string }) => this.#addToEmailList(user.email)

  #addToEmailList = (email: string) => {
    let emails: string[] = this.#component.state.emails

    if (!this.#component.state.emails.includes(email)) {
      this.#component.setState({
        emails: emails.concat(email),
      })
    }

    this.#component.setState({ currentEmailText: '' })
  }

  #onClickRemoveEmail = (email: string) => {
    this.#component.setState({
      emails: this.#component.state.emails.filter((e) => e !== email),
    })
  }

  #onClickCopyShareableLink = () => {
    let afterCopy = () => {
      let copyButton: HTMLButtonElement | null = this.#component.componentRefs.copyButton.current

      if (copyButton) {
        let button = copyButton
        button.textContent = 'Copied!'
        setTimeout(() => {
          button.textContent = 'Copy'
        }, 2000)
      }
    }

    afterCopy = afterCopy.bind(this)

    Utils.copyTextToClipboard(
      LuruUser.isWebappContext() ? window.location.href : LuruUser.getTenantDomain() + window.location.pathname,
      afterCopy
    )
    trackEvent('note_copy_to_clipboard', '')
  }

  #onClickCopyNoteContent = async () => {
    let afterCopy = () => {
      let copyButton: HTMLButtonElement | null = this.#component.componentRefs.copyButton.current

      if (copyButton) {
        let button = copyButton
        button.textContent = 'Copied!'
        setTimeout(() => {
          button.textContent = 'Copy note to clipboard'
          this.#component.componentRefs.modal.current?.cancel()
        }, 1000)
      }
    }
    afterCopy = afterCopy.bind(this)

    this.#component.props.readNoteBody(this.#component.state.noteId).then((noteBody) => {
      var userFirstName = this.#component.props.userFirstName
      var userLastName = this.#component.props.userLastName
      var name =
        userFirstName !== null && userFirstName !== undefined && userFirstName.length > 0 ? userFirstName : userLastName

      // @ts-ignore
      var jsonBody = json5.parse(noteBody)
      var fields = this.#component.getFieldsOfConnectedNote()
      jsonBody = this.#component.getNewFormattedJsonBody(jsonBody, fields)
      var noteTitle = this.#component.getNoteId()
      var meetingId = this.#component.getMeetingId()?.sor_record_id;
      var htmlBody = HtmlConvertor.convertLuruNoteToHtmlWithFooter(jsonBody, name, noteTitle, meetingId)
      var textBody = MarkdownConvertor.convertLuruNoteToMarkdownWithFooter(jsonBody, name, noteTitle, meetingId)

      var clipboardContent = {
        'text/html': htmlBody,
        'text/plain': textBody,
      }
      Utils.copyToClipboard(clipboardContent, afterCopy)
    })
  }
}
