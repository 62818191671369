import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CreateNoteConnectionParameter } from '../middleware/createNoteConnection'

export default async function createNoteConnection(
  { noteId, sorObject, extraData }: Readonly<CreateNoteConnectionParameter>,
  options: LuruAPIRequestOptions
) {
  var request = new LuruAPIRequest(`/api/notes/${noteId}/connections`, HTTPMethod.POST)

  request.setBody({ ...sorObject, ...extraData })

  return request.make()
}
