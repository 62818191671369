import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { NotesMiddleware } from '../../../features/notes/middleware'
import { getNewNoteContent } from '../../../features/notes/helpers/getNewNoteContent'
import { EMBEDDED_NOTE_HOME } from '../../../entryPoints/EmbeddedNoteApp/EmbeddedNoteAppComponent'
import LuruUser from '../../../domain/users/LuruUser'
import { LuruEntryPoint } from '../../../app/types'
import styles from '../css/MeetingNoteChooser.module.css'
import luruLogo from '../../../images/luru/logoMain.svg'
import plusIcon from '../../../images/plus-icon.svg'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export default function MeetingNoteChooser({ noteList, calendarId }) {
  const { showToast } = useLuruToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const entryPoint = LuruUser.getCurrentEntryPoint()

  const linkCreator = (noteId) =>
    entryPoint === LuruEntryPoint.EMBEDDED_GMEET_NOTE
      ? `${EMBEDDED_NOTE_HOME}?target=specificNote&noteId=${noteId}`
      : `/embedded_note/${noteId}`

  const eventHandlers = {
    onCreateNote: () => {
      try {
        let defaultNewNote = getNewNoteContent()
        var newNoteObject = {
          draftNote: true,
          title: defaultNewNote.title,
          body: defaultNewNote.body,
          baseNoteId: noteList[0].note_id,
          useSorConnection: calendarId.toUpperCase(),
        }
      } catch (e) {
        console.warn(e)
      }

      dispatch(NotesMiddleware.createNote.action(newNoteObject))
        // On successfully creating a note with notesAPI...
        .then((response) => {
          showToast({
            id: ToastId.NOTES_EDITOR_TOAST_ID,
            message: 'Created new note',
            severity: 'success',
          })
          navigate(linkCreator(response.payload.data.note_id))
        })
        // If there is an error creating the note
        .catch((error) => {
          // TODO: show home page empty editor with error message
          // Set home page editor status to error with error message so home page
          // editor can display it
          console.warn('Error creating new note:' + JSON.stringify(error))
        })
    },
  }

  return (
    <div className={styles.parent}>
      <a href='https://my.luru.app/' title='Luru' target='_blank' rel='noreferrer'>
        <img src={luruLogo} alt='Luru' />
      </a>
      <div className={styles.info}>
        There are {noteList.length} notes for this meeting. <br />
        Choose one to proceed:
      </div>
      <ul>
        {noteList.map((note) => (
          <li key={note.note_id}>
            <NavLink to={linkCreator(note.note_id)}>
              <button data-luru-role='note-link' className='cta'>
                <span className={styles.left}>{note.title}</span>
                <span className={styles.right}>{moment(note.updated_at).fromNow()}</span>
              </button>
            </NavLink>
          </li>
        ))}
      </ul>
      <div className={styles.newNote}>
        <h2>OR</h2>
        <h3>Create a note</h3>
        <button
          className={['ctaBlue', styles.newNoteButton].join(' ')}
          onClick={eventHandlers.onCreateNote ?? null}
          title={'Create new note for this meeting'}
        >
          <img src={plusIcon} style={{ filter: 'invert(1)' }} alt='New note' />
        </button>
      </div>
    </div>
  )
}
