import { HTTPMethod } from '../../../app/types'
import { getAuthUrl } from '../../../domutils/utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DisconnectGmailRequestParams } from '../middleware/disconnectGmail'

export const disconnectGmail = async (
  params: DisconnectGmailRequestParams,
  options: LuruAPIRequestOptions
) => {
  var oAuthKey = 'google-oauth2-gmail'
  var disconnectUrl = getAuthUrl() + '/disconnect/' + oAuthKey + '/'
  var request = new LuruAPIRequest(disconnectUrl, HTTPMethod.POST, {
    ...options,
    headers: {
      'X-CSRFToken': params.csrfToken ?? '',
    },
    credentials: 'include',
  })

  return request.make()
}
