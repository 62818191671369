import type { AppDispatch } from '../../../../app/store'
import { NotesAPI } from '../../../../features/notes/api'
import { NoteShareRecipient } from '../../../../features/notes/types'
import { NotesMiddleware } from '../../../../features/notes/middleware'

export default function getActions(dispatch: AppDispatch) {
  return {
    shareNote: (noteId: string, emailList: string[]) => {
      var recipients: Array<NoteShareRecipient> = emailList.map((email) => ({
        medium: 'EMAIL',
        to: email,
      }))

      return NotesAPI.shareNote({ noteId, recipients })
    },
    readNoteBody: async (noteId: string) => {
      let noteEntity = await dispatch(
        NotesMiddleware.readNoteEntityFromRedux.action(noteId)
      ).unwrap()

      return noteEntity?.body
    },
  }
}
