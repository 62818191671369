import { connect, ConnectedProps } from 'react-redux'
import LuruCollectionsSelectBoxComponent from './LuruCollectionsSelectBoxComponent'
import getReduxState from './getReduxState'
import Utils from '../../../../utils/Utils'
import { ConnectLuruExtensionsProps } from '@/app/types'

const connector = connect(getReduxState, null, null, { forwardRef: true })

export interface LuruCollectionsSelectBoxConnectedProps
  extends ConnectedProps<typeof connector>,
    ConnectLuruExtensionsProps {}

export default connector(Utils.connectLuruExtensions(LuruCollectionsSelectBoxComponent))
