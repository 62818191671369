import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse, LuruEntryPoint } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import Utils from '../../../utils/Utils'
import LuruError from '../../LuruError'
import { UserAPI } from '../api'
import { ReduxUserState } from '../types'

export interface DisconnectSlackRequestParams {
  csrfToken?: string
}

export interface DisconnectSlackResponse extends LuruAPIResponse {}

export const disconnectSlack = {
  action: createAsyncThunk<
    DisconnectSlackResponse['data'],
    DisconnectSlackRequestParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | DisconnectSlackResponse['metadata']
      rejectedMeta: null | DisconnectSlackResponse['metadata']
    }
  >(
    'user/disconnectSlack',
    async (params: DisconnectSlackRequestParams, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      var csrfToken = params.csrfToken

      if (!csrfToken) {
        csrfToken = Utils.getCookieValue('csrftoken') || ''
        var entryPoint = LuruUser.getCurrentEntryPoint()

        if (
          [
            LuruEntryPoint.NEWTAB,
            LuruEntryPoint.GLOBALSEARCH,
            LuruEntryPoint.EMBEDDED_GMEET_NOTE,
            LuruEntryPoint.OPTIONS,
          ].includes(entryPoint)
        ) {
          // Inside Extension, so get csrf token from store
          csrfToken = getState()?.user?.extensionSettings?.csrfTokenCookieValue || ''
        }
      }

      try {
        var response = (await UserAPI.disconnectSlack({ csrfToken }, { signal })) as DisconnectSlackResponse
        return fulfillWithValue(response.data, null)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    }
  ),

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(disconnectSlack.action.fulfilled, (state, action) => {
      if (state?.data?.userSettings?.connections && Array.isArray(state?.data?.userSettings?.connections)) {
        state.data.userSettings.connections = state.data.userSettings.connections?.filter?.(
          (conn) => conn.name !== 'SLACK'
        )
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUserState>) {
    disconnectSlack.addFulfilledCase(builder)
  },
}
