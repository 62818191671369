import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod, LuruEntryPoint } from '../../../app/types'
import { CreateNoteParameter } from '../types'
import LuruUser from '../../../domain/users/LuruUser'

export async function createNote(
  { title, body, connections, source }: Readonly<CreateNoteParameter>,
  options: LuruAPIRequestOptions
) {
  source = source ?? (LuruUser.getCurrentEntryPoint() as LuruEntryPoint)
  var request = new LuruAPIRequest('/api/notes', HTTPMethod.POST, options)

  request.setBody({
    title: title ?? 'New private note',
    body,
    connections,
    source,
  })

  return request.make()
}
