import { connect, ConnectedProps } from 'react-redux'
import NoteTemplateShareDialogComponent from './NoteTemplateShareDialogComponent'
import getReduxState from './getReduxState'
import getActions from './getActions'

const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type NoteTemplateShareDialogConnectedProps = ConnectedProps<
  typeof connector
>

export default connector(NoteTemplateShareDialogComponent)
