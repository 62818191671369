import { Children, cloneElement, forwardRef, useCallback, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styles from './Tooltip.module.css'
import Utils from '@/utils/Utils'
import DomUtils from '@/coreEditor/utils/DomUtils'

interface TooltipProps {
  label: string | React.ReactElement
  placement?: 'left' | 'right' | 'top' | 'bottom'
  children: React.ReactElement
}

const TOOLTIP_BUFFER = 8

export default function Tooltip(props: TooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  const [tooltipX, setTooltipX] = useState(0)

  const [tooltipY, setTooltipY] = useState(0)

  const contentRef = useRef<HTMLDivElement>(null)

  const tooltipProps = {
    onMouseEnter: useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        const elem = e.target as HTMLElement

        const coords = DomUtils.getElementScreenCoordinates(elem)

        const elDimensions = DomUtils.getElementDimensions(elem)

        const ttDimensions = contentRef.current ? DomUtils.getElementDimensions(contentRef.current) : null

        if (coords) {
          switch (props.placement) {
            case 'top':
              setTooltipX(coords.getX() + (elDimensions?.width ?? 0) / 2 - (ttDimensions?.width ?? 0) / 2)
              setTooltipY(coords.getY() - (ttDimensions?.height ?? 0) - TOOLTIP_BUFFER)
              break

            case 'bottom':
              setTooltipX(coords.getX() + (elDimensions?.width ?? 0) / 2 - (ttDimensions?.width ?? 0) / 2)
              setTooltipY(coords.getY() + (elDimensions?.height ?? 0) + TOOLTIP_BUFFER)
              break

            case 'left':
              setTooltipX(coords.getX() - (ttDimensions?.width ?? 0) - TOOLTIP_BUFFER)
              setTooltipY(coords.getY() + (elDimensions?.height ?? 0) / 2 - (ttDimensions?.height ?? 0) / 2)
              break

            case 'right':
            default:
              setTooltipX(coords.getX() + (elDimensions?.width ?? 0) + TOOLTIP_BUFFER)
              setTooltipY(coords.getY() + (elDimensions?.height ?? 0) / 2 - (ttDimensions?.height ?? 0) / 2)
          }
          setShowTooltip(true)
        }
      },
      [props.placement]
    ),

    onMouseLeave: useCallback(() => {
      setShowTooltip(false)
    }, []),

    onBlur: useCallback(() => {
      setShowTooltip(false)
    }, []),

    // Adding tabIndex to make all child element focusable so that onBlur will be called
    // whenever child is deleted, This props is needed because when we delete embedded field
    // the onMouseLeave is not calling which is causing the tooltip to show forever
    tabIndex: -1,
  }

  const child = Children.only(props.children) as React.ReactElement

  const shouldWrap = typeof child.type !== 'string'

  return (
    <>
      {cloneElement(shouldWrap ? <span className={styles.ttWrapper}>{child}</span> : child, tooltipProps)}
      {createPortal(
        <TooltipContent {...props} show={showTooltip} x={tooltipX} y={tooltipY} ref={contentRef} />,
        document.getElementById('luruPortal') ?? document.body
      )}
    </>
  )
}

interface TooltipContentProps extends TooltipProps {
  x: number
  y: number
  show: boolean
}

const TooltipContent = forwardRef((props: TooltipContentProps, ref) => (
  <div
    ref={ref as any}
    className={Utils.classes(styles.tt, props.show && props.label && styles.show)}
    style={{ top: `${Math.max(props.y, TOOLTIP_BUFFER)}px`, left: `${Math.max(props.x, TOOLTIP_BUFFER)}px` }}
  >
    {props.label}
  </div>
))
