import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import { DateUtils } from '../../../utils/dateUtils'
import LuruError from '../../LuruError'
import { ViewsAPI } from '../api'
import { ReduxLuruViewEntity, ReduxViewsState } from '../types'

export interface UseViewParameter {
  view_id: string
  crmObjectNamePlural: string
}

export interface UseViewAPIResponse extends LuruAPIResponse {
  data: ReduxLuruViewEntity
}

export const useView = {
  action: createAsyncThunk<
    UseViewAPIResponse['data'],
    UseViewParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: UseViewAPIResponse['metadata']
      rejectedMeta: UseViewAPIResponse['metadata'] | null
    }
  >(
    'views/useView',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var view = (await ViewsAPI.useView(params, {
          signal,
        })) as UseViewAPIResponse

        return fulfillWithValue(view.data, view.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(useView.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(useView.action.fulfilled, (state, action) => {
      var viewData = state.entities[action.meta.arg.view_id]?.data

      if (viewData) {
        viewData.used_at = DateUtils.toAPIDateString(new Date())
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(useView.action.rejected, (state, action) => {
      console.error('Error in posting a use view')
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    useView.addPendingCase(builder)
    useView.addFulfilledCase(builder)
    useView.addRejectedCase(builder)
  },
}
