import { EntityStatus } from '../../app/types'
import { CRMProvider } from '../user/types'

export const ReduxCrmStateTTL = 60 * 60 * 1_000

export type SchemaMapI = {
  [objectName in CrmObjectName]?: {
    lastRefreshedAt?: string
    status: EntityStatus
    data?: CrmObjectSchema | null
    error?: {} | null
  }
}

export type ReduxCrmState = {
  [crmKey in ReduxCrmKey]?: {
    schema?: {
      [objectName in CrmObjectName]?: {
        lastRefreshedAt?: string
        status: EntityStatus
        data?: CrmObjectSchema | null
        error?: {} | null
      }
    }
    record_info?: {
      [objectName in CrmObjectName]?: {
        [recordId: string]: {
          sor_record_name: string
          sor_owner_name: string
          sor_owner_id: string
        }
      }
    }
    records?: {
      [objectName in CrmObjectName]?: Record<
        string,
        {
          status: EntityStatus
          data?: CrmObjectSchema | null
          error?: {} | null
        }
      >
    }

    activities?: {
      status?: EntityStatus
      entities: Record<
        string,
        {
          status: EntityStatus
          data?: CrmActivity | null
          error?: {} | null
        }
      >
    }
    // TODO: Old state structure; heavier; remove after migrating CRM slice
    entities?: {
      [objectName in CrmObjectName]?: Record<
        string,
        {
          lastRefreshedAt?: string
          status: EntityStatus
          data?: Record<string, { value: any; originalValue?: any; schema: CrmObjectSchema }> | null
          error?: { message: string } | null
        }
      >
    }
    objects?: {
      status: EntityStatus
      data?: Array<any>
      error?: {} | null
    }
  }
} & {
  search: {}
}

export type ReduxCrmKey = 'sfdc' | 'hubspot' | 'pipedrive'

export type CrmObjectName = SfdcObjectName | HubspotObjectName | PipedriveObjectName

// export enum SfdcPrimaryObjectName {
//   Opportunity = 'Opportunity',
//   Lead = 'Lead',
//   Contact = 'Contact',
//   Account = 'Account',
// }

export type SfdcObjectName = string

// export enum HubspotPrimaryObjectName {
//   deals = 'deals',
//   contacts = 'contacts',
//   companies = 'companies',
// }

export type HubspotObjectName = string

// export enum PipedrivePrimaryObjectName {
//   deal = 'deal',
//   lead = 'lead',
//   person = 'person',
//   organization = 'organization',
// }

export type PipedriveObjectName = string

// export enum CrmRecordType {
//   DEAL = 'deal',
//   LEAD = 'lead',
//   ACCOUNT = 'account',
//   CONTACT = 'contact',
//   MEETING = 'meeting',
// }

export type CrmRecordType = string

export enum CrmName {
  SFDC = 'Salesforce',
  SFDC_SANDBOX = 'Salesforce',
  HUBSPOT = 'HubSpot',
  PIPEDRIVE = 'Pipedrive',
}

export interface CrmObjectSchema {
  fields: Array<CrmFieldSchema>
}

export interface CrmObjectSchemaMap {
  fields: Record<string, CrmFieldSchema>
}

export interface PicklistOption {
  label: string
  value: string
  valueForUpdate?: any
  validFor?: Array<string>
}

export interface CrmFieldSchema {
  luruFieldType: LuruFieldType
  // System facing name
  name: string
  // User facing name
  label: string
  // Original type
  type: string
  // Original type
  native_type?: string
  // Default value
  defaultValue?: string | null
  // Number of digits
  digits?: number | null
  // Length
  length?: number | null
  // Is name field?
  nameField?: boolean
  // Picklist values (for enum/multi-enum)
  picklistValues?: Array<PicklistOption>
  // Hierarchical picklist values
  hierarchicalPicklistValues?: LuruHierarchialPicklistOptions
  // Referenced field (for reference field type)
  referencedObject?: string
  // Is field updateable?
  updateable?: boolean
  // Is field mandatory?
  isMandatory?: boolean
  // Is field filterable?
  isFilterable: boolean
  // TODO: Remove later
  filterable: boolean
  // Reference fields
  referenceTo?: Array<string>
  // Is field nillable?
  isNillable?: boolean
  // Is field non-createable?
  nonCreateable?: boolean
  // Controller of this field
  controllerName?: string
}

export enum LuruFieldType {
  DATE = 'date',
  DATETIME = 'datetime',
  INTEGER = 'int',
  DOUBLE = 'double',
  CURRENCY = 'currency',
  PERCENTAGE = 'percent',
  TEXT = 'string',
  BOOLEAN = 'boolean',
  ENUM = 'picklist',
  ENUM_RADIO = 'picklist',
  ENUM_SELECT = 'picklist',
  MULTIENUM = 'multipicklist',
  REFERENCE = 'reference',
  MULTIREFERENCE = 'multi_reference',
  REFERENCE_FIELD = 'reference_field',
  LARGETEXT = 'textarea',
  ADDRESS = 'address',
  TELEPHONE = 'phone',
  EMAIL = 'email',
  URL = 'url',
  INTEGER_NOFORMAT = 'integer_noformat',
  HIERARCHICAL_ENUM = 'hierarchical_picklist',
  ASSOCIATED_EMAIL = 'associated_email',
  ASSOCIATED_CALL = 'associated_call',
  MEETING_TIMESTAMP = 'meeting_timestamp',
  LURU_MEETING_NOTE = 'luru_meeting_note',
}

export const EmptyCrmState: ReduxCrmState = {
  search: { status: EntityStatus.Idle, error: null, results: null },
}

export interface SorRecord {
  sor_object_name: string
  sor_record_id: string
  sor_record_name: string
  sor_record_owner?: SorUser
}

export interface SorUser {
  sor_object_name: string
  sor_record_id: string
  sor_record_name: string
  photo_url?: string
}

export type LuruHierarchialPicklistOptions = Array<{
  label: string
  value: string
  options: Array<LuruPicklistOption>
}>

export type LuruPicklistOption = { label: string; value: string; metadata: any }

export interface LuruHierarchialPicklistValue {
  level1: string
  level2: string
}

export enum CrmActivityLogType {
  Call = 'calls',
  Meeting = 'meeting',
  DoNotLog = 'Do not log',
}

export interface CrmActivityConnection extends Omit<SorRecord, 'sor_record_name'> {}

export interface CrmActivity {
  activity_id?: string
  sor_activity_id?: string
  assigned_to?: SorRecord
  sor?: CRMProvider
  external_url?: string
  title: string
  description?: string
  notes?: string
  start_time: string
  end_time?: string
  disposition?: string
  type?: string
  connections: Array<CrmActivityConnection>
  attendees: Array<CrmActivityConnection> | Array<string>
}
