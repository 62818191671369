import { NotesMiddleware } from '../../../features/notes/middleware'
import { getNewNoteContent } from '../../../features/notes/helpers/getNewNoteContent'
import { EMBEDDED_NOTE_HOME } from '../../../entryPoints/EmbeddedNoteApp/EmbeddedNoteAppComponent'
import saveNote from '../reduxHelpers/saveNote'
import { BrowserUtils } from '../../../utils/browserUtils'

// Message passing logic between chrome extension and component
export const Messages = {
  PopOutLuruWindow: 'popOutLuruWindow',
  PopInLuruWindow: 'popInLuruWindow',
  PopInWithDraftNote: 'popInWithDraftNote',
  SlideInLuruIFrame: 'slideInLuruPanel',
  SlideOutLuruIFrame: 'slideOutLuruPanel',
  FrameLoaded: 'luruIFrameLoaded',
  RedirectEmbeddedNote: 'redirectEmbeddedNote',
  RefreshDataStore: 'refreshDataStore',
}

export default function handleMessage({
  e,
  noteId,
  dispatch,
  meetingUrl,
  dataRefresher,
  updateNoteIdCallback,
}) {
  let callback = null

  switch (e.data.messageName) {
    case Messages.PopOutLuruWindow:
      // Embedded, inside an iFrame
      let popupDimensions = {
        width: 360,
        height: window.screen.availHeight,
      }
      let popupWindowParams = {
        width: popupDimensions.width,
        height: popupDimensions.height,
        top: window.screen.availHeight / 2 - popupDimensions.height / 2,
        left: window.screen.availWidth - popupDimensions.width,
      }
      callback = async ({ noteDirtyAndSaved }) => {
        let noteEntity = await dispatch(
          NotesMiddleware.readNoteEntityFromRedux.action(noteId)
        )
        let popoutUrl = window.location.href

        if (
          noteEntity.payload.isDraft === true &&
          noteDirtyAndSaved === false &&
          popoutUrl.includes('target=specificNote')
        ) {
          // In this case, we have a draft new note inside frame.  It is not yet
          // saved in DB.  We need to now open the popout window with an
          // instruction to load a new private draft note

          // NEW_EMBEDDED_ROUTES
          // popoutUrl = '/embedded_new_draft_note/'
          popoutUrl = EMBEDDED_NOTE_HOME + '?target=draftNote'
        }

        BrowserUtils.openWindow(
          popoutUrl,
          `luruPopoutWindow`,
          popupWindowParams
        )

        // Message to Google Meet window from embedded note
        window.parent.postMessage(
          { messageName: Messages.SlideOutLuruIFrame },
          '*'
        )
      }
      saveNote(noteId, callback)
      // setTimeout(callback, 100)

      break

    case Messages.PopInLuruWindow:
      callback = () => {
        // Message to Google Meet window from embedded note
        window.parent.postMessage(
          { messageName: Messages.SlideInLuruIFrame },
          '*'
        )
      }
      if (e.data?.noteId && e.data.noteId !== noteId) {
        updateNoteIdCallback(e.data.noteId)
      }
      setTimeout(callback, 50)
      break

    case Messages.PopInWithDraftNote:
      setTimeout(async () => {
        let slideInCallBack = () =>
          // Message to Google Meet window from embedded note
          window.parent.postMessage(
            { messageName: Messages.SlideInLuruIFrame },
            '*'
          )
        // If the popped out window was in embedded_meeting_note page, and it
        // was in a draft state (and not dirtied), it must be true that the
        // luru frame which popped it out must also be an /embedded_meeting_note
        // So, we just need to slide the luru frame in
        // If in case the meeting note was saved in popped out window, the
        // note status would have changed to loaded and popin message would
        // be with the relevant note id
        // NEW EMBEDDED ROUTES
        // Old logic:
        // if (e.data.url.includes('/embedded_meeting_note/')) {
        // New logic:
        if (e.data.url.includes('target=noteForMeeting')) {
          setTimeout(slideInCallBack, 50)
          return
        }
        let createPayload = {
          ...getNewNoteContent(),
          draftNote: true,
        }
        let createNoteAction = NotesMiddleware.createNote.action(createPayload)
        let newDraftNoteResponse = await dispatch(createNoteAction)
        // TODO: Can there be error in response?
        // Ideally not at all, as this is a deterministic frontend function
        updateNoteIdCallback(newDraftNoteResponse.payload.data.note_id)
        setTimeout(slideInCallBack, 50)
      })

      break

    case Messages.RefreshDataStore:
      // console.log(`Received message to refresh data store`)
      if (dataRefresher) {
        dataRefresher.refresh()
      }
      break

    default:
  }
}
