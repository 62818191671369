import { useAppSelector } from '../../../app/hooks'
// import { NotificationCategory, NotificationState, NotificationType } from '../types'

export const useUserNotifications = () => {
  var userNotifications = useAppSelector((state) => state?.user?.data?.notifications || [])
  // var userNotifications = [
  //   {
  //     id: 'f21e392e-c0c1-445e-8c6b-3b77ffdf1e64',
  //     data: 'This is Test User Notification',
  //     notify_at: new Date('2023-06-30T13:48:50.164Z'),
  //     expiry_at: new Date('2023-07-30T14:48:50.164Z'),
  //     type: NotificationType.ERROR,
  //     category: NotificationCategory.BANNER,
  //     state: NotificationState.ACTIVE,
  //     reminder_frequency: 5 * 60 * 1000, //5 min
  //     dismissible: true,
  //   },
  //   {
  //     id: 'f21e392e-c0c1-445e-8c6b-3b77ffdf1e63',
  //     data: 'Hello World! Notification',
  //     notify_at: new Date(new Date().setMinutes(new Date().getMinutes() + 10)),
  //     // notify_at: new Date('2023-06-30T13:48:50.164Z'),
  //     expiry_at: new Date('2023-07-30T14:28:36.902Z'),
  //     type: NotificationType.ALERT,
  //     category: NotificationCategory.MODAL,
  //     state: NotificationState.ACTIVE,
  //     dismissible: false,
  //   },
  // ]

  return userNotifications
}
