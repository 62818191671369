import { NoteJotting } from '@/features/notes/types'
import json5 from 'json5'

export default function getDirtiedAfterInsertion(noteB: string, templateB: string) {
  let isDirtied = false

  if (!noteB || !templateB) {
    return false
  }

  try {
    let noteBJson = json5.parse(noteB) as Array<NoteJotting>

    let templateBJson = json5.parse(templateB) as Array<NoteJotting>

    if (noteBJson.length - 1 === templateBJson.length) {
      noteBJson.pop()
    }

    if (noteBJson.length !== templateBJson.length) {
      isDirtied = true
    }

    for (let i = 0; i < noteBJson.length; i++) {
      if (typeof noteBJson[i]?.data === 'string') {
        if (noteBJson[i]?.data !== templateBJson[i]?.data) {
          isDirtied = true
        }
      }
    }
  } catch (error) {
    console.warn(error)
  }

  return isDirtied
}
