import { useEffect } from 'react'
import { getBrowserName } from '../../utils/Utils'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { useLuruToast } from '@/hooks/useLuruToast'
import useAppUIState from '@/app_ui/hooks/useAppUIState'

interface ChromeUpdateBannerBasedOnNewExtensionApiProps {}

export function ChromeUpdateBannerBasedOnNewExtensionApi(props: ChromeUpdateBannerBasedOnNewExtensionApiProps) {
  const { showToast } = useLuruToast()
  const {
    toast: { isOpen: isToastOpen },
  } = useAppUIState()
  const upgradeBrowserNotificationId = 'upgradeBrowserNotificationId'
  const smallerScreenNotificationId = 'smallerScreenNotificationId'
  const isSmallerScreen = useMediaQuery(`(max-width:350px)`)

  useEffect(() => {
    if (isSmallerScreen && !isToastOpen) {
      showToast({
        message: `To fully enjoy the robust Luru note-taking experience, please increase the screen width, as it appears to be quite small.`,
        severity: 'info',
        id: smallerScreenNotificationId,
        duration: 5000,
      })
    }
  }, [isSmallerScreen, showToast, isToastOpen])

  useEffect(() => {
    const checkForChromeUpdateToSupportChromeSidePanel = () => {
      // Check for chrome side panel api avaibility and show upgrade browser notification
      chrome?.runtime?.sendMessage?.(
        {
          messageName: 'isOpenChromeSidePanelApiAvailable',
        },
        (response) => {
          if (!response?.payload?.available) {
            !isToastOpen &&
              showToast({
                message: `You have an older version of the ${getBrowserName()} browser. Please upgrade to the latest version to enjoy a more robust Luru note taking experience`,
                severity: 'info',
                id: upgradeBrowserNotificationId,
                duration: null,
              })
          }
        }
      )
    }
    checkForChromeUpdateToSupportChromeSidePanel()
  }, [isToastOpen, showToast])
  return <div></div>
}
