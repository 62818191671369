import { getNewWorkflowDraft } from './getNewWorkflowDraft'
import {
  getNewWorkflowV2EditorDraft,
  getNewWorkflowV2ActionEditorBlockDraft,
  getWorkflowV2EditorDraftFromOriginalWorkflow,
  getWorkflowV2EntityFromEditorDraft,
  getNewWorkflowV2SendMessageActionEditorBlockDraft,
  getNewWorkflowV2SendMessageStandardActionEditorBlockDraft,
  getNewWorkflowV2SearchRecordActionEditorBlockDraft,
  getNewWorkflowV2TriggerEditorBlockDraft,
  getNewWorkflowV2DelayActionEditorBlockDraft,
  getNewWorkflowV2FilterRecordActionEditorBlockDraft,
  validateFilterExpression,
} from './getNewWorkflowV2EditorDraft'
import { getWorkflowSorIcon } from './getWorkflowSorIcon'
import { normalizeWorkflowSchema, appendLuruFieldTypeInFieldSchema } from './normalizeWorkflowSchema'
import {
  getBlockSchemaFromBlockId,
  isBlockSchemaOutputArrayFromBlockId,
  isBlockSchemaOutputArrayFromBlockSchema,
} from './getBlockSchema'

export const WorkflowsSliceHelpers = {
  getNewWorkflowDraft,
  getWorkflowSorIcon,
  normalizeWorkflowSchema,
  appendLuruFieldTypeInFieldSchema,
  workflowV2: {
    getNewWorkflowV2EditorDraft,
    getNewWorkflowV2ActionEditorBlockDraft,
    getWorkflowV2EditorDraftFromOriginalWorkflow,
    getWorkflowV2EntityFromEditorDraft,
    getNewWorkflowV2SendMessageActionEditorBlockDraft,
    getNewWorkflowV2SendMessageStandardActionEditorBlockDraft,
    getNewWorkflowV2SearchRecordActionEditorBlockDraft,
    getNewWorkflowV2TriggerEditorBlockDraft,
    getNewWorkflowV2DelayActionEditorBlockDraft,
    getNewWorkflowV2FilterRecordActionEditorBlockDraft,
    getBlockSchemaFromBlockId,
    isBlockSchemaOutputArrayFromBlockId,
    isBlockSchemaOutputArrayFromBlockSchema,
    validateFilterExpression,
  },
}
