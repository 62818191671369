import { AppDispatch, LuruReduxStore, RootState } from '@/app/store'
import { EntityStatus, LuruAPIResponse } from '@/app/types'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { UserAPI } from '../api'
import LuruError from '@/features/LuruError'
import { ReduxUserState } from '../types'

export interface EmailSyncInfoResponse extends LuruAPIResponse {}

export interface EmailSyncInfoParams {}

export const getEmailSyncInfo = {
  action: createAsyncThunk<
    EmailSyncInfoResponse['data'],
    EmailSyncInfoParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | EmailSyncInfoResponse['metadata']
      rejectedMeta: null | EmailSyncInfoResponse['metadata']
    }
  >(
    'user/getEmailSyncInfo',
    async (params: EmailSyncInfoParams, { fulfillWithValue, rejectWithValue }) => {
      try {
        var syncData = LuruReduxStore.getState().user?.data?.userSettings?.emailSyncData

        if (typeof syncData?.data === 'object' && Object.keys(syncData?.data)?.length !== 0) {
          return fulfillWithValue(
            {
              status: syncData?.data?.syncStatus,
              mailbox: syncData?.data?.mailBox,
              last_sync_time: syncData?.data?.lastSyncTime,
            },
            null
          )
        }

        var response = (await UserAPI.getEmailSyncInfo(params)) as EmailSyncInfoResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    },
    {
      condition: () => {
        return (
          LuruReduxStore.getState().user?.data?.userSettings?.emailSyncData?.getStatus !== EntityStatus.Loading &&
          LuruReduxStore.getState().user?.data?.userSettings?.emailSyncData?.setStatus !== EntityStatus.Loading
        )
      },
    }
  ),

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(getEmailSyncInfo.action.fulfilled, (state, action) => {
      if (state?.data?.userSettings) {
        state.data.userSettings.emailSyncData = state?.data?.userSettings.emailSyncData
          ? {
              ...state.data.userSettings.emailSyncData,
              data: {
                syncStatus: action.payload.status,
                mailBox: action.payload.mailbox,
                lastSyncTime: action.payload.last_sync_time,
              },
              getStatus: EntityStatus.Loaded,
            }
          : {
              data: {
                syncStatus: action.payload.status,
                mailBox: action.payload.mailbox,
                lastSyncTime: action.payload.last_sync_time,
              },
              getStatus: EntityStatus.Loaded,
            }
      }
    })
  },

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(getEmailSyncInfo.action.pending, (state, action) => {
      const userSettingsData = state?.data?.userSettings

      if (userSettingsData) {
        userSettingsData.emailSyncData = userSettingsData.emailSyncData
          ? {
              ...userSettingsData.emailSyncData,

              data: state.data?.userSettings?.emailSyncData?.data
                ? { ...state.data?.userSettings?.emailSyncData?.data }
                : {},
              getStatus: EntityStatus.Loading,
            }
          : {
              data: state.data?.userSettings?.emailSyncData?.data
                ? { ...state.data?.userSettings?.emailSyncData?.data }
                : {},
              getStatus: EntityStatus.Loading,
            }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUserState>) {
    builder.addCase(getEmailSyncInfo.action.rejected, (state, action) => {
      const userSettingsData = state?.data?.userSettings

      if (userSettingsData) {
        userSettingsData.emailSyncData = userSettingsData.emailSyncData
          ? {
              ...userSettingsData.emailSyncData,
              data: {},
              getStatus: EntityStatus.ErrorLoading,
            }
          : {
              data: {},
              getStatus: EntityStatus.ErrorLoading,
            }
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUserState>) {
    getEmailSyncInfo.addFulfilledCase(builder)
    getEmailSyncInfo.addPendingCase(builder)
    getEmailSyncInfo.addRejectedCase(builder)
  },
}
