import { createNote } from './createNote'
import { createNoteConnection } from './createNoteConnection'
import { deleteNote } from './deleteNote'
import { deleteNoteConnection } from './deleteNoteConnection'
import { fetchNote } from './fetchNote'
import { getSorConnection } from './getSorConnection'
import { readNoteEntityFromRedux } from './readNoteEntityFromRedux'
import { saveNote } from './saveNote'
import { searchLuruNotes } from './searchLuruNotes'
import { searchSORNotes } from './searchSORNotes'
import { syncNote } from './syncNote'

// Async thunks for integrating notes API with notes slice
export const NotesMiddleware = {
  createNote,
  createNoteConnection,
  deleteNote,
  deleteNoteConnection,
  fetchNote,
  getSorConnection,
  readNoteEntityFromRedux,
  saveNote,
  searchLuruNotes,
  searchSORNotes,
  syncNote,
}
