import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import LuruUser from '../../../domain/users/LuruUser'
import { useAsyncFn } from '../../../hooks/useAsyncFn'
import { CrmMiddleware } from '../../crm/middleware'
import { CrmActivity, CrmActivityLogType } from '../../crm/types'
import { MeetingsMiddleware } from '../../meetings/middleware'
import { useConnectedActivityByExternalUrl } from './useConnectedActivity'

export function useConnectedMeeting(noteId: string, fetchConnectedActivity: boolean = true) {
  const dispatch = useAppDispatch()
  const note = useAppSelector((state) => state.notes.entities[noteId])?.data
  const calendarId = useAppSelector((state) => state.user.data?.userSettings?.connectedCalendar)?.name
  const crmKey = LuruUser.getCurrentUserReduxCrmKey()

  const connectedMeeting = useMemo(
    () => note?.connections?.find((f) => f.sor === calendarId),
    [note?.connections, calendarId]
  )

  const meeting = useAppSelector((state) => state.meetings.entities?.[connectedMeeting?.sor_record_id || ''])

  const activities = useConnectedActivityByExternalUrl(meeting?.data?.external_url || '')

  const meetingRecordState = useAsyncFn(async () => {
    if (connectedMeeting) {
      var activities: Array<CrmActivity> | null = null
      const meeting = await dispatch(
        MeetingsMiddleware.fetchMeeting.action({ meetingId: connectedMeeting.sor_record_id })
      ).unwrap()
      if (fetchConnectedActivity) {
        const searchActivityLogRes = await dispatch(
          CrmMiddleware.searchActivityLog.action({
            crmKey,
            params: { external_url: meeting.external_url },
            type: CrmActivityLogType.Meeting,
          })
        ).unwrap()
        activities = searchActivityLogRes
      }

      return { meeting, activities }
    } else {
      return null
    }
  }, [connectedMeeting?.sor_record_id])

  return { connectedMeeting, meetingRecordState, meetingRecord: meeting, activityRecord: activities }
}
