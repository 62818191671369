// Redux
import { createAsyncThunk } from '@reduxjs/toolkit'

// CRM base APIs
import { crmApi } from '../crmBaseAPI'

// CRM middleware parent object
import { abortAllRequests } from '../../crmMiddleware'

/**
 * @classdesc - Class to manage a CRM's createRecord API request
 */
export default class CreateRecord {
  #crmId = null
  #requestKey = null
  #middlewareFactory = null

  /**
   * Constructor for this class.  Sets up local variables.  Request key is
   * used as the thunk name for Redux purposes.  It is also used to namespace
   * abort controllers object in CRM middleware.
   * @param {CRMBaseMiddlewareFactory} middlewareFactory - A middleware factory
   * which is going to use this instance
   */
  constructor(middlewareFactory) {
    this.#middlewareFactory = middlewareFactory
    this.#crmId = this.#middlewareFactory.getCrmId()
    this.#requestKey = `${this.#crmId}/createRecord`
  }

  /**
   * Function to get the portion of the middleware for managing this particular
   * request.  This will be used by the top-level CRM middleware to compose a
   * large middleware object.
   * @returns {Object} - Middleware to manage this request
   */
  getRequestMiddleware() {
    return {
      key: this.#requestKey,
      action: this.#computeAction(),
      abortAllRequests: this.#computeAbortAllRequests(),
      pending: this.#computePendingState(),
      fulfilled: this.#computeFulfilledState(),
      rejected: this.#computeRejectedState(),
    }
  }

  /**
   * Returns a function to initiate a search in CRM.  The thunk itself expects
   * an object as a parameter with two keys: query and objects, which is an
   * array of object names across which to search
   * @return {Object} - Output of reduxjs/toolkit/createAsyncThunk() function
   */
  #computeAction() {
    let thunk = async (payload, { requestId, rejectWithValue }) => {
      let { fields, sorObjectName } = payload
      fields = this.#middlewareFactory.prepareFieldSetForUpdate(fields)
      return await crmApi.createRecord(this.#crmId, { sorObjectName, fields }, { requestId, rejectWithValue })
    }

    return createAsyncThunk(this.#requestKey, thunk.bind(this))
  }

  /**
   * Compute a function to abort requests for this particular request key
   * @returns {Function} - Function to abort all requests for this request
   */
  #computeAbortAllRequests() {
    let abortFunction = () => abortAllRequests(this.#requestKey)
    abortFunction.bind(this)
    return abortFunction
  }

  /**
   * Compute a pending state handler required by Redux for handling this request
   * @returns {Function} - Pending state handler
   */
  #computePendingState() {
    return () => {}
  }

  /**
   * Compute a fulfilled handler required by Redux for handling this request
   * @returns {Function} - Fulfilled state handler
   */
  #computeFulfilledState() {
    return () => {}
  }

  /**
   * Compute a rejected handler required by Redux for handling this request
   * @returns {Function} - Rejected state handler
   */
  #computeRejectedState() {
    var rejectedStateHandler = (state, action) => {}
    return rejectedStateHandler.bind(this)
  }
}
