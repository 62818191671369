// Own libraries
import { JottingType } from '../../features/notes/types'

// Styles
import editorStyles from '../../routes/notes/css/NotesEditor.module.css'
import styles from '../../routes/notes/css/JottingDragDrop.module.css'

/**
 * @classdesc A class for managing jotting drag and drop related events
 */
export default class JottingDragDropHandler {
  /**
   * Compute if and how navigation event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   * @return {Object} - Instructions on handling event - {do, undo,
   * preventDefault, dirtyFlag}
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]
    let dragEvent =
      lastEvent?.sourceEvent?.type.startsWith('drag') ||
      lastEvent?.sourceEvent?.type === 'drop'
        ? lastEvent
        : null

    switch (dragEvent?.sourceName) {
      case 'drag':
        return this.#computeDragElementHandler(dragEvent)

      case 'note':
        return this.#computeNoteDragHandler(dragEvent)

      case 'jotting':
        return this.#computeJottingDragHandler(dragEvent)
      default:
    }

    return null
  }

  /**
   * Compute drag handling for drag handle
   * @param {Object} dragEvent - Luru event object
   */
  #computeDragElementHandler(dragEvent) {
    switch (dragEvent.sourceEvent.type) {
      case 'dragstart':
        return {
          do: (view) => {
            this.#handleDragStart(view, dragEvent)
          },
          preventDefault: true,
        }

      case 'dragend':
        return {
          do: (view) => {
            this.#handleDragEnd(view, dragEvent)
          },
          preventDefault: true,
          dirtyFlag: true,
        }

      default:
        return null
    }
  }

  /**
   * Compute drag handling for jotting element
   * @param {Object} dragEvent - Luru event object
   */
  #computeJottingDragHandler(dragEvent) {
    switch (dragEvent.sourceEvent.type) {
      case 'dragenter':
        return {
          do: (view) => {
            this.#handleDragEnter(view, dragEvent)
          },
          preventDefault: true,
        }

      case 'dragover':
        return {
          do: (view) => {
            this.#handleDragOver(view, dragEvent)
          },
          preventDefault: true,
        }

      case 'dragleave':
        return {
          do: (view) => {
            this.#handleDragLeave(view, dragEvent)
          },
          preventDefault: true,
        }

      case 'drop':
        return {
          do: (view) => {
            this.#handleDrop(view, dragEvent)
          },
          preventDefault: true,
          dirtyFlag: true,
        }

      default:
        return null
    }
  }

  /**
   * Compute drag handling for a note element
   * @param {Object} dragEvent - Luru event object
   */
  #computeNoteDragHandler(dragEvent) {
    // Override drag-drop behavior on note element
    switch (dragEvent.sourceEvent.type) {
      case 'dragstart':
        // Don't allow note element contents to be dragged
        return {
          do: () => {
            dragEvent.sourceEvent.preventDefault()
          },
          preventDefault: true,
        }

      // Let the 'enter', 'over' and 'leave' events be handled by the jotting DIV
      case 'dragenter':
        return {
          do: (view) => {
            dragEvent.sourceEvent.preventDefault()
            this.#handleDragEnter(view, dragEvent)
          },
          preventDefault: true,
        }

      case 'dragover':
        return {
          do: (view) => {
            dragEvent.sourceEvent.preventDefault()
            this.#handleDragOver(view, dragEvent)
          },
          preventDefault: true,
        }

      case 'dragleave':
        return {
          do: (view) => {
            dragEvent.sourceEvent.preventDefault()
            this.#handleDragLeave(view, dragEvent)
          },
          preventDefault: true,
        }

      case 'drop':
      default:
        // Prevent default behavior when note receives a drop.
        // This would be handled by jotting DIV.
        return {
          do: () => {
            dragEvent.sourceEvent.preventDefault()
          },
          preventDefault: true,
          dirtyFlag: true,
        }
    }
  }

  /**
   * Handle drag start event with a jotting element
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} luruEvent - Luru drag event
   */
  #handleDragStart(view, luruEvent) {
    let sourceEvent = luruEvent.sourceEvent
    // Set up data being dragged
    // Our application will make use of only "application/x.jottingIndex"
    // If dragged content is dropped in another place, that application can
    // make use of either text/html or text/plain depending on its capability
    // TODO: Support a text/markdown as well
    sourceEvent.dataTransfer.setData(
      'application/x.jottingIndex',
      luruEvent.data.jottingElement.dataset.jottingIndex
    )
    sourceEvent.dataTransfer.setData(
      'text/html',
      luruEvent.data.noteElement?.innerHTML ?? ''
    )
    sourceEvent.dataTransfer.setData(
      'text/plain',
      luruEvent.data.noteElement?.textContent ?? ''
    )

    // Indicate that all operations are allowed.  We want to play nice with
    // other applications.  While dragging and dropping in our canvas may only
    // mean a 'move' operation, dragging from our canvas and dropping into
    // another application can be defined by user as a 'copy' or 'move' or
    // 'link'
    sourceEvent.dataTransfer.effectAllowed = 'all'

    // Set the translucent layer that would be dragged
    sourceEvent.dataTransfer.setDragImage(
      this.#getDragImageElement(view, luruEvent.data.jottingElement),
      0,
      0
    )
  }

  /**
   * Handle drag end event of a jotting div
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} luruEvent - Luru drag event
   */
  #handleDragEnd(view, luruEvent) {
    luruEvent.sourceEvent.preventDefault()
    view.removeShadowContainer()
  }

  /**
   * Handle drag enter event on a jotting div
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} luruEvent - Luru drag event
   */
  #handleDragEnter(view, luruEvent) {
    var sourceEvent = luruEvent?.sourceEvent

    if (sourceEvent) {
      sourceEvent.preventDefault()
      // Our canvas allows a 'move' operation for now
      sourceEvent.dataTransfer.dropEffect = 'move'
    }

    // let srcJotting = e.dataTransfer.getData("application/x.jottingIndex");
    if (
      this.#isDragOverTopHalf(view, sourceEvent, luruEvent.data.jottingElement)
    ) {
      luruEvent?.data.jottingElement?.classList.add(styles.dropTargetBefore)
    } else {
      luruEvent?.data.jottingElement?.classList.add(styles.dropTargetAfter)
    }
  }

  /**
   * Handle drag enter event on a jotting div
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} luruEvent - Luru drag event
   */
  #handleDragOver(view, luruEvent) {
    var sourceEvent = luruEvent?.sourceEvent
    var jottingElement = luruEvent?.data?.jottingElement

    sourceEvent?.preventDefault()

    if (this.#isDragOverTopHalf(view, sourceEvent, jottingElement)) {
      if (jottingElement?.classList.contains(styles.dropTargetAfter)) {
        jottingElement?.classList.remove(styles.dropTargetAfter)
      }

      if (!jottingElement?.classList.contains(styles.dropTargetBefore)) {
        jottingElement?.classList.add(styles.dropTargetBefore)
      }
    } else {
      if (jottingElement?.classList.contains(styles.dropTargetBefore)) {
        jottingElement?.classList.remove(styles.dropTargetBefore)
      }

      if (!jottingElement?.classList.contains(styles.dropTargetAfter)) {
        jottingElement?.classList.add(styles.dropTargetAfter)
      }
    }
  }

  /**
   * Handle drag leave event on a jotting div
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} luruEvent - Luru drag event
   */
  #handleDragLeave(view, luruEvent) {
    var sourceEvent = luruEvent?.sourceEvent
    var jottingElement = luruEvent?.data?.jottingElement

    sourceEvent?.preventDefault()

    if (jottingElement?.classList.contains(styles.dropTargetBefore)) {
      jottingElement.classList.remove(styles.dropTargetBefore)
    }

    if (jottingElement?.classList.contains(styles.dropTargetAfter)) {
      jottingElement.classList.remove(styles.dropTargetAfter)
    }
  }

  /**
   * Handle drop event on a jotting div
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} luruEvent - Luru drag event
   */
  #handleDrop(view, luruEvent) {
    var sourceEvent = luruEvent?.sourceEvent
    var jottingElement = luruEvent?.data?.jottingElement
    var draggedIndex = sourceEvent?.dataTransfer.getData(
      'application/x.jottingIndex'
    )

    sourceEvent?.preventDefault()
    jottingElement?.classList.remove(styles.dropTargetAfter)
    jottingElement?.classList.remove(styles.dropTargetBefore)

    if (sourceEvent) {
      sourceEvent.data = {
        dropPosition: this.#isDragOverTopHalf(view, sourceEvent, jottingElement)
          ? 'top-half'
          : 'bottom-half',
      }
    }

    this.#onDragDropJottingFinish(
      view,
      sourceEvent,
      document.body.querySelector(`[data-jotting-index="${draggedIndex}"]`),
      jottingElement
    )
  }

  /**
   * Create an element for setting up as the drag image.  If a question or
   * answer is dragged, the entire question block is set as the drag image
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {HTMLElement} jottingElement - A jotting element
   */
  #getDragImageElement(view, jottingElement) {
    return view.createShadowContainer(jottingElement)
  }

  /**
   * Test if drag event is in top-half or bottom-half of the drop target
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {DragEvent} - DragEvent object passed to event handler
   * @param {HTMLElement} - Jotting element
   * @returns {Boolean} - true if top-half, false if bottom-half
   */
  #isDragOverTopHalf(view, e, jottingElement) {
    if (!view || !e || !jottingElement) {
      return true
    }

    return (
      e.clientY - view.getEditorTop() + view.getEditorScrollTop() <=
      jottingElement?.offsetTop + jottingElement?.offsetHeight / 2
    )
  }

  /**
   * Handle the event of a jotting DIV dragged and dropped over another jotting
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {DragEvent} e - DragEvent customized with a 'dropPosition' parameter
   * @param {HTMLElement} sourceJotting - Dragged jotting DIV element
   * @param {HTMLElement} targetJotting - Jotting DIV element that received drop
   */
  #onDragDropJottingFinish(view, e, sourceJotting, targetJotting) {
    let sourceJottingType = view.getJottingType(sourceJotting)

    // Change answer-to-and-fro-nonanswer logic
    if (!view.isAnswerJotting(sourceJotting)) {
      let markSourceAsAnswer =
        (e.data.dropPosition === 'top-half' &&
          view.isAnswerJotting(targetJotting)) ||
        (e.data.dropPosition === 'bottom-half' &&
          view.isAnswerJotting(view.getNextJottingElement(targetJotting)))

      markSourceAsAnswer &&= sourceJottingType !== JottingType.Q

      if (markSourceAsAnswer) {
        sourceJotting.classList.add(editorStyles.answer)
        view.changeJottingType(
          sourceJotting,
          view.getAnswerType(sourceJottingType)
        )
      }
    } else {
      let prevJotting = view.getPreviousJottingElement(targetJotting)
      let unmarkSourceAsAnswer =
        (e.data.dropPosition === 'top-half' &&
          !view.isAnswerJotting(prevJotting) &&
          view.getJottingType(prevJotting) !== JottingType.Q) ||
        (e.data.dropPosition === 'bottom-half' &&
          !view.isAnswerJotting(targetJotting) &&
          view.getJottingType(targetJotting) !== JottingType.Q)

      if (unmarkSourceAsAnswer) {
        sourceJotting.classList.remove(editorStyles.answer)
        view.changeJottingType(
          sourceJotting,
          view.getNonAnswerType(sourceJottingType)
        )
      }
    }

    // Insertion logic
    if (view.getJottingType(sourceJotting) === JottingType.Q) {
      let qaBlockElements = [sourceJotting]
      let nextJottingElement = view.getNextJottingElement(sourceJotting)
      while (nextJottingElement && view.isAnswerJotting(nextJottingElement)) {
        qaBlockElements.push(nextJottingElement)
        nextJottingElement = view.getNextJottingElement(nextJottingElement)
      }
      if (e.data.dropPosition === 'top-half') {
        for (let element of qaBlockElements) {
          targetJotting.before(element)
        }
      } else {
        let insertionPoint = targetJotting
        for (let element of qaBlockElements) {
          insertionPoint.after(element)
          insertionPoint = element
        }
      }
    } else {
      if (e.data.dropPosition === 'top-half') {
        targetJotting.before(sourceJotting)
      } else {
        targetJotting.after(sourceJotting)
      }
    }
  }
}
