import { LuruReduxStore } from '../../../app/store'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import LuruUser from '../../../domain/users/LuruUser'
import LuruError, { LuruErrorName } from '../../LuruError'
import { findMatchingCrmRecords } from '../../meetings/api/findMatchingCrmRecords'
import { NotesMiddleware } from '../middleware'
import { ReduxNoteEntity } from '../types'

export const getMatchingCrmRecords = async (noteId: string) => {
  var note = await LuruReduxStore.dispatch(NotesMiddleware.fetchNote.action({ noteId })).unwrap()
  var meetingConnections = getMeetingConnections(note)

  if (meetingConnections.length === 0) {
    return undefined
  }

  var meetingId = meetingConnections[0].sor_record_id

  try {
    return await findMatchingCrmRecords({ meetingId })
  } catch (e) {
    throw new LuruError(
      LuruErrorName.ApplicationError,
      `Cannot find matching ${CrmRecord.getCrmName()} records for given meeting id: ${meetingId}`,
      e as Error
    )
  }
}

function getMeetingConnections(note: ReduxNoteEntity) {
  if (!note.connections) {
    return []
  }

  var calendar = LuruUser.getCurrentUserCalendarName()

  return note.connections.filter((connection) => connection.sor === calendar)
}
