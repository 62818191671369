import { useAppSelector } from '../../../app/hooks'
import { CrmActivity, ReduxCrmKey } from '../../crm/types'

export function useConnectedActivityByExternalUrl(external_url: string) {
  const crmKey = useAppSelector(
    (state) => state.user.data?.userSettings?.connectedCRM
  )?.name?.toLowerCase?.() as ReduxCrmKey
  const activities = useAppSelector((state) =>
    Object.values(state.crm[crmKey]?.activities?.entities || {}).filter((f) => f.data?.external_url === external_url)
  )

  return activities?.map?.((f) => f.data) as CrmActivity[]
}
