// Own libraries
import { getFullyQualifiedKey } from '../domutils/utils'
import { JottingType } from '../features/notes/types'

// Editor events
export const EditorEvent = {
  REORDER_JOTTING: 'onReorderJotting',
  MARK_TASK_COMPLETE: 'onMarkTaskComplete',
  MARK_TASK_INCOMPLETE: 'onMarkTaskInomplete',
  FOCUS_NOTE: 'onFocusNote',
  BLUR_NOTE: 'onBlurNote',
  KEYDOWN: 'onKeydown',
}

Object.freeze(EditorEvent)

/**
 * @classdesc A class for managing all editor related events
 */
export default class EditorEventsManager {
  #config = null
  #actions = null
  #eventStream = null
  #mutationObserver = null
  #previousActiveJottingElement = null

  /**
   * Initialize EditorEventsManager object and its internal data structures
   * @param {Object} config - of the following format:
   * { view }
   */
  constructor(config) {
    this.#config = config
    this.#actions = [
      this.#computeKeydownEvents(),
      this.#computeClipboardEvents(),
      this.#computeMouseDownEvents(),
      this.#computeClickEvents(),
      this.#computeFocusEvents(),
      this.#computeBlurEvents(),
      this.#computeBlurEventsForCrmFields(),
      this.#computeSelectionChangeEvents(),
      this.#computeInputEvents(),
      this.#computeDragDropEvents(),
      this.#computeCanvasEvents(),
      this.#computeLinkEvents(),
      this.#computeMutationObservation(),
    ]
    this.#eventStream = []
  }

  // Actions
  /**
   * Setup events
   */
  setup() {
    if (!this.#config?.view) {
      return
    }
    this.#actions?.forEach((action) => action?.setup.apply())
  }

  /**
   * Setup events for the given jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   */
  setupForJotting(jottingElement) {
    let actions = [
      this.#computeDragDropEventsForJotting(jottingElement),
      this.#computeMouseDownEventsForJotting(jottingElement),
      this.#computeClickEventsForJotting(jottingElement),
      this.#computeBlurEventForJotting(jottingElement),
    ]
    actions.forEach((action) => action.setup.apply())
    this.#actions.concat(actions)
  }

  setupLinkEvents(target) {
    var elements = target
    if (!Array.isArray(target)) {
      elements = [target]
    }
    for (let element of elements) {
      this.#config.linkDetailsPopup?.setupForElement(element)
    }
  }

  teardownLinkEvents(target) {
    var elements = target
    if (!Array.isArray(target)) {
      elements = [target]
    }
    for (let element of elements) {
      this.#config.linkDetailsPopup?.teardownForElement(element)
    }
  }

  /**
   * Teardown events
   */
  teardown() {
    if (!this.#config?.view) {
      return
    }
    this.#actions.forEach((action) => action?.teardown.apply())
  }

  /**
   * Central function to receive all UI events, compute what is to be done, and
   * send event data upwards to controller
   * @param {UIEvent} event - Source event
   * @param {string} sourceName - Optional source element name
   */
  #processEvent(event, sourceName = null) {
    let luruEvent = {
      sourceEvent: event,
      data: this.#computeEventDetails(event),
      sourceName,
    }

    if (event.type === 'keydown' || event.type === 'mousedown') {
      this.#eventStream = []
    }

    if (
      luruEvent.data.jottingElement ||
      luruEvent.data.resetSelection.start ||
      luruEvent.data.resetSelection.end ||
      !luruEvent.data.range?.collapsed ||
      ['cut', 'copy', 'paste'].includes(luruEvent.sourceEvent.type)
    ) {
      // Don't push consecutive selectionchange events as selectionchange event
      // handler may be changing selection
      if (
        luruEvent.sourceEvent.type === 'selectionchange' &&
        this.#eventStream.length > 1 &&
        this.#eventStream[this.#eventStream.length - 1].sourceEvent.type ===
          'selectionchange'
      ) {
        return
      }
      this.#eventStream.push(luruEvent)
      this.#config.eventHandler(this.#eventStream)
    }
  }

  // Computations

  /**
   *
   * @returns {Object} - With setup and teardown functions to add and remove
   * keydown event listeners
   */
  #computeCanvasEvents() {
    let editorRoot = this.#config?.view?.getEditorRootElement()
    let canvas = editorRoot?.parentElement
    let onClick = (e) => {
      this.#processEvent(e)
    }
    onClick = onClick.bind(this)
    return {
      setup: () => canvas?.addEventListener('click', onClick),
      teardown: () => canvas?.removeEventListener('click', onClick),
    }
  }

  /**
   * Compute logic to setup and teardown keydown event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * keydown event listeners
   */
  #computeKeydownEvents() {
    let editorRoot = this.#config?.view?.getEditorRootElement()
    let onKeydown = (e) => {
      this.#processEvent(e)
    }
    onKeydown = onKeydown.bind(this)
    return {
      setup: () => editorRoot?.addEventListener('keydown', onKeydown),
      teardown: () => editorRoot?.removeEventListener('keydown', onKeydown),
    }
  }

  /**
   * Compute logic to setup and teardown clipboard event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * clipboard event listeners
   */
  #computeClipboardEvents() {
    let editorRoot = this.#config?.view?.getEditorRootElement()
    let onClipboardEvent = (e) => {
      this.#processEvent(e)
    }
    onClipboardEvent = onClipboardEvent.bind(this)
    return {
      setup: () => {
        editorRoot?.addEventListener('cut', onClipboardEvent)
        editorRoot?.addEventListener('copy', onClipboardEvent)
        editorRoot?.addEventListener('paste', onClipboardEvent)
      },
      teardown: () => {
        editorRoot?.removeEventListener('cut', onClipboardEvent)
        editorRoot?.removeEventListener('copy', onClipboardEvent)
        editorRoot?.removeEventListener('paste', onClipboardEvent)
      },
    }
  }

  /**
   * Compute logic to setup and teardown focus event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * focus event listeners
   */
  #computeFocusEvents() {
    let editorRoot = this.#config?.view?.getEditorRootElement()
    let onFocus = (e) => {
      this.#processEvent(e)
    }
    onFocus = onFocus.bind(this)
    return {
      setup: () => editorRoot?.addEventListener('focus', onFocus),
      teardown: () => editorRoot?.removeEventListener('focus', onFocus),
    }
  }

  /**
   * Compute logic to setup and teardown blur event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * blur event listeners
   */
  #computeBlurEvents() {
    let editorRoot = this.#config?.view?.getEditorRootElement()
    let onBlur = (e) => {
      this.#processEvent(e)
    }
    onBlur = onBlur.bind(this)
    return {
      setup: () => editorRoot?.addEventListener('blur', onBlur),
      teardown: () => editorRoot?.removeEventListener('blur', onBlur),
    }
  }

  /**
   * Compute logic to setup and teardown selectionchange event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * selectionchange event listeners
   */
  #computeSelectionChangeEvents() {
    let onSelectionChange = (e) => {
      this.#processEvent(e)
    }
    onSelectionChange = onSelectionChange.bind(this)
    return {
      setup: () =>
        document.addEventListener('selectionchange', onSelectionChange),
      teardown: () =>
        document.removeEventListener('selectionchange', onSelectionChange),
    }
  }

  /**
   * Compute logic to setup and teardown 'input' event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * 'input' event listeners
   */
  #computeInputEvents() {
    let editorRoot = this.#config?.view?.getEditorRootElement()
    let onInput = (e) => {
      this.#processEvent(e)
    }
    onInput = onInput.bind(this)
    return {
      setup: () => editorRoot?.addEventListener('input', onInput),
      teardown: () => editorRoot?.removeEventListener('input', onInput),
    }
  }

  /**
   * Compute mousedown event for a given prefix element
   */
  computePrefixMousedownEvent(prefixElement) {
    let onMouseDown = (e) => {
      this.#processEvent(e)
    }
    onMouseDown = onMouseDown.bind(this)
    return {
      setup: () => prefixElement?.addEventListener('mousedown', onMouseDown),
      teardown: () =>
        prefixElement?.removeEventListener('mousedown', onMouseDown),
    }
  }

  /**
   * Compute logic to setup and teardown mousedown event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * mousedown event listeners
   */
  #computeMouseDownEvents() {
    let jottingElements = this.#config?.view?.getJottingElementsList()
    if (!jottingElements) {
      return null
    }

    let handlers = Array.from(jottingElements)
      .map((jotting) => this.#computeMouseDownEventsForJotting(jotting))
      .reduce((result, handler) => result.concat(handler), [])

    return {
      setup: () => [...handlers].forEach((handler) => handler.setup()),
      teardown: () => [...handlers].forEach((handler) => handler.teardown()),
    }
  }

  /**
   * Compute logic to setup and teardown click event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * click event listeners
   */
  #computeClickEvents() {
    let jottingElements = this.#config?.view?.getJottingElementsList()
    if (!jottingElements) {
      return null
    }

    let handlers = Array.from(jottingElements)
      .map((jotting) => this.#computeClickEventsForJotting(jotting))
      .reduce((result, handler) => result.concat(handler), [])

    return {
      setup: () => [...handlers].forEach((handler) => handler.setup()),
      teardown: () => [...handlers].forEach((handler) => handler.teardown()),
    }
  }
  /**
   * Compute logic to setup and teardown mousedown event listeners for a jotting
   * @returns {Object} - With setup and teardown functions to add and remove
   * mousedown event listeners
   */
  #computeMouseDownEventsForJotting(jottingElement) {
    let noteElement = this.#config?.view?.getNoteElement(jottingElement)
    let prefixElement = this.#config?.view?.getPrefixElement(jottingElement)
    let deleteButton = this.#config?.view?.getDeleteButton(jottingElement)
    let onMouseDown = (e) => {
      this.#processEvent(e)
    }
    onMouseDown = onMouseDown.bind(this)
    return {
      setup: () =>
        [noteElement, prefixElement, deleteButton].forEach((element) =>
          element?.addEventListener('mousedown', onMouseDown)
        ),
      teardown: () =>
        [noteElement, prefixElement, deleteButton].forEach((element) =>
          element?.removeEventListener('mousedown', onMouseDown)
        ),
    }
  }

  /**
   * Compute logic to setup and teardown click event listeners for a jotting
   * @returns {Object} - With setup and teardown functions to add and remove
   * mousedown event listeners
   */
  #computeClickEventsForJotting(jottingElement) {
    let checkboxes = this.#config?.view?.getCheckboxes(jottingElement)
    if (!checkboxes) {
      return {
        setup: () => {},
        teardown: () => {},
      }
    }
    let onClick = (e) => {
      this.#processEvent(e)
    }
    onClick = onClick.bind(this)
    return {
      setup: () =>
        Array.from(checkboxes).forEach((element) =>
          element?.addEventListener('click', onClick)
        ),
      teardown: () =>
        Array.from(checkboxes).forEach((element) =>
          element?.removeEventListener('click', onClick)
        ),
    }
  }

  /**
   * Compute logic to setup and teardown mousedown event listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * mousedown event listeners
   */
  #computeBlurEventsForCrmFields() {
    let jottingElements = this.#config?.view?.getJottingElementsList()
    if (!jottingElements) {
      return
    }

    let handlers = Array.from(jottingElements)
      .map((jotting) => this.#computeBlurEventForJotting(jotting))
      .reduce((result, handler) => result.concat(handler), [])

    return {
      setup: () => [...handlers].forEach((handler) => handler.setup()),
      teardown: () => [...handlers].forEach((handler) => handler.teardown()),
    }
  }

  /**
   * Compute logic to setup and teardown focus/blur event listeners for jotting
   * @returns {Object} - With setup and teardown functions to add and remove
   * focus/blur event listeners
   */
  #computeBlurEventForJotting(jottingElement) {
    let jottingType = this.#config?.view?.getJottingType(jottingElement)

    if (
      jottingType !== JottingType.CRM_FIELD_VALUE &&
      jottingType !== JottingType.A_CRM_FIELD_VALUE
    ) {
      return { setup: () => {}, teardown: () => {} }
    }

    let inputElement = this.#config?.view?.getInputElement(jottingElement)

    if (!inputElement) {
      return { setup: () => {}, teardown: () => {} }
    }

    let onBlur = (e) => {
      this.#processEvent(e, 'crm-field')
    }
    onBlur = onBlur.bind(this)

    return {
      setup: () => inputElement?.addEventListener('blur', onBlur),
      teardown: () => inputElement?.removeEventListener('blur', onBlur),
    }
  }

  /**
   * Compute logic to setup and teardown drag and dropevent listeners
   * @returns {Object} - With setup and teardown functions to add and remove
   * drag-and-drop event listeners
   */
  #computeDragDropEvents() {
    let jottingElements = this.#config?.view?.getJottingElementsList()
    if (!jottingElements) {
      return
    }

    let handlers = Array.from(jottingElements)
      .map((jotting) => this.#computeDragDropEventsForJotting(jotting))
      .reduce((result, handler) => result.concat(handler), [])

    return {
      setup: () => [...handlers].forEach((handler) => handler.setup()),
      teardown: () => [...handlers].forEach((handler) => handler.teardown()),
    }
  }

  #computeDragDropEventsForJotting(jottingElement) {
    let processEventDrag = (e) => this.#processEvent(e, 'drag')
    let processEventNote = (e) => this.#processEvent(e, 'note')
    let processEventJotting = (e) => this.#processEvent(e, 'jotting')
    processEventDrag = processEventDrag.bind(this)
    processEventNote = processEventNote.bind(this)
    processEventJotting = processEventJotting.bind(this)

    let eventStructure = [
      {
        element: this.#config?.view?.getDragElement(jottingElement),
        types: ['dragstart', 'dragend'],
        handler: processEventDrag,
      },
      {
        element: this.#config?.view?.getNoteElement(jottingElement),
        types: ['dragenter', 'dragover', 'dragleave', 'drop'],
        handler: processEventNote,
      },
      {
        element: jottingElement,
        types: ['dragstart', 'dragenter', 'dragover', 'dragleave', 'drop'],
        handler: processEventJotting,
      },
    ]

    return {
      setup: () => {
        eventStructure.forEach(({ element, types, handler }) =>
          types.forEach((eventType) => {
            element?.addEventListener(eventType, handler)
          })
        )
      },
      teardown: () => {
        eventStructure.forEach(({ element, types, handler }) =>
          types.forEach((eventType) =>
            element?.removeEventListener(eventType, handler)
          )
        )
      },
    }
  }

  #computeLinkEvents() {
    return {
      setup: () => {
        let links = Array.from(this.#config?.view?.getLinks() ?? [])
        this.setupLinkEvents(links)
      },
      teardown: () => {
        let links = Array.from(this.#config?.view?.getLinks() ?? [])
        this.teardownLinkEvents(links)
      },
    }
  }

  #computeMutationObservation() {
    this.onCanvasMutation = this.onContentMutated.bind(this)
    return {
      setup: () => {
        if (!this.#config?.view) {
          return
        }
        this.#mutationObserver = new MutationObserver((x) =>
          this.onContentMutated(x)
        )
        this.#mutationObserver.observe(
          this.#config.view.getEditorRootElement(),
          {
            // characterData: true,
            // attributes: true,
            // attributeOldValue: true,
            childList: true,
            subtree: true,
          }
        )
      },
      teardown: () => {
        if (!this.#mutationObserver) {
          return
        }
        let unprocessedMutations = this.#mutationObserver.takeRecords()
        this.onContentMutated(unprocessedMutations)
        this.#mutationObserver.disconnect()
      },
    }
  }

  onContentMutated(mutationList) {
    // console.log(`EditorEventsManager:onContentMutated:`, mutationList)

    // Remove event listeners for link elements
    this.teardownLinkEvents(
      mutationList
        .filter((mutation) => mutation.type === 'childList')
        .filter((mutation) => mutation.removedNodes.length > 0)
        .map((mutation) =>
          Array.from(mutation.removedNodes).filter(
            (element) =>
              element?.getAttribute &&
              element.getAttribute('data-luru-role') === 'note-hyperlink'
          )
        )
        .reduce((prev, curr) => prev.concat(curr), [])
    )
  }

  /**
   * Compute details of a note editor event.  If event occured outside of
   * editor container, then ignore.
   * @param {UIEvent} e - Any UIEvent fired by browser
   * @return {Object} - Object with details of event including the note &
   * jotting elements where the event occured and the current range object
   */
  #computeEventDetails(e) {
    let range = null
    let noteElement = null
    let prefixElement = null
    let jottingElement = null
    let resetSelection = { start: false, end: false }

    if (e instanceof DragEvent) {
      let target = e.target
      if (this.#config?.view?.isNoteElement(target)) {
        noteElement = target
        jottingElement = this.#config?.view?.getJottingElement(noteElement)
      } else if (this.#config?.view?.isJottingElement(target)) {
        jottingElement = target
      } else {
        jottingElement = this.#config?.view?.getJottingElement(target)
      }
    } else if (e instanceof MouseEvent) {
      noteElement = this.#config?.view?.getContainingNoteElement(e.target)
      prefixElement = this.#config?.view?.getContainingPrefixElement(e.target)
    } else {
      range =
        document.getSelection().rangeCount >= 1
          ? document.getSelection().getRangeAt(0)
          : null
      let rangeSource = range ? range.commonAncestorContainer : null
      noteElement = rangeSource
        ? this.#config?.view?.getContainingNoteElement(rangeSource)
        : null
      prefixElement =
        noteElement === null
          ? this.#config?.view?.getContainingPrefixElement(rangeSource)
          : null

      if (!noteElement && !prefixElement && !range?.collapsed && range) {
        // We have a selection that spans across multiple jottings
        if (e.type === 'keydown' || e.type === 'selectionchange') {
          resetSelection = this.computeSelectionReset(e, range)
        }
      }
    }

    jottingElement = noteElement
      ? this.#config?.view?.getJottingElement(noteElement)
      : prefixElement
      ? this.#config?.view?.getJottingElement(prefixElement)
      : jottingElement

    let dragElement = jottingElement
      ? this.#config?.view.getDragElement(jottingElement)
      : null

    let taskBlurred = false
    let blurredTaskJotting = undefined

    if (
      this.#previousActiveJottingElement !== jottingElement ||
      e.type === 'blur'
    ) {
      let prevJottingType = this.#config?.view?.getJottingType(
        this.#previousActiveJottingElement
      )
      let taskJottingTypes = [
        JottingType.TASK_COMPLETE,
        JottingType.TASK_INCOMPLETE,
        JottingType.A_TASK_COMPLETE,
        JottingType.A_TASK_INCOMPLETE,
      ]

      taskBlurred =
        taskJottingTypes.includes(prevJottingType) &&
        e.type === 'selectionchange' &&
        e.target === document &&
        e.currentTarget === document

      if (taskBlurred) {
        blurredTaskJotting = this.#previousActiveJottingElement
      }
    }
    // Store the current jotting element as previously active jotting element
    this.#previousActiveJottingElement = jottingElement

    let result = {
      jottingElement,
      dragElement,
      prefixElement,
      noteElement,
      range,
      resetSelection,
      taskBlurred,
      blurredTaskJotting,
    }

    // if (e.type === "keydown") {
    //   console.log(
    //     `computeEventDetails:`,
    //     `Is selection?:`,
    //     !result.range.collapsed,
    //     `Process selection?:`,
    //     result.resetSelection,
    //     `jottingElement:`,
    //     result.jottingElement,
    //     `noteElement:`,
    //     result.noteElement,
    //     `prefixElement:`,
    //     result.prefixElement
    //   );
    // }

    return result
  }

  /**
   * Check if it is required to change the selection to be contained inside
   * editor root element
   * @param {UIEvent} event - Event for which details are computed
   * @param {Range} selectionRange - Current range
   */
  computeSelectionReset(event, selectionRange) {
    let view = this.#config?.view
    let editTable = view.getEditorRootElement()
    let editCanvas = editTable?.parentElement
    let tableSelector = `[data-role="editing-table"]`

    let isSelectionAboveTable
    try {
      isSelectionAboveTable =
        selectionRange.commonAncestorContainer?.querySelector(tableSelector) !==
        null
    } catch (e) {
      isSelectionAboveTable = false
    }

    let resetBoth =
      (selectionRange.startContainer === editTable &&
        selectionRange.endContainer === editTable) ||
      (selectionRange.startContainer === editCanvas &&
        selectionRange.endContainer === editCanvas) ||
      isSelectionAboveTable

    if (resetBoth) {
      return { start: true, end: true }
    }

    if (event.type === 'keydown') {
      let triggerKey = getFullyQualifiedKey(event)
      if (['ShiftArrowDown', 'CtrlShiftArrowDown'].includes(triggerKey)) {
        if (
          view.isLastNote(
            view.getContainingNoteElement(selectionRange.endContainer)
          )
        ) {
          // At last note and still want to go down
          return { start: false, end: true }
        }
      }
      if (['ShiftArrowUp', 'CtrlShiftArrowUp'].includes(triggerKey)) {
        if (
          view.isFirstNote(
            view.getContainingNoteElement(selectionRange.startContainer)
          )
        ) {
          // At first note and still want to go up
          return { start: true, end: false }
        }
      }
    }

    return { start: false, end: false }
  }
}
