import React from 'react'
import { CrmFieldSchema } from '../../../../../../domain/crmRecord/typings.d'
import LuruTextBox from '../../../../../ui/LuruTextBox'
import CrmMultiObjectFieldChooserEventHandler from '../../CrmMultiObjectFieldChooserEventHandler'
import styles from './styles.module.css'
import { CrmMultiObjectFieldChooserRefs } from '../../CrmMultiObjectFieldChooserComponent'
import searchIcon from '../../../../../../images/search.png'
import LuruButton from '../../../../../ui/LuruButton'
import lockIcon from '../../../../../../images/lock.svg'
import checkIcon from '../../../../../../images/fluent/checkmark.svg'

import { EntityStatus } from '../../../../../../app/types'
import { CRMProvider } from '../../../../../../features/user/types'
import LuruUser from '../../../../../../domain/users/LuruUser'
import { CrmRecordType } from '../../../../../../features/crm/types'
import CrmRecord from '@/domain/crmRecord/CrmRecord'
export type AvailableFieldsListProps = {
  selectedCrmFieldSchema: Partial<CrmFieldSchema & { crmObjectName: string }>
  selectedItems: Array<string>
  crmRecordType: CrmRecordType
  selectedCrmRecordType: CrmRecordType
  availableFields: CrmFieldSchema[]
  eventHandler: CrmMultiObjectFieldChooserEventHandler
  globalComponentRefs: CrmMultiObjectFieldChooserRefs
  disabled?: boolean
  entityStatus: EntityStatus
  lockedNonUpdatableFields?: boolean
  lockedNonCreatableFields?: boolean
}

export type AvailableFieldsListState = {
  highlightedIndex: number
  filteredAvailableFields: CrmFieldSchema[]
  filterText: string
}

const MAX_ITEMS = 20

export interface AvailableFieldsListEvents {
  onFilterBoxChange: (value: string | null) => void
  onFilterBoxNavigateDown: () => void
  onFilterBoxNavigateUp: () => void
  onFilterBoxReturn: () => void
  onBlur: () => void
  onFieldToggle: (targetFieldName: string) => void
  onForceRefreshSchema: () => void
}

export default class AvailableFieldsList extends React.Component<AvailableFieldsListProps, AvailableFieldsListState> {
  props: AvailableFieldsListProps
  state: AvailableFieldsListState
  handlers: AvailableFieldsListEvents
  #crmProvider: CRMProvider | undefined
  #crmCommonName: string | undefined

  constructor(props: AvailableFieldsListProps) {
    super(props)
    this.props = props

    this.state = {
      filterText: '',
      highlightedIndex: -1,
      filteredAvailableFields: props.availableFields || [],
    }

    this.handlers = {
      onFilterBoxChange: this.#onFilterBoxChange.bind(this),
      onFilterBoxNavigateUp: this.#onFilterBoxNavigateUp.bind(this),
      onFilterBoxNavigateDown: this.#onFilterBoxNavigateDown.bind(this),
      onFilterBoxReturn: this.#onFilterBoxReturn.bind(this),
      onBlur: this.#onBlur.bind(this),
      onFieldToggle: this.#onFieldToggle.bind(this),
      onForceRefreshSchema: this.#onForceRefreshSchema.bind(this),
    }

    this.#crmProvider = LuruUser.getCurrentUserCrmName()
    this.#crmCommonName = LuruUser.getCurrentUserCrmCommonName()
  }

  componentDidUpdate = (prevProps: AvailableFieldsListProps) => {
    if (prevProps.availableFields !== this.props.availableFields) {
      this.setState({
        filteredAvailableFields: this.props.availableFields || [],
      })
    }

    if (prevProps.entityStatus !== EntityStatus.Loaded && this.props.entityStatus === EntityStatus.Loaded) {
      this.props.globalComponentRefs.availableFiledFilterBox?.current?.focus?.()
    }

    this.#navigateToSelectedItem()
  }

  #onFilterBoxChange = (value: string | null) => {
    var filterText = value ?? ''

    this.setState({
      filteredAvailableFields:
        this.props.availableFields.filter(
          (item) => item.label.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
        ) || [],
      highlightedIndex: -1,
      filterText: filterText,
    })
  }

  #onFilterBoxReturn = () => {
    const { crmRecordType, selectedCrmRecordType, selectedCrmFieldSchema, eventHandler } = this.props

    var chosenIndex = this.state.highlightedIndex

    if (chosenIndex >= 0 && chosenIndex < this.state.filteredAvailableFields.length) {
      let chosenItem = this.state.filteredAvailableFields[chosenIndex]
      var targetFieldName = chosenItem.name

      if (selectedCrmRecordType !== crmRecordType) {
        targetFieldName = `${selectedCrmFieldSchema.name}.${targetFieldName}`
      }

      eventHandler.handlers.toggleFieldSelection(targetFieldName)
    }
  }

  #onFilterBoxNavigateUp = () => {
    var nextIndex =
      this.state.highlightedIndex <= -1
        ? this.state.filteredAvailableFields.length - 1
        : this.state.highlightedIndex - 1

    this.setState({ highlightedIndex: nextIndex })
  }

  #onFilterBoxNavigateDown = () => {
    var nextIndex =
      this.state.highlightedIndex >= this.state.filteredAvailableFields.length - 1
        ? -1
        : this.state.highlightedIndex + 1

    this.setState({ highlightedIndex: nextIndex })
  }

  #onBlur = () => {
    this.setState({ highlightedIndex: -1 })
  }

  #onFieldToggle(targetFieldName: string) {
    const { eventHandler, globalComponentRefs } = this.props
    eventHandler.handlers?.toggleFieldSelection?.(targetFieldName)
    // Focus searchbox and select text inside it
    this.props.globalComponentRefs.availableFiledFilterBox?.current?.focus?.()
    globalComponentRefs.availableFiledFilterBox?.current?.select?.()
  }

  #onForceRefreshSchema() {
    const { selectedCrmRecordType, eventHandler, globalComponentRefs } = this.props
    eventHandler.handlers?.loadSchema?.(selectedCrmRecordType, true)

    // Focus searchbox and select text inside it
    this.props.globalComponentRefs.availableFiledFilterBox?.current?.focus?.()
    globalComponentRefs.availableFiledFilterBox?.current?.select?.()
  }

  #navigateToSelectedItem = () => {
    // var ulElement = this.props.globalComponentRefs.availableFiledsList.current as HTMLUListElement

    // var liElement = ulElement?.querySelector('li[data-highlighted="yes"]') as HTMLLIElement

    // if (liElement) {
    //   let topOverflow = Math.max(0, ulElement.scrollTop + liElement.offsetHeight - liElement.offsetTop)

    //   let bottomOverflow = Math.max(
    //     0,
    //     liElement.offsetTop + liElement.offsetHeight - ulElement.scrollTop - ulElement.offsetHeight
    //   )

    //   if (topOverflow !== 0) {
    //     // @ts-ignore
    //     ulElement.scrollBy({ top: -topOverflow, behavior: 'instant' })
    //   } else if (bottomOverflow !== 0) {
    //     // @ts-ignore
    //     ulElement.scrollBy({ top: bottomOverflow, behavior: 'instant' })
    //   }
    // }
    // New scroll logic
    var ulElement = this.props.globalComponentRefs.availableFiledsList.current as HTMLUListElement
    var liElements = ulElement?.querySelectorAll?.('li[data-highlighted="yes"]') || []
    var liElement = liElements?.item?.(liElements.length - 1) as HTMLLIElement

    if (liElement) {
      liElement?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
    }
  }

  renderList = () => {
    const {
      selectedItems,
      crmRecordType,
      selectedCrmRecordType,
      selectedCrmFieldSchema,
      eventHandler,
      globalComponentRefs,
      entityStatus,
      lockedNonUpdatableFields,
      lockedNonCreatableFields,
    } = this.props

    const { filteredAvailableFields } = this.state
    var objectName = CrmRecord.getCrmRecordName(selectedCrmRecordType) || selectedCrmRecordType

    if (filteredAvailableFields.length === 0) {
      return (
        <div>
          <div className={styles.emptyFields}>No fields available</div>
          {this.state.filterText && !['global', 'Global'].includes(objectName) && (
            <li className={[styles.refreshSchemaInfoContainer].join(' ')}>
              <span>Did not find some field?</span>
              <span>
                If you added a custom field in {this.#crmCommonName || 'CRM'} recently, you can{' '}
                <span className={styles.refreshLink} onClick={this.handlers.onForceRefreshSchema}>
                  refresh your {objectName} schema
                </span>
              </span>
            </li>
          )}
        </div>
      )
    }

    if (entityStatus === EntityStatus.Loading) {
      return <div className={styles.loadingContainer}>Loading...</div>
    } else if (entityStatus === EntityStatus.ErrorLoading) {
      return (
        <div className={styles.errorContainer}>
          <div>Error occured</div>
          <LuruButton
            title='Try again'
            onClick={() => {
              eventHandler.handlers?.loadSchema?.(selectedCrmRecordType)
            }}
          >
            Try Again
          </LuruButton>
        </div>
      )
    } else if (entityStatus === EntityStatus.Loaded) {
      const filteredFieldsBasedOnFilterText =
        filteredAvailableFields?.filter?.(
          (item) => item.label.toLowerCase().indexOf(this.state.filterText.toLowerCase()) !== -1
        ) || []
      return (
        <ul className={[styles.availableFieldsContainer].join(' ')} ref={globalComponentRefs.availableFiledsList}>
          {filteredFieldsBasedOnFilterText
            ?.filter((_, ix) => ix < MAX_ITEMS)
            ?.map?.((f, index) => {
              //Case1: If selected crm type is base crm type that comes from props
              //Don't added dot(.) in fieldName if not add dot(.) in fieldName
              var targetFieldName = f.name
              if (selectedCrmRecordType !== crmRecordType) {
                targetFieldName = `${selectedCrmFieldSchema.name}.${targetFieldName}`
              }
              const isSelected = selectedItems.find((i) => i === targetFieldName)
              const isSelectable = lockedNonUpdatableFields
                ? f.updateable === true
                : lockedNonCreatableFields
                ? f.nonCreateable === false
                : true
              return (
                <li
                  className={styles.fieldItem}
                  data-highlighted={index === this.state.highlightedIndex ? 'yes' : 'no'}
                  data-locked={!isSelectable}
                  key={`${targetFieldName}-${index}`}
                  onClick={() => isSelectable && this.handlers.onFieldToggle(targetFieldName)}
                >
                  <label title={f.label}>{f.label}</label>
                  {!isSelectable && (
                    <img src={lockIcon} alt='Not updateable' title='Not updateable' className={styles.lockIcon} />
                  )}
                  {isSelected && <img src={checkIcon} alt='done' className={styles.checkedIcon} />}
                </li>
              )
            })}

          {filteredFieldsBasedOnFilterText?.length > MAX_ITEMS && (
            <li className={[styles.fieldItem, styles.dataMoreLabel].join(' ')}>
              <em>and {filteredFieldsBasedOnFilterText.length - MAX_ITEMS} more fields</em>
            </li>
          )}

          {this.state.filterText && !['global', 'Global'].includes(objectName) && (
            <li className={[styles.refreshSchemaInfoContainer].join(' ')}>
              <span>Did not find some field?</span>
              <span>
                If you added a custom field in {this.#crmCommonName || 'CRM'} recently, you can{' '}
                <span className={styles.refreshLink} onClick={this.handlers.onForceRefreshSchema}>
                  refresh your {objectName} schema
                </span>
              </span>
            </li>
          )}
        </ul>
      )
    }
  }

  render = () => (
    <div>
      <div className={[styles.fieldItem, styles.availableFiledsFilterTextInputItemContainer].join(' ')}>
        <img src={searchIcon} alt='New record' />
        <LuruTextBox
          placeholder={'Search available ' + (this.#crmProvider === CRMProvider.HUBSPOT ? 'properties' : 'fields')}
          additionalClassNames={[styles.availableFiledsFilterTextInput]}
          ref={this.props.globalComponentRefs.availableFiledFilterBox}
          onChange={this.handlers.onFilterBoxChange}
          onNavigateDown={this.handlers.onFilterBoxNavigateDown}
          readOnly={
            this.props.disabled ||
            this.props.entityStatus === EntityStatus.Loading ||
            this.props.entityStatus === EntityStatus.ErrorLoading
          }
          onNavigateUp={this.handlers.onFilterBoxNavigateUp}
          onReturn={this.handlers.onFilterBoxReturn}
          onBlur={this.handlers.onBlur}
        />
      </div>

      {this.renderList()}
    </div>
  )
}
