import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { WorkflowsAPI } from '../api'
import { ReduxWorkflowsState, PauseUnpauseWorkflowParams, ReduxV2WorkflowEntity } from '../types'

export interface PauseUnpauseWorkflowAPIResponse {
  data: ReduxV2WorkflowEntity
  metadata: null
  error_data: null | { message: string }
}

export const pauseUnpauseV2Workflow = {
  action: createAsyncThunk<
    PauseUnpauseWorkflowAPIResponse['data'],
    PauseUnpauseWorkflowParams,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: PauseUnpauseWorkflowAPIResponse['metadata']
      rejectedMeta: PauseUnpauseWorkflowAPIResponse['metadata']
    }
  >('workflows/pauseUnpauseV2Workflow', async (params, { fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await WorkflowsAPI.pauseUnpauseWorkflow(params)) as PauseUnpauseWorkflowAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue({ message: (e as Error)?.message ?? 'Error creating alert' }, null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(pauseUnpauseV2Workflow.action.pending, (state, action) => {
      if (state.entities[action.meta.arg.workflow_id]) {
        state.entities[action.meta.arg.workflow_id].status = EntityStatus.Updating
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(pauseUnpauseV2Workflow.action.fulfilled, (state, action) => {
      const workflow = action.payload

      state.entities[workflow.workflow_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...workflow,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(pauseUnpauseV2Workflow.action.rejected, (state, action) => {
      if (state.entities[action.meta.arg.workflow_id]) {
        state.entities[action.meta.arg.workflow_id].status = EntityStatus.ErrorUpdating
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    pauseUnpauseV2Workflow.addPendingCase(builder)
    pauseUnpauseV2Workflow.addFulfilledCase(builder)
    pauseUnpauseV2Workflow.addRejectedCase(builder)
  },
}
