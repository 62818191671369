import { useAppSelector } from '@/app/hooks'
import luruLogo from '@/images/luru/logoMain.png'
import styles from './UserAdminTasksWizard.module.css'
import LuruButton from '@/primitives/ui/LuruButton'
import { useCallback, useState } from 'react'
import { CalendarProvider, MessagingProvider } from '@/features/user/types'
import MicrosoftTeamsCard from '../settings/sections/Integrations/Messaging/MicrosoftTeamsCard'
import SlackCard from '../settings/sections/Integrations/Messaging/SlackCard'
import GChatCard from '../settings/sections/Integrations/Messaging/GChatCard'
import GoogleCalendar from '../settings/sections/Integrations/Calendar/GoogleCalendarCard'
import O365Calendar from '../settings/sections/Integrations/Calendar/O365CalendarCard'
import { useConnectedMessagingProvider } from '@/features/user/hooks/useConnectedMessagingProvider'
import { useConnectedCalendar } from '@/features/user/hooks/useConnectedCalendar'

export default function UserAdminTasksWizard() {
  const userAdminPendingTasks = useAppSelector((state) =>
    state.user.data.userAdminTasks.filter((task) => task.status === 'PENDING')
  )
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const handleMoveToNextStep = useCallback(
    () => setCurrentStep((step) => (step < userAdminPendingTasks.length - 1 ? step + 1 : step)),
    [userAdminPendingTasks]
  )
  const handleMoveToPrevStep = useCallback(() => setCurrentStep((step) => (step >= 0 ? step - 1 : step)), [])

  return (
    <div className={styles.parent}>
      <div>
        <header>
          <h1>Welcome to Luru!</h1>
          <img src={luruLogo} title='Luru' alt='Luru' />
        </header>
        <section>
          <StepDetails index={currentStep} />
        </section>
        <footer>
          {currentStep === -1 ? (
            <div></div>
          ) : (
            <LuruButton title='Previous' onClick={handleMoveToPrevStep} variant='outline'>
              Previous
            </LuruButton>
          )}

          {currentStep === userAdminPendingTasks.length - 1 ? (
            <div></div>
          ) : (
            <LuruButton title='Continue' onClick={handleMoveToNextStep}>
              Continue
            </LuruButton>
          )}
        </footer>
      </div>
    </div>
  )
}

function StepDetails({ index }: { index: number }) {
  const userAdminPendingTasks = useAppSelector((state) =>
    state.user.data.userAdminTasks.filter((task) => task.status === 'PENDING')
  )

  return index === -1 ? (
    <IntroStep stepCount={userAdminPendingTasks.length} />
  ) : (
    <Step {...userAdminPendingTasks[index]} />
  )
}

function IntroStep({ stepCount }: { stepCount: number }) {
  const tasks = useAppSelector((state) => state.user.data.userAdminTasks.filter((task) => task.status === 'PENDING'))
  const getSorName = useCallback(({ sor }: { sor: string }) => {
    if (sor === MessagingProvider.GCHAT) {
      return 'Google Chat'
    } else if (sor === MessagingProvider.MSTEAMS) {
      return 'Microsoft Teams'
    } else if (sor === MessagingProvider.SLACK) {
      return 'Slack'
    } else if (sor === CalendarProvider.GCAL) {
      return 'Google Calendar'
    } else if (sor === CalendarProvider.O365CAL) {
      return 'Microsoft Outlook Calendar'
    }
    return sor
  }, [])
  return (
    <>
      <p>
        There {stepCount === 1 ? 'is' : 'are'} {stepCount} pending setup tasks configured by your administrator. Please
        complete them to continue.
      </p>
      <ol>
        {tasks
          .filter((task) => task.name === 'CONNECT_SOR')
          .map((task) => (
            <li key={task.name}>Connect to {getSorName(task.data as { sor: string })}</li>
          ))}
      </ol>
    </>
  )
}

function Step({ name, status, data }: { name: string; status: string; data: {} }) {
  if (name === 'CONNECT_SOR') {
    const messagingProviders = [MessagingProvider.SLACK, MessagingProvider.GCHAT, MessagingProvider.MSTEAMS]
    const calendarProviders = [CalendarProvider.GCAL, CalendarProvider.O365CAL]
    const connectSorData: { sor: string } = data as { sor: string }

    if (messagingProviders.includes(connectSorData.sor as MessagingProvider)) {
      return <ConnectMessagingProvider messagingProvider={connectSorData.sor as MessagingProvider} />
    }

    if (calendarProviders.includes(connectSorData.sor as CalendarProvider)) {
      return <ConnectCalendarProvider calendarProvider={connectSorData.sor as CalendarProvider} />
    }

    return <>Unknown configuration setup: {connectSorData.sor}</>
  }
  return null
}

function ConnectMessagingProvider({ messagingProvider }: { messagingProvider: MessagingProvider }) {
  var connectSorComponent = <></>
  var disconnectSorComponent = <></>
  var currentMessagingProvider = useConnectedMessagingProvider()
  var shouldDisconnect = Boolean(currentMessagingProvider)

  switch (messagingProvider) {
    case MessagingProvider.MSTEAMS:
      connectSorComponent = (
        <>
          <h2>Connect to Microsoft Teams</h2>
          <MicrosoftTeamsCard />
        </>
      )
      break
    case MessagingProvider.SLACK:
      connectSorComponent = (
        <>
          <h2>Connect to Slack</h2>
          <SlackCard />
        </>
      )
      break
    case MessagingProvider.GCHAT:
      connectSorComponent = (
        <>
          <h2>Connect to Google Chat</h2>
          <GChatCard />
        </>
      )
      break
    default:
      connectSorComponent = <>Unexcepted error: Requirement to connect {messagingProvider} not recognized</>
  }

  if (shouldDisconnect) {
    switch (currentMessagingProvider) {
      case MessagingProvider.MSTEAMS:
        disconnectSorComponent = (
          <>
            <h2>Disconnect from Microsoft Teams to proceed</h2>
            <MicrosoftTeamsCard />
          </>
        )
        break
      case MessagingProvider.SLACK:
        disconnectSorComponent = (
          <>
            <h2>Disconnect from Slack to proceed</h2>
            <SlackCard />
          </>
        )
        break
      case MessagingProvider.GCHAT:
        disconnectSorComponent = (
          <>
            <h2>Disconnect from Google Chat to proceed</h2>
            <GChatCard />
          </>
        )
        break
      default:
        disconnectSorComponent = <></>
    }
  }

  return <div className={styles.wrapper}>{shouldDisconnect ? disconnectSorComponent : connectSorComponent}</div>
}

function ConnectCalendarProvider({ calendarProvider }: { calendarProvider: CalendarProvider }) {
  var connectSorComponent = <></>
  var disconnectSorComponent = <></>
  var currentCalendarProvider = useConnectedCalendar()
  var shouldDisconnect = Boolean(currentCalendarProvider)

  switch (calendarProvider) {
    case CalendarProvider.GCAL:
      connectSorComponent = (
        <>
          <h2>Connect to Google Calendar</h2>
          <GoogleCalendar />
        </>
      )
      break
    case CalendarProvider.O365CAL:
      connectSorComponent = (
        <>
          <h2>Connect to Microsoft Outlook Calendar</h2>
          <O365Calendar />
        </>
      )
      break
    default:
      connectSorComponent = <>Unexcepted error: Requirement to connect {calendarProvider} not recognized</>
  }

  if (shouldDisconnect) {
    switch (currentCalendarProvider) {
      case CalendarProvider.GCAL:
        disconnectSorComponent = (
          <>
            <h2>Disconnect from Google Calendar to proceed</h2>
            <GoogleCalendar />
          </>
        )
        break
      case CalendarProvider.O365CAL:
        disconnectSorComponent = (
          <>
            <h2>Disconnect from Office 365 / Outlook Calendar to proceed</h2>
            <O365Calendar />
          </>
        )
        break
      default:
        disconnectSorComponent = <></>
    }
  }

  return <div className={styles.wrapper}>{shouldDisconnect ? disconnectSorComponent : connectSorComponent}</div>
}
