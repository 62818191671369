import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { InviteUsersParameter } from '../middleware/inviteUsers'

export const inviteUsers = async (params: InviteUsersParameter, options?: LuruAPIRequestOptions) => {
  var requestBody = {
    sor: params.sor,
    sor_user_ids: params.sorUserIds,
    message: params.message,
    connections: params.connections,
  }
  var request = new LuruAPIRequest(`/api/users/invite`, HTTPMethod.POST, options)
  request.setBody(requestBody)
  return request.make()
}
