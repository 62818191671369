// 3rd party components
import json5 from 'json5'

// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import { NotesAPI } from '../api'
import Utils from '../../../utils/Utils'
import { NoteSyncState } from '../../../features/notes/types'
import { CalendarProvider, CRMProvider } from '../../../features/user/types'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { ReduxNoteEntity, ReduxNotesState } from '../types'
import { AppDispatch, RootState } from '../../../app/store'
import LuruError from '../../LuruError'

export type CreateNoteConnectionParameter = {
  key: string
  noteId: string
  sorObject: ConnectionParameter
  extraData?: any
}

export type ConnectionParameter = {
  sor: CRMProvider | CalendarProvider
  sor_record_id: string
  sor_object_name: string
  sor_record_name: string
  sor_note_id?: string
  data?: string
}

export interface CreateNoteConnectionAPIResponse extends LuruAPIResponse {
  data: Partial<ReduxNoteEntity> & { noteStatus?: 'draft' }
}

export const createNoteConnection = {
  /**
   * Thunk to link note to an object in CRM or CAL.
   * Query is an object: { key, noteId, sorObject }.
     - key: Key to identify the caller.
     - noteId: The note to link the SOR object with
     - sorObject: { sor_id, sor_record_id, sor_object_name, sor_record_name }
   */
  action: createAsyncThunk<
    CreateNoteConnectionAPIResponse['data'],
    CreateNoteConnectionParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | CreateNoteConnectionAPIResponse['metadata']
      rejectedMeta: null | CreateNoteConnectionAPIResponse['metadata']
    }
  >(
    'notes/createNoteConnection',
    async ({ key, noteId, sorObject }, { getState, rejectWithValue, fulfillWithValue, signal }) => {
      try {
        var currNote = getState().notes?.entities?.[noteId]
        var currSorConn = currNote?.data?.connections?.find((conn) => conn.sor === sorObject.sor.toUpperCase())
        var otherConnList =
          currNote?.data?.connections?.filter((conn) => conn.sor !== sorObject.sor.toUpperCase()) ?? []

        // If note is draft, we just create a link in frontend, not call any API
        if (currNote?.isDraft) {
          let newConnection = {
            ...sorObject,
            connection_id: Utils.generateUUID(),
            sor: sorObject.sor,
          }
          let connections = [...otherConnList, { ...newConnection }]
          let response: CreateNoteConnectionAPIResponse['data'] = {
            ...currNote.data,
            connections,
            noteStatus: 'draft',
          }

          if (
            [CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX, CRMProvider.HUBSPOT, CRMProvider.PIPEDRIVE].includes(
              newConnection.sor as CRMProvider
            )
          ) {
            response.sync_state = NoteSyncState.SYNCED
          }

          return fulfillWithValue(response, null)
        }

        // Delete note connection first before creating.  We're replacing conn.
        if (currSorConn && currSorConn.connection_id) {
          try {
            await NotesAPI.deleteNoteConnection(
              {
                key,
                noteId,
                connectionId: currSorConn.connection_id,
                sorRecordId: currSorConn.sor_record_id,
              },
              { signal }
            )
          } catch (e) {
            let luruError = e instanceof LuruError ? (e as LuruError) : null
            return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
          }
        }

        // note is not draft, call connections API
        try {
          let extraData = undefined

          if (
            [CalendarProvider.GCAL, CalendarProvider.O365CAL].includes(sorObject.sor.toUpperCase() as CalendarProvider)
          ) {
            let externalUrl = getState().meetings.entities?.[sorObject.sor_record_id]?.data?.external_url
            if (externalUrl) {
              extraData = { data: JSON.stringify({ external_url: externalUrl }) }
            }
          }

          var createNoteConnectionResponse = (await NotesAPI.createNoteConnection(
            { key, noteId, sorObject, extraData },
            { signal }
          )) as CreateNoteConnectionAPIResponse

          return fulfillWithValue(createNoteConnectionResponse.data, createNoteConnectionResponse.metadata)
        } catch (e) {
          let luruError = e instanceof LuruError ? (e as LuruError) : null
          return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
        }
      } catch (error) {
        return rejectWithValue('Not able to create connection', null)
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(createNoteConnection.action.pending, (state, action) => {
      var { noteId, key: callerId, sorObject } = action.meta.arg

      if (!noteId) {
        return
      }

      // Ideally we should always have the entity in Redux, as link note
      // is called from a note context (either from editor or later when)
      // we may provide a link button to each note displayed in a list
      if (!state.entities[noteId]) {
        state.entities[noteId] = {
          linkingStatus: {},
          status: EntityStatus.Linking,
          data: {
            note_id: noteId,
          },
        }
      }

      var currEntity = state.entities[noteId]

      if (!currEntity) {
        return
      }

      if (currEntity.linkingStatus) {
        currEntity.linkingStatus = {
          ...currEntity.linkingStatus,
          [callerId]: {
            status: EntityStatus.Loading,
            connection: { ...sorObject },
          },
        }
      } else {
        currEntity.linkingStatus = {
          [callerId]: {
            status: EntityStatus.Loading,
            connection: { ...sorObject },
          },
        }
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(createNoteConnection.action.fulfilled, (state, action) => {
      // Read link note response and create a new connection in note entity
      var linkedNote = action.payload
      var { noteId, key: callerId, sorObject } = action.meta.arg
      var currEntity = state.entities[noteId]
      var prevRecord = currEntity?.data

      if (!currEntity || !prevRecord) {
        return
      }

      // Read SOR record name from arguments
      currEntity.data = {
        ...prevRecord,
        connections: action.payload.connections ?? prevRecord.connections,
        updated_at: action.payload.updated_at ?? prevRecord.updated_at,
        sync_state: action.payload.sync_state ?? prevRecord.sync_state,
      }

      var connection = action.payload.connections?.find((conn) => conn.sor === sorObject.sor)
      // Update link to the object connected just now
      if (connection) {
        currEntity.linkingStatus = {
          ...currEntity.linkingStatus,
          [callerId]: {
            status: EntityStatus.Loaded,
            connection,
          },
        }
      }

      // Ensure body is formatted correctly in record
      if (typeof linkedNote.body !== 'string') {
        currEntity.data.body = json5.stringify(currEntity.data.body)
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(createNoteConnection.action.rejected, (state, action) => {
      var { noteId, key: callerId, sorObject } = action.meta.arg
      var currEntity = state.entities[noteId]

      if (!currEntity) {
        return
      }

      currEntity.linkingStatus = {
        ...currEntity.linkingStatus,
        [callerId]: {
          status: EntityStatus.Idle,
          connection: { ...sorObject },
        },
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    createNoteConnection.addPendingCase(builder)
    createNoteConnection.addFulfilledCase(builder)
    createNoteConnection.addRejectedCase(builder)
  },
}
