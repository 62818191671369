import { getFullyQualifiedKey, isEditEvent } from '../../domutils/utils'
import { JottingType } from '../../features/notes/types'
import EditorDOM, { CaretPosition } from '../EditorDOM'
import { getTextDetailsUntil } from '../../domutils/utils'
import DomUtils, { NodeType } from '../utils/DomUtils'
import { EditorEntityType } from '../EditorController'

/**
 * @classdesc Class to handle editing events inside editor
 */
export default class EditingHandler {
  // Computations

  /**
   * Compute if and how navigation event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   * @param {EditorController} controller - Instance of EditorController class
   */
  computeHandling(view, eventStream, controller) {
    const lastEvent = eventStream[eventStream.length - 1]

    const editEvent = lastEvent?.sourceEvent?.type === 'keydown' ? lastEvent : null

    const isEditableCellEvent =
      Boolean(lastEvent?.sourceEvent?.target) &&
      Boolean(lastEvent?.sourceEvent?.target?.closest?.('[data-luru-role="editable-cell"]'))

    if (isEditableCellEvent) {
      return {
        do: null,
        dirtyFlag: false,
        preventDefault: true,
      }
    }

    if (!editEvent) {
      return this.#computeHandlingForInputEvent(view, eventStream)
    }

    let eventKey = getFullyQualifiedKey(editEvent?.sourceEvent)

    // console.table({
    //   eventKey,
    //   startContainerText: editEvent.data.range.startContainer.textContent,
    //   startOffset: editEvent.data.range.startOffset,
    //   endContainerText: editEvent.data.range.startContainer.textContent,
    //   endOffset: editEvent.data.range.startOffset,
    //   prevNode:
    //     editEvent.data.range.startContainer.previousSibling?.tagName ??
    //     editEvent.data.range.startContainer.previousSibling?.textContent ??
    //     null,
    //   parentNode: editEvent.data.range.startContainer.parentNode.tagName,
    // })

    switch (eventKey) {
      case 'Enter':
      case 'CtrlEnter':
      case 'CtrlShiftEnter':
        var linkHandler = this.#computeLinkHandler(view, editEvent, '')
        var enterKeyHandler = this.#computeEnterKeyHandler(
          view,
          editEvent,
          eventKey.indexOf('Ctrl') !== -1,
          eventKey.indexOf('Shift') !== -1
        )
        return {
          do: (view) => {
            if (linkHandler) {
              linkHandler.do(view)
            }
            enterKeyHandler.do(view)
          },
          // TODO: When undo is available from either handlers, insert them
          preventDefault: enterKeyHandler.preventDefault || linkHandler.preventDefault,
          dirtyFlag: enterKeyHandler.dirtyFlag || linkHandler.dirtyFlag,
        }

      case 'Delete':
        return this.#computeDeleteKeyHandler(view, editEvent)

      case 'Backspace':
        return this.#computeBackspaceKeyHandler(view, editEvent)

      case 'CtrlShift<':
        return this.#computeDecreaseSizeHandler(view, editEvent)

      case 'CtrlShift>':
        return this.#computeIncreaseSizeHandler(view, editEvent)

      case 'Tab':
        return this.#computeIncreaseIndentHandler(view, editEvent)

      case 'ShiftTab':
        return this.#computeDecreaseIndentHandler(view, editEvent)

      case 'AltArrowUp':
        return this.#computeAltArrowUpHandler(view, editEvent)

      case 'AltArrowDown':
        return this.#computeAltArrowDownHandler(view, editEvent)

      case ' ':
        linkHandler = this.#computeLinkHandler(view, editEvent, eventKey)
        if (linkHandler) {
          return linkHandler
        }
        return this.#computeMarkdownFormatting(view, editEvent, controller)

      case ',':
      case '.':
      case ';':
        linkHandler = this.#computeLinkHandler(view, editEvent, eventKey)
        if (linkHandler) {
          return linkHandler
        }
        return this.#computeDefaultHandler(view, editEvent)

      default:
      // console.log(`EditingHandler:`, eventKey)
    }

    return this.#computeDefaultHandler(view, editEvent)
  }

  #computeHandlingForInputEvent(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]
    let inputEvent = lastEvent?.sourceEvent?.type === 'input' ? lastEvent : null

    if (!inputEvent) {
      return null
    }

    return {
      do: (view) => {
        const notes = view?.getNoteElementsList()
        const firstNoteContent = notes?.[0].textContent ?? ''
        const clearPlaceholder =
          (notes.length > 1 || firstNoteContent !== '') && notes?.[0].getAttribute('placeholder') !== ''
        if (clearPlaceholder) {
          view?.clearPlaceholders()
        }
      },
    }
  }

  #computeDefaultHandler(view, editEvent) {
    if (!editEvent?.sourceEvent) {
      return null
    }
    let defaultHandler = isEditEvent(editEvent.sourceEvent)
      ? {
          do: null,
          dirtyFlag: true,
          preventDefault: false,
        }
      : null
    return defaultHandler
  }

  #computeBackspaceKeyHandler(view, editEvent) {
    // Compute is backspace should be handled
    let jottingType = view?.getJottingType(editEvent.data.jottingElement)
    let prevJottingElement = view?.getPreviousJottingElement(editEvent.data.jottingElement)
    let prevJottingType = view?.getJottingType(prevJottingElement)
    let disallowedTypes = [JottingType.CRM_FIELD_LABEL, JottingType.CRM_FIELD_VALUE, JottingType.CRM_COLLECTION]

    // Prevent backspace in first position
    if (view?.isCaretAtStartOfNote(editEvent) && !EditorDOM.PREFIXED_JOTTING_TYPES.includes(jottingType)) {
      if (
        (view?.isFirstNote(editEvent.data.noteElement) && !disallowedTypes.includes(jottingType)) ||
        disallowedTypes.includes(prevJottingType)
      ) {
        return {
          do: (view) => {
            editEvent.sourceEvent.preventDefault()
          },
          preventDefault: true,
        }
      }
    }

    if (!view?.isCaretAtStartOfNote(editEvent) || disallowedTypes.includes(jottingType)) {
      return this.#computeDefaultHandler(view, editEvent)
    }

    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()

        // Part 1 of logic: If we are in an item with a prefix - UL1-3, OL1-3,
        // Q, A, Task, change this element to a paragraph.  If we are inside
        // the non-prefix jotting types, then join current line at the end
        // of previous line (Part 2 of logic)
        if (EditorDOM.PREFIXED_JOTTING_TYPES.includes(jottingType)) {
          view?.changeJottingType(editEvent.data.jottingElement, view?.getPrefixStrippedJottingType(jottingType))
          return
        }

        // Part 2 of logic
        let previousJottingElement = view?.getPreviousJottingElement(editEvent.data.jottingElement)
        view?.setCaretAt(previousJottingElement, CaretPosition.END_OF_NOTE)
        view?.joinJottings(previousJottingElement, editEvent.data.jottingElement)
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeDeleteKeyHandler(view, editEvent) {
    // Compute if delete key should be handled
    let jottingType = view?.getJottingType(editEvent.data.jottingElement)
    let nextJottingElement = view?.getNextJottingElement(editEvent.data.jottingElement)
    let nextJottingType = view?.getJottingType(nextJottingElement)
    let disallowedTypes = [
      JottingType.Q,
      JottingType.CRM_FIELD_LABEL,
      JottingType.CRM_FIELD_VALUE,
      JottingType.CRM_COLLECTION,
    ]
    let disallowedNextTypes = [
      JottingType.Q,
      JottingType.CRM_FIELD_LABEL,
      JottingType.CRM_FIELD_VALUE,
      JottingType.CRM_COLLECTION,
    ]

    if (view?.getTaskJottingTypes().includes(nextJottingType) && view?.isCaretAtEndOfNote(editEvent)) {
      return {
        do: () => {
          editEvent.sourceEvent.preventDefault()
        },
        preventDefault: true,
        dirtyFlag: false,
      }
    }

    if (
      !view?.isCaretAtEndOfNote(editEvent) ||
      view?.isLastNote(editEvent.data.noteElement) ||
      disallowedTypes.includes(jottingType) ||
      disallowedNextTypes.includes(nextJottingType)
    ) {
      return this.#computeDefaultHandler(view, editEvent)
    }

    return {
      do: (view) => {
        view?.joinJottings(editEvent.data.jottingElement, nextJottingElement)
        view?.clearPlaceholders()
        view?.setPlaceholder(editEvent.data.noteElement)
        editEvent.sourceEvent.preventDefault()
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeIncreaseSizeHandler(view, editEvent) {
    let jottingElement = editEvent.data.jottingElement
    let jottingType = view?.getJottingType(jottingElement)
    let nextSize = {
      [JottingType.H2]: JottingType.H1,
      [JottingType.H3]: JottingType.H2,
      [JottingType.A_H2]: JottingType.A_H1,
      [JottingType.A_H3]: JottingType.A_H2,
    }

    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()
        if (!(jottingType in nextSize)) {
          return
        }
        view?.changeJottingType(jottingElement, nextSize[jottingType])
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeDecreaseSizeHandler(view, editEvent) {
    let jottingElement = editEvent.data.jottingElement
    let jottingType = view?.getJottingType(jottingElement)
    let nextSize = {
      [JottingType.H1]: JottingType.H2,
      [JottingType.H2]: JottingType.H3,
      [JottingType.A_H1]: JottingType.A_H2,
      [JottingType.A_H2]: JottingType.A_H3,
    }

    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()
        if (!(jottingType in nextSize)) {
          return
        }
        view?.changeJottingType(jottingElement, nextSize[jottingType])
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeIncreaseIndentHandler(view, editEvent) {
    let jottingElement = editEvent.data.jottingElement
    let jottingType = view?.getJottingType(jottingElement)
    let nextLevel = {
      [JottingType.UL1]: JottingType.UL2,
      [JottingType.UL2]: JottingType.UL3,
      [JottingType.A_UL1]: JottingType.A_UL2,
      [JottingType.A_UL2]: JottingType.A_UL3,
      [JottingType.OL1]: JottingType.OL2,
      [JottingType.OL2]: JottingType.OL3,
      [JottingType.A_OL1]: JottingType.A_OL2,
      [JottingType.A_OL2]: JottingType.A_OL3,
    }

    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()
        if (!(jottingType in nextLevel)) {
          return
        }
        view?.changeJottingType(jottingElement, nextLevel[jottingType])
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeDecreaseIndentHandler(view, editEvent) {
    let jottingElement = editEvent.data.jottingElement
    let jottingType = view?.getJottingType(jottingElement)
    let nextLevel = {
      [JottingType.UL2]: JottingType.UL1,
      [JottingType.UL3]: JottingType.UL2,
      [JottingType.A_UL2]: JottingType.A_UL1,
      [JottingType.A_UL3]: JottingType.A_UL2,
      [JottingType.OL2]: JottingType.OL1,
      [JottingType.OL3]: JottingType.OL2,
      [JottingType.A_OL2]: JottingType.A_OL1,
      [JottingType.A_OL3]: JottingType.A_OL2,
    }

    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()
        if (!(jottingType in nextLevel)) {
          return
        }
        view?.changeJottingType(jottingElement, nextLevel[jottingType])
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeEnterKeyHandler(view, editEvent, isCtrl = false, isShift = false) {
    let command = this.#computeNextCommandForEnter(view, editEvent, isCtrl, isShift)
    let handler
    let jottingType = view?.getJottingType(editEvent.data.jottingElement)

    switch (command.action) {
      case 'navigate':
        handler = (view) => {
          editEvent.sourceEvent.preventDefault()
          view?.setCaretAt(command.noteElement, CaretPosition.END_OF_NOTE)
        }
        break

      case 'replace':
        handler = (view) => {
          editEvent.sourceEvent.preventDefault()
          view?.changeJottingType(view?.getJottingElement(command.noteElement), command.jottingType)
        }
        break

      case 'insert':
      default:
        let fragmentRange = null

        // Decision making flag to indicate if current line should be broken
        let breakCurrentLine = true
        // (1) Break current line only if Ctrl and/or Shift are not pressed
        breakCurrentLine = !isCtrl && !isShift
        // (2) Break current line only if current jotting is not task like
        breakCurrentLine = breakCurrentLine && !view?.getTaskJottingTypes().includes(jottingType)

        if (breakCurrentLine) {
          fragmentRange = new Range()
          let noteElement = editEvent.data.noteElement
          if (editEvent.data.range) {
            fragmentRange.setStart(editEvent.data.range.startContainer, editEvent.data.range.startOffset)
          }
          noteElement.lastChild?.nodeType === 3
            ? fragmentRange.setEnd(noteElement.lastChild, noteElement.lastChild.textContent.length)
            : fragmentRange.setEnd(noteElement, noteElement.childNodes.length)
        }

        handler = (view) => {
          editEvent.sourceEvent.preventDefault()
          view?.insertJotting(command.noteElement, isShift ? 'before' : 'after', command.jottingType, fragmentRange)

          // Set caret and placeholder in new element
          let newNoteElement
          if (isShift) {
            newNoteElement = view?.getPreviousNoteElement(command.noteElement)
          } else {
            newNoteElement = view?.getNextNoteElement(editEvent.data.noteElement)
          }
          view?.setPlaceholder(newNoteElement)
          view?.setCaretAt(newNoteElement, CaretPosition.START_OF_NOTE)
          DomUtils.scrollIntoViewIfNeeded(newNoteElement, view?.getEditorContainer())
        }
    }

    return {
      do: handler,
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeNextCommandForEnter(view, event, isCtrl = false, isShift = false) {
    /** Logic to decide jotting type of new element */
    let jottingType = view?.getJottingType(event.data.jottingElement)
    let currentNoteContent = event?.data?.noteElement?.textContent ?? ''
    // Object to store a 'command' to execute
    let command = {
      action: 'insert',
      jottingType: JottingType.P,
      noteElement: event.data.noteElement,
    }

    switch (jottingType) {
      // Headings
      case JottingType.H1:
      case JottingType.H2:
      case JottingType.H3:
        // Irrespective of nextJottingType, setting the new jotting type
        // to paragraph after a heading type
        if (event.data.range.startOffset !== 0) {
          command.jottingType = JottingType.P
        } else {
          command.jottingType = jottingType
        }
        break
      case JottingType.A_H1:
      case JottingType.A_H2:
      case JottingType.A_H3:
        // Irrespective of nextJottingType, setting the new jotting type
        // to paragraph after a heading type
        command.jottingType = JottingType.A_P
        break

      // Tasks
      case JottingType.TASK_COMPLETE:
      case JottingType.TASK_INCOMPLETE:
        if (!isShift && currentNoteContent === '') {
          command.jottingType = JottingType.P
          command.action = 'replace'
        } else {
          command.jottingType = JottingType.TASK_INCOMPLETE
        }
        break
      case JottingType.A_TASK_COMPLETE:
      case JottingType.A_TASK_INCOMPLETE:
        if (!isShift && currentNoteContent === '') {
          command.jottingType = JottingType.A_P
          command.action = 'replace'
        } else {
          command.jottingType = JottingType.A_TASK_INCOMPLETE
        }
        break

      // Lists
      case JottingType.UL1:
      case JottingType.UL2:
      case JottingType.UL3:
      case JottingType.OL1:
      case JottingType.OL2:
      case JottingType.OL3:
        // If we press an enter key from an empty list item (ordered or
        // unordered, we insert a paragraph)
        if (!isShift && currentNoteContent === '') {
          command.jottingType = JottingType.P
          command.action = 'replace'
        } else {
          command.jottingType = jottingType
        }
        break
      case JottingType.A_UL1:
      case JottingType.A_UL2:
      case JottingType.A_UL3:
      case JottingType.A_OL1:
      case JottingType.A_OL2:
      case JottingType.A_OL3:
        // If we press an enter key from an empty list item (ordered or
        // unordered, we insert a paragraph)
        if (!isShift && currentNoteContent === '') {
          command.jottingType = JottingType.A_P
          command.action = 'replace'
        } else {
          command.jottingType = jottingType
        }
        break

      // CRM fields
      case JottingType.CRM_FIELD_LABEL:
        if (isCtrl && isShift) {
          // We are trying to add a new line before CRM field
          command.jottingType = JottingType.P
        } else {
          // User trying to add a new line after CRM field
          //   But after CRM field, there can only be a CRM value
          command.action = 'navigate'
          command.noteElement = view?.getNextNoteElement(event.data.noteElement)
        }
        // Indicates we don't want to create a new jotting after CRM field
        // label
        break

      case JottingType.CRM_FIELD_VALUE:
        command.jottingType = JottingType.P
        break

      // Question & answer
      case JottingType.Q:
        if (isCtrl && isShift) {
          command.jottingType = JottingType.P
        } else {
          command.jottingType = JottingType.A_P
        }
        break

      case JottingType.A_P:
        if (
          !isShift &&
          currentNoteContent === '' &&
          !view?.isAnswerJotting(view?.getNextJottingElement(event.data.jottingElement))
        ) {
          command.action = 'replace'
          command.jottingType = JottingType.P
        } else {
          command.jottingType = JottingType.A_P
        }
        break

      // Default: All cases are handled above; this is a no-op
      default:
        break
    }
    return command
  }

  #computeAltArrowUpHandler(view, editEvent) {
    let previousJottingElement = view?.getPreviousJottingElement(editEvent.data.jottingElement)
    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()
        if (view?.isFirstJotting(editEvent.data.jottingElement)) {
          return
        }
        view?.moveJottingBefore(editEvent.data.jottingElement, previousJottingElement)
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeAltArrowDownHandler(view, editEvent) {
    let nextJottingElement = view?.getNextJottingElement(editEvent.data.jottingElement)
    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()
        if (view?.isLastJotting(editEvent.data.jottingElement)) {
          return
        }
        view?.moveJottingAfter(editEvent.data.jottingElement, nextJottingElement)
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeMarkdownFormatting(view, editEvent, controller) {
    let range = editEvent.data.range
    let noteElement = editEvent.data.noteElement

    if (!range.collapsed) {
      return this.#computeDefaultHandler(view, editEvent)
    }

    const startingPortion = getTextDetailsUntil(noteElement, range.startContainer, range.startOffset)

    const markersWithSpace = ['[ ]']

    if (startingPortion.text.trim().indexOf(' ') !== -1 && !markersWithSpace.includes(startingPortion.text.trim())) {
      return this.#computeDefaultHandler(view, editEvent)
    }

    let newJottingType = null
    switch (startingPortion.text.trim()) {
      case '#':
        newJottingType = JottingType.H1
        break

      case '##':
        newJottingType = JottingType.H2
        break

      case '###':
        newJottingType = JottingType.H3
        break
      case '*':
      case '-':
      case '+':
        newJottingType = JottingType.UL1
        break

      case '1.':
        newJottingType = JottingType.OL1
        break

      //  Disabling tasks for first release; uncomment later
      case '[]':
      case '[ ]':
        if (controller.getEntityType() === EditorEntityType.Note) {
          newJottingType = JottingType.TASK_INCOMPLETE
        }
        break

      case '[*]':
      case '[x]':
      case '[X]':
        if (controller.getEntityType() === EditorEntityType.Note) {
          newJottingType = JottingType.TASK_COMPLETE
        }
        break

      case '?':
        newJottingType = JottingType.Q
        break

      default:
        if (/^\d+\.$/.test(startingPortion.text)) {
          newJottingType = JottingType.OL1
        }
    }

    // Check if markdown is being applied to an answer jotting
    // Fix jotting type to its 'answer' version if it is so
    if (view?.isAnswerJotting(editEvent.data.jottingElement)) {
      newJottingType = view?.getAnswerType(newJottingType)
    }

    if (!newJottingType) {
      return this.#computeDefaultHandler(view, editEvent)
    }

    return {
      do: (view) => {
        editEvent.sourceEvent.preventDefault()
        let markerRange = new Range()
        markerRange.setStartBefore(noteElement.firstChild)
        markerRange.setEnd(range.startContainer, range.startOffset)
        markerRange.deleteContents()
        view?.changeJottingType(editEvent.data.jottingElement, newJottingType)
        setTimeout(view?.setPlaceholder(noteElement))
      },
      preventDefault: true,
      dirtyFlag: true,
    }
  }

  #computeLinkHandler(view, editEvent, eventKey) {
    let container = editEvent?.data?.range?.endContainer

    // container can be a textNode child of any element within the note
    // If container is already a child of luru link, there is no need to create
    // a link
    if (
      (container.closest && container.closest(EditorDOM.LURU_LINK_SELECTOR)) ||
      container.parentElement.closest(EditorDOM.LURU_LINK_SELECTOR)
    ) {
      return null
    }

    if (view?.isNoteElement(container) && editEvent?.data?.range?.endOffset > container.childElementCount - 1) {
      container = container.lastChild
    }

    let containerTextContent = container?.textContent ?? ''
    let lastWordIndex = containerTextContent?.lastIndexOf(' ')

    let rangeEndOffset =
      container?.nodeType === NodeType.ELEMENT_NODE ? editEvent?.data?.range?.endOffset : containerTextContent.length

    let lastWord = containerTextContent.slice(lastWordIndex + 1, rangeEndOffset)

    let regex =
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i

    let linkMatch = lastWord.match(regex)

    if (linkMatch) {
      return {
        do: (view) => {
          // Prevent default behavior, which is to insert 'eventKey' character
          editEvent.sourceEvent.preventDefault()

          let linkElement = view?.insertLink({
            linkText: linkMatch[0],
            linkUrl: linkMatch[0],
            startContainer: container,
            startOffset: lastWordIndex === -1 ? 0 : lastWordIndex,
            endContainer: container,
            endOffset: rangeEndOffset,
          })

          // We need the before element because otherwise the space character
          // which was valid as a separator between two words in a text node,
          // becomes the last whitespace character of new link element's
          // previous sibling.
          if (lastWordIndex !== -1 && linkElement) {
            linkElement.before?.(document.createTextNode(' '))
          }

          // The after space element is to repo caret after (outside) the link
          // element inserted
          let nodeAfterLink = document.createTextNode(eventKey ?? ' ')
          linkElement.after(nodeAfterLink)

          let caretRepoRange = new Range()
          caretRepoRange.setEndAfter(linkElement.nextSibling)
          caretRepoRange.collapse(false)
          document.getSelection().removeAllRanges()
          document.getSelection().addRange(caretRepoRange)
        },
        preventDefault: true,
        dirtyFlag: true,
      }
    }

    return null
  }
}
