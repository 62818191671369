import { HTTPMethod } from '../../../app/types'
import { DateUtils } from '../../../utils/dateUtils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CreateTaskParameter } from '../middleware/createTask'

export const createTask = (
  params: Readonly<CreateTaskParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest('/api/tasks', HTTPMethod.POST, options)
  var requestBody: Partial<CreateTaskParameter> = { ...params }

  for (let key in params) {
    let typedKey = key as keyof CreateTaskParameter

    if (params[typedKey] === undefined || params[typedKey] === null) {
      delete requestBody[typedKey]
    } else if (typedKey === 'due_date' && params.due_date) {
      requestBody.due_date = DateUtils.toAPIDateString(
        new Date(params.due_date)
      )
    } else if (typedKey === 'assigned_to' && params.assigned_to) {
      requestBody.assigned_to = { ...params.assigned_to }
    } else if (typedKey === 'connections' && params.connections) {
      requestBody.connections = [...params.connections]
    }
  }

  request.setBody(requestBody)

  return request.make()
}
