import { NotificationActionButton, NotificationType } from '../../../../features/user/types'
import styles from './styles.module.css'
import Modal from '../../../Modal'
import LuruButton from '../../../ui/LuruButton'
import { useAppDispatch } from '../../../../app/hooks'
import { UserMiddleware } from '../../../../features/user/middleware'
import moment from 'moment'

interface ModalNotificationProps {
  notificationId?: string
  title?: string
  message: string
  type?: NotificationType
  closable?: boolean
  onClose?: (notificationId?: string) => void
  className?: string
  modalContentClassName?: string
  modalBodyClassName?: string
  actionButtons?: Array<NotificationActionButton>
}

export default function ModalNotification({
  notificationId,
  title,
  message,
  type,
  closable = true,
  onClose,
  className,
  modalContentClassName,
  modalBodyClassName,
  actionButtons = [],
}: ModalNotificationProps) {
  const dispatch = useAppDispatch()
  const handlers = {
    handleCloseNotification: () => {
      dispatch(
        UserMiddleware.updateNotificationStats.action({
          notificationId,
          payload: {
            dismissed_at: moment(new Date()).format(), //Iso date format
          },
        })
      )
        .unwrap()
        .finally(() => {
          onClose?.(notificationId)
        })
    },
  }

  var varinatStyle = type ? styles[`modalContent_${type}`] : ''
  return (
    <div className={[styles.modalContainer, className].join(' ')} data-luru-notification-type={type}>
      <Modal
        titleText={title}
        show={true}
        onClose={handlers.handleCloseNotification}
        modalContentClass={[styles.modalContent, varinatStyle, modalContentClassName].join(' ')}
        disallowClosing={!closable}
        data-luru-type={type}
      >
        <>
          {/* <div className={styles.imagePlaceholder} data-luru-notification-type={type} /> */}
          <div
            className={[styles.modalBody, modalBodyClassName].join(' ')}
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
          <div className={styles.actionButtonsContainer} data-luru-notification-type={type}>
            {/* {closable && (
              <LuruButton
                extraClassNames={[styles.closeBtn]}
                title=''
                variant='outline'
                onClick={handlers.handleCloseNotification}
              >
                Close
              </LuruButton>
            )} */}
            {actionButtons &&
              actionButtons.map((btn, indx) => {
                return (
                  <LuruButton
                    key={indx}
                    title=''
                    variant='outline'
                    onClick={() => {
                      btn.href && window.open(btn.href, '_blank')
                    }}
                  >
                    {btn.label}
                  </LuruButton>
                )
              })}
          </div>
        </>
      </Modal>
    </div>
  )
}
