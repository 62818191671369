import json5 from 'json5'
import { HTTPMethod, LuruAPIResponse } from '../app/types'
import LuruError, { LuruErrorName } from './LuruError'
import luruFetch from './LuruFetch'

export interface LuruAPIRequestOptions extends RequestInit {
  signal: AbortSignal
  headers?: Record<string, string>
}

export default class LuruAPIRequest {
  #url: string
  #body?: string
  #method: HTTPMethod
  #options?: LuruAPIRequestOptions

  constructor(url: string, method = HTTPMethod.GET, options: LuruAPIRequestOptions | undefined = undefined) {
    this.#url = url
    this.#method = method
    this.#options = options
  }

  setBody(body: string | {}) {
    if (typeof body === 'object') {
      try {
        this.#body = JSON.stringify(body)
      } catch (e) {
        throw new LuruError(LuruErrorName.JSONConversionError)
      }
    } else if (typeof body === 'string') {
      this.#body = body
    } else {
      throw new LuruError(LuruErrorName.InvalidArgumentError)
    }
  }

  async make() {
    try {
      var response = await luruFetch(this.#url, this.#computeFetchParams())
    } catch (error) {
      throw new LuruError(LuruErrorName.NetworkError)
    }

    try {
      var responseText = await response.text()
    } catch (error) {
      throw new LuruError(LuruErrorName.HTTPReadError)
    }

    try {
      var json = (responseText ? await json5.parse(responseText) : {}) as LuruAPIResponse
    } catch (error) {
      throw new LuruError(LuruErrorName.JSONParseError, undefined, error as Error, {
        responseText,
      })
    }

    // TODO: Debug state to check error handling
    // if (this.#url.startsWith('/api/tasks?')) {
    //   json.error_data = {
    //     error_code: 1,
    //     description: 'placeholder desc',
    //     message: 'placeholder message',
    //     traceback: 'placeholder traceback',
    //   }
    // }

    if (!response.ok || json.error_data) {
      throw new LuruError(LuruErrorName.LuruAPIError, json.error_data?.message, undefined, {
        error_code: json.error_data?.error_code,
        http_code: json?.http_code,
        description: json.error_data?.description,
        message: json.error_data?.message,
        traceback: json.error_data?.traceback,
        meta: json.metadata,
        additional_data: {
          ...(json.error_data?.additional_data ?? {}),
          url: this.#url,
          body: this.#body,
        },
      })
    }

    return json
  }

  // Computations
  #computeJsonHeaderName() {
    return this.#method === 'GET' ? 'Accept' : 'Content-Type'
  }

  #computeFetchParams() {
    const { headers, credentials } = this.#options || {}
    var params: {
      method: HTTPMethod
      headers: Record<string, any>
      body?: string
      signal?: AbortSignal
      credentials?: RequestCredentials
    } = {
      method: this.#method,
      headers: {
        [this.#computeJsonHeaderName()]: 'application/json',
        ...(headers ?? {}),
      },
      signal: this.#options?.signal,
      credentials: credentials,
    }

    if (this.#body) {
      params.body = this.#body
    }

    return params
  }
}
