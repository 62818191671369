import { useState, useEffect } from 'react'

/**
 * Execute a handler when a click is detected outside a given ref
 * @param {React.RefObject<HTMLElement>|any} ref Ref of element, whose outside click we need to detect
 * @param {Function} handler Handler to execute when outside click is detected
 */
export function useOutsideClick(ref, handler, id = undefined) {
  useEffect(() => {
    // Event handler for detecting any click in body and selectively handle
    // given handler
    function execHandler(e) {
      if (!ref?.current?.contains(e.target)) {
        handler(e)
      }
    }

    // Bind event handler to body
    document.body.addEventListener('mousedown', execHandler)

    return () => {
      // Unbind event on unmount
      document.body.removeEventListener('mousedown', execHandler)
    }
  }, [ref, handler, id])
}

/**
 * Detect click in one of 3 zones and run appropriate handler.  The 3 zones are:
 * 1. Outside of outsideRef
 * 2. Inside of insideRef
 * 3. Inside out outsideRef & outside of insideRef
 * @param {Object} With 2 refs specified by 2 keys
 * @param {Object} With 3 functions specified by 3 keys
 */
export function useThreeWayClickHandler(
  { outsideRef, insideRef },
  { outsideClick, insideClick, betweenClick },
  sourceElement = null
) {
  if (sourceElement) {
    console.log(`useThreeWayClickHandler:init:${sourceElement}`)
  }
  useEffect(() => {
    // Generic handler to handle a click and run the required handler
    function execHandler(e) {
      if (sourceElement) {
        console.log(sourceElement)
        console.dir(e.target)
      }
      if (!outsideRef?.current?.contains(e.target)) {
        sourceElement && console.log('Outside click')
        outsideClick && outsideClick(e)
      } else {
        if (insideRef?.current?.contains(e.target)) {
          sourceElement && console.log('Inside click')
          insideClick && insideClick(e)
        } else {
          sourceElement && console.log('Between click')
          betweenClick && betweenClick(e)
        }
      }
    }

    // Bind event selector to body.onClick
    document.body.addEventListener('click', execHandler)

    // Unbind event on exit
    return () => {
      document.body.removeEventListener('click', execHandler)
    }
  }, [outsideRef, insideRef, outsideClick, insideClick, betweenClick, sourceElement])
}

/**
 * Simple utility to get state as an object with get and set
 * @param {object} Any object
 * @returns {object} Object with {get: <value>, set: setValue() function}
 */
export function useGetSetState(obj) {
  const [objValue, setObjValue] = useState(obj ?? null)
  return {
    get: () => objValue,
    set: (value) => setObjValue(value),
  }
}
