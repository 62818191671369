import { combineReducers } from '@reduxjs/toolkit'
import appStateReducer from '../../features/app/appSlice'
import { ChatSliceReducer } from '../../features/chat/chatSlice'
import collectionsReducer from '../../features/collections/collectionsSlice'
import emailsReducer from '../../features/emails/emailsSlice'
import emailsyncsReducer from '../../features/emailsyncs/emailsyncsSlice'
import crmReducer from '../../features/crm/crmSlice'
import meetingsReducer from '../../features/meetings/meetingsSlice'
import notesReducer from '../../features/notes/notesSlice'
import noteTemplatesReducer from '../../features/noteTemplates/noteTemplatesSlice'
import tasksReducer from '../../features/tasks/tasksSlice'
import userReducer from '../../features/user/userSlice'
import usersReducer from '../../features/users/usersSlice'
import viewsNewReducer from '../../features/views/viewsSlice'
import { WorkflowsSliceReducer } from '../../features/workflows/workflowsSlice'

export const luruRootReducer = combineReducers({
  app: appStateReducer,
  collections: collectionsReducer,
  emails: emailsReducer,
  emailsyncs: emailsyncsReducer,
  chat: ChatSliceReducer,
  crm: crmReducer,
  meetings: meetingsReducer,
  notes: notesReducer,
  noteTemplates: noteTemplatesReducer,
  tasks: tasksReducer,
  user: userReducer,
  users: usersReducer,
  views: viewsNewReducer,
  workflows: WorkflowsSliceReducer,
})
