import { signInUser } from './signInUser'
import { signOutUser } from './signOutUser'
import { addRecentItem } from './addRecentItem'
import { setRecentItems } from './setRecentItems'
import { setUserSettings } from './setUserSettings'
import { setNetworkErrorState } from './setNetworkErrorState'
import { setAuthorizationErrorState } from './setAuthorizationErrorState'
import { unsetNetworkErrorState } from './unsetNetworkErrorState'
import { refreshUserSettings } from './refreshUserSettings'
import { setSearchShortcut } from './setSearchShortcut'
import { setConnectedCalendarErrorCode } from './setConnectedCalendarErrorCode'

export const UserSliceReducers = {
  signInUser,
  signOutUser,
  addRecentItem,
  setRecentItems,
  setUserSettings,
  setNetworkErrorState,
  setAuthorizationErrorState,
  unsetNetworkErrorState,
  refreshUserSettings,
  setSearchShortcut,
  setConnectedCalendarErrorCode,
}
