import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { ViewsAPI } from '../api'
import { ReduxViewEntity, ReduxViewsState, ViewType } from '../types'

export interface ListViewsParameter {
  type: 'LURU' | 'SOR'
  object_name: string
  force?: boolean
}

export interface ListViewsAPIResponse extends LuruAPIResponse {
  data: Array<ReduxViewEntity>
}

export const listViews = {
  action: createAsyncThunk<
    ListViewsAPIResponse['data'],
    ListViewsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ListViewsAPIResponse['metadata']
      rejectedMeta: ListViewsAPIResponse['metadata'] | null
    }
  >(
    'views/listViews',
    async (params, { signal, getState, fulfillWithValue, rejectWithValue }) => {
      try {
        var notes = (await ViewsAPI.listViews(params, {
          signal,
        })) as ListViewsAPIResponse

        return fulfillWithValue(notes.data, notes.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    },
    {
      condition: (params, { getState }) =>
        params.force ||
        (getState().views.status?.[params.type]?.[params.object_name] !==
          EntityStatus.Loading &&
          getState().views.status?.[params.type]?.[params.object_name] !==
            EntityStatus.Loaded),
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(listViews.action.pending, (state, action) => {
      var viewType = action.meta.arg.type
      var objType = action.meta.arg.object_name

      if (!state.status) {
        state.status = {
          [ViewType.LURU]: {},
          [ViewType.SOR]: {},
        }
      }

      state.status[viewType][objType] = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(listViews.action.fulfilled, (state, action) => {
      var viewType = action.meta.arg.type
      var objType = action.meta.arg.object_name

      if (!state.status) {
        state.status = {
          [ViewType.LURU]: {},
          [ViewType.SOR]: {},
        }
      }

      state.status[viewType][objType] = EntityStatus.Loaded

      action.payload.forEach(
        (v) =>
          state.entities[v.view_id]?.status !== EntityStatus.Loaded &&
          state.entities[v.view_id]?.status !== EntityStatus.Loading &&
          (state.entities[v.view_id] = {
            data: { ...v },
            status: EntityStatus.Loaded,
          })
      )

      state.refreshedAt = new Date().toISOString()
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(listViews.action.rejected, (state, action) => {
      if (!state.status) {
        state.status = {
          [ViewType.LURU]: {},
          [ViewType.SOR]: {},
        }
      }

      console.log(
        'listViews.rejected:aborted:',
        action.meta.aborted,
        'status:',
        state.status[action.meta.arg.type][action.meta.arg.object_name]
      )

      if (!action.meta.aborted) {
        var viewType = action.meta.arg.type
        var objType = action.meta.arg.object_name
        state.status[viewType][objType] = EntityStatus.ErrorLoading
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    listViews.addPendingCase(builder)
    listViews.addFulfilledCase(builder)
    listViews.addRejectedCase(builder)
  },
}
