import { PayloadAction } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { ReduxEmailsState } from '../types'

export const invalidateCache = (
  state: ReduxEmailsState,
  action: PayloadAction<undefined>
) => {
  state.entities = {}
  state.refreshedAt = undefined
  state.status = EntityStatus.NotLoaded
}
