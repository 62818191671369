// Note: processUIEffects() is a function used by Redux dispatch listeners that
// listen for dispatches from other tabs (webapp from other webapp tabs or
// extension tabs; extension tabs from other components like webapp or worker).
// Once the received action is applied on the current Redux store, there may be
// other UI side effects that need to be applied.  This function takes care of

import { PayloadAction } from '@reduxjs/toolkit'
import {
  EditorMessage,
  postMessageToNoteEditor,
} from '../../coreEditor/EditorController'
import { AppSliceActions } from '../../features/app/appSlice'
import { MeetingsSliceActions } from '../../features/meetings/meetingsSlice'
import { SaveNoteParameter } from '../../features/notes/middleware/saveNote'
import { ReduxNoteEntity } from '../../features/notes/types'
import { LuruReduxStore } from './LuruReduxStore'

// those side effects
export const processUIEffects = (action: PayloadAction<{}>) => {
  switch (action.type) {
    // When a note is saved,
    // 1. pass on the message to the respective note editor
    // 2. add note to the app slice default luru notes, if it does not
    // exist already in the app slice, so that notes explorer may show the
    // note
    // 3. ensure note is added to the respective meeting in Redux
    case 'notes/saveNote/fulfilled': {
      let noteSavedAction = action as PayloadAction<
        ReduxNoteEntity,
        string,
        {
          arg: SaveNoteParameter
          requestId: string
          requestStatus: 'fulfilled'
        } & {
          count: number
          cursor: string | null
        },
        never
      >

      if (noteSavedAction.meta.arg.note_id) {
        postMessageToNoteEditor(
          noteSavedAction.meta.arg.note_id,
          EditorMessage.NoteChanged,
          action.payload
        )

        LuruReduxStore.dispatch(AppSliceActions.updateAppNotes(noteSavedAction))

        LuruReduxStore.dispatch(
          MeetingsSliceActions.updateMeetingNote(noteSavedAction)
        )
      }
    }
  }
}
