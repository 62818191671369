import LuruSelectBox from '.'
import { LuruSelectBoxProps } from './index'

export interface LuruSelectBoxEvents {
  onReset: () => void
  onChooseItem: (e: React.MouseEvent<HTMLElement>) => void
  onShowSelectDropdown: () => void
  onFilterBoxChange: (value: string | null) => void
  onFilterBoxNavigateDown: () => void
  onFilterBoxNavigateUp: () => void
  onFilterBoxReturn: () => void
  onFilterBoxCancel: () => void
}

export default class LuruSelectBoxEventHandler {
  #component: LuruSelectBox
  handlers: LuruSelectBoxEvents

  constructor(component: LuruSelectBox) {
    this.#component = component
    this.handlers = {
      onReset: this.#onReset.bind(this),
      onChooseItem: this.#onChooseItem.bind(this),
      onShowSelectDropdown: this.#onShowSelectDropdown.bind(this),
      onFilterBoxChange: this.#onFilterBoxChange.bind(this),
      onFilterBoxNavigateUp: this.#onFilterBoxNavigateUp.bind(this),
      onFilterBoxNavigateDown: this.#onFilterBoxNavigateDown.bind(this),
      onFilterBoxReturn: this.#onFilterBoxReturn.bind(this),
      onFilterBoxCancel: this.#onFilterBoxCancel.bind(this),
    }
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentDidUpdate = this.#onComponentUpdate.bind(this)
  }

  #onComponentMount() {
    // Auto focus logic
    if (this.#component.props.autoFocus) {
      this.#component.componentRefs.popupControl.current?.showMenu?.()
    }
  }

  #onComponentUpdate(prevProps: LuruSelectBoxProps) {
    if (prevProps.items !== this.#component.props.items) {
      this.#component.setState({
        filteredItems: this.#component.props.items,
      })
    }
    if (prevProps.prechosenItem !== this.#component.props.prechosenItem) {
      this.#component.setState({
        chosenItemName: this.#component.props.prechosenItem,
      })
    }

    this.#navigateToSelectedItem()
  }

  #onFilterBoxChange(value: string | null) {
    var filterText = value ?? ''
    this.#component.setState({
      filteredItems: this.#component.props.items.filter(
        (item) => item.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
      ),
    })
  }

  #onReset() {
    this.#chooseItem(undefined)
    this.#component.componentRefs.filterBox?.current?.reset()
  }

  #onChooseItem(e: React.MouseEvent<HTMLElement>) {
    const chosenItem = this.#component.props.items.find(
      (item) =>
        item.key?.toString() === (e.currentTarget as HTMLElement)?.dataset.itemChooserKey?.toString() ||
        (item.key === null && (e.currentTarget as HTMLElement)?.dataset.itemChooserKey === '--None--')
    )

    if (chosenItem) {
      this.#chooseItem(chosenItem)
    }
  }

  #onFilterBoxReturn() {
    var chosenIndex = this.#component.state.highlightedIndex

    if (chosenIndex >= 0 && chosenIndex < this.#component.state.filteredItems.length) {
      let chosenItem = this.#component.state.filteredItems[chosenIndex]
      this.#chooseItem(chosenItem)
    } else {
      this.#component.componentRefs.popupControl.current?.hideMenu()
      this.#component.componentRefs.filterBox?.current?.reset()
    }
  }

  #chooseItem(chosenItem?: { name: string; key: string | null }) {
    this.#component.componentRefs.popupControl.current?.hideMenu()
    this.#component.componentRefs.filterBox?.current?.reset()
    this.#component.setState({
      chosenItemName: this.#component.props.dontShowChoosenItemOnChoose ? undefined : chosenItem?.name,
      highlightedIndex: -1,
      filteredItems: this.#component.props.items,
    })

    if (chosenItem) {
      this.#component.props.onChooseItem(chosenItem.key)
    } else {
      this.#component.props.onReset?.()
    }
  }

  #onShowSelectDropdown() {
    // We need a setTimeout because focus() method can be called only when
    // element is visible.  The 100ms timeout allows this component to wait for
    // show() method to finish (including CSS fade-in animation transition end)
    setTimeout(() => this.#component.componentRefs.filterBox?.current?.focus(), 100)
  }

  #onFilterBoxNavigateUp() {
    var nextIndex =
      this.#component.state.highlightedIndex <= 0
        ? this.#component.state.filteredItems.length - 1
        : this.#component.state.highlightedIndex - 1

    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #onFilterBoxNavigateDown() {
    var nextIndex =
      this.#component.state.highlightedIndex >= this.#component.state.filteredItems.length - 1
        ? 0
        : this.#component.state.highlightedIndex + 1
    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #navigateToSelectedItem() {
    var ulElement = this.#component.componentRefs.list.current as HTMLUListElement
    var liElement = ulElement?.querySelector('li[data-highlighted="yes"]') as HTMLLIElement

    if (liElement) {
      let topOverflow = Math.max(0, ulElement.scrollTop + liElement.offsetHeight - liElement.offsetTop)
      let bottomOverflow = Math.max(
        0,
        liElement.offsetTop + liElement.offsetHeight - ulElement.scrollTop - ulElement.offsetHeight
      )
      if (topOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: -topOverflow, behavior: 'instant' })
      } else if (bottomOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: bottomOverflow, behavior: 'instant' })
      }
    }
  }

  #onFilterBoxCancel() {
    this.#component.setState({
      highlightedIndex: -1,
      filteredItems: this.#component.props.items,
    })
    this.#component.componentRefs.popupControl.current?.hideMenu()
    this.#component.componentRefs.filterBox?.current?.reset()
  }
}
