import React from 'react'
import { NavLink } from 'react-router-dom'

import NotesExplorerBarEventHandler from './NotesExplorerBarEventHandler'
import type { NotesExplorerBarConnectedProps } from '.'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { ConnectLuruExtensionsProps, EntityStatus, LuruEntryPoint } from '../../../app/types'
import TypeAheadSearchBox from '../../../primitives/TypeAheadSearchBox'
import explorerStyles from '../../../layout/css/Explorer.module.css'
import newExplorerStyles from '../../../layout/css/NewExplorer.module.css'
import crmIconStyles from '../../../primitives/css/CRMIcons.module.css'
import infoIcon from '../../../images/info.svg'
import LoadingSpinner from '../../../primitives/LoadingSpinner'
import LuruUser from '../../../domain/users/LuruUser'
import LuruButton from '../../../primitives/ui/LuruButton'
import moment from 'moment'
import AppComponentsContext from '../../../primitives/AppComponents/AppComponentsContext'
import { ReduxNoteEntity } from '../../../features/notes/types'
import { EMBEDDED_NOTE_HOME } from '../../../entryPoints/EmbeddedNoteApp/EmbeddedNoteAppComponent'
import refreshIcon from '../../../images/fluent/arrow_clockwise.svg'
import addCircleOutlinedIcon from '../../../images/fluent/add_circle.svg'
import arrowCircleDownIcon from '../../../images/fluent/chevron_circle_down.svg'
import noteIcon from '../../../images/fluent/document_bullet_list.svg'
import deleteIcon from '../../../images/fluent/delete.svg'
import shareIcon from '../../../images/fluent/share_android.svg'
import { AppComponents } from '../../../primitives/AppComponents/typings'
import closeDrawerStyles from '../../../layout/css/CloseDrawer.module.css'
import Tooltip from '@/components/Tooltip'
import { NoteDeleteConfirmDialogMethods } from '@/routes/notes/components'

export interface NotesExplorerBarProps extends NotesExplorerBarConnectedProps, ConnectLuruExtensionsProps {
  noteDeleteDialogRef: React.RefObject<NoteDeleteConfirmDialogMethods>
  isEmbedded?: boolean
  linkPrefix?: string
}

export interface NotesExplorerBarRefs {
  // Add other ref elements as required
  container: React.RefObject<HTMLElement>
  search: React.RefObject<typeof TypeAheadSearchBox>
}

export interface NotesExplorerBarState {
  displayedItemType: 'default' | 'search'
  sorNotesDisplay: 'collapsed' | 'expanded'
  searchQuery?: string
  endIndex: {
    luru: number
    sor: number
  }
  navigateToDefaultNoteCompleted: boolean
}

export default class NotesExplorerBarComponent extends React.Component<NotesExplorerBarProps, NotesExplorerBarState> {
  props: NotesExplorerBarProps
  state: NotesExplorerBarState
  eventHandler: NotesExplorerBarEventHandler
  componentRefs: NotesExplorerBarRefs
  pageSize: number = 10
  static contextType?: React.Context<any> = AppComponentsContext

  constructor(props: NotesExplorerBarProps) {
    super(props)
    this.props = props
    this.state = {
      displayedItemType: props.searchQuery ? 'search' : 'default',
      sorNotesDisplay: 'collapsed',
      searchQuery: props.searchQuery,
      endIndex: {
        luru: this.pageSize - 1,
        sor: this.pageSize - 1,
      },
      navigateToDefaultNoteCompleted: false,
    }
    this.componentRefs = {
      container: React.createRef(),
      search: React.createRef(),
    }
    this.eventHandler = new NotesExplorerBarEventHandler(this)
  }

  isValidEntryPoint() {
    return LuruUser.getCurrentEntryPoint().toLocaleLowerCase() === LuruEntryPoint.WEBAPP.toLocaleLowerCase()
  }

  // Add any external world facing methods here (so consumers can call methods
  // on this components with ref handles)
  render() {
    // Context
    var appContext = this.context as AppComponents

    return (
      <aside
        className={[
          explorerStyles.explorer,
          newExplorerStyles.flexible,
          this.isValidEntryPoint() && (appContext.layoutSettings?.hamburgerOpen ? null : closeDrawerStyles.closeAside),
          this.props.isEmbedded ? newExplorerStyles.embeddedExplorer : null,
        ].join(' ')}
        ref={this.componentRefs.container}
      >
        {this.#renderSearchBox()}
        {this.#renderLuruNotesHeading()}
        {this.#renderLuruNotes()}
        {this.#renderLoadMoreLuruNotesButton()}
        {this.#renderSORNotesHeading()}
        {this.#renderSORNotes()}
        {this.#renderLoadMoreSORNotesButton()}
      </aside>
    )
  }

  #renderSearchBox() {
    return (
      <TypeAheadSearchBox
        key={this.props.searchQuery ?? '__empty__luru__search__query'}
        // @ts-ignore
        typeAheadDelay={1000}
        placeholder='Search all notes'
        ariaLabel='Search all notes'
        searchText={this.props.searchQuery}
        // searchQuerySelector={searchQuerySelector}
        onTriggerSearch={this.eventHandler.handlers.onTriggerSearch}
        onClearSearch={this.eventHandler.handlers.onClearSearch}
        ref={this.componentRefs.search}
        loading={false}
      />
    )
  }

  #renderLuruNotesHeading() {
    var renderRefreshButton =
      this.props.notes.luru.default.status === EntityStatus.Loaded && this.state.displayedItemType === 'default'

    return (
      <h1 className={newExplorerStyles.heading}>
        <span>Luru Notes</span>
        <Tooltip label='Refresh list' placement='left'>
          <button
            className={[
              newExplorerStyles.createButton,
              newExplorerStyles.actionButton,
              !renderRefreshButton ? explorerStyles.disabled : null,
            ].join(' ')}
            onClick={this.eventHandler.handlers.onClickRefreshLuruNotes}
            disabled={!renderRefreshButton}
          >
            <img src={refreshIcon} alt='Refresh' />
          </button>
        </Tooltip>
        <Tooltip label='New Luru Note' placement='left'>
          <button
            className={[newExplorerStyles.createButton, newExplorerStyles.actionButton].join(' ')}
            onClick={this.eventHandler.handlers.onClickNewNote}
          >
            <img src={addCircleOutlinedIcon} alt='Refresh' />
          </button>
        </Tooltip>
      </h1>
    )
  }

  #renderLuruNotes() {
    return this.#renderNotes(
      'luru',
      this.state.displayedItemType === 'default' ? this.props.notes.luru.default : this.props.notes.luru.search
    )
  }

  #renderLoadMoreLuruNotesButton() {
    return <div>{this.#renderLoadMoreButton('luru')}</div>
  }

  #renderSORNotesHeading() {
    var renderRefreshButton =
      this.props.notes.sor.default.status === EntityStatus.Loaded && this.state.displayedItemType === 'default'

    return (
      <h1 className={newExplorerStyles.heading}>
        <span>{CrmRecord.getCrmName()} Notes</span>
        <Tooltip label='Refresh list' placement='left'>
          <button
            className={[
              newExplorerStyles.createButton,
              newExplorerStyles.actionButton,
              !renderRefreshButton ? explorerStyles.disabled : null,
            ].join(' ')}
            onClick={this.eventHandler.handlers.onClickRefreshSorNotes}
            disabled={!renderRefreshButton}
          >
            <img src={refreshIcon} alt='Refresh' />
          </button>
        </Tooltip>
        <Tooltip
          label={`${this.state.sorNotesDisplay === 'collapsed' ? 'Show' : 'Hide'} ${CrmRecord.getCrmName()} notes`}
          placement='left'
        >
          <button
            className={[
              newExplorerStyles.createButton,
              newExplorerStyles.actionButton,
              newExplorerStyles.expandCollapseButton,
              this.state.sorNotesDisplay === 'expanded' ? newExplorerStyles.upsideDown : null,
            ].join(' ')}
            onClick={this.eventHandler.handlers.onCollapseExpandSorNotes}
          >
            <img src={arrowCircleDownIcon} alt='Show or hide notes' />
          </button>
        </Tooltip>
      </h1>
    )
  }

  #renderSORNotes() {
    return this.state.sorNotesDisplay === 'collapsed'
      ? null
      : this.#renderNotes(
          'sor',
          this.state.displayedItemType === 'default' ? this.props.notes.sor.default : this.props.notes.sor.search
        )
  }

  #renderLoadMoreSORNotesButton() {
    return <div>{this.#renderLoadMoreButton('sor')}</div>
  }

  #renderNotes(
    section: 'luru' | 'sor',
    data: {
      status?: EntityStatus
      error?: { message: string } | null
      cursor: string | null
      results?: Array<Partial<ReduxNoteEntity>>
    }
  ) {
    if (data.status === EntityStatus.ErrorLoading) {
      return this.#renderError(data.error?.message ?? 'Error loading items')
    }

    if (data.status === EntityStatus.Loading) {
      return (
        <div className={newExplorerStyles.emptyPanel}>
          <LoadingSpinner size={12} color='--var(--brand-accent-color-blue)' />
          {this.state.displayedItemType === 'default' ? 'Loading notes...' : 'Searching...'}
        </div>
      )
    }

    if (!data.results?.length) {
      return (
        <div className={newExplorerStyles.emptyPanel}>
          No {this.state.displayedItemType === 'default' ? 'notes' : 'results'} to display
        </div>
      )
    }

    return (
      <ul
        className={[
          newExplorerStyles.recordList,
          this.state.sorNotesDisplay === 'collapsed' ||
          (this.props.notes.sor[this.state.displayedItemType].results?.length ?? -1) <= 0
            ? newExplorerStyles.naturalSize
            : null,
        ].join(' ')}
      >
        {data.results
          ?.filter((_, ix) => ix <= this.state.endIndex[section])
          .map((note) => {
            var crmConnection = this.getCRMConnection(note)
            var crmRecordType = CrmRecord.getCrmRecordType(crmConnection?.sor_object_name ?? '')
            var connectionTooltip = (
              <div className={newExplorerStyles.tooltip}>
                <div className='table-row'>
                  <div className={['table-cell', newExplorerStyles.colHeader].join(' ')}>
                    {crmConnection?.sor_object_name ?? ''}:
                  </div>
                  <div className={['table-cell', newExplorerStyles.colValue].join(' ')}>
                    {crmConnection?.sor_record_name ?? ''}
                  </div>
                </div>
                <div className='table-row'>
                  <div className={['table-cell', newExplorerStyles.colHeader].join(' ')}>Updated:</div>
                  <div className={['table-cell', newExplorerStyles.colValue].join(' ')}>
                    {moment(note.updated_at ?? note.created_at).format('DD-MMM-YY')}
                  </div>
                </div>
              </div>
            )
            var privateNoteTooltip = (
              <div className={newExplorerStyles.tooltip}>
                <div className='table-row'>
                  <div className={['table-cell', newExplorerStyles.colHeader].join(' ')}>Updated:</div>
                  <div className={['table-cell', newExplorerStyles.colValue].join(' ')}>
                    {moment(note.updated_at ?? note.created_at).format('DD-MMM-YY')}
                  </div>
                </div>
              </div>
            )
            var connectionName = (
              (crmConnection?.sor_object_name ?? '') +
              ': ' +
              (crmConnection?.sor_record_name ?? '')
            ).trim()
            var entryPoint = LuruUser.getCurrentEntryPoint()
            var link = `/notes/${note.note_id}` // Applicable for WEBAPP, NEWTAB

            switch (entryPoint) {
              case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
                // GMeet extension page
                link = `${EMBEDDED_NOTE_HOME}?target=specificNote&noteId=${note.note_id}`
                break

              case LuruEntryPoint.EMBEDDED_ZOOM_MEETING:
                link = `/embedded_note/${note.note_id}`
            }

            var currentLocation = this.props.router.location.pathname
            var linkClassName = currentLocation === link ? newExplorerStyles.selected : ''
            var luruIconName = `${crmConnection?.sor?.toLowerCase?.()}-${crmConnection?.sor_object_name?.toLowerCase?.()}`

            return (
              <NavLink
                key={note.note_id}
                // NEW EMBEDDED ROUTES; Old logic vs. New logic
                // to={`${this.props.linkPrefix ?? '/notes'}/${note.note_id}`}
                to={link}
                className={linkClassName}
              >
                <li key={note.note_id}>
                  <div className={newExplorerStyles.icon}>
                    {/* Add CRM icon rendering logic */}
                    <Tooltip
                      label={note.sync_state === 'private' && !crmConnection ? privateNoteTooltip : connectionTooltip}
                      placement='bottom'
                    >
                      {note.sync_state === 'private' && !crmConnection ? (
                        <img src={noteIcon} alt='Note' />
                      ) : (
                        <img
                          src={CrmRecord.getIcon(crmRecordType)}
                          alt={connectionName}
                          className={crmIconStyles.icon}
                          data-luru-icon-name={luruIconName}
                        />
                      )}
                    </Tooltip>
                  </div>
                  <div className={newExplorerStyles.recordDisplay}>
                    <div className={newExplorerStyles.primaryLine}>{note.title}</div>
                    <div className={newExplorerStyles.secondaryLine}>
                      <div className={newExplorerStyles.recordInfo}>
                        {note.sync_state === 'private' && !crmConnection?.sor_record_name
                          ? 'Private note'
                          : crmConnection?.sor_record_name || 'No Name'}
                      </div>
                      {this.props.isEmbedded ? null : (
                        <Tooltip label={'Share note'} placement='top'>
                          <button
                            className={[newExplorerStyles.shareButton, newExplorerStyles.actionButton].join(' ')}
                            onClick={() => {
                              this.eventHandler.handlers.onClickShareNote(note.note_id)
                            }}
                          >
                            <img src={shareIcon} alt='Share note' />
                          </button>
                        </Tooltip>
                      )}
                      {section === 'luru' && !this.props.isEmbedded ? (
                        <Tooltip label='Delete note' placement='top'>
                          <button
                            className={[newExplorerStyles.deleteButton, newExplorerStyles.actionButton].join(' ')}
                            onClick={(e) => {
                              // Prevent navigating to respective note when clicking on delete note from notes explorer
                              e.preventDefault()
                              e.stopPropagation()
                              this.eventHandler.handlers.onClickDeleteNote(note.note_id, note.sync_state)
                            }}
                          >
                            <img src={deleteIcon} alt='Delete note' />
                          </button>
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                </li>
              </NavLink>
            )
          })}
      </ul>
    )
  }

  #renderLoadMoreButton(section: 'luru' | 'sor') {
    if (section === 'sor' && this.state.sorNotesDisplay === 'collapsed') {
      return null
    }

    var displayedData = this.props.notes[section][this.state.displayedItemType]
    if (!displayedData.results?.length) {
      // retrun null if there are no results
      return null
    }
    var isCursorNonNull = displayedData.cursor !== null
    var renderedResults = displayedData.results
    var doRenderLoadMoreButton =
      (isCursorNonNull || (renderedResults?.length ?? -1) > this.state.endIndex[section] + 1) &&
      displayedData.status !== EntityStatus.Loading
    var isLoadingMore = displayedData.status === EntityStatus.LoadingMore

    return doRenderLoadMoreButton ? (
      <LuruButton
        title={isLoadingMore ? 'Loading' : 'Load more'}
        variant='link'
        loading={isLoadingMore}
        loadingSize={8}
        disabled={isLoadingMore}
        onClick={isLoadingMore ? undefined : () => this.eventHandler.handlers.onClickLoadMoreNotes(section)}
      >
        {isLoadingMore ? 'Loading' : 'More'}
      </LuruButton>
    ) : null
  }

  #renderError(message: string) {
    return (
      <div className={explorerStyles.noResults}>
        <div className={explorerStyles.warning} title={message}>
          <img src={infoIcon} alt={message} />
          <span>Error loading items</span>
        </div>
      </div>
    )
  }

  getCRMConnection(note: Partial<ReduxNoteEntity>) {
    var crmName = LuruUser.getCurrentUserCrmName()
    return note?.connections?.find((c) => c.sor === crmName)
  }
}
