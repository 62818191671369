// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse, LuruAPIResponseMetaData } from '../../../app/types'
import LuruError from '../../LuruError'
import { TasksAPI } from '../api'
import { ReduxTasksState } from '../types'

const requestName = 'tasks/deleteTask'

export type DeleteTaskParameter = {
  taskId: string
}

interface DeleteTaskAPIResponse extends LuruAPIResponse {
  data: ''
}

export const deleteTask = {
  action: createAsyncThunk<
    '',
    DeleteTaskParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: null | LuruAPIResponseMetaData
      rejectedMeta: null | LuruAPIResponseMetaData
    }
  >(
    requestName,
    async (
      params: DeleteTaskParameter,
      { fulfillWithValue, rejectWithValue, signal }
    ) => {
      try {
        var response = (await TasksAPI.deleteTask(params, {
          signal,
        })) as DeleteTaskAPIResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(deleteTask.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(deleteTask.action.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg.taskId]
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(deleteTask.action.rejected, (state, action) => {})
  },

  // TODO: We can do away with this after standardization
  addAllCases(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    deleteTask.addPendingCase(builder)
    deleteTask.addRejectedCase(builder)
    deleteTask.addFulfilledCase(builder)
  },
}
