import { PayloadAction } from '@reduxjs/toolkit'
import { SaveNoteParameter } from '../../notes/middleware/saveNote'
import { ReduxNoteEntity } from '../../notes/types'
import { CalendarProvider } from '../../user/types'
import { ReduxMeetingsState } from '../types'

export const updateMeetingNote = (
  state: ReduxMeetingsState,
  action: PayloadAction<{
    payload: ReduxNoteEntity
    meta: {
      arg: SaveNoteParameter
      requestId: string
      requestStatus: 'fulfilled'
    } & {
      count: number
      cursor: string | null
    }
    type: string
  }>
) => {
  const originalAction = action.payload
  const meetingId = originalAction.payload.connections?.find(
    (conn) => conn.sor === CalendarProvider.GCAL || conn.sor === CalendarProvider.O365CAL
  )?.sor_record_id
  const updatedNote = JSON.parse(JSON.stringify(originalAction.payload)) as ReduxNoteEntity

  if (!meetingId || !state.entities?.[meetingId] || !state.entities?.[meetingId]?.data?.notes) {
    return
  }

  const noteIx = state.entities?.[meetingId]?.data?.notes?.findIndex?.((n) => n.note_id === updatedNote.note_id)

  if (noteIx === -1) {
    state.entities[meetingId].data.notes.push(updatedNote)
  } else {
    state.entities[meetingId].data.notes[noteIx] = updatedNote
  }
}
