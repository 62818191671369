import moment from 'moment'
import { useRef } from 'react'
import { Provider } from 'react-redux'
import { LuruReduxStore } from '../../app/store'
import { EntityStatus } from '../../app/types'
import { TasksMiddleware } from '../../features/tasks/middleware'
import TaskViewModal from '../../routes/tasks/TaskViewModal'
import { EditorMessage, EditorMessageEvent } from '../EditorController'
import styles from './css/LuruTaskInfo.module.css'

// Icons
import personIcon from '../../images/fluent/person.svg'
import eventIcon from '../../images/fluent/calendar_ltr.svg'
import { useAppSelector } from '@/app/hooks'
// import { AppComponentsProvider } from '@/primitives/AppComponents'
// import { BrowserRouter } from 'react-router-dom'
// import AppUI from '@/app_ui/AppUI'

function getTaskInfoSelector(taskId) {
  return function getTaskInfo(state) {
    if (state?.tasks?.entities?.[taskId]?.status === EntityStatus.Loaded) {
      let { due_date, assigned_to } = state?.tasks?.entities?.[taskId]?.data
      return {
        dueDate: due_date,
        assignedToUser: assigned_to?.sor_record_name ?? undefined,
      }
    }
    return undefined
  }
}

function compareTaskInfo(left, right) {
  return left?.dueDate === right?.dueDate && left?.assignedToUser === right?.assignedToUser
}

function getTaskStatusSelector(taskId) {
  return function getTaskStatus(state) {
    return state?.tasks?.entities?.[taskId]?.status
  }
}

export default function LuruTaskInfoButtonWrapper(props) {
  let { taskId, noteId } = props
  return (
    <Provider store={LuruReduxStore}>
      {/* <BrowserRouter>
        <AppUI> */}
            <LuruTaskInfoButton taskId={taskId} noteId={noteId} />
        {/* </AppUI>
      </BrowserRouter> */}
    </Provider>
  )
}

function LuruTaskInfoButton(props) {
  let { taskId, noteId } = props
  let state = {
    taskInfo: useAppSelector(getTaskInfoSelector(taskId), compareTaskInfo),
    taskStatus: useAppSelector(getTaskStatusSelector(taskId)),
  }
  let taskModalRef = useRef()

  function onTaskUpdated(updateResponse = undefined) {
    if (updateResponse?.task_id) {
      let editorMessagePayload = {
        message: EditorMessage.TaskUpdated,
        data: updateResponse,
      }
      let editorMessageEvent = new CustomEvent(EditorMessageEvent, {
        detail: editorMessagePayload,
      })
      let editorElement = document.getElementById(`editor-${noteId.slice(0, 7)}`)
      if (editorElement) {
        editorElement.dispatchEvent(editorMessageEvent)
      }
    }
  }

  async function showModal() {
    let record = await LuruReduxStore.dispatch(TasksMiddleware.fetchTask.action({ taskId })).unwrap()
    taskModalRef.current?.showModal(record, onTaskUpdated)
  }

  return state.taskStatus === EntityStatus.Loaded ? (
    <span key={taskId} className={styles.luruTaskInfo} data-role='luru-task-info-container' contentEditable={false}>
      <TaskViewModal ref={taskModalRef} />
      <button className={styles.assignedTo} onClick={showModal}>
        <span className={styles.icon}>
          <img src={personIcon} alt='person' />
        </span>
        <span>{state.taskInfo?.assignedToUser ? state.taskInfo.assignedToUser : ''}</span>
      </button>
      <button className={styles.dueDate} onClick={showModal}>
        <span className={styles.icon}>
          <img src={eventIcon} alt='event' />
        </span>
        <span>{state.taskInfo?.dueDate ? moment(state.taskInfo.dueDate).format('DD-MMM-YY') : 'No due date'}</span>
      </button>
    </span>
  ) : state.taskStatus === EntityStatus.Loading ? (
    <span key={taskId} className={styles.luruTaskInfo} data-role='luru-task-info-container' contentEditable={false}>
      <button className={styles.noDueDate}>Updating...</button>
    </span>
  ) : (
    ''
  )
}
