import { connectGmailSync } from './connectGmailSync'
import { disconnectGmailSync } from './disconnectGmailSync'
import { connectO365MailSync } from './connectO365MailSync'
import { disconnectO365MailSync } from './disconnectO365MailSync'

export const EmailSyncsAPI = {
  connectGmailSync,
  disconnectGmailSync,
  connectO365MailSync,
  disconnectO365MailSync,
}
