import React, { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../app/hooks'
import { EntityStatus } from '../../../../../app/types'
import { useEditorCaretOperations } from './useEditorCaretOperations'
import { InsertCollectionCommandContext } from './useEditorMenuCommandListener'
import { useHandleChooseCollection } from './useHandleChooseCollection'
import { useShowHideCollectionsChooser } from './useShowHideCollectionsChooser'

export const useFilterBoxEvents = ({
  filterRef,
  hideCollectionsChooser,
  goBackToNotesEditor,
  editorMenuContext,
  sorObjectName,
}: {
  filterRef: React.MutableRefObject<HTMLInputElement | null>
  hideCollectionsChooser: ReturnType<
    typeof useShowHideCollectionsChooser
  >['hideCollectionsChooser']
  goBackToNotesEditor: ReturnType<typeof useEditorCaretOperations>
  editorMenuContext: InsertCollectionCommandContext | null
  sorObjectName: string | undefined
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [filterText, setFilterText] = useState('')

  const displayedRecords = useAppSelector((state) =>
    Object.keys(state.collections.entities).filter(
      (collectionId) =>
        state.collections.entities[collectionId].status ===
          EntityStatus.Loaded &&
        state.collections.entities[collectionId].data?.sor_object_name ===
          sorObjectName &&
        state.collections.entities[collectionId].data?.name
          ?.toLowerCase()
          .includes(filterText.toLowerCase())
    )
  )

  const handleFilterBoxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(e.target.value)
    },
    []
  )

  const handleChooseCollection = useHandleChooseCollection({
    cmdContext: editorMenuContext,
    selectedIndex,
    displayedRecords,
    goBackToNotesEditor,
    hideCollectionsChooser,
  })

  const handleFilterBoxKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowUp': {
          const currentIndex = selectedIndex

          if (e.key === 'ArrowDown') {
            setSelectedIndex((currentIndex + 1) % displayedRecords.length)
          } else {
            setSelectedIndex(
              currentIndex === 0
                ? displayedRecords.length - 1
                : currentIndex - 1
            )
          }

          // Set the state for currently highlighted item
          e.preventDefault()

          break
        }

        case 'Escape': {
          hideCollectionsChooser()
          goBackToNotesEditor({ trace: 'Escape key pressed in filter box' })
          // Set the state for currently highlighted item
          e.preventDefault()
          e.stopPropagation();
          break
        }

        case 'Enter': {
          if (selectedIndex >= 0) {
            handleChooseCollection(e)
            e.preventDefault()
          }
        }
      }
    },
    [
      goBackToNotesEditor,
      handleChooseCollection,
      hideCollectionsChooser,
      selectedIndex,
      displayedRecords,
    ]
  )

  useEffect(() => {
    let filterBox = filterRef.current

    filterBox?.addEventListener('keydown', handleFilterBoxKeyDown)

    return () => {
      filterBox?.removeEventListener('keydown', handleFilterBoxKeyDown)
    }
  })

  return {
    filterText,
    selectedIndex,
    handleFilterBoxChange,
    handleChooseCollection,
  }
}
