// Third party libraries
import json5 from 'json5'

// Own functions
import EditorDOM, { CaretPosition } from './EditorDOM'
import EditingHandler from './eventHandlers/EditingHandler'
import ClipboardHandler from './eventHandlers/ClipboardHandler'
import SelectionHandler from './eventHandlers/SelectionHandler'
import NavigationHandler from './eventHandlers/NavigationHandler'
import EditorEventsManager from './EditorEventsManager'
import TaskToggleHandler from './eventHandlers/TaskToggleHandler'
import CanvasEventsHandler from './eventHandlers/CanvasEventsHandler'
import CRMFieldDeleteHandler from './eventHandlers/CRMFieldDeleteHandler'
import JottingDragDropHandler from './eventHandlers/JottingDragDropHandler'
import { /* HashtagMenu, */ ShortcutMenu } from './EditorMenus'
import { LuruFieldType } from '../domain/crmRecord/typings.d'
import CRMFieldUpdateHandler from './eventHandlers/CRMFieldUpdateHandler'

// Styles
import styles from '../routes/notes/css/NotesEditor.module.css'
import loadingStyles from '../primitives/css/LoadingAnimation.module.css'
import { JottingType } from '../features/notes/types'
import CrmRecord from '../domain/crmRecord/CrmRecord'
import { trackEvent } from '../analytics/Ga'
import LuruUser from '../domain/users/LuruUser'
import { EntityStatus, LuruEntryPoint } from '../app/types'
import GlobalSearchHomeEventHandler from '../routes/globalSearch/GlobalSearchHome/GlobalSearchHomeEventHandler'
import { GlobalSearchHomeCustomEventsName } from '../routes/globalSearch/GlobalSearchHome/typings.d'
import { getPlatform } from '../utils/Utils'
import { NoteCrmConnectionChangeType } from './types'
import { LuruReduxStore } from '../app/store'
import { CollectionsMiddleware } from '../features/collections/middleware'
import { AppSliceActions } from '@/features/app/appSlice'
import getNoteIsEmtpy from '@/primitives/ui/ListItemChooserPopup/helpers/getNoteIsEmpty'
import getDirtiedAfterInsertion from '@/primitives/ui/ListItemChooserPopup/helpers/getDirtiedAfterInsertion'
import { ToastId } from '@/app_ui/types'

export const EditorEntityType = {
  Note: 'note',
  NoteTemplate: 'noteTemplate',
}

Object.freeze(EditorEntityType)

export const EditorMessageEvent = 'editormessage'

export const EditorMessage = {
  UpdateCRMConnectionAndShowTemplates: 'updateCrmConnectionAndShowTemplates',
  CRMRecordLinked: 'crmRecordLinked',
  CRMRecordUnlinked: 'crmRecordUnlinked',
  EditedEntityDirtied: 'noteDirtied',
  SaveNote: 'saveNote',
  TaskUpdated: 'taskUpdated',
  FocusEditor: 'focusEditor',
  NoteChanged: 'noteChanged',
  // ShowTemplates: 'showTemplates',
}

export const MeetingPlaybookMessage = {
  InsertPlaybook: 'insertPlaybook',
  UnselectPlaybook: 'unSelectPlaybook',
  ChangePlaybook: 'changePlaybook',
}

Object.freeze(EditorMessage)

/**
 * Send message to editor that has loaded note with ID noteId
 * @param {string} noteId Note ID
 * @param {string} message An accepted EditorMessage
 * @param {any} data Any payload
 */
export const postMessageToNoteEditor = (noteId, message, data) => {
  const editorMessagePayload = { message, data }
  const editorMessageEvent = new CustomEvent(EditorMessageEvent, {
    detail: editorMessagePayload,
  })
  const elemId = `editor-${noteId.slice(0, 7)}`
  const editorElement = document.getElementById(elemId)

  if (editorElement) {
    editorElement.dispatchEvent(editorMessageEvent)
  }
}

/**
 * @classdesc Main class for controlling editor component
 */
export default class EditorController {
  #view = null
  #menus = null
  #config = null
  #actions = null
  #noteBody = null
  #readonly = false
  #eventManager = null
  #autoSaveDelay = 1000
  #eventHandlers = null
  #noteDirtyFlag = false
  #autoSaveTimerId = null
  #syncSaveTimerId = null
  #autoSyncDelay = 60 * 1000 //60 sec
  #lastAutoSyncDateTime = new Date()
  // #crmRecordFields = null
  #infoBlockEditableTimerId = null
  /**
   * Initialize EditorController object and its internal data structures
   * @param {Object} config - of the following format:
   * { model, entityId, editorElement }
   */
  constructor(config) {
    this.#config = config
    this.#initializeActions()
    if (this.#config?.model) {
      this.#config?.model.setController(this)
    }
    this.onTemplateSelect = this.onTemplateSelect.bind(this)
  }

  // Actions
  /**
   * Initializes data structures for actions
   */
  #initializeActions() {
    // Add more setup and teardown actions into this array.
    // Creating the array per initialize() call ensures that a setup or
    // teardown can't be duplicated.
    this.#actions = [
      this.#computeEditorMenuActions(),
      this.#computeViewActions(),
      this.#computeEventManagementActions(),
      this.#computeNoteTitleActions(),
      this.#computeNoteEditorActions(),
      this.#computeDocumentSaveActions(),
      this.#computeWindowUnloadActions(),
    ]
  }

  /**
   * Initializes an ordered array of event handlers
   */
  #initializeEventHandlers() {
    this.#eventHandlers = {
      // Menus are like extensions to main editor; they build their own DOM,
      // they also need to interact with editor's DOM (with the view param)
      // given to their action.do() methods.  These menus also act as handlers.
      shortcutMenu: this.#menus?.shortcutMenu,
      // hashtagMenu: this.#menus?.hashtagMenu,
      // Core handlers like the following, don't have their own DOM.  They are
      // integral to the core editor and send messages to editor's DOM using
      // the view param passed to their action.do() methods
      editing: new EditingHandler(),
      clipboard: new ClipboardHandler(),
      navigation: new NavigationHandler(),
      selection: new SelectionHandler(),
      task: new TaskToggleHandler(),
      crmFieldDelete: new CRMFieldDeleteHandler(),
      crmFieldUpdate: new CRMFieldUpdateHandler(),
      dragdrop: new JottingDragDropHandler(),
      canvas: new CanvasEventsHandler(),
    }
  }
  onTemplateSelect(e) {
    const { message, template } = e

    switch (message) {
      case MeetingPlaybookMessage.InsertPlaybook:
        this.#config.dispatch?.(
          AppSliceActions.setNoteDetailsChanged({
            template_id: template.template_id,
            isEmpty: false,
            note_id: this.#config.noteId,
          })
        )
        this.onInsertTemplate(
          {
            data: template,
            position: 'before',
          },
          this.#view.getLastNoteElement()
        )
        this.#view?.setCaretAt(this.#view?.getNoteElementsList()[0], CaretPosition.END_OF_NOTE)
        break

      case MeetingPlaybookMessage.ChangePlaybook:
        this.#config.dispatch?.(
          AppSliceActions.setNoteDetailsChanged({
            template_id: template.template_id,
            isEmpty: false,
            note_id: this.#config.noteId,
          })
        )
        this.#noteBody = template.body
        // // Setting up new view for new body
        this.#actions.forEach((action) => action?.setup?.apply())

        if (this.#readonly === false) {
          setTimeout(() => {
            this.#view?.setCaretAt(this.#view?.getFirstNoteElement(), CaretPosition.END_OF_NOTE)
          }, 50)
        }

        this.setNoteDirtyFlag(true)
        break

      case MeetingPlaybookMessage.UnselectPlaybook:
        this.#config.dispatch?.(
          AppSliceActions.setNoteDetailsChanged({
            template_id: null,
            autoLinkedTemplateId: null,
            isEmpty: true,
            note_id: this.#config.noteId,
          })
        )
        this.#noteBody = json5.stringify([
          {
            type: JottingType.P,
            data: '',
          },
        ])
        // // Setting up new view for empty body
        this.#actions.forEach((action) => action?.setup?.apply())

        if (this.#readonly === false) {
          setTimeout(() => {
            this.#view?.setCaretAt(this.#view?.getFirstNoteElement(), CaretPosition.END_OF_NOTE)
          }, 50)
        }

        this.setNoteDirtyFlag(true)
        break
      default:
        return
    }
  }

  /**
   * Setup controller object by executing all setup actions
   */
  setup() {
    // Set loading message
    this.#config.templateChooserPopup?.current?.setTemplateStatus(EntityStatus.Loading)
    this.#config?.editorElement?.classList?.add(styles.editorLoading)
    let loadingElement = this.#config?.editorElement?.querySelector(`.${styles.loadingMessage?.replace('+', '\\+')}`)

    // Setting up the callback for insertion of playbooks
    this.getTemlateChooserPopup()?.setCallback(this.onTemplateSelect)

    // Make non editable for template chooser until the setup is done
    // this.getTemlateChooserPopup()?.makeNonEditable()

    // Initialize model (fetch note details to populate editor)
    this.#config.model
      .fetchAndSetupEntityDetails()
      .then((response) => {
        // Set the redux entities for tempalte chooser popup
        let noteDetails = LuruReduxStore.getState().notes.entities
        let detials = {}

        for (var key in noteDetails) {
          detials = {
            ...detials,
            [key]: {
              template_id: noteDetails[key]?.data?.template_id ?? null,
              isEmpty: getNoteIsEmtpy(noteDetails[key]?.data?.body ?? ''),
              noteBody: noteDetails[key]?.data?.body,
            },
          }
        }

        this.#config.dispatch?.(AppSliceActions.setNoteDetails(detials))

        this.#noteBody = response.noteBody

        let templateId = LuruReduxStore.getState().app.home.notes.noteDetails?.[this.#config.noteId]?.template_id
        let templateBody = LuruReduxStore.getState().noteTemplates[templateId ?? '']

        let isDirtiedAfterInsertion = getDirtiedAfterInsertion(response.noteBody ?? '', templateBody ?? '')

        if (isDirtiedAfterInsertion) {
          this.#config.dispatch?.(
            AppSliceActions.setNoteDetailsChanged({
              note_id: this.#config.noteId,
              dirtiedAfterInsertion: true,
            })
          )
        }

        if (this.getEntityType() === EditorEntityType.Note) {
          // this.#config.templateChooserPanel?.current?.setTemplates(this.#config.model.getApplicableTemplates())
          this.#config.templateChooserPopup?.current?.setTemplates(this.#config.model.getApplicableTemplates())
        }

        if (loadingElement) {
          loadingElement.innerHTML = ''
        }

        // Check if note should be read only
        if (!this.#config.model.isEntityEditable()) {
          this.#readonly = true

          // Using CSS we hide the "Edit in Luru" button if
          // dataset-luru-editable-status is "ReadonlyNoEditAccessInCRM"
          this.#getEditorContainerElement().dataset.luruEditableStatus = this.#config.model.getNoteEditStatus()
          let banner = this.#config.editorElement.querySelector('[data-role="editor-banner"]')
          let bannerMessage = this.#config.editorElement.querySelector('[data-role="editor-banner-message"]')
          let bannerButton = this.#config.editorElement.querySelector('[data-role="editor-banner-button"]')
          if (banner) {
            banner.classList.remove(styles.hidden)
          }
          if (bannerMessage) {
            bannerMessage.innerHTML = this.#config.model.getReadonlyMessage()
          }
          if (bannerButton) {
            bannerButton.firstChild.textContent =
              this.getEntityType() === EditorEntityType.Note ? 'Edit in Luru' : 'Copy template'

            if (bannerButton.addEventListener) {
              bannerButton.addEventListener(
                'click',
                this.getEntityType() === EditorEntityType.Note
                  ? this.#makeNoteEditable.bind(this)
                  : () =>
                      this.#config.model.createFromNoteTemplate(
                        {
                          target: {
                            dataset: {
                              noteTemplateId: this.#config.model.getEntityId(),
                            },
                          },
                        },
                        `noteTemplateEditor/banner`
                      )
              )
            }
          }
        } else {
          this.#readonly = false
          let infoBlock = document.getElementById(`note-info-${this.#config?.entityId?.slice(0, 7)}`)
          if (infoBlock) {
            infoBlock.dispatchEvent(new CustomEvent('makeeditable'))
          } else {
            this.#infoBlockEditableTimerId = setInterval(() => {
              let infoBlock = document.getElementById(`note-info-${this.#config?.noteId?.slice?.(0, 7)}`)
              if (infoBlock && infoBlock.getAttribute('data-editable') === 'true') {
                clearInterval(this.#infoBlockEditableTimerId)
              } else if (infoBlock) {
                infoBlock.dispatchEvent(new CustomEvent('makeeditable'))
              }
            }, 200)
          }
        }

        // Remove loading message
        this.#config?.editorElement?.classList?.remove(styles.editorLoading)
        this.#config.templateChooserPopup?.current?.setTemplateStatus(EntityStatus.Loaded)
        if (loadingElement) {
          loadingElement.classList.remove(styles.notLoadedMessage)
        }

        // Make editable for template chooser
        // this.getTemlateChooserPopup()?.makeEditable()

        // View is setup after all data is ready
        this.#actions.forEach((action) => action?.setup?.apply())

        if (this.#readonly === false) {
          setTimeout(() => {
            this.#view?.setCaretAt(this.#view?.getFirstNoteElement(), CaretPosition.END_OF_NOTE)
          }, 50)
        }
        // Event handlers can be available only after DOM setup is done
        this.#initializeEventHandlers()
      })
      .catch((error) => {
        if (loadingElement) {
          loadingElement.innerHTML = error.message
        }
      })
  }

  /**
   * Make the note editable in model
   */
  async #makeNoteEditable() {
    // Set 'making note editable' in progress
    let banner = this.#config.editorElement.querySelector('[data-role="editor-banner"]')
    let bannerMessage = this.#config.editorElement.querySelector('[data-role="editor-banner-message"]')
    let bannerButton = this.#config.editorElement.querySelector('[data-role="editor-banner-button"]')

    bannerButton.setAttribute('disabled', 'true')
    bannerButton.firstChild.textContent = 'Please wait...'
    bannerButton.firstChild.classList.add(loadingStyles.loading)
    bannerButton.firstChild.classList.add(loadingStyles.middle)

    try {
      // Make note editable through model
      let editResponse = await this.#config.model.makeNoteEditable()
      // If it is from Global Search Context, Sent a custom event
      if (LuruUser.getCurrentEntryPoint() === LuruEntryPoint.GLOBALSEARCH) {
        var noteId = editResponse.path?.split('/')?.[2]
        var eventDetail = {
          noteId: noteId,
        }
        GlobalSearchHomeEventHandler.getGlobalSearchContainer()?.dispatchEvent?.(
          new CustomEvent(GlobalSearchHomeCustomEventsName.onClickEditNoteInLuruFromGlobalSearch, {
            detail: eventDetail,
          })
        )
        return
      }
      if (editResponse?.next === 'makeCurrentNoteEditable') {
        this.#makeEditorEditable()
        banner.classList.add(styles.hidden)
        bannerButton.firstChild.classList.remove(loadingStyles.loading)
        bannerButton.firstChild.classList.remove(loadingStyles.middle)
      } else if (editResponse?.next === 'navigate') {
        bannerMessage.innerHTML = 'Navigating to new note'
        this.#config.navigate(editResponse.path)
      }
    } catch (e) {
      bannerMessage.innerHTML = e.message
      bannerButton.firstChild.textContent = 'Retry'
      bannerButton.firstChild.classList.remove(loadingStyles.loading)
      bannerButton.firstChild.classList.remove(loadingStyles.middle)
    }
  }

  /**
   * Make the editor editable
   */
  #makeEditorEditable() {
    // console.log(`#makeEditorEditable`)
    this.#readonly = false
    this.#view.makeEditorEditable()
    this.#view.setCaretAt(this.#view.getFirstNoteElement(), CaretPosition.END_OF_NOTE)
    let infoBlock = document.getElementById(`note-info-${this.#config?.entityId.slice(0, 7)}`)
    if (infoBlock) {
      infoBlock.dispatchEvent(new CustomEvent('makeeditable'))
    }
  }

  /**
   * Teardown controller object and constituents by executing all teardowns
   */
  teardown() {
    this.#actions.forEach((action) => action?.teardown?.apply())
  }

  /**
   * Set the record details of the CRM record whose fields are present in note
   * @param {Object} crmRecordFields - CRM record details object
   */
  // setCrmRecordFields(crmRecordFields) {
  //   this.#crmRecordFields = crmRecordFields
  // }

  /**
   * Set or unset note dirty flag
   * @param {Boolean} dirtyFlag - Indicator if note is dirty or not
   */
  setNoteDirtyFlag(dirtyFlag) {
    // Dispatching this action becuase ListItemChooserPopup need this to validate
    if (!dirtyFlag) {
      let isEmpty = this.#view?.isNoteEmpty?.() ?? true
      this.#config.dispatch?.(
        AppSliceActions.setNoteDetailsChanged({
          note_id: this.#config.noteId,
          isEmpty,
        })
      )
    }

    this.#noteDirtyFlag = dirtyFlag
  }

  getNoteDirtyFlag() {
    return this.#noteDirtyFlag
  }

  setupLinkEvents(target) {
    this.#eventManager.setupLinkEvents(target)
  }

  /**
   * Handle event when a CRM field is chosen for insertion
   * @param {Object} response - Response with field details from field chooser
   * @param {HTMLElement} noteElement - Note element from which insert field was
   * triggered
   */
  onReceiveChosenCrmField(response, noteElement) {
    if (response.field !== undefined) {
      this.insertSingleCrmField(response, noteElement)
    } else if (response.fieldSet !== undefined) {
      for (let eachField of response.fieldSet) {
        this.insertSingleCrmField(
          {
            crmId: response.crmId,
            linkedRecord: response.linkedRecord,
            field: eachField,
          },
          noteElement
        )
      }
    }
  }

  /**
   * Handle event when a CRM field is chosen for insertion
   * @param {Object} response - Response with field details from field chooser
   * @param {HTMLElement} noteElement - Note element from which insert field was
   * triggered
   */
  onReceiveChosenCrmFieldNew(response, noteElement) {
    if (response.field !== undefined) {
      this.insertSingleCrmFieldNew(response, noteElement)
    } else if (response.fieldSet !== undefined) {
      for (let eachField of response.fieldSet) {
        this.insertSingleCrmFieldNew(
          {
            crmId: response.crmId,
            linkedRecord: response.linkedRecord,
            field: eachField,
          },
          noteElement
        )
      }
    }
  }

  /**
   * Handle event when a CRM field is chosen for insertion
   * @param {Object} response - Response with field details from field chooser
   * @param {HTMLElement} noteElement - Note element from which insert field was
   * triggered
   */
  onReceiveChosenCrmCollection(response, noteElement) {
    const { chosenCollection: collectionId } = response
    this.insertCrmCollection(collectionId, noteElement)
  }

  /**
   * Handle event when a CRM field is chosen for insertion
   * @param {{crmId: string, field: { name: string, value: any }}} response - Response with field details from field chooser
   * @param {HTMLElement} noteElement - Note element from which insert field was
   * triggered
   */
  insertSingleCrmFieldNew(response, noteElement) {
    let luruFieldType = response?.field?.schema?.luruFieldType
    let crmJottingData =
      this.getEntityType() === EditorEntityType.Note
        ? {
            sorId: response.crmId,
            record: {
              sorRecordId: response?.linkedRecord?.connection?.sor_record_id ?? 'No Record Id',
              sorObjectName: response?.linkedRecord?.connection?.sor_object_name ?? 'No Object Name',
              sorRecordName: response?.linkedRecord?.connection?.sor_record_name ?? 'No Record Name',
            },
            field: {
              name: response.field.name,
              label: response.field.schema.label,
              value: response.field.value,
              luruFieldType,
              readonly: !response.field.schema.updateable,
              isNameField: response.field.schema.nameField,
            },
          }
        : {
            sorId: response.crmId,
            field: {
              name: response.field.name,
              label: response.field.schema.label,
              luruFieldType,
              readonly: !response.field.schema.updateable,
              isNameField: response.field.schema.nameField,
            },
          }

    if (luruFieldType === LuruFieldType.ENUM || luruFieldType === LuruFieldType.MULTIENUM) {
      crmJottingData.field.picklistValues =
        response?.field?.schema?.picklistValues?.map((item) => ({
          label: item.label,
          value: item.value,
        })) ?? []
    }

    if (luruFieldType === LuruFieldType.REFERENCE || luruFieldType === LuruFieldType.MULTIREFERENCE) {
      crmJottingData.field.sorObjectName =
        response?.field?.schema?.referencedObject ?? response.field.value?.sor_object_name ?? 'Error'
    }
    this.#view?.insertCrmFieldNew(noteElement, 'after', crmJottingData)
  }

  /**
   * Handle event when a CRM field is chosen for insertion
   * @param {Object} response - Response with field details from field chooser
   * @param {HTMLElement} noteElement - Note element from which insert field was
   * triggered
   */
  insertSingleCrmField(response, noteElement) {
    let luruFieldType = response.field.schema.luruFieldType
    let crmJottingData =
      this.getEntityType() === EditorEntityType.Note
        ? {
            sorId: response.crmId,
            record: {
              sorRecordId: response.linkedRecord.connection.sor_record_id,
              sorObjectName: response.linkedRecord.connection.sor_object_name,
            },
            field: {
              name: response.field.name,
              label: response.field.schema.label,
              value: response.field.value,
              luruFieldType,
              readonly: !response.field.schema.updateable,
              isNameField: response.field.schema.nameField,
            },
          }
        : {
            sorId: response.crmId,
            field: {
              name: response.field.name,
              label: response.field.schema.label,
              luruFieldType,
              readonly: !response.field.schema.updateable,
              isNameField: response.field.schema.nameField,
            },
          }

    if (luruFieldType === LuruFieldType.ENUM || luruFieldType === LuruFieldType.MULTIENUM) {
      crmJottingData.field.picklistValues =
        response?.field?.schema?.picklistValues?.map((item) => ({
          label: item.label,
          value: item.value,
        })) ?? []
    }

    if (luruFieldType === LuruFieldType.REFERENCE || luruFieldType === LuruFieldType.MULTIREFERENCE) {
      crmJottingData.field.sorObjectName =
        response?.field?.schema?.referencedObject ?? response.field.value?.sor_object_name ?? 'Error'
    }

    // 27-02-23: Using new CRM field method
    // this.#view?.insertCrmField(noteElement, 'before', crmJottingData)
    this.#view?.insertCrmFieldNew(noteElement, 'before', {
      fieldName: response.field.name,
    })
  }

  /**
   * Handle inserting a CRM field collection
   * @param {string} collectionId - Collection id
   * @param {HTMLElement} noteElement - Note element from which insert field was
   * triggered
   */
  async insertCrmCollection(collectionId, noteElement) {
    try {
      const collection = await LuruReduxStore.dispatch(
        CollectionsMiddleware.fetchCollection.action({ collectionId })
      ).unwrap()

      this.#view?.insertCrmCollection(noteElement, 'after', {
        collectionId,
        collection,
      })
    } catch (e) {
      console.error('Error fetching collection#', collectionId, ':', e)
    }
  }

  /**
   * Handle event when a template is chosen for insertion
   * @param {Object} insertSpec - Response with template details
   * @param {HTMLElement} noteElement - Note element from which insert field was
   * triggered
   */
  onInsertTemplate(insertSpec, noteElement) {
    const templateBodyJson = insertSpec?.data?.body ?? insertSpec?.body ?? insertSpec?.template?.body
    const insertPosition = insertSpec?.position ?? 'before'

    if (!templateBodyJson) {
      console.warn('onInsertTemplate called without valid playbook')
    }

    let templateBody = null

    try {
      templateBody = json5.parse(templateBodyJson)

      if (insertPosition === 'after') {
        templateBody.reverse()
      }
    } catch (e) {
      console.warn(e, 'Playbook received for insertion is not in a valid JSON format')
    }

    templateBody?.forEach?.((jotting) => {
      const jottingType = jotting.type

      // 27-02-23: For now, for CRM_FIELD_VALUE, we will use the regular
      // insertJotting() function
      if (jottingType === JottingType.CRM_FIELD_VALUE && false) {
        const crmRecordDetails = this.#config.model.getCrmRecordDetails()
        const crmConnection = this.#config.model.getCrmConnection()

        let crmFieldData = {
          ...jotting.data,
          record: {
            sorRecordId: crmConnection.sor_record_id,
            sorObjectName: crmConnection.sor_object_name,
          },
          field: {
            ...jotting.data.field,
            value:
              Object.entries(crmRecordDetails?.record).find(
                ([fieldName, fieldDetails]) => fieldName === jotting?.data?.field?.name
              )?.[1]?.value ?? 'Error',
          },
        }

        if ([LuruFieldType.ENUM, LuruFieldType.MULTIENUM].includes(jotting.data.field.luruFieldType)) {
          crmFieldData.field.picklistValues = jotting.data.field.picklistValues.map((item) => ({
            label: item.label,
            value: item.value,
          }))
        }

        this.#view?.insertCrmField(noteElement, insertPosition, crmFieldData)
      } else {
        this.#view?.insertJotting(noteElement, insertPosition, jottingType, null, jotting.data)
      }
    })

    this.setNoteDirtyFlag(true)
  }

  /**
   * Handle the event when caret moves away from a task jotting
   * @param {HTMLElement} taskJotting Task jotting that got blurred
   */
  async onTaskJottingBlurred(taskJotting) {
    let taskData = this.#view.getTaskJottingData(taskJotting)
    // Strip html tags from title if any
    if (taskData?.data?.title) {
      taskData.data.title = taskData.data.title.replace(/<[^>]*>/g, '')
    }
    if (taskData?.data?.titleText) {
      taskData.data.titleText = taskData.data.titleText.replace(/<[^>]*>/g, '')
    }

    if (taskData === null) {
      return
    } else if (
      !taskData.data.taskId ||
      taskData.data.taskId === 'null' ||
      taskData.data.taskId === 'undefined' ||
      taskData.data.taskId === ''
    ) {
      let taskId = taskData.data.taskId
      // Create task
      if (taskData.data.title !== '') {
        this.#view.addTaskCreatingElement(taskJotting)
        taskId = await this.#config.model.createTask(taskData)
        this.#view.markJottingWithTaskId(taskJotting, taskId)
        this.#view.removeTaskCreatingElement(taskJotting)
        this.#view.addTaskInfoElement(taskJotting, taskId)
      } else {
        this.#view.unmarkJottingWithTaskId(taskJotting)
      }
    } else {
      // Update task
      if (!taskData.data.title) {
        return this.#config?.showToast?.({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: <span>Task title can't be empty</span>,
          severity: 'warning',
        })
      }
      // Remove any br tags if exists
      this.#view.removeTaskBreakElements(taskJotting)
      await this.#config.model.updateTask(taskData)
    }
  }

  /**
   * Handle the event when a CRM field value has been updated
   * @param {Object} crmFieldData - Luru CRM data
   * @param {string} newFieldValue - New field value
   * @param {HTMLElement} jottingElement - Jotting element where update happened
   */
  onCrmFieldUpdated(crmFieldData, newFieldValue, jottingElement) {
    if (crmFieldData?.field?.readonly === 'true' || crmFieldData?.field?.readonly === true) {
      return
    }

    let isValidValue = CrmRecord.isFieldValueValid(crmFieldData.field.luruFieldType, newFieldValue)
    /**
     * TODO: Do this for MULTIREFERENCE as well, decode field values from
     * newFieldValue
     */
    let valueForUpdate =
      crmFieldData.field.luruFieldType === LuruFieldType.REFERENCE ? newFieldValue.sorRecordId : newFieldValue

    if (isValidValue) {
      if (newFieldValue === '' && crmFieldData.field.luruFieldType !== LuruFieldType.MULTIENUM) {
        return
      }

      this.#view?.showUpdateFieldInfo(jottingElement, {
        type: EntityStatus.Loading,
      })

      this.#config.model
        .updateRecordField(crmFieldData, valueForUpdate)
        .then((response) => {
          if (crmFieldData.field.luruFieldType === LuruFieldType.REFERENCE) {
            let fieldValueObj = crmFieldData.field.value

            fieldValueObj = {
              ...fieldValueObj,
              sor_record_id: newFieldValue.sorRecordId,
              sor_record_name: newFieldValue.sorRecordName || 'No Name',
            }

            jottingElement.setAttribute('data-field-value', json5.stringify(fieldValueObj))
          } else if (crmFieldData.field.luruFieldType === LuruFieldType.MULTIREFERENCE) {
            jottingElement.setAttribute('data-field-value', json5.stringify(newFieldValue))
          } else {
            jottingElement.setAttribute('data-field-value', newFieldValue)
          }

          jottingElement.removeAttribute('data-field-previous-value')
          this.#view?.showUpdateFieldInfo(jottingElement, {
            type: EntityStatus.Updated,
          })
          this.setNoteDirtyFlag(true)

          let isTemplateInserted =
            LuruReduxStore.getState().app.home.notes.noteDetails?.[this.#config.noteId]?.template_id

          if (isTemplateInserted) {
            this.#config.dispatch?.(
              AppSliceActions.setNoteDetailsChanged({
                note_id: this.#config.noteId,
                dirtiedAfterInsertion: true,
              })
            )
          }
        })
        .catch((error) => {
          console.trace(error)
          this.#view?.showUpdateFieldInfo(jottingElement, {
            type: EntityStatus.ErrorUpdating,
            message: error,
          })
          // this.setCrmRecordFields(this.#config.model.getCrmRecordDetails())
        })

      return
    }

    // If field value is not valid
    this.#view?.showUpdateFieldInfo(jottingElement, {
      type: EntityStatus.ErrorUpdating,
      message: 'Invalid value',
    })
  }

  /**
   * Handle messages from external world to editor
   */
  async onReceiveMessage(e) {
    const { detail: payload } = e
    const { message, data } = payload

    switch (message) {
      case EditorMessage.NoteChanged:
        // Reload editor
        this.teardown()
        this.setup()
        break

      case EditorMessage.TaskUpdated:
        this.#view?.updateTaskJottings(data)
        break

      case EditorMessage.FocusEditor:
        if (this.#readonly === false) {
          setTimeout(() => {
            this.#view?.setCaretAt(this.#view?.getFirstNoteElement(), CaretPosition.END_OF_NOTE)
          }, 50)
        }
        break

      case EditorMessage.UpdateCRMConnectionAndShowTemplates:
        // Update CRM connection itself would refresh templates
        // (This is done so Model's integrity is responsibility of model)
        let { isNoteNonDraft, connection, isEmbedded, connectionChangeType } = data
        let isNoteEmpty = this.#view?.isNoteEmpty?.()

        // For auto insertion

        await this.#config.model.updateCrmConnection(connection)
        await this.#config.model.updateTaskConnection(connection)

        // Replace or remove CRM field values based on connection change type
        switch (connectionChangeType) {
          case NoteCrmConnectionChangeType.REPLACE_SAME_TYPE:
            // 27-02-23: New embedded fields are automatically refreshed
            // this.#view?.refreshCrmFieldValues(data?.connection)
            break

          case NoteCrmConnectionChangeType.REPLACE_OTHER_TYPE:
            this.#view?.removeCrmFieldValueJottings()
            this.#view?.removeCrmCollections()
            break

          default:
        }

        // this.#config.templateChooserPanel?.current?.setTemplates(this.#config.model.getApplicableTemplates())
        this.#getNoteSyncButton()?.dispatchEvent(new CustomEvent('makeeditable'))

        // DEFERRED: Disabling dirtying & auto-save on add link in draft note
        if (isNoteNonDraft === true) {
          this.setNoteDirtyFlag(true)

          this.autoSave()
        }

        let templates = data.templates.map((result) => ({
          data: result.template,
          status: EntityStatus.Loaded,
          id: result.template.template_id,
          label: result.template.title,
        }))

        if (templates.length === 0) {
          // If there no templates, no-op.  We return without doing anything.
          return
        }

        // Insert template if we're inside embedded note and only 1 max
        // specificity template is available
        if (isEmbedded || true) {
          let maxSpecificity = data.templates.reduce(
            (prevValue, result) => (result.specificity > prevValue ? result.specificity : prevValue),
            -1
          )
          let maxSpecifityTemplates = data.templates.filter((result) => result.specificity === maxSpecificity)
          let numberOfMaxSpecificityTemplates = maxSpecifityTemplates.length
          let noteBody = this.#view.getNoteDataArray()
          if (numberOfMaxSpecificityTemplates === 1 && noteBody.length <= 1 && isNoteEmpty) {
            this.onInsertTemplate(
              {
                data: maxSpecifityTemplates[0].template,
                position: 'before',
              },
              this.#view.getLastNoteElement()
            )
            this.#view?.setCaretAt(this.#view?.getNoteElementsList()[0], CaretPosition.END_OF_NOTE)
            this.#config.templateChooserPopup?.current?.setTemplates(data.templates)
            this.#config.dispatch?.(
              AppSliceActions.setNoteDetailsChanged({
                autoLinkedTemplateId: maxSpecifityTemplates[0].template?.template_id,
                note_id: this.#config.noteId,
                template_id: maxSpecifityTemplates[0].template?.template_id,
              })
            )
            //   this.#config.templateChooserPanel?.current?.hide()
            let isManuallyChanged = this.#config.templateChooserPopup?.current?.getIsManuallyToggled()
            !isManuallyChanged && this.#config.templateChooserPopup?.current?.hideTemplates()
            // Google-Analytics
            trackEvent('use_template', 'auto')
            trackEvent('template_autoinsertion', '')
            return
          }
        }

        // Show template chooser dialog when
        // 1. We're not inside an embedded note, or,
        // 2. There are more than 1 max specificity template matches
        // let templateInfo = `
        //   ${LuruUser.getCurrentUserCrmName()?.toTitleCase?.()} record "${connection.sor_record_name}"
        //   ${isAutoLinked ? 'auto-linked' : 'linked'} to note. Matching note
        //   template${templates.length > 1 ? 's' : ''}
        //   ${isAutoLinked ? 'also' : ''} found based on record.
        // `

        // this.getTemplateChooserDialog()
        //   ?.setItems(templates)
        //   ?.showDialog({
        //     title: templateInfo,
        //     ok: (chosenTemplate) => {
        //       trackEvent('use_template', 'suggested')
        //       this.onInsertTemplate({ ...chosenTemplate, position: 'after' }, this.#view?.getLastNoteElement())
        //       this.#config.templateChooserPanel?.current?.hide()
        //       this.#view?.setCaretAt(this.#view?.getNoteElementsList()[0], CaretPosition.END_OF_NOTE)
        //     },
        //   })
        this.#config.templateChooserPopup?.current?.setTemplates(data.templates)
        let isManuallyChanged = this.#config.templateChooserPopup?.current?.getIsManuallyToggled()
        let autoLinked = this.#config?.templateChooserPopup?.current?.getAutoLinedTemplateId?.()
        if (!isManuallyChanged && isNoteEmpty && !autoLinked) {
          this.#config.templateChooserPopup?.current?.showTemplates()
        }
        trackEvent('template_autosuggest')

        break

      case EditorMessage.CRMRecordLinked:
        // Update CRM connection itself would refresh templates
        // (This is done so Model's integrity is responsibility of model)
        await this.#config.model.updateCrmConnection(data?.connection)
        await this.#config.model.updateTaskConnection(data?.connection)

        // Replace or remove CRM field values based on connection change type
        switch (data?.connectionChangeType) {
          case NoteCrmConnectionChangeType.REPLACE_SAME_TYPE:
            // 27-02-23: New embedded fields are automatically refreshed
            // this.#view?.refreshCrmFieldValues(data?.connection)
            break

          case NoteCrmConnectionChangeType.REPLACE_OTHER_TYPE:
            this.#view?.removeCrmFieldValueJottings()
            this.#view?.removeCrmCollections()
            break

          default:
        }

        // this.#config.templateChooserPanel?.current?.setTemplates(this.#config.model.getApplicableTemplates())
        this.#config.templateChooserPopup?.current?.setTemplates(this.#config.model.getApplicableTemplates())
        this.#getNoteSyncButton()?.dispatchEvent(new CustomEvent('makeeditable'))
        // DEFERRED: Disabling dirtying & auto-save on add link in draft note
        if (data?.isNoteNonDraft === true) {
          this.setNoteDirtyFlag(true)

          this.autoSave()
        }
        break

      case EditorMessage.CRMRecordUnlinked:
        this.#view?.removeCrmFieldValueJottings()
        this.#view?.removeCrmCollections()

        this.#config.dispatch?.(
          AppSliceActions.setNoteDetailsChanged({
            template_id: null,
            note_id: this.#config.noteId,
          })
        )
        await this.#config.model.removeTaskConnections()
        // Remove CRM connection itself would refresh templates
        // (This is done so Model's integrity is responsibility of model)
        await this.#config.model.removeCrmConnection()
        // this.#config.templateChooserPanel?.current?.setTemplates(this.#config.model.getApplicableTemplates())
        this.#config.templateChooserPopup?.current?.setTemplates(this.#config.model.getApplicableTemplates())
        // DEFERRED: Disabling dirtying & auto-save on remove link in draft note
        if (data?.response?.payload?.noteStatus !== 'draft') {
          this.setNoteDirtyFlag(true)

          this.autoSave()
        }
        break

      case EditorMessage.EditedEntityDirtied:
        this.setNoteDirtyFlag(true)

        let isTemplateInserted =
          LuruReduxStore.getState().app.home.notes.noteDetails?.[this.#config.noteId]?.template_id

        if (isTemplateInserted) {
          this.#config.dispatch?.(
            AppSliceActions.setNoteDetailsChanged({
              note_id: this.#config.noteId,
              dirtiedAfterInsertion: true,
            })
          )
        }

        this.autoSave()
        break

      case EditorMessage.SaveNote:
        // console.log(`EditorController:message:SaveNote:`)
        this.saveEditedEntity(data.callback)
        break

      default:
    }
  }

  /**
   * Handle events from event manager
   */
  #onReceiveUIEvent(eventStream) {
    if (!this.#eventHandlers) {
      return
    }
    const readOnlyHandlers = ['clipboard']
    const handlers = this.isEditorReadOnly()
      ? Object.keys(this.#eventHandlers).filter((handlerName) => readOnlyHandlers.includes(handlerName))
      : Object.keys(this.#eventHandlers)

    for (let key of handlers) {
      let handler = this.#eventHandlers[key]
      // Each handler first tells if & what is to be done
      let action = handler.computeHandling(this.#view, eventStream, this)

      // Logging to check what a handler returns
      // key === 'selection' &&
      // console.log(`EditorController:onReceiveUIEvent:`, key, handler)

      // If there is something to be done by this handler, it is done
      if (action && action.do instanceof Function) {
        action.do(this.#view, this)
      }
      // If that something results in note becoming dirty, state is updated so
      if (action && action.dirtyFlag) {
        this.setNoteDirtyFlag(true)

        let isTemplateInserted =
          LuruReduxStore.getState().app.home.notes.noteDetails?.[this.#config.noteId]?.template_id

        if (isTemplateInserted) {
          this.#config.dispatch?.(
            AppSliceActions.setNoteDetailsChanged({
              note_id: this.#config.noteId,
              dirtiedAfterInsertion: true,
            })
          )
        }
      }

      // If the handler decides further processing is not needed, it can tell so
      // Ordering of event handlers is critical if stopHandling is used.  It is
      // upto controller to ensure this order (and it is easy to manage it so).
      if (action && action.stopHandling) {
        break
      }
    }

    if (this.#noteDirtyFlag) {
      this.autoSave()
    }
  }

  /**
   * Function to auto-save after a delay.  Clears any pending timeout set
   */
  autoSave() {
    if (!this.#noteDirtyFlag) {
      return
    }

    var template_id = LuruReduxStore.getState().app.home.notes.noteDetails?.[this.#config.noteId]?.template_id
    // Handle autoSave
    let autoSave = () => {
      if (!this.#view) {
        return
      }

      // Check auto sync delay & set the autoSyncFlag to enable note sync in the backend.
      var autoSyncFlag = false
      var now = new Date()
      var thenSyncDate = this.#lastAutoSyncDateTime
      const diffInMSeconds = Math.abs(now - thenSyncDate)
      var noteDataString = this.getLatestNoteContent()
      if (diffInMSeconds >= this.#autoSyncDelay) {
        autoSyncFlag = true
        this.#lastAutoSyncDateTime = new Date()
      }

      var onSuccess = () => {
        this.#noteBody = noteDataString
        this.setNoteDirtyFlag(false)
      }

      onSuccess = onSuccess.bind(this)

      this.#config.model.saveEntity({
        data: noteDataString,
        sync: autoSyncFlag,
        type: 'draft',
        source: 'autosave',
        onSuccess,
        template_id: template_id,
      })

      if (autoSyncFlag) {
        // 1-s auto-save puts the saved note ahead of synced note when the note
        // has just been (< 60s) synced.  To bring synced note in line with
        // this auto-saved note, we start another 60-s timer to sync-save
        if (this.#syncSaveTimerId) {
          clearTimeout(this.#syncSaveTimerId)
        }

        this.#syncSaveTimerId = setTimeout(() => {
          try {
            var onSyncSaveSuccess = () => {
              this.#syncSaveTimerId = null
              onSuccess()
            }

            // Update local variable noteDataString to reflect latest body
            noteDataString = this.getLatestNoteContent()
            // Save note with sync
            this.#config.model.saveEntity({
              data: noteDataString,
              sync: true,
              type: 'draft',
              source: 'autosave',
              // Callback - this uses noteDataString
              onSuccess: onSyncSaveSuccess,
              template_id: template_id,
            })
          } catch (e) {
            // console.warn(e)
          }
        }, this.#autoSyncDelay)
      }
    }

    autoSave = autoSave.bind(this)

    if (this.#autoSaveTimerId) {
      clearTimeout(this.#autoSaveTimerId)
    }

    this.#autoSaveTimerId = setTimeout(autoSave, this.#autoSaveDelay)
  }

  /**
   * Function to auto-save after a delay.  Clears any pending timeout set
   */
  saveEditedEntity(callback = null) {
    // console.log(callback)
    if (this.#noteDirtyFlag) {
      if (!this.#view) {
        return
      }
      let noteDataString = this.getLatestNoteContent()
      const template_id = LuruReduxStore.getState().notes.entities[this.#config.noteId]?.data?.template_id

      let onSuccess = () => {
        this.#noteBody = noteDataString
        this.setNoteDirtyFlag(false)
        if (callback instanceof Function) {
          callback({ noteDirtyAndSaved: true })
        }
      }
      onSuccess = onSuccess.bind(this)

      return this.#config.model.saveEntity({
        type: 'full',
        data: noteDataString,
        onSuccess,
        source: 'saveEditedEntity',
        sync: true,
        template_id: template_id,
      })
    } else {
      callback({ noteDirtyAndSaved: false })
      return new Promise((resolve, reject) => resolve(true))
    }
  }

  setFocusInTitle() {
    let titleElement = this.#getNoteTitleElement()
    titleElement.focus()
    setTimeout(() => {
      titleElement.selectionStart = 1000
      titleElement.selectionEnd = 1000
    })
  }

  static focusEditor(noteId) {
    const editorId = `editor-${noteId.slice(0, 7)}`
    const editorElement = document.getElementById(editorId)
    const editorMessagePayload = {
      message: EditorMessage.FocusEditor,
      data: {},
    }

    editorElement?.dispatchEvent(
      new CustomEvent(EditorMessageEvent, {
        detail: editorMessagePayload,
      })
    )
  }

  // Calculations
  getEntityType() {
    // By default, edited entity is note; noteTemplateEditorController overrides
    // this method.  Later noteEditorController would also override/implement
    // this, making this function an abstract one
    return EditorEntityType.Note
  }

  getViewInstance() {
    return this.#view
  }

  getLatestNoteContent() {
    return json5.stringify(this.#view?.getNoteDataArray())
  }

  #getNoteSyncButton() {
    return document.getElementById(`noteSyncButton-${this.#config?.entityId.slice(0, 7)}`) ?? null
  }

  #getEditorContainerElement() {
    return document.getElementById(`editor-${this.#config?.entityId.slice(0, 7)}`)
  }

  getCrmFieldChooser() {
    return document.getElementById(`crm-field-chooser-${this.#config.entityId.slice(0, 7)}`)
  }

  getCrmCollectionChooser() {
    return document.getElementById(`crm-collection-chooser-${this.#config.entityId.slice(0, 7)}`)
  }

  getTemplateChooserDialog() {
    return this.#config?.templateChooserDialog?.current
  }

  getTemlateChooserPopup() {
    return this.#config?.templateChooserPopup?.current
  }

  getCrmLinkPopupElement() {
    return document.getElementById(`crm-link-popup-${this.#config.entityId.slice(0, 7)}`)
  }

  getApplicableTemplates() {
    return this.#config?.model.getApplicableTemplates()
  }

  /**
   * Get the record details of the CRM record whose fields are present in note
   * @returns {Object} crmRecordFields - CRM record details object
   */
  getCrmRecordFields() {
    return this.#config?.model.getCrmRecordDetails()
  }

  getCrmConnection() {
    return this.#config?.model.getCrmConnection()
  }

  #getEditorCanvasElement() {
    return document.getElementById(`canvas-${this.#config?.entityId.slice(0, 7)}`)
  }

  #getNoteTitleElement() {
    return document.getElementById(`title-${this.#config?.entityId.slice(0, 7)}`)
  }

  /**
   * Handler to bring focus to the first note element inside editor, when a
   * tab or enter key is pressed in the note title.
   * @return {Object} - { setup: function, teardown: function }
   */
  #computeNoteTitleActions() {
    let onKeydownInTitle = (event) => {
      if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'ArrowDown') {
        event.preventDefault()
        this.#view?.setCaretAt(this.#view?.getNoteElementsList()[0], CaretPosition.END_OF_NOTE)
      }
    }
    onKeydownInTitle = onKeydownInTitle.bind(this)

    let titleElement = this.#getNoteTitleElement()

    return {
      setup: () => titleElement?.addEventListener('keydown', onKeydownInTitle),
      teardown: () => titleElement?.removeEventListener('keydown', onKeydownInTitle),
    }
  }

  /**
   * Handler to remove linked fields from note when note is unlinked
   * @return {Object} - { setup: function, teardown: function }
   */
  #computeNoteEditorActions() {
    let onReceiveMessage = (event) => {
      this.onReceiveMessage(event)
    }
    onReceiveMessage = onReceiveMessage.bind(this)

    let editorContainerElement = this.#getEditorContainerElement()

    return {
      setup: () => {
        editorContainerElement?.addEventListener(EditorMessageEvent, onReceiveMessage)
        editorContainerElement?.setAttribute('data-message-listener-setup', 'true')
      },
      teardown: () => {
        editorContainerElement?.removeEventListener(EditorMessageEvent, onReceiveMessage)
        editorContainerElement?.setAttribute('data-message-listener-setup', 'false')
      },
    }
  }

  /**
   * If Ctrl-S or Cmd-S is pressed, override with note save instead of file
   * save dialog of browser
   * @return {Object} - { setup: function, teardown: function }
   */
  #computeDocumentSaveActions() {
    let onDocumentSave = (event) => {
      const template_id = LuruReduxStore.getState().app.home.notes.noteDetails?.[this.#config.noteId]?.template_id

      if (!event.defaultPrevented && event.key === 's' && (getPlatform() === 'Mac' ? event.metaKey : event.ctrlKey)) {
        let noteDataString = this.getLatestNoteContent()

        let onSuccess = () => {
          this.#noteBody = noteDataString
          this.setNoteDirtyFlag(false)
        }
        this.#config.model.saveEntity({
          type: 'full',
          data: noteDataString,
          onSuccess,
          source: 'fullsave',
          sync: true,
          template_id,
        })
        event.preventDefault()
      }
    }

    onDocumentSave = onDocumentSave.bind(this)

    return {
      setup: () => document.addEventListener('keydown', onDocumentSave),
      teardown: () => document.removeEventListener('keydown', onDocumentSave),
    }
  }

  #computeWindowUnloadActions() {
    let trapUnload = (e) => {
      // Check if note is to be saved
      const latestBody = this.getLatestNoteContent()
      const template_id = LuruReduxStore.getState().app.home.notes.noteDetails?.[this.#config.noteId]?.template_id
      if (this.#config.model.isNoteDirty(latestBody)) {
        let onSuccess = () => (this.#noteBody = latestBody)
        onSuccess = onSuccess.bind(this)

        this.#config.model.saveEntity({
          data: latestBody,
          onSuccess,
          type: 'full',
          source: 'beforeunload',
          sync: true,
          template_id,
        })

        e.preventDefault()
        e.returnValue = 'Changes that you made may not be saved'
      } else {
        delete e['returnValue']
      }
    }

    trapUnload = trapUnload.bind(this)

    return {
      setup: () => window.addEventListener('beforeunload', trapUnload),
      teardown: () => window.removeEventListener('beforeunload', trapUnload),
    }
  }

  /**
   * Calculate and return view setup and teardown actions
   * @return {Object} - { setup: function, teardown: function }
   */
  #computeViewActions() {
    return {
      setup: () => {
        this.#view = new EditorDOM({
          editorContainer: this.#getEditorContainerElement(),
          canvas: this.#getEditorCanvasElement(),
          entityId: this.#config?.entityId,
          noteBody: this.#noteBody,
          savedTasks: this.#config.model.getSavedTaskDetails(),
          controller: this,
          templateChooserDialog: this.#config?.templateChooserDialog,
          templateChooserPanel: this.#config?.templateChooserPanel,
          floatingFormattingMenu: this.#config?.floatingFormattingMenu,
          templateChooserPopup: this.#config?.templateChooserPopup,
          templates: this.#config?.model?.getApplicableTemplates?.(),
          model: this.#config.model,
        })
        this.#view.setup()
      },
      teardown: () => {
        this.#view?.teardown()
        this.#view = null
      },
    }
  }

  /**
   * Calculate and return editor menu setup and teardown actions
   * @returns {Object} - { setup: function, teardown: function }
   */
  #computeEditorMenuActions() {
    return {
      setup: () => {
        this.#menus = {
          shortcutMenu: new ShortcutMenu({
            editorContainer: this.#getEditorContainerElement(),
            controller: this,
            crmId: this.#config.model.getCrmId(),
            showToast: this.#config?.showToast,
          }),
          // hashtagMenu: new HashtagMenu({
          //   editorContainer: this.#getEditorContainerElement(),
          //   controller: this,
          //   crmId: this.#config.model.getCrmId(),
          // }),
        }
        this.#menus?.shortcutMenu.initialize()
        // this.#menus?.hashtagMenu.initialize()
      },
      teardown: () => {
        this.#menus?.shortcutMenu.teardown()
        // this.#menus?.hashtagMenu.teardown()
        this.#menus = null
      },
    }
  }

  /**
   * Calculate and return event setup and teardown actions
   * @return {Object} - { setup: function, teardown: function }
   */
  #computeEventManagementActions() {
    let handleEvent = (eventStream) => this.#onReceiveUIEvent(eventStream)
    handleEvent = handleEvent.bind(this)
    return {
      setup: () => {
        this.#eventManager = new EditorEventsManager({
          view: this.#view,
          eventHandler: handleEvent,
          linkDetailsPopup: this.#config?.linkDetailsPopup?.current,
        })
        this.#eventManager?.setup()
      },
      teardown: () => {
        this.#eventManager?.teardown()
        this.#eventManager = null
      },
    }
  }

  /**
   * Get event manager
   * @return {EditorEventsManager} - Instance of events manager
   */
  getEventManager() {
    return this.#eventManager
  }

  /**
   * Get model
   * @return {EditorModel} - Model held by this instance
   */
  getModel() {
    return this.#config?.model
  }

  /**
   * Get the handle to floating formatting menu
   * @returns {FloatingFormattingMenu} - Instance of component
   */
  getFloatingFormattingMenu() {
    return this.#config?.floatingFormattingMenu?.current
  }

  /**
   * Check whether editor should be readonly
   */
  isEditorReadOnly() {
    return Boolean(this.#readonly)
  }
}

// Core editor actions (this will eventually move to a new controller class)
export const EditorMenuAction = {
  HIDE_MENU: 'hideMenu',
  INSERT_HASHTAG: 'insertHashtag',
  INSERT_NEW_HASHTAG: 'insertNewHashtag',
  INSERT_TEXT: 'insertText',
  INSERT_CONTACT: 'insertContact',
  COMMAND_TASK: 'selectCommandTask',
  COMMAND_QUESTION: 'selectCommandQuestion',
  INSERT_CRM_FIELD: 'insertCrmField',
  // INSERT_CRM_FIELD_NEW: 'insertCrmFieldNew',
  INSERT_CRM_COLLECTION: 'insertCrmCollection',
  INSERT_CRM_TEMPLATE: 'insertCrmTemplate',
  INSERT_CRM_CONTACT: 'insertCrmContact',
  FORMAT: {
    H1: 'formatHeading1',
    H2: 'formatHeading2',
    H3: 'formatHeading3',
    P: 'formatParagraph',
    UL: 'formatUnorderedList',
    OL: 'formatOrderedList',
  },
  SELECT_NEXT: 'selectNext',
  SELECT_PREVIOUS: 'selectPrevious',
}

Object.freeze(EditorMenuAction)
