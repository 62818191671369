import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DeleteActivityLogParameter } from '../middleware/deleteActivityLog'
import { CrmActivityLogType } from '../types'

export const deleteActivityLog = (
  { type, activityId }: DeleteActivityLogParameter,
  options?: LuruAPIRequestOptions
) => {
  var url =
    type === CrmActivityLogType.Call ? `/api/activities/calls/${activityId}` : `/api/activities/meetings/${activityId}`
  var request = new LuruAPIRequest(url, HTTPMethod.DELETE, options)

  return request.make()
}
