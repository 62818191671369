import { LuruReduxStore } from '../../../app/store'
import { EntityStatus, LuruEntryPoint } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import { NotesMiddleware } from '../../../features/notes/middleware'
import { getNewNoteContent } from '../../../features/notes/helpers/getNewNoteContent'
import { AppComponents } from '../../../primitives/AppComponents/typings'
import NotesExplorerBarComponent from './NotesExplorerBarComponent'
import { EMBEDDED_NOTE_HOME } from '../../../entryPoints/EmbeddedNoteApp/EmbeddedNoteAppComponent'
import { trackEvent } from '../../../analytics/Ga'
import { AppSliceActions } from '../../../features/app/appSlice'

interface NotesExplorerBarEvents {
  // Add required event handler methods here
  onTriggerSearch: (args: { data: { searchText: string } }) => void
  onClearSearch: () => void
  onClickNewNote: () => void
  onClickShareNote: (noteId: string | undefined) => void
  onClickDeleteNote: (noteId: string | undefined, syncState: string | undefined) => void
  onCollapseExpandSorNotes: () => void
  onClickLoadMoreNotes: (section: 'luru' | 'sor') => void
  onClickRefreshLuruNotes: () => void
  onClickRefreshSorNotes: () => void
}

export default class NotesExplorerBarEventHandler {
  handlers: NotesExplorerBarEvents
  #component: NotesExplorerBarComponent

  constructor(component: NotesExplorerBarComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentWillUnmount = this.#onComponentUnmount.bind(this)
    this.#component.componentDidUpdate = this.#onComponentUpdate.bind(this)

    this.handlers = {
      // Add event handler methods to this class and assemble them into this
      // object and bind them to this object as well
      onTriggerSearch: this.#onTriggerSearch.bind(this),
      onClearSearch: this.#onClearSearch.bind(this),
      onClickNewNote: this.#onClickNewNote.bind(this),
      onClickShareNote: this.#onClickShareNote.bind(this),
      onClickDeleteNote: this.#onClickDeleteNote.bind(this),
      onCollapseExpandSorNotes: this.#onCollapseExpandSorNotes.bind(this),
      onClickLoadMoreNotes: this.#onClickLoadMoreNotes.bind(this),
      onClickRefreshLuruNotes: this.#onClickRefreshLuruNotes.bind(this),
      onClickRefreshSorNotes: this.#onClickRefreshSorNotes.bind(this),
    }
  }

  #onComponentMount() {
    var defaultLuruNoteStatus = this.#component.props.notes.luru.default.status

    if (
      defaultLuruNoteStatus.toLowerCase() === EntityStatus.NotLoaded.toLowerCase() ||
      defaultLuruNoteStatus === EntityStatus.Idle
    ) {
      LuruReduxStore.dispatch(
        // @ts-ignore
        NotesMiddleware.searchLuruNotes.action({
          key: 'home/LuruNotes',
          limit: 10,
        })
      )
    }

    if (!this.#component.state.navigateToDefaultNoteCompleted) {
      this.#navigateToDefaultNote()
    }
  }

  #navigateToDefaultNote() {
    var defaultLuruNotes = this.#component.props.notes.luru.default.results
    var defaultLuruNoteId = defaultLuruNotes?.[0]?.note_id

    if (
      (window.location.pathname === '/notes' || window.location.pathname === '/notes/') &&
      this.#component.state.searchQuery === undefined &&
      defaultLuruNoteId
    ) {
      setTimeout(() => this.#component.props.router.navigate(`/notes/${defaultLuruNoteId}`))
    }
  }

  #onComponentUnmount() {
    // Add required onUnmount behavior here
    var searchBox = this.#component.componentRefs.container.current?.querySelector(
      '[data-luru-element-type="type-ahead-search-box"]'
    ) as HTMLInputElement | undefined
    var searchText = searchBox?.value ?? null

    if (searchText) {
      LuruReduxStore.dispatch(AppSliceActions.setHomeSearchQuery(searchText))
    }
  }

  #onComponentUpdate() {
    // console.log('NoteExplorer:componentUpdate:state:', this.#component.state)
    // console.log('NoteExplorer:componentUpdate:props:', this.#component.props)
    var defaultLuruNoteStatus = this.#component.props.notes.luru.default.status

    if (
      defaultLuruNoteStatus.toLowerCase() === EntityStatus.NotLoaded.toLowerCase() ||
      defaultLuruNoteStatus === EntityStatus.Idle
    ) {
      LuruReduxStore.dispatch(
        // @ts-ignore
        NotesMiddleware.searchLuruNotes.action({
          key: 'home/LuruNotes',
          limit: 10,
        })
      )
    }

    if (!this.#component.state.navigateToDefaultNoteCompleted) {
      this.#navigateToDefaultNote()
    }
  }

  #onTriggerSearch(args: { data: { searchText: string } }) {
    trackEvent('search_notes')

    this.#component.setState({
      displayedItemType: 'search',
      sorNotesDisplay: 'collapsed',
      searchQuery: args.data.searchText,
    })

    LuruReduxStore.dispatch(
      // @ts-ignore
      NotesMiddleware.searchLuruNotes.action({
        key: 'home/LuruNotes',
        q: args.data.searchText,
        limit: 10,
      })
    )
  }

  #onClearSearch() {
    trackEvent('clear_search_notes')

    this.#component.setState({
      displayedItemType: 'default',
      searchQuery: undefined,
    })

    LuruReduxStore.dispatch(AppSliceActions.clearHomeSearchQuery())
  }

  async #onClickNewNote() {
    trackEvent('create_note', 'notesExplorerBar')
    // Clear search state from component
    this.#component.setState({
      displayedItemType: 'default',
      searchQuery: undefined,
    })
    // Clear search query from Redux
    LuruReduxStore.dispatch(AppSliceActions.clearHomeSearchQuery())

    // Dispatch request to Redux store to create new note
    var newNoteContent = getNewNoteContent()
    var createNoteResponse = await LuruReduxStore.dispatch(
      NotesMiddleware.createNote.action({
        title: newNoteContent.title,
        body: JSON.stringify(newNoteContent.body),
        draftNote: true,
      })
    )

    if (NotesMiddleware.createNote.action.fulfilled.match(createNoteResponse)) {
      var newNoteId = createNoteResponse.payload.note_id

      // Go to new note
      if (newNoteId) {
        switch (LuruUser.getCurrentEntryPoint()) {
          case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
            this.#component.props.router.navigate(`${EMBEDDED_NOTE_HOME}?target=specificNote&noteId=${newNoteId}`)
            break

          case LuruEntryPoint.EMBEDDED_ZOOM_MEETING:
            this.#component.props.router.navigate(`/embedded_note/${newNoteId}`)
            break

          default:
            this.#component.props.router.navigate(`/notes/${newNoteId}`)
        }
      }
    }
  }

  #onClickShareNote(noteId: string | undefined) {
    if (!noteId) {
      return
    }

    var appComponents = this.#component.context as AppComponents
    appComponents.noteShareDialog?.current?.showModal(noteId)
  }

  #onClickDeleteNote(noteId: string | undefined, syncState: string | undefined) {
    if (!noteId || !syncState) {
      return
    }

    trackEvent('delete_note', syncState)

    var editorNoteId = this.#component.props.router.params['noteId']

    this.#component.props.noteDeleteDialogRef.current?.showForNote({
      crmId: LuruUser.getCurrentUserCrmName() ?? '',
      noteId,
      syncState,
      dispatch: LuruReduxStore.dispatch,
      navigateCallback: () => {
        if (editorNoteId === noteId) {
          this.#component.props.router.navigate('/notes')
        }
      },
    })
  }

  #onCollapseExpandSorNotes() {
    var isCollapsed = this.#component.state.sorNotesDisplay === 'collapsed'
    var displayedItemType = this.#component.state.displayedItemType

    this.#component.setState({
      sorNotesDisplay: isCollapsed ? 'expanded' : 'collapsed',
    })

    if (!isCollapsed) {
      return
    }

    if (
      displayedItemType === 'search' &&
      this.#component.state.searchQuery &&
      this.#component.props.notes.sor.search.status !== EntityStatus.Loaded &&
      this.#component.props.notes.sor.search.status !== EntityStatus.Loading
    ) {
      LuruReduxStore.dispatch(
        // @ts-ignore
        NotesMiddleware.searchSORNotes.action({
          key: 'home/SORNotes',
          q: this.#component.state.searchQuery,
          limit: 10,
        })
      )
    }

    if (
      displayedItemType === 'default' &&
      this.#component.props.notes.sor.default.status !== EntityStatus.Loaded &&
      this.#component.props.notes.sor.default.status !== EntityStatus.Loading
    ) {
      LuruReduxStore.dispatch(
        // @ts-ignore
        NotesMiddleware.searchSORNotes.action({
          key: 'home/SORNotes',
          limit: 10,
        })
      )
    }
  }

  #onClickLoadMoreNotes(section: 'sor' | 'luru') {
    var displayedData = this.#component.props.notes[section][this.#component.state.displayedItemType]
    var cursor = displayedData.cursor
    var lastIndex = (displayedData.results?.length ?? -1) - 1

    // If more results are available in store, increment the endIndex state
    // of component; do not search for more in backend
    if (this.#component.state.endIndex[section] + this.#component.pageSize <= lastIndex || cursor === null) {
      this.#component.setState({
        endIndex: {
          ...this.#component.state.endIndex,
          [section]: Math.min(this.#component.state.endIndex[section] + this.#component.pageSize, lastIndex),
        },
      })

      return
    }

    // If we are already past the available results, (cursor is non-null here)
    var searchQuery = this.#component.state.searchQuery
    var key = section === 'luru' ? 'home/LuruNotes' : 'home/SORNotes'
    var limit = this.#component.pageSize
    var actionKey = section === 'luru' ? 'searchLuruNotes' : 'searchSORNotes'

    this.#component.setState({
      endIndex: {
        ...this.#component.state.endIndex,
        [section]: this.#component.state.endIndex[section] + this.#component.pageSize,
      },
    })
    LuruReduxStore.dispatch(
      // @ts-ignore
      NotesMiddleware[actionKey].action({
        key,
        q: searchQuery,
        cursor,
        limit,
        loadMore: true,
      })
    )
  }

  #onClickRefreshLuruNotes() {
    trackEvent('refresh_luru_notes', 'notesExplorer')
    LuruReduxStore.dispatch(
      NotesMiddleware.searchLuruNotes.action({
        key: 'home/LuruNotes',
        limit: this.#component.pageSize,
        loadMore: false,
        refreshRequest: false,
      })
    )
  }

  #onClickRefreshSorNotes() {
    trackEvent('refresh_sor_notes', 'notesExplorer')
    LuruReduxStore.dispatch(
      NotesMiddleware.searchSORNotes.action({
        key: 'home/SORNotes',
        limit: this.#component.pageSize,
        loadMore: false,
        refreshRequest: false,
      })
    )
  }
}
