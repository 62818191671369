import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { notesSliceDeleteNote } from './notesSliceDeleteNote'
import { UserSliceActions } from '../../user/userSlice'
import { ReduxAppState } from '../types'
import { meetingsSliceListMeetings } from './meetingsSliceListMeetings'
import { meetingsSliceSearchMeetings } from './meetingsSliceSearchMeetings'
import { notesSliceCreateNote } from './notesSliceCreateNote'
import { notesSliceSearchLuruNotes } from './notesSliceSearchLuruNotes'
import { notesSliceSearchSORNotes } from './notesSliceSearchSORNotes'
import { notesSliceInvalidateCache } from './notesSliceInvalidateCache'
import { notesSliceRemoveNote } from './notesSliceRemoveNote'
import { tasksSliceListOrSearchTasks } from './tasksSliceListOrSearchTasks'
import { userSliceSignInUser } from './userSliceSignInUser'
import { userSliceSignOutUser } from './userSliceSignOutUser'
import { viewsSliceUseView } from './viewsSliceUseView'
import { viewsSliceInvalidateCache } from './viewsSliceInvalidateCache'
import { meetingsSliceInvalidateCache } from './meetingsSliceInvalidateCache'
import { tasksSliceInvalidateCache } from './tasksSliceInvalidateCache'

export const AppSliceExtraReducers = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    // User slice
    builder.addCase(UserSliceActions.signInUser, userSliceSignInUser)
    builder.addCase(UserSliceActions.signOutUser, userSliceSignOutUser)

    // Notes slice
    notesSliceCreateNote.addAllCases(builder)
    notesSliceDeleteNote.addAllCases(builder)
    notesSliceRemoveNote.addAllCases(builder)
    notesSliceSearchSORNotes.addAllCases(builder)
    notesSliceSearchLuruNotes.addAllCases(builder)
    notesSliceInvalidateCache.addAllCases(builder)

    // Meetings slice
    meetingsSliceListMeetings.addAllCases(builder)
    meetingsSliceSearchMeetings.addAllCases(builder)
    meetingsSliceInvalidateCache.addAllCases(builder)

    // Tasks slice
    tasksSliceListOrSearchTasks.addAllCases(builder)
    tasksSliceInvalidateCache.addAllCases(builder)

    // Views slice
    viewsSliceUseView.addAllCases(builder)
    viewsSliceInvalidateCache.addAllCases(builder)
  },
}
