import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { CreateV2WorkflowParameter } from '../types'

export interface UpdateV2WorkflowParameter extends CreateV2WorkflowParameter {
  workflow_id: string
}

export const updateV2Workflow = async (params: UpdateV2WorkflowParameter, options: LuruAPIRequestOptions) => {
  var request = new LuruAPIRequest(`/api/workflows/${params.workflow_id}`, HTTPMethod.PUT, options)

  var requestBody: Partial<UpdateV2WorkflowParameter> = { ...params }

  // delete requestBody.workflow_id

  request.setBody(requestBody)

  return request.make()
}
