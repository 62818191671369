import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'

export const connectGmailSync = async (
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/gmail/connect`,
    HTTPMethod.GET,
    options
  )

  return request.make()
}
