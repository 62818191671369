import React from 'react'
import { EntityStatus } from '@/app/types'
import { TabSections } from '@/primitives/domain/crm/Omnibox/Omnibox'
import EditableCell from '@/primitives/EditableCell'
import RecordViewEventHandler from './RecordViewEventHandler'
import NewNotesTabPanel from '@/primitives/domain/crm/Omnibox/components/NewNotesTabPanel'
import TasksTabPanel from '@/primitives/domain/crm/Omnibox/components/TasksTabPanel'
import { CrmFieldSchema, CrmRecordType } from '@/features/crm/types'
import { StringUtils } from '@/utils/stringUtils'
import LuruCrmFieldInputLabel from '@/primitives/domain/crm/LuruCrmFieldInput/LuruCrmFieldInputLabel'
import CrmRecord from '@/domain/crmRecord/CrmRecord'
import Tabs from '@/primitives/Tabs'
import styles from './styles.module.css'
import EditableCellComp from '@/primitives/EditableCell'

export interface RecordViewProps {
  crmRecordType: CrmRecordType
  sorRecordId?: string
  sorRecordName?: string
  fieldValues?: Record<string, any>
  visibleFields?: Array<string>
  onFieldValueChange: (value: any, source: EditableCell) => void
  collectionView?: JSX.Element | null
  onChooseCommand?: (
    action: 'CREATE' | 'EDIT',
    entityType: 'NOTE' | 'TASK' | CrmRecordType,
    entityId: string | undefined,
    entityName: string | undefined
  ) => void
  action: 'CREATE' | 'EDIT'
  loading?: boolean
  alertMessage?: string
  errorFields?: Array<string>
}

interface RecordViewState {
  schemaFields?: Array<CrmFieldSchema>
  schemaStatus: EntityStatus
  visibleFields?: Array<string>
  fieldValuesMap: Record<string, any>
  currentTabName: string
  fieldStatus: EntityStatus
}

export default class RecordView extends React.Component<RecordViewProps, RecordViewState> {
  props: RecordViewProps
  state: RecordViewState
  eventHandler: RecordViewEventHandler

  constructor(props: RecordViewProps) {
    super(props)
    this.props = props
    this.state = {
      schemaFields: undefined,
      schemaStatus: EntityStatus.Loading,
      fieldValuesMap: this.props.fieldValues ?? {},
      visibleFields: this.props.visibleFields,
      currentTabName: TabSections.DETAILS,
      fieldStatus: EntityStatus.Idle,
    }
    this.eventHandler = new RecordViewEventHandler(this)
  }

  getSchema = () => this.state.schemaFields

  getFieldValues = () => this.state.fieldValuesMap

  setFields = (fields: Array<string>) => this.setState({ visibleFields: fields })

  render = () => {
    switch (this.state.schemaStatus) {
      case EntityStatus.Loading:
        return this.#renderLoadingState()

      case EntityStatus.Loaded:
        return this.#renderLoadedState()

      default:
        return this.#renderErrorState()
    }
  }

  #renderLoadingState = () => <>Loading...</>

  #renderErrorState = () => <>Error loading schema for {StringUtils.toTitleCase(this.props.crmRecordType)}</>

  #renderLoadedState = () => {
    var fields =
      this.state.visibleFields?.map((f) => this.state.schemaFields?.find((field) => f === field.name)) ??
      this.state.schemaFields

    const createDetailsTab = (
      <>
        {this.props.alertMessage ? <div className={styles.errorMessage}>{this.props.alertMessage}</div> : null}
        <div className={styles.collectionViewContainer}>{this.props.collectionView}</div>
        {this.props.loading ? (
          this.#renderLoadingState()
        ) : (
          <ul className={[styles.recordView, styles.recordViewContainer].join(' ')}>
            {fields?.map((f, ix) => (
              <li key={`${f?.name}_${ix}`}>
                <LuruCrmFieldInputLabel
                  objectName={CrmRecord.getCrmRecordName(this.props.crmRecordType)}
                  fieldName={f?.name || ''}
                  showNonEditableLabel={true}
                />
                {f ? (
                  <div className={styles.fieldInput}>
                    <EditableCellWrapper
                      key={f?.controllerName ? this.state.fieldValuesMap?.[f?.controllerName]?.value : undefined}
                      fieldSchema={f}
                      value={this.state.fieldValuesMap[f.name]?.value}
                      onChange={this.eventHandler.handlers.onFieldChange}
                      onBlur={this.eventHandler.handlers.onFieldBlur}
                      rowIndex={ix}
                      controllerFieldValue={
                        f?.controllerName ? this.state.fieldValuesMap?.[f?.controllerName]?.value : undefined
                      }
                      isReadOnly={f.nonCreateable === true}
                      showErroneousStatus={this.props.errorFields?.includes(f?.name)}
                      status={this.state.fieldStatus}
                      onFieldValueChange={this.eventHandler.handlers.onFieldValueChange}
                    />
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        )}
        <div> </div>
      </>
    )

    const updateDetailsTab = (
      <>
        <div>{this.props.collectionView}</div>
        <ul className={[styles.recordView, styles.recordViewContainer].join(' ')}>
          {fields?.map(
            (f, ix) =>
              f?.updateable && (
                <li key={`${f?.name}_${ix}`}>
                  <LuruCrmFieldInputLabel
                    objectName={CrmRecord.getCrmRecordName(this.props.crmRecordType)}
                    fieldName={f?.name || ''}
                    showNonEditableLabel={true}
                  />
                  {f ? (
                    <div className={styles.fieldInput} data-luru-field-type={f?.luruFieldType}>
                      <EditableCellWrapper
                        fieldSchema={f}
                        value={this.state.fieldValuesMap[f.name]?.value}
                        onChange={this.eventHandler.handlers.onFieldChange}
                        onBlur={this.eventHandler.handlers.onFieldBlur}
                        rowIndex={ix}
                        controllerFieldValue={
                          f?.controllerName ? this.state.fieldValuesMap?.[f?.controllerName]?.value : undefined
                        }
                        status={this.state.fieldStatus}
                        onFieldValueChange={this.eventHandler.handlers.onFieldValueChange}
                      />
                    </div>
                  ) : null}
                </li>
              )
          )}
        </ul>
      </>
    )

    const notesTab = (
      <div style={{ height: '100%' }}>
        <NewNotesTabPanel
          key={`${this.props.crmRecordType}-${this.props.sorRecordId}`}
          crmRecordType={this.props.crmRecordType}
          sorRecordId={this.props.sorRecordId}
          sorRecordName={this.props.sorRecordName}
          explorerStyle={{ display: 'flex' }}
          containerStyle={{ height: '100%' }}
          disableLink={true}
          onClickNote={this.eventHandler.handlers.onClickNote}
          onClickNewNote={this.eventHandler.handlers.onClickNewNote}
        />
      </div>
    )

    const tasksTab = (
      <div style={{ height: '100%' }}>
        <TasksTabPanel
          sorRecordName={this.props.sorRecordName || ''}
          sorRecordId={this.props.sorRecordId || ''}
          crmRecordType={this.props.crmRecordType}
          preventDefaultClick={true}
          onClickTask={this.eventHandler.handlers.onClickTask}
          onClickNewTask={this.eventHandler.handlers.onClickNewTask}
        />
      </div>
    )

    const tabs =
      this.props.action === 'CREATE'
        ? [{ name: TabSections.DETAILS, content: createDetailsTab }]
        : [
            { name: TabSections.DETAILS, content: updateDetailsTab },
            { name: TabSections.NOTES, content: notesTab },
            { name: TabSections.TASKS, content: tasksTab },
          ]

    return <Tabs tabs={tabs} />
  }
}

export const EditableCellWrapper = ({
  fieldSchema,
  value,
  onChange,
  onBlur,
  rowIndex,
  disableExplicitWidth,
  updateOnChange,
  crmRecordType,
  sorRecordId,
  onUpdateError,
  isReadOnly,
  align,
  controllerFieldValue,
  showErroneousStatus,
  status,
  onFieldValueChange,
}: {
  fieldSchema: CrmFieldSchema
  value: any
  onChange?: (value: any, source: EditableCell) => void
  onBlur?: (value: any, source: EditableCell) => void
  rowIndex: number
  disableExplicitWidth?: boolean
  updateOnChange?: boolean
  crmRecordType?: CrmRecordType
  sorRecordId?: string
  onUpdateError?: (_: string | undefined) => void
  isReadOnly?: boolean
  align?: 'left' | 'right'
  controllerFieldValue?: string
  showErroneousStatus?: boolean
  status: EntityStatus
  onFieldValueChange: (fieldName: string, value: string) => void
}) => (
  <EditableCellComp
    columnCount={0}
    columnIndex={-1}
    crmRecordType={crmRecordType}
    disableExplicitWidth={disableExplicitWidth}
    fieldSchema={fieldSchema}
    isReadOnly={isReadOnly}
    luruFieldType={fieldSchema.luruFieldType}
    picklistValues={fieldSchema.picklistValues}
    rowCount={0}
    rowIndex={rowIndex}
    sorRecordId={sorRecordId}
    updateOnChange={updateOnChange}
    value={value ?? ''}
    width={280}
    onBlur={onBlur}
    onChange={onChange}
    onNavigateDown={() => {}}
    onNavigateLeft={() => {}}
    onNavigateRight={() => {}}
    onNavigateUp={() => {}}
    onUpdateError={onUpdateError}
    align={align}
    controllerFieldValue={controllerFieldValue}
    showErroneousStatus={showErroneousStatus}
    status={status}
    onFieldValueChange={onFieldValueChange}
  />
)
