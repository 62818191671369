// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { NoteSorConnection, ReduxNotesState } from '../types'

export type GetSorConnectionParameter = {
  noteId: string
  sorId: string
}

export const getSorConnection = {
  action: createAsyncThunk<
    | { status: EntityStatus; connection: NoteSorConnection | undefined }
    | undefined,
    GetSorConnectionParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null
      rejectedMeta: null
    }
  >(
    'notes/getSorConnection',
    async (
      { noteId, sorId },
      { getState, fulfillWithValue, rejectWithValue }
    ) => {
      if (!noteId) {
        let message = 'getSorConnection called without required params'
        return rejectWithValue({ message }, null)
      }

      const record = getState().notes?.entities
        ? getState().notes?.entities[noteId]
        : null
      // If there is no note record or data in it, return null
      if (!record || typeof record !== 'object' || !record.data || !sorId) {
        return fulfillWithValue(undefined, null)
      }

      return fulfillWithValue(
        {
          status: EntityStatus.Idle,
          connection: Array.isArray(record?.data?.connections)
            ? record.data.connections.find((item) => item.sor === sorId)
            : undefined,
        },
        null
      )
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(getSorConnection.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(getSorConnection.action.fulfilled, (state, action) => {})
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(getSorConnection.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    getSorConnection.addPendingCase(builder)
    getSorConnection.addFulfilledCase(builder)
    getSorConnection.addRejectedCase(builder)
  },
}
