import Tooltip from '@/components/Tooltip'
import styles from './css/UserLogo.module.css'
import { useAppSelector } from '@/app/hooks'
import { useState } from 'react'

interface UserLogoProps {
  firstname: string
  lastname: string
  email?: string
  title?: string
  position?: Array<string>
  className?: string
}

export default function UserLogo(props: UserLogoProps) {
  var name = ''
  var userInitials = ''
  var photoUrl = useAppSelector((state) => state.user.data.photoUrl)
  const [imageError, setImageError] = useState(false)

  if (props.firstname && props.lastname) {
    userInitials = props.firstname.substring(0, 1).toUpperCase() + props.lastname.substring(0, 1).toUpperCase()
    name = props.firstname + ' ' + props.lastname
  } else if (props.firstname) {
    // Only first name is given
    userInitials = props.firstname.substring(0, 1)
    name = props.firstname
  } else if (props.lastname) {
    // Only last name is given
    userInitials = props.lastname.substring(0, 1)
    name = props.lastname
  } else {
    // Neither first nor last name is given
    userInitials = '?'
    name = 'John Doe'
  }

  if (name && props.email) {
    name = `${name} (${props.email})`
  }

  return (
    <Tooltip label={name} placement='right'>
      <div className={styles.userLogoContainer}>
        {photoUrl && !imageError ? (
          <img
            src={photoUrl}
            alt={name}
            className={styles.userLogoImage}
            onError={(e) => {
              setImageError(true)
            }}
          />
        ) : (
          <span className={[styles.userLogo, props.className || ''].join(' ')}>{userInitials}</span>
        )}
      </div>
    </Tooltip>
  )
}
