import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { TestWorkflowAPIRequestBody } from '../types'

export const testWorkflow = async (
  params: TestWorkflowAPIRequestBody,
  options?: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    '/api/workflows/temporary/test',
    HTTPMethod.POST,
    options
  )

  request.setBody(params)

  return request.make()
}
