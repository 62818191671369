import React from 'react'
import { EntityStatus } from '../../../../app/types'
import { CrmObjectSchema } from '../../../../domain/crmRecord/typings.d'
import LoadingSpinner from '../../../LoadingSpinner'
import LuruCrmFieldInput from '../LuruCrmFieldInput'
import LuruCrmFieldInputSetEventHandler from './LuruCrmFieldInputSetEventHandler'

import styles from './styles.module.css'
import { CrmRecordType } from '../../../../features/crm/types'

export interface LuruCrmFieldInputSetProps {
  // Object name
  crmRecordType: CrmRecordType
  // List of fields to display
  fieldNames: Array<string>
  // Hide read-only fields?
  hideReadonlyFields?: boolean
  // Hide non-createable fields?
  hideNonCreateableFields?: boolean

  // Disable non-createable fields?
  disableNonCreateableFields?: boolean
  // Optional record id; record values will be loaded if record id is given
  sorRecordId?: string
  // Stacking anchor selector
  menuParentSelector?: string
  // Alert field names
  alertFieldNames?: Array<string>
  // Size
  size?: 'narrow' | 'normal' | 'large' | 'wide'
  // Disabled?
  disabled?: boolean
  // Show edited status on field value change?
  editedFields?: Array<string>
  // Show erroneous fields?
  showErroneousFields?: boolean
  //// Callbacks
  // Callback for field value change
  onChange?: (fieldName: string, value: any) => void

  onStatusChange?: (status: EntityStatus) => void

  //Custom error message to show
  errorMessageWhenErrorLoading?: string
  // default values
  defaultValues?: { [fieldName: string]: any }
}

interface LuruCrmFieldInputSetState {
  status: EntityStatus
  schema?: CrmObjectSchema
  errorMessage?: string
  fieldValues: { [fieldName: string]: any }
}

export default class LuruCrmFieldInputSet extends React.Component<
  LuruCrmFieldInputSetProps,
  LuruCrmFieldInputSetState
> {
  props: LuruCrmFieldInputSetProps
  state: LuruCrmFieldInputSetState
  eventHandler: LuruCrmFieldInputSetEventHandler

  constructor(props: LuruCrmFieldInputSetProps) {
    super(props)
    this.props = props
    this.state = {
      status: EntityStatus.Idle,
      fieldValues: {},
    }
    this.eventHandler = new LuruCrmFieldInputSetEventHandler(this)
  }

  render() {
    switch (this.state.status) {
      case EntityStatus.Idle:
      case EntityStatus.Loading:
        return (
          <>
            <span>Loading...</span>
            <LoadingSpinner size={8} color={'var(--brand-accent-color-blue)'} />
          </>
        )

      case EntityStatus.ErrorLoading:
        return (
          <>
            <span className='error'>
              {this.props.errorMessageWhenErrorLoading || `Error loading schema: ${this.state.errorMessage}`}
            </span>
          </>
        )

      case EntityStatus.Loaded:
        return (
          <>
            {this.props.fieldNames?.map((fieldName) => {
              var fieldSchema = this.state.schema?.payload?.fields?.find((f) => f.name === fieldName)
              var isUpdateable = fieldSchema?.updateable

              // this check is for lead in PD for now.
              // In future we can use this for any CRM and any field schema
              // we just need to pass the option_if_present in field schema from BE
              if (fieldSchema?.optional_if_present) {
                if (this.state.fieldValues[fieldSchema?.optional_if_present ?? '']) {
                  fieldSchema = { ...fieldSchema, isMandatory: false }
                }
              }

              if (
                (!isUpdateable && this.props.hideReadonlyFields) ||
                (fieldSchema?.nonCreateable && this.props.hideNonCreateableFields)
              ) {
                return null
              }

              //Do not show fields that is not a part of recordFields if there is sorRecordId present for viewing sor record
              if (this.props.sorRecordId && this.state.fieldValues[fieldName] === undefined) {
                return null
              }

              return (
                <div className={styles.parent} key={fieldName}>
                  <LuruCrmFieldInput
                    crmRecordType={this.props.crmRecordType}
                    fieldName={fieldName}
                    sorRecordId={this.props.sorRecordId}
                    displayLabel={true}
                    onChange={(value: any) => this.eventHandler.handlers.onChange(fieldName, value)}
                    menuParentSelector={this.props.menuParentSelector}
                    warningState={this.props.alertFieldNames?.includes(fieldName)}
                    size={this.props.size ?? 'normal'}
                    disabled={
                      this.props.disabled || (fieldSchema?.nonCreateable && this.props.disableNonCreateableFields)
                    }
                    showEditedStatus={this.props.editedFields?.includes(fieldName)}
                    showErroneousStatus={this.props.showErroneousFields}
                    controllerFieldValue={
                      fieldSchema?.controllerName ? this.state.fieldValues[fieldSchema?.controllerName] : undefined
                    }
                    defaultValue={this.props.defaultValues ? this.props.defaultValues[fieldName.toLowerCase()] : ''}
                    isMandatory={fieldSchema?.isMandatory}
                  />
                </div>
              )
            })}
          </>
        )
    }
  }
}
