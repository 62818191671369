import { MouseEventHandler } from 'react'
import msSignInButton from '@/images/sor/msteams/ms-sign-in-button.svg'
import './MicrosoftSignInButton.css'

const MicrosoftSignInButton = ({ handleSignIn }: { handleSignIn: MouseEventHandler<HTMLButtonElement> }) => (
  <button onClick={handleSignIn} className='ms-sign-in-button'>
    <img src={msSignInButton} alt='Sign In with Microsoft' />
  </button>
)

export default MicrosoftSignInButton
