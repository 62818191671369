import React from 'react'
import ModalScreen from '../../../../primitives/ModalScreen'
import NoteShareDialogEventHandler from './NoteShareDialogEventHandler'
import type { NoteShareDialogConnectedProps } from '.'
import { NoteSyncState, NoteSorConnection, JottingType } from '../../../../features/notes/types'
import { LuruReduxStore } from '../../../../app/store'
import { NotesMiddleware } from '../../../../features/notes/middleware'
import LuruUserSelectBox, { LuruUserSelectBoxType } from '../../../../primitives/domain/users/LuruUserSelectBox'
import { EntityStatus } from '../../../../app/types'
import styles from './styles.module.css'
import LoadingSpinner from '../../../../primitives/LoadingSpinner'
import { CrmObjectName, ReduxCrmKey } from '../../../../features/crm/types'

// icons
import warningIcon from '@/images/fluent/warning.svg'
import closeIcon from '../../../../images/fluent/close.svg'
import { isValidEmail } from '@/utils'

export interface NoteShareDialogComponentProps extends NoteShareDialogConnectedProps {}

export interface NoteShareDialogComponentRefs {
  modal: React.RefObject<ModalScreen>
  copyButton: React.RefObject<HTMLButtonElement>
  currentEmailTextBox: React.RefObject<HTMLInputElement>
}

export interface NoteShareDialogComponentState {
  currentEmailText: string
  emails: string[]
  noteId: string
  shareEmailProgressState: EntityStatus
  syncState: NoteSyncState | undefined
}

export default class NoteShareDialogComponent extends React.Component<NoteShareDialogComponentProps> {
  props: NoteShareDialogComponentProps
  state: NoteShareDialogComponentState
  eventHandler: NoteShareDialogEventHandler
  componentRefs: NoteShareDialogComponentRefs

  constructor(props: NoteShareDialogComponentProps) {
    super(props)
    this.props = props

    this.state = {
      currentEmailText: '',
      emails: [],
      noteId: '',
      shareEmailProgressState: EntityStatus.Idle,
      syncState: undefined,
    }

    this.componentRefs = {
      modal: React.createRef(),
      copyButton: React.createRef(),
      currentEmailTextBox: React.createRef(),
    }

    this.eventHandler = new NoteShareDialogEventHandler(this)
  }

  getNewFormattedJsonBody(jsonBody: any, fields: any) {
    jsonBody = jsonBody || []
    // Collection fields will not be copied to clipboard if fields are not available here.
    if (!fields) {
      return jsonBody
    }

    var jsonNewBody = jsonBody.map((el: any) => {
      if (el.type === JottingType.CRM_COLLECTION) {
        let modifiedFields = el.data.collection.fields.map((e: string) => {
          let field = fields[e]
          return { name: e, value: field?.value, luruFieldType: field?.schema?.luruFieldType }
        })
        let existedData = el.data
        let collection = el.data.collection
        return { ...el, data: { ...existedData, collection: { ...collection, fields: modifiedFields } } }
      } else {
        return el
      }
    })

    return jsonNewBody
  }

  getFieldsOfConnectedNote() {
    var crmId = this.props.state?.user.data.userSettings.connectedCRM?.name
    var crmKey = crmId?.toLowerCase() as ReduxCrmKey
    var recordId = this.props.state?.notes?.entities[this.state.noteId]?.data?.connections?.find(
      (item: NoteSorConnection) => item.sor === crmId
    )?.sor_record_id
    var sorObjectName = this.props.state?.notes?.entities[this.state.noteId]?.data?.connections?.find(
      (item: NoteSorConnection) => item.sor === crmId
    )?.sor_object_name as CrmObjectName

    var fields

    if (crmId && sorObjectName && recordId) {
      fields = this.props.state?.crm[crmKey]?.entities?.[sorObjectName]?.[recordId].data
    }

    return fields
  }

  getNoteId() {
    return this.props.state?.notes?.entities[this.state.noteId]?.data?.title
  }

  getMeetingId() {
    return this.props.state?.notes?.entities[this.state.noteId]?.data?.connections?.find((e) => e?.sor === 'GCAL')
  }

  showModal = async (noteId: string) => {
    var note = await LuruReduxStore.dispatch(NotesMiddleware.fetchNote.action({ noteId })).unwrap()

    this.setState({ noteId, syncState: note.sync_state })

    this.componentRefs.modal.current?.showModal({
      cancel: () => {
        this.eventHandler.handlers.onClose()
      },
    })
  }

  renderShareViaEmail = () => {
    // No email sharing allowed for private notes
    if (this.state.syncState === NoteSyncState.PRIVATE) {
      return <></>
    }

    const isAllEmailsValid = this.state.emails.every((f) => isValidEmail(f))
    return (
      <div>
        <label>Share this note via email with your teammates:</label>
        {!isAllEmailsValid && <label data-validation>There are some invalid email addresses, please fix</label>}
        <div className={styles.emailInput}>
          <LuruUserSelectBox
            type={LuruUserSelectBoxType.CrmUsers}
            onChooseUser={this.eventHandler.handlers.onChooseUser}
            isSelectableList={false}
            placeholder='Add users to share note - start typing'
          />
        </div>
        {this.state.emails.length > 0 ? (
          <ul className={styles.emailList}>
            {this.state.emails.map((email) => (
              <li key={email} data-invalid-email={!isValidEmail(email)}>
                {email}
                <span className={styles.actions}>
                  <button title='Invalid email address' className={styles.warningButton}>
                    <img src={warningIcon} alt='warning' />
                  </button>
                  <button
                    title='Remove'
                    className={styles.deleteButton}
                    onClick={() => this.eventHandler.handlers.onClickRemoveEmail(email)}
                  >
                    <img src={closeIcon} alt='close' />
                  </button>
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p>Email addresses added will appear here</p>
        )}
        <div className={styles.actionButtons}>
          <button
            className={[
              styles.okButton,
              this.state.emails.length === 0 || !isAllEmailsValid ? styles.disabled : null,
            ].join(' ')}
            onClick={this.eventHandler.handlers.onClickShareEmailButton}
            disabled={this.state.emails.length === 0 || !isAllEmailsValid}
            title={this.state.emails.length === 0 ? 'Please add at least one email to share with' : ''}
          >
            {this.state.shareEmailProgressState === EntityStatus.Loading ? (
              <LoadingSpinner color='white' size={8} />
            ) : null}
            {this.state.shareEmailProgressState === EntityStatus.Idle
              ? 'Share via email'
              : this.state.shareEmailProgressState === EntityStatus.ErrorLoading
              ? 'Error... Please retry'
              : this.state.shareEmailProgressState === EntityStatus.Loaded
              ? 'Success!'
              : 'Sharing...'}
          </button>
        </div>
        <label></label>
      </div>
    )
  }

  renderCopyToClipboard = () => (
    <div className={styles.shareLink}>
      <button onClick={this.eventHandler.handlers.onClickCopyNoteContent} ref={this.componentRefs.copyButton}>
        Copy note to clipboard
      </button>
    </div>
  )

  render = () => (
    <ModalScreen
      ref={this.componentRefs.modal}
      width='80%'
      height='auto'
      hideButtons={true}
      title='Share Note'
      titleCloseButton={true}
      className={styles.noteShareModal}
    >
      <div className={styles.parent}>
        {this.renderShareViaEmail()}
        {this.renderCopyToClipboard()}
      </div>
    </ModalScreen>
  )
}
