import { CrmRecordType } from '../../../features/crm/types'
import { UserSliceHelpers } from '../../../features/user/helpers'
import GlobalSearchHomeComponent, { GlobalSearchHomeState } from './GlobalSearchHomeComponent'
import { EditNoteInLuruCustomEventDetail, GlobalSearchHomeCustomEventsName } from './typings.d'

interface GlobalSearchHomeEvents {
  onChooseCommand: (
    action: 'CREATE' | 'EDIT',
    entityType: 'NOTE' | 'TASK' | CrmRecordType,
    entityId: string | undefined,
    entityName: string | undefined,
    crmConnection?: {
      sorObjectName: string
      sorRecordId: string
      sorRecordName: string
    }
  ) => void
  onCloseSearch: () => void
  onClickOutside: (event: MouseEvent) => void
}

export default class GlobalSearchHomeEventHandler {
  #component: GlobalSearchHomeComponent
  handlers: GlobalSearchHomeEvents

  constructor(component: GlobalSearchHomeComponent) {
    this.#component = component
    this.handlers = {
      onChooseCommand: this.#onChooseCommand.bind(this),
      onCloseSearch: this.#onCloseSearch.bind(this),
      onClickOutside: this.#onClickOutside.bind(this),
    }
    this.#component.componentDidMount = this.onComponentMount.bind(this)
    this.#component.componentWillUnmount = this.onComponentUnmount.bind(this)
    this.handleQueryParamsAndUpdateState = this.handleQueryParamsAndUpdateState.bind(this)
  }

  static getGlobalSearchContainer() {
    return document.querySelector('div.globalSearchMain') as HTMLDivElement | undefined
  }

  onComponentMount() {
    // Setup event listener to hide frame on clicking outside search form
    var searchContainer = document.querySelector('div.globalSearchMain') as HTMLDivElement | undefined
    searchContainer?.addEventListener('mousedown', this.handlers.onClickOutside)

    //Custom events Handlers
    searchContainer?.addEventListener?.(
      GlobalSearchHomeCustomEventsName.onClickEditNoteInLuruFromGlobalSearch,
      (e: any) => {
        const { noteId } = e.detail as EditNoteInLuruCustomEventDetail
        this.handlers.onChooseCommand('EDIT', 'NOTE', noteId, undefined)
      }
    )

    // Set default recent items (though we don't yet use recent items in
    // global search bar, we may use it in future)
    if (!Array.isArray(this.#component.props.recentItems)) {
      UserSliceHelpers.setDefaultRecentItems()
    }

    this.handleQueryParamsAndUpdateState()

    // Check for chrome side panel api avaibility
    chrome?.runtime?.sendMessage?.(
      {
        messageName: 'isOpenChromeSidePanelApiAvailable',
      },
      (response) => {
        this.#component.setState({ isOpenChromeSidePanelApiAvailable: response?.payload?.available })
      }
    )
  }

  // This function will check the state query params and will update the initial state of the component
  handleQueryParamsAndUpdateState() {
    if (this.#component?.props?.router?.searchParams?.get?.('state')) {
      const state: Partial<GlobalSearchHomeState> = JSON.parse(
        this.#component?.props?.router?.searchParams?.get('state') || '{}'
      )
      this.#component.setState(
        (prevState) => ({ ...state, mode: state.mode || prevState.mode }),
        () => {
          // Do not delete state query params, we may need it when user clicks on refresh panel
          // this.#component?.props?.router?.searchParams?.delete?.('state')
          // this.#component?.props?.router?.setSearchParams?.(this.#component?.props?.router?.searchParams)
          // location.href=
        }
      )
    }
  }

  onComponentUnmount() {
    var searchContainer = document.querySelector('div.globalSearchMain') as HTMLDivElement | undefined
    searchContainer?.removeEventListener('mousedown', this.handlers.onClickOutside)
  }

  #onClickOutside(event: MouseEvent) {
    var searchContainer = document.querySelector('div.globalSearchMain')
    if (this.#component.state.mode === 'RENDER_SEARCHBAR' && event.target === searchContainer) {
      this.#component.hideFrame()
    }
  }

  async #onChooseCommand(
    action: 'CREATE' | 'EDIT',
    entityType: 'NOTE' | 'TASK' | CrmRecordType,
    entityId: string | undefined,
    entityName: string | undefined,
    crmConnection?: {
      sorObjectName: string
      sorRecordId: string
      sorRecordName: string
    }
  ) {
    const newState: GlobalSearchHomeState = {
      mode: 'RENDER_ENTITY',
      action,
      entityType,
      entityId,
      entityName,
      crmConnection,
    }

    if (this.#component.state.isOpenChromeSidePanelApiAvailable && this.#component.state.mode === 'RENDER_SEARCHBAR') {
      // Reset the state so, next time when we open modal using CMD-J, it will be in initail state
      this.#component.hideFrame()
      this.#component.setState(newState, () => {
        // Immediately set mode to RENDER_SEARCHBAR
        this.#component.setState({
          mode: 'RENDER_SEARCHBAR',
        })
      })
      // Open Result in Chrome side panel
      this.#openSidePanelForResultView(newState)
    } else {
      // Open Result in webpage itself( old ways)
      this.#component.setState(newState)
    }
  }

  async #openSidePanelForResultView(state: GlobalSearchHomeState) {
    const url = `${chrome.runtime.getURL('pages/globalsearch/index.html')}?state=${JSON.stringify(state)}`

    chrome.runtime.sendMessage({
      messageName: 'setSidePanelOptions',
      payload: {
        path: url,
        enabled: true,
      },
    })
    chrome.runtime.sendMessage({
      messageName: 'openEmbeddedSidePanel',
    })
  }

  #onCloseSearch() {
    this.#component.setState({ mode: 'RENDER_SEARCHBAR' })
  }
}
