import { EntityStatus, LuruEntryPoint } from '../../app/types'
import { CalendarProvider, CRMProvider } from '../../features/user/types'

type NoteShareNotificationRecipient = {
  medium: 'NOTIFICATION'
  user_id: string
}

type NoteShareEmailRecipient = {
  medium: 'EMAIL'
  to: string
}

export type NoteShareRecipient = NoteShareEmailRecipient | NoteShareNotificationRecipient

export type NoteShareRecipients = {
  recipients: NoteShareRecipient[]
}

export type NoteId = string

export type NoteSorConnection = {
  connection_id?: string
  sor: CRMProvider | CalendarProvider
  sor_note_id?: NoteId
  sor_object_name: string
  sor_record_id: string
  sor_record_name: string
  sor_version?: string
  synced_version?: string
  synced_at?: string
  data?: string
}

export type NoteUser = {
  sor_object_name: 'User'
  sor_record_id: string
  sor_record_name: string
}

export interface CreateNoteParameter {
  title: string
  body: string
  sync_state?: NoteSyncState
  source?: LuruEntryPoint
  connections?: Array<NoteSorConnection> | null
}

export interface ReduxNoteEntity extends CreateNoteParameter {
  note_id: string
  prevBody?: string
  draft?: string
  created_at: string
  created_by: NoteUser | null
  updated_at: string
  updated_by: NoteUser | null
  sync_state: NoteSyncState
  template_id: string | null
  source?: LuruEntryPoint
}

export enum NoteSyncState {
  SOR = 'sor',
  SYNCED = 'synced',
  PRIVATE = 'private',
}

export const ReduxNotesStateTTL = 60 * 60 * 1_000 // millisecs/hour

export type ReduxNotesState = {
  status: EntityStatus
  refreshedAt?: string
  deletedNotes?: Array<{ note_id: string; deleted_at: string }>
  entities: Record<
    NoteId,
    | undefined
    | {
        status: EntityStatus
        saveStatus?: EntityStatus.Updating | EntityStatus.Updated | EntityStatus.ErrorUpdating
        // Partial, because a note can be 'loaded-partially'
        // In other slices, we only have either EntityStatus.Loaded or EntityStatus.NotLoaded
        data: Partial<ReduxNoteEntity>
        isDraft?: boolean
        markedEditable?: boolean
        linkingStatus?: Record<string, { status: EntityStatus; connection: NoteSorConnection }>
      }
  >
}

// Note structure
export enum JottingType {
  // Paragraph
  P = 'paragraph',
  // Unordered list
  UL1 = 'unorderedListLevel1',
  UL2 = 'unorderedListLevel2',
  UL3 = 'unorderedListLevel3',
  // Ordered list
  OL1 = 'orderedListLevel1',
  OL2 = 'orderedListLevel2',
  OL3 = 'orderedListLevel3',
  // Heading:
  H1 = 'heading1',
  H2 = 'heading2',
  H3 = 'heading3',
  // Task
  TASK_INCOMPLETE = 'taskIncomplete',
  TASK_COMPLETE = 'taskComplete',
  // Salesforce field
  CRM_FIELD_LABEL = 'crmFieldLabel',
  CRM_FIELD_VALUE = 'crmFieldValue',
  // CRM_FIELD_VALUE_NEW = 'crmFieldValueNew',
  CRM_COLLECTION = 'crmFieldCollection',
  // Question-Answer
  Q = 'question',
  // Answer - Paragraph
  A_P = 'answerParagraph',
  // Answer - Unordered list
  A_UL1 = 'answerUnorderedListLevel1',
  A_UL2 = 'answerUnorderedListLevel2',
  A_UL3 = 'answerUnorderedListLevel3',
  // Answer - Ordered list
  A_OL1 = 'answerOrderedListLevel1',
  A_OL2 = 'answerOrderedListLevel2',
  A_OL3 = 'answerOrderedListLevel3',
  // Answer - Heading:
  A_H1 = 'answerHeading1',
  A_H2 = 'answerHeading2',
  A_H3 = 'answerHeading3',
  // Answer - Task
  A_TASK_INCOMPLETE = 'answerTaskIncomplete',
  A_TASK_COMPLETE = 'answerTaskComplete',
  // Answer - Salesforce field
  A_CRM_FIELD_LABEL = 'answerCrmFieldLabel',
  A_CRM_FIELD_VALUE = 'answerCrmFieldValue',
}

// export type NoteJottingCrmData = {
//   record: {
//     sorObjectName: string
//   }
//   field: {
//     label: string
//     value: string
//   }
// }

export type NoteJottingCrmData = {
  fieldName: string
}

export type NoteJotting = {
  type: JottingType
  data: string | NoteJottingCrmData
  // TODO: Add CRM field jotting type fields
}

export type NoteBody = Array<NoteJotting>

export const EmptyNotesState: ReduxNotesState = {
  status: EntityStatus.Idle,
  entities: {},
}
