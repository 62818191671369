import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxViewEntity } from '../../views/types'
import { RecentEntityType, ReduxUserState } from '../types'

export const viewsSliceUpdateView: CaseReducer<
  ReduxUserState,
  PayloadAction<ReduxViewEntity>
> = (state, action) => {
  var { view_id, name } = action.payload
  var recentItem = state.recentItems?.find(
    (item) => item.id === view_id && item.type === RecentEntityType.VIEW
  )

  if (recentItem) {
    recentItem.name = name
  }
}
