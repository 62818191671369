import { configureStore } from '@reduxjs/toolkit'
import { luruRootReducer } from './luruRootReducer'
import { extensionMiddleware } from '../store/extensionMiddleware'
import { getEmptyReduxState } from '../store/getEmptyReduxStore'

// TODO: Measure the performance impact of initializing a store with an empty
// state; this is required to type the store correctly everywhere else
export var LuruReduxStore = configureStore({
  reducer: luruRootReducer,
  preloadedState:
    typeof window !== 'undefined' &&
    // @ts-ignore
    window.__PRELOADED_STATE__
      ? // @ts-ignore
        window.__PRELOADED_STATE__
      : getEmptyReduxState(),
  devTools: process.env.REACT_APP_DEBUG === 'true',
  middleware:
    // TODO: This is a check for current entry point being extension
    // Replace this with the chrome.luru.entryPoint variable read check
    // @ts-ignore
    typeof window !== 'undefined' && !window.__PRELOAED_STATE__
      ? (getDefaultMiddleware) => getDefaultMiddleware().concat(extensionMiddleware)
      : undefined,
})

export const setLuruReduxStore = (store: any) => {
  LuruReduxStore = store

  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.__luru_store = LuruReduxStore
    const signInStatus = LuruReduxStore.getState().user.status
    const hostname = window.location.hostname
    const hostnameParts = hostname.split('.')
    const tenantName = hostnameParts.length === 5 ? hostnameParts[0] : 'unknown'
    var username = 'unknown'
    if (signInStatus === 'signedIn') {
      username = LuruReduxStore.getState().user.data.email
    }
    // @ts-ignore
    window?.clarity?.('identify', username, 'session/' + tenantName + '/' + username)
    // @ts-ignore
    window?.clarity?.('set', 'tenant', tenantName)
    // @ts-ignore
    window?.clarity?.('set', 'user', username)
  }
}
