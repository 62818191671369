import { LuruFieldType, CrmRecordSchema } from '../../domain/crmRecord/typings.d'
import { CRMProvider } from '../../features/user/types'
import LuruUser from '../../domain/users/LuruUser'

export enum ViewFilterOperator {
  // General
  EQ = 'equals',
  NEQ = 'not_equals',
  BLANK = 'blank',
  NOT_BLANK = 'not_blank',
  // Number specific
  GT = 'greater_than',
  GTE = 'greater_than_eq',
  LT = 'less_than',
  LTE = 'less_than_eq',
  // List specific
  IN = 'in',
  NOT_IN = 'not in',
  // String specific
  STARTS_WITH = 'starts_with',
  CONTAINS = 'contains',
  // Date specific
  PREV_MTH = 'prev_month',
  CURR_MTH = 'curr_month',
  NEXT_MTH = 'next_month',
  PREV_QTR = 'prev_quarter',
  CURR_QTR = 'curr_quarter',
  NEXT_QTR = 'next_quarter',
  PREV_FY = 'prev_fy',
  CURR_FY = 'curr_fy',
  NEXT_FY = 'next_fy',
  PREV_YEAR = 'prev_year',
  CURR_YEAR = 'curr_year',
  NEXT_YEAR = 'next_year',
}

enum OperatorGroup {
  DATE = 'date',
  STRING = 'string',
  NUMBER = 'number',
  LIST = 'list',
  MULTILIST = 'multilist',
}

const operatorMap = {
  [OperatorGroup.STRING]: {
    [ViewFilterOperator.EQ]: 'is',
    [ViewFilterOperator.NEQ]: 'is not',
    [ViewFilterOperator.BLANK]: 'is empty',
    [ViewFilterOperator.NOT_BLANK]: 'is not empty',
    [ViewFilterOperator.STARTS_WITH]: 'starts with',
    [ViewFilterOperator.CONTAINS]: 'contains',
  },
  [OperatorGroup.DATE]: {
    [ViewFilterOperator.EQ]: 'is',
    [ViewFilterOperator.NEQ]: 'is not',
    [ViewFilterOperator.BLANK]: 'is empty',
    [ViewFilterOperator.NOT_BLANK]: 'is not empty',
    [ViewFilterOperator.GT]: 'is after',
    [ViewFilterOperator.LT]: 'is before',
    [ViewFilterOperator.PREV_MTH]: 'in last month',
    [ViewFilterOperator.CURR_MTH]: 'in this month',
    [ViewFilterOperator.NEXT_MTH]: 'in next month',
    [ViewFilterOperator.PREV_QTR]: 'in last quarter',
    [ViewFilterOperator.CURR_QTR]: 'in current quarter',
    [ViewFilterOperator.NEXT_QTR]: 'in next quarter',
    [ViewFilterOperator.PREV_FY]: 'in last FY',
    [ViewFilterOperator.CURR_FY]: 'in current FY',
    [ViewFilterOperator.NEXT_FY]: 'in next FY',
    [ViewFilterOperator.PREV_YEAR]: 'in last year',
    [ViewFilterOperator.CURR_YEAR]: 'in current year',
    [ViewFilterOperator.NEXT_YEAR]: 'in next year',
  },
  [OperatorGroup.NUMBER]: {
    [ViewFilterOperator.EQ]: 'is',
    [ViewFilterOperator.NEQ]: 'is not',
    [ViewFilterOperator.BLANK]: 'is empty',
    [ViewFilterOperator.NOT_BLANK]: 'is not empty',
    [ViewFilterOperator.GT]: 'is greater than',
    [ViewFilterOperator.GTE]: 'is greater than or equals',
    [ViewFilterOperator.LT]: 'is less than',
    [ViewFilterOperator.LTE]: 'is less than or equals',
  },
  [OperatorGroup.LIST]: {
    [ViewFilterOperator.BLANK]: 'is empty',
    [ViewFilterOperator.NOT_BLANK]: 'is not empty',
    [ViewFilterOperator.EQ]: 'is',
    [ViewFilterOperator.NEQ]: 'is not',
    [ViewFilterOperator.IN]: 'is in',
    [ViewFilterOperator.NOT_IN]: 'is not in',
  },
  [OperatorGroup.MULTILIST]: {
    [ViewFilterOperator.BLANK]: 'is empty',
    [ViewFilterOperator.NOT_BLANK]: 'is not empty',
    [ViewFilterOperator.EQ]: 'is',
    [ViewFilterOperator.NEQ]: 'is not',
    [ViewFilterOperator.IN]: 'is in',
    [ViewFilterOperator.NOT_IN]: 'is not in',
  },
}

export default class OperatorUtils {
  static luruFieldTypeOperatorMap = {
    [LuruFieldType.ADDRESS]: operatorMap[OperatorGroup.STRING],
    [LuruFieldType.BOOLEAN]: operatorMap[OperatorGroup.LIST],
    [LuruFieldType.CURRENCY]: operatorMap[OperatorGroup.NUMBER],
    [LuruFieldType.DATE]: operatorMap[OperatorGroup.DATE],
    [LuruFieldType.DATETIME]: operatorMap[OperatorGroup.DATE],
    [LuruFieldType.DOUBLE]: operatorMap[OperatorGroup.NUMBER],
    [LuruFieldType.EMAIL]: operatorMap[OperatorGroup.STRING],
    [LuruFieldType.ENUM]: operatorMap[OperatorGroup.LIST],
    [LuruFieldType.ENUM_RADIO]: operatorMap[OperatorGroup.LIST],
    [LuruFieldType.ENUM_SELECT]: operatorMap[OperatorGroup.LIST],
    [LuruFieldType.INTEGER]: operatorMap[OperatorGroup.NUMBER],
    [LuruFieldType.INTEGER_NOFORMAT]: operatorMap[OperatorGroup.NUMBER],
    [LuruFieldType.LARGETEXT]: operatorMap[OperatorGroup.STRING],
    [LuruFieldType.MULTIENUM]: operatorMap[OperatorGroup.MULTILIST],
    [LuruFieldType.PERCENTAGE]: operatorMap[OperatorGroup.NUMBER],
    [LuruFieldType.REFERENCE]: operatorMap[OperatorGroup.LIST],
    [LuruFieldType.TELEPHONE]: operatorMap[OperatorGroup.STRING],
    [LuruFieldType.TEXT]: operatorMap[OperatorGroup.STRING],
    [LuruFieldType.URL]: operatorMap[OperatorGroup.STRING],
  }

  // operator(fieldName, value) => count = 2, e.g. is_equals(amount, 0)
  // operator(fieldName) => count = 1, e.g. is_blank(name)
  static operatorArgumentCountMap = {
    [ViewFilterOperator.EQ]: 2,
    [ViewFilterOperator.NEQ]: 2,
    [ViewFilterOperator.BLANK]: 1,
    [ViewFilterOperator.NOT_BLANK]: 1,
    [ViewFilterOperator.GT]: 2,
    [ViewFilterOperator.GTE]: 2,
    [ViewFilterOperator.LT]: 2,
    [ViewFilterOperator.LTE]: 2,
    [ViewFilterOperator.BETWEEN]: 3,
    [ViewFilterOperator.IN]: 2, // the value argument is a list, (one argument)
    [ViewFilterOperator.NOT_IN]: 2,
    [ViewFilterOperator.STARTS_WITH]: 2,
    [ViewFilterOperator.CONTAINS]: 2,
    // Date specific
    [ViewFilterOperator.PREV_MTH]: 1,
    [ViewFilterOperator.CURR_MTH]: 1,
    [ViewFilterOperator.NEXT_MTH]: 1,
    [ViewFilterOperator.PREV_QTR]: 1,
    [ViewFilterOperator.CURR_QTR]: 1,
    [ViewFilterOperator.NEXT_QTR]: 1,
    [ViewFilterOperator.PREV_FY]: 1,
    [ViewFilterOperator.CURR_FY]: 1,
    [ViewFilterOperator.NEXT_FY]: 1,
    [ViewFilterOperator.PREV_YEAR]: 1,
    [ViewFilterOperator.CURR_YEAR]: 1,
    [ViewFilterOperator.NEXT_YEAR]: 1,
  }

  static operatorSyntaxMap = {
    [ViewFilterOperator.BLANK]: '=',
    [ViewFilterOperator.CONTAINS]: '~',
    [ViewFilterOperator.CURR_FY]: '=',
    [ViewFilterOperator.CURR_YEAR]: '=',
    [ViewFilterOperator.CURR_MTH]: '=',
    [ViewFilterOperator.CURR_QTR]: '=',
    [ViewFilterOperator.EQ]: '=',
    [ViewFilterOperator.GT]: '>',
    [ViewFilterOperator.GTE]: '>=',
    [ViewFilterOperator.IN]: 'IN',
    [ViewFilterOperator.LT]: '<',
    [ViewFilterOperator.LTE]: '<=',
    [ViewFilterOperator.NEQ]: '!=',
    [ViewFilterOperator.NEXT_FY]: '=',
    [ViewFilterOperator.NEXT_YEAR]: '=',
    [ViewFilterOperator.NEXT_MTH]: '=',
    [ViewFilterOperator.NEXT_QTR]: '=',
    [ViewFilterOperator.NOT_BLANK]: '!=',
    [ViewFilterOperator.NOT_IN]: 'NOT IN',
    [ViewFilterOperator.PREV_FY]: '=',
    [ViewFilterOperator.PREV_YEAR]: '=',
    [ViewFilterOperator.PREV_MTH]: '=',
    [ViewFilterOperator.PREV_QTR]: '=',
    [ViewFilterOperator.STARTS_WITH]: '^~',
  }

  static unaryOperatorArgument = {
    [ViewFilterOperator.BLANK]: '%%EMPTY%%',
    [ViewFilterOperator.NOT_BLANK]: '%%EMPTY%%',
    [ViewFilterOperator.PREV_MTH]: '%%LAST_MONTH%%',
    [ViewFilterOperator.CURR_MTH]: '%%THIS_MONTH%%',
    [ViewFilterOperator.NEXT_MTH]: '%%NEXT_MONTH%%',
    [ViewFilterOperator.PREV_QTR]: '%%LAST_QUARTER%%',
    [ViewFilterOperator.CURR_QTR]: '%%THIS_QUARTER%%',
    [ViewFilterOperator.NEXT_QTR]: '%%NEXT_QUARTER%%',
    [ViewFilterOperator.PREV_FY]: '%%LAST_FISCAL_YEAR%%',
    [ViewFilterOperator.CURR_FY]: '%%THIS_FISCAL_YEAR%%',
    [ViewFilterOperator.NEXT_FY]: '%%NEXT_FISCAL_YEAR%%',
    [ViewFilterOperator.PREV_YEAR]: '%%LAST_YEAR%%',
    [ViewFilterOperator.CURR_YEAR]: '%%THIS_YEAR%%',
    [ViewFilterOperator.NEXT_YEAR]: '%%NEXT_YEAR%%',
  }

  static getNumArguments(operator: ViewFilterOperator) {
    return OperatorUtils.operatorArgumentCountMap[operator]
  }

  static getOperatorSyntax(operator: ViewFilterOperator) {
    return OperatorUtils.operatorSyntaxMap[operator]
  }

  static getOperators(luruFieldType: string): Record<ViewFilterOperator, string> {
    const crmExcludedOperators = {
      [CRMProvider.SFDC]: [ViewFilterOperator.PREV_YEAR, ViewFilterOperator.CURR_YEAR, ViewFilterOperator.NEXT_YEAR],
      [CRMProvider.SFDC_SANDBOX]: [
        ViewFilterOperator.PREV_YEAR,
        ViewFilterOperator.CURR_YEAR,
        ViewFilterOperator.NEXT_YEAR,
      ],
      [CRMProvider.HUBSPOT]: [ViewFilterOperator.PREV_FY, ViewFilterOperator.CURR_FY, ViewFilterOperator.NEXT_FY],
      [CRMProvider.PIPEDRIVE]: [],
    }
    const crmName = LuruUser.getCurrentUserCrmName()
    const excludedOperators = crmExcludedOperators[crmName]
    const allOperatorEntries = OperatorUtils.luruFieldTypeOperatorMap[luruFieldType]
    const applicableOperators = Object.keys(allOperatorEntries).filter((op) => !excludedOperators.includes(op))

    return Object.entries(allOperatorEntries)
      .filter(([key, value]) => applicableOperators.includes(key))
      .reduce((prev, [key, value]) => Object.assign(prev, { [key]: value }), {})
  }

  static constructFilterExpression(expression: ViewFilterExpressionTree, schema: CrmRecordSchema) {
    if (Array.isArray(expression)) {
      return expression.map((e) => this.constructFilterExpression(e, schema)).filter((x) => x !== undefined)
    } else if (Object.keys(expression).length === 1) {
      // this is a tree
      let e = expression as ViewFilterExpressionTree
      return {
        [Object.keys(e)[0]]: this.constructFilterExpression(Object.values(e)[0], schema),
      }
    } else if (expression) {
      // this is a filter expression
      let e = expression as ViewFilterExpression
      let isNumerical = [
        LuruFieldType.INTEGER,
        LuruFieldType.INTEGER_NOFORMAT,
        LuruFieldType.CURRENCY,
        LuruFieldType.DOUBLE,
        LuruFieldType.PERCENTAGE,
      ].includes(schema.find((f) => f.name === e.fieldName)?.luruFieldType)

      if (e.fieldName?.trim?.() === '') {
        return undefined
      }

      var sysFieldName = schema.find((f) => f.label === e.fieldName)?.name

      if (!sysFieldName) {
        sysFieldName = schema.find((f) => f.name === e.fieldName)?.name
      }

      var fieldValue =
        this.getNumArguments(e.operator) === 1
          ? this.unaryOperatorArgument[e.operator]
          : isNumerical
          ? Number(e.value)
          : e.value

      return {
        object_name: e.objectName,
        field: sysFieldName,
        op: this.getOperatorSyntax(e.operator),
        original_op: e.operator,
        value: fieldValue,
      }
    } else {
      return {}
    }
  }

  static getOperatorFromDataValues(op: string, value: any) {
    var matchingOperators = Object.keys(OperatorUtils.operatorSyntaxMap).filter(
      (operator) => OperatorUtils.operatorSyntaxMap[operator as keyof typeof OperatorUtils.operatorSyntaxMap] === op
    )

    if (matchingOperators.length === 0) {
      return op
    }

    if (matchingOperators.length === 1) {
      return matchingOperators[0]
    }

    var matchingUnaryOperators = matchingOperators.filter((op) => OperatorUtils.unaryOperatorArgument[op] === value)

    if (matchingUnaryOperators.length === 1) {
      return matchingUnaryOperators[0]
    }

    // matchingUnaryOperators.length can't be >= 2
    return matchingOperators[0]
  }
}

export interface ViewFilterExpression {
  objectName: string
  fieldName: string
  operator: ViewFilterOperator
  value: any
}

export interface ViewAPIFilterExpression {
  object_name: string
  field: string
  op: string
  original_op: ViewFilterOperator
  value: any
  object_name: string
}

export enum ViewFilterGroupingOperator {
  OR = 'or',
  AND = 'and',
}

export interface ViewAPIFilterStructure {
  [ViewFilterGroupingOperator.AND]: [
    {
      [ViewFilterGroupingOperator.AND]: Array<ViewAPIFilterExpression>
    },
    {
      [ViewFilterGroupingOperator.OR]: Array<ViewAPIFilterExpression>
    }
  ]
}

export interface ViewFilterExpressionTree {
  [ViewFilterGroupingOperator]: ViewFilterExpressionTree | Array<ViewFilterExpression | ViewFilterExpressionTree>
}
