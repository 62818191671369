import json5 from 'json5'
import { JottingType } from '../features/notes/types'
import { LuruReduxStore } from '../app/store'
import moment from 'moment'

/**
 * MarkdownConvertor has utility method for converting a note to a Markdown doc
 * This is useful when we want to share the note as text or copy-to-clipboard
 */
export class MarkdownConvertor {
  static convertLuruNoteToMarkdownWithFooter(body, userName, noteTitle, meetingId) {
    var html = ''
    html += this.getMeetingDetails(html, meetingId, noteTitle)
    html += MarkdownConvertor.convertLuruNoteToMarkdown(body)
    var footer = ''

    if (userName !== null && userName !== undefined) {
      footer += `${userName}'s note was powered by Luru `
    }

    html += footer

    return html
  }

  static getMeetingDetails(html, meetingId, noteTitle) {
    html += `# ${noteTitle}\n`

    const meetingDetails = LuruReduxStore.getState()?.meetings?.entities?.[meetingId]
    const participantNames =
      meetingDetails?.data?.participants?.map((p) => (p.name ? `${p.name} (${p.email})` : p.email)) || []
    const meetingInterval =
      meetingDetails?.data?.start_time && meetingDetails.data.end_time
        ? this.getMeetingStartAndEndDetails(meetingDetails.data.start_time, meetingDetails.data.end_time)
        : ''

    if (meetingInterval && participantNames) {
      html += '###Meeting participants:\n'
      html += `${participantNames.join(', ')}\n`
      html += '###Date & Time:\n'
      html += `${meetingInterval}\n`
    }

    return html
  }

  static getMeetingStartAndEndDetails(startTime, endTime) {
    const start = new Date(startTime)
    const end = new Date(endTime)

    if (start.toDateString() !== end.toDateString()) {
      return `${moment(startTime).format('LLLL')} - ${moment(endTime).format('LLLL')}`
    } else {
      return `${moment(startTime).format('LLLL')} - ${moment(endTime).format('h:mm A')}`
    }
  }

  static convertLuruNoteToMarkdown(body) {
    let i = 0
    let result = ''

    while (i < body.length) {
      let jot = body[i]
      let temp = ''

      switch (jot.type) {
        case JottingType.TASK_INCOMPLETE:
          ;[temp, i] = MarkdownConvertor.#convertTaskIncompleteToMarkdown(body, i)
          break

        case JottingType.TASK_COMPLETE:
          ;[temp, i] = MarkdownConvertor.#convertTaskCompleteToMarkdown(body, i)
          break

        case JottingType.H1:
        case JottingType.H2:
        case JottingType.H3:
          ;[temp, i] = MarkdownConvertor.#convertHeadingToMarkdown(body, i)
          break

        case JottingType.P:
          ;[temp, i] = MarkdownConvertor.#convertParaToMarkdown(body, i)
          break

        case JottingType.Q:
          ;[temp, i] = MarkdownConvertor.#convertQuestionToMarkdown(body, i)
          break

        case JottingType.UL1:
        case JottingType.UL2:
        case JottingType.UL3:
          ;[temp, i] = MarkdownConvertor.#convertUnorderedListToMarkdown(body, i)
          break

        case JottingType.OL1:
        case JottingType.OL2:
        case JottingType.OL3:
          ;[temp, i] = MarkdownConvertor.#convertOrderedListToMarkdown(body, i, MarkdownConvertor.#isOrderedListJot)
          break

        case JottingType.CRM_FIELD_VALUE:
          ;[temp, i] = MarkdownConvertor.#convertCrmFieldValueToMarkdown(body, i)
          break

        case JottingType.CRM_COLLECTION:
          ;[temp, i] = MarkdownConvertor.#convertCrmFieldCollectionToMarkdown(body, i)
          break

        default:
          console.error(`Ignoring unrecognized JottingType at root level = ${jot.type}`)
          i++
      }

      result += temp + '\n'
    }

    return result
  }

  static #convertTaskIncompleteToMarkdown(body, i, prefix = '') {
    let jot = body[i]
    let result = prefix

    result += `[ ] ${jot.data}`

    return [result, i + 1]
  }

  static #convertTaskCompleteToMarkdown(body, i, prefix = '') {
    let jot = body[i]
    let result = prefix

    result += `[x] ${jot.data}`

    return [result, i + 1]
  }

  static #convertHeadingToMarkdown(body, i, prefix = '') {
    let jot = body[i]
    let result = prefix

    switch (jot.type) {
      case JottingType.A_H1:
      case JottingType.H1:
        result += '#'
        break

      case JottingType.A_H2:
      case JottingType.H2:
        result += '##'
        break

      case JottingType.A_H3:
      case JottingType.H3:
      default:
        result += '###'
        break
    }

    result += ` ${jot.data}`

    return [result, i + 1]
  }

  static #convertParaToMarkdown(body, i, prefix = '') {
    let jot = body[i]
    let result = prefix

    result += `${jot.data}`

    return [result, i + 1]
  }

  static #convertUnorderedListToMarkdown(body, i, prefix = '') {
    let ulJot = body[i]
    let result = prefix

    switch (ulJot.type) {
      case JottingType.UL1:
      case JottingType.A_UL1:
        result += '*'
        break

      case JottingType.UL2:
      case JottingType.A_UL2:
        result += '    +'
        break

      case JottingType.UL3:
      case JottingType.A_UL3:
      default:
        result += '        -'
        break
    }

    result += ` ${ulJot.data}`

    return [result, i + 1]
  }

  static #convertOrderedListToMarkdown(body, i, checkFunc, prefix = '') {
    let ulJot = body[i]
    let currLevel = 0

    // Since jots only contain levels (ul1, ul2, ul3) we have to create the numbered indices
    // This array is for storing the current index at each level
    let indices = {
      1: 1,
      2: 1,
      3: 1,
    }

    let result = ''

    while (ulJot && checkFunc(ulJot.type)) {
      let type = ulJot.type
      let prevLevel = currLevel

      // type is something like 'answerUnorderedListLevel3', pick the last char
      currLevel = Number(type.slice(type.length - 1))

      result += prefix + ' '.repeat((currLevel - 1) * 4) + indices[currLevel] + '. ' + ulJot.data + '\n'

      // Update index
      indices[currLevel]++

      if (currLevel < prevLevel) {
        indices[prevLevel] = 1
      }

      ulJot = body?.[++i]
    }

    return [result, i]
  }

  static #convertCrmFieldCollectionToMarkdown(fields, e) {
    if (typeof fields[e].data.collection.fields[0] === 'string') {
      return ['', e + 1]
    }

    var result = '### Crm Collection fields\n'
    var i = 0

    while (i < fields[e].data.collection.fields.length) {
      let temp = ''
      ;[temp, i] = MarkdownConvertor.#convertCrmFieldValueToMarkdown(fields[e].data.collection.fields, i, true)
      result += temp + '\n'
    }

    return [result, e + 1]
  }

  static #convertCrmFieldValueToMarkdown(body, i, isCollectionField) {
    let jot = body?.[i]

    if (!jot) {
      return ['', i + 1]
    }

    if (!jot.data?.field?.luruFieldType && !isCollectionField) {
      return ['', i + 1]
    }

    let type = jot.data?.field?.luruFieldType || jot.luruFieldType
    let value = jot.data?.field?.value || jot.value

    if (type === 'reference') {
      if (typeof value === 'string') {
        try {
          let jsonValue = json5.parse(value)

          value = jsonValue.sor_record_name
        } catch (error) {
          console.error(error)
        }
      } else if (typeof value === 'object') {
        value = value.sor_record_name
      }
    } else if (type === 'multi_reference') {
      let jsonValue = json5.parse(value)

      value = ''

      for (let i = 0; i < jsonValue.length; i++) {
        if (value !== '') {
          value += '; '
        }

        value += jsonValue[i].sor_record_name
      }
    }

    let result = `> CRM Field : ${jot.data?.field?.label || jot.name}\n`

    result += `> Value(s) : ${value ? value : 'None'}`

    return [result, i + 1]
  }

  static #convertQuestionToMarkdown(body, i) {
    let qJot = body[i]
    let answer = ''
    let result = `> Q: ${qJot.data}`

    result += '\n'
    result += `%%__ANSWER__%%`
    ;[answer, i] = MarkdownConvertor.#convertAnswersToMarkdown(body, i + 1)
    result = result.replace('%%__ANSWER__%%', answer)

    return [result, i]
  }

  static #convertAnswersToMarkdown(body, i) {
    let aJot = body?.[i]
    let result = ''
    let prefix = '>> '

    while (aJot && MarkdownConvertor.#isAnswerJot(aJot.type)) {
      let temp = ''

      switch (aJot.type) {
        case JottingType.A_UL1:
        case JottingType.A_UL2:
        case JottingType.A_UL3:
          ;[temp, i] = MarkdownConvertor.#convertUnorderedListToMarkdown(body, i, prefix)
          break

        case JottingType.A_OL1:
        case JottingType.A_OL2:
        case JottingType.A_OL3:
          ;[temp, i] = MarkdownConvertor.#convertOrderedListToMarkdown(
            body,
            i,
            MarkdownConvertor.#isAnswerOrderedListJot,
            prefix
          )
          break

        case JottingType.A_H1:
        case JottingType.A_H2:
        case JottingType.A_H3:
          ;[temp, i] = MarkdownConvertor.#convertHeadingToMarkdown(body, i, prefix)
          break

        case JottingType.A_TASK_INCOMPLETE:
          ;[temp, i] = MarkdownConvertor.#convertTaskIncompleteToMarkdown(body, i, prefix)
          break
        case JottingType.A_TASK_COMPLETE:
          ;[temp, i] = MarkdownConvertor.#convertTaskCompleteToMarkdown(body, i, prefix)
          break

        case JottingType.A_CRM_FIELD_VALUE:
          ;[temp, i] = MarkdownConvertor.#convertCrmFieldValueToMarkdown(body, i, prefix)
          break

        case JottingType.A_P:
        default:
          ;[temp, i] = MarkdownConvertor.#convertParaToMarkdown(body, i, prefix)
          break
      }

      result += temp + '\n'
      aJot = body?.[i]
    }

    return [result, i]
  }

  static #isAnswerJot(jotType) {
    return jotType.startsWith('answer')
  }

  static #isOrderedListJot(jotType) {
    return jotType.startsWith('orderedList')
  }

  static #isAnswerOrderedListJot(jotType) {
    return jotType.startsWith('answerOrderedList')
  }
}
