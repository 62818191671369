import React from 'react'
import { NotesMiddleware } from '../../../features/notes/middleware'
import styles from '../css/EmbeddedMeetingNote.module.css'

// icons
import refreshIcon from '../../../images/fluent/refresh.svg'

export default class DataStoreRefreshComponent extends React.Component {
  #pageSize = 10

  constructor(props) {
    super(props)
    this.dispatch = props.dispatch
    this.state = { loading: false }
    this.resetLoading = this.resetLoading.bind(this)
  }

  resetLoading() {
    this.setState({ loading: false })
  }

  refresh() {
    this.setState({ loading: true })
    // let p1 = this.dispatch(
    //   notesMiddleware.searchSORNotes.action({
    //     key: 'home/SORNotes',
    //     limit: this.#pageSize, // TODO: Fetch more and buffer?
    //   })
    // )
    let p2 = this.dispatch(
      NotesMiddleware.searchLuruNotes.action({
        key: 'home/LuruNotes',
        limit: this.#pageSize, // TODO: Should we request for larger than this?
        refreshRequest: true,
      })
    )
    Promise.all([/*p1, */ p2]).finally(this.resetLoading)
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={styles.loadingModal}>
          <img src={refreshIcon} alt='refresh' />
          Refreshing...
        </div>
      )
    }
    return <></>
  }
}
