import { PayloadAction } from '@reduxjs/toolkit'
import { LuruViewFilterRootTree } from '../../views/types'
import { ReduxAppState } from '../types'

export const setMruView = (
  state: ReduxAppState,
  action: PayloadAction<{
    crmObjectNamePlural: string
    view_id: string
    filter: LuruViewFilterRootTree | undefined
  }>
) => {
  state.views = {
    ...state.views,
    mruView: {
      crmObjectNamePlural: action.payload.crmObjectNamePlural,
      id: action.payload.view_id,
      filter: action.payload.filter,
    },
  }
}
