// Own hooks
import { useRef } from 'react'
import { useGetSetState } from '../../hooks/luru_hooks'
import { useOutsideClick } from '../../hooks/luru_hooks'

// Own styles
import styles from './css/LuruPicklist.module.css'

//Icons 
import doneIcon from '../../images/fluent/done.svg'
import circleDownIcon from '../../images/fluent/chevron_circle_down.svg'

export default function LuruPicklist({
  fieldData,
  onSelectItem,
  computeIfReadonly,
}) {
  const refs = {
    filterBox: useRef(),
    inputElement: useRef(),
    dropdownList: useRef(),
  }

  const compareValues = (a, b) => `${a}` === `${b}`

  const state = {
    filtered: useGetSetState(false),
    selectedIndex: useGetSetState(
      fieldData?.field?.picklistValues
        ?.map((option, index) =>
          compareValues(option.value, fieldData?.field?.value) ? index : null
        )
        .find((x) => x !== null) ?? -1
    ),
    selectedValue: useGetSetState(
      fieldData?.field?.picklistValues?.find((option) =>
        compareValues(option.value, fieldData?.field?.value)
      )?.label ?? null
    ),
    typedValue: useGetSetState(
      fieldData?.field?.picklistValues?.find((option) =>
        compareValues(option.value, fieldData?.field?.value)
      )?.label ?? null
    ),
    items: useGetSetState(fieldData?.field?.picklistValues ?? null),
    allItems: fieldData?.field?.picklistValues ?? null,
  }

  const actions = {
    showDropdown: () => {
      refs.dropdownList.current?.classList.remove(styles.collapsed)
      refs.dropdownList.current?.classList.add(styles.expandedDown)
      state.typedValue.set(state.selectedValue.get())
      state.items.set(state.allItems)
      state.filtered.set(false)
      refs.filterBox.current?.focus()
    },
    hideDropDown: () => {
      refs.dropdownList.current?.classList.remove(styles.expandedDown)
      refs.dropdownList.current?.classList.add(styles.collapsed)
      state.typedValue.set(state.selectedValue.get())
      state.items.set(state.allItems)
      state.filtered.set(false)
    },
    selectItem: ({ label, value }, index) => {
      state.typedValue.set(label)
      state.selectedValue.set(label)
      if (index) {
        state.selectedIndex.set(index)
      }
      typeof onSelectItem === 'function' &&
        onSelectItem(value, refs.inputElement.current)
    },
  }

  const eventHandlers = {
    onDropdownActivate: () => {
      !computeIfReadonly() &&
      refs.dropdownList.current?.classList.contains(styles.collapsed)
        ? actions.showDropdown()
        : actions.hideDropDown()
    },

    onClickItem: (option, index) => {
      actions.selectItem(option, index)
      actions.hideDropDown()
    },

    onValueChange: (e) => {
      state.typedValue.set(e.target.value)
      let filterText = e.target.value.toLowerCase()
      state.filtered.set(true)
      state.items.set(
        state.allItems?.filter(
          (option) => option?.label?.toLowerCase()?.indexOf(filterText) !== -1
        )
      )
    },

    onKeyDown: (e) => {
      switch (e.key) {
        case 'Enter':
          actions.selectItem(state.items.get()[state.selectedIndex.get()])
          actions.hideDropDown()
          e.preventDefault()
          e.stopPropagation()
          break

        case 'Escape':
          actions.hideDropDown()
          e.preventDefault()
          e.stopPropagation()
          break

        case 'ArrowUp':
          state.selectedIndex.set(
            state.selectedIndex.get() === 0
              ? state.items.get().length - 1
              : state.selectedIndex.get() - 1
          )
          e.preventDefault()
          e.stopPropagation()
          break

        case 'ArrowDown':
          state.selectedIndex.set(
            state.selectedIndex.get() >= state.items.get().length - 1
              ? 0
              : state.selectedIndex.get() + 1
          )
          e.preventDefault()
          e.stopPropagation()
          break
        default:
      }
    },
  }

  const renderer = {
    formatResult: (option, index) => {
      try {
        if (!option) {
          return ''
        }
        const searchIndex = option.label
          .toLowerCase()
          .indexOf(state.typedValue.get()?.toLowerCase())
        let filterLength = state.typedValue.get()?.length ?? 0
        let preSearch = ''
        let search = option.label
        let postSearch = ''

        if (filterLength > 0 && searchIndex >= 0 && state.filtered.get()) {
          preSearch = option.label.slice(0, searchIndex)
          search = (
            <b>{option.label.slice(searchIndex, searchIndex + filterLength)}</b>
          )
          postSearch =
            searchIndex < option.label.length - 1
              ? option.label.slice(searchIndex + filterLength)
              : ''
        }

        return (
          <li
            key={option.value}
            className={
              index === state.selectedIndex.get() ? styles.selected : null
            }
            data-luru-value={option.label}
            onClick={() => eventHandlers.onClickItem(option, index)}
          >
            {option.label === state.selectedValue.get() ? (
              <img src={doneIcon} alt='done' />
            ) : (
              <span>&nbsp;</span>
            )}
            <span className={styles.searchResult}>
              {preSearch}
              {search}
              {postSearch}
            </span>
          </li>
        )
      } catch (e) {
        return option?.label ?? 'Error formatting option'
      }
    },
  }

  useOutsideClick(refs.inputElement, actions.hideDropDown)

  return (
    <div className={styles.parent} ref={refs.inputElement}>
      <div
        className={styles.inputRow}
        onClick={eventHandlers.onDropdownActivate}
      >
        <div className={styles.inputBox}>{state.selectedValue.get() ?? ''}</div>
        <button className={styles.dropdownButton}>
          <img src={circleDownIcon} alt='expand_down' />
        </button>
      </div>
      <div
        className={[styles.dropdownList, styles.collapsed].join(' ')}
        ref={refs.dropdownList}
      >
        <input
          ref={refs.filterBox}
          type='search'
          value={state.typedValue.get() ?? ''}
          onChange={eventHandlers.onValueChange}
          onKeyDown={eventHandlers.onKeyDown}
        />
        <ul>
          {state.items
            .get()
            ?.map((option, index) => renderer.formatResult(option, index))}
        </ul>
      </div>
    </div>
  )
}
