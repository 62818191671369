import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ListRecordsInfoAPIResponse, ListRecordsInfoParameter } from '../middleware/listRecordsInfo'

export const listRecordsInfo = (
  { crmKey, records }: ListRecordsInfoParameter,
  options?: LuruAPIRequestOptions
): Promise<ListRecordsInfoAPIResponse> => {
  var url = `/api/sor/${crmKey}/list_records_info`
  var request = new LuruAPIRequest(url, HTTPMethod.POST, options)
  request.setBody({
    records: records,
  })
  return request.make()
}
