import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { UpdateSorRecordPreparedParameter } from '../middleware/updateSorRecord'

export const updateSorRecord = (params: UpdateSorRecordPreparedParameter, options: LuruAPIRequestOptions) => {
  var { crmKey, objectName, recordId, fields } = params
  var url = `/api/sor/${crmKey}/objects/${objectName}/${recordId}`
  var request = new LuruAPIRequest(url, HTTPMethod.PUT, options)

  request.setBody(JSON.parse(JSON.stringify(fields)))

  return request.make()
}
