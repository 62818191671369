import { ConnectedProps, connect } from 'react-redux'
import getReduxState from './getReduxState'
import getActions from './getActions'
import CrmMultiObjectSelectBoxComponent from './CrmMultiObjectSelectBoxComponent'

const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type CrmMultiObjectSelectBoxConnectedProps = ConnectedProps<typeof connector>

export default connector(CrmMultiObjectSelectBoxComponent)
