import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { UsersAPI } from '../api'
import { LuruUserRole, ReduxUsersState } from '../types'

export interface GetLuruUserRolesAPIResponse extends LuruAPIResponse {
  data: Array<LuruUserRole>
}

export const getLuruUserRoles = {
  action: createAsyncThunk<
    GetLuruUserRolesAPIResponse['data'],
    {},
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: GetLuruUserRolesAPIResponse['metadata']
      rejectedMeta: GetLuruUserRolesAPIResponse['metadata']
    }
  >('users/getLuruUserRoles', async (params, { signal, fulfillWithValue, rejectWithValue }) => {
    try {
      var response = (await UsersAPI.getLuruUserRoles({ signal })) as GetLuruUserRolesAPIResponse

      if (response.error_data) {
        throw new Error(response.error_data.message)
      }

      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      return rejectWithValue({ message: (e as Error)?.message ?? 'Error retrieving Luru users' }, null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getLuruUserRoles.action.pending, (state, action) => {
      state.LURU.roles.status = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getLuruUserRoles.action.fulfilled, (state, action) => {
      state.LURU.roles.status = EntityStatus.Loaded
      action.payload.forEach(
        (role) =>
          (state.LURU.roles.entities[role.id] = {
            status: EntityStatus.Loaded,
            data: { ...role },
          })
      )
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    builder.addCase(getLuruUserRoles.action.rejected, (state, action) => {
      state.LURU.roles.status = EntityStatus.ErrorLoading
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxUsersState>) {
    getLuruUserRoles.addPendingCase(builder)
    getLuruUserRoles.addFulfilledCase(builder)
    getLuruUserRoles.addRejectedCase(builder)
  },
}
