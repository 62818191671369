import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { ViewsAPI } from '../api'
import { ReduxViewsState } from '../types'
import { AppSliceActions } from '../../app/appSlice'

export interface DeleteViewParameter {
  viewId: string
}

export interface DeleteViewAPIResponse extends LuruAPIResponse {
  data: ''
}

export const deleteView = {
  action: createAsyncThunk<
    DeleteViewAPIResponse['data'],
    DeleteViewParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: DeleteViewAPIResponse['metadata']
      rejectedMeta: DeleteViewAPIResponse['metadata'] | null
    }
  >('views/deleteView', async (params, { signal, getState, fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const state = getState()
      var response = (await ViewsAPI.deleteView(params, {
        signal,
      })) as DeleteViewAPIResponse
      //Reset the MruView if deleted view id is same as murViewId
      if (state.app.views?.mruView?.id === params.viewId) {
        dispatch(AppSliceActions.resetMruView())
      }
      return fulfillWithValue(response.data, response.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(deleteView.action.pending, (state, action) => {
      if (state.entities[action.meta.arg.viewId]) {
        state.entities[action.meta.arg.viewId].status = EntityStatus.Deleting
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(deleteView.action.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg.viewId]
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(deleteView.action.rejected, (state, action) => {
      if (state.entities[action.meta.arg.viewId]) {
        state.entities[action.meta.arg.viewId].status = EntityStatus.Loaded
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    deleteView.addPendingCase(builder)
    deleteView.addFulfilledCase(builder)
    deleteView.addRejectedCase(builder)
  },
}
