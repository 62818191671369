import { RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { CalendarProvider, CRMProvider } from '../../../features/user/types'
import { NoteId } from '../types'

export const getSORConnection =
  (
    noteId: NoteId | undefined,
    sorId: CRMProvider | CalendarProvider | undefined,
    callerId = null
  ) =>
  (state: RootState) => {
    let record =
      state.notes.entities && noteId ? state.notes.entities[noteId] : null

    // If there is no note record or data in it, return null
    if (!record || typeof record !== 'object' || !record.data || !sorId) {
      return null
    }

    // If there is a linkingStatus for this callerId, return the same
    // This will be true when a caller has already linked this note to an
    // SOR object earlier in the app state
    if (
      callerId &&
      typeof record.linkingStatus === 'object' &&
      record.linkingStatus[callerId]?.status === EntityStatus.Loaded
    ) {
      return record.linkingStatus[callerId]
    }

    // Right now, we are returning only one connection.  But later, this can
    // evolve into a function which returns all connections of the note to an
    // SOR.
    return {
      status: EntityStatus.Idle,
      connection: Array.isArray(record?.data?.connections)
        ? record.data.connections.find((item) => item.sor === sorId) ?? null
        : null,
    }
  }
