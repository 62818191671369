import { PayloadAction } from '@reduxjs/toolkit'
import { EmptyTasksState, ReduxTasksState } from '../types'

export const invalidateCache = (
  state: ReduxTasksState,
  action: PayloadAction<undefined>
) => {
  state.assignees = { ...EmptyTasksState.assignees }
  state.entities = { ...EmptyTasksState.entities }
  state.refreshedAt = undefined
}
