import update from 'immutability-helper'
import CrmMultiObjectSelectBoxComponent from './CrmMultiObjectSelectBoxComponent'

interface CrmMultiObjectSelectBoxEvents {
  onSelectItem: (sorRecordId: string, sorRecordName: string, srcElement: HTMLElement, data: any, index: number) => void
  onClickRemove: (index: number) => void
  onClickAdd: (index: number) => void
  onClickOkButton: () => void
  onClickCancelButton: () => void
  onClickSummaryText: () => void
}

export default class CrmMultiObjectSelectBoxEventHandlers {
  #component: CrmMultiObjectSelectBoxComponent
  handlers: CrmMultiObjectSelectBoxEvents

  constructor(component: CrmMultiObjectSelectBoxComponent) {
    this.#component = component
    this.handlers = {
      onSelectItem: this.#onSelectItem.bind(this),
      onClickRemove: this.#onClickRemove.bind(this),
      onClickAdd: this.#onClickAdd.bind(this),
      onClickOkButton: this.#onClickOkButton.bind(this),
      onClickCancelButton: this.#onClickCancelButton.bind(this),
      onClickSummaryText: this.#onClickSummaryText.bind(this),
    }
  }

  #onSelectItem(sorRecordId: string, sorRecordName: string, srcElement: HTMLElement, data: any, index: number) {
    const { values } = this.#component.state
    const oldValues = Array.isArray(values) ? [...(values || [])] : []
    const isAlreadyPresent = oldValues.find((f) => f.sor_record_id === sorRecordId)

    if (!isAlreadyPresent) {
      oldValues[index] = {
        sor_record_id: sorRecordId,
        sor_object_name: data?.sor_object_name || this.#component.props.sorObjectName,
        sor_record_name: sorRecordName,
      }
    } else {
      oldValues[index] = {
        sor_record_id: '',
        sor_object_name: '',
        sor_record_name: '',
      }

      const p = document.createElement('P')
      const textNode = document.createTextNode('Duplicate value')

      p.className = 'duplicate_value'
      p.style.color = 'red'
      p.style.fontSize = '0.9em'
      p.appendChild(textNode)
      srcElement.appendChild(p)
    }

    this.#component.setState({ values: oldValues })
  }

  #onClickRemove(index: number) {
    const { values } = this.#component.state
    const oldValues = [...values]

    if (oldValues.length === 1) {
      this.#component.setState((prevState) => ({
        ...prevState,
        warningMessage: 'Cannot remove last item.',
      }))

      setTimeout(() => {
        this.#component.setState((prevState) => ({
          ...prevState,
          warningMessage: '',
        }))
      }, 2000)
      return
    }

    oldValues.splice(index, 1)
    this.#component.setState({ values: oldValues })
  }

  #onClickAdd(index: number) {
    const { values } = this.#component.state
    const oldValues = [...values]
    oldValues.splice(index + 1, 0, {
      sor_object_name: '',
      sor_record_id: '',
      sor_record_name: '',
    })
    this.#component.setState({
      values: oldValues,
    })
  }

  #onClickCancelButton() {
    if (this.#component.props.doNotResetOnCancel) {
      this.#component.hideDialog()
      return
    }
    const { values } = this.#component.props
    this.#component.setState({
      values: JSON.parse(JSON.stringify(values ?? {})),
    })
    this.#component.hideDialog()
  }

  #onClickOkButton() {
    const { values } = this.#component.state
    // Check for blank value
    var valuesToSend = values.filter((val) => val.sor_record_id && val.sor_record_name && val.sor_object_name)

    // Check for duplicate
    valuesToSend = valuesToSend.filter((v, i, a) => a.findIndex((v2) => v2.sor_record_id === v.sor_record_id) === i)

    this.#component.setState((prevState) => update(prevState, { values: { $set: valuesToSend } }))
    this.#component.props.onFinishSelection?.(valuesToSend)
    this.#component.hideDialog()
  }

  #onClickSummaryText() {
    this.#component.showDialog()
  }
}
