import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'

export interface ListWorkflowTemplateFieldsParameter {
  template_id: string
}

export const listWorkflowTemplateFields = async (
  params: ListWorkflowTemplateFieldsParameter,
  options?: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/workflows/templates/fields?templates=${params.template_id}`,
    HTTPMethod.GET,
    options
  )

  return request.make()
}
