import React from 'react'

import styles from '../css/TemplateChooser.module.css'

export default class TemplateChooser extends React.Component {
  constructor(props) {
    super(props)
    this.state = { shown: false, templates: props.templates ?? null }
    this.onChooseCallBack = this.onChooseCallBack.bind(this)
    this.containerRef = React.createRef()
  }

  getTemplatesCount() {
    return this.state.templates?.length ?? 0
  }

  setTemplates(templates) {
    this.setState({ templates })
  }

  showNear(element, callback) {
    const containerElement = this.containerRef.current
    this.callback = callback
    this.setState({ shown: true })
    if (containerElement) {
      containerElement.style.top =
        element?.offsetTop +
        element?.clientHeight +
        element?.parentNode?.parentNode?.offsetTop +
        'px'
      containerElement.style.left =
        element?.offsetLeft + element?.parentNode?.parentNode?.offsetLeft + 'px'
    }
  }

  hide() {
    this.setState({ shown: false })
  }

  onChooseCallBack(template) {
    if (this.callback instanceof Function) {
      this.callback.apply(null, [template])
    }
    this.hide()
  }

  render() {
    return (
      <nav
        ref={this.containerRef}
        className={[
          styles.parent,
          this.state.shown ? styles.visible : styles.hidden,
        ].join(' ')}
      >
        {this.state.templates instanceof Array
          ? this.state.templates
              .filter((item, index) => index < 12)
              .map((template) => (
                <button
                  key={template.data.template_id}
                  className={styles.templateChip}
                  onMouseDown={() => this.onChooseCallBack(template)}
                >
                  {template.data.title}
                </button>
              ))
          : null}
      </nav>
    )
  }
}
