export enum ToastId {
  COLLECTION_TOAST_ID = 'collectionToastId',
  EMBEDDED_COLLECTION_TOAST_ID = 'embeddedCollectionToastId',
  EMBEDDED_NOTE_APP_TOAST_ID = 'embeddedNoteAppToastId',
  GLOBAL_SEARCH_APP_COMP_TOAST_ID = 'globalSearchAppComponentToastId',
  NEW_TAB_APP_COMPONENT_TOAST_ID = 'newTabAppComponentToastId',
  OPTION_APP_COMPONENT_TOAST_ID = 'optionAppComponentToastId',
  CREATE_VIEW_DIALOG_TOAST_ID = 'createViewDialogToastId',
  GLOBAL_SEARCH_RESULT_VIEW_TOAST_ID = 'globalSearchResultViewToastId',
  WORKFLOW_V2_EDITOR_TOAST_ID = 'workflowV2EditorToastId',
  O365_CALENDAR_TOAST_ID = 'o365CalendarToastId',
  CREATE_ACTIVITY_LOG_TOAST_ID = 'createActiviyLogToastId',
  CRM_LOG_AS_LINK_CONTROLL_TOAST_ID = 'crmLogAsLinkControlToastId',
  CREATE_CRM_RECORD_DAILOG_TOAST_ID = 'createCrmRecordDailogToastId',
  CRM_MULTI_OBJECT_SELECT_TOAST_ID = 'crmMultiObjectSelectToastId',
  MEETINGS_TAB_PANEL_TOAST_ID = 'meetingsTabPanelToastId',
  NOTES_TAB_PANEL_TOAST_ID = 'notesTabPanelToastId',
  TASK_TAB_PANEL_TOAST_ID = 'taskTabPanelToastId',
  OMNIBOX_TOAST_ID = 'omniboxToastId',
  CELL_TOAST_ID = 'cellToastId',
  ONBOARDING_TOAST_ID = 'onboardingToastId',
  PLAYBOOK_TOAST_ID = 'playbookToastId',
  MEETING_NOTES_TOAST_ID = 'meetingNotesToastId',
  CRM_COLLECTION_CHOOSER_TOAST_ID = 'crmCollectionChooserToastId',
  PIPLINE_TOAST_ID = 'pipelineToastId',
  SETTING_INTEGRATION_TOAST_ID = 'settingIntegrationsToastId',
  SETTINGS_USERS_TOAST_ID = 'settingsUsersToastId',
  WORKFLOW_V1_EDITOR_TOAST_ID = 'workflowV1EditorToastId',
  NOTES_EDITOR_TOAST_ID = 'notesEditorToastId',
  EDITABLE_CELL_TOAST_ID = 'editableCellToastId',
  TASKS_TOAST_ID = 'taskToastId',
  NOTIFICATION_CONTROLLER_TOAST_ID = 'notificationControllerToastId'
}
