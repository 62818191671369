import { getAllObjects } from './getAllObjects'
import { getObjectSchema } from './getObjectSchema'
import { updateSorRecord } from './updateSorRecord'
import { searchCrmRecords } from './searchRecords'
import { createActivityLog } from './createActivityLog'
import { updateActivityLog } from './updateActivityLog'
import { deleteActivityLog } from './deleteActivityLog'
import { searchActivityLog } from './searchActivityLog'
import { createActivityLogFromCalEvnt } from './createActivityLogFromCalEvnt'
import { listRecordsInfo } from './listRecordsInfo'

export const CrmAPI = {
  getObjectSchema,
  updateSorRecord,
  searchCrmRecords,
  createActivityLog,
  updateActivityLog,
  searchActivityLog,
  deleteActivityLog,
  createActivityLogFromCalEvnt,
  getAllObjects,
  listRecordsInfo,
}
