import { EntityStatus } from '../../app/types'

export const ReduxEmailsStateTTL = 60 * 60 * 1_000 // millisecs/hour

export interface ReduxEmailsState {
  refreshedAt: string | undefined
  status: EntityStatus
  entities: Record<
    string,
    {
      status: EntityStatus
      data: ReduxEmailEntity
    }
  >
}

export interface ReduxEmailEntity {
  email: string
  watch_status: string
  user: string
}

export const EmptyEmailsState: ReduxEmailsState = {
  refreshedAt: undefined,
  status: EntityStatus.NotLoaded,
  entities: {},
}
