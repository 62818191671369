import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export function initSentry() {
  Sentry.init({
    // TODO: Take this from env file so that test / prod can have different setting
    dsn: 'https://e6a055aeebb142e586baf10afc037146@o1210519.ingest.sentry.io/6379150',
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  })
}
