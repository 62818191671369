import { LuruReduxStore } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { UsersMiddleware } from '../middleware'

export const fetchLuruUser = async (userId: string, force: boolean = false) => {
  var state = LuruReduxStore.getState()
  var luruUsers =
    state.users.LURU.status === EntityStatus.Loaded
      ? Object.values(state.users.LURU.entities)
          .map((e) => e.data)
          .filter(Boolean)
      : null

  luruUsers =
    !luruUsers || force
      ? await LuruReduxStore.dispatch(
          UsersMiddleware.getLuruUsers.action({})
        ).unwrap()
      : luruUsers

  return luruUsers.find((u) => u.user_id === userId)
}
