import { updateTitle } from './updateTitle'
import { updateBody } from './updateBody'
import { removeNote } from './removeNote'
import { markNoteEditable } from './markNoteEditable'
import { invalidateCache } from './invalidateCache'

export const NotesSliceReducers = {
  updateTitle,
  updateBody,
  removeNote,
  markNoteEditable,
  invalidateCache,
}
