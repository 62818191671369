import LuruUserSelectBox from './LuruUserSelectBoxComponent'
import { LuruReduxStore } from '../../../../app/store'
import { EntityStatus } from '../../../../app/types'
import { UsersMiddleware } from '../../../../features/users/middleware'
import { LuruUserSelectBoxType } from '.'

export interface LuruUserSelectBoxEvents {
  onUserInputBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  onUserInputFocus: (e: React.FocusEvent<HTMLInputElement>) => void
  onUserInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChooseUser: (user: { userId: string; name: string; email: string }) => void
}

export default class LuruUserSelectBoxEventHandler {
  #component: LuruUserSelectBox
  handlers: LuruUserSelectBoxEvents

  constructor(component: LuruUserSelectBox) {
    this.#component = component
    this.#component.componentDidMount = this.onComponentMount.bind(this)

    this.handlers = {
      onUserInputBlur: this.onUserInputBlur.bind(this),
      onUserInputFocus: this.onUserInputFocus.bind(this),
      onUserInputChange: this.onUserInputChange.bind(this),
      onChooseUser: this.onChooseUser.bind(this),
    }
  }

  async onComponentMount() {
    this.#component.setState({ usersLoadingStatus: EntityStatus.Loading })

    try {
      switch (this.#component.props.type) {
        case LuruUserSelectBoxType.CrmUsers:
          await this.#loadCrmUsers()
          break

        case LuruUserSelectBoxType.LuruUsers:
          await this.#loadLuruUsers()
          break

        default:
          await Promise.allSettled([this.#loadLuruUsers(), this.#loadLuruUsers()])
      }

      this.#component.setState({
        usersLoadingStatus: EntityStatus.Loaded,
      })
    } catch (e) {
      console.trace(e)
      this.#component.setState({
        usersLoadingStatus: EntityStatus.ErrorLoading,
      })
    }
  }

  async #loadCrmUsers() {
    if (
      this.#component.props.crmUsersStatus !== EntityStatus.ErrorLoading &&
      this.#component.props.crmUsersStatus !== EntityStatus.Loaded
    ) {
      try {
        await LuruReduxStore.dispatch(
          UsersMiddleware.getSorUsers.action({
            sor: this.#component.props.crmProvider,
          })
        ).unwrap()
      } catch (e) {
        console.warn(e)
      }
    }
  }

  async #loadLuruUsers() {
    if (
      this.#component.props.luruUsersStatus !== EntityStatus.ErrorLoading &&
      this.#component.props.luruUsersStatus !== EntityStatus.Loaded
    ) {
      await LuruReduxStore.dispatch(UsersMiddleware.getLuruUsers.action({})).unwrap()
    }
  }

  onUserInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.#component.setState({ currentInput: e.target.value })
  }

  onUserInputFocus(e: React.FocusEvent<HTMLInputElement>) {
    this.#component.setState({ popupVisible: true })
  }

  onUserInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      this.#component.setState({ popupVisible: false })
    }, 100)
  }

  onChooseUser(user: { userId: string; name: string; email: string }) {
    let { userId, name, email } = user
    this.#component.userInputRef.current?.blur()
    this.#component.props.onChooseUser({ userId, name, email })
  }
}
