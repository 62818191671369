import React, { useCallback, useEffect, useImperativeHandle } from 'react'
import styles from './ConnectCalendarModal.module.css'
import Modal from '@/components/Modal'
import { useCrmCommonName } from '@/features/crm/hooks/useCrmName'
import { LuruEntryPoint } from '@/app/types'
import LuruUser from '@/domain/users/LuruUser'
import { CalendarProvider, LocalUserSettingKey, NoCalendarConnectionInfoToastType } from '@/features/user/types'
import { useAppSelector } from '@/app/hooks'
import { trackEvent } from '@/analytics/Ga'
import { getAuthUrl } from '@/domutils/utils'
import { BrowserUtils } from '@/utils/browserUtils'
import { localUserSettings } from '@/features/user/localUserSettings'
import { useLuruToast } from '@/hooks/useLuruToast'

export type ConnectCalendarModalRefValue = {
  showModal: (calendarProvider?: CalendarProvider) => void
  showGCalModal: () => void
  showO365CalModal: () => void
}

type ConnectCalendarModalProps = {
  errorState?: boolean
  showRefreshButton?: boolean
  onClose?: () => void
}

const ConnectCalendarModal = React.forwardRef<ConnectCalendarModalRefValue, ConnectCalendarModalProps>((props, ref) => {
  const [modalShown, setModalShown] = React.useState(false)

  const handleOk = useCallback(() => {
    setModalShown(false)
  }, [])

  const handleCancel = useCallback(() => {
    setModalShown(false)
    props.onClose?.()
  }, [props])

  const handleConnectFromToast = useCallback(() => {
    setModalShown(true)
  }, [])

  const [calendarProvider, setCalendarProvider] = React.useState<CalendarProvider | undefined>(undefined)

  // Following function will work both in NewTab as well as WebApp context
  // For NewTab, user will be redirected to WebApp after sign-in
  const handleConnect = useCallback((calendarProvider: CalendarProvider) => {
    trackEvent('calendar_connect', calendarProvider)
    var currentEntryPoint = LuruUser.getCurrentEntryPoint()
    // Add redirect path so that we end up at the exact page we are in now
    switch (currentEntryPoint) {
      case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
      case LuruEntryPoint.GLOBALSEARCH:
        var path = `${window.location.protocol}//${window.location.host}/pages/newtab/index.html?force=true%26redirect=/noop_close`
        var calConnectUrl =
          calendarProvider === CalendarProvider.GCAL
            ? `${getAuthUrl()}/login/google-oauth2/?next=/next${path}`
            : `${getAuthUrl()}/login/azuread-v2-tenant-oauth2-cal/?next=/next${path}`
        break

      case LuruEntryPoint.NEWTAB:
      case LuruEntryPoint.OPTIONS:
        path = `${window.location.protocol}//${window.location.host}/pages/newtab/index.html?force=true%26redirect=${window.location.pathname}`
        calConnectUrl =
          calendarProvider === CalendarProvider.GCAL
            ? `${getAuthUrl()}/login/google-oauth2/?next=/next${path}`
            : `${getAuthUrl()}/login/azuread-v2-tenant-oauth2-cal/?next=/next${path}`
        break

      default:
        path = window.location.pathname ?? '/'
        calConnectUrl =
          calendarProvider === CalendarProvider.GCAL
            ? `${getAuthUrl()}/login/google-oauth2/?next=/next${path}`
            : `${getAuthUrl()}/login/azuread-v2-tenant-oauth2-cal/?next=/next${path}`
    }

    switch (currentEntryPoint) {
      case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
      case LuruEntryPoint.GLOBALSEARCH:
        BrowserUtils.openWindow(
          calConnectUrl,
          `Connect to ${calendarProvider === CalendarProvider.GCAL ? 'Google Calendar' : 'Microsoft Office'}`
        )
        break

      default:
        window.location.href = calConnectUrl
    }
  }, [])

  useImperativeHandle(ref, () => ({
    showModal: (calendarProvider?: CalendarProvider) => {
      setCalendarProvider(calendarProvider)
      setModalShown(true)
    },
    showGCalModal: () => {
      setCalendarProvider(CalendarProvider.GCAL)
      setModalShown(true)
    },
    showO365CalModal: () => {
      setCalendarProvider(CalendarProvider.O365CAL)
      setModalShown(true)
    },
  }))

  return (
    <>
      <Modal
        hideOkButton={calendarProvider === CalendarProvider.O365CAL}
        doShow={modalShown}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className={styles.container}>
          <h4 className={styles.title}>
            <Header errorState={props.errorState} />
          </h4>
          <div className={styles.body}>
            <Content errorState={props.errorState} calendarProvider={calendarProvider} handleConnect={handleConnect} />
          </div>
          <div className={styles.footer}>
            <Footer errorState={props.errorState} />
          </div>
        </div>
      </Modal>
      <NoCalendarConnectionInfoToast handleConnect={handleConnectFromToast} />
      <CalendarConnectionWarningToast handleConnect={handleConnectFromToast} />
    </>
  )
})

export default ConnectCalendarModal

const Header = ({ errorState }: ConnectCalendarModalProps) => {
  const calendarConnectErrorCode = useAppSelector(
    (state) => state.user.data?.userSettings?.connectedCalendar?.errorCode || state.meetings?.error?.error_code
  )
  return [10104, 10100].includes(calendarConnectErrorCode) ? (
    <>Reconnect your calendar</>
  ) : errorState ? (
    <>Cannot load meetings</>
  ) : (
    <>Connect your calendar</>
  )
}

const Content = ({
  errorState,
  calendarProvider,
  handleConnect,
}: {
  errorState?: boolean
  calendarProvider?: CalendarProvider
  handleConnect: (c: CalendarProvider) => void
}) => {
  const calendarConnectErrorCode = useAppSelector(
    (state) => state.user.data?.userSettings?.connectedCalendar?.errorCode || state.meetings?.error?.error_code
  )
  const connectedCrm = useCrmCommonName()
  const isZoomContext = [LuruEntryPoint.EMBEDDED_ZOOM_MEETING, LuruEntryPoint.EMBEDDED_ZOOM_PANEL].includes(
    LuruUser.getCurrentEntryPoint()
  )

  if (calendarConnectErrorCode && isZoomContext) {
    return (
      <>
        <p>
          There was a problem connecting to your{' '}
          {calendarProvider === CalendarProvider.O365CAL ? 'Microsoft' : 'Google'} Calendar. This is required to
          auto-link your meeting note to CRM records
        </p>
        <ZoomContextMessage />
      </>
    )
  }

  if (errorState) {
    return <>This may be a temporary problem. Sign in with Google again to fetch meeting notes</>
  }

  return (
    <>
      <p>
        Ace your meetings by connecting with your calendar. Luru will auto-log your sales meetings to your
        {connectedCrm}. And the notes you take inside your Zoom / GMeet will be auto-synced with your {connectedCrm}.
      </p>
      {isZoomContext ? (
        <ZoomContextMessage />
      ) : (
        <NonZoomContextMessage calendarProvider={calendarProvider} handleConnect={handleConnect} />
      )}
    </>
  )
}

const ZoomContextMessage = () => {
  const calendarConnectErrorCode = useAppSelector(
    (state) => state.user.data?.userSettings?.connectedCalendar?.errorCode || state.meetings?.error?.error_code
  )

  if (calendarConnectErrorCode) {
    return (
      <div data-luru-role='zoom-message'>
        <span>Please re-authorize Luru by visiting</span> <strong>https://my.luru.app/settings/integrations</strong>{' '}
        <span>in your browser</span>
      </div>
    )
  }
  return (
    <div data-luru-role='zoom-message'>
      <span>Please visit</span> <strong>https://my.luru.app/settings/integrations</strong>{' '}
      <span>in your browser to finish setting up</span>
    </div>
  )
}

const NonZoomContextMessage = ({
  calendarProvider,
  handleConnect,
}: {
  calendarProvider?: CalendarProvider
  handleConnect: (c: CalendarProvider) => void
}) => {
  return (
    <div className={styles.connectButtonsContainer}>
      {calendarProvider && calendarProvider !== CalendarProvider.GCAL ? null : (
        <button className={styles.gcalButton} onClick={() => handleConnect(CalendarProvider.GCAL)}>
          <div className={styles.icon} data-luru-cal={CalendarProvider.GCAL}></div>
          <div className={styles.buttonText}>Sign in with Google</div>
        </button>
      )}
      {calendarProvider && calendarProvider !== CalendarProvider.O365CAL ? null : (
        <button className={styles.gcalButton} onClick={() => handleConnect(CalendarProvider.O365CAL)}>
          <div className={styles.icon} data-luru-cal={CalendarProvider.O365CAL}></div>
          <div className={styles.buttonText}>Sign in with Microsoft</div>
        </button>
      )}
    </div>
  )
}

const Footer = ({ errorState }: ConnectCalendarModalProps) => {
  const isZoomContext = [LuruEntryPoint.EMBEDDED_ZOOM_MEETING, LuruEntryPoint.EMBEDDED_ZOOM_PANEL].includes(
    LuruUser.getCurrentEntryPoint()
  )
  const calendarConnectErrorCode = useAppSelector(
    (state) => state.user.data?.userSettings?.connectedCalendar?.errorCode || state.meetings?.error?.error_code
  )

  const handleRefreshPanel = useCallback(() => {
    window.location.reload()
  }, [])

  if (calendarConnectErrorCode && isZoomContext) {
    return null
  }
  return errorState ? (
    <>
      Signed in again?
      <br />
      <button className='cta' onClick={handleRefreshPanel}>
        Refresh this panel to retry
      </button>
    </>
  ) : isZoomContext ? ( // Show this only for zoomContext, as per Sid suggestion
    <>
      Already connected?
      <button className={['cta', styles.actionButton].join(' ')} onClick={handleRefreshPanel}>
        Refresh this panel
      </button>
    </>
  ) : null
}

const NoCalendarConnectionInfoToast = ({ handleConnect }: { handleConnect: () => void }) => {
  const meetingsAPIError = useAppSelector((state) => state.meetings.error)
  const calendarConnectModalHidden = localUserSettings.get(LocalUserSettingKey.CONNECT_CALENDAR_DIALOG_HIDDEN) ?? false
  const { showToast } = useLuruToast()
  // Show toast if there is a meeting fetch error
  useEffect(() => {
    if (calendarConnectModalHidden) {
      return
    }
    if (meetingsAPIError?.error_code === 10100) {
      // Show toast
      const toastContent = (
        <span>
          You haven't connected Luru with your calendar. You can continue to take notes, but won't be able to link this
          note to a meeting.{' '}
          <span onClick={handleConnect} data-role='link'>
            Click here
          </span>{' '}
          to connect to your calendar
        </span>
      )
      const noCalendarConnectInfoTaostInfo = localUserSettings.get(
        LocalUserSettingKey.NO_CALENDAR_CONNECT_INFO_TOAST
      ) as NoCalendarConnectionInfoToastType

      if (!noCalendarConnectInfoTaostInfo) {
        showToast({
          id: 'NoCalendarConnectionInfoToast',
          message: toastContent,
          severity: 'info',
          duration: 60 * 1000, //Default duration for this toast will be 1min
        })
      } else {
        // For now we are showing this toast after 2 days once the user closes the toast
        const noCalendarConnectToastHiddenAt = new Date(noCalendarConnectInfoTaostInfo?.hiddenAt ?? '')
        const isNoCaledarConnectToastHidden2DaysBack =
          new Date().valueOf() - noCalendarConnectToastHiddenAt.valueOf() > 2 * 24 * 60 * 60 * 1000

        if (isNoCaledarConnectToastHidden2DaysBack) {
          showToast({
            id: 'NoCalendarConnectionInfoToast',
            message: toastContent,
            severity: 'info',
            duration: 60 * 1000,
          })
        }
      }
    } else {
      // Hide toast
    }
  }, [meetingsAPIError, handleConnect, calendarConnectModalHidden, showToast])
  return <></>
}

const CalendarConnectionWarningToast = ({ handleConnect }: { handleConnect: () => void }) => {
  const meetingsAPIError = useAppSelector((state) => state.meetings.error)
  const calendarProvider = useAppSelector((state) => state.user.data.userSettings.connectedCalendar?.name)
  const calendarConnectModalHidden = localUserSettings.get(LocalUserSettingKey.CONNECT_CALENDAR_DIALOG_HIDDEN) ?? false
  const { showToast } = useLuruToast()
  // Show toast if there is a meeting fetch error
  useEffect(() => {
    if (calendarConnectModalHidden) {
      return
    }

    if (meetingsAPIError?.error_code === 10400) {
      // Show toast
      const toastContent = (
        <span>
          You have connected with your{' '}
          <span>{`${calendarProvider === CalendarProvider.GCAL ? 'Google Calendar' : 'Office 365 Calendar'}`}</span>,
          but you seem to have missed giving permissions for Luru to view your meetings. You can continue to take notes,
          but without this permission, you won't be able to link this note to a meeting.{' '}
          <span onClick={handleConnect} className={styles.toastClickHereActionText}>
            Click here
          </span>
          to connect again
        </span>
      )
      showToast({
        id: 'CalendarConnectionWarningToast',
        message: toastContent,
        severity: 'info',
      })
    } else {
      // Hide toast
    }
  }, [meetingsAPIError, handleConnect, calendarProvider, calendarConnectModalHidden, showToast])
  return <></>
}
