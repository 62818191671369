// Third party libraries
import moment from 'moment'

// Own components
import FlatButton from './FlatButton'

// Styles
import styles from './css/WeekBrowser.module.css'

// Assets
import leftArrow from '../images/left-arrow.svg'
import rightArrow from '../images/right-arrow.svg'

/**
 * A week browser with previous & next week buttons, today shortcut, month
 * label and week days & dates, filterable, displayed all together as one block
 * @param {object} props Attributes accepted
 * - week: A moment object to initialize the week browser with
 * - multiFilter: Flag to enable multiple date filters (like checkboxes)
 * Event handlers
 * - onWeekChange: Event handler for changing the week to a new week
 * - onDateFilterChange: Event handler for change of date filters
 * @returns JSX
 */
export default function WeekBrowser(props) {
  // Component props
  const week = moment(props.week ?? null).startOf('isoWeek')
  const dateFilters = props.dateFilters

  // Component views
  function render() {
    return (
      <div className={styles.main}>
        {shortcuts}
        {weekdayHeader}
        {dateList}
      </div>
    )
  }

  const shortcuts = <Shortcuts week={week} onWeekChange={onWeekChange} />

  const weekdayHeader = (
    <div className={styles.days}>
      {[...Array(7).keys()].map((i) => (
        <div key={i}>
          {moment(week).add(i, 'days').format('ddd')}
        </div>
      ))}
    </div>
  )

  const weekStyle =
    week.date() + 6 > week.daysInMonth()
      ? styles.overlapWeek
      : styles.normalWeek

  function computeClassNames(date) {
    let classes = []
    classes.push(
      date.date() >= week.date() ? styles.prevMonthDay : styles.currentMonthDay
    )
    if (dateFilters?.includes(date.format('yyyyMMDD'))) {
      classes.push(styles.filtered)
    }
    if (moment().isSame(date, 'day')) {
      classes.push(styles.today)
    }
    return classes.join(' ')
  }

  const dateList = (
    <div className={weekStyle}>
      {[...Array(7).keys()]
        .map((i) => moment(week).add(i, 'days'))
        .map((date, index) => (
          <div
            key={index}
            data-date-index={date.format('yyyyMMDD')}
            className={computeClassNames(date)}
            onClick={onClickDate}
          >
            {date.format('D')}
          </div>
        ))}
    </div>
  )

  // Component event handlers
  function onWeekChange(e) {
    if (props.onWeekChange) {
      props.onWeekChange(e)
    }
  }

  /**
   * Inform hosting component of change in date filters.
   * If no onDateFilterChange component is given, filtering is not enabled,
   * as it is deemed that that hosting component is not interested in filtering
   * This function does not update view directly.
   * @param {Event} e MouseEvent
   */
  function onClickDate(e) {
    // Call any callback function, if provided
    if (props.onDateFilterChange) {
      // Extract date index that was targeted for filter/de-filter
      const d = e.target.dataset.dateIndex

      // Compute new date filters
      let newDateFilters = Array.from(dateFilters || [])
      if (props.multiFilter) {
        dateFilters.includes(d)
          ? newDateFilters.splice(dateFilters.indexOf(d), 1)
          : newDateFilters.push(d)
      } else {
        newDateFilters =
          dateFilters.length === 0 || dateFilters[0] !== d ? [d] : []
      }

      // Inform hosting container
      props.onDateFilterChange(newDateFilters)
    }
  }

  return render()
}

export const ShortcutName = {
  PREVIOUS_WEEK: 'previous_week',
  NEXT_WEEK: 'next_week',
  TODAY: 'today',
}

Object.freeze(ShortcutName)

/**
 * Internal component of WeekBrowser component to define the shortcuts area
 * @param {obj} param0 Attributes for week and callback for week change
 * @returns Shortcuts element before week day list in WeekBrowser component
 */
function Shortcuts({ week, onWeekChange }) {
  // Component views (computed view here is nothing but the simple text label)
  let label = week.format('MMMM YYYY')
  if (week.date() + 7 > week.daysInMonth()) {
    label =
      week.month() !== 11
        ? label.replace(' ', `-${moment(week).add(7, 'days').format('MMM')} `)
        : label + '-' + moment(week).add(7, 'days').format('MMM YY')
  }

  // Component controllers (these are hosting component or model facing)
  function handleWeekChange(e, newWeek) {
    if (onWeekChange) {
      e.data = { ...e.data, week: newWeek }
      onWeekChange(e)
    }
  }

  // Component event handlers (these are user-facing)
  function onPrevWeekClick(e) {
    e.data = { ...e.data, source: ShortcutName.PREVIOUS_WEEK }
    handleWeekChange(e, moment(week).subtract(7, 'days'))
  }

  function onNextWeekClick(e) {
    e.data = { ...e.data, source: ShortcutName.NEXT_WEEK }
    handleWeekChange(e, moment(week).add(7, 'days'))
  }

  function onTodayClick(e) {
    e.data = { ...e.data, source: ShortcutName.TODAY }
    handleWeekChange(e, moment().startOf('isoWeek'))
  }

  return (
    <div className={styles.shortcuts}>
      <div className={styles.periodName}>{label}</div>
      <FlatButton
        className={
          moment().startOf('isoWeek').isSame(week)
            ? styles.notVisible
            : styles.visible
        }
        onClick={onTodayClick}
      >
        Today
      </FlatButton>
      <div className={styles.navigate}>
        <img src={leftArrow} alt='Previous' onClick={onPrevWeekClick} />
        <img src={rightArrow} alt='Next' onClick={onNextWeekClick} />
      </div>
    </div>
  )
}
