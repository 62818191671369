import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import LuruUser from '../../domain/users/LuruUser'
import { getAuthUrl } from '../../domutils/utils'
import { trackEvent } from '../../analytics/Ga'
import { BrowserUtils } from '../../utils/browserUtils'
import styles from './styles.module.css'
import luruLogo from '../../images/luru/logoMain.svg'
import supergirlImage from '../../images/luru/supergirl.png'
import checkboxBlank from '../../images/fluent/check_box_blank.svg'
import checkboxFilled from '../../images/fluent/check_box_filled.svg'
import greenTick from '../../images/green_tick.png'
import securityBadge from '../../images/fluent/verified_user.svg'
import { CRMProvider } from '../../features/user/types'
import { LuruEntryPoint } from '../../app/types'
import CrmRecord from '../../domain/crmRecord/CrmRecord'
import NotificationController from '@/primitives/NotificationController'

enum ConditionType {
  BOTH = 'both',
  TERMS = 'terms',
  PRIVACY = 'privacy',
}

const CRMNames = {
  [CRMProvider.SFDC]: 'Salesforce',
  [CRMProvider.HUBSPOT]: 'HubSpot',
  [CRMProvider.PIPEDRIVE]: 'Pipedrive',
  [CRMProvider.SFDC_SANDBOX]: 'Salesforce Sandbox',
}

const CRMAuthNames = {
  [CRMProvider.SFDC]: 'salesforce-oauth2',
  [CRMProvider.SFDC_SANDBOX]: 'salesforce-oauth2-sandbox',
  [CRMProvider.HUBSPOT]: 'hubspot',
  [CRMProvider.PIPEDRIVE]: 'pipedrive',
}

const ConditionData = {
  [ConditionType.BOTH]: {
    summary: 'Agree to terms of service & privacy',
    url: 'https://luru.app/tos',
    name: 'Terms of Service & Privacy',
  },
  [ConditionType.TERMS]: {
    summary: 'Agree to terms of service',
    url: 'https://luru.app/tos',
    name: 'Terms of Service',
  },
  [ConditionType.PRIVACY]: {
    summary: 'Agree to privacy policy',
    url: 'https://luru.app/privacy-policy',
    name: 'Privacy Policy',
  },
}

export const LoginScreen = () => {
  const [conditionAccepted, setConditionAccepted] = useState(true)
  const handleToggleAcceptance = (e: React.MouseEvent<HTMLElement>) => setConditionAccepted((x) => !x)

  const location = useLocation()
  const handleLogin = (crm: CRMProvider) => {
    if (!conditionAccepted) {
      alert('Please read & accept our terms of service and privacy policy')
      return
    }

    trackEvent('login_connect', crm)

    const urlParams = new URLSearchParams(location.search)
    var loginUrl = getAuthUrl() + '/login/' + CRMAuthNames[crm] + '/?next=/next'
    switch (LuruUser.getCurrentEntryPoint()) {
      case LuruEntryPoint.EMBEDDED_GMEET_NOTE:
        loginUrl += '/noop_close'
        BrowserUtils.openLoginWindow(loginUrl)

        break

      case LuruEntryPoint.WEBAPP:
      case LuruEntryPoint.EMBEDDED_ZOOM_MEETING:
      case LuruEntryPoint.EMBEDDED_ZOOM_PANEL:
        loginUrl += location.pathname.startsWith('/signin') ? urlParams.get('next') ?? '/' : location.pathname ?? '/'
        window.location.href = loginUrl

        break

      case LuruEntryPoint.NEWTAB: {
        let paths = location.pathname.split('/')
        let path = paths.length >= 2 && paths[1] !== 'pages' ? paths[1] : ''

        loginUrl +=
          window.location.protocol +
          '//' +
          window.location.host +
          '/pages/newtab/index.html?force=true%26redirect=/' +
          path
        window.location.href = loginUrl
        break
      }

      case LuruEntryPoint.GLOBALSEARCH:
        loginUrl +=
          window.location.protocol + '//' + window.location.host + '/pages/globalsearch/index.html?redirect=noop_close'
        BrowserUtils.openLoginWindow(loginUrl)
    }
  }

  return (
    <div className={styles.parent}>
      <NotificationController />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <img src={luruLogo} alt='Luru' />
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1>
              Welcome to the Zero Friction
              <em>Sales Productivity Workspace</em>
            </h1>
            <LoginButton crm={CRMProvider.SFDC} handleLogin={handleLogin} />
            <LoginButton crm={CRMProvider.HUBSPOT} handleLogin={handleLogin} />
            <LoginButton crm={CRMProvider.PIPEDRIVE} handleLogin={handleLogin} />
            <LoginButton crm={CRMProvider.SFDC_SANDBOX} handleLogin={handleLogin} />
            <h3>
              <img src={securityBadge} alt='Privacy & Security' />
              We do not store your {CrmRecord?.getCrmName?.() ?? 'CRM'} data
            </h3>
            <LoginCondition
              type={ConditionType.BOTH}
              status={conditionAccepted}
              handleToggleAcceptance={handleToggleAcceptance}
            />
            {/* <LoginCondition
              type={ConditionType.TERMS}
              status={termsAccepted}
              handleToggleAcceptance={handleToggleAcceptance}
            />
            <LoginCondition
              type={ConditionType.PRIVACY}
              status={privacyAccepted}
              handleToggleAcceptance={handleToggleAcceptance}
            /> */}
          </div>
          <div className={styles.right}>
            <img src={supergirlImage} alt='Luru' data-role='hero' />
            <h2>Luru is the fastest way to access and update your CRM. Get your superpower now</h2>
            <div className={styles.features}>
              <Feature name='Pipeline'>Lightning fast Pipeline updates</Feature>
              <Feature name='Automation'>Process automations on Slack</Feature>
              <Feature name='Notes'>Synced Notes</Feature>
              <Feature name='Global'>Access your {CrmRecord?.getCrmName?.() ?? 'CRM'} from anywhere</Feature>
              <Feature name='Productivity'>60% increase in productivity</Feature>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const LoginCondition = ({
  type,
  status,
  handleToggleAcceptance,
}: {
  type: ConditionType
  status: boolean
  handleToggleAcceptance: (e: React.MouseEvent<HTMLElement>) => void
}) => (
  <div className={styles.condition} data-type={type} onClick={handleToggleAcceptance}>
    <img src={status ? checkboxFilled : checkboxBlank} alt={ConditionData[type].summary} />
    <span>
      I agree to the &nbsp;
      <a href={ConditionData[type].url} title={ConditionData[type].name} target='_blank' rel='noreferrer'>
        {ConditionData[type].name}
      </a>
    </span>
  </div>
)

const Feature = ({ children, name }: { children: React.ReactNode; name: string }) => (
  <div className={styles.feature}>
    <img src={greenTick} alt={name} />
    {children}
  </div>
)

const LoginButton = ({ crm, handleLogin }: { crm: CRMProvider; handleLogin: (crm: CRMProvider) => void }) => (
  <button style={{ width: '320px' }} onClick={() => handleLogin(crm)}>
    Continue with {CRMNames[crm]}
  </button>
)

// https://auth.looru.io:10000/login/salesforce-oauth2/?next=/nextchrome-extension://blcfdiknaohhklnbgfpgkkkdgnahepno/pages/newtab/index.html?force=true%26redirect=/
// https://auth.looru.io:10000/login/salesforce-oauth2/?next=/nextchrome-extension://blcfdiknaohhklnbgfpgkkkdgnahepno/pages/newtab/index.html?force=true%26redirect=/notes
