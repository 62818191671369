import moment from 'moment'
import React from 'react'
import LuruDatePicker from '.'

interface LuruDatePickerEvents {
  onTextBoxKeyDown: React.KeyboardEventHandler<HTMLInputElement>
  onTextBoxChange: React.ChangeEventHandler<HTMLInputElement>
  onTextBoxFocus: React.FocusEventHandler<HTMLInputElement>
  onTextBoxBlur: React.FocusEventHandler<HTMLInputElement>
  onGoToNextMonth: React.MouseEventHandler<HTMLButtonElement>
  onGoToPrevMonth: React.MouseEventHandler<HTMLButtonElement>
  onGoToNextYear: React.MouseEventHandler<HTMLButtonElement>
  onGoToPrevYear: React.MouseEventHandler<HTMLButtonElement>
  onGoToYearRange: (e: React.MouseEvent<HTMLButtonElement>, yearRangeBegin: number) => void
  onClearDate: React.MouseEventHandler<HTMLButtonElement>
  onChooseToday: React.MouseEventHandler<HTMLButtonElement>
  onChooseTodayWithoutApplying: React.MouseEventHandler<HTMLButtonElement>
  onBrowseMonths: React.MouseEventHandler<HTMLElement>
  onBrowseYears: React.MouseEventHandler<HTMLElement>
  onClickMonth: (e: React.MouseEvent<HTMLElement>, month: number) => void
  onClickYear: (e: React.MouseEvent<HTMLElement>, year: number) => void
  onHideCalendarMenu: () => void
}

export default class LuruDatePickerEventHandler {
  #component: LuruDatePicker
  handlers: LuruDatePickerEvents
  #blurredApplyTimerID: NodeJS.Timeout | null = null
  #blurredApplyDelay: number = 100

  constructor(component: LuruDatePicker) {
    this.#component = component
    this.handlers = {
      onTextBoxKeyDown: this.#onTextBoxKeyDown.bind(this),
      onTextBoxChange: this.#onTextBoxChange.bind(this),
      onTextBoxFocus: this.#onTextBoxFocus.bind(this),
      onTextBoxBlur: this.#onTextBoxBlur.bind(this),
      onGoToNextMonth: this.#onGoToNextMonth.bind(this),
      onGoToPrevMonth: this.#onGoToPrevMonth.bind(this),
      onGoToNextYear: this.#onGoToNextYear.bind(this),
      onGoToPrevYear: this.#onGoToPrevYear.bind(this),
      onGoToYearRange: this.#onGoToYearRange.bind(this),
      onClearDate: this.#onClearDate.bind(this),
      onChooseToday: this.#onChooseToday.bind(this),
      onChooseTodayWithoutApplying: this.#onChooseTodayWithoutApplying,
      onBrowseMonths: this.#onBrowseMonths.bind(this),
      onBrowseYears: this.#onBrowseYears.bind(this),
      onClickMonth: this.#onClickMonth.bind(this),
      onClickYear: this.#onClickYear.bind(this),
      onHideCalendarMenu: this.#onHideCalendarMenu.bind(this),
    }
    moment.suppressDeprecationWarnings = true
  }

  #cancelBlurredApply() {
    if (this.#blurredApplyTimerID) {
      clearTimeout(this.#blurredApplyTimerID)
      this.#blurredApplyTimerID = null
    }
  }

  #onTextBoxKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    event.stopPropagation()
    var textBox = event.currentTarget

    if (event.key === 'Escape') {
      this.#component.setState(this.#component.getOriginalState(), () => {
        textBox.blur()
        this.#component.componentRefs.popupMenu.current?.hideMenu()
      })
    } else if (event.key === 'Enter' || event.key === 'Tab') {
      textBox.blur()
      this.#component.componentRefs.popupMenu.current?.hideMenu()
    }
  }

  #onTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      var dateText = event.target.value
      var dateObj = moment(dateText)

      if (!dateObj.isValid()) {
        let [a, b, c] = event.target.value.split(/[/\-.]/)
        dateObj = moment([b, a, c].filter((x) => x).join('-'))
      }

      if (
        dateObj.year() === 2001 &&
        !dateText.startsWith('2001') &&
        !dateText.endsWith('2001') &&
        !dateText.match(/[/\-.]01$/)
      ) {
        dateObj.set('year', new Date().getFullYear())
      }

      if (dateObj.isValid()) {
        this.#component.setState({
          stringValue: event.target.value,
          date: dateObj.date(),
          month: dateObj.month(),
          year: dateObj.year(),
        })
      } else {
        throw new Error('Invalid date')
      }
    } catch (error) {
      this.#component.setState({ stringValue: event.target.value })
      this.#component.props.onValuePicked?.(null)
    }
  }

  #onTextBoxFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.currentTarget?.setSelectionRange(0, event.currentTarget?.value.length)
  }

  #onTextBoxBlur(event: React.FocusEvent<HTMLInputElement>) {
    this.#cancelBlurredApply()
    // this.#blurredApplyTimerID = setTimeout(() => {
    //   this.#applyCurrentDate()
    // }, this.#blurredApplyDelay)
  }

  #onGoToNextMonth(event: React.MouseEvent<HTMLButtonElement>) {
    var dateValue = this.#component.getValue() ?? new Date()
    var date = 1
    var month = dateValue.getMonth() + 1
    var year = dateValue.getFullYear()

    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({
      date,
      month,
      year,
      stringValue: this.#component.getFormattedValue(year, month, date),
    })
  }

  #onGoToPrevMonth(event: React.MouseEvent<HTMLButtonElement>) {
    var dateValue = this.#component.getValue() ?? new Date()
    var date = 1
    var month = dateValue.getMonth() - 1
    var year = dateValue.getFullYear()

    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({
      date,
      month,
      year,
      stringValue: this.#component.getFormattedValue(year, month, date),
    })
  }

  #onGoToNextYear(event: React.MouseEvent<HTMLButtonElement>) {
    var dateValue = this.#component.getValue() ?? new Date()
    var date = 1
    var month = dateValue.getMonth()
    var year = dateValue.getFullYear() + 1

    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({
      date,
      month,
      year,
      stringValue: this.#component.getFormattedValue(year, month, date),
    })
  }

  #onGoToPrevYear(event: React.MouseEvent<HTMLButtonElement>) {
    var dateValue = this.#component.getValue() ?? new Date()
    var date = 1
    var month = dateValue.getMonth()
    var year = dateValue.getFullYear() - 1

    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({
      date,
      month,
      year,
      stringValue: this.#component.getFormattedValue(year, month, date),
    })
  }

  #onClearDate(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState(
      {
        date: undefined,
        month: undefined,
        year: undefined,
        stringValue: '',
      },
      () => {
        this.#applyCurrentDate()
        this.#component.componentRefs.popupMenu.current?.hideMenu()
      }
    )
  }

  #onChooseTodayWithoutApplying = (event: React.MouseEvent<HTMLButtonElement>) => {
    var today = new Date()
    var date = today.getDate()
    var month = today.getMonth()
    var year = today.getFullYear()

    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({
      date,
      month,
      year,
      stringValue: this.#component.getFormattedValue(year, month, date),
      displayState: 'calendar',
    })
  }

  #onChooseToday(event: React.MouseEvent<HTMLButtonElement>) {
    var today = new Date()
    var date = today.getDate()
    var month = today.getMonth()
    var year = today.getFullYear()

    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState(
      {
        date,
        month,
        year,
        stringValue: this.#component.getFormattedValue(year, month, date),
      },
      () => {
        this.#applyCurrentDate()
        this.#component.componentRefs.popupMenu.current?.hideMenu()
      }
    )
  }

  #applyCurrentDate() {
    if (this.#component.props.readOnly) {
      return
    }

    try {
      if (
        this.#component.state.year === undefined ||
        this.#component.state.month === undefined ||
        this.#component.state.date === undefined
      ) {
        throw new Error('Empty date')
      }

      var dateValue = new Date(this.#component.state.year, this.#component.state.month, this.#component.state.date)
      var formattedDate = dateValue.toLocaleString('default', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })

      const originalDateState = this.#component.getOriginalState()
      if (formattedDate === originalDateState.stringValue) {
        // Nothing changed return
        return
      }

      this.#component.setState({
        stringValue: formattedDate,
      })

      this.#component.props.onValuePicked?.(dateValue.toISOString())
    } catch (e) {
      this.#component.setState({
        stringValue: '',
        date: undefined,
        month: undefined,
        year: undefined,
      })
      this.#component.props.onValuePicked?.(null)
    }
  }

  chooseDate(event: React.MouseEvent<HTMLElement>, date: number, month: number, year: number) {
    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({ date, month, year }, () => {
      this.#applyCurrentDate()
      this.#component.componentRefs.popupMenu.current?.hideMenu?.()
    })
  }

  #onClickMonth(event: React.MouseEvent<HTMLElement>, month: number) {
    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({
      month,
      displayState: 'calendar',
      stringValue: this.#component.getFormattedValue(this.#component.state.year, month),
    })
  }

  #onClickYear(event: React.MouseEvent<HTMLElement>, year: number) {
    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({
      year,
      yearRangeBegin: undefined,
      displayState: 'month_list',
      stringValue: this.#component.getFormattedValue(year),
    })
  }

  #onBrowseYears(event: React.MouseEvent<HTMLSpanElement>) {
    event.preventDefault()
    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({ displayState: 'year_list' })
  }

  #onBrowseMonths(event: React.MouseEvent<HTMLSpanElement>) {
    event.preventDefault()
    event.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({ displayState: 'month_list' })
  }

  #onGoToYearRange(e: React.MouseEvent<HTMLButtonElement>, yearRangeBegin: number) {
    e.stopPropagation()
    this.#cancelBlurredApply()
    this.#component.setState({ yearRangeBegin })
  }

  #onHideCalendarMenu() {
    this.#component.resetState()
  }
}
