import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxAppState } from '../../app/types'
// import LuruUser from '../../../domain/users/LuruUser'
import { ReduxMeetingsState } from '../../meetings/types'
import { ReduxNotesState } from '../../notes/types'
import { ReduxNoteTemplatesState } from '../../noteTemplates/types'
import { ReduxTasksState } from '../../tasks/types'
import { ReduxUserState } from '../types'

export interface SignInUserPayload {
  app: ReduxAppState
  meetings: ReduxMeetingsState
  notes: ReduxNotesState
  noteTemplates: ReduxNoteTemplatesState
  tasks: ReduxTasksState
  user: ReduxUserState
}

export const signInUser: CaseReducer<
  ReduxUserState,
  PayloadAction<SignInUserPayload>
> = (state, action) => {
  // console.log(`userSlice:signInUser:entry`)
  // console.log(`userSlice:signInUser:${LuruUser.getCurrentEntryPoint()}:entry`)
  // console.log(
  //   `userSlice:signInUser:action:signIn signatures:`,
  //   action.payload?.user?.extensionSettings?.signInTimeStamp
  // )

  // Prevent duplicate sign-in actions
  // However, honor requests with non-undefined refreshStateTimeStamp
  if (
    action.payload?.user?.extensionSettings?.signInTimeStamp ===
      state?.extensionSettings?.signInTimeStamp &&
    action.payload?.user?.extensionSettings?.refreshStateTimeStamp === undefined
  ) {
    // console.log(`userSlice:signInUser:Received duplicate signIn action; ignore`)
    return
    // } else {
    //   console.log(
    //     'userSlice:signInUser:Received refreshStateTimeStamp:',
    //     action.payload?.user?.extensionSettings?.refreshStateTimeStamp
    //   )
  }

  Object.assign(state, {
    ...(action.payload?.user ?? {}),
    data: {
      ...(action.payload?.user?.data ?? {}),
      userSettings: {
        ...(action.payload?.user?.data?.userSettings ?? {}),
      },
    },
    extensionSettings: {
      ...(action.payload?.user?.extensionSettings ?? {}),
    },
    applicationStatus: {},
  })
}
