import { EntityStatus } from '../../../app/types'
import { ReduxNoteEntity, ReduxNotesState } from '../types'

export const addMeetingNotes = (
  state: ReduxNotesState,
  meetingList: Array<{ notes: Array<ReduxNoteEntity> }>
) => {
  if (!meetingList) {
    return
  }

  meetingList
    .filter((meeting) => Boolean(meeting.notes))
    .forEach((meeting) =>
      meeting.notes.forEach((note) => {
        if (!state.entities[note.note_id]) {
          state.entities[note.note_id] = {
            data: note,
            status: EntityStatus.Loaded,
          }
        }
      })
    )
}
