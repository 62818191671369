import LuruMultiSelectBox from '.'

interface LuruMultiSelectBoxEvents {
  onFinishSelection: (arg: Array<string>) => void
  onCancel: () => void
  onShowMenu: () => void
  onRemoveItem: (name: string, e?: any) => void
}

export default class LuruMultiSelectBoxEventHandler {
  #component: LuruMultiSelectBox
  handlers: LuruMultiSelectBoxEvents

  constructor(component: LuruMultiSelectBox) {
    this.#component = component

    this.handlers = {
      onFinishSelection: this.#onFinishSelection,
      onCancel: this.#onCancel,
      onShowMenu: this.#onShowMenu,
      onRemoveItem: this.#onRemoveItem,
    }

    this.#component.componentDidMount = this.#onComponentMount.bind(this)
  }

  #onComponentMount() {
    // Auto focus logic
    if (this.#component.props.autoFocus) {
      this.#component.componentRefs.popupButton.current?.showMenu?.()
    }
  }

  #onFinishSelection = (arg: Array<string>) => {
    this.#component.setState({
      ...this.#component.state,
      selectedItems: this.#component.props.items.filter((item) => arg.includes(item.key)),
    })

    this.#component.props.onFinishSelection(arg)
  }

  #onCancel = () => {
    this.#component.componentRefs.popupButton.current?.hideMenu()
    this.#component.props.onCancel()
  }

  #onShowMenu = () => {
    this.#component.componentRefs.luruMultiSelectPopup?.current?.focusFilterBox?.()
  }

  #onRemoveItem = (key: string, e: any) => {
    alert(key)
    e?.stopPropagation?.()
    e?.preventDefault?.()
    const newSelectedItems = this.#component.state.selectedItems.filter((f) => f.key !== key)
    const arg = newSelectedItems.map((f) => f.key)
    this.#component.setState({
      selectedItems: newSelectedItems,
    })
    this.#component.props.onFinishSelection(arg)
  }
}
