import { JottingType } from '../../features/notes/types'

/**
 * @classdesc Class to handle updating a CRM field - Currently only handling
 * the checkboxes as other fields are handled by selection handler
 */
export default class CRMFieldUpdateHandler {
  // Computations

  /**
   * Compute if and how CRM field update event is to be handled
   * @param {EditorDOM} view - Instance of EditorDOM where note is hosted
   * @param {Array} eventStream - Array of luruEvents generated by an instance
   * of EditorEventsManager
   */
  computeHandling(view, eventStream) {
    let lastEvent = eventStream[eventStream.length - 1]

    if (
      lastEvent?.sourceEvent?.type !== 'click' ||
      view?.getJottingType(lastEvent.data.jottingElement) !==
        JottingType.CRM_FIELD_VALUE
    ) {
      return null
    }
    return null
    // return this.#computeCrmFieldUpdateHandler(view, lastEvent)
  }

  /**
   * Compute handler for handling CRM field value checkbox clicks
   * @param {EditorDOM} view - Instance of EditorDOM hosting the note
   * @param {Object} clickEvent - Luru event
   * @returns {Object} - Of type { do, preventDefault }
   */
  #computeCrmFieldUpdateHandler(view, clickEvent) {
    let jottingElement = clickEvent.data.jottingElement

    return {
      do: (view, controller) => {
        let checkboxes = view?.getCheckboxes?.(clickEvent.data.jottingElement)
        let jottingCrmData = view?.getCrmJottingData?.(jottingElement)
        let newValue = Array.from(checkboxes)
          .filter((box) => box.checked)
          .map((box) => box.value)
          .join(';')

        controller.onCrmFieldUpdated(
          jottingCrmData.data,
          newValue,
          jottingElement
        )
      },
      dirtyFlag: true,
    }
  }
}
