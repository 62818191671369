import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { NoteTemplateFilter, ReduxNoteTemplatesState } from '../types'

export const updateFilter: CaseReducer<
  ReduxNoteTemplatesState,
  PayloadAction<{ noteTemplateId: string; filter: NoteTemplateFilter }>
> = (state, action) => {
  var { noteTemplateId, filter } = action.payload

  if (state.entities[noteTemplateId]?.data) {
    state.entities[noteTemplateId].data!.filter = {
      ...state.entities[noteTemplateId].data?.filter,
      ...filter,
    }
  }
}
