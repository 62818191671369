import { useAppSelector } from '@/app/hooks'
import { CRMProvider } from '@/features/user/types'

export default function useCrmName() {
  return useAppSelector((state) => state.user?.data?.userSettings?.connectedCRM?.name as CRMProvider | undefined)
}

export function useCrmCommonName() {
  const crmName = useCrmName()

  if (crmName === CRMProvider.SFDC || crmName === CRMProvider.SFDC_SANDBOX) {
    return 'Salesforce'
  }

  if (crmName === CRMProvider.HUBSPOT) {
    return 'HubSpot'
  }

  if (crmName === CRMProvider.PIPEDRIVE) {
    return 'Pipedrive'
  }

  return 'CRM'
}
