import { RootState } from '../../app/store'

// Selectors
export const UserSliceSelectors = {
  getUserRecentItems(state: RootState) {
    return state.user.recentItems
  },

  getUserFirstName(state: RootState) {
    return state.user.data.firstName
  },

  getUserSignInStatus(state: RootState) {
    return state.user.status
  },

  getSignedInState(state: RootState) {
    return state.user.status
  },

  getUserSettings(state: RootState) {
    return state.user.data.userSettings
  },

  getApplicationError(state: RootState) {
    return state.user?.applicationStatus?.errorCode
  },

  getUserId(state: RootState) {
    return state.user?.data.user_id
  },

  getTenantId(state: RootState) {
    return state.user?.data.tenant_id
  },
}
