import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { SearchTasksParameter } from '../middleware/listOrSearchTasks'

export const listOrSearchTasks = (
  params: Readonly<SearchTasksParameter>,
  options: LuruAPIRequestOptions
) => {
  var urlParams: Partial<SearchTasksParameter> = { ...params }

  for (let key in params) {
    let typedKey = key as keyof SearchTasksParameter

    if (params[typedKey] === undefined || params[typedKey] === null) {
      delete urlParams[typedKey]
    } else if (typedKey === 'q') {
      urlParams.q = urlParams.q?.trim()
    }
  }

  delete urlParams.loadMore
  delete urlParams.key

  var request = new LuruAPIRequest(
    '/api/tasks?' + new URLSearchParams(urlParams as URLSearchParams),
    HTTPMethod.GET,
    options
  )

  return request.make()
}
