import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { NotesMiddleware } from '../../notes/middleware'
import { ReduxAppState } from '../types'

export const notesSliceDeleteNote = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    notesSliceDeleteNote.addFulfilledCase(builder)
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      NotesMiddleware.deleteNote.action.fulfilled,
      (state, action) => {
        try {
          const noteId = action.meta.arg.noteId
          type tier1Key = 'search' | 'default'
          type tier2Key = 'sorNotes' | 'luruNotes'

          if (state.home.notes?.lastLoadedNoteId === noteId) {
            state.home.notes.lastLoadedNoteId = null
          }

          for (let i in ['search', 'default']) {
            for (let j in ['sorNotes', 'luruNotes']) {
              let t1: tier1Key = i as tier1Key
              let t2: tier2Key = j as tier2Key

              if (state.home[t1]?.[t2]?.results instanceof Array) {
                state.home[t1][t2].results = state.home[t1][t2].results.filter(
                  (item) => item !== noteId
                )
              }
            }
          }
        } catch (e) {
          console.warn(e)
        }
      }
    )
  },
}
