import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxCollectionsState } from '../types'

export const setSortNumber: CaseReducer<
  ReduxCollectionsState,
  PayloadAction<{ collectionId: string; sortNumber: number }>
> = (state, action) => {
  var { collectionId, sortNumber } = action.payload

  if (!state.entities[collectionId]) {
    return
  }

  state.entities[collectionId].sort_number = sortNumber
}
