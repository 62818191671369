import json5 from 'json5'
import CreateCrmRecordDialogComponent, { FieldsetSelection } from './CreateCrmRecordDialogComponent'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { LuruReduxStore } from '../../../../app/store'
import { UserSliceActions } from '../../../../features/user/userSlice'
import { RecentEntityType } from '../../../../features/user/types'
import { trackEvent } from '../../../../analytics/Ga'
import LuruUser from '../../../../domain/users/LuruUser'
import update from 'immutability-helper'
import LuruError from '../../../../features/LuruError'
import { ToastId } from '@/app_ui/types'

interface CreateCrmRecordDialogEvents {
  onClickCreateButton: () => void
  onClickCancelButton: () => void
  loadSchema: () => Promise<void>
  onChooseFields: (fieldNames: Array<string>) => void
  onChooseCollection: (collectionId?: string) => void
  onFieldValueChange: (fieldName: string, value: any) => void
  onDefaultCollectionLoaded: (fields?: Array<string>) => void
  onChooseDefaultCollection: (fields?: Array<string>) => void
}

export default class CreateCrmRecordDialogEventHandler {
  #component: CreateCrmRecordDialogComponent
  handlers: CreateCrmRecordDialogEvents

  constructor(component: CreateCrmRecordDialogComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount
    this.handlers = {
      onClickCancelButton: this.#onClickCancelButton,
      onClickCreateButton: this.#onClickCreateButton,
      onChooseFields: this.#onChooseFields,
      loadSchema: this.#loadSchema,
      onChooseCollection: this.#onChooseCollection,
      onFieldValueChange: this.#onFieldValueChange,
      onDefaultCollectionLoaded: this.#onDefaultCollectionLoaded,
      onChooseDefaultCollection: this.#onChooseDefaultCollection,
    }
  }

  #onComponentMount = async () => await this.#loadSchema()

  #loadSchema = async () => {
    if (this.#component.state.fieldNamesStatus === EntityStatus.NotLoaded) {
      this.#component.setState({ fieldNamesStatus: EntityStatus.Loading })

      try {
        let schema = await CrmRecord.getObjectSchema(this.#component.props.crmRecordType)
        this.#component.setSchema(json5.parse(json5.stringify(schema)))
      } catch (e) {
        this.#component.setState({
          fieldNamesStatus: EntityStatus.ErrorLoading,
        })
        console.warn(e)
      }
    }
  }

  #onClickCreateButton = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    e?.stopPropagation()
    var nameField = this.#component.getSchema()?.payload?.fields?.find((f) => f.nameField)?.name
    var recordName = nameField ? this.#component.state.fieldValues[nameField] : undefined
    var objectName = CrmRecord.getCrmRecordName(this.#component.props.crmRecordType)
    try {
      if (this.#component.state.fieldValues !== undefined) {
        let filteredValues: { [_: string]: any } = {}
        let missedMandatoryFields = []

        for (let fieldName in this.#component.state.fieldValues) {
          const fieldValue =
            typeof this.#component.state.fieldValues[fieldName] === 'string'
              ? this.#component.state.fieldValues[fieldName].trim?.()
              : this.#component.state.fieldValues[fieldName]
          if (
            this.#component.getSchema()?.payload?.fields?.find((f) => f.name === fieldName) &&
            fieldValue &&
            fieldValue !== null &&
            fieldValue !== undefined &&
            !this.#component.state.readOnlyFields.includes(fieldName)
          ) {
            filteredValues[fieldName] = fieldValue
          } else {
            if (this.#component.state.mandatoryFields?.includes(fieldName)) {
              let fieldSchema = this.#component.getSchema()?.payload?.fields?.find((f) => f.name === fieldName)

              // this check is for lead in PD for now.
              // In future we can use this for any CRM and any field schema
              // we just need to pass the option_if_present in field schema from BE
              if (fieldSchema?.optional_if_present) {
                if (this.#component.state.fieldValues[fieldSchema?.optional_if_present ?? '']) {
                  continue
                }
              } else {
                missedMandatoryFields.push(fieldName)
              }
            }
          }
        }

        // Bail out without making an API call when all mandatory fields are not filled
        if (missedMandatoryFields.length > 0) {
          this.#component.setState({
            createRecordStatus: EntityStatus.Idle,
            alertMessage: 'Please enter all mandatory fields (marked with *)',
            missedMandatoryFields,
          })
          return
        }

        this.#component.setState({
          alertMessage: undefined,
          createRecordStatus: EntityStatus.Creating,
          missedMandatoryFields,
        })

        let createResponse = await CrmRecord.createRecord({
          crmRecordType: this.#component.props.crmRecordType,
          fields: filteredValues,
        })
        let recordId = createResponse?.payload?.data?.id

        // Mark object as a recent item
        if (recordId && recordName) {
          LuruReduxStore.dispatch(
            UserSliceActions.addRecentItem({
              id: recordId,
              name: recordName,
              type: this.#component.props.crmRecordType as unknown as RecentEntityType,
            })
          )
        }

        // Need not set createRecordState to Created here, as we are immediately
        // going to call onClickCancelButton() before exiting this function
        this.#component.props.toast.showToast({
          id: ToastId.CREATE_CRM_RECORD_DAILOG_TOAST_ID,
          message: `Created ${objectName} '${recordName ?? ''}' successfully`,
          severity: 'success',
        })
        this.#onClickCancelButton()
        trackEvent('create_record', `${LuruUser.getCurrentUserCrmName()}-${objectName}-${recordName}`)
        createResponse && this.#component.state?.linkRecordAfterCreation?.(createResponse)
      }
    } catch (e) {
      if (e instanceof LuruError) {
        let alertMessage = e.message
        let errorFields = e.toErrorValue().additional_data?.error_fields

        this.#component.setState((prevState) =>
          update(prevState, {
            createRecordStatus: { $set: EntityStatus.ErrorCreating },
            alertMessage: { $set: alertMessage },
            errorFields: { $set: errorFields },
          })
        )
      } else {
        let err = e as Error
        let alertMessage = recordName
          ? `Error creating ${objectName} '${recordName}'. ${err.message}`
          : `Error creating ${objectName}. ${err.message}`

        this.#component.setState((prevState) =>
          update(prevState, {
            createRecordStatus: { $set: EntityStatus.ErrorCreating },
            alertMessage: { $set: alertMessage },
          })
        )
      }
    }
  }

  #onClickCancelButton = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    e?.stopPropagation()
    this.#component.setState({
      missedMandatoryFields: [],
      readOnlyFields: [],
      createRecordStatus: EntityStatus.Idle,
      selectionMode: FieldsetSelection.DEFAULT,
      alertMessage: undefined,
      fieldValues: Object.keys(this.#component.state.fieldValues || {})?.reduce(
        (prev, key) => ({ ...prev, [key]: null }),
        {}
      ),
      errorFields: undefined,
    })
    this.#component.componentRefs.modal.current?.cancel()
  }

  #onChooseFields = (fieldNames: Array<string>) => {
    this.#component.setState({
      selectionMode: FieldsetSelection.MANUAL,
      fieldNames: this.getCreateFieldNames(fieldNames),
    })
    // Whenever new fields choosen remove the collection Selection
    this.#component.componentRefs.luruCollectionsQuickAccessBarRef.current?.setState({
      selectedCollectionId: undefined,
    })
  }

  #onChooseCollection = async (collectionId?: string) => {
    var collection = this.#component.props.collections.entities?.[collectionId || '']?.data
    if (collection) {
      this.#component.setState({
        selectionMode: FieldsetSelection.COLLECTION,
        fieldNames: this.getCreateFieldNames(collection?.fields || []),
      })
      trackEvent('use_collection', 'CreateCrmRecordDialog')
    }
  }

  #onFieldValueChange = (fieldName: string, fieldValue: any) => this.#component.setFieldValue(fieldName, fieldValue)

  #onDefaultCollectionLoaded = (fields?: Array<string>) => {
    if (fields) {
      this.#component.setState({ fieldNames: this.getCreateFieldNames(fields) })
    }
  }

  #onChooseDefaultCollection = (fields?: Array<string>) => {
    if (fields) {
      this.#component.setState({
        selectionMode: FieldsetSelection.DEFAULT,
        fieldNames: this.getCreateFieldNames(fields),
      })
    }
  }

  getCreateFieldNames(additionalFieldNames: string[] = []) {
    // Union of mandatoryFields & given field names
    const { mandatoryFields } = this.#component.state
    var fields = [...new Set([...(mandatoryFields ?? []), ...additionalFieldNames])]
    if (fields.length === 0) {
      fields = this.#component.getSchema()?.payload?.fields?.map((f) => f.name) ?? []
    }
    return fields
  }
}
