import React from 'react'

// Styles
import styles from './css/OvalButton.module.css'
import loadingStyles from './css/LoadingAnimation.module.css'
import { FocusEventHandler, MouseEventHandler } from 'react'

export interface OvalButtonProps {
  id?: string
  title?: string
  classes?: Array<string>
  subdued?: boolean
  centered?: boolean
  disabled?: boolean
  loading?: boolean
  transparent?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  onFocus?: FocusEventHandler<HTMLButtonElement>
  onMouseDown?: MouseEventHandler<HTMLButtonElement>
  children?: any
  blueVarient?: boolean
  lightBlueVarient?: boolean
}
/**
 * Opinionated, oval button to use it easily across app
 * - Has a lively effect on hover and on click
 * - Calls the onClick handler passed to it
 * - Renders the children as is inside it
 * - Can be asked to present in a 'subdued' state
 * - Customizations can be overridden by targeting the button element of the
 *   containing parent
 * @param {object} props { onClick }
 * @returns JSX of the OvalButton element
 */
export default class OvalButton extends React.Component<OvalButtonProps> {
  props: OvalButtonProps

  constructor(props: OvalButtonProps) {
    super(props)
    this.props = props
  }

  render() {
    var classNames = [styles.main]

    if (this.props.subdued) {
      classNames.push(styles.subdued)
    }
    if (this.props.centered) {
      classNames.push(styles.centered)
    }
    if (this.props.classes) {
      classNames = classNames.concat(this.props.classes)
    }
    if (this.props.transparent) {
      classNames.push(styles.transparent)
    }
    if (this.props.blueVarient) {
      classNames.push(styles.blueVarient)
    }
    if (this.props.lightBlueVarient) {
      classNames.push(styles.lightBlueVarient)
    }

    return (
      <button
        type='button'
        className={classNames.join(' ')}
        onClick={this.props.onClick ?? undefined}
        onFocus={this.props.onFocus ?? undefined}
        onMouseDown={this.props.onMouseDown ?? undefined}
        title={this.props.title?.toString() ?? ''}
        disabled={'disabled' in this.props ? this.props.disabled : false}
      >
        {this.props.children}
        {this.props.loading ? (
          <span className={styles.pushRight}>
            <span className={[loadingStyles.loading, styles.spinner].join(' ')}> </span>
          </span>
        ) : (
          ''
        )}
      </button>
    )
  }
}
