import LuruCollectionsQuickAccessBarComponent, {
  LuruCollectionsQuickAccessBarProps,
} from './LuruCollectionsQuickAccessBarComponent'
import LuruUser from '../../../../domain/users/LuruUser'
import { LuruReduxStore } from '../../../../app/store'
import { EntityStatus } from '../../../../app/types'
import { CollectionsMiddleware } from '../../../../features/collections/middleware'

interface LuruCollectionsQuickAccessBarEvents {
  // Add required event handler methods here
  onFetchInitialCollections: () => void
  onChooseCollection: (collectionId?: string) => void
}

export default class LuruCollectionsQuickAccessBarEventHandler {
  handlers: LuruCollectionsQuickAccessBarEvents
  #component: LuruCollectionsQuickAccessBarComponent

  constructor(component: LuruCollectionsQuickAccessBarComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount.bind(this)
    this.#component.componentDidUpdate = this.#componentDidUpdate.bind(this)
    this.#component.componentWillUnmount = this.#onComponentUnmount.bind(this)

    this.handlers = {
      // Add event handler methods to this class and assemble them into this
      // object and bind them to this object as well
      // onChooseCollection: this.#onChooseCollection.bind(this),
      onFetchInitialCollections: this.#onFetchInitialCollections.bind(this),
      onChooseCollection: this.#onChooseCollection.bind(this),
    }
  }

  #onFetchInitialCollections() {
    var crmId = LuruUser.getCurrentUserCrmName()?.toUpperCase()

    if (!crmId) {
      return
    }

    LuruReduxStore.dispatch(CollectionsMiddleware.listCollections.action({})).catch((err) => {})
  }

  #onChooseCollection(collectionId?: string) {
    if (!collectionId) {
      console.error(
        'LuruCollectionsQuickAccessBarEventHandler:onChooseCollection:Unexpected collectionId:',
        collectionId
      )

      return
    }

    var { collections } = this.#component.props
    var { recentCollections } = this.#component.state
    var collection = Object.values(collections).find((f) => f?.collection_id === collectionId)

    // If selected from dropdown, Add this collection to the front of list
    if (!recentCollections?.find?.((c) => c.collection_id === collectionId) && collection) {
      recentCollections?.unshift(collection)
      // Limit the no. of collections to 4
      if (recentCollections && recentCollections?.length > 4) {
        recentCollections = recentCollections.slice(0, 4)
      }
    }

    this.#component.setState({
      selectedCollectionId: collectionId,
      recentCollections: recentCollections,
    })

    this.#component.props.onChooseCollection?.(collectionId, collection ?? undefined)

    LuruReduxStore.dispatch(CollectionsMiddleware.useCollection.action({ collectionId }))
      .unwrap()
      .catch((e) => {
        console.warn('Error making collection as used', e)
      })
  }

  #onComponentMount() {
    // Add required onMount behavior here
    if (this.#component.props.collectionsNotLoaded) {
      this.#onFetchInitialCollections()
    } else {
      const recentCollection = Object.values(this.#component.props.recentCollections)?.[0]

      this.#component.props.onCollectionLoaded?.(recentCollection?.collection_id)
      this.#component.setState({
        selectedCollectionId: this.#component.props.useRecentUsedCollectionAsDefaultSelected
          ? recentCollection?.collection_id
          : undefined,
        recentCollections: this.#component.props.recentCollections,
      })
    }
  }

  #componentDidUpdate(prevProps: LuruCollectionsQuickAccessBarProps) {
    if (
      prevProps.collectionsStatus === EntityStatus.Loading &&
      this.#component.props.collectionsStatus === EntityStatus.Loaded
    ) {
      const recentCollection = Object.values(this.#component.props.recentCollections)?.[0]

      this.#component.props.onCollectionLoaded?.(recentCollection?.collection_id)

      this.#component.setState({
        selectedCollectionId: this.#component.props.useRecentUsedCollectionAsDefaultSelected
          ? recentCollection?.collection_id
          : undefined,
        recentCollections: this.#component.props.recentCollections,
      })
    }
  }

  #onComponentUnmount() {
    // Add required onUnmount behavior here
  }
}
