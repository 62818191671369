import { createRoot } from 'react-dom/client'

// Third party libraries
import json5 from 'json5'

// Own libaries and components
import { JottingType } from '../features/notes/types'
import EditorCrmFieldView from './views/EditorCrmFieldView'
import { LuruFieldType } from '../domain/crmRecord/typings.d'
import { EditorEntityType } from './EditorController'
import EditorFormatter from './views/EditorFormatter'
import { NodeType } from './utils/DomUtils'
import EditorTaskFieldView from './views/EditorTaskFieldView'

// Styles
import styles from '../routes/notes/css/NotesEditor.module.css'
import CrmRecord from '../domain/crmRecord/CrmRecord'
import { EntityStatus } from '../app/types'
import { EmbeddedCollection } from './components/EmbeddedCollection'
import { Provider } from 'react-redux'
import { LuruReduxStore } from '../app/store'
import { EmbeddedCrmField } from './components/EmbeddedCrmField'
// import LuruUser from '../domain/users/LuruUser'
// import { CRMProvider } from '../features/user/types'

/**
 * @classdesc View class of editor
 */
export default class EditorDOM {
  #config = null
  #isSetup = false
  #numTabstops = 6
  #crmFieldView = null
  #taskFieldView = null
  #formatter = null

  // Link element selector inside a Luru note
  static LURU_LINK_SELECTOR = 'a[data-luru-role="note-hyperlink"]'

  // Jotting types that have a prefix
  static PREFIXED_JOTTING_TYPES = [
    JottingType.UL1,
    JottingType.UL2,
    JottingType.UL3,
    JottingType.OL1,
    JottingType.OL2,
    JottingType.OL3,
    JottingType.TASK_COMPLETE,
    JottingType.TASK_INCOMPLETE,
    JottingType.Q,
    JottingType.A_UL1,
    JottingType.A_UL2,
    JottingType.A_UL3,
    JottingType.A_OL1,
    JottingType.A_OL2,
    JottingType.A_OL3,
    JottingType.A_TASK_COMPLETE,
    JottingType.A_TASK_INCOMPLETE,
  ]

  /**
   * Initialize EditorDOM object and its internal data structures
   * @param {Object} config - of the following format:
   * { noteId, noteBody, editorContainer }
   */
  constructor(config) {
    this.#config = config
  }

  // Actions
  /**
   * Setup editor
   */
  setup() {
    this.#crmFieldView = new EditorCrmFieldView()
    this.#taskFieldView = new EditorTaskFieldView()
    this.#removeExistingDOMStructure()
    this.#addNoteDOMStructure()
    this.#isSetup = true
    this.#renderReactComponents()
    this.#setupFormatter()
  }

  #setupFormatter() {
    this.#formatter = new EditorFormatter(this.#config, this)
    this.#config?.floatingFormattingMenu?.current?.setFormatter(this.#formatter)
  }

  #renderReactComponents(specificJottingElement = null, reRender = false) {
    try {
      const jottingElementList = this.getJottingElementsList()

      if (!jottingElementList) {
        return
      }

      const jottingElements =
        specificJottingElement === null ? Array.from(jottingElementList) : [specificJottingElement]

      jottingElements.forEach((e) => {
        let host = e.querySelector(EditorCrmFieldView.ReactRootSelector)

        if (!host) {
          host = e.querySelector(EditorTaskFieldView.ReactRootSelector)
        }

        if (host) {
          let component = host.luruObjects?.reactComponent

          if (reRender && host.luruObjects?.reRender instanceof Function) {
            component = host.luruObjects?.reRender()
          }

          let root = createRoot(host)
          root.render(component)
        }
      })
    } catch (e) {
      console.log(`EditorDOM:#renderReactComponents:error:`, e)
    }
  }

  /**
   * Teardown editor
   */
  teardown() {
    this.#isSetup = false
    this.#formatter = null
    // Remove DOM tree
    // Remove event listeners on DOM tree elements
  }

  /**
   * Compute and add DOM note structure to editor canvas element
   */
  #addNoteDOMStructure() {
    if (!this.#config?.editorContainer) {
      return
    }
    let canvas = this.#config.canvas
    let noteDOMStructure = this.#computeEditorDOMElement(this.#computeNoteBody())
    if (noteDOMStructure) {
      canvas.appendChild(noteDOMStructure)
    }
  }

  /**
   * Remove any existing DOM structure (when this class's setup() is called
   * repeatedly by hosting component, for whatever reason)
   */
  #removeExistingDOMStructure() {
    let existingStructure = this.#getEditorRootElementList()
    if (existingStructure) {
      for (let element of existingStructure) {
        element.remove()
      }
    }
  }

  /**
   * Join two jotting elements
   * @param {HTMLElement} host - Element that will be retained after joining
   * @param {HTMLElement} target - Element that will be removed after joining
   */
  joinJottings(host, target) {
    if (!this.isJottingElement(host) || !this.isJottingElement(target)) {
      return null
    }
    let hostNote = this.getNoteElement(host)
    let targetNote = this.getNoteElement(target)
    targetNote.childNodes.forEach((node) => {
      hostNote.append(node.cloneNode(true))
    })
    target.remove()
  }

  /**
   * Delete a given jotting element
   * @param {MouseEvent} e - onClick event from delete button
   */
  onDeleteCrmCollectionOrField = (e) => {
    var deleteButton = e.currentTarget
    var noteElement = this.getContainingNoteElement(deleteButton)
    var jottingElement = this.getJottingElement(noteElement)

    jottingElement.remove()
  }

  /**
   * Insert link into a note using given parameters
   * @param {Object} params - Input params for inserting link
   * @returns {HTMLElement} - Link element inserted
   */
  insertLink(params) {
    try {
      const { linkText, linkUrl, startContainer, startOffset, endContainer, endOffset } = params

      // console.log({
      //   linkText,
      //   linkUrl,
      //   startContainer,
      //   startOffset,
      //   endContainer,
      //   endOffset,
      // })

      // linkRange is the range of text that has been identified as a url, and
      // that which needs to be surrounded by an anchor-tag equivalent, so it
      // looks like a link to the user
      let linkRange = new Range()
      linkRange.setStart(startContainer, startOffset)
      linkRange.setEnd(endContainer, endOffset)

      // linkElement is the element that will look like the url, it would
      // surround the link range described above
      let linkElement = document.createElement('A')
      linkElement.setAttribute('data-luru-role', 'note-hyperlink')
      linkElement.setAttribute('data-luru-url', linkUrl)
      linkElement.setAttribute('href', linkUrl)
      linkRange.surroundContents(linkElement)
      if (linkText) {
        linkElement.innerHTML = linkText
      }

      this.#config.controller.setupLinkEvents(linkElement)

      return linkElement
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Change jotting type of a jotting element to a given jotting type
   * @param {HTMLElement} jottingElement - A jotting element
   * @param {string} jottingType - Jotting type to change the jotting element to
   */
  changeJottingType(jottingElement, jottingType) {
    let currentJottingType = this.getJottingType(jottingElement)
    // Step 1. Add or remove the prefix element
    if (this.#hasPrefixElement(currentJottingType)) {
      this.getPrefixElement(jottingElement).remove()
    }
    if (this.#hasPrefixElement(jottingType)) {
      let newPrefixElement = this.#computePrefixElement({ type: jottingType })
      this.getNoteElement(jottingElement)?.before?.(newPrefixElement)
      let prefixEvents = this.#config?.controller?.getEventManager()?.computePrefixMousedownEvent(newPrefixElement)
      prefixEvents?.setup && prefixEvents.setup()
    }

    // Step 1a. If jotting type change is from task to non-task, remove React
    // rendered nodes and unset taskId attribute.
    // Currently, the only way to change a jotting from non-task type to task
    // type is to type [] or [x] in front of the text.  In this case, when the
    // jotting element is blurred, relevant task and task info elements will be
    // automatically created
    if (this.getTaskJottingTypes().includes(currentJottingType) && !this.getTaskJottingTypes().includes(jottingType)) {
      let taskInfoElement = jottingElement.querySelector(EditorTaskFieldView.ReactRootSelector)
      if (taskInfoElement) {
        taskInfoElement.remove()
      }
      jottingElement.removeAttribute('data-task-id')
    }

    // Step 2. Set the right colspan for note Element
    // (The prefix element would have the right colspan set by its compute)
    this.getNoteElement(jottingElement).setAttribute(
      'colspan',
      this.#numTabstops - this.#getJottingTabstops(jottingType) - 1
    )

    // Step 3. Change the jotting element attributes
    jottingElement.setAttribute('data-jotting-type', jottingType)
    jottingElement.classList.remove(styles[currentJottingType])
    jottingElement.classList.add(styles[jottingType])

    // Step 4. Mark or unmark jottingElement as answer
    if (this.isAnswerJottingType(jottingType) && !this.isAnswerJottingType(currentJottingType)) {
      jottingElement.classList.add(styles.answer)
    }
    if (!this.isAnswerJottingType(jottingType) && this.isAnswerJottingType(currentJottingType)) {
      jottingElement.classList.remove(styles.answer)
    }
  }

  /**
   * Set caret inside a noteElement at given position
   * @param {HTMLElement} element - A note element or jotting element
   * @param {CaretPosition} position - A position
   */
  setCaretAt(element, position) {
    let jottingElement
    if (this.isJottingElement(element)) {
      jottingElement = element
      element = this.getNoteElement(element)
    } else {
      jottingElement = this.getJottingElement(element)
    }
    let jottingType = this.getJottingType(jottingElement)

    let range = new Range()
    try {
      range.selectNodeContents(element)

      // In case of task nodes, with task info element rendered, set caret
      // before the task info element when setting caret range
      if (this.getTaskJottingTypes().includes(jottingType)) {
        let taskInfoElement = element.querySelector(EditorTaskFieldView.ReactRootSelector)
        if (taskInfoElement) {
          range.setEndBefore(taskInfoElement)
        }
      }

      if (position === CaretPosition.START_OF_NOTE) {
        range.collapse(true)
      } else if (position === CaretPosition.END_OF_NOTE) {
        range.collapse(false)
      } else {
        // By default, set position to end of note
        range.collapse(false)
      }
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(range)
    } catch (e) {
      // `element` has gone out of DOM; ignore
    }
  }

  /**
   * Function to scroll editor container to top
   */
  scrollContainerToTop() {
    this.#config.editorContainer.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  /**
   * Function to scroll editor container to bottom
   */
  scrollContainerToBottom() {
    this.#config.editorContainer.scrollTo({
      top: 1e9,
      behavior: 'instant',
    })
  }

  isNoteEmpty() {
    const domElements = this.getNoteElementsList()

    if (domElements) {
      const noteElements = Array.from(domElements)

      return noteElements.length === 1 && noteElements[0].textContent === ''
    }
  }

  /**
   * Clear placeholders if any in any note element
   */
  clearPlaceholders() {
    const domElements = this.getNoteElementsList()

    if (domElements) {
      const noteElements = Array.from(domElements)

      const isNoteEmpty = noteElements.length === 1 && noteElements[0].textContent === ''

      if (!isNoteEmpty) {
        // this.#config?.templateChooserPanel?.current?.hide()
        let isManuallyChanged = this.#config?.templateChooserPopup?.current?.getIsManuallyToggled?.()
        let autoLinked = this.#config?.templateChooserPopup?.current?.getAutoLinedTemplateId?.()

        if (!isManuallyChanged && !autoLinked) {
          this.#config?.templateChooserPopup?.current?.hideTemplates?.()
        }
      }
    }

    this.getEditorRootElement()
      ?.querySelectorAll(`td.${styles.note?.replace('+', '\\+')}:not([placeholder=""])`)
      ?.forEach((element) => element.setAttribute('placeholder', ''))
  }

  /**
   * Insert a CRM field before or after a given note element
   * @param {HTMLElement} noteElement - Source note element
   * @param {'before' | 'after'} position - 'before' or 'after'
   * @param {fieldName: string} crmField - CRM field object
   */
  insertCrmFieldNew(noteElement, position, crmField) {
    this.insertJotting(
      noteElement,
      position,
      // JottingType.CRM_FIELD_VALUE_NEW,
      JottingType.CRM_FIELD_VALUE,
      null,
      crmField
    )

    const lastNoteElement = this.getLastNoteElement()
    const lastJottingType = this.getJottingType(lastNoteElement)

    if ([JottingType.CRM_FIELD_VALUE, JottingType.CRM_COLLECTION].includes(lastJottingType)) {
      this.insertJotting(lastNoteElement, 'after', JottingType.P, null, '')
    }
  }

  /**
   * Insert a CRM field before or after a given note element
   * @param {HTMLElement} noteElement - Source note element
   * @param {string} position - 'before' or 'after'
   * @param {Object} crmField - CRM field object
   */
  insertCrmField(noteElement, position, crmField) {
    this.insertJotting(noteElement, position, JottingType.CRM_FIELD_VALUE, null, crmField)

    let crmInputElement = this.getInputElement(
      position === 'before'
        ? this.getPreviousJottingElement(this.getJottingElement(noteElement))
        : this.getNextJottingElement(this.getJottingElement(noteElement))
    )

    if (EditorCrmFieldView.canSelectAndEdit(crmInputElement)) {
      setTimeout(() => {
        try {
          crmInputElement.setSelectionRange(crmInputElement.value.length, crmInputElement.value.length, 'forward')
          crmInputElement.focus()
        } catch (e) {}
      }, 100)
    }
  }

  /**
   * Insert a CRM collection before or after a given note element
   * @param {HTMLElement} noteElement - Source note element
   * @param {'before' | 'after'} position - Where to insert
   * @param {{collectionId: string, collection: import('../features/collections/types').ReduxCollectionEntity}} collection - Collection
   * data used for insertion
   */
  insertCrmCollection(noteElement, position, collection) {
    this.insertJotting(noteElement, position, JottingType.CRM_COLLECTION, null, collection)

    const lastNoteElement = this.getLastNoteElement()
    const lastJottingType = this.getJottingType(lastNoteElement)

    if ([JottingType.CRM_FIELD_VALUE, JottingType.CRM_COLLECTION].includes(lastJottingType)) {
      this.insertJotting(lastNoteElement, 'after', JottingType.P, null, '')
    }

    // this.#config?.templateChooserPanel?.current?.hide()
    let isManuallyChanged = this.#config?.templateChooserPopup?.current?.getIsManuallyToggled?.()
    let autoLinked = this.#config?.templateChooserPopup?.current?.getAutoLinedTemplateId?.()

    if (!isManuallyChanged && !autoLinked) {
      this.#config?.templateChooserPopup?.current?.hideTemplates?.()
    }
  }

  /**
   * Insert a jotting before or after a given note element
   * @param {HTMLElement} noteElement - Source note element
   * @param {string} position - 'before' or 'after'
   * @param {string} jottingType - Jotting type of inserted jotting
   * @param {Range} contentRange - A range object from which to extract contents
   * for new jotting
   * @param {any} data - Any string of data - text or html, if contentRange
   * is not available
   */
  insertJotting(noteElement, position, jottingType, contentRange = null, data = '') {
    const currentJottingType = this.getJottingType(this.getJottingElement(noteElement))

    const newJottingData =
      currentJottingType === JottingType.CRM_COLLECTION
        ? data
        : currentJottingType === JottingType.CRM_FIELD_VALUE || currentJottingType === JottingType.A_CRM_FIELD_VALUE
        ? data
        : contentRange?.extractContents() ?? data

    const newJotting = {
      type: jottingType,
      data: newJottingData,
    }

    const jottingElement = this.getJottingElement(noteElement)
    const newJottingElement = this.#computeJottingRow(newJotting)

    this.#config.controller.getEventManager().setupForJotting(newJottingElement)

    if (position === 'before') {
      jottingElement?.before?.(newJottingElement)
    } else {
      jottingElement?.after?.(newJottingElement)
    }

    this.#renderReactComponents(newJottingElement)
  }

  /**
   * Move one jotting before another jotting
   * @param {HTMLElement} targetJotting - Target jotting to be moved
   * @param {HTMLElement} sourceJotting - Jotting before which target is to move
   */
  moveJottingBefore(targetJotting, sourceJotting) {
    if (!this.isJottingElement(targetJotting) || !this.isJottingElement(sourceJotting)) {
      return
    }
    targetJotting.after(sourceJotting)

    let nextJotting = this.getNextJottingElement(targetJotting)
    let nextJottingType = this.getJottingType(nextJotting)
    let jottingType = this.getJottingType(targetJotting)
    if (this.isAnswerJottingType(nextJottingType) && !this.isAnswerJottingType(jottingType)) {
      let answerType = this.getAnswerType(jottingType)
      this.changeJottingType(targetJotting, answerType)
      return
    }

    let previousJotting = this.getPreviousJottingElement(targetJotting)
    let previousJottingType = this.getJottingType(previousJotting)
    if (
      !this.isAnswerJottingType(previousJottingType) &&
      previousJottingType !== JottingType.Q &&
      this.isAnswerJottingType(jottingType)
    ) {
      let nonanswerType = this.getNonAnswerType(jottingType)
      this.changeJottingType(targetJotting, nonanswerType)
      return
    }
  }

  /**
   * Move one jotting after another jotting
   * @param {HTMLElement} targetJotting - Target jotting to be moved
   * @param {HTMLElement} sourceJotting - Jotting after which target is to move
   */
  moveJottingAfter(targetJotting, sourceJotting) {
    if (!this.isJottingElement(targetJotting) || !this.isJottingElement(sourceJotting)) {
      return
    }
    targetJotting?.before?.(sourceJotting)

    let nextJotting = this.getNextJottingElement(targetJotting)
    let nextJottingType = this.getJottingType(nextJotting)
    let jottingType = this.getJottingType(targetJotting)
    if (this.isAnswerJottingType(nextJottingType) && !this.isAnswerJottingType(jottingType)) {
      let answerType = this.getAnswerType(jottingType)
      this.changeJottingType(targetJotting, answerType)
      return
    }

    let previousJotting = this.getPreviousJottingElement(targetJotting)
    let previousJottingType = this.getJottingType(previousJotting)
    if (!this.isAnswerJottingType(previousJottingType) && this.isAnswerJottingType(jottingType)) {
      let nonanswerType = this.getNonAnswerType(jottingType)
      this.changeJottingType(targetJotting, nonanswerType)
      return
    }
  }

  /**
   * Remove a jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   */
  removeJottingElement(jottingElement) {
    jottingElement.remove()
  }

  /**
   * Remove CRM field value jottings from note
   */
  removeCrmFieldValueJottings() {
    let crmJottingElements = this.#config?.canvas?.querySelectorAll(
      `[data-jotting-type="${JottingType.CRM_FIELD_VALUE}"]`
    )
    crmJottingElements.forEach((element) => this.removeJottingElement(element))
  }

  /**
   * Remove embedded CRM collections
   */
  removeCrmCollections() {
    let collectionElements = this.#config?.canvas?.querySelectorAll(
      `[data-jotting-type="${JottingType.CRM_COLLECTION}"]`
    )
    collectionElements.forEach((element) => this.removeJottingElement(element))
  }

  /**
   * Set or reset a placeholder of a given note element
   * @param {HTMLElement} noteElement - A note element
   */
  setPlaceholder(noteElement) {
    // Blank out the placeholder field, if any
    if (noteElement.textContent !== '') {
      noteElement.setAttribute('placeholder', '')
      return
    }

    if (
      this.#config?.controller.getEntityType() === EditorEntityType.NoteTemplate &&
      noteElement.textContent === '' &&
      this.getNoteElementsList()?.length === 1
    ) {
      // let crm = LuruUser.getCurrentUserCrmName()
      // let objectNames = {
      //   [CRMProvider.SFDC]: 'Opportunities/Leads/Accounts/Contacts',
      //   [CRMProvider.SFDC_SANDBOX]: 'Opportunities/Leads/Accounts/Contacts',
      //   [CRMProvider.HUBSPOT]: 'Deals/Contacts/Companies',
      //   [CRMProvider.PIPEDRIVE]: 'Deals/Contacts/Companies/Leads',
      // }
      noteElement.setAttribute('placeholder', `Type / anywhere to insert fields and other formatting options`)
      return
    }

    if (this.#config?.controller.getEntityType() === EditorEntityType.Note) {
      const domElements = this.getNoteElementsList()
      if (!domElements) {
        return
      }

      const noteElements = Array.from(domElements)
      if (noteElements.length === 1 && noteElements[0].textContent === '') {
        noteElement.setAttribute(
          'placeholder',
          this.#config?.model?.getApplicableTemplates?.().length === 0
            ? 'Start typing...\nTip: Create playbooks to supercharge your notes.'
            : 'Start typing, or choose a playbook to follow'
        )
        let isManuallyChanged = this.#config?.templateChooserPopup?.current?.getIsManuallyToggled?.()
        let autoLinked = this.#config?.templateChooserPopup?.current?.getAutoLinedTemplateId?.()
        if (!isManuallyChanged && !autoLinked) {
          this.#config?.templateChooserPopup?.current?.showTemplates?.()
        }
        // this.#config?.templateChooserPanel?.current?.showNear(noteElement, (template) => {
        //   trackEvent('use_template', 'manual')
        //   this.#config?.controller.onInsertTemplate(template, noteElement)
        //   this.setCaretAt(this.getLastNoteElement(), CaretPosition.END_OF_NOTE)
        //   this.#config?.controller.setNoteDirtyFlag(true)
        //   this.#config?.controller.autoSave()
        // })
        return
      }
    }

    let placeholder = {
      [JottingType.H1]: 'Heading 1',
      [JottingType.H2]: 'Heading 2',
      [JottingType.H3]: 'Heading 3',
      [JottingType.Q]: 'Enter a question here...',
      [JottingType.TASK_INCOMPLETE]: 'Take down a task here...',
      [JottingType.TASK_COMPLETE]: 'Keep note of a completed task...',
    }
    let jottingType = this.getJottingType(noteElement)

    if (jottingType in placeholder) {
      noteElement.setAttribute('placeholder', placeholder[jottingType])
    } else {
      noteElement.setAttribute('placeholder', 'Type / for Shortcuts')
    }
  }

  /**
   * Insert shadow of a jotting element
   * @param {HTMLElement} jottingElement
   */
  createShadowContainer(jottingElement) {
    let container = document.createElement('TABLE')
    container.setAttribute('data-role', 'shadow')
    container.style.position = 'absolute'
    container.style.top = '-1000px'
    container.style.zIndex = 1
    container.style.width = '90%'
    container.style.border = '1px dashed var(--brand-accent-color-blue-lighter-1)'

    let shadowJottings = [jottingElement]
    if (this.getJottingType(jottingElement) === JottingType.Q) {
      let nextJottingElement = this.getNextJottingElement(jottingElement)
      while (nextJottingElement && this.isAnswerJottingType(this.getJottingType(nextJottingElement))) {
        shadowJottings.push(nextJottingElement)
        nextJottingElement = this.getNextJottingElement(nextJottingElement)
      }
    }

    for (let element of shadowJottings) {
      let elementShadow = element.cloneNode(true)
      elementShadow.firstChild.remove()
      container.appendChild(elementShadow)
    }

    this.insertShadowContainer(container)
    return container
  }

  /**
   * Insert a container of shadow jotting DIVs
   * @param {HTMLElement} container
   */
  insertShadowContainer(container) {
    this.#config?.canvas.insertAdjacentElement('beforeend', container)
  }

  /**
   * Insert a container of shadow jotting DIVs
   * @param {HTMLElement} shadowDiv
   */
  removeShadowContainer() {
    let shadowElement = this.#config?.canvas.lastChild
    if (shadowElement && shadowElement.dataset?.role === 'shadow') {
      shadowElement.remove()
    }
  }

  /**
   * Show the result of an update field operation
   * @param {Object} result - Result of update field operation
   * @param {HTMLElement} jottingElement - Jotting element that triggered update
   */
  showUpdateFieldInfo(jottingElement, result) {
    let infoElement = this.#getInfoElement(jottingElement)

    switch (result.type) {
      case EntityStatus.Updated:
        infoElement.classList.remove(styles.error)
        infoElement.classList.remove(styles.loading)
        infoElement.classList.remove(styles.fieldChangedTip)
        infoElement.classList.add(styles.success)
        infoElement.innerHTML = 'Updated in CRM'
        setTimeout(() => this.hideUpdateFieldInfo(jottingElement), 2000)
        break

      case EntityStatus.ErrorUpdating:
        infoElement.classList.remove(styles.success)
        infoElement.classList.remove(styles.loading)
        infoElement.classList.add(styles.error)
        infoElement.innerHTML = result.message

        break

      case EntityStatus.Loading:
      default:
        infoElement.classList.remove(styles.success)
        infoElement.classList.remove(styles.error)
        infoElement.classList.add(styles.loading)
        infoElement.innerHTML = 'Saving to CRM...'
    }
  }

  /**
   * Hide the result of an update field operation
   * @param {Object} result - Result of update field operation
   * @param {HTMLElement} jottingElement - Jotting element that triggered update
   */
  hideUpdateFieldInfo(jottingElement) {
    let infoElement = this.#getInfoElement(jottingElement)
    infoElement.innerHTML = ''
  }

  /**
   * Make the note editor editable
   */
  makeEditorEditable = () => {
    this.getEditorRootElement()?.setAttribute('contenteditable', true)
    Array.from(this.getNoteElementsList() ?? []).forEach((element) => element.setAttribute('contenteditable', 'true'))
    this.#renderReactComponents(null, true)
  }

  /**
   * Update task jottings inside the note with a given task API record
   * @param {object} task - Task record in API response format
   */
  updateTaskJottings(task = undefined) {
    if (!task) {
      return
    }

    let jottings = this.getJottingElementsList()
    jottings.forEach((jotting) => {
      let noteElement = this.getNoteElement(jotting)
      let taskInfoElement = noteElement.querySelector(EditorTaskFieldView.ReactRootSelector)
      let reactHTML = taskInfoElement?.outerHTML
      let taskTitle = noteElement.innerHTML.replace(reactHTML, '')
      let taskId = jotting.getAttribute('data-task-id')
      if (task?.task_id === taskId && task?.title !== taskTitle) {
        // Delete old task
        let range = new Range()
        range.setStart(noteElement, 0)
        range.setEndBefore(taskInfoElement)
        range.deleteContents()
        // Insert new task
        let newNode = document.createTextNode(task.title)
        noteElement.insertBefore(newNode, taskInfoElement)
      }
    })
  }

  /**
   * Replace CRM field values with new CRM connection
   * @param {{sor: string, sor_object_name: string, sor_record_id: string, sor_record_name: string}} conn - CRM connection object
   */
  // refreshCrmFieldValues(conn) {
  //   var jottingElemList = this.getJottingElementsList()
  //   var latestRecord = this.#config.controller.getCrmRecordFields()

  //   jottingElemList.forEach((elem) => {
  //     var jottingType = this.getJottingType(elem)

  //     if (
  //       jottingType !== JottingType.A_CRM_FIELD_VALUE &&
  //       jottingType !== JottingType.CRM_FIELD_VALUE
  //     ) {
  //       return
  //     }

  //     var currJottingData = this.getCrmJottingData(elem).data
  //     var newJottingData = {
  //       ...currJottingData,
  //       record: {
  //         ...currJottingData.record,
  //         sorRecordId: conn.sor_record_id,
  //       },
  //       field: {
  //         ...currJottingData.field,
  //         value: latestRecord?.record[currJottingData.field.name]?.value,
  //       },
  //     }

  //     delete newJottingData.field.previousValue

  //     var newJottingElement = this.#computeJottingRow({
  //       type: jottingType,
  //       data: newJottingData,
  //     })

  //     elem.replaceWith(newJottingElement)
  //     this.#config.controller
  //       .getEventManager()
  //       .setupForJotting(newJottingElement)
  //     this.#renderReactComponents(newJottingElement)
  //   })
  // }

  // Accessor functions
  /**
   * Get note data as JSON
   * @returns {Array} - of jotting element in luru format
   */
  getNoteDataArray() {
    let jottingElements = this.getJottingElementsList()

    if (!jottingElements) {
      return null
    }

    let result = Array.from(jottingElements).reduce((json, jottingElement) => {
      let jottingType = this.getJottingType(jottingElement)
      let jottingData
      let addJotting = true

      if (jottingType === JottingType.CRM_FIELD_VALUE || jottingType === JottingType.A_CRM_FIELD_VALUE) {
        jottingData = this.getCrmJottingData(jottingElement)
      } else if (jottingType === JottingType.CRM_COLLECTION) {
        jottingData = this.getCrmCollectionJottingData(jottingElement)
      } else if (this.getTaskJottingTypes().includes(jottingType)) {
        let noteElement = this.getNoteElement(jottingElement)
        let taskData = this.getTaskJottingData(jottingElement)

        let taskInfoElement = noteElement.querySelector(EditorTaskFieldView.ReactRootSelector)

        let reactHTML = taskInfoElement?.outerHTML
        let taskHTML = noteElement.innerHTML.replace(reactHTML, '')

        jottingData = {
          type: jottingType,
          data: taskHTML,
          taskId: taskData?.data?.taskId,
        }
      } else {
        let noteElement = this.getNoteElement(jottingElement)

        if (noteElement) {
          jottingData = {
            type: jottingType,
            data: noteElement.innerHTML,
          }
        } else {
          addJotting = false
        }
      }

      return addJotting ? json.concat(jottingData) : json
    }, [])

    return result
  }

  /**
   * Get the task data in a given jotting
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {Object} - Task jotting data, if jotting element is task
   * null, otherwise
   */
  getTaskJottingData(jottingElement) {
    let taskJottingTypes = [
      JottingType.TASK_COMPLETE,
      JottingType.TASK_INCOMPLETE,
      JottingType.A_TASK_COMPLETE,
      JottingType.A_TASK_INCOMPLETE,
    ]

    let jottingType = this.getJottingType(jottingElement)

    if (!taskJottingTypes.includes(jottingType)) {
      return null
    }

    let noteElement = this.getNoteElement(jottingElement)
    let taskInfoElement = noteElement.querySelector(EditorTaskFieldView.ReactRootSelector)
    let reactHTML = taskInfoElement?.outerHTML ?? ''
    let taskHTML = noteElement.innerHTML.replace(reactHTML, '')

    let jottingData = {
      type: jottingType,
      data: {
        taskId: jottingElement.dataset.taskId ?? undefined,
        title: taskHTML,
        // TODO: Drop HTML tags
        titleText: taskHTML,
        status: [JottingType.TASK_COMPLETE, JottingType.A_TASK_COMPLETE].includes(jottingType) ? 'COMPLETED' : 'OPEN',
      },
    }
    return jottingData
  }

  /**
   * Mark a jotting with a task id
   * @param {HTMLElement} jottingElement - Jotting element
   * @param {string} taskId - A task id
   */
  markJottingWithTaskId(jottingElement, taskId) {
    if (jottingElement) {
      jottingElement.dataset.taskId = taskId
    }
  }

  /**
   * Unmark a jotting with a task id
   * @param {HTMLElement} jottingElement - Jotting element
   * @param {string} taskId - A task id
   */
  unmarkJottingWithTaskId(jottingElement) {
    if (jottingElement) {
      jottingElement.removeAttribute('data-task-id')
    }
  }

  /**
   * Add an indicator to display task is getting created
   * @param {HTMLElement} jottingElement - Jotting element
   */
  addTaskCreatingElement(jottingElement) {
    let noteElement = this.getNoteElement(jottingElement)
    let creatingElement = document.createElement('span')
    let elemStyle = 'font-style:italic;font-size: var(--font-size-decrease-1); margin-left: 1em'
    creatingElement.setAttribute('style', elemStyle)
    creatingElement.setAttribute('data-luru-role', 'task-creating')
    creatingElement.innerHTML = 'Creating task...'
    noteElement.appendChild(creatingElement)
  }

  /**
   * Remove the indicator displaying task is getting created
   * @param {HTMLElement} jottingElement - Jotting element
   */
  removeTaskCreatingElement(jottingElement) {
    let noteElement = this.getNoteElement(jottingElement)
    let elemSelector = '[data-luru-role="task-creating"]'
    let creatingElement = noteElement.querySelector(elemSelector)
    if (noteElement && creatingElement && noteElement.contains(creatingElement)) {
      noteElement?.removeChild?.(creatingElement)
    }
  }

  /**
   * Remove task's <br/> elements
   * @param {HTMLElement} jottingElement - Jotting element
   */
  removeTaskBreakElements(jottingElement) {
    let noteElement = this.getNoteElement(jottingElement)
    // Check if the parent element exists
    if (noteElement) {
      // Get all child nodes of the parent element
      var childNodes = noteElement.childNodes

      // Iterate through child nodes
      for (var i = 0; i < childNodes.length; i++) {
        var childNode = childNodes[i]

        // Check if the child node is a <br> element
        if (childNode.nodeName === 'BR') {
          // Remove the <br> element
          noteElement.removeChild(childNode)
          i-- // Adjust the counter to account for removed element
        }
      }
    }
  }

  /**
   * Add task info element to note
   * @param {HTMLElement} jottingElement - Jotting element
   * @param {string} taskId - Task ID
   */
  addTaskInfoElement(jottingElement, taskId) {
    let jottingType = this.getJottingType(jottingElement)
    if (this.getTaskJottingTypes().includes(jottingType)) {
      let { element, component } = this.#taskFieldView.computeTaskInfoElement(
        { taskId },
        this.#config?.controller.isEditorReadOnly(),
        this.#config?.entityId
      )
      if (element) {
        if (component) {
          element.luruObjects = { reactComponent: component }
        }
        let noteElement = this.getNoteElement(jottingElement)
        noteElement.appendChild(element)
        this.#renderReactComponents(jottingElement)
      }
    }
  }

  /**
   * Get the CRM collection data object stored in a jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {{collectionId: string, collection: import('../features/collections/types').ReduxCollectionEntity}} - CRM collection jotting data object
   */
  getCrmCollectionJottingData(jottingElement) {
    var jottingType = this.getJottingType(jottingElement)

    if (jottingType !== JottingType.CRM_COLLECTION) {
      return null
    }

    const collectionId = jottingElement.dataset.collectionId
    try {
      var collection = JSON.parse(jottingElement.dataset.s_collection)
    } catch (e) {
      console.error(
        'EditorDOM:getCrmCollectionJottingData:Error extracting collection data from attribute data-collection of element:',
        jottingElement,
        '; error:',
        e
      )
    }
    try {
      const connection = this.#config.controller.getCrmConnection()
      const sorId = connection?.sor?.toLowerCase()
      const sorObjectName = connection?.sor_object_name
      const sorRecordId = connection?.sor_record_id
      let reduxRecord = null
      if (sorId && sorObjectName && sorRecordId) {
        reduxRecord = window.__luru_store.getState().crm[sorId]?.entities?.[sorObjectName]?.[sorRecordId]
      }
      var values = {
        sorId: sorId,
        record: {
          sorRecordId: sorRecordId,
          sorObjectName: sorObjectName,
        },
      }
      // For each field in collection create field values
      var fieldValues = {}
      collection.fields.forEach((field) => {
        let _field = reduxRecord?.data?.[field]

        let fieldValue = _field?.value
        let fieldType = _field?.schema?.luruFieldType
        // Ensure fieldValue is a string
        if (fieldType !== LuruFieldType.REFERENCE && fieldType !== LuruFieldType.MULTIREFERENCE) {
          fieldValue = fieldValue + ''
        }

        if (
          (fieldType === LuruFieldType.REFERENCE || fieldType === LuruFieldType.MULTIREFERENCE) &&
          typeof fieldValue === 'string'
        ) {
          try {
            fieldValue = json5.parse(fieldValue)
          } catch (e) {}
        }

        fieldValues[field] = {
          name: _field?.schema?.name,
          label: _field?.schema?.label,
          value: fieldValue,
          luruFieldType: fieldType,
          readonly: _field?.schema?.updateable === false,
          isNameField: _field?.schema?.name_field,
        }
      })
      values.fieldValues = fieldValues
    } catch (e) {
      console.error(
        'EditorDOM:getCrmCollectionJottingData:Error forming collection data from attribute data-collection of element:',
        jottingElement,
        '; error:',
        e
      )
    }

    return {
      type: JottingType.CRM_COLLECTION,
      data: {
        collectionId,
        collection,
        values,
      },
    }
  }

  /**
   * Get the CRM data object stored in a jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {Object} - CRM jotting data, if jotting element is CRM value field
   * null, otherwise
   */
  getCrmJottingData(jottingElement) {
    const jottingType = this.getJottingType(jottingElement)

    if (jottingType !== JottingType.CRM_FIELD_VALUE && jottingType !== JottingType.A_CRM_FIELD_VALUE) {
      return null
    }
    const connection = this.#config.controller.getCrmConnection()
    const sorId = connection?.sor?.toLowerCase()
    const sorObjectName = connection?.sor_object_name
    const sorRecordId = connection?.sor_record_id
    const fieldName = jottingElement?.dataset?.fieldName
    var fieldValue = jottingElement?.dataset?.fieldValue
    const fieldType = jottingElement?.dataset?.fieldType

    if (sorId && sorObjectName && sorRecordId) {
      const reduxRecord = window.__luru_store.getState().crm[sorId]?.entities?.[sorObjectName]?.[sorRecordId]
      fieldValue = reduxRecord?.data?.[fieldName]?.value
      // Ensure fieldValue is a string
      if (fieldType !== LuruFieldType.REFERENCE && fieldType !== LuruFieldType.MULTIREFERENCE) {
        fieldValue = fieldValue + ''
      }
    }

    if (
      (fieldType === LuruFieldType.REFERENCE || fieldType === LuruFieldType.MULTIREFERENCE) &&
      typeof fieldValue === 'string'
    ) {
      try {
        fieldValue = json5.parse(fieldValue)
      } catch (e) {}
    }

    var jottingData = {
      type: JottingType.CRM_FIELD_VALUE,
      data: {
        sorId: jottingElement?.dataset?.crmId,
        record: {
          sorRecordId: jottingElement?.dataset?.sorRecordId,
          sorObjectName: jottingElement?.dataset?.sorObjectName,
        },
        field: {
          name: jottingElement?.dataset?.fieldName,
          label: jottingElement?.dataset?.fieldLabel,
          value: fieldValue,
          luruFieldType: jottingElement?.dataset?.fieldType,
          readonly: jottingElement?.dataset?.fieldReadonly,
          isNameField: jottingElement?.dataset?.isNameField,
        },
      },
    }

    if (jottingElement?.dataset?.picklistValues !== undefined) {
      jottingData.data.field.picklistValues = json5.parse(jottingElement.dataset.picklistValues)
    }

    if (jottingElement?.dataset?.fieldSorObjectName !== undefined) {
      jottingData.data.field.sorObjectName = jottingElement.dataset.fieldSorObjectName
    }

    if (jottingElement?.dataset?.fieldPreviousValue) {
      jottingData.data.field.previousValue = jottingElement.dataset.fieldPreviousValue
    }

    return jottingData
  }
  /**
   * Get the root elements hosting the note
   * @returns {HTMLNodeList} - Root element (a table) hosting the note
   */
  #getEditorRootElementList() {
    let canvas = this.#config?.canvas
    return canvas?.querySelectorAll(`[data-container-name="${this.#computeUniqueContainerName()}"]`) ?? null
  }

  /**
   * Get the y-coordinate of editor container from top of browser window
   * @returns {number} Y-coordinate in pixels
   */
  getEditorTop() {
    return this.#config?.editorContainer?.offsetTop
  }

  /**
   * Get the vertically scrolled distance of editor container
   * @returns {number} Vertical scroll distance in pixels
   */
  getEditorScrollTop() {
    return this.#config?.editorContainer?.scrollTop
  }

  /**
   * Access the editor container element
   * @returns {HTMLElement} - Editor container element
   */
  getEditorContainer() {
    return this.#config?.editorContainer
  }

  /**
   * Get the root element hosting the note
   * @returns {HTMLNode} - Root element (a table) hosting the note
   */
  getEditorRootElement() {
    let canvas = this.#config?.canvas
    return canvas?.querySelector(`[data-container-name="${this.#computeUniqueContainerName()}"]`) ?? null
  }

  /**
   * Get the list of jotting elements currently in the editor
   * @returns {NodeList} - List of all jotting elements (table rows)
   */
  getJottingElementsList() {
    if (!this.#isSetup) {
      return null
    }
    let canvas = this.#config?.canvas
    return canvas?.querySelectorAll(`tr.${styles.jotting?.replace('+', '\\+')}`)
  }

  /**
   * Get the list of note elements currently in the editor
   * @returns {NodeList} - List of all note elements (table cells)
   */
  getNoteElementsList() {
    if (!this.#isSetup) {
      return null
    }
    let canvas = this.#config?.canvas
    return canvas?.querySelectorAll(`td.${styles.note?.replace('+', '\\+')}`)
  }

  /**
   * Get the first note element currently in the editor
   * @returns {Node} - HTML element
   */
  getFirstNoteElement() {
    if (!this.#isSetup) {
      return null
    }
    const noteElements = this.getNoteElementsList()
    if (!noteElements) {
      return null
    }
    const list = Array.from(this.getNoteElementsList())
    return list ? list[0] : null
  }

  /**
   * Get the first note element currently in the editor
   * @returns {Node} - HTML element
   */
  getLastNoteElement() {
    if (!this.#isSetup) {
      return null
    }
    const list = Array.from(this.getNoteElementsList())
    return list ? list[list.length - 1] : null
  }

  /**
   * Get the drag element of a jotting
   * @returns {HTMLElement} - Drag element inside the jotting
   */
  getDragElement(jottingElement) {
    if (!this.#isSetup) {
      return null
    }
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    return jottingElement.querySelector(`td.${styles.dragHandle?.replace('+', '\\+')}`) ?? null
  }

  /**
   * Get the editable note element inside a CRM field value jotting
   * @param {HTMLElement} jottingElement - Get the CRM note element
   * @returns {HTMLElement}
   */
  getCrmNoteElement(jottingElement) {
    if (!this.#isSetup) {
      return null
    }
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    return jottingElement.querySelector(`div[data-role="crm-field-note"]`) ?? null
  }

  /**
   * Get the element to show info/tip next to the CRM field value element
   * @param {HTMLElement} jottingElement - Jotting element containing CRM field
   */
  #getInfoElement(jottingElement) {
    return jottingElement.querySelector(`span.${styles.updateFieldInfo.replace('+', '\\+')}`)
  }

  /**
   * Get the note element of a given jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {HTMLElement} - Note element, if available
   */
  getNoteElement(jottingElement) {
    if (!this.#isSetup) {
      return null
    }
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    return jottingElement.querySelector(`td.${styles.note}`.replace('+', '\\+')) ?? null
  }

  /**
   * Get the input element, if any, inside a jotting.  Applicable only for
   * CRM field value jottings
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {HTMLElement} - Input element inside the jotting
   */
  getInputElement(jottingElement) {
    if (!this.#isSetup) {
      return null
    }
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    let result = jottingElement.querySelector('[data-role="luru-crm-field"]') ?? null
    return result
  }

  /**
   * Get the list of prefix elements currently in the editor
   * @returns {NodeList} - List of all prefix elements (table cells)
   */
  getPrefixElementsList() {
    if (!this.#isSetup) {
      return null
    }
    let canvas = this.#config?.canvas
    return canvas?.querySelectorAll(`td.${styles.prefix.replace('+', '\\+')}`)
  }

  /**
   * Get the prefix element of a given jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {HTMLElement} - Prefix element, if available
   */
  getPrefixElement(jottingElement) {
    if (!this.#isSetup) {
      return null
    }
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    return jottingElement.querySelector(`td.${styles.prefix.replace('+', '\\+')}`) ?? null
  }

  /**
   * Get the delete button element, if any, of a given jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {HTMLElement} - Delete button element, if available
   */
  getDeleteButton(jottingElement) {
    if (!this.#isSetup) {
      return null
    }
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    return jottingElement.querySelector(`[data-role="crm-field-delete"]`) ?? null
  }

  /**
   * Get the checkbox elements list, if any, of a given jotting element
   * @param {HTMLElement} jottingElement - A jotting element
   * @returns {HTMLElement} - List of input type=checkbox elements
   */
  getCheckboxes(jottingElement) {
    if (!this.#isSetup) {
      return null
    }
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    return jottingElement.querySelectorAll(`input[type="checkbox"]`) ?? null
  }

  /**
   * Get the list of link elements in the note
   * @returns {HTMLElement} - List of links
   */
  getLinks() {
    if (!this.#isSetup) {
      return null
    }
    return Array.from(this.getEditorRootElement()?.querySelectorAll(EditorDOM.LURU_LINK_SELECTOR) ?? [])
  }

  /**
   * Get the next jotting element, given a jotting element
   * @param {HTMLElement} jottingElement - HTML element with the jotting
   * @returns {HTMLElement} - Next jotting element of given jotting element,
   * null if there is no next note element
   */
  getNextJottingElement(jottingElement) {
    if (!this.isJottingElement(jottingElement)) {
      throw new TypeError('Given element is not a jotting')
    }
    return jottingElement.nextElementSibling ?? null
  }

  /**
   * Get the previous jotting element, given a jotting element
   * @param {HTMLElement} jottingElement - HTML element with the jotting
   * @returns {HTMLElement} - Previous jotting element of given note element,
   * null if there is no previous jotting element
   */
  getPreviousJottingElement(jottingElement) {
    if (!this.isJottingElement(jottingElement)) {
      return null
    }
    let previousSibling = jottingElement.previousElementSibling
    if (!previousSibling || previousSibling.matches(`tr.${styles.ruler}`.replace('+', '\\+'))) {
      return null
    }
    return previousSibling
  }

  /**
   * Get the jotting element of a given note or prefix element
   * @param {HTMLElement} element - HTML element with the note or prefix or drag
   * @returns {HTMLElement} - Jotting element of the given element
   */
  getJottingElement(element) {
    if (!this.isNoteElement(element) && !this.isPrefixElement(element) && !this.isDragElement(element)) {
      // throw new TypeError('Given element is not a note or prefix or drag icon')
      return undefined
    }
    return element.parentElement
  }

  /**
   * Get the next note element, given a note element
   * @param {HTMLElement} noteElement - HTML element with the note
   * @returns {HTMLElement} - Next note element of given note element, null if
   * there is no next note element
   */
  getNextNoteElement(noteElement) {
    if (!noteElement) {
      return null
    }
    if (!this.isNoteElement(noteElement)) {
      throw new TypeError('Given element is not a note')
    }
    return (
      noteElement.parentElement?.nextElementSibling?.querySelector(`td.${styles.note?.replace('+', '\\+')}`) ?? null
    )
  }

  /**
   * Get the previous note element, given a note element
   * @param {Node} noteElement - HTML element with the note
   * @returns {HTMLElement} - Previous note element of given note element, null
   * if there is no previous note element
   */
  getPreviousNoteElement(noteElement) {
    if (!noteElement) {
      return null
    }
    if (!this.isNoteElement(noteElement)) {
      throw new TypeError('Given element is not a note')
    }
    return (
      noteElement.parentElement.previousElementSibling?.querySelector(`td.${styles.note?.replace('+', '\\+')}`) ?? null
    )
  }

  /**
   * Get the containing prefix element, if any, of any given element.
   * @param {HTMLElement} node - Any HTML node
   * @returns {HTMLElement} - The prefix element that contains the given element
   * or null, if such a prefix element is not found
   */
  getContainingPrefixElement(node) {
    if (!node || !node.nodeType) {
      return null
    }
    if (node.nodeType === NodeType.TEXT_NODE) {
      node = node.parentElement
    }
    return (node instanceof HTMLElement && node.closest(`td.${styles.prefix}`)) ?? null
  }

  /**
   * Get the containing note element, if any, of any given element.
   * @param {Node} node - Any HTML node
   * @returns {HTMLElement} - The note element that contains the given element,
   * or null, if such a note element is not found
   */
  getContainingNoteElement(node) {
    if (!node || !node.nodeType) {
      return null
    }
    if (node.nodeType === NodeType.TEXT_NODE) {
      node = node.parentElement
    }
    if (this.isNoteElement(node)) {
      return node
    }
    return (node instanceof HTMLElement && node.closest(`td.${styles.note}`)) ?? null
  }

  /**
   * Get jotting type of a given element
   * @param {HTMLElement} element - Any element
   * @returns {string} - Jotting type of given element; null, if not found
   */
  getJottingType(element) {
    if (!element) {
      return null
    }
    if (this.isJottingElement(element)) {
      return element.dataset?.jottingType ?? null
    }
    let container = this.getContainingNoteElement(element) ?? this.getContainingPrefixElement(element)
    return this.getJottingElement(container)?.dataset?.jottingType ?? null
  }

  /**
   * Get the prefix-stripped value of a given jotting type
   * @param {JottingType} jottingType - A jotting type
   */
  getPrefixStrippedJottingType(jottingType) {
    if (!EditorDOM.PREFIXED_JOTTING_TYPES.includes(jottingType)) {
      return jottingType
    }
    return jottingType === JottingType.A_P
      ? JottingType.P
      : jottingType.startsWith('answer')
      ? JottingType.A_P
      : JottingType.P
  }

  /**
   * Get the answer-version of a given jotting type
   * @param {string} jottingType - A jotting type
   * @returns {string} - Answer version of given type, same, if not possible
   */
  getAnswerType(jottingType) {
    let answerTypes = {
      [JottingType.H1]: JottingType.A_H1,
      [JottingType.H2]: JottingType.A_H2,
      [JottingType.H3]: JottingType.A_H3,
      [JottingType.P]: JottingType.A_P,
      [JottingType.TASK_INCOMPLETE]: JottingType.A_TASK_INCOMPLETE,
      [JottingType.TASK_COMPLETE]: JottingType.A_TASK_COMPLETE,
      [JottingType.UL1]: JottingType.A_UL1,
      [JottingType.UL2]: JottingType.A_UL2,
      [JottingType.UL3]: JottingType.A_UL3,
      [JottingType.OL1]: JottingType.A_OL1,
      [JottingType.OL2]: JottingType.A_OL2,
      [JottingType.OL3]: JottingType.A_OL3,
    }
    if (jottingType in answerTypes) {
      return answerTypes[jottingType]
    }
    return jottingType
  }

  /**
   * Get the non-answer-version of a given jotting type
   * @param {string} jottingType - A jotting type
   * @returns {string} - Non-answer version of given type, same, if not possible
   */
  getNonAnswerType(jottingType) {
    let nonanswerTypes = {
      [JottingType.A_H1]: JottingType.H1,
      [JottingType.A_H2]: JottingType.H2,
      [JottingType.A_H3]: JottingType.H3,
      [JottingType.A_P]: JottingType.P,
      [JottingType.A_TASK_INCOMPLETE]: JottingType.TASK_INCOMPLETE,
      [JottingType.A_TASK_COMPLETE]: JottingType.TASK_COMPLETE,
      [JottingType.A_UL1]: JottingType.UL1,
      [JottingType.A_UL2]: JottingType.UL2,
      [JottingType.A_UL3]: JottingType.UL3,
      [JottingType.A_OL1]: JottingType.OL1,
      [JottingType.A_OL2]: JottingType.OL2,
      [JottingType.A_OL3]: JottingType.OL3,
    }
    if (jottingType in nonanswerTypes) {
      return nonanswerTypes[jottingType]
    }
    return jottingType
  }

  // Calculations
  /**
   * Get the current formatter instance of this editor
   * @returns  {EditorFormatter} Instance of formatter bound with this object
   */
  getFormatter() {
    return this.#formatter
  }

  /**
   * Check if a given jotting element is the last jotting
   * @param {HTMLElement} jottingElement - Input jotting element
   * @returns {Boolean} - true, if given jotting element is the last jotting
   */
  isLastJotting(jottingElement) {
    if (!this.isJottingElement(jottingElement)) {
      throw new TypeError('Given element is not a jotting')
    }
    return this.getNextJottingElement(jottingElement) === null
  }

  /**
   * Check if a given jotting element is the last jotting
   * @param {HTMLElement} jottingElement - Input jotting element
   * @returns {Boolean} - true, if given jotting element is the last jotting
   */
  isFirstJotting(jottingElement) {
    if (!this.isJottingElement(jottingElement)) {
      throw new TypeError('Given element is not a jotting')
    }
    return this.getPreviousJottingElement(jottingElement) === null
  }

  /**
   * Check if a given note element is the last note
   * @param {Node} noteElement - Input note element
   * @returns {Boolean} - true, if given note element is the last note
   */
  isLastNote(noteElement) {
    if (!noteElement) {
      return false
    }
    if (!this.isNoteElement(noteElement)) {
      throw new TypeError('Given element is not a note')
    }
    return this.getNextNoteElement(noteElement) === null
  }

  /**
   * Check if a given note element is the first note
   * @param {HTMLElement} noteElement - Input note element
   * @returns {Boolean} - true, if given note element is the first note
   */
  isFirstNote(noteElement) {
    if (!noteElement) {
      return false
    }
    if (!this.isNoteElement(noteElement)) {
      throw new TypeError('Given element is not a note')
    }
    return this.getPreviousNoteElement(noteElement) === null
  }

  /**
   * Check if a given HTML element is a jotting element or not
   * @param {HTMLElement} element - Input element
   * @returns {Boolean} - true, if given element is a jotting element
   */
  isJottingElement(element) {
    return element && element instanceof HTMLElement && element.matches(`tr.${styles.jotting}`)
  }

  /**
   * Check if a given HTML element is a note element or not
   * @param {Node} element - Input element
   * @returns {Boolean} - true, if given element is a note element
   */
  isNoteElement(element) {
    return element && element instanceof HTMLElement && element.matches(`td.${styles.note}`)
  }

  /**
   * Check if a given HTML element is a prefix element or not
   * @param {HTMLElement} element - Input element
   * @returns {Boolean} - true, if given element is a note element
   */
  isPrefixElement(element) {
    return element && element instanceof HTMLElement && element.matches(`td.${styles.prefix}`)
  }

  /**
   * Check if a given HTML element is a drag element or not
   * @param {HTMLElement} element - Input element
   * @returns {Boolean} - true, if given element is a note element
   */
  isDragElement(element) {
    return element && element instanceof HTMLElement && element.matches(`td.${styles.dragHandle}`)
  }

  /**
   * Check if caret is at start of a note
   * @param {Object} event - A luruEvent
   * @returns {Boolean} - True if caret is at start of line
   */
  isCaretAtStartOfNote(event) {
    return event?.data?.range?.collapsed && event?.data?.range?.startOffset === 0
  }

  /**
   * Check if caret is at start of a note
   * @param {Object} event - A luruEvent
   * @returns {Boolean} - True if caret is at end of note element
   */
  isCaretAtEndOfNote(event) {
    if (!event?.data?.noteElement || !event?.data?.range) {
      return false
    }
    let range = event.data.range
    let noteElement = event.data.noteElement
    let jottingElement = this.getJottingElement(noteElement)
    let jottingType = this.getJottingType(jottingElement)
    let isJottingTask = this.getTaskJottingTypes().includes(jottingType)

    return isOffspringYoungest(range.endContainer, noteElement) && isRangeAtEndOfNode(range)

    function isOffspringYoungest(offspring, ancestor) {
      let node = offspring
      while (node && node.parentElement !== ancestor) {
        if (node.parentElement && node !== node.parentElement.lastChild) {
          return false
        }
        node = node.parentElement
      }
      return isJottingTask
        ? node.nextElementSibling?.getAttribute('data-luru-role') === 'sor-task-info-root'
        : node === ancestor?.lastChild
    }

    function isRangeAtEndOfNode(range) {
      let result = false
      if (!range) {
        return false
      }
      if (range.collapsed) {
        if (range.endContainer.nodeType === 3) {
          // We are in a text node
          result = range.endOffset === range.endContainer.textContent.length
        } else {
          // We are in an element node
          let jottingElement = this.getJottingElement(noteElement)
          let jottingType = this.getJottingType(jottingElement)
          if (this.getTaskJottingTypes().includes(jottingType)) {
            result = range.endOffset === range.endContainer.childNodes.length - 1
          } else {
            result = range.endOffset === range.endContainer.childNodes.length
          }
        }
      }
      return result
    }
  }

  /**
   * Compute the DOM data structure for given note body
   * @param {Array} noteBody - An array of jotting objects {type, data}
   * @return {HTMLElement} - An element containing the HTML structure of note
   */
  #computeEditorDOMElement(noteBody) {
    // Table element
    // We're creating a DOM tree here, even though this appears like an
    // impure function, until we attach this DOM tree to the DOM, we have not
    // changed the DOM.  We have just calculated the data structure.
    let table = document.createElement('TABLE')
    let rulerRow = this.#computeRulerRow()

    let taskJottingTypes = [
      JottingType.TASK_COMPLETE,
      JottingType.TASK_INCOMPLETE,
      JottingType.A_TASK_COMPLETE,
      JottingType.A_TASK_INCOMPLETE,
    ]

    noteBody = noteBody.filter(Boolean)

    let noteBodyTasksRefreshed = noteBody.map((jotting) => {
      if (taskJottingTypes.includes(jotting.type)) {
        let taskId = jotting.taskId
        if (!taskId || taskId === 'null' || taskId === 'undefined' || taskId === '') {
          return jotting
        }

        let latestTask = this.#config.savedTasks?.find((resultContainer) => resultContainer?.value?.task_id === taskId)

        if (!latestTask) {
          // console.log(`Cannot find latest task record for task id:`, taskId)
          return {
            data: jotting.data,
            type: jotting.type.indexOf('answer') !== -1 ? JottingType.A_P : JottingType.P,
          }
        }

        latestTask = latestTask.value

        let refreshedTaskJotting = {
          ...jotting,
          type:
            latestTask.status === 'OPEN'
              ? jotting.type.indexOf('answer') === -1
                ? JottingType.TASK_INCOMPLETE
                : JottingType.A_TASK_INCOMPLETE
              : jotting.type.indexOf('answer') === -1
              ? JottingType.TASK_COMPLETE
              : JottingType.A_TASK_COMPLETE,
          data: latestTask.title,
        }
        // console.log(`refreshedTaskJotting:`, refreshedTaskJotting)
        return refreshedTaskJotting
      } else {
        return jotting
      }
    })

    let tableRows = noteBodyTasksRefreshed?.map((jotting) => this.#computeJottingRow(jotting)) ?? null

    // Structure
    table.setAttribute('data-container-name', this.#computeUniqueContainerName())
    table.setAttribute('data-role', 'editing-table')
    if (!this.#config?.controller.isEditorReadOnly()) {
      table.setAttribute('contenteditable', 'true')
    }
    table.setAttribute('readonly', 'true')
    // Style
    table.classList.add(styles.noteTable)

    // Append rows to table
    table.appendChild(rulerRow)
    if (tableRows) {
      tableRows.forEach((row) => table.appendChild(row))
    }

    return table
  }

  /**
   * Create and return a ruler row with cells for tabstops
   * @returns {HTMLElement} - Ruler row with tabstops
   */
  #computeRulerRow() {
    let tabstops = Array.from(Array(this.#numTabstops).keys()).map((i) => {
      let tabstop = document.createElement('TD')
      tabstop.setAttribute('contenteditable', 'false')
      if (i === this.#numTabstops - 2) {
        tabstop.classList.add(styles.lastTabstop)
      } else if (i < this.#numTabstops - 1) {
        tabstop.classList.add(styles.tabstop)
      }
      return tabstop
    })

    // Structure - Layout management row
    let ruler = document.createElement('TR')
    ruler.setAttribute('contenteditable', 'false')
    tabstops.forEach((tabstop) => ruler.appendChild(tabstop))
    // Style - Layout management row
    ruler.classList.add(styles.ruler)

    return ruler
  }

  /**
   * Create and return a jotting row for a given jotting
   * @param {Object} jotting - Jotting object
   * @returns {HTMLElement} - Jotting row
   */
  #computeJottingRow(jotting) {
    let drag = this.#computeDragElement()
    let prefix = this.#computePrefixElement(jotting)
    let note = this.#computeNoteElement(jotting)
    let letters = 'abcdefghijklmnopqrstuvwxyz1234567890'
    let uidLength = 7
    let uid = Array.from(Array(uidLength).keys()).reduce(
      (result, i) => result + ((i + 1) % 5 ? letters[Math.floor(Math.random() * (letters.length - 1))] : '-'),
      ''
    )

    // Structure - Jotting
    let jottingRow = document.createElement('TR')

    jottingRow.appendChild(drag)

    if (prefix) {
      jottingRow.appendChild(prefix)
    }

    jottingRow.appendChild(note)
    jottingRow.setAttribute('data-jotting-type', jotting.type)
    jottingRow.setAttribute('data-jotting-index', uid)

    if (jotting.type === JottingType.CRM_FIELD_VALUE || jotting.type === JottingType.A_CRM_FIELD_VALUE) {
      let storedJottingData = jotting.data
      let storedJottingField = storedJottingData.field
      let storedJottingRecord = storedJottingData.record
      let latestRecord = this.#config.controller.getCrmRecordFields()
      let crmConnection = this.#config.controller.getCrmConnection()
      let sorId = crmConnection?.sor

      // fieldName will be available in one of two ways
      // 1. storedJottingData.fieldName: For notes created after embedded CRM field implementation
      // 2. storedJottingField.name: For notes created before embedded CRM field implementation and going forward
      let fieldName = storedJottingData?.fieldName ?? storedJottingField?.name

      if (this.#config.controller.getEntityType() === EditorEntityType.Note && !storedJottingField?.name) {
        // storedJottingField is to be populated
        storedJottingField = {
          name: fieldName,
          value: latestRecord?.['record']?.[fieldName]?.value,
          label: latestRecord?.['record']?.[fieldName]?.schema?.label,
          luruFieldType: latestRecord?.['record']?.[fieldName]?.schema?.luruFieldType,
          readonly: !latestRecord?.['record']?.[fieldName]?.schema?.updateable,
          isNameField: latestRecord?.['record']?.[fieldName]?.schema?.nameField,
          picklistValues: latestRecord?.['record']?.[fieldName]?.schema?.picklistValues,
        }
      }

      if (this.#config.controller.getEntityType() === EditorEntityType.Note && !storedJottingRecord) {
        storedJottingRecord = {
          sorObjectName: crmConnection?.sor_object_name,
          sorRecordId: crmConnection?.sor_record_id,
          sorRecordName: crmConnection?.sor_record_name,
        }
      }

      if (storedJottingData?.fieldName) {
        jottingRow.dataset.fieldName = storedJottingData.fieldName
      }

      try {
        let latestValue = latestRecord ? latestRecord.record[storedJottingField.name]?.value : storedJottingField.value
        let isFieldValueMismatch =
          storedJottingField.value !== undefined
            ? storedJottingField.value !== null && typeof storedJottingField.value === 'object'
              ? json5.stringify(latestValue) !== json5.stringify(storedJottingField.value)
              : `${latestValue}` !== `${storedJottingField.value}`
            : false

        if (latestValue !== undefined) {
          if (typeof latestValue === 'object' && latestValue !== null) {
            jottingRow.setAttribute('data-field-value', json5.stringify(latestValue))
          } else {
            jottingRow.setAttribute('data-field-value', latestValue)
          }
        }

        if (isFieldValueMismatch) {
          if (typeof storedJottingField.value === 'object' && storedJottingField.value !== null) {
            jottingRow.setAttribute('data-field-previous-value', json5.stringify(storedJottingField.value))
          } else {
            jottingRow.setAttribute('data-field-previous-value', storedJottingField.value)
          }
        }

        if (!isFieldValueMismatch && storedJottingField && 'previousValue' in storedJottingField) {
          if (storedJottingField.previousValue !== null && typeof storedJottingField.previousValue === 'object') {
            jottingRow.setAttribute('data-field-previous-value', json5.stringify(storedJottingField.previousValue))
          } else {
            jottingRow.setAttribute('data-field-previous-value', storedJottingField.previousValue)
          }
        }

        jottingRow.setAttribute('data-crm-id', sorId)

        if (storedJottingRecord) {
          jottingRow.setAttribute('data-sor-record-id', storedJottingRecord.sorRecordId)
          jottingRow.setAttribute('data-sor-object-name', storedJottingRecord.sorObjectName)
        }
        jottingRow.setAttribute('data-field-name', storedJottingField.name)
        jottingRow.setAttribute('data-field-label', storedJottingField.label)
        jottingRow.setAttribute('data-field-type', storedJottingField.luruFieldType)
        jottingRow.setAttribute('data-field-readonly', storedJottingField.readonly ?? 'false')
        jottingRow.setAttribute('data-is-name-field', storedJottingField.isNameField)

        if (
          storedJottingField.luruFieldType === LuruFieldType.ENUM ||
          storedJottingField.luruFieldType === LuruFieldType.MULTIENUM
        ) {
          jottingRow.setAttribute('data-picklist-values', json5.stringify(storedJottingField.picklistValues))
        }

        if (
          storedJottingField.luruFieldType === LuruFieldType.REFERENCE ||
          storedJottingField.luruFieldType === LuruFieldType.MULTIREFERENCE
        ) {
          jottingRow.setAttribute('data-field-sor-object-name', storedJottingField.value?.sor_object_name)
        }
      } catch (e) {
        console.trace(e)
        console.warn(`Can't set jottingRow attributes as of now`)
      }
    }

    let taskJottingTypes = [
      JottingType.TASK_COMPLETE,
      JottingType.TASK_INCOMPLETE,
      JottingType.A_TASK_COMPLETE,
      JottingType.A_TASK_INCOMPLETE,
    ]

    if (taskJottingTypes.includes(jotting.type)) {
      if (jotting.taskId && jotting.taskId !== 'undefined' && jotting.taskId !== 'null') {
        jottingRow.dataset.taskId = jotting.taskId
      }
    }

    if (jotting.type === JottingType.CRM_COLLECTION) {
      jottingRow.dataset.collectionId = jotting.data.collectionId
      jottingRow.dataset.s_collection = JSON.stringify(jotting.data.collection)
    }

    // Style - jotting
    jottingRow.classList.add(styles.jotting)

    if (jotting.type in styles) {
      jottingRow.classList.add(styles[jotting.type])
    }

    if (this.isAnswerJottingType(jotting.type)) {
      jottingRow.classList.add(styles.answer)
    }

    return jottingRow
  }

  /**
   * Create and return a prefix element
   * @returns {HTMLElement} - Prefix element (a table cell)
   */
  #computePrefixElement(jotting) {
    if (!this.#hasPrefixElement(jotting.type)) {
      return null
    }

    // Structure - Prefix
    let prefixElement = document.createElement('TD')

    prefixElement.setAttribute('contenteditable', 'false')
    prefixElement.setAttribute('colspan', this.#getJottingTabstops(jotting.type))
    prefixElement.innerHTML = this.#getPrefix(jotting.type)

    // Style
    prefixElement.classList.add(styles.prefix)

    return prefixElement
  }

  /**
   * Create and return a note element
   * @returns {HTMLElement} - Note element (a table cell)
   */
  #computeNoteElement(jotting) {
    // 27-02-23 Replacing old with new
    // if (jotting.type === JottingType.CRM_FIELD_VALUE) {
    //   return this.#computeNoteElementForCrm(jotting)
    // }

    if (jotting.type === JottingType.CRM_COLLECTION) {
      return this.#computeNoteElementForCrmCollection(jotting)
    }

    if (jotting.type === JottingType.CRM_FIELD_VALUE || jotting.type === JottingType.A_CRM_FIELD_VALUE) {
      return this.#computeNoteElementForCrmNew(jotting)
    }

    // Structure - Note
    let noteElement = document.createElement('TD')

    noteElement.setAttribute('placeholder', '')
    noteElement.setAttribute('data-luru-role', 'note-element')

    noteElement.setAttribute('contenteditable', this.#config?.controller.isEditorReadOnly() ? 'false' : 'true')

    noteElement.setAttribute('colspan', this.#numTabstops - this.#getJottingTabstops(jotting.type) - 1)

    if (jotting.data instanceof DocumentFragment) {
      noteElement.appendChild(jotting.data)
    } else if (jotting.data !== '') {
      noteElement.innerHTML = jotting.data
      noteElement.normalize()
    } else {
      noteElement.appendChild(document.createTextNode(''))
    }

    if (this.getTaskJottingTypes().includes(jotting.type)) {
      let { element, component } = this.#taskFieldView.computeTaskInfoElement(
        jotting,
        this.#config?.controller.isEditorReadOnly(),
        this.#config?.entityId
      )

      if (element) {
        if (component) {
          element.luruObjects = { reactComponent: component }
        }
        noteElement.appendChild(element)
      }
    }

    // Style
    noteElement.classList.add(styles.note)

    return noteElement
  }

  /**
   * Calculate the note element for a CRM collection jotting
   * @param {{type: JottingType.CRM_FIELD_VALUE, data: {fieldName: string}} jotting - Jotting data
   * @returns Note element (HTMLElement) for a CRM collection jotting
   */
  #computeNoteElementForCrmNew(jotting) {
    var collectionContainer = document.createElement('DIV')

    const computeComponent = function () {
      return (
        <Provider store={LuruReduxStore}>
          <EmbeddedCrmField
            fieldName={jotting.data.fieldName ?? jotting.data.field?.name}
            entityType={this.#config.controller.getEntityType()}
            entityId={this.#config.entityId}
            isReadOnly={Boolean(this.#config?.controller.isEditorReadOnly())}
            onDeleteJotting={this.onDeleteCrmCollectionOrField}
            // objectName={}
          />
        </Provider>
      )
    }.bind(this)

    collectionContainer.dataset.luruRole = 'sor-field-value-root'

    collectionContainer.luruObjects = {
      reactComponent: computeComponent(),
      reRender: computeComponent,
    }

    // Note
    var noteElement = document.createElement('TD')

    noteElement.setAttribute('placeholder', '')
    noteElement.setAttribute('data-luru-role', 'note-element')
    noteElement.setAttribute('contenteditable', 'false')

    noteElement.setAttribute('colspan', this.#numTabstops - this.#getJottingTabstops(jotting.type) - 1)

    noteElement.appendChild(collectionContainer)

    // Style
    noteElement.classList.add(styles.note)

    return noteElement
  }

  /**
   * Calculate the note element for a CRM collection jotting
   * @param {{type: JottingType.CRM_COLLECTION, data: {collectionId: string, collection: import('../features/collections/types').ReduxCollectionEntity}}} jotting - Jotting data
   * @returns Note element (HTMLElement) for a CRM collection jotting
   */
  #computeNoteElementForCrmCollection(jotting) {
    var collectionContainer = document.createElement('DIV')
    const computeComponent = function () {
      return (
        <Provider store={LuruReduxStore}>
          <EmbeddedCollection
            collectionId={jotting.data.collectionId}
            fields={jotting.data.collection.fields}
            collectionObjectName={jotting.data.collection?.sor_object_name}
            entityType={this.#config.controller.getEntityType()}
            entityId={this.#config.entityId}
            isReadOnly={Boolean(this.#config?.controller.isEditorReadOnly())}
            onDeleteCollection={this.onDeleteCrmCollectionOrField}
          />
        </Provider>
      )
    }.bind(this)

    collectionContainer.dataset.luruRole = 'sor-field-value-root'

    collectionContainer.luruObjects = {
      reactComponent: computeComponent(),
      reRender: computeComponent,
    }

    // Note
    var noteElement = document.createElement('TD')

    noteElement.setAttribute('placeholder', '')
    noteElement.setAttribute('data-luru-role', 'note-element')
    noteElement.setAttribute('contenteditable', 'false')

    noteElement.setAttribute('colspan', this.#numTabstops - this.#getJottingTabstops(jotting.type) - 1)

    noteElement.appendChild(collectionContainer)

    // Style
    noteElement.classList.add(styles.note)

    return noteElement
  }

  /**
   * Create and return a note element for a Crm field
   * @returns {HTMLElement} - Note element (a table cell)
   */
  #computeNoteElementForCrm(jotting) {
    // Value element
    var latestRecord = this.#config.controller.getCrmRecordFields()
    var valueChangeCallback = (value, inputElement) => {
      const jottingElement = this.getJottingElement(this.getContainingNoteElement(inputElement))

      this.#config.controller.onCrmFieldUpdated(jotting.data, value, jottingElement)
    }
    var isFieldReadOnly = jotting.data.field.readonly === 'true' || jotting.data.field.readonly === true
    var valueElementDetails = this.#crmFieldView.computeViewElement(
      // arg1: crmJottingData
      jotting.data,
      // arg2: fieldValue
      latestRecord ? latestRecord.record[jotting.data.field.name]?.value : jotting.data.field.value,
      // arg3: isReadonly = false
      this.#config.controller.isEditorReadOnly() ||
        this.#config.controller.getEntityType() === EditorEntityType.NoteTemplate ||
        isFieldReadOnly,
      // arg4: onValueChange = null
      valueChangeCallback,
      // arg5: isReadonlyCallback = () => false
      () => this.#config.controller.isEditorReadOnly() || isFieldReadOnly
    )

    var { element: valueElement, component } = valueElementDetails

    if (component) {
      // Attaching React component as an attribute of value element
      // The value element itself is an empty DIV with data-luru-role set to
      // 'sor-field-value-root'
      valueElement.luruObjects = { reactComponent: component }
    }

    var isLatestValueDefined =
      latestRecord !== null &&
      latestRecord?.record[jotting.data.field.name]?.value !== undefined &&
      latestRecord?.record[jotting.data.field.name]?.value !== null

    var storedPreviousValue = jotting.data.field.previousValue

    if (
      [LuruFieldType.REFERENCE, LuruFieldType.MULTIREFERENCE].includes(jotting.data.field.luruFieldType) &&
      storedPreviousValue
    ) {
      try {
        storedPreviousValue = json5.parse(storedPreviousValue)
      } catch (e) {
        /** Ignore any unexpected JSON parse error */
      }
    }

    var isFieldValueMismatch =
      (isLatestValueDefined &&
        !CrmRecord.doFieldValuesMatch(
          latestRecord?.record[jotting.data.field.name]?.value,
          jotting.data.field.value,
          jotting.data.field
        )) ||
      (`previousValue` in jotting.data.field &&
        !CrmRecord.doFieldValuesMatch(storedPreviousValue, jotting.data.field.value, jotting.data.field))

    var previousValue = isFieldValueMismatch
      ? `previousValue` in jotting.data.field
        ? storedPreviousValue
        : jotting.data.field.value
      : null

    var previousValueToDisplay = CrmRecord.getFormattedValue({
      luruFieldType: jotting.data.field.luruFieldType,
      value: previousValue,
    })

    // Fieldset element - structure
    var fieldsetElement = document.createElement('fieldset')
    fieldsetElement.setAttribute('placeholder', '')
    fieldsetElement.setAttribute('contenteditable', 'false')

    var legendElement = document.createElement('legend')

    try {
      legendElement.innerHTML = jotting.data.field.label
      fieldsetElement.appendChild(legendElement)
    } catch (e) {
      console.warn(`Cannot set jotting html as of now:`, e)
      legendElement.innerHTML = '(warning) Field label'
    }
    fieldsetElement.appendChild(valueElement)

    // Fieldset element - styles
    fieldsetElement.classList.add(styles.crmLabel)

    // Field delete button
    var deleteButton = document.createElement('button')
    deleteButton.setAttribute('data-role', 'crm-field-delete')
    // Styles
    deleteButton.classList.add(styles.crmFieldDeleteButton)

    // Info element
    var infoElement = document.createElement('span')
    if (isFieldValueMismatch) {
      infoElement.innerHTML = `This value has changed since you last opened.`
      infoElement.setAttribute(
        'title',
        [
          `This value has been updated in your CRM.  The earlier value `,
          `updated using this note was '`,
          previousValueToDisplay,
          `'`,
        ].join('')
      )
      infoElement.classList.add(styles.fieldChangedTip)
    }

    infoElement.classList.add(styles.updateFieldInfo)

    // Flex container - Structure
    var flexContainer = document.createElement('div')
    flexContainer.setAttribute('placeholder', '')
    flexContainer.setAttribute('contenteditable', 'false')
    flexContainer.setAttribute('data-role', 'crm-field-note')
    flexContainer.appendChild(fieldsetElement)
    flexContainer.appendChild(deleteButton)
    flexContainer.appendChild(infoElement)
    // Flex container - style
    flexContainer.classList.add(styles.fieldContainer)

    // Note
    var noteElement = document.createElement('TD')
    noteElement.setAttribute('placeholder', '')
    noteElement.setAttribute('data-luru-role', 'note-element')
    noteElement.setAttribute('contenteditable', 'false')
    noteElement.setAttribute('colspan', this.#numTabstops - this.#getJottingTabstops(jotting.type) - 1)
    noteElement.appendChild(flexContainer)
    // Style
    noteElement.classList.add(styles.note)

    return noteElement
  }

  /**
   * Create and return a drag element
   * @returns {HTMLElement} - Drag element (a table cell)
   */
  #computeDragElement() {
    // Structure - Drag
    let dragElement = document.createElement('TD')
    dragElement.setAttribute('draggable', 'true')
    dragElement.setAttribute('contenteditable', 'false')
    dragElement.setAttribute('colspan', 1)
    dragElement.innerHTML = ' '
    // Style - Drag
    dragElement.classList.add(styles.dragHandle)
    return dragElement
  }

  /**
   * Compute a unique id to be used by the element containing note body
   * @returns {string} - A unique id
   */
  #computeUniqueContainerName() {
    return `note-${this.#config?.entityId}`
  }

  /**
   * Compute the note body data structure
   * @return {Array} - Luru note body structure
   */
  #computeNoteBody() {
    const defaultNote = [
      {
        type: JottingType.P,
        data: '',
      },
    ]

    try {
      if (!this.#config?.noteBody) {
        return defaultNote
      }

      let noteBody = this.#config?.noteBody

      if (typeof this.#config?.noteBody === 'string') {
        noteBody = json5.parse(this.#config?.noteBody)
      }

      if (Array.isArray(noteBody)) {
        if (noteBody.length === 0) {
          return defaultNote
        } else {
          /**
           * Parse CRM field values as objects for ref and multi-ref fields
           */
          // 27-02-03
          // noteBody.forEach((jotting) => {
          //   if (jotting.type === JottingType.CRM_FIELD_VALUE) {
          //     if (
          //       (jotting.data.field.luruFieldType === LuruFieldType.REFERENCE ||
          //         jotting.data.field.luruFieldType ===
          //           LuruFieldType.MULTIREFERENCE) &&
          //       typeof jotting.data.field.value === 'string'
          //     ) {
          //       jotting.data.field.value = json5.parse(jotting.data.field.value)
          //     }
          //   }
          // })
          return noteBody
        }
      } else if (typeof noteBody === 'string') {
        return [
          {
            type: JottingType.P,
            data: noteBody,
          },
        ]
      } else {
        console.warn(`EditorDOM:#computeNoteBody:Received invalid note body`)
        console.warn(`EditorDOM:#computeNoteBody:`, this.#config?.noteBody)
      }
    } catch (e) {
      console.warn(e)
    }
    return defaultNote
  }

  /**
   * Compute if a jotting type has prefix or not
   * @return {Boolean} - true, if jotting type has prefix
   */
  #hasPrefixElement(jottingType) {
    return Object.keys(this.#getJottingPrefixes()).includes(jottingType)
  }

  /**
   * Compute if a jotting type is an answer type
   * @return {Boolean} - true, if jotting type has prefix
   */
  isAnswerJottingType(jottingType) {
    return jottingType?.slice(0, 6) === 'answer'
  }

  /**
   * Compute if a jotting type is an answer type
   * @param {HTMLElement} jottingElement - A jotting element
   * @return {Boolean} - true, if jotting type has prefix
   */
  isAnswerJotting(jottingElement) {
    return this.isAnswerJottingType(this.getJottingType(jottingElement))
  }

  /**
   * Compute if a jotting type has prefix or not
   * @return {Boolean} - true, if jotting type has prefix
   */
  #getPrefix(jottingType) {
    return this.#getJottingPrefixes()[jottingType] ?? null
  }

  /**
   * Get list of jotting prefixes as a map like object
   * @return {Object} - A map-like object with (jottingType, prefix) values
   */
  #getJottingPrefixes() {
    return {
      [JottingType.A_H1]: ' ',
      [JottingType.A_H2]: ' ',
      [JottingType.A_H3]: ' ',
      [JottingType.A_P]: ' ',
      [JottingType.TASK_INCOMPLETE]: '\u2610',
      [JottingType.TASK_COMPLETE]: '\u2611',
      [JottingType.Q]: 'Q',
      [JottingType.UL1]: '\u2b24',
      [JottingType.UL2]: '\u25ef',
      [JottingType.UL3]: '\u2014',
      [JottingType.OL1]: '.',
      [JottingType.OL2]: '.',
      [JottingType.OL3]: '.',
      [JottingType.A_UL1]: '\u2b24',
      [JottingType.A_UL2]: '\u25ef',
      [JottingType.A_UL3]: '\u2014',
      [JottingType.A_OL1]: '.',
      [JottingType.A_OL2]: '.',
      [JottingType.A_OL3]: '.',
      [JottingType.A_TASK_INCOMPLETE]: '\u2610',
      [JottingType.A_TASK_COMPLETE]: '\u2611',
      [JottingType.A_CRM_FIELD_VALUE]: ' ',
    }
  }

  /**
   * Get tab stop of a jotting prefix
   * @return {number} - Tab-stop for the given jotting prefix
   */
  #getJottingTabstops(jottingType) {
    const tabStops = {
      [JottingType.UL1]: 1,
      [JottingType.UL2]: 2,
      [JottingType.UL3]: 3,
      [JottingType.OL1]: 1,
      [JottingType.OL2]: 2,
      [JottingType.OL3]: 3,
      [JottingType.TASK_INCOMPLETE]: 1,
      [JottingType.TASK_COMPLETE]: 1,
      [JottingType.Q]: 1,
      [JottingType.A_H1]: 1,
      [JottingType.A_H2]: 1,
      [JottingType.A_H3]: 1,
      [JottingType.A_P]: 1,
      [JottingType.A_UL1]: 2,
      [JottingType.A_UL2]: 3,
      [JottingType.A_UL3]: 4,
      [JottingType.A_OL1]: 2,
      [JottingType.A_OL2]: 3,
      [JottingType.A_OL3]: 4,
      [JottingType.A_TASK_INCOMPLETE]: 2,
      [JottingType.A_TASK_COMPLETE]: 2,
      [JottingType.CRM_COLLECTION]: 0,
      // [JottingType.CRM_FIELD_VALUE_NEW]: 0,
      [JottingType.CRM_FIELD_VALUE]: 0,
      [JottingType.A_CRM_FIELD_VALUE]: 1,
    }
    return jottingType in tabStops ? tabStops[jottingType] : 0
  }

  /**
   * Get the list of task jotting types
   * @return {Array} - Array of task jotting types
   */
  getTaskJottingTypes() {
    let taskJottingTypes = [
      JottingType.TASK_COMPLETE,
      JottingType.A_TASK_COMPLETE,
      JottingType.TASK_INCOMPLETE,
      JottingType.A_TASK_INCOMPLETE,
    ]
    return taskJottingTypes
  }
}

export const CaretPosition = {
  END_OF_NOTE: 'endOfNote',
  START_OF_NOTE: 'startOfNote',
}

Object.freeze(CaretPosition)
