import React from 'react'
// import ToolTip from '../../ToolTip'

import LuruMultiSelectPopup from '../LuruMultiSelectPopup'
import LuruPopupButton from '../LuruPopupButton'
import LuruMultiSelectBoxEventHandler from './LuruMultiSelectBoxEventHandler'

import styles from './styles.module.css'

// Icons
import arrowDropDownIcon from '../../../images/fluent/arrow_drop_down.svg'
import { PopupMenuProps } from '@/primitives/PopupMenu'
import { Chip } from '../ChipSelectBox'

export interface LuruSelectBoxItem {
  name: string
  key: string
  icon?: string
  isSelected: boolean
}

export interface LuruMultiSelectBoxProps {
  classes?: Array<string>
  disabled?: boolean
  items: Array<LuruSelectBoxItem>
  leftAlign?: boolean
  menuParentSelector?: string
  subdued?: boolean
  onFinishSelection: (arg: Array<string>) => void
  onCancel: () => void
  noItemsText?: string
  // additional Menu Props
  popupMenuProps?: Partial<PopupMenuProps>

  showItemsAsChip?: boolean
  chipContainerClassName?: string
  // Whether to auto focus and open menu
  autoFocus?: boolean
}

interface LuruMultiSelectBoxState {
  // selectedItems is a filtered items array.  Its .isSelected is irrelevant
  selectedItems: Array<LuruSelectBoxItem>
}

interface LuruMultiSelectBoxRefs {
  popupButton: React.RefObject<LuruPopupButton>
  luruMultiSelectPopup: React.RefObject<LuruMultiSelectPopup>
}

export default class LuruMultiSelectBox extends React.Component {
  props: LuruMultiSelectBoxProps
  state: LuruMultiSelectBoxState
  componentRefs: LuruMultiSelectBoxRefs
  eventHandler: LuruMultiSelectBoxEventHandler

  constructor(props: LuruMultiSelectBoxProps) {
    super(props)
    this.props = props
    this.state = {
      selectedItems: this.props.items.filter((item) => item.isSelected),
    }
    this.componentRefs = {
      popupButton: React.createRef(),
      luruMultiSelectPopup: React.createRef(),
    }
    this.eventHandler = new LuruMultiSelectBoxEventHandler(this)
  }

  render() {
    var labelElement = this.#renderLabelElement()
    var popupElement = this.#renderPopupElement()

    return (
      <LuruPopupButton
        ref={this.componentRefs.popupButton}
        menuParentSelector={this.props.menuParentSelector}
        subdued={this.props.subdued === true}
        items={popupElement}
        classes={[styles.parent, this.props.disabled ? styles.disabled : ''].concat(this.props.classes ?? [])}
        hideOnMenuClick={false}
        disabled={this.props.disabled}
        leftAlign={this.props.leftAlign}
        popupMenuProps={this.props.popupMenuProps}
        onShowMenu={this.eventHandler.handlers.onShowMenu}
      >
        {labelElement}
      </LuruPopupButton>
    )
  }

  #renderLabelElement() {
    var text = this.#getDisplayText()

    return (
      <div className={styles.display}>
        <span className={styles.text}>{text}</span>
        <span className={styles.actionIcon}>
          <img src={arrowDropDownIcon} alt='dropdown' />
        </span>
      </div>
    )
  }

  #renderPopupElement() {
    if (this.props.items.length <= 0) {
      return (
        <div className={styles.emptyData}>
          <span>No values</span>
        </div>
      )
    }
    return (
      <LuruMultiSelectPopup
        onFinishSelection={this.eventHandler.handlers.onFinishSelection}
        onCancel={this.eventHandler.handlers.onCancel}
        items={this.props.items}
        ref={this.componentRefs.luruMultiSelectPopup}
      />
    )
  }

  #getDisplayText() {
    var selectedNames = this.state.selectedItems.map((item) => item.name)
    if (this.state.selectedItems.length === 0) {
      return this.props.noItemsText ?? 'Select'
    }
    if (this.props.showItemsAsChip) {
      return (
        <span className={[styles.selectedChipsContainer, this.props.chipContainerClassName].join(' ')}>
          {this.state.selectedItems.map((item, indx) => (
            <Chip
              titleClass={styles.chipTitle}
              toolTip={item.name}
              title={item.name}
              identityKey={item.key}
              key={`${indx}_${item.name}`}
              hideRemove
              // onRemove={this.eventHandler.handlers.onRemoveItem}
            />
          ))}
        </span>
      )
    }

    return this.state.selectedItems.length === 0
      ? this.props.noItemsText ?? 'Select'
      : this.state.selectedItems.length === 1
      ? selectedNames[0]
      : this.state.selectedItems.length === 2
      ? `${selectedNames[0]} & ${selectedNames[1]}`
      : selectedNames.filter((_, ix) => ix < 2).join(', ') + ' & ' + (this.state.selectedItems.length - 2) + ' more'
  }
}
