import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod, LuruAPIResponse } from '../../../app/types'
import { DateUtils } from '@/utils/dateUtils'

export interface SearchRunInfosForWorkflowParameter {
  workflowId: string
  time_min: Date
  time_max: Date
}

export interface RunEventForWorkflow {
  name: string
  timestamp: number
  tenant_id: string
  event_id: string
  run_id: string
  entity_ids: Array<{ type: string; id: string }>
  status: 'success' | 'error'
  error: Record<string, any>
  data: {
    sor: string
    type: string
  }
  request_id: string
  records: Array<{
    sor_object_name: string
    sor_record_id: string
    sor_record_name: string
  }>
}

export interface RunInfoForWorkflow {
  run_id: string
  start_time: number
  events: Array<RunEventForWorkflow>
}

export interface SearchRunInfosForWorkflowResponse extends LuruAPIResponse {
  data: Array<RunInfoForWorkflow>
}

export const searchRunInfosForWorkflow = async (
  params: SearchRunInfosForWorkflowParameter,
  options?: LuruAPIRequestOptions
) => {
  const { workflowId, ...otherParams } = params
  var urlParams: Record<string, any> = { ...otherParams }

  if (urlParams.time_min) {
    urlParams.time_min = DateUtils.toISOStringCurrentTZ(urlParams.time_min)
  }

  if (urlParams.time_max) {
    urlParams.time_max = DateUtils.toISOStringCurrentTZ(urlParams.time_max)
  }

  var queryString = new URLSearchParams(urlParams as URLSearchParams)
  var request = new LuruAPIRequest(`/api/workflows/${workflowId}/runs?${queryString}`, HTTPMethod.GET, options)

  return request.make()
}
