// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { listOrSearchTasks as listOrSearchTasksAPI } from '../api/listOrSearchTasks'
import { ReduxTaskEntity, ReduxTasksState, TaskStatus } from '../types'

const requestName = 'tasks/listOrSearchTasks'

export type SearchTasksParameter = {
  key: string
  loadMore?: boolean
  q?: string
  cursor?: string | null
  limit?: number
  status?: TaskStatus
  assigned_to?: 'me' | 'notme'
  sor_object_name?: string
  sor_record_id?: string
}

interface SearchTasksAPIResponse extends LuruAPIResponse {
  data: Array<ReduxTaskEntity>
}

export const listOrSearchTasks = {
  action: createAsyncThunk<
    SearchTasksAPIResponse['data'],
    SearchTasksParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: SearchTasksAPIResponse['metadata']
      rejectedMeta: SearchTasksAPIResponse['metadata']
    }
  >(
    requestName,
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await listOrSearchTasksAPI(params, {
          signal,
        })) as SearchTasksAPIResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(listOrSearchTasks.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(listOrSearchTasks.action.fulfilled, (state, action) => {
      const tasks = action.payload

      state.refreshedAt = new Date().toISOString()

      for (let task of tasks) {
        state.entities[task.task_id] = {
          data: task,
          status: EntityStatus.Loaded,
        }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    builder.addCase(listOrSearchTasks.action.rejected, (state, action) => {})
  },

  // TODO: We can do away with this after standardization
  addAllCases(builder: ActionReducerMapBuilder<ReduxTasksState>) {
    listOrSearchTasks.addPendingCase(builder)
    listOrSearchTasks.addRejectedCase(builder)
    listOrSearchTasks.addFulfilledCase(builder)
  },
}
