import { createSlice } from '@reduxjs/toolkit'
import { CollectionsSliceReducer as reducers } from './reducers'
import { CollectionsMiddleware } from './middleware'
import { EmptyCollectionsState } from './types'

const collectionsSlice = createSlice({
  name: 'collections',
  initialState: EmptyCollectionsState,
  reducers,
  extraReducers: (builder) => {
    CollectionsMiddleware.createCollection.addAllCases(builder)
    CollectionsMiddleware.fetchCollection.addAllCases(builder)
    CollectionsMiddleware.updateCollection.addAllCases(builder)
    CollectionsMiddleware.deleteCollection.addAllCases(builder)
    CollectionsMiddleware.hideCollection.addAllCases(builder)
    CollectionsMiddleware.shareCollection.addAllCases(builder)
    CollectionsMiddleware.useCollection.addAllCases(builder)
    CollectionsMiddleware.listCollections.addAllCases(builder)
  },
})

export default collectionsSlice.reducer

export const CollectionsSliceActions = collectionsSlice.actions
