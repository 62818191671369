// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

// Own libraries
import { NotesAPI } from '../api'
import { isNoteSyncRequired } from '../selectors/isNoteSyncRequired'
import { ThreeWayAnswer } from '../../../utils/Utils'
import { CRMProvider } from '../../../features/user/types'
import { NoteId, ReduxNoteEntity, ReduxNotesState } from '../types'
import { LuruAPIResponse } from '../../../app/types'
import { AppDispatch, RootState } from '../../../app/store'
import LuruError from '../../LuruError'

export type SyncNoteParameter = {
  noteId: NoteId
  crmId: CRMProvider
}

export interface SyncNoteAPIResponse extends LuruAPIResponse {
  data: ReduxNoteEntity
}

export const syncNote = {
  action: createAsyncThunk<
    SyncNoteAPIResponse['data'],
    SyncNoteParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | SyncNoteAPIResponse['metadata']
      rejectedMeta: null | SyncNoteAPIResponse['metadata']
    }
  >(
    'notes/syncNote',
    async (
      { noteId, crmId },
      { getState, fulfillWithValue, rejectWithValue, signal }
    ) => {
      var isSyncRequired =
        isNoteSyncRequired(noteId, crmId)(getState()) === ThreeWayAnswer.YES
      var currNote = getState().notes?.entities?.[noteId]?.data
      var currNoteId = currNote?.note_id

      if (!isSyncRequired && currNoteId) {
        return fulfillWithValue(currNote as ReduxNoteEntity, null)
      }

      try {
        var response = (await NotesAPI.syncNote(
          { noteId },
          { signal }
        )) as SyncNoteAPIResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    },
    {
      condition: ({ noteId }, { getState }) =>
        !getState()
          .notes.deletedNotes?.map((e) => e.note_id)
          .includes(noteId),
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(syncNote.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(syncNote.action.fulfilled, (state, action) => {
      try {
        var syncedNote = action.payload

        if (!syncedNote || !Array.isArray(syncedNote?.connections)) {
          return
        }

        syncedNote.connections
          .filter((x) => Boolean(x))
          .forEach((responseConn) => {
            var currEntity = state.entities[action.meta.arg.noteId]
            currEntity?.data?.connections?.forEach((conn) => {
              if (conn?.sor === responseConn.sor) {
                conn.synced_at = responseConn.synced_at
                conn.sor_version = responseConn.sor_version
                conn.synced_version = responseConn.synced_version
              }
            })
          })
      } catch (e) {
        console.warn(e)
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(syncNote.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    syncNote.addPendingCase(builder)
    syncNote.addFulfilledCase(builder)
    syncNote.addRejectedCase(builder)
  },
}
