import React from 'react'
import LuruUserDisplay from './LuruUserDisplay'

import styles from './css/LuruUserListDisplay.module.css'

export interface LuruUserListDisplayProps {
  users: Array<{
    userId: string
    name: string
    email: string
    isSelected: boolean
  }>
  onChooseUser?: (user: { userId: string; name: string; email: string }) => void
  isSelectableList: boolean
}

export default class LuruUserListDisplay extends React.Component<LuruUserListDisplayProps> {
  props: LuruUserListDisplayProps

  constructor(props: LuruUserListDisplayProps) {
    super(props)
    this.props = props
  }

  render() {
    if (Array.isArray(this.props.users) && this.props.users.length > 0) {
      if (this.props.onChooseUser !== undefined) {
        var onChooseUser = this.props.onChooseUser
        return (
          <ul className={styles.parent} data-role='luru-user-list-display'>
            {this.props.users.map((user) => (
              <li
                key={user.userId}
                className={styles.pointer}
                onMouseDown={(e) => onChooseUser(user)}
              >
                <LuruUserDisplay
                  name={user.name}
                  email={user.email}
                  isSelected={user.isSelected}
                  inSelectableList={this.props.isSelectableList}
                />
              </li>
            ))}
          </ul>
        )
      } else {
        return (
          <ul className={styles.parent} data-role='luru-user-list-display'>
            {this.props.users.map((user) => (
              <li key={user.userId}>
                <LuruUserDisplay
                  name={user.name}
                  email={user.email}
                  isSelected={user.isSelected}
                  inSelectableList={this.props.isSelectableList}
                />
              </li>
            ))}
          </ul>
        )
      }
    } else {
      return <></>
    }
  }
}
