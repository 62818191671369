import React from 'react'
import CrmRecord from '../../../../../domain/crmRecord/CrmRecord'
import styles from '../styles.module.css'
import LuruButton from '../../../../ui/LuruButton'
import Utils from '../../../../../utils/Utils'
import LoadingSpinner from '../../../../LoadingSpinner'
import update from 'immutability-helper'
import AppComponentsContext from '../../../../AppComponents/AppComponentsContext'
import { AppComponents } from '../../../../AppComponents/typings'
import { OmniboxRefs } from '../Omnibox'
import { ReduxTaskEntity, TaskStatus } from '../../../../../features/tasks/types'
import { LuruReduxStore } from '../../../../../app/store'
import { TasksMiddleware } from '../../../../../features/tasks/middleware'
import { ConnectLuruExtensionsProps, LuruAPIResponseErrorData } from '../../../../../app/types'
import newExplorerStyles from '../../../../../layout/css/NewExplorer.module.css'
// Icons
import addCircleIcon from '../../../../../images/fluent/add_circle.svg'
import personIcon from '../../../../../images/fluent/person.svg'
import checkBoxBlankIcon from '../../../../../images/fluent/check_box_blank.svg'
import checkBoxCheckedIcon from '../../../../../images/fluent/check_box_filled.svg'
import eventIcon from '../../../../../images/fluent/calendar_ltr.svg'
import { CrmRecordType } from '../../../../../features/crm/types'
import Tooltip from '@/components/Tooltip'
import { ToastId } from '@/app_ui/types'

interface TaskAPIRecordItem extends ReduxTaskEntity {
  task_id: string
}

interface ITasksTabPanelProps extends ConnectLuruExtensionsProps {
  crmRecordType: CrmRecordType
  sorRecordName: string
  sorRecordId: string
  globalRefs?: OmniboxRefs
  preventDefaultClick?: boolean
  onClickTask?: (task: TaskAPIRecordItem) => void
  onClickNewTask?: () => void
}

interface ITasksTabPanelStates {
  loading: boolean
  loadingMore: boolean
  data: ReduxTaskEntity[]
  metadata: { count?: number; cursor?: string }
  error: LuruAPIResponseErrorData
}

class TasksTabPanel extends React.Component<ITasksTabPanelProps> {
  props: ITasksTabPanelProps
  state: ITasksTabPanelStates
  constructor(props: ITasksTabPanelProps) {
    super(props)
    this.props = props
    this.state = {
      loading: true,
      loadingMore: false,
      data: [],
      error: null,
      metadata: {
        count: 10,
        cursor: undefined,
      },
    }
  }

  async #onToggleTask(e: React.MouseEvent, task: ReduxTaskEntity) {
    e.preventDefault()
    e.stopPropagation()

    var checkboxElem = e.currentTarget
    var nextState = task.status === TaskStatus.OPEN ? TaskStatus.COMPLETED : TaskStatus.OPEN
    var taskIx = this.state.data?.findIndex((t: ReduxTaskEntity) => t.task_id === task.task_id)

    checkboxElem.classList.add(styles.toggling)

    try {
      await LuruReduxStore.dispatch(
        TasksMiddleware.updateTask.action({
          task_id: task.task_id,
          status: nextState === 'OPEN' ? TaskStatus.OPEN : TaskStatus.COMPLETED,
        })
      ).unwrap()

      checkboxElem.classList.remove(styles.toggling)

      this.setState((prevState: ITasksTabPanelStates) =>
        update(prevState, {
          data: {
            [taskIx]: {
              status: {
                $set: nextState,
              },
            },
          },
        })
      )
    } catch (e) {
      checkboxElem.classList.remove(styles.toggling)
      console.log(`Error updating task:`, e)
    }
  }

  #hasNextPage() {
    const { metadata } = this.state
    return metadata?.cursor !== null && metadata?.cursor !== undefined
  }

  #onClickLoadMore = async () => {
    var { metadata } = this.state

    try {
      this.setState({ loadingMore: true })

      var { crmRecordType, sorRecordId } = this.props
      var sorObjectName: string = CrmRecord.getCrmRecordName(crmRecordType)
      var taskAPIRecordsAction = LuruReduxStore.dispatch(
        TasksMiddleware.listOrSearchTasks.action({
          key: 'TasksTabPanel',
          sor_object_name: sorObjectName,
          sor_record_id: sorRecordId,
          cursor: metadata.cursor,
          limit: metadata.count,
        })
      )
      var taskAPIRecords = await taskAPIRecordsAction.unwrap()

      this.setState({
        loadingMore: false,
        data: [...this.state.data, ...(taskAPIRecords || [])],
        metadata: (await taskAPIRecordsAction).meta || {},
      })
    } catch (error) {
      var e = error as LuruAPIResponseErrorData

      this.setState({ loadingMore: false })
      this.props.toast.showToast({
        id: ToastId.OMNIBOX_TOAST_ID,
        message: e?.message ?? 'Error while loading more tasks!',
        severity: 'error',
      })
      console.log('Error', error)
    }
  }

  #fetchTasks = async () => {
    this.setState({ error: null, loading: true })

    var { metadata } = this.state

    try {
      var { crmRecordType, sorRecordId } = this.props
      var sorObjectName: string = CrmRecord.getCrmRecordName(crmRecordType)
      var taskAPIRecordsAction = LuruReduxStore.dispatch(
        TasksMiddleware.listOrSearchTasks.action({
          key: 'TasksTabPanel',
          sor_object_name: sorObjectName,
          sor_record_id: sorRecordId,
          limit: metadata.count,
        })
      )
      var taskAPIRecords = await taskAPIRecordsAction.unwrap()

      this.setState({
        loading: false,
        data: taskAPIRecords || [],
        metadata: (await taskAPIRecordsAction).meta || {},
      })
    } catch (error) {
      this.setState({ loading: false, error: error })
    }
  }

  componentDidMount() {
    this.#fetchTasks()
  }

  #onChooseTask(item: TaskAPIRecordItem) {
    if (!this.props.preventDefaultClick) {
      var appComponents = this.context as AppComponents
      this.props.globalRefs?.modal?.current?.cancel?.()
      appComponents.taskViewModal?.current?.showModal(item)
    }
    this.props?.onClickTask?.(item)
  }

  #renderError = () => {
    return (
      <div className={styles.errorBlockContainer}>
        <div>Error: </div>
        <div style={{ fontWeight: 'bold' }}>{this.state.error?.message ?? 'Error occured while loading tasks'}</div>
        {this.state.error?.description ? <div>{this.state.error?.description}</div> : null}
        <LuruButton title='Try again' onClick={() => this.#fetchTasks()}>
          Try Again
        </LuruButton>
      </div>
    )
  }

  #renderTaskItem(item: ReduxTaskEntity) {
    return (
      <div className={styles.taskResult} onClick={() => this.#onChooseTask(item)}>
        <span className={styles.checkbox} onClick={(e) => this.#onToggleTask(e, item)}>
          <span className={styles.spinnerContainer}>
            <i className='mdc-button__icon' aria-hidden='true'>
              <LoadingSpinner size={6} color='#333' />
            </i>
          </span>
          <span className={styles.box}>
            <img
              src={item.status === 'OPEN' ? checkBoxBlankIcon : checkBoxCheckedIcon}
              alt={item.status === 'OPEN' ? 'uncheck' : 'checked'}
            />
          </span>
        </span>
        <label className={styles.title}>{item.title}</label>
        {/* <span className={styles.trailingButtons}> */}
        <span
          className={[
            styles.timestamp,
            item.due_date && item.due_date < new Date().toISOString() ? styles.overdue : '',
            !item.due_date ? styles.noduedate : '',
          ]
            .join(' ')
            .trim()}
        >
          <span className={styles.icon}>
            <img src={eventIcon} alt='event' />
          </span>
          <span title={item.due_date ? Utils.formatDate(item.due_date) : 'No due date'} className={styles.dueDateText}>
            {item.due_date ? Utils.formatDate(item.due_date) : 'No due date'}
          </span>
        </span>
        <span className={styles.assignee}>
          <span className={styles.icon}>
            <img src={personIcon} alt='person' />
          </span>
          <span
            className={styles.assigneeNames}
            title={item.assigned_to ? item.assigned_to.sor_record_name : 'Not assigned'}
          >
            {item.assigned_to ? item.assigned_to.sor_record_name : 'Not assigned'}
          </span>
        </span>
      </div>
    )
  }

  #renderTasksList() {
    const { data } = this.state
    if (data.length <= 0) {
      return <div className={styles.noResults}>No results found</div>
    }
    return (
      <div>
        <ul className={styles.resultsList} data-luru-role={`search-results-Tasks`}>
          {data.map((task) => (
            <li key={task.task_id}>{this.#renderTaskItem(task)}</li>
          ))}
        </ul>
        <div className={styles.loadMoreContainer}>
          <LuruButton
            title='More tasks'
            loading={this.state.loadingMore}
            onClick={this.#onClickLoadMore}
            disabled={!this.#hasNextPage()}
            variant='outline'
          >
            More
          </LuruButton>
        </div>
      </div>
    )
  }

  #renderTaskHeading() {
    return (
      <h1 className={newExplorerStyles.heading}>
        <span></span>
        <Tooltip label='New Task'>
          <button
            className={[newExplorerStyles.createButton, newExplorerStyles.actionButton].join(' ')}
            onClick={this.props.onClickNewTask}
          >
            <img src={addCircleIcon} alt='add' />
          </button>
        </Tooltip>
      </h1>
    )
  }

  public render() {
    const { loading, error } = this.state
    if (error) {
      return <div className={styles.errorBlock}>{this.#renderError()}</div>
    }
    if (loading) {
      return (
        <div className={[styles.loading, styles.loadingBlock, styles.loadingBlockContainer].join(' ')}>Loading...</div>
      )
    }
    return (
      <div style={{ height: '100%' }}>
        {this.#renderTaskHeading()}
        <div className={styles.searchResults}>{this.#renderTasksList()}</div>
      </div>
    )
  }
}

TasksTabPanel.contextType = AppComponentsContext

export default React.forwardRef(Utils.connectLuruExtensions(TasksTabPanel))
