import { LuruReduxStore, RootState } from '../../app/store'
import { RecentEntityType } from './types'
import { UserSliceActions } from './userSlice'

// Function to set default recent items as private notes
export const UserSliceHelpers = {
  setDefaultRecentItems() {
    var state = LuruReduxStore.getState() as RootState
    var defaultRecentItems = (
      state.notes.entities
        ? Object.keys(state.notes.entities)
            .map((id) => ({
              id,
              name: state.notes.entities[id]?.data.title,
              type: RecentEntityType.NOTE,
            }))
            .filter((i) => Boolean(i.name))
        : []
    ) as Array<{
      id: string
      name: string
      type: RecentEntityType.NOTE
    }>

    LuruReduxStore.dispatch(UserSliceActions.setRecentItems(defaultRecentItems))
  },
}
