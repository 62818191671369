import type { RootState } from '../../../../app/store'

export default function getReduxState(state: RootState): {
  userEmailDomain: string
  userFirstName: string
  userLastName: string
  state: RootState
} {
  var email = state?.user?.data?.email
  var userEmailDomain = email ? email.slice(email.indexOf('@') + 1) : ''

  var userFirstName = state?.user?.data?.firstName
  var userLastName = state?.user?.data?.lastName

  return {
    userEmailDomain,
    userFirstName,
    userLastName,
    state
  }
}
