import update from 'immutability-helper'
import React from 'react'
import { trackEvent } from '../../analytics/Ga'
import { LuruReduxStore } from '../../app/store'
import { EntityStatus, LuruEntryPoint } from '../../app/types'
import CrmRecord from '../../domain/crmRecord/CrmRecord'
import { CRMProvider } from '../../features/user/types'
import LuruUser from '../../domain/users/LuruUser'
import { crmMiddleware } from '../../features/crm/crmMiddleware'
import { NotesMiddleware } from '../../features/notes/middleware'
import { getNewNoteContent } from '../../features/notes/helpers/getNewNoteContent'
// import { meetingsAPI } from '../../features/meetings/meetingsMiddleware'
// import { notesMiddleware } from '../../features/notes/notesMiddleware'
// import { getNewNoteContent } from '../../features/notes/notesSlice'
// import { tasksAPI } from '../../features/tasks/tasksAPI'
import { getPlatform } from '../../utils/Utils'
import CreateSearchRecordDialogComponent, {
  AllNotesSearchResults,
  CreateSearchRecordDialogMode,
  CreateSearchRecordDialogState,
  MeetingsSearchResults,
  SearchCategory,
} from './CreateSearchRecordDialogComponent'
import styles from './styles.module.css'
import { ReduxTaskEntity, TaskStatus } from '../../features/tasks/types'
import { TasksMiddleware } from '../../features/tasks/middleware'
import { ReduxNoteEntity } from '../../features/notes/types'
import { MeetingsAPI } from '../../features/meetings/api'
import { SearchMeetingsAPIResponseEntity } from '../../features/meetings/middleware/searchMeetings'
import { CrmRecordType } from '../../features/crm/types'

interface CreateSearchRecordDialogEvents {
  onSearchBoxKeyDown: (e: KeyboardEvent) => void
  onTriggerSearch: (e: KeyboardEvent) => void
  onTriggerSORNotesSearch: () => void
  onClearSearch: () => void
  onResultDisplayCountAdjust: (category: SearchCategory) => void
  // Create functions
  onCreateNote: () => void
  onCreateNoteTemplate: () => void
  onCreateTask: () => void
  onCreateCrmRecord: (crmRecordType: CrmRecordType) => void
  onCreateMeetingNote: (meeting: SearchMeetingsAPIResponseEntity) => void
  // Navigate functions
  onChooseMeetingNote: (noteId: string | null) => void
  onChooseTask: (task: ReduxTaskEntity) => void
  onToggleTask: (e: React.MouseEvent, task: ReduxTaskEntity) => void
  onClickCrmRecord: (e: React.MouseEvent, sorObjectName: string, sorRecordId: string, sorRecordName: string) => void
}

export default class CreateSearchRecordDialogEventHandler {
  #component: CreateSearchRecordDialogComponent
  handlers: CreateSearchRecordDialogEvents
  handleSearchError: (category: string, error: any) => void

  constructor(component: CreateSearchRecordDialogComponent) {
    this.#component = component
    this.handlers = {
      onSearchBoxKeyDown: this.#onSearchBoxKeyDown.bind(this),
      onTriggerSearch: this.#onTriggerSearch.bind(this),
      onTriggerSORNotesSearch: this.#onTriggerSORNotesSearch.bind(this),
      onClearSearch: this.#onClearSearch.bind(this),
      onResultDisplayCountAdjust: this.#onResultDisplayCountAdjust.bind(this),
      onCreateNote: this.#onCreateNote.bind(this),
      onCreateNoteTemplate: this.#onCreateNoteTemplate.bind(this),
      onCreateTask: this.#onCreateTask.bind(this),
      onCreateCrmRecord: this.#onCreateCrmRecord.bind(this),
      onCreateMeetingNote: this.#onNewMeetingNoteClick.bind(this),
      onChooseMeetingNote: this.#onChooseMeetingNote.bind(this),
      onChooseTask: this.#onChooseTask.bind(this),
      onToggleTask: this.#onToggleTask.bind(this),
      onClickCrmRecord: this.#onClickCrmRecord.bind(this),
    }
    this.handleSearchError = this.#handleSearchError.bind(this)
    this.#component.componentDidMount = this.onComponentMount.bind(this)
    this.#component.componentWillUnmount = this.onComponentUnmount.bind(this)
    this.computeSearchBarShortcutKeyActions = this.computeSearchBarShortcutKeyActions.bind(this)
    this.computeShowSearchContainerCustomEvent = this.computeShowSearchContainerCustomEvent.bind(this)
  }

  onComponentMount() {
    //Only Add Event for WEBAPP OR NEWTAB
    if (
      LuruUser.getCurrentEntryPoint() === LuruEntryPoint.WEBAPP ||
      LuruUser.getCurrentEntryPoint() === LuruEntryPoint.NEWTAB
    ) {
      //showSearchContainer Custom Event Handler
      this.computeShowSearchContainerCustomEvent().setup()

      //KeyPress Event Handler
      this.computeSearchBarShortcutKeyActions().setup()
    }
  }

  onComponentUnmount() {
    //Only Remove Event for WEBAPP OR NEWTAB
    if (
      LuruUser.getCurrentEntryPoint() === LuruEntryPoint.WEBAPP ||
      LuruUser.getCurrentEntryPoint() === LuruEntryPoint.NEWTAB
    ) {
      //Removing showSearchContainer Custom Event Handler
      this.computeShowSearchContainerCustomEvent().teardown()

      //Removing KeyPress Event Handler
      this.computeSearchBarShortcutKeyActions().teardown()
    }
  }

  static getSearchModalElement() {
    return document.body.querySelector('[data-luru-role="create-or-search-record-dialog"]')
  }

  computeSearchBarShortcutKeyActions() {
    let onKeyDown = (event: KeyboardEvent) => {
      if (
        // !event.defaultPrevented &&
        event.key === 'j' &&
        (getPlatform() === 'Mac' ? event.metaKey : event.ctrlKey)
      ) {
        this.#component.showDialog()
        event.stopPropagation()
        event.preventDefault()
      }
    }

    onKeyDown = onKeyDown.bind(this)

    return {
      setup: () => document.addEventListener('keydown', onKeyDown, { capture: true }),
      teardown: () => document.removeEventListener('keydown', onKeyDown, true),
    }
  }

  computeShowSearchContainerCustomEvent() {
    return {
      setup: () => {
        var searchModalEl = CreateSearchRecordDialogEventHandler.getSearchModalElement()
        searchModalEl?.addEventListener?.('showSearchContainer', () => {
          this.#component.showDialog()
        })
      },

      teardown: () => {
        var searchModalEl = CreateSearchRecordDialogEventHandler.getSearchModalElement()
        searchModalEl?.removeEventListener?.('showSearchContainer', () => {
          this.#component.showDialog()
        })
      },
    }
  }

  #onSearchBoxKeyDown(e: KeyboardEvent) {
    // console.log(`#onSearchBoxKeyDown:`, e)
    if (e?.key === 'Escape') {
      this.#component.closeModal()
    }
  }

  #onClearSearch() {
    var emptyState = this.#component.getResetState()

    this.#component.setState({
      mode: CreateSearchRecordDialogMode.CREATE,
      search: emptyState.search,
      searchText: '',
      noteSearchCategory: 'luru',
    })
  }

  #onTriggerSearch(e: KeyboardEvent) {
    var emptyState = this.#component.getResetState(EntityStatus.Loading)
    var searchEl = e.target as HTMLInputElement

    this.#component.setState({
      mode: CreateSearchRecordDialogMode.SEARCH,
      search: emptyState.search,
      searchText: searchEl.value,
      noteSearchCategory: 'luru',
    })

    this.#initiateSearch(searchEl.value)
  }

  async #onTriggerSORNotesSearch() {
    var searchText = this.#component.state.searchText
    var searchResponse = await LuruReduxStore.dispatch(
      NotesMiddleware.searchSORNotes.action({
        q: searchText,
        limit: 5,
        key: 'searchDialog',
        refreshRequest: false,
      })
    )

    if (NotesMiddleware.searchSORNotes.action.fulfilled.match(searchResponse)) {
      this.#handleNotesSearchResults({
        payload: {
          data: searchResponse.payload as Array<ReduxNoteEntity>,
          metadata: {
            count: searchResponse.meta.count,
            cursor: searchResponse.meta.cursor,
          },
        },
      })
    } else if (NotesMiddleware.searchSORNotes.action.rejected.match(searchResponse)) {
      this.handleSearchError(SearchCategory.NOTE as string, searchResponse.error)
    }

    this.#component.setState((prevState) =>
      update(prevState, {
        search: {
          [SearchCategory.NOTE]: {
            status: { $set: EntityStatus.Loading },
            results: { $set: [] },
            error: { $set: undefined },
          },
        },
        noteSearchCategory: { $set: 'sor' },
      })
    )
  }

  async #onCreateNote() {
    // Dispatch request to Redux store to create new note
    var newNoteContent = getNewNoteContent()
    var createNoteResponse = await LuruReduxStore.dispatch(
      NotesMiddleware.createNote.action({
        title: newNoteContent.title,
        body: JSON.stringify(newNoteContent.body),
        draftNote: true,
      })
    )

    if (NotesMiddleware.createNote.action.fulfilled.match(createNoteResponse)) {
      var newNoteId = createNoteResponse?.payload?.note_id

      // Go to new note
      if (newNoteId) {
        this.#component.resetState()
        this.#component.closeModal()
        this.#component.props.router.navigate(`/notes/${newNoteId}`)
      }
    }
  }

  #onCreateNoteTemplate() {
    this.#component.props.router.navigate('/settings/meeting_playbooks', {
      state: { action: 'create_new' },
    })
    this.#component.resetState()
    this.#component.closeModal()
  }

  #onCreateTask() {
    this.#component.resetState()
    this.#component.closeModal()
    this.#component.props.appComponents.taskViewModal?.current?.showModal()
  }

  #onCreateCrmRecord(crmRecordType: CrmRecordType) {
    this.#component.resetState()
    this.#component.closeModal()
    // @ts-ignore
    this.#component.props.appComponents.createRecordDialog[crmRecordType].current?.showDialog()
  }

  #initiateSearch(query: string) {
    var calendarProvider = LuruUser.getCurrentUserCalendarName()
    var crmProvider = LuruUser.getCurrentUserCrmName()

    // Meetings search
    if (calendarProvider) {
      this.#initiateMeetingSearch(query)
    }

    if (crmProvider === CRMProvider.HUBSPOT) {
      const notesSearch = this.#initiateNotesSearch.bind(this)
      const tasksSearch = this.#initiateTasksSearch.bind(this)

      this.#initiateCrmSearch(query)
      setTimeout(() => notesSearch(query), 1200)
      setTimeout(() => tasksSearch(query), 2400)
    } else if (crmProvider === CRMProvider.PIPEDRIVE) {
      this.#initiateCrmSearch(query)
      this.#initiateNotesSearch(query)
    } else {
      this.#initiateCrmSearch(query)
      this.#initiateNotesSearch(query)
      this.#initiateTasksSearch(query)
    }
  }

  /** Initiate CRM record search function */
  #initiateCrmSearch(query: string) {
    var crmKey = LuruUser.getCurrentUserCrmName()?.toLowerCase()

    if (!crmKey) {
      return
    }

    // CRM record search
    LuruReduxStore.dispatch(
      // @ts-ignore
      crmMiddleware[crmKey].searchRecords.action({
        query,
        objects: CrmRecord.getPrimaryCrmObjects().map((obj) => obj.name),
        limit: 5,
      })
    )
      .then(this.#handleCrmResults.bind(this))
      .catch((error: any) => this.handleSearchError('CRM', error))
  }

  /** Initiate meeting search function */
  async #initiateMeetingSearch(query: string) {
    try {
      var response = (await MeetingsAPI.searchMeetings({
        key: 'createSearchRecordDialog',
        q: query,
        include_notes: true,
        limit: 5,
      })) as MeetingsSearchResults

      this.#handleMeetingsSearchResults(response)
    } catch (e) {
      this.#handleSearchError(SearchCategory.MEETING, e)
    }
  }

  /** Initiate notes search function */
  async #initiateNotesSearch(query: string) {
    // Notes search
    var searchResponse = await LuruReduxStore.dispatch(
      NotesMiddleware.searchLuruNotes.action({
        q: query,
        limit: 5,
        key: 'searchDialog',
        refreshRequest: false,
      })
    )

    if (NotesMiddleware.searchLuruNotes.action.fulfilled.match(searchResponse)) {
      this.#handleNotesSearchResults({
        payload: {
          data: searchResponse.payload as Array<ReduxNoteEntity>,
          metadata: {
            count: searchResponse.meta.count,
            cursor: searchResponse.meta.cursor,
          },
        },
      })
    } else if (NotesMiddleware.searchLuruNotes.action.rejected.match(searchResponse)) {
      this.handleSearchError(SearchCategory.NOTE as string, searchResponse.error)
    }
  }

  /** Initiate tasks search function */
  #initiateTasksSearch(query: string) {
    // Tasks search
    LuruReduxStore.dispatch(
      TasksMiddleware.listOrSearchTasks.action({
        key: 'CreateSearchRecordDialog',
        q: query,
        limit: 5,
        status: TaskStatus.OPEN,
      })
    )
      .unwrap()
      .then(this.#handleTasksSearchResults.bind(this))
      .catch((error: any) => this.#handleSearchError(SearchCategory.TASK, error))
  }

  #handleCrmResults(response: { payload?: any }) {
    this.#setSearchResults(SearchCategory.CRM_OBJECT, response.payload ?? [])
  }

  #handleNotesSearchResults(response: { payload: AllNotesSearchResults }) {
    this.#setSearchResults(
      SearchCategory.NOTE,
      response.payload.data.sort((a, b) => b.updated_at?.localeCompare(a.updated_at) ?? 0).filter((_, ix) => ix < 5)
    )
  }

  #handleMeetingsSearchResults(response: MeetingsSearchResults) {
    var results = response.data
    var now = new Date().valueOf()
    var fromNow = (d: string) => Math.abs(new Date(d).valueOf() - now)

    results.sort((meeting1, meeting2) => fromNow(meeting1.start_time) - fromNow(meeting2.start_time))
    results = results.filter((_, ix) => ix < 5)

    this.#setSearchResults(SearchCategory.MEETING, results)
  }

  #handleTasksSearchResults(response: ReduxTaskEntity[]) {
    this.#setSearchResults(SearchCategory.TASK, response)
  }

  #setSearchResults(category: SearchCategory, results: Array<any>) {
    this.#component.setState((prevState: CreateSearchRecordDialogState) =>
      update(prevState, {
        search: {
          [category]: {
            status: { $set: EntityStatus.Loaded },
            results: { $set: results },
          },
        },
      })
    )
  }

  #onResultDisplayCountAdjust(category: SearchCategory) {
    var listElement = document.body.querySelector(`ul[data-luru-role="search-results-${category}"]`)
    var moreButton = document.querySelector(`button[data-luru-role='show-more-button-${category}']`)

    if (listElement && moreButton) {
      if (listElement.classList.contains(styles.expanded)) {
        listElement.classList.remove(styles.expanded)
        moreButton.textContent = 'More'
      } else {
        listElement.classList.add(styles.expanded)
        moreButton.textContent = 'Less'
      }
    }
  }

  #handleSearchError(category: string, error: any) {
    console.log('handleSearchError:', category, error)
    this.#component.setState({
      search: {
        ...this.#component.state.search,
        [category]: {
          results: [],
          status: EntityStatus.ErrorLoading,
          error: error,
        },
      },
    })
  }

  #onNewMeetingNoteClick(meeting: SearchMeetingsAPIResponseEntity) {
    this.#component.resetState()
    this.#component.closeModal()
    this.#component.props.router.navigate('/meeting_notes', {
      state: {
        action: 'create_new',
        meetingId: meeting.meeting_id,
        meetingSubject: meeting.subject,
      },
    })
  }

  #onChooseMeetingNote(noteId: string | null) {
    if (!noteId) {
      return
    }

    this.#component.resetState()
    this.#component.closeModal()
    this.#component.props.router.navigate(`/meeting_notes/${noteId}`)
    trackEvent('accessed_search_meeting_note_result', LuruUser.getCurrentUserCrmName())
  }

  #onChooseTask(task: ReduxTaskEntity) {
    // Hide search results dialog on clicking task record
    // (Uncomment later if needed)
    // this.#component.resetState()
    // this.#component.closeModal()
    this.#component.props.appComponents.taskViewModal?.current?.showModal(task)
    trackEvent('accessed_search_task_result', LuruUser.getCurrentUserCrmName())
  }

  async #onToggleTask(e: React.MouseEvent, task: ReduxTaskEntity) {
    e.preventDefault()
    e.stopPropagation()

    var checkboxElem = e.currentTarget
    var nextState = task.status === 'OPEN' ? 'COMPLETED' : 'OPEN'
    var taskIx = this.#component.state.search.TASK.results?.findIndex(
      (t: ReduxTaskEntity) => t.task_id === task.task_id
    )

    checkboxElem.classList.add(styles.toggling)

    try {
      await LuruReduxStore.dispatch(
        TasksMiddleware.updateTask.action({
          task_id: task.task_id,
          status: nextState === 'OPEN' ? TaskStatus.OPEN : TaskStatus.COMPLETED,
        })
      ).unwrap()
      checkboxElem.classList.remove(styles.toggling)

      this.#component.setState((prevState: CreateSearchRecordDialogState) =>
        update(prevState, {
          search: {
            TASK: {
              results: {
                [taskIx]: {
                  status: {
                    $set: nextState,
                  },
                },
              },
            },
          },
        })
      )
    } catch (e) {
      checkboxElem.classList.remove(styles.toggling)
      console.log(`Error updating task:`, e)
    }
  }

  #onClickCrmRecord(e: React.MouseEvent, sorObjectName: string, sorRecordId: string, sorRecordName: string) {
    trackEvent('accessed_search_record_result', LuruUser.getCurrentUserCrmName())
    e.preventDefault()

    var crmRecordType = CrmRecord.getCrmRecordType(sorObjectName)

    // Don't hide search results dialog when opening CRM record
    // (Uncomment later, if needed)
    // this.#component.resetState()
    // this.#component.closeModal()

    this.#component.props.appComponents.omnibox?.current?.showDialog(crmRecordType, sorRecordId, sorRecordName)
    trackEvent('accessed_search_crm_record_result', LuruUser.getCurrentUserCrmName())
  }
}
