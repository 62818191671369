import { EntityStatus } from '../../app/types'

export enum ChatUser {
  HUMAN_USER = 'HUMAN_USER',
}

export enum ChatConversationType {
  CHANNEL = 'CHANNEL',
  USER = 'USER',
  HUMAN = 'HUMAN', // Applicable for GChat
  GROUP_CHAT = 'GROUP_CHAT', // Applicable for GChat
  SPACE = 'SPACE', // Applicable for GChat
  DIRECT_MESSAGE = 'DIRECT_MESSAGE', // Applicable for GChat
}

export enum ChatConversationChannelType {
  PUBLIC_CHANNEL = 'PUBLIC_CHANNEL',
  MSTEAMS_CHANNEL = 'MSTEAMS_CHANNEL',
  GROUP_CHAT = 'GROUP_CHAT', // Applicable for GChat
  SPACE = 'SPACE', // Applicable for GChat
  DIRECT_MESSAGE = 'DIRECT_MESSAGE', // Applicable for GChat
}

export interface ReduxChatUserEntity {
  id: string
  type: ChatUser
  handle: string
  first_name: string
  last_name: string
  display_name: string
  is_admin: boolean
  profile_pic_url: string
}

export interface ReduxChatConversationEntity {
  id: string
  type: ChatConversationType
  name: string
  data: {
    id: string
    type: ChatConversationChannelType
    handle: string
    display_name: string
    description: string
    profile_pic_url: null | string
  }
}

export const ReduxChatStateTTL = 60 * 60 * 1_000 // millisecs/hour

export interface ReduxChatState {
  status: {
    users: EntityStatus
    conversations: EntityStatus
  }
  refreshedAt: {
    users: string | undefined
    conversations: string | undefined
  }
  userEntities: Record<string, ReduxChatUserEntity>
  conversationEntities: Record<string, ReduxChatConversationEntity>
}

export const EmptyReduxChatState: ReduxChatState = {
  status: {
    users: EntityStatus.NotLoaded,
    conversations: EntityStatus.NotLoaded,
  },
  refreshedAt: {
    users: undefined,
    conversations: undefined,
  },
  conversationEntities: {},
  userEntities: {},
}
