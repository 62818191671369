import { forwardRef } from 'react'
import Utils from '../../../../utils/Utils'
import OmniboxComponent from './Omnibox'
import { connect, ConnectedProps } from 'react-redux'
import getReduxState from './getReduxState'
import getActions from './getActions'

const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type OmniboxConnectedProps = ConnectedProps<typeof connector>

export default forwardRef(Utils.connectLuruExtensions(connector(OmniboxComponent)))
