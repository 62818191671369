import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { TasksSliceActions } from '../../tasks/tasksSlice'
import { EmptyAppState, ReduxAppState } from '../types'

export const tasksSliceInvalidateCache = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(TasksSliceActions.invalidateCache, (state, action) => {
      state.tasks = { ...EmptyAppState.tasks }
    })
  },
}
