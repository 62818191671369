// Redux
import { createSlice } from '@reduxjs/toolkit'
import { NotesMiddleware } from './middleware'
import { MeetingsMiddleware } from '../meetings/middleware'

// 3rd party components
import { UserSliceActions } from '../user/userSlice'
import { NotesSliceReducers as reducers } from './reducers'
import { NotesSliceHelpers } from './helpers'
import { EmptyNotesState, ReduxNotesState } from './types'

const notesSlice = createSlice({
  name: 'notes',
  // This initialState is given only for having Typescript detect type of this
  // slice.  The data would be replaced by initial state
  initialState: EmptyNotesState,
  reducers,

  // Extra reducers
  extraReducers: (builder) => {
    NotesMiddleware.createNote.addAllCases(builder)
    NotesMiddleware.fetchNote.addAllCases(builder)
    NotesMiddleware.saveNote.addAllCases(builder)
    NotesMiddleware.deleteNote.addAllCases(builder)
    NotesMiddleware.createNoteConnection.addAllCases(builder)
    NotesMiddleware.deleteNoteConnection.addAllCases(builder)
    NotesMiddleware.syncNote.addAllCases(builder)
    NotesMiddleware.searchLuruNotes.addAllCases(builder)
    NotesMiddleware.searchSORNotes.addAllCases(builder)
    NotesMiddleware.getSorConnection.addAllCases(builder)
    NotesMiddleware.readNoteEntityFromRedux.addAllCases(builder)

    // When fetching meetings via search, we might get some notes objects
    // as a part of meeting.notes property. In case that note is not already
    // in "store.notes", add it here
    builder
      .addCase(MeetingsMiddleware.searchMeetings.action.fulfilled, (state, action) => {
        NotesSliceHelpers.addMeetingNotes(state, action.payload)
      })
      // Sign-in and sign-out user flows
      .addCase(UserSliceActions.signInUser, (state, action) => {
        Object.keys(state).forEach((key) => delete state[key as keyof ReduxNotesState])
        Object.assign(state, action.payload?.notes)
      })
      .addCase(UserSliceActions.signOutUser, (state, action) => {
        Object.keys(state).forEach((key) => delete state[key as keyof ReduxNotesState])
        Object.assign(state, action.payload?.notes)
      })

    // When fetching meetings via list, we might get some notes objects
    // as a part of meeting.notes property. In case that note is not already
    // in "store.notes", add it here
    builder.addCase(MeetingsMiddleware.listMeetings.action.fulfilled, (state, action) => {
      NotesSliceHelpers.addMeetingNotes(state, action.payload)
    })
  },
})

// Action creators
export const NotesSliceActions = notesSlice.actions

// Reducer
export default notesSlice.reducer
