import ReactGA from 'react-ga4'
import LuruUser from '../domain/users/LuruUser'

function initGA() {
  const GA_MEASUREMENT_ID = process.env.REACT_APP_DEBUG === 'true' ? 'G-W1QL2B0NHD' : 'G-DHQZ0V1XKF'
  // G-DHQZ0V1XKF should be enabled for Prod
  // G-W1QL2B0NHD is for staging
  if (LuruUser.isWebappContext()) {
    ReactGA.initialize(GA_MEASUREMENT_ID)
  } else {
    //Incase of extension context, bundle ga script, check README.md file for more
  }
}

/**
 * Track usage with Google Analytics
 * @param {string} action What is the usage?  Eg: addnote, deletenote etc.
 * @param {string} label Additional label (optional)
 * @param {number} value Additional value (optional)
 */
function trackEvent(action: string, label?: string, value?: number) {
  const isWebAppContext = LuruUser.isWebappContext()
  const appContext = isWebAppContext ? 'webapp' : 'chromeext'
  if (isWebAppContext) {
    // Call only in webapp context since we removed remotely hosted script for now as per google web store violation
    ReactGA.event({
      category: appContext,
      action,
      label,
      value,
    })
  }
}

function trackTiming(variable: string, value?: number, label?: string) {
  const isWebAppContext = LuruUser.isWebappContext()
  const entryPoint = LuruUser.getCurrentEntryPoint()

  if (isWebAppContext) {
    // Call only in webapp context since we removed remotely hosted script for now as per google web store violation
    if (typeof process !== 'undefined' && process.env?.REACT_APP_DEBUG === 'true') {
      console.log(`trackTiming:${label}:${variable}:${value}`)
    }

    ReactGA.event({
      category: `Performance/${entryPoint}`,
      action: variable,
      label,
      value,
    })
  }
}

export { initGA, trackEvent, trackTiming }
