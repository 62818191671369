import { createWorkflow } from './createWorkflow'
import { createV2Workflow } from './createV2Workflow'
import { fetchWorkflow } from './fetchWorkflow'
import { fetchV2Workflow } from './fetchV2Workflow'
import { updateWorkflow } from './updateWorkflow'
import { updateV2Workflow } from './updateV2Workflow'
import { deleteWorkflow } from './deleteWorkflow'
import { listWorkflows } from './listWorkflows'
import { pauseUnpauseWorkflow } from './pauseUnpauseWorkflow'
import { pauseUnpauseV2Workflow } from './pauseUnpauseV2Workflow'
import { fetchWorkflowSchema } from './fetchWorkflowSchema'

export const WorkflowsMiddleware = {
  fetchWorkflowSchema,
  createWorkflow,
  createV2Workflow,
  fetchWorkflow,
  fetchV2Workflow,
  updateWorkflow,
  updateV2Workflow,
  deleteWorkflow,
  listWorkflows,
  pauseUnpauseWorkflow,
  pauseUnpauseV2Workflow,
}
