import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { ViewsAPI } from '../api'
import { ReduxViewEntity, ReduxViewsState } from '../types'

export interface FetchViewParameter {
  force?: boolean
  viewId: string
}

export interface FetchViewAPIResponse extends LuruAPIResponse {
  data: ReduxViewEntity
}

export const fetchView = {
  action: createAsyncThunk<
    FetchViewAPIResponse['data'],
    FetchViewParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: FetchViewAPIResponse['metadata']
      rejectedMeta: FetchViewAPIResponse['metadata'] | null
    }
  >(
    'views/fetchView',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await ViewsAPI.fetchView(params, {
          signal,
        })) as FetchViewAPIResponse

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    },
    {
      condition: (params, { getState }) =>
        params.force ||
        (getState().views.entities?.[params.viewId]?.status !==
          EntityStatus.Loading &&
          getState().views.entities?.[params.viewId]?.status !==
            EntityStatus.Loaded) ||
        // When SOR views are filled with data using list views API, schema
        // is null for SFDC
        (!getState().views.entities?.[params.viewId]?.data?.output.schema &&
          getState().views.entities?.[params.viewId]?.status !==
            EntityStatus.Loading),
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(fetchView.action.pending, (state, action) => {
      if (state.entities[action.meta.arg.viewId]) {
        state.entities[action.meta.arg.viewId].status = EntityStatus.Loading
      } else {
        state.entities[action.meta.arg.viewId] = {
          status: EntityStatus.Loading,
        }
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(fetchView.action.fulfilled, (state, action) => {
      if (state.entities[action.meta.arg.viewId]) {
        state.entities[action.meta.arg.viewId].status = EntityStatus.Loaded
        state.entities[action.meta.arg.viewId].data = { ...action.payload }
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    builder.addCase(fetchView.action.rejected, (state, action) => {
      if (state.entities[action.meta.arg.viewId]) {
        state.entities[action.meta.arg.viewId].status = action.meta.aborted
          ? EntityStatus.NotLoaded
          : EntityStatus.ErrorLoading
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxViewsState>) {
    fetchView.addPendingCase(builder)
    fetchView.addFulfilledCase(builder)
    fetchView.addRejectedCase(builder)
  },
}
