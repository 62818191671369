import React, { CSSProperties } from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import NewNotesTabPanelEventHandler from './NewNotesTabPanelEventHandler'
import type { NewNotesTabPanelConnectedProps } from '.'
import CrmRecord from '../../../../../../domain/crmRecord/CrmRecord'
import { ReduxNoteEntity } from '../../../../../../features/notes/types'
import { ConnectLuruExtensionsProps, EntityStatus } from '../../../../../../app/types'
import LoadingSpinner from '../../../../../../primitives/LoadingSpinner'
import LuruUser from '../../../../../../domain/users/LuruUser'
import LuruButton from '../../../../../../primitives/ui/LuruButton'
import AppComponentsContext from '../../../../../../primitives/AppComponents/AppComponentsContext'
import infoIcon from '../../../../../../images/info.svg'
import calendarIcon from '../../../../../../images/calendar.png'
import styles from './NewNotesTabPanel.module.css'
import explorerStyles from '../../../../../../layout/css/Explorer.module.css'
import newExplorerStyles from '../../../../../../layout/css/NewExplorer.module.css'
// Icons
import circleDownIcon from '../../../../../../images/fluent/chevron_circle_down.svg'
import addCircleIcon from '../../../../../../images/fluent/add_circle.svg'
import { CrmRecordType } from '../../../../../../features/crm/types'
import Tooltip from '@/components/Tooltip'

export interface NewNotesTabPanelProps extends NewNotesTabPanelConnectedProps, ConnectLuruExtensionsProps {
  crmRecordType: CrmRecordType
  sorRecordId: string
  sorRecordName: string
  onNavigateAway: () => void
  explorerStyle: CSSProperties
  containerStyle: CSSProperties
  disableLink: boolean
  onClickNote: (note: ReduxNoteEntity) => void
  onClickNewNote: (note: ReduxNoteEntity) => void
}

export interface NewNotesTabPanelRefs {
  // Add other ref elements as required
}

export interface NewNotesTabPanelState {
  notes: {
    luru: {
      results: Array<ReduxNoteEntity>
      status: EntityStatus
      cursor: string | null
      error: { message: string } | null
      endIndex: number
    }
    sor: {
      results: Array<ReduxNoteEntity>
      status: EntityStatus
      cursor: string | null
      error: { message: string } | null
      endIndex: number
    }
  }
  sorNotesDisplay: 'collapsed' | 'expanded'
}

export default class NewNotesTabPanelComponent extends React.Component<NewNotesTabPanelProps, NewNotesTabPanelState> {
  props: NewNotesTabPanelProps
  state: NewNotesTabPanelState
  eventHandler: NewNotesTabPanelEventHandler
  pageSize: number = 10
  static contextType?: React.Context<any> = AppComponentsContext

  constructor(props: NewNotesTabPanelProps) {
    super(props)
    this.props = props
    this.state = {
      notes: {
        luru: {
          results: [],
          status: EntityStatus.NotLoaded,
          cursor: null,
          error: null,
          endIndex: this.pageSize - 1,
        },
        sor: {
          results: [],
          status: EntityStatus.NotLoaded,
          cursor: null,
          error: null,
          endIndex: this.pageSize - 1,
        },
      },
      sorNotesDisplay: 'collapsed',
    }
    this.eventHandler = new NewNotesTabPanelEventHandler(this)
  }

  render() {
    return (
      <div className={styles.parent} style={this.props.containerStyle}>
        <aside className={explorerStyles.explorer} style={this.props.explorerStyle}>
          {this.#renderLuruNotesHeading()}
          {this.#renderNotes('luru', this.state.notes.luru)}
          {this.#renderLoadMoreButton('luru')}
          {this.#renderSORNotesHeading()}
          {this.#renderSORNotes()}
          {this.#renderLoadMoreButton('sor')}
        </aside>
      </div>
    )
  }

  #renderLuruNotesHeading() {
    return (
      <h1 className={newExplorerStyles.heading}>
        <span>Luru Notes</span>
        <Tooltip label='New Note'>
          <button
            className={[newExplorerStyles.createButton, newExplorerStyles.actionButton].join(' ')}
            onClick={this.eventHandler.handlers.onClickNewNote}
          >
            <img src={addCircleIcon} alt='add' />
          </button>
        </Tooltip>
      </h1>
    )
  }

  #renderSORNotesHeading() {
    return (
      <h1 className={newExplorerStyles.heading}>
        <span>{CrmRecord.getCrmName()} Notes</span>
        <Tooltip
          label={`${this.state.sorNotesDisplay === 'collapsed' ? 'Show' : 'Hide'} ${CrmRecord.getCrmName()} notes`}
        >
          <button
            className={[
              newExplorerStyles.createButton,
              newExplorerStyles.actionButton,
              newExplorerStyles.expandCollapseButton,
              this.state.sorNotesDisplay === 'expanded' ? newExplorerStyles.upsideDown : null,
            ].join(' ')}
            onClick={this.eventHandler.handlers.onCollapseExpandSorNotes}
          >
            <img src={circleDownIcon} alt='expandDown' />
          </button>
        </Tooltip>
      </h1>
    )
  }

  #renderSORNotes() {
    return this.state.sorNotesDisplay === 'collapsed' ? null : this.#renderNotes('sor', this.state.notes.sor)
  }

  #renderNotes(
    section: 'luru' | 'sor',
    data: {
      status?: EntityStatus
      error?: { message: string } | null
      cursor: string | null
      results?: Array<ReduxNoteEntity>
    }
  ) {
    if (data.status === EntityStatus.ErrorLoading) {
      return this.#renderError(data.error?.message ?? 'Error loading items')
    }

    if (data.status === EntityStatus.Loading) {
      return (
        <div className={newExplorerStyles.emptyPanel}>
          <LoadingSpinner size={12} color='--var(--brand-accent-color-blue)' />
          Loading notes...
        </div>
      )
    }

    if (!data.results?.length) {
      return <div className={newExplorerStyles.emptyPanel}>No notes</div>
    }

    return (
      <ul className={newExplorerStyles.recordList}>
        {data.results
          ?.filter((_, ix) => ix <= this.state.notes[section].endIndex)
          .map((note) => {
            var meetingConnection = note.connections?.find((c) => c.sor === LuruUser.getCurrentUserCalendarName())
            var meetingName = meetingConnection?.sor_record_name
            var meetingInfo = <div>{meetingName}</div>

            return (
              <Wrapper
                key={note.note_id}
                wraper={(children) =>
                  this.props.disableLink ? (
                    <div
                      key={note.note_id}
                      onClick={() => {
                        this.props?.onNavigateAway?.()
                        this.props?.onClickNote?.(note)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {children}
                    </div>
                  ) : (
                    <NavLink
                      key={note.note_id}
                      to={`/notes/${note.note_id}`}
                      onClick={() => {
                        this.props?.onNavigateAway?.()
                        this.props?.onClickNote?.(note)
                      }}
                    >
                      {children}
                    </NavLink>
                  )
                }
              >
                <li key={note.note_id}>
                  <div className={newExplorerStyles.oneLineRecordDisplay}>
                    <div className={newExplorerStyles.recordTitle} title={note.title}>
                      {note.title}
                    </div>
                    {meetingName ? (
                      <Tooltip label={meetingInfo} placement='right'>
                        <img src={calendarIcon} alt='Meeting note' />
                      </Tooltip>
                    ) : (
                      <img src={calendarIcon} data-luru-disable='true' alt='Meeting note' />
                    )}
                    <div className={newExplorerStyles.recordInfo}>
                      {moment(note.updated_at ?? note.created_at).format('DD-MMM-yy')}
                    </div>
                  </div>
                </li>
              </Wrapper>
            )
          })}
      </ul>
    )
  }

  #renderLoadMoreButton(section: 'luru' | 'sor') {
    if (section === 'sor' && this.state.sorNotesDisplay === 'collapsed') {
      return null
    }

    var displayedData = this.state.notes[section]
    var isCursorNonNull = displayedData.cursor !== null
    var renderedResults = displayedData.results
    var doRenderLoadMoreButton =
      (isCursorNonNull || (renderedResults?.length ?? -1) > this.state.notes[section].endIndex + 1) &&
      displayedData.status !== EntityStatus.Loading

    var isLoadingMore = displayedData.status === EntityStatus.LoadingMore

    return doRenderLoadMoreButton ? (
      <LuruButton
        title={isLoadingMore ? 'Loading' : 'Load more'}
        variant='outline'
        loading={isLoadingMore}
        loadingSize={8}
        disabled={isLoadingMore}
        onClick={isLoadingMore ? undefined : () => this.eventHandler.handlers.onClickLoadMoreNotes(section)}
      >
        {isLoadingMore ? 'Loading' : 'More'}
      </LuruButton>
    ) : null
  }

  #renderError(message: string) {
    return (
      <div className={explorerStyles.noResults}>
        <div className={explorerStyles.warning} title={message}>
          <img src={infoIcon} alt={message} />
          <span>Error loading items</span>
        </div>
      </div>
    )
  }

  getCRMConnection(note: ReduxNoteEntity) {
    var crmName = LuruUser.getCurrentUserCrmName()
    return note.connections?.find((c) => c.sor === crmName)
  }
}

function Wrapper(props: { children: JSX.Element; wraper?: (children: JSX.Element) => JSX.Element }) {
  return props.wraper ? props.wraper(props.children) : props.children
}
