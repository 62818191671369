import React from 'react'
import update from 'immutability-helper'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { ConnectLuruExtensionsProps, EntityStatus } from '../../../../app/types'
import { CrmObjectSchema } from '../../../../domain/crmRecord/typings.d'
import ModalScreen from '../../../ModalScreen'
import LuruCrmFieldInputSet from '../LuruCrmFieldInputSet'
import CreateCrmRecordDialogEventHandler from './CreateCrmRecordDialogEventHandler'

import styles from './styles.module.css'
import LuruCollectionsComponent from '../../collections/LuruCollections/LuruCollectionsComponent'
import LuruCollectionsQuickAccessBar from '../../collections/LuruCollectionsQuickAccessBar'
import LuruCollectionsQuickAccessBarComponent from '../../collections/LuruCollectionsQuickAccessBar/LuruCollectionsQuickAccessBarComponent'
import LuruUser from '../../../../domain/users/LuruUser'
import { CalendarProvider, EntryPoint } from '../../../../features/user/types'
import { CreateCrmRecordDialogConnectedProps } from '.'
import { CrmRecordType } from '../../../../features/crm/types'
import LuruButton from '@/primitives/ui/LuruButton'

export interface CreateCrmRecordDialogProps extends CreateCrmRecordDialogConnectedProps, ConnectLuruExtensionsProps {
  id: string
  crmRecordType: CrmRecordType
}

export enum FieldsetSelection {
  DEFAULT = 'DEFAULT',
  COLLECTION = 'COLLECTION',
  MANUAL = 'MANUAL',
}

interface CreateCrmRecordDialogState {
  fieldNamesStatus: EntityStatus
  fieldNames: Array<string>
  fieldValues: { [fieldName: string]: any }
  mandatoryFields?: Array<string>
  readOnlyFields: Array<string>
  missedMandatoryFields: Array<string>
  createRecordStatus: EntityStatus
  selectionMode: FieldsetSelection
  alertMessage?: string
  errorFields?: Array<string>
  linkRecordAfterCreation?: Function
}

interface CreateCrmRecordDialogRefs {
  modal: React.RefObject<ModalScreen>
  luruCollectionsRef: React.RefObject<LuruCollectionsComponent>
  luruCollectionsQuickAccessBarRef: React.RefObject<LuruCollectionsQuickAccessBarComponent>
}

export default class CreateCrmRecordDialogComponent extends React.Component<
  CreateCrmRecordDialogProps,
  CreateCrmRecordDialogState
> {
  props: CreateCrmRecordDialogProps
  state: CreateCrmRecordDialogState
  componentRefs: CreateCrmRecordDialogRefs
  eventHandler: CreateCrmRecordDialogEventHandler
  #schema?: CrmObjectSchema

  constructor(props: CreateCrmRecordDialogProps) {
    super(props)
    this.props = props
    this.state = this.getEmptyState()

    this.componentRefs = {
      modal: React.createRef(),
      luruCollectionsRef: React.createRef(),
      luruCollectionsQuickAccessBarRef: React.createRef(),
    }
    this.eventHandler = new CreateCrmRecordDialogEventHandler(this)
  }

  getEmptyState = () => ({
    fieldNamesStatus: EntityStatus.NotLoaded,
    fieldNames: [],
    fieldValues: {},
    mandatoryFields: [],
    readOnlyFields: [],
    missedMandatoryFields: [],
    createRecordStatus: EntityStatus.Idle,
    selectionMode: FieldsetSelection.DEFAULT,
    alertMessage: undefined,
    params: undefined,
  })

  isWebAppEntryPoint() {
    return LuruUser.getCurrentEntryPoint()?.toLowerCase() === EntryPoint.WEBAPP?.toLowerCase()
  }

  showDialog = (callback?: Function, noteId?: string) => {
    const crmPrimaryRecoryType = CrmRecord.getAllPrimaryObjects()

    this.componentRefs.modal.current?.showModal()
    // Calling this method to make sure to get latest fields from collection incase we updated it.
    this.eventHandler.handlers.loadSchema()
    this.setState({
      linkRecordAfterCreation: callback,
      fieldValues:
        this.props.crmRecordType === crmPrimaryRecoryType.CONTACT
          ? { ...this.state.fieldValues, ...this.getDefaultValueForContactRecord(noteId) }
          : { ...this.state.fieldValues },
    })
  }

  render = () => {
    const crmPrimaryRecoryType = CrmRecord.getAllPrimaryObjects()
    var objectName = CrmRecord.getCrmRecordNameSingular(this.props.crmRecordType)
    const crmRecordIcon = CrmRecord.getIcon(this.props.crmRecordType || crmPrimaryRecoryType.DEAL)

    var collectionQuickAccessBar = (
      <LuruCollectionsQuickAccessBar
        crmRecordType={this.props.crmRecordType}
        useRecentUsedCollectionAsDefaultSelected={true}
        onChooseCollection={this.eventHandler.handlers.onChooseCollection}
        onCollectionLoaded={this.eventHandler.handlers.onChooseCollection}
        ref={this.componentRefs.luruCollectionsQuickAccessBarRef}
        luruFieldSetChooserProps={{
          crmRecordType: this.props.crmRecordType,
          selectedFieldNames: this.state.fieldNames,
          disableMandatory: true,
          disableNonUpdateable: true,
          disableNonCreateable: true,
          hideNonUpdateable: true,
          hideNonCreateable: true,
          onChooseFields: this.eventHandler.handlers.onChooseFields,
          forceBottomPosition: true,
        }}
        containerClassName={styles.quickAccess}
      />
    )
    var modalTitle = (
      <div className={styles.modalTitle}>
        <span>
          <img
            src={crmRecordIcon}
            alt={objectName}
            data-crm-id={LuruUser.getCurrentUserCrmName()}
            data-sor-object-name={objectName}
          />
        </span>
        <span>{`Create ${objectName}`}</span>
      </div>
    )

    return (
      <ModalScreen
        ref={this.componentRefs.modal}
        id={this.props.id}
        height='auto'
        hideButtons={true}
        title={modalTitle}
        titleCloseButton={true}
        dialogRole='create-crm-record-dialog'
      >
        {this.state.alertMessage !== undefined ? <div className={styles.alert}>{this.state.alertMessage}</div> : null}
        <div className={styles.quickAccessContainer}>{collectionQuickAccessBar}</div>
        <div className={styles.createRecordDialog}>{this.#renderFields()}</div>
        <div className={styles.actionButtons}>
          <LuruButton
            role='create-crm-record-cancel-button'
            onClick={this.eventHandler.handlers.onClickCancelButton}
            title='Cancel'
            variant='outline'
          >
            Cancel
          </LuruButton>
          <LuruButton
            role='create-crm-record-save-button'
            onClick={this.eventHandler.handlers.onClickCreateButton}
            disabled={this.state.createRecordStatus === EntityStatus.Creating}
            loading={this.state.createRecordStatus === EntityStatus.Creating}
            title={`Create ${objectName}`}
          >
            Create
          </LuruButton>
        </div>
      </ModalScreen>
    )
  }

  #renderFields = () => {
    switch (this.state.fieldNamesStatus) {
      case EntityStatus.Loaded:
        return (
          <div className={styles.fieldSet}>
            <LuruCrmFieldInputSet
              crmRecordType={this.props.crmRecordType}
              fieldNames={this.state.fieldNames}
              // hideReadonlyFields={true}
              // hideNonCreateableFields={true}
              disableNonCreateableFields={true}
              onChange={this.eventHandler.handlers.onFieldValueChange}
              menuParentSelector={`#${this.props.id}`}
              alertFieldNames={this.state.errorFields ?? this.state.missedMandatoryFields}
              showErroneousFields={true}
              defaultValues={this.state?.fieldValues}
            />
          </div>
        )

      case EntityStatus.ErrorLoading:
        return <div className={styles.error}>Error fetching field names</div>
    }

    return <div className={styles.loading}>Loading...</div>
  }

  setSchema = (schema: CrmObjectSchema) => {
    this.#schema = schema
    const mandatoryFields = schema.payload?.fields?.filter((f) => f.isMandatory).map((f) => f.name) || []
    const allFields = schema.payload?.fields?.map((f) => f.name) || []
    this.setState({
      fieldNames: mandatoryFields.length > 0 ? mandatoryFields : allFields,
      fieldNamesStatus: EntityStatus.Loaded,
      fieldValues: Object.assign(
        schema.payload?.fields?.reduce(
          (prev: { [_: string]: any }, f) => Object.assign(prev, { [f.name]: f.defaultValue }),
          {}
        ) ?? {},
        this.state.fieldValues
      ),
      mandatoryFields: mandatoryFields,
      readOnlyFields: schema.payload?.fields?.filter((f) => !f.updateable)?.map((f) => f.name) ?? [],
    })
  }

  getDefaultValueForContactRecord(noteId: string | undefined) {
    let userDomain = this.props.state.user.data?.email?.split?.('@')?.[1]
    let meetingId = noteId
      ? this.props.state.notes.entities[noteId]?.data?.connections?.find?.(
          (e) => e?.sor === (CalendarProvider.GCAL || CalendarProvider.GCAL)
        )?.sor_record_id
      : ''
    let connectedMeetingParticipants = meetingId
      ? this.props.state.meetings.entities[meetingId]?.data?.participants
      : []
    let firstname = ''
    let lastname = ''
    let email = ''

    // Filtering the meeting participants who is outside contact.
    let filteredParticipants = connectedMeetingParticipants?.filter(
      (participant) => participant.email.split('@')[1] !== userDomain
    )

    if (filteredParticipants?.length !== 0) {
      email = filteredParticipants[0]?.email

      for (let participant of filteredParticipants) {
        if (participant?.name) {
          let nameParts = participant?.name?.split?.(' ')
          firstname = nameParts[0]
          nameParts.shift()
          lastname = nameParts.join(' ')
          email = participant?.email
          break
        }
      }
      return {
        firstname,
        lastname,
        email,
      }
    } else {
      return {
        firstname,
        lastname,
        email,
      }
    }
  }

  getSchema = () => this.#schema

  setFieldValue = (fieldName: string, value: any) =>
    this.setState((prevState) =>
      update(prevState, {
        fieldValues: {
          [fieldName]: { $set: value },
        },
      })
    )
}
