import { RootState } from '../../../app/store'
import { ReduxNotesStateTTL } from '../types'

export const isNotesCacheValid = (state: RootState) =>
  !Boolean(state.notes.refreshedAt) ||
  new Date().valueOf() -
    (state.notes.refreshedAt
      ? new Date(state.notes.refreshedAt).valueOf()
      : 0) <
    ReduxNotesStateTTL
