import { LuruReduxStore } from '../../../app/store'
import { ReduxMeetingEntity } from '../types'

export interface FilterMeetingsSearchResultsParameter {
  meetingUrl: string
  key: string
}

export const filterSearchResults = ({ meetingUrl, key }: FilterMeetingsSearchResultsParameter) => {
  var state = LuruReduxStore.getState()
  var searchResults = state.meetings.search?.[key]?.results

  // Get a clean meeting url
  meetingUrl = meetingUrl.replace(/_meet\/([a-z-]+)\?.*$/, '$1')

  if (!Array.isArray(searchResults) || searchResults?.length <= 0) {
    return null
  }

  var meetingRecord: ReduxMeetingEntity | null = null

  if (meetingUrl === 'undefined') {
    // meetingUrl will be undefined for zoom app so, find the best match based on current time
    meetingRecord =
      [...searchResults]
        .sort((meeting1, meeting2) => {
          let currDate = new Date()
          let date1 = new Date(meeting1.end_time)
          let date2 = new Date(meeting2.end_time)

          return Math.abs(currDate.valueOf() - date1.valueOf()) - Math.abs(currDate.valueOf() - date2.valueOf())
        })
        .filter((meeting) =>
          Boolean(
            meeting?.locations?.find(
              (location) =>
                (location?.type === 'online' || location?.type === 'physical') && location?.location.includes('zoom.us')
            )
          )
        )
        .find((meeting) => {
          let currentTime = new Date()
          let startTime = new Date(meeting.start_time)
          let endTime = new Date(meeting.end_time)
          return currentTime >= startTime && currentTime <= endTime
        }) ?? null
  } else {
    meetingRecord =
      [...searchResults]
        .sort((meeting1, meeting2) => {
          let currDate = new Date()
          let date1 = new Date(meeting1.end_time)
          let date2 = new Date(meeting2.end_time)

          return Math.abs(currDate.valueOf() - date1.valueOf()) - Math.abs(currDate.valueOf() - date2.valueOf())
        })
        .find((meeting) =>
          Boolean(
            meeting?.locations?.find(
              (location) =>
                (location?.type === 'online' || location?.type === 'physical') &&
                (location?.location.includes(meetingUrl) || meetingUrl.includes(location?.location))
            )
          )
        ) ?? null
  }
  return meetingRecord
}
