import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { NotesSliceActions } from '../../notes/notesSlice'
import { EmptyAppState, ReduxAppState } from '../types'

export const notesSliceInvalidateCache = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(NotesSliceActions.invalidateCache, (state, action) => {
      state.home.default.luruNotes = { ...EmptyAppState.home.default.luruNotes }
      state.home.default.sorNotes = { ...EmptyAppState.home.default.sorNotes }
      state.home.search.query = null
      state.home.search.luruNotes = { ...EmptyAppState.home.search.luruNotes }
      state.home.search.sorNotes = { ...EmptyAppState.home.search.sorNotes }
      state.home.notes = { ...EmptyAppState.home.notes }
    })
  },
}
