import CreateCrmRecordDialogComponent from './CreateCrmRecordDialogComponent'
import { connect, ConnectedProps } from 'react-redux'
import getActions from './getActions'
import getReduxState from './getReduxState'
import Utils from '@/utils/Utils'
import React from 'react'
const connector = connect(getReduxState, getActions, null, { forwardRef: true })

export type CreateCrmRecordDialogConnectedProps = ConnectedProps<typeof connector>

export default connector(React.forwardRef(Utils.connectLuruExtensions(CreateCrmRecordDialogComponent)))
