import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { EntityStatus } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { CrmObjectName, CrmRecordType, ReduxCrmKey } from '../../../features/crm/types'
import { getSORConnection } from '../../../features/notes/selectors/getSORConnection'
import LoadingSpinner from '../../../primitives/LoadingSpinner'
import { EditorEntityType } from '../../EditorController'
import styles from './EmbeddedCrmField.module.css'
import deleteIcon from '../../../images/fluent/delete.svg'
import warningIcon from '../../../images/fluent/error_circle.svg'
import { EditableCellWrapper } from '../../../forms/GlobalSearchResultView/RecordView'
import { useSchema } from '../../../features/crm/hooks/useSchema'
import { useFieldValues } from '../../../features/crm/hooks/useFieldValues'
import recordViewStyles from '../../../forms/GlobalSearchResultView/RecordView/styles.module.css'
import { SourceEntityType } from '../../../routes/notes/components/CRMCollectionChooser'
import Tooltip from '@/components/Tooltip'
import readOnlyIcon from '@/images/readonly-icon.svg'

export interface EmbeddedCrmFieldProps {
  entityType: (typeof EditorEntityType)[keyof typeof EditorEntityType]
  entityId: string | undefined
  fieldName: string
  isReadOnly: boolean
  onDeleteJotting: () => void
}

export interface EmbeddedCrmFieldState {
  userAlertMessage: string
  fieldStatus: EntityStatus
}

export const EmbeddedCrmField = ({
  entityId,
  entityType,
  fieldName,
  isReadOnly,
  onDeleteJotting,
}: EmbeddedCrmFieldProps) => {
  var content = <></>

  // Connected CRM
  const crmId = useAppSelector((s) => s.user.data?.userSettings?.connectedCRM?.name)

  // Linked CRM Record
  const linkedCRMRecord = useAppSelector(getSORConnection(entityId, crmId, null))

  // Linked object name for template
  const templateLinkedObject = useAppSelector((s) =>
    entityId ? s.noteTemplates.entities[entityId]?.data?.filter?.sorObjectName : null
  )

  const crmRecordType =
    entityType === SourceEntityType.NOTE
      ? linkedCRMRecord?.connection?.sor_object_name
        ? CrmRecord.getCrmRecordType(linkedCRMRecord?.connection?.sor_object_name)
        : undefined
      : CrmRecord.getCrmRecordType(templateLinkedObject ?? '')

  // Record status
  const crmKey = crmId?.toLocaleLowerCase() as ReduxCrmKey

  // Record object name
  const sorObjectName = CrmRecord.getCrmRecordName(crmRecordType as CrmRecordType) as CrmObjectName | undefined

  const sorRecordId = entityType === SourceEntityType.NOTE ? linkedCRMRecord?.connection?.sor_record_id : undefined

  const recordStatus = useAppSelector((state) =>
    entityType === SourceEntityType.NOTE && crmId && sorObjectName && sorRecordId
      ? state.crm[crmKey]?.entities?.[sorObjectName]?.[sorRecordId]?.status
      : undefined
  )

  const [state, setState] = useState<EmbeddedCrmFieldState>({
    userAlertMessage: '',
    fieldStatus: EntityStatus.Idle,
  })

  const handleUpdateError = useCallback((message: string | undefined) => {
    setState((prevState) => ({
      ...prevState,
      userAlertMessage: message ?? '',
    }))
  }, [])

  // Schema
  const { schemaMap } = useSchema(sorObjectName)

  // Field schema
  const fieldSchema = schemaMap?.fields?.[fieldName]

  // Field value
  const fieldValue = useFieldValues(
    fieldSchema?.controllerName ? [fieldName, fieldSchema?.controllerName] : [fieldName],
    sorObjectName,
    sorRecordId
  )

  if (entityType === EditorEntityType.Note && !linkedCRMRecord) {
    content = <div className={styles.info}>Please connect note to a {CrmRecord.getCrmName()} object</div>
  }

  if (entityType === EditorEntityType.NoteTemplate && (!templateLinkedObject || templateLinkedObject === 'All')) {
    content = <div className={styles.info}>Please link playbook to an object</div>
  }

  if (recordStatus !== EntityStatus.Loaded && entityType === SourceEntityType.NOTE) {
    content = (
      <div className={styles.info}>
        <LoadingSpinner />
        Loading...
      </div>
    )
  }

  useEffect(() => {
    if (
      recordStatus !== EntityStatus.Loading &&
      recordStatus !== EntityStatus.Loaded &&
      recordStatus !== EntityStatus.ErrorLoading &&
      recordStatus !== EntityStatus.Updating &&
      recordStatus !== EntityStatus.Updated &&
      recordStatus !== EntityStatus.ErrorUpdating &&
      crmRecordType &&
      sorRecordId &&
      entityType === SourceEntityType.NOTE
    ) {
      CrmRecord.getRecordFields(crmRecordType, sorRecordId)
    }
  })

  const onFieldValueChange = useCallback(
    async (fieldName: string, value: string) => {
      setState((prevState) => ({
        ...prevState,
        fieldStatus: EntityStatus.Loading,
      }))

      try {
        if (crmRecordType && sorRecordId) {
          await CrmRecord.multiUpdate({
            crmRecordType,
            fields: { [fieldName]: value },
            sorRecordId,
          })

          setState((prevState) => ({
            ...prevState,
            fieldStatus: EntityStatus.Loaded,
          }))

          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              fieldStatus: EntityStatus.Idle,
            }))
          }, 2000)
        }
      } catch (e) {
        handleUpdateError('Error updating field. ' + (e as Error).message)
        setState((prevState) => ({
          ...prevState,
          fieldStatus: EntityStatus.ErrorLoading,
        }))

        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            fieldStatus: EntityStatus.Idle,
          }))
        }, 2000)
      }
    },
    [crmRecordType, sorRecordId, handleUpdateError]
  )

  if (recordStatus === EntityStatus.ErrorLoading && entityType === SourceEntityType.NOTE) {
    content = (
      <div className={styles.mainContainer}>
        <div className={[styles.container, recordViewStyles.recordView].join(' ')}>
          {Boolean(state.userAlertMessage) ? (
            <button className={styles.warning}>
              <Tooltip label={state.userAlertMessage ?? ''} placement='bottom'>
                <img src={warningIcon} alt='Warning' />
              </Tooltip>
            </button>
          ) : null}
          {/* <LuruCrmFieldInputSet
            fieldNames={fields}
            crmRecordType={crmRecordType}
            sorRecordId={linkedCRMRecord?.connection?.sor_record_id}
            size={'wide'}
            disabled={isReadOnly}
          /> */}
          <label>
            {fieldSchema?.label ?? null}
            {fieldSchema?.isMandatory ? '*' : null}
            {fieldSchema?.updateable === false && (
              <Tooltip label='This field is not editable'>
                <span className={styles.readOnlyIconStyles}>
                  <img src={readOnlyIcon} alt='read-only' />
                </span>
              </Tooltip>
            )}
          </label>
          {state.userAlertMessage ? <div className={styles.userAlert}>{state.userAlertMessage}</div> : null}
          {fieldSchema ? (
            <EditableCellWrapper
              key={JSON.stringify(fieldValue)}
              fieldSchema={fieldSchema}
              rowIndex={-1}
              value={fieldValue[fieldName]}
              disableExplicitWidth={true}
              updateOnChange={false}
              crmRecordType={crmRecordType}
              sorRecordId={sorRecordId}
              onUpdateError={handleUpdateError}
              isReadOnly={true}
              align='left'
              controllerFieldValue={fieldSchema?.controllerName ? fieldValue?.[fieldSchema?.controllerName] : undefined}
              onFieldValueChange={onFieldValueChange}
              status={state.fieldStatus}
            />
          ) : schemaMap ? (
            <>
              Field {fieldName} not found in {linkedCRMRecord?.connection?.sor_record_name ?? sorObjectName}
            </>
          ) : null}
          <span className={styles.error}>
            Error loading record, the linked record might have been deleted on your CRM
          </span>
        </div>
        <button className={styles.delete} onClick={onDeleteJotting}>
          <Tooltip label='Remove this field from note' placement='bottom'>
            <img src={deleteIcon} alt='Delete' />
          </Tooltip>
        </button>
      </div>
    )
  } else {
    content = (
      <div className={styles.mainContainer}>
        <div className={[styles.container, recordViewStyles.recordView].join(' ')}>
          {Boolean(state.userAlertMessage) ? (
            <button className={styles.warning}>
              <Tooltip label={state.userAlertMessage ?? ''}>
                <img src={warningIcon} alt='Warning' />
              </Tooltip>
            </button>
          ) : null}
          {/* <LuruCrmFieldInputSet
            fieldNames={fields}
            crmRecordType={crmRecordType}
            sorRecordId={linkedCRMRecord?.connection?.sor_record_id}
            size={'wide'}
            disabled={isReadOnly}
          /> */}
          <label>
            {fieldSchema?.label ?? null}
            {fieldSchema?.isMandatory ? '*' : null}
            {fieldSchema?.updateable === false && (
              <Tooltip label='This field is not editable'>
                <span className={styles.readOnlyIconStyles}>
                  <img src={readOnlyIcon} alt='read-only' />
                </span>
              </Tooltip>
            )}
          </label>
          {state.userAlertMessage ? <div className={styles.userAlert}>{state.userAlertMessage}</div> : null}
          {fieldSchema ? (
            <EditableCellWrapper
              key={JSON.stringify(fieldValue)}
              fieldSchema={fieldSchema}
              rowIndex={-1}
              value={fieldValue[fieldName]}
              disableExplicitWidth={true}
              updateOnChange={true}
              crmRecordType={crmRecordType}
              sorRecordId={sorRecordId}
              onUpdateError={handleUpdateError}
              isReadOnly={
                isReadOnly ||
                entityType === SourceEntityType.TEMPLATE ||
                ((recordStatus === EntityStatus.Loading || !crmRecordType) && entityType === SourceEntityType.NOTE)
              }
              align='left'
              controllerFieldValue={fieldSchema?.controllerName ? fieldValue?.[fieldSchema?.controllerName] : undefined}
              status={state.fieldStatus}
              onFieldValueChange={onFieldValueChange}
            />
          ) : schemaMap ? (
            <>
              Field {fieldName} not found in {linkedCRMRecord?.connection?.sor_record_name ?? sorObjectName}
            </>
          ) : null}
        </div>
        <button className={styles.delete} onClick={onDeleteJotting}>
          <Tooltip label='Remove this field from note' placement='bottom'>
            <img src={deleteIcon} alt='Delete' />
          </Tooltip>
        </button>
      </div>
    )
  }

  if (!entityId) {
    return null
  }

  return <div className={styles.parent}>{content}</div>
}
