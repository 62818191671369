// React
import React from 'react'

// Own components
import LoadingSpinner from '../../LoadingSpinner'

// Styles
import styles from './styles.module.css'

// Icons
import warningIcon from '../../../images/fluent/warning.svg'

export interface LuruButtonProps {
  // Properties / button state
  title: string
  disabled?: boolean
  loading?: boolean
  loadingSize?: number
  role?: string
  children?: React.ReactNode
  // Visual
  subdued?: boolean
  outlined?: boolean
  extraClassNames?: Array<string>
  // Behavior
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onBlur?: (e?: React.FocusEvent<HTMLButtonElement, Element>) => void
  onFocus?: (e?: React.FocusEvent<HTMLButtonElement, Element>) => void
  id?: string
  luru_data?: string
  // Retry
  warning?: boolean
  // New props
  // Variants
  variant?: 'solid' | 'outline' | 'link'
  // Color
  color?: 'red' | 'blue' | 'gray'
  // Size
  size?: 'xs' | 'sm' | 'md'
  // Icon
  icon?: React.ReactNode
}

class LuruButton extends React.Component<LuruButtonProps & { innerRef: any }> {
  props: LuruButtonProps & { innerRef: any }

  constructor(props: LuruButtonProps & { innerRef: any }) {
    super(props)
    this.props = props
  }

  render() {
    var classes = [styles.parent]

    if (this.props.extraClassNames) {
      classes = classes.concat(this.props.extraClassNames)
    }

    if (this.props.subdued) {
      classes.push(styles.subdued)
    }

    if (this.props.outlined) {
      classes.push(styles.outlined)
    }

    if (this.props?.warning) {
      classes.push(styles.warning)
    }

    if (!this.props.children) {
      classes.push(styles.noChildren)
    }

    var buttonProps = {
      ref: this.props.innerRef,
      title: this.props.title,
      disabled: Boolean(this.props.disabled) || Boolean(this.props.loading),
      className: classes.join(' '),
      onClick: this.props.onClick,
      onBlur: this.props.onBlur,
      onFocus: this.props.onBlur,
      id: this.props.id,
      'luru-data': this.props.luru_data,
      'data-role': this.props.role ?? '',
      'data-variant': this.props.variant ?? 'solid',
      'data-color': this.props.color ?? 'blue',
      'data-size': this.props.size ?? 'md',
    }

    const spinnerColorMap = {
      red: 'var(--brand-accent-color-pink)',
      blue: 'var(--brand-accent-color-blue)',
      gray: 'var(--brand-accent-color-gray)',
    }

    return (
      <button {...buttonProps}>
        {this.props.loading ? (
          <LoadingSpinner
            color={
              this.props.variant === 'outline' || this.props.variant === 'link'
                ? spinnerColorMap[this.props.color ?? 'blue']
                : 'var(--white-color)'
            }
            size={this.props.loadingSize ?? 8}
          />
        ) : (
          this.props.icon
        )}
        {this.props.warning ? <img src={warningIcon} alt='retry' /> : null}
        {this.props.children}
      </button>
    )
  }
}

export default React.forwardRef((props: LuruButtonProps, ref: any) => <LuruButton {...props} innerRef={ref} />)
