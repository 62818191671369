import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { EntityStatus } from '../../../app/types'
import { MeetingsMiddleware } from '../../meetings/middleware'
import { ReduxAppState } from '../types'

export const meetingsSliceListMeetings = {
  addAllCases: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    meetingsSliceListMeetings.addPendingCase(builder)
    meetingsSliceListMeetings.addFulfilledCase(builder)
    meetingsSliceListMeetings.addRejectedCase(builder)
  },

  addPendingCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      MeetingsMiddleware.listMeetings.action.pending,
      (state, action) => {
        if (action.meta.arg.key === 'home/UpcomingMeetings') {
          if (action.meta.arg.loadMore === true) {
            state.home.default.upcomingMeetings.status =
              EntityStatus.LoadingNext
          } else if (action.meta.arg.loadPrev === true) {
            state.home.default.upcomingMeetings.status =
              EntityStatus.LoadingPrev
          } else {
            state.home.default.upcomingMeetings.status = EntityStatus.Loading
          }
          state.home.default.upcomingMeetings.error_data = null
        }
      }
    )
  },

  addFulfilledCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      MeetingsMiddleware.listMeetings.action.fulfilled,
      (state, action) => {
        if (action.meta.arg.key === 'home/UpcomingMeetings') {
          state.home.default.upcomingMeetings.status = EntityStatus.Loaded

          const meetings = action.payload
          // From above API response, store only the meeting_ids of the meetings
          // that are not already there with us.
          let results = meetings.map((meeting) => meeting.meeting_id)
          if (action.meta.arg.loadMore) {
            // If this was a next-page request, append to existing results
            // Remove duplicates first
            results = results.filter(
              (meeting_id) =>
                !state.home.default.upcomingMeetings.results?.includes(
                  meeting_id
                )
            )
            state.home.default.upcomingMeetings.results.push(...results)
          } else if (action.meta.arg.loadPrev) {
            // If this was a prev-page request, prepend to existing results
            // Remove duplicates first
            results = results.filter(
              (meeting_id) =>
                !state.home.default.upcomingMeetings.results?.includes(
                  meeting_id
                )
            )
            state.home.default.upcomingMeetings.results = [
              ...results,
              ...state.home.default.upcomingMeetings.results,
            ]
          } else {
            // If this was a new list call, just replace
            // (though this path will never be called for default meeting list
            // since we get the initial list during initial load)
            state.home.default.upcomingMeetings.results = results
          }
          state.home.default.upcomingMeetings.error_data = null
        }
      }
    )
  },

  addRejectedCase: (builder: ActionReducerMapBuilder<ReduxAppState>) => {
    builder.addCase(
      MeetingsMiddleware.listMeetings.action.rejected,
      (state, action) => {
        const errorPayload = action.payload as Record<string, any>
        if (action.meta.arg.key === 'home/UpcomingMeetings') {
          state.home.default.upcomingMeetings.status = EntityStatus.ErrorLoading
          state.home.default.upcomingMeetings.error_data = {
            ...errorPayload,
            message:
              errorPayload?.message ??
              action.error.message ??
              'Error listing meetings',
          }
        }
      }
    )
  },
}
