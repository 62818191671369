import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CollectionsAPI } from '../api'
import {
  CollectionShareInfo,
  ReduxCollectionEntity,
  ReduxCollectionsState,
} from '../types'

export interface CreateCollectionParameter {
  name: string
  description?: string
  sor: string
  sor_object_name: string
  fields?: string[]
  shares?: CollectionShareInfo[]
}

export interface CreateCollectionAPIResponse extends LuruAPIResponse {
  data: ReduxCollectionEntity
}

export const createCollection = {
  action: createAsyncThunk<
    CreateCollectionAPIResponse['data'],
    CreateCollectionParameter,
    {
      state: RootState
      dispatch: AppDispatch
      fulfilledMeta: CreateCollectionAPIResponse['metadata']
      rejectedMeta: CreateCollectionAPIResponse['metadata']
    }
  >(
    'collections/createCollection',
    async (params, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response: CreateCollectionAPIResponse =
          await CollectionsAPI.createCollection(params, { signal })

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null
        return rejectWithValue(
          luruError?.toErrorValue() ?? e,
          luruError?.toErrorValue().meta ?? null
        )
      }
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(createCollection.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(createCollection.action.fulfilled, (state, action) => {
      state.entities[action.payload.collection_id] = {
        status: EntityStatus.Loaded,
        data: {
          ...action.payload,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    builder.addCase(createCollection.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCollectionsState>) {
    createCollection.addPendingCase(builder)
    createCollection.addFulfilledCase(builder)
    createCollection.addRejectedCase(builder)
  },
}
