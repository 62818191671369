import { createSlice } from '@reduxjs/toolkit'
import { UserSliceActions } from '../user/userSlice'
import { UsersMiddleware } from './middleware'
import { EmptyUsersState, ReduxUsersState } from './types'

const usersSlice = createSlice({
  name: 'users',
  initialState: EmptyUsersState,
  reducers: {},
  extraReducers: (builder) => {
    UsersMiddleware.configureConnections.addAllCases(builder)
    UsersMiddleware.getLuruUsers.addAllCases(builder)
    UsersMiddleware.getLuruUserRoles.addAllCases(builder)
    UsersMiddleware.getSorUsers.addAllCases(builder)
    UsersMiddleware.inviteUsers.addAllCases(builder)
    UsersMiddleware.updateLuruUserRoles.addAllCases(builder)
    builder
      .addCase(UserSliceActions.signInUser, (state, action) => {
        Object.keys(state).forEach((key) => delete state[key as keyof ReduxUsersState])
        Object.assign(state, EmptyUsersState)
      })
      .addCase(UserSliceActions.signOutUser, (state, action) => {
        Object.keys(state).forEach((key) => delete state[key as keyof ReduxUsersState])
        Object.assign(state, EmptyUsersState)
      })
  },
})

export default usersSlice.reducer
