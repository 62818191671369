import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { WorkflowsAPI } from '../api'
import { FetchWorkflowSchemaParameter } from '../api/fetchWorkflowSchema'
import { ReduxWorkflowsState, WorkflowSorName, WorkflowSorSchema } from '../types'
import { WorkflowsSliceHelpers } from '../helpers'

export interface FetchWorkflowSchemaAPIResponse extends LuruAPIResponse {
  data: Record<WorkflowSorName, WorkflowSorSchema>
}

export const fetchWorkflowSchema = {
  action: createAsyncThunk<
    FetchWorkflowSchemaAPIResponse['data'],
    FetchWorkflowSchemaParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: FetchWorkflowSchemaAPIResponse['metadata']
      rejectedMeta: FetchWorkflowSchemaAPIResponse['metadata']
    }
  >(
    'workflows/fetchWorkflowSchema',
    async (params, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await WorkflowsAPI.fetchWorkflowSchema(params, {
          signal,
        })) as FetchWorkflowSchemaAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        return rejectWithValue({ message: (e as Error)?.message ?? 'Error creating alert' }, null)
      }
    },
    {
      condition: ({ sor }, { getState }) => {
        // Return false if the required schema is already loading
        const workflowSchema = sor ? getState().workflows.schema.entities[sor] : getState().workflows.schema
        const isSchemaLoading = workflowSchema && workflowSchema.status === EntityStatus.Loading
        return !isSchemaLoading
      },
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(fetchWorkflowSchema.action.pending, (state, action) => {
      const { sor } = action.meta.arg
      if (sor) {
        state.schema.entities = {
          ...state.schema.entities,
          [sor]: {
            ...state.schema?.entities?.[sor],
            status: EntityStatus.Loading,
          },
        }
      } else {
        state.schema.status = EntityStatus.Loading
        state.schema.error = null
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(fetchWorkflowSchema.action.fulfilled, (state, action) => {
      const workflowSchema = action.payload
      state.schema.status = EntityStatus.Loaded
      for (const workflowSorName in workflowSchema) {
        state.schema.entities = {
          ...state.schema.entities,
          [workflowSorName]: {
            status: EntityStatus.Loaded,
            data: workflowSchema[workflowSorName as WorkflowSorName],
          },
        }
      }
      state.schema.normalizedEntities = WorkflowsSliceHelpers.normalizeWorkflowSchema(workflowSchema)
      state.schema.refreshedAt = new Date().toISOString()
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    builder.addCase(fetchWorkflowSchema.action.rejected, (state, action) => {
      const { sor } = action.meta.arg
      if (sor) {
        state.schema.entities = {
          ...state.schema.entities,
          [sor]: {
            ...state.schema?.entities?.[sor],
            status: EntityStatus.ErrorLoading,
          },
        }
      } else {
        state.schema.status = EntityStatus.ErrorLoading
        state.schema.error = action.payload as any
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxWorkflowsState>) {
    fetchWorkflowSchema.addPendingCase(builder)
    fetchWorkflowSchema.addFulfilledCase(builder)
    fetchWorkflowSchema.addRejectedCase(builder)
  },
}
