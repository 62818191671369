// Google Analytics
import { initGA } from './Ga'
// Sentry
import { initSentry } from './Sentry'

export function initRemoteScripts() {}

export function initThirdPartyScripts() {
  if (typeof window === 'undefined') {
    return
  }

  // Initialize Google Analytics tracking
  initGA()

  // Initialize Sentry
  if (process.env.REACT_APP_DEBUG !== 'true') {
    initSentry()
  }
}
