// Libraries
import { Outlet } from 'react-router-dom'

// Luru components
import LuruAppError from '../../primitives/LuruAppError'
import WebAppRoutes from './WebAppRoutes'

// Hooks
import useWebAppEffects from './hooks/useWebAppEffects'

// Luru Redux store
import { useAppSelector } from '../../app/hooks'
import { UserSliceSelectors } from '../../features/user/selectors'

// Other functions, types and utilities
import { LuruAppErrorCode } from '../../features/user/types'
import { AppComponentsProvider } from '../../primitives/AppComponents'
import UserAdminTasksWizard from '@/routes/userAdminTasksWizard/UserAdminTasksWizard'
import AppUI from '@/app_ui/AppUI'
import NotificationController from '@/primitives/NotificationController'

export default function WebAppSignedIn() {
  const userSettings = useAppSelector((state) => state.user.data.userSettings)
  const applicationError = useAppSelector(UserSliceSelectors.getApplicationError)
  const userAdminPendingTasks = useAppSelector((state) =>
    state.user.data.userAdminTasks.filter((task) => task.status === 'PENDING')
  )
  const areUserAdminTasksPending = userAdminPendingTasks.length > 0

  useWebAppEffects()

  return (
    <AppUI>
      <AppComponentsProvider>
        <div className={`main ${userSettings?.darkTheme ? ' darkTheme' : ''}`}>
          {applicationError !== LuruAppErrorCode.NETWORK_ERROR ? (
            areUserAdminTasksPending ? (
              <UserAdminTasksWizard />
            ) : (
              <>
                <NotificationController />
                <WebAppRoutes />
                <Outlet />
              </>
            )
          ) : null}
        </div>
        <LuruAppError key={applicationError ?? 'no-app-error'} errorCode={applicationError} />
      </AppComponentsProvider>
    </AppUI>
  )
}
