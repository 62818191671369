import { useEffect, useRef } from 'react'
import { CRMLogAsLinkControlProps, CRMLogAsLinkControlRefs } from '.'
import { UseCRMLogAsLinkControlComponentState } from './useComponentState'
import { CrmActivity, CrmActivityLogType } from '../../features/crm/types'
import { EntityStatus, LuruEntryPoint } from '../../app/types'
import LuruUser from '../../domain/users/LuruUser'
import moment from 'moment'
import { useAppDispatch } from '../../app/hooks'
import { CrmMiddleware } from '../../features/crm/middleware'
import { cleanUrl } from '../../utils/Utils'
import { getBaseUrl } from '../../domutils/utils'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export default function useCRMLogAsLinkControlEventHandlers(
  sharedState: UseCRMLogAsLinkControlComponentState,
  props: CRMLogAsLinkControlProps,
  refs: CRMLogAsLinkControlRefs
) {
  const { showToast } = useLuruToast()
  const autoLogTimer = useRef<NodeJS.Timeout | undefined>(undefined)
  const isLoggedMeeting = useRef<boolean>(false)
  const dispatch = useAppDispatch()
  const isUpdatedLoggedMeeting = useRef<boolean>(false)
  const { onChooseItem, noteID } = props
  const { state, connectedMeetingObj, connectedRecord } = sharedState
  const { getState, setState } = state
  const { connectedMeeting, meetingRecordState, activityRecord } = connectedMeetingObj
  const crmKey = LuruUser.getCurrentUserReduxCrmKey()

  useEffect(() => {
    // Auto logging can only happen when the note is linked to a record AND linked to a meeting.
    if (!connectedMeeting || !connectedRecord) {
      return
    }
    if (meetingRecordState.status !== EntityStatus.Loaded) {
      // meetingRecord is not loaded
      return
    }
    if (meetingRecordState.data?.activities) {
      // In case the activity does not exists proceed below.
      if (meetingRecordState.data?.activities?.length <= 0) {
        // Auto-logging will only happen as a part of the embedded note-taker
        if (
          [LuruEntryPoint.EMBEDDED_GMEET_NOTE, LuruEntryPoint.EMBEDDED_ZOOM_MEETING].includes(
            LuruUser.getCurrentEntryPoint()
          ) &&
          !isLoggedMeeting.current &&
          !getState.createdLog &&
          getState.createLogActivityStatus !== EntityStatus.Creating
        ) {
          const meetingDuration = moment.duration(
            moment(meetingRecordState.data.meeting.end_time).diff(moment(meetingRecordState.data.meeting.start_time))
          )
          var meetingDurationAsMinutes = meetingDuration.asMinutes()
          // console.log(`Auto Logging the meeting now in ${Math.min(10, meetingDurationAsMinutes / 2)} Minutes`)
          // delete any old timer
          if (autoLogTimer.current) {
            clearTimeout(autoLogTimer.current)
          }
          autoLogTimer.current = setTimeout(() => {
            handleLogActivityFromCalEvnt()
          }, Math.min(10, meetingDurationAsMinutes / 2) * 60 * 1000) // Autolog only min(10, x/2) minutes after a meeting has started where x is the duration of the meeting.
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          logAs: (meetingRecordState.data?.activities?.[0]?.type as CrmActivityLogType) || CrmActivityLogType.DoNotLog,
        }))
        // In case the activity already exists (say, multiple notes for the same meeting), then the Luru note link should be appended to the activity description.
        if (
          !meetingRecordState.data?.activities?.find?.((f) =>
            f?.connections?.find?.((c) => c.sor_record_id === connectedRecord.sor_record_id)
          ) &&
          !isUpdatedLoggedMeeting.current &&
          getState.updateLogActivityStatus !== EntityStatus.Updating
        ) {
          // Meeting activity is already exists for some other record, then the Luru note link should be appended to the activity description.
          const activityToBeUpdated = meetingRecordState.data?.activities?.[0]
          const currentLuruNoteLink = cleanUrl(`${getBaseUrl()}/notes/${props.noteID}`)
          if (activityToBeUpdated?.notes?.includes?.(currentLuruNoteLink)) {
            // Already appended, ignoring
            return
          } else {
            handleUpdateLogActivity(activityToBeUpdated.activity_id!, {
              notes: `${activityToBeUpdated.notes} ${
                crmKey === 'hubspot' ? '<br/>' : '\n'
              }Luru note: ${currentLuruNoteLink}`,
            })
          }
        }
      }
    }

    return () => {
      // Do cleanup
      clearTimeout(autoLogTimer.current)
    }
    // eslint-disable-next-line
  }, [connectedRecord, connectedMeeting, meetingRecordState.data, meetingRecordState.status])

  const handleLogActivityFromCalEvnt = async () => {
    try {
      if (connectedRecord && connectedMeeting && !getState.createdLog) {
        isLoggedMeeting.current = true
        setState((prevState) => ({ ...prevState, createLogActivityStatus: EntityStatus.Creating }))
        const currentLuruNoteLink = cleanUrl(`${getBaseUrl()}/notes/${noteID}`)
        const createActivityLogFromCalEvntRes = await dispatch(
          CrmMiddleware.createActivityLogFromCalEvnt.action({
            crmKey,
            type: CrmActivityLogType.Meeting,
            params: { log_from_calendar: true },
            payload: {
              meeting_id: connectedMeeting.sor_record_id,
              connections: [
                {
                  sor_object_name: connectedRecord.sor_object_name,
                  sor_record_id: connectedRecord.sor_record_id,
                },
              ],
              notes: `Luru note: ${currentLuruNoteLink}`,
            },
          })
        ).unwrap()
        setState((prevState) => ({
          ...prevState,
          createLogActivityStatus: EntityStatus.Created,
          logAs: CrmActivityLogType.Meeting,
          createdLog: createActivityLogFromCalEvntRes,
        }))
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, createLogActivityStatus: EntityStatus.ErrorCreating }))
      isLoggedMeeting.current = false
      showToast({
        id: ToastId.CRM_LOG_AS_LINK_CONTROLL_TOAST_ID,
        message: 'Error logging activity!',
        severity: 'error',
      })
    }
  }

  const handleDeleteActivity = () => {
    const activityToBeDeleted = activityRecord?.[0] || meetingRecordState.data?.activities?.[0]
    if (!activityToBeDeleted) {
      return
    }
    refs.deleteActivityLogModalRef.current?.showModal({
      ok: () => {
        showToast({
          id: ToastId.CRM_LOG_AS_LINK_CONTROLL_TOAST_ID,
          message: 'Deleting log',
          isLoading: true,
        })
        setState((prevState) => ({ ...prevState, deleteLogActivityStatus: EntityStatus.Deleting }))
        dispatch(
          CrmMiddleware.deleteActivityLog.action({
            crmKey,
            type: CrmActivityLogType.Meeting,
            activityId: activityToBeDeleted?.activity_id!,
          })
        )
          .unwrap()
          .then(() => {
            setState((prevState) => ({
              ...prevState,
              logAs: CrmActivityLogType.DoNotLog,
              deleteLogActivityStatus: EntityStatus.Deleted,
            }))
            showToast({
              id: ToastId.CRM_LOG_AS_LINK_CONTROLL_TOAST_ID,
              message: 'Deleted log',
              severity: 'success',
            })
          })
          .catch((e) => {
            setState((prevState) => ({ ...prevState, deleteLogActivityStatus: EntityStatus.ErrorDeleting }))
            showToast({
              id: ToastId.CRM_LOG_AS_LINK_CONTROLL_TOAST_ID,
              message: 'Error deleting log',
              severity: 'success',
            })
          })
      },
    })
  }

  const handleUpdateLogActivity = async (activityId: string, payload: Partial<CrmActivity>) => {
    try {
      isUpdatedLoggedMeeting.current = true
      setState((prevState) => ({ ...prevState, updateLogActivityStatus: EntityStatus.Updating }))
      await dispatch(
        CrmMiddleware.updateActivityLog.action({
          activityId,
          crmKey,
          type: CrmActivityLogType.Meeting,
          payload: payload,
        })
      ).unwrap()
      setState((prevState) => ({
        ...prevState,
        updateLogActivityStatus: EntityStatus.Updated,
      }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, updateLogActivityStatus: EntityStatus.ErrorUpdating }))
      isUpdatedLoggedMeeting.current = false
    }
  }

  const handleOnChooseLogActivity = (itemKeySelected: string | null, highlightClassName?: string) => {
    if (itemKeySelected && itemKeySelected !== CrmActivityLogType.DoNotLog) {
      if (!connectedRecord?.sor_record_id) {
        showToast({
          id: ToastId.CRM_LOG_AS_LINK_CONTROLL_TOAST_ID,
          message: 'Please connect to record first',
          severity: 'warning',
        })
        highLightRecordLinkController(highlightClassName || '')
        setTimeout(() => {
          removeHighLightRecordLinkController(highlightClassName || '')
        }, 2000)
        refs.crmLogSelectBoxRef.current?.setState({ chosenItemName: CrmActivityLogType.DoNotLog })
        return
      }
      var draftActivity: Partial<CrmActivity & { time_picked?: Date; duration?: string }> = {}
      if (connectedMeetingObj.connectedMeeting?.sor_record_id && meetingRecordState?.data?.meeting) {
        const meetingDuration = moment.duration(
          moment(meetingRecordState.data.meeting.end_time).diff(moment(meetingRecordState.data.meeting.start_time))
        )
        var meetingDurationAsMinutes = meetingDuration.asMinutes()

        draftActivity.duration = meetingDurationAsMinutes?.toString?.()
        draftActivity.start_time = meetingRecordState.data.meeting.start_time
        draftActivity.time_picked = new Date(meetingRecordState.data.meeting.start_time)
        draftActivity.external_url = meetingRecordState.data.meeting.external_url
        draftActivity.title = meetingRecordState.data.meeting.subject
        draftActivity.notes = ''

        draftActivity.connections = connectedRecord
          ? [{ sor_object_name: connectedRecord?.sor_object_name, sor_record_id: connectedRecord?.sor_record_id }]
          : []
        refs.createActivityLogModalRef.current?.show?.(
          { activityLogType: itemKeySelected as CrmActivityLogType },
          draftActivity
        )
      } else {
        refs.createActivityLogModalRef.current?.show?.(
          { activityLogType: itemKeySelected as CrmActivityLogType },
          draftActivity
        )
      }

      setState((prevState) => ({ ...prevState, logAs: itemKeySelected as CrmActivityLogType }))
      onChooseItem?.(itemKeySelected)
    }
  }

  const onLogSuccess = (payload: CrmActivity) => {
    setState((prevState) => ({ ...prevState, createdLog: payload }))
    clearTimeout(autoLogTimer.current)
  }

  const onCloseCreateActivityModal = () => {
    setState((prevState) => ({ ...prevState, logAs: CrmActivityLogType.DoNotLog }))
  }

  const highLightRecordLinkController = (highlightClassName: string) => {
    let infoBlock = document.getElementById(`note-info-${noteID?.slice?.(0, 7)}`) as HTMLElement
    const recordLinkControllerEle = infoBlock?.querySelector?.(`#crm-record-link-control-${noteID}`)
    if (recordLinkControllerEle) {
      recordLinkControllerEle?.classList?.add?.(highlightClassName || '')
    }
  }

  const removeHighLightRecordLinkController = (highlightClassName: string) => {
    let infoBlock = document.getElementById(`note-info-${noteID?.slice?.(0, 7)}`) as HTMLElement
    const recordLinkControllerEle = infoBlock?.querySelector?.(`#crm-record-link-control-${noteID}`)
    if (recordLinkControllerEle) {
      recordLinkControllerEle?.classList?.remove?.(highlightClassName || '')
    }
  }

  return {
    handleOnChooseLogActivity,
    onLogSuccess,
    onCloseCreateActivityModal,
    handleDeleteActivity,
  }
}
