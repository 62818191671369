import React from 'react'
import { CrmRecordSchema, CrmRecordType } from '../../../../../../domain/crmRecord/typings.d'
import CrmMultiObjectFieldChooserEventHandler from '../../CrmMultiObjectFieldChooserEventHandler'
import styles from './styles.module.css'
import { CrmMultiObjectFieldChooserRefs } from '../../CrmMultiObjectFieldChooserComponent'
import CrmRecord from '../../../../../../domain/crmRecord/CrmRecord'
import { Chip } from '../../../../../ui/ChipSelectBox'
import { EntityStatus } from '../../../../../../app/types'
// Icons
import refreshIcon from '../../../../../../images/fluent/refresh.svg'

export type SelectedFieldsChipsListProps = {
  selectedItems: Array<string>
  crmRecordType: CrmRecordType
  eventHandler: CrmMultiObjectFieldChooserEventHandler
  globalComponentRefs: CrmMultiObjectFieldChooserRefs
  disabled?: boolean
  schema: {
    [key: string]: {
      data: CrmRecordSchema
      status: EntityStatus
    }
  }
}

export type SelectedFieldsChipsListState = {}

export interface SelectedFieldsChipsListEvents {}

export default class SelectedFieldsChipsList extends React.Component<
  SelectedFieldsChipsListProps,
  SelectedFieldsChipsListState
> {
  props: SelectedFieldsChipsListProps
  state: SelectedFieldsChipsListState
  handlers: SelectedFieldsChipsListEvents
  dragStartPosition: number
  dragStartElement: HTMLDivElement | null
  dragEndPosition: number | null
  dragOversElements: Array<{ element: HTMLDivElement; position: number }>
  constructor(props: SelectedFieldsChipsListProps) {
    super(props)
    this.props = props
    this.state = {}
    this.handlers = {}
    this.dragStartPosition = 0
    this.dragStartElement = null
    this.dragEndPosition = null
    this.dragOversElements = []
  }

  onDragStart = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    this.dragStartPosition = position
    this.dragStartElement = e.currentTarget
    e.currentTarget?.classList.add(styles.beingGrabbed)
  }

  onDragEnter = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    this.dragEndPosition = position
  }

  onDragOver = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    e.preventDefault()
    this.dragEndPosition = position
    var fIndex = this.dragOversElements.findIndex((f) => f.position === position)
    if (fIndex === -1) {
      this.dragOversElements.push({
        element: e.currentTarget,
        position: position,
      })
    }
    if (position !== this.dragStartPosition) {
      e.currentTarget?.classList.add(styles.beingDraggedOver)
    }
  }

  onDragLeave = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    this.dragEndPosition = null
    this.dragOversElements = this.dragOversElements.filter((f) => f.position !== position)
    e.currentTarget?.classList.remove(styles.beingDraggedOver)
  }

  onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    //Not Ended within our drop zone
    if (this.dragEndPosition === null) {
      this.dragStartElement?.classList?.remove?.(styles.beingGrabbed)
      this.dragStartPosition = 0
      this.dragEndPosition = null
      this.dragOversElements = []
      this.dragStartElement = null
      return
    }
    const { selectedItems } = this.props
    const copyListItems = [...selectedItems]
    const dragItemContent = copyListItems[this.dragStartPosition]
    copyListItems.splice(this.dragStartPosition, 1)
    copyListItems.splice(this.dragEndPosition, 0, dragItemContent)
    this.props?.eventHandler?.handlers?.onReorderSelectedFields?.(copyListItems)
    this.dragOversElements?.forEach?.((i) => {
      i?.element.classList.remove(styles.beingDraggedOver)
    })
    this.dragStartElement?.classList?.remove?.(styles.beingGrabbed)
    this.dragStartPosition = 0
    this.dragEndPosition = null
    this.dragOversElements = []
    this.dragStartElement = null
  }

  render() {
    const { schema, selectedItems, crmRecordType, eventHandler, disabled } = this.props
    const sorObjectName = CrmRecord.getCrmRecordName(crmRecordType)
    const recordsTypes = schema?.[crmRecordType]?.data?.filter?.((f) => f.referencedObject) || []

    const itemsToShow = selectedItems.slice(0, 5)
    const remainingToShow = selectedItems.slice(5, selectedItems.length)
    return (
      <React.Fragment>
        {itemsToShow.map((f, index) => {
          const fields = f.split('.')
          var recordName = ''
          var fieldName = <span></span>
          var fieldNameTextOnly = ''
          var entitiyStatus: EntityStatus = EntityStatus.Loading
          if (fields.length === 1) {
            recordName = sorObjectName
            entitiyStatus = schema?.[crmRecordType]?.status
            fieldNameTextOnly =
              entitiyStatus === EntityStatus.ErrorLoading
                ? 'Error'
                : entitiyStatus === EntityStatus.Loading
                ? 'Loading...'
                : schema?.[crmRecordType]?.data?.find((f) => f.name === fields[0])?.label || ''
            fieldName =
              entitiyStatus === EntityStatus.ErrorLoading ? (
                <span
                  className={styles.errorType2SchemaLoading}
                  onClick={(e) => {
                    eventHandler.handlers.loadSchema(crmRecordType)
                    e.stopPropagation()
                  }}
                  title='Reload'
                >
                  Error
                  <img src={refreshIcon} alt='refresh' />
                </span>
              ) : entitiyStatus === EntityStatus.Loading ? (
                <span>Loading...</span>
              ) : (
                <span>{schema?.[crmRecordType]?.data?.find((f) => f.name === fields[0])?.label || ''}</span>
              )
          } else if (fields.length === 2) {
            recordName = recordsTypes.find((f) => f.name === fields[0])?.label || ''
            const referencedObject = recordsTypes.find((f) => f.name === fields[0])?.referencedObject
            const currentRecordType =
              CrmRecord.getCrmRecordType(referencedObject || '') ||
              referencedObject ||
              CrmRecord.getAllPrimaryObjects().DEAL
            entitiyStatus = schema?.[currentRecordType]?.status
            fieldNameTextOnly =
              entitiyStatus === EntityStatus.ErrorLoading
                ? 'Error'
                : entitiyStatus === EntityStatus.Loading
                ? 'Loading...'
                : schema?.[currentRecordType]?.data?.find((f) => f.name === fields[1])?.label || ''
            fieldName =
              entitiyStatus === EntityStatus.ErrorLoading ? (
                <span
                  className={styles.errorType2SchemaLoading}
                  onClick={(e) => {
                    eventHandler.handlers.loadSchema(currentRecordType)
                    e.stopPropagation()
                  }}
                  title='Reload'
                >
                  Error
                  <img src={refreshIcon} alt='refresh' />
                </span>
              ) : entitiyStatus === EntityStatus.Loading ? (
                <span>Loading...</span>
              ) : (
                <span>{schema?.[currentRecordType]?.data?.find((f) => f.name === fields[1])?.label || ''}</span>
              )
          }
          var chipTitle = (
            <span className={styles.chipTitleContainer}>
              <span className={styles.drag}></span>
              <span className={styles.title}>
                {fieldName} ({recordName})
              </span>
            </span>
          )

          return (
            <div
              key={f}
              draggable={disabled ? 'false' : 'true'}
              onDragStart={(e) => this.onDragStart(e, index)}
              onDragEnter={(e) => this.onDragEnter(e, index)}
              onDragOver={(e) => this.onDragOver(e, index)}
              onDragLeave={(e) => this.onDragLeave(e, index)}
              onDragEnd={(e) => this.onDragEnd(e)}
              // onDrop={(e) => this.onDrop(e, index)}
            >
              <Chip
                title={chipTitle}
                toolTip={`${recordName}: ${fieldNameTextOnly}`}
                identityKey={f}
                disabled={
                  entitiyStatus === EntityStatus.Loading || entitiyStatus === EntityStatus.ErrorLoading
                    ? true
                    : disabled
                }
                onRemove={(key) => eventHandler.handlers.toggleFieldSelection(key)}
                titleClass={styles.chipsTitle}
                classes={[styles.chip]}
              />
            </div>
          )
        })}
        {remainingToShow.length > 0 && (
          <div className={styles.moreField}>
            <span>& {remainingToShow.length} More...</span>
          </div>
        )}
      </React.Fragment>
    )
  }
}
