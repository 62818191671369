import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruError from '../../LuruError'
import { CrmAPI } from '../api'
import { ReduxCrmKey, ReduxCrmState } from '../types'

export interface GetAllObjectsParameter {
  crmKey: ReduxCrmKey
  isVerbose: boolean
}

export interface GetAllObjectsAPIResponse extends LuruAPIResponse {}

export const getAllObjects = {
  action: createAsyncThunk<
    GetAllObjectsAPIResponse['data'],
    GetAllObjectsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: GetAllObjectsAPIResponse['metadata']
      rejectedMeta: GetAllObjectsAPIResponse['metadata']
    }
  >(
    'crm/getAllObjects',
    async (params, { getState, signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var { crmKey } = params
        var objectsBefore = getState()?.crm?.[crmKey]?.objects?.data

        if (objectsBefore) {
          return fulfillWithValue(objectsBefore, null)
        }

        var objects = (await CrmAPI.getAllObjects(params, {
          signal,
        })) as GetAllObjectsAPIResponse

        // Deep-copy schema
        return fulfillWithValue(objects.data, objects.metadata)
      } catch (e) {
        var luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(luruError?.toErrorValue?.() ?? e, luruError?.toErrorValue?.()?.meta ?? null)
      }
    },
    {
      condition: (_, { getState }) => getState().crm?.[_.crmKey]?.objects?.status !== EntityStatus.Loading,
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(getAllObjects.action.pending, (state, action) => {
      var { crmKey } = action.meta.arg

      state[crmKey] = {
        ...state[crmKey],
        objects: {
          ...state[crmKey]?.objects,
          status: EntityStatus.Loading,
        },
      }
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(getAllObjects.action.fulfilled, (state, action) => {
      var { crmKey } = action.meta.arg

      state[crmKey] = {
        ...state[crmKey],
        objects: {
          ...state[crmKey]?.objects,
          status: EntityStatus.Loaded,
          data: action.payload,
        },
      }
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    builder.addCase(getAllObjects.action.rejected, (state, action) => {
      var { crmKey } = action?.meta?.arg

      if (crmKey && state[crmKey]) {
        state[crmKey] = {
          ...state?.[crmKey],
          objects: {
            ...state?.[crmKey]?.objects,
            status: EntityStatus.ErrorLoading,
            error: action?.payload as {},
          },
        }
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxCrmState>) {
    getAllObjects.addPendingCase(builder)
    getAllObjects.addFulfilledCase(builder)
    getAllObjects.addRejectedCase(builder)
  },
}
