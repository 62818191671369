import './calendly.css'

export default function CalendlySpinner() {
  return (
    <div className='calendly-spinner'>
      <div className='calendly-bounce1'></div>
      <div className='calendly-bounce2'></div>
      <div className='calendly-bounce3'></div>
    </div>
  )
}
