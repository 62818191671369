import type { RootState } from '../../../../app/store'
import { EntityStatus } from '../../../../app/types'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { LuruCollectionsSelectBoxOwnProps } from './LuruCollectionsSelectBoxComponent'

export default function getReduxState(
  state: RootState,
  ownProps: LuruCollectionsSelectBoxOwnProps
) {
  var sorObjectName = CrmRecord.getCrmRecordName(ownProps.crmRecordType)
  var entities = state.collections.entities
  var collectionsNotLoaded =
    (!state.collections.status ||
      state.collections.status === EntityStatus.NotLoaded) &&
    !Object.keys(state.collections.entities ?? {}).length

  entities = Object.keys(entities ?? {})
    .filter(
      (id) =>
        entities?.[id]?.data?.sor_object_name === sorObjectName &&
        entities?.[id]?.data?.visibility === 'VISIBLE'
    )
    .sort((id1, id2) =>
      new Date(
        entities?.[id1]?.data?.used_at ??
          entities?.[id1]?.data?.updated_at ??
          new Date()
      ) >
      new Date(
        entities?.[id2]?.data?.used_at ??
          entities?.[id2]?.data?.updated_at ??
          new Date()
      )
        ? -1
        : 1
    )
    .reduce(
      (prev, id) => Object.assign({}, { ...prev, [id]: entities?.[id] }),
      {}
    )

  return {
    collections: entities,
    collectionsNotLoaded,
  }
}
