import { ReduxViewEntity } from '../types'

export const computeDisplaySettingsWithNewFieldOrder = (
  view: ReduxViewEntity,
  allFields: Array<{ key: string; isSelected: boolean; order: number }>
) => {
  var displayInfo = view?.output.schema?.fields.map((f, ix) => ({
    field: f.field,
    display:
      view?.display_settings?.field_settings?.[f.field]?.display ??
      view?.display_settings?.field_settings?.defaults?.display ??
      true,
    width: view?.display_settings?.field_settings?.[f.field]?.width,
    display_order:
      view?.display_settings?.field_settings?.[f.field]?.display_order ?? ix,
  }))

  if (!displayInfo) {
    return
  }

  var newFieldSettings = displayInfo
    .map((fieldSettings, ix) => {
      var orderedField = allFields.find((f) => f.key === fieldSettings.field)

      return {
        ...fieldSettings,
        display: Boolean(orderedField?.isSelected),
        display_order: orderedField?.order || ix,
      }
    })
    .reduce(
      (prev, curr) =>
        curr.width
          ? {
              ...prev,
              [curr.field]: {
                display: curr.display,
                display_order: curr.display_order,
                width: curr.width,
              },
            }
          : {
              ...prev,
              [curr.field]: {
                display: curr.display,
                display_order: curr.display_order,
              },
            },
      {}
    )

  return {
    ...view?.display_settings,
    field_settings: {
      defaults: view?.display_settings?.field_settings?.defaults ?? {},
      ...newFieldSettings,
    },
  }
}
