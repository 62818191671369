import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { ChatAPI } from '../api'
import { ReduxChatState, ReduxChatConversationEntity, EmptyReduxChatState } from '../types'

export interface ListConversationsAPIResponse extends LuruAPIResponse {
  data: Array<ReduxChatConversationEntity>
}

export type ListConversationsParameter = {
  forceRefresh?: boolean
}
export const listConversations = {
  action: createAsyncThunk<
    ListConversationsAPIResponse['data'],
    ListConversationsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ListConversationsAPIResponse['metadata']
      rejectedMeta: ListConversationsAPIResponse['metadata']
    }
  >(
    'chat/listConversations',
    async ({ forceRefresh }, { signal, fulfillWithValue, rejectWithValue }) => {
      try {
        var response = (await ChatAPI.listConversations(
          { forceRefresh },
          {
            signal,
          }
        )) as ListConversationsAPIResponse

        if (response.error_data) {
          throw new Error(response.error_data.message)
        }

        return fulfillWithValue(response.data, response.metadata)
      } catch (e) {
        return rejectWithValue({ message: (e as Error)?.message ?? 'Error retrieving chat users' }, null)
      }
    },
    {
      condition: (arg, { getState }) => {
        var chatState = getState().chat
        var { status } = chatState

        if (status.conversations === EntityStatus.Loading) {
          return false
        }

        if (status.conversations === EntityStatus.Loaded && !arg.forceRefresh) {
          return false
        }

        return true
      },
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxChatState>) {
    builder.addCase(listConversations.action.pending, (state, action) => {
      state.status.conversations = EntityStatus.Loading
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxChatState>) {
    builder.addCase(listConversations.action.fulfilled, (state, action) => {
      state.status.conversations = EntityStatus.Loaded
      state.conversationEntities = { ...EmptyReduxChatState.conversationEntities }
      action.payload.forEach((conversation) => (state.conversationEntities[conversation.id] = { ...conversation }))

      if (!state.refreshedAt) {
        state.refreshedAt = { ...EmptyReduxChatState.refreshedAt }
      }

      state.refreshedAt.conversations = new Date().toISOString()
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxChatState>) {
    builder.addCase(listConversations.action.rejected, (state, action) => {
      state.status.conversations = EntityStatus.ErrorLoading
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxChatState>) {
    listConversations.addPendingCase(builder)
    listConversations.addFulfilledCase(builder)
    listConversations.addRejectedCase(builder)
  },
}
