export interface AppUIState {
  collectionDialog: {
    isOpen: boolean
    objectName: string | undefined
    editMode: 'create' | 'update'
    collectionId: string | undefined
  }
  toast: Record<
    string,
    {
      isOpen: boolean
      message: React.ReactNode | undefined
      severity: 'success' | 'error' | 'warning' | 'info'
      isLoading: boolean
    }
  >
}

export enum AppUIStateActionType {
  SHOW_COLLECTION_DIALOG = 'SHOW_COLLECTION_DIALOG',
  HIDE_COLLECTION_DIALOG = 'HIDE_COLLECTION_DIALOG',
  SHOW_TOAST = 'SHOW_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
  CLOSE_TOAST = 'CLOSE_TOAST',
}

export type AppUIStateAction =
  | {
      type: AppUIStateActionType.SHOW_COLLECTION_DIALOG
      payload?: {
        objectName?: string
        editMode?: 'create' | 'update'
        collectionId?: string
      }
    }
  | {
      type: AppUIStateActionType.HIDE_COLLECTION_DIALOG
      payload: {}
    }
  | {
      type: AppUIStateActionType.SHOW_TOAST
      payload: {
        id: string
        message: React.ReactNode
        severity?: 'success' | 'error' | 'warning' | 'info'
        isLoading?: boolean
        duration?: null | number
        isOpen?: boolean
        withdrawDefaultHideBehaviour?: boolean
      }
    }
  | {
      type: AppUIStateActionType.HIDE_TOAST
      payload: {
        id: string
      }
    }
  | {
      type: AppUIStateActionType.CLOSE_TOAST
      payload: {
        id: string
      }
    }

export const initialAppUIState: AppUIState = {
  collectionDialog: {
    isOpen: false,
    objectName: undefined,
    editMode: 'create',
    collectionId: undefined,
  },
  toast: {},
}
