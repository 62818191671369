import { HTTPMethod } from '../../../app/types'
import { getAuthUrl } from '../../../domutils/utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DisconnectO365MailRequestParams } from '../middleware/disconnectO365Mail'

export const disconnectO365Mail = async (params: DisconnectO365MailRequestParams, options: LuruAPIRequestOptions) => {
  var oAuthKey = 'azuread-v2-tenant-oauth2-mail'
  var disconnectUrl = getAuthUrl() + '/disconnect/' + oAuthKey + '/'
  var request = new LuruAPIRequest(disconnectUrl, HTTPMethod.POST, {
    ...options,
    headers: {
      'X-CSRFToken': params.csrfToken ?? '',
    },
    credentials: 'include',
  })

  return request.make()
}
