import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { GetObjectSchemaParameter } from '../middleware/getObjectSchema'

export const getObjectSchema = ({ crmKey, objectName }: GetObjectSchemaParameter, options?: LuruAPIRequestOptions) => {
  var url = `/api/sor/${crmKey}/objects/${objectName}/schema`
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
