// Own libraries
import { JottingType } from '../../features/notes/types'
import { FloatingFormatterCommand } from '../../primitives/FloatingFormattingMenu'
import EditorDOM from '../EditorDOM'
import DomUtils, { IDOMBoundary, NodeType } from '../utils/DomUtils'

// Styles
import styles from './css/EditorFormatter.module.css'

export default class EditorFormatter {
  #config: {}
  #view: EditorDOM
  #currentRange?: Range
  #noteBoundary?: { start?: HTMLElement; end?: HTMLElement }
  #rangeBoundary?: { start?: Node; end?: Node }
  #currentNoteElements?: HTMLElement[]
  #currentSelectionRanges?: Range[]
  #caretRepoRange?: StaticRange

  #commands: {
    [x: string]: ({
      state,
      url,
      color,
    }: {
      state: Boolean
      url: string
      color: string
    }) => void
  } = {
    [FloatingFormatterCommand.BOLD]: this.#formatContentBold,
    [FloatingFormatterCommand.ITALIC]: this.#formatContentItalic,
    [FloatingFormatterCommand.UNDERLINED]: this.#formatContentUnderlined,
    [FloatingFormatterCommand.HIGHLIGHT]: this.#highlightContent,
    [FloatingFormatterCommand.UNORDERED_LIST]: this.#formatSelectionAsUL,
    [FloatingFormatterCommand.ORDERED_LIST]: this.#formatSelectionAsOL,
    [FloatingFormatterCommand.ADD_LINK]: this.#insertLink,
    [FloatingFormatterCommand.REMOVE_LINK]: this.#removeLink,
    [FloatingFormatterCommand.HEADING1]: this.#formatSelectionAsH1,
    [FloatingFormatterCommand.HEADING2]: this.#formatSelectionAsH2,
    [FloatingFormatterCommand.HEADING3]: this.#formatSelectionAsH3,
    [FloatingFormatterCommand.CLEAR_FORMAT]: this.#clearFormatting,
  }

  constructor(config: {}, view: EditorDOM) {
    this.#config = config
    this.#view = view
    Object.freeze(this.#commands)
  }

  #setRange() {
    let currentNoteElements = []

    // Ideally, we don't need these extra local variables.
    // These variables are declared and assigned to private members for
    // watching them in watch window during debug
    let rangeBoundary = {
      start: this.#currentRange?.startContainer,
      end: this.#currentRange?.endContainer,
    }
    this.#rangeBoundary = rangeBoundary

    let noteBoundary = {
      start: this.#view.getContainingNoteElement(
        this.#rangeBoundary?.start as HTMLElement
      ),
      end: this.#view.getContainingNoteElement(
        this.#rangeBoundary?.end as HTMLElement
      ),
    }
    this.#noteBoundary = noteBoundary

    if (this.#currentRange?.endContainer) {
      if (
        this.#view.isNoteElement(
          this.#currentRange.endContainer as HTMLElement
        ) &&
        this.#currentRange.endOffset === 0 &&
        this.#noteBoundary.end
      ) {
        this.#noteBoundary.end = this.#view.getPreviousNoteElement(
          this.#noteBoundary.end
        )
      }
    }

    let note = this.#noteBoundary.start

    if (note) {
      currentNoteElements.push(note)
    }

    while (note && note !== this.#noteBoundary.end) {
      note = this.#view.getNextNoteElement(note)
      currentNoteElements.push(note)
    }

    this.#currentNoteElements = currentNoteElements

    let currentSelectionRanges = this.#currentNoteElements.map(
      (note, index) => {
        let range = new Range()

        if (index === 0) {
          range.setStart(
            this.#currentRange?.startContainer ?? note,
            this.#currentRange?.startOffset ?? 0
          )
        } else {
          if (note.firstChild) {
            range.setStartBefore(note.firstChild)
          } else {
            range.setStart(note, 0)
          }
        }

        if (index === (this.#currentNoteElements?.length ?? 0) - 1) {
          if (
            this.#view.isNoteElement(
              this.#currentRange?.endContainer as HTMLElement
            ) &&
            this.#currentRange?.endOffset === 0 &&
            this.#noteBoundary?.end
          ) {
            range.setEnd(
              this.#noteBoundary.end,
              this.#noteBoundary.end.childNodes.length
            )
          } else {
            range.setEnd(
              this.#currentRange?.endContainer ?? note,
              this.#currentRange?.endOffset ?? note.children.length
            )
          }
        } else {
          if (note.lastChild) {
            range.setEndAfter(note.lastChild)
          } else {
            range.setEnd(note, 0)
          }
        }
        return range
      }
    )

    this.#currentSelectionRanges = currentSelectionRanges
    let lastRange =
      this.#currentSelectionRanges?.[this.#currentSelectionRanges?.length - 1]
    if (lastRange) {
      this.#caretRepoRange = new StaticRange({
        startContainer: lastRange.startContainer,
        startOffset: lastRange.startOffset,
        endContainer: lastRange.endContainer,
        endOffset: lastRange.endOffset,
      })
    }

    return
  }

  #resetRange() {
    if (this.#currentRange) {
      this.#currentRange.collapse(false)
      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(this.#currentRange)
    }

    this.#currentRange = undefined
    this.#currentNoteElements = undefined
    this.#currentSelectionRanges = undefined
  }

  getCurrentFormatInfo(range?: Range) {
    // Current selection can be turned bold if any element in the DOM tree
    // of selection is not bold
    // Current selection can be switched off from bold (and made non-bold), if
    // every element in the DOM tree of selection is bold
    if (!range) {
      return null
    }

    this.#currentRange = range

    // Check if #currentRange is within note element, if not, set it correctly
    let startNote = this.#view.getContainingNoteElement(range.startContainer)
    if (!startNote) {
      this.#currentRange.setStart(this.#view.getFirstNoteElement(), 0)
    }
    let endNote = this.#view.getContainingNoteElement(range.endContainer)
    if (!endNote) {
      let lastNoteElement = this.#view.getLastNoteElement()
      if (lastNoteElement) {
        this.#currentRange.setEnd(
          lastNoteElement,
          lastNoteElement.childNodes.length
        )
      }
    }

    let lastContainer = this.#currentRange.endContainer
    let lastOffset = this.#currentRange.endOffset
    // Exclude empty selections (where note element is empty)
    while (
      lastContainer &&
      this.#view.isNoteElement(lastContainer) &&
      lastOffset === 0
    ) {
      lastContainer = this.#view.getPreviousNoteElement(lastContainer)
      this.#currentRange.setEnd(lastContainer, lastContainer.childNodes.length)
      lastOffset = lastContainer.childNodes.length
    }

    let formatInfo = {
      [FloatingFormatterCommand.BOLD]: true,
      [FloatingFormatterCommand.ITALIC]: true,
      [FloatingFormatterCommand.UNDERLINED]: true,
      [FloatingFormatterCommand.UNORDERED_LIST]: true,
      [FloatingFormatterCommand.ORDERED_LIST]: true,
      [FloatingFormatterCommand.HEADING1]: true,
      [FloatingFormatterCommand.HEADING2]: true,
      [FloatingFormatterCommand.HEADING3]: true,
    }

    let callback = (node?: Node | ChildNode | null) => {
      // Only text elements within a td.note element are considered for format
      // info computation

      if (!node) {
        return
      }

      // Get the relevant element
      let element =
        node.nodeType === NodeType.TEXT_NODE ? node.parentElement : null

      // If node is not a text node, element will be null and we return
      if (!element) {
        return
      }

      // If this text node's parent is outside a note, return
      if (!element.closest('[data-luru-role="note-element"]')) {
        return
      }

      // Note:
      // For B, I, U, all elements in a selection should be B, I, U in order
      // for the menu to show B, I, U in toggle state ON
      formatInfo[FloatingFormatterCommand.BOLD] =
        formatInfo[FloatingFormatterCommand.BOLD] &&
        (element.closest('b') || element.closest('strong'))
          ? true
          : false

      formatInfo[FloatingFormatterCommand.ITALIC] =
        formatInfo[FloatingFormatterCommand.ITALIC] &&
        (element.closest('em') || element.closest('i'))
          ? true
          : false

      formatInfo[FloatingFormatterCommand.UNDERLINED] =
        formatInfo[FloatingFormatterCommand.UNDERLINED] && element.closest('u')
          ? true
          : false

      formatInfo[FloatingFormatterCommand.HEADING1] =
        formatInfo[FloatingFormatterCommand.HEADING1] &&
        this.#view.getJottingType(element) === JottingType.H1

      formatInfo[FloatingFormatterCommand.HEADING2] =
        formatInfo[FloatingFormatterCommand.HEADING2] &&
        this.#view.getJottingType(element) === JottingType.H2

      formatInfo[FloatingFormatterCommand.HEADING3] =
        formatInfo[FloatingFormatterCommand.HEADING3] &&
        this.#view.getJottingType(element) === JottingType.H3

      formatInfo[FloatingFormatterCommand.ORDERED_LIST] =
        formatInfo[FloatingFormatterCommand.ORDERED_LIST] &&
        [JottingType.OL1, JottingType.OL2, JottingType.OL3].includes(
          this.#view.getJottingType(element) as JottingType
        )

      formatInfo[FloatingFormatterCommand.UNORDERED_LIST] =
        formatInfo[FloatingFormatterCommand.UNORDERED_LIST] &&
        [JottingType.UL1, JottingType.UL2, JottingType.UL3].includes(
          this.#view.getJottingType(element) as JottingType
        )
    }

    DomUtils.traverseDOMWithCallback({
      startNode: range.startContainer,
      endNode: range.endContainer,
      callback,
      context: this,
      checkTerminateCallback: () => false,
    })

    return formatInfo
  }

  formatSelectedText({
    command,
    payload,
  }: {
    command: string
    payload: { state: Boolean; url: string; color: string }
  }): void {
    this.#setRange()
    if (command in this.#commands) {
      this.#commands[command].call(this, payload)
    } else {
      console.log({ command, payload })
    }
    this.#setCaretAfterFormat()
    this.#resetRange()
  }

  #formatContentBold({ state }: { state: Boolean }): void {
    if (state && this.#currentSelectionRanges) {
      // Remove bold
      this.#currentSelectionRanges.forEach((range) => {
        let rangeElement = range.endContainer as HTMLElement
        let noteElement = this.#view.getContainingNoteElement(rangeElement)
        let checkFn = (n: Node): Boolean => n !== noteElement
        let selectionBoundary = DomUtils.nonPartialSelectRange(
          range,
          checkFn,
          noteElement
        )
        setTimeout(() => {
          DomUtils.clearTagsBetween(selectionBoundary, ['strong', 'b'])
        })
      })
    } else if (this.#currentSelectionRanges) {
      // Add bold
      this.#currentSelectionRanges.forEach((range) => {
        let rangeElement = range.endContainer as HTMLElement
        let noteElement = this.#view.getContainingNoteElement(rangeElement)
        let checkFn = (n: Node): Boolean => n !== noteElement
        let selectionBoundary = DomUtils.nonPartialSelectRange(
          range,
          checkFn,
          noteElement
        )
        DomUtils.clearTagsBetween(selectionBoundary, ['strong', 'b'])
        this.#formatSelection(selectionBoundary, 'strong', noteElement)
      })
    }
  }

  #formatContentItalic({ state }: { state: Boolean }): void {
    if (state && this.#currentSelectionRanges) {
      // Remove italic
      this.#currentSelectionRanges.forEach((range) => {
        let rangeElement = range.endContainer as HTMLElement
        let noteElement = this.#view.getContainingNoteElement(rangeElement)
        let checkFn = (n: Node): Boolean => n !== noteElement
        let selectionBoundary = DomUtils.nonPartialSelectRange(
          range,
          checkFn,
          noteElement
        )
        setTimeout(() => {
          DomUtils.clearTagsBetween(selectionBoundary, ['em', 'i'])
        })
      })
    } else if (this.#currentSelectionRanges) {
      // Add italic
      this.#currentSelectionRanges.forEach((range) => {
        let rangeElement = range.endContainer as HTMLElement
        let noteElement = this.#view.getContainingNoteElement(rangeElement)
        let checkFn = (n: Node): Boolean => n !== noteElement
        let selectionBoundary = DomUtils.nonPartialSelectRange(
          range,
          checkFn,
          noteElement
        )
        DomUtils.clearTagsBetween(selectionBoundary, ['em', 'i'])
        this.#formatSelection(selectionBoundary, 'em', noteElement)
      })
    }
  }

  #formatContentUnderlined({ state }: { state: Boolean }): void {
    if (state && this.#currentSelectionRanges) {
      // Remove underline
      this.#currentSelectionRanges.forEach((range) => {
        let rangeElement = range.endContainer as HTMLElement
        let noteElement = this.#view.getContainingNoteElement(rangeElement)
        let checkFn = (n: Node): Boolean => n !== noteElement
        let selectionBoundary = DomUtils.nonPartialSelectRange(
          range,
          checkFn,
          noteElement
        )
        setTimeout(() => {
          DomUtils.clearTagsBetween(selectionBoundary, ['u'])
        })
      })
    } else if (this.#currentSelectionRanges) {
      // Add underline
      this.#currentSelectionRanges.forEach((range) => {
        let rangeElement = range.endContainer as HTMLElement
        let noteElement = this.#view.getContainingNoteElement(rangeElement)
        let checkFn = (n: Node): Boolean => n !== noteElement
        let selectionBoundary = DomUtils.nonPartialSelectRange(
          range,
          checkFn,
          noteElement
        )
        DomUtils.clearTagsBetween(selectionBoundary, ['u'])
        this.#formatSelection(selectionBoundary, 'u', noteElement)
      })
    }
  }

  #highlightContent({ color }: { color: string }): void {
    if (this.#currentSelectionRanges) {
      this.#currentSelectionRanges.forEach((range) => {
        let hlElement = document.createElement('MARK')
        hlElement.classList.add(styles.highlight)
        hlElement.classList.add(styles[color])

        range.surroundContents(hlElement)
      })
    }
  }

  #insertLink({ url }: { url: string }): void {
    if (
      Array.isArray(this.#currentSelectionRanges) &&
      this.#currentSelectionRanges.length > 0
    ) {
      let firstRange = this.#currentSelectionRanges[0]
      if (!firstRange) {
        return
      }

      this.#view.insertLink({
        linkUrl: url,
        startContainer: firstRange.startContainer,
        startOffset: firstRange.startOffset,
        endContainer: firstRange.endContainer,
        endOffset: firstRange.endOffset,
      })
    }
  }

  #removeLink(): void {
    if (Array.isArray(this.#currentSelectionRanges)) {
      // Remove formatting
      this.#currentSelectionRanges.forEach((range) => {
        let rangeElement = range.endContainer as HTMLElement
        let noteElement = this.#view.getContainingNoteElement(rangeElement)
        let checkFn = (n: Node): Boolean => n !== noteElement
        let selectionBoundary = DomUtils.nonPartialSelectRange(
          range,
          checkFn,
          noteElement
        )
        setTimeout(() => {
          DomUtils.clearTagsBetween(selectionBoundary, ['a'])
        })
      })
    }
  }

  #formatSelectionAsUL({ state }: { state: Boolean }): void {
    if (state) {
      // Remove UL
      this.#changeSelectionJottingType(JottingType.P)
    } else {
      // Add UL
      this.#changeSelectionJottingType(JottingType.UL1)
    }
  }

  #formatSelectionAsOL({ state }: { state: Boolean }): void {
    if (state) {
      // Remove OL
      this.#changeSelectionJottingType(JottingType.P)
    } else {
      // Add OL
      this.#changeSelectionJottingType(JottingType.OL1)
    }
  }

  #formatSelectionAsH1({ state }: { state: Boolean }): void {
    if (state) {
      // Remove H1
      this.#changeSelectionJottingType(JottingType.P)
    } else {
      // Add H1
      this.#changeSelectionJottingType(JottingType.H1)
    }
  }

  #formatSelectionAsH2({ state }: { state: Boolean }): void {
    if (state) {
      // Remove H2
      this.#changeSelectionJottingType(JottingType.P)
    } else {
      // Add H2
      this.#changeSelectionJottingType(JottingType.H2)
    }
  }

  #formatSelectionAsH3({ state }: { state: Boolean }): void {
    if (state) {
      // Remove H3
      this.#changeSelectionJottingType(JottingType.P)
    } else {
      // Add H3
      this.#changeSelectionJottingType(JottingType.H3)
    }
  }

  #clearFormatting() {
    if (!this.#currentSelectionRanges) {
      return
    }
    // Remove formatting
    this.#currentSelectionRanges.forEach((range) => {
      let rangeElement = range.endContainer as HTMLElement
      let noteElement = this.#view.getContainingNoteElement(rangeElement)
      let checkFn = (n: Node): Boolean => n !== noteElement
      let selectionBoundary = DomUtils.nonPartialSelectRange(
        range,
        checkFn,
        noteElement
      )
      setTimeout(() => {
        let clearTags = ['a', 'strong', 'b', 'em', 'i', 'u', 'font', 'mark']
        clearTags.forEach((tag) => {
          DomUtils.clearTagsBetween(selectionBoundary, [tag])
        })
      })
    })
    // Change jotting type to default P
    this.#changeSelectionJottingType(JottingType.P)
  }

  #formatSelection(
    boundary: IDOMBoundary,
    tagName: string,
    noteElement: Node
  ): void {
    // Get the positions from boundary
    let { startPosition, endPosition, startNode, endNode } = boundary
    // Add required tag over the enveloped range
    let formatRange = new Range()

    if (startNode?.nodeType === NodeType.ELEMENT_NODE) {
      formatRange.setStartAfter(startNode)
    } else if (startPosition.container) {
      formatRange.setStart(startPosition.container, startPosition.offset)
    }
    if (endNode?.nodeType === NodeType.ELEMENT_NODE) {
      formatRange.setEndAfter(endNode)
    } else if (endPosition.container) {
      formatRange.setEnd(endPosition.container, endPosition.offset)
    }

    let newElement = document.createElement(tagName)
    formatRange.surroundContents(newElement)

    // Normalize HTML of note element
    noteElement.normalize()
  }

  #setCaretAfterFormat() {
    setTimeout(() => {
      if (this.#caretRepoRange) {
        try {
          let caretRepoRange = new Range()
          caretRepoRange.setEnd(
            this.#caretRepoRange.endContainer,
            this.#caretRepoRange.endOffset
          )
          caretRepoRange.collapse(false)
          document.getSelection()?.removeAllRanges()
          document.getSelection()?.addRange(caretRepoRange)
        } catch (e) {}
      }
    }, 50)
  }

  #changeSelectionJottingType(jottingType: string): void {
    if (!this.#currentNoteElements) {
      return
    }
    this.#currentNoteElements.forEach((note) => {
      this.#view.changeJottingType(
        this.#view.getJottingElement(note),
        jottingType
      )
    })
  }
}
