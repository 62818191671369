import { Route } from 'react-router-dom'
import DealsDashboardHomeLazyLoading from './dealsDashboard/DealsDashboardHome'

const renderDealsDashboardRoutes = () => (
  <Route path='/deals_dashboard' element={<DealsDashboardHomeLazyLoading />}>
    <Route index element={<DealsDashboardHomeLazyLoading />} />
  </Route>
)

export default renderDealsDashboardRoutes
