import { PayloadAction } from '@reduxjs/toolkit'
import { listOrSearchEmptyState, ReduxAppState } from '../types'

export const clearMeetingNotesHomeSearchQuery = (
  state: ReduxAppState,
  action: PayloadAction<undefined>
) => {
  state.meetingNotesHome.search.query = null
  state.home.search.upcomingMeetings = {
    ...listOrSearchEmptyState,
  }
}
