import { LuruAPIResponse } from '../../../app/types'
import { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { ReduxNoteEntity } from '../types'
import searchLuruNotes from './searchLuruNotes'
import searchSORNotes from './searchSORNotes'

export type SearchNotesParameter = {
  key: string
  refreshRequest: boolean
  q?: string
  cursor?: string
  limit?: number
  sor_object_name?: string
  sor_record_id?: string
  loadMore?: boolean
}

export interface SearchNotesAPIResponse extends LuruAPIResponse {
  data: Array<Partial<ReduxNoteEntity>>
}

export default async function searchAllNotes(
  params: Readonly<SearchNotesParameter>,
  options: LuruAPIRequestOptions
) {
  return Promise.all([
    searchLuruNotes(params, options),
    searchSORNotes(params, options),
  ])
}
