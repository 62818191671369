// React
import React from 'react'

// Components
import { EditLinkDialogMode } from '../primitives/EditLinkDialog'
import AppComponentsContext from './AppComponents/AppComponentsContext'

// Helpers
import DomUtils from '../coreEditor/utils/DomUtils'

// Styles
import styles from './css/LinkDetailsPopup.module.css'

export default class LinkDetailsPopup extends React.Component {
  static contextType = AppComponentsContext

  constructor(props) {
    super(props)
    this.state = {
      url: '',
      visible: false,
      linkElement: null,
    }

    // Observed elements
    this.targets = []

    // Element refs
    this.elements = {
      popup: React.createRef(),
      hyperlink: React.createRef(),
    }

    // Bindings
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.showEditDialog = this.showEditDialog.bind(this)
    this.hideAndResetState = this.hideAndResetState.bind(this)

    this.intervalTimer = null
  }

  setupForElement(target) {
    if (target && target.addEventListener) {
      target.addEventListener('mouseover', this.show)
      target.addEventListener('mouseout', this.hide)
      this.targets.push(target)
    }
  }

  teardownForElement(target) {
    target.removeEventListener('mouseover', this.show)
    target.removeEventListener('mouseout', this.hide)
    this.hide()
  }

  componentWillUnmount() {
    this.targets
      .filter((target) => Boolean(target))
      ?.forEach((element) => this.teardownForElement(element))
  }

  showEditDialog(e) {
    e.preventDefault()

    let linkElement = this.state.linkElement
    let hyperlink = this.elements.hyperlink?.current
    let url = this.state.url

    this.context.editLinkDialog?.current?.showDialog({
      url,
      mode: EditLinkDialogMode.UPDATE_LINK,
      tip: 'You can edit the link text directly in editor',
      callback: (givenUrl) => {
        var newUrl = !givenUrl.startsWith('http://')
          ? `http://${givenUrl}`
          : givenUrl
        hyperlink?.setAttribute('href', newUrl)
        if (linkElement.textContent === url) {
          linkElement.textContent = givenUrl
        }
        linkElement.setAttribute('data-luru-url', givenUrl)
      },
    })
  }

  show({ currentTarget }) {
    if (this.intervalTimer) {
      clearTimeout(this.intervalTimer)
      this.intervalTimer = null
    }
    const targetPosition = DomUtils.getElementScreenCoordinates(currentTarget)
    if (this.elements.popup.current) {
      this.elements.popup.current.style.left = targetPosition.getX() + 'px'
      this.elements.popup.current.style.top =
        targetPosition.getY() - this.elements.popup.current.offsetHeight + 'px'
    }
    this.setState({
      url: currentTarget.getAttribute('data-luru-url'),
      visible: true,
      linkElement: currentTarget,
    })
  }

  hideAndResetState() {
    if (this.elements?.popup?.current) {
      this.elements.popup.current.style.left = '-1000px'
      this.elements.popup.current.style.top = '-1000px'
    }
    this.setState({ url: '', visible: false, linkElement: null })
  }

  hide(e) {
    // If there is no event associated, we must be calling this from a teardown
    // chain.  Just hide the popup with relevant state changes, already!
    // Check if hiding is actually necessary before hiding
    if (!e) {
      if (this.state.visible) {
        this.hideAndResetState()
      }
      return
    }

    // If mouse moved into a link from popup or from popup to link, don't hide
    if (
      e.relatedTarget === this.elements.popup.current ||
      this.targets.includes(
        e.relatedTarget?.closest('[data-luru-role="hyperlink-helper"]')
      )
    ) {
      return
    }

    // Clear any existing timer to avoid multiple calls/race conditions
    if (this.intervalTimer) {
      clearTimeout(this.intervalTimer)
    }

    // Hide after a small delay, to avoid conditions when mouse event is
    // happening near the 'event horizon' (LOL!)
    this.intervalTimer = setTimeout(this.hideAndResetState, 100)
  }

  render() {
    return (
      // linkHelper is the element that pops up when user hovers over the link
      // element (which is a SPAN)
      <>
        <div
          data-luru-role='hyperlink-helper'
          contentEditable={false}
          className={styles[`visible-${this.state.visible}`]}
          ref={this.elements.popup}
          onMouseOut={this.hide}
          onMouseOver={() => clearTimeout(this.intervalTimer)}
        >
          {/* linkHelper popup has two actual links - 1. go to link, 2. edit link */}
          <a
            data-luru-role='hyperlink'
            href={
              this.state.url?.startsWith('http')
                ? this.state.url
                : `http://${this.state.url}`
            }
            ref={this.elements.hyperlink}
            target='_blank'
            rel='noreferrer'
          >
            Go to link
          </a>
          |
          <button data-luru-role='hyperlink-edit' onClick={this.showEditDialog}>
            Edit
          </button>
        </div>
      </>
    )
  }
}
