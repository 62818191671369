import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { EntityStatus } from '../../../app/types'
import { CollectionsMiddleware } from '../../../features/collections/middleware'
import { getSORConnection } from '../../../features/notes/selectors/getSORConnection'
import LoadingSpinner from '../../../primitives/LoadingSpinner'
import { EditorEntityType } from '../../EditorController'
import LuruCrmFieldInputSet from '../../../primitives/domain/crm/LuruCrmFieldInputSet'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import styles from './EmbeddedCollection.module.css'
import warningIcon from '../../../images/fluent/error_circle.svg'
import infoIcon from '../../../images/fluent/info.svg'
import deleteIcon from '../../../images/fluent/delete.svg'
import { CrmObjectName, ReduxCrmKey } from '../../../features/crm/types'
import { useSchema } from '../../../features/crm/hooks/useSchema'
import { useMandatoryFields } from '../../../features/crm/hooks/useMandatoryFields'
import { useFieldValues } from '../../../features/crm/hooks/useFieldValues'
import { compareFieldValues } from '../../../features/crm/helpers/compareFieldValues'
import { SourceEntityType } from '../../../routes/notes/components/CRMCollectionChooser'
import Tooltip from '@/components/Tooltip'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

export interface EmbeddedCollectionProps {
  entityType: (typeof EditorEntityType)[keyof typeof EditorEntityType]
  entityId: string | undefined
  collectionId: string
  collectionObjectName: string
  fields: Array<string>
  isReadOnly: boolean
  onDeleteCollection: () => void
}

export const EmbeddedCollection = ({
  entityId,
  entityType,
  collectionId,
  collectionObjectName,
  fields,
  isReadOnly,
  onDeleteCollection,
}: EmbeddedCollectionProps) => {
  var content = <></>

  const [status, setStatus] = useState<EntityStatus>(EntityStatus.Idle)

  const { showToast } = useLuruToast()

  // Connected CRM
  const crmId = useAppSelector((s) => s.user?.data?.userSettings?.connectedCRM?.name)

  // Linked CRM Record
  const linkedCRMRecord = useAppSelector(getSORConnection(entityId, crmId, null))

  const crmKey = crmId?.toLocaleLowerCase() as ReduxCrmKey

  const sorObjectName = linkedCRMRecord?.connection?.sor_object_name as CrmObjectName | undefined

  const sorRecordId = linkedCRMRecord?.connection?.sor_record_id

  // Linked object name for template
  const templateLinkedObject = useAppSelector((s) =>
    entityId ? s.noteTemplates.entities[entityId]?.data?.filter?.sorObjectName : null
  )

  // Collection status
  const collectionStatus = useAppSelector((s) => s.collections.entities[collectionId]?.status)

  // Collection entity
  const collection = useAppSelector((s) => s.collections.entities[collectionId]?.data)

  // Check isOriginalCollectionDeleted
  var isOriginalCollectionDeleted = collectionStatus === EntityStatus.ErrorLoading

  // Collection fields
  const collectionFields = collection?.fields ?? fields

  // CRM record type
  const crmRecordType = CrmRecord.getCrmRecordType(collectionObjectName)

  // Schema
  const { schemaMap } = useSchema(collectionObjectName as CrmObjectName | undefined)

  // Schema mandatory fields
  const mandatoryFields = useMandatoryFields(collectionObjectName as CrmObjectName | undefined)

  // Field values
  const currentFieldValues = useFieldValues(collection?.fields, sorObjectName, sorRecordId)

  // Record status
  const recordStatus = useAppSelector((state) =>
    crmId && sorObjectName && sorRecordId
      ? state.crm[crmKey]?.entities?.[sorObjectName]?.[sorRecordId]?.status
      : undefined
  )

  // User alert message
  const [userAlertMessage, setUserAlertMessage] = useState<ReactNode | undefined>(undefined)

  // Store and manage changing record values
  const [recordValues, setRecordValues] = useState<Record<string, any>>({})

  const handleValueChange = useCallback(
    (fieldName: string, value: any) => {
      const fieldType = schemaMap?.fields[fieldName]?.luruFieldType

      const hasValueChanged = !compareFieldValues(currentFieldValues[fieldName], value, fieldType)

      if (hasValueChanged) {
        setRecordValues((recordValues) => ({
          ...recordValues,
          [fieldName]: value,
        }))
      } else if (fieldName in recordValues) {
        setRecordValues((recordValues) => {
          const { [fieldName]: removed, ...rest } = recordValues
          return rest
        })
      }
    },
    [currentFieldValues, schemaMap, recordValues]
  )

  // Handle update record
  const handleUpdateRecord = useCallback(async () => {
    if (!crmRecordType || !sorRecordId) {
      return
    }

    // Start with an empty alert message
    setUserAlertMessage(undefined)
    headingRef.current?.classList.remove(styles.errorUpdating)

    // Check for presence of all mandatory fields
    let isFieldMissed = false

    for (let fieldName in currentFieldValues) {
      let isMandatoryField = mandatoryFields?.includes(fieldName) ?? false

      isFieldMissed =
        isFieldMissed ||
        (isMandatoryField &&
          fieldName in recordValues &&
          (recordValues[fieldName] === '' || recordValues[fieldName] === null || recordValues[fieldName] === undefined))

      if (isFieldMissed) {
        break
      }
    }

    if (isFieldMissed) {
      setUserAlertMessage('Please fill all mandatory fields')
      return
    }

    // Set status to loading
    headingRef.current?.classList.add(styles.updating)

    // Execute update
    try {
      await CrmRecord.multiUpdate({ crmRecordType, sorRecordId, fields: recordValues })
      headingRef.current?.classList.remove(styles.updating)
      headingRef.current?.classList.add(styles.successUpdating)
      showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: 'Updated successfully',
        severity: 'success',
      })
      setRecordValues({})
      setTimeout(() => headingRef.current?.classList.remove(styles.successUpdating), 2000)
    } catch (e) {
      setUserAlertMessage((e as Error).message)
      headingRef.current?.classList.remove(styles.updating)
      headingRef.current?.classList.add(styles.errorUpdating)
      showToast({
        id: ToastId.COLLECTION_TOAST_ID,
        message: 'Error updating:' + (e as Error).message,
        severity: 'error',
      })
    }
  }, [crmRecordType, recordValues, sorRecordId, currentFieldValues, mandatoryFields, showToast])

  // Heading ref
  const headingRef = useRef<HTMLDivElement>(null)

  useLoadCollection(collectionId)

  if (entityType === EditorEntityType.Note && !linkedCRMRecord) {
    content = (
      <div className={styles.info}>Please connect note to a {CrmRecord.getCrmName()} object to see fields here</div>
    )
  }

  if (entityType === EditorEntityType.NoteTemplate && (!templateLinkedObject || templateLinkedObject === 'All')) {
    content = <div className={styles.info}>Please link playbook to an object to see fields here</div>
  }

  if (collectionStatus === EntityStatus.Loading) {
    content = (
      <div className={styles.info}>
        <LoadingSpinner />
        Loading collection...
      </div>
    )
  } else if (
    ((entityType === EditorEntityType.Note && collectionObjectName !== linkedCRMRecord?.connection?.sor_object_name) ||
      (entityType === EditorEntityType.NoteTemplate && collectionObjectName !== templateLinkedObject)) &&
    collectionStatus === EntityStatus.ErrorLoading
  ) {
    content = (
      <div className={styles.info}>
        Collection {collection?.name ? `'${collection?.name}'` : ''} can be used only when this{' '}
        {entityType === EditorEntityType.Note ? 'note' : 'playbook'} is linked to {collectionObjectName}
      </div>
    )
  } else {
    content = (
      <>
        <div
          className={[styles.heading, Object.keys(recordValues).length > 0 ? styles.modified : null].join(' ')}
          ref={headingRef}
        >
          <label>{collection?.name}</label>
          <span>{Object.keys(recordValues).length > 0 ? ' \u25CF' : ''}</span>
        </div>
        <div className={styles.container}>
          {userAlertMessage ? <div className={styles.userAlert}>{userAlertMessage}</div> : null}
          {userAlertMessage ? (
            <button className={styles.info}>
              <img src={warningIcon} alt='Warning' />
            </button>
          ) : isOriginalCollectionDeleted ? (
            <button className={styles.info}>
              <Tooltip
                label={
                  <>
                    Cannot find original collection. <br /> Showing fields last used
                  </>
                }
                placement='bottom'
              >
                <img src={infoIcon} alt='Info' />
              </Tooltip>
            </button>
          ) : null}
          <button
            className={[
              isReadOnly || entityType !== SourceEntityType.NOTE ? styles.deleteRightPos : null,
              styles.delete,
            ].join(' ')}
            onClick={onDeleteCollection}
          >
            <Tooltip label='Remove this field collection from note' placement='bottom'>
              <img src={deleteIcon} alt='Delete' />
            </Tooltip>
          </button>
          {entityType === SourceEntityType.NOTE ? (
            recordStatus === EntityStatus.Loading ? (
              <button className={styles.update} disabled={true}>
                Update
              </button>
            ) : !isReadOnly ? (
              <button
                disabled={
                  status === EntityStatus.Loading ||
                  status === EntityStatus.ErrorLoading ||
                  Object.keys(recordValues || {}).length <= 0
                }
                className={styles.update}
                onClick={handleUpdateRecord}
                title={Object.keys(recordValues || {}).length <= 0 ? 'Edit the values before updating' : ''}
              >
                Update
              </button>
            ) : null
          ) : null}
          <LuruCrmFieldInputSet
            fieldNames={collectionFields}
            crmRecordType={crmRecordType}
            sorRecordId={linkedCRMRecord?.connection?.sor_record_id}
            size={'wide'}
            disabled={isReadOnly || entityType === SourceEntityType.TEMPLATE}
            onChange={handleValueChange}
            showErroneousFields={Boolean(userAlertMessage)}
            errorMessageWhenErrorLoading='Error loading record'
            onStatusChange={(status) => setStatus(status)}
          />
          {entityType === SourceEntityType.NOTE && (
            <div className={styles.action}>
              <button
                disabled={
                  recordStatus === EntityStatus.Loading ||
                  status === EntityStatus.Loading ||
                  status === EntityStatus.ErrorLoading ||
                  Object.keys(recordValues || {}).length <= 0
                }
                className={[styles.update, styles.bottom].join(' ')}
                onClick={handleUpdateRecord}
                title={Object.keys(recordValues || {}).length <= 0 ? 'Edit the values before updating' : ''}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </>
    )
  }

  if (!entityId) {
    return null
  }

  return (
    <div className={styles.parent} data-luru-role={`embedded-collection-${entityType}`}>
      {content}
    </div>
  )
}

const useLoadCollection = (collectionId: string) => {
  const reduxDispatch = useAppDispatch()
  const collectionStatus = useAppSelector((s) => s.collections.entities[collectionId]?.status)

  useEffect(() => {
    if (
      collectionStatus !== EntityStatus.Loaded &&
      collectionStatus !== EntityStatus.ErrorLoading &&
      collectionStatus !== EntityStatus.Updated &&
      collectionStatus !== EntityStatus.ErrorUpdating
    ) {
      reduxDispatch(CollectionsMiddleware.fetchCollection.action({ collectionId }))
    }
  }, [collectionId, reduxDispatch, collectionStatus])
}
