import ChipSelectBox, { ChipSelectBoxState, ChipSelectBoxProps } from '.'
import update from 'immutability-helper'

export interface ChipSelectBoxEvents {
  onReset: () => void
  onFocus: () => void
  onBlur: () => void
  onToggleItem: (key: string) => void
  onFilterBoxChange: (value: string | null) => void
  onFilterBoxNavigateDown: () => void
  onFilterBoxNavigateUp: () => void
  onFilterBoxReturn: () => void
}

export default class ChipSelectBoxEventHandler {
  #component: ChipSelectBox
  handlers: ChipSelectBoxEvents

  constructor(component: ChipSelectBox) {
    this.#component = component
    this.handlers = {
      onReset: this.#onReset.bind(this),
      onFocus: this.#onFocus.bind(this),
      onBlur: this.#onBlur.bind(this),
      onFilterBoxChange: this.#onFilterBoxChange.bind(this),
      onFilterBoxNavigateUp: this.#onFilterBoxNavigateUp.bind(this),
      onFilterBoxNavigateDown: this.#onFilterBoxNavigateDown.bind(this),
      onFilterBoxReturn: this.#onFilterBoxReturn.bind(this),
      onToggleItem: this.#onToggleItem.bind(this),
    }
    this.#component.componentDidUpdate = this.#onComponentUpdate.bind(this)
  }

  #onComponentUpdate(prevProps: ChipSelectBoxProps) {
    if (
      prevProps.items !== this.#component.props.items ||
      prevProps.selectedItems !== this.#component.props.selectedItems
    ) {
      this.#component.setState({
        filteredItems: this.#component.props.items,
        selectedItems:
          this.#component.props.selectedItems ||
          this.#component.props.items
            .filter((x) => x.isSelected)
            .map((f) => f.key),
      })
    }
    this.#navigateToSelectedItem()
  }

  #onFilterBoxChange(value: string | null) {
    var filterText = value ?? ''
    this.#component.setState({
      filteredItems: this.#component.props.items.filter(
        (item) =>
          item.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
      ),
    })
  }

  #onFilterBoxReturn() {
    var chosenIndex = this.#component.state.highlightedIndex
    if (
      chosenIndex >= 0 &&
      chosenIndex < this.#component.state.filteredItems.length
    ) {
      let chosenItem = this.#component.state.filteredItems[chosenIndex]
      this.#toggleItem(chosenItem)
    }
  }

  #onFilterBoxNavigateUp() {
    var nextIndex =
      this.#component.state.highlightedIndex <= -1
        ? this.#component.state.filteredItems.length - 1
        : this.#component.state.highlightedIndex - 1

    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #onFilterBoxNavigateDown() {
    var nextIndex =
      this.#component.state.highlightedIndex >=
      this.#component.state.filteredItems.length - 1
        ? -1
        : this.#component.state.highlightedIndex + 1
    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #navigateToSelectedItem() {
    var ulElement = this.#component.componentRefs.list
      .current as HTMLUListElement
    var liElement = ulElement?.querySelector(
      'li[data-highlighted="yes"]'
    ) as HTMLLIElement

    if (liElement) {
      let topOverflow = Math.max(
        0,
        ulElement.scrollTop + liElement.offsetHeight - liElement.offsetTop
      )
      let bottomOverflow = Math.max(
        0,
        liElement.offsetTop +
          liElement.offsetHeight -
          ulElement.scrollTop -
          ulElement.offsetHeight
      )
      if (topOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: -topOverflow, behavior: 'instant' })
      } else if (bottomOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: bottomOverflow, behavior: 'instant' })
      }
    }
  }

  #onReset() {
    this.#component.setState({ selectedItems: [] })
    this.#component.props?.onReset?.()
  }

  #onFocus() {
    !this.#component.props.disabled && this.#component.setState({ focus: true })
    this.#component.componentRefs.popupMenu.current?.showMenu()
  }

  #onBlur() {
    this.#component.setState({ focus: false })
  }

  #onToggleItem(key: string) {
    const toggledItem = this.#component.props.items.find(
      (item) => item.key === key && !item.isDisabled
    )
    if (toggledItem) {
      this.#toggleItem(toggledItem)
    }
  }

  #toggleItem(item: { name: string; key: string }) {
    var itemIx = this.#component.state.selectedItems.findIndex(
      (k) => k === item.key
    )

    if (itemIx !== -1) {
      this.#component.setState(
        (prevState: ChipSelectBoxState) => ({
          selectedItems: update(prevState.selectedItems, {
            $splice: [[itemIx, 1]],
          }),
        }),
        () => {
          this.#component.props?.onDeselectItem?.(
            item.key,
            this.#component.state.selectedItems
          )
          this.#component.props?.onSelect?.(this.#component.state.selectedItems)
        }
      )
    } else {
      this.#component.setState(
        (prevState: ChipSelectBoxState) => ({
          selectedItems: update(prevState.selectedItems, {
            $push: [item.key],
          }),
        }),
        () => {
          this.#component.props?.onSelectItem?.(
            item.key,
            this.#component.state.selectedItems
          )
          this.#component.props?.onSelect?.(this.#component.state.selectedItems)
        }
      )
    }
    this.#component.componentRefs.filterBox?.current?.reset?.()
  }
}
