import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { CreateCollectionParameter } from '../middleware/createCollection'

export const createCollection = async (
  params: Readonly<CreateCollectionParameter>,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest('/api/collections', HTTPMethod.POST, options)
  var requestBody: Partial<CreateCollectionParameter> = { ...params }

  for (let key in params) {
    let typedKey = key as keyof CreateCollectionParameter

    if (params[typedKey] === undefined || params[typedKey] === null) {
      delete requestBody[typedKey]
    }
  }

  request.setBody(requestBody)

  return request.make()
}
