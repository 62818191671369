import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import LuruError from '../../LuruError'
import { ReduxNoteEntity } from '../../notes/types'
import { MeetingsAPI } from '../api'
import { MeetingLocation, MeetingParticipant, ReduxMeetingsState } from '../types'

export interface ListMeetingsParameter {
  key: string
  q?: string | null
  time_min?: string | null
  time_max?: string | null
  limit?: number | null
  cursor?: string | null
  include_notes?: boolean | null
  loadMore?: boolean | null
  loadPrev?: boolean | null
}

export interface ListMeetingsAPIResponseEntity {
  meeting_id: string
  subject: string
  body: null | string
  start_time: string
  end_time: string
  organizer: MeetingParticipant
  participants: Array<MeetingParticipant>
  locations: Array<MeetingLocation>
  notes: Array<ReduxNoteEntity>
}

export interface ListMeetingsAPIResponse extends LuruAPIResponse {
  data: Array<ListMeetingsAPIResponseEntity>
}

export const listMeetings = {
  action: createAsyncThunk<
    ListMeetingsAPIResponse['data'],
    ListMeetingsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ListMeetingsAPIResponse['metadata'] | null
      rejectedMeta: ListMeetingsAPIResponse['metadata'] | null
    }
  >('meetings/listMeetings', async (params, { signal, fulfillWithValue, rejectWithValue, getState }) => {
    var connectedCalendar = LuruUser.getCurrentUserCalendarName(getState())
    var connectedCalendarErrorCode =
      getState()?.user?.data?.userSettings?.connectedCalendar?.errorCode || getState()?.meetings?.error?.error_code

    if (!connectedCalendar) {
      let errorResponse = {
        error_code: 10100,
        message: 'No calendar connection exists',
        description: 'Please connect to your calendar and retry',
      }
      return rejectWithValue(errorResponse, null)
    }

    if (connectedCalendarErrorCode) {
      let errorResponse = {
        error_code: connectedCalendarErrorCode,
        message: 'Error accessing calendar API',
        description: 'Please connect to your calendar and retry',
      }
      return rejectWithValue(errorResponse, null)
    }

    try {
      var meetings = (await MeetingsAPI.listMeetings(params, {
        signal,
      })) as ListMeetingsAPIResponse

      return fulfillWithValue(meetings.data, meetings.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(listMeetings.action.pending, (state, action) => {
      state.status = EntityStatus.Loading
      state.error = null
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(listMeetings.action.fulfilled, (state, action) => {
      var meetings = action.payload

      if (!state.entities) {
        state.entities = {}
      }

      for (let meeting of meetings) {
        state.entities[meeting.meeting_id] = {
          status: EntityStatus.Loaded,
          data: {
            ...meeting,
            // Store only the note_ids as a part of store.meetings.entities[meeting_id]
            notes: meeting.notes ?? [],
          },
        }
      }
      state.refreshedAt = new Date().toISOString()
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(listMeetings.action.rejected, (state, action) => {
      state.status = EntityStatus.ErrorLoading
      state.error = action.payload as any
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    listMeetings.addPendingCase(builder)
    listMeetings.addFulfilledCase(builder)
    listMeetings.addRejectedCase(builder)
  },
}
