import { setEmailSyncInfo } from './setEmailSyncInfo'
import { getEmailSyncInfo } from './getEmailSyncInfo'
import { disconnectGCal } from './disconnectGCal'
import { disconnectZoom } from './disconnectZoom'
import { disconnectSlack } from './disconnectSlack'
import { disconnectO365Cal } from './disconnectO365Cal'
import { updateNotificationStats } from './updateNotificationStats'
import { disconnectMSTeams } from './disconnectMSTeams'
import { disconnectGChat } from './disconnectGChat'
import { disconnectGmail } from './disconnectGmail'
import { disconnectO365Mail } from './disconnectO365Mail'

export const UserAPI = {
  disconnectGCal,
  disconnectZoom,
  disconnectSlack,
  disconnectO365Cal,
  disconnectMSTeams,
  disconnectGChat,
  disconnectGmail,
  disconnectO365Mail,
  updateNotificationStats,
  getEmailSyncInfo,
  setEmailSyncInfo,
}
