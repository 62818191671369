import { HTTPMethod } from '../../../app/types'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { FetchViewParameter } from '../middleware/fetchView'

export const fetchView = (
  params: Readonly<FetchViewParameter>,
  options: LuruAPIRequestOptions
) => {
  var url = `/api/views/${params.viewId}`
  var request = new LuruAPIRequest(url, HTTPMethod.GET, options)

  return request.make()
}
