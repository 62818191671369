import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import { ListConversationsParameter } from '../middleware/listConversations'
import LuruUser from '@/domain/users/LuruUser'
import { MessagingProvider } from '@/features/user/types'
import { ChatConversationType } from '../types'

export const listConversations = async (param: ListConversationsParameter, options: LuruAPIRequestOptions) => {
  const { forceRefresh } = param
  var request = new LuruAPIRequest(
    forceRefresh ? `/api/chat/conversations?force_refresh=true` : '/api/chat/conversations',
    HTTPMethod.GET,
    options
  )
  const currentUserMessagingProvider = LuruUser.getCurrentUserMessagingProviderName()
  return request.make().then((res) => {
    if (currentUserMessagingProvider === MessagingProvider.SLACK) {
      res.data = (res.data || [])?.filter?.((f: any) => f.type === ChatConversationType.CHANNEL)
    }
    return Promise.resolve(res)
  })
}
