import { ReduxViewEntity } from '../types'

export const getDisplayInfo = (view?: ReduxViewEntity) =>
  view?.output.schema?.fields
    .map((f, ix) => ({
      field: f.field,
      updateable: f.updateable,
      sortable: f.sortable,
      label: f.label,
      visible:
        view?.display_settings?.field_settings?.[f.field]?.display ??
        view?.display_settings?.field_settings?.defaults?.display ??
        true,
      width: view?.display_settings?.field_settings?.[f.field]?.width,
      display_order:
        view?.display_settings?.field_settings?.[f.field]?.display_order ?? ix,
    }))
    .filter((f) => f.visible)
    .sort((a, b) => (a.display_order < b.display_order ? -1 : 1))
