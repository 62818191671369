import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { SignInUserPayload } from '../../user/reducers/signInUser'
import { ReduxNoteTemplatesState } from '../types'

export const userSliceSignInUser: CaseReducer<ReduxNoteTemplatesState, PayloadAction<SignInUserPayload>> = (
  state,
  action
) => {
  Object.keys(state).forEach((key) => delete state[key as keyof ReduxNoteTemplatesState])
  Object.assign(state, action.payload?.noteTemplates)
}
