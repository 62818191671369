import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ReduxAppState } from '../../app/types'
// import LuruUser from '../../../domain/users/LuruUser'
import { ReduxMeetingsState } from '../../meetings/types'
import { ReduxNotesState } from '../../notes/types'
import { ReduxNoteTemplatesState } from '../../noteTemplates/types'
import { ReduxTasksState } from '../../tasks/types'
import { ReduxUserState, UserSignInStatus } from '../types'

export interface SignOutUserPayload {
  app: ReduxAppState
  meetings: ReduxMeetingsState
  notes: ReduxNotesState
  noteTemplates: ReduxNoteTemplatesState
  tasks: ReduxTasksState
  user: ReduxUserState
}

export const signOutUser: CaseReducer<ReduxUserState, PayloadAction<SignOutUserPayload>> = (state, action) => {
  // console.log(`userSlice:signOutUser:entry`)
  // console.log(`userSlice:signOutUser:${LuruUser.getCurrentEntryPoint()}:entry`)
  // console.log(`userSlice:signOutUser:`, JSON.stringify(action, null, 2))

  try {
    Object.assign(state, {
      ...state,
      status: UserSignInStatus.SIGNED_OUT,
      data: {
        user_id: '',
        tenant_id: '',
        firstName: null,
        lastName: null,
        isSignedIn: false,
        userSettings: null,
      },
      recentItems: undefined,
      extensionSettings: {
        ...(action.payload?.user?.extensionSettings ?? {}),
      },
    })

    if (typeof document !== 'undefined') {
      document.title = 'Login to Luru'
    }
  } catch (e) {
    console.warn('userSlice:reducer:signOutUser:', e)
  }
}
