import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { HTTPMethod } from '../../../app/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import { CreateWorkflowParameter } from '../types'

export interface UpdateWorkflowParameter extends CreateWorkflowParameter {
  workflow_id: string
}

export const updateWorkflow = async (
  params: UpdateWorkflowParameter,
  options: LuruAPIRequestOptions
) => {
  var request = new LuruAPIRequest(
    `/api/workflows/${params.workflow_id}`,
    HTTPMethod.PUT,
    options
  )

  var requestBody: Partial<UpdateWorkflowParameter> = { ...params }

  // Make adjustments to request body from params
  requestBody.sor_object_name = CrmRecord.getCrmRecordName(
    params.sor_object_name
  )
  // delete requestBody.workflow_id

  request.setBody(requestBody)

  return request.make()
}
