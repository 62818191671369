import React, { useRef } from 'react'
import EditLinkDialog from '../../EditLinkDialog'
import TaskViewModal from '../../../routes/tasks/TaskViewModal'
import AddCollectionDialog from '../../../routes/settings/sections/Collections/AddCollectionDialog'
import AppComponentsContext from '../AppComponentsContext'
import CreateCrmRecordDialog from '../../domain/crm/CreateCrmRecordDialog'
import LuruUser from '../../../domain/users/LuruUser'
import { CRMProvider } from '../../../features/user/types'
import CrmRecord from '../../../domain/crmRecord/CrmRecord'
import ConnectCalendarModal from '@/forms/ConnectCalendarModal'
// import { CrmRecordType } from '../../../features/crm/types'

export function EmbeddedNoteAppComponentsProvider(props) {
  const crmPrimaryRecordType = CrmRecord.getAllPrimaryObjects()

  const appComponents = {
    editLinkDialog: useRef(),
    connectCalendarModal: useRef(),
    taskViewModal: useRef(),
    createCollectionDialog: useRef(),
    createRecordDialog: {
      [crmPrimaryRecordType.DEAL]: useRef(),
      [crmPrimaryRecordType.ACCOUNT]: useRef(),
      [crmPrimaryRecordType.CONTACT]: useRef(),
      [crmPrimaryRecordType.LEAD]: useRef(),
    },
  }

  return (
    <AppComponentsContext.Provider value={appComponents}>
      <EditLinkDialog ref={appComponents.editLinkDialog} tip='Edit URL' />
      {props.children}
      <ConnectCalendarModal ref={appComponents.connectCalendarModal} />
      <TaskViewModal ref={appComponents.taskViewModal} />
      <AddCollectionDialog ref={appComponents.createCollectionDialog} />
      <CreateCrmRecordDialog
        id='__global_createDealDialog'
        ref={appComponents.createRecordDialog[crmPrimaryRecordType.DEAL]}
        crmRecordType={crmPrimaryRecordType.DEAL}
      />
      <CreateCrmRecordDialog
        id='__global_createAccountDialog'
        ref={appComponents.createRecordDialog[crmPrimaryRecordType.ACCOUNT]}
        crmRecordType={crmPrimaryRecordType.ACCOUNT}
      />
      <CreateCrmRecordDialog
        id='__global_createContactDialog'
        ref={appComponents.createRecordDialog[crmPrimaryRecordType.CONTACT]}
        crmRecordType={crmPrimaryRecordType.CONTACT}
      />
      {LuruUser.getCurrentUserCrmName() !== CRMProvider.HUBSPOT ? (
        <CreateCrmRecordDialog
          id='__global_createLeadDialog'
          ref={appComponents.createRecordDialog[crmPrimaryRecordType.LEAD]}
          crmRecordType={crmPrimaryRecordType.LEAD}
        />
      ) : null}
    </AppComponentsContext.Provider>
  )
}
