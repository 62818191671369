import { ChatSliceActions } from '../../features/chat/chatSlice'
import { isChatConversationsCacheValid } from '../../features/chat/helpers/isChatConversationsCacheValid'
import { isChatUsersCacheValid } from '../../features/chat/helpers/isChatUsersCacheValid'
import { CollectionsSliceActions } from '../../features/collections/collectionsSlice'
import { isCollectionsCacheValid } from '../../features/collections/helpers/isCollectionsCacheValid'
import { CollectionsMiddleware } from '../../features/collections/middleware'
import { EmailsSliceActions } from '../../features/emails/emailsSlice'
import { isEmailsCacheValid } from '../../features/emails/helpers/isEmailsCacheValid'
import { EmailSyncsSliceActions } from '../../features/emailsyncs/emailsyncsSlice'
import { isEmailSyncsCacheValid } from '../../features/emailsyncs/helpers/isEmailSyncsCacheValid'
import { CrmSliceActions } from '../../features/crm/crmSlice'
import { isMeetingsCacheValid } from '../../features/meetings/helpers/isMeetingsCacheValid'
import { MeetingsSliceActions } from '../../features/meetings/meetingsSlice'
import { MeetingsMiddleware } from '../../features/meetings/middleware'
import { isNotesCacheValid } from '../../features/notes/helpers/isNotesCacheValid'
import { NotesSliceActions } from '../../features/notes/notesSlice'
import { isNoteTemplatesCacheValid } from '../../features/noteTemplates/helpers/isNoteTemplatesCacheValid'
import { NoteTemplatesMiddleware } from '../../features/noteTemplates/middleware'
import { NoteTemplatesSliceActions } from '../../features/noteTemplates/noteTemplatesSlice'
import { TasksSliceHelpers } from '../../features/tasks/helpers'
import { isTasksCacheValid } from '../../features/tasks/helpers/isTasksCacheValid'
import { TasksSliceActions } from '../../features/tasks/tasksSlice'
import { ViewHelpers } from '../../features/views/helpers'
import { isViewsCacheValid } from '../../features/views/helpers/isViewsCacheValid'
import { ViewsSliceActions } from '../../features/views/viewsSlice'
import { isWorkflowsCacheValid } from '../../features/workflows/helpers/isWorkflowsCacheValid'
import { WorkflowsSliceActions } from '../../features/workflows/workflowsSlice'
import { DateUtils } from '../../utils/dateUtils'
import { LuruReduxStore } from './LuruReduxStore'

export const ReduxCacheObserver = {
  init: () =>
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        return
      }

      var state = LuruReduxStore.getState()

      if (!isNotesCacheValid(state)) {
        LuruReduxStore.dispatch(NotesSliceActions.invalidateCache())
      }

      if (!isViewsCacheValid(state)) {
        LuruReduxStore.dispatch(ViewsSliceActions.invalidateCache())
        ViewHelpers.fetchAllViews()
      }

      if (!isWorkflowsCacheValid(state)) {
        LuruReduxStore.dispatch(WorkflowsSliceActions.invalidateCache())
      }

      if (!isChatUsersCacheValid(state)) {
        LuruReduxStore.dispatch(ChatSliceActions.invalidateUsersCache())
      }

      if (!isChatConversationsCacheValid(state)) {
        LuruReduxStore.dispatch(ChatSliceActions.invalidateConversationsCache())
      }

      if (!isMeetingsCacheValid(state)) {
        LuruReduxStore.dispatch(MeetingsSliceActions.invalidateCache())

        // Also, refresh meetings
        const pageSize = 20
        LuruReduxStore.dispatch(
          MeetingsMiddleware.listMeetings.action({
            key: 'home/UpcomingMeetings',
            time_min: DateUtils.toAPIDateString(DateUtils.getStartOfDay()),
            time_max: DateUtils.toAPIDateString(
              new Date(
                DateUtils.getStartOfDay().valueOf() +
                  3 * 7 * DateUtils.MILLISECS_PER_DAY
              )
            ),
            limit: pageSize,
            include_notes: true,
          })
        )
      }

      if (!isNoteTemplatesCacheValid(state)) {
        LuruReduxStore.dispatch(NoteTemplatesSliceActions.invalidateCache())

        // Also, refresh note templates
        LuruReduxStore.dispatch(
          NoteTemplatesMiddleware.listNoteTemplates.action({})
        )
      }

      if (!isTasksCacheValid(state)) {
        LuruReduxStore.dispatch(TasksSliceActions.invalidateCache())
        TasksSliceHelpers.fetchAllTasks()
      }

      if (!isCollectionsCacheValid(state)) {
        LuruReduxStore.dispatch(CollectionsSliceActions.invalidateCache())

        // Also, refresh collections
        LuruReduxStore.dispatch(
          CollectionsMiddleware.listCollections.action({})
        )

      }
      if (!isEmailsCacheValid(state)) {
        LuruReduxStore.dispatch(EmailsSliceActions.invalidateCache())
      }

      if (!isEmailSyncsCacheValid(state)) {
        LuruReduxStore.dispatch(EmailSyncsSliceActions.invalidateCache())
      }


      // Request crmSlice to clean up old entries
      LuruReduxStore.dispatch(CrmSliceActions.invalidateCache())
    }),
}
