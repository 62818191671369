import { createSlice } from '@reduxjs/toolkit'
import { EmptyReduxWorkflowsState } from './emptyState'
import { WorkflowsMiddleware } from './middleware'
import { WorkflowsSliceReducers as reducers } from './reducers'

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState: EmptyReduxWorkflowsState,
  reducers,
  extraReducers: (builder) => {
    WorkflowsMiddleware.createWorkflow.addAllCases(builder)
    WorkflowsMiddleware.createV2Workflow.addAllCases(builder)
    WorkflowsMiddleware.fetchWorkflow.addAllCases(builder)
    WorkflowsMiddleware.fetchV2Workflow.addAllCases(builder)
    WorkflowsMiddleware.updateWorkflow.addAllCases(builder)
    WorkflowsMiddleware.updateV2Workflow.addAllCases(builder)
    WorkflowsMiddleware.deleteWorkflow.addAllCases(builder)
    WorkflowsMiddleware.listWorkflows.addAllCases(builder)
    WorkflowsMiddleware.pauseUnpauseWorkflow.addAllCases(builder)
    WorkflowsMiddleware.pauseUnpauseV2Workflow.addAllCases(builder)
    WorkflowsMiddleware.fetchWorkflowSchema.addAllCases(builder)
  },
})

export const WorkflowsSliceReducer = workflowsSlice.reducer

export const WorkflowsSliceActions = workflowsSlice.actions
