// React
import React from 'react'

// Styles
import styles from './css/ListItemChooserDialog.module.css'
import { useOutsideClick } from '@/hooks/luru_hooks'
import { LuruEntryPoint } from '@/app/types'
import LuruUser from '@/domain/users/LuruUser'
import LuruCopyText from './ui/LuruCopyText'

export default class ListItemChooserDialog extends React.Component {
  #dialogConfig = null

  constructor(props) {
    super(props)
    this.props = props
    this.items = props.items
    this.showDialog = this.showDialog.bind(this)
    this.filterList = this.filterList.bind(this)
    this.filterBoxKeydown = this.filterBoxKeydown.bind(this)
    this.parentRef = React.createRef()
    this.dialogRef = React.createRef()
    this.filterBoxRef = React.createRef()
    this.cancel = this.cancel.bind(this)
    this.ok = this.ok.bind(this)
    this.chooseItemWithClick = this.chooseItemWithClick.bind(this)
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this)
    this.handleRedirectPlaybook = this.handleRedirectPlaybook.bind(this)
    this.state = {
      items: this.items,
      filterText: '',
      selectedIndex: 0,
      title: '',
    }
  }

  setItems(items) {
    if (items instanceof Array) {
      this.items = items
      this.setState({ items: items, filterText: '', selectedIndex: 0 })
    }
    return this
  }

  showDialog(dialogConfig = null) {
    if (dialogConfig?.title && dialogConfig?.title !== '') {
      this.setState({ title: dialogConfig.title })
    }
    this.parentRef.current?.classList.remove(styles.minimized)
    this.filterBoxRef.current?.focus()
    this.#dialogConfig = dialogConfig
  }

  filterList(e) {
    this.setState((state, props) => ({
      filterText: e.target.value,
      items: this.items.filter(
        (item) => item?.template?.title?.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      ),
    }))
  }

  filterBoxKeydown(e) {
    if (e.key === 'Escape') {
      this.cancel()
      e.stopPropagation()
      e.preventDefault()
    } else if (e.key === 'Enter') {
      this.ok()
    } else if (e.key === 'ArrowUp') {
      this.setState((state, props) => ({
        selectedIndex: state.selectedIndex === 0 ? state.items.length - 1 : state.selectedIndex - 1,
      }))
    } else if (e.key === 'ArrowDown') {
      this.setState((state, props) => ({
        selectedIndex: state.selectedIndex === state.items.length - 1 ? 0 : state.selectedIndex + 1,
      }))
    }
  }

  ok() {
    this.parentRef.current?.classList.add(styles.minimized)
    if (this.#dialogConfig && this.#dialogConfig.ok) {
      let chosenItem = this.state.items[this.state.selectedIndex]
      this.#dialogConfig.ok.apply(null, [chosenItem])
    }
  }

  onClickOutsideHandler() {
    if (this.parentRef?.current?.classList?.contains?.(styles.minimized)) {
      return
    }
    this.cancel()
  }

  chooseItemWithClick(clickedItem) {
    this.parentRef.current?.classList.add(styles.minimized)
    if (this.#dialogConfig && this.#dialogConfig.ok) {
      this.#dialogConfig.ok.apply(null, [clickedItem])
    }
  }

  handleRedirectPlaybook() {
    this.props?.handleRedirectPlaybook?.()
  }

  cancel() {
    this.parentRef.current?.classList.add(styles.minimized)
    if (this.#dialogConfig && this.#dialogConfig.cancel) {
      this.#dialogConfig.cancel.call()
    }
  }

  componentDidUpdate() {
    const selectedItem = this.parentRef.current?.querySelector('[data-luru-list-item-selected="true"]')
    selectedItem?.scrollIntoView(false)
  }

  render() {
    return (
      <div
        className={[styles.parent, styles.minimized].join(' ')}
        ref={this.parentRef}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <OutsideClickObserver parentRef={this.dialogRef} handler={this.onClickOutsideHandler} />
        <div className={styles.dialog} ref={this.dialogRef}>
          {this.state.title && this.state.title !== '' ? <div className={styles.title}>{this.state.title}</div> : ''}
          <input
            ref={this.filterBoxRef}
            type='search'
            value={this.state.filterText}
            onChange={this.filterList}
            onKeyDown={this.filterBoxKeydown}
            placeholder={this.props.title}
            data-luru-role='filter-box'
          />
          <div className={styles.content}>
            {this.state.items.length <= 0 ? (
              [LuruEntryPoint.EMBEDDED_ZOOM_MEETING].includes(LuruUser.getCurrentEntryPoint()) ? (
                <div className={styles.parentMessage}>
                  <div>
                    <span>No playbooks yet? Create one at</span>
                  </div>
                  <div>
                    <span>
                      <b>https://my.luru.app/settings/meeting_playbooks </b>
                    </span>
                    <span>
                      <LuruCopyText
                        positionForToolTip={['bottom', 'right']}
                        text='https://my.luru.app/settings/meeting_playbooks'
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  No playbooks yet?{' '}
                  <span className={styles.empty} onClick={this.props.handleRedirectPlaybook}>
                    {' '}
                    Click here
                  </span>{' '}
                  to create one
                </div>
              )
            ) : (
              <ul>
                {this.state.items.map((item, index) => {
                  return (
                    <li
                      key={item?.template?.template_id}
                      className={index === this.state.selectedIndex ? styles.selected : null}
                      data-luru-list-item-selected={index === this.state.selectedIndex ? true : false}
                      data-item-id={item?.template?.template_id}
                      onClick={(e) => this.chooseItemWithClick(item?.template)}
                    >
                      {item?.template?.title}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
          {/* <div className={styles.buttons}>
            <FlatButton onClick={this.cancel}>Cancel</FlatButton>
            <FlatButton onClick={this.ok} className={styles.okButton}>
              OK
            </FlatButton>
          </div> */}
          <footer>
            <div>Esc: Cancel</div>
            <div>
              {'\u2191'} or {'\u2193'} :Navigate
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

/**
 *
 * @param {{parentRef:React.RefObject<HTMLElement>|any,handler:Function}} param0
 */
function OutsideClickObserver({ parentRef, handler }) {
  useOutsideClick(parentRef, (e) => {
    handler?.()
  })
  return null
}
