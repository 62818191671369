import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import { EntityStatus } from '../../../../../app/types'
import { CollectionsMiddleware } from '../../../../../features/collections/middleware'
import { useEditorCaretOperations } from './useEditorCaretOperations'
import { useShowHideCollectionsChooser } from './useShowHideCollectionsChooser'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'

// Handler when an object is chosen for a template when we are in template
// editor
export const useHandleLinkObject = ({
  goBackToNotesEditor,
  showCollectionsChooser,
  hideCollectionsChooser,
  setLinkRecordPopupShown,
}: {
  goBackToNotesEditor: ReturnType<typeof useEditorCaretOperations>
  showCollectionsChooser: ReturnType<typeof useShowHideCollectionsChooser>['showCollectionsChooser']
  hideCollectionsChooser: ReturnType<typeof useShowHideCollectionsChooser>['hideCollectionsChooser']
  setLinkRecordPopupShown: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  // Redux dispatch
  const reduxDispatch = useAppDispatch()

  // Toast
  const { showToast } = useLuruToast()

  // Collections status
  const collectionsStatus = useAppSelector((s) => s.collections.status)

  const handleLinkObject = useCallback(
    (objectInfo: { noteTemplateId?: string; filter?: any } | null) => {
      if (!objectInfo) {
        goBackToNotesEditor({ trace: 'Handle link object, object=null' })
        return
      }

      const { noteTemplateId, filter } = objectInfo

      if (!noteTemplateId || !filter) {
        hideCollectionsChooser()
        goBackToNotesEditor({
          trace: 'Handle link object, field/noteTemplate=null',
        })
        return
      }

      if (collectionsStatus !== EntityStatus.Loaded) {
        showToast({
          id: ToastId.CRM_COLLECTION_CHOOSER_TOAST_ID,
          message: 'Fetching schema',
          isLoading: true,
        })
        setLinkRecordPopupShown(false)
        reduxDispatch(CollectionsMiddleware.listCollections.action({}))
      } else {
        showCollectionsChooser()
      }
    },
    [
      goBackToNotesEditor,
      collectionsStatus,
      reduxDispatch,
      showCollectionsChooser,
      hideCollectionsChooser,
      setLinkRecordPopupShown,
      showToast,
    ]
  )

  return handleLinkObject
}
