import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../app/store'
import { LuruAPIResponse } from '../../../app/types'
import LuruUser from '../../../domain/users/LuruUser'
import LuruError from '../../LuruError'
import { ReduxNoteEntity } from '../../notes/types'
import { MeetingsAPI } from '../api'
import { ReduxMeetingsState } from '../types'

export interface ListZoomMeetingsParameter {
  type?: 'scheduled' | 'live' | 'upcoming' | 'upcoming_meetings' | 'previous_meetings'
}

export interface ListZoomMeetingsAPIResponseEntity {
  uuid: string
  id: string
  host_id: string
  duration: number
  start_time: string
  timezone: string
  join_url: string
  topic: string
  type: number
  notes: Array<ReduxNoteEntity>
}

export interface ListZoomMeetingsAPIResponse extends LuruAPIResponse {
  data: Array<ListZoomMeetingsAPIResponseEntity>
}

export const listZoomMeetings = {
  action: createAsyncThunk<
    ListZoomMeetingsAPIResponse['data'],
    ListZoomMeetingsParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: ListZoomMeetingsAPIResponse['metadata'] | null
      rejectedMeta: ListZoomMeetingsAPIResponse['metadata'] | null
    }
  >('meetings/listZoomMeetings', async (params, { signal, fulfillWithValue, rejectWithValue, getState }) => {
    var isZoomConnected = LuruUser.isZoomConnected(getState())

    if (!isZoomConnected) {
      let errorResponse = {
        error_code: 10100,
        message: 'No zoom connection exists',
        description: 'Please connect to your zoom and retry',
      }
      return rejectWithValue(errorResponse, null)
    }

    try {
      var meetings = (await MeetingsAPI.listZoomMeetings(params, {
        signal,
      })) as ListZoomMeetingsAPIResponse

      return fulfillWithValue(meetings.data, meetings.metadata)
    } catch (e) {
      var luruError = e instanceof LuruError ? (e as LuruError) : null

      return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
    }
  }),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(listZoomMeetings.action.pending, (state, action) => {})
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(listZoomMeetings.action.fulfilled, (state, action) => {})
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    builder.addCase(listZoomMeetings.action.rejected, (state, action) => {})
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxMeetingsState>) {
    listZoomMeetings.addPendingCase(builder)
    listZoomMeetings.addFulfilledCase(builder)
    listZoomMeetings.addRejectedCase(builder)
  },
}
