import { useLayoutEffect } from 'react'
import { useAppDispatch } from '../../../app/hooks'
import { UserSliceActions } from '../../../features/user/userSlice'

export default function useNetworkErrorEffect() {
  const dispatch = useAppDispatch()
  useLayoutEffect(() => {
    // Add a listener for network offline event
    window.addEventListener('offline', () => {
      dispatch(UserSliceActions.setNetworkErrorState({}))
    })

    window.addEventListener('online', () => {
      dispatch(UserSliceActions.unsetNetworkErrorState({}))
    })
  })
}
