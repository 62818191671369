// Evaluate localStorage
export const isLocalStorageAvailable = () => {
  try {
    var l = localStorage
    return l !== undefined
  } catch (e) {
    // If there is ReferenceError
    return false
  }
}
