// React
import { useState, useRef, useLayoutEffect, useContext, useCallback } from 'react'

// Router
import { useNavigate, useSearchParams } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux'
import { NotesSliceSelectors } from '../../features/notes/selectors'
import { NotesSliceActions } from '../../features/notes/notesSlice'
import { CRMProvider, RecentEntityType } from '../../features/user/types'
import { LuruReduxStore } from '../../app/store'
import { UserSliceActions } from '../../features/user/userSlice'

// Own components
import CRMRecordLinkControl from '../../primitives/CRMRecordLinkControl'
import MeetingLinkControl from '../../primitives/MeetingLinkControl'
import PopupMenu from '../../primitives/PopupMenu'
import { EditorMessage, EditorMessageEvent } from '../../coreEditor/EditorController'

// Own functions/classes
import AppComponentsContext from '../../primitives/AppComponents/AppComponentsContext'

// 3rd party libraries
// import moment from 'moment'

// Styles
import styles from './css/NotesEditorInfoBlock.module.css'
import { trackEvent } from '../../analytics/Ga'
import LuruUser from '../../domain/users/LuruUser'
import { LuruEntryPoint } from '../../app/types'
import { NotesSliceHelpers } from '../../features/notes/helpers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { NotesMiddleware } from '../../features/notes/middleware'
import CRMLogAsLinkControl from '../../primitives/CRMLogAsLinkControl'
import CrmRecord from '../../domain/crmRecord/CrmRecord'
import { useLuruToast } from '@/hooks/useLuruToast'
import { ToastId } from '@/app_ui/types'
import Utils from '@/utils/Utils'

// Component
export default function NotesEditorInfoBlock(props) {
  const { showToast } = useLuruToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const newMeetingId = searchParams.get('new_meeting_id')
  const infoBlockRef = useRef()
  const appComponentsContext = useContext(AppComponentsContext)
  // const timeFormat = 'D MMM, HH:mm'
  const noteTitle = useSelector(NotesSliceSelectors.getNoteTitle(props.noteId))
  const embedded = props.embedded ?? false
  const globalRefs = props.globalRefs
  const [title, setTitle] = useState(noteTitle)
  // const noteCreatedTS = useSelector(NotesSliceSelectors.getNoteCreatedTime(props.noteId))
  // const noteUpdatedTS = useSelector(NotesSliceSelectors.getNoteUpdatedTime(props.noteId)) ?? noteCreatedTS
  // const updatedTS = moment(noteUpdatedTS).format(timeFormat)
  const syncState = useSelector(NotesSliceSelectors.getNoteSyncState(props.noteId))
  const crmId = useSelector((state) => state.user?.data?.userSettings?.connectedCRM?.name)
  const [autoLinkAttempted, setAutoLinkAttempted] = useState(false)
  const noteSaveStatus = useAppSelector((state) => state.notes.entities[props.noteId]?.saveStatus)

  function onTitleChange(e) {
    const editorId = `editor-${props.noteId.slice(0, 7)}`
    const editorElement = document.getElementById(editorId)
    const lastUpdatedTime = `${new Date().getTime()}`

    // Update INPUT element
    setTitle(e.target.value)

    // Update redux
    dispatch(
      NotesSliceActions.updateTitle({
        noteId: props.noteId,
        title: e.target.value,
      })
    )

    // Update editor element
    editorElement?.setAttribute('data-title-last-changed-time', lastUpdatedTime)

    // Send message to make note dirty and set autoSave
    editorElement?.dispatchEvent?.(
      new CustomEvent(EditorMessageEvent, {
        detail: {
          message: EditorMessage.EditedEntityDirtied,
        },
      })
    )
  }

  function onDeleteNote() {
    trackEvent('delete_note_attempt', syncState)
    globalRefs?.noteDeleteConfirmDialog?.current?.showForNote({
      crmId,
      noteId: props.noteId,
      syncState,
      dispatch,
      navigateCallback: () => {
        // Compute redirect path after delete note; default is 'home'
        trackEvent('delete_note', syncState)
        const redirectPath = window.location.href.indexOf('/meeting_notes/') !== -1 ? '/meeting_notes/' : '/notes'
        navigate(redirectPath)
      },
    })
  }

  // Show auto-link magic wand
  const showAutoLinkMessage = useCallback(
    (matchingCrmRecords, postSetupCallback = null) => {
      var crmRecordLinkPopupElement = document.getElementById(`crm-link-popup-${props.noteId.slice(0, 7)}`)
      crmRecordLinkPopupElement?.dispatchEvent?.(
        new CustomEvent('crmrecordautolinked', {
          detail: { matchingCrmRecords, postSetupCallback, embedded },
        })
      )
    },
    [props.noteId, embedded]
  )

  const onMeetingLinked = useCallback(
    async (meetingId, fromNewMeetingNote = false, postSetupCallback = null) => {
      var noteId = props.noteId
      var crmConnections = (await NotesSliceHelpers.getCrmConnections(noteId)) ?? []

      if (crmConnections.length === 0) {
        if (autoLinkAttempted) {
          return
        }
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: fromNewMeetingNote
            ? `Finding ${CrmRecord.getCrmName()} records to auto-match`
            : `Successfully linked note.  Finding ${CrmRecord.getCrmName()} records to auto-match`,
          isLoading: true,
        })

        try {
          var matchingCrmRecords = await NotesSliceHelpers.getMatchingCrmRecords(noteId)

          if (matchingCrmRecords?.data?.length > 0) {
            var chosenMatch = matchingCrmRecords.data[0]
            var { sor_record_name: recordName } = chosenMatch

            await dispatch(
              NotesMiddleware.createNoteConnection.action({
                key: `NotesEditorInfoBlock:${noteId.slice(0, 7)}`,
                noteId,
                sorObject: chosenMatch,
              })
            ).unwrap()

            showToast({
              id: ToastId.NOTES_EDITOR_TOAST_ID,
              message: `Linked ${recordName} to note`,
              severity: 'success',
            })
            showAutoLinkMessage(matchingCrmRecords, postSetupCallback)
            trackEvent('link_meeting', 'Auto')
          } else {
            showToast({
              id: ToastId.NOTES_EDITOR_TOAST_ID,
              message: 'Could not find records to auto-link to meeting note',
              severity: 'success',
            })
          }
        } catch (e) {
          console.warn(e)
        } finally {
          setAutoLinkAttempted(true)
        }
      } else {
        showToast({
          id: ToastId.NOTES_EDITOR_TOAST_ID,
          message: 'Successfully linked note to meeting',
          severity: 'success',
        })
      }
    },
    [props.noteId, showAutoLinkMessage, dispatch, autoLinkAttempted, showToast]
  )

  async function onShareNote() {
    await appComponentsContext.noteShareDialog.current?.showModal(props.noteId)
  }

  const moreOptionsMenuItems = (
    <ul>
      <li onClick={onShareNote}>Share note...</li>
      {LuruUser.getCurrentEntryPoint() !== LuruEntryPoint.GLOBALSEARCH ? (
        <li onClick={onDeleteNote}>Delete note...</li>
      ) : null}
    </ul>
  )

  const checkForNewMeetingNote = useCallback(() => {
    if (newMeetingId !== null) {
      setTimeout(() => {
        onMeetingLinked(newMeetingId, true)
      }, 500)
    }
  }, [newMeetingId, onMeetingLinked])

  useLayoutEffect(() => {
    if (noteTitle === null) {
      // This means component has been unmounted
      // We don't need to execute useEffect()/useLayoutEffect()
      return () => {}
    }

    // Mark note as a recently used item
    let recentItem = {
      id: props.noteId,
      name: noteTitle,
      type:
        window.location.href.indexOf('/meeting_notes/') === -1 ? RecentEntityType.NOTE : RecentEntityType.MEETING_NOTE,
    }

    LuruReduxStore.dispatch(UserSliceActions.addRecentItem(recentItem))
    // END: Mark note as a recently used item

    let infoBlock = infoBlockRef.current

    // Event listener to make info block editable
    let makeInfoBlockEditable = (e) => {
      infoBlock.classList.remove(styles.disabled)
      document.getElementById(props.titleId).removeAttribute('disabled')
      infoBlock.setAttribute('data-editable', 'true')
      let syncButton = document.getElementById(`noteSyncButton-${props.noteId.slice(0, 7)}`)
      syncButton?.dispatchEvent?.(new CustomEvent('makeeditable'))
    }
    infoBlock.addEventListener('makeeditable', makeInfoBlockEditable)

    // Event listener for informing meeting has been auto-linked (from embedded
    // note)
    let onEmbeddedNoteMeetingLinked = (e) => {
      let { meetingId, postSetupCallback } = e.detail
      onMeetingLinked(meetingId, true, postSetupCallback)
    }
    infoBlock.addEventListener('embeddednotemeetinglinked', onEmbeddedNoteMeetingLinked)
    infoBlock.setAttribute('data-message-listener-setup', 'true')

    // Set window/tab title
    document.title = `Luru: ` + noteTitle

    checkForNewMeetingNote()

    // Adding !noteSaveStatus becuase we don't wan't to update the title
    // when ever we save the note this may lead to unexpected behaviour
    // where user is changing the title but in the background the note saves with old
    // typed title and then it may update that old title while changing the title.
    if (title !== noteTitle && !noteSaveStatus) {
      setTitle(noteTitle)
    }

    return () => {
      infoBlock.removeEventListener('makeeditable', makeInfoBlockEditable)
      infoBlock.removeEventListener('embeddednotemeetinglinked', onEmbeddedNoteMeetingLinked)
      infoBlock.setAttribute('data-message-listener-setup', 'false')
    }
  }, [
    infoBlockRef,
    props.noteId,
    props.titleId,
    noteTitle,
    title,
    checkForNewMeetingNote,
    onMeetingLinked,
    noteSaveStatus,
  ])

  const handleBlur = useCallback(() => {
    const editorId = `editor-${props.noteId.slice(0, 7)}`
    const editorElement = document.getElementById(editorId)

    editorElement?.dispatchEvent?.(
      new CustomEvent(EditorMessageEvent, {
        detail: {
          message: EditorMessage.SaveNote,
          data: {
            callback: () => {},
          },
        },
      })
    )
  }, [props.noteId])

  function render() {
    return (
      <div
        id={`note-info-${props.noteId.slice(0, 7)}`}
        ref={infoBlockRef}
        data-note-title={noteTitle}
        className={[styles.disabled, styles.infoBlock].join(' ')}
      >
        <div className={styles.titleRow}>
          <input
            type='text'
            value={title ? title : ''}
            onChange={onTitleChange}
            id={props.titleId}
            className={styles.noteTitle}
            disabled
            key={`title-box-${props.noteId}`}
            placeholder='Untitled note'
            onBlur={handleBlur}
          />
          {embedded ? null : (
            <PopupMenu items={moreOptionsMenuItems} leftalign={false}>
              <button className={styles.noSelect}>...</button>
            </PopupMenu>
          )}
        </div>
        <div className={Utils.classes(styles.noteInfo, 'max-sm:text-sm max-sm:!rounded-b-none')}>
          <div
            className={styles.noteInfoRow}
            data-type='record'
            data-embedded={embedded}
            id={`crm-record-link-control-${props.noteId}`}
          >
            <div>Record</div>
            <div>
              <CRMRecordLinkControl
                key={`crm-record-link-control-${props.noteId}`}
                noteId={props.noteId}
                readonly={props.readonly}
                embedded={props.embedded}
                popupRefs={props.popupRefs}
              />
            </div>
          </div>
          {/* Log activity enabled only for HUBSPOT & SFDC for now */}
          {[CRMProvider.HUBSPOT, CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX].includes(
            LuruUser.getCurrentUserCrmName()
          ) ? (
            <div
              className={styles.noteInfoRow}
              data-embedded={embedded}
              id={`crm-activity-log-control-${props.noteId}`}
              data-type='logas'
            >
              <div>Log as</div>
              <div>
                <CRMLogAsLinkControl embedded={props.embedded} noteID={props.noteId} />
              </div>
            </div>
          ) : null}
          <div className={styles.noteInfoRow} data-embedded={embedded} data-type='meeting'>
            <div>Meeting</div>
            <div>
              <MeetingLinkControl
                key={`meeting-link-control-${props.noteId}`}
                noteID={props.noteId}
                readonly={props.readonly}
                globalRefs={globalRefs}
                onMeetingLinked={onMeetingLinked}
                embedded={props.embedded}
              />
            </div>
            {/* <div className={[styles.noteInfoRight, styles.timestamp].join(' ')}>Last updated: {updatedTS}</div> */}
          </div>
        </div>
      </div>
    )
  }

  return render()
}
