import json5 from 'json5'
import CrmRecord from '../../../../domain/crmRecord/CrmRecord'
import { LuruFieldType } from '../../../../domain/crmRecord/typings.d'
import LuruCrmFieldInputComponent, {
  LuruCrmFieldInputProps,
  LuruCrmFieldInputState,
  LuruCrmFieldInputStatus,
} from './LuruCrmFieldInputComponent'

interface LuruCrmFieldInputEvents {
  onBlur: (_: string | null) => void
  onFocus: () => void
  onEnter: (_: string) => void
  onCancel: () => void
  onBlurTextArea: (_: string | null) => void
  onFocusTextArea: () => void
  onEnterTextArea: (_: string) => void
  onCancelTextArea: () => void
  onRevert: () => void
  onEditCell: () => void
  onMultiSelectFinish: (values: Array<string>) => void
  onMultiReferenceSelectFinish: (
    values: Array<{
      sor_record_id: string
      sor_record_name: string
      sor_object_name: string
    }>
  ) => void
  onSelectLayerKeyDown: React.KeyboardEventHandler<HTMLDivElement>
  onChooseItem: (value: string | null) => void
  onReferenceValueChange: (sorRecordId: string, sorRecordName: string, sorObjectName?: string) => void
}

export default class LuruCrmFieldInputEventHandler {
  #component: LuruCrmFieldInputComponent
  handlers: LuruCrmFieldInputEvents

  constructor(component: LuruCrmFieldInputComponent) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount
    this.#component.componentDidUpdate = this.#onComponentUpdate

    this.handlers = {
      onBlur: this.#onBlur,
      onFocus: this.#onFocus,
      onEnter: this.#onEnter,
      onCancel: this.#onCancel,
      onBlurTextArea: this.#onBlurTextArea,
      onFocusTextArea: this.#onFocusTextArea,
      onEnterTextArea: this.#onEnterTextArea,
      onCancelTextArea: this.#onCancelTextArea,
      onRevert: this.#onRevert,
      onEditCell: this.#onEditCell,
      onMultiSelectFinish: this.#onMultiSelectFinish,
      onMultiReferenceSelectFinish: this.#onMultiReferenceSelectFinish,
      onSelectLayerKeyDown: this.#onSelectLayerKeyDown,
      onChooseItem: this.#onChooseItem,
      onReferenceValueChange: this.#onReferenceValueChange,
    }
  }

  #onComponentMount = () => {
    this.#loadSchema()
    this.loadFields()
  }

  #onComponentUpdate = (
    prevProps: Readonly<LuruCrmFieldInputProps>,
    prevState: Readonly<LuruCrmFieldInputState>,
    snapshot?: any
  ) => {
    if (prevProps.refreshedAt !== this.#component.props.refreshedAt) {
      this.loadFields()
    }
    if (prevProps.defaultValue !== this.#component.props.defaultValue) {
      this.#component.setState({ fieldValue: this.#component.props.defaultValue })
    }
  }

  #loadSchema = async () => {
    if (this.#component.state.isSchemaLoaded) {
      return
    }

    try {
      let schema = await CrmRecord.getObjectSchema(this.#component.props.crmRecordType)
      this.#component.setSchema(json5.parse(json5.stringify(schema)))
    } catch (e) {
      console.warn(e)
    }
  }

  loadFields = async () => {
    if (this.#component.props.sorRecordId) {
      try {
        let response = await CrmRecord.getRecordFields(
          this.#component.props.crmRecordType,
          this.#component.props.sorRecordId
        )

        let fields = response.payload

        this.#component.setFields(fields)
      } catch (e) {
        console.warn(e)
      }
    }
  }

  #onFocus = () => {
    var nonFormattedValue = this.#component.state.fieldValue
    var textBox = this.#component.componentRefs.textBoxRef.current

    if (textBox && nonFormattedValue !== undefined) {
      textBox.setValue(nonFormattedValue)
    }

    this.#component.setState({ componentStatus: LuruCrmFieldInputStatus.IDLE })
  }

  #onBlur = (value: string | null) => {
    var formattedValue = this.#component.formatFieldValue(value ?? '')
    var textBox = this.#component.componentRefs.textBoxRef.current

    // Value is present in text box, (contd...)
    if (textBox) {
      textBox.setValue(formattedValue)
    }

    // ...and as fieldValue & displayedValue in this component (contd...)
    // ...and as this.#component.props.value (which we cannot change from here)
    this.#component.setState({
      fieldValue: value,
      displayedValue: this.#component.formatFieldValue(value ?? ''),
    })

    // Value is also present (in records arry) in PipelineView (fin.)
    if (this.#component.props.onBlur) {
      this.#component.props.onBlur(value, this.#component)
    }

    this.#onChooseItem(value)
  }

  #onEnter = (value: string) => {
    this.#component.componentRefs.textBoxRef.current?.blur()
    this.#onChooseItem(value)
  }

  #onCancel = () => {
    var textBox = this.#component.componentRefs.textBoxRef.current
    if (textBox) {
      textBox.setValue(this.#component.state.fieldValue, () => {
        if (textBox) {
          textBox.blur()
        }
      })
    }
  }

  #onFocusTextArea = () => {
    var nonFormattedValue = this.#component.state.fieldValue
    var textAreaBox = this.#component.componentRefs.textAreaBoxRef.current

    if (textAreaBox && nonFormattedValue !== undefined) {
      textAreaBox.setValue(nonFormattedValue)
    }

    this.#component.setState({ componentStatus: LuruCrmFieldInputStatus.IDLE })
  }

  #onBlurTextArea = (value: string | null) => {
    var formattedValue = this.#component.formatFieldValue(value ?? '')
    var textAreaBox = this.#component.componentRefs.textAreaBoxRef.current

    // Value is present in text box, (contd...)
    if (textAreaBox) {
      textAreaBox.setValue(formattedValue)
    }

    // ...and as fieldValue & displayedValue in this component (contd...)
    // ...and as this.#component.props.value (which we cannot change from here)
    this.#component.setState({
      fieldValue: value,
      displayedValue: this.#component.formatFieldValue(value ?? ''),
    })

    // Value is also present (in records arry) in PipelineView (fin.)
    if (this.#component.props.onBlur) {
      this.#component.props.onBlur(value, this.#component)
    }

    this.#onChooseItem(value)
  }

  #onEnterTextArea = (value: string) => {
    this.#component.componentRefs.textAreaBoxRef.current?.blur()
    this.#onChooseItem(value)
  }

  #onCancelTextArea = () => {
    var textAreaBox = this.#component.componentRefs.textAreaBoxRef.current
    if (textAreaBox) {
      textAreaBox.setValue(this.#component.state.fieldValue, () => {
        if (textAreaBox) {
          textAreaBox.blur()
        }
      })
    }
  }

  #onRevert = () => {
    var textBox = this.#component.componentRefs.textBoxRef.current
    var textAreaBox = this.#component.componentRefs.textAreaBoxRef.current
    var originalValue = this.#component.state.fieldValue

    if (textBox) {
      textBox.setValue(originalValue)
    }

    if (textAreaBox) {
      textAreaBox.setValue(originalValue)
    }

    this.#component.setState({
      fieldValue: originalValue,
      componentStatus: LuruCrmFieldInputStatus.IDLE,
    })
  }

  #onEditCell = () => {
    var selectLayer = this.#component.componentRefs.selectLayerRef.current

    if (selectLayer) {
      let textBox = this.#component.componentRefs.textBoxRef.current

      if (textBox) {
        textBox.focus()
      }

      selectLayer.style.display = 'none'
    }
  }

  #onSelectLayerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        this.#component.props.onNavigateDown && this.#component.props.onNavigateDown()
        e.preventDefault()
        return

      case 'ArrowUp':
        this.#component.props.onNavigateUp && this.#component.props.onNavigateUp()
        e.preventDefault()
        return

      case 'ArrowLeft':
        this.#component.props.onNavigateLeft && this.#component.props.onNavigateLeft()
        e.preventDefault()
        return

      case 'ArrowRight':
        this.#component.props.onNavigateRight && this.#component.props.onNavigateRight()
        e.preventDefault()
        return

      case 'Tab':
        if (e.shiftKey) {
          this.#component.props.onNavigateLeft && this.#component.props.onNavigateLeft()
        } else {
          this.#component.props.onNavigateRight && this.#component.props.onNavigateRight()
        }
        e.preventDefault()
        return

      case 'Escape':
        this.#component.componentRefs.selectLayerRef.current?.blur()
        e.preventDefault()
        return
    }
  }

  #onChooseItem = (value: string | null) => {
    this.#component.setState({ fieldValue: value })
    if (this.#component.props.onChange) {
      let convertedValue = CrmRecord.convertToPrimitiveType(this.#component.getFieldType() ?? LuruFieldType.TEXT, value)
      this.#component.props.onChange(convertedValue, this.#component)
    }
  }

  #onReferenceValueChange = (sorRecordId: string, sorRecordName: string, sorObjectName?: string) => {
    if (this.#component.props.onChange && sorObjectName) {
      this.#component.props.onChange(
        {
          sor_object_name: sorObjectName,
          sor_record_id: sorRecordId,
          sor_record_name: sorRecordName,
        },
        this.#component
      )
    }
  }

  #onMultiSelectFinish = (values: Array<string>) => {
    this.#component.setState({ fieldValue: values.join(';') })
    if (this.#component.props.onChange) {
      this.#component.props.onChange(values, this.#component)
    }
  }

  #onMultiReferenceSelectFinish = (
    values: Array<{
      sor_record_id: string
      sor_record_name: string
      sor_object_name: string
    }>
  ) => {
    if (this.#component.props.onChange) {
      this.#component.props.onChange(values, this.#component)
    }
  }
}
