import React, { ClipboardEvent } from 'react'
import LuruDatePicker from '../LuruDatePicker'
import styles from './styles.module.css'

export interface LuruTextAreaProps {
  additionalClassNames?: Array<string>
  datePickerPopupMenuClassName?: string
  additionalTextBoxClassNames?: Array<string>
  alignment?: 'left' | 'center' | 'right'
  defaultValue?: string
  disabled?: boolean
  menuParentSelector?: string
  placeholder?: string
  readOnly?: boolean
  size?: 'small' | 'normal'
  onBlur?: (value: string, e?: React.FocusEvent<HTMLTextAreaElement, Element>) => void
  onCancel?: () => void
  onChange?: (value: string | null) => void
  onFocus?: () => void
  onNavigateUp?: () => void
  onNavigateDown?: () => void
  onReturn?: (value: string) => void
  autoFocus?: boolean
  width?: string
  rows?: number
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
}

interface LuruTextAreaState {
  value: string
}

interface LuruTextAreaRefs {
  textBox: React.RefObject<HTMLTextAreaElement>
  datePicker?: React.RefObject<LuruDatePicker>
}

interface LuruTextAreaEvents {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  onPaste: (e: ClipboardEvent<HTMLTextAreaElement>) => void
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}

export default class LuruTextArea extends React.Component {
  props: LuruTextAreaProps
  state: LuruTextAreaState
  value: string = ''
  #componentRefs: LuruTextAreaRefs
  #eventHandler: LuruTextAreaEventHandler

  constructor(props: LuruTextAreaProps) {
    super(props)
    this.props = props
    this.state = {
      value: this.props.defaultValue ?? '',
    }
    this.#componentRefs = {
      textBox: React.createRef(),
    }
    this.#eventHandler = new LuruTextAreaEventHandler(this)
  }

  getValue() {
    return this.state.value
  }

  setValue(value: string, callback?: () => void) {
    this.value = value
    this.setState({ value }, callback)

    this.#componentRefs.datePicker?.current?.setValue(value)
  }

  focus() {
    this.#componentRefs.textBox.current?.focus()
  }

  select() {
    this.#componentRefs.textBox.current?.select?.()
  }

  blur() {
    this.#componentRefs.textBox.current?.blur()
  }

  reset() {
    this.value = ''
    this.setState({ value: '' })
  }

  isReadOnly() {
    return this.props.readOnly === true
  }

  render() {
    var classNames = ['luruTextAreaBox', styles.textArea, this.props.readOnly ? styles.disabled : '']

    if (this.props.additionalClassNames) {
      classNames = classNames.concat(this.props.additionalClassNames)
    }

    var inputElemValue = this.state.value
    if (inputElemValue === null) {
      // Fix for React warning about controlled/uncontrolled input:
      // Warning: `value` prop on `input` should not be null.
      // Consider using an empty string to clear the component or `undefined` for uncontrolled components.
      inputElemValue = ''
    }

    return (
      <textarea
        className={classNames.join(' ')}
        data-luru-role='luru-text-area-box'
        data-luru-size={this.props.size}
        disabled={this.props.readOnly === true || this.props.disabled}
        placeholder={this.props.placeholder ?? ''}
        readOnly={this.props.readOnly === true}
        ref={this.#componentRefs.textBox}
        style={{ textAlign: this.props.alignment ?? 'left', width: this.props.width }}
        value={inputElemValue}
        onBlur={this.#eventHandler.handlers.onBlur}
        onChange={this.#eventHandler.handlers.onChange}
        onFocus={this.#eventHandler.handlers.onFocus}
        onKeyDown={this.#eventHandler.handlers.onKeyDown}
        onPaste={this.#eventHandler.handlers.onPaste}
        rows={this.props.rows}
      />
    )
  }
}

class LuruTextAreaEventHandler {
  #component: LuruTextArea
  #eventTimeStamps: {
    onKeyDown?: number
    onChange?: number
  } = {
    onKeyDown: undefined,
    onChange: undefined,
  }
  handlers: LuruTextAreaEvents

  constructor(component: LuruTextArea) {
    this.#component = component
    this.#component.componentDidUpdate = this.#componentDidUpdate.bind(this)
    this.#component.componentDidMount = this.#componentDidMount.bind(this)

    this.handlers = {
      onChange: this.#onChange,
      onKeyDown: this.#onKeyDown,
      onBlur: this.#onBlur,
      onPaste: this.#onPaste,
      onFocus: this.#onFocus,
    }
  }

  #componentDidMount() {
    if (this.#component.props.autoFocus) {
      this.#component?.focus?.()
    }
  }

  #componentDidUpdate(
    prevProps: Readonly<LuruTextAreaProps>,
    prevState: Readonly<LuruTextAreaState>,
    snapshot?: any
  ): void {
    if (prevProps.defaultValue !== this.#component.props.defaultValue) {
      this.#component.setValue(this.#component.props.defaultValue ?? '')
    }
  }

  #onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    var externalHandler = this.#component.props.onChange
    var inputElement = e.target as HTMLTextAreaElement
    var value = inputElement.value

    e.preventDefault()
    this.#component.setValue(value, () => {
      externalHandler && externalHandler(value)
    })
  }

  #onPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    // Stop sending key down event to its parent
    e.stopPropagation()
  }

  #onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation() // Stop sending key down event to its parent
    var key = e.key
    var keyMappings: { [key: string]: ((_: string) => void) | undefined } = {
      ArrowUp: this.#component.props.onNavigateUp,
      ArrowDown: this.#component.props.onNavigateDown,
      Escape: this.#component.props.onCancel,
    }

    if (Object.keys(keyMappings).includes(key)) {
      let handler = keyMappings[key]

      if (handler !== undefined && typeof handler === 'function') {
        handler(this.#component.state.value)
      }
    }
    this.#component.props?.onKeyDown?.(e)
  }

  #onBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    if (this.#component.props.onBlur) {
      this.#component.props.onBlur(this.#component.getValue(), e)
    }
  }

  #onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    var delayAfterChange = 0

    if (this.#eventTimeStamps.onChange !== undefined) {
      delayAfterChange = new Date().valueOf() - this.#eventTimeStamps.onChange
    }

    if ((this.#eventTimeStamps.onChange === undefined || delayAfterChange > 50) && this.#component.props.onFocus) {
      this.#component.props.onFocus()
    }

    // User of timestamp resets it after use
    this.#eventTimeStamps.onChange = undefined
  }
}
