import type { RootState } from '../../../app/store'
import { EntityStatus } from '../../../app/types'
import { ReduxNoteEntity } from '../../../features/notes/types'

export default function getReduxState(state: RootState) {
  type ResultsType = Array<Partial<ReduxNoteEntity>> | undefined
  var mapNoteEntities = (noteIdList?: Array<string>) =>
    noteIdList
      ?.filter(
        (noteId: string) =>
          state.notes.entities[noteId]?.status === EntityStatus.Loaded ||
          state.notes.entities[noteId]?.status ===
            EntityStatus.LoadedPartially ||
          (state.notes.entities[noteId]?.status === EntityStatus.Loading &&
            state.notes.entities[noteId]?.data?.title &&
            (state.notes.entities[noteId]?.data?.updated_at ||
              state.notes.entities[noteId]?.data?.created_at))
      )
      ?.map((noteId: string) => state.notes.entities?.[noteId]?.data)
      .filter(Boolean)
      .sort(
        (a, b) =>
          new Date(b?.updated_at ?? b?.created_at ?? '').valueOf() -
          new Date(a?.updated_at ?? a?.created_at ?? '').valueOf()
      )

  var luruNoteIdSet = new Set(
    (state.app?.home?.default?.luruNotes?.results ?? []).concat(state.app?.home?.default?.luruNotes?.draftNotes ?? [])
  )
  var luruNoteIdArray = [...luruNoteIdSet]

  var response = {
    searchQuery: state.app?.home?.search?.query ?? undefined,
    notes: {
      luru: {
        default: {
          status: state.app?.home?.default?.luruNotes?.status,
          error: state.app?.home?.default?.luruNotes?.error_data,
          cursor: state.app?.home?.default?.luruNotes?.cursor,
          results: mapNoteEntities(luruNoteIdArray) as ResultsType,
          originalResults: state.app?.home?.default?.luruNotes?.results,
        },
        search: {
          status: state.app?.home?.search?.luruNotes?.status,
          error: state.app?.home?.search?.luruNotes?.error_data,
          cursor: state.app?.home?.search?.luruNotes?.cursor,
          results: mapNoteEntities(state.app?.home?.search?.luruNotes?.results) as ResultsType,
        },
      },
      sor: {
        default: {
          status: state.app?.home?.default?.sorNotes?.status,
          error: state.app?.home?.default?.sorNotes?.error_data,
          cursor: state.app?.home?.default?.sorNotes?.cursor,
          results: mapNoteEntities(state.app?.home?.default?.sorNotes?.results) as ResultsType,
        },
        search: {
          status: state.app?.home?.search?.sorNotes?.status,
          error: state.app?.home?.search?.sorNotes?.error_data,
          cursor: state.app?.home?.search?.sorNotes?.cursor,
          results: mapNoteEntities(state.app?.home?.search?.sorNotes?.results) as ResultsType,
        },
      },
    },
  }

  return response
}
