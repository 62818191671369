import { HTTPMethod } from '../../../app/types'
import { getAuthUrl } from '../../../domutils/utils'
import LuruAPIRequest, { LuruAPIRequestOptions } from '../../LuruAPIRequest'
import { DisconnectMSTeamsRequestParams } from '../middleware/disconnectMSTeams'

export const disconnectMSTeams = async (params: DisconnectMSTeamsRequestParams, options: LuruAPIRequestOptions) => {
  var oAuthKey = 'azuread-v2-tenant-oauth2-msteams'
  var disconnectUrl = getAuthUrl() + '/disconnect/' + oAuthKey + '/'
  var request = new LuruAPIRequest(disconnectUrl, HTTPMethod.POST, {
    ...options,
    headers: {
      'X-CSRFToken': params.csrfToken ?? '',
    },
    credentials: 'include',
  })

  return request.make()
}
