import { LuruReduxStore } from '../../app/store'
import { crmMiddleware, getFormattedCrmFieldValue } from '../../features/crm/crmMiddleware'
import CRMBaseMiddlewareFactory from '../../features/crm/api/crmBaseMiddleware'
import LuruError, { LuruErrorName } from '../../features/LuruError'
import LuruUser from '../users/LuruUser'
import {
  CrmRecordUpdatePayload,
  recordNameInCrm,
  LuruFieldType,
  CrmRecordMultiFieldUpdatePayload,
  LuruHierarchialPicklistValue,
  CrmFieldSchema,
  CompoundFieldValue,
  CrmRecordType,
} from './typings.d'
import { crmIcons } from '../../primitives/CRMIcons'
import { CRMProvider } from '../../features/user/types'

import sfdcIcon from '../../images/sfdclogo.png'
import hubspotIcon from '../../images/hubspotlogo.png'
import pipedriveIcon from '../../images/pipedrivelogo.png'

interface MiddlewareCrmRecordUpdatePayload {
  sorObjectName: string
  sorRecordId: string
  fieldName: string
  fieldValue: string
  fieldType: string
  luruFieldType: string
  completeRecordFields: {
    record: {
      [fieldName: string]: {
        schema: {
          picklistValues?: Array<{ value: string; valueForUpdate?: string }>
        }
      }
    }
  }
}

export default class CrmRecord {
  /**
   * Check if a field value is valid
   * @param {string} fieldType - A luru field type
   * @param {string} fieldValue - Field value as a string
   * @return {boolean} true if field value is valid for given field type
   */
  static isFieldValueValid(fieldType: string, fieldValue: string) {
    switch (fieldType) {
      case LuruFieldType.INTEGER:
      case LuruFieldType.CURRENCY:
      case LuruFieldType.INTEGER_NOFORMAT:
        return fieldValue.match(/^[0-9]*$/)

      case LuruFieldType.DOUBLE:
        return fieldValue.match(/^[0-9]*(\.[0-9]+)?$/)

      case LuruFieldType.PERCENTAGE:
        return (
          (fieldValue.match(/^[0-9]+$/) && parseFloat(fieldValue) >= 0 && parseFloat(fieldValue) <= 100) ||
          fieldValue === ''
        )

      default:
        return true
    }
  }

  /**
   * Check if a field value is valid
   * @param {string} fieldType - A luru field type
   * @param {string} fieldValue - Field value as a string
   */
  static convertToPrimitiveType(fieldType: string, fieldValue: string | null) {
    if (typeof fieldValue !== 'string') {
      return fieldValue
    }

    var response: any = fieldValue

    switch (fieldType) {
      case LuruFieldType.BOOLEAN:
        response = fieldValue.toLowerCase() === 'true'
        break

      case LuruFieldType.INTEGER:
      case LuruFieldType.INTEGER_NOFORMAT:
        response = parseInt(fieldValue)
        if (isNaN(response)) {
          response = null
        }
        break

      case LuruFieldType.CURRENCY:
      case LuruFieldType.DOUBLE:
      case LuruFieldType.PERCENTAGE:
        response = parseFloat(fieldValue)
        if (isNaN(response)) {
          response = null
        }
        break

      case LuruFieldType.MULTIREFERENCE:
      case LuruFieldType.HIERARCHICAL_ENUM:
        try {
          return JSON.parse(fieldValue)
        } catch (e) {
          return fieldValue
        }

      case LuruFieldType.DATE:
        if (
          ([CRMProvider.SFDC, CRMProvider.SFDC_SANDBOX, CRMProvider.HUBSPOT] as any[]).includes(
            LuruUser.getCurrentUserCrmName()
          )
        ) {
          if (fieldValue.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/)) {
            let d = new Date(fieldValue)
            return (
              d.getFullYear() +
              '-' +
              (d.getMonth() < 9 ? '0' : '') +
              (d.getMonth() + 1) +
              '-' +
              (d.getDate() < 10 ? '0' : '') +
              d.getDate()
            )
          }
        }
        return fieldValue

      default:
        if (fieldValue === undefined || fieldValue === null) {
          response = ''
        }
    }

    return response
  }

  static getMiddleware(): CRMBaseMiddlewareFactory | undefined {
    var crmName = LuruUser.getCurrentUserCrmName()

    if (!crmName) {
      return undefined
    }

    var middlewareId = crmName.toLowerCase() as 'sfdc' | 'hubspot' | 'pipedrive'
    return crmMiddleware[middlewareId] as CRMBaseMiddlewareFactory
  }

  static async createRecord(payload: { crmRecordType: CrmRecordType; fields: { [fieldName: string]: any } }) {
    var middleware = CrmRecord.getMiddleware()

    try {
      var crmObjectName = CrmRecord.getCrmRecordName(payload.crmRecordType)
      var schema = await CrmRecord.getObjectSchema(payload.crmRecordType)

      // Handle any possible error with schema structure
      if (!Array.isArray(schema?.payload?.fields)) {
        throw new LuruError(
          LuruErrorName.ApplicationError,
          `Did not get correct schema for ${crmObjectName}`,
          new Error(`Did not get correct schema for ${crmObjectName}`),
          { crmObjectName, schema }
        )
      }

      var createPayloadFields: {
        [fieldName: string]: {
          fieldValue: any
          luruFieldType: LuruFieldType
          completeRecordFields: {
            record: {
              [_: string]: {
                schema: {
                  picklistValues?: Array<{
                    value: string
                    valueForUpdate?: string
                  }>
                }
              }
            }
          }
        }
      } = {}

      for (let fieldName in payload.fields) {
        if (payload.fields[fieldName] !== null) {
          let fieldSchema = schema?.payload.fields.find((f: { name: string }) => f.name === fieldName)
          if (!fieldSchema) {
            continue
          }
          createPayloadFields[fieldName] = {
            fieldValue: payload.fields[fieldName],
            luruFieldType:
              schema?.payload.fields.find((f: { name: string }) => f.name === fieldName)?.luruFieldType ??
              LuruFieldType.TEXT,
            completeRecordFields: {
              record: {
                [fieldSchema.name]: {
                  schema: {
                    picklistValues: fieldSchema.picklistValues ? [...fieldSchema.picklistValues] : undefined,
                  },
                },
              },
            },
          }
        }
      }

      var response = await LuruReduxStore.dispatch(
        // @ts-ignore
        middleware.createRecord.action({ fields: createPayloadFields, sorObjectName: crmObjectName })
      )

      if (response?.error) {
        let errorMsg = response.payload?.message ?? null
        let errorDesc = response.payload?.description ?? null

        throw new LuruError(
          LuruErrorName.LuruAPIError,
          !errorMsg && !errorDesc
            ? `Error creating ${CrmRecord.getCrmName()} record.  Please check field values and retry.`
            : errorMsg + '; ' + errorDesc,
          response.payload?.error_code,
          response.payload
        )
      }

      return response
    } catch (e) {
      if (e instanceof LuruError) {
        throw e
      } else {
        let err = e as Error
        throw new LuruError(LuruErrorName.LuruAPIError, err.message, err, payload)
      }
    }
  }

  static getPrimarySfdcObjects() {
    return {
      Opportunity: 'Opportunity',
      Lead: 'Lead',
      Contact: 'Contact',
      Account: 'Account',
      Task: 'Task',
    }
  }

  static async multiUpdate(payload: CrmRecordMultiFieldUpdatePayload) {
    var middleware = CrmRecord.getMiddleware()

    try {
      var crmObjectName = CrmRecord.getCrmRecordName(payload.crmRecordType as CrmRecordType)
      var schema = await CrmRecord.getObjectSchema(payload.crmRecordType)

      // Handle any possible error with schema structure
      if (!Array.isArray(schema?.payload?.fields)) {
        throw new LuruError(
          LuruErrorName.ApplicationError,
          `Did not get correct schema for ${crmObjectName}`,
          new Error(`Did not get correct schema for ${crmObjectName}`),
          { crmObjectName, schema }
        )
      }

      var updatePayloadFields: {
        [fieldName: string]: {
          fieldValue: any
          luruFieldType: LuruFieldType
          completeRecordFields: {
            record: {
              [_: string]: {
                schema: {
                  picklistValues: Array<{
                    value: string
                    valueForUpdate?: string
                  }>
                }
              }
            }
          }
        }
      } = {}

      for (let fieldName in payload.fields) {
        let fieldSchema = schema?.payload.fields.find((f: { name: string }) => f.name === fieldName)

        updatePayloadFields[fieldName] = {
          fieldValue: payload.fields[fieldName],
          luruFieldType:
            schema?.payload.fields.find((f: { name: string }) => f.name === fieldName)?.luruFieldType ??
            LuruFieldType.TEXT,
          completeRecordFields: {
            record: {
              [fieldSchema.name]: {
                schema: {
                  picklistValues: Array.isArray(fieldSchema.picklistValues) ? [...fieldSchema.picklistValues] : [],
                },
              },
            },
          },
        }
      }

      var response = await LuruReduxStore.dispatch(
        // @ts-ignore
        middleware.multiUpdate.action({
          fields: updatePayloadFields,
          sorObjectName: crmObjectName,
          sorRecordId: payload.sorRecordId,
        })
      )

      if (response?.error) {
        throw new Error(
          (response.payload?.message ? response.payload?.message + '. ' : '') +
            (response.payload?.description ??
              `Error updating ${CrmRecord.getCrmName()} record.  Please check field values and retry.`)
        )
      }

      return response
    } catch (e) {
      var err = e as Error
      throw new LuruError(LuruErrorName.LuruAPIError, err.message, err, payload)
    }
  }

  static async updateRecord(payload: CrmRecordUpdatePayload) {
    var middleware = CrmRecord.getMiddleware()

    try {
      var crmObjectName = CrmRecord.getCrmRecordName(payload.crmRecordType as CrmRecordType)
      var schema = await CrmRecord.getObjectSchema(payload.crmRecordType)

      // Handle any possible error with schema structure
      if (!Array.isArray(schema?.payload?.fields)) {
        throw new LuruError(
          LuruErrorName.ApplicationError,
          `Did not get correct schema for ${crmObjectName}`,
          new Error(`Did not get correct schema for ${crmObjectName}`),
          { crmObjectName, schema }
        )
      }

      var fieldSchema:
        | {
            name: string
            type: string
            luruFieldType: string
            picklistValues?: Array<{ value: string; valueForUpdate?: string }>
          }
        | undefined = schema?.payload.fields.find((f: { name: string }) => f.name === payload.fieldName)

      if (fieldSchema === undefined) {
        throw new LuruError(
          LuruErrorName.ApplicationError,
          `Did not get field schema for ${crmObjectName}/${payload.fieldName}`,
          new Error(`Did not get correct schema for ${crmObjectName}`),
          { crmObjectName, schema }
        )
      }

      var middlewarePayload: MiddlewareCrmRecordUpdatePayload = {
        sorObjectName: crmObjectName,
        sorRecordId: payload.sorRecordId,
        fieldName: payload.fieldName,
        fieldValue: payload.fieldValue,
        fieldType: fieldSchema.type,
        luruFieldType: fieldSchema.luruFieldType,
        completeRecordFields: {
          record: {
            [fieldSchema.name]: {
              schema: {
                picklistValues: fieldSchema.picklistValues ? [...fieldSchema.picklistValues] : undefined,
              },
            },
          },
        },
      }

      var response = await LuruReduxStore.dispatch(
        // @ts-ignore
        middleware.updateRecordField.action(middlewarePayload)
      )

      if (response?.error) {
        throw new Error(
          response.payload?.description ??
            `Error updating ${CrmRecord.getCrmName()} record.  Please check field values and retry.`
        )
      }

      return response
    } catch (e) {
      var err = e as Error
      throw new LuruError(LuruErrorName.LuruAPIError, err.message, err, payload)
    }
  }

  static getRecordFieldsPromise: {
    [recordType: CrmRecordType]: {
      [sorRecordId: string]: Promise<any>
    }
  } = {
    deal: {},
    account: {},
    lead: {},
    contact: {},
    meeting: {},
  }

  static async getRecordFields(crmRecordType: CrmRecordType, sorRecordId: string) {
    var middleware = CrmRecord.getMiddleware()

    if (!middleware?.getRecordFields) {
      return
    }

    try {
      var sorObjectName = CrmRecord.getCrmRecordNameForSchema(crmRecordType) ?? crmRecordType

      if (!CrmRecord.getRecordFieldsPromise[crmRecordType]) {
        CrmRecord.getRecordFieldsPromise[crmRecordType] = {}
      }

      if (!CrmRecord.getRecordFieldsPromise[crmRecordType]?.[sorRecordId]) {
        var promise = LuruReduxStore.dispatch(
          // @ts-ignore
          middleware.getRecordFields.action({ sorRecordId, sorObjectName })
        )
        CrmRecord.getRecordFieldsPromise[crmRecordType] = Object.assign(
          { [sorRecordId]: promise },
          CrmRecord.getRecordFieldsPromise[crmRecordType]
        )
      }

      var response = await CrmRecord.getRecordFieldsPromise[crmRecordType]?.[sorRecordId]

      delete CrmRecord.getRecordFieldsPromise[crmRecordType]?.[sorRecordId]

      return response
    } catch (e) {
      console.warn(e)
      throw new LuruError(
        LuruErrorName.LuruAPIError,
        `Cannot get record fields for ${CrmRecord.getCrmName()} ${crmRecordType} #${sorRecordId}`,
        e as Error,
        { sorObjectName }
      )
    }
  }

  static getObjectSchemaPromise: Record<CrmRecordType, Promise<any> | undefined> = {
    deal: undefined,
    account: undefined,
    lead: undefined,
    contact: undefined,
    meeting: undefined,
  }

  static async getObjectSchema(crmRecordType: CrmRecordType | string, forceRefresh?: boolean) {
    var middleware = CrmRecord.getMiddleware()

    if (!middleware?.getObjectSchema) {
      return
    }

    if (!crmRecordType || crmRecordType === 'global' || crmRecordType === 'Global') {
      return
    }

    try {
      var sorObjectName = CrmRecord.getCrmRecordNameForSchema(crmRecordType as CrmRecordType) ?? crmRecordType

      if (!CrmRecord.getObjectSchemaPromise[crmRecordType as CrmRecordType]) {
        CrmRecord.getObjectSchemaPromise[crmRecordType as CrmRecordType] = LuruReduxStore.dispatch(
          // @ts-ignore
          middleware.getObjectSchema.action({ sorObjectName, forceRefresh })
        )
      }

      var response = await CrmRecord.getObjectSchemaPromise[crmRecordType as CrmRecordType]
      delete CrmRecord.getObjectSchemaPromise[crmRecordType as CrmRecordType]

      return { payload: response?.payload?.schema }
    } catch (e) {
      console.warn(e)
      throw new LuruError(LuruErrorName.LuruAPIError, 'Cannot get schema for CRM object', e as Error, { sorObjectName })
    }
  }

  static getNameFieldFromObjectSchema(crmRecordType: CrmRecordType) {
    var crmName = LuruUser.getCurrentUserCrmName()
    var crmObjects = CrmRecord.getAllPrimaryObjects()

    if (!crmName) {
      return undefined
    }

    var currentCrmKey = crmName.toUpperCase()
    var nameField: string = ''

    if (currentCrmKey === CRMProvider.SFDC || currentCrmKey === CRMProvider.SFDC_SANDBOX) {
      switch (crmRecordType) {
        case crmObjects.DEAL:
          nameField = 'Name'
          break
        case crmObjects.CONTACT:
          nameField = 'Name'
          break
        case crmObjects.ACCOUNT:
          nameField = 'Name'
          break
        case crmObjects.LEAD:
          nameField = 'Name'
          break
        case crmObjects.MEETING:
          nameField = 'Title'
          break
      }
    }

    if (currentCrmKey === CRMProvider.HUBSPOT) {
      switch (crmRecordType) {
        case crmObjects.DEAL:
          nameField = 'dealname'
          break
        case crmObjects.CONTACT:
          nameField = 'firstname'
          break
        case crmObjects.ACCOUNT:
          nameField = 'name'
          break
        case crmObjects.MEETING:
          nameField = 'Title'
          break
      }
    }

    if (currentCrmKey === CRMProvider.PIPEDRIVE) {
      switch (crmRecordType) {
        case crmObjects.DEAL:
          nameField = 'dealname'
          break
        case crmObjects.CONTACT:
          nameField = 'contactname'
          break
        case crmObjects.ACCOUNT:
          nameField = 'firstname'
          break
        case crmObjects.MEETING:
          nameField = 'Title'
          break
      }
    }

    return nameField
  }

  /**
   * Get the name to be used for referring to a record according to current CRM
   * This API is to be used only for getting schema
   * @param crmRecordType A standard CRM record type (enum)
   * @returns Name of record in the respective CRM according to backen
   */
  static getCrmRecordNameForSchema(crmRecordType: CrmRecordType) {
    var crmName = LuruUser.getCurrentUserCrmName()

    if (!crmName) {
      return undefined
    }

    var crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()

    var names: { [_: string]: any } = {
      SFDC: {
        [crmPrimaryRecordTypes.DEAL]: 'Opportunity',
        [crmPrimaryRecordTypes.LEAD]: 'Lead',
        [crmPrimaryRecordTypes.CONTACT]: 'Contact',
        [crmPrimaryRecordTypes.ACCOUNT]: 'Account',
        [crmPrimaryRecordTypes.MEETING]: 'meeting',
        [crmPrimaryRecordTypes.TASK]: 'Task',
      },
      HUBSPOT: {
        [crmPrimaryRecordTypes.DEAL]: 'deals',
        [crmPrimaryRecordTypes.CONTACT]: 'contacts',
        [crmPrimaryRecordTypes.ACCOUNT]: 'companies',
        [crmPrimaryRecordTypes.MEETING]: 'meetings',
      },
      PIPEDRIVE: {
        [crmPrimaryRecordTypes.DEAL]: 'deal',
        [crmPrimaryRecordTypes.LEAD]: 'lead',
        [crmPrimaryRecordTypes.CONTACT]: 'person',
        [crmPrimaryRecordTypes.ACCOUNT]: 'organization',
        [crmPrimaryRecordTypes.MEETING]: 'meeting',
      },
    }

    return names[crmName][crmRecordType] ?? undefined
  }

  /**
   * Get the name to be used for referring to a record according to current CRM.
   * TODO: This API is currently used only by View related APIs.  Move other
   * APIs as well to use this
   * @param crmRecordType A standard CRM record type (enum)
   * @returns Name of record in the respective CRM according to backen
   */
  static getCrmRecordName(crmRecordType: CrmRecordType | string | undefined, defaultName?: string) {
    if (!crmRecordType) {
      return undefined
    }

    var crmName = LuruUser.getCurrentUserCrmName()

    if (!crmName) {
      return undefined
    }

    var crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()

    var names: { [_: string]: any } = {
      SFDC: {
        [crmPrimaryRecordTypes.DEAL]: 'Opportunity',
        [crmPrimaryRecordTypes.LEAD]: 'Lead',
        [crmPrimaryRecordTypes.CONTACT]: 'Contact',
        [crmPrimaryRecordTypes.ACCOUNT]: 'Account',
        [crmPrimaryRecordTypes.MEETING]: 'meeting',
        [crmPrimaryRecordTypes.TASK]: 'Task',
      },
      HUBSPOT: {
        [crmPrimaryRecordTypes.DEAL]: 'deals',
        [crmPrimaryRecordTypes.CONTACT]: 'contacts',
        [crmPrimaryRecordTypes.ACCOUNT]: 'companies',
        [crmPrimaryRecordTypes.MEETING]: 'meetings',
      },
      PIPEDRIVE: {
        [crmPrimaryRecordTypes.DEAL]: 'deal',
        [crmPrimaryRecordTypes.LEAD]: 'lead',
        [crmPrimaryRecordTypes.CONTACT]: 'person',
        [crmPrimaryRecordTypes.ACCOUNT]: 'organization',
        [crmPrimaryRecordTypes.MEETING]: 'meeting',
      },
    }

    return names[crmName]?.[crmRecordType] ?? defaultName ?? crmRecordType ?? undefined
  }

  static getCrmRecordNamePlural(crmRecordType: CrmRecordType) {
    var crmName = LuruUser.getCurrentUserCrmName()
    var crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()

    if (!crmName) {
      return undefined
    }

    var pluralNames: { [_: string]: any } = {
      SFDC: {
        [crmPrimaryRecordTypes.DEAL]: 'opportunities',
        [crmPrimaryRecordTypes.LEAD]: 'leads',
        [crmPrimaryRecordTypes.CONTACT]: 'contacts',
        [crmPrimaryRecordTypes.ACCOUNT]: 'accounts',
        [crmPrimaryRecordTypes.MEETING]: 'meetings',
        [crmPrimaryRecordTypes.TASK]: 'Task',
      },
      HUBSPOT: {
        [crmPrimaryRecordTypes.DEAL]: 'deals',
        [crmPrimaryRecordTypes.CONTACT]: 'contacts',
        [crmPrimaryRecordTypes.ACCOUNT]: 'companies',
        [crmPrimaryRecordTypes.MEETING]: 'meetings',
      },
      PIPEDRIVE: {
        [crmPrimaryRecordTypes.DEAL]: 'deals',
        [crmPrimaryRecordTypes.LEAD]: 'leads',
        [crmPrimaryRecordTypes.CONTACT]: 'persons',
        [crmPrimaryRecordTypes.ACCOUNT]: 'organizations',
        [crmPrimaryRecordTypes.MEETING]: 'meetings',
      },
    }

    return pluralNames[crmName]?.[crmRecordType] ?? undefined
  }

  static getCrmRecordTypeFromPlural(plural: string) {
    var crmName = LuruUser.getCurrentUserCrmName()
    var crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()

    if (!crmName) {
      return undefined
    }

    var pluralNames: { [_: string]: any } = {
      SFDC: {
        opportunities: [crmPrimaryRecordTypes.DEAL],
        leads: [crmPrimaryRecordTypes.LEAD],
        contacts: [crmPrimaryRecordTypes.CONTACT],
        accounts: [crmPrimaryRecordTypes.ACCOUNT],
        meetings: [crmPrimaryRecordTypes.MEETING],
        task: [crmPrimaryRecordTypes.TASK],
      },
      HUBSPOT: {
        deals: [crmPrimaryRecordTypes.DEAL],
        contacts: [crmPrimaryRecordTypes.CONTACT],
        companies: [crmPrimaryRecordTypes.ACCOUNT],
        meetings: [crmPrimaryRecordTypes.MEETING],
      },
      PIPEDRIVE: {
        deals: [crmPrimaryRecordTypes.DEAL],
        leads: [crmPrimaryRecordTypes.LEAD],
        persons: [crmPrimaryRecordTypes.CONTACT],
        organizations: [crmPrimaryRecordTypes.ACCOUNT],
        meetings: [crmPrimaryRecordTypes.MEETING],
      },
    }

    return pluralNames[crmName]?.[plural.toLowerCase()] ?? undefined
  }

  static getCrmRecordNameSingular(crmRecordType: CrmRecordType) {
    var crmName = LuruUser.getCurrentUserCrmName()
    var crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()

    if (!crmName) {
      return undefined
    }

    var singularNames: { [_: string]: any } = {
      SFDC: {
        [crmPrimaryRecordTypes.DEAL]: 'Opportunity',
        [crmPrimaryRecordTypes.LEAD]: 'Lead',
        [crmPrimaryRecordTypes.CONTACT]: 'Contact',
        [crmPrimaryRecordTypes.ACCOUNT]: 'Account',
        [crmPrimaryRecordTypes.MEETING]: 'meeting',
        [crmPrimaryRecordTypes.TASK]: 'Task',
      },
      HUBSPOT: {
        [crmPrimaryRecordTypes.DEAL]: 'deal',
        [crmPrimaryRecordTypes.CONTACT]: 'contact',
        [crmPrimaryRecordTypes.ACCOUNT]: 'company',
        [crmPrimaryRecordTypes.MEETING]: 'meeting',
      },
      PIPEDRIVE: {
        [crmPrimaryRecordTypes.DEAL]: 'deal',
        [crmPrimaryRecordTypes.LEAD]: 'lead',
        [crmPrimaryRecordTypes.CONTACT]: 'person',
        [crmPrimaryRecordTypes.ACCOUNT]: 'organization',
        [crmPrimaryRecordTypes.MEETING]: 'meeting',
      },
    }

    return singularNames[crmName]?.[crmRecordType] ?? undefined
  }

  static getCrmRecordType(sorObjectName: string, crmName?: CRMProvider): CrmRecordType {
    var crmPrimaryRecordTypes = CrmRecord.getAllPrimaryObjects()

    const names: Record<CRMProvider, { [_: string]: CrmRecordType }> = {
      [CRMProvider.SFDC]: {
        Opportunity: crmPrimaryRecordTypes.DEAL,
        Lead: crmPrimaryRecordTypes.LEAD,
        Contact: crmPrimaryRecordTypes.CONTACT,
        Account: crmPrimaryRecordTypes.ACCOUNT,
        meeting: crmPrimaryRecordTypes.MEETING,
      },
      [CRMProvider.SFDC_SANDBOX]: {
        Opportunity: crmPrimaryRecordTypes.DEAL,
        Lead: crmPrimaryRecordTypes.LEAD,
        Contact: crmPrimaryRecordTypes.CONTACT,
        Account: crmPrimaryRecordTypes.ACCOUNT,
        meeting: crmPrimaryRecordTypes.MEETING,
      },
      [CRMProvider.HUBSPOT]: {
        deals: crmPrimaryRecordTypes.DEAL,
        contacts: crmPrimaryRecordTypes.CONTACT,
        companies: crmPrimaryRecordTypes.ACCOUNT,
        meetings: crmPrimaryRecordTypes.MEETING,
        deal: crmPrimaryRecordTypes.DEAL,
        contact: crmPrimaryRecordTypes.CONTACT,
        account: crmPrimaryRecordTypes.ACCOUNT,
        meeting: crmPrimaryRecordTypes.MEETING,
      },
      [CRMProvider.PIPEDRIVE]: {
        deal: crmPrimaryRecordTypes.DEAL,
        lead: crmPrimaryRecordTypes.LEAD,
        person: crmPrimaryRecordTypes.CONTACT,
        organization: crmPrimaryRecordTypes.ACCOUNT,
        meeting: crmPrimaryRecordTypes.MEETING,
      },
    }

    crmName = (crmName ?? LuruUser.getCurrentUserCrmName()) as CRMProvider

    return names?.[crmName]?.[sorObjectName] ?? sorObjectName
  }

  static getFormattedValue({
    luruFieldType,
    value,
    schema,
  }: {
    luruFieldType: string
    value: any
    schema?: CrmFieldSchema
  }) {
    if (luruFieldType === '') {
      return value
    }
    return getFormattedCrmFieldValue({ luruFieldType, value, schema })
  }

  /**
   * Get icon for a given record type
   */
  static getIcon(recordType: CrmRecordType) {
    var crmName = LuruUser.getCurrentUserCrmName() as CRMProvider
    var recordName = recordNameInCrm[crmName][recordType] ?? recordType
    var icons = crmIcons as Record<string, Record<string, string>>
    return icons[crmName.toLowerCase()][recordName]
  }

  /**
   * Get icon for the current CRM
   */
  static getCrmIcon(crmName?: CRMProvider) {
    crmName = crmName || (LuruUser.getCurrentUserCrmName() as CRMProvider)
    var icons = {
      [CRMProvider.SFDC]: sfdcIcon,
      [CRMProvider.SFDC_SANDBOX]: sfdcIcon,
      [CRMProvider.HUBSPOT]: hubspotIcon,
      [CRMProvider.PIPEDRIVE]: pipedriveIcon,
    }

    return icons[crmName]
  }

  static getCrmName() {
    var crmKey = LuruUser.getCurrentUserCrmName()

    if (!crmKey) {
      return undefined
    }

    var crmName = new Map([
      ['SFDC', 'Salesforce'],
      ['SFDC_SANDBOX', 'Salesforce'],
      ['HUBSPOT', 'HubSpot'],
      ['PIPEDRIVE', 'Pipedrive'],
    ])

    return crmName.get(crmKey) ?? ''
  }

  static getPrimaryCrmObjects(): Array<{
    name: string
    plural: string
    singular: string
    isOpportunity: boolean
    crmRecordType: CrmRecordType
  }> {
    // @ts-ignore
    return CrmRecord.getMiddleware().getPrimaryObjectNames()
  }

  static getAllCrmObjects(): Array<{
    name: string
    plural: string
    singular: string
    isOpportunity: boolean
    crmRecordType: CrmRecordType
  }> {
    // @ts-ignore
    return CrmRecord.getMiddleware().getAllPrimaryObjectNames()
  }

  static getAllPrimaryObjects(): {
    [x: string]: string
  } {
    return {
      DEAL: 'deal',
      LEAD: 'lead',
      ACCOUNT: 'account',
      CONTACT: 'contact',
      MEETING: 'meeting',
      TASK: 'Task',
    }
  }

  /**
   * Search for 'sorObjectName' records with 'query'
   */
  static async searchRecords(sorObjectName: string, query: string) {
    var middleware = CrmRecord.getMiddleware()

    return LuruReduxStore.dispatch(
      // @ts-ignore
      middleware.searchRecords.action({
        query,
        objects: [sorObjectName],
      })
    ).then((response: any) => response.payload)
  }

  /** Get list of pipeline values */
  static async getPipelineList() {
    var middleware = CrmRecord.getMiddleware()

    return LuruReduxStore.dispatch(
      // @ts-ignore
      middleware.getPipelineList.action({ callerId: 'CrmRecord' })
    )
  }

  /** Get list of pipeline stages for a given pipeline */
  static async getPipelineStages(pipelineId: string) {
    var middleware = CrmRecord.getMiddleware()

    return LuruReduxStore.dispatch(
      // @ts-ignore
      middleware.getPipelineStages.action({ pipelineId, callerId: 'CrmRecord' })
    )
  }

  /** Check whether two CRM field values match */
  static doFieldValuesMatch(value1?: any, value2?: any, data?: { luruFieldType: LuruFieldType }): boolean {
    var fieldType = data?.luruFieldType
    type CompoundField = {
      sor_record_id: string
      sor_record_name: string
      sor_object_name: string
    }
    type MultiRefValue = Array<CompoundField> | undefined | null | string

    switch (fieldType) {
      case LuruFieldType.REFERENCE:
        let ref1 = value1 as CompoundField | undefined
        let ref2 = value2 as CompoundField | undefined

        return (
          ref1?.sor_object_name === ref2?.sor_object_name &&
          ref1?.sor_record_id === ref2?.sor_record_id &&
          ref1?.sor_record_name === ref2?.sor_record_name
        )

      case LuruFieldType.MULTIREFERENCE:
        let multiRef1 = [...value1] as MultiRefValue
        let multiRef2 = [...value2] as MultiRefValue
        let sortFn = (a: CompoundField, b: CompoundField) =>
          a.sor_record_id < b.sor_record_id ? -1 : a.sor_record_id === b.sor_record_id ? 0 : 1

        if (!Array.isArray(multiRef1) || !Array.isArray(multiRef2)) {
          // We don't expect to come here
          return `${multiRef1}` === `${multiRef2}`
        }

        multiRef1.sort(sortFn)
        multiRef2.sort(sortFn)

        return multiRef1.map((m) => m.sor_record_id).join(';') === multiRef2.map((m) => m.sor_record_id).join(';')
    }

    return `${value1}` === `${value2}`
  }

  /**
   * Compare two field values
   * @param type LuruFieldType
   * @param value1
   * @param value2
   * @returns boolean - true/false
   */
  static compareFieldValues(type: LuruFieldType, value1?: any, value2?: any) {
    switch (type) {
      case LuruFieldType.HIERARCHICAL_ENUM:
        let hv1 = value1 as LuruHierarchialPicklistValue | undefined
        let hv2 = value2 as LuruHierarchialPicklistValue | undefined
        return hv1?.level1 === hv2?.level1 && hv1?.level2 === hv2?.level2

      case LuruFieldType.REFERENCE:
        let refVal1 = value1 as CompoundFieldValue | undefined
        let refVal2 = value2 as CompoundFieldValue | undefined
        return (
          refVal1?.sor_object_name === refVal2?.sor_object_name &&
          refVal1?.sor_record_id === refVal2?.sor_record_id &&
          refVal1?.sor_record_name === refVal2?.sor_record_name
        )

      case LuruFieldType.MULTIENUM: {
        let v1 = Array.isArray(value1)
          ? value1.map(String).sort().join(';')
          : typeof value1 === 'string'
          ? value1.split(';').sort().join(';')
          : String(value1)
        let v2 = Array.isArray(value2)
          ? value2.map(String).sort().join(';')
          : typeof value2 === 'string'
          ? value2.split(';').sort().join(';')
          : String(value2)

        return v1 === v2
      }

      default:
        return value1 === value2
    }
  }
}
