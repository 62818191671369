import LuruHierarchicalPicklist, { LuruHierarchicalPicklistProps } from '.'

interface LuruHierarchicalPicklistEvents {
  onReset: () => void
  onChooseItem: (e: React.MouseEvent<HTMLElement>) => void
  onShowSelectDropdown: () => void
  onFilterBoxChange: (value: string | null) => void
  onFilterBoxNavigateDown: () => void
  onFilterBoxNavigateUp: () => void
  onFilterBoxReturn: () => void
  onFilterBoxCancel: () => void
}

export default class LuruHierarchicalPicklistEventHandler {
  #component: LuruHierarchicalPicklist
  handlers: LuruHierarchicalPicklistEvents

  constructor(component: LuruHierarchicalPicklist) {
    this.#component = component
    this.#component.componentDidUpdate = this.#onComponentUpdate.bind(this)
    this.handlers = {
      onReset: this.#onReset.bind(this),
      onChooseItem: this.#onChooseItem.bind(this),
      onShowSelectDropdown: this.#onShowSelectDropdown.bind(this),
      onFilterBoxChange: this.#onFilterBoxChange.bind(this),
      onFilterBoxNavigateUp: this.#onFilterBoxNavigateUp.bind(this),
      onFilterBoxNavigateDown: this.#onFilterBoxNavigateDown.bind(this),
      onFilterBoxReturn: this.#onFilterBoxReturn.bind(this),
      onFilterBoxCancel: this.#onFilterBoxCancel.bind(this),
    }
  }

  #onComponentUpdate(prevProps: LuruHierarchicalPicklistProps) {
    if (
      prevProps.prechosenItem?.level1 !==
        this.#component.props.prechosenItem?.level1 ||
      prevProps.prechosenItem?.level2 !==
        this.#component.props.prechosenItem?.level2
    ) {
      var chosenItemLevel1 = this.#component.props.options.find(
        (item) => item.value === this.#component.props.prechosenItem?.level1
      )
      var chosenItemLevel2 = chosenItemLevel1?.options.find(
        (item) => item.value === this.#component.props.prechosenItem?.level2
      )
      var chosenItemName =
        chosenItemLevel1?.label + ': ' + chosenItemLevel2?.label

      this.#component.setState({ chosenItemName })
    }

    this.#navigateToSelectedItem()
  }

  #chooseItem(index: { level1: number; level2: number }) {
    this.#component.componentRefs.popupControl.current?.hideMenu()
    this.#component.componentRefs.filterBox?.current?.reset()
    this.#component.setState({
      chosenItemName:
        this.#component.state.filteredOptions[index.level1].label +
        ': ' +
        this.#component.state.filteredOptions[index.level1].options[
          index.level2
        ].label,
      highlightedIndex: {
        level1: -1,
        level2: -1,
      },
      filteredOptions: this.#component.props.options,
    })
    this.#component.props.onChooseItem({
      level1: this.#component.state.filteredOptions[index.level1].value,
      level2:
        this.#component.state.filteredOptions[index.level1].options[
          index.level2
        ].value,
    })
  }

  #onFilterBoxChange(value: string | null) {
    var filterText = value ?? ''
    this.#component.setState({
      filteredOptions: this.#component.props.options
        .map((level1Option) => ({
          ...level1Option,
          options: level1Option.options.filter((option) =>
            option.label
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase())
          ),
        }))
        .filter(
          (level1Option) =>
            level1Option.label
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase()) ||
            level1Option.options.length > 0
        ),
    })
  }

  #onFilterBoxReturn() {
    var chosenIndex = this.#component.state.highlightedIndex

    if (
      chosenIndex.level1 >= 0 &&
      chosenIndex.level2 >= 0 &&
      chosenIndex.level1 < this.#component.state.filteredOptions.length &&
      chosenIndex.level2 <
        this.#component.state.filteredOptions[chosenIndex.level1].options.length
    ) {
      this.#chooseItem(this.#component.state.highlightedIndex)
    } else {
      this.#component.componentRefs.popupControl.current?.hideMenu()
      this.#component.componentRefs.filterBox?.current?.reset()
    }
  }

  #onFilterBoxNavigateUp() {
    var currOptions = this.#component.state.filteredOptions
    var currIndex = this.#component.state.highlightedIndex
    var lastIxLevel1 = currOptions.length - 1
    var prevIxLevel1 = currIndex.level1 - 1
    var nextIndex = {
      level1:
        currIndex.level2 > 0
          ? currIndex.level1
          : currIndex.level2 === 0
          ? currIndex.level1 === 0
            ? lastIxLevel1
            : prevIxLevel1
          : lastIxLevel1,
      level2:
        currIndex.level2 > 0
          ? currIndex.level2 - 1
          : currIndex.level2 === 0
          ? currIndex.level1 > 0
            ? currOptions[prevIxLevel1].options.length - 1
            : currOptions[lastIxLevel1].options.length - 1
          : currOptions[lastIxLevel1].options.length - 1,
    }

    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #onFilterBoxNavigateDown() {
    var currOptions = this.#component.state.filteredOptions
    var currIndex = this.#component.state.highlightedIndex
    var lastIxLevel1 = currOptions.length - 1
    var nextIndex = {
      level1:
        currIndex.level1 === -1
          ? 0
          : currIndex.level2 !==
            currOptions[currIndex.level1].options.length - 1
          ? currIndex.level1
          : currIndex.level1 === lastIxLevel1
          ? 0
          : currIndex.level1 + 1,
      level2:
        currIndex.level2 === -1
          ? 0
          : currIndex.level2 < currOptions[currIndex.level1].options.length - 1
          ? currIndex.level2 + 1
          : 0,
    }

    this.#component.setState({ highlightedIndex: nextIndex })
  }

  #onFilterBoxCancel() {
    this.#component.setState({
      highlightedIndex: { level1: -1, level2: -1 },
      filteredOptions: this.#component.props.options,
    })
    this.#component.componentRefs.popupControl.current?.hideMenu()
    this.#component.componentRefs.filterBox?.current?.reset()
  }

  #onReset() {
    this.#component.setState({ chosenItemName: undefined })
    this.#component.componentRefs.filterBox?.current?.reset()
  }

  #onChooseItem(e: React.MouseEvent<HTMLElement>) {
    const chosenIx = (e.target as HTMLElement)?.dataset.itemChooserKey?.split(
      ';'
    )

    if (Array.isArray(chosenIx) && chosenIx.length === 2) {
      this.#chooseItem({
        level1: parseInt(chosenIx[0]),
        level2: parseInt(chosenIx[1]),
      })
    }
  }

  #onShowSelectDropdown() {
    // We need a setTimeout because focus() method can be called only when
    // element is visible.  The 100ms timeout allows this component to wait for
    // show() method to finish (including CSS fade-in animation transition end)
    setTimeout(
      () => this.#component.componentRefs.filterBox?.current?.focus(),
      100
    )
  }

  #navigateToSelectedItem() {
    var ulElement = this.#component.componentRefs.list
      .current as HTMLUListElement
    var liElement = ulElement?.querySelector(
      'li[data-highlighted="yes"]'
    ) as HTMLLIElement

    if (liElement) {
      let topOverflow = Math.max(
        0,
        ulElement.scrollTop + liElement.offsetHeight - liElement.offsetTop
      )
      let bottomOverflow = Math.max(
        0,
        liElement.offsetTop +
          liElement.offsetHeight -
          ulElement.scrollTop -
          ulElement.offsetHeight
      )
      if (topOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: -topOverflow - 10, behavior: 'instant' })
      } else if (bottomOverflow !== 0) {
        // @ts-ignore
        ulElement.scrollBy({ top: bottomOverflow, behavior: 'instant' })
      }
    }
  }
}
