import { openWindow } from './openWindow'

export const openLoginWindow = (loginUrl: string) =>
  openWindow(
    loginUrl.replace(
      'embedded_meeting_note',
      `signin_and_embed_meeting_note/${document.body.dataset.luruTabId}`
    ),
    'Login to Luru',
    {
      width: 800,
      height: window.screen.availHeight * 0.8,
    }
  )
