import { Route } from 'react-router-dom'
import { RedirectToEmbeddedNote } from '../../../routes/meetingNotes/EmbeddedMeetingNote'
import EmbeddedMeetingNoteWrapperLazyLoading from './extension_pages/EmbeddedMeetingNoteWrapper'
import EmbeddedNoteCreateDraftNoteLazyLoading from './extension_pages/EmbeddedNoteCreateDraftNote'
import EmbeddedNoteWrapperLazyLoading from './extension_pages/EmbeddedNoteWrapper'
import ZoomEmbeddedMeetingNoteLazyLoading from './extension_pages/ZoomEmbeddedMeetingNote'
import ZoomConnectModalLazyLoading from './extension_pages/ZoomConnectModal'

const renderExtensionPageRoutes = () => (
  <>
    <Route path='/embedded_meeting_note/:meetingUrl' element={<EmbeddedMeetingNoteWrapperLazyLoading />} />
    <Route path='/embedded_new_draft_note/' element={<EmbeddedNoteCreateDraftNoteLazyLoading />} />
    <Route path='/embedded_note/:urlNoteId' element={<EmbeddedNoteWrapperLazyLoading />} />
    <Route
      path='/signin_and_embed_meeting_note/:tabId/:meetingUrl'
      element={<RedirectToEmbeddedNote embedded={true} />}
    />
    <Route path='/zoombedded_meeting_note' element={<ZoomEmbeddedMeetingNoteLazyLoading />} />
    <Route path='/embedded_zoom_connect' element={<ZoomConnectModalLazyLoading />} />
  </>
)

export default renderExtensionPageRoutes
