import { useState } from 'react'
import { EmailConnectModalProps, EmailConnectModalState } from './EmailConnectModalComponent'
import { EmailProvider } from '@/features/user/types'

export default function useComponentState(props: EmailConnectModalProps) {
  const [state, setState] = useState<EmailConnectModalState>({
    show: false,
    isFromError: false,
    emailProvider: EmailProvider.GMAIL
  })

  return {
    state,
    setState,
  }
}

export type UseComponentState = ReturnType<typeof useComponentState>
