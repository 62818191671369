import moment from 'moment'
import React, { ClipboardEvent } from 'react'
import {
  EditableCellStatus,
  // EditableCellProps,
  // EditableCellState,
} from '.'
// import CrmRecord from '../../domain/crmRecord/CrmRecord'
import { compareFieldValues } from '../../features/crm/helpers/compareFieldValues'
import { LuruFieldType } from '../../features/crm/types'
import { AppComponents } from '../AppComponents/typings'
import EditableCell from '.'

export interface EditableCellEvent extends Event {
  detail: {
    value: any
  }
}

function isEditableCellEvent(event: Event): event is EditableCellEvent {
  // @ts-ignore
  return 'detail' in event && 'value' in event['detail']
}

export default class EditableCellEventHandler {
  #component: EditableCell
  setValue: (e: Event) => void
  #isMounted = false

  handlers: {
    onBlur: (_: string) => void
    onFocus: () => void
    onFocusLargeText: () => void
    onEnter: () => void
    onCancel: () => void
    onRevert: () => void
    onEditCell: () => void
    onClickShowNotes: () => void
    onClickShowDetails: () => void
    onDatePicked: () => void
    onMultiSelectFinish: (values: Array<string>) => void
    onSelectItem: (value: string | null) => void
    onSelectLayerKeyDown: React.KeyboardEventHandler<HTMLDivElement>
    onReferenceValueChange: (sorRecordId: string, sorRecordName: string, sorObjectName?: string) => void
    onReferenceValueDeleted: () => void
    onTextAreaKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
    onTextAreaPaste: (e: ClipboardEvent<HTMLTextAreaElement>) => void
    onTextBoxPaste: (e: ClipboardEvent<HTMLInputElement>) => void
    onTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    onTextBoxKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  }

  constructor(component: EditableCell) {
    this.#component = component
    this.#component.componentDidMount = this.#onComponentMount
    // this.#component.componentDidUpdate = this.#onComponentUpdate
    this.#component.componentWillUnmount = this.#onComponentUnmount

    this.handlers = {
      onBlur: this.#onBlur,
      onFocus: this.#onFocus,
      onFocusLargeText: this.#onFocusLargeText,
      onEnter: this.#onEnter,
      onCancel: this.#onCancel,
      onRevert: this.#onRevert,
      onClickShowNotes: this.#onClickShowNotes,
      onClickShowDetails: this.#onClickShowDetails,
      onEditCell: this.#onEditCell,
      onDatePicked: this.#onDatePicked,
      onSelectLayerKeyDown: this.#onSelectLayerKeyDown,
      onReferenceValueChange: this.#onReferenceValueChange,
      onReferenceValueDeleted: this.#onReferenceValueDeleted,
      onTextAreaKeyDown: this.#onTextAreaKeyDown,
      onTextAreaChange: this.#onTextAreaChange,
      onMultiSelectFinish: this.#onMultiSelectFinish,
      onSelectItem: this.#onSelectItem,
      onTextBoxKeyDown: this.#onTextBoxKeyDown,
      onTextBoxPaste: this.#onTextBoxPaste,
      onTextAreaPaste: this.#onTextAreaPaste,
    }

    this.setValue = this.#setValue
  }

  #onComponentMount = () => {
    var elemId = this.#component.getElementId()
    var element = document.getElementById(elemId)

    element?.addEventListener('setValue', this.setValue)
    this.#isMounted = true
  }

  #onComponentUnmount = () => {
    var elemId = this.#component.getElementId()
    var element = document.getElementById(elemId)

    element?.removeEventListener('setValue', this.setValue)
    this.#isMounted = false
  }

  #setValue = (event: Event) => {
    if (isEditableCellEvent(event)) {
      this.#component.setState(
        {
          fieldValue: event.detail.value,
          displayedValue: this.#component.formatFieldValue(event.detail.value),
        },
        () => this.#component.forceUpdate()
      )
    }
  }

  #onClickShowNotes = () => this.#component.props?.onClickShowNotes?.()

  #onClickShowDetails = () => this.#component.props?.onClickShowDetails?.()

  #onFocus = () => {
    var nonFormattedValue = this.#component.state.fieldValue
    var textBox = this.#component.componentRefs.textBoxRef.current

    if (textBox) {
      if (this.#component.props.luruFieldType === LuruFieldType.DATE && nonFormattedValue) {
        textBox.setValue(moment(nonFormattedValue).format('yyyy-MM-DD'))
      } else if (this.#component.props.luruFieldType === LuruFieldType.DATETIME && nonFormattedValue) {
        textBox.setValue(moment(nonFormattedValue).format('yyyy-MM-DDTHH:mm'))
      } else {
        textBox.setValue(nonFormattedValue)
      }
    }

    this.#component.setState({ status: EditableCellStatus.IDLE })
  }

  #onFocusLargeText = () => {
    var appComponents = this.#component.context as AppComponents
    var textBox = this.#component.componentRefs.textBoxRef.current

    appComponents.largeTextInput?.current?.showDialog({
      title: 'Edit',
      label: 'Enter text',
      value: textBox?.state?.value ?? this.#component.props.value,
      callback: (value: string) => {
        if (textBox) {
          textBox.setValue(value)
        }

        // this.#component.props.onBlur?.(value, this.#component)
        this.handleBlur(value)
      },
    })
  }

  #onBlur = (value: any) => {
    var formattedValue = this.#component.formatFieldValue(value)
    var textBox = this.#component.componentRefs.textBoxRef.current

    // Value is present in text box, (contd...)
    if (textBox) {
      textBox.setValue(formattedValue)
    }

    // ...and as fieldValue & displayedValue in this component (contd...)
    // ...and as this.#component.props.value (which we cannot change from here)
    this.#component.setState(
      {
        fieldValue: value,
        displayedValue: this.#component.formatFieldValue(value),
      },
      () => {
        // Value is also present (in records array) in PipelineView (fin.)
        this.handleBlur(value)
      }
    )
  }

  #onEnter = () => this.#component.componentRefs.textBoxRef.current?.blur()

  #onCancel = () => {
    var textBox = this.#component.componentRefs.textBoxRef.current
    var textArea = this.#component.componentRefs.textAreaRef.current

    if (textBox) {
      textBox.setValue(this.#component.state.fieldValue, () => {
        if (textBox) {
          textBox.blur()
        }
      })
    }

    if (textArea) {
      textArea.value = this.#component.state.fieldValue

      setTimeout(() => {
        if (textArea) {
          textArea.blur()
        }
      })
    }
  }

  #onRevert = () => {
    var textBox = this.#component.componentRefs.textBoxRef.current
    var originalValue = this.#component.props.value

    if (textBox) {
      textBox.setValue(this.#component.formatFieldValue(this.#component.props.value))
    }

    this.#component.setState({
      displayedValue: this.#component.formatFieldValue(this.#component.props.value),
      fieldValue: originalValue,
      status: EditableCellStatus.IDLE,
    })
  }

  #onEditCell = () => {
    var selectLayer = this.#component.componentRefs.selectLayerRef.current

    if (selectLayer) {
      let textBox = this.#component.componentRefs.textBoxRef.current

      if (textBox) {
        textBox.focus()
      }

      selectLayer.style.display = 'none'
    }
  }

  #onMultiSelectFinish = (values: Array<string>) => {
    this.handleFieldValueChange(values.join(';'))
  }

  #onSelectItem = (value: string | null) => {
    if (value !== null || this.#component.props.fieldSchema?.isNillable) {
      this.handleFieldValueChange(value)
    }
  }

  #onSelectLayerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation()

    switch (e.key) {
      case 'ArrowDown':
        this.#component.props.onNavigateDown && this.#component.props.onNavigateDown()
        e.preventDefault()
        return

      case 'ArrowUp':
        this.#component.props.onNavigateUp && this.#component.props.onNavigateUp()
        e.preventDefault()
        return

      case 'ArrowLeft':
        this.#component.props.onNavigateLeft && this.#component.props.onNavigateLeft()
        e.preventDefault()
        return

      case 'ArrowRight':
        this.#component.props.onNavigateRight && this.#component.props.onNavigateRight()
        e.preventDefault()
        return

      case 'Tab':
        if (e.shiftKey) {
          this.#component.props.onNavigateLeft && this.#component.props.onNavigateLeft()
        } else {
          this.#component.props.onNavigateRight && this.#component.props.onNavigateRight()
        }
        e.preventDefault()
        return

      case 'Escape':
        this.#component.componentRefs.selectLayerRef.current?.blur()
        e.preventDefault()
        return
    }
  }

  #onDatePicked = () => setTimeout(() => this.#component.componentRefs.textBoxRef.current?.blur(), 50)

  #onReferenceValueChange = (sorRecordId: string, sorRecordName: string, sorObjectName?: string) => {
    if (sorObjectName && sorRecordId && sorRecordName) {
      this.handleFieldValueChange({
        sor_object_name: sorObjectName,
        sor_record_id: sorRecordId,
        sor_record_name: sorRecordName,
      })
    }
  }

  #onReferenceValueDeleted = () => this.handleFieldValueChange(null)

  handleBlur = (value: any) => {
    if (this.#component.props.updateOnChange) {
      this.handleFieldValueChange(value)
    } else {
      this.#component.props.onBlur?.(value, this.#component)
    }
  }

  handleFieldValueChange = async (value: any) => {
    if (this.#component.props.updateOnChange) {
      let { crmRecordType, sorRecordId } = this.#component.props
      let fieldName = this.#component.props.fieldSchema?.name

      if (!crmRecordType || !sorRecordId || !fieldName) {
        return
      }

      if (compareFieldValues(this.#component.props.value, value, this.#component.props.luruFieldType)) {
        return
      }

      if (
        this.#component.props.fieldSchema?.isMandatory &&
        !Boolean(value) &&
        value !== 0 &&
        value !== false &&
        this.#component.props.onUpdateError
      ) {
        let textBox = this.#component.componentRefs.textBoxRef.current

        if (textBox) {
          textBox.setValue(this.#component.formatFieldValue(this.#component.props.value))

          this.#component.setState({
            displayedValue: this.#component.formatFieldValue(this.#component.props.value),
            fieldValue: this.#component.props.value,
            status: EditableCellStatus.IDLE,
          })
        }

        this.#component.props.onUpdateError('This field is mandatory')
        setTimeout(() => this.#component.props.onUpdateError?.(undefined), 3000)

        return
      }

      this.#component.props.onUpdateError?.(undefined)
      this.#component.setLoadingState()

      // try {
      //   await CrmRecord.multiUpdate({
      //     crmRecordType,
      //     fields: { [fieldName]: value },
      //     sorRecordId,
      //   })
      //   if (this.#isMounted) {
      //     this.#component.setSuccessState()
      //     setTimeout(() => this.#isMounted && this.#component.setIdleState(), 2000)
      //   }
      // } catch (e) {
      //   if (this.#isMounted) {
      //     this.#component.props.onUpdateError?.('Error updating field. ' + (e as Error).message)
      //     this.#isMounted && this.#component.setIdleState()
      //   }
      // }
      this.#component.props?.onFieldValueChange?.(fieldName, value)
    } else if (!compareFieldValues(this.#component.props.value, value, this.#component.props.luruFieldType)) {
      this.#component.props.onChange?.(value, this.#component)
    }
  }

  #onTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation()

    const key = e.key

    switch (key) {
      case 'Escape':
        this.#onCancel()
        return
    }
  }

  #onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const elem = this.#component.componentRefs.textAreaRef.current

    if (elem) {
      this.#component.setState({
        displayedValue: e.target.value,
      })
    }
  }

  #onTextBoxKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
  }

  #onTextBoxPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
  }

  #onTextAreaPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation()
  }
}
