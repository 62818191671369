// 3rd party components
import json5 from 'json5'

// Redux
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

// Own libraries
import { NotesSliceActions } from '../notesSlice'
import { NotesAPI } from '../api'
import { NoteSyncState, ReduxNoteEntity, ReduxNotesState } from '../types'
import { EntityStatus, LuruAPIResponse } from '../../../app/types'
import { AppDispatch, RootState } from '../../../app/store'
import LuruError from '../../LuruError'

export interface SaveNoteParameter extends Partial<ReduxNoteEntity> {
  isDraft: boolean
  sync: boolean
  syncState: NoteSyncState
  template_id: string | null
}

export interface SaveNoteAPIResponse extends LuruAPIResponse {
  data: ReduxNoteEntity
}

export const saveNote = {
  action: createAsyncThunk<
    SaveNoteAPIResponse['data'],
    SaveNoteParameter,
    {
      dispatch: AppDispatch
      state: RootState
      fulfilledMeta: null | SaveNoteAPIResponse['metadata']
      rejectedMeta: null | SaveNoteAPIResponse['metadata']
    }
  >(
    'notes/saveNote',
    async (
      { note_id, body = null, isDraft = false, sync = false, template_id = null },
      { getState, dispatch, fulfillWithValue, rejectWithValue, signal }
    ) => {
      try {
        if (!note_id) {
          let message = 'saveNote called without noteId'
          return rejectWithValue({ message }, null)
        }

        var title = getState().notes?.entities[note_id]?.data?.title

        // Attempting to get a non-null non-undefined body before saving
        if (!body) {
          body = getState().notes?.entities[note_id]?.data?.draft ?? null

          if (!body) {
            body = getState().notes?.entities[note_id]?.data?.body ?? null
          }

          if (!body) {
            let noteIdShort = note_id.slice(0, 5)
            let message = `saveNote:[${noteIdShort}]: Can't get body to save`
            return rejectWithValue({ message }, null)
          }
        }

        if (!title && title !== '') {
          let noteIdShort = note_id.slice(0, 5)
          let message = `saveNote:[${noteIdShort}]: Can't get a non-null title to save`
          return rejectWithValue({ message }, null)
        }

        if (body && body.trim() !== '' && body.trim() !== '[]') {
          let bodyObj = json5.parse(body)

          if (!(bodyObj instanceof Array)) {
            let noteIdShort = note_id.slice(0, 5)
            let message = `saveNote:[${noteIdShort}]: Body is not valid JSON`
            return rejectWithValue({ message }, null)
          }

          dispatch(NotesSliceActions.updateBody({ noteId: note_id, body, isDraft }))

          const existingRecord = getState().notes.entities?.[note_id]

          try {
            const response = (await NotesAPI.saveNote(
              {
                note_id,
                title,
                body,
                sync: sync,
                isDraft: existingRecord?.isDraft === true ?? false,
                syncState: existingRecord?.data?.sync_state ?? NoteSyncState.PRIVATE,
                connections: existingRecord?.data?.connections ?? null,
                template_id,
              },
              { signal }
            )) as SaveNoteAPIResponse

            return fulfillWithValue(response.data, response.metadata)
          } catch (e) {
            let luruError = e instanceof LuruError ? (e as LuruError) : null

            return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
          }
        } else {
          return rejectWithValue(
            {
              message: 'Error saving note. Body is empty',
            },
            null
          )
        }
      } catch (e) {
        let luruError = e instanceof LuruError ? (e as LuruError) : null

        return rejectWithValue(luruError?.toErrorValue() ?? e, luruError?.toErrorValue().meta ?? null)
      }
    },
    {
      condition: ({ note_id }, { getState }) =>
        note_id !== undefined &&
        !getState()
          .notes.deletedNotes?.map((e) => e.note_id)
          .includes(note_id) &&
        getState().notes?.entities?.[note_id]?.saveStatus !== EntityStatus.Updating,
    }
  ),

  addPendingCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(saveNote.action.pending, (state, action) => {
      var { note_id } = action.meta.arg

      if (note_id === undefined) {
        return
      }

      var currEntity = state.entities[note_id]

      if (!currEntity) {
        state.entities[note_id] = {
          data: { note_id },
          status: EntityStatus.Updating,
          saveStatus: EntityStatus.Updating,
        }
        return
      }

      currEntity.saveStatus = EntityStatus.Updating
    })
  },

  addFulfilledCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(saveNote.action.fulfilled, (state, action) => {
      // Request may have been fulfilled by the thunk from redux itself
      // In this case, we need not update redux.  If the request had been
      // satisfied using an API, then we need to add an entry in redux
      var noteId = action.meta.arg.note_id

      if (!noteId) {
        return
      }

      var currEntity = state.entities[noteId]

      if (!currEntity) {
        return
      }

      if (!action.payload) {
        currEntity.saveStatus = EntityStatus.ErrorUpdating
        return
      }

      var noteTitle = action.payload.title

      // Update title
      currEntity.data.title = noteTitle
      currEntity.status = EntityStatus.Loaded
      currEntity.isDraft = false
      currEntity.saveStatus = EntityStatus.Updated

      if (!currEntity.data) {
        currEntity.data = action.payload
      } else {
        for (let recordKey of Object.keys(action.payload)) {
          // Update all record keys except under only 1 circumstance:
          // When this is a draft save, don't update body
          if (!(recordKey === 'body' && action.meta.arg.isDraft)) {
            let noteProperty = recordKey as keyof ReduxNoteEntity
            let value = action.payload[noteProperty]

            // @ts-ignore
            state.entities[noteId].data[noteProperty] = value

            if (recordKey === 'body' && typeof value !== 'string') {
              // console.warn(`notesAPI:saveNote returned a non-string body`)
              // console.warn(`notesAPI:Fix this at API level`)
              currEntity.data.body = json5.stringify(value)
            } // end:if(body was an object)
          } // end:if(data.recordKey should be updated)
        } // end:for(iterate recordKey)
      } // end:if(noteId.data null vs. not)..else..
    })
  },

  addRejectedCase(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    builder.addCase(saveNote.action.rejected, (state, action) => {
      if (action.meta.aborted) {
        return
      }

      var { note_id } = action.meta.arg
      var currEntity = note_id ? state.entities[note_id] : undefined

      if (currEntity) {
        currEntity.saveStatus = EntityStatus.ErrorUpdating
      }
    })
  },

  addAllCases(builder: ActionReducerMapBuilder<ReduxNotesState>) {
    saveNote.addPendingCase(builder)
    saveNote.addFulfilledCase(builder)
    saveNote.addRejectedCase(builder)
  },
}
