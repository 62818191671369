import LuruSelectBox, { LuruSelectBoxProps } from '../../../ui/LuruSelectBox'
import LuruUser from '../../../../domain/users/LuruUser'
import { CRMProvider } from '../../../../features/user/types'
import { CrmActivityLogType } from '../../../../features/crm/types'
import { useMemo } from 'react'

export interface CrmLogSelectBoxProps extends Omit<LuruSelectBoxProps, 'items'> {
  selectBoxRef?: React.RefObject<LuruSelectBox>
  loading?: boolean
}

export default function CrmLogSelectBox({ prechosenItem, selectBoxRef, loading, ...props }: CrmLogSelectBoxProps) {
  const dropDownItems: LuruSelectBoxProps['items'] = useMemo(() => {
    const userCrmName = LuruUser.getCurrentUserCrmName()
    return userCrmName === CRMProvider.SFDC || userCrmName === CRMProvider.SFDC_SANDBOX
      ? [
          // {
          //   name: 'Call',
          //   key: 'Call',
          // },
          {
            name: 'Event',
            key: CrmActivityLogType.Meeting,
          },
          {
            name: 'Do not log',
            key: CrmActivityLogType.DoNotLog,
          },
        ]
      : [
          // {
          //   name: 'Call',
          //   key: 'Call',
          // },
          {
            name: 'Meeting',
            key: CrmActivityLogType.Meeting,
          },
          {
            name: 'Do not log',
            key: CrmActivityLogType.DoNotLog,
          },
        ]
  }, [])

  if (loading) {
    return (
      <LuruSelectBox
        leftAlign={true}
        selectLabel='Loading...'
        disabled={true}
        {...props}
        items={[]}
        ref={selectBoxRef}
      />
    )
  }
  return (
    <LuruSelectBox
      leftAlign={true}
      selectLabel='Log as'
      {...props}
      prechosenItem={
        dropDownItems?.find?.(
          (f) =>
            f.key ===
            ([CrmActivityLogType.Meeting, 'meetings'].includes(prechosenItem || '')
              ? CrmActivityLogType.Meeting
              : prechosenItem)
        )?.name
      }
      items={dropDownItems}
      ref={selectBoxRef}
    />
  )
}
