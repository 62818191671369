import React from 'react'
import UserLogo from '../../UserLogo'

import styles from './css/LuruUserDisplay.module.css'
// Icons
import doneIcon from '../../../images/fluent/done.svg'

export interface LuruUserDisplayProps {
  name: string
  email: string
  isSelected: boolean
  inSelectableList: boolean
}

export default class LuruUserDisplay extends React.Component<LuruUserDisplayProps> {
  props: LuruUserDisplayProps

  constructor(props: LuruUserDisplayProps) {
    super(props)
    this.props = props
  }

  render() {
    let names = this.props.name.split(' ')
    let firstname = names[0]
    let lastname = names[1] ?? ''

    return (
      <div className={styles.parent}>
        <div className={styles.userContainer}>
          {this.props.isSelected ? (
            <div className={styles.selected}>
              <img src={doneIcon} alt='done' />
            </div>
          ) : this.props.inSelectableList ? (
            <div className={styles.selected}>
              <span>&nbsp;</span>
            </div>
          ) : null}
          <UserLogo firstname={firstname} lastname={lastname} />
          <div className={styles.nameEmailContainer}>
            <div className={styles.userName}>{this.props.name}</div>
            <div className={styles.userEmail}>{this.props.email}</div>
          </div>
        </div>
      </div>
    )
  }
}
