import { RootState } from '../store'
import { EmptyAppState } from '../../features/app/types'
import { EmptyReduxChatState } from '../../features/chat/types'
import { EmptyCollectionsState } from '../../features/collections/types'
import { EmptyEmailsState } from '../../features/emails/types'
import { EmptyEmailSyncsState } from '../../features/emailsyncs/types'
import { EmptyCrmState } from '../../features/crm/types'
import { EmptyMeetingsState } from '../../features/meetings/types'
import { EmptyNotesState } from '../../features/notes/types'
import { EmptyNoteTemplatesState } from '../../features/noteTemplates/types'
import { EmptyTasksState } from '../../features/tasks/types'
import { EmptyUserState } from '../../features/user/types'
import { EmptyUsersState } from '../../features/users/types'
import { EmptyViewsState } from '../../features/views/types'
import { EmptyReduxWorkflowsState } from '../../features/workflows/emptyState'

// TODO: Replace each slice's empty state with the corresponding empty state
// imported from respective slice file
export const getEmptyReduxState: () => RootState = () => ({
  app: EmptyAppState,
  chat: EmptyReduxChatState,
  collections: EmptyCollectionsState,
  emails: EmptyEmailsState,
  emailsyncs: EmptyEmailSyncsState,
  crm: EmptyCrmState,
  meetings: EmptyMeetingsState,
  notes: EmptyNotesState,
  noteTemplates: EmptyNoteTemplatesState,
  tasks: EmptyTasksState,
  user: EmptyUserState,
  users: EmptyUsersState,
  views: EmptyViewsState,
  workflows: EmptyReduxWorkflowsState,
})
