// React
import React from 'react'

// Own components
import FlatButton from './FlatButton'

// Styles
import styles from './css/ConfirmDialog.module.css'

export default class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.showDialog = this.showDialog.bind(this)
    this.parentRef = React.createRef()
    this.onButtonClick = this.onButtonClick.bind(this)
    this.renderButton = this.renderButton.bind(this)
    this.state = {
      title: this.props.title,
      message: this.props.children,
      buttons: this.props.buttons,
    }
    this.onBlur = this.onBlur.bind(this)
  }

  onBlur(e) {
    e.preventDefault()
    let btnIndex = parseInt(
      e.target.dataset.role.replace('luru-button-index-', ''),
      10
    )
    let nextIndex =
      btnIndex === this.state.buttons.length - 1 ? 0 : btnIndex + 1
    this.parentRef.current
      ?.querySelector(`[data-role="luru-button-index-${nextIndex}"]`)
      ?.focus()
  }

  setDialog({ title, message, buttons }) {
    this.setState({ title, message, buttons })
  }

  showDialog() {
    this.parentRef.current?.classList.remove(styles.minimized)
    setTimeout(() => {
      this.parentRef.current
        ?.querySelector(`[data-role="luru-button-index-0"]`)
        ?.focus()
    }, 50)
  }

  onButtonClick(callback) {
    return async (e) => {
      if (callback) {
        await callback()
      }
      this.parentRef.current?.classList.add(styles.minimized)
    }
  }

  renderButton(button, index) {
    if (!button) {
      return <></>
    }
    let name = button.title
    let isOkButton = button.isOkButton
    if (isOkButton) {
      return (
        <FlatButton
          className={styles.okButton}
          key={name}
          dataRole={`luru-button-index-${index}`}
          onClick={this.onButtonClick(button.onClick)}
          onBlur={this.onBlur}
        >
          {name}
        </FlatButton>
      )
    } else {
      return (
        <FlatButton
          key={name}
          dataRole={`luru-button-index-${index}`}
          onClick={this.onButtonClick(button.onClick)}
          onBlur={this.onBlur}
        >
          {name}
        </FlatButton>
      )
    }
  }

  renderButtons(buttons) {
    if (!buttons) {
      return <></>
    }
    return buttons.map((button, index) => this.renderButton(button, index))
  }

  render() {
    return (
      <div
        className={[styles.parent, styles.minimized].join(' ')}
        ref={this.parentRef}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className={styles.dialog}>
          <div className={styles.title}>{this.state.title}</div>
          <div className={styles.content}>{this.state.message}</div>
          <div className={styles.buttons}>
            {this.renderButtons(this.state.buttons)}
          </div>
        </div>
      </div>
    )
  }
}
