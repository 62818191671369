import EditorController, {
  EditorEntityType,
} from '../../coreEditor/EditorController'

export default class NotesEditorController extends EditorController {
  constructor(config) {
    super({ ...config, entityId: config.noteId })
  }

  getEntityType() {
    return EditorEntityType.Note
  }
}
