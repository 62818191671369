import { localUserSettings } from '@/features/user/localUserSettings'
import { LocalUserSettingKey } from '@/features/user/types'

const hideToastHelper = (id: string) => {
  switch (id) {
    case 'NoCalendarConnectionInfoToast':
      localUserSettings.set(LocalUserSettingKey.NO_CALENDAR_CONNECT_INFO_TOAST, {
        hidden: true,
        hiddenAt: new Date(),
      })
      break
    default:
      break
  }
}

export default hideToastHelper
