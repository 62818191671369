import React, { Component } from 'react'
// Styles
import styles from './styles.module.css'
import expandLessIcon from '../../../images/fluent/expand_less.svg'

interface CollapsibleSectionProps {
  children: JSX.Element
  title: string
  open: boolean
  containerClassName?: Array<string>
  headerClassName?: Array<string>
  contentClassName?: Array<string>
  onExpand: () => void
  onCollapse: () => void
  label?: string | JSX.Element
  labelClassName?: Array<string>
  extraCompAtHeader?: JSX.Element
}

interface CollapsibleSectionState {
  // open?: boolean
}

export default class CollapsibleSection extends Component<CollapsibleSectionProps, CollapsibleSectionState> {
  props: CollapsibleSectionProps
  state: CollapsibleSectionState
  constructor(props: CollapsibleSectionProps) {
    super(props)
    this.props = props
    this.state = {
      // open: this.props.open || false,
    }
    this.toggleExpand = this.toggleExpand.bind(this)
  }

  // componentDidUpdate(prevProps: Readonly<CollapsibleSectionProps>): void {
  //   if (prevProps.open !== this.props.open) {
  //     this.setState({ open: this.props.open })
  //   }
  // }

  toggleExpand(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    const { open } = this.props
    // this.setState({ open: !open })
    if (open) {
      this.props.onCollapse()
    } else {
      this.props.onExpand()
    }
  }

  render() {
    const { open } = this.props
    const {
      children,
      title,
      containerClassName = [],
      headerClassName = [],
      contentClassName = [],
      labelClassName = [],
      label,
      extraCompAtHeader,
    } = this.props
    return (
      <div
        className={[
          styles.parent,
          ...containerClassName,
          open ? styles.showParentConent : styles.hidenParentContent,
        ].join(' ')}
      >
        <div className={[styles.header, ...headerClassName].join(' ')} onClick={this.toggleExpand}>
          <img
            className={[styles.icon, open ? styles.openIcon : styles.closeIcon].join(' ')}
            src={expandLessIcon}
            alt='CollapseArrowIcon'
          />
          <div className={styles.title}>{title}</div>
          {label && <label className={[...labelClassName].join(' ')}>{label}</label>}
        </div>
        {extraCompAtHeader && <div className={styles.extraCompAtHeaderEnd}>{extraCompAtHeader}</div>}
        <div
          className={[styles.content, ...contentClassName, open ? styles.showContent : styles.hideContent].join(' ')}
        >
          {children}
        </div>
      </div>
    )
  }
}
