import { EntityStatus } from '@/app/types'
import { SorRecord } from '../crm/types'

export type ConnectCrmBannerSetting = {
  hidden: boolean
  hiddenAt: Date | undefined
}

export type NoCalendarConnectionInfoToastType = {
  hidden: boolean
  hiddenAt: Date | undefined
}

export enum LocalUserSettingKey {
  DARK_THEME = 'darkTheme',
  CONNECT_CALENDAR_DIALOG_HIDDEN = 'connectCalenderDialogHidden',
  CONNECT_EMAIL_DIALOG_HIDDEN = 'connectEmailDialogHidden',
  CONNECT_CRM_BANNER_HIDDEN = 'crmConnectBannerHidden',
  ONBOARDING_BANNER_HIDDEN = 'onboardingBannerHidden',
  USER_NOTIFICATION_SETTINGS = 'userNotificationsSetting',
  CONNECTED_EMAIL_FROM_MISC_PAGE = 'connectedEmailFromMiscPage',
  NO_CALENDAR_CONNECT_INFO_TOAST = 'noCalendarConnectInfoToast',
}

export type LocalUserSettings = {
  [LocalUserSettingKey.DARK_THEME]: boolean
  [LocalUserSettingKey.CONNECT_CALENDAR_DIALOG_HIDDEN]: boolean
  [LocalUserSettingKey.CONNECT_EMAIL_DIALOG_HIDDEN]: boolean
  [LocalUserSettingKey.CONNECT_CRM_BANNER_HIDDEN]: {
    hidden: boolean
    hiddenAt: Date
  }
  [LocalUserSettingKey.ONBOARDING_BANNER_HIDDEN]: {
    hidden: boolean
    hiddenAt: Date
  }
  [LocalUserSettingKey.USER_NOTIFICATION_SETTINGS]: Array<{
    notificationId: string
    hidden: boolean
    hiddenAt: Date
    hiddenCount: number
    crm: CRMProvider
  }>
  [LocalUserSettingKey.CONNECTED_EMAIL_FROM_MISC_PAGE]: boolean
  [LocalUserSettingKey.NO_CALENDAR_CONNECT_INFO_TOAST]: {
    hidden: boolean
    hiddenAt: Date
  }
}

export enum RecentEntityType {
  NOTE = 'note',
  MEETING_NOTE = 'meeting_note',
  VIEW = 'view',
  TEMPLATE = 'note_template',
  OPPORTUNITY = 'deal',
  ACCOUNT = 'account',
  LEAD = 'lead',
  CONTACT = 'contact',
}

export interface RecentItem {
  type: RecentEntityType
  id: string
  name: string
  link?: string
}

export enum UserSignInStatus {
  SIGNED_IN = 'signedIn',
  SIGNED_OUT = 'signedOut',
  INITIAL = 'initial',
}

export enum EntryPoint {
  WEBAPP = 'webapp',
  EXTENSION = 'extension',
}

export enum StateDataSource {
  INITIAL_STATE_JS = 'js-initial-state',
  SEED_STATE_EXTN = 'ext-seed-state',
}

export enum EmailSyncState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
}

export type ReduxUserState = {
  status: UserSignInStatus
  data: {
    user_id: string
    tenant_id: string
    firstName: string
    lastName: string
    isSignedIn: boolean
    email: string
    photoUrl: string | null
    userSettings: {
      connectedCRM: {
        name: CRMProvider
        sor_user?: UserSorRecord
      } | null
      connectedCalendar: { name: CalendarProvider; errorCode?: number } | null
      connectedEmail: { name: EmailProvider } | null
      connections: Array<{ name: string }>
      darkTheme?: boolean
      emailSyncData?: {
        data: {
          syncStatus?: EmailSyncState
          mailBox?: string
          lastSyncTime?: string
        }
        getStatus?: EntityStatus
        setStatus?: EntityStatus
      }
    }
    userPolicies?: Array<UserPolicy>
    notifications: Array<UserNotification>
    requestSource?: StateDataSource
    userRoles: Array<{ name: string; id: string }>
    userAdminTasks: Array<{ name: string; status: string; data: {} }>
  }
  recentItems?: Array<RecentItem>
  extensionSettings?: {
    extensionUrl: string
    extensionVersion: string
    webServer: string
    searchShortcut: string
    signInStatus: string
    sessionCookieValue: string
    tenantDomain: string
    csrfTokenCookieValue: string
    signInTimeStamp?: string
    refreshStateTimeStamp?: string
  }
  applicationStatus?: {
    errorCode: LuruAppErrorCode | undefined
  }
  searchShortcut: string
}

export interface UserSorRecord extends SorRecord {
  sor_object_name: 'User'
}

export enum UserPolicyEffect {
  ALLOW = 'allow',
  denay = 'deny',
}
export enum UserPolicyType {
  API = 'api',
  WORKFLOW = 'workflow',
}
export interface UserPolicy {
  name: string
  effect: UserPolicyEffect
  action: string | Array<string>
  resource: string | Array<string>
}

export interface UserNotification {
  id: string
  data: string
  notify_at: string
  expiry_at: string
  category: NotificationCategory
  type: NotificationType
  state: NotificationState
  dismissible?: boolean
  reminder_frequency?: number
  target_type: NotificationTargetType
  target_id: string
  last_dismissed_at: string | null
}

// Empty state, default initial state given to createSlice() for inferring
// correct type of ReduxUserState
export const EmptyUserState: ReduxUserState = {
  status: UserSignInStatus.SIGNED_OUT,
  data: {
    user_id: '',
    tenant_id: '',
    firstName: '',
    lastName: '',
    isSignedIn: false,
    photoUrl: null,
    email: '',
    userSettings: {
      connectedCRM: null,
      connectedCalendar: null,
      connectedEmail: null,
      connections: [],
    },
    userPolicies: [],
    notifications: [],
    userRoles: [],
    userAdminTasks: [],
  },
  recentItems: undefined,
  searchShortcut: '',
}

export enum LuruAppErrorCode {
  NETWORK_ERROR = 'networkError',
  AUTHORIZATION_ERROR = 'authorizationError',
}

// TODO: Move it under CRM typings
export enum CRMProvider {
  SFDC = 'SFDC',
  SFDC_SANDBOX = 'SFDC_SANDBOX',
  HUBSPOT = 'HUBSPOT',
  PIPEDRIVE = 'PIPEDRIVE',
}

export enum CalendarProvider {
  GCAL = 'GCAL',
  O365CAL = 'O365CAL',
}

export enum EmailProvider {
  GMAIL = 'GMAIL',
  O365MAIL = 'O365MAIL',
}

export enum MessagingProvider {
  SLACK = 'SLACK',
  MSTEAMS = 'MSTEAMS',
  GCHAT = 'GCHAT',
}

// Notification typings
export enum NotificationCategory {
  BANNER = 'BANNER',
  MODAL = 'MODAL',
  TOAST = 'TOAST',
}

export enum NotificationTargetType {
  TENANT = 'TENANT',
  USER = 'USER',
  GROUP = 'GROUP',
}

export enum NotificationState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum NotificationType {
  REMINDER = 'REMINDER',
  INFO = 'INFO',
  ERROR = 'ERROR',
  ALERT = 'ALERT',
}

export interface NotificationActionButton {
  label: string
  href?: string
  outline?: boolean
}
