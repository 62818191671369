import { RootState } from '../../../app/store'
import { ReduxChatStateTTL } from '../types'

export const isChatConversationsCacheValid = (state: RootState) =>
  !Boolean(state.chat.refreshedAt.conversations) ||
  new Date().valueOf() -
    (state.chat.refreshedAt.conversations
      ? new Date(state.chat.refreshedAt.conversations).valueOf()
      : 0) <
    ReduxChatStateTTL
